import {Injectable} from '@angular/core';
import {Headers} from '@angular/http';
import {Router} from '@angular/router';
import {throwError as observableThrowError} from 'rxjs';
import {PhaseService} from '@shared/services/phase.service';
import {CommonService} from '@shared/services/common.service';
import {HttpHeaders} from '@angular/common/http';
declare let window: any;

@Injectable()
export class GenericService {
  constructor(private _router: Router, private commonService: CommonService) {
  }

  public getRequestOptionArgs(contentType?: string) {
    let headers = new HttpHeaders();
    let token;

    if (this.getAdminUser()) {
      token = this.getAdminUser().authtoken;
    }

    if (!contentType) {
      headers.append('Content-Type', 'application/json');
    }
    headers.append('Content-Type', contentType);
    if (token) {
      headers.append('authtoken', token);
    }
    return headers;
  }

  public getAdminUser() {
    if (this.commonService.isPlatformBrowser) {
      const user = localStorage.getItem('payment_user');
      if (user) {
        return JSON.parse(user);
      }
    }
  }

  public setAdminUser(admin) {
    if (this.commonService.isPlatformBrowser) {
      localStorage.setItem('payment_user', JSON.stringify(admin));
    }
  }

  public removeAdminUserData() {
    if (this.commonService.isPlatformBrowser) {
      localStorage.removeItem('payment_user');
    }
  }

  public handleError(error: Response) {
    if (error.status === 401) {
      this.removeAdminUserData();
      this._router.navigate(['admin-dashboard/login']);
    }
    return observableThrowError(error);
  }

  public handleDateForDatePicker(date: string) {
    const dateObj: Date = new Date(date);
    const month: number = dateObj.getUTCMonth() + 1;
    const day: number = dateObj.getUTCDate();
    const year: number = dateObj.getUTCFullYear();

    return {date: {day: day, month: month, year: year}};
  }

}
