<div class="completeProfile">
  <div class="completeProfileHolder">
    <h2>Complete Profile</h2>
    <div class="whiteBg">
      <div class="paginationTabs">
        <ul>
          <li
            [ngClass]="{'complete': dataService.isPartnerProfileUpdated, 'current': !dataService.isPartnerProfileUpdated}">
            <div class="icon"></div>
            <span>Profile</span></li>
          <li
            [ngClass]="{'complete': dataService.allContractsSigned, 'current': dataService.isPartnerProfileUpdated && !dataService.allContractsSigned}">
            <div class="icon"></div>
            <span>Sign Contract</span></li>
        </ul>
      </div>


      <div class="profileForm" *ngIf="!dataService.isPartnerProfileUpdated && !dataService.allContractsSigned"
           [ngClass]="{'active': !dataService.isPartnerProfileUpdated && !dataService.allContractsSigned}">
        <form [formGroup]="partnerDataService.profileForm" (ngSubmit)="onProfileSubmit(partnerDataService.profileForm)">
          <ul>
            <li>
              <mat-form-field>
                <input matInput type="text" placeholder="Name of Partner *" formControlName="partnerName">
              </mat-form-field>
              <span class="errorMsg"
                    *ngIf="partnerDataService.profileForm?.get('partnerName')?.hasError('required') && partnerDataService.profileForm?.get('partnerName')?.touched">This field is mandatory</span>
            <li>
              <mat-form-field>
                <input type="text" matInput placeholder="Company Name *" formControlName="companyName">
              </mat-form-field>
              <span class="errorMsg"
                    *ngIf="partnerDataService.profileForm?.get('companyName')?.hasError('required') && partnerDataService.profileForm?.get('companyName')?.touched">This field is mandatory</span>
            </li>
            <li>
              <mat-form-field>
                <input type="text" placeholder="Website Link " matInput formControlName="websiteLink">
              </mat-form-field>
              <span class="errorMsg"
                    *ngIf="partnerDataService.profileForm?.get('websiteLink')?.hasError('invalidUrl') && partnerDataService.profileForm?.get('websiteLink')?.touched">Invalid url</span>
            </li>
            <li>
              <mat-form-field>
                <input type="text" matInput placeholder="Your Role *" formControlName="spocRole">
              </mat-form-field>
              <span class="errorMsg"
                    *ngIf="partnerDataService.profileForm?.get('spocRole')?.hasError('required') && partnerDataService.profileForm?.get('spocRole')?.touched">This field is mandatory</span>
            </li>
            <li>
              <mat-form-field>
                <input type="text" matInput placeholder="Your Name *" formControlName="spocName">
              </mat-form-field>
              <span class="errorMsg"
                    *ngIf="partnerDataService.profileForm?.get('spocName')?.hasError('required') && partnerDataService.profileForm?.get('spocName')?.touched">This field is mandatory</span>
            </li>
            <li>
              <div class="customSelect">
                <mat-form-field>
                  <mat-select formControlName="companySize" placeholder="Company size *">
                    <mat-option value="Less than 10">Less than 10</mat-option>
                    <mat-option value="Less than 100">Less than 100</mat-option>
                    <mat-option value="Between 100 - 1,000">Between 100 - 1,000</mat-option>
                    <mat-option value="Between 1,000 - 10,000">Between 1,000 - 10,000</mat-option>
                    <mat-option value="Greater than 10,000">Greater than 10,000</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </li>
            <li>
              <div class="customSelect">
                <mat-form-field>
                  <input type="text" matInput placeholder="Industry *" formControlName="industry">
                </mat-form-field>
              </div>
              <span class="errorMsg"
                    *ngIf="partnerDataService.profileForm?.get('industry')?.hasError('required') && partnerDataService.profileForm?.get('industry')?.touched">This field is mandatory</span>
            </li>
          </ul>
          <div class="buttonControl">
            <button type="submit" class="doneButton" [disabled]="!partnerDataService.profileForm?.valid">Next
              <img *ngIf="loader" src="./../../../../assets/images/buttonLoader.gif" alt=""/>
            </button>
          </div>
        </form>
      </div>

      <app-partner-contracts *ngIf="dataService.isPartnerProfileUpdated && !dataService.allContractsSigned"
                             [ngClass]="{'active': dataService.isPartnerProfileUpdated && !dataService.allContractsSigned}">
      </app-partner-contracts>


      <div class="thanskScreen" *ngIf="dataService.allContractsSigned">
        <em class="icon-tick"></em>
        <h3>Thanks!</h3>
        <h4>Your profile is now complete, We are in process of verification.</h4>
        <p><strong>Sit back and Relax!</strong>
          Our sales team will verify your profile and send you Unique Code to process.</p>
      </div>


    </div>
  </div>
</div>
