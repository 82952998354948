import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ShareInviteComponent} from './popups/shareinvite/shareinvite.component';

@NgModule({
    declarations: [ShareInviteComponent],
    imports: [
        CommonModule,
        FormsModule
    ],
    exports: [ShareInviteComponent]
})
export class ShareinviteModule { }
