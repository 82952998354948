import {Component, OnInit} from '@angular/core';
import {PartnerDashboardDataService} from '../../Utils/partner-dashboard-data.service';
import {DataService} from '@shared/services/data.service';
import {FormControl} from '@angular/forms';
import {PartnerDashboardApiService} from '../../Utils/partner-dashboard-api.service';
import {PhaseService} from '@shared/services/phase.service';
import {ApiRequests} from '@shared/services/api-requests.service';

@Component({
  selector: 'app-partner-configuration',
  templateUrl: 'configuration.component.html'
})

export class PartnerConfigurationComponent implements OnInit {
  public errorMessage = '';
  public buttonText = 'Upload';
  public isSignupAllowed = false;
  public validators = [this.isEmail];
  public loader = false;
  public canUpload = true;
  public chipsValidationMessages = {
    'isEmail': 'Invalid email',
  };

  constructor(public partnerDataService: PartnerDashboardDataService,
              private _partnerApiService: PartnerDashboardApiService,
              public phaseService: PhaseService,
              private _apiRequestService: ApiRequests,
              public dataService: DataService) {
  }

  public ngOnInit() {
    this._apiRequestService.fetchHomePageData()
      .subscribe(
        (data) => {

          this.partnerDataService.homePage = data;
        }
      );
  }

  public uploadLogo(file) {
    const imageFile = file.target.files[0];
    if (imageFile.type === 'image/jpeg' || imageFile.type === 'image/png' || imageFile.type === 'image/svg+xml') {
      if (imageFile.size <= 1024 * 1024 * 2) {
        this.partnerDataService.configData.logoFile = imageFile;
        this.buttonText = 'Change';
        this.errorMessage = null;
        this.canUpload = false;
      } else {
        this.errorMessage = 'Image size should not be more than 2MB';
        this.partnerDataService.configData.logoFile = null;
        this.buttonText = 'Upload';
      }
    } else {
      this.errorMessage = 'Invalid file';
      this.partnerDataService.configData.logoFile = null;
      this.buttonText = 'Upload';
    }
  }

  public checkImageDimensions(file) {
    const vm: any = this;
    const reader: any = new FileReader();
    reader.readAsDataURL(file.files[0]);
    reader.onload = function (e) {
      const image: any = new Image();
      image.src = e.target.result;
      image.onload = function () {
        const height = this.height;
        const width = this.width;
        vm.canUpload = (height < 40 && width < 100);
      };
    };
  }

  public selectTemplate(template) {
    this.partnerDataService.configData.selectedTemplate = template;
  }

  public setPaymentMechanism(mechanism) {
    this.partnerDataService.configData.paymentMechanism = mechanism;
    if (mechanism === 'sell_through') {
      this.partnerDataService.selectedCurrency = [];
    }
  }

  public isEmail(control: FormControl) {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (control && control.value && !control.value.match(emailRegex)) {
      return {
        'isEmail': true
      };
    }
  }

  public validateVariance() {
    return parseInt(this.partnerDataService.configData.variance, 10) <= 100;
  }

  public limitInput(event) {
    const val = event.target.value;
    if (parseInt(val, 10) > 100) {
      this.errorMessage = 'Invalid entry';
    } else {
      this.errorMessage = null;
    }
  }

  public limitDecimal(event) {
    const val = event.target.value;
    const arr = val.toString().split('');
    let count = 0;
    arr.forEach(data => {
      if (data === '.') {
        count++;
      }
    });
    if (count > 1) {
      this.errorMessage = 'Invalid entry';
      event.stopPropagation();
      return false;
    } else {
      if (val.toString().split('.')[1] && val.toString().split('.')[1].length === 1) {
        return false;
      } else {
        event.stopPropagation();
        return true;
      }
    }
  }

  public changePartnerType() {
    if (this.partnerDataService.configData.isSignupAllowed === 'true') {
      this.partnerDataService.configData.paymentMechanism = 'sell_through';
    } else {
      this.partnerDataService.configData.paymentMechanism = 'sell_to';
    }
  }

  public selectCurrency(currency) {
    this.partnerDataService.selectedCurrency = [];
    this.partnerDataService.selectedCurrency.push(currency.id);
  }

  public onSubmitConfigData() {
    this.partnerDataService.partnerStatus = 'profile_completed';
    this.loader = true;
    this._partnerApiService.configureBuilder(this.partnerDataService.configData)
      .subscribe(
        (data) => {
          this.loader = true;
          this._partnerApiService.updatePartnerProfile()
            .subscribe(
              (res) => {
                this.partnerDataService.setPartnerUser(res);
                this.loader = false;
                this.dataService.resetPartnerViews();
                if (data.currencies && this.partnerDataService.configData.paymentMechanism === 'sell_to') {
                  this.partnerDataService.selectedCurrency = data.currencies;
                }
                if (this.partnerDataService.configData.paymentMechanism === 'sell_to') {
                  this.dataService.showHideViews.payment = true;
                } else if (this.partnerDataService.configData.paymentMechanism === 'sell_through') {
                  this.dataService.showHideViews.partnerVerified = true;
                  this.dataService.partnerProfileSubmitted = true;
                }
              },
              (err) => {
                this.loader = false;
              }
            );
          this.loader = false;
        },
        (error) => {
          this.loader = false;
        }
      );
  }

  public limitMarkup(event?) {
    let val;

    if (event) {
      val = event.target.value;
    } else {
      val = this.partnerDataService.configData.variance;
    }
    if (val) {
      if (isNaN(Number(val))) {
        this.errorMessage = 'Invalid entry';
        return false;
      } else {
        if (Number(val) > 100) {
          this.errorMessage = 'Invalid entry';
          return false;
        } else {
          this.errorMessage = '';
          return true;
        }
      }
    }
    return false;
  }

  public checkSellto() {
    if (this.partnerDataService.getPartnerUser() && this.partnerDataService.getPartnerUser().partner_type === 'sell_to') {
      if (this.partnerDataService.selectedCurrency && this.partnerDataService.selectedCurrency.length === 0) {
        return true;
      }
    }
    return false;
  }

  public validateFloatKeyPress(event) {
    if ((event.which != 46 || event.target.value.indexOf('.') != -1) && ((event.which < 48 || event.which > 57) && event.which !== 45)) {
      //alert('hello');
      if (((event.which != 46 && event.which != 45) || event.target.value.indexOf('.') != -1)) {
        event.preventDefault();
      }
      event.preventDefault();
    }
    if (event.target.value.indexOf('.') > -1 && (event.target.value.split('.')[1].length > 0)) {
      event.preventDefault();
    }
  }
}
