import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Angulartics2Segment } from 'angulartics2/segment';
import { PhaseService } from '@shared/services/phase.service';
import { DataService } from '@shared/services/data.service';
import { Router } from '@angular/router';
import { ApiRequests } from '@shared/services/api-requests.service';
import { Constants } from '@shared/utils/constants';
import { ActivatedRoute } from '@angular/router';
import { AppDataService } from '@rootmodule/app-data.service';
import { CommonService } from '../../../../shared/shared-module/services/common.service';
import { Intercom } from 'ng-intercom';
import { SeoService } from '../../../../shared/shared-module/services/seo.service';
import { DynamicScriptLoaderService } from '../../../../shared/shared-module/services/dynamic-script-loader.service';
import {PaymentBuilderService} from '@featuremodules/payment-builder-module/payment-builder.service';
import { PaymentService } from '@shared/services/payment.service';
import axios from 'axios';
import moment from 'moment';
import { ReferralService } from '@shared/services/referral.service';
import {HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-payment-plan',
  templateUrl: './payment-plan.component.html',
  styleUrls: ['./payment-plan.component.scss'],
  // providers: [PaymentService]
})
export class PaymentPlanComponent implements OnInit, AfterViewInit, OnDestroy {
  dateOfPayment = new Date();
  dateOfLastPayment = new Date();
  showDetails = false;
  showBiMonthly = false;
  showDeliverable = false;
  showMilestone = false;
  disableMonthly = false;
  disableWeekly = false;
  docsToBeReset = [];
  isBillingCycleChanged = false;
  weeklyOrMonthly: string;
  math = Math;
  moment: any;
  selectedIndex = -1;
  twoPayCreditCheckData: any;
  @ViewChild('iframe') iframe: ElementRef;

  constructor(public analyticsSegment: Angulartics2Segment,  public phaseService: PhaseService,
              private appDataService: AppDataService, public apiRequests: ApiRequests, public dataService: DataService, public router: Router,
              public apiRequest: ApiRequests, private _route: ActivatedRoute, public commonService: CommonService, private seoService: SeoService,
              private dynamicScriptLoaderService: DynamicScriptLoaderService, public paymentService: PaymentBuilderService, public referralService: ReferralService,
              public payments: PaymentService
              ) {
    this.moment = moment;
    this.loadScriptDynamically();
    this.phaseService.supportMaintenance = 0;
    this.seoService.noIndexMeta();
  }


  ngOnInit() {
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    this.dataService.showHideLoader(true);
    if (this.dataService.errorMessage) {
      this.commonService.showError();
      this.dataService.showHideLoader(false);
    }
    if (this.dataService.user) {
      this.phaseService.currencySymbol = this.dataService.user.currency.symbol;
    }
    if (this.phaseService.isEditFeatureRequest()) {
      const frequency = this.dataService.changeFeatureRequestModel.build_card
        ? (this.dataService.changeFeatureRequestModel.build_card.frequency)
        : (this.dataService.buildCardData.frequency);
      if (frequency === 'weekly') {
        this.dataService.showWeekly = true;
        this.dataService.showMonthly = false;
        this.dataService.showUpfront = false;
        this.disableMonthly = true;
      } else if (frequency === 'monthly') {
        this.dataService.showMonthly = true;
        this.dataService.showWeekly = false;
        this.dataService.showUpfront = false;
        this.disableWeekly = true;
      } else if (frequency === 'upfront') {
        this.dataService.showMonthly = false;
        this.dataService.showWeekly = false;
        this.dataService.showUpfront = true;
      }
      this.phaseService.fetchInstallments(false, true);
      this.dataService.currentPage = 'paymentPlan';
      if (this.phaseService.isStoreFeatureRequest) {
        this.dataService.unsavedChanges = false;
      }
    } else {
      if (!this.dataService.buildCardData.uniq_code) {
        this.getBuildCardIDOnReload();
      }
      if (!Object.values(this.dataService.proposalInstallments).length && this.dataService.buildCardData.id) {
        this.phaseService.fetchProposalInstallments();
      }
      this.phaseService.checkIfAddressSubmitted();
      this.phaseService.getInstallmentCount();
      this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.installments.code);
      if (!this.dataService.homePageData.platforms) {
        this.phaseService.timerStart();
        this.apiRequest.fetchHomePageData().subscribe((data: any) => {
          this.dataService.dataLoadingCurrency = Object.assign({}, this.dataService.homePageData.currency);
          this.phaseService.platformPrices = Array.apply(null, Array(this.dataService.homePageData.platforms.length));
          this.phaseService.platformPrices.map((x, i) => {
            return i;
          });
          if (this.dataService.homePageData.currency) {
            this.phaseService.specing_price = this.dataService.homePageData.currency.specing_price;
          }
          if (this.dataService.buildCardData.uniq_code) {
            this.fetchSingleBuildCardData(this.dataService.buildCardData.uniq_code);
          }
        });
      } else {
        if (this.dataService.buildCardData.uniq_code) {
          this.fetchSingleBuildCardData(this.dataService.buildCardData.uniq_code);
        }
      }
    }
    this.openCapchaseIframe();
  }

  openCapchaseIframe() {
    this.paymentService.isIframeLink.subscribe((resp) => {
      if (resp === 'openIframe') {
        this.iframe.nativeElement.setAttribute('src', this.paymentService.capchaseIframeLink);
        document.querySelector('#myframe').addEventListener('load', ev => {
          this.paymentService.isLoading = false;
        });
      } else if (resp === 'closeIframe') {
        this.iframe.nativeElement.setAttribute('src', '');
        this.paymentService.isCapchaseLink = false;
      }
    });
  }

  loadScriptDynamically() {
    this.dynamicScriptLoaderService.load('google_places').then((data) => {
    }).catch(error => {

    });
  }

  public ngAfterViewInit() {
    this.phaseService.checkPaymentExitIntentTimer();
  }

  getInstallmentAndSupportWithTax(installment){
    return Number(installment.installment_amount) + Number(installment.tax_amount);
  }

  getTotalPrice(installments) {
    let amountArray = [];
    installments.forEach((installment)=>{
      amountArray.push(Number(installment.installment_amount) + Number(installment.tax_amount));
    })
    if (amountArray.length) {
      return amountArray.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
      );
    }
  }
  public getTotalWithTax() {
    return this.phaseService.getTotalWithTax();
  }
  updateBillingFrequency() {
    this.dataService.showMonthly = false;
    this.dataService.showWeekly = false;
    this.dataService.showUpfront = true;
    this.commonService.startTwoPayFlow = false;
    this.commonService.isTwoPayRejected = false;
    this.commonService.isBNPLRejected = false;
  }

  public fetchSingleBuildCardData(uniqueCode) {
    const user = this.dataService.user;
    if (this.phaseService.promotionChangedOnMaxBuildCard || this.phaseService.isInstallmentsChanged ||
      !(this.phaseService.paymentData && this.phaseService.paymentData.installments && (this.phaseService.paymentData.installments.length > 0))) {
      this.dataService.showHideLoader(true);
      this.apiRequest.fetchSingleBuildCardData(uniqueCode).subscribe((data: any) => {
        this.dataService.currentPage = 'paymentPlan';
        this.referralService.sliderValueForCreditedReferralPoints = this.dataService.buildCardData.reward_point;
        const card = this.dataService.buildCardData;
        if (card.nda_signed) {
          this.paymentService.selectAllTermsAndConditions();
        }
        if ((card.build_card_type === 'studio_store') && (card.status === 'started')) {
          this.phaseService.redirectToBuilderLive();
        } 
        if ((card.status === 'started') && !this.phaseService.isViewUpdatedInstallmets) {
          this.paymentService.isCardPaid = true;
          this.paymentService.showPaymentScreen = false;
          this.paymentService.showBillingInfo = false;
          this.paymentService.startPollingForPayment();
        }
        if (this.phaseService.checkIfStudioStorePromoModule() && this.phaseService.isRentalBuildCard() && this.dataService.buildCardData.promotion && this.dataService.buildCardData.promotion.type === 'MonthDiscountPromotion') {
          this.phaseService.monthsDiscountForRental = this.dataService.buildCardData.promotion.months_discount;
        }
        if (card.status === Constants.buildCardStatus.UNDER_DEVELOPMENT ||
          (card.status !== Constants.buildCardStatus.UNDER_DEVELOPMENT && card.development_state) || card.kick_off_date) {
          this.phaseService.redirectToBuilderLive();
        }

        if (card.subscription_flow && this.dataService.isUnpaidCard()) {
          const installmentType = { frequency: 'upfront' };
          this.apiRequests.createUpdateBuildCard(installmentType, this.dataService.buildCardData.id, '', 'rename_build_card').subscribe(() => {
            this.phaseService.fetchInstallments();
            this.phaseService.showWeeklyOrMonthly();
          });
        }

        if (this.dataService.buildCardData && this.dataService.buildCardData.change_requests && (this.dataService.buildCardData.change_requests.length > 0)) {
          this.dataService.onPageReload = true;
          this.dataService.isEditRequestedFeatures = true;
          this.phaseService.inProgressBuildCardId = this.dataService.buildCardData.id;
          this.dataService.totalAdditionalFeaturesCost = this.dataService.buildCardData.price;
          this.dataService.totalAdditionalFeaturesWeek = this.dataService.buildCardData.weeks;
          this.phaseService.fetchInstallments(false, true);
        } else {
          this.phaseService.isInstallmentsChanged = false;
          if (this.dataService.buildCardData.uniq_code) {
            this.phaseService.setUpInFullBuildCard();
            this.phaseService.setTheBuildCard();
            this.phaseService.fetchInstallments(true);
            this.phaseService.fetchProposalInstallments();
            this.phaseService.promotionChangedOnMaxBuildCard = false;
            // this.showWeeklyOrMonthly();
          }
        }
        // this.dataService.showHideLoader(false);
      }, (error) => {
        this.dataService.showHideLoader(false);
        if (this.commonService.isPlatformBrowser && (error.status === 403 || error.status === 401 || error.status === 404)) {
          this.dataService.invalidAccess = true;
          this.router.navigate(['invalidaccess']);
        }
      });
    } else {
      this.dataService.currentPage = 'paymentPlan';
      this.phaseService.getBuildCardPriceWithDiscount();
      this.dataService.isShowUpfrontPaymentPlan = false;
    }
  }

  showWeeklyOrMonthly() {
    this.phaseService.showWeeklyOrMonthly();
  }

  public getDateOfPayment(installmentNo) {
    this.dateOfPayment = new Date();
    this.dateOfPayment.setDate(this.dateOfPayment.getDate() + (7 * installmentNo));
    return this.dateOfPayment;
  }

  public getDateOfLastPayment() {
    this.dateOfLastPayment = new Date();
    return this.dateOfLastPayment.setDate((this.dateOfLastPayment.getDate() + (7 * this.phaseService.installmentCount)));
  }

  public getBuildCardIDOnReload(): void {
    this._route.parent.params.subscribe(params => {
      const uniq_code = params['id'];
      this.dataService.buildCardData.uniq_code = uniq_code;
      this.dataService.buildCardUniqueCode = uniq_code;
      if (uniq_code) {
        this.phaseService.fetchContractDetails(uniq_code);
      }
    });
  }

  public getLocaledPrice(price: any): any {
    return this.dataService.getLocaledPrice(price);
  }

  public getCurrencySymbol() {
    return this.dataService.buildCardData.id ? this.dataService.buildCardData.currency.symbol : this.phaseService.currencySymbol;
  }

  selectedCare() {
    const careType = this.dataService.buildCardData.care_type
    return careType === 'standard_care' ? 'Builder Care' : careType === 'standard_care_plus' ? 'Builder Care Plus' : careType === 'enterprise_care' ?'Enterprise Care' : 'Enterprise Care Plus'
  }

  isShowToolTip(installments) {
    return installments.findIndex((installment)=>{
      return installment.care_included || installment.type === 'support';
    })
  }

  public getInstallments() {
    return this.phaseService.installments;
  }

  public getInstallmentWithTax(installment: any) {
    return this.phaseService.getInstallmentWithTax(installment);
  }

  public updateShowDetails(showDetails: boolean, weeklyOrMonthly?) {
    this.showDetails = showDetails;
    this.weeklyOrMonthly = weeklyOrMonthly;
  }

  public showBillingCycleTab(tabNo, event?) {
    if (((tabNo === 1) && this.dataService.showWeekly) || ((tabNo === 3) && this.dataService.showMonthly)) {
      event.target.checked = true;
      return;
    }
    if (!this.checkIfMSAIsSigned()) {
      this.dataService.showWeekly = false;
      this.showBiMonthly = false;
      this.dataService.showMonthly = false;
      this.showDeliverable = false;
      this.showMilestone = false;
    }
    switch (tabNo) {
      case 1:

        this.phaseService.trackEventForObject(this.analyticsSegment, 'billing_cycle_selected', {
          buildcard_name : this.phaseService.currentProjectName,
          billing_cycle_selected : 'Weekly'
        });
        this.dataService.showWeekly = true;
        this.dataService.showMonthly = false;
        if (this.dataService.isEditBuildCardAfterSecurityPaid()) {
          this.changeFrequencyAfterEditBuildCardAfterSecurityPaid();
        }
        return;
      case 2:
        this.phaseService.trackEventForObject(this.analyticsSegment, 'billing_cycle_selected', {
          buildcard_name : this.phaseService.currentProjectName,
          billing_cycle_selected : 'Bi-Monthly'
        });
        this.showBiMonthly = true;
        return;
      case 3:
        this.phaseService.trackEventForObject(this.analyticsSegment, 'billing_cycle_selected', {
          buildcard_name : this.phaseService.currentProjectName,
          billing_cycle_selected : 'Monthly'
        });
        this.dataService.showMonthly = true;
        this.dataService.showWeekly = false;
        if (this.dataService.isEditBuildCardAfterSecurityPaid()) {
          this.changeFrequencyAfterEditBuildCardAfterSecurityPaid();
        }
        return;
      case 4:
        this.phaseService.trackEventForObject(this.analyticsSegment, 'billing_cycle_selected', {
          buildcard_name : this.phaseService.currentProjectName,
          billing_cycle_selected : 'Delivery Based'
        });
        this.showDeliverable = true;
        return;
      case 5:
        this.phaseService.trackEventForObject(this.analyticsSegment, 'billing_cycle_selected', {
          buildcard_name : this.phaseService.currentProjectName,
          billing_cycle_selected : 'Milestone Based'
        });
        this.showMilestone = true;
        return;
      case 6:
        this.phaseService.trackEventForObject(this.analyticsSegment, 'billing_cycle_selected', {
          buildcard_name : this.phaseService.currentProjectName,
          billing_cycle_selected : 'Upfront'
        });
        this.dataService.showMonthly = false;
        this.dataService.showWeekly = false;
        this.dataService.showUpfront = true;
        if (this.dataService.isEditBuildCardAfterSecurityPaid()) {
          this.changeFrequencyAfterEditBuildCardAfterSecurityPaid();
        }
      default:
        return;
    }
  }

  changeFrequencyAfterEditBuildCardAfterSecurityPaid() {
    const postData = {};
    if (this.dataService.showWeekly) {
      postData['frequency'] = 'weekly';
    } else {
      postData['frequency'] = 'monthly';
    }
    this.apiRequest.createUpdateBuildCard(postData, this.phaseService.inProgressBuildCardId, '', 'changeFrequency')
      .subscribe(
      (data: any) => {
      }, (error) => {
      });
  }

  public redirectToDashboardForAdditionalFeatures() {
    this.payments.additionalFeatureRequest();
    if (this.phaseService.isStoreFeatureRequest) {
      this.dataService.unsavedChanges = false;
    }
  }

  getTaxedPrice(price) {
    if (this.dataService.buildCardData.currency.tax > 0) {
      return price + (price * this.dataService.buildCardData.currency.tax) / 100;
    } else {
      return price;
    }
  }


  public closeApprovalPopup() {
    this.dataService.showApproveAndContinuePopup = false;
  }

  public ngOnDestroy() {
    this.payments.disableDoneButton = false;
    this.dataService.onPageReload = false;
    this.dataService.isEditRequestedFeatures = false;
    this.dataService.isMSAUpdatedFromPaymentPlan = false;
    this.paymentService.isCardPaid = false;
    this.paymentService.showBillingInfo = true;
    this.paymentService.showPaymentScreen = false;
    this.paymentService.showSpecCallInfo = false;
    this.phaseService.specCallIsScheduled = false;
    this.paymentService.startCapchaseFlow = false;
    this.paymentService.capchaseInstallments = [];
    this.paymentService.capchaseIframeLink = '';
    this.paymentService.isCapchaseFlowApplicable = false;
    this.commonService.startTwoPayFlow = false;
  }

  checkIfMSAIsSigned() {
    this.docsToBeReset = [];
    let isMSASigned = false;
    if (this.paymentService.checkIfAllTermsSelected()) {
      isMSASigned = true;
      return isMSASigned;
    } else {
      return isMSASigned;
    }
  }

  checkIfMSAUploaded(element) {
    return (element.document_status === 'Signed');
  }

  resetMSAContract() {
    this.isBillingCycleChanged = false;
    this.dataService.isMSAUpdatedFromPaymentPlan = true;
    this.dataService.showHideLoader(true);
    this.resetDocument(this.docsToBeReset, true);
    const isShowWeekly = this.dataService.showWeekly;
    this.dataService.showWeekly = this.dataService.showMonthly;
    this.dataService.showMonthly = isShowWeekly;
    if (this.dataService.showWeekly) {
      this.phaseService.trackEventForObject(this.analyticsSegment, 'billing_cycle_selected', {
        buildcard_name : this.phaseService.currentProjectName,
        billing_cycle_selected : 'Weekly'
      });
    } else if (this.dataService.showMonthly) {
      this.phaseService.trackEventForObject(this.analyticsSegment, 'billing_cycle_selected', {
        buildcard_name : this.phaseService.currentProjectName,
        billing_cycle_selected : 'Bi-Monthly'
      });
    }
  }

  resetDocument(documentType, isActionFromPopup?) {
    const reqParams = { nda_signed: false, is_custom_prototype: this.appDataService.urlParameters.isCustomPrototype ? true : false };
    this.apiRequests.setResetContractsSigned(this.dataService.buildCardData.id, reqParams).subscribe((resp) => {
      this.phaseService.resetTermsAndConditions();
      this.phaseService.fetchContractDetails(this.dataService.buildCardData.uniq_code);
      this.dataService.showHideLoader(false);
    }, error => {
      this.dataService.showHideLoader(false);
      console.log(error);
    });
  }

  closeResetMessagePopup() {
    this.isBillingCycleChanged = false;
  }

  shouldDisableWeekly() {
    if (this.dataService.buildCardData.type === 'rental_price' && this.dataService.buildCardData.source === 'app_detail') {
      this.disableWeekly = true;
    }
    return this.disableWeekly;
  }

  scrollTheSection(scrollTo) {
    document.getElementById('left-part').scrollTop = scrollTo;
  }

  showProposalInstallments() {
    if (this.weeklyOrMonthly === 'Weekly') {
      return this.dataService.proposalInstallments['weekly'];
    } else {
      return this.dataService.proposalInstallments['monthly'];
    }
  }

  async getFirstAlbumTitle() {
    const response = await axios.get('https://jsonplaceholder.typicode.com/albums');
    return 'result';
  }

  getBuildCardData() {
    return this.dataService.buildCardData;
  }

  getTootipShipmentText() {
   return this.dataService.proposalInstallments['first_shipment_date'].length ? 'first shipment' : 'final delivery';
  }

  getEstimatedDeliveryDate() {

    if(this.dataService.proposalInstallments['first_shipment_date'].length){
      return this.dataService.proposalInstallments['first_shipment_date'][0];
    }

    if (!this.phaseService.phaseData) {
      this.phaseService.phaseData = JSON.parse(JSON.stringify(this.dataService.buildCardData.progress.buildcardphases));
      this.phaseService.phaseData.forEach(phase => {
        phase.id = phase['build_phase_id'];
      });
    }
    if (this.phaseService.timePromotionApplied()) {
      return this.moment().add((this.phaseService.finalPricenWeeks.weeks * 7), 'days');
    } else {
      return this.phaseService.getFinalDeliveryDate();
    }
  }

  getCarePlusPrice(installment) {
    return (installment.care_amount ? installment.care_amount : 0);
  }

  getBuildCardPriceWithSSCost(installment) {
    return ((installment.build_card_amount ? installment.build_card_amount : 0) +
      (installment.subscription_amount ? installment.subscription_amount : 0));
  }

  getAdditionalCareText() {
    return this.paymentService.getCareTextForSubsFlow(this.phaseService.selectedHash.care_type).replace(' Plus', '+');
  }

  byPassBNPLFlow() {
    return (this.dataService.user.bypass_bnpl || this.dataService.buildCardData.bypass_bnpl);
  }

  openOtherPlans() {
    const user = this.dataService.user;
    if ((user.currency.id === 4) && user.company_id && (user.address.billed_as === 'company')
      && !this.byPassBNPLFlow()) {
      if (!this.commonService.isTwoPayRegCompany) {
        this.twoPayCreditCheck();
      } else {
        this.fetchTwoPayInstallments();
      }
    } else if ((user.currency.id === 2) && user.address && user.address.company_name &&
      (user.address.billed_as === 'company') && !this.byPassBNPLFlow()) {
      this.capchaseFlowChecking();
    } else {
      this.dataService.showMonthly = true;
      this.dataService.showWeekly = false;
      this.dataService.showUpfront = false;
    }
    this.paymentService.isCapchaseLink = false;
  }

  showPriceDetails(index) {
    if (this.selectedIndex === index) {
      this.selectedIndex = -1;
    } else {
      this.selectedIndex = index;
    }
  }

  getDiscountedPriceForPaidCards() {
    if (this.dataService.buildCardData.new_care_flow) {
      return this.dataService.modifiedBuildCardData.bcard_discounted_price;
    } else {
      return this.dataService.modifiedBuildCardData.discounted_price;
    }
  }

  getTotalUpdatedPriceWithTax() {
    const price = this.getDiscountedPriceForPaidCards().new;
    if (price) {
      return Number(price) +
        Math.round((Number(price) *
          Number(this.dataService.buildCardData.currency.tax)) / 100);
    } else {
      return this.getTotalWithTax();
    }
  }

  setPaymentPlanHeading() {
    if (this.dataService.modifiedBuildCardData && (this.dataService.buildCardData.frequency === 'upfront')) {
      return 'Your payment plan';
    } else {
      return 'Select your payment plan';
    }
  }

  twoPayCreditCheck() {
    const payload = {
      'national_identifier': this.dataService.user.company_id,
      'company_name': this.dataService.user.address.company_name,
      'amount': this.dataService.buildCardData.bcard_price,
      'discounted_amount' : this.dataService.buildCardData.bcard_discounted_price
    };
    this.dataService.showHideLoader(true);
    this.apiRequests.creditCheck(payload).subscribe(resp => {
      this.commonService.twoPayCreditCheckData = resp;
      const address = resp['address'];
      if ((resp['credit_check']['decision'] === 'APPROVED') && address['city'] && address['postalCode']
        && address['streetAddress']) {
        this.commonService.isTwoPayRegCompany = true;
        this.fetchTwoPayInstallments();
        if (!this.checkIfTwoPayAccount()) {
          this.getTwoPayBillingDetailId();
        }
      } else {
        // Check capchase as fallback
        this.capchaseFlowChecking();
      }
    }, error => {
      // Check capchase as fallback
      this.capchaseFlowChecking();
    });
  }

  openMonthlyFlow() {
    this.dataService.showMonthly = true;
    this.dataService.showWeekly = false;
    this.dataService.showUpfront = false;
    this.commonService.startTwoPayFlow = false;
    this.commonService.isTwoPayRejected = false;
    this.commonService.isBNPLRejected = false;
    this.paymentService.startCapchaseFlow = false;
  }

  getWeeks() {
    const modifyData = this.dataService.modifiedBuildCardData;
    return ((modifyData && modifyData.weeks && modifyData.weeks.new) ? modifyData.weeks.new : this.dataService.buildCardData.weeks);
  }

  fetchTwoPayInstallments() {
    const buildCardData = this.dataService.buildCardData;
    const changeCost = (buildCardData.change_price_status ? buildCardData.change_amount : -buildCardData.change_amount);
    const payload = {
      'amount': (this.dataService.buildCardData.bcard_price + changeCost),
      'discounted_amount': (this.dataService.buildCardData.bcard_discounted_price + changeCost),
      'required_weeks': this.getWeeks(),
      'minimum_instalments': this.getCreditCheckData()['credit_check']['minimum_instalments']
    };
    this.dataService.showHideLoader(true);
    this.apiRequest.fetchTwoPayInstallments(payload).subscribe(resp => {
      this.commonService.startTwoPayFlow = true;
      this.commonService.twoPayInstallments = resp;
      this.dataService.showHideLoader(false);
    }, error => {
      this.dataService.showHideLoader(false);
    });
  }

  checkIfTwoPayAccount() {
    const billingAccounts = this.dataService.user['external_billing_accounts'],
      capchaseData = [];
    if (billingAccounts.length > 0) {
      for (const data of billingAccounts) {
        if (data['service_provider'] === 'two') {
          capchaseData.push(data);
          break;
        }
      }
    }
    return ((capchaseData.length > 0) ? true : false);
  }

  getTwoPayBillingDetailId() {
    const user = this.dataService.user,
      address = this.getCreditCheckData()['address'];
    const payload = {
      'buyer_company': {
        'trade_name': user.address.company_name,
        'official_address': {
          'address_line_1': address['streetAddress'],
          'city': address['city'],
          'postal_code': address['postalCode']
        },
        'national_identifier': user.company_id
      },
      'representative': {
        'first_name': user.first_name,
        'last_name': user.last_name,
        'phone_number': user.address.phone,
        'email': user.email
      },
      'user_id': this.dataService.user.id
    };
    this.apiRequest.getTwoPayBillingID(payload).subscribe(resp => {
      this.commonService.twoPayBillingIDDetails = resp;
    });
  }

  checkIfCapchaseAccount() {
    const billingAccounts = this.dataService.user['external_billing_accounts'],
      capchaseData = [];
    if (billingAccounts.length > 0) {
      for (const data of billingAccounts) {
        if (data['service_provider'] === 'capchase') {
          capchaseData.push(data);
          break;
        }
      }
    }
    return ((capchaseData.length > 0) ? true : false);
  }

  capchaseFlowChecking() {
    this.dataService.showHideLoader(true);
    this.apiRequests.capchaseFeatureCheck(this.dataService.user.id).subscribe(response => {
      if (response['enabled']) {
        if (this.checkIfCapchaseAccount()) {
          this.fetchCapchaseInstallments();
        } else {
          this.createCapchaseAccount();
        }
      } else {
        this.openMonthlyFlow();
        this.dataService.showHideLoader(false);
      }
    }, error => {
      this.openMonthlyFlow();
      this.dataService.showHideLoader(false);
    });
  }

  getBillingAccountID() {
    const billingData = this.dataService.user['external_billing_accounts'];
    if (billingData.length > 0) {
      const capchaseData = billingData.find(data => data['service_provider'] === 'capchase');
      if (capchaseData) {
        return capchaseData['reference_uuid'];
      }
    }
  }

  fetchCapchaseInstallments(buyerID?) {
    const buildCardData = this.dataService.buildCardData;
    const changeCost = (buildCardData.change_price_status ? buildCardData.change_amount : -buildCardData.change_amount);
    const payload = {
      build_card: {
        id: buildCardData.id,
        project_name: buildCardData.project_name,
        description: buildCardData.description,
        discounted_amount: (buildCardData.bcard_discounted_price  + changeCost),
        amount: (buildCardData.bcard_price  + changeCost),
        required_weeks: buildCardData.weeks
      },
      buyer_id: buyerID ? buyerID : this.getBillingAccountID()
    };
    this.apiRequest.fetchCapchaseInstallments(payload).subscribe(resp => {
      this.paymentService.startCapchaseFlow = true;
      this.paymentService.isCapchaseFlowApplicable = true;
      this.paymentService.capchaseInstallments = resp;
      this.dataService.showHideLoader(false);
    }, error => {
      this.dataService.showHideLoader(false);
    });
  }

  createCapchaseAccount() {
    const countryIdToDataMap = {
      2: ['USA', 'USD'],
      4: ['GBR', 'GBP'],
    };
    const user = this.dataService.user;
    const userAddress = user.address;
    const payload = {
      user_id: user.id,
      buyer: {
        address_line1: userAddress.address,
        address_line2: userAddress.address_line_2,
        city: userAddress.city,
        country_iso3: countryIdToDataMap[user.currency.id][0],
        currency: countryIdToDataMap[user.currency.id][1],
        legal_name: userAddress.company_name,
        name: (user.first_name + ' ' + user.last_name),
        postal_code: userAddress.pincode,
        state: userAddress.state
      }
    };
    this.apiRequest.createCapchaseAccount(payload).subscribe(resp => {
      this.fetchCapchaseInstallments(resp['id']);
    }, error => {
      this.dataService.showHideLoader(false);
    });
  }

  startForCapchaseFlow() {
    this.dataService.showMonthly = false;
    this.dataService.showWeekly = false;
    this.dataService.showUpfront = true;
    this.paymentService.startCapchaseFlow = false;
  }

  get2PayTotalPrice(installments) {
    const amountArray = [];
    installments.forEach((installment) => {
      amountArray.push(Number(installment.repayment_amount));
    });
    if (amountArray.length) {
      return amountArray.reduce((previousValue, currentValue) => previousValue + currentValue);
    }
  }

  getCreditCheckData() {
    return this.commonService.twoPayCreditCheckData;
  }

  getUpfrontDiscountPercentage() {
    return this.phaseService.isEditFeatureRequest() ? this.dataService.changeFeatureRequestModel.installments[0]['upfront_discount_percentage']
            : this.phaseService.upfrontInstallment?.upfront_discount_percentage
  }

  isStrikedOutUpfrontCost() {
    return (!this.paymentService.checkIfOnlyProfessionalPrototype() && this.getUpfrontDiscountPercentage()
      && (this.getUpfrontDiscountPercentage() > 0));
  }

}
