import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {PhaseService} from './phase.service';
import moment from 'moment';
import {PlatformModel} from '@models/platform-model/platform.model';
import {PhaseModel} from '@models/phase-model/phase.model';

@Injectable()
export class RecalculatePhaseDataService {
  moment: any;

  constructor(
    private dataService: DataService,
    private phaseService: PhaseService
  ) {
    this.moment = moment;
  }

  public propagateSpeedDataToPhases(speedId: number, phase?) {
    const {build_phases, speed} = this.dataService.homePageData;
    const speedData = speed.filter((speedItemData) => speedItemData.id === speedId)[0];

    if (phase) {
      phase.speed = speedData;
    } else {
      build_phases.forEach((phaseData) => {
        phaseData.speed = speedData;
      });
    }

    this.phaseService.updatePrice();
  }

  public calculateDeliveryDateOfPlatforms(platforms, phaseData) {
    let initialDate;
    let totalFeatureWeeks = 0;
    for (const feature of phaseData.features) {
      totalFeatureWeeks += feature.weeks;
    }
    totalFeatureWeeks *= phaseData.week_multiplier;

    if (phaseData.speed) {
      totalFeatureWeeks *= phaseData.speed.week_multiplier;
    }

    if (this.phaseService.selectedTeam) {
      totalFeatureWeeks *= this.phaseService.selectedTeam.week_multiplier;
    }
    let countSelectedPlatform =0;
    //let platformMultiplier;
    platforms.forEach((platform, index) => {
      if (platform.selected) {
        countSelectedPlatform++;
      }
    });
    const totalDays = phaseData.delivery_time;
    let counterVar = totalDays / (1 + ((countSelectedPlatform - 1)*0.1));
    let firstPlatform = true;
    let platformDate;
    platforms.forEach((platform, index) => {
      if(platform.selected){
        if (phaseData.initial_date) {
          initialDate = this.moment(phaseData.initial_date);
        } else {
          initialDate = this.moment();
        }
        if (firstPlatform) {
          firstPlatform = false;
          platformDate = initialDate.add(counterVar, 'days');
        }
        else{
          platformDate = platformDate.add(counterVar * 0.1, 'days');
        }
        platform['delivery_date'] = platformDate.format('LL');
      }
      else {
        delete platform['delivery_date'];
      }
    });


  /*  if (platforms) {
      let firstPlatform = true;
      let lastComputedDays = 0;
      let totalPhaseDays = 0;
      platforms.forEach((platform, index) => {
        if (platform.selected) {
          if (phaseData.initial_date) {
            initialDate = moment(phaseData.initial_date);
          } else {
            initialDate = moment();
          }
          if (firstPlatform) {
            lastComputedDays = totalDays;
            firstPlatform = false;
            totalPhaseDays += totalDays;
          } else {
            totalPhaseDays  += totalDays * (platform.week_multiplier - 1);
            lastComputedDays += totalDays * (platform.week_multiplier - 1);
          }
          platform['delivery_date'] = initialDate.add(lastComputedDays, 'days').format('LL');
        } else {
          delete platform['delivery_date'];
        }
      });
      console.log('total days', totalDays);
      console.log('initial date ', initialDate);
      console.log('totalphasedays', totalPhaseDays);
    }*/
  }

  public calculateDeliveryDateOfParallelPlatformsSimpleMode(platformsSelected: PlatformModel[]): any {
    let date = this.moment();
    let totalDays = 0;
    if (platformsSelected.length > 0) {
      this.phaseService.selectedPhases.forEach((phaseData) => {
        let phaseWeeks = 0;
        for (const feature of phaseData.features) {
          phaseWeeks += feature.weeks;
        }

        phaseWeeks *= phaseData.week_multiplier;

        if (phaseData.speed) {
          phaseWeeks *= phaseData.speed.week_multiplier;
        }

        if (this.phaseService.selectedTeam) {
          phaseWeeks *= this.phaseService.selectedTeam.week_multiplier;
        }
        totalDays += this.phaseService.getTotalDays(phaseWeeks);
      });
    }
    date.add(totalDays + this.phaseService.selectedPhases.length - 1, 'days');
    return date.format('LL');

  }

  public calculateDeliveryDateOfParallelPlatformsSinglePhase(platformsSelected: PlatformModel[], phaseData: PhaseModel): any {
    let date = this.moment();
    return this.moment(phaseData.final_date).format('LL');
  /*  if (phaseData.initial_date) {
      date = moment(phaseData.initial_date);
    }
    console.log(phaseData);
    console.log(moment(phaseData.final_date)+"-----"+moment(phaseData.initial_date));
    let totalDays = 0;
    if (moment(phaseData.final_date) == moment(phaseData.initial_date)) {
      console.log("coming");
      return this.phaseService.getFinalDeliveryDate();
    }
    else {
      if (platformsSelected.length > 0) {
        let totalWeeks = 0;
        let totalPhaseWeeks = 0;
        for (const feature of phaseData.features) {
          totalPhaseWeeks += feature.weeks;
        }

        totalPhaseWeeks *= phaseData.week_multiplier;

        if (phaseData.speed) {
          totalPhaseWeeks *= phaseData.speed.week_multiplier;
        }

        if (this.phaseService.selectedTeam) {
          totalPhaseWeeks *= this.phaseService.selectedTeam.week_multiplier;
        }

        totalWeeks += totalPhaseWeeks;
        totalDays = this.phaseService.getTotalDays(totalWeeks);
      }
      date.add(totalDays, 'days');
      return date.format('LL');
    }*/
  }
}
