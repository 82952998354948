/**
 * Created by nitin on 14/12/17.
 */
export class CurrencyModel {
  code: string;

  country_code: string;

  exchange_rate: number;

  icon_image_file_url: string;

  default_store_promo_code: string;

  id: number;

  multiplier: number;

  name: string;

  specing_price: number;

  symbol: string;

  tax : number;

  custom_prototype_price : number;

  post_upfront_price:number;

  tax_type: string;

}
