<div class="commonPopUp active" *ngIf="!this.phaseService.isPartnerDashboard()" (click)="closeCurrencySelection()">
  <div class="popOverlay"></div>
  <div class="popHolder loginScreen" [ngClass]="{'scrollFix': isSmallImage}">
    <!---- if height scrollable the then add class 'scrollFix'  -->
    <div class="new-CloseButton" [ngClass]='{"socialSignUpActive": isSocialSignUp}' *ngIf="!isMandatorySignUpflow" (click)="closeCommonAuthPopup()"><em class="icon-cancel"></em></div>
    <!--login left section start-->
    <div class="loginLeft">
      <div class="titletxt">
        <h3>
          <span *ngIf="phaseService.showRegister || isSocialSignUp">Sign up
          </span>
          <span *ngIf="!phaseService.showRegister && !isSocialSignUp && !isShowForgotPopup()">Sign in </span>
          <span *ngIf="isShowForgotPopup() && showForgotPassword">Forgot your password?</span>
          <span *ngIf="isShowForgotPopup() && !showForgotPassword">Password reset link sent </span>
        </h3>
      </div>

      <!--- web -->
      <!--<img class="onlyDesktop" src="../../../../assets/images/commonModal.png" alt="" />-->

      <!--- mobile -->
      <img class="onlyMobile" src="../../../../assets/images/newsignuppopup.png" alt="" />
    </div>
    <!--login left section close-->

    <!--login right section start-->
    <div class="loginRight" (scroll)="onScrollRightSection($event)">
      <!--- test for show only web -->
      <div class="titletxt">
        <h3>
          <span *ngIf="isShowSignupPopup() || isSocialSignUp">Sign up
            <span *ngIf='call_type'>
              to book your free {{call_type}}
            </span>
            </span>
          <span *ngIf="!phaseService.showRegister && !isSocialSignUp && !isShowForgotPopup()">Sign In </span>
          <span *ngIf="isShowForgotPopup() && showForgotPassword">Forgot your password?</span>
          <span *ngIf="isShowForgotPopup() && !showForgotPassword">Password reset link sent </span>
        </h3>
      </div>

      <!--- normal sign up -->
      <form class="formElements signUpBx" #registerForm="ngForm" *ngIf="phaseService.showRegister && !isSocialSignUp">
        <div class="sectionHeading"><span>Sign up using  </span></div>

        <div class="socialLogins">
          <button type="button" (mouseenter)="removeFocus()" (click)="social_sign('google')"><img src="../../../../assets/images/googlelogin.svg" alt="" /></button>
          <button type="button" (mouseenter)="removeFocus()" (click)="social_sign('linkedin')"><img src="../../../../assets/images/linkedinIcon.svg" alt="" /></button>
          <button type="button" (mouseenter)="removeFocus()" (click)="social_sign('facebook')"><img src="../../../../assets/images/facebook_logo_signupmodal.svg" alt="" /></button>
        </div>

        <div class="sectionHeading"><span>Sign up with email</span></div>
        <div class="errorMsgBox apiError">
          <span class="errorMsg show" *ngIf="ErrorMsg && !checkFirstNameValid() && !checkIfMobileValid() && !isPasswordError()">{{ErrorMsg}}
          </span>
        </div>
        <ul>
          <li>
            <label>Company email</label>
            <input type="email" [ngClass]="{'redBorder' : !isSocialSignClicked && (checkIfEmailContainsErrorAfterSignup() || (checkIfEmailValid(false) && checkPartnerEmail && signup_data.email) || (checkIfEmailValid(false) && checkPartnerEmail && !signup_data.email))}"
              placeholder="Enter your company email" #email="ngModel" name="signup_email" [(ngModel)]="signup_data.email" maxlength="100"
              id='loginEmailInput' pattern="^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*(?:\.\w{1,30})+$" (focusout)="validateEmailOnFocusLeave()"
              (click)="emailFieldClicked()" (keyup)="resetErrorMessage()" class="form-control" appAutofocus required />
            <div class="errorMsgBox">
              <div class="errorMsg show" *ngIf="checkIfEmailValid(false) && checkPartnerEmail && signup_data.email">Please enter valid email
              </div>
              <div class="errorMsg show" *ngIf="!isSocialSignClicked && checkIfEmailValid(false) && checkPartnerEmail && !signup_data.email">Email cannot be blank
              </div>
            </div>
          </li>

          <li>
            <label>Name</label>
            <input #regName type="text" name="firstlastname" #firstlastname="ngModel" [ngClass]="{'redBorder' : ((checkFirstNameValid() && checkNameValidOnClick && !firstlastname.value) || (checkFirstNameValid() && checkNameValidOnClick && firstlastname.value))}"
              pattern="[a-zA-Z ]*" (keydown)="registrationNameChanged($event)" (focusout)="validateNameOnFocusLeave()" class="form-control"
              [(ngModel)]="fullName" maxlength="100" placeholder="Enter your name" required/>
            <div class="errorMsgBox">
              <div class="errorMsg show" *ngIf="checkFirstNameValid() && checkNameValidOnClick && !firstlastname.value">Name cannot be blank
              </div>
              <div class="errorMsg show" *ngIf="checkFirstNameValid() && checkNameValidOnClick && firstlastname.value">Please enter valid Name
              </div>
            </div>
          </li>

          <li>
            <label>Phone Number</label>
            <app-phone-number [contactNumber]="signup_data.phone_number"
                              [isSaveButtonClicked]="signUpButtonClicked"
                              (setContactInUserInfo)="setUserContact($event)">
            </app-phone-number>
          </li>

          <li *ngIf="currencyByIp.length > 0">
            <label>Currency</label>
            <div class="currencySelection">
              <div class="valueHolder disabled" (click)="toggleCurrenctDropdown($event)" [ngClass]="{'redBorder' : (!selectedCurrency && isSubmitted)}"
                required>
                <div class="selectValue" *ngIf="selectedCurrency">{{ selectedCurrency.name }}</div>
                <div class="placeholderValue" *ngIf="!selectedCurrency"> Select Currency </div>
              </div>

              <div class="currencyDropdown" [ngClass]="isShowIpBasedCurrencyDropdown ? 'active' : ''">
                <ol>
                  <li (click)="selectCurrency(currency)" *ngFor="let currency of currencyByIp; let i = index" [ngClass]="isCurrentCurrency(currency) ? 'active' : ''">
                    <div class="currencyIcon"><img [src]="utilityService.addHttps(currency.icon_image_file_url)" alt="" /></div>
                    {{ currency.name }}
                  </li>
                </ol>
              </div>
            </div>

            <div class="errorMsgBox">
              <!-- <span class="errorMsg show"
                    *ngIf="ErrorMsg && !checkFirstNameValid() && !checkIfMobileValid() && !isPasswordError()">{{ErrorMsg}}
              </span> -->
              <span class="errorMsg show" *ngIf="!selectedCurrency && isSubmitted">
                Please select currency
              </span>
            </div>
          </li>

          <li>
            <label>Password </label>
            <div class="relativeRow">
              <input type="password" (keydown)="registrationPwdChanged($event)" [ngClass]="{'redBorder' : ((isPasswordError() && checkPwdValidOnClick && isPasswordLengthCorrect()) || (isPasswordError() && checkPwdValidOnClick && isPasswordRequiredError()))  }"
                [(ngModel)]="signup_data.password" name="signup_password" placeholder="Enter password" (focusout)="validatePasswordOnFocusLeave()"
                class="form-control" type="{{registerPass.show?'text':'password'}}" #signup_password="ngModel" maxlength="100"
                minlength="8" required />
              <div class="viewPassword" (click)="showPasswordRegister()" *ngIf="signup_data.password"><em class="icon-eye"></em></div>
            </div>
            <div class="errorMsgBox">
              <div *ngIf="isPasswordError() && checkPwdValidOnClick" class="errorMsg show">
                <span *ngIf="isPasswordLengthCorrect()">Password should contain 8 characters</span>
                <span *ngIf="isPasswordRequiredError()">Password cannot be blank</span>
              </div>
            </div>
          </li>

          <li class="termsCondition">
            <input type="checkbox" id="termsandcondition" name="termsandcondition" (click)="$event.stopPropagation()" (change)="validateTandCOnCheckboxChange()"
              class="form-control" [(ngModel)]="signupTerm2" required/>
            <label for="termsandcondition" (click)="$event.stopPropagation()">
              I agree to the <a href="https://www.builder.ai/privacy" target="blank" class="linkcolor" rel="noopener">Privacy Policy</a> and <a (click)="commonService.navigateToTnC(true)" target="blank" class="linkcolor">Terms and Conditions</a>
            </label>
            <div class="errorMsgBox">
              <span class="errorMsg show" *ngIf="!signupTerm2 && checkTandCValidOnClick">
                Please check terms & conditions
              </span>
            </div>
          </li>
        </ul>
        <div class="footersection">
          <div class="actionbtn">
            <button type="submit" class="doneButton" (click)="triggerSignup()"
                    [disabled]="!registerForm.form.valid || checkIfPhoneInValid() || signUpButtonClicked">
              Create Account
            </button>
          </div>
          <div class="alreadyHave">Already have an account? <span class="linkcolor" (click)="showLoginIn(registerForm, 'default_sign_up')">Sign in</span></div>
          <div class="sublinks">
            <!-- <span (click)="commonService.navigateToTnC()">Terms and Conditions</span> -->
            <span (click)="openUrls('https://www.builder.ai/terms/privacy')">Privacy</span>
            <span (click)="openUrls('https://www.builder.ai/terms/ccpa')">CCPA</span>
          </div>
        </div>
      </form>

      <!--sign up with social media-->
      <div class="formElements signUpSocialMedia"  *ngIf="isSocialSignUp">
        <button type="button" class="btn" (click)="showSignUp()"> Use another account </button>
        <div class="userBx">
          <div class="userProfilePic"> <img src="../../../../assets/images/user.png" alt="" /></div>
          <div class="userDetail">
            <h3 class="userName">{{dataService.user.first_name + ' ' + dataService.user.last_name}}</h3>
            <p class="useremail">{{dataService.user.email}}</p>
          </div>
        </div>
        <ul>
          <li *ngIf='!this.dataService.user.email'>
            <label>Company email</label>
            <input type="email" placeholder="Enter your company email" [ngClass]="showEmailError && checkIfValidSocialemail() ? 'redBorder' :''"
              placeholder="Company email" #email="ngModel" name="email" id='loginEmailInput' [(ngModel)]="socialSignup.email"
              maxlength="100" (focusout)="validateEmailOnLoginOnFocusLeave()" (click)="emailFieldClicked()" pattern="^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*(?:\.\w{1,30})+$"
              (keydown)="loginEmailChanged($event)" appAutofocus required/>
            <div class="errorMsgBox">
              <div class="errorMsg show" *ngIf="checkIfValidSocialemail() && checkValidEmailOnLogin && !email.pristine && email.value && showEmailError">
                Please enter valid email
              </div>
              <div class="errorMsg show" *ngIf="checkIfValidSocialemail() && checkValidEmailOnLogin && (email.pristine || !email.value) && showEmailError">
                Email cannot be blank
              </div>
            </div>
          </li>

          <li *ngIf='!this.dataService.user.phone_number'>
            <label>Phone Number</label>
            <app-phone-number [contactNumber]="socialSignup.phone_number"
                              [isSaveButtonClicked]="isSubmitted"
                              (setContactInUserInfo)="setUserContact($event)">
            </app-phone-number>
            <!--<div [ngClass]="{'redBorder': ((checkIfMobileValid() && checkNumberValidOnClick && mobileNumber.pristine) || (checkIfMobileValid() && checkNumberValidOnClick && !mobileNumber.pristine))}">
              <international-phone-number (keyup)="onFlagChange()" (keydown)="registrationMobileChanged($event)" (focusin)="focusInNumber($event)"
                [(ngModel)]="socialSignup.phone_number" placeholder="Mobile Number" (focusout)="validatePhoneOnFocusLeave()"
                class="form-control phoneNumber" [maxlength]="20" [defaultCountry]="phaseService.countryNameCode" [required]="true"
                #mobileNumber="ngModel" name="mobileNumber" required>
              </international-phone-number>
            </div>
            <div class="errorMsgBox">
              <div class="errorMsg show" *ngIf="checkIfMobileValid()  && mobileNumber.pristine">Mobile Number cannot be blank
              </div>
              <div class="errorMsg show" *ngIf="checkIfMobileValid()  && !mobileNumber.pristine">Please enter valid Mobile Number
              </div>
            </div>-->
          </li>

          <li>
            <label>Currency</label>

            <div class="currencySelection">
              <div class="valueHolder" (click)="toggleCurrenctDropdown($event)" [ngClass]="isShowIpBasedCurrencyDropdown ? 'active' : ''"
                required>
                <div class="selectValue" *ngIf="selectedCurrency">{{ selectedCurrency.name }}</div>
                <div class="placeholderValue" *ngIf="!selectedCurrency"> Select Currency </div>
              </div>

              <div class="currencyDropdown" [ngClass]="isShowIpBasedCurrencyDropdown ? 'active' : ''">
                <ol>
                  <li (click)="selectCurrency(currency)" *ngFor="let currency of currencyByIp; let i = index" [ngClass]="isCurrentCurrency(currency) ? 'active' : ''">
                    <div class="currencyIcon"><img [src]="utilityService.addHttps(currency.icon_image_file_url)" alt="" /></div>
                    {{ currency.name }}
                  </li>
                </ol>
              </div>
            </div>
            <div class="errorMsgBox">
              <span class="errorMsg show" *ngIf="!selectedCurrency && isCreateAccountWithAuth">
                  Please select currency
              </span>

              <span class="errorMsg show" *ngIf="!signupTerm2 && isCreateAccountWithAuth">
              Please check terms & conditions
            </span>
            </div>
          </li>
          <li class="termsCondition">
            <input type="checkbox" id="signuptermscondition" (click)="$event.stopPropagation()" [(ngModel)]="signupTerm2" [ngModelOptions]="{standalone: true}">
            <label for="signuptermscondition" (click)="$event.stopPropagation()">I agree to the <a href="https://www.builder.ai/privacy" target="blank" rel="noopener" class="linkcolor">Privacy Policy</a> and <a (click)="commonService.navigateToTnC(true)" target="blank" class="linkcolor">Terms and Conditions</a></label>
          </li>
        </ul>
        <div class="footersection">
          <div class="actionbtn">
            <button type="button" class="doneButton" (click)="createAccountThroughSocialAuth()">Create Account</button>
          </div>
          <!-- <div class="alreadyHave" (click)="showLoginIn(registerForm, 'default_sign_up')"> Already have an account? <span class="linkcolor">Sign in</span></div> -->
        </div>
      </div>

      <!---- sign in ---->
      <form class="formElements loginBlock" #loginForm="ngForm" *ngIf="!phaseService.showRegister && !isSocialSignUp && !isShowForgotPopup()">
        <div class="sectionHeading"><span>Sign in using</span></div>

        <div class="socialLogins">
          <button type="button" (mouseenter)="removeFocus()" (click)="social_sign('google')"><img src="../../../../assets/images/googlelogin.svg" alt="" /></button>
          <button type="button" (mouseenter)="removeFocus()" (click)="social_sign('linkedin')"><img src="../../../../assets/images/linkedinIcon.svg" alt="" /></button>
          <button type="button" (mouseenter)="removeFocus()" (click)="social_sign('facebook')"><img src="../../../../assets/images/facebook_logo_signupmodal.svg" alt="" /></button>
        </div>

        <div class="sectionHeading"><span>Sign in with email</span></div>

        <ul>
          <li>
            <label>Company email</label>
            <input type="email" placeholder="Enter your company email" [ngClass]="!isSocialSignClicked && showEmailError && checkIfEmailValid(true) ? 'redBorder' :''"
              placeholder="Company email" #email="ngModel" name="email" id='loginEmailInput' [(ngModel)]="login_data.email"
              maxlength="100" (focusout)="validateEmailOnLoginOnFocusLeave()" (click)="emailFieldClicked()" pattern="^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*(?:\.\w{1,30})+$"
              (keydown)="loginEmailChanged($event)" appAutofocus required/>
            <div class="errorMsgBox">
              <div class="errorMsg show" *ngIf="!isSocialSignClicked && checkIfEmailValid(true) && checkValidEmailOnLogin && !email.pristine && email.value && showEmailError">
                Please enter valid email
              </div>
              <div class="errorMsg show" *ngIf="!isSocialSignClicked && checkIfEmailValid(true) && checkValidEmailOnLogin && (email.pristine || !email.value) && showEmailError">
                Email cannot be blank
              </div>
            </div>

          </li>
          <li>
            <label>Password </label>
            <div class="forgot" (click)="forgot_password(loginForm)"> Forgot your password? </div>
            <div class="relativeRow">
              <input type="password" [ngClass]="showPwdError && isPasswordError(true) ? 'redBorder' :''" [(ngModel)]="login_data.password"
                name="password" type="{{loginPass.show?'text':'password'}}" #password="ngModel" maxlength="100" minlength="8"
                (focusout)="validatePasswordOnLoginOnFocusLeave()" (keydown)="loginPwdChanged($event)" required placeholder="Enter password"
              />
              <div class="errorMsgBox">
                <div *ngIf="isPasswordError(true) && checkValidPwdOnLogin && showPwdError" class="errorMsg show">
                  <span *ngIf="isPasswordLengthCorrect(true)">Password should contain 8 characters</span>
                  <span *ngIf="isPasswordRequiredError(true)">Password cannot be blank</span>
                </div>
                <span class="errorMsg show" *ngIf="ErrorMsg && !checkIfEmailValid(true) && !isPasswordError(true) && showServerError">Oops! This combination is not valid.</span>
              </div>

              <div class="viewPassword" (click)="showPasswordlogin()" *ngIf="login_data.password"><em class="icon-eye"></em></div>
            </div>
          </li>
        </ul>
        <div class="footersection">
          <div class="actionbtn">
            <button type="submit" class="doneButton" (click)="triggerLogin()" [disabled]="!loginForm.form.valid">Sign in</button>
          </div>
          <div class="alreadyHave" *ngIf="!dataService.homePageData?.block_sign_up">Don't have an account? <span class="linkcolor" (click)="toggleSignInAndSignUp()">Sign up</span></div>
          <div class="sublinks">
            <!-- <span (click)="commonService.navigateToTnC()">Terms and Conditions</span> -->
            <span (click)="openUrls('https://www.builder.ai/terms/privacy')">Privacy</span>
            <span (click)="openUrls('https://www.builder.ai/terms/ccpa')">CCPA</span>
          </div>
        </div>
      </form>
      <!----FORGOT PASSWORD ---->
      <!--- screen 1 -->
      <div *ngIf="isShowForgotPopup()">
        <form name="forgotForm" #forgotForm class="formElements forgotpassBlock" *ngIf="showForgotPassword">
          <p class="forgotTitle">Enter your email address below and we’ll get you back on track.</p>
          <ul>
            <li>
              <label>E-mail address</label>
              <input #forgetemail [ngClass]="isSendClicked && checkIfForgetEmailValid() ? 'redBorder' : ''" matInput type="email" (keydown)="forgotEmailSelection($event)"
                placeholder="Enter your e-mail address" #forgot_email="ngModel" name="forgot_email" [(ngModel)]="resetData.email"
                maxlength="100" required/>
              <div class="errorMsgBox">
                <div class="errorMsg show" *ngIf="checkIfForgetEmailValid() && checkOnForgotEmail && forgot_email.value && showForgotEmailError">
                  Please enter valid email
                </div>
                <div class="errorMsg show" *ngIf="checkIfForgetEmailValid() && checkOnForgotEmail && !forgot_email.value && showForgotEmailError">
                  Email cannot be blank
                </div>
                <span class="errorMsg show" *ngIf="!checkIfForgetEmailValid() && ErrorMsg && showForgotEmailError">User not found!</span>
              </div>
            </li>
          </ul>
          <div class="footersection">
            <div class="actionbtn">
              <button type="button" class="doneButton" [ngClass]="sendButtonClass" type="submit" (click)="send_email(forgotForm)" [disabled]="(resetData.email == '') || (!checkIfForgetEmailValid() && ErrorMsg && showForgotEmailError)"> Send Reset Password Link</button>
            </div>
            <div class="alreadyHave login"> <span class="linkcolor" (click)="goToLogin(forgotForm)">Back to sign in</span></div>
          </div>
          <div class="footersection signUpInstead">
            <div class="sectionHeading">
              <span>Sign up instead</span>
            </div>
            <div class="actionbtn">
              <button type="button" class="doneButton" type="submit" (click)="toggleSignInAndSignUp(true)" [disabled]="!(!checkIfForgetEmailValid() && ErrorMsg && showForgotEmailError)">Create my Studio account</button>
            </div>
          </div>
        </form>
      </div>
      <!---- Password reset link sent sreen --->
      <div class="formElements forgotpassBlock" *ngIf="isShowForgotPopup() && !showForgotPassword">
        <p class="forgotTitle">We’ve sent a password reset link to your email, click on the link to confirm.</p>
        <div class="footersection">
          <div class="actionbtn">
            <button type="button" class="doneButton" (click)="goToLogin(forgotForm)">Sign in again</button>
          </div>
        </div>
      </div>

    </div>
    <!--login right section close-->
  </div>
</div>

<!-----NDA -->
<div class="commonPopUp active" *ngIf="phaseService.showSignNDAPopup">
  <div class="popOverlay"></div>
  <div class="popHolder ndaBlock" [ngClass]="{'scrollFix': isSmallImage}">
    <!---- if height scrollable the then add class 'scrollFix'  -->
    <div class="new-CloseButton" *ngIf="!isMandatorySignUpflow" (click)="closeCommonAuthPopup()"><em class="icon-cancel"></em></div>
    <!--login left section start-->
    <div class="loginLeft">
      <div class="titletxt">
        <h3>
          <span>Sign NDA</span>
        </h3>
      </div>

      <!--- web -->
      <img class="onlyDesktop" src="../../../../assets/images/signin_up_desktop.png" alt="" />

      <!--- mobile -->
      <img class="onlyMobile" src="../../../../assets/images/newsignuppopup.png" alt="" />
    </div>
    <!--login left section close-->

    <!--login right section start-->
    <div class="loginRight" (scroll)="onScrollRightSection($event)">
      <!--- test for shoe only web -->
      <div class="titletxt">
        <h3>
          <span>Sign NDA</span>
        </h3>
      </div>

      <!--- normal sign up -->
      <form class="formElements signUpBx" #registerForm="ngForm">
        <!--- input field ---->
        <ul>
          <li>
            <label>Company name</label>
            <input type="text" />
          </li>
          <li>
            <label class="lftrightTxt">
                <span>Company address</span>
                <span class="linkcolor">Enter address manually</span>
              </label>
            <input type="text" />
          </li>
          <li>
            <p>The Customer and Builder.ai are considering entering into a contract for the provision of sofware building services.
              In advance of doing so the parties intend to exchange information that may be sensitive to their business.
              The non-disclosure agreement is entered into in contemplation of that exchange of information.</p>
            <p>By clicking “I agree”, you warrant that you are duly authorised, and understand and agree to all of the above
              terms without exception. </p>
          </li>
        </ul>
        <div class="footersection">
          <div class="actionbtn">
            <button type="submit" class="doneButton">I agree</button>
          </div>
        </div>

      </form>

    </div>
    <!--login right section close-->
  </div>
</div>

<div #loginScreen id="loginScreen" *ngIf="((phaseService.openCommonAuth || partnerDataService.isPartnerVerified)) && this.phaseService.isPartnerDashboard()"
  [ngClass]="{'loginScreenAppDetail': dataService.currentPage === 'templateDetail' || dataService.loginNewModule}">
  <div class="commonPopUp higher-zindex" [ngClass]="{active: isCommonAuthPopup()}">
    <div class="popOverlay"></div>
    <div class="popHolder loginRegister">
      <span class="mobileSmall-logo" [ngClass]="{'hide' : phaseService.signupNextStep}">
          <img src="../assets/images/logoSmall.svg" width="21" height="28" alt="logo"/>
        </span>
      <div class="new-CloseButton" (click)="closeSignIn(loginForm, true); closeCommonAuthPopup('navigateToHome')" *ngIf="((isNotForceLogin() && (!phaseService.signupNextStep || phaseService.isPartnerDashboard())) || phaseService.showclose
           || phaseService.showMobileClose) && !appDataService?.urlParameters.cpe_user" [ngClass]="{'hide' : phaseService.signUpCurrencyForBNow}">
        <em class="icon-cancel"></em>
      </div>
      <div class="new-CloseButton" (click)="closeSignIn(loginForm, true); closeCommonAuthPopup('navigateToHome')" *ngIf="((dataService.currentPage === 'templateDetail') || dataService.loginNewModule) && !appDataService?.urlParameters.cpe_user">
        <em class="icon-cancel"></em>
      </div>

      <div class="new-goBack signup" (click)="goBackToInitialSignupStep()" *ngIf="!phaseService.isPartnerDashboard() && phaseService.showRegister && phaseService.signupNextStep  && !phaseService.showclose && !phaseService.showMobileClose">
        <em class="icon-left-arrow"></em>
      </div>

      <div class="new-goBack hidden-xs forgot" (click)="goToLogin(forgotForm)" *ngIf="showForgotPassword">
        <em class="icon-left-arrow"></em>
      </div>

      <div class="backtoSignin" (click)="showLoginIn(registerForm, 'default_sign_up')" *ngIf="phaseService.signupNextStep && !phaseService.showclose">
        Already have an account? <span>Sign in</span>
      </div>


      <div class="loginRegisterLeft">
        <div class="loginRegisterLeft-bg1" [ngClass]="{'active' : isShowLoginPopup() && !isShowV2LoginPopup()}"></div>
        <div class="loginRegisterLeft-bg2" [ngClass]="{'active' : isShowSignupPopup() && !(isShowV2SignUpPopup() || isShowV2RegisterPopup())}"></div>
        <div class="loginRegisterLeft-bg2" [ngClass]="{'active' : isShowForgotPopup()}"></div>
        <div class="loginRegisterLeft-v2" *ngIf="isShowV2SignUpPopup() && !(isShowV2LoginPopup() || isShowV2RegisterPopup()) ">
          <div class="v2-left-screen">
            <h2> Software building so easy anyone can do it. </h2>
            <p> Human-assisted AI can help you turn any idea into tailor-made software. </p>
          </div>
        </div>
        <div class="loginRegisterLeft-v2" *ngIf="isShowV2LoginPopup()">
          <div class="v2-left-screen">
            <h2> Software building so easy anyone can do it. </h2>
            <p> Human-assisted AI can help you turn any idea into tailor-made software. </p>
          </div>
        </div>
        <div class="loginRegisterLeft-v2" *ngIf="isShowV2RegisterPopup()">
          <div class="v2-left-screen">
            <h2> Software building so easy anyone can do it. </h2>
            <p> Human-assisted AI can help you turn any idea into tailor-made software. </p>
          </div>

        </div>
        <span class="topSmall-logo" *ngIf="!isFromV2()">
          <img src="../assets/images/logoSmall.svg" width="21" height="28" alt="logo"/>
        </span>
        <span class="v2-logo" *ngIf="isFromV2()">
          <img src="../assets/images/fullLogoWhite.png" alt="logo"/>
        </span>
      </div>
      <div class="loginRegisterRight">
        <div class="heading-appdetail">
          <p> one last step. </p>
          <h2> Build your own version of <span>{{ dataService.appDetails?.title }}</span> </h2>
        </div>

        <div class="loginRegisterForm" *ngIf="isShowLoginPopup()">
          <div class="authHeading">
            <span class="authHeading-main">{{ getMainHeading('login') }}</span>
            <span class="authHeading-sub">{{ getSubHeading('login') }}</span>
          </div>
          <div class="loginTabs" *ngIf="!dataService.isEngineerAIBuilder() && dataService.homePageData?.builder_partner?.is_customer_allowed">
            <span [ngClass]="{active: currentActiveTab ==='Partner'}" (click)="changeSelectedTab('Partner')">Sign in as Partner</span>
            <span [ngClass]="{active: currentActiveTab ==='User'}" (click)="changeSelectedTab('User')">Sign in as Customer</span>
          </div>
          <form name="loginForm" #loginForm="ngForm">
            <ul>
              <li>
                <span class="field-labels">Enter Details</span>

                <input type="email" [ngClass]="checkIfEmailValid(true) ? 'redBorder' :''" placeholder="Company email" #email="ngModel" name="email"
                  [(ngModel)]="login_data.email" maxlength="100" pattern="^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*(?:\.\w{1,30})+$"
                  (keydown)="loginEmailChanged($event)" appAutofocus required/>
                <div class="errorMsgBox">
                  <div class="errorMsg show" *ngIf="checkIfEmailValid(true) && checkValidEmailOnLogin && !email.pristine && email.value && showEmailError">
                    Please enter valid email
                  </div>
                  <div class="errorMsg show" *ngIf="checkIfEmailValid(true) && checkValidEmailOnLogin && (email.pristine || !email.value) && showEmailError">
                    Email cannot be blank
                  </div>
                </div>
              </li>

              <li class="clearfix">
                <div class="relativeRow">
                  <input placeholder="Password" [ngClass]="isPasswordError(true) ? 'redBorder' :''" [(ngModel)]="login_data.password" name="password"
                    type="{{loginPass.show?'text':'password'}}" #password="ngModel" maxlength="100" minlength="8" (keydown)="loginPwdChanged($event)"
                    required>

                  <div class="errorMsgBox">
                    <div *ngIf="isPasswordError(true) && checkValidPwdOnLogin && showPwdError" class="errorMsg show">
                      <span *ngIf="isPasswordLengthCorrect(true)">Password should contain 8 characters</span>
                      <span *ngIf="isPasswordRequiredError(true)">Password cannot be blank</span>
                    </div>
                    <span class="errorMsg show" *ngIf="ErrorMsg && !checkIfEmailValid(true) && !isPasswordError(true) && showServerError">Oops! This combination is not valid.</span>
                  </div>

                  <!--<span class="showPass" (click)="showPasswordlogin()" *ngIf="login_data.password">{{loginPass.text}}</span>-->
                  <span class="showPass" (click)="showPasswordlogin()" *ngIf="login_data.password"><em
                    class="icon-view"></em></span>
                </div>
                <span *ngIf="!phaseService.isPartnerDashboard()" class="forgotPass" (click)="forgot_password(loginForm)">Forgot password?</span>
              </li>

              <li *ngIf="phaseService.popupCoupon && dataService.couponName" class="clearfix">
                <div class="couponBox"><strong>{{dataService.couponName}}</strong> applied <em class="icon-right"></em></div>
              </li>

              <li>

                <button class="submitButton" [ngClass]='loginButtonClass' type="submit" (click)="triggerLogin()"><span class="icon-right"></span> Login
                  <!--<img-->
                  <!--*ngIf="preventClick && !socialLoginOn" src="./../../../../assets/images/buttonLoader.gif" alt=""/>-->
                </button>
                <p class="orAction">Don't have an account?
                  <button (click)="showRegisterMethod(loginForm)" type="button">Sign Up</button>
                </p>
              </li>
            </ul>

          </form>

          <div class="socialLogin" *ngIf="!phaseService.isPartnerDashboard() && dataService.isEngineerAIBuilder()">
            <h4><span>or connect using</span></h4>
            <span class="socialIcon fbIcon" (click)="social_sign('facebook')"><em class="icon-facebook"></em></span>
            <span class="socialIcon googleIcon" (click)="social_sign('google')"><em
              class="icon-google-plus"></em></span>
          </div>
        </div>

        <div *ngIf="isShowSignupPopup()" [ngClass]="{active: isShowSignupPopup()}" class="loginBox">
          <div class="loginRegisterForm">
            <div class="authHeading" *ngIf="!phaseService.signupNextStep && !phaseService.signUpCurrencyForBNow">
              <span class="authHeading-main">{{ getMainHeading('sign_up_1') }}</span>
              <span class="authHeading-sub">{{ getSubHeading('sign_up_1') }}</span>
            </div>
            <div class="authHeading" *ngIf="phaseService.signupNextStep && !phaseService.signUpCurrencyForBNow">
              <span class="authHeading-main">{{ getMainHeading('sign_up_2') }}</span>
              <span class="authHeading-sub">{{ getSubHeading('sign_up_2') }}</span>
            </div>
            <div class="loginTabs" *ngIf="!dataService.isEngineerAIBuilder() && dataService.homePageData?.builder_partner?.is_customer_allowed && !phaseService.signupNextStep">
              <span [ngClass]="{active: currentActiveTab ==='Partner'}" (click)="changeSelectedTab('Partner')">Sign up as Partner</span>
              <span [ngClass]="{active: currentActiveTab ==='User'}" (click)="changeSelectedTab('User')">Sign up as Customer</span>
            </div>

            <form name="registerForm" #registerForm="ngForm" [hidden]="isOtpFormVisible">
              <ul class="singupSteps" [ngClass]="{'activeStep' : !phaseService.signupNextStep && !phaseService.signUpCurrencyForBNow}">
                <li>
                  <span class="field-labels">Sign up to builder</span>
                  <div class="emailloader" *ngIf="checkingEmailValid"><img src="./../../../../assets/images/rolling.gif" alt="" /></div>


                  <div class="input-container" #divClicked>
                    <input type="text" placeholder="Company email" [ngClass]="(toCheck && checkIfEmailValid() ) ? 'redBorder' : ''" #signup_email="ngModel"
                      [(ngModel)]="signup_data.email" maxlength="100" email (keydown)="emailHostSelection($event)" (keyup)="changeValidationStatus($event)"
                      autoComplete="off" name="signup_email" appAutofocus required/>
                  </div>
                  <div class="errorMsgBox">
                    <div class="errorMsg show" *ngIf="toCheck && checkIfEmailValid() && signup_email.value && checkEmailError">Please enter valid email
                    </div>
                    <div class="errorMsg show" *ngIf="toCheck && checkIfEmailValid() && !signup_email.value && checkEmailError">Email cannot be blank
                    </div>
                  </div>
                </li>
                <li>

                  <button class="submitButton extraSpace signUpNextButtonClass" [ngClass]="signUpNextButtonClass" type="button" (click)="signupStepNext()">Next
                  </button>
                  <p class="extraText">No credit card needed</p>
                  <p class="orAction noMargin">Already have an account?
                    <button (click)="showLoginIn(registerForm, 'default_sign_up')" type="button">Sign in</button>
                  </p>
                </li>
              </ul>

              <ul class="singupSteps" [ngClass]="{'activeStep' : phaseService.signupNextStep && !phaseService.signUpCurrencyForBNow}">
                <li>
                  <div class="onlyAppdetailPopup" (click)="goBackToInitialSignupStep()">
                    Edit
                  </div>

                  <div *ngIf="!phaseService.isPartnerDashboard(); else partnerBlock">
                    <legend class="ex-label">Email Address</legend>
                    <span class="enteredEmail">{{signup_data.email}}</span>
                  </div>
                  <ng-template #partnerBlock>
                    <input type="email" [ngClass]="checkIfEmailValid(true) ? 'redBorder' :''" placeholder="Email address" #email="ngModel" name="signup_email"
                      [(ngModel)]="signup_data.email" maxlength="100" pattern="^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*(?:\.\w{1,30})+$"
                      appAutofocus required/>
                  </ng-template>
                  <div class="errorMsgBox">
                    <div class="errorMsg show" *ngIf="phaseService.isPartnerDashboard() && checkPartnerEmail && checkIfEmailValid(false,true) && signup_email.value">Please enter valid email
                    </div>
                  </div>
                </li>

                <li>
                  <input #regName name="firstlastname" #firstlastname="ngModel" type="text" [ngClass]="checkFirstNameValid() ? 'redBorder' : ''"
                    pattern="[a-zA-Z ]*" (keydown)="registrationNameChanged($event)" [(ngModel)]="fullName" maxlength="100"
                    placeholder="Enter Name (ex. John Smith)" required/>
                  <div class="errorMsgBox">
                    <div class="errorMsg show" *ngIf="checkFirstNameValid() && checkNameValidOnClick && !firstlastname.value">Name cannot be blank
                    </div>
                    <div class="errorMsg show" *ngIf="checkFirstNameValid() && checkNameValidOnClick && firstlastname.value">Please enter valid Name
                    </div>
                  </div>
                </li>

                <li>
                  <international-phone-number (keyup)="onFlagChange()" (keydown)="registrationMobileChanged($event)" (focusin)="focusInNumber($event)"
                    [ngClass]="checkIfMobileValid() ? 'redBorder' : ''" [(ngModel)]="signup_data.phone_number" placeholder="Mobile Number"
                    [maxlength]="20" [defaultCountry]="phaseService.countryNameCode" [required]="true" #mobileNumber="ngModel"
                    name="mobileNumber" required></international-phone-number>
                  <div class="errorMsgBox">
                    <div class="errorMsg show" *ngIf="checkIfMobileValid() && checkNumberValidOnClick && mobileNumber.pristine">Mobile Number cannot be blank
                    </div>
                    <div class="errorMsg show" *ngIf="checkIfMobileValid() && checkNumberValidOnClick && !mobileNumber.pristine">Please enter valid Mobile Number
                    </div>
                  </div>
                </li>

                <li>
                  <input #organisation name="organisationName" #organisationName="ngModel" type="text" [(ngModel)]="signup_data.organisation"
                    maxlength="100" placeholder="Organisation (Optional)" required/>

                </li>
                <li>
                  <div class="relativeRow">
                    <input (keydown)="registrationPwdChanged($event)" [ngClass]="isPasswordError() ? 'redBorder' : ''" placeholder="Password"
                      [(ngModel)]="signup_data.password" name="signup_password" type="{{registerPass.show?'text':'password'}}"
                      #signup_password="ngModel" maxlength="100" minlength="8" required>
                  </div>
                  <!-- == -->
                  <div class="errorMsgBox">
                    <div *ngIf="isPasswordError() && checkPwdValidOnClick" class="errorMsg show">
                      <span *ngIf="isPasswordLengthCorrect()">Password should contain 8 characters</span>
                      <span *ngIf="isPasswordRequiredError()">Password cannot be blank</span>
                    </div>

                    <span class="errorMsg show" *ngIf="ErrorMsg && !checkFirstNameValid() && !checkIfMobileValid() && !isPasswordError()">{{ErrorMsg}}</span>
                  </div>
                  <!-- == -->
                </li>

                <li *ngIf="phaseService.popupCoupon && dataService.couponName" class="clearfix">
                  <div class="couponBox"><strong>{{dataService.couponName}}</strong> applied <em class="icon-right"></em></div>
                </li>

                <li>
                  <div class="registerCheck">
                    <p><input type="checkbox" id="businesspurposes" [(ngModel)]="signupTerm2"
                        [ngModelOptions]="{standalone: true}"> <label for="businesspurposes"></label> Email me exclusive Builder
                      promotions and updates. I can unsubscribe at any time as stated in the <a href="https://www.builder.ai/privacy"
                        target="_blank" rel="noopener">Privacy Policy.</a>
                    </p>
                  </div>
                </li>
                <li>
                  <button class="submitButton" [ngClass]="registerButtonClass" type="submit" (click)="triggerSignup()">
                    <span class="icon-right"></span> Register
                  </button>
                  <p class="orAction">By clicking on Register, you are agree with our
                    <a href="https://www.builder.ai/terms" target="_blank" rel="noopener">Terms and Conditions.</a></p>
                </li>
              </ul>
              <div class="singupSteps" [ngClass]="{'activeStep' : phaseService.signUpCurrencyForBNow}">
                <div class="currentblock">
                  <h3 class="currency-head">To continue with your prototype you have to select your currency</h3>
                  <div class="currencySelBox">
                    <span class="sel_state" (click)="showPopUp(); hideOptionList()" [ngClass]="isShowCurrencyDropdown() ? 'active' : ''">
                          <span class="selectCurrencyValue" *ngIf="phaseService.showCurrencyOptionsForBnow">{{ getCurrency() ? getCurrencyCode() : "" }}</span>
                    <span class="defaultCurrencyValue" *ngIf="!phaseService.showCurrencyOptionsForBnow"> Select Currency </span>
                    </span>
                    <div class="userDropdown" [ngClass]="isShowCurrencyDropdown() ? 'active' : ''">
                      <div class="userDropdownBox">
                        <div class="currencychoose" (click)="selectCurrency(i)" *ngFor="let currency of getCurrencies(); let i = index" [ngClass]="isCurrentCurrency(currency) ? 'active' : ''">
                          <div class="icons">
                            <img [src]="utilityService.addHttps(currency.icon_image_file_url)" alt="" />
                          </div>
                          <span class="currencyName">{{ currency.name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="actionBx">
                    <button class="doneButton" (click)="closeCurrencyChooserForUser()" [disabled]="!phaseService.showCurrencyOptionsForBnow"> Done </button>
                  </div>
                </div>
              </div>

              <div class="backtoSigninMob" (click)="showLoginIn(registerForm, 'default_sign_up')" *ngIf="phaseService.signupNextStep">
                Already have an account? <span class="">Sign in</span>
              </div>
            </form>


            <div class="otpScreen" *ngIf="isOtpFormVisible">
              <h2 *ngIf="!dataService.loginData">Hello {{ signup_data?.first_name }}!!</h2>
              <h2 *ngIf="dataService.loginData">Hello {{ dataService.loginData?.first_name }}!!</h2>
              <p>We've sent an 4 digit OTP to your email
                <strong *ngIf="!dataService.loginData">{{ signup_data?.email }}</strong>
                <strong *ngIf="dataService.loginData">{{ dataService.loginData?.email }}</strong> . Open it and Enter the
                code to proceed.</p>
              <div class="otpForm">
                <form (submit)="onSubmitOtp()" #otpForm>
                  <input type="text" autofocus #otp1 pattern="[0-9]{6}" (keypress)="phaseService.isNumberKey($event);enterOtpDigit(otp2)" (keydown)="clearError();changeFocus($event, null);handleKeyLeft($event, null);handleKeyRight($event, otp2)"
                    maxlength="1" size="1">
                  <input type="text" #otp2 pattern="[0-9]{6}" (keypress)="phaseService.isNumberKey($event);enterOtpDigit(otp3)" (keydown)="clearError();changeFocus($event, otp1);handleKeyLeft($event, otp1);handleKeyRight($event, otp3)"
                    maxlength="1" size="1">
                  <input type="text" #otp3 pattern="[0-9]{6}" (keypress)="phaseService.isNumberKey($event);enterOtpDigit(otp4)" (keydown)="clearError();changeFocus($event, otp2);handleKeyLeft($event, otp2);handleKeyRight($event, otp4)"
                    maxlength="1" size="1">
                  <input type="text" #otp4 pattern="[0-9]{6}" maxlength="1" size="1" (keypress)="phaseService.isNumberKey($event);enterOtpDigit(otpSubmitBtn)"
                    (keydown)="changeFocus($event, otp3);handleKeyLeft($event, otp3);handleKeyRight($event, otpSubmitBtn)">
                  <input type="submit" class="verifyButton" #otpSubmitBtn [disabled]="!isOtpSubmitEnabled()" (keydown)="changeFocus($event, otp4);handleKeyLeft($event, otp4);handleKeyRight($event, otpSubmitBtn)"
                    value="Verify">
                  <span class="errorMsg" *ngIf="otpError">{{ otpError }}</span>
                </form>
              </div>
              <h6>Is you haven’t received any email, Please click on <strong (click)="resendLinkClicked = true; sendOTPAfterSignup()">Resend Link <span
                *ngIf="isOtpSent && resendLinkClicked"><strong>Done!</strong> Please check your Inbox</span>
                </strong>
              </h6>
            </div>


            <div *ngIf="!phaseService.signupNextStep && !phaseService.signUpCurrencyForBNow">
              <div class="socialLogin" *ngIf="!phaseService.isPartnerDashboard() && dataService.isEngineerAIBuilder()">
                <h4><span>or connect using</span></h4>
                <span class="socialIcon fbIcon" (click)="social_sign('facebook')"><em
                  class="icon-facebook"></em></span>
                <span class="socialIcon googleIcon" (click)="social_sign('google')"><em
                  class="icon-google-plus"></em></span>
              </div>
            </div>
            <div class="promomsgRow" *ngIf="isShowPromoBar()"><em class="icon-tick"></em> Promocode will be applied on the next step</div>

          </div>

        </div>

        <div class="loginRegisterForm" [ngClass]="{active: isShowForgotPopup()}" *ngIf="isShowForgotPopup()">
          <!--first step start-->
          <div id="backStep1" class="forgotPassBox" [ngClass]="{active: showForgotPassword}">
            <div class="forgotPassText">
              <h3>Forgot your password?</h3>
              <p>Enter your email address below and we'll get you back on track.</p>
            </div>

            <form name="forgotForm" #forgotForm>
              <ul>
                <li>
                  <span class="field-labels">Email Address</span>

                  <input #forgetemail [ngClass]="checkIfForgetEmailValid() ? 'redBorder' : ''" matInput type="email" (keydown)="forgotEmailSelection($event)"
                    placeholder="Enter your Email address" #forgot_email="ngModel" name="forgot_email" [(ngModel)]="resetData.email"
                    maxlength="100" required/>
                  <div class="errorMsgBox">
                    <div class="errorMsg show" *ngIf="checkIfForgetEmailValid() && checkOnForgotEmail && forgot_email.value && showForgotEmailError">
                      Please enter valid email
                    </div>
                    <div class="errorMsg show" *ngIf="checkIfForgetEmailValid() && checkOnForgotEmail && !forgot_email.value && showForgotEmailError">
                      Email cannot be blank
                    </div>
                    <span class="errorMsg show" *ngIf="!checkIfForgetEmailValid() && ErrorMsg && showForgotEmailError">There is no account exists with this email address</span>
                  </div>
                </li>

                <li>
                  <button class="submitButton" [ngClass]="sendButtonClass" type="submit" (click)="send_email(forgotForm)">
                    <span class="icon-right"></span> Reset Password
                    <!--<img *ngIf="preventClick" src="./../../../../assets/images/buttonLoader.gif" alt=""/>-->
                  </button>
                  <p class="orAction">
                    <button type="button" (click)="goToLogin(forgotForm)">Back to Sign in</button>
                  </p>
                </li>

              </ul>
            </form>
          </div>
          <!--first step close-->

          <!--second step start-->
          <div id="backStep2" class="forgotPassBox" [ngClass]="{active: !showForgotPassword}">
            <!--<div class="backPanel" (click)="goToLogin(forgotForm)">Go back</div>-->
            <div class="forgotPassText">
              <h3>Password reset link sent.</h3>
              <p *ngIf="partnerDataService.partnerSignupData?.first_name">
                Hey {{ partnerDataService.partnerSignupData.first_name }}</p>
              <p>We've sent you a verification link to your email, Click on the link to confirm.</p>
            </div>
            <ul>
              <li>
                <button class="submitButton" type="submit" (click)="goToLogin(forgotForm)">Login again</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="commonPopUp" [ngClass]="{active: partnerDataService.isPartnerVerified}" *ngIf="partnerDataService.isPartnerVerified">
    <div class="popOverlay"></div>
    <div class="popHolder loginRegister">
      <div class="closePopUp" (click)="partnerDataService.isPartnerVerified = false; closeForgot()"><span class="icon-cancel"></span></div>

      <div class="loginRegisterLeft">
        <h3>The ride is smoother when you have your own account.</h3>
        <h3>Get yourself one!</h3>
      </div>
      <div class="loginRegisterRight">
        <div class="loginRegisterForm">
          <!--first step start-->
          <div id="backStep5" class="forgotPassBox" [ngClass]="{'active': partnerResendClicked}">
            <div class="backPanel" (click)="goToVerificationMessage()">Go back</div>
            <div class="forgotPassText">
              <p>Enter your Email id to resend verification code.</p>
            </div>

            <form name="resendVerificationForm">
              <ul>
                <li>

                  <mat-form-field>
                    <input matInput type="email" (keydown)="resendEmailChanged($event)" placeholder="Email" #verification_email="ngModel" name="verification_email"
                      [(ngModel)]="resetData.email" maxlength="100" (focus)="ErrorMsg = null; successMsg=null" required/>
                  </mat-form-field>
                  <div class="errorMsgBox">
                    <div class="errorMsg show" *ngIf="!phaseService.validateEmail(resetData.email) && !verification_email.pristine && showResendEmailError">
                      Please enter valid email
                    </div>
                    <div class="errorMsg show" *ngIf="!phaseService.validateEmail(resetData.email) && verification_email.pristine && showResendEmailError">
                      Email cannot be blank
                    </div>
                    <span class="errorMsg show" *ngIf="phaseService.validateEmail(resetData.email) && ErrorMsg && showResendEmailError">{{ErrorMsg}}</span>
                  </div>
                  <span class="successMsg" *ngIf="successMsg">{{ successMsg }}</span>
                </li>
                <li>
                  <button class="submitButton" [ngClass]="resendVerificationClass" type="submit" (click)="resendVerificationLinkToPartner()"
                    disabled="{{(verification_email != null && verification_email.errors && (verification_email.dirty || verification_email.touched))}}">
                    Send
                  </button>
                </li>

              </ul>
            </form>
          </div>

          <div id="backStep6" class="forgotPassBox" [ngClass]="{'active': showVerificationMessage}">
            <h3 *ngIf="partnerDataService.partnerSignupData?.first_name || partnerDataService.partnerData?.first_name">
              Hey {{ partnerDataService.partnerSignupData?.first_name || partnerDataService.partnerData?.first_name }}</h3>
            <div class="forgotPassText">
              Thanks for registering with us!<br> We have sent you a verification link, click on the link on the email to
              verify your email address.
            </div>
            <div>
              <ul>
                <li>
                  <button class="submitButton" type="submit" (click)="openVerificationForm()">
                    Resend
                  </button>
                </li>
              </ul>
            </div>
            <div>
              Please click on Resend button if you haven't received the mail.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
