<div *ngIf="(careType === 'standardCare') && (!dataService.buildCardData.subscription_flow && !subscriptionFlow)">
  <h4 *ngIf="dataService.currentPage !== 'delivery'">How Standard Care protects your software</h4>
  <ul>
    <li>24*7 uptime monitoring.</li>
    <li *ngIf="phaseService.isBuilderCloudChecked">Software application bug fixes (including permanent fixes for recurring issues).</li>
    <li *ngIf="!phaseService.isBuilderCloudChecked">Software application bug fixes (including bug report for permanent fixes for recurring issues).</li>
    <li>Platform updates for latest iOS / Android versions.</li>
    <li>Proactive technical support for third-party API / SDK updates on all standard features.**</li>
    <ng-container *ngIf="(isFromDeliveryPage && viewMoreSCare) || !isFromDeliveryPage">
      <li *ngIf="phaseService.isBuilderCloudChecked">Security patches & Backup Management.*</li>
      <li *ngIf="!phaseService.isBuilderCloudChecked">Security patches.</li>
      <li>Updated tech-stack.</li>
      <li>Industry Standard SLA.</li>
      <li>Multi-Channel Support - Online, Chat, Email with average 24 hour response time.</li>
    </ng-container>
  </ul>
  <ul class="bottomDescription" *ngIf="dataService.currentPage !== 'delivery'">
    <li>*If part of your original software.</li>
    <li>**Excluding any third-party, SDK, security, feature, custom integration or browser addition - that wasn't part of your Buildcard.</li>
    <li>***Features covered under your Buildcard.</li>
    <li><strong class="carot">&#94;</strong> If hosted with Builder Cloud.</li>
  </ul>
</div>

<div *ngIf="((careType === 'standardCarePlus') && (!dataService.buildCardData.subscription_flow && !subscriptionFlow))">
  <h4 *ngIf="dataService.currentPage !== 'delivery'">How Standard Care Plus protects your software</h4>
  <ul>
    <li *ngIf="phaseService.isBuilderCloudChecked">24*7 uptime monitoring. Includes proactive load planning and monitoring.</li>
    <li *ngIf="!phaseService.isBuilderCloudChecked">24*7 uptime monitoring. Includes proactive load planning.</li>
    <li>Software application bug fixes (including root cause analysis and permanent fixes for recurring issues).</li>
    <ng-container *ngIf="(isFromDeliveryPage && viewMoreSCare) || !isFromDeliveryPage">
      <li>Platform updates for latest iOS / Android versions.</li>
      <li>Proactive technical support for third-party API / SDK updates on all standard features and custom features.**</li>
      <li *ngIf="phaseService.isBuilderCloudChecked">Security patches & Backup Management.*</li>
      <li *ngIf="!phaseService.isBuilderCloudChecked">Security patches.</li>
      <li>Updated tech-stack.</li>
      <li *ngIf="phaseService.isBuilderCloudChecked">Industry Standard SLA with 99.95% Uptime Commitment.</li>
      <li *ngIf="!phaseService.isBuilderCloudChecked">Industry Standard SLA.</li>
      <li *ngIf="phaseService.isBuilderCloudChecked">Infrastructure performance and Application Performance Monitoring (APM)<strong class="carot">&#94;</strong>.</li>
      <li>Multi-Channel Support - Online, Chat, Email with average 24 hour response time and 4 hour response commitment for severe issues.</li>
      <li *ngIf="phaseService.isBuilderCloudChecked">Enhancements to current*** features (30% of the app features can be enhanced, limited to, 7.5% per quarter).</li>
      <li *ngIf="!phaseService.isBuilderCloudChecked">Enhancements to current features (30% of the app features can be enhanced, limited to, 7.5% per quarter).***</li>
    </ng-container>
  </ul>
  <ul class="bottomDescription" *ngIf="dataService.currentPage !== 'delivery'">
    <li>*If part of your original software.</li>
    <li>**Excluding any third-party, SDK, security, feature, custom integration or browser addition - that wasn't part of your Buildcard.</li>
    <li>***Features covered under your Buildcard.</li>
    <li><strong class="carot">&#94;</strong> If hosted with Builder Cloud.</li>
  </ul>
</div>

<div *ngIf="((careType === 'standardCarePlus') && (dataService.buildCardData.subscription_flow || subscriptionFlow))">
  <h4 *ngIf="dataService.currentPage !== 'delivery'">Upgrade, for more support and to enhance your app features:</h4>
  <ul>
    <li>Enhance up to 30% of your app features, every year (7.5% each quarter)</li>
    <li>2 hour response commitment for severe issues</li>
    <ng-container *ngIf="(isFromDeliveryPage && viewMoreSCare) || !isFromDeliveryPage">
      <li>Proactive load planning and monitoring</li>
      <li>Infrastructure and performance monitoring</li>
      <li>Root cause analysis</li>
    </ng-container>
  </ul>
  <!--<ul class="bottomDescription" *ngIf="dataService.currentPage !== 'delivery'">
    <li>*If part of your original software.</li>
    <li>**Excluding any third-party, SDK, security, feature, custom integration or browser addition - that wasn't part of your Buildcard.</li>
    <li>***Features covered under your Buildcard.</li>
    <li><strong class="carot">&#94;</strong> If hosted with Builder Cloud.</li>
  </ul>-->
</div>

<div *ngIf="((careType === 'enterpriseCare') && (!dataService.buildCardData.subscription_flow && !subscriptionFlow))">
  <h4 *ngIf="dataService.currentPage !== 'delivery'">How Enterprise Care protects your software</h4>
  <ul>
    <li *ngIf="phaseService.isBuilderCloudChecked">24*7 uptime and performance monitoring. Includes proactive load planning and monitoring.</li>
    <li *ngIf="!phaseService.isBuilderCloudChecked">24*7 uptime and performance monitoring. Includes proactive load planning.</li>
    <li>Software application bug fixes (including root cause analysis and permanent fixes for recurring issues).</li>
    <ng-container *ngIf="(isFromDeliveryPage && viewMoreSCare) || !isFromDeliveryPage">
      <li>Platform updates for latest iOS / Android versions.</li>
      <li>Proactive technical support for third-party API / SDK updates for all standard and custom features.**</li>
      <li *ngIf="phaseService.isBuilderCloudChecked">Security patches & Backup Management.*</li>
      <li *ngIf="!phaseService.isBuilderCloudChecked">Security patches.</li>
      <li>Updated tech-stack.</li>
      <li *ngIf="phaseService.isBuilderCloudChecked">Enterprise SLA with 99.999% Uptime Guarantee.</li>
      <li *ngIf="!phaseService.isBuilderCloudChecked">Enterprise SLA.</li>
      <li *ngIf="phaseService.isBuilderCloudChecked">Enhancements to current*** features (30% of the app features can be enhanced, limited to 7.5% per quarter).</li>
      <li *ngIf="!phaseService.isBuilderCloudChecked">Enhancements to current features (30% of the app features can be enhanced, limited to 7.5% per quarter).***</li>
      <li *ngIf="phaseService.isBuilderCloudChecked">Infrastructure performance and Application Performance Monitoring (APM)<strong class="carot">&#94;</strong></li>
      <li>Multi-Channel Support - Online, Chat, Email with average 24 hour response time and 4 hour response commitment for severe issues.</li>
    </ng-container>
  </ul>
  <ul class="bottomDescription" *ngIf="dataService.currentPage !== 'delivery'">
    <li>*If part of your original software.</li>
    <li>**Excluding any third-party, SDK, security, feature, custom integration or browser addition - that wasn't part of your Buildcard.</li>
    <li>***Features covered under your Buildcard.</li>
    <li><strong class="carot">&#94;</strong> If hosted with Builder Cloud.</li>
  </ul>
</div>

<div *ngIf="(careType === 'standardCare') && (dataService.buildCardData.subscription_flow || subscriptionFlow)">
  <h4 *ngIf="dataService.currentPage !== 'delivery'">Upgrade, for more support and to enhance your app features:</h4>
  <p>
    Your app price includes Studio One for 1 year, which unlocks a dashboard to monitor your build in
    real-time – approve designs, give feedback and make changes. It’s our way of ensuring ongoing success
    with your new app. You also get expert support throughout your build and beyond.
  </p>
  <p>Success built-in to every app</p>
  <ul>
    <li>Ongoing expert support</li>
    <li>Collaboration tools to manage your project</li>
    <ng-container *ngIf="(isFromDeliveryPage && viewMoreStndSubscription) || !isFromDeliveryPage">
      <li>24/7 uptime monitoring</li>
      <li>4 hour response commitment for severe issues</li>
      <li>Third-party API updates </li>
      <li>iOS and Android platform updates </li>
      <li>Security patches & bug fixes</li>
      <li>Updated tech stack</li>
    </ng-container>
  </ul>
</div>

<div *ngIf="((careType === 'enterpriseCare') && (dataService.buildCardData.subscription_flow || subscriptionFlow))">
  <h4 *ngIf="dataService.currentPage !== 'delivery'">Upgrade, for more support and to enhance your app features:</h4>
  <p>
    Your app price includes Enterprise One for 1 year, which unlocks a dashboard to monitor your build
    in real-time – approve designs, give feedback and make changes. It’s our way of ensuring ongoing
    success with your new app. You also get expert support throughout your build and beyond.
  </p>
  <p>Success built-in to every app</p>
  <ul>
    <li>Ongoing expert support</li>
    <li>Collaboration tools to manage your project</li>
    <ng-container *ngIf="(isFromDeliveryPage && viewMoreEntSubscription) || !isFromDeliveryPage">
      <li>24/7 uptime monitoring</li>
      <li>Third-party API updates </li>
      <li>iOS and Android platform updates </li>
      <li>Security patches & bug fixes</li>
      <li>Updated tech stack</li>
      <li>Enhance up to 30% of your app features, every year (7.5% each quarter)</li>
      <li>Proactive load planning and monitoring</li>
      <li>Infrastructure and performance monitoring</li>
      <li>Root cause analysis</li>
      <li>Priority response for urgent issues (within 2 hours)</li>
    </ng-container>
  </ul>
  <!--<ul class="bottomDescription" *ngIf="dataService.currentPage !== 'delivery'">
    <li>*If part of your original software.</li>
    <li>**Excluding any third-party, SDK, security, feature, custom integration or browser addition - that wasn't part of your Buildcard.</li>
    <li>***Features covered under your Buildcard.</li>
    <li><strong class="carot">&#94;</strong> If hosted with Builder Cloud.</li>
  </ul>-->
</div>

<div *ngIf="((careType === 'enterpriseCarePlus') && (!dataService.buildCardData.subscription_flow && !subscriptionFlow))">
  <h4 *ngIf="dataService.currentPage !== 'delivery'">How Enterprise Care Plus protects your software</h4>
  <ul>
    <li *ngIf="phaseService.isBuilderCloudChecked">24*7 uptime and performance monitoring. Includes proactive load planning and monitoring.</li>
    <li *ngIf="!phaseService.isBuilderCloudChecked">24*7 uptime and performance monitoring. Includes proactive load planning.</li>
    <li>Software application bug fixes (including root cause analysis and permanent fixes for recurring issues).</li>
    <ng-container *ngIf="(isFromDeliveryPage && viewMoreSCare) || !isFromDeliveryPage">
      <li>Platform updates for latest iOS / Android versions.</li>
      <li>Proactive technical support for third-party API / SDK updates for all standard and custom features.**</li>
      <li *ngIf="phaseService.isBuilderCloudChecked">Security patches & Backup Management.*</li>
      <li *ngIf="!phaseService.isBuilderCloudChecked">Security patches.</li>
      <li>Updated tech-stack.</li>
      <li *ngIf="phaseService.isBuilderCloudChecked">Enterprise SLA with 99.999% Uptime Guarantee.</li>
      <li *ngIf="!phaseService.isBuilderCloudChecked">Enterprise SLA.</li>
      <li *ngIf="phaseService.isBuilderCloudChecked">Enhancements to current*** features (50% of the app features can be enhanced, limited to 12.5% per quarter).</li>
      <li *ngIf="!phaseService.isBuilderCloudChecked">Enhancements to current features (50% of the app features can be enhanced, limited to 12.5% per quarter).***</li>
      <li *ngIf="phaseService.isBuilderCloudChecked">Infrastructure performance and Application Performance Monitoring (APM).<strong class="carot">&#94;</strong></li>
      <li *ngIf="phaseService.isBuilderCloudChecked">Multi-Channel Online, Chat, Email, with average 24 hour response time and 2 hour response commitment for severe issues.</li>
      <li *ngIf="!phaseService.isBuilderCloudChecked">Multi-Channel Online, Chat, Email, with average 24 hour response time and 1 hour response commitment for severe issues.</li>
    </ng-container>
  </ul>
  <ul class="bottomDescription" *ngIf="dataService.currentPage !== 'delivery'">
    <li>*If part of your original software.</li>
    <li>**Excluding any third-party, SDK, security, feature, custom integration or browser addition - that wasn't part of your Buildcard.</li>
    <li>***Features covered under your Buildcard.</li>
    <li><strong class="carot">&#94;</strong> If hosted with Builder Cloud.</li>
  </ul>
</div>

<div *ngIf="((careType === 'enterpriseCarePlus') && (dataService.buildCardData.subscription_flow || subscriptionFlow))">
  <h4 *ngIf="dataService.currentPage !== 'delivery'">Upgrade, for more support and to enhance your app features:</h4>
  <ul>
    <li>Enhance up to 50% of your app features, every year (12.5% each quarter)</li>
    <li>1 hour response commitment for urgent issues</li>
  </ul>
</div>
