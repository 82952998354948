import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '@shared/services/data.service';
import { PhaseService } from '@shared/services/phase.service';

@Component({
  selector: 'app-builder-care-content',
  templateUrl: './builder-care-content.component.html',
  styleUrls: ['./builder-care-content.component.scss']
})
export class BuilderCareContentComponent  {

  @Input('isFromDeliveryPage') isFromDeliveryPage;
  @Input('careType') careType;
  @Input('viewMoreSCare') viewMoreSCare;
  @Input('viewMoreStndSubscription') viewMoreStndSubscription;
  @Input('viewMoreEntSubscription') viewMoreEntSubscription;
  @Input() subscriptionFlow;

  constructor(public dataService: DataService, public phaseService: PhaseService) {
  }



}
