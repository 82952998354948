<div class="profileView">
  <div class="profileHead">
    <h3>Profile</h3>
    <button class="editProfile" *ngIf="!editProfileShow" (click)="editProfile()">Edit</button>
    <button class="editProfile" *ngIf="editProfileShow" (click)="cancelUpdateProfile()">Cancel</button>

    <div class="changesSaved" *ngIf="changesSaved">
      <em class="icon-right"></em>
      <div class="text"> All changes saved successfully <em class="icon-x" (click)="hideUpdateNotification()"></em></div>
    </div>
  </div>

  <div class="profilemiddle">
    <div class="profilePic">
      <div class="imgContainer">
        <div class="profileImg" *ngIf="!dataService.user.profile_image" [ngStyle]="{'background': dataService.natashaAvatarBackground}">{{dataService.natashaAvatarInitials}}</div>
        <div class="profileImg" *ngIf="dataService.user.profile_image"><img [src]="dataService.user.profile_image" alt=""></div>
        <div class="textInfo">A photo help personalise your account</div>
      </div>
      <!-- <div class="editButton changePhoto">Change Photo</div> -->
      <input type="file" class="custom-file-input" accept="image/png, image/jpeg" (change)="changeProfilePic($event)">
    </div>

    <div class="profileDetail" *ngIf="!editProfileShow">
      <!--if we need to blur this section then plz apply class name "blurThis" on <ul>------>
      <ul [ngClass]="{'blurThis': !getUser()?.user_profiles && userProfiles?.length > 0}">
        <li><strong>First Name</strong> <span class="profileTxt">{{getUser().first_name}}</span></li>
        <li><strong>Last Name</strong> <span class="profileTxt">{{getUser().last_name}}</span></li>
        <li><strong>Email Address</strong> <span class="profileTxt">{{getUser().email}}</span></li>
        <li><strong>Password</strong> <span class="profileTxt">*************</span></li>
      </ul>
    </div>

    <div class="profileField" [ngClass]="{active: editProfileShow}">
      <ul>
        <li><strong>First Name*</strong>
          <input type="text" placeholder="First Name" #firstName="ngModel" name="firstName" pattern="[a-zA-Z ]*"
            [(ngModel)]="userData.first_name" maxlength="100" required />
          <div class="errorMsg" *ngIf="firstName.errors && (firstName.dirty || firstName.touched)">Please enter valid
            first name
          </div>
        </li>
        <li><strong>Last Name</strong>
          <input type="text" placeholder="Last Name" #lastName="ngModel" name="lastName" pattern="[a-zA-Z ]*"
            [(ngModel)]="userData.last_name" maxlength="100" />
          <div class="errorMsg" *ngIf="lastName.errors && (lastName.dirty || lastName.touched)">Please enter valid last
            name
          </div>
        </li>
        <li><strong>Email Address</strong> <span class="profileTxt">{{getUser().email}}</span></li>
        <li class="passwordRow"><strong>Password</strong>
          <div class="passwordField">
            <input type="password" placeholder="Type new password" name="password" #password="ngModel"
              [(ngModel)]="userData.password" maxlength="100" minlength="8" type="{{resetPass.show?'text':'password'}}">
            <div *ngIf="(password.touched || password.dirty) && password.errors">
              <div *ngIf="password.errors.minLength && !password.errors.required" class="errorMsg">
                <span>Password should contain 8 characters</span>
              </div>
              <div *ngIf="password.errors.required" class="errorMsg">
                <span>Password is required</span>
              </div>
            </div>
            <span class="showPass" (click)="showPassword()">{{resetPass.text}}</span>
            <input type="password" placeholder="Retype Password" name="confirm_password" #confirm_password="ngModel"
              [(ngModel)]="userData.confirm_password" maxlength="100" minlength="8" validateEqual="password">
            <span class="errorMsg"
              *ngIf="userData.password && userData.confirm_password && userData.password != userData.confirm_password">Password
              mismatch
            </span>
          </div>
        </li>
      </ul>
      <span class="errorMsg show">{{ErrorMsg}}</span>
      <div class="profileButton">
        <button class="cancelButton" (click)="cancelUpdateProfile()">Cancel</button>
        <button class="saveButton" (click)="saveProfile()" [disabled]="(firstName != null && firstName.errors && (firstName.dirty || firstName.touched)) ||
                lastName.errors && (lastName.dirty || lastName.touched) ||
                (userData.password  && userData.confirm_password  && userData.password != userData.confirm_password)
                || (userData.password && !userData.confirm_password)? true : null">
          Save <img *ngIf="preventClick" src="./../../../assets/images/buttonLoader.gif" alt="" />
        </button>
      </div>
    </div>

    <div class="companyInfo blurThis" *ngIf="getUser().user_profiles && getUser().user_profiles.length > 0">
      <h3>Company Information</h3>
      <div class="infoField">
        <div class="labelName">Tell us, what defines you better?</div>
        <div class="infoData">
          <ol class="dottedList">
            <li *ngFor="let profile of getUser().user_profiles">
              <label>{{ profile.title }}</label>
            </li>
          </ol>
        </div>
      </div>
    </div>

    <div class="companyInfo" *ngIf="!getUser()?.user_profiles && userProfiles?.length">
      <h3>Company Information</h3>
      <div class="infoField">
        <div class="labelName">Tell us, what defines you better?</div>
        <div class="infoData">
          <form>
            <ol>
              <li *ngFor="let userProfile of userProfiles">
                <input type="checkbox" [id]="userProfile.id" (change)="selectUserProfile(userProfile)">
                <label [for]="userProfile.id">{{ userProfile.title }}</label>
              </li>
            </ol>
            <button class="saveInfo" (click)="dataService.isUserProfileSelected = true">Done <img *ngIf="false"
                src="./../../../assets/images/buttonLoader.gif" alt="" />
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- Notification section -->
<div class="profileView notificationSettings">
  <header>
    <h3>Notification Settings</h3>
    <div class="cancelButton" *ngIf="isNotificationEditMode" (click)="toggleEditPreferences()">Cancel</div>
    <div class="cancelButton" *ngIf="!isNotificationEditMode" (click)="toggleEditPreferences()">Edit</div>
  </header>
  <div class="innerContainer" [ngClass]="{'disabledNotification': !isNotificationEditMode}">
    <div class="inputRow">
      <div class="titleBox">
        <img src="../../../../../../../assets/images/icon-whatsapp-white.svg" alt="whatsApp">
        <span>Whatsapp</span>
      </div>
      <div class="ddlContainer">
        <div class="phoneNumberField">
          <div class="phoneNumberField" [ngClass]="{'redBorder' : (isSaveButtonClicked ||mobileNumber.touched) &&(!notificationData.whatsapp_phone_number || (notificationData.whatsapp_phone_number && checkIfPhoneInValid(notificationData.whatsapp_phone_number)))}">
            <div class="countryFlagCode" (click)="toggleCountriesList($event, 'whatsapp')">
              <span class="selectedFlag flag-icon flag-icon-squared" [class]="'flag-icon-' + countryISOCode.whatsapp?.toLowerCase()"></span>
              <div class="countryFlagCodeDropdown" *ngIf="showCountriesList.whatsapp">

                <ul *ngIf="countriesData">
                  <li *ngFor="let country of countriesData | searchCountries: countryCodeOrName" (click)="setPhoneNumber(country, $event, 'whatsapp')">
                    <span class="flag-icon flag-icon-squared" [class]="'flag-icon-' + country.code.toLowerCase()"></span>
                    {{ country.name }} <span class="countryCode">{{ country.dial_code }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <input class="inputPhone" #mobileNumber="ngModel" name="mobileNumber"[(ngModel)]="notificationData.whatsapp_phone_number"
                   [ngClass]="{'errorInput1': (mobileNumber.touched && !contactNumber) || (contactNumber && checkIfPhoneInValid(notificationData.whatsapp_phone_number))}"
                   (keypress)="acceptNumberOnly($event, 'whatsapp')" (keyup)="acceptNumberOnly($event, 'whatsapp'); setFlagForDialCode('whatsapp')" required>
            <input [ngClass]="!showCountriesList.whatsapp ? 'hide-search-input':'searchByName'" id="hiddenInputBox"
                   [(ngModel)]="countryCodeOrName" #searchNameOrCode name="fname"
                   #fname="ngModel" (keypress)="acceptAlphaNumeric($event)">
          </div>
          <div class="inputfield phoneNo">
            <div class="errorMsgBox">
              <span class="errorMsg" *ngIf="((isSaveButtonClicked ||mobileNumber.touched) && notificationData.whatsapp_phone_number && checkIfPhoneInValid(notificationData.whatsapp_phone_number))">Please enter valid contact</span>
              <span class="errorMsg" *ngIf="((isSaveButtonClicked ||mobileNumber.touched) && !notificationData.whatsapp_phone_number)">please enter your contact info</span>
            </div>
          </div>
        </div>
      </div>
      <div class="radioContainer">
        <div class="advanceTab">
          <strong [ngClass]="{'active': notificationData.whatsapp_enabled}"
                  (click)="toggleRadioButton('whatsapp')">
          </strong>
        </div>
      </div>
    </div>
    <div class="inputRow">
      <div class="titleBox">
        <img src="../../../../../../../assets/images/icon-message-white.svg" alt="SMS">
        <span>SMS</span>
      </div>
      <div class="ddlContainer">
        <div class="phoneNumberField">
          <div class="phoneNumberField" [ngClass]="{'redBorder' : (isSaveButtonClicked ||mobileNumber.touched) &&(!notificationData.sms_phone_number || (notificationData.sms_phone_number && checkIfPhoneInValid(notificationData.sms_phone_number)))}">
            <div class="countryFlagCode" (click)="toggleCountriesList($event, 'SMS')">
              <span class="selectedFlag flag-icon flag-icon-squared" [class]="'flag-icon-' + countryISOCode.sms?.toLowerCase()"></span>
              <div class="countryFlagCodeDropdown" *ngIf="showCountriesList.sms">

                <ul *ngIf="countriesData">
                  <li *ngFor="let country of countriesData | searchCountries: countryCodeOrName" (click)="setPhoneNumber(country, $event, 'SMS')">
                    <span class="flag-icon flag-icon-squared" [class]="'flag-icon-' + country.code.toLowerCase()"></span>
                    {{ country.name }} <span class="countryCode">{{ country.dial_code }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <input class="inputPhone" #mobileNumber="ngModel" name="mobileNumber"[(ngModel)]="notificationData.sms_phone_number"
                   [ngClass]="{'errorInput1': (mobileNumber.touched && !contactNumber) || (contactNumber && checkIfPhoneInValid(notificationData.sms_phone_number))}"
                   (keypress)="acceptNumberOnly($event, 'SMS')" (keyup)="acceptNumberOnly($event, 'SMS'); setFlagForDialCode('SMS')" required>
            <input [ngClass]="!showCountriesList.sms ? 'hide-search-input':'searchByName'" id="hiddenInputBox"
                   [(ngModel)]="countryCodeOrName" #searchNameOrCode name="fname"
                   #fname="ngModel" (keypress)="acceptAlphaNumeric($event)">
          </div>
          <div class="inputfield phoneNo">
            <div class="errorMsgBox">
              <span class="errorMsg" *ngIf="((isSaveButtonClicked ||mobileNumber.touched) && notificationData.sms_phone_number && checkIfPhoneInValid(notificationData.sms_phone_number))">Please enter valid contact</span>
              <span class="errorMsg" *ngIf="((isSaveButtonClicked ||mobileNumber.touched) && !notificationData.sms_phone_number)">please enter your contact info</span>
            </div>
          </div>
        </div>
      </div>
      <div class="radioContainer">
        <div class="advanceTab">
          <strong [ngClass]="{'active': notificationData.sms_enabled}"
                  (click)="toggleRadioButton('SMS')">
          </strong>
        </div>
      </div>
    </div>
  </div>
  <footer *ngIf="isNotificationEditMode">
    <button class="btnCancel" (click)="toggleEditPreferences()">Cancel</button>
    <button class="btnSave" (click)="savePreferences()">Save</button>
  </footer>
</div>
<!-- Notification section cloase -->

<div class="profileView">
  <div class="profilemiddle">
    <div class="banttQuestions" *ngIf="!showEditBanttAnswerPopup">
      <h3>Company</h3>
      <div class="editButton" (click)="openEditBanttAnswerPopup()" >Edit</div>
      <div class="banttQuestionRow" *ngFor="let question of bantQuestion;let questionIndex = index">
        <div class="banttQuestionText">
          <h4>{{question.title}}</h4>
          <p *ngIf="showBanttAnswer && (dataService.user.bant_answers !== null)">
            {{ showAnswer(dataService.user.bant_answers[questionIndex][1]) }}
          </p>
          <p *ngIf="!(showBanttAnswer && (dataService.user.bant_answers !== null))">-</p>
        </div>
      </div>
    </div>

    <div class="banttQuestions" *ngIf="showEditBanttAnswerPopup">
      <h3>Company</h3>
      <div class="cancelButton" (click)="closeEditBanttPopup()">Cancel</div>
      <div class="banttQuestionRow" *ngFor="let question of bantQuestion;let questionIndex = index">
        <div class="banttQuestionText">
          <h4>{{question.title}}</h4>
            <div class="customSelect" (change)="selectBantAnswer($event,questionIndex)">
              <select>
                <option selected="selected" style="display:none;">
                  {{getSelectedItem(question,questionIndex)}}
                </option>
                <option *ngFor="let option of question.options; let answerIndex = index" value="{{answerIndex}}" id="option{{answerIndex}}{{questionIndex}}">{{option.key}}</option>
              </select>
            </div>
        </div>
      </div>

      <div class="questionUpdateButton">
        <button class="cancelButton1" type="button" (click)="closeEditBanttPopup()">Cancel</button>
        <button class="saveButton" type="button" [disabled]="isAllQuestionAnswered()" (click)="updateBanttAnswer()">Save</button>
      </div>
    </div>
  </div>
</div>

<!-- Update your preferences modal -->
<div class="commonPopUp ">
  <div class="popOverlay"></div>
  <div class="popHolder updatePreferencesWrap">
    <div class="closeButton"><em class="icon-cancel"></em></div>
    <div class="bodyContainer">
      <h2>As you have more than one Buildcard, how would you like to update your preferences?</h2>
      <div class="contentWrap">
        <div class="contentBox">
          <div class="counter">
            <span>1</span>
          </div>
          <h4 class="title">Update Notification preference for all Buildcard</h4>
          <p class="descriptionText">Save common preferences for all your Buildcard</p>
          <div class="buttonContainer">
            <button>Proceed</button>
          </div>
        </div>
        <div class="contentBox">
          <div class="counter">
            <span>2</span>
          </div>
          <h4 class="title">Update Notification preference for a single Buildcard</h4>
          <p class="descriptionText">Personalise preference for any of your Buildcard</p>
          <div class="buttonContainer">
            <button>Proceed</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Update your preferences modal close -->
<!-- Thanks you modal -->
<div class="commonPopUp "><!--active-->
  <div class="popOverlay"></div>
  <div class="popHolder thankyouWrap">
    <div class="closeButton"><em class="icon-cancel"></em></div>
    <div class="bodyContainer">
      <div class="checkboxContainer">
        <div class="checkboxBlue">
          <img src="../../../../../../../assets/images/shape-check.svg" alt=""/>
        </div>
      </div>
      <h2>Thank you!</h2>
      <p>Your preferences has been saved !</p>
      <div class="buttonContainer">
        <button class="gotoButton">Ok, got it</button>
      </div>
    </div>
  </div>
</div>
<!-- Thanks you modal -->
<!-- Buildcard preferences modal -->
<div class="commonPopUp">
  <div class="popOverlay"></div>
  <div class="popHolder buildcardPreferenceWrap">
    <div class="closeButton"><em class="icon-cancel"></em></div>
    <div class="bodyContainer">
      <h2>Which Buildcard would you like to update your preferences on?</h2>
      <p>Please select the Buildcard you wish to update preferences for and we will take you to Builder Home where you can adjust preferences for this Buildcard only.</p>
      <div class="ddlContainer">
        <label>Select your Buildcard</label>
        <div class="ddlWrap">
          <select><option>Buildcard-1</option><option>Buildcard-2</option></select>
        </div>
      </div>
      <div class="buttonContainer">
        <button class="nextButton disabled">Next</button>
      </div>
    </div>
  </div>
</div>
<!-- Buildcard preferences modal -->
