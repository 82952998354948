import {Component, OnInit} from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {AppDataService} from '@rootmodule/app-data.service';
import {DataService} from '@shared/services/data.service';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-right-side',
  templateUrl: './right-side.component.html',
  styleUrls: ['./right-side.component.scss']
})
export class RightSideComponent {
  PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
  };

  constructor(public phaseService: PhaseService, public appDataService: AppDataService, public dataService: DataService) {
  }



  handlePaymentoption(index: number) {
    if (this.dataService.buildCardData.studio_store_nda_signed && !this.phaseService.isRentalCardPaid) {
      this.phaseService.handleNextPrevForRentalFlow(index);
    }
  }

  handleContractClick(index: number) {
    if (!this.phaseService.isRentalCardPaid && !this.appDataService.urlParameters.is_freemium) {
      this.phaseService.handleNextPrevForRentalFlow(index);
    }
    if(!this.phaseService.isRentalCardPaid && this.appDataService.urlParameters.is_freemium){
      if(this.phaseService.cardStatusSelected){
        this.phaseService.handleNextPrevForRentalFlow(index);
      }
    }
  }

  handleCardType(index: number){
    if (!this.phaseService.isRentalCardPaid && this.phaseService.buildCardUpdated && this.dataService.user){
      this.phaseService.handleNextPrevForRentalFlow(index);
    }
  }

  ContiueToPayment(index: number){
    if (!this.phaseService.isRentalCardPaid && this.phaseService.cardStatusSelected){
      this.phaseService.handleNextPrevForRentalFlow(index);
    }
  }

  handlePaymentOverViewClick(index: number) {
    if (!this.phaseService.isRentalCardPaid){
      this.phaseService.handleNextPrevForRentalFlow(1);
    }
  }

  handleProceedClick(){
    if (!this.phaseService.isRentalCardPaid && this.dataService.user){
      this.phaseService.handleNextPrevForRentalFlow(1);
    }
  }

  getCurrency() {
    return (this.dataService.buildCardData && this.dataService.buildCardData.id) ? this.dataService.buildCardData.currency.symbol : this.dataService.user.currency.symbol;
  }

}
