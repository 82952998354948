import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { PhaseService } from '@shared/services/phase.service';
import { DataService } from '@shared/services/data.service';
import { Router } from '@angular/router';
import { ApiRequests } from '@shared/services/api-requests.service';
import { CommonService } from '@shared/services/common.service';
import { AppDataService } from '@rootmodule/app-data.service';
import { HomeTemplateService } from '@shared/services/home-template.service';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { Angulartics2Segment } from 'angulartics2/segment';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'app-studio-intro-modal',
  templateUrl: './studio-intro-modal.component.html',
  styleUrls: ['./studio-intro-modal.component.scss']
})
export class StudioIntroModalComponent implements OnInit, AfterViewInit {
  isShowInfoModal = false;
  isSignUpflow = false;
  isDesktopScreen: boolean;
  carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 3, lg: 3, all: 0 },
    load: 3,
    interval: { timing: 4000, initialDelay: 1000 },
    loop: true,
    touch: true,
    velocity: 0.2
  };
  carouselItems = [{ step: 'Step 1. Choose a base', image: '../../../../../assets/images/Choose-a-base.png', desc: 'Pick from the world’s favourite apps – find ones that do similar things to your idea. Use them as a base (it saves loads of time).' },
  { step: 'Step 2. Make it your own', image: '../../../../../assets/images/make-it-your-own.png', desc: 'Add features to your base (like Google login, push notifications or shopping cart) and remove ones your idea doesn\'t need.' },
  { step: 'Step 3. Plan delivery', image: '../../../../../assets/images/plan-delivery.png', desc: 'Want your idea to work on Apple, Android, Web or a Smartwatch? Want us to design it? And how fast do you want it? It’s up to you.' }];

  constructor(private renderer: Renderer2, private appDataService: AppDataService, private commonService: CommonService,
    public apiRequest: ApiRequests, public router: Router, public phaseService: PhaseService, public dataService: DataService,
    public homeTemplateService: HomeTemplateService, private cdr: ChangeDetectorRef, private analyticsSegemnt: Angulartics2Segment) {
    this.checkWindowScreen();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkWindowScreen();
  }

  ngOnInit(): void {
    if (!this.appDataService.urlParameters.schedular_reason) {
      this.isShowInfoModal = true;
    }

    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    this.renderer.addClass(document.getElementById('app-component'), 'hide-scroll-bar');
    if (this.appDataService.urlParameters.show_signup) {
      this.isSignUpflow = true;
    }
    if (this.commonService.isPlatformBrowser) {
      localStorage.setItem('welcome_screen_seen', 'true');
      localStorage.setItem('welcome_screen_letstart', 'true');
      let result = JSON.stringify(Math.ceil(Number(Date.now() / 1000)));
      localStorage.setItem('welcome_screen_expiry', result);
    }
    this.phaseService.showWelcomeScreen = false;
    this.apiRequest.getUserData();
    if (this.dataService.user) {
      this.router.navigate(['home']);
    }

  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  checkWindowScreen() {
    if (screen.width < 768) {
      this.isDesktopScreen = false;
    } else {
      this.isDesktopScreen = true;
    }
  }

  openBuildNow() {

    this.phaseService.trackEvent(this.analyticsSegemnt, '', 'welcome_cta_selected');
    // if (this.commonService.isPlatformBrowser) {
    localStorage.setItem('welcome_screen_seen', 'true');
    localStorage.setItem('welcome_screen_letstart', 'true');
    this.isShowInfoModal = false;
    this.homeTemplateService.isShowTemplateNatashaInitialAnimation = true;
    this.homeTemplateService.isShowTemplateNatashaIntroPopup = true;
    this.phaseService.showTemplateNatashaMobileAnimation = true;
    this.homeTemplateService.showNatashaPanelMobile = true;
    if (!this.dataService.user) {
      this.homeTemplateService.isShowNatashaNameScreen = true;
    }

    setTimeout(() => {
      this.phaseService.showTemplateNatashaMobileAnimation = false;
    }, 7000);
    // }
    // this.router.navigate(['home']);
  }

  openSignupModal() {
    this.phaseService.openCommonAuth = true;
    if (this.dataService.homePageData?.block_sign_up) {
      this.phaseService.showSignin = true;
      this.phaseService.showRegister = false;
    } else {
      this.phaseService.showRegister = true;
      this.phaseService.showSignin = false;
    }
    this.isShowInfoModal = false;
  }

  loadMore() {
    window.open('https://www.builder.ai/', '_blank' , "noopener");
  }
}
