import {Component, Input, ViewChild} from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {Router} from '@angular/router';
import {DragScrollComponent} from 'ngx-drag-scroll';
import {DataService} from '@shared/services/data.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {UtilityService} from '@shared/services/utility.service';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'question-type',
  styleUrls: ['question.component.scss'],
  templateUrl: 'question.component.html'
})

export class QuestionComponent {

  @Input('problem_group') problem_group;
  @Input('problem_groupSection') problem_groupSection;
  primarySections = [];
  selectedAppsIds = [];
  problemIds = [];
  showConfirm = false;
  showLoader = true;
  leftNavDisabled = false;
  rightNavDisabled = false;
  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;

  constructor( public analyticsSegment: Angulartics2Segment, public dataService: DataService, public phaseService: PhaseService, public router: Router, public utilityService: UtilityService) {
  }

  ngOnInit() {
    if (this.problem_group.primary_section) {
      this.primarySections = this.problem_group.primary_section;
      this.phaseService.newProblemsData = this.primarySections;
      this.sortByPosition();
      if (this.primarySections.length > 0) {
        this.showLoader = false;
      }
    }
  }

  ngOnChanges() {
    if (this.problem_groupSection) {
      this.primarySections = this.problem_groupSection.section_details;
      this.phaseService.newProblemsData = this.primarySections;
      this.sortByPosition();
      if (this.primarySections.length > 0) {
        this.showLoader = false;
      }
    }
  }

  closePopUp() {
    this.showConfirm = false;
  }

  public sortByPosition() {
    this.primarySections.sort(function (a, b) {
      const keyA = new Date(a.precedence),
        keyB = new Date(b.precedence);
      // Compare the 2 dates
      if (keyA < keyB) { return -1; }
      if (keyA > keyB) { return 1; }
      return 0;
    });
  }

  clickProblem(section) {
    if (this.phaseService.newSelectedAppIds.length > 0) {
      this.phaseService.firstClicked = 'app';
    }
    this.showConfirm = true;
    const arr = [];
    const appArr = [];
    if (this.phaseService.newSelectedProblemsIds.includes(section.id)) {
      const indexP = this.phaseService.newSelectedProblemsIds.indexOf(section.id);
      if (indexP !== -1) { this.phaseService.newSelectedProblemsIds.splice(indexP, 1); }
      delete this.phaseService.newSelectedProblemsAppsIds[section.id];
      delete this.phaseService.newSelectedProblemsApps[section.id];
    } else {
      if (section.applications.length > 0) {
        section.applications.forEach(element => {
          arr.push(element.id);
          appArr.push(element);
        });
        this.phaseService.newSelectedProblemsAppsIds[section.id] = arr;
        this.phaseService.newSelectedProblemsApps[section.id] = appArr;
        this.phaseService.newSelectedProblemsIds = Object.keys(this.phaseService.newSelectedProblemsAppsIds).map(Number);
      }

    }
    appArr.forEach((psection) => {
      const mandatoryFeaturesIds = this.phaseService.mandatoryFeatures.map(feature => feature.id);
      this.phaseService.mandatoryFeatures.push(...psection.features.filter(feature => {
        if (mandatoryFeaturesIds.indexOf(feature.id) === -1) {
          return feature.is_mvp_feature;
        }
      }));
    });
  }

  checkApp(section, application) {
    if (this.phaseService.newSelectedProblemsIds.length > 0 && this.phaseService.newSelectedProblemsAppsIds[section.id]) {
      return this.phaseService.newSelectedProblemsAppsIds[section.id].includes(application.id);
    }
  }

  removeApp(section, application) {
    if (this.phaseService.newSelectedAppIds.length > 0) {
      this.phaseService.firstClicked = 'app';
    }
    this.showConfirm = true;
    if (!this.phaseService.newSelectedProblemsAppsIds[section.id]) {
      this.phaseService.newSelectedProblemsAppsIds[section.id] = [];
      this.phaseService.newSelectedProblemsApps[section.id] = [];
      const arr = [];
      const arrApp = [];
      arr.push(application.id);
      arrApp.push(application);
      this.phaseService.newSelectedProblemsAppsIds[section.id] = arr;
      this.phaseService.newSelectedProblemsApps[section.id] = arrApp;
      this.phaseService.newSelectedProblemsIds = Object.keys(this.phaseService.newSelectedProblemsAppsIds).map(Number);
      arrApp.forEach((psection) => {
        const mandatoryFeaturesIds = this.phaseService.mandatoryFeatures.map(feature => feature.id);
        this.phaseService.mandatoryFeatures.push(...psection.features.filter(feature => {
          if (mandatoryFeaturesIds.indexOf(feature.id) === -1) {
            return feature.is_mvp_feature;
          }
        }));
      });
    } else {
      if (this.phaseService.newSelectedProblemsAppsIds[section.id].length > 0) {
        if (!this.phaseService.newSelectedProblemsAppsIds[section.id].includes(application.id)) {
          const selArr = this.phaseService.newSelectedProblemsAppsIds[section.id];
          const selAppArr = this.phaseService.newSelectedProblemsApps[section.id];
          selArr.push(application.id);
          selAppArr.push(application);
          this.phaseService.newSelectedProblemsAppsIds[section.id] = selArr;
          this.phaseService.newSelectedProblemsApps[section.id] = selAppArr;
        } else {
          const arr = this.phaseService.newSelectedProblemsAppsIds[section.id];
          const arrApp = this.phaseService.newSelectedProblemsApps[section.id];
          const index = arr.indexOf(application.id);
          if (index !== -1) { arr.splice(index, 1); }
          // var indexApp = arrApp.indexOf(application.id);
          // if (indexApp !== -1) arrApp.splice(indexApp, 1);
          arrApp.forEach((item, i) => {
            if (application.id === item.id) {
              arrApp.slice(i, 1);
            }
          });
          if (arr.length === 0) {
            delete this.phaseService.newSelectedProblemsAppsIds[section.id];
            delete this.phaseService.newSelectedProblemsApps[section.id];
            const indexP = this.phaseService.newSelectedProblemsIds.indexOf(section.id);
            if (indexP !== -1) { this.phaseService.newSelectedProblemsIds.splice(indexP, 1); }
          } else {
            this.phaseService.newSelectedProblemsAppsIds[section.id] = arr;
            this.phaseService.newSelectedProblemsApps[section.id] = arrApp;
          }
          arrApp.forEach((psection) => {
            const mandatoryFeaturesIds = this.phaseService.mandatoryFeatures.map(feature => feature.id);
            this.phaseService.mandatoryFeatures.push(...psection.features.filter(feature => {
              if (mandatoryFeaturesIds.indexOf(feature.id) === -1) {
                return feature.is_mvp_feature;
              }
            }));
          });
        }
      }
    }
  }

  maintainPreSelection() {
    this.showConfirm = false;
    this.phaseService.firstClicked = '';
    this.phaseService.newSelectedProblemsAppsIds = [];
    this.phaseService.newSelectedProblemsApps = [];
    this.phaseService.newSelectedProblemsIds = [];
  }

  removePreSelection() {
    this.phaseService.firstClicked = '';
    this.showConfirm = false;
    this.phaseService.newSelectedAppIds = [];
    this.phaseService.appListApps = [];
  }

  viewAllProblems() {
    this.phaseService.firstClicked = '';
    this.phaseService.newSelectedAppIds = [];
    this.phaseService.appListApps = [];
    this.phaseService.newSelectedProblemsAppsIds = [];
    this.phaseService.newSelectedProblemsApps = [];
    this.phaseService.newSelectedProblemsIds = [];
    // this.router.navigate(['problemsolve']);
  }

  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }


}
