  <div *ngIf="!phaseService.conditionToCreateAndSyncBuildCardToBNow()">
  <!-- <ul>
    <li [ngClass]="phaseService.selectedSectionIndex === 4 ? 'currentSelected':'clickable'"><em
      class="icon-tick"></em><span>5</span></li>
    <li [ngClass]="phaseService.selectedSectionIndex === 3 ? 'currentSelected':'clickable'"
        (click)="handlePaymentoption(3)"><em class="icon-tick"></em><span>4</span></li>
    <li [ngClass]="phaseService.selectedSectionIndex === 2 ? 'currentSelected':'clickable'"
        (click)="handleContractClick(2)"><em class="icon-tick"></em><span>3</span></li>
    <li [ngClass]="phaseService.selectedSectionIndex === 1 ? 'currentSelected':'clickable'"
        (click)="handlePaymentOverViewClick(1)"><em class="icon-tick"></em><span>2</span></li>
    <li [ngClass]="phaseService.selectedSectionIndex === 0 ? 'currentSelected':'clickable'"><em class="icon-tick"></em>
      <span>1</span></li>
  </ul> -->

  <!-- <h2 *ngIf="phaseService.selectedSectionIndex === 0">{{(dataService.newSignUpFlowScreen === 'signup')? 'Sign Up' : 'Login'}}</h2>
  <h2 *ngIf="phaseService.selectedSectionIndex === 1">Payment Overview
    <span>({{phaseService.selectMonthlyPackage ? 'Monthly' : 'One time'}})</span></h2>
  <h2 *ngIf="phaseService.selectedSectionIndex === 2">Account Information</h2>
  <h2 *ngIf="phaseService.selectedSectionIndex === 3">Payment Options</h2>
  <h2 *ngIf="phaseService.selectedSectionIndex === 4">Schedule Kick off Call with a Product Expert</h2> -->

  <div class="leftBottom">
    <div class="imgbx">
      <!-- <img [src]="appDetails ? appDetails?.mobile_cover_image_url : dataService.buildCardData?.progress?.apps[0]?.mobile_cover_image_url"/> -->
      
      <img *ngIf="!smbUser" src="../../../../../../assets/images/studioStotreModalPic.png" alt="" />
      <!-- SMB User Flow -->
      <img *ngIf="smbUser" class="smbUserFlow" src="../../../../../../assets/images/studioStotreModalPic.png" alt="" /><!-- studioStotreModalPicSMB -->
    </div>
    <div class="handFrame"></div>
    <!-- <div class="badgeImg">
      <div>
        3 Months <strong>FREE</strong>
      </div>
    </div> -->
  </div>
</div>

<div class="left-signin-bx" *ngIf="phaseService.conditionToCreateAndSyncBuildCardToBNow()">
<div class="logo-bx"><img src="../../../../../../assets/images/builder-now-logo.png" alt="" /></div>

  <h2 >Sign up to get your <span> free prototype </span></h2>
  <div class="leftBottom">
    <div class="handFrame signupimage2"></div>
  </div>

</div>
