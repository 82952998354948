// tslint:disable:max-line-length
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Constants} from '@shared/utils/constants';
import {ApiRequests} from '@shared/services/api-requests.service';
import { Intercom } from 'ng-intercom';
import { CommonService } from "src/app/shared/shared-module/services/common.service";
import {AppDataService} from '@rootmodule/app-data.service';
import { BuildCardModel } from "src/app/shared/shared-module/models/build-card-model/build-card.model";
import { ReferralService } from '@shared/services/referral.service';
const COMPLETED = 'completed';
const INPROGRESS = 'inprogress';
const PAID = 'paid';
const INVITED = 'invited';
const ARCHIVED = 'archived';
const PROTOTYPE = 'prototype';
const CUSTOM_PROTOTYPE = 'custom_prototype';
const TAILOR_MADE_PROTOTYPE = 'tailor_made_prototype';
const INCOMPLETE = 'incomplete';
const RUNNING = 'running';
const PROJECT_COMPLETED = 'project_completed';
const FREEMIUM = 'freemium';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'dashboard.component.html'
})

export class DashboardComponent implements OnInit {
  public showSpecCalls = true;
  public showDemoCalls = true;
  public showCompleted = true;
  public showInProgress = true;
  public showPaid = true;
  public showRecentSpecCalls = true;
  public showRecentComplete = true;
  public showRecentRunning = true;
  public showRecentProgress = true;
  public showRecentArchived = true;
  public showRecentInvited = true;
  public showTooltip = false;
  public changesSaved = false;
  public user;
  public showMoreIndex = 0;
  public showInvited = true;
  public showArchived = false;
  public showPrototype = true;
  public showCustomPrototype = true;

  public selectedProfileList = [];
  public invitedCard;
  public cardToDelete = null;
  public cardInRenameProgress = null;
  public showSubMenuLoader = true;
  public preventClick = false;
  public showAdditionalFeatures = true;
  showRecentPrototype = true;
  showRecentCompletedProjects = true;
  showRecentCustomPrototype = true;
  public showProjectCompleted = true;
  showFreemiumCards = true;
  showRecentFreemiumCards = true;
  showRecentTailorMadePrototype = true;
  showTailorMadePrototype = true;

  constructor(public dataService: DataService, public phaseService: PhaseService, private router: Router, public apiRequest: ApiRequests,
    public analyticsSegment: Angulartics2Segment, private commonService: CommonService,  public cdRef: ChangeDetectorRef, public appDataService: AppDataService, public referralService: ReferralService) {
    this.dataService.currentPage = 'dashboard';
    this.isFromEtisalatService();
  }
  public detectChanges() {
    this.cdRef.detectChanges();
  }

  public ngOnInit() {
    this.phaseService.handleDashboardLoading.subscribe(() => {
      if (!this.commonService.isPlatformBrowser) {
        return;
      }
      this.dataService.modifiedBuildCardData = undefined;
      this.dataService.buildCardData = new BuildCardModel();
      this.phaseService.isShowApproveChangeModalAfterSecurityPaid = false;
      this.phaseService.paymentData = {custom_prototype: {}, pending_installment: {}, general_info: {}, installments: []};
      this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.dashboard.code);
      if (!this.dataService.homePageData.platforms) {
        this.apiRequest.fetchHomePageData().subscribe((data: any) => {
          this.dataService.dataLoadingCurrency = Object.assign({}, this.dataService.homePageData.currency);
          this.phaseService.platformPrices = Array.apply(null, Array(this.dataService.homePageData.platforms.length));
          this.phaseService.platformPrices.map((x, i) => {
            return i;
          });
          if (this.dataService.homePageData.currency) {
            this.phaseService.specing_price = this.dataService.homePageData.currency.specing_price;
          }
          this.convertDateTimeIntoLocalTimezone();
        });
      }
      if (this.dataService.homePageData.build_phases) {
        this.dataService.homePageData.build_phases.forEach(phase => {
          phase.selected = false;
        });
      }
      this.initializeDashboard();
      this.showSubMenuLoader = !this.dataService.loadFilteredData;
      if (this.appDataService.urlParameters.prototypePayment === 'custom_prototype' && !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling) {
        this.showCards(9);
      }
    });
  }
  convertDateTimeIntoLocalTimezone() {
    this.dataService.paidCards.forEach((card) => {
      this.getCardTime(card);
    });
  }

  isFromEtisalatService() {
    if (this.appDataService.urlParameters.is_etisalat_flow) {
      this.phaseService.openCommonAuth = false;
      this.phaseService.showRegister = false;
      const refresh = window.sessionStorage.getItem('refresh1');
      if (refresh === null) {
        setTimeout(() => {
          window.location.reload();
          window.sessionStorage.setItem('refresh1', '1');
        }, 1000);
      }
    }
  }

  getCardTime(card) {
    const startTime = moment.unix((new Date(card.scheduled_date + ' ' + card.scheduled_call_start + ' GMT')).getTime() / 1000).format('h:mm A');
    card.scheduled_date = startTime;

  }
  public initializeDashboard() {
    setTimeout(() => {
      this.phaseService.dashboardOpen = true;
      this.phaseService.isDashboardShow = true;
    });
    if (!this.dataService.user && this.appDataService.urlParameters.is_etisalat_flow) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    if(this.dataService.user.enable_referral_code){
      this.referralService.getTransactionDataForReferralWallet();
    }
    this.user = this.dataService.user;
    this.phaseService.trackEvent(this.analyticsSegment, 'dashboard_page_visited', 'user_id', this.dataService.user.id);
    for (let i = 13; i > 0; i--) {
      this.getCardsData(i);
    }

    this.phaseService.breadcrumb_links = [];

    this.phaseService.parseUserProperties({ 'number_of_inProgressCards': this.dataService.inProgressCards.length });
    this.phaseService.parseUserProperties({ 'number_of_completedCards': this.dataService.completedCards.length });
    if (this.phaseService.comingFromProjectProgress) {
      this.showPaymentStatus(this.phaseService.currentCardInProgress);
      this.phaseService.comingFromProjectProgress = false;
      this.phaseService.currentCardInProgress = null;
    }
  }

  public exitPaymentStatus() {
    this.phaseService.showPaymentStatus = false;
    this.phaseService.paymentBuildCard = null;
  }

  public getCardsData(index) {
    switch (index) {
      case 1:
        this.getClickedMenuBarData('card_completed');
        if (this.dataService.activeAdditionalFeatureRequestCard) {
          this.showCards(7);
          this.dataService.activeAdditionalFeatureRequestCard = false;
        }
        break;
      case 2:
        this.getClickedMenuBarData('card_initialized');
        break;
      case 3:
        this.getClickedMenuBarData('started,under_development,on_hold');
        break;
      case 4:
        this.getClickedMenuBarData(INVITED);
        break;
      case 5:
        this.getClickedMenuBarData(ARCHIVED);
        break;
      case 6:
        this.phaseService.currentSelectedBuildCardStatus = 'spec_calls';
        this.apiRequest.fetchBuildCards(0, 10, this.phaseService.currentSelectedBuildCardStatus).subscribe((data: any) => {
          this.dataService.isScrolled = true;
        });
        break;
      case 7:
        this.phaseService.currentSelectedBuildCardStatus = 'additional_features'; /*/change_requests*/
        this.apiRequest.fetchBuildCardForReqestedFeatures().subscribe((resp) => {
        });
        break;
      case 9:
        this.getClickedMenuBarData(CUSTOM_PROTOTYPE);
        break;
      case 10:
        this.getClickedMenuBarData(PROTOTYPE);
        break;
      case 11:
        this.getClickedMenuBarData(COMPLETED);
        break;
      case 12:
        this.getClickedMenuBarData(FREEMIUM);
        break;
      case 13:
        this.getClickedMenuBarData(TAILOR_MADE_PROTOTYPE);
        break;
      case 14:
        this.phaseService.currentSelectedBuildCardStatus = 'spec_calls';
        this.apiRequest.fetchBuildCards(0, 10, this.phaseService.currentSelectedBuildCardStatus).subscribe((data: any) => {
          this.dataService.isScrolled = true;
        });
        break;
      default:
        break;
    }
  }

  getClickedMenuBarData(menuBarName) {
    this.phaseService.currentSelectedBuildCardStatus = menuBarName;
    this.phaseService.loadDashBoardData(this.analyticsSegment, this.phaseService.currentSelectedBuildCardStatus);
  }

  public showCards(index) {
    if (typeof window !== 'undefined') {
      window.scroll(0, 0);
    }
    this.changesSaved = false;
    this.showMoreIndex = index;
    if (!this.phaseService.buildCardPage[index] || this.phaseService.buildCardPage[index]) {
      this.phaseService.buildCardPage[index] = 0;
    }
    if (index > 0) {
      this.getCardsData(index);
    }
    switch (index) {
      case 0:
        this.showCompleted = true;
        this.showInProgress = true;
        this.showPaid = true;
        this.showRecentComplete = true;
        this.showRecentRunning = true;
        this.showRecentPrototype = true;
        this.showRecentCompletedProjects = true;
        this.showRecentCustomPrototype = true;
        this.showRecentTailorMadePrototype = true;
        this.showRecentProgress = true;
        this.showTailorMadePrototype = true;
        this.showSpecCalls = true;
        this.showDemoCalls = true;
        this.showInvited = true;
        this.showArchived = false;
        this.showRecentArchived = false;
        this.showRecentInvited = true;
        this.showRecentSpecCalls = true;
        this.showAdditionalFeatures = true;
        this.dataService.showPaymentCards = false;
        this.showPrototype = true;
        this.showCustomPrototype = true;
        this.showProjectCompleted = true;
        this.exitPaymentStatus();
        break;
      case 1:
        this.handleMenuBarClicking(COMPLETED);
        break;
      case 2:
        this.handleMenuBarClicking(INPROGRESS);
        break;
      case 3:
        this.handleMenuBarClicking(PAID);
        break;
      case 4:
        this.handleMenuBarClicking(INVITED);
        break;
      case 5:
        this.handleMenuBarClicking(ARCHIVED);
        break;
      case 6:
        this.handleMenuBarClicking('showSpecCalls');
        break;
      case 7:
        this.handleMenuBarClicking('showAdditionalFeatures');
        break;
      case 8:
        this.handleMenuBarClicking('showPaymentCards');
        break;
      case 9:
        this.handleMenuBarClicking(CUSTOM_PROTOTYPE);
        break;
      case 10:
        this.handleMenuBarClicking(PROTOTYPE);
        break;
      case 11:
        this.handleMenuBarClicking(PROJECT_COMPLETED);
        break;
      case 12:
        this.handleMenuBarClicking(FREEMIUM);
        break;
      case 13:
        this.handleMenuBarClicking(TAILOR_MADE_PROTOTYPE);
        break;
      case 14:
        this.handleMenuBarClicking('showDemoCalls');
        break;
      default:
        break;
    }
    this.phaseService.isProfile = false;
    this.referralService.showReferralWallet = false;
    this.phaseService.showTaxAndBilling = false;
  }

  handleMenuBarClicking(sectionName) {
    if (this.getCardCount(sectionName) === 0) {
      return;
    }
    this.dataService.loadMore = true;
    this.showCompleted = (sectionName === COMPLETED) ? true : false;
    this.showInProgress = (sectionName === INPROGRESS) ? true : false;
    this.showPaid = (sectionName === PAID) ? true : false;
    this.showRecentComplete = (sectionName === 'showRecentComplete') ? true : false;
    this.showRecentRunning = (sectionName === 'showRecentRunning') ? true : false;
    this.showRecentProgress = (sectionName === 'showRecentProgress') ? true : false;
    this.showInvited = (sectionName === INVITED) ? true : false;
    this.showArchived = (sectionName === ARCHIVED) ? true : false;
    this.showRecentSpecCalls = (sectionName === 'showRecentSpecCalls') ? true : false;
    this.showRecentArchived = (sectionName === 'showRecentArchived') ? true : false;
    this.showRecentInvited = (sectionName === 'showRecentInvited') ? true : false;
    this.showSpecCalls = (sectionName === 'showSpecCalls') ? true : false;
    this.showDemoCalls = (sectionName === 'showDemoCalls') ? true : false;
    this.showAdditionalFeatures = (sectionName === 'showAdditionalFeatures') ? true : false;
    this.dataService.showPaymentCards = (sectionName === 'showPaymentCards') ? true : false;
    this.showPrototype = (sectionName === PROTOTYPE) ? true : false;
    this.showCustomPrototype = (sectionName === CUSTOM_PROTOTYPE) ? true : false;
    this.showRecentPrototype = false;
    this.showRecentCompletedProjects = false;
    this.showRecentCustomPrototype = false;
    this.showRecentTailorMadePrototype = false;
    this.showProjectCompleted = (sectionName === PROJECT_COMPLETED) ? true : false;
    this.showFreemiumCards = (sectionName === FREEMIUM) ? true : false;
    this.showTailorMadePrototype = (sectionName === TAILOR_MADE_PROTOTYPE) ? true : false;
    this.exitPaymentStatus();
  }

  public getCardCount(type) {
    if (this.dataService.buildCardCountData) {
      switch (type) {
        case COMPLETED:
          return this.dataService.buildCardCountData[COMPLETED];
        case INPROGRESS:
          return this.dataService.buildCardCountData[INCOMPLETE];
        case PAID:
          return this.dataService.buildCardCountData[RUNNING] || this.dataService.paidCards.length;
        case INVITED:
          return this.dataService.buildCardCountData[INVITED];
        case ARCHIVED:
          return this.dataService.buildCardCountData[ARCHIVED];
        case PROTOTYPE:
          return this.dataService.buildCardCountData[PROTOTYPE];
        case CUSTOM_PROTOTYPE:
          return this.dataService.buildCardCountData[CUSTOM_PROTOTYPE];
        case PROJECT_COMPLETED:
          return this.dataService.buildCardCountData[PROJECT_COMPLETED];
        case TAILOR_MADE_PROTOTYPE:
          return this.dataService.buildCardCountData[TAILOR_MADE_PROTOTYPE];
      }
    } else {
      return 0;
    }
  }

  public showPaymentStatus(card) {
    this.phaseService.paymentBuildCard = card;
    this.phaseService.currencySymbol = this.dataService.user.currency.symbol;
    this.fetchInstallmentsForCard(card.id, card.phase_installment_paid ? card.build_card_phases !== null ? card.build_card_phases[0].id : null : null);
  }

  public proceedForPhasePayment(card) {
    this.dataService.buildCardData = card;
    this.phaseService.paidPhaseId = card.build_card_phases !== null ? card.build_card_phases[0].id : '';
    this.phaseService.inProgressBuildCardId = card.id;
    this.phaseService.inProgressUniqCode = card.uniq_code;
    this.phaseService.navigateNormally = true;
    this.phaseService.currencySymbol = this.dataService.user.currency.symbol;
    this.phaseService.breadcrumb_links = [];
    this.router.navigate([`buildcard-payment/${card.id}`]);

  }

  public fetchInstallmentsForCard(cardId, phaseId?) {
    this.dataService.showHideLoader(true);
    this.phaseService.supportMaintenance = 0;
    this.apiRequest.fetchInstallments(cardId, phaseId).subscribe((data: any) => {
      this.setPaymentData(data);
      this.phaseService.showPaymentStatus = true;
      this.dataService.showHideLoader(false);
    }, (error) => {
      this.dataService.showHideLoader(false);
    });
  }

  public setPaymentData(data: any) {
    if (data) {
      this.phaseService.paymentData = data;
      this.phaseService.installments = this.phaseService.paymentData.installments.filter((installment) => {
        return installment.type === 'installment';
      });

      this.phaseService.deposit = this.phaseService.paymentData.installments.find(installmemt => {
        return installmemt.type === 'deposit';
      });

      const supportIndex = this.phaseService.paymentData.installments.findIndex(installment => {
        return installment.type === 'support';
      });

      if (supportIndex > -1) {
        this.phaseService.supportMaintenance = this.phaseService.paymentData.installments[supportIndex];
        this.phaseService.paymentData.installments.splice(supportIndex, 1);
        this.phaseService.paymentData.installments.push(this.phaseService.supportMaintenance);
      }

    }
  }

  public showDeleteConfirmation(card) {
    this.cardToDelete = card;
  }

  public startPayment() {
    this.phaseService.billingInfo.firstname = this.dataService.user.first_name;
    this.phaseService.billingInfo.lastname = this.dataService.user.last_name;
    this.phaseService.billingInfo.email = this.dataService.user.email;
    this.router.navigate([`buildcard-payment/${this.dataService.buildCardData.id}`, 'confirmation']);
  }

  public showRenameCard(card) {
    this.dataService.renameInProgress = true;
    this.cardInRenameProgress = card;
  }

  public selectUserProfile(profile) {
    const index = this.selectedProfileList.indexOf(profile);
    if (index === -1) {
      this.selectedProfileList.push(profile);
    } else {
      this.selectedProfileList.splice(index, 1);
    }
  }

  public changePreventClick(flage) {
    this.preventClick = flage;
  }

  public pshowTooltip(flage) {
    this.showTooltip = flage;
  }
}

