<div class="templateDetailPage" *ngIf="!phaseService.showTemplateLoader">
    <div class="detailSection">
        <div class="detailContent visibleOnlyMobile">
            <h2><img src="{{dataService.appDetails?.app_builder_icon_url}}" alt="" /> {{dataService.appDetails?.title}}</h2>
            <div class="SubDetail"> {{dataService.appDetails?.features.length}} features included</div>
            <div class="discriptionContent" *ngIf="dataService.appDetails && dataService.appDetails?.description">
                {{dataService.appDetails?.description}}
            </div>
        </div>
        <div class="detailSlider">
            <div class="sticky">
                <div class="slider-privew">
                    <em class="left-arrow" *ngIf="selectedImageIndex > 0 && selectedImageIndex < screenshots.length" (click)="changeSelectedImage('previous')"></em>
                    <div class="deviceFrame mobile" *ngIf="selectedPreviewPlatform === 'android'">
                        <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
                        <img src="{{selectedImage}}" class="featureImg" alt="" />
                    </div>
                    <div class="deviceFrame desktop" *ngIf="selectedPreviewPlatform === 'web'">
                        <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
                        <img src="{{selectedImage}}" class="featureImg" alt="" />
                    </div>
                    <em class="right-arrow" *ngIf="selectedImageIndex <  screenshots.length-1" (click)="changeSelectedImage('next')" ></em>
                </div>
                <div class="info-block-wrapper">
                    <span class="show-count">{{ screenshots.length ? selectedImageIndex + 1 : 0}}/{{screenshots.length}}</span>
                    <span>{{this.dataService.appDetails.features[selectedImageIndex].title}} <em class="icon-info-circle" (click)="displayInfoblock()"></em></span>
                    <div class="discription-block" *ngIf="activityFeed">
                        {{dataService.appDetails.features[selectedImageIndex].description}}
                    </div>
                    <div class="tooltipOverlay" *ngIf="activityFeed" (click)="displayInfoblock()"></div>
                </div>
                <div class="viewSelection">
                    <ul>
                        <li [ngClass]="{active : selectedPreviewPlatform === 'android'}">
                        <div class="viewIcon" (click)="toggleLayoutView('android')"><em class="icon-mobiledevice"></em></div>
                        </li>
                        <li [ngClass]="{active : selectedPreviewPlatform === 'web'}">
                        <div class="viewIcon" (click)="toggleLayoutView('web')"><em class="icon-desktopdevice"></em></div>
                        </li>
                    </ul>
                </div>
                <div class="slider-thumbnil-wrapper" [ngClass]="{'alignment': totalImageSetCount > 1}">
                    <button type="button" class="prevNav" *ngIf="imageSetCount > 0 && imageSetCount < totalImageSetCount" (click)="changeImageSet('previous')">
                        <span></span>
                    </button>
                    <ul class="slider-thumbnil">
                        <li [ngClass]="{'active': selectedImageIndex % imageSet === index}"  *ngFor="let image of imagesToShow; let index = index" (click)="selectImage(index)" >
                            <div class="deviceFrame mobile" *ngIf="selectedPreviewPlatform === 'android'">
                                <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
                                <img src="{{image}}" class="featureImg" alt="" />
                            </div>
                            <div class="deviceFrame desktop" *ngIf="selectedPreviewPlatform === 'web'">
                                <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
                                <img src="{{image}}" class="featureImg" alt="" />
                            </div>
                        </li>
                    </ul>
                    <button type="button" class="nextNav" *ngIf="imageSetCount < totalImageSetCount-1" (click)="changeImageSet('next')">
                        <span></span>
                    </button>

                </div>
                <div class="slider-info">
                    <p>These designs are representative of the features included in the app.</p>
                    <p>They do not show your finished product. You can customise how everything looks with our network of designers.</p>
                </div>
            </div>
        </div>
        <div class="detailContent">
            <div class="hideOnMobile" id='imageContainer{{dataService.appDetails.id}}'>
                <h2><img src="{{dataService.appDetails?.app_builder_icon_url}}" alt="" /> {{dataService.appDetails?.title}}</h2>
                <div class="SubDetail"> {{dataService.appDetails?.features.length}} features included</div>
                <div class="discriptionContent" *ngIf="dataService.appDetails && dataService.appDetails?.description">
                    {{dataService.appDetails?.description}}
                </div>
            </div>
            <!----Pricing box start---->
            <div class="pricingBox">
                <div class="tagTitle"><span class="labelSmall">From </span></div>
                <!-- <div class="tagTitle"><span class="labelSmall">from <span>XYZ</span> per platform </span></div> -->
                <div class="pricingRow">
                    <strong>
                      {{dataService.getCurrency()}}{{dataService.getLocaledPrice(dataService?.appDetails?.template_price | number: '1.0-0')}}
                    </strong>
                    <span>per platform</span>
                </div>
                <!--<div class="threeDotLoaderContainer priceBox" *ngIf="phaseService.priceLoader">
                    <div class="threeDotloader"><div class="dot-flashing"></div></div>
                </div>-->
            </div>
            <!----Pricing box close---->

            <!----Configurable options box start---->
            <div class="configurableSection">
                <div class="title-wrapper">
                <div class="platformAvilablity"><span class="labelSmall">PLATFORMS AVAILABLE</span>
                    <em class="icon-info-circle" (click)="displayPlatformAvilability()"></em></div>
                <div class="discription-block" *ngIf="platformAvilability">Which platform? <br/>Choose whether you want your app available for iPhone and iPad users or Android devices – or both. <br/><br/>What are Progressive Web Apps? <br/>A responsive website, which your customers can access seamlessly on any device of every screen size.</div>
                    <div class="tooltipOverlay" *ngIf="platformAvilability" (click)="displayPlatformAvilability()"></div>
                </div>
                <div class="includeBox platforms">
                    <!-- <h4>Platforms</h4> -->
                    <div class="detailRow">
                        <div class="imgSection">
                            <img src="../../../../../assets/images/mobile.svg" alt="" />
                        </div>
                        <div class="textSection">
                            <p>iOS app for Apple (iPhones)</p>
                        </div>
                    </div>
                    <div class="detailRow">
                        <div class="imgSection">
                            <img src="../../../../../assets/images/mobile.svg" alt="" />
                        </div>
                        <div class="textSection">
                            <p>Android app (for Android phones)</p>
                        </div>
                    </div>
                    <div class="detailRow">
                        <div class="imgSection">
                            <img src="../../../../../assets/images/progressiveIcon.svg" alt="" />
                        </div>
                        <div class="textSection">
                            <p>Progressive Web App for desktop and mobile</p>
                        </div>
                    </div>
                </div>

                <div class="includeBox">
                    <h4>
                        <span class="logo-image">
                            <img src="../../../../../assets/images/studio_one.png" />
                        </span>
                        <!-- <span class="blueTag graytxt">Available from {{ dataService.getCurrency()}}{{dataService.getLocaledPrice(phaseService?.supportMaintenance)}} /month</span> -->
                        <span class="blueTag graytxt">Included for 12 months</span>
                    </h4>
                    <div class="detailRow">
                        <div class="textSection">
                          <p>
                            Your app price includes Studio One for 1 year, which unlocks a dashboard to monitor your build in
                            real-time – approve designs, give feedback and make changes. It’s our way of ensuring ongoing success
                            with your new app. You also get expert support throughout your build and beyond.
                          </p>
                          <p>
                            Success built-in to every app
                          </p>
                          <ul>
                            <ng-container>
                              <li>Ongoing expert support</li>
                              <li>Collaboration tools to manage your project</li>
                              <li>24/7 uptime monitoring</li>
                              <li>Third-party API updates </li>
                              <li>iOS and Android platform updates </li>
                              <li>Security patches & bug fixes</li>
                              <li>Updated tech stack</li>
                            </ng-container>
                          </ul>
                        </div>
                    </div>
                </div>

                <div class="includeBox">
                    <h4><img src="../../../../assets/images/Builder_Cloud_Blue.svg" height="20" alt=""/><span class="blueTag graytxt">Available from {{ dataService.getCurrency()}}{{dataService.getLocaledPrice(builderCloudPrice() | number: '1.0-0')}} /month</span></h4>
                    <div class="detailRow">
                        <div class="textSection">
                            <p>Cloud to help you run your app is included in this price. It’s topped up each month. <a (click)="navigateToCloudLinks()" href="https://www.builder.ai/builder-cloud" target="_blank" rel="noopener">Learn more</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <!----Configurable options box close---->
        </div>
    </div>


    <div class="sticky-bottom-bar" id="stickyBar">
        <div class="left-block" *ngIf="dataService.currentPage === 'templateDetail' && phaseService.selectedApps.length > 0">
            <img src="{{dataService.appDetails?.app_builder_icon_url}}" alt="" />
            <div class="link-block" title="Clear selection" (click)="clearTemplateSelections()">Clear selection</div>
        </div>
        <div class="priceFrom visibleOnlyMobile">
            <div class="labelFrom">From</div>
            <!-- <div class="labelFrom">from <span>XYZ</span> per platform</div> -->
            <div class="labelPrice"><strong>{{dataService.getCurrency()}}{{dataService.getLocaledPrice(dataService?.appDetails?.template_price | number: '1.0-0')}}</strong></div>
        </div>
        <div class="clear-left"></div>
        <div class="btn-wrapper">
            <div class="textLinks" *ngIf="dataService.currentPage === 'templateDetail' && phaseService.selectedApps.length > 0">
            <span> <div>{{phaseService.selectedApps.length}}/3 templates  selected</div> </span>
            <a class="link-block" (click)="gotoHome()" rel="noopener">Add more templates</a>
            </div>
            <button type="button" class="AddTemplateButton" title="Build now" *ngIf="dataService.currentPage == 'templateDetail' && phaseService.selectedApps.length > 0 && checkActive(dataService.appDetails)" (click)="buildNow()" >Build now</button>
            <button type="button" class="AddTemplateButton" title="Add template" *ngIf="!checkActive(dataService.appDetails)" (click)="homeTemplateService.selectTemplate(dataService.appDetails, true)">Add template<template></template></button>
            <button type="button" class="removeTemplateButton" title="remove template" *ngIf="checkActive(dataService.appDetails) && dataService.currentPage !== 'templateDetail'" (click)="homeTemplateService.selectTemplate(dataService.appDetails)">Remove template</button>
        </div>
    </div>
</div>
<!-- Product skeleton loading screen -->
<div class="productModalLoadingScreen" *ngIf="phaseService.showTemplateLoader" [ngClass]="{'detailPage': !dataService.isShowTemplateModal}">
    <div class="appdetailSectionLoading">
        <!-- For Desktop View
        <div class="imageBlock" [ngClass]="{'desktop': !dataService.isShowTemplateModal}"> -->
        <div class="imageBlock" >
            <div class="mobileImage"></div>
            <div class="previewTitle"></div>
            <ul class="thumbnailList">
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
            </ul>
            <div class="scrollbar">
                <div><span></span></div>
                <div><span></span></div>
            </div>
        </div>
        <div class="descriptionBlock">
            <h2></h2>
            <ul class="labels">
                <li></li>
            </ul>
            <p class="secDescription"></p>
            <p class="secDescription2"></p>
            <p class="secDescription3"></p>

            <div class="platforms">
                <div class="circle"></div>
                <div class="content">
                    <div class="title"></div>
                    <div class="description">
                        <div class="block1"></div>
                        <div class="block2"></div>
                    </div>
                </div>
            </div>
            <div class="platforms">
                <div class="circle"></div>
                <div class="content">
                    <div class="title"></div>
                    <div class="description">
                        <div class="block1"></div>
                        <div class="block2"></div>
                    </div>
                </div>
            </div>
            <div class="btnBox"></div>

            <div class="whatsInclude">
                <div class="platformsBox">
                    <div class="platformInclude"></div>
                    <div class="device"></div>
                </div>
                <div class="deviceDescription">
                    <div class="deviceImage"></div>
                    <div class="deviceName"></div>
                </div>
                <div class="deviceDescription">
                    <div class="deviceImage"></div>
                    <div class="deviceName"></div>
                </div>
            </div>
            <div class="whatsInclude brandLogo">
                <div class="platformsBox">
                    <div class="platformInclude"></div>
                    <div class="device"></div>
                </div>
                <div class="platformsBox bottom">
                    <div class="platformInclude"></div>
                </div>
                <div class="deviceDescription">
                    <div class="deviceImage"></div>
                    <div class="deviceName">
                        <div class="brandDesc"></div>
                        <div class="brandDesc bottom"></div>
                    </div>
                </div>
            </div>
            <div class="whatsInclude brandLogo">
                <div class="platformsBox">
                    <div class="platformInclude"></div>
                    <div class="device"></div>
                </div>
                <div class="platformsBox bottom">
                    <div class="platformInclude"></div>
                </div>
                <div class="deviceDescription">
                    <div class="deviceImage"></div>
                    <div class="deviceName">
                        <div class="brandDesc"></div>
                        <div class="brandDesc bottom"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bottomBar">
        <span class="textBox"></span>
        <div class="buttonBox"></div>
    </div>
</div>
<!-- Product skeleton loading screen -->
