<!-- Buildcard™ screen start -->
<div class="freemium" id="studioStore">
  <!--preview section start-->
  <div class="freemiumPrev" #studioStorePrev>
    <div class="freemiumLeft onlyweb">
      <div class="detailSlider">
        <!-- Slider -->
        <div class="sticky">
          <div class="slider-privew">
            <em class="left-arrow" *ngIf="selectedImageIndex > 0 && selectedImageIndex < screenshots.length" (click)="changeSelectedImage('previous')"></em>
            <div class="deviceFrame mobile" *ngIf="selectedPreviewPlatform === 'android'">
              <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
              <img src="{{selectedImage}}" class="featureImg" alt="">
            </div>
            <div class="deviceFrame desktop" *ngIf="selectedPreviewPlatform === 'web'">
              <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
              <img src="{{selectedImage}}" class="featureImg" alt="">
            </div>
            <em class="right-arrow" *ngIf="selectedImageIndex <  screenshots.length-1" (click)="changeSelectedImage('next')"></em>
          </div>
          <div class="info-block-wrapper">
            <span class="show-count">{{ screenshots.length ? selectedImageIndex + 1 : 0}}/{{screenshots.length}}</span>
            <span class="infoTitle"> {{ dataService.appDetails.features[selectedImageIndex].title }} <em class="icon-info-circle" (click)="displayInfoblock()"></em></span>
            <div class="discription-block" *ngIf="activityFeed">
              {{ dataService.appDetails?.features[selectedImageIndex]?.description }}
            </div>
            <div class="tooltipOverlay" *ngIf="activityFeed" (click)="displayInfoblock()"></div>
          </div>
          <div class="viewSelection" *ngIf="selectedPlatform == 'all'">
            <ul>
              <li [ngClass]="{active : selectedPreviewPlatform === 'android'}">
                <div class="viewIcon" (click)="toggleLayoutView('android')"><em class="icon-mobiledevice"></em></div>
              </li>
              <li [ngClass]="{active : selectedPreviewPlatform === 'web'}">
                <div class="viewIcon" (click)="toggleLayoutView('web')"><em class="icon-desktopdevice"></em></div>
              </li>
            </ul>
          </div>
          <div class="slider-thumbnil-wrapper">
            <button type="button" class="prevNav" *ngIf="imageSetCount > 0 && imageSetCount < totalImageSetCount" (click)="changeImageSet('previous')">
              <span></span>
              </button>
              <ul class="slider-thumbnil">
                  <li class="active" [ngClass]="{'active': selectedImageIndex % imageSet === index}" *ngFor="let image of imagesToShow; let index = index"
                      (click)="selectImage(index)">
                      <div class="deviceFrame mobile" *ngIf="selectedPreviewPlatform === 'android'">
                        <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
                        <img src="{{image}}" class="featureImg" alt="">
                      </div>
                      <div class="deviceFrame desktop" *ngIf="selectedPreviewPlatform === 'web'">
                        <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
                        <img src="{{image}}" class="featureImg" alt="">
                      </div>
                  </li>
              </ul>
              <button type="button" class="nextNav" *ngIf="imageSetCount < totalImageSetCount-1" (click)="changeImageSet('next')">
                  <span></span>
              </button>
          </div>
          <div class="slider-info">
            <p>These designs are representative of the features included in the app.</p>
            <p>They do not show your finished product. You can customise how everything looks with our network of designers.</p>
          </div>
        </div>
        <!-- Slider end -->
      </div>

      <!-- Old freemium slider
      <div class="freemiumSlider">
        <drag-scroll class="sliderHeight" (reachesLeftBound)="leftBoundStat($event,'imageSlider')" (reachesRightBound)="rightBoundStat($event,'imageSlider')"
                     #navImageSlider>
          <div class="freemiumSlide" drag-scroll-item *ngFor="let url of dataService.appDetails.screenshots">
            <img src="{{url}}" alt="" />
          </div>
        </drag-scroll>
        <button class="dragbutton leftArrow" [disabled]="leftImageNavDisabled" type="button" (click)="moveLeft('imageSlider')"></button>
        <button class="dragbutton rightArrow" [disabled]="rightImageNavDisabled" type="button" (click)="moveRight('imageSlider')"></button>
      </div>
      <div class="makeOwn">
        <h3>Make it your own</h3>
        <p>This is just an illustration. The finished product will be in your branding — with your logo and colours.</p>
      </div>
      -->
    </div>

    <div class="freemiumRight">
      <div #rightTopSection>
        <h2>{{dataService.appDetails?.title}}</h2>
        <div class="featuresCounter">
          <span class="text">{{ dataService.appDetails?.feature_count }} features included</span>
        </div>
        <div class="tagsRow">
          <span *ngFor="let name of dataService.appDetails.category_names">  {{name}} </span>
        </div>

        <div class="discriptionContent">
            <span *ngIf="dataService.appDetails && dataService.appDetails?.description">
                {{dataService.appDetails.description }} </span>
        </div>
      </div>
      <!---- only show mobile slider -->
      <div class="freemiumLeft onlymobile mobilesliderui">
        <div class="detailSlider">
          <!--Slider goes here! as same as for web-->
        </div>
        <!--
        <div class="freemiumSlider">
          <drag-scroll class="sliderHeight" (reachesLeftBound)="leftBoundStat($event,'mobileImageSlider')" (reachesRightBound)="rightBoundStat($event,'mobileImageSlider')"
                       #navImageSliderMobile>
            <div class="freemiumSlide" drag-scroll-item *ngFor="let url of dataService.appDetails.screenshots">
              <img src="{{url}}" alt="" />
            </div>
          </drag-scroll>
          <button class="dragbutton leftArrow" [disabled]="leftMobileImageNavDisabled" type="button" (click)="moveLeft('mobileImageSlider')"></button>
          <button class="dragbutton rightArrow" [disabled]="rightMobileImageNavDisabled" type="button" (click)="moveRight('mobileImageSlider')"></button>
        </div>
        <div class="makeOwn">
          <h3>Make it your own</h3>
          <p>The interface is for illustration purposes and not representative of your finished product, you'll be
            able to customise your UI...</p>
        </div>
        -->
      </div>

      <div class="includeSection">
        <h3>What's included</h3>
        <div class="pricingBox">
          <div class="tagTitle"><span class="labelSmall">For </span></div>
          <div class="pricingRow"><strong>Free </strong></div>
          <ul>
            <li>{{getOrderLimit()}} orders a month </li>
          </ul>
        </div>

        <div class="includeBox">
          <h4>Platforms included: {{selectedPlatform == 'app'? "Just apps" : selectedPlatform == 'web'? "Just Web"
              : "Web + Apps"}}
              <!-- <span class="blueTag" (click)="openChangePlatformModal('top')">Change</span> -->
          </h4>
          <div class="detailRow">
              <div class="textSection platforms">
                  <ng-container *ngIf="selectedPlatform == 'app' || selectedPlatform == 'all'">
                    <p><img class="iconMobile" width="17" src="../../../../../assets/images/icon-apple-32.svg" alt="" /> <span>Apple iOS mobile app</span></p>
                    <p><img class="iconMobile" width="17" src="../../../../../assets/images/icon-android.svg" alt="" /> <span>Google Android mobile app</span></p>
                  </ng-container>
                  <ng-container *ngIf="selectedPlatform == 'web' || selectedPlatform == 'all'">
                    <p class="text"><img class="iconMobile" width="17" src="../../../../../assets/images/icon-desktop-responsive.svg" alt="" /> <span>Responsive website – optimised for desktop, laptop and mobile screens</span></p>
                  </ng-container>
              </div>
          </div>
        </div>

        <div class="includeBox">
          <h4>Builder Care</h4>
          <div class="detailRow">
              <div class="imgSection"><img src="../../../../../assets/images/careinclude.svg" alt="" /></div>
              <div class="textSection">
                  <p>Our aftercare warantee updates, upgrades and keeps your app running smoothly.</p>
              </div>
          </div>
        </div>

        <div class="includeBox">
            <h4>Builder Cloud</h4>
            <div class="detailRow">
                <div class="imgSection"><img src="../../../../../assets/images/cloudinclude.svg" alt="" /></div>
                <div class="textSection">
                    <p>Best-in-class multi-cloud: Azure, AWS, and more. Just one bill (for a lot less).</p>
                </div>
            </div>
        </div>

        <!----Configurable options box start---->
        <!--
        <div class="configurableSection">
          <div class="title-wrapper">
            <div class="platformAvilablity"><span class="labelSmall">PLATFORMS AVAILABLE</span></div>
            <div class="tooltipOverlay"></div>
          </div>
          <div class="includeBox platforms">
              <div class="detailRow" *ngFor="let platform of dataService.appDetails.platforms">
                  <div class="imgSection">
                      <img *ngIf="platform.title === 'iOS'" src="../../../../../assets/images/icon-apple-32.svg" alt="">
                      <img *ngIf="platform.title === 'Android'" src="../../../../../assets/images/icon-android.svg" alt="">
                      <img *ngIf="(platform.title !== 'Android') && (platform.title !== 'iOS')" src="../../../../../assets/images/icon-desktop-responsive.svg" alt="">
                  </div>
                  <div class="textSection">
                      <p *ngIf="platform.title === 'iOS'">iOS app for Apple (iPhones)</p>
                      <p *ngIf="platform.title === 'Android'">Android app (for Android phones)</p>
                      <p *ngIf="(platform.title !== 'Android') && (platform.title !== 'iOS')">Responsive website – optimised for desktop, laptop and mobile screens</p>
                  </div>
              </div>
          </div>
          <div class="includeBox">
              <h4>
                <span class="builder-wrapper">
                  <span class="labelSmall">Builder Care
                  </span>
                </span>
							</h4>
              <div class="detailRow">
                  <div class="imgSection"><img src="../../../../../assets/images/careinclude.svg" alt="" /></div>
                  <div class="textSection"><p>Our aftercare warantee updates, upgrades and keeps your app running smoothly.</p></div>
              </div>
          </div>
          <div class="includeBox">
              <h4><span class="labelSmall">Builder Cloud </span></h4>
              <div class="detailRow">
                  <div class="imgSection"><img src="../../../../../assets/images/cloudinclude.svg" alt="" /></div>
                  <div class="textSection">
                      <p>Cloud to help you run your app is included in this price. It’s topped up each month.</p>
                  </div>
              </div>
          </div>
        </div>
        -->

      </div>
      <!-- --------------- -->

      <!--
      <div class="buttonsSection">
        <button type="button" class="paymentButton" [disabled]="isBuildCardUpdating"
                (click)="payNowForRental(dataService.appDetails, 'Top')">Proceed
          <span class="loaderAnimation fiveDotloader" *ngIf="isBuildCardUpdating">
            <span></span><span></span><span></span>
          </span>
        </button>
      </div>
      -->

    </div>
  </div>
  <!--preview section close-->

  <!--
  <div class="freemium-container">
    <div class="sectionBx sliderSectionMain">
      <div class="headerBlock">
        <h3 class="heading">Your {{dataService.appDetails?.feature_count}} {{dataService.appDetails?.feature_count > 1 ? 'Features' : 'Feature'
          }} explained</h3>
        &lt;!--<p class="subheading"> </p>--&gt;
      </div>
    </div>
    &lt;!--- slider ---&gt;
    <div class="sliderBlockmain">

      <div class="appfeatureSlider">
        <div class="slideHolder">
          &lt;!--- overlayBg --&gt;
          <drag-scroll (reachesLeftBound)="leftBoundStat($event,'featureSlider')" (reachesRightBound)="rightBoundStat($event,'featureSlider')" #navFeatureSlider>
            <div class="appfeatureBox" (click)="openFeaturePopup(feature, index)" drag-scroll-item *ngFor="let feature of dataService.appDetails.features; let index = index" (mouseenter)="setInfoPositionOnFeature()" (mouseleave)="isFeatureHoverActive = false" >

              &lt;!-- manin box ---&gt;
              <div class="infoBlock">
                <span class="infotxt"><em class="icon-info-circle"></em> {{feature.title}}</span>
                <div class="infordetailBx">
                  <h3 class="title leftPosition"> {{feature.title}} </h3>
                  <p class="descriptionTxt">
                    {{feature.description}}
                  </p>
                </div>
              </div>

              <div class="frameBox">
                <div class="imageFrame">
                  <ng-container *ngIf="feature.feature_screenshots?.android">
                    <img src="{{feature.feature_screenshots?.android}}" alt="" />
                  </ng-container>
                  <ng-container *ngIf="!feature.feature_screenshots?.android">
                    <img src="./../../../../../assets/images/notavailable_mobile.png" alt="" />
                  </ng-container>
                </div>
              </div>

            </div>
          </drag-scroll>
          &lt;!-- next prevoius button --&gt;
          <button class="dragbutton leftArrow" [disabled]="leftFeatureNavDisabled" type="button" (click)="moveTo('left')">
            <span class="arrowbx left-arrow"> <img src="../../../../../assets/images/sliderArrow.svg" alt="" /> </span>
          </button>
          <button class="dragbutton rightArrow" [disabled]="rightFeatureNavDisabled"  type="button" (click)="moveTo('right')">
            <span class="arrowbx right-arrow"> <img src="../../../../../assets/images/sliderArrow.svg" alt="" /> </span></button>
        </div>
      </div>

    </div>
  </div>
  -->


  <!--- freemium works : How it all works-->
  <div class="freemium-container howItAllWorkes">
    <div class="sectionBx">
      <div class="headerBlock">
        <h3 class="heading">Steps to launch your app</h3>
        <!-- <h3 class="heading">How it all works</h3>
        <p class="subheading">Looks like you’ve found the perfect template for your business. What happens next? </p> -->
      </div>
    </div>
    <div class="sectionBx">
      <div class="worksmidblock">
        <div class="listing">
          <div class="imgbx"><img src="../../../../../assets/images/step-start-today.svg" alt="" /></div>
          <div class="imageCaption">
            <h5 class="title">Start Today</h5>
            <p class="detail">Click ‘Get started’ and add your branding.</p>
            <!-- <h5 class="title">Make it your own</h5>
            <p class="detail">Discuss your idea with our experts and share your branding.</p> -->
          </div>
        </div>
        <div class="listing">
          <div class="imgbx"><img src="../../../../../assets/images/step-launch-app.svg" alt="" /></div>
          <div class="imageCaption">
            <h5 class="title">Launch App</h5>
            <p class="detail">Free cloud hosting – apps in as little as 2 weeks. </p>
            <!-- <h5 class="title">Kick off & track progress</h5>
            <p class="detail">Pay a refundable deposit and track your build’s progress from our dashboard. </p> -->
          </div>
        </div>
        <div class="listing">
          <div class="imgbx"><img src="../../../../../assets/images/step-start-selling.svg" alt="" /></div>
          <div class="imageCaption">
            <h5 class="title">Start Selling</h5>
            <p class="detail">Process up to 50 orders a month – for free! </p>
            <!-- <h5 class="title">Launch app</h5>
            <p class="detail">week after your kick-off call. </p> -->
          </div>
        </div>
        <div class="listing">
          <div class="imgbx"><img src="../../../../../assets/images/step-try-for-free.svg" alt="" /></div>
          <div class="imageCaption">
            <h5 class="title">Try for free</h5>
            <p class="detail">Loads of orders? It’s easy to upgrade to Premium.</p>
            <!-- <h5 class="title">Keep it fresh</h5>
            <p class="detail">Your app moves into maintenance and cloud. Your business grows.</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>

     <!--- question answer -->
     <!-- <div class="freemium-container questionMainBx">
      <div class="sectionBx">
          <div class="headerBlock">
              <h3 class="heading">Your questions answered</h3>
              <p class="subheading">Find out more about Studio Store apps, below. </p>
          </div>
      </div>
      <div class="sectionBx">
          <div class="questionListBox">
              <ul (click)="showAnswer($event.target)">
                  <li *ngFor="let question of dataService.appDetails?.builder_faqs; let i = index" [value]="i">
                      <div class="qlist" [ngClass]="{'active': i == questionShownIndex}" [attr.data-index]="i">
                          {{question?.title}}
                          <span class="toggleButton"><em class="icon-next" [attr.data-index]="i"></em></span>
                      </div> -->
                      <!--- add active class-->
                      <!-- <div class="detailstxt">
                          <p>{{question?.description}}</p>
                      </div>
                  </li>
              </ul>
          </div>
      </div>
  </div> -->

  <!--- footer strip -->
  <div class="freemium-container bottomBox" ><!-- *ngIf="isShowBottomBar" -->
    <div class="bottom-strip">
      <div class="leftSection">
        <ul>
          <li class="listing">
            <div class="headtxt"> {{dataService.appDetails?.title}}</div>
            <div class="nortxt">
              <span class="count"> {{dataService.appDetails?.feature_count}}</span> {{dataService.appDetails?.feature_count
                > 1 ? 'features' : 'feature' }}
            </div>
          </li>
          <li class="listing">
            <div class="headtxt"> Platforms</div>
            <div class="nortxt justPrice"> {{selectedPlatform == 'app'? "Just Apps" : selectedPlatform == 'web'? "Just Web" : "Web + Apps"}}</div>
          </li>
          <li class="listing mobileView">
            <div class="headtxt"> For
            </div>
            <div class="pricebox">
              <span class="ammount">Free</span>
            </div>
          </li>
        </ul>
      </div>
      <!--
      <div class="leftSection">
        <ul>
          <li class="listing">
            <div class="headtxt"> {{dataService.appDetails?.title}}</div>
            <div class="nortxt">
              <span class="headtxt"> {{dataService.appDetails?.feature_count}}</span> {{dataService.appDetails?.feature_count
              > 1 ? 'features' : 'feature' }} included
            </div>
          </li>
          <li class="listing">
            <div class="nortxt"> Platforms</div>
            <div class="headtxt"> {{selectedPlatform == 'app'? "Just Apps" : selectedPlatform == 'web'? "Just Web" : "Web + Apps"}}
            </div>
          </li>
          <li class="listing mobileView">
            <div class="nortxt"> For
            </div>
            <div class="pricebox">
              <div class="headtxt">Free</div>
            </div>
          </li>
        </ul>
      </div>
      -->
      <div class="rightSection">
        <button type="button" class="overviewBtn" (click)="payNowForRental(dataService.appDetails, 'Summary bar')"
          [disabled]="isBuildCardUpdating" > Proceed
          <span class="loaderAnimation fiveDotloader" *ngIf="isBuildCardUpdating">
            <span></span><span></span><span></span>
          </span>
        </button>

      </div>
    </div>
  </div>
  <!---->

<!----popup change plateform ---->
<div class="commonPopUp active" *ngIf="isShowChangePlatformModal">
  <div class="popOverlay"></div>
  <div class="popHolder plateformPopUp">
    <div class="closePopUp" (click)="closeChangePlatFormModal()">
      <span class="icon-cancel"></span>
    </div>
    <div class="sideSpaceBlock">
      <div class="changePlataformMain">
        <h2 class="title"> Change platforms </h2>
        <div class="plataformTab">
          <div class="serviceTab">
            <input type="radio" name="selectingPlatform" id="app" [checked]="selectedPlatform == 'app'" (change)="changePlatform('app')">
            <label for="app">
              <span class="tagTitle">Just apps</span>
              <ul class="txtListing">
                <li>
                  iOS app for Apple (iPhones)
                </li>
                <li>
                  Android app (for Android phones)
                </li>

              </ul>
            </label>
          </div>
          <div class="serviceTab">
            <input type="radio" name="selectingPlatform" id="web" [checked]="selectedPlatform == 'web'" (change)="changePlatform('web')">
            <label for="web">
              <span class="tagTitle">Just web</span>
              <ul class="txtListing">
                <li>Responsive website – optimised for desktop, laptop and mobile screens.</li>

              </ul>
            </label>
          </div>
          <div class="serviceTab">
            <input type="radio" name="selectingPlatform" id="webapp" [checked]="selectedPlatform == 'all'" (change)="changePlatform('all')">
            <label for="webapp">
              <span class="tagTitle">Web + apps</span>
              <ul class="txtListing">
                <li> iOS app for Apple (iPhones)
                </li>
                <li>
                  Android app (for Android phones)
                </li>
                <li>
                  Responsive website – optimised for desktop, laptop and mobile screens
                </li>
              </ul>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="plateformfooter">
      <button type="button" class="applyButton" (click)="applyChangedPlatform()" [disabled]="!(tempSelectedPlatform && tempSelectedPlatform !== selectedPlatform)" >Apply</button>
    </div>
  </div>
</div>

<!----slider popup ---->
<div class="commonPopUp active" *ngIf="selectedFeature >=0">
  <div class="popOverlay"></div>
  <div class="popHolder sliderPouUpBx">
    <div class="closePopUp" (click)="closeFeaturePopup()" ><span class="icon-cancel"></span></div>
    <div class="singlePopUp" #featureSliderHolder>
      <drag-scroll class="slideHolder" (reachesLeftBound)="leftBoundStat($event,'mobilefeatureSlider')" (reachesRightBound)="rightBoundStat($event,'mobilefeatureSlider')"
                   #navFeatureSliderPopUp>
        <div class="appfeatureBox" drag-scroll-item *ngFor="let feature of dataService.appDetails.features">
          <div class="imageFrame">
            <ng-container *ngIf="feature.feature_screenshots?.android">
              <img src="{{feature.feature_screenshots?.android}}" alt="" />
            </ng-container>
            <ng-container *ngIf="!feature.feature_screenshots?.android">
              <img src="./../../../../../assets/images/notavailable_mobile.png" alt="" />
            </ng-container>
          </div>
          <!-- information box --->
          <div class="infoBlock">
            <h3> {{feature.title}} </h3>
            <p>{{feature.description}}</p>
          </div>
          <!----->
        </div>
      </drag-scroll>
      <!-- next prevoius button -->
      <button class="dragbutton leftArrow" [disabled]="leftMobileFeatureNavDisabled" type="button" (click)="moveLeft('mobilefeatureSlider')">
      </button>
      <button class="dragbutton rightArrow" [disabled]="rightMobileFeatureNavDisabled" type="button" (click)="moveRight('mobilefeatureSlider')">
      </button>
    </div>
  </div>

</div>

<rental-parent *ngIf="phaseService.showRentalPaymentFlow" [appDetails]="dataService.appDetails"></rental-parent>
