import {Component, OnInit} from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';


@Component({
  selector: 'app-create-card-for-others',
  templateUrl: './create-card-for-others.component.html',
  styleUrls: ['./create-card-for-others.component.css']
})
export class CreateCardForOthersComponent {
  emailForCardCreation: string;
  selectedOption = 0;

  constructor(public phaseService: PhaseService, public dataService: DataService) {
  }



  changeOption(index) {
    this.selectedOption = index;
    this.dataService.isEmailToBeOwner = index === 0;
  }

  hidePopup() {
    this.phaseService.isShowCreateCardForOthersPopup = false;
  }

  startCreatingCardForOthers() {
    this.dataService.emailIdToShareWith = this.emailForCardCreation;
    this.phaseService.isShowCreateCardForOthersPopup = false;
  }

  isEmailValid() {
    return this.phaseService.isEmailValid(this.emailForCardCreation);
  }
}
