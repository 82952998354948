import {Component, OnInit} from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {SpecCallModel} from '@models/spec-call-model/spec-call.model';
import moment from 'moment';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import {DataService} from '@shared/services/data.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {Router} from '@angular/router';
import {Intercom} from 'ng-intercom';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {AppDataService} from '@rootmodule/app-data.service';
import {Subscription} from 'rxjs';
import {CommonService} from '@shared/services/common.service';
@Component({
  selector: 'app-thanku-screen',
  templateUrl: './thanku-screen.component.html',
  styleUrls: ['./thanku-screen.component.scss']
})
export class ThankuScreenComponent implements OnInit {
  public specCallData: SpecCallModel;
  public showOrderSummary = false;
  isPaymentTypeSpecing = false;
  transactionId: string;
  isScheduleCall = false;
  isCalenderOpen = false;
  dateChange = false;
  jsDate: any;
  public scheduleCallWaiting = true;
  public scheduleCallSubscription : Subscription;
  public inProgressBuildCardId : number;
  public schedulerWaitingMsg : string;
  public progressBarStep : boolean;
  dateString = '';
  day = 1;
  schedulerURL: SafeResourceUrl;
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd.mm.yyyy',
    disableWeekends: true,
    inline: true,
    disableDates: this.getNextThreeDays()
    // other options are here...
  };
  model: IMyDateModel = null;
  slotsArray = ['12:00 AM',
    '01:00 AM',
    '02:00 AM',
    '03:00 AM',
    '04:00 AM',
    '05:00 AM',
    '06:00 AM',
    '07:00 AM',
    '08:00 AM',
    '09:00 AM',
    '10:00 AM',
    '11:00 AM',
    '12:00 PM',
    '01:00 PM',
    '02:00 PM',
    '03:00 PM',
    '04:00 PM',
    '05:00 PM',
    '06:00 PM',
    '07:00 PM',
    '08:00 PM',
    '09:00 PM',
    '10:00 PM',
    '11:00 PM',
    '12:00 AM'];
  endSlotsArray = JSON.parse(JSON.stringify(this.slotsArray));
  public startTimeSlot = this.slotsArray[0];
  public endTimeSlot = this.slotsArray[0];
  showCalendar: boolean;
  moment: any;
  smbUser = false;

  constructor(public phaseService: PhaseService, public dataService: DataService, public apiRequests: ApiRequests,
              public analyticsSegment: Angulartics2Segment, private router: Router,
              public sanitizer: DomSanitizer, public appDataService: AppDataService,
              private commonService: CommonService) {
                this.moment = moment;
  }

  ngOnInit(): void {
    this.schedulerWaitingMsg = 'Hold tight, we’re assigning your Buildcard™ to a squad...';
    setTimeout(() => {
      this.progressBarStep = true;
      this.schedulerWaitingMsg = 'Finding the best experts to work on your Buildcard™...';
    }, 8000);
    this.pollForSchedular();
    this.setDateInfo();
    if(!this.phaseService.IsFromFreemium && this.appDataService.ismicrosoftActive()){
      this.smbUser = true;
    }
  }

  public setDateInfo(): void {
    const today = new Date();
    today.setDate(today.getDate());
    const disableDateUntil = {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()};
    this.myDpOptions = {
      dateRange: false,
      dateFormat: 'dd.mm.yyyy',
      disableUntil: disableDateUntil,
      disableWeekends: true,
      inline: true
    };
    this.model = {isRange: false, singleDate: {jsDate: new Date()}};
  }

  public showHideOrderSummary(isShow) {
    this.showOrderSummary = isShow;
  }

  public getTransactionID() {
    return this.phaseService.transactionId;
  }

  public getNextThreeDays() {
    return [this.getDateObj(), this.getDateObj(this.day), this.getDateObj(this.day), this.getDateObj(this.day)];
  }

  getDateObj(day?) {
    this.moment = moment;
    const today = this.moment();
    if (day) {
      const tomorrow = this.moment(today).add(day, 'days');
      const tomorrowday = tomorrow.day();
      if (tomorrowday === 0 || tomorrowday === 6) {
        this.day = day + 1;
        return this.getDateObj(day + 1);
      } else {
        this.day = day + 1;
        return {year: tomorrow.year(), month: tomorrow.month() + 1, day: tomorrow.date()};
      }
    } else {
      const todaydate = today.day();
      if (todaydate === 0 || todaydate === 6) {
        this.day = todaydate + 1;
        return this.getDateObj(todaydate + 1);
      } else {
        return {year: today.year(), month: today.month() + 1, day: today.date()};
      }
    }
  }

  // onCalendarToggle(event: number): void {
  //   if (event === 1) {
  //     this.isCalenderOpen = true;
  //   } else {
  //     this.isCalenderOpen = false;
  //   }
  // }

  // onDateChanged(event: IMyDateModel): void {
  //   this.jsDate = event.singleDate.jsDate;
  //   this.dateString = this.moment(event.singleDate.jsDate).format('ll');
  //   this.dateChange = true;
  // }

  // saveScheduleDate() {
  //     if (this.phaseService.inProgressBuildCardId) {
  //       const obj = {date: this.jsDate, slot_start: this.startTimeSlot, slot_end: this.endTimeSlot};
  //       this.apiRequests.addScheduleDate(this.phaseService.inProgressBuildCardId, obj).subscribe((data) => {
  //         this.dateChange = false;
  //         this.showCalendar = false;
  //         this.phaseService.showCallBookedSuccess = true;
  //       });
  //     }
  // }

  // startTimeSlotChanged(value: any) {
  //   this.startTimeSlot = value;
  //   this.endSlotsArray = JSON.parse(JSON.stringify(this.slotsArray));
  //   const startTimeIndex = this.slotsArray.indexOf(this.startTimeSlot);
  //   this.endSlotsArray = this.endSlotsArray.splice(startTimeIndex + 1, this.slotsArray.length);
  //   this.endTimeSlot = this.endSlotsArray[0];
  // }

  // endTimeSlotChanged(value: any) {
  //   this.endTimeSlot = value;
  // }

  closeSpecModal() {
    this.phaseService.showRentalPaymentFlow = false;
    this.phaseService.showCallBookedSuccess = false;
    this.gotoDashboard();
  }

  gotoDashboard() {
    this.router.navigate(['dashboard']);
  }

  scheduleExertCall() {
    this.showCalendar = true;
    this.commonService.isBuildCardSynced = true;
    const specingId = parseInt(this.appDataService.urlParameters.specingId);
    this.schedulerURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.phaseService.showTimeKitWindow(false, this.dataService.buildCardData.id, 'kickoff'));
  }

  getStartedForFreemium() {
    window.open('https://intercom.help/engineerai/en/articles/5793150-1-first-steps');
  }

  pollForSchedular() {
    this.scheduleCallSubscription = this.apiRequests.scheduleCallPolling(this.dataService.buildCardData.id)
    .subscribe(res => {
    if ( res.data.status === "pending"){
      this.scheduleCallWaiting = true;
      setTimeout(() => {
        this.pollForSchedular();
      }, 10000);

    } else {
      this.scheduleCallSubscription.unsubscribe();
      this.progressBarStep = false;
      this.scheduleCallWaiting = false;
      if (!this.appDataService.urlParameters.is_freemium) {
        this.scheduleExertCall();
      }
    }
  });
}

  closeSchedulerPopup() {
    this.dataService.openSchedulerPopup = false;
    this.dataService.showMeetNowModel = false;
    this.phaseService.showScheduleCallFlow = false;
    this.phaseService.specCallPopupAppeared = true;
    this.phaseService.isFromDashBard = false;
    this.router.navigate(['dashboard']);
    if (this.goToTaxDetailsSection()) {
      this.phaseService.showTaxAndBilling = true;
    }
  }

  goToTaxDetailsSection() {
    const user = this.dataService.user;
    return (this.checkPageForTaxText() && (this.showTextForINR() || (user.address.billed_as === 'company')));
  }

  showTextForINR() {
    const user = this.dataService.user;
    return ((user.currency.code === 'INR') && (user.address.billed_as === 'individual') &&
      this.checkPageForTaxText());
  }

  showTextExceptINR() {
    const user = this.dataService.user;
    return ((user.address.billed_as === 'company') && this.checkPageForTaxText());
  }

  checkPageForTaxText() {
    const currentPage = this.dataService.currentPage;
    return ((currentPage === 'home') || (currentPage === 'templateDetail'));
  }

}
