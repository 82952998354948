import { Injectable } from '@angular/core';
import { HttpRequests } from '@shared/services/http-requests.service';
import { AppDataService } from '@rootmodule/app-data.service';
import { environment } from '../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { ModelMapperService } from '@shared/services/model-mapper.service';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { ReferralWalletModel } from '@models/referral-data-model/referralwallet.model';
import { CommonService } from './common.service';
import {BuildCardModel} from '@models/build-card-model/build-card.model';
import { DataService } from './data.service';
import { PhaseService } from './phase.service';
import { Options } from '@angular-slider/ngx-slider';
@Injectable({
  providedIn: 'root'
})
export class ReferralService {

  showMainReferralComponent = false;
  openShareReferralPopup = false;
  showReferralWallet = false;
  showUseBuilderPointsPopup = false;
  referralWalletData: ReferralWalletModel[] = [];
  referralCode: string;
  listOfBuildCards: BuildCardModel[] = [];
  selectedBuildCard: BuildCardModel;
  builderPointsUsageData = [];
  rewardPointsAPISuccess = false;
  showConfirmBuilderPointsReductionPopup = false;
  totalAmountInWallet: any = null;
  referralCodeError: string;
  referralBuilderPointsForUser = null;
  showUseCreditedReferralPointsPopup = false;
  sliderValueForCreditedReferralPoints = 0;
  referralPointsUsageSliderOptions: Options = {
    showSelectionBar: true,
    floor: 0,
    ceil: 1000
  };
  sliderValueAfterBuilderPointsReductionConfirm = 0;
  typeOfInstallmentsForConfirmPopup = '';
  builderPointsUsageAPISuccess = false;
  builderPointsUsageEquivalentToUserCurrency = 0;
  creditedBuilderAmountEquivalentToUsedReferralPoints = null;
  builderAmountEquivalentToReferralPointsUsed = null;

  constructor(public httpRequest: HttpRequests, public appDataService: AppDataService, private modelMapperService: ModelMapperService,
    private commonService: CommonService, public dataService: DataService, public phaseService: PhaseService) { }

  showShareReferralPopup() {
    this.openShareReferralPopup = true;
  }

  getShareURLLink() {
    let link = 'https://www.builder.ai/ref?code=' + this.dataService.user.referral_code;
    return link;
  }

  getTransactionDataForReferralWallet() {
    let API = environment.API_URL + 'reward_points';
    this.dataService.showHideLoader(true);
    this.httpRequest.get(API, this.appDataService.getRequestOptionArgs()).subscribe((response) => {
      this.rewardPointsAPISuccess = true;
      this.totalAmountInWallet = response.total_amount;
      if (typeof this.totalAmountInWallet === 'string') {
        this.totalAmountInWallet = parseInt(this.totalAmountInWallet.replace(/,/g, ''));
      }
      this.referralPointsUsageSliderOptions = {
        showSelectionBar: true,
        floor: 0,
        ceil: this.totalAmountInWallet
      };
      this.referralWalletData = [];
      this.modelMapperService.getMappedArrayModel(this.referralWalletData, response.data);
      this.dataService.showHideLoader(false);
      return this.referralWalletData;
    }, (error) => {
      this.commonService.showError();
    });
  }

  getListOfBuildcards() {
    let API = environment.API_URL + 'build_card';
    this.dataService.showHideLoader(true);
    this.httpRequest.get(API, this.appDataService.getRequestOptionArgs()).subscribe((response) => {
      this.listOfBuildCards = response.build_card;
      this.selectedBuildCard = this.listOfBuildCards[0];
      this.dataService.showHideLoader(false);
      return this.listOfBuildCards;
    }, (error) => {
      this.commonService.showError();
    });
  }

  getBuilderPointsUsageData(requestData) {
    let API = environment.API_URL + 'use_reward_point';
    const headers = this.appDataService.getRequestOptionArgs();
    const requestOptions = {
      headers: headers,
      params: requestData
    };
    this.dataService.showHideLoader(true);
    return this.httpRequest.get(API, null, requestOptions).subscribe((response) => {
      this.builderPointsUsageData = response.data;
      this.builderPointsUsageEquivalentToUserCurrency = response.total_amount;
      this.builderPointsUsageAPISuccess = true;
      this.dataService.showHideLoader(false);
    }, (error) => {
      this.commonService.showError();
    });
  }

  confirmBuilderPointsReduction(requestPayload) {
    let API = environment.API_URL + 'confirm_reward_point_use';
    const headers = this.appDataService.getRequestOptionArgs();
    this.dataService.showHideLoader(true);
    return this.httpRequest.put(API, requestPayload, headers).subscribe((response) => {
      this.showUseBuilderPointsPopup = true;
      this.showConfirmBuilderPointsReductionPopup = true;
      this.getTransactionDataForReferralWallet();
      this.dataService.showHideLoader(false);
    }, (error) => {
      this.commonService.showError();
    });
  }

  applyReferralCode(referralCode) {
    let API = environment.API_URL + 'check_referral_code';
    const headers = this.appDataService.getRequestOptionArgs();
    let requestData = {
      referrer_code: referralCode,
      build_card_id: this.dataService.buildCardData.id
    };
    const requestOptions = {
      headers: headers,
      params: requestData
    };
    this.dataService.showHideLoader(true);
    return this.httpRequest.get(API, null, requestOptions).subscribe((response) => {
      if (response.error) {
        this.referralCodeError = response.error;
      } else if (response.message === 'Apply succesfully') {
        this.referralBuilderPointsForUser = response.builder_points;
        this.sliderValueForCreditedReferralPoints = response.builder_points;
        this.creditedBuilderAmountEquivalentToUsedReferralPoints = response.total_amount;
      }
      this.dataService.showHideLoader(false);
    }, (error) => {
      this.commonService.showError();
    });
  }

  useCreditedBuilderPoints(requestPayload, isFromSliderValueChange) {
    let API = environment.API_URL + 'apply_referral_code';
    const headers = this.appDataService.getRequestOptionArgs();
    this.dataService.showHideLoader(true);
    return this.httpRequest.put(API, requestPayload, headers).subscribe((response) => {
      if (!isFromSliderValueChange) {
        if ((this.dataService.currentPage === 'billingDetails') || (this.dataService.currentPage === 'summary')) {
          this.phaseService.fetchProposalInstallments(true, true);
        } else {
          this.phaseService.fetchProposalInstallments(true);
        }
        this.phaseService.fetchInstallments(true);
        this.showUseCreditedReferralPointsPopup = false;
        this.referralBuilderPointsForUser = response.used_referral_points;
        this.sliderValueForCreditedReferralPoints = response.used_referral_points;
        this.creditedBuilderAmountEquivalentToUsedReferralPoints = response.total_amount;
      } else {
        this.builderAmountEquivalentToReferralPointsUsed = response.total_amount;
      }
      this.dataService.showHideLoader(false);
    }, (error) => {
      this.commonService.showError();
    });
  }

  reverseRewardPointBonus() {
    let API = environment.API_URL + 'reverse_reward_point_bonus';
    const headers = this.appDataService.getRequestOptionArgs();
    let requestPayload = {
      build_card_id: this.dataService.buildCardData.id
    };
    return this.httpRequest.put(API, requestPayload, headers).subscribe((response) => {
      this.phaseService.fetchProposalInstallments(true);
    }, (error) => {
      this.commonService.showError();
    });
  }

  useExistingRewardPoints() {
    let API = environment.API_URL + 'use_existing_reward_point';
    const headers = this.appDataService.getRequestOptionArgs();
    let requestPayload = {build_card_id: this.dataService.buildCardData.id};
    this.dataService.showHideLoader(true);
    return this.httpRequest.put(API, requestPayload, headers).subscribe((response) => {
      // if (!isFromSliderValueChange) {
        if ((this.dataService.currentPage === 'billingDetails') || (this.dataService.currentPage === 'summary')) {
          this.phaseService.fetchProposalInstallments(true, true);
        } else {
          this.phaseService.fetchProposalInstallments(true);
        }
        this.phaseService.fetchInstallments(true);
        // this.showUseCreditedReferralPointsPopup = false;
        this.referralBuilderPointsForUser = response.used_referral_points;
        this.sliderValueForCreditedReferralPoints = response.used_referral_points;
        this.creditedBuilderAmountEquivalentToUsedReferralPoints = response.total_amount;
      // } else {
      //   this.builderAmountEquivalentToReferralPointsUsed = response.total_amount;
      // }
      this.dataService.showHideLoader(false);
    }, (error) => {
      this.commonService.showError();
    });
  }

  confirmRewardPayment() {
    let API = environment.API_URL + 'confirm_reward_payment';
    const headers = this.appDataService.getRequestOptionArgs();
    let requestPayload = {build_card_id: this.dataService.buildCardData.id};
    this.dataService.showHideLoader(true);
    return this.httpRequest.put(API, requestPayload, headers).subscribe((response) => {
      this.dataService.showHideLoader(false);
    }, (error) => {
      this.commonService.showError();
    });
  }

}
