import { Injectable } from '@angular/core';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {CommonService} from '@shared/services/common.service';
import {postcodeValidator} from 'postcode-validator';
import parsePhoneNumber from 'libphonenumber-js';

@Injectable({
  providedIn: 'root'
})
export class BillingDetailsService {

  isCompanyAddress = false;
  citiesList: any;
  userCountryData = [];
  selectedStates = [];
  showGSTIN = false;
  isGSTCheckboxChecked = false;

  constructor(public dataService: DataService, public phaseService: PhaseService,
              public apiRequest: ApiRequests, private commonService: CommonService) { }

  changeAddressType(addressType) {
    this.phaseService.billingInfo.billed_as = addressType;
    if (addressType === 'company') {
      this.isCompanyAddress = true;
    } else {
      this.isCompanyAddress = false;
    }
  }

  renderCitiesInDropdown() {
    const state = this.phaseService.billingInfo.state;
    if (this.phaseService.billingInfo.state && this.citiesList[state]) {
      return this.citiesList[state].sort((a, b) => {
        if (a > b) {
          return 1;
        } else if (a < b) {
          return -1;
        }
      });
    } else {
      return ['Not Available'];
    }
  }

  getCountryWiseState() {
    this.apiRequest.getUserCountryData().subscribe((data: any) => {
      this.userCountryData = data.data;
      if ((this.dataService.user.currency.id === 4) || (data.data && (data.data.length === 1))) {
        if (this.dataService.user.currency.id === 4) {
          this.phaseService.billingInfo.country = data['data'].find(country => country.code === 'GB')['name'];
        } else {
          this.phaseService.billingInfo.country = data['data'][0]['name'];
        }
      } else if ((this.dataService.user.currency.id === 2) && !this.dataService.user.address) {
        this.phaseService.billingInfo.country = data['data'].find(country => country.code === 'US')['name'];
      }
      const fillState = (this.userCountryData && ((this.userCountryData.length === 1) ||
        (this.dataService.user.currency.id === 2)) && !this.dataService.user.address);
      this.availablestate(fillState ? '' : 'dontFillState');
    }, error => {
      this.commonService.showError();
    });
  }

  availablestate(dontFillState?) {
    const index = this.userCountryData.findIndex((country) => {
      return this.phaseService.billingInfo.country.toLowerCase() === country.name.toLowerCase();
    });
    if (index !== -1) {
      this.selectedStates = this.userCountryData[index].states.states;
      if (!dontFillState) {
        this.phaseService.billingInfo.state = this.selectedStates[1].name;
        if (!this.citiesList && this.checkIfUSCustomer()) {
          this.getCitiesList();
        }
      }
    } else if (!this.dataService.user.address) {
      this.phaseService.billingInfo.country = '';
    }
  }

  getCitiesList() {
    if (this.dataService.user.currency.id !== 2) { return false; }
    this.apiRequest.getCitiesList().subscribe((resp) => {
      this.citiesList = resp.data;
      if (!this.phaseService.billingInfo.city) {
        this.phaseService.billingInfo.city = (this.citiesList.length ? this.citiesList[0] : '');
      }
    });
  }

  checkIfUSCustomer() {
    return (this.phaseService.billingInfo.country === 'United States of America');
  }

  checkIfFormValid() {
    for (const key of Object.keys(this.phaseService.billingInfo)) {
      if ( (key !== 'companyName') && (key !== 'address_line_2') && (key !== 'apartment') && (key !== 'billing_entity')) {
        if ((this.phaseService.billingInfo[key] === '') && ((this.showGSTIN && this.isGSTCheckboxChecked) ? ((key !== 'name') && (key !== 'address')) : ((key !== 'name') && (key !== 'companyName') && (key !== 'address') && (key !== 'apartment') && (key !== 'gstNumber')))) {
          return false;
          break;
        }
      }
      if ((this.phaseService.billingInfo[key] === '') && this.isCompanyAddress && ((key === 'companyName'))) {
        return false;
        break;
      }
    }
    return true;
  }

  setCity() {
    const billingInfo = this.phaseService.billingInfo;
    if (this.checkIfUSCustomer() && billingInfo.state && this.citiesList[billingInfo.state]) {
      billingInfo.city = this.citiesList[billingInfo.state][0];
    } else {
      billingInfo.city = '';
    }
  }

  getBillingInfo() {
    if (this.dataService.user.currency.code === 'INR') {
      this.showGSTIN = (this.phaseService.billingInfo && this.phaseService.billingInfo.country && (this.phaseService.billingInfo.country.toLowerCase() === 'india')) ? true : false;
    }
    if (!this.showGSTIN) {
      this.phaseService.billingInfo.gstNumber = '';
      this.isGSTCheckboxChecked = false;
    }
    if (this.phaseService.billingInfo.street) {
      this.phaseService.billingInfo.street = decodeURIComponent(this.phaseService.billingInfo.street);
    }
    if (this.phaseService.billingInfo.apartment) {
      try {
        this.phaseService.billingInfo.apartment = decodeURIComponent(this.phaseService.billingInfo.apartment);
      } catch (e) {

      }
    }
    Object.keys(this.phaseService.billingInfo).map((key) => {
      if (this.phaseService.billingInfo[key] && ((this.phaseService.billingInfo[key] === 'null') || (this.phaseService.billingInfo[key] === undefined))) {
        this.phaseService.billingInfo[key] = '';
      }
      if (this.phaseService.billingInfo[key] && key != 'contact') {
        this.phaseService.billingInfo[key] = this.phaseService.billingInfo[key].trim();
      }
      return this.phaseService.billingInfo[key];
    });
    return this.phaseService.billingInfo;
  }

  checkIfValidDetails() {
    const isUkOrCyprusCountry = !this.ifNotUkOrCyprus();
    const billingInfo = this.phaseService.billingInfo,
      countryCodes = ['IN', 'US', 'GB', 'CA', 'JP'];
    const ifCountriesAreAvailable = this.dataService.userCurrencyAndCountries && this.dataService.userCurrencyAndCountries['available_country'] && (this.dataService.userCurrencyAndCountries['available_country'].length > 0);
    if (ifCountriesAreAvailable && !this.dataService.userCurrencyAndCountries['available_country'].map(country => country.name.toLowerCase()).includes(billingInfo.country.toLowerCase())) {
      billingInfo.country = '';
      return false;
    }
    if (!isUkOrCyprusCountry && !this.selectedStates.map(state => state.name.toLowerCase()).includes(billingInfo.state.toLowerCase())) {
      billingInfo.state = '';
      return false;
    }
    if (isUkOrCyprusCountry) {
      delete billingInfo.state;
    }
    if ((!this.checkIfFormValid() || this.checkIfPhoneInValid()) && !this.dataService.user.address_disabled) {
      return false;
    }
    const selectedCountry = this.dataService.userCurrencyAndCountries['available_country'].find(country => country.name.toLowerCase() === billingInfo.country.toLowerCase());
    if (countryCodes.includes(selectedCountry.code) && !postcodeValidator(billingInfo.pincode, selectedCountry.code)) {
      billingInfo.pincode = '';
      return false;
    }
    return true;
  }

  ifNotUkOrCyprus() {
    return ((this.dataService.user.currency.id !== 4) && (this.phaseService.billingInfo.country.toLocaleLowerCase() !== 'Cyprus'.toLocaleLowerCase()));
  }

  checkIfPhoneInValid() {
    const phoneNumber = parsePhoneNumber(this.getBillingInfo().contact.toString(), this.dataService.countryISOCode);
    if (phoneNumber) {
      return !(phoneNumber.isValid() && this.getBillingInfo().contact.includes('+' + phoneNumber.countryCallingCode));
    } else {
      return true;
    }
  }

  setUserAddress() {
    const userAddress = this.dataService.user.address,
      billingInfo = this.phaseService.billingInfo;
    billingInfo.contact = userAddress ? userAddress.phone.replace('-', '') : '';
    billingInfo.street = userAddress ? decodeURIComponent(userAddress.street) : '';
    billingInfo.apartment = userAddress ? decodeURIComponent(userAddress.apartment) : '';
    billingInfo.state = userAddress ? userAddress.state : '';
    billingInfo.city = userAddress ? userAddress.city : '';
    billingInfo.pincode = userAddress ? userAddress.pincode : '';
    billingInfo.address_line_2 = userAddress ? userAddress.address_line_2 : '';
    billingInfo.country = userAddress ? userAddress.country : '';
    billingInfo.companyName = userAddress ? userAddress.company_name : '';
    billingInfo.gstNumber = userAddress ? userAddress.gst_number : '';
    billingInfo.billed_as = userAddress ? userAddress.billed_as : 'company';
    billingInfo.firstname = userAddress && userAddress.first_name ? userAddress.first_name : billingInfo.firstname;
    billingInfo.lastname = userAddress && userAddress.last_name ? userAddress.last_name : billingInfo.lastname;
    if (billingInfo.gstNumber && billingInfo.companyName) {
      this.isGSTCheckboxChecked = true;
    }
    if (billingInfo.billed_as === 'company') {
      this.isCompanyAddress = true;
    }
    if (!this.citiesList && userAddress && (userAddress.country === 'United States of America')) {
      this.getCitiesList();
    }
  }

}
