import { Component, Input, OnChanges } from '@angular/core';
import {SectionModel} from '@models/section-model/section.model';
import {PhaseService} from '@shared/services/phase.service';

@Component({
  // selector: 'app-home-lazy-load',
  templateUrl: './home-lazy-load.component.html',
  styleUrls: ['./home-lazy-load.component.css']
})
export class HomeLazyLoadComponent implements OnChanges {
  @Input() section: any;
  @Input() sectionsData: any;
  constructor(public phaseService: PhaseService) {
  }

  ngOnChanges() {
      // console.log(this.section, this.sectionsData, 'section');
  }
}
