export class SpecCallModel {
  id: number;
  initial_amount: null;
  discounted_amount: number;
  payable_amount: number;
  status: string;
  is_paid: string;
  is_pending: boolean;
  invoice_id: string;
  transaction_id: number;
  zoom_link: string;
  assigned_to: string;
  manager_availability: boolean;
  spec_call_promotion: { id: number };
}
