import {Component} from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {UtilityService} from '@shared/services/utility.service';

@Component({
  selector: 'learn-more',
  templateUrl: 'learn-more.component.html',
  styleUrls: ['learn-more.component.scss']
})

export class LearnMoreComponent {
  public data: any[];
  public currentTabIndex = 0;
  public readonly MAX_DATA_LIMIT = 4;

  constructor(public phaseService: PhaseService, public dataService: DataService, public utilityService: UtilityService) {
    this.data = this.phaseService.getLearnMoreData();
  }

  public showPreviousData(): void {
    if (this.phaseService.learnMoreIndex <= 0) { return; }
    this.currentTabIndex = 0;
    this.phaseService.learnMoreIndex--;
  }

  public showNextData(): void {
    if (this.phaseService.learnMoreIndex >= this.MAX_DATA_LIMIT - 1) { return; }
    this.currentTabIndex = 0;
    this.phaseService.learnMoreIndex++;
  }

  public viewDocument(url: string): void {
    window.open(url, '_blank', "noopener");
  }

  public downloadDocument(url: string): void {
    const link = document.createElement('a');
    link.download = 'document';
    //link.href = url;
    window.open(url, '_blank', "noopener");
    link.click();
  }

  public showLearnMore() {
    return this.phaseService.showLearnMore;
  }

  public hideLearnMore() {
    this.phaseService.showLearnMore = false;
    this.phaseService.learnMoreIndex = -1;
    this.phaseService.infoForWhichPrototype = '';
  }

  public getLearnMoreIndex() {
    return this.phaseService.learnMoreIndex;
  }
}

