import {ChangeDetectorRef, Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Angulartics2Segment } from 'angulartics2/segment';
import { DataService } from '@shared/services/data.service';
import { PhaseService } from '@shared/services/phase.service';
import { ApiRequests } from '@shared/services/api-requests.service';
import { Intercom } from 'ng-intercom';
import {AppDataService} from "@rootmodule/app-data.service";
import parsePhoneNumber from 'libphonenumber-js';
import {UserModel} from '@models/user-model/user.model';
import {ModelMapperService} from '@shared/services/model-mapper.service';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss']
})
export class ProfileViewComponent implements OnInit {

  @Output() pselectUserProfile = new EventEmitter();
  @Output() pshowTooltip = new EventEmitter();
  @Output() ppreventClick = new EventEmitter();
  @Input('preventClick') preventClick;
  public editProfileShow = false;
  // public showUserProfile = false;
  userProfiles: any;

  userData: { first_name: string; last_name: string; password: string; confirm_password: string; };

  changesSaved = false;
  bantQuestion: any;
  showEditBanttAnswerPopup = false;
  showBanttAnswer = true;
  aboutYouTooltip = false;
  bant_anwsers = {};
  answers : any;
  singleUser : boolean;
  public ErrorMsg = '';
  public resetPass = { 'show': false, 'text': 'show' };
  isSaveButtonClicked = false;
  isNotificationEditMode = false;
  notificationData = {
    sms_phone_number: '',
    sms_enabled: false,
    whatsapp_phone_number: '',
    whatsapp_enabled: false
  };
  countriesData = [];
  countryCodeOrName = '';
  showCountriesList = { whatsapp: false, sms: false };
  @ViewChild('searchNameOrCode') searchNameOrCode: ElementRef;
  @ViewChild('mobileNumber') public mobileNumber;
  countryISOCode = { whatsapp: null, sms: null };
  contactNumber: string;
  countryNameCode = { whatsapp: '', sms: '' };

  constructor(public dataService: DataService, public phaseService: PhaseService, private router: Router,
              public apiRequest: ApiRequests, public cdRef: ChangeDetectorRef, public analyticsSegment: Angulartics2Segment,
              public appDataService: AppDataService, private modelMapperService: ModelMapperService) {
      let firstName = '';
      let lastName = '';
      if (this.dataService.user) {
        firstName = this.dataService.user.first_name;
        lastName = this.dataService.user.last_name;
        this.setNotificationData();
      }
      this.userData = {
        first_name: firstName,
        last_name: lastName,
        password: '',
        confirm_password: ''
      };
      if (!this.dataService.user) {
        this.phaseService.reset();
        this.router.navigate([this.dataService.firstPage]);
      }

    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        for (const key in this.phaseService.buildCardPage) {
          if (true) { this.phaseService.buildCardPage[key] = 0; }
        }
      }
    });
  }

  ngOnInit() {
    if(this.appDataService.urlParameters.login_token){
      this.editProfile();
    }

    this.singleUser = false;
    this.phaseService.isSettingPage = true;
    this.phaseService.countryNameCode = this.dataService.country_code ? this.dataService.country_code.toLowerCase() : '';
    this.getCountriesData();
    this.apiRequest.getUserProfile().subscribe(
      (data) => {
        if (this.dataService.user && !this.dataService.user.user_profiles) {
          this.getUserProfile();
        }
        if (this.phaseService.checkIfSelfServeUser()) {
          this.getBantQuestionForSelfServe();
        } else {
          this.getBantQuestion();
        }
      }
    );
  }


  public ngOnDestroy() {
    this.phaseService.isSettingPage = false;
  }

  public selectUserProfile(profile) {
    this.pselectUserProfile.emit(profile);
  }

  public editProfile() {
    this.editProfileShow = true;
    // this.showUserProfile = true;
  }

  public getUser() {
    return this.dataService.user;
  }

  public getUserProfile() {
    this.apiRequest.getUserProfiles()
      .subscribe(
        (data) => {
          this.userProfiles = data;
          if (this.userProfiles.length > 0) {
            this.pshowTooltip.emit(true);
          }
        }
      );
  }

  public cancelUpdateProfile() {
    this.editProfileShow = false;
    this.userData = {
      first_name: this.dataService.user.first_name,
      last_name: this.dataService.user.last_name,
      password: '',
      confirm_password: ''
    };
  }

  public saveProfile() {
    if (!this.preventClick) {
      this.preventClick = true;
      this.ppreventClick.emit(true);
      this.apiRequest.updateProfile(this.userData).subscribe((data: any) => {
        this.userData = {
          first_name: this.dataService.user.first_name,
          last_name: this.dataService.user.last_name,
          password: '',
          confirm_password: ''
        };
        this.editProfileShow = false;
        this.preventClick = false;
        this.ppreventClick.emit(false);
        this.changesSaved = true;
      }, error => {
        const err = error.error;
        if (err.errors.email != null) {
          this.ErrorMsg = 'Email ' + err.errors.email;
        } else if (err.errors.password != null) {
          this.ErrorMsg = 'Password ' + err.errors.password;
        } else {
          this.ErrorMsg = err.message;
        }
        this.preventClick = false;
        this.ppreventClick.emit(false);
      });
    }
  }
  public showPassword() {
    this.resetPass.show = !this.resetPass.show;
    (this.resetPass.show) ? this.resetPass.text = 'hide' : this.resetPass.text = 'show';
  }

  public openEditBanttAnswerPopup() {
    this.showEditBanttAnswerPopup = true;
  }

  public closeEditBanttPopup(){
    this.singleUser = false;
    if (!(this.bantQuestion.find(a => a.title === 'Size of your company?')) && !this.phaseService.checkIfSelfServeUser()) {
      this.bantQuestion.splice(1, 0, {
        'title': 'Size of your company?',
        'options': [{ key: '1 to 10 people' },
        { key: '10 to 50 people' },
        { key: '50 to 250 people' },
        { key: '250 to 1,000 people' },
        { key: '1,000+ people' },
        { key: 'Not Applicable' }]
      })
    } else if (!(this.bantQuestion.find(a => a.title === 'Size of your company?')) && this.phaseService.checkIfSelfServeUser()) {
      this.bantQuestion.splice(1, 0, {
        'title': 'Size of your company?',
        'options': [{ key: '1-10 employees' },
        { key: '11-50 employees' },
        { key: '51-200 employees' },
        { key: '201-500 employees' },
        { key: '501-1000 employees' },
        { key: '1001-5000 employees' }]
      })
    }
    this.showEditBanttAnswerPopup = false;
    this.bant_anwsers = {};
  }

  public selectBantAnswer(event, questionIndex) {
    this.bant_anwsers[questionIndex] = event.target.value;
    if(this.bant_anwsers[0] == 0 || this.bant_anwsers[0] == 1){
      this.singleUser = true;
      if(this.bantQuestion.find(a => a.title === 'Size of your company?')){
        const index = this.bantQuestion.indexOf(this.bantQuestion.find(a => a.title === 'Size of your company?'))
        this.bantQuestion.splice(index, 1);
      }
    }
    else if (!(this.bantQuestion.find(a => a.title === 'Size of your company?')) && !this.phaseService.checkIfSelfServeUser()) {
      this.bantQuestion.splice(1, 0, {
        'title': 'Size of your company?',
        'options': [{ key: '1 to 10 people' },
        { key: '10 to 50 people' },
        { key: '50 to 250 people' },
        { key: '250 to 1,000 people' },
        { key: '1,000+ people' },
        { key: 'Not Applicable' }]
      })
    } else if (!(this.bantQuestion.find(a => a.title === 'Size of your company?')) && this.phaseService.checkIfSelfServeUser()) {
      this.bantQuestion.splice(1, 0, {
        'title': 'Size of your company?',
        'options': [{ key: '1-10 employees' },
        { key: '11-50 employees' },
        { key: '51-200 employees' },
        { key: '201-500 employees' },
        { key: '501-1000 employees' },
        { key: '1001-5000 employees' }]
      })
    }
  }

  public isAllQuestionAnswered() {
    return Object.keys(this.bant_anwsers).length >= this.bantQuestion.length ? false : true;
  }
  public updateBanttAnswer() {
    const bant_anwsers = Object.keys(this.bant_anwsers);
    if (bant_anwsers.length) {
      const postData = {
        'bant_answers': []
      }
      bant_anwsers.forEach((key) => {
        let answer = this.bantQuestion[key].options[this.bant_anwsers[key]]['value'] ?  this.bantQuestion[key].options[this.bant_anwsers[key]] : this.bantQuestion[key].options[this.bant_anwsers[key]]['key'];
        if (this.phaseService.checkIfSelfServeUser()) {
          if (answer === 'C-Level') {
            answer = 'CEO';
          }
          // if (answer && answer['mapToSF']) {
          //   answer['value'] = answer['mapToSF'];
          //   delete answer['mapToSF'];
          // }
        }
        // else if (answer && answer['mapToSF']) {
        //   delete answer['mapToSF'];
        // }
        postData.bant_answers.push([this.bantQuestion[key].title, answer]);
      });

      if (!(this.bantQuestion.find(a => a.title === 'Size of your company?'))) {
        postData.bant_answers.splice(1, 0, ['Size of your company?', '1']);
      }
      let sizes = ['1,000+ employees'];
      if (this.phaseService.checkIfSelfServeUser()) {
        sizes = ["1001-5000 employees"];
      }
      const sizeOfCompany = postData['bant_answers'].find(a => a[0] === 'Size of your company?');
       if (sizes.includes(sizeOfCompany[1])) {
         postData['enterprise_lead'] = true;
       }
       console.log(postData)
      this.dataService.showHideLoader(true);
      this.apiRequest.saveBantAnswer(postData).subscribe((data) => {
        this.showEditBanttAnswerPopup = false;
        this.bant_anwsers =  {};
        this.singleUser = false;
        this.apiRequest.setUpUserData(data);
        this.fetchHomePageData();
        this.dataService.showHideLoader(false);
        if (this.phaseService.checkIfSelfServeUser()) {
          this.getBantQuestionForSelfServe();
        } else {
          this.getBantQuestion();
        }
      });
    }
  }

  fetchHomePageData() {
    this.apiRequest.fetchHomePageData().subscribe((data: any) => {
      this.dataService.dataLoadingCurrency = Object.assign({}, this.dataService.homePageData.currency);
      this.phaseService.platformPrices = Array.apply(null, Array(this.dataService.homePageData.platforms.length));
      this.phaseService.platformPrices.map((x, i) => {
        return i;
      });
      if (this.dataService.homePageData.currency) {
        this.phaseService.specing_price = this.dataService.homePageData.currency.specing_price;
      }
    });
  }

  public showHideBanttAnswer( showBanttAnswer: boolean){
    this.showBanttAnswer = showBanttAnswer;
  }

  getBantQuestion() {
    this.bantQuestion = [
      {
        title: "What best describes you?",
        options: [
          { key: "Student" },
          { key: "Solo entrepreneur" },
          { key: "Manager" },
          { key: "Director" },
          { key: "CEO, CFO, CTO..." },
          { key: "Other" },
        ],
      },
      {
        title: "Size of your company?",
        options: [
          { key: "1 to 10 people" },
          { key: "10 to 50 people" },
          { key: "50 to 250 people" },
          { key: "250 to 1,000 people" },
          { key: "1,000+ people" },
          { key: "Not Applicable" },
        ],
      },
      {
        title: "When do you need your software delivered?",
        options: [
          { key: "Less than 2 months" },
          { key: "2 to 6 months" },
          { key: "6 to 12 months" },
          { key: "12 to 24 months" },
          { key: "24 months +" },
          { key: "Not sure" },
        ],
      },
      {
        title: "How much do you expect to spend?",
        options: [],
      },
      {
        title: "Which app category would it fit into?",
        options: [
          { key: 'Business & finance' },
          { key: 'Education' },
          { key: 'Entertainment & music' },
          { key: 'Food & drink' },
          { key: 'Health & fitness' },
          { key: 'Lifestyle' },
          { key: 'Medical' },
          { key: 'Navigation' },
          { key: 'News' },
          { key: 'Photo & video' },
          { key: 'Productivity' },
          { key: 'Shopping' },
          { key: 'Social Media' },
          { key: 'Travel' },
          { key: 'Utilities' },
          { key: 'Events' },
          { key: 'Sports' },
          { key: 'Other' }
        ],
      },
    ];

    this.getCurrencyOptions();
  }

  getCurrencyOptions() {
    const currencyWiseOptions = {
      INR: [
        { key: "Less than ₹400,000", value: "Less than $5,000" },
        { key: "₹400,000 to ₹2,000,000", value: "$5,000 to $25,000" },
        { key: "₹2,000,000 to ₹4,000,000", value: "$25,000 to $50,000" },
        { key: "₹4,000,000+", value: "$50,0000+" },
      ],

      USD: [
        { key: "Less than $5,000", value: "Less than $5,000" },
        { key: "$5,000 to $25,000", value: "$5,000 to $25,000" },
        { key: "$25,000 to $50,000", value: "$25,000 to $50,000" },
        { key: "$50,000+", value: "$50,0000+" },
      ],

      EUR: [
        { key: "Less than €5,000", value: "Less than $5,000" },
        { key: "€5,000 to €20,000", value: "$5,000 to $25,000" },
        { key: "€20,000 to €40,000", value: "$25,000 to $50,000" },
        { key: "€40,000+", value: "$50,0000+" },
      ],

      SAR: [
        { key: "Less than SAR 20,000", value: "Less than $5,000" },
        { key: "SAR 20,000 to SAR 100,000", value: "$5,000 to $25,000" },
        { key: "SAR 100,000 to SAR 200,000", value: "$25,000 to $50,000" },
        { key: "SAR 200,000 +", value: "$50,0000+" },
      ],

      JPY: [
        { key: "Less than ¥500,000", value: "Less than $5,000" },
        { key: "¥500,000 - ¥2,500,000", value: "$5,000 to $25,000" },
        { key: "¥2,500,000 - ¥5,000,000", value: "$25,000 to $50,000" },
        { key: "¥5,000,000+", value: "$50,0000+" },
      ],

      MYR: [
        { key: "Less than RM 20,000", value: "Less than $5,000" },
        { key: "RM 20,000 to 100,000", value: "$5,000 to $25,000" },
        { key: "RM 100,000 to RM 200,000", value: "$25,000 to $50,000" },
        { key: "RM 200,000+", value: "$50,0000+" },
      ],

      CAD: [
        { key: "Less than C$6000", value: "Less than $5,000" },
        { key: "C$6000 to C$35,000", value: "$5,000 to $25,000" },
        { key: "C$35,000 to C$70,000", value: "$25,000 to $50,000" },
        { key: "C$70,000+", value: "$50,0000+" },
      ],

      AED: [
        { key: "Less than AED 18,000", value: "Less than $5,000" },
        { key: "AED 18,000 to AED 90,000", value: "$5,000 to $25,000" },
        { key: "AED 90,000 to AED 180,000", value: "$25,000 to $50,000" },
        { key: "AED 180,000+", value: "$50,0000+" },
      ],

      SGD: [
        { key: "Less than S$6500", value: "Less than $5,000" },
        { key: "S$6500 to S$30,000", value: "$5,000 to $25,000"},
        { key: "S$30,000 to S$70,000", value: "$25,000 to $50,000" },
        { key: "S$70,0000+", value: "$50,0000+" },
      ],

      GBP: [
        { key: "Less than £5,000", value: "Less than $5,000" },
        { key: "£5,000 to £20,000", value: "$5,000 to $25,000" },
        { key: "£20,000 to £40,000", value: "$25,000 to $50,000" },
        { key: "£40,000+", value: "$50,0000+" },
      ],
    };
    const currencyQuestion = this.bantQuestion.find(x => x.title === 'How much do you expect to spend?');
    const index = this.bantQuestion.indexOf(currencyQuestion);
    this.bantQuestion[index].options = currencyWiseOptions[this.dataService.user.currency.code];
  }

  hideUpdateNotification() {
    this.changesSaved = false;
  }

  changeProfilePic(event) {
    let reader = new FileReader();
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.apiRequest.updateProfile(this.dataService.user,'','','',reader.result).subscribe((resp: any) => {
        });
      }
    }

  }

  displayInfoblock() {
    this.aboutYouTooltip = !this.aboutYouTooltip;
  }

  getSelectedItem(question,index) {
    if (this.dataService.user.bant_answers) {
      let answerIndex = this.dataService.user.bant_answers.findIndex(a => a[0] === question.title);
      if (this.dataService.user.bant_answers && answerIndex!== -1 && this.dataService.user.bant_answers[answerIndex][1] !=="1" && !this.singleUser) {
        return this.showAnswer(this.dataService.user.bant_answers[answerIndex][1]);
      } else if ((this.dataService.user.bant_answers && answerIndex!== -1 && this.dataService.user.bant_answers[answerIndex][1] =="1" && !this.singleUser)){
        return this.phaseService.checkIfSelfServeUser() ?  "1-10 employees" : "1 to 10 people"
      } else if (this.singleUser) {
        this.answers = this.dataService.user.bant_answers.filter(a => {if( a[0] !==  'Size of your company?'){return a}});
        if (this.phaseService.checkIfSelfServeUser()) {
          this.answers.splice(1,0, ['Size of your company?',"1-10 employees"]);
        } else {
          this.answers.splice(1,0, ['Size of your company?',"1 to 10 people"]);
        }
        return this.showAnswer(this.answers[answerIndex][1]);
      }
    }
    else {
      return "Please select"
    }
  }

  setUserContact(contactType, event) {
    if (contactType === 'whatsapp') {
      this.notificationData.whatsapp_phone_number = event;
    } else if (contactType === 'SMS') {
      this.notificationData.sms_phone_number = event;
    }
  }

  setNotificationData() {
    const preferences = this.dataService.user.message_preferences;
    if (preferences && preferences.whatsapp_phone_number) {
      this.notificationData.whatsapp_phone_number = preferences.whatsapp_phone_number.replace('-', '').trim();
      this.notificationData.whatsapp_enabled = preferences.whatsapp_enabled;
    } else {
      this.notificationData.whatsapp_phone_number = this.dataService.user.phone_number.replace('-', '').trim();
    }
    if (preferences && preferences.sms_phone_number) {
      this.notificationData.sms_phone_number = preferences.sms_phone_number.replace('-', '').trim();
      this.notificationData.sms_enabled = preferences.sms_enabled;
    } else {
      this.notificationData.sms_phone_number = this.dataService.user.phone_number.replace('-', '').trim();
    }
    this.setISOCode('whatsapp', this.notificationData.whatsapp_phone_number);
    this.setISOCode('sms', this.notificationData.sms_phone_number);
  }

  checkIfPhoneInValid(contactNumber) {
    const phoneNumber = parsePhoneNumber(contactNumber.toString(), this.countryISOCode.whatsapp);
    if (phoneNumber) {
      return !(phoneNumber.isValid() && contactNumber.includes('+' + phoneNumber.countryCallingCode));
    } else {
      return true;
    }
  }

  toggleEditPreferences() {
    this.isNotificationEditMode = !this.isNotificationEditMode;
    if (!this.isNotificationEditMode) {
      this.isSaveButtonClicked = false;
      this.showCountriesList.whatsapp = false;
      this.showCountriesList.sms = false;
      this.setNotificationData();
    }
  }

  toggleRadioButton(contactType) {
    if (contactType === 'whatsapp') {
      this.notificationData.whatsapp_enabled = !this.notificationData.whatsapp_enabled;
    } else if (contactType === 'SMS') {
      this.notificationData.sms_enabled = !this.notificationData.sms_enabled;
    }
  }

  savePreferences() {
    const whatsappCountryCode = this.countryNameCode.whatsapp,
      smsCountryCode = this.countryNameCode.sms,
      whatsAppNo = this.notificationData.whatsapp_phone_number,
      smsNo = this.notificationData.sms_phone_number;
    this.isSaveButtonClicked = false;
    if (this.checkIfPhoneInValid(whatsAppNo) || this.checkIfPhoneInValid(smsNo)) {
      this.isSaveButtonClicked = true;
      return false;
    }
    this.notificationData.whatsapp_phone_number = whatsAppNo.replace(whatsappCountryCode, (whatsappCountryCode + '-')).replace(/\s/g, '');
    this.notificationData.sms_phone_number = smsNo.replace(smsCountryCode, (smsCountryCode + '-')).replace(/\s/g, '');
    const data = { user : { preferences: this.notificationData } };
    this.dataService.showHideLoader(true);
    this.apiRequest.updateTaxOrPreferencesData(data).subscribe(resp => {
      this.dataService.showHideLoader(false);
      const userObj: UserModel = new UserModel();
      resp.user = this.modelMapperService.getMappedModel(userObj, resp.user);
      this.dataService.user = resp.user;
      this.dataService.storeUserObject();
      this.isSaveButtonClicked = false;
      this.isNotificationEditMode = false;
      this.notificationData.whatsapp_phone_number = this.notificationData.whatsapp_phone_number.replace('-', '');
      this.notificationData.sms_phone_number = this.notificationData.sms_phone_number.replace('-', '');
    }, error => {
      this.dataService.showHideLoader(false);
      this.notificationData.whatsapp_phone_number = this.notificationData.whatsapp_phone_number.replace('-', '');
      this.notificationData.sms_phone_number = this.notificationData.sms_phone_number.replace('-', '');
    });
  }

  getCountriesData() {
    this.apiRequest.getCountriesList('../../../../assets/countries.json').subscribe(resp => {
      this.countriesData = resp;
      let countryNameCodeWhatsapp = '', countryNameCodeSMS = '', countryNameCode = '';
      const user = this.dataService.user;
      const urlParams = this.appDataService.urlParameters;
      if (!this.dataService.user.message_preferences && (user && user.phone_number) || urlParams.phone_number) {
        const phoneNumber = user.phone_number.split('-');
        if (phoneNumber && phoneNumber.length === 2) {
          const countryCode = phoneNumber[0].replace('+', '') ;
          countryNameCode = countryCode.replace(/\s/g, '');
          this.countryNameCode = { whatsapp: countryNameCode, sms: countryNameCode };
        }
      } else {
        if (!this.countryNameCode.whatsapp || !this.countryNameCode.sms) {
          const phoneNumberWhatsapp = parsePhoneNumber(this.dataService.user.message_preferences.whatsapp_phone_number.toString());
          const phoneNumberSms = parsePhoneNumber(this.dataService.user.message_preferences.sms_phone_number.toString());
          if (phoneNumberWhatsapp) {
            countryNameCodeWhatsapp = this.countriesData.find(country => (country.code.toLowerCase() === phoneNumberWhatsapp.country.toLowerCase())).dial_code;
            countryNameCodeWhatsapp = countryNameCodeWhatsapp.replace('+', '');
          }
          if (phoneNumberSms) {
            countryNameCodeSMS = this.countriesData.find(country => (country.code.toLowerCase() === phoneNumberSms.country.toLowerCase())).dial_code;
            countryNameCodeSMS = countryNameCodeSMS.replace('+', '');
          }
        } else {
          countryNameCodeWhatsapp = this.countryNameCode.whatsapp;
          countryNameCodeSMS = this.countryNameCode.sms;
        }
        countryNameCodeWhatsapp = countryNameCodeWhatsapp.replace('+', '').replace(/\s/g, '');
        countryNameCodeSMS = countryNameCodeSMS.replace('+', '').replace(/\s/g, '');
        this.countryNameCode = { whatsapp: countryNameCodeWhatsapp, sms: countryNameCodeSMS };
      }
      const defaultCountry1 = this.countriesData.find(country => country.dial_code.substr(1) === (this.countryNameCode.whatsapp ? this.countryNameCode.whatsapp : this.countryNameCode.sms));
      const defaultCountry2 = this.countriesData.find(country => country.dial_code.substr(1) === this.countryNameCode.sms);
      this.setPhoneNumber(defaultCountry1, '', 'whatsapp');
      this.setPhoneNumber(defaultCountry2, '', 'SMS');
    });
  }

  setPhoneNumber(country, event, contactType?) {
    if (event) {
      event.stopPropagation();
    }
    const user = this.dataService.user;
    if (event || (!user && !this.appDataService.urlParameters.phone_number) || (user && !user.phone_number)) {
      if (contactType === 'whatsapp') {
        this.notificationData.whatsapp_phone_number = country.dial_code;
      } else if (contactType === 'SMS') {
        this.notificationData.sms_phone_number = country.dial_code;
      }
    }
    this.showCountriesList.whatsapp = false;
    this.showCountriesList.sms = false;
    this.countryCodeOrName = '';
    if (contactType === 'whatsapp') {
      this.countryISOCode.whatsapp = country.code;
      this.notificationData.whatsapp_phone_number = this.notificationData.whatsapp_phone_number.replace(/\s/g, '');
      this.notificationData.whatsapp_phone_number = this.notificationData.whatsapp_phone_number.replace(this.countryNameCode.whatsapp, (this.countryNameCode.whatsapp + ' '));
    } else if (contactType === 'SMS') {
      this.countryISOCode.sms = country.code;
      this.notificationData.sms_phone_number = this.notificationData.sms_phone_number.replace(/\s/g, '');
      this.notificationData.sms_phone_number = this.notificationData.sms_phone_number.replace(this.countryNameCode.sms, (this.countryNameCode.sms + ' '));
    }
  }

  toggleCountriesList(event, listType) {
    event.stopPropagation();
    if (listType === 'whatsapp') {
      this.showCountriesList.whatsapp = !this.showCountriesList.whatsapp;
    } else if (listType === 'SMS') {
      this.showCountriesList.sms = !this.showCountriesList.sms;
    }
    this.countryCodeOrName = '';
    if (this.showCountriesList.whatsapp || this.showCountriesList.sms) {
      this.searchNameOrCode.nativeElement.focus();
    }
  }

  acceptNumberOnly(event, contactType): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && ((charCode < 48 && charCode !== 43) || charCode > 57)) {
      return false;
    }
    let phoneNumber;
    if (contactType === 'whatsapp') {
      phoneNumber = parsePhoneNumber(this.notificationData.whatsapp_phone_number.toString(), this.dataService.countryISOCode);
    } else if (contactType === 'SMS') {
      phoneNumber = parsePhoneNumber(this.notificationData.sms_phone_number.toString(), this.dataService.countryISOCode);
    }
    if (phoneNumber) {
      const dialCode = '+' + phoneNumber.countryCallingCode;
      if (contactType === 'whatsapp') {
        this.countryISOCode.whatsapp = this.countriesData.find(country => (country.dial_code === dialCode)).code;
        this.countryNameCode.whatsapp = phoneNumber.countryCallingCode.toString();
      } else if (contactType === 'SMS') {
        this.countryISOCode.sms = this.countriesData.find(country => (country.dial_code === dialCode)).code;
        this.countryNameCode.sms = phoneNumber.countryCallingCode.toString();
      }
    }
    return true;
  }

  setFlagForDialCode(contactType) {
    let phoneNumber;
    if (contactType === 'whatsapp') {
      phoneNumber = parsePhoneNumber(this.notificationData.whatsapp_phone_number.toString(), this.countryISOCode.whatsapp);
    } else if (contactType === 'SMS') {
      phoneNumber = parsePhoneNumber(this.notificationData.sms_phone_number.toString(), this.countryISOCode.sms);
    }
    if (!phoneNumber && this.contactNumber) {
      if (contactType === 'whatsapp') {
        this.setISOCode('whatsapp', this.notificationData.whatsapp_phone_number);
      } else if (contactType === 'SMS') {
        this.setISOCode('sms', this.notificationData.sms_phone_number);
      }
    }
  }

  setISOCode(contactType, contactNumber) {
    const iSOCode = this.countriesData.find(country => (contactNumber.toString().includes(country.dial_code)));
    if (iSOCode) {
      this.countryISOCode[contactType] = iSOCode.code;
    } else {
      this.countryISOCode[contactType] = '';
    }
    if (contactType === 'whatsapp') {
      this.notificationData.whatsapp_phone_number = this.notificationData.whatsapp_phone_number.replace(/\s/g, '');
      this.notificationData.whatsapp_phone_number = this.notificationData.whatsapp_phone_number.replace(this.countryNameCode.whatsapp, (this.countryNameCode.whatsapp + ' '));
    } else if (contactType === 'SMS') {
      this.notificationData.sms_phone_number = this.notificationData.sms_phone_number.replace(/\s/g, '');
      this.notificationData.sms_phone_number = this.notificationData.sms_phone_number.replace(this.countryNameCode.sms, (this.countryNameCode.sms + ' '));
    }
  }

  acceptAlphaNumeric(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode === 32) || (charCode === 43) || (charCode > 47 || charCode < 58) && ((charCode > 64 || charCode < 91)) && ((charCode > 96 || charCode < 123))) {
      return true;
    } else {
      return false;
    }
  }

  showAnswer(questionObj) {
    let value = questionObj['value'] ? questionObj['key'] : questionObj;
    return ((this.phaseService.checkIfSelfServeUser() && (value === 'CEO')) ? 'C-Level' : value);
  }

  getBantQuestionForSelfServe() {
    this.bantQuestion = [
      {
        'title': 'What best describes you?',
        'options': [
          { key: 'Student' },
          { key: 'Solo entrepreneur' },
          { key: 'Manager' },
          { key: 'Director' },
          { key: 'C-Level' },
          { key: 'Other' }
        ]
      },
      {
        'title': 'Size of your company?',
        'options': [{ key: '1-10 employees' },
        { key: '11-50 employees' },
        { key: '51-200 employees' },
        { key: '201-500 employees' },
        { key: '501-1000 employees' },
        { key: '1001-5000 employees' }]
      },
      {
        'title': 'When do you need your software delivered?',
        'options': [{ key: 'Less than 2 months' },
        { key: '2 to 6 months' },
        { key: '6 to 12 months' },
        { key: '12 to 24 months' },
        { key: '24 months +' }]
      },
      {
        'title': 'How much do you expect to spend?',
        'options': []
      },
      {
        'title': 'Which app category would it fit into?',
        'options': [
          { key: 'Business & finance' },
          { key: 'Education' },
          { key: 'Entertainment & music' },
          { key: 'Food & drink' },
          { key: 'Health & fitness' },
          { key: 'Lifestyle' },
          { key: 'Medical' },
          { key: 'Navigation' },
          { key: 'News' },
          { key: 'Photo & video' },
          { key: 'Productivity' },
          { key: 'Shopping' },
          { key: 'Social Media' },
          { key: 'Travel' },
          { key: 'Utilities' },
          { key: 'Events' },
          { key: 'Sports' },
          { key: 'Other' }
        ]
      }
    ];
    this.getCurrencyOptions();
  }

}
