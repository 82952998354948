<!-- Buildcard™ screen start -->
<div *ngIf="dataService.showDeliveryLoading">
    <div class="main-buildcard-bx2">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-md-4 col-sm-12 pull-right">
                    <div class="payment-summery-main">
                        <h3></h3>
                        <div>
                            <ul>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                        <div class="coupon"><span></span></div>
                        <div class="additional-ser">
                            <h3></h3>
                            <span></span>
                        </div>
                        <div class="btn-effect"></div>
                    </div>
                    <div class="dummy-btn"></div>
                </div>
                <div class="col-xs-12 col-md-7 col-sm-12">
                    <div class="main-buildcard-bx2">
                        <div class="container">
                            <div class="row">
                                <div class="col-xs-12 col-md-4 col-sm-12 pull-right">
                                    <div class="payment-summery-main">
                                        <h3></h3>
                                        <div>
                                            <ul>
                                                <li></li>
                                                <li></li>

                                            </ul>
                                        </div>
                                        <div class="coupon"><span></span></div>
                                        <div class="additional-ser">
                                            <h3></h3>
                                            <span></span>
                                        </div>
                                        <div class="btn-effect"></div>
                                    </div>
                                    <div class="dummy-btn"></div>
                                </div>
                                <div class="col-xs-12 col-md-7 col-sm-12">
                                    <div class="buildCardmain2">
                                        <div class="buildCardmain2">
                                            <div class="buildCardTop2">
                                                <div class="buildcardtop-dummy">
                                                    <span></span>
                                                </div>
                                            </div>
                                            <div class="buildcardtab-dummy">
                                                <ul>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="buildcardtab-dummy">
                                            <ul>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div class="buildcardtabdetail_1-dummy">
                                            <h3 class="pro-head-dummy"></h3>
                                            <div class="pro-listDetail-dummy">
                                                <ul>
                                                    <li>
                                                        <strong class="circle"></strong>
                                                        <span></span>
                                                    </li>
                                                    <li>
                                                        <strong class="circle"></strong>
                                                        <span></span>
                                                    </li>
                                                    <li>
                                                        <strong class="circle"></strong>
                                                        <span></span>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Product skeleton loading screen -->
<div class="productModalLoadingScreen" *ngIf="phaseService.showTemplateLoader" [ngClass]="{'detailPage': !dataService.isShowTemplateModal}">
    <div class="appdetailSectionLoading">
        <!-- For Desktop View 
        <div class="imageBlock" [ngClass]="{'desktop': !dataService.isShowTemplateModal}"> -->
        <div class="imageBlock" >
            <div class="mobileImage"></div>
            <div class="previewTitle"></div>
            <ul class="thumbnailList">
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
            </ul>
            <div class="scrollbar">
                <div><span></span></div>
                <div><span></span></div>
            </div>
        </div>
        <div class="descriptionBlock">
            <h2></h2>
            <ul class="labels">
                <li></li>
            </ul>
            <p class="secDescription"></p>
            <p class="secDescription2"></p>
            <p class="secDescription3"></p>

            <div class="platforms">
                <div class="circle"></div>
                <div class="content">
                    <div class="title"></div>
                    <div class="description">
                        <div class="block1"></div>
                        <div class="block2"></div>
                    </div>
                </div>
            </div>
            <div class="platforms">
                <div class="circle"></div>
                <div class="content">
                    <div class="title"></div>
                    <div class="description">
                        <div class="block1"></div>
                        <div class="block2"></div>
                    </div>
                </div>
            </div>
            <div class="btnBox"></div>

            <div class="whatsInclude">
                <div class="platformsBox">
                    <div class="platformInclude"></div>
                    <div class="device"></div>
                </div>
                <div class="deviceDescription">
                    <div class="deviceImage"></div>
                    <div class="deviceName"></div>
                </div>
                <div class="deviceDescription">
                    <div class="deviceImage"></div>
                    <div class="deviceName"></div>
                </div>
            </div>
            <div class="whatsInclude brandLogo">
                <div class="platformsBox">
                    <div class="platformInclude"></div>
                    <div class="device"></div>
                </div>
                <div class="platformsBox bottom">
                    <div class="platformInclude"></div>                    
                </div>
                <div class="deviceDescription">
                    <div class="deviceImage"></div>
                    <div class="deviceName">
                        <div class="brandDesc"></div>
                        <div class="brandDesc bottom"></div>
                    </div>                    
                </div>
            </div>
            <div class="whatsInclude brandLogo">
                <div class="platformsBox">
                    <div class="platformInclude"></div>
                    <div class="device"></div>
                </div>
                <div class="platformsBox bottom">
                    <div class="platformInclude"></div>                    
                </div>
                <div class="deviceDescription">
                    <div class="deviceImage"></div>
                    <div class="deviceName">
                        <div class="brandDesc"></div>
                        <div class="brandDesc bottom"></div>
                    </div>                    
                </div>
            </div>
        </div>        
    </div>
    <div class="bottomBar">
        <span class="textBox"></span>
        <div class="buttonBox"></div>
    </div>
</div>
<!-- Product skeleton loading screen -->
<div *ngIf="!dataService.showAppDetailsLoader && !dataService.showDeliveryLoading">

    <app-template-detail-page *ngIf="!appDataService.urlParameters.is_freemium && dataService.appDetails && dataService.appDetails.pricing_modal !== 'rental_price'">
    </app-template-detail-page>

    <studio-store-template-detail *ngIf="!appDataService.urlParameters.is_freemium && dataService.appDetails.pricing_modal === 'rental_price'" [appDetails]="appDetails">

    </studio-store-template-detail>
    <app-freemium-template-detail  *ngIf="appDataService.urlParameters.is_freemium">
    </app-freemium-template-detail>
</div>
