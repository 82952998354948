<div class="commonPopUp" [ngClass]="getSelectedSpecing() ? 'active' : ''">
  <div class="popOverlay"></div>
  <div class="popHolder scheduleCallpopup">
    <div class="closePopUp" (click)="hideSpecing()" *ngIf="!showRedirectingScreen"><span class="icon-cancel"></span>
    </div>
    <!--screen first start-->
    <div class="scheduleCall" *ngIf="!showRedirectingScreen">
      <div class="topImg"><img src="./../../../assets/images/customer-service.png" alt=""/></div>
      <h3>Schedule Call</h3>
      <p>One of our product expert will connect with you shortly. You will receive an email for details.</p>
      <div class="priceBox"><span
        *ngIf="promotionId">{{dataService.user.currency.symbol}}{{dataService.getLocaledPrice((discountedPrice) | number :
        '1.2-2')}}</span><span
        [ngClass]="{'strick' : promotionId}">{{dataService.user.currency.symbol}}{{dataService.getLocaledPrice((dataService.user.currency.specing_price) | number :
        '1.2-2')}}</span></div>

      <div class="promocodeArea">
        <div class="haveCode" *ngIf="!showPromoInputSection" (click)="clickShowPromo()">Have a Promo Code?</div>

        <form *ngIf="showPromoInputSection">
          <input type="text" class="inputCode" placeholder="Enter Promo Code" name="promoinput"
                 [(ngModel)]="specPromoCode"/>
          <button type="submit" class="applyButton" [disabled]="!specPromoCode" (click)="applyPromotionOnSpec()">Apply
            <img
              *ngIf="isPromoApplication"
              src="./../../../assets/images/buttonLoader.gif" alt=""/></button>
          <span class="errorMsg" *ngIf="errorMessage">{{errorMessage}}</span>
          <span class="successMsg" *ngIf="!errorMessage && promotionId">Promo Code Applied!</span>
        </form>
      </div>

      <div class="footerButton">
        <button type="button" class="scheduleLater" (click)="createSpecCall()">Schedule <img
          *ngIf="isSpecCreation"
          src="./../../../assets/images/rolling.gif" alt=""/></button>
        <h4>“This amount is refundable when you start the Buildcard<sup class="tm">®</sup>.”</h4>
      </div>

      <!-- <div class="scheduleForm">
         <input type="text" placeholder="Enter Email Id"/>
         <button type="submit">Schedule</button>
         <span class="errorMsg">Please enter correct email id to proceed</span>
       </div>-->
    </div>
    <!--screen first close-->

    <!--screen second start-->
    <!--<div class="thankScreen">
      <h3>Thank You!</h3>
      <p>One of our product expert will connect with you shortly. You will receive an email for details.</p>
    </div>-->
    <!--screen second close-->

    <!--screen third start-->
    <div class="thankScreen" *ngIf="showRedirectingScreen">
      <div class="loadingIocn"><img src="./../../../assets/images/spinner1.gif" alt="" /></div>
      <h3>Redirecting</h3>
      <p>You will be redirected to payment gateway.</p>
      <h5>Please do not refresh the page or click "Back" or "Close" button on your browser</h5>
      <specing-payment [paymentAmount]="specCallData.payable_amount" [paymentId]="specCallData.id"></specing-payment>
    </div>
    <!--screen third close-->


  </div>
</div>
