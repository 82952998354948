import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {finalize} from 'rxjs/internal/operators';
import {Router} from '@angular/router';
import { Angulartics2Segment } from 'angulartics2/segment';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'app-freemium-cards',
  templateUrl: './freemium-cards.component.html',
  styleUrls: ['./freemium-cards.component.css']
})
export class FreemiumCardsComponent  {

  @Input() showRecentProgress: boolean;
  @Output() pshowCards = new EventEmitter();
  loaderCount = 0;
  @Input('showMoreIndex') showMoreIndex;
  @Input() showRecentFreemium: boolean;
  showDummyLoader: boolean;

  constructor(public dataService: DataService, public phaseService: PhaseService, private apiRequest: ApiRequests,public analyticsSegment: Angulartics2Segment,
    private router: Router) { }



  showCards(type) {
    this.pshowCards.emit(type);
  }

  getFreemiumCards() {
    return this.dataService.freemiumCards;
  }

  onScroll(cardCount: number) {
    this.showDummyLoader = true;
    if (this.showMoreIndex !== 0 && window.pageYOffset > 0) {
      if (this.dataService.loadMore) {
        if (this.dataService.isScrolled) {
          if (cardCount > 10) {
            let pageNo = 0;
            if (this.phaseService.buildCardPage[this.showMoreIndex]) {
              pageNo = (this.phaseService.buildCardPage[this.showMoreIndex] > 0) ? this.phaseService.buildCardPage[this.showMoreIndex] : 0;
            }
            this.loaderCount = (((pageNo + 1) * 10) >= cardCount) ? 0 : (4 - (((pageNo + 1) * 10) % 4));
          }
          this.dataService.isScrolled = false;
          this.phaseService.buildCardPage[this.showMoreIndex]++;
          this.apiRequest.fetchBuildCards(this.phaseService.buildCardPage[this.showMoreIndex],
            this.phaseService.perPageCardCount, this.phaseService.currentSelectedBuildCardStatus).pipe(
            finalize(() => {
              this.loaderCount = 0;
            }))
            .subscribe(
              () => {
                this.dataService.isScrolled = true;
              }
            );
        }
      }
    }
  }

  openBuildCardPdf(card) {
    if (card.build_card_pdf_url) {
      window.open(card.build_card_pdf_url, '_blank', "noopener");
      this.phaseService.trackEventForObject(this.analyticsSegment, "build_card_downloaded", {
        user_id : this.dataService.user?.id
      });
    }
  }

  proceedToCompleteCard(card) {
   location.href = `${location.origin}/apps/${card.template_id}?exp=seven&promotion=freethree&currency_id=${card.currency.id}&is_freemium=true&uniq_code=${card.uniq_code}`;
  }

  navigateToAdminBoard(card) {
    location.href = card['admin_urls']['table']['store_front_url'];
  }
}
