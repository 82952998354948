import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {finalize} from 'rxjs/operators';
import {Constants} from '@shared/utils/constants';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Router} from '@angular/router';
import { Intercom } from 'ng-intercom';
import {Subscription} from 'rxjs/Rx';

@Component({
  selector: 'app-saved-cards',
  templateUrl: './saved-cards.component.html',
  styleUrls: ['./saved-cards.component.scss']
})
export class SavedCardsComponent  {

  @Output() pshowCards = new EventEmitter();
  @Output() pshowRenameCard = new EventEmitter();

  @Input('showRecentSpecCalls') showRecentSpecCalls;
  @Input('preventClick') preventClick;

  @Input('showCompleted') showCompleted;
  @Input('showInProgress') showInProgress;
  @Input('showPaid') showPaid;
  @Input('showRecentComplete') showRecentComplete;
  @Input('showSpecCalls') showSpecCalls;
  @Input('showRecentRunning') showRecentRunning;
  @Input('showRecentProgress') showRecentProgress;
  @Input('showInvited') showInvited;
  @Input('showArchived') showArchived;
  @Input('showMoreIndex') showMoreIndex;

  public selectedCard: any;
  public loaderCount = 0;
  public showDummyLoader: boolean;
  searchSubscription: Subscription;

  constructor(public dataService: DataService, public phaseService: PhaseService, public apiRequest: ApiRequests,
    public analyticsSegment: Angulartics2Segment,  public router: Router) { }



  public showCards(type) {
    this.pshowCards.emit(type);
  }

  public getHomePagedata() {
    return this.dataService.homePageData;
  }

  public getLocaledPrice(price: any) {
    return this.dataService.getLocaledPrice(price);
  }

  onScroll(cardCount: number) {
    this.showDummyLoader = true;
    if (this.showMoreIndex !== 0 && window.pageYOffset > 0) {
      if (this.dataService.loadMore) {
        if (this.dataService.isScrolled) {
          if (cardCount > 10) {
            let pageNo = 0;
            if (this.phaseService.buildCardPage[this.showMoreIndex]) {
              pageNo = (this.phaseService.buildCardPage[this.showMoreIndex] > 0) ? this.phaseService.buildCardPage[this.showMoreIndex] : 0;
            }
            this.loaderCount = (((pageNo + 1) * 10) >= cardCount) ? 0 : (4 - (((pageNo + 1) * 10) % 4));
          }
          this.dataService.isScrolled = false;
          this.phaseService.buildCardPage[this.showMoreIndex]++;
          // tslint:disable-next-line:max-line-length
          this.apiRequest.fetchBuildCards(this.phaseService.buildCardPage[this.showMoreIndex], this.phaseService.perPageCardCount, this.phaseService.currentSelectedBuildCardStatus).pipe(
            finalize(() => {
              this.loaderCount = 0;
            }))
            .subscribe(
              () => {
                this.dataService.isScrolled = true;
              }
            );
        }
      }
    }
  }

  public openBuildCard(card, isInvite?, showLoader?) {
    this.selectedCard = card;
    if (card.status === Constants.buildCardStatus.CARD_INITIALISED) {

    } else {
      if (card.status === Constants.buildCardStatus.STARTED) {
      }
    }
    if (!this.preventClick) {
      if (!showLoader) {
        this.preventClick = true;
      } else {
        this.dataService.showHideLoader(false);
        // this.dataService.showHideBuildCardLoader(true);
      }
      this.apiRequest.fetchSingleBuildCardData(card.uniq_code).subscribe((data: any) => {

        if (this.dataService.buildCardData.id) {
          this.phaseService.isFromDashBard = true;
          this.dataService.buildCardUpdates = null;
          this.checkPromotionApplied();
          isInvite ? this.phaseService.isInviteEdited = true : this.phaseService.isInviteEdited = false;
          this.phaseService.getEditedBuildCardTimeline(card);
          this.dataService.showHideLoader(false);
          if (card.status === Constants.buildCardStatus.CARD_INITIALISED) {
            this.setupDataAndProceed();
          } else {
            this.setupDataAndShowFullBuildCard();
          }
        }
        this.preventClick = false;
      }, (error) => {
        if (error.status === 403 || error.status === 401) {
          this.dataService.user = null;
          this.phaseService.clearLocalStorage();
          this.phaseService.showSignin = true;
          this.phaseService.openCommonAuth = true;
          this.phaseService.loadDashBoard = true;
          this.dataService.showHideLoader(false);
        }
        this.preventClick = false;
      });
    }
  }

  public checkPromotionApplied() {
    if (this.dataService.buildCardData && this.dataService.buildCardData.id) {
      if (this.isStatusPaymentReceived(this.dataService.buildCardData) || this.isActiveProgressCards()) {
        this.phaseService.manualPromotionApplied = false;
      } else {
        this.phaseService.manualPromotionApplied = true;
      }
      return this.phaseService.manualPromotionApplied;
    }
  }

  public setupDataAndProceed() {
    this.phaseService.setUpInProgressBuildCard();
    this.dataService.unsavedChanges = true;
    this.dataService.isIncompleteCardEditMode = true;
    let link = '';
    if (this.dataService.buildCardData.id) {
      if (this.dataService.buildCardData.progress.buildphases && this.dataService.buildCardData.progress.buildphases.length > 0
        && this.dataService.buildCardData.progress.buildcardphases && this.dataService.buildCardData.progress.buildcardphases.length > 0) {
        link = 'delivery';
      } else if (!this.dataService.buildCardData.progress.apps || !this.dataService.buildCardData.progress.apps.length) {
        this.phaseService.isEmptyBuildCard = true;
        link = 'home';
      } else if (!this.dataService.buildCardData.progress.features || !this.dataService.buildCardData.progress.features.length) {
        sessionStorage.setItem('show_natasha_again', 'not_from_template_page');
        this.phaseService.isEmptyBuildCard = true;
        link = this.dataService.buildCardData.uniq_code + '/feature';
      } else {
        link = '';
      }
    }
    if (link) {
      this.router.navigate([link]);
    } else {
      this.phaseService.reset();
      this.router.navigate([this.dataService.firstPage]);
    }
  }

  public setupDataAndShowFullBuildCard() {
    this.phaseService.setUpInFullBuildCard();
    this.phaseService.fromDashBoard = true;
    this.phaseService.paidPhaseId = null;
    this.router.navigate(['build-card/' + this.phaseService.getIdOrUniqCode()]);
  }

  public isStatusPaymentReceived(card: any): boolean {
    return card.status === Constants.buildCardStatus.STARTED && !card.development_state && !card.kick_off_date;
  }

  public isActiveProgressCards(): boolean {
    return !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling && !this.showSpecCalls &&
      this.showInProgress && !this.showCompleted && !this.showPaid && !this.showArchived && !this.showInvited;
  }



  public showDeleteConfirmation(card) {
    this.dataService.cardToDelete = card;
  }

  public showRenameCard(card) {
    this.pshowRenameCard.emit(card);
  }

  public allowedForInvite(card): boolean {
    return card.owner && card.status !== Constants.buildCardStatus.STARTED && card.status !== Constants.buildCardStatus.UNDER_DEVELOPMENT;
  }

  public getInProgressCards() {
    return this.dataService.inProgressCards;
  }

  getBuildCardsWithSearch(clearSearch?) {
    let page, perPage;
    if (clearSearch) {
      this.dataService.searchText = '';
      this.phaseService.buildCardPage[this.showMoreIndex] = 0;
    } else {
      page = this.phaseService.buildCardPage[this.showMoreIndex];
      perPage = this.phaseService.perPageCardCount;
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    this.searchSubscription = this.apiRequest.fetchBuildCards(page, perPage, this.phaseService.currentSelectedBuildCardStatus).pipe(
      finalize(() => {
        this.loaderCount = 0;
      }))
      .subscribe(
        () => {
          this.dataService.isScrolled = true;
        }
      );
  }

  checkCareType(card) {
    if (card.is_under_support && !card.care_type) {
      return this.dataService.checkIfEnterpriseUserOrCard() ? 'enterprise_care_plus' : 'standard_care';
    } else {
      return card.care_type;
    }
  }

}
