<div class="walletPage" *ngIf="referralService.rewardPointsAPISuccess && referralService.referralWalletData && (referralService.referralWalletData.length > 0)">
    <h2>Wallet</h2>
    <div class="walletSection">
        <div class="walletLeft">
            <div class="totalPoints">
                <h3>Total <strong>{{dataService.getLocaledPriceInt(referralService.totalAmountInWallet)}} pts</strong></h3>
                <button type="button" (click)="openUseBuilderPointsPopup()" [disabled]="(referralService.totalAmountInWallet <= 0)">Use Builder Points</button>
            </div>
            <div class="referralLink">
                <h3>Referral link <span>{{referralService.getShareURLLink()}}</span></h3>
                <button type="button" (click)="referralService.showShareReferralPopup()">Share link</button>
            </div>
        </div>

        <div class="walletRight">
            <table summary="">
                <tr>
                    <th>Action</th>
                    <th>Date</th>
                    <th>Transaction points</th>
                    <th>New balance</th>
                </tr>
                <tr *ngFor="let walletData of referralService.referralWalletData">
                    <td><div class="mobileHeading">Action</div> {{walletData.transaction_type}}</td>
                    <td><div class="mobileHeading">Date</div> {{walletData.created_at | date :  'dd-MMM-y'}}</td>
                    <td [ngClass]="{purpalColor: (getWalletTransactionPointsSign(walletData) === '+')}"><div class="mobileHeading">Transaction points</div> {{getWalletTransactionPointsSign(walletData)}}{{walletData.transaction_point}}</td>
                    <td><div class="mobileHeading">New balance</div> {{walletData.new_balance}}</td>
                </tr>
            </table>
        </div>
    </div>
</div>

<app-referral-points-usage *ngIf="referralService.showUseBuilderPointsPopup"></app-referral-points-usage>
<app-share-referral *ngIf="referralService.openShareReferralPopup"></app-share-referral>
<app-referral-component *ngIf="referralService.rewardPointsAPISuccess && (referralService.referralWalletData.length === 0)"></app-referral-component>
