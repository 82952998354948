<div class="commonPopUp active">
  <div class="popOverlay"></div>
  <div class="popHolder shareReferralPopup">
    <div class="closePopUp" (click)="closeShareReferralPopup()"><em class="icon-cancel"></em></div>
    <h3>Share referral link</h3>
    <ul>
      <li><span>Copy link</span> <span (click)="trackEvent('Copy link')"><share-buttons theme="circles-dark"
        [include]="['copy']"
        [showIcon]="true"
        [showText]="false"
        url="https://www.builder.ai/ref?code={{dataService.user?.referral_code}}"
        description="Referral link share"
        class="pt-5"
        [autoSetMeta]="false">
      </share-buttons></span></li>
      <li><span>Email</span> <span (click)="trackEvent('Email')"><share-buttons theme="circles-dark"
        [include]="['email']"
        [showIcon]="true"
        [showText]="false"
        url="https://www.builder.ai/ref?code={{dataService.user?.referral_code}}"
        description="Hey, I found Builder.ai - they're great app builders so I thought of you. Follow the link below and we'll both get a discount! Give me a shout if you want to chat about why I like them. Thanks,"
        class="pt-5"
        [autoSetMeta]="false">
      </share-buttons></span></li>
      <li><span>Whatsapp</span> <span (click)="trackEvent('Whatsapp')"><share-buttons theme="circles-dark"
        [include]="['whatsapp']"
        [showIcon]="true"
        [showText]="false"
        url="https://www.builder.ai/ref?code={{dataService.user?.referral_code}}"
        description="Do you know Builder.ai? They're great app builders so I thought of you. I can get both of us a discount on our apps, just follow this link: "
        class="pt-5"
        [autoSetMeta]="false">
      </share-buttons></span></li>
      <li><span>Linkedin</span> <span (click)="trackEvent('Linkedin')"><share-buttons theme="circles-dark"
        [include]="['linkedin']"
        [showIcon]="true"
        [showText]="false"
        url="https://www.builder.ai/ref?code={{dataService.user?.referral_code}}"
        description="Do you know Builder.ai? I found these great app builders, so I thought of you. And we both get a discount, if you follow this link: "
        class="pt-5"
        [autoSetMeta]="false">
      </share-buttons></span></li>
      <li><span>Facebook</span> <span (click)="trackEvent('Facebook')"><share-buttons theme="circles-dark"
        [include]="['facebook']"
        [showIcon]="true"
        [showText]="false"
        url="https://www.builder.ai/ref?code={{dataService.user?.referral_code}}"
        description="I found Builder.ai - they're great app builders so I thought of you. Follow this link and we'll both get money off our apps!"
        class="pt-5"
        [autoSetMeta]="false">
      </share-buttons></span></li>
      <!-- <li><span>Gmail</span> <span><img src="../../../../../assets/images/gmail-icon.png" alt="" /></span></li>
      <li><span>Outlook</span> <span><img src="../../../../../assets/images/outlook-icon.png" alt="" /></span></li> -->
    </ul>

    <!-- <div class="alsoVia">
        <h6><span>also via</span></h6>
        <ol>
          <li><img src="../../../../../assets/images/whatsup-icon.png" alt="" /></li>
          <li><img src="../../../../../assets/images/linked-icon.png" alt="" /></li>
          <li><img src="../../../../../assets/images/facebook-icon.png" alt="" /></li>
        </ol>
    </div> -->
  </div>
</div>
