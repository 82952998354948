import {Component, OnInit} from '@angular/core';
import {PartnerDashboardDataService} from '../../Utils/partner-dashboard-data.service';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {PartnerDashboardApiService} from '../../Utils/partner-dashboard-api.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'app-partner-contracts',
  templateUrl: 'partner-contracts.component.html'
})

export class PartnerContractsComponent implements OnInit {
  public loader = false;

  constructor(public partnerDataService: PartnerDashboardDataService,
              private _partnerApiService: PartnerDashboardApiService,
              public phaseService: PhaseService,
              public dataService: DataService,  public analyticsSegment: Angulartics2Segment) {
  }

  public ngOnInit() {
    this.fetchAllContracts();
  }

  public fetchAllContracts(): void {
    this.dataService.showHideLoader(true);
    this._partnerApiService.fetchContractDetails(this.partnerDataService.getPartnerUser().id)
      .subscribe(
        (data) => {
          this.partnerDataService.contracts = data;
          this.dataService.showHideLoader(false);
        },
        (error) => {
          this.dataService.showHideLoader(false);
        }
      );
  }

  public fetchDocSignUrl(): void {
    const queryString = '?builder_partner_id=' + this.partnerDataService.getPartnerUser().id + '&redirect_url=' + this.phaseService.getRedirectUrl();
    this.dataService.showHideLoader(true);

    this._partnerApiService.fetchDocSignUrl(queryString)
      .subscribe(
        (data) => {
          this.dataService.showHideLoader(false);
          window.location.href = data.docusign_url;
        },
        (error) => {
          this.dataService.showHideLoader(false);
        }
      );
  }

  public signMasterContract(contract): void {
    if (contract.id) {
      const query = '?record_id=' + contract.id + '&redirect_url=' + this.phaseService.getRedirectUrl();

      this._partnerApiService.fetchPartnerContractUrl(query)
        .subscribe(
          (data) => {
            window.location.href = data.signing_url;
          }
        );
    }
  }

  public viewContract(contract): void {
    window.open(contract, '_blank', "noopener");
  }

  public submitForVerification() {
    if (this.partnerDataService.partnerContractsSigned()) {
      this.partnerDataService.partnerStatus = 'submit_for_verification';
      this.loader = true;

      this._partnerApiService.updatePartnerProfile()
        .subscribe(
          (data) => {
            this.partnerDataService.setPartnerUser(data);
            this.dataService.resetPartnerViews();
            this.dataService.showHideViews.profile = true;
            this.dataService.allContractsSigned = true;
            this.loader = false;
          },
          (error) => {
            this.loader = false;
          }
        );
    }
  }
}
