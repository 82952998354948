import { Component, Input, OnInit } from '@angular/core';
import { PhaseService } from '@shared/services/phase.service';
import { Router } from '@angular/router';
import { DataService } from '@shared/services/data.service';
import { Angulartics2Segment } from 'angulartics2/segment';
import { Intercom } from 'ng-intercom';
import { ApiRequests } from '@shared/services/api-requests.service';
import {CommonService} from '@shared/services/common.service';

@Component({
  selector: 'rental-parent',

  templateUrl: './rental-parent.component.html',
  styleUrls: ['./rental-parent.component.scss']
})
export class RentalParentComponent {

  @Input() appDetails;
  showCancelOptions = false;
  constructor(public phaseService: PhaseService, private router: Router, public dataService: DataService,private apiRequest: ApiRequests,
    public analyticsSegment: Angulartics2Segment, private commonService: CommonService) {
  }


  closeModal() {
    if (this.dataService.currentPage === 'templateDetail' && (this.phaseService.checkIfRentalPricingModal() || this.phaseService.isRentalBuildCard())) {
      this.phaseService.showCurrencyOptionsForBnow = false;
      this.phaseService.currencyForNow = null;
    }
    if (this.phaseService.isRentalCardPaid) {
      this.openBeforeLeavingSchedulerPopup()
      return;
    } else {
      this.phaseService.startPollingForPayment(true);
    }
    this.phaseService.showRentalPaymentFlow = false;
    this.phaseService.showCallBookedSuccess = false;
    this.dataService.paymentUrl = '';
    if (this.phaseService.windowToCLose) {
      this.phaseService.windowToCLose.close();
      this.phaseService.windowToCLose = null;
    }
  }

  openBeforeLeavingSchedulerPopup() {
    let callType = 'kickoff';
    this.dataService.showHideLoader(true);
    let specId = (this.phaseService.specCallCreationData && this.phaseService.specCallCreationData.id) ? this.phaseService.specCallCreationData.id : this.dataService.specingId ? this.dataService.specingId : this.dataService.buildCardData.id;
    this.apiRequest.confirmSpecCallBooked(callType, specId).subscribe((resp) => {
      this.dataService.showHideLoader(false);
      if (resp.message !== 'success') {
        this.phaseService.showBeforeLeavingSchedulerPopup = true;
      }else{
        this.dataService.openSchedulerPopup = false;
        this.dataService.showMeetNowModel = false;
        this.phaseService.showScheduleCallFlow = false;
        this.phaseService.specCallPopupAppeared = true;
        this.phaseService.isFromDashBard = false;
        this.router.navigate(['dashboard']);
        if (this.goToTaxDetailsSection()) {
          this.phaseService.showTaxAndBilling = true;
        }
      }
    }, error => {
      this.dataService.showHideLoader(false);
      this.phaseService.showBeforeLeavingSchedulerPopup = true;
    });
  }

  scheduleCallLater(cancelReason?) {
    this.dataService.showHideLoader(true);
    let reason = {kickoff_cancel_reason : cancelReason}
    const buildCardId = this.dataService.buildCardData.id ? this.dataService.buildCardData.id : this.dataService.specingId;
    this.apiRequest.kickOffCancelReason(reason, buildCardId).subscribe((data: any) => {
      this.closeSpecModal();
      this.router.navigate(['dashboard']);
      if (this.goToTaxDetailsSection()) {
        this.phaseService.showTaxAndBilling = true;
      }
      this.dataService.showHideLoader(false);
    });
  }

  showOptions(){
    this.showCancelOptions = !this.showCancelOptions;
  }

  closeSpecModal() {
    this.phaseService.showBeforeLeavingSchedulerPopup = false;
  }

  scheduleCallNow() {
    this.closeSpecModal();
  }

  goToTaxDetailsSection() {
    const user = this.dataService.user;
    return (((user.currency.code === 'INR') && (user.address.billed_as === 'individual')) ||
      (user.address.billed_as === 'company'));
  }
}
