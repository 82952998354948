import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'phases-mode',
  templateUrl: './phases-mode.component.html',
  styleUrls: ['./phases-mode.component.scss']
})
export class PhasesModeComponent {
  @Output() discardCallBack: EventEmitter<boolean> = new EventEmitter(false);



  closeDiscardAdvancedModePopup(isDiscard) {
    this.discardCallBack.emit(isDiscard);
  }
}
