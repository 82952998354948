import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PaymentSummaryComponent} from '@featuremodules/payment-summary-module/payment-summary/payment-summary.component';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '@shared/shared.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { UpgradePaymentSummaryComponent } from './upgrade-payment-summary/upgrade-payment-summary.component';
import { ReferralProgramModule } from '@featuremodules/referral-program-module/referral-program.module';

@NgModule({
  declarations: [PaymentSummaryComponent, UpgradePaymentSummaryComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgxSliderModule,
    ReferralProgramModule
  ],
  exports: [
    PaymentSummaryComponent
  ]
})
export class PaymentSummaryModule { }
