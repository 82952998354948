import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PartnerDashboardRoutingModule} from './partner-dashboard.routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {PartnerMainComponent} from './components/main/main.component';
import {PartnerConfigurationComponent} from './components/configuration/configuration.component';
import {PartnerHomeComponent} from './components/home/home.component';
import {PartnerProfileComponent} from './components/profile/profile.component';
import {PartnerVerifyComponent} from './components/verify/verfify.component';
import {PartnerConfirmationComponent} from './components/confirmation/confirmation.component';
import {PartnerDashboardApiService} from './Utils/partner-dashboard-api.service';
import {PartnerDashboardDataService} from './Utils/partner-dashboard-data.service';
import {SharedModule} from '@shared/shared.module';
import {PartnerContractsComponent} from './components/contracts/partner-contracts.component';
import {TagInputModule} from 'ngx-chips';
// import {MatFormFieldModule, MatInputModule, MatOptionModule, MatSelectModule} from '@angular/material';
import {PartnerPaymentComponent} from './components/payment/app-payment.component';
import {PartnerPaymentSubmissionComponent} from './components/payment/partner-payment-submission/partner-payment-submission.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PartnerDashboardRoutingModule,
    SharedModule,
    TagInputModule
    // MatFormFieldModule,
    // MatInputModule,
    // MatSelectModule,
    // MatOptionModule
  ],
  declarations: [
    PartnerMainComponent,
    PartnerConfigurationComponent,
    PartnerHomeComponent,
    PartnerProfileComponent,
    PartnerVerifyComponent,
    PartnerConfirmationComponent,
    PartnerContractsComponent,
    PartnerPaymentComponent,
    PartnerPaymentSubmissionComponent
  ],
  providers: [
    PartnerDashboardApiService,
    PartnerDashboardDataService
  ]
})

export class PartnerDashboardModule {
}
