import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Angulartics2Segment } from 'angulartics2/segment';
import { PhaseService } from '@shared/services/phase.service';
import { PaymentService } from '@shared/services/payment.service';
import { DataService } from '@shared/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiRequests } from '@shared/services/api-requests.service';
import { CommonService } from '../../../../shared/shared-module/services/common.service';
import { AppDataService } from '@rootmodule/app-data.service';
import { InstallmentModel } from '@models/feature-model/change-feature-request.model';
import { Intercom } from 'ng-intercom';
import moment from 'moment';
import {stopPropagation} from 'ngx-scrollbar/lib/scrollbar/common';
import { Options } from '@angular-slider/ngx-slider';
import { ReferralService } from '@shared/services/referral.service';
import {PaymentBuilderService} from '@featuremodules/payment-builder-module/payment-builder.service';
import {BillingDetailsService} from '@shared/services/billing-details.service';

@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss'],
})
export class PaymentSummaryComponent implements OnInit {
  showApplyPromotion = false;
  showSupportAndMaintenance = false;
  showDueNowInfo = false;
  showSecurityDepositInfo = false;
  showTotalPriceBreakup = false;
  showAdditionalFeaturePriceBreakup = false;
  public additionalServiceMobileview = false;
  public dueNowMobileview = false;
  isContinueEnable = false;
  checkToDisable = false;
  customPrototypeInstallment: any;
  showCloudDescription = false;
  isActiveOldSummary = true;
  editcardMobileView = false;
  updateFrequencySubscription: any;
  additionalFeatureTooltip = false;
  builderCareTooltip = false;
  fileSizeValid: boolean;
  showDeliveryInfo = false;
  moment: any;
  showDonationDescription = false;
  showDonationFundDescription = false;
  barValue = 5;
  @ViewChild('evidence') evidence : ElementRef<HTMLElement>;
  fromAddServices = false;
  options: Options = {
    showTicksValues: true,
    showSelectionBar: true,
    stepsArray: [
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10 }
    ]
  };
  uniq_code : any;
  showFixedCostTooltip = false;
  referralCode: string;
  showOldTotalPriceBreakup = false;

  constructor(public analyticsSegment: Angulartics2Segment, public intercom: Intercom, public phaseService: PhaseService, public dataService: DataService,
    public router: Router, public apiRequest: ApiRequests, private _route: ActivatedRoute, public commonService: CommonService, private paymentService: PaymentService,
    public appDataService: AppDataService, private route: ActivatedRoute, public paymentBuilderService: PaymentBuilderService,
              public referralService: ReferralService, private billingService: BillingDetailsService) {
    this.phaseService.supportMaintenance = 0;
    this.moment = moment;
  }

  ngOnInit() {
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    this.fileSizeValid = true;
    if (!this.phaseService.DocUploadedName || (this.phaseService.DocUploadedName === 'Find file')) {
      if (!this.phaseService.SelectedDoc) {
        this.phaseService.DocUploadedName = 'Find file';
      } else if (this.phaseService.SelectedDoc) {
        this.phaseService.DocUploadedName = this.phaseService.SelectedDoc.name;
      }
    }
    if (this.dataService.user) {
      if (this.isPaymentType()) {
        if (this.dataService.currentPage === 'payConfirm') {
          if (!this.dataService.buildCardData.uniq_code) {
            this.getBuildCardIDOnReload();
          }
        }
        this.dataService.defaultCurrency = this.dataService.buildCardData.currency;
        this.phaseService.currencySymbol = this.dataService.user.currency.symbol;
        if (this.dataService.currentPage !== 'paymentPlan') {
          if (!this.dataService.homePageData.platforms) {
            this.phaseService.timerStart();
            this.apiRequest.fetchHomePageData().subscribe((data: any) => {
              this.dataService.dataLoadingCurrency = Object.assign({}, this.dataService.homePageData.currency);
              this.phaseService.platformPrices = Array.apply(null, Array(this.dataService.homePageData.platforms.length));
              this.phaseService.platformPrices.map((x, i) => {
                return i;
              });
              if (this.dataService.homePageData.currency) {
                this.phaseService.specing_price = this.dataService.homePageData.currency.specing_price;
              }
              if (this.dataService.buildCardData.uniq_code) {
                this.fetchSingleBuildCardData(this.dataService.buildCardData.uniq_code);
              }
            });
          } else {
            if (this.dataService.buildCardData.uniq_code) {
              this.fetchSingleBuildCardData(this.dataService.buildCardData.uniq_code);
            }
          }
        }
      }
      if(this.dataService.user.enable_referral_code){
        this.referralService.getTransactionDataForReferralWallet();
      }

    } else {
      this.dataService.invalidAccess = true;
      this.router.navigate(['invalidaccess']);
    }
  }

  public editcardMobileClick() {
    this.editcardMobileView = !this.editcardMobileView;
  }

  public serviceboxMobile() {
    this.additionalServiceMobileview = !this.additionalServiceMobileview;
  }

  public dueNowBoxMobile() {
    this.dueNowMobileview = !this.dueNowMobileview;
  }

  createPrototypeInstallment() {
    this.apiRequest.createCustomPrototypeInstallment().subscribe((resp) => {
      this.phaseService.paymentData.custom_prototype = resp.data[0].attributes;
      this.phaseService.isInstallmentsAPIResolved = true;
    }, error => {
      this.phaseService.isInstallmentsAPIResolved = true;
    });
  }

  public fetchSingleBuildCardData(uniqueCode) {
    if (!(this.phaseService.paymentData && this.phaseService.paymentData.installments && (this.phaseService.paymentData.installments.length > 0))) {
      this.apiRequest.fetchSingleBuildCardData(uniqueCode).subscribe((data: any) => {
        if (this.dataService.buildCardData.uniq_code) {
          this.phaseService.inProgressBuildCardId = this.dataService.buildCardData.id;
          this.phaseService.inProgressUniqCode = this.dataService.buildCardData.uniq_code;
          this.phaseService.setUpInFullBuildCard();
          this.phaseService.setTheBuildCard();
          const card = this.dataService.buildCardData;
          if (this.dataService.buildCardData && this.dataService.buildCardData.nda_signed) {
            this.phaseService.setContractTermsAndConditions();
            this.paymentBuilderService.showMSATooltip = false;
            this.paymentBuilderService.isMSALinkCLicked = true;
          } else if (this.dataService.buildCardData && !this.dataService.buildCardData.nda_signed) {
            this.phaseService.resetTermsAndConditions();
          }
          this.phaseService.fetchContractDetails(uniqueCode);
          if (card.progress.apps && (card.progress.apps.length > 0)) {
            this.phaseService.getAdditionalFeaturesFroRental();
          }
          if (this.appDataService.urlParameters.isCustomPrototype) {
            this.createPrototypeInstallment();
          } else if (this.dataService.currentPage !== 'paymentPlan') {
            this.phaseService.fetchInstallments();
          }
        }
      }, (error) => {
        if (this.commonService.isPlatformBrowser && (error.status === 403 || error.status === 401 || error.status === 404)) {
          this.dataService.invalidAccess = true;
          this.router.navigate(['invalidaccess']);
        }
      });
    }
  }

  public getBuildCardIDOnReload(): void {
    this._route.parent.params.subscribe(params => {
      const uniq_code = params['id'];
      this.dataService.buildCardData.uniq_code = uniq_code;
    });
  }


  public getCurrencySymbol() {
    return this.dataService.buildCardData.id ? this.dataService.buildCardData.currency.symbol : this.phaseService.currencySymbol;
  }

  public getLocaledPrice(price: any): any {
    return this.dataService.getLocaledPrice(price, this.dataService.buildCardData.currency);
  }

  public isEditFeatureRequest() {
    return (this.phaseService.isFeatureRequest || this.dataService.isEditRequestedFeatures);
  }

  public getTotalWithTax() {
    return this.phaseService.getTotalWithTax();
  }

  public getAdditionalFeatureCostWithTax() {
    return ((this.dataService.totalAdditionalFeaturesCost * (100 + Number(this.dataService.changeFeatureRequestModel.general_info.tax))) / 100);
  }

  public getProjectCost() {
    return Number(this.getDiscountedprice(this.dataService.buildCardData));
  }

  public getTaxAmount() {
    return Number(this.phaseService.getTaxOnTotal());
  }

  public getAdditionalFeatureTaxAmount() {
    return Number(Math.round(((Number(this.dataService.totalAdditionalFeaturesCost) * Number(this.dataService.changeFeatureRequestModel.general_info.tax)) / 100)));
  }

  public getInstallmentWithTax(installmentOrAmount: any) {
    if (this.dataService.buildCardData.subscription_flow && !installmentOrAmount.installment_amount) {
      return installmentOrAmount;
    } else if (installmentOrAmount) {
      if (this.isDonationOpted() && (installmentOrAmount.type !== 'support')) {
        return this.phaseService.getInstallmentWithTax(installmentOrAmount) + this.donationAmount();
      } else {
        return this.phaseService.getInstallmentWithTax(installmentOrAmount);
      }

    }
  }

  public getInstallmentWithoutTax(installment: any) {
    if (installment) {
      if (installment.type === 'deposit') {
        this.phaseService.totalDepositAmount = Number(installment.installment_amount);
        return this.phaseService.totalDepositAmount;
      } else {
        return Number(installment.installment_amount);
      }
    }
  }

  public getSupportMaintenance() {
    if (this.getBuildCardData().type === 'rental_price' && this.getBuildCardData().source === 'app_detail') {
      this.phaseService.selectedHash.is_under_support = false;
      return false;
    }
    if (!this.isPaymentType() && this.getBuildCardData().support_yearly_price) {
      const modifiesData = this.dataService.modifiedBuildCardData;
      return ((modifiesData && modifiesData.support_maintenance_price.new) ? modifiesData.support_maintenance_price.new : this.getBuildCardData().support_yearly_price);
    } else if (this.dataService.modifiedBuildCardData && this.dataService.modifiedBuildCardData.support_maintenance_price.new) {
      return this.getModifiedBuilderCarePrice();
    } else {
      return this.phaseService.builderCareAmount;
    }
  }

  public donationAmount() {
    // return this.getBuildCardData().donation_amount;
    return false;
  }


  public isDonationOpted() {
    return this.getBuildCardData().donation_opted;
  }

  public toggleDonationAmount() {
    this.getBuildCardData().donation_opted = this.getBuildCardData().donation_opted ? false : true;
    this.phaseService.donationOpted = this.getBuildCardData().donation_opted;
    this.barValue = this.getBuildCardData().donation_percentage;
    this.calculateDonationAmount(this.getBuildCardData().donation_percentage);
  }

  public getDeposit() {
    if (this.appDataService.urlParameters.isCustomPrototype) {
      return this.getPaymentData().custom_prototype;
    }
    if (this.dataService.showWeekly) {
      return this.phaseService.weeklyDeposit;
    } else if (this.dataService.showMonthly) {
      return this.phaseService.monthlyDeposit;
    } else if (this.dataService.showUpfront) {
      return this.phaseService.upfrontInstallment;
    } else {
      return this.phaseService.deposit;
    }
  }

  public getDepositTaxAmount() {
    if (this.phaseService.isRentalBuildCard() && this.dataService.currentPage === 'billingDetails' && this.phaseService.paymentData
      && this.phaseService.paymentData.pending_installment) {
      return this.phaseService.paymentData.pending_installment.tax_amount;
    }
    if (this.getBuildCardData().promotion && this.getBuildCardData().promotion.type === 'GlobalPromotion' && this.phaseService.isDiscountApplied()) {
      return (this.getBuildCardData().currency.tax > 0) ? (this.getBuildCardData().promotion.deposit * (this.getBuildCardData().currency.tax / 100)) : 0;

    } else if (this.appDataService.urlParameters.isCustomPrototype && this.getPaymentData().custom_prototype) {
      return this.getPaymentData().custom_prototype.tax_amount;
    } else {
      if (this.dataService.showWeekly && this.phaseService.weeklyDeposit) {
        return this.phaseService.weeklyDeposit.tax_amount;
      } else if (this.dataService.showMonthly && this.phaseService.monthlyDeposit) {
        return this.phaseService.monthlyDeposit.tax_amount;
      } else {
        return (this.phaseService.deposit && this.phaseService.deposit.tax_amount) ? Number(this.phaseService.deposit.tax_amount) : 0;
      }
    }
  }

  checkIfFormValid() {
    for (const key of Object.keys(this.phaseService.billingInfo)) {
      if ((key !== 'city') && (key !== 'companyName') && (key !== 'address_line_2') && (key !== 'apartment') && (key !== 'billing_entity')) {
        if ((this.phaseService.billingInfo[key] === '') && ((this.phaseService.showGSTIN && this.phaseService.isGSTCheckboxChecked) ? ((key !== 'name') && (key !== 'address')) : ((key !== 'name') && (key !== 'companyName') && (key !== 'address') && (key !== 'apartment') && (key !== 'gstNumber')))) {
          return false;

        }
      }
      if ((this.phaseService.billingInfo[key] === '') && this.billingService.isCompanyAddress && ((key === 'companyName'))) {
        return false;

      }
    }
    return true;
  }

  public continueToPayment() {
    if ((this.dataService.currentPage === 'paymentPlan' && !this.phaseService.proceedToContinuePayment && !this.phaseService.skipSignNDA) || this.isUserInviteeOfBuildCard()
      || this.dataService.isEditBuildCardAfterSecurityPaid()) {
      return;
    }
    if (this.dataService.currentPage === 'billingDetails') {
      if (!this.checkIfFormValid() && (this.dataService.currentPage === 'billingDetails')) {
        if (screen.width < 768) {
          this.isContinueEnable = true;
          this.checkToDisable = this.phaseService.skipSignNDA ? false : this.phaseService.proceedToContinuePayment ? false : true;
        }
        return false;
      }
      if (!this.dataService.user.address_disabled && (!this.dataService.user.address || this.paymentBuilderService.checkIfAddressUpdated())) {
        this.dataService.submitAddress.next('submit_address');
      } else {
        this.router.navigate([`buildcard-payment/${this.dataService.buildCardData.uniq_code}/` + 'paymentPlan']);
        // this.dataService.submitAddress.next('go_to_payment_page');
      }
    }
  }

  public ApplyPromotion(pageName) {
    let eventValue: string;
    switch (pageName) {
      case 'delivery':
        eventValue = 'Delivery';
        break;
      case 'features':
        eventValue = 'Feature';
        break;
      case 'summary':
        eventValue = 'Buildcard';
        break;
      case 'paymentPlan':
        eventValue = 'Payment Plan';
        break;
      case 'billingDetails':
        eventValue = 'Billing Details';
        break;
      case 'payConfirm':
        eventValue = 'Contract,Confirm and Pay';
    }
    this.phaseService.promoError = null;
    this.phaseService.promotionError = null;
    this.showApplyPromotion = true;
    this.phaseService.priceCalculated = true;
    this.phaseService.openSideNav = true;
    this.phaseService.fetchPromotionList();
  }

  public hideShowTotalPriceBreakup() {
    this.showTotalPriceBreakup = !this.showTotalPriceBreakup;
  }

  public hideShowAdditionalFeaturePriceBreakup() {
    this.showAdditionalFeaturePriceBreakup = !this.showAdditionalFeaturePriceBreakup;
  }

  public hideShowSupportAndMaintenance(showSupport: boolean, isFromAddServices?) {
    if (showSupport) {
      this.phaseService.trackEventForObject(this.analyticsSegment,  'builder_care_explored', {
        user_id: this.dataService.user?.id
      });
    }
    this.showSupportAndMaintenance = showSupport;
    this.showDueNowInfo = false;
    this.showSecurityDepositInfo = false;
    this.showCloudDescription = false;
    this.showDeliveryInfo = false;
    this.showDonationDescription = false;
    this.showDonationFundDescription = false;
    this.fromAddServices = false;
    this.showFixedCostTooltip = false;
    if (isFromAddServices) {
      this.fromAddServices = true;
    }
  }

  public hideShowDueNowInfo(showDue: boolean) {
    if (showDue) {
      this.phaseService.trackEventForObject(this.analyticsSegment,  "due_now_tooltip_explored", {
        user_id: this.dataService.user?.id
      });
    }
    this.showDueNowInfo = showDue;
    this.showSecurityDepositInfo = false;
    this.showSupportAndMaintenance = false;
    this.showDeliveryInfo = false;
    this.showDonationFundDescription = false;
    this.showCloudDescription = false;
    this.showDonationDescription = false;
  }

  public hideShowSecurityDepositInfo(showSecurity: boolean) {
    if (showSecurity) {
      this.phaseService.trackEventForObject(this.analyticsSegment,  "security_deposit_explored", {
        user_id: this.dataService.user?.id
      });
    }
    this.showSecurityDepositInfo = showSecurity;
    this.showDueNowInfo = false;
    this.showSupportAndMaintenance = false;
    this.showDeliveryInfo = false;
    this.showDonationFundDescription = false;
    this.showCloudDescription = false;
    this.showDonationDescription = false;
  }

  public getPerWeekOrMonthCost() {
    return ((this.dataService.buildCardData && this.dataService.buildCardData.installment_amount) ?
      this.dataService.buildCardData.installment_amount
      : 0);
  }

  toggleToolTip(showMsg) {
    if (showMsg) {
      this.isContinueEnable = true;
      this.checkToDisable = !this.checkIfFormValid();
    } else {
      this.isContinueEnable = false;
      this.checkToDisable = false;
    }
  }

  public getBuildCardData() {
    return this.dataService.buildCardData;
  }

  public isPaymentType() {
    return (this.dataService.currentPage === 'summary' ? false : true);
  }

  public showUpdatedInstallments() {
    this.phaseService.updatedInstamentsClicked = true;
    if (!this.appDataService?.urlParameters.cpe_user) {
      if (this.phaseService.Feedback_Answers && (Object.keys(this.phaseService.Feedback_Answers).length === this.dataService.additionalFeatures.length)) {
        this.phaseService.allQuestionsAnswered = true;
      }
      if ((this.phaseService.allQuestionsAnswered && this.dataService.user.roles !== 'CPE') || (this.phaseService.allQuestionsAnswered && !this.phaseService.DocNotUploaded)) {
        this.phaseService.changeRequestBody.change_request.reason = this.phaseService.Feedback_Answers;
        this.phaseService.isInstallmentsChanged = true;
        const uniqueCode = this.dataService.changeFeatureRequestModel.build_card ? this.dataService.changeFeatureRequestModel.build_card.uniq_code : this.dataService.buildCardData.uniq_code;
        this.router.navigate([`buildcard-payment/${uniqueCode}/` + 'paymentPlan']);
        this.phaseService.showDocUploadArea = false;
      }
    }
    else {
      this.phaseService.isInstallmentsChanged = true;
      const uniqueCode = this.dataService.changeFeatureRequestModel.build_card ? this.dataService.changeFeatureRequestModel.build_card.uniq_code : this.dataService.buildCardData.uniq_code;
      this.router.navigate([`buildcard-payment/${uniqueCode}/` + 'paymentPlan']);
    }
  }

  clickLabel(){
    let el: HTMLElement = this.evidence.nativeElement;
   this.phaseService.DocNotUploaded ? el.click() : this.downloadFile()
  }

  downloadFile() {

        if(!this.phaseService.SelectedDoc){
          return;
        }
        let blob = new Blob([this.phaseService.SelectedDoc]);
        let link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", this.phaseService.changeRequestBody.change_request['file_name']);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

  }

  onFileSelected(event) {
    this.phaseService.SelectedDoc = <File>event.target.files[0];
    this.phaseService.DocNotUploaded = true;
    this.phaseService.DocUploadedName = this.phaseService.SelectedDoc.name;
    this.onUpload();
  }

  onUpload() {
    let filesize = this.phaseService.SelectedDoc.size / 1024 / 1024;
    if (filesize < 2) {
      this.fileSizeValid = true;
      if (this.phaseService.SelectedDoc !== null) {
        this.phaseService.DocNotUploaded = false;
        const reader = new FileReader();
        reader.readAsDataURL(this.phaseService.SelectedDoc);
        reader.onload = () => {
          this.phaseService.changeRequestBody.change_request['file'] = reader.result;
          this.phaseService.changeRequestBody.change_request['file_name'] = this.phaseService.DocUploadedName;
        }
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      }
    }

    else {
      this.fileSizeValid = false;
    }
  }

  removeFile() {
    this.phaseService.changeRequestBody.change_request['file'] = '';
    this.phaseService.changeRequestBody.change_request['file_name'] = '';
    this.phaseService.DocNotUploaded = true;
    this.phaseService.DocUploadedName = '';
    this.phaseService.SelectedDoc = null;
  }

  public onApproveAndContinue(actionType) {
    if(this.dataService.user.roles === 'CPE') {
      this.redirectToDashboardForAdditionalFeatures('pending', actionType);
    } else {
      this.dataService.showApproveAndContinuePopup = true;
    }

  }
  public getMonths() {
    const noOfMonths = ((this.dataService.totalAdditionalFeaturesWeek % 4) === 0)
      ? (this.dataService.totalAdditionalFeaturesWeek / 4)
      : Math.floor(this.dataService.totalAdditionalFeaturesWeek / 4) + 1;
    return noOfMonths;
  }
  public getPromotiondepositwithtax() {
    return this.getBuildCardData().promotion.deposit + this.getDepositTaxAmount();
  }

  getBillingInfo() {
    this.phaseService.billingInfo.name = this.phaseService.billingInfo.firstname + (this.phaseService.billingInfo.lastname ? ' ' + this.phaseService.billingInfo.lastname : '');
    this.phaseService.billingInfo.address = this.phaseService.billingInfo.apartment + (this.phaseService.billingInfo.street ? ' ' + this.phaseService.billingInfo.street : '');
    return this.phaseService.billingInfo;
  }

  getPaymentData() {
    return this.phaseService.paymentData;
  }

  getDurationMonthsForEditBuildCard() {
    if (this.dataService.modifiedBuildCardData.weeks.new) {
      return Math.ceil(this.dataService.modifiedBuildCardData.weeks.new / 4);
    } else {
      return this.getDurationMonths();
    }
  }

  getOldMonthDuration() {
    return Math.ceil(this.dataService.buildCardData.modification_request.base_build_card_data.weeks / 4);
  }

  getOldWeeks() {
    return this.dataService.buildCardData.modification_request.base_build_card_data.weeks;
  }

  getWeeks() {
    return this.dataService.modifiedBuildCardData.weeks.new ? this.dataService.modifiedBuildCardData.weeks.new : this.dataService.buildCardData.weeks;
  }

  getDurationMonths() {
    const change_week = this.getBuildCardData().change_week_status;
    const weeks = Number(this.getBuildCardData().weeks) + Number((change_week ? change_week : -change_week));
    let durationMonths;
    durationMonths = Math.ceil(weeks / 4);
    return durationMonths;
  }

  getModifiedBuilderCarePrice() {
    const buildCardData = this.dataService.buildCardData,
     newCarePrice = this.dataService.modifiedBuildCardData.support_maintenance_price.new,
     oldCarePrice = this.dataService.buildCardData.support_maintenance_price;
    if (this.isPaymentType()) {
      let price = (buildCardData.new_care_flow && !buildCardData.subscription_flow) ? (newCarePrice * 12) : newCarePrice;
      price = price ? price : (buildCardData.new_care_flow ? oldCarePrice : (oldCarePrice * 12));
      return Number(price) +
        Math.round((Number(price) *
          Number(this.phaseService.paymentData.general_info.tax)) / 100);
    } else {
      const price = ((buildCardData.new_care_flow && !buildCardData.subscription_flow) ? (newCarePrice * 12) : newCarePrice);
      return price ? price : (buildCardData.new_care_flow ? oldCarePrice : (oldCarePrice * 12));
    }
  }


  getSecurityDepositWithTax() {
    if (this.phaseService.promotionValid && !this.phaseService.promotionError && this.phaseService.isDiscountApplied() && this.getBuildCardData().promotion.type === 'GlobalPromotion' && this.getBuildCardData().promotion.deposit) {
      return this.getBuildCardData().promotion.deposit + this.getDepositTaxAmount();
    } else if (this.appDataService.urlParameters.isCustomPrototype) {
      return this.getInstallmentWithTax(this.getPaymentData().custom_prototype);
    } else {
      return this.getInstallmentWithTax(this.getPaymentData().pending_installment);
    }
  }

  getSecurityDepositTaxAmount() {
    const taxAmount = this.getDepositTaxAmount();
    if (taxAmount > 0) {
      return taxAmount;
    } else {
      return 0;
    }
  }

  getSecurityDepositWithoutTax() {
    if (this.phaseService.promotionValid && !this.phaseService.promotionError && this.phaseService.isDiscountApplied() && this.getBuildCardData().promotion.type === 'GlobalPromotion' && this.getBuildCardData().promotion.deposit) {
      return this.getBuildCardData().promotion.deposit;
    } else if (this.appDataService.urlParameters.isCustomPrototype) {
      return this.getInstallmentWithoutTax(this.getPaymentData().custom_prototype);
    } else {
      return this.getInstallmentWithoutTax(this.getPaymentData().pending_installment);
    }
  }

  getDeliveryDateForRapidPrototype() {
    let initialDate = this.moment(new Date());
    let deliveryTime = this.dataService.buildCardData.progress.buildcardphases[0].delivery_time;
    return this.moment(initialDate).add(deliveryTime, 'days').format('DD-MMM-YYYY');
  }



  toggleUpfrontSelection() {
    if (!this.phaseService.isEditableAndPayable()) {
      return;
    }
    this.phaseService.selectUpfrontPackage = true;
    this.phaseService.selectMonthlyPackage = false;
    if (this.phaseService.selectUpfrontPackage) {
      this.phaseService.selectedHash.upfront = true;
    } else {
      this.phaseService.selectedHash.upfront = false;
    }
    this.updateUpFront();
  }

  toggleMonthlySelection() {
    if (!this.phaseService.isEditableAndPayable()) {
      return;
    }
    this.phaseService.selectMonthlyPackage = true;
    this.phaseService.selectUpfrontPackage = false;
    if (this.phaseService.selectUpfrontPackage) {
      this.phaseService.selectedHash.upfront = true;
    } else {
      this.phaseService.selectedHash.upfront = false;
    }
    this.updateUpFront();
  }

  isUpfrontSelected() {
    return this.phaseService.selectUpfrontPackage;
  }

  isMonthlySelected() {
    return this.phaseService.selectMonthlyPackage;
  }

  payNowFromMaxBuildCard() {
    if (!this.dataService.checkIfCPEUser()) {
      this.phaseService.showRentalPaymentFlow = true;
      this.phaseService.handleNextPrevForRentalFlow(1);
    }
  }

  updateUpFront() {
    if (this.dataService.buildCardData && this.dataService.buildCardData.id) {
      const upfront = { build_card: { upfront: this.phaseService.selectedHash.upfront } };
      if (this.updateFrequencySubscription) {
        this.updateFrequencySubscription.unsubscribe();
      }
      this.updateFrequencySubscription = this.apiRequest.updateRentalFrequency(upfront).subscribe(resp => {
      }, error => {
        console.log(error);
      });
    }
  }

  getAdditionalFeatureCount() {
    return (this.phaseService.isEditFeatureRequest() && !this.appDataService?.urlParameters.cpe_user) ? this.dataService.additionalFeatures.length : this.phaseService.additionalRentalFeatures.length;
  }
  getAdditionalFeaturePerMonthCost() {
    return this.dataService.totalAdditionalFeaturesWeek > 4 ? (this.dataService.totalAdditionalFeaturesCost / this.dataService.totalAdditionalFeaturesWeek) * 4 : this.dataService.totalAdditionalFeaturesCost;
  }

  getAdditionalMonth() {
    return Math.ceil(this.dataService.totalAdditionalFeaturesWeek / 4);
  }

  showApproveEditBuildCardAfterSecurityPaid() {
    const postData = { promotion_id: this.phaseService.selectedHash.promotion_id };
    this.apiRequest.createUpdateBuildCard(postData, this.phaseService.inProgressBuildCardId, '', 'modificationPending')
      .subscribe(
        (data: any) => {
          this.phaseService.isShowApproveChangeModalAfterSecurityPaid = true;
          this.phaseService.preventClick = false;
          this.dataService.unsavedChanges = false;
          this.phaseService.isReadyToProceed = true;
        }, (error) => {

        });
  }

  goToDashboard() {
    this.router.navigate(['dashboard']);
  }

  checkIfBuilderCloudValue() {
    if (this.getBuildCardData().type === 'rental_price' && this.getBuildCardData().source === 'app_detail') {
      this.phaseService.selectedHash.cloud_opted = false;
      return false;
    } else {
      if (this.getBuildCardData().maximum_cloud_price) {
        return parseFloat(this.dataService.getLocaledPrice(this.getBuildCardData().minimum_cloud_price.toFixed(2)) + ' - ' +
          this.dataService.getLocaledPrice(this.getBuildCardData().maximum_cloud_price.toFixed(2)));
      } else {
        return parseFloat(this.dataService.getLocaledPrice(this.getBuildCardData().cloud_price?.toFixed(2)));
      }
    }
  }

  public getBuilderCloud() {
    if (this.getBuildCardData().type === 'rental_price' && this.getBuildCardData().source === 'app_detail') {
      this.phaseService.selectedHash.cloud_opted = false;
      return false;
    } else {
      if (this.getBuildCardData().maximum_cloud_price && this.getBuildCardData().cloud_user_count <= 50000) {
        return (this.dataService.getLocaledPrice(this.getBuildCardData().minimum_cloud_price.toFixed(2)) + ' - ' +
          this.getCurrencySymbol() + this.dataService.getLocaledPrice(this.getBuildCardData().maximum_cloud_price.toFixed(2)));
      }else if (this.getBuildCardData().maximum_cloud_price && this.getBuildCardData().cloud_user_count > 50000) {
        return (this.dataService.getLocaledPrice(this.getBuildCardData().minimum_cloud_price.toFixed(2))+'+');
      }
      else {
        return this.dataService.getLocaledPrice(this.getBuildCardData().cloud_price.toFixed(2));
      }
    }
  }

  public hideShowBuilderCloud(shouldShowCloudDescription: boolean) {
    this.phaseService.trackEventForObject(this.analyticsSegment,  "builder_cloud_explored", {
      user_id: this.dataService.user?.id
    });
    this.showCloudDescription = shouldShowCloudDescription;
    this.showSupportAndMaintenance = false;
    this.showDueNowInfo = false;
    this.showSecurityDepositInfo = false;
    this.showDeliveryInfo = false;
    this.showDonationDescription = false;
    this.showDonationFundDescription = false;
    this.showFixedCostTooltip = false;
  }

  public toggleDonationDescription(shouldShowCloudDescription: boolean) {
    this.showDonationDescription = shouldShowCloudDescription;
    this.showSupportAndMaintenance = false;
    this.showDueNowInfo = false;
    this.showSecurityDepositInfo = false;
    this.showDeliveryInfo = false;
    this.showDonationFundDescription = false;
    this.showCloudDescription = false;
  }

  public toggleDonationAmountDescription(shouldShowCloudDescription: boolean) {
    this.showDonationFundDescription = shouldShowCloudDescription;
    this.showSupportAndMaintenance = false;
    this.showDueNowInfo = false;
    this.showSecurityDepositInfo = false;
    this.showDeliveryInfo = false;
    this.showDonationDescription = false;
    this.showCloudDescription = false;
  }

  public toggleOldSummary() {
    this.isActiveOldSummary = !this.isActiveOldSummary;
  }

  public getTotalUpdatedPriceWithTax() {
    const price = this.getDiscountedPriceForPaidCards().new;
    if (price) {
      return Number(price) +
        Math.round((Number(price) *
          Number(this.dataService.buildCardData.currency.tax)) / 100);
    } else {
      return this.getTotalWithTax();
    }
  }

  public getOldPriceWithTax() {
    const baseCard = this.dataService.buildCardData.modification_request.base_build_card_data;
    const price = this.dataService.buildCardData.new_care_flow ? baseCard.bcard_discounted_price : baseCard.discounted_price;
    return Number(price) +
      Math.round((Number(price) *
        Number(this.phaseService.paymentData.general_info.tax)) / 100);
  }

  public getTotalUpdatedPrice() {
    return this.getDiscountedPriceForPaidCards().new ? this.getDiscountedPriceForPaidCards().new : this.getDiscountedprice(this.dataService.buildCardData);
  }

  public getOldSecurityAmount() {
    const modifyData = this.dataService.modifiedBuildCardData;
    const deposit = this.dataService.modifiedBuildCardData.deposit.old;
    if (this.checkIfUpfront()) {
      return this.dataService.buildCardData.upfront_paid_amount;
    }
    if (deposit) {
      return deposit;
    } else {
      return this.dataService.buildCardData.modification_request.base_build_card_data.deposit;
    }
  }

  public getNewSecurityAmount() {
    const deposit = this.dataService.modifiedBuildCardData.deposit.new;
    if (this.checkIfUpfront()) {
      return (this.dataService.proposalInstallments['upfront']['installment_amount'] +
        this.dataService.proposalInstallments['upfront']['tax_amount']);
    }
    if (deposit) {
      return deposit;
    } else {
      return this.dataService.buildCardData.modification_request.base_build_card_data.deposit;
    }
  }

  public getDiffSecurityAmount() {
    const OldDeposit = this.dataService.modifiedBuildCardData.deposit.old;
    const newDeposit = this.dataService.modifiedBuildCardData.deposit.new;
    if (this.checkIfUpfront()) {
      const upfrontInstall = this.dataService.proposalInstallments['upfront'];
      return ((upfrontInstall['installment_amount'] + upfrontInstall['tax_amount']) -
        (this.dataService.buildCardData.upfront_paid_amount ? this.dataService.buildCardData.upfront_paid_amount : 0));
    }
    if (OldDeposit && newDeposit) {
      return Math.abs(newDeposit - OldDeposit);
    } else {
      return 0;
    }
  }

  public isShowApproveBotton() {
    if (this.dataService.currentPage !== 'paymentPlan' || !this.dataService.modifiedBuildCardData || (this.dataService.modifiedBuildCardData && this.dataService.buildCardData.modification_request.status !== 'initial') || !this.dataService.isEditBuildCardAfterSecurityPaid()) {
      return false;
    } else {
      return true;
    }
  }

  additionalFeatureTootipClicked() {
    this.additionalFeatureTooltip = !this.additionalFeatureTooltip;
  }

  builderCareTootipClicked() {
    this.builderCareTooltip = !this.builderCareTooltip;
  }

  getEstimatedDeliveryDate(additionalWeeks?: number) {
    if (!this.phaseService.phaseData) {
      this.phaseService.phaseData = JSON.parse(JSON.stringify(this.dataService.buildCardData.progress.buildcardphases));
      this.phaseService.phaseData.forEach(phase => {
        phase.id = phase['build_phase_id'];
      });
    }
    if (this.phaseService.timePromotionApplied()) {
      additionalWeeks ? this.phaseService.finalPricenWeeks.weeks + additionalWeeks : this.phaseService.finalPricenWeeks.weeks;
      if(this.dataService.buildCardData.kick_off_date){
        return this.moment(this.dataService.buildCardData.kick_off_date).add((this.phaseService.finalPricenWeeks.weeks * 7 + this.addDelay()), 'days').format('DD-MMM-YYYY');
      } else {
        return this.moment().add((this.phaseService.finalPricenWeeks.weeks * 7), 'days').format('DD-MMM-YYYY');
      }

    } else {
      if(additionalWeeks) {
        return this.moment(this.dataService.buildCardData.kick_off_date).add(((additionalWeeks + this.dataService.buildCardData.weeks) * 7 + this.addDelay()), 'days').format('DD-MMM-YYYY');
      }  else {
        return this.moment(this.phaseService.getFinalDeliveryDate()).format('DD-MMM-YYYY');
      }

    }
  }
  addDelay() {
    return this.dataService?.buildCardData?.total_delay+ this.dataService.buildCardData?.total_block
  }
  toggleDeliveryInfo(event) {
    event.stopPropagation();
    this.showDeliveryInfo = !this.showDeliveryInfo;
    this.showSupportAndMaintenance = false;
    this.showCloudDescription = false;
    this.showDonationDescription = false;
    this.showDonationFundDescription = false;
    this.showDueNowInfo = false;
    this.showSecurityDepositInfo = false;
    this.showFixedCostTooltip = false;
  }

  checkCareType() {
    return this.phaseService.checkCareType();
  }

  showCareType(careType) {
    if (careType === 'enterprise_care_plus') {
      return 'Enterprise Care Plus';
    } else if (careType === 'enterprise_care') {
      return 'Enterprise Care';
    } else if (careType === 'standard_care_plus') {
      return 'Standard Care Plus';
    } else if (careType === 'standard_care') {
      return 'Standard Care';
    }
  }

  getDiscountedprice(card) {
    return this.phaseService.getDiscountedPriceForCards(card);
  }

  getprice(card) {
    return this.phaseService.getPriceForCards(card);
  }

  getDiscountedPriceForPaidCards() {
    if (this.dataService.buildCardData.new_care_flow) {
      return this.dataService.modifiedBuildCardData.bcard_discounted_price;
    } else {
      return this.dataService.modifiedBuildCardData.discounted_price;
    }
  }

  getPriceForPaidCards() {
    if (this.dataService.buildCardData.new_care_flow) {
      return this.dataService.modifiedBuildCardData.bcard_price;
    } else {
      return this.dataService.modifiedBuildCardData.price;
    }
  }

  calculateDonationAmount(percentage) {
    this.dataService.calculateDonationAmount(percentage);
  }

  getCloudPrice(event) {
    this.barValue = event;
    this.calculateDonationAmount(event);
  }

  checkIfShowCloud() {
    const mvp = this.phaseService.selectedPhases.find(
      phase => (phase.title === 'MVP') || (phase['type'] === 'custom_phase')
    );
    const fullBuild = this.phaseService.selectedPhases.find(
      phase => phase.title === 'Full Build'
    );
    return ((mvp && mvp.selected) || (fullBuild && fullBuild.selected));
  }

  showAdditionalServices() {
    return ((this.donationAmount() && this.dataService.buildCardData.show_donation && !this.phaseService.checkIfBuildCardForRapidPrototype()) ||
      (this.getSupportMaintenance() || this.getBuilderCloud()) && !this.phaseService.checkIfBuildCardForRapidPrototype());
  }

  applyReferralCode() {
    this.referralService.applyReferralCode(this.referralCode);
  }

  editReferralBuilderPoints() {
    this.referralService.showUseCreditedReferralPointsPopup = true;
  }

  getConditionForBuilderPointsText() {
    return this.referralService.referralBuilderPointsForUser || (this.referralService.referralBuilderPointsForUser === 0) ? this.referralService.referralBuilderPointsForUser : this.dataService.buildCardData.reward_point;
  }

  usePointsCTAClicked() {
    this.referralService.useExistingRewardPoints();
  }

  ngOnDestroy() {
    this.referralService.referralBuilderPointsForUser = null;
    this.referralService.creditedBuilderAmountEquivalentToUsedReferralPoints = null;
    this.referralService.referralCodeError = null;
  }

  openMSALink() {
    this.paymentBuilderService.isMSALinkCLicked = true;
    if (this.commonService.startTwoPayFlow) {
      window.open('https://builderassets.blob.core.windows.net/assets-builder/Builder-MSA%20%20Two%20Clickthrough-2023.pdf', '_blank' , 'noopener');
    } else if (this.paymentBuilderService.startCapchaseFlow) {
      window.open('https://builderassets.blob.core.windows.net/assets-builder/Builder%20TnC%20and%20Capchase%20buyer%20agreement.pdf', '_blank' , 'noopener');
    } else {
      this.commonService.navigateToTnC();
    }
  }

  termsAndConditionsClicked(event) {
    if (event.target.checked) {
      const date = new Date();
      this.paymentBuilderService.msaOpenedAt = date.toUTCString();
      this.phaseService.contractTermsConditionsChecked = true;
    } else {
      this.paymentBuilderService.msaOpenedAt = '';
      this.phaseService.contractTermsConditionsChecked = false;
    }
    this.phaseService.checkForDisabledPayment();
  }

  toggleMSAToolTip() {
    this.paymentBuilderService.showMSATooltip = !this.paymentBuilderService.showMSATooltip;
  }

  getTaxedPrice(price) {
    if (this.dataService.currentPage === 'paymentPlan' && this.dataService.buildCardData.currency.tax > 0) {
      return price + (price * this.dataService.buildCardData.currency.tax) / 100;
    } else {
      return price;
    }
  }

  getDurationMonthsForAdditional(weeks) {
    return Math.ceil(weeks / 4);
  }

  public startEditing(page, eventName?, editAdditionalFeatures?) {
    this.paymentService.featureEditingForAdditionFeature(page, eventName, editAdditionalFeatures)
  }

  public redirectToDashboardForAdditionalFeatures(type, actionType) {
    this.paymentService.additionalFeatureRequest(type, actionType);
  }

  public isUserInviteeOfBuildCard() {
    return this.dataService.buildCardData && !this.dataService.buildCardData.owner;
  }

  toggleFixedCostTooltip(event) {
    event.stopPropagation();
    this.showFixedCostTooltip = !this.showFixedCostTooltip;
    this.showSupportAndMaintenance = false;
    this.showDeliveryInfo = false;
    this.showCloudDescription = false;
  }

  showCare(careOrSubscription) {
    const subscription = this.dataService.buildCardData.subscription_flow;
    if (subscription && (careOrSubscription === 'subscription')) {
      return this.dataService.buildCardData.is_under_support;
    } else if (!subscription && (careOrSubscription === 'bcare')) {
      return (this.dataService.buildCardData.new_care_flow && this.getSupportMaintenance() && !subscription &&
      this.dataService.buildCardData.is_under_support);
    }
  }

  getFixedCost() {
    const buildCardData = this.dataService.buildCardData;
    if (!this.isEditFeatureRequest() && buildCardData.subscription_flow) {
      return (buildCardData.reusable_features_price + buildCardData.subscription_price);
    } else if (this.isEditFeatureRequest() && buildCardData.subscription_flow) {
      return (this.phaseService.featureReusableCost + this.phaseService.subscriptionCost);
    } else if (this.isEditFeatureRequest() && !buildCardData.subscription_flow) {
      return this.phaseService.featureReusableCost;
    } else if (!this.isEditFeatureRequest() && !buildCardData.subscription_flow) {
      return buildCardData.reusable_features_price;
    }
  }

  getCarePrice() {
    return this.getBuildCardData().support_yearly_price;
  }

  showCareForChangeReq() {
    if (this.dataService.buildCardData.subscription_flow) {
      return ((this.checkCareType() === 'standard_care_plus') || (this.checkCareType() === 'enterprise_care_plus'));
    } else {
      return true;
    }
  }

  checkIfLoadSpecingPayment() {
    return (((this.dataService.currentPage === 'paymentPlan') ||
      (this.phaseService.checkIfBuildCardForRapidPrototype() && !this.paymentBuilderService.checkIfOnlyProfessionalPrototype()
        && !this.dataService.modifiedBuildCardData))
      && (this.dataService.buildCardData && this.dataService.buildCardData.id));
  }

  isFrequencyUpfront() {
    return this.dataService.showUpfront;
  }

  checkIfUpfront() {
    const modifyData = this.dataService.modifiedBuildCardData;
    return ((modifyData.frequency.add === 'upfront') ||
      (this.dataService.buildCardData.frequency === 'upfront'));
  }

  showDueNowSection() {
    return (this.isPaymentType() && this.dataService.isUnpaidCard() && (!this.phaseService.isRentalBuildCard() ||
      (this.phaseService.isRentalBuildCard() && this.dataService.currentPage === 'billingDetails')));
  }

  toggleOldTotalPriceBreakup() {
    this.showOldTotalPriceBreakup = !this.showOldTotalPriceBreakup;
  }

  getUpdatedTaxPrice() {
    return Math.round(((Number(this.getTotalUpdatedPrice()) * Number(this.dataService.buildCardData.currency.tax)) / 100));
  }

  getPayableAmtWithChangeCost() {
    const buildCardCost = this.getDiscountedprice(this.getBuildCardData());
    if (this.showTaxBifurcation()) {
      return (buildCardCost + this.getPrototypeTaxOnTotal());
    } else {
      return buildCardCost;
    }
  }

  getPayableAmount() {
    if (this.showTaxBifurcation()) {
      return (this.getBuildCardData().price + this.getPrototypeTaxOnTotal());
    } else {
      return this.getBuildCardData().price;
    }
  }

  showTaxBifurcation() {
    return (this.isPaymentType() && (this.dataService.buildCardData.currency.tax > 0));
  }

  getPrototypeTaxOnTotal() {
    const buildCardCost = this.phaseService.getDiscountedPriceForCards(this.dataService.buildCardData);
    return ((Number(buildCardCost) * Number(this.dataService.buildCardData.currency.tax)) / 100);
  }

  getDueNowAmount() {
    if (this.paymentBuilderService.startCapchaseFlow) {
      return this.getTotalWithTax();
      // const buildCardData = this.dataService.buildCardData;
      // const changeCost = (buildCardData.change_price_status ? buildCardData.change_amount : -buildCardData.change_amount);
      // return (this.dataService.buildCardData.bcard_discounted_price + changeCost);
    } else {
      return this.getInstallmentWithTax(this.getDeposit());
    }
  }

  disablePromoAndOnePlusForBNPL() {
    return (this.paymentBuilderService.startCapchaseFlow || this.commonService.startTwoPayFlow
      || this.dataService.buildCardData.paid_using_bnpl);
  }

  disableCloudForBNPL() {
    return this.dataService.buildCardData.paid_using_bnpl;
  }

  getMSALinkText() {
    if (this.commonService.startTwoPayFlow) {
      return 'Builder Terms and Conditions and TWO Buyer Terms and Conditions';
    } else if (this.paymentBuilderService.startCapchaseFlow) {
      return 'Terms and Conditions and Capchase Pay Agreement';
    } else {
      return 'Terms and Conditions';
    }
  }

  checkIfAddFeaturesOrPlatforms() {
    return (this.dataService.additionalFeatures.length || this.paymentBuilderService.getAddedPlatformsCount());
  }

  checkIfNotAddFeaturesOrPlatforms() {
    return (!this.dataService.additionalFeatures.length && !this.paymentBuilderService.getAddedPlatformsCount());
  }
  
  getTnCText(){
    if (this.commonService.startTwoPayFlow) {
      return 'Check here to confirm purchase and indicate that you have read, understood and agree to be bound by';
    } else {
      return 'Check here to indicate you have read and agreed to the terms of the';
    }
  }

  goBackTOBHome() {
    this.dataService.unsavedChanges = false;
    const uniq_code = this.dataService.buildCardData.uniq_code;
    if (this.dataService.cookie_domain && this.dataService.cookie_domain === 'localhost') {
      window.location.href = `http://localhost:5000/#/project-progress/${uniq_code}/changerequest`;
    } else {
      window.location.href = `${this.dataService.homePageData.builder_partner.builder_live_url}/#/project-progress/${uniq_code}/changerequest`;
    }
  }

  getTotalPrice(installments) {
    let amountArray = [];
    installments.forEach((installment)=>{
      amountArray.push(Number(installment.installment_amount) + Number(installment.tax_amount));
    })
    if (amountArray.length) {
      return amountArray.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
      );
    }
  }

  public getInstallments() {
    return this.phaseService.installments;
  }

  getDepositIncludingTax() {
      return (this.getInstallmentWithoutTax(this.getDeposit()) + this.getTaxAmountIfIncluded());
  }

  checkIfTaxToBeIncluded() {
    if ((this.getDepositTaxAmount() > 0) && (this.dataService.buildCardData?.currency?.tax > 0) && (this.dataService.buildCardData?.currency?.code === 'AED')) {
      return true;
    }
    return false;
  }

  getTaxAmountIfIncluded() {
    if (this.checkIfTaxToBeIncluded()) {
      return this.getDepositTaxAmount();
    }
    return 0;
  }

  public getFinalWeeks() {
    return this.phaseService.finalPricenWeeks.weeks;
  }

}
