<div class="paymentDetail">
  <div class="paymentHead">
    <div class="optionBox">
      <h4></h4>
      <div class="spaceCall">
        Spec call
        <div class="option">
          <span [ngClass]="{'yes': isSpecCallSet === 'yes', 'no': isSpecCallSet === 'no'}">{{ isSpecCallSet }}</span>
          <em class="icon-next"></em>
          <ul>
            <li (click)="setSpecCall('yes')">Yes</li>
            <li (click)="setSpecCall('no')">No</li>
          </ul>
        </div>
      </div>
      <div class="spacePrice" *ngIf="!isPriceEditable">
        {{ buildCardService.getBuildCardCurrency() }} {{ buildCardService.currentBuildCard.currency.specing_price }}/-
        <em class="icon-edit" (click)="editToggleInput('price')"></em>
      </div>

      <div class="spacePriceEdit" *ngIf="isPriceEditable">
        <input type="text" [value]="buildCardService.currentBuildCard.currency.specing_price">
        <button type="button" (click)="editToggleInput('price')"><em class="icon-right"></em></button>
      </div>

    </div>

    <div class="optionBox">
      <h4>Kick off date</h4>
      <div class="calenderDate">
        <!--<input type="text" value="Kick Off"> <em class="icon-calendar-combined"></em>-->
        <input class="input-box" placeholder="Click to select a date"
               angular-mydatepicker name="mydate" (click)="dp.toggleCalendar()"
               [(ngModel)]="model" [options]="myDpOptions"
               #dp="angular-mydatepicker" (dateChanged)="onDateChanged($event)"/>
        <!--<my-date-picker name="mydate"
                        [options]="dateSelected.kickoffDate.options"
                        [(ngModel)]="dateSelected.kickoffDate.date" required
                        (dateChanged)="onDateChange($event)">
        </my-date-picker>-->
      </div>
    </div>

    <div class="optionBox">
      <h4>Buildcard<sup class="tm">®</sup> on Hold</h4>
      <div class="calenderDate">
        <!--<input type="text" value="Start date"> <em class="icon-calendar-combined"></em>-->
        <input class="input-box" placeholder="Click to select a date"
               angular-mydatepicker name="mydate" (click)="dp.toggleCalendar()"
               [(ngModel)]="model" [options]="myDpOptions"
               #dp="angular-mydatepicker" (dateChanged)="onDateChanged($event)"/>
        <!--<my-date-picker name="mydate" [options]="dateSelected.initialDate.options"
                        [(ngModel)]="dateSelected.initialDate.date" required></my-date-picker>-->
      </div>
      <div class="calenderDate">
        <!--<input type="text" value="End date"> <em class="icon-calendar-combined"></em>-->
        <input class="input-box" placeholder="Click to select a date"
               angular-mydatepicker name="mydate" (click)="dp.toggleCalendar()"
               [(ngModel)]="model" [options]="myDpOptions"
               #dp="angular-mydatepicker" (dateChanged)="onDateChanged($event)"/>
        <!--<my-date-picker name="mydate" [options]="dateSelected.finalDate.options"
                        [(ngModel)]="dateSelected.finalDate.date" required></my-date-picker>-->
      </div>
    </div>


  </div>

  <div class="projectPriceRow">
    <div class="totalWeeks" *ngIf="!isTotalWeeksEditable">
      Total no. of Weeks
      <strong>{{ buildCardService.currentBuildCard.weeks }} {{ buildCardService.currentBuildCard.weeks === 1 ? 'week' : 'weeks' }}</strong>
      <em class="icon-edit" (click)="editToggleInput('weeks')"></em>
    </div>

    <div class="totalWeeksEdit" *ngIf="isTotalWeeksEditable">
      <label>Total no. of Weeks</label> <input type="text" [value]="buildCardService.currentBuildCard.weeks + 'weeks'">
      <button type="button" (click)="editToggleInput('weeks')"><em class="icon-right"></em></button>
    </div>

    <div class="clearfix"></div>

    <div class="projectPriceBox">
      <h3>Total Buildcard<sup class="tm">®</sup> Cost</h3>
      <h4 class="green"
          *ngIf="!isProjectCostEditable">{{ buildCardService.getBuildCardCurrency() }} {{ getDiscountedprice(buildCardService.currentBuildCard) }}
        /-</h4>
      <div class="priceEditForm" *ngIf="isProjectCostEditable">
        <input type="text"/>
        <button type="button"><em class="icon-right"></em></button>
      </div>
      <div class="editPrice"><em class="icon-edit" (click)="editToggleInput('total_cost')"></em></div>
    </div>

    <div class="projectPriceBox">
      <h3>Amount Paid</h3>
      <h4>{{ buildCardService.getBuildCardCurrency() }} {{ buildCardService.paymentDetails?.amount_details?.paid_amount }}
        /-</h4>
    </div>

    <div class="projectPriceBox">
      <h3>Amount Remaining</h3>
      <h4>{{ buildCardService.getBuildCardCurrency() }} {{ buildCardService.paymentDetails?.amount_details?.pending_amount }}
        /-</h4>
    </div>

    <div class="projectPriceBox">
      <h3>Security Deposit</h3>
      <h4>{{ buildCardService.getBuildCardCurrency() }} {{ getSecurityDeposit() | number : '1.2-2' }}</h4>
    </div>

  </div>

  <div class="installmentList">
    <table summary="">
      <tr>
        <th>S. no.</th>
        <th>installment<br> Amount</th>
        <th>installment<br> Type</th>
        <th>Due Date</th>
        <th>Paid On</th>
        <th>Status</th>
        <th>Invoice</th>
        <th>Actions</th>
      </tr>
      <tbody *ngIf="buildCardService.paymentDetails?.installment">
      <tr *ngFor="let installment of buildCardService.paymentDetails?.installment; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ buildCardService.currentBuildCard.currency.symbol }} {{ installment.installment_amount | number : '1.2-2' }}</td>
        <td>{{ installment.installment_type }}</td>
        <td
          [ngClass]="{'yellow': installment.status === 'pending'}">{{ installment.due_at ? moment(installment.due_at).format('LL') : '--' }}</td>
        <td
          [ngClass]="{'yellow': installment.status === 'pending'}">{{ installment.paid_at ? moment(installment.paid_at).format('LL') : '--' }}</td>
        <td><span
          [ngClass]="{'received': installment.status === 'paid', 'pending': installment.status === 'pending'}">{{ installment.status === 'paid' ? 'Recieved' : 'Pending' }}</span>
        </td>
        <td><em class="icon-view" (click)="viewInvoice(installment.invoice.invoice.url)"></em> <em class="icon-download"
                                                                                                   (click)="viewInvoice(installment.invoice.invoice.url)"></em>
        </td>
        <td><em class="icon-edit"></em> <em class="icon-view"></em> <em class="icon-deletedoc"></em></td>
      </tr>
      </tbody>
      <tr *ngIf="buildCardService.paymentDetails?.installment?.length === 0">
        <td colspan="8">
          <div class="noResult">
            <img src="../../../../../../assets/images/searchNot.png" alt="" />
            <h3>Sorry, no installments found</h3>
          </div>
        </td>
      </tr>
    </table>
  </div>

</div>
