<div class="dashHeading"> Demo Call Details
  <button type="button" (click)="showCards(14)" *ngIf="dataService.demoCallCount > 4 && showRecentSpecCalls">Show all
  </button>
</div>
<div class="dashProjectRow spec" [ngClass]="{'hideCard': showRecentSpecCalls}" infiniteScroll
  [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300" (scrolled)="onScroll(dataService.demoCallCount)">
  <div class="progressCard"
    *ngFor="let specCard of (showRecentSpecCalls ? (dataService.demoCards | slice: 0 : (dataService.demoCallCount > 4 ? 4: dataService.demoCallCount)) : dataService.demoCards); let i = index;">
    <div class="cardHead specCallHead">
      <div class="cardLeft">
        <div>
          <h3>Demo Call - {{i + 1}}</h3>
          <h4>{{(specCard.start_time_pretty | date: 'dd-MMM-y. hh:mm a': 'GMT') || "-"}}, {{ specCard?.client_timezone }}</h4>
        </div>
        <div class="expertName">
          <span class="splitLetter">{{specCard.assigned_to.substring(0,2)}}</span>
          <div class="fullName">{{specCard.assigned_to}}</div>
        </div>
      </div>

      <div class="cardRight" *ngIf="(specCard?.meeting_status !== 'cancelled') && (specCard?.meeting_status !== 'pending')">
        <div class="cardMore"><em class="icon-more"></em>
          <div class="cardDropDown">
            <ul>
              <li (click)="showHideSpecCallCancelModal(specCard?.id)">Cancel Call</li>
            </ul>
          </div>
        </div>
      </div>

      <div *ngIf="specCard?.meeting_status === 'cancelled'" class="cancelTag">Cancelled</div>
    </div>
    <div class="cardMiddle">
      <div class="specCallText">
        <h3>Agenda</h3>
        <ul>
          <li>Explain your app requirements</li>
          <li>Create a Buildcard for those requirements</li>
          <li>Discuss any queries</li>
          <li>Plan your next steps</li>
        </ul>

        <h3>Idea</h3>
        <p>{{specCard?.description}}</p>
        <div class="paidOn" *ngIf="specCard.paid_at"><strong>Paid On</strong> <span>{{specCard.paid_at}}</span></div>
      </div>

      <!--<div class="projectPhases">
        <ul class="spec-call-sepc">
          <li>
            <div class="phaseTitle">Sales Rep. Name </div>
            <div class="phaseDetail"> {{specCard.assigned_to || "-"}} </div>
          </li>
        </ul>
      </div>-->

    </div>
    <div class="cardBottom speccallBottom" *ngIf="specCard.zoom_link">
      <a href="{{specCard.zoom_link}}" target="_blank" rel="noopener">Go to call</a>
    </div>
  </div>
  <app-progress-card-load class="progressCardload" *ngFor="let dummy of ' '.repeat(loaderCount).split(''), let x = index" [loaderCount]='loaderCount'></app-progress-card-load>
</div>
<div class="commonPopUp active" *ngIf="cancelSpecCallModal">
    <div class="popOverlay"></div>
    <div class="popHolder confirmPopup">
      <div class="closePopUp" (click)="showHideSpecCallCancelModal()"><span class="icon-cancel"></span></div>
      <h3>Cancel Call</h3>
      <p>Are you sure, you want to cancel this call?</p>

      <div class="footerButton">
        <button class="keepButton" (click)="showHideSpecCallCancelModal()">No</button>
        <button class="doneButton" (click)="cancelSpecCall()">Yes</button>
      </div>

    </div>
  </div>
