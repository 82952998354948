import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Angulartics2Segment } from "angulartics2/segment";
import { Subscription } from "rxjs";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { DataService } from "@shared/services/data.service";
import { PhaseService } from "@shared/services/phase.service";
import { ApiRequests } from "@shared/services/api-requests.service";
import { Constants } from "@shared/utils/constants";
import { UtilityService } from "@shared/services/utility.service";
import { AppDataService } from "@rootmodule/app-data.service";
import { CommonService } from "@shared/services/common.service";
import { Intercom } from "ng-intercom";
import { SeoService } from "@shared/services/seo.service";
import { ModelMapperService } from "@shared/services/model-mapper.service";
import { BuilderCareContentComponent } from "@shared/shared-components/builder-care-content/builder-care-content.component";
import { ReferralService } from "@shared/services/referral.service";
import { PaymentService } from "@shared/services/payment.service";
import {PaymentBuilderService} from '@featuremodules/payment-builder-module/payment-builder.service';
import { WebSocketService } from '@shared/services/web-socket.service';
import {HeaderService} from '@shared/services/header.service';

@Component({
  selector: "project-summary",
  templateUrl: "project-summary.component.html",
  styleUrls: ["project-summary.component.scss"],
})
export class ProjectSummaryComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
  };
  public pdf_status = false;
  public showAllFeatures = false;
  public summary;
  // public showProgress = true;
  public preventClick = false;
  public updatingBuildCard = false;
  public quotePrice = null;
  public showQuotePricePopup = false;
  public betterQuoteShow = false;
  public price = { platform: 0, features: 0 };
  public duplicateFile;
  public currentTag = "about";
  public paymentAmount;
  public orderId;
  public currentCard = { project_name: "", id: 0, status: "" };
  public sub: any;
  public productsList = [];
  public searchObservable: Subscription;
  public isResultLoading = false;
  public isSearchHappend = false;
  public singleUrl = null;
  public isEditEnabled = false;
  public showProjectNameTip = false;
  @ViewChild("projectName") public projectNameElement;
  public buildCardUpdates;
  componentType;
  @Input("buildId") buildId;
  private downloadInterval: any;
  public isPromotionPopupOpened = false;
  public promoError;
  public promotionApplied = false;
  public promotionLoader = false;
  popForAddCompetitor = false;
  expandActionButton = false;
  public selectedCompetitors = [];
  public showAllApps = false;
  public disableAddCompetitors = false;
  public isEditName = false;
  public isEditDesc = false;
  public isInfoIcon = false;
  public sectionName = "project_info";
  public isViewPrototypeClicked = false;
  public updatedSelectedHash;
  isCompetitorEdited = false;
  buildCardID: string;
  public ExitIntentChecked = false;
  feedBackStrings = [
    "Missed adding feature during spec call",
    "Feature not discussed during spec call",
    "Identified as a new requirement post project kick off",
  ];
  title = 'editor';
  editorConfig = {
    placeholder: 'Type the description here!',
  };
  isRealTimeUpdated = false;
  wssMessageSubsciription: Subscription;

  /*@ngInject*/
  constructor(
    public appDataService: AppDataService,
    public dataService: DataService,
    public renderer: Renderer2,
    public phaseService: PhaseService,
    public router: Router,
    public analyticsSegment: Angulartics2Segment,
    public route: ActivatedRoute,
    public cdRef: ChangeDetectorRef,
    public apiRequest: ApiRequests,
    public commonService: CommonService,
    private seoService: SeoService,
    public utilityService: UtilityService,
    public paymentService: PaymentService,
    private modelMapperService: ModelMapperService,
    public referralService: ReferralService,
    public paymentBuilderService: PaymentBuilderService,
    private webSocketService: WebSocketService,
    private headerService: HeaderService
  ) {
    this.componentType = "summary";
    this.commonService.setEditorConfiguration();
    this.seoService.noIndexMeta();
  }

  public ngOnInit() {
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    this.phaseService.SSCardRenamed = true;
    this.phaseService.allQuestionsAnswered = false;
    this.phaseService.showDocUploadArea = true;
    this.phaseService.updatedInstamentsClicked = false;
    // this.setSectionTabIfChangeAfterSecurityPaid();
    this.dataService.isEditRequestedFeatures =
      this.dataService.isFromAdditionalFeature;
    this.phaseService.showMobileSteps = true;
    if (!this.dataService.homePageData.platforms) {
      this.phaseService.timerStart();
      this.dataService.showHideLoader(true);
      this.apiRequest.fetchHomePageData().subscribe((data: any) => {
        this.dataService.dataLoadingCurrency = Object.assign(
          {},
          this.dataService.homePageData.currency
        );
        this.phaseService.platformPrices = Array.apply(
          null,
          Array(this.dataService.homePageData.platforms.length)
        );
        this.phaseService.platformPrices.map((x, i) => {
          return i;
        });
        if (this.dataService.homePageData.currency) {
          this.phaseService.specing_price =
            this.dataService.homePageData.currency.specing_price;
        }
        this.intializeSummary();
      });
    } else {
      this.intializeSummary();
      this.setSectionTabIfChangeAfterSecurityPaid();
      this.checkShowChangeNotify();
    }

    this.wssMessageSubsciription = this.webSocketService.buildCardFetched$.subscribe(() => {
      this.isRealTimeUpdated = true;
      this.intializeSummary();
      this.isRealTimeUpdated = false;
    });

  }
  setSectionTabIfChangeAfterSecurityPaid() {
    if (this.dataService.isEditBuildCardAfterSecurityPaid()) {
      this.sectionName = "features";
    }
  }
  public fillDataForAdditionalFeatures() {
    this.apiRequest
      .fetchSingleBuildCardData(
        this.dataService.changeFeatureRequestModel.build_card.uniq_code
      )
      .subscribe((data: any) => {
        this.phaseService.setUpInFullBuildCard();
        const featuress = this.dataService.buildCardData.change_requests.filter(
          (x) => x.status === "pending"
        );
        this.appDataService.urlParameters.featureRequest = "true";
        if (featuress[0].reason) {
          this.phaseService.Feedback_Answers = featuress[0].reason;
        }
        featuress[0]["features"].forEach((f) => (f.selected = true));
        if (featuress[0].file_url) {
          const fileArray = featuress[0].file_url.split("/");
          this.phaseService.DocUploadedName = fileArray[fileArray.length - 1];
          this.phaseService.DocNotUploaded = false;
        }
        if (this.dataService.changeFeatureRequestModel.features.length) {
          this.dataService.additionalFeatures = featuress[0]["features"];
        }
        this.phaseService.updatePrice(true);
        this.dataService.totalAdditionalFeaturesWeek =
          this.dataService.changeFeatureRequestModel.weeks;
        this.dataService.totalAdditionalFeaturesCost =
          this.dataService.changeFeatureRequestModel.price;
      });
  }

  private intializeSummary() {
    this.phaseService.appliedPromotion = false;
    if (this.componentType === "summary") {
      this.dataService.currentPage = "summary";
      this.phaseService.setComponentTitle(
        this.analyticsSegment,
        Constants.PAGE_TITLES.summary.code
      );
    } else if (this.componentType === "project-settings") {
      this.dataService.currentPage = "project_dashboard";
      this.phaseService.setComponentTitle(
        this.analyticsSegment,
        Constants.PAGE_TITLES.project_dashboard.code
      );
    }
    if (this.dataService.isEditRequestedFeatures) {
      this.fillDataForAdditionalFeatures();
    } else {
      this.phaseService.breadcrumb_links = [];
      if (this.buildId) {
        this.openBuildCard(this.buildId);
      } else {
        this.sub = this.route.params.subscribe((params: any) => {
          if (params.id) {
            this.buildCardID = params.id;
            this.dataService.buildCardUniqueCode = params.id;
            this.openBuildCard(this.buildCardID);
            if (this.dataService.isBuildCardUrl) {
              this.dataService.isBuildCardUrl = false;
            }
          } else {
            this.phaseService.setBreadcrumbLink(this.router.url.split("/")[1]);
            this.setUpBuildCard();
          }
        });
      }
      this.checkIfPdfAvailable();
    }
  }

  public checkIfPdfAvailable() {
    if (
      this.commonService.isPlatformBrowser &&
      this.dataService.buildCardData.id &&
      !this.dataService.buildCardData.build_card_pdf_url
    ) {
      this.isDownloadAvailable();

      this.downloadInterval = setInterval(() => {
        if (this.pdf_status === true) {
          clearInterval(this.downloadInterval);
        }
        this.isDownloadAvailable();
      }, 10000);
    } else {
      this.pdf_status = true;
    }
  }

  public checkForExitIntentPopup() {
    if (this.commonService.isPlatformBrowser) {
      if (
        this.phaseService.isEditableAndPayable() &&
        !this.phaseService.isInviteEdited &&
        this.isUserInviteeOfBuildCard()
      ) {
        this.phaseService.checkExitIntentTimer();
      } else {
        this.phaseService.exitIntentTimeSpent = 0;
        clearInterval(this.phaseService.exitIntentInterval);
      }
    }
  }

  public ngAfterViewInit() {
    if (this.commonService.isPlatformBrowser) {
      if (
        this.dataService.buildCardData &&
        this.dataService.buildCardData.status &&
        !this.ExitIntentChecked
      ) {
        this.ExitIntentChecked = true;
        this.checkForExitIntentPopup();
      } else {
        this.ExitIntentChecked = false;
      }

      if (this.checkIfInitialName() && this.projectNameElement) {
        setTimeout(() => {
          this.showProjectNameTip = true;
          this.projectNameElement.nativeElement.focus();
        });
      }
    }
  }

  public getBuildCardData() {
    return this.dataService.buildCardData;
  }

  public ngOnDestroy() {
    this.phaseService.isFromDashBard = false;
    this.dataService.isFromViewPrototype = false;
    this.dataService.isFromAdditionalFeature = false;
    this.dataService.isRestrictEdit = false;
    this.phaseService.showProjectNamePopup = false;
    this.phaseService.currentProjectName = "";
    this.phaseService.showMobileSteps = false;
    if (
      this.phaseService.isEditDoneOnBuildCard &&
      this.dataService.user &&
      !this.phaseService.buildCardEditMode
    ) {
      this.phaseService.isEditDoneOnBuildCard = false;
      this.dataService.buildCardUpdates = null;
      this.apiRequest
        .triggerEditBuildCardEmail(this.dataService.buildCardData.id)
        .subscribe();
    }
    if (this.commonService.isPlatformBrowser) {
      clearInterval(this.downloadInterval);
    }
    this.dataService.toSummary = false;
    this.phaseService.showNDA = false;
    if (this.dataService.user) {
      this.dataService.defaultCurrency = this.dataService.user.currency;
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (
      !this.phaseService.buildCardEditMode &&
      !this.phaseService.fromDashBoard &&
      !this.dataService.invalidAccess &&
      !this.phaseService.isEditFeatureRequest() &&
      !this.phaseService.navigateNormally &&
      !this.phaseService.holdOfferFlow &&
      this.dataService.currentPage !== "project_dashboard" &&
      !this.phaseService.checkIfBuildCardForRapidPrototype() &&
      !this.phaseService.isRentalBuildCard()
    ) {
      if (this.commonService.isPlatformBrowser) {
        setTimeout(() => {
          this.phaseService.reset();
          this.dataService.normalFlow = true;
          this.router.navigate([this.dataService.firstPage]);
        }, 1);
      }
    }
    this.phaseService.navigateNormally = false;
    this.phaseService.manualPromoCode = null;
    if (this.wssMessageSubsciription) {
      this.wssMessageSubsciription.unsubscribe();
    }
  }

  public setUpBuildCard() {
    this.renderer.addClass(
      document.getElementById("app-component"),
      "hide-scroll-bar"
    );
    if (
      this.dataService.buildCardData.project_name === "My Builder Project" &&
      this.phaseService.isFromDashBard &&
      !this.phaseService.isFromDelivery
    ) {
      this.phaseService.showProjectNamePopup = true;
    }
    if (
      this.dataService.buildCardData &&
      !this.dataService.buildCardData.owner
    ) {
      this.dataService.defaultCurrency =
        this.dataService.buildCardData.currency;
    }
    this.phaseService.filesList = [];
    this.betterQuoteShow = !(
      this.dataService.buildCardData && this.dataService.buildCardData.quote
    );
    this.phaseService.showNDA = true;
    this.phaseService.summaryScreen = true;
    if (this.dataService.user && !this.dataService.user.showTutorial) {
      this.dataService.user.showTutorial = true;
      this.dataService.storeUserObject();
    }
    // this.trackEvent();
    this.dataService.unsavedChanges = false;
    this.currentCard.id = this.dataService.buildCardData.id;
    this.phaseService.inProgressBuildCardId = this.dataService.buildCardData.id;
    this.phaseService.inProgressUniqCode =
      this.dataService.buildCardData.uniq_code;
    this.currentCard.project_name = this.dataService.buildCardData.project_name;
    this.currentCard.status = this.dataService.buildCardData.status;
    if (
      this.dataService.isUnpaidCard() &&
      this.dataService.buildCardData.donation_opted === null
    ) {
      this.dataService.buildCardData.donation_opted = true;
    } else if (this.dataService.buildCardData.donation_opted === false) {
      this.dataService.calculateDonationAmount(5);
    }
    this.checkEditRestrictions();
    this.setSectionTabIfChangeAfterSecurityPaid();
    this.checkShowChangeNotify();
  }

  public setTheBuildCard() {
    this.phaseService.setPromotionData();
    this.phaseService.getEditedBuildCardTimeline(
      this.dataService.buildCardData
    );
    this.setUpBuildCard();
    if (
      !this.isRealTimeUpdated &&
      this.phaseService.currentProjectName &&
      this.phaseService.inProgressBuildCardId &&
      this.phaseService.currentProjectName !== "My Builder Project"
    ) {
      this.updateBuildCardName();
    } else {
      this.phaseService.currentProjectName =
        this.dataService.buildCardData.project_name;
    }
    if (this.dataService.buildCardData.description) {
      this.phaseService.natashaSearchedText =
        this.dataService.buildCardData.description;
    }
    /*if (this.phaseService.isFeatureRequest || this.dataService.isEditRequestedFeatures) {
      this.phaseService.updatePrice(true);
    } else {
      this.phaseService.getBuildCardPriceWithDiscount();
    }*/
  }

  public checkEditRestrictions() {
    if (
      this.dataService.buildCardData.progress &&
      this.dataService.buildCardData.progress.apps &&
      this.dataService.buildCardData.progress.apps.length > 0
    ) {
      this.dataService.isRestrictEdit =
        this.dataService.buildCardData.progress.apps.filter(
          (x) => x.restrict_edit === true
        ).length > 0
          ? true
          : false;
    } else {
      this.dataService.isRestrictEdit = false;
    }
  }

  public openBuildCard(cardId) {
    if (this.dataService.buildCardData.id) {
      if (this.phaseService.isRentalBuildCard()) {
        this.sectionName = "rental_features";
        if (
          this.dataService.buildCardData.upfront &&
          !this.dataService.buildCardData.kick_off_date
        ) {
          this.phaseService.selectUpfrontPackage = true;
          this.phaseService.selectMonthlyPackage = false;
        } else {
          this.phaseService.selectMonthlyPackage = true;
          this.phaseService.selectUpfrontPackage = false;
        }
      }
      const card = this.dataService.buildCardData;
      if (!card.donation_amount && card.status === "card_completed") {
        this.calculateDonationAmount(5);
      }
      if (card.progress.apps && card.progress.apps.length > 0) {
        this.phaseService.getAdditionalFeaturesFroRental();
      }
      if (this.componentType === "project-settings") {
        this.phaseService.setUpInFullBuildCard();
        this.setTheBuildCard();
      } else {
        this.setTheBuildCard();
      }
    } else {
      this.dataService.showHideLoader(true);
      this.apiRequest.fetchSingleBuildCardData(cardId).subscribe(
        (data: any) => {
          const card = this.dataService.buildCardData;
          if (!card.donation_amount && card.status === "card_completed") {
            this.calculateDonationAmount(5);
          }
          if (this.phaseService.isRentalBuildCard()) {
            this.sectionName = "rental_features";
            if (
              this.dataService.buildCardData.upfront &&
              !this.dataService.buildCardData.kick_off_date
            ) {
              this.phaseService.selectUpfrontPackage = true;
              this.phaseService.selectMonthlyPackage = false;
            } else {
              this.phaseService.selectMonthlyPackage = true;
              this.phaseService.selectUpfrontPackage = false;
            }
          }
          if (card.progress.apps && card.progress.apps.length > 0) {
            this.phaseService.getAdditionalFeaturesFroRental();
          }
          if (!this.ExitIntentChecked) {
            this.ExitIntentChecked = true;
            this.checkForExitIntentPopup();
          }
          this.phaseService.showWeeklyOrMonthly();
          this.checkEditRestrictions();

          if (
            card.status === Constants.buildCardStatus.UNDER_DEVELOPMENT ||
            (card.status !== Constants.buildCardStatus.UNDER_DEVELOPMENT &&
              card.development_state) ||
            card.kick_off_date
          ) {
            this.runningBuildCardChangeRequest();
          } else if (this.dataService.buildCardData.id) {
            this.phaseService.setUpInFullBuildCard();
            this.setTheBuildCard();
            if (
              !(
                this.dataService.proposalInstallments &&
                this.dataService.proposalInstallments.length > 0
              )
            ) {
              this.phaseService.fetchProposalInstallments();
            }
            this.dataService.showHideLoader(false);
          }
          this.phaseService.currentProjectName =
            this.dataService.buildCardData.project_name;
          /*if (this.phaseService.isFeatureRequest || this.dataService.isEditRequestedFeatures) {
          this.phaseService.updatePrice(true);
        }*/
        },
        (error) => {
          this.dataService.showHideLoader(false);
          if (
            this.commonService.isPlatformBrowser &&
            (error.status === 403 ||
              error.status === 401 ||
              error.status === 404)
          ) {
            this.dataService.invalidAccess = true;
            this.router.navigate(["invalidaccess"]);
          }
        }
      );
    }
  }

  public runningBuildCardChangeRequest() {
    if (
      this.dataService.buildCardData &&
      this.dataService.buildCardData.change_requests &&
      this.dataService.buildCardData.change_requests.length > 0
    ) {
      const filteredRequest =
        this.dataService.buildCardData.change_requests.filter(
          (x) => x.status === "pending"
        );
      if (filteredRequest.length > 0) {
        this.dataService.changeFeatureRequestModel = filteredRequest[0];
        this.dataService.isEditRequestedFeatures = true;
        this.fillDataForAdditionalFeatures();
        this.dataService.showHideLoader(false);
      } else {
        this.phaseService.redirectToBuilderLive();
      }
    } else {
      this.phaseService.redirectToBuilderLive();
    }
  }

  public handleFocusIn(): void {
    this.isEditName = true;
  }

  public checkIfInitialName(): boolean {
    return this.phaseService.currentProjectName === "My Builder Project"
      ? true
      : false;
  }

  updateProjectName(event) {
    if (event.keyCode === 13) {
      this.updateBuildCardName();
    }
  }
  parseText(text) {
    let html = text;
    let div = document.createElement("div");
    div.innerHTML = html;
    return div.innerText;
  }

  updateBuildCardDesc() {
    if (this.phaseService.natashaSearchedText) {
      this.phaseService.selectedHash.description = this.phaseService.natashaSearchedText;
    }
    this.apiRequest
      .createUpdateBuildCard(
        this.phaseService.selectedHash,
        this.phaseService.inProgressBuildCardId,
        "",
        "rename_build_card"
      )
      .subscribe(
        (data: any) => {},
        (error) => {
          if (
            this.commonService.isPlatformBrowser &&
            (error.status === 403 || error.status === 401)
          ) {
            this.dataService.user = null;
            // remove cookie here
            this.dataService.unsetCookie();
            this.phaseService.clearLocalStorage();
            this.phaseService.showSignin = true;
            this.phaseService.openCommonAuth = true;
          }
          this.updatingBuildCard = false;
        }
      );
    this.isEditDesc = false;
  }

  public updateBuildCardName() {
    this.phaseService.currentProjectName = this.parseText(
      this.phaseService.currentProjectName
    );
    if (
      !this.updatingBuildCard &&
      this.phaseService.currentProjectName !== "" &&
      this.phaseService.currentProjectName.trim() !== "" &&
      this.phaseService.selectedHash.project_name !==
        this.phaseService.currentProjectName
    ) {
      this.phaseService.isEditDoneOnBuildCard = true;
      this.updatingBuildCard = true;
      this.currentCard.project_name = this.phaseService.currentProjectName;
      this.phaseService.selectedHash.project_name =
        this.phaseService.currentProjectName;
      this.phaseService.selectedHash.status = "";
      const promotion =
        this.dataService.homePageData.promotion ||
        this.dataService.manualPromotion;
      if (promotion) {
        this.phaseService.selectedHash.promotion_id = promotion.id;
      }
      this.updatedSelectedHash = this.phaseService.updatedBuildCardDataName(
        ["project_name"],
        this.phaseService.selectedHash
      );
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        "build_card_name_saved",
        {
          buildcard_name: this.phaseService.currentProjectName,
        }
      );
      const donationOpted = this.dataService.buildCardData.donation_opted;
      const donationPercentage =
        this.dataService.buildCardData.donation_percentage;
      this.apiRequest
        .createUpdateBuildCard(
          this.updatedSelectedHash,
          this.phaseService.inProgressBuildCardId,
          "",
          "rename_build_card"
        )
        .subscribe(
          (data: any) => {
            this.updatingBuildCard = false;
            this.showProjectNameTip = false;
            if (
              !this.dataService.buildCardData.donation_amount &&
              this.dataService.buildCardData.status === "card_completed"
            ) {
              this.dataService.buildCardData.donation_opted = donationOpted;
              this.calculateDonationAmount(
                donationOpted ? donationPercentage : 5
              );
            }
            this.checkIfPdfAvailable();
          },
          (error) => {
            if (
              this.commonService.isPlatformBrowser &&
              (error.status === 403 || error.status === 401)
            ) {
              this.dataService.user = null;
              // remove cookie here
              this.dataService.unsetCookie();
              this.phaseService.clearLocalStorage();
              this.phaseService.showSignin = true;
              this.phaseService.openCommonAuth = true;
            }
            this.updatingBuildCard = false;
          }
        );
    } else {
      this.phaseService.currentProjectName =
        this.phaseService.selectedHash.project_name;
    }
    this.isEditName = false;
  }

  public updateBuildCardCompetitors() {
    if (!this.updatingBuildCard) {
      this.updatingBuildCard = true;
      this.phaseService.selectedHash.status = "";
      let updatedSelectedHash;
      updatedSelectedHash = this.phaseService.updatedBuildCardDataName(
        ["competitors"],
        this.phaseService.selectedHash
      );
      this.apiRequest
        .createUpdateBuildCard(
          updatedSelectedHash,
          this.phaseService.inProgressBuildCardId,
          "",
          "update_competitors"
        )
        .subscribe(
          (data: any) => {
            this.updatingBuildCard = false;
            this.selectedCompetitors = [];
          },
          (error) => {
            if (error.status === 403 || error.status === 401) {
              this.dataService.user = null;
              // remove cookie here
              this.dataService.unsetCookie();
              this.phaseService.clearLocalStorage();
              this.phaseService.showSignin = true;
              this.phaseService.openCommonAuth = true;
            }
            this.updatingBuildCard = false;
          }
        );
    }
  }

  public deleteCompetitors(index) {
    this.phaseService.selectedHash.competitors.splice(index, 1);
    this.phaseService.selectedHash.status = "";
    let updatedSelectedHash;
    updatedSelectedHash = this.phaseService.updatedBuildCardData(
      ["customized_template_name"],
      this.phaseService.selectedHash
    );
    this.apiRequest
      .createUpdateBuildCard(
        updatedSelectedHash,
        this.phaseService.inProgressBuildCardId
      )
      .subscribe(
        (data: any) => {
          this.selectedCompetitors = [];
        },
        (error) => {
          if (error.status === 403 || error.status === 401) {
            this.dataService.user = null;
            // remove cookie here
            this.dataService.unsetCookie();
            this.phaseService.clearLocalStorage();
            this.phaseService.showSignin = true;
            this.phaseService.openCommonAuth = true;
          }
        }
      );
  }

  public openBuildCardPdf() {
    if (this.dataService.buildCardData.build_card_pdf_url) {
      window.open(this.dataService.buildCardData.build_card_pdf_url, "_blank"  , "noopener");
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        "build_card_downloaded",
        {
          user_id: this.dataService.user?.id,
        }
      );
    }
  }

  // public closeTutotial() {
  //   this.showTutorial = false;
  // }

  // public hideShowProgress() {
  //   this.showProgress = !this.showProgress;
  // }

  public setSelectedClass(currentTag) {
    this.currentTag = currentTag;
  }

  // public processNDAFlow() {
  //   this.phaseService.trackEvent(this.analyticsSegment,  'NDA clicked', 'currentPage', 'Project summary');
  //   this.showProgress = !this.showProgress;
  //   if (!this.dataService.user) {
  //     this.phaseService.showSignin = true;
  //     this.phaseService.openCommonAuth = true;
  //     this.phaseService.isNdaFlow = true;
  //   } else {
  //     this.dataService.showHideLoader(true);
  //     this.fetchDocusignUrl();
  //   }
  // }

  public fetchDocusignUrl() {
    this.phaseService.isNdaFlow = false;
    const params = { redirect_url: "", build_card_id: 0 };
    // params.redirect_url = window.location.origin;
    params.redirect_url =
      this.phaseService.getRedirectUrl() +
      "&uniq_code=" +
      this.dataService.buildCardData.uniq_code;
    params.build_card_id = this.dataService.buildCardData.id;

    this.apiRequest.fetchDocuSignUrl(params).subscribe((data: any) => {
      this.dataService.showHideLoader(false);
      if (this.dataService.docuSignUrl) {
        window.location.href = this.dataService.docuSignUrl;
      }
    });
  }

  public getPlatformTitle() {
    let title = "";
    let index = 0;
    for (const platform of this.phaseService.selectedPlatforms) {
      if (index > 0) {
        if (index === this.phaseService.selectedPlatforms.length - 1) {
          title += " and ";
        } else {
          title += ", ";
        }
      }
      title += platform.title;
      index++;
    }
    return title;
  }

  public fileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      for (const mainFile of Array.from(fileList)) {
        for (const file of this.phaseService.filesList) {
          if (file.name === mainFile.name) {
            this.duplicateFile = true;
            return;
          }
        }
        this.duplicateFile = false;
        this.phaseService.filesList.push(mainFile);
      }
    }
    if (this.phaseService.filesList.length > 0) {
      for (const input of this.phaseService.filesList) {
        const f = {
          file: input,
        };
        this.phaseService.selectedHash.attachments_attributes.push(f);
      }
    }
    event.target.value = null;
  }

  public remove(index) {
    if (!this.preventClick) {
      this.phaseService.filesList.splice(index, 1);
    }
  }

  public showQuotePrice() {
    this.showQuotePricePopup = !this.showQuotePricePopup;
  }

  public uploadQuote() {
    if (!this.preventClick) {
      this.preventClick = true;
      const productId = this.phaseService.inProgressBuildCardId;

      this.apiRequest
        .uploadQuote(
          this.quotePrice,
          this.phaseService.selectedHash.attachments_attributes,
          productId
        )
        .subscribe(
          (data: any) => {
            this.quotePrice = 0;
            this.preventClick = false;
            this.phaseService.filesList = [];
            this.showQuotePricePopup = false;
            this.betterQuoteShow = false;
          },
          (error) => {
            if (error.status === 403 || error.status === 401) {
              this.dataService.user = null;
              this.dataService.unsetCookie();
              this.phaseService.clearLocalStorage();
              this.phaseService.showSignin = true;
              this.phaseService.openCommonAuth = true;
            }
            this.preventClick = false;
            this.showQuotePricePopup = false;
            this.quotePrice = 0;
            this.betterQuoteShow = true;
          }
        );
    }
  }

  public gotoDashBoardMethod() {
    setTimeout(() => {
      this.phaseService.navigateNormally = true;
      this.router.navigate(["dashboard"]);
    }, 100);
  }

  public startEditing(
    page,
    eventName?,
    editAdditionalFeatures?,
    editAdditionalRentalFeatures?
  ) {

    this.paymentService.featureEditingForAdditionFeature(
      page,
      eventName,
      editAdditionalFeatures,
      editAdditionalRentalFeatures
    );
  }

  public searchProducts() {
    if (this.singleUrl && this.singleUrl.length > 2) {
      if (this.searchObservable) {
        this.searchObservable.unsubscribe();
      }
      this.isResultLoading = true;
      this.isSearchHappend = true;
      this.searchObservable = this.apiRequest
        .fetchProductsList(this.singleUrl)
        .subscribe(
          (data: any) => {
            this.productsList = data.data;
            this.isResultLoading = false;
            this.cdRef.detectChanges();
          },
          (error) => {
            this.isResultLoading = false;
            this.cdRef.detectChanges();
          }
        );
    } else {
      if (this.searchObservable) {
        this.searchObservable.unsubscribe();
      }
      this.isResultLoading = false;
      if (this.isSearchHappend) {
        this.isSearchHappend = false;
      }
      this.productsList = [];
    }
  }

  public addCompetitor(add?) {
    if (add && !this.selectedCompetitors.length && this.singleUrl) {
      this.selectedCompetitors.push({ product_name: this.singleUrl });
    }
    this.phaseService.selectedHash.competitors.push(
      ...this.selectedCompetitors
    );
    if (this.isCompetitorEdited) {
      let competitorTitle = "";
      this.selectedCompetitors.forEach((competitor) => {
        competitorTitle = competitor.product_name;
      });
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        "competitors_added",
        {
          user_id: this.dataService.user?.id,
          Competitor_name: competitorTitle,
        }
      );
    }
    this.changeEditState();
    this.productsList = [];
    this.singleUrl = null;
    this.isSearchHappend = false;
    this.closeAddCompetitorPopup();
    this.updateBuildCardCompetitors();
  }

  public changeEditState() {
    this.isEditEnabled = !this.isEditEnabled;
  }

  public startPaymentFlow(editBuildCardAfterSecurityPaid?) {
    this.dataService.showHideLoader(true);
    this.getBuildCardData().show_donation = false;
    this.dataService.errorMessage = false;
    this.phaseService.navigateNormally = true;
    this.phaseService.breadcrumb_links = [];
    this.phaseService.isViewUpdatedInstallmets = true;
    if (this.componentType === "summary") {
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        "build_card_started",
        {
          user_id: this.dataService.user?.id,
        }
      );
    }
    if (this.dataService.buildCardData.uniq_code) {
      this.phaseService.paymentModuleStarted = true;
      const uniqueCode = this.dataService.buildCardData.uniq_code;
      if (!editBuildCardAfterSecurityPaid) {
        this.phaseService.fetchContractDetails(uniqueCode);
      }
      if (
        this.dataService.buildCardData.wallet_balance !== null &&
        this.dataService.buildCardData.reward_point !== null &&
        this.dataService.buildCardData.wallet_balance <
          this.dataService.buildCardData.reward_point &&
        this.dataService.buildCardData.status === "card_completed"
      ) {
        this.referralService.reverseRewardPointBonus();
      } else {
        this.phaseService.fetchProposalInstallments(true);
      }
      // this.router.navigate([`buildcard-payment/${this.dataService.buildCardData.uniq_code}/` + 'paymentplan']);
    }
    if (
      this.getBuildCardData().show_donation &&
      this.getBuildCardData().status === "card_completed"
    ) {
      const updateData = {};
      updateData["donation_opted"] = this.getBuildCardData().donation_opted;
      updateData["donation_percentage"] =
        this.getBuildCardData().donation_percentage;
      updateData["donation_amount"] = this.getBuildCardData().donation_amount;
      if (!this.getBuildCardData().donation_opted) {
        updateData["donation_percentage"] = 0;
        updateData["donation_amount"] = 0;
      }
      this.apiRequest
        .createUpdateBuildCard(
          updateData,
          this.phaseService.inProgressBuildCardId,
          "",
          "updatefromemi"
        )
        .subscribe(
          (data: any) => {},
          (error) => {}
        );
    }
    this.dataService.isMobileModel = false;
  }

  public isShowProjectNameTip(): boolean {
    return (
      this.showProjectNameTip &&
      this.dataService.buildCardData.status ===
        Constants.buildCardStatus.CARD_COMPLETED
    );
  }

  public updateProjectNameTip() {
    this.showProjectNameTip = false;
  }

  public getCurrencyIcon() {
    return this.dataService.buildCardData.currency.icon_image_file_url;
  }

  getCurrencySymbol() {
    return this.dataService.buildCardData.id
      ? this.dataService.buildCardData.currency.symbol
      : this.phaseService.currencySymbol;
  }

  public getUserName() {
    return this.dataService.user
      ? this.dataService.user.first_name +
          (this.dataService.user.last_name !== null
            ? " " + this.dataService.user.last_name
            : "")
      : "Test User";
  }

  public getProjectStartDate() {
    return this.dataService.buildCardData.kick_off_date;
  }

  public handleDashBoardPdf() {
    const pdfUrl = this.dataService.buildCardData.build_card_pdf_url
      ? this.dataService.buildCardData.build_card_pdf_url
      : "";
    window.open(pdfUrl, "_blank", "noopener");
  }

  public getCustomizedName() {
    return this.phaseService.selectedHash.customized_template_name;
  }

  public isDownloadAvailable() {
    this.apiRequest
      .isDownloadAvailable(this.dataService.buildCardData.id)
      .subscribe((res) => {
        this.pdf_status = res.pdf_status;
        if (res.pdf_url) {
          this.dataService.buildCardData.build_card_pdf_url = res.pdf_url;
        }
      });
  }

  public closeUpdateNotifications(buildCardId) {
    this.dataService.showHideLoader(true);
    this.apiRequest.deactivateTimeline(buildCardId).subscribe(
      (data) => {
        this.dataService.buildCardUpdates = null;
        this.dataService.showHideLoader(false);
      },
      (error) => {
        this.dataService.showHideLoader(false);
      }
    );
  }

  public showHidePromotionPopup(isShow) {
    this.isPromotionPopupOpened = isShow;
    this.phaseService.manualPromoCode = null;
    this.phaseService.promoError = null;
  }

  public isUserInviteeOfBuildCard() {
    return (
      this.dataService.buildCardData && this.dataService.buildCardData.owner
    );
  }
  public featureLengthOfBuildCard() {
    return this.dataService.buildCardData.progress.features.length;
  }

  public goToDelivery(phase) {
    this.dataService.showDeliveryLoading = false;
    this.dataService.homePageData.build_phases.forEach(
      (ph) => (ph.active = false)
    );
    this.phaseService.buildCardEditMode = true;
    this.dataService.isEditBuildCard = true;
    this.phaseService.fromDashBoard = false;
    this.router.navigate(["delivery"]);
  }

  public openAddCompetitorPopup() {
    this.isCompetitorEdited = true;
    this.popForAddCompetitor = true;
  }

  public closeAddCompetitorPopup() {
    this.popForAddCompetitor = false;
    this.selectedCompetitors = [];
    this.singleUrl = "";
  }

  public expandtriggerButtons() {
    this.expandActionButton = !this.expandActionButton;
  }

  public closetriggerButtons() {
    this.expandActionButton = false;
  }

  public selectCompetitors(competitor, event) {
    if (this.phaseService.selectedHash.competitors) {
      const index = this.phaseService.selectedHash.competitors.findIndex(
        (comp) => comp.product_name === competitor.product_name
      );
      if (event.target.checked) {
        if (index === -1) {
          this.selectedCompetitors.push(competitor);
        }
      } else {
        this.selectedCompetitors.splice(index, 1);
      }
      if (
        this.selectedCompetitors.length +
          this.phaseService.selectedHash.competitors.length >
        3
      ) {
        this.disableAddCompetitors = true;
      } else {
        this.disableAddCompetitors = false;
      }
    }
  }

  infoIconClicked() {
    this.isInfoIcon = !this.isInfoIcon;
  }

  showClickedSection(sectionName) {
    if (
      this.phaseService.additionalRentalFeatures.length === 0 &&
      !this.dataService.checkIfCPEUser() &&
      sectionName === "rental_additional_features"
    ) {
      return;
    }
    this.sectionName = sectionName;
  }

  togglePrototypeModel() {
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      "build_card_prototype_selected",
      {
        user_id: this.dataService.user?.id,
      }
    );
    this.isViewPrototypeClicked = !this.isViewPrototypeClicked;
  }

  redirectToNow() {
    this.isViewPrototypeClicked = false;
    if (this.phaseService.showViewPrototype()) {
      this.apiRequest
        .syncBuildcardToNow(this.dataService.buildCardData.id)
        .subscribe((data) => {
          window.open(
            this.dataService.homePageData.builder_partner.builder_now_url +
              "/" +
              this.dataService.buildCardData.id,
            "_blank", "noopener"
          );
        });
    } else {
      this.phaseService.redirectWIPBuilderNow();
    }
  }

  builderCareCondition() {
    if (
      this.dataService.modifiedBuildCardData &&
      this.dataService.modifiedBuildCardData.is_under_support !== null
    ) {
      return this.dataService.modifiedBuildCardData.is_under_support;
    } else if (
      this.dataService.modifiedBuildCardData &&
      this.dataService.modifiedBuildCardData.is_under_support === null
    ) {
      return this.dataService.buildCardData.modification_request
        .base_build_card_data.is_under_support;
    }
    if (
      this.getBuildCardData().type === "rental_price" &&
      this.getBuildCardData().source === "app_detail"
    ) {
      this.phaseService.selectedHash.is_under_support = false;
      return false;
    }
    return this.getBuildCardData().is_under_support;
  }

  builderCloudCondition() {
    if (
      this.dataService.modifiedBuildCardData &&
      this.dataService.modifiedBuildCardData.cloud_opted !== null
    ) {
      return this.dataService.modifiedBuildCardData.cloud_opted;
    } else if (
      this.dataService.modifiedBuildCardData &&
      this.dataService.modifiedBuildCardData.cloud_opted === null
    ) {
      return this.dataService.buildCardData.modification_request
        .base_build_card_data.cloud_opted;
    }
    if (
      this.getBuildCardData().type === "rental_price" &&
      this.getBuildCardData().source === "app_detail"
    ) {
      this.phaseService.selectedHash.cloud_opted = false;
      return false;
    }
    return this.getBuildCardData().cloud_opted;
  }

  goToRapidPrototypePayment() {
    if (!this.isUserInviteeOfBuildCard()) {
      return;
    }
    if (!this.paymentBuilderService.checkIfOnlyProfessionalPrototype()) {
      this.appDataService.urlParameters.isCustomPrototype = 'true';
    }
    this.apiRequest.getUserforBilling().subscribe(
      (data) => {
        this.router.navigate([
          `buildcard-payment/${this.dataService.buildCardData.uniq_code}/` +
            "billingdetails",
        ]);
      },
      (error) => {
        this.commonService.showError(error);
      }
    );
  }

  // startRentalBuildCardFlow() {
  //   if (this.phaseService.isRentalBuildCard() && this.getBuildCardData().upfront) {
  //     if (this.dataService.isMSAUpdatedFromPaymentPlan) {
  //       this.dataService.isContractAPICalled = true;
  //     }
  //     const installmentType = { frequency: 'monthly' };
  //     if (installmentType.frequency !== this.dataService.buildCardData.frequency) {
  //       this.apiRequest.createUpdateBuildCard(installmentType, this.phaseService.inProgressBuildCardId, '', 'rename_build_card').subscribe(() => {
  //         this.phaseService.fetchInstallments(true);
  //       });
  //     }
  //     if (this.dataService.buildCardData.uniq_code) {
  //       this.apiRequest.getUserforBilling().subscribe(
  //         (data) => {
  //           this.router.navigate([`buildcard-payment/${this.dataService.buildCardData.uniq_code}/` + 'billingdetails']);
  //         },
  //         error => {
  //           this.commonService.showError();
  //         }
  //       );
  //     }
  //   } else {
  //     this.startPaymentFlow();
  //   }
  // }

  checkShowChangeNotify() {
    if (
      this.dataService.buildCardData?.modification_request?.status === "pending"
    ) {
      this.dataService.isModificationPending = true;
    } else {
      this.dataService.isModificationPending = false;
    }
  }

  hideChangeNotify() {
    this.dataService.isModificationPending = false;
  }

  cloneBuildCard(uniqueCode, currency) {
    this.phaseService.cardIdToBeCloned = uniqueCode;
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      "build_card_duplicated",
      {
        user_id: this.dataService.user?.id,
      }
    );
    if (this.dataService.checkIfCPEUser()) {
      this.phaseService.clonedCardCurrency = currency;
      this.commonService.isDuplicateCardFlow = true;
      this.expandActionButton = false;
      this.commonService.duplicateCardData = { uniQueCode: uniqueCode, currency: currency };
    } else {
      const curr = this.phaseService.clonedCardCurrency ? this.phaseService.clonedCardCurrency.id : '';
      this.dataService.showHideLoader(true);
      this.apiRequest.cloneBuildCard(uniqueCode, curr).subscribe(
        (resp) => {
          this.expandActionButton = false;
          this.dataService.showHideLoader(false);
          window.open(
            `${window.location.origin}/build-card/${resp.data.attributes.uniq_code}`
          );
        },
        (error) => {
          this.dataService.showHideLoader(false);
          this.commonService.showError("Something Went Wrong.");
        }
      );
    }
  }

  public SaveSelectedReason(event: any, index) {
    event.stopPropagation();
    this.phaseService.Feedback_Answers[index] = event.target.value;
    if (
      Object.keys(this.phaseService.Feedback_Answers).length ==
      this.dataService.additionalFeatures.length
    ) {
      this.phaseService.allQuestionsAnswered = true;
    }
  }

  public RemoveFeature(event, feature) {
    event.stopPropagation();
    const index = this.dataService.additionalFeatures.indexOf(feature);
    this.dataService.additionalFeatures.splice(index, 1);
    const featureindex = this.phaseService.selectedFeatures.findIndex(
      (ftr) => ftr.id === feature.id
    );

    let Rolesfeature = this.phaseService.selectedFeatures.find(
      (ftr) => ftr.id === feature.id
    );
    if(Rolesfeature?.repeatable_count) {
      // const index = this.dataService.additionalFeatures.findIndex((ftr)=>{
      //   ftr.id === feature.id
      // })
      // this.dataService.additionalFeatures.splice(index,1)
      Rolesfeature.feature_count = Rolesfeature.repeatable_count;
    }

    if (featureindex !== -1 && !Rolesfeature) {
      this.phaseService.selectedFeatures[featureindex].selected = false;
      this.phaseService.selectedFeatures.splice(featureindex, 1);
    }
    if (
      this.dataService.buildCardData.change_requests &&
      this.dataService.buildCardData.change_requests.length
    ) {
      const features = this.dataService.buildCardData.change_requests[0]
        .features
        ? this.dataService.buildCardData.change_requests[0].features
        : this.dataService.buildCardData.change_requests;
      const i = features.findIndex((ftr) => ftr.id === feature.id);
      if (i !== -1) {
        features[i].selected = false;
        features.splice(i, 1);
      }
      this.dataService.remainingRequestedFeatures = features;
    }
    this.dataService.isFromSummary = true;
    if (this.phaseService.Feedback_Answers) {
      delete this.phaseService?.Feedback_Answers[feature.id];
    }
    if (
      this.phaseService.Feedback_Answers &&
      Object.keys(this.phaseService.Feedback_Answers).length ===
        this.dataService.additionalFeatures.length
    ) {
      this.phaseService.allQuestionsAnswered = true;
    }
    this.phaseService.updatePrice(true);
  }

  getBreakupPrice(featureId) {
    if (!this.phaseService.featureData.length) {
      return 0;
    }
    if (!this.phaseService.featureData.length) {
      this.phaseService.updatePrice(true);
    }
    const feature = this.phaseService.featureData.find(
      (f) => f.id === featureId
    );
    if (feature) {
      return parseFloat(feature["customisation"] + feature["fixed"]).toFixed(2);
    }
  }

  checkIfFeedbackGiven(featureId) {
    if (!this.phaseService.Feedback_Answers) {
      return;
    }
    return Object.keys(this.phaseService.Feedback_Answers).includes(
      featureId.toString()
    );
  }

  setFeedbackSelected(featureId) {
    if (!this.phaseService.Feedback_Answers) {
      return;
    }
    const index = Object.keys(this.phaseService.Feedback_Answers).findIndex(
      (fId) => fId === featureId.toString()
    );
    return Object.keys(this.phaseService.Feedback_Answers).includes(
      featureId.toString()
    )
      ? Object.values(this.phaseService.Feedback_Answers)[index]
      : "";
  }

  checkCareType() {
    return this.phaseService.checkCareType();
  }

  getDiscountedprice(card) {
    return this.phaseService.getDiscountedPriceForCards(card);
  }

  getPrice(card) {
    return this.phaseService.getPriceForCards(card);
  }

  calculateDonationAmount(percentage) {
    this.dataService.calculateDonationAmount(percentage);
  }

  public handleEditDesc(): void {
    this.isEditDesc = true;
  }

  updateProjectDesc(event) {
    if (event.keyCode === 13) {
      this.updateBuildCardDesc();
    }
  }

  checkCountUpdates(feature) {
    if (
      feature.feature_count &&
      this.dataService.buildCardData.progress.features.find(
        (el) => el.id === feature.id
      )
    ) {
      const updatedCount = this.dataService.additionalFeatures.find(
        (el) => el.id === feature.id
      ).feature_count;
      const preCount = this.dataService.buildCardData.progress.features.find(
        (el) => el.id === feature.id
      ).feature_count;
      return updatedCount - preCount;
    } else return feature.feature_count;
  }

  checkRolesCount() {
    if (
      this.dataService.additionalFeatures.find(
        (el) => el.title === "Roles & Permissions"
      )
    ) {
      let feature = this.dataService.additionalFeatures.find(
        (el) => el.title === "Roles & Permissions"
      );
      if (
        feature.feature_count &&
        this.dataService.buildCardData.progress.features.find(
          (el) => el.id === feature.id
        )
      ) {
        const updatedCount = this.dataService.additionalFeatures.find(
          (el) => el.id === feature.id
        ).feature_count;
        const preCount = this.dataService.buildCardData.progress.features.find(
          (el) => el.id === feature.id
        ).feature_count;
        return updatedCount - preCount;
      } else return feature.feature_count;
    } else return 1;
  }

  enableEditForPaidCards() {
    const buildCardData = this.dataService.buildCardData;
    return (this.dataService.isEditBuildCardAfterSecurityPaid() && !buildCardData.paid_using_bnpl
      && buildCardData.modification_request?.status !== 'pending');
  }

  showViewInstallments() {
    const buildCardData = this.dataService.buildCardData;
    return (((this.dataService.isEditBuildCardAfterSecurityPaid() && !this.dataService.modifiedBuildCardData)
      || buildCardData?.modification_request?.status === 'approved') && !buildCardData.paid_using_bnpl);
  }

  checkLengthValidation() {
    if (this.phaseService.natashaSearchedText) {
      const html = this.phaseService.natashaSearchedText;
      const div = document.createElement('div');
      div.innerHTML = html;
      return (div.innerText.length < 30);
    }
  }

  openScheduleCallPopup() {
    this.headerService.showScheduleCall();
  }
}
