<div class="taxBillingWrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12">
        <div class="headingTop">
          <h1>Tax Details</h1>
          <button type="button" *ngIf="isFromBillingDetails" [disabled]="!phaseService.billingInfo.country" (click)="toggleTaxDetailsOnBillingPage()"></button>
        </div>
        <p *ngIf="(dataService.currentPage === 'taxDetails')" class="infoText">We need you to provide the following tax details to ensure you are billed correctly and generate your invoices. We will not be able to kick-off your Buildcard until this is completed. Please do this as soon as possible.</p>
      </div>
    </div>
    
    <div class="row">
      <ng-container *ngIf="(dataService.currentPage === 'taxDetails') || taxDetailsService.isShowTaxDetailsOnBillingPage">
        <div [ngClass]="(dataService.currentPage === 'taxDetails') ? 'col-sm-9' : 'col-xs-12'">
          <div class="innerContainer taxDetails">
            <form [formGroup]="taxDetailsService.taxDetailsForm" [ngClass]="{'disable-tax-details': checkIfTaxDetailsAvailable()}">
              <div class="topInputWrapper">
                <ng-container *ngIf="showFieldsAsPerRegion('GST')">
                  <div class="multiRadioContainer">
                    <label>Are you registered under GST?<sup>*</sup></label>
                    <div class="radioInputWrap">
                      <div class="inputBox" [ngClass]="{'active': !taxDetailsService.checkBoxValues.isGstChecked}">
                        <input type="radio" name="isRegisterGST" id="registerGSTNo" [checked]="!taxDetailsService.checkBoxValues.isGstChecked"
                              (change)="toggleCheckBoxes('isGstChecked', 'gst_number')"/>
                        <label for="registerGSTNo">No</label>
                      </div>
                      <div class="inputBox" [ngClass]="{'active': taxDetailsService.checkBoxValues.isGstChecked}">
                        <input type="radio" name="isRegisterGST" id="registerGSTYes" [checked]="taxDetailsService.checkBoxValues.isGstChecked"
                              (change)="toggleCheckBoxes('isGstChecked')"/>
                        <label for="registerGSTYes">Yes</label>
                      </div>
                    </div>
                  </div>
                  <div class="containerRow gstNumberRow" *ngIf="taxDetailsService.checkBoxValues.isGstChecked">
                    <div class="inputRow">
                      <label for="GSTNumber">What is your GST Number?<sup>*</sup></label>
                      <div class="gstInputBox">
                        <div class="smfull">
                          <input type="text" class="form-control" id="GSTNumber" placeholder="eg. 06ABCDE1234F2Z5"
                                pattern="^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$"
                                [ngClass]="{ 'error': showErrorMsg(taxDetailsService.gstNumber) }"
                                formControlName="gst_number">
  <!--                        <button class="btn btnCheck" (click)="checkGSTValidation()">Check</button>-->
                        </div>
                        <div class="errorMsg" *ngIf="showErrorMsg(taxDetailsService.gstNumber)">Please enter valid GST Number</div>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="showFieldsAsPerRegion('VAT')">
                  <div class="multiRadioContainer">
                    <label>Are you registered under {{ taxDetailsService.checkIfUKOwningEntity() ? 'VAT or any other local tax' : 'Sales tax' }}?<sup>*</sup></label>
                    <div class="radioInputWrap">
                      <div class="inputBox" [ngClass]="{'active': !taxDetailsService.checkBoxValues.isVatChecked}">
                        <input type="radio" name="isRegisterVAT" id="registerVATNo" [checked]="!taxDetailsService.checkBoxValues.isVatChecked"
                              (change)="toggleCheckBoxes('isVatChecked', 'vat_number')"/>
                        <label for="registerVATNo">No</label>
                      </div>
                      <div class="inputBox" [ngClass]="{'active': taxDetailsService.checkBoxValues.isVatChecked}">
                        <input type="radio" name="isRegisterVAT" id="registerVATYes" [checked]="taxDetailsService.checkBoxValues.isVatChecked"
                              (change)="toggleCheckBoxes('isVatChecked')"/>
                        <label for="registerVATYes">Yes</label>
                      </div>
                    </div>
                  </div>
                  <div class="containerRow gstNumberRow" *ngIf="taxDetailsService.checkBoxValues.isVatChecked">
                    <div class="inputRow">
                      <label for="GSTNumber">What is your {{ taxDetailsService.checkIfUKOwningEntity() ? 'VAT number' : 'Sales tax Number' }}?<sup>*</sup></label>
                      <div class="gstInputBox">
                        <div class="smfull">
                          <input type="text" class="form-control" id="VATNumber" placeholder="{{ getVatPlaceHolder() }}"
                                [ngClass]="{ 'error': ((showErrorMsg(taxDetailsService.vatNumber) || taxDetailsService.showInvalidVatError) || taxDetailsService.isCountryCOdeInVAT) }"
                                formControlName="vat_number" (focus)="removeError()">
                        </div>
                        <div class="errorMsg" *ngIf="showErrorMsg(taxDetailsService.vatNumber) || taxDetailsService.showInvalidVatError">Please enter valid VAT Number</div>
                        <div class="errorMsg" *ngIf="taxDetailsService.isCountryCOdeInVAT">Please remove any country code prefixes such as DE, FR, IN before submitting</div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="showFieldsAsPerRegion('sales_tax')">
                  <div class="multiRadioContainer">
                    <label>Are you registered under {{ taxDetailsService.checkIfDMCCOwningEntity() ? 'VAT / Sales Tax' : 'Sales tax' }}?<sup>*</sup></label>
                    <div class="radioInputWrap">
                      <div class="inputBox" [ngClass]="{'active': (!taxDetailsService.checkBoxValues.isSalesTaxChecked)}">
                        <input type="radio" name="isRegisterSalestax" id="registerSalesTaxNo" [checked]="!taxDetailsService.checkBoxValues.isSalesTaxChecked"
                              (change)="toggleCheckBoxes('isSalesTaxChecked', 'sales_tax_number')"/>
                        <label for="registerSalesTaxNo">No</label>
                      </div>
                      <div class="inputBox" [ngClass]="{'active': (taxDetailsService.checkBoxValues.isSalesTaxChecked)}">
                        <input type="radio" name="isRegisterSalestax" id="registerSalesTaxYes" [checked]="taxDetailsService.checkBoxValues.isSalesTaxChecked"
                              (change)="toggleCheckBoxes('isSalesTaxChecked')"/>
                        <label for="registerSalesTaxYes">Yes</label>
                      </div>
                    </div>
                  </div>
                  <div class="containerRow gstNumberRow" *ngIf="taxDetailsService.checkBoxValues.isSalesTaxChecked">
                    <div class="inputRow">
                      <label for="GSTNumber">What is your {{ taxDetailsService.checkIfDMCCOwningEntity() ? 'VAT / TRN number' : 'Sales tax Number' }}?<sup>*</sup></label>
                      <div class="gstInputBox">
                        <div class="smfull">
                          <input type="text" class="form-control" id="SalesTaxNumber" placeholder="{{ getVatPlaceHolder() }}"
                                [ngClass]="{ 'error': (showErrorMsg(taxDetailsService.salesTaxNumber) || taxDetailsService.showInvalidVatError) }"
                                formControlName="sales_tax_number" (focus)="removeError()"
                                (keypress)="checkPressedKey($event)">
                        </div>
                        <div class="errorMsg" *ngIf="showErrorMsg(taxDetailsService.salesTaxNumber) || taxDetailsService.showInvalidVatError">Please enter valid Sales Tax Number</div>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="showFieldsAsPerRegion('TIN')">
                  <div class="multiRadioContainer">
                    <label>Do you have {{ taxDetailsService.checkIfUKOwningEntity() ? 'a UTR / TIN no.' : taxDetailsService.checkIfDMCCOwningEntity() ? 'any tax identification number' : 'a TIN no.' }}?<sup>*</sup></label>
                    <div class="radioInputWrap">
                      <div class="inputBox" [ngClass]="{'active': !taxDetailsService.checkBoxValues.isTinChecked}">
                        <input type="radio" name="isRegisterTIN" id="registerTINNo" [checked]="!taxDetailsService.checkBoxValues.isTinChecked"
                              (change)="toggleCheckBoxes('isTinChecked', 'tin_number')"/>
                        <label for="registerTINNo">No</label>
                      </div>
                      <div class="inputBox" [ngClass]="{'active': taxDetailsService.checkBoxValues.isTinChecked}">
                        <input type="radio" name="isRegisterTIN" id="registerTINYes" [checked]="taxDetailsService.checkBoxValues.isTinChecked"
                              (change)="toggleCheckBoxes('isTinChecked')"/>
                        <label for="registerTINYes">Yes</label>
                      </div>
                    </div>
                  </div>
                  <div class="containerRow gstNumberRow" *ngIf="taxDetailsService.checkBoxValues.isTinChecked">
                    <div class="inputRow">
                      <label for="GSTNumber">What is your {{ taxDetailsService.checkIfUKOwningEntity() ? 'UTR/TIN No.' : taxDetailsService.checkIfDMCCOwningEntity() ? 'tax identification number' : 'TIN no' }}?<sup>*</sup></label>
                      <div class="gstInputBox">
                        <div class="smfull">
                          <input type="text" class="form-control" id="TINNumber"
                                placeholder="{{ getTinPlaceHolder() }}"
                                (keypress)="checkPressedKey($event)"
                                minlength="{{ setMinLengthForTINNumber() }}"
                                [ngClass]="{ 'error': showErrorMsg(taxDetailsService.tinNumber) }"
                                formControlName="tin_number">
                        </div>
                        <div class="errorMsg" *ngIf="showErrorMsg(taxDetailsService.tinNumber)">Please enter valid TIN Number</div>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <div class="containerRow" *ngIf="showFieldsAsPerRegion('reg_type')">
                  <div class="inputRow">
                    <label for="">What is your registration type?<sup>*</sup></label>
                    <select class="form-control" formControlName="registration_type" (change)="setFilesData()"
                            [ngClass]="{ 'error': (taxDetailsService.isContinueClicked && !taxDetailsService.regTYpe.value) }">
                      <option>Regular</option>
                      <option [hidden]="(dataService.user?.address?.billed_as !== 'company')">SEZ</option>
                      <option>Composite Dealer</option>
                    </select>
                  </div>
                </div>
                <div class="multiRadioContainer" *ngIf="showFieldsAsPerRegion('ZeroGst')">
                  <label>Do you avail 0% GST?<sup>*</sup></label>
                  <div class="radioInputWrap">
                    <div class="inputBox" [ngClass]="{'active': !taxDetailsService.checkBoxValues.isZeroGstChecked}">
                      <input type="radio" checked name="availGST" id="availGSTNo" [checked]="!taxDetailsService.checkBoxValues.isZeroGstChecked"
                            (change)="toggleCheckBoxes('isZeroGstChecked')"/>
                      <label for="availGSTNo">No</label>
                    </div>
                    <div class="inputBox" [ngClass]="{'active': taxDetailsService.checkBoxValues.isZeroGstChecked}">
                      <input type="radio" name="availGST" id="availGSTYes" [checked]="taxDetailsService.checkBoxValues.isZeroGstChecked"
                            (change)="toggleCheckBoxes('isZeroGstChecked')"/>
                      <label for="availGSTYes">Yes</label>
                    </div>
                  </div>
                </div>
                <div class="containerRow uploadWrapper" *ngIf="showFieldsAsPerRegion('sez_doc')">
                  <div class="inputRow">
                    <label for="">Please upload your SEZ LOA<sup>*</sup></label>
                    <div class="inputBox filesUpload" [ngClass]="{'error': (taxDetailsService.isContinueClicked && !taxDetailsService.documentFiles.sez_doc['file_name'])}">
                      <ng-container *ngIf="taxDetailsService.documentFiles.sez_doc['file_name']">
                        <span class="uploadedDocName">
                          <span>{{ taxDetailsService.documentFiles.sez_doc['file_name'] }}</span>
                          <span class="icon-cancel" (click)="deleteFile('sez_doc')"></span>
                        </span>
                      </ng-container>
                      <span class="fileInputLabelWrap" [ngClass]="{'inline': taxDetailsService.documentFiles.sez_doc['file_name']}">
                        <label class="text">Drag & Drop the image proof here <br *ngIf="!taxDetailsService.documentFiles.sez_doc['file_name']"/>or
                        </label>{{ taxDetailsService.documentFiles.sez_doc['file_name'] ? '&nbsp;' : '' }}
                        <input type="file" class="custom-file-input" (change)="onFileChange($event, 'sez_doc')" formControlName="sez_doc"/>
                      </span>
                    </div>
                    <div class="errorMsg" *ngIf="(taxDetailsService.isContinueClicked && !taxDetailsService.documentFiles.sez_doc['file_name']) || taxDetailsService.fileUploadingError">
                      Please upload the document, (max size - 8mb, supported files - PDF, TIFF, BITMAP, JPEG, PNG)
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="showFieldsAsPerRegion('PAN')">
                  <div class="multiRadioContainer">
                    <label>Do you have a PAN number?<sup>*</sup></label>
                    <div class="radioInputWrap">
                      <div class="inputBox" [ngClass]="{'active': !taxDetailsService.checkBoxValues.isPanChecked}">
                        <input type="radio" name="havePAN" id="havePANNo" [checked]="!taxDetailsService.checkBoxValues.isPanChecked"
                              (change)="toggleCheckBoxes('isPanChecked', 'pan_number')"/>
                        <label for="havePANNo">No</label>
                      </div>
                      <div class="inputBox" [ngClass]="{'active': taxDetailsService.checkBoxValues.isPanChecked}">
                        <input type="radio" name="havePAN" id="havePANYes" [checked]="taxDetailsService.checkBoxValues.isPanChecked"
                              (change)="toggleCheckBoxes('isPanChecked')"/>
                        <label for="havePANYes">Yes</label>
                      </div>
                    </div>
                  </div>
                  <div class="containerRow" *ngIf="taxDetailsService.checkBoxValues.isPanChecked">
                    <div class="inputRow">
                      <label for="PANNumber">What is your PAN number?<sup>*</sup></label>
                      <div class="inputBox">
                        <input type="text" class="form-control" id="PANNumber" placeholder="eg. BAXJK6587B"
                              [ngClass]="{ 'error': showErrorMsg(taxDetailsService.panNumber) }"
                              pattern="^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$"
                              formControlName="pan_number" >
                      </div>
                      <div class="errorMsg" *ngIf="showErrorMsg(taxDetailsService.panNumber)">Please enter valid PAN Number</div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="showFieldsAsPerRegion('TAN')">
                  <div class="multiRadioContainer">
                    <label>Will you be deducting TDS?<sup>*</sup></label>
                    <div class="radioInputWrap">
                      <div class="inputBox" [ngClass]="{'active': !taxDetailsService.checkBoxValues.isTanChecked}">
                        <input type="radio" name="deductingTDS" id="deductingTDSNo" [checked]="!taxDetailsService.checkBoxValues.isTanChecked"
                              (change)="toggleCheckBoxes('isTanChecked', 'tan_number')"/>
                        <label for="deductingTDSNo">No</label>
                      </div>
                      <div class="inputBox" [ngClass]="{'active': taxDetailsService.checkBoxValues.isTanChecked}">
                        <input type="radio" name="deductingTDS" id="deductingTDSYes" [checked]="taxDetailsService.checkBoxValues.isTanChecked"
                              (change)="toggleCheckBoxes('isTanChecked')"/>
                        <label for="deductingTDSYes">Yes</label>
                      </div>
                    </div>
                  </div>
                  <div class="containerRow" *ngIf="taxDetailsService.checkBoxValues.isTanChecked">
                    <div class="inputRow">
                      <label for="TANNumber">What is your TAN number?<sup>*</sup></label>
                      <div class="inputBox">
                        <input type="text" class="form-control" id="TANNumber" placeholder="eg. BAXJ06587B"
                              [ngClass]="{ 'error': showErrorMsg(taxDetailsService.tanNumber) }"
                              pattern="^([a-zA-Z]){4}([0-9]){5}([a-zA-Z]){1}?$"
                              formControlName="tan_number">
                      </div>
                      <div class="errorMsg" *ngIf="showErrorMsg(taxDetailsService.tanNumber)">Please enter valid TAN Number</div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="showFieldsAsPerRegion('GST_TAN')">
                  <div class="multiRadioContainer">
                    <label>Are you a Gov entity seeking to deduct TDS under GST?<sup>*</sup></label>
                    <div class="radioInputWrap">
                      <div class="inputBox" [ngClass]="{'active': !taxDetailsService.checkBoxValues.isTDSGSTChecked}">
                        <input type="radio" name="deductingTDSGST" id="deductingTDSGSTNo" [checked]="!taxDetailsService.checkBoxValues.isTDSGSTChecked"
                              (change)="toggleCheckBoxes('isTDSGSTChecked', 'gst_tds_number')"/>
                        <label for="deductingTDSGSTNo">No</label>
                      </div>
                      <div class="inputBox" [ngClass]="{'active': taxDetailsService.checkBoxValues.isTDSGSTChecked}">
                        <input type="radio" name="deductingTDSGST" id="deductingTDSGSTYes" [checked]="taxDetailsService.checkBoxValues.isTDSGSTChecked"
                              (change)="toggleCheckBoxes('isTDSGSTChecked')"/>
                        <label for="deductingTDSGSTYes">Yes</label>
                      </div>
                    </div>
                  </div>
                  <div class="containerRow" *ngIf="taxDetailsService.checkBoxValues.isTDSGSTChecked">
                    <div class="inputRow">
                      <label for="GSTTANNumber">what is your GST TAN?<sup>*</sup></label>
                      <div class="inputBox">
                        <input type="text" class="form-control" id="GSTTANNumber" placeholder="eg. BAXJ06587B"
                              [ngClass]="{ 'error': showErrorMsg(taxDetailsService.gstTdsNumber) }"
                              pattern="^([a-zA-Z]){4}([0-9]){5}([a-zA-Z]){1}?$"
                              formControlName="gst_tds_number">
                      </div>
                      <div class="errorMsg" *ngIf="showErrorMsg(taxDetailsService.gstTdsNumber)">Please enter valid TAN Number</div>
                    </div>
                  </div>
                </ng-container>
                <ng-container>
                  <div class="checkboxRow" *ngIf="taxDetailsService.checkIfUKOwningEntity() && !taxDetailsService.checkBoxValues.isVatChecked && !taxDetailsService.checkBoxValues.isTinChecked">
                    <input type="checkbox" id="businessPurose" [checked]="(taxDetailsService.businessCategory === 'business')" (change)="toggleBusinessCategory($event)">
                    <label for="businessPurose">This app will be used for business purpose</label>
                    <div class="tooltipBox">
                      <div class="tooltipIcon"><img src="../../../../../assets/images/info_blue.png" /></div>
                      <div class="tooltipText">Only uncheck if this app is not for business use</div>
                    </div>
                  </div>
                </ng-container>
              </div>
              
              <div class="buttonSaveWrap" *ngIf="(dataService.currentPage === 'taxDetails')">
                <div class="tooltipMessage" *ngIf="(showToolTip && taxDetailsService.checkIfCardAbove2Lacs() && (!taxDetailsService.checkBoxValues.isPanChecked || !taxDetailsService.panNumber.value))">As your Buildcard is over 2 Lakh in value you are required by law to provide your PAN.
                  If there are any issues with this please reach out to your AE to discuss further.</div>
                <button class="btn btnSaveTaxDetails" *ngIf="!checkIfTaxDetailsAvailable()" (click)="showConfirmationPopup()"
                        [ngClass]="{'disable-button': (taxDetailsService.checkIfCardAbove2Lacs() && (!taxDetailsService.checkBoxValues.isPanChecked || !taxDetailsService.panNumber.value))}"
                        (mouseover)="toggleToolTip()" (mouseleave)="toggleToolTip()"
                        [disabled]="disableSaveTaxDetails()">
                  Save Tax Details
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-1"></div>
      </ng-container>
    </div>
    <div class="row" *ngIf="isFromBillingDetails">
      <div class="col-xs-12 noteText">
        <p><strong>Please note : It's your responsibility to provide the correct tax information</strong> (above).</p>
        <p>Builder.ai isn't liable for any issues with the tax benefits, if you don't. Also any tax benefits apply to future instalments and won't be adjusted for past instalments.</p>
      </div>
    </div>

  </div>
</div>

<div class="commonPopUp active" *ngIf="isConfirmationPopup">
  <div class="popOverlay"></div>
  <div class="popHolder updateDetailsModal">
    <div class="closePopUp"><span class="icon-cancel" (click)="toggleConfirmationPopup()"></span></div>
    <div class="innerSection">
      <p class="textContent">
        You are about to submit your tax details, once submitted you will not be able to edit or amend
        this information. Are you sure you want to submit your details now?
      </p>
      <div class="actionBlock">
        <button type="button" class="actionButton confirmBtn Submit" (click)="saveTaxDetails()">
          Yes, please save my details
        </button>
        <button type="button" class="actionButton confirmBtn Submit" (click)="toggleConfirmationPopup()">
          No, I want to check again
        </button>
      </div>
    </div>
  </div>
</div>

<div class="commonPopUp active" *ngIf="areDetailsSaved">
  <div class="popOverlay"></div>
  <div class="popHolder updateDetailsModal">
    <div class="closePopUp"><span class="icon-cancel" (click)="hideSuccessPopup()"></span></div>
    <div class="innerSection">
      <p class="textContent">
        Success! Thanks for providing your tax details, we will update your profile accordingly.
        Any adjustments to your instalments based on the information provided will be visible on
        your 1st invoice. This will be available for review in the payments section of Builder Home.
      </p>
      <div class="actionBlock">
        <button type="button" class="actionButton confirmBtn Submit" (click)="hideSuccessPopup()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>


<!-- modal popup for GST -->
<div class="commonPopUp " ><!-- active -->
  <div class="popOverlay"></div>
  <div class="popHolder GSTCheckModalPopup">
    <div class="closePopUp"><span class="icon-cancel"></span></div>
    <div class="innerSection">
      <div class="modalTitle">Did you mean...?</div>
      <p class="textContent">The name associated with this GSTN / TAN  number is</p>
      <div class="innerContainer">
        <div class="contentRow">
          <input type="text" class="form-control" placeholder="Company Name"/>
        </div>
      </div>
      <p class="textContent">Would you like to update your details to match the government portal?</p>
      <div class="actionBlock">
        <button type="button" class="actionButton cancelBtn">No</button>
        <button type="button" class="actionButton confirmBtn">Yes</button>
      </div>
    </div>
  </div>
</div>
<!-- modal popup for GST end -->

<!-- update details modal popup -->
<div class="commonPopUp" >
  <div class="popOverlay"></div>
  <div class="popHolder updateDetailsModal">
    <div class="closePopUp"><span class="icon-cancel"></span></div>
    <div class="innerSection">
      <div class="modalTitle">Thanks, we have updated your details. </div>
      <p class="textContent">Please review and sign the Terms and Conditions again so that we have the correct <br/>information recorded</p>
      <div class="innerContainer">
        <div class="contentRow">
          <button type="button" class="btnReview">Review Terms and Conditions</button>
        </div>
      </div>
      <div class="actionBlock">
        <button type="button" class="actionButton confirmBtn Submit">Submit</button>
      </div>
    </div>
  </div>
</div>
<!-- update details modal popup end end -->
