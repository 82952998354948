<div class="commonPopUp" [ngClass]="{active: isSharePopup() || isInvitePopup() || isOwnershipTransferPopup()}"
     *ngIf="isSharePopup() || isInvitePopup() || isOwnershipTransferPopup()">
  <div class="popOverlay"></div>
  <div class="popHolder" [ngClass]="isOwnershipTransferPopup() ? 'transferOwnership' : 'sharingPopup'">
    <div class="closePopUp" (click)="hideShareInvitePopup()"><span class="icon-cancel"></span></div>

    <div class="sharingHead" *ngIf="isSharePopup()">
      Share <strong>{{getCardShareInvite().project_name}}</strong> with others.
    </div>
    <div class="sharingHead" *ngIf="isInvitePopup()">
      Invite others to view <strong>{{getCardShareInvite().project_name}}</strong>
    </div>
    <div class="sharingHead" *ngIf="isOwnershipTransferPopup()">
      <h3>Transfer ownership for <strong>{{getCardShareInvite().project_name}}</strong></h3>
      <p>Add the email whom you want to transfer this ownership</p>
    </div>
    <div class="sharingmid">

      <div class="emailCollect" *ngIf="!isOwnershipTransferPopup()">
        <span *ngFor="let email of getEmailsToShareInvite(); let i = index;">{{email}} <em class="icon-cancel"
                                                                                           (click)="removeEmailFromList(i)"></em></span>
      </div>

      <form role="form" #emailForm>
        <ul>
          <li>
            <input type="email" email #email="ngModel" placeholder="Enter email here and press enter" required
                   [(ngModel)]="phaseService.emailToEnterForOwnershipTransfer" name="email" [pattern]="validEmailPattern" (keydown)="errorMessage = null"
                   (keyup.enter)="!isOwnershipTransferPopup() ? mailEntered() : null">
            <span class="errorMsg"
                  *ngIf="checkIfEmailValid()">Please enter valid email</span>
            <span class="errorMsg" *ngIf="errorMessage">{{ errorMessage }}</span>
          </li>
        </ul>
      </form>
    </div>

    <div class="footerButton" *ngIf="!isOwnershipTransferPopup()">
      <button class="cancleButton" (click)="hideShareInvitePopup()">Cancel</button>
      <button class="doneButton"
              (click)="phaseService.emailToEnterForOwnershipTransfer || getEmailsToShareInvite().length > 0 ? addEmailToList(emailForm) : email.focus()">
        Done <img *ngIf="preventClick" src="../../../../../assets/images/buttonLoader.gif" alt=""/></button>
    </div>

    <div class="footerButton" *ngIf="isOwnershipTransferPopup()">
      <button class="doneButton" (click)="phaseService.emailToEnterForOwnershipTransfer || getEmailsToShareInvite().length > 0 ? addEmailToList(emailForm) : email.focus()">
        Transfer ownership <img *ngIf="preventClick" src="../../../../../assets/images/buttonLoader.gif" alt=""/></button>
    </div>

  </div>
</div>
