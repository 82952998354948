import {
  AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, Renderer2,
  ViewChild
} from '@angular/core';
import { DataService } from '@shared/services/data.service';
import { PhaseService } from '@shared/services/phase.service';
import { ApiRequests } from '@shared/services/api-requests.service';
import { TeamsModel } from '@models/teams-model/teams.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ModelMapperService } from '@shared/services/model-mapper.service';
import { Constants } from '@shared/utils/constants';
import { Angulartics2Segment } from 'angulartics2/segment';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { TeamCardComponent } from './team-card/team-card.component';
import { CommonService } from '@shared/services/common.service';
import { Intercom } from 'ng-intercom';
import moment from 'moment';
import { BuilderCloudModel } from '@models/builder-cloud/cloud-model';
import { Options } from '@angular-slider/ngx-slider';;
import { Subscription } from 'rxjs';
import { DeliveryService } from '@featuremodules/delivery-module/services/delivery.service';
import { WebSocketService } from '@shared/services/web-socket.service';

@Component({
  selector: 'delivery',
  templateUrl: 'delivery.component.html',
  styleUrls: ['delivery.component.scss']
})


export class DeliveryComponent implements OnInit, OnDestroy, AfterViewInit {
  innerWidth = 1280;
  @Output() teamSelection: EventEmitter<boolean> = new EventEmitter(false);
  public showPrompt: boolean;
  public step1 = true;
  public step2 = true;
  public step3 = true;
  public step4 = false;
  public currentUrl;
  public showBottomPipe = false;
  public openCustomPhasePopup = false;
  public showConfirmStep = false;
  public leftNavDisabled = false;
  public rightNavDisabled = false;
  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;
  isMobileActive = false;
  public showOverlay = false;
  public tooltipIndex: number = 0;
  public tutorialSeen = true;
  public showDiscardAdvancedPopup: boolean;
  @ViewChild(TeamCardComponent)
  private teamCardComponent: TeamCardComponent;
  showCloudPopup: boolean;
  barValue = 500;
  public deliveryInfo = false;
  systemConfig: object;
  selectedRange = '0 - 500';
  public currencyChangeSubscription: Subscription;
  viewMoreSCare = false;
  viewMoreSCarePlus = false;
  viewMoreECare = false;
  viewMoreECarePlus = false;
  viewMoreEntSubscription = false;
  viewMoreStndSubscription = false;
  options: Options = {
    showTicksValues: true,
    showSelectionBar: true,
    stepsArray: [
      { value: 500 },
      { value: 5000 },
      { value: 50000 },
      { value: 1000000 }
    ],
    translate: (value: any): string => {
      if (value <= 500) {
        return '0 - ' + value;
      } else if ((value > 500) && (value <= 5000)) {
        return '500 - ' + (value / 1000 + 'k');
      } else if ((value > 5000) && (value <= 50000)) {
        return '5k - ' + (value / 1000 + 'k');
      } else if (value > 50000) {
        return '50K +';
      }
    }
  };
  moment: any;
  wssMessageSubsciription: Subscription;

  constructor(
    public dataService: DataService,
    public phaseService: PhaseService,
    private _apiRequest: ApiRequests,
    private _router: Router,
    public analyticsSegment: Angulartics2Segment,

    private apiRequest: ApiRequests,
    private modelMapperService: ModelMapperService,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2, private deliveryService: DeliveryService,
    private webSocketService: WebSocketService,
  ) {
    this.innerWidth = window.innerWidth;
    this.moment = moment;
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        if (event.url.includes('dashboard')) {
          this.phaseService.reset();
          this.phaseService.showSaveProgress = false;
          this.renderer.removeClass(document.getElementById('app-component'), 'hide-scroll-bar');
        }
      }
    });
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.dataService.showExpertsButtonInHeader = false;
    }, 4000);
    this.phaseService.currentProjectName = '';
    this.phaseService.showBottomBar = true;
    if (this.dataService.user.enterprise_lead) {
      this.barValue = 50000;
    }
    if (this.dataService.user && this.activatedRoute.snapshot.paramMap.get('id') && !this.phaseService.buildCardEditMode && !this.dataService.shareUrlData) {
      const uniqCode = this.activatedRoute.snapshot.paramMap.get('id');
      if (!this.dataService.homePageData.platforms) {
        this.dataService.showHideLoader(true);
        this.apiRequest.fetchHomePageData().subscribe((data: any) => {
          this.dataService.dataLoadingCurrency = Object.assign({}, this.dataService.homePageData.currency);
          this.phaseService.platformPrices = Array.apply(null, Array(this.dataService.homePageData.platforms.length));
          this.phaseService.platformPrices.map((x, i) => {
            return i;
          });
          if (this.dataService.homePageData.currency) {
            this.phaseService.specing_price = this.dataService.homePageData.currency.specing_price;
          }
          this.getBuildCardData(uniqCode);
        });
      } else {
        this.getBuildCardData(uniqCode);
      }
    } else {
      this.initializeDeliveryPage();
    }
    if (this.dataService.checkIfEnterpriseUserOrCard() && (this.phaseService.selectedHash.care_type !== 'enterprise_care')) {
      this.phaseService.selectedHash.care_type = 'enterprise_care_plus';
    }
    this.wssMessageSubsciription = this.webSocketService.buildCardFetched$.subscribe(() => {
      this.rebuildPageAfterBuildCardRefresh();
    });
  }

  rebuildPageAfterBuildCardRefresh() {
    this.phaseService.buildCardEditMode = true;
    this.dataService.isEditBuildCard = true;
    this.phaseService.setPromotionData();
    this.initializeDeliveryPage();
  }

  getBuildCardData(uniqCode) {
    this.apiRequest.fetchSingleBuildCardData(uniqCode).subscribe((data: any) => {
      this.phaseService.setUpInFullBuildCard();
      this.rebuildPageAfterBuildCardRefresh();
    });
  }


  initializeDeliveryPage() {
    const buildCardData = this.dataService.buildCardData;
    if (!buildCardData.new_care_flow && (buildCardData.status === 'card_initialized' || buildCardData.status === 'card_completed')) {
      this.dataService.buildCardData.new_care_flow = true;
    }
    /*if (!buildCardData.subscription_flow && (buildCardData.status === 'card_initialized' || buildCardData.status === 'card_completed')) {
      this.dataService.buildCardData.subscription_flow = true;
    }*/
    this.initializeTeamSpeed();
    this.phaseService.setBreadcrumbLink(this._router.url.split('/')[1]);
    this.setPhases();
    this.phaseService.preventClick = false;
    this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.delivery.code);

    this.setBreadCrumbForDelivery();
    if (window.innerWidth < 768) {
      this.isMobileActive = true;
    } else {
      this.isMobileActive = false;
    }
    this.handleTutorial();
    if (this.phaseService.selectedHash.is_advance) {
      this.phaseService.isAdvancedVersion = true;
    } else {
      this.phaseService.isAdvancedVersion = false;
    }
    this.setUpCurrencyChangeSubscription();
    this.getBuilderCloudDetails();
    if (this.dataService.modifiedBuildCardData && this.dataService.modifiedBuildCardData.care_type.add) {
      this.phaseService.selectedHash.care_type = this.dataService.modifiedBuildCardData['care_type']['add'];
    }
    if (this.dataService.checkIfEnterpriseUserOrCard() && !this.dataService.buildCardData.subscription_flow && (this.dataService.buildCardData.status === 'card_initialized')) {
      this.phaseService.selectedHash.is_under_support = true;
    } else if (this.dataService.isUnpaidCard() && !this.dataService.buildCardData.subscription_flow) {
      this.phaseService.selectedHash.is_under_support = false;
    }
  }

  public ngAfterViewInit() {
    this.phaseService.checkExitIntentTimer();
    this.phaseService.checkIdlestate();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (window.innerWidth < 768) {
      this.isMobileActive = true;
    } else {
      this.isMobileActive = false;
    }
  }

  public advancedTab() {
    if (this.phaseService.isAdvancedVersion) {
      let selectedPhases = this.dataService.homePageData.build_phases.filter(phase => {
        return phase.selected;
      });
      if (selectedPhases.length > 1) {
        let speedSame = false;
        //check if speed is same
        for (let i = 0; i < selectedPhases.length - 1; i++) {
          speedSame = selectedPhases[i].speed.id === selectedPhases[i + 1].speed.id;
          if (!speedSame) {
            this.showDiscardAdvancedPopup = true;
            break;
          }
        }

        //check if platforms are same
        let samePlatform = false;
        for (let i = 0; i < selectedPhases.length - 1; i++) {
          if (selectedPhases[i].platforms.length !== selectedPhases[i + 1].platforms.length) {
            samePlatform = false;
            this.showDiscardAdvancedPopup = true;
            break;
          } else {
            for (let platform of selectedPhases[i].platforms) {
              if (selectedPhases[i + 1].platforms.findIndex((platfrm) => {
                  return platfrm.id === platform.id;
                }) !== -1) {
                samePlatform = true;
              } else {
                samePlatform = false;
                this.showDiscardAdvancedPopup = true;
                break;
              }
            }
          }
          if (!samePlatform) {
            break;
          }
        }
        if (speedSame) {
          this.phaseService.selectedSpeed = selectedPhases[0].speed;
        }
        if (samePlatform && speedSame) {
          this.phaseService.isAdvancedVersion = !this.phaseService.isAdvancedVersion;
          this.phaseService.selectedHash.is_advance = false;
          this.handleModeSwitch();
        }
      } else {
        this.phaseService.isAdvancedVersion = !this.phaseService.isAdvancedVersion;
        this.phaseService.selectedHash.is_advance = false;
        this.handleModeSwitch();
      }
    } else {
      this.phaseService.trackEventForObject(this.analyticsSegment, 'advanced_settings_selected', {
        user_id : this.dataService.user?.id,
        phase_selected : this.phaseService.getPhaseTitle(),
        same_speed_selected : this.phaseService.assignSameSpeed
      });
      this.phaseService.isAdvancedVersion = !this.phaseService.isAdvancedVersion;
      this.phaseService.selectedHash.is_advance = true;
      this.setNonParallelPlatformsInAllPhases();
      this.phaseService.updatePrice();
    }
  }

  public setPhases() {
    if (!this.dataService.shareUrlData) {
      if (this.phaseService.selectedPhases.length > 0) {
        this.phaseService.setSelectedItems('selectedPhases');
      } else {
        this.preSelectItems();
      }
      this.phaseService.updatePrice();

      this.dataService.homePageData.build_phases.forEach(
        phase => (phase.active = true)
      );
    } else {
      this.phaseService.setPhasesUsingSharedUrl();
    }
  }

  public initializeTeamSpeed() {
    this.dataService.currentPage = 'delivery';
    if (!(this.dataService.teams.all && this.dataService.teams.all.length > 0)) {
      this._apiRequest.fetchTeams().subscribe((data: any) => {
        this.dataService.teams = this.modelMapperService.getTeamMappedModel(
          new TeamsModel(),
          data.teams
        );
      });
    }
    if (this.dataService.homePageData.speed && this.dataService.homePageData.speed[0].title !== 'Relaxed') {
      this.dataService.homePageData.speed.reverse();
    }
    if (this.dataService.checkIfBuildCardisinvited() && this.dataService.user && this.dataService.homePageData.speed && !this.dataService.user.enterprise_lead && this.dataService.buildCardData && (this.dataService.buildCardData.enterprise_lead === 'true') && !this.dataService.isEditBuildCardAfterSecurityPaid() && !this.phaseService.dontSetCareConfig) {
      this.phaseService.selectedSpeed = this.dataService.homePageData.speed.find(speed => speed.title === 'Relaxed');
      this.dataService.homePageData.build_phases.forEach(phase => {
        phase.speed = this.phaseService.selectedSpeed;
      });
      this.phaseService.selectedHash.care_type = 'standard_care';
    }
    if (this.dataService.checkIfBuildCardisinvited() && this.dataService.user && this.dataService.user.enterprise_lead && this.dataService.buildCardData && (this.dataService.buildCardData.enterprise_lead !== 'true') && !this.dataService.isEditBuildCardAfterSecurityPaid() && !this.phaseService.dontSetCareConfig && !this.dataService.buildCardData.new_care_flow) {
      this.phaseService.selectedSpeed = this.dataService.homePageData.speed.find(speed => speed.title === 'Standard');
      this.dataService.homePageData.build_phases.forEach(phase => {
        phase.speed = this.phaseService.selectedSpeed;
      });
      this.phaseService.selectedHash.care_type = 'enterprise_care_plus';
    }
    this.phaseService.selectedSpeed
      ? this.phaseService.setSelectedItems('selectedSpeed')
      : this.resetSpeed();
  }

  getCurrencySymbol() {
    return this.phaseService.getCurrencySymbol();
  }

  public resetSpeed() {
    if (
      this.dataService.homePageData.speed &&
      this.dataService.homePageData.speed.length > 0
    ) {
      this.dataService.homePageData.speed[0].selected = true;
      if (this.dataService.user && this.dataService.user.enterprise_lead) {
        const speedForEnt = this.phaseService.setSpeedForMultiplier();
        this.phaseService.selectedSpeed = this.dataService.homePageData.speed.find(speed => speed.id === speedForEnt);
      } else if (this.dataService.checkIfEnterpriseUserOrCard() || this.dataService.checkIfCardWithUsd()) {
        this.phaseService.selectedSpeed = this.dataService.homePageData.speed.find(speed => speed.title === 'Standard');
      } else {
        this.phaseService.selectedSpeed = this.dataService.homePageData.speed[0];
      }
      this.phaseService.setSelectedSpeedId(this.phaseService.selectedSpeed.id);
    }
  }

  public preSelectItems() {
    if (this.dataService.homePageData.build_phases) {
      for (const buildType of this.dataService.homePageData.build_phases) {
        if (buildType.title.includes('Design') || buildType.title.includes('Full Build')) {
          this.setDataInPhases(buildType);
          this.phaseService.toggleSelectedBuild(buildType);
          this.phaseService.toggleSelectedBuildId(buildType.index);
          this.phaseService.toggleSelectedBuildIds(buildType.index);
        }
      }
    }
  }

  public setDataInPhases(phase) {
    phase.is_parallel_platform = true;
    if (this.phaseService.selectedPlatforms.length > 0) {
      phase.platforms = this.phaseService.selectedPlatforms;
    } else {
      this.phaseService.selectedPlatforms.push(this.dataService.homePageData.platforms[0]);
      phase.platforms = this.phaseService.selectedPlatforms;
    }
    phase.features = this.phaseService.selectedFeatures;
    if (this.dataService.homePageData.speed && this.dataService.homePageData.speed.length > 0) {
      phase.speed = this.dataService.homePageData.speed.find(speed => speed.title === 'Relaxed');
    }
  }

  public toggleBottomPipe() {
    this.showBottomPipe = !this.showBottomPipe;
  }

  public setBreadCrumbForDelivery() {
    if (this.phaseService.selectedApps.length > 0) {
      const step = this.phaseService.headerBreadCrumb.find(
        step => step.title.indexOf('Apps') > -1
      );
      if (step) {
        step.selected = true;
      }
    } else {
      const step = this.phaseService.headerBreadCrumb.findIndex(
        step => step.title.indexOf('Apps') > -1
      );
      if (step > -1) {
        this.phaseService.headerBreadCrumb.splice(step, 1);
      }
    }
    if (this.phaseService.selectedFeatures.length > 0) {
      const step = this.phaseService.headerBreadCrumb.find(
        step => step.title.indexOf('Features') > -1
      );
      if (step) {
        step.selected = true;
      }
    }
    const step = this.phaseService.headerBreadCrumb.find(
      step => step.title.indexOf('Delivery') > -1
    );
    if (step) {
      step.selected = false;
    }
  }

  public openCPP() {
    this.openCustomPhasePopup = true;
  }

  public closeCPP() {
    this.openCustomPhasePopup = false;
    this.showConfirmStep = false;
  }

  public gotoConfirm() {
    this.showConfirmStep = true;
  }

  public discardCustomPhase() {
    this.openCustomPhasePopup = false;
    this.showConfirmStep = false;
  }

  public moveLeft() {
    this.ds.moveLeft();
  }

  public moveRight() {
    this.ds.moveRight();
  }

  public leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  public rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }

  public ngOnDestroy() {
    this.dataService.shareUrlData = null;
    this.dataService.currentPage = '';
    if (this.wssMessageSubsciription) {
      this.wssMessageSubsciription.unsubscribe();
    }
  }

  public sortSpeedASC(index, speedItem) {
    return speedItem.title;
  }

  /**start tutorial logic */

  public handleTutorial() {
    setTimeout(() => {
      if ((this.commonService.isPlatformBrowser ? localStorage.getItem('tutorial_seen_delivery') !== 'true' : true) && !this.phaseService.buildCardEditMode) {
        this.tutorialSeen = false;
        this.showOverlay = true;
        this.tooltipIndex = 0;
      }
    }, 100);
  }

  public checkNavigationToPage() {
    return this.dataService.landingPage || this.dataService.shareUrlData;
  }

  public closeTooltip($event) {
    this.tooltipIndex = null;
    if (this.commonService.isPlatformBrowser) {
      localStorage.setItem('tutorial_seen_delivery', 'true');
    }
    this.tutorialSeen = true;
  }

  public handleDiscard(isDiscard) {
    this.showDiscardAdvancedPopup = false;
    if (isDiscard) {
      this.handleModeSwitch();
      this.phaseService.selectedHash.is_advance = false;
    }
    this.phaseService.isAdvancedVersion = !isDiscard;
  }

  public getFinalDeliveryDate() {
    if (this.phaseService.timePromotionApplied()) {
      return this.moment(this.phaseService.selectedHash.pre_kick_off_date).add((this.phaseService.finalPricenWeeks.weeks * 7), 'days').format('DD-MMM-YYYY');
    } else {
      return this.moment(this.phaseService.getFinalDeliveryDate()).format('DD-MMM-YYYY');
    }
  }

  public setSimpleModeDataInPhases(phase) {
    phase.is_parallel_platform = true;
    let phasePlatforms = [];
    if (this.phaseService.selectedPlatforms.length > 0) {
      const selectedPlatforms = this.phaseService.selectedPlatforms.map(platform => platform.id);
      if (phase.title.includes('MVP') || (phase['type'] === 'custom_phase')) {
        phase.features = JSON.parse(JSON.stringify(phase.features));
        phasePlatforms = phase.platforms.map(p => p.id);
        if (phase.platforms && this.phaseService.selectedPlatforms.length > 0) {
          phasePlatforms.forEach(() => {
            phase.platforms.forEach((platform, index) => {
              if (selectedPlatforms.indexOf(platform.id) === -1) {
                phase.platforms.splice(index, 1);
              }
            });
          });
          phase.features.map(f => f.id).forEach(() => {
            phase.features.forEach(feature => {
              const featurePlatforms = feature.platforms.map(p => p.id);
              featurePlatforms.forEach(() => {
                feature.platforms.forEach((pltfrm, i) => {
                  if (phase.platforms.map(p => p.id).indexOf(pltfrm.id) === -1) {
                    feature.platforms.splice(i, 1);
                    feature.phaseId.splice(feature.phaseId.findIndex(p => p === (phase.id + '' + pltfrm.id)), 1);
                  }
                });
              });
            });
          });
          phase.features = phase.features.filter(f => (f.platforms.length > 0) && (f.phaseId.length > 0) && (f.phaseId.map(id => id.charAt(0)).includes(phase.id.toString())));
          phase.features.forEach(feature => {
            const featurePlatforms = feature.platforms.map(p => p.id);
            featurePlatforms.forEach(() => {
              this.phaseService.selectedFeatures.forEach(ftr => {
                if (feature.id === ftr.id) {
                  if (!ftr.platforms) { ftr.platforms = []; }
                  if (!ftr.phaseId) { ftr.phaseId = []; }
                  ftr.platforms.forEach((p1, i) => {
                    if (this.phaseService.selectedPlatforms.map(p => p.id).indexOf(p1.id) === -1) {
                      ftr.platforms.splice(i, 1);
                      ftr.phaseId.splice(ftr.phaseId.findIndex(p => p === (phase.id + '' + p1.id)), 1);
                    }
                  });
                }
              });
            });
          });
          if ((phase.platforms.length === 0)) {
            phase.platforms.push(this.phaseService.selectedPlatforms[0]);
            phase.selected = false;
            this.phaseService.removeSelectedBuildId(phase.index);
            this.phaseService.removeSelectedBuildIds(phase.index);
            this.phaseService.removeSelectedBuild(phase);
          }
        } else {
          phase.platforms = [];
          phase.platforms.push(this.phaseService.selectedPlatforms[0]);
        }
      } else if (this.phaseService.checkIfOnlyRapidPrototypeSelected()) {
        if (phase.is_tailor_made && this.phaseService.selectedPlatformsForRapidPrototype && this.phaseService.selectedPlatformsForRapidPrototype.length > 0) {
          phase.platforms = this.phaseService.selectedPlatformsForRapidPrototype;
        } else if (this.checkIfSelectedPlatformsAreDevices()) {
          phase.platforms = [this.dataService.homePageData.platforms[0]];
        } else {
          phase.platforms = this.phaseService.selectedPlatforms;
        }
      } else {
        phase.platforms = this.phaseService.selectedPlatforms;
      }
    } else {
      this.phaseService.selectedPlatforms.push(this.dataService.homePageData.platforms[0]);
      if (this.phaseService.checkIfOnlyRapidPrototypeSelected()) {
        if (phase.is_tailor_made && this.phaseService.selectedPlatformsForRapidPrototype && this.phaseService.selectedPlatformsForRapidPrototype.length > 0) {
          phase.platforms = this.phaseService.selectedPlatformsForRapidPrototype;
        } else if (this.checkIfSelectedPlatformsAreDevices()) {
          phase.platforms = [this.dataService.homePageData.platforms[0]];
        } else {
          phase.platforms = this.phaseService.selectedPlatforms;
        }
      } else {
        phase.platforms = this.phaseService.selectedPlatforms;
      }
    }
    if (!phase.title.includes('MVP') && (phase['type'] !== 'custom_phase')) {
      phase.features = this.phaseService.selectedFeatures;
    }
    phase.speed = this.phaseService.selectedSpeed;
  }

  private handleModeSwitch() {
    for (let phase of this.dataService.homePageData.build_phases) {
      if (phase.selected) {
        this.setSimpleModeDataInPhases(phase);
      }
    }
    this.phaseService.updatePrice();
  }

  checkIfSelectedPlatformsAreDevices() {
    const devices = this.phaseService.selectedPlatformsForRapidPrototype.map(p => p.title);
    const areDevices = [];
    this.phaseService.selectedPlatforms.forEach(platform => {
      if (devices.indexOf(platform.title) !== -1) {
        areDevices.push(platform.title);
      }
    });
    return areDevices.length ? true : false;
  }


  private setNonParallelPlatformsInAllPhases() {
    for (let phase of this.dataService.homePageData.build_phases) {
      if (phase.selected && !phase.title.toLowerCase().includes('MVP'.toLowerCase())) {
        phase.is_parallel_platform = true;
      }
    }
  }

  getFirstDeliveryDate() {
    return this.moment(this.phaseService.selectedHash.pre_kick_off_date).add(this.phaseService.finalPricenWeeks.first_delivery_time, 'days').format('DD-MMM-YYYY');
  }

  getBuilderCloudDetails() {
    if (this.dataService.shareUrlData) {
      this.dataService.buildCardData = this.dataService.shareUrlData;
    }
    const buildCardData = this.dataService.buildCardData;
    if (this.phaseService.buildCardEditMode && (buildCardData.minimum_cloud_price || buildCardData.cloud_price) || (this.dataService.shareUrlData && this.dataService.shareUrlData.cloud_price)) {
      this.phaseService.isBuilderCloudChecked = buildCardData.cloud_opted;
      if (!this.dataService.modifiedBuildCardData) {
        this.phaseService.selectedHash.cloud_opted = buildCardData.cloud_opted;
      }
      this.barValue = this.dataService.buildCardData.cloud_user_count;
      if (this.dataService.isEditBuildCardAfterSecurityPaid() && !buildCardData.maximum_cloud_price) {
        this.phaseService.selectedHash.cloud_price = buildCardData.cloud_price;
      } else {
        this.phaseService.selectedHash.maximum_cloud_price = buildCardData.maximum_cloud_price;
        this.phaseService.selectedHash.minimum_cloud_price = buildCardData.minimum_cloud_price;
      }
    }
    this.fetchCloudParameters();
  }

  toggleBuilderCloud(event) {
    if ((event === 'selectCloud') || event.target.checked) {
      const selectedHash = this.phaseService.selectedHash;
      this.phaseService.isBuilderCloudChecked = true;
      this.showCloudPopup = false;
      if (this.dataService.isEditBuildCardAfterSecurityPaid() && !this.dataService.buildCardData.maximum_cloud_price) {
        selectedHash.cloud_price = selectedHash.cloud_price ? selectedHash.cloud_price : this.phaseService.builderCloudDetails[0].monthly_cost;
      } else {
        selectedHash.minimum_cloud_price = selectedHash.minimum_cloud_price ? selectedHash.minimum_cloud_price : this.phaseService.builderCloudDetails[0].minimum_cost;
        selectedHash.maximum_cloud_price = selectedHash.maximum_cloud_price ? selectedHash.maximum_cloud_price : this.phaseService.builderCloudDetails[0].maximum_cost;
      }
      selectedHash.cloud_opted = true;
    } else if (!event.target.checked) {
      this.phaseService.trackEventForObject(this.analyticsSegment,  'builder_cloud_unselected', {
        user_id : this.dataService.user?.id,
        phase_selected : this.phaseService.getPhaseTitle()
      });
      this.showCloudPopup = true;
      this.phaseService.isBuilderCloudChecked = false;
      this.phaseService.selectedHash.cloud_opted = false;
    }
  }

  closeCloudPopup() {
    this.showCloudPopup = false;
  }

  setSystemValues(index) {
    this.phaseService.selectedIndexForCloudPrice = index;
    const cloudIndex = this.phaseService.builderCloudDetails[index];
    if (this.dataService.isEditBuildCardAfterSecurityPaid() && !this.dataService.buildCardData.maximum_cloud_price) {
      this.phaseService.selectedHash.cloud_price = cloudIndex.monthly_cost;
    } else {
      this.phaseService.selectedHash.maximum_cloud_price = cloudIndex.maximum_cost;
      this.phaseService.selectedHash.minimum_cloud_price = cloudIndex.minimum_cost;
    }
    this.systemConfig = cloudIndex.system_configuration;
  }

  getCloudPrice(event) {
    this.phaseService.trackEventForObject(this.analyticsSegment,  'cloud_pricing_interacted', {
      user_id : this.dataService.user?.id,
      phase_selected : this.phaseService.getPhaseTitle()
    });
    this.phaseService.selectedHash.cloud_user_count = event;
    this.barValue = event;
    this.setSystemValuesAccordingToRange(event);
  }

  setSystemValuesAccordingToRange(event) {
    this.selectedRange = this.setSelectedRange(event);
    if (event <= 500) {
      this.setSystemValues(0);
    } else if ((event > 500) && (event <= 5000)) {
      this.setSystemValues(1);
    } else if ((event > 5000) && (event <= 50000)) {
      this.setSystemValues(2);
    } else if (event > 50000) {
      this.setSystemValues(3);
    }
  }

  getBuilderCloudPrice() {
    const selectedHash = this.phaseService.selectedHash;
    if ((selectedHash.minimum_cloud_price >= 0) || (selectedHash.cloud_price >= 0)) {
      if (this.dataService.isEditBuildCardAfterSecurityPaid() && !selectedHash.maximum_cloud_price) {
        return this.dataService.getLocaledPrice(selectedHash.cloud_price.toFixed(2));
      } else {
        const minPrice = this.dataService.getLocaledPrice(selectedHash.minimum_cloud_price.toFixed(2));
        if (this.selectedRange === '50K +') {
          return minPrice + ' + '
        }else{
          return minPrice + ' - ' + this.getCurrencySymbol() + this.dataService.getLocaledPrice(selectedHash.maximum_cloud_price.toFixed(2));
        }
      }
    }
  }

  setSelectedRange(value) {
    if (value <= 500) {
      return '0 - ' + value;
    } else if ((value > 500) && (value <= 5000)) {
      return '500 - ' + (value / 1000 + 'k');
    } else if ((value > 5000) && (value <= 50000)) {
      return '5k - ' + (value / 1000 + 'k');
    } else if (value > 50000) {
      return '50K +';
    }
  }

  platformChaged() {
    this.fetchCloudParameters();
  }

  getBuilderCareShowHide() {
    const mvp = this.phaseService.selectedPhases.find(
      phase => (phase.title === 'MVP') || (phase['type'] === 'custom_phase')
    );
    const fullBuild = this.phaseService.selectedPhases.find(
      phase => phase.title === 'Full Build'
    );
    if ((mvp && mvp.selected) || (fullBuild && fullBuild.selected)) {
      if (this.dataService.buildCardData.new_care_flow) {
        if (!this.dataService.buildCardData.subscription_flow) {
          this.phaseService.selectedHash.is_under_support = true;
        }
        if (!this.phaseService.selectedHash.care_type && this.dataService.checkIfEnterpriseUserOrCard()) {
          this.phaseService.selectedHash.care_type = 'enterprise_care_plus';
        } else if (!this.phaseService.selectedHash.care_type) {
          this.phaseService.selectedHash.care_type = 'standard_care';
        }
      }
      return true;
    } else {
      this.phaseService.selectedHash.is_under_support = false;
      return false;
    }
  }

  deliveryInfoToggle() {
    this.deliveryInfo = !this.deliveryInfo;
  }

  setUpCurrencyChangeSubscription() {
    this.currencyChangeSubscription = this.phaseService.getCurrencyUpdatedCallback().subscribe(isCurrencyChanged => {
      if (isCurrencyChanged && isCurrencyChanged.is_currency_updated && this.dataService.currentPage === 'delivery') {
        this.phaseService.updatePrice();
      }
    });
  }


  addCustomPhase() {
    const mvp = Object.assign({}, this.dataService.homePageData.build_phases[3]);
    const totalPhases = this.dataService.homePageData.build_phases.length;
    let phaseNumber = 1;
    mvp.selected = true;
    mvp.id = this.dataService.homePageData.build_phases.length + 1;
    if (this.dataService.homePageData.build_phases[totalPhases - 1].title.includes('New phase')) {
      phaseNumber = parseInt(this.dataService.homePageData.build_phases[totalPhases - 1].title.slice(10)) + 1;
    }
    mvp.title = 'New phase ' + phaseNumber;
    mvp.features = [];
    mvp.platforms = [];
    mvp.is_parallel_platform = true;
    mvp['type'] = 'custom_phase';
    if (mvp.build_card_phase_id) { delete mvp.build_card_phase_id; }
    mvp.index = this.dataService.homePageData.build_phases.length;
    if (!mvp.speed) {
      mvp['speed'] = this.phaseService.selectedSpeed;
    }
    this.phaseService.selectedHash.is_under_support = true;
    this.phaseService.isBuilderCloudChecked = true;
    this.phaseService.selectedHash.cloud_opted = true;
    this.dataService.homePageData.build_phases.push(mvp);
    this.phaseService.toggleSelectedBuild(mvp);
    this.phaseService.toggleSelectedBuildId(mvp.index);
    this.phaseService.toggleSelectedBuildIds(mvp.index);
    if (this.checkIfTailorMadeAndOneCustom()) {
      this.deliveryService.setDataInRapidPrototype();
      this.dataService.homePageData.build_phases.forEach(phase => {
        phase.speed = this.phaseService.selectedSpeed;
      });
    }
    // this.cdr.detectChanges();
    setTimeout(() => {
      this.moveRight();
    }, 500);
  }

  checkIfTailorMadeAndOneCustom() {
    const selectedPhases = this.dataService.homePageData.build_phases.filter(phase => phase.selected);
    const prototype = this.dataService.homePageData.build_phases.find(
      phase => phase.title === 'Professional Prototype'
    );
    if (selectedPhases && (selectedPhases.length === 2) && prototype && prototype.selected) {
      return true;
    }
    return false;
  }

  fetchCloudParameters() {
    const data = {
      currency: this.phaseService.currencyForCAT,
      features: {
        builder_feature_ids: this.phaseService.getFeatureIdsForCloudData(),
        custom_features: this.phaseService.selectedFeatures.filter(feature => feature.user_requested)
      },
      location: this.phaseService.selectedTeam ? this.phaseService.selectedTeam.title : 'Anywhere',
      platforms: this.phaseService.selectedPlatforms.map(platform => platform.title),
      templates: this.phaseService.selectedApps.map(template => template.id)
    };
    this.apiRequest.fetchBuilderCloudDetails(data).subscribe((resp: BuilderCloudModel) => {
      this.phaseService.builderCloudDetails = resp;
      this.phaseService.builderCloudDetailsForUSD = JSON.parse(JSON.stringify(resp));
      this.phaseService.convertCloudPriceBasedOnUserCurrency();
      this.phaseService.selectedHash.cloud_user_count = this.barValue;
      this.setSystemValuesAccordingToRange(this.barValue);
    });
  }

  getMVPFeaturesLength() {
    const mvpPhase = this.dataService.homePageData.build_phases.find(phase => phase.title === 'MVP');
    if (mvpPhase.features && mvpPhase.features.length) {
      return mvpPhase.features.length;
    } else {
      return 0;
    }
  }

  setCareChecked(careType) {
    return ((this.phaseService.selectedHash.care_type === careType) && this.phaseService.selectedHash.is_under_support);
  }

  viewMoreOrLess(careType) {
    if (careType === 'standard_care') {
      this.viewMoreSCare = !this.viewMoreSCare;
    } else if (careType === 'standard_care_plus') {
      this.viewMoreSCarePlus = !this.viewMoreSCarePlus;
    } else if (careType === 'enterprise_care') {
      this.viewMoreECare = !this.viewMoreECare;
    } else if (careType === 'enterprise_care_plus') {
      this.viewMoreECarePlus = !this.viewMoreECarePlus;
    } else if (careType === 'enterpriseCare') {
      this.viewMoreEntSubscription = !this.viewMoreEntSubscription;
    } else if (careType === 'standardCare') {
      this.viewMoreStndSubscription = !this.viewMoreStndSubscription;
    }
  }

  getSelectedSpeedDescription() {
    switch (this.phaseService.selectedSpeed?.title) {
      case 'Relaxed':
        return "Our most budget-friendly option for those who aren't in a hurry";
      case 'Slow':
        return 'For those with a fixed long-term plan who want to keep costs down';
      case 'Standard':
        return 'The perfect middle ground for anyone with a modest budget and medium-term deadlines';
      case 'Fast':
        return 'We put your app build on turbo charge for a few extra bucks';
      case 'Speedy':
        return 'We build your app at the speed of light for a premium price';
    }
  }

  isPlatformRequest() {
    return this.phaseService.isPlatformRequest;
  }
  
  getSelectedDate() {
    return this.moment(this.phaseService.selectedHash.pre_kick_off_date).format('D MMM YYYY');
  }

}

