import {Component, OnChanges, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiRequests} from '@shared/services/api-requests.service';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import {AppDataService} from '@rootmodule/app-data.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {CommonService} from '@shared/services/common.service';
import {HomeTemplateService} from '@shared/services/home-template.service';
// import {OnChange} from 'ngx-bootstrap';
import { Intercom } from 'ng-intercom';


@Component({
  selector: 'app-template-detail-page',
  templateUrl: './template-detail-page.component.html',
  styleUrls: ['./template-detail-page.component.scss']
})
export class TemplateDetailPageComponent implements OnInit{
  activityFeed = false;
  platformAvilability = false;
  selectedImage = '';
  selectedImageIndex = 0;
  imageSet = 4;
  imageSetCount = 0;
  imagesToShow: any;
  totalImageSetCount: number;
  screenshots = [];
  public selectedPreviewPlatform = 'android';
  constructor(public route: ActivatedRoute,
              public apiRequest: ApiRequests,
              public dataService: DataService,
              public phaseService: PhaseService,
              public router: Router,
              public appDataService: AppDataService,
              public homeTemplateService: HomeTemplateService,
              public analyticsSegment: Angulartics2Segment,
              
              public commonService: CommonService) { }

  ngOnInit(): void {
    this.phaseService.priceLoader = true;
    if (!this.dataService.templateSlug) {
      this.getSelectedImage();
      this.selectedImage = this.screenshots[0];
      this.imagesToShow = this.screenshots.slice(0, this.imageSet);
      this.totalImageSet();
    } else {
      this.apiRequest.fetchTemplateDetails(this.dataService.templateSlug, false).subscribe((data: any) => {
          this.dataService.appDetails = this.dataService.templateDetailModel;
          this.dataService.appDetails['slug'] = this.dataService.templateSlug;
          this.checkIsSelectedTemplate();
          if (this.dataService.appDetails.pricing_modal) {
          this.phaseService.pricingModal = this.dataService.appDetails.pricing_modal;
          }
          if (this.dataService.appDetails.features && this.dataService.appDetails.features.length > 0) {
            this.phaseService.selectedFeatures = Object.assign([], [...this.dataService.appDetails.features]);
            this.phaseService.mandatoryFeatures = this.dataService.appDetails.features.filter(feature => feature.is_mvp_feature);
            this.preSelectPlatforms();
            this.phaseService.selectDefaultPhases();
            this.phaseService.selectedSpeed = this.dataService.homePageData.speed.find(speed => speed.title === 'Relaxed');
            this.phaseService.selectedHash.speed_id = this.phaseService.selectedSpeed.id;
            this.phaseService.selectedHash.source = 'app_detail';
            this.phaseService.initialAppPhases = Object.assign([...this.phaseService.selectedHash.build_phase_vars]);
            this.phaseService.fetchFeatures();
            // this.phaseService.updatePrice();
            this.phaseService.getCloudDetails();
            this.dataService.redirectionUrl = this.phaseService.getRedirectionLink(this.dataService.appDetails).replace(window.location.host, '');
            this.getSelectedImage();
            this.selectedImage = this.screenshots[0];
            this.imagesToShow = this.screenshots.slice(0, this.imageSet);
            this.totalImageSet();
            this.phaseService.showTemplateLoader = false;
          }
        },
        (error) => {
        });
    }

  }

  checkIsSelectedTemplate() {
    const index = this.phaseService.selectedApps.findIndex((app)=>{
      return app.id == this.dataService.appDetails.id;
    })
    if(index !== -1 ){
      this.dataService.appDetails['selected'] = true;
    }
  }

  public getSelectedImage() {
     this.dataService.appDetails.features.forEach((feature) => {
       this.screenshots.push(feature.feature_screenshots.android);
    });
  }

  public preSelectPlatforms() {
    this.phaseService.selectedPlatforms = [];
    this.phaseService.selectedHash.platform_ids = [];
    if (this.dataService.homePageData.platforms) {
      const platforms = this.dataService.homePageData.platforms;
      if (this.dataService.appDetails && this.dataService.appDetails.platforms && this.dataService.appDetails.platforms.length > 0) {
        for (const platform of platforms) {
          platform.selected = false;
          this.dataService.appDetails.platforms.forEach((element, index) => {
            if (element.id === platform.id) {
              platform.selected = true;
              this.phaseService.toggleSelectedPlatform(platform);
              this.phaseService.toggleSelectedPlatformId(platform.id);
            }
          });
        }
      } else {
        platforms[0].selected = true;
        this.phaseService.toggleSelectedPlatform(platforms[0]);
        this.phaseService.toggleSelectedPlatformId(platforms[0].id);
      }
    }
  }


  builderCloudPrice() {
    return Number(this.phaseService.selectedHash.cloud_price > 0 ?this.phaseService.selectedHash.cloud_price.toFixed(2) : 0);
  }

  displayInfoblock() {
    this.phaseService.trackEventForObject(this.analyticsSegment,  'template_view_details_feature_info_clicked ', {
      user_id : this.dataService.user?.id,
      buildcard_category : this.homeTemplateService.templateType
    });
    this.activityFeed = !this.activityFeed;
  }

  displayPlatformAvilability() {
    this.platformAvilability = !this.platformAvilability;
  }

  selectTemplate(template) {
    if (template['template_type'] === 'studio_store') {
      return;
    }
    if (this.phaseService.selectedApps.length === 3 && !template.selected) {
      this.commonService.showError({ error: { message: 'Maximum of 3 template selections are allowed for optimized feature recommendation from our end' } });
      return;
    }
    this.homeTemplateService.showConfirmPopup = false;
    this.phaseService.mandatoryFeatures = [];
    this.toggleClickedItem(template);
    this.phaseService.toggleSelectedAppsById(template);
    this.phaseService.toggleSelectedAppid(template.id);
    this.setMandatoryFeatures();
    this.phaseService.selectedFeatures = [].concat.apply([], this.phaseService.selectedApps.map(a => a.features));
    this.phaseService.selectedHash.platform_ids = [... new Set([].concat.apply([], this.phaseService.selectedApps.map(a => a.platform_ids)))];
    this.dataService.isShowTemplateModal = false;
  }

  toggleClickedItem(item) {
    item.selected = !item.selected;
  }

  setMandatoryFeatures() {
    this.phaseService.selectedApps.filter((psection) => {
      psection.features = psection.features['data'] ? psection.features['data'].map(feature => feature.attributes) : psection.features;
      const mandatoryFeaturesIds = this.phaseService.mandatoryFeatures.map(feature => feature.id);
      if (this.phaseService.selectedHash.application_ids.indexOf(psection.id) > -1) {
        this.phaseService.mandatoryFeatures.push(...psection.features.filter(feature => {
          if (mandatoryFeaturesIds.indexOf(feature.id) === -1) {
            return feature.is_mvp_feature;
          }
        }));
      }
    });
  }


  getIndex(template) {
   return  this.phaseService.selectedApps.findIndex(temp => template.id === temp.id);
  }
  gotoHome() {
    this.phaseService.selectedPhasesIds = [];
    this.router.navigate(['home']);
  }

  clearTemplateSelections() {

    let templates = this.phaseService.selectedApps.reduce((previousValue, currentValue) => previousValue + currentValue.title, '')

    this.phaseService.trackEventForObject(this.analyticsSegment, 'template_removed', {'template_removed': templates, event_trigger: 'clear selection'});
    this.phaseService.selectedApps = [];
  }

  buildNow() {
    this.homeTemplateService.isBuildNowClickedFromTemplatePage = true;
    this.homeTemplateService.goToFeatures();
  }

  selectImage(index) {
    const selectedIndex = (this.imageSet * this.imageSetCount) + index;
    this.selectedImageIndex = selectedIndex;
    this.selectedImage = this.screenshots[selectedIndex];
  }

  changeSelectedImage(type) {
    this.phaseService.trackEventForObject(this.analyticsSegment,  'template_view_details_feature_carousel_interacted', {
      user_id : this.dataService.user?.id,
      buildcard_category : this.homeTemplateService.templateType
    });
    if ( type === 'next' ) {
      this.selectedImageIndex++;
    }  else {
      this.selectedImageIndex--;
    }
    this.selectedImage = this.screenshots[this.selectedImageIndex];

    if ( this.selectedImageIndex >= this.imageSet * (this.imageSetCount + 1) ) {
      this.changeImageSet('next');
    } else if ( this.selectedImageIndex < this.imageSet * (this.imageSetCount) ) {
      this.changeImageSet('previous');
    }
  }

  changeImageSet(type) {
    if ( type === 'next') {
      this.imageSetCount++;
    } else {
      this.imageSetCount--;
    }
    this.imagesToShow = this.screenshots.slice((this.imageSet * this.imageSetCount), (this.imageSet * (this.imageSetCount + 1)));
  }

  totalImageSet() {
     this.totalImageSetCount = Math.ceil(this.screenshots.length / this.imageSet);
  }

  navigateToCareLinks() {
      this.phaseService.trackEventForObject(this.analyticsSegment,  'template_view_details_care_clicked', {
        user_id : this.dataService.user?.id,
        buildcard_category : this.homeTemplateService.templateType
      });
  }

  navigateToCloudLinks() {
    this.phaseService.trackEventForObject(this.analyticsSegment,  'template_view_details_cloud_clicked', {
      user_id : this.dataService.user?.id,
      buildcard_category : this.homeTemplateService.templateType
    });
    }

  public toggleLayoutView(platform) {
    this.screenshots = [];
    if (this.selectedPreviewPlatform !== platform) {
      this.selectedPreviewPlatform = platform;
    }
    if (platform == 'web') {
      this.dataService.appDetails.features.forEach((feature) => {
        this.screenshots.push(feature.feature_screenshots.web);
     });
     this.imagesToShow = this.screenshots.slice(0, this.imageSet);
     this.selectedImage = this.screenshots[this.selectedImageIndex]
    }else{
      this.dataService.appDetails.features.forEach((feature) => {
        this.screenshots.push(feature.feature_screenshots.android);
     });
     this.imagesToShow = this.screenshots.slice(0, this.imageSet);
     this.selectedImage = this.screenshots[this.selectedImageIndex]
    }
  }

  checkActive(template) {
    if (this.phaseService.selectedApps?.length > 0 && template) {
      let index = this.getIndex(template);
      return index >= 0 ?  true :  false;
    } else {
      return false;
    }
  }

}
