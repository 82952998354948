import { SwUpdate } from '@angular/service-worker';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { PhaseService } from '@shared/services/phase.service';
import {
  AfterViewChecked,
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener, OnInit, Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { DataService } from '@shared/services/data.service';
import { PartnerDashboardDataService } from '@featuremodules/partner-dashboard-module/Utils/partner-dashboard-data.service';
import { ApiRequests } from '@shared/services/api-requests.service';
// import { Idle } from '@ng-idle/core';
import { Angulartics2Segment } from 'angulartics2/segment';
import { AppDataService } from '@rootmodule/app-data.service';
import { Constants } from '@shared/utils/constants';
import { Intercom } from 'ng-intercom';
import { CommonService } from '@shared/services/common.service';
import { ComponentLoaderService } from '@shared/services/component-loader.service';
import { environment } from '@env/environment';
import moment from 'moment';
import { HomeTemplateService } from '@shared/services/home-template.service';
import { AppComponentService } from './app-component.service';
import { WebSocketService } from '@shared/services/web-socket.service';

declare const gtag: any;

@Component({
  selector: 'app',
  styleUrls: ['../../stylesheets/index.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'app.component.html'
})
export class AppComponent implements AfterViewChecked, AfterViewInit, OnInit {
  public startTime = 0;
  public endTime = 0;
  public url = '';
  public removeOffers = false;
  public showScroll = '';
  public promotionClass: string | any = '';
  showExitIntent = false;
  isExpandedTradeMark = true;
  public currentUrl;
  public isMouseHoverOutside = false;
  public isPageRendered = false;
  timeSpentOnSite;
  public year;
  moment: any;
  referralPath: any;

  constructor(
    public phaseService: PhaseService,
    public dataService: DataService,
    public appDataService: AppDataService,
    public router: Router,
    // public idle: Idle,
    public cd: ChangeDetectorRef,
    public analyticsSegment: Angulartics2Segment,
    private intercom: Intercom,
    public ref: ApplicationRef,
    public apiRequest: ApiRequests,
    public partnerDataService: PartnerDashboardDataService,
    public swUpdate: SwUpdate,
    public appComponentService: AppComponentService,
    private commonService: CommonService,
    public componentLoader: ComponentLoaderService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    public homeTemplateService: HomeTemplateService,
    public webSocketService: WebSocketService,
  ) {
    this.moment = moment;
    this.year = this.moment(new Date()).format('YYYY');
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    if (this.commonService.isPlatformBrowser) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = event.url;
        }
      });
    }
  }

  public ngOnInit() {
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    // this.checkVersion();
    if (this.commonService.isPlatformBrowser) {
      this.openBuildNow();
      this.getDeviceInfo();
      this.referralPath = window.location.href;
      this.timeSpentOnSite = this.getTimeSpentOnSite();
      this.startCounting();
      setTimeout(() => {
        this.onLoadAppComponentPage();
      }, 1000);
      this.appDataService.setDomain();
      this.initializeApp();
      this.dataService.hashPath = window.location.hash;
      this.handlePageScroll();
      this.handleSoftwareUpdate();
      if (this.dataService.user) {
        if (!this.dataService.user.currency) {
          this.phaseService.openCommonAuth = true;
          this.phaseService.showRegister = true;
        }
        this.updateUser();
      } else if (this.appDataService.urlParameters.login_token && this.appDataService.urlParameters.email) {
        this.checkAutoSignin({ login_token: this.appDataService.urlParameters.login_token, email: this.appDataService.urlParameters.email });
      } else if (this.appDataService.urlParameters.email_confirm_token) {
        this.phaseService.openCommonAuth = true;
        this.phaseService.showRegister = false;
        this.phaseService.showSignin = true;
      }
      this.homeTemplateService.checkForSchedular();
      this.checkToShowSignUpModal();
      this.checkForLinkedLogin();
    }
  }

  checkAutoSignin(loginData) {
    this.phaseService.openCommonAuth = false;
    this.apiRequest.login_signup(loginData, 'login').subscribe((data) => {
      this.router.navigate(['dashboard']);
    }, (error) => {
      this.phaseService.showSignin = true;
      this.phaseService.openCommonAuth = true;
    })

  }

  public getDeviceInfo() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      this.phaseService.deviceInfo.deviceType = 'Mobile'
    } else {
      this.phaseService.deviceInfo.deviceType = 'Desktop'
    }
    this.phaseService.deviceInfo.browser = this.getBrowserName();
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  checkToShowSignUpModal() {
    if ((this.appDataService.urlParameters.email || this.appDataService.urlParameters.redirection_url || this.appDataService.urlParameters.is_self_serve) && !this.appDataService.urlParameters.login_token && !this.dataService.user?.first_name && !this.appDataService.urlParameters.token ) {
      if (this.dataService.homePageData?.block_sign_up) {
        this.phaseService.showSignin = true;
        this.phaseService.showRegister = false;
      } else {
        this.phaseService.showRegister = true;
        this.phaseService.showSignin = false;
      }
      this.phaseService.openCommonAuth = true;
      localStorage.setItem('welcome_screen_seen', 'true');
      localStorage.setItem('welcome_screen_letstart', 'true');
    } else if (this.appDataService.urlParameters.token && !location.href.includes('reset-password') &&
      !location.href.includes('etisalat')) {
      this.getUserDetailsFromToken();
    }
    setTimeout(() => {
      if (this.appDataService.urlParameters.is_self_serve) {
        const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
        window.history.pushState({ path: newurl }, '', newurl);
      }
    }, 100);
  }

  getUserDetailsFromToken() {
    if (this.appDataService.urlParameters.token && !this.appDataService.urlParameters.is_freemium) {
      const decodedToken = decodeURIComponent(this.appDataService.urlParameters.token).split(' ').join('+');
      const data = {
        'token' : decodedToken,
        'build_card_type': 'store_pro'
      };
      this.apiRequest.tokenResolve(data).subscribe((resp: any) => {
        if (resp) {
          this.dataService.userDataFromToken = resp;
          if (resp.user && resp.user.id) {
            if (this.dataService.user) {
              if (this.dataService.user.email !== resp.user.email) {
                this.apiRequest.signout_user().subscribe(() => {
                  this.phaseService.openCommonAuth = true;
                  this.phaseService.showSignin = true;
                  this.phaseService.showRegister = false;
                });
              } else if (this.dataService.user.email === resp.user.email) {
                this.phaseService.showRegister = false;
                this.phaseService.openCommonAuth = false;
                this.phaseService.showSignin = false;
              }
            } else {
              this.phaseService.openCommonAuth = true;
              this.phaseService.showSignin = true;
              this.phaseService.showRegister = false;
            }
          } else {
            this.phaseService.showRegister = true;
            this.phaseService.openCommonAuth = true;
          }
        }
      });
    }
  }

  checkVersion() {

    this.apiRequest.fetchVersion().subscribe(data => {
      const version = localStorage.getItem('app_version');
      if ((version && version < data) || !version) {
        this.swUpdate.activateUpdate().then(() => {
          localStorage.setItem('app_version', data)
          if (!window.location.search) {
            window.location.reload()
          }
        });
      }
    });
  }

  updateUser() {
    this.apiRequest.fetchUser().subscribe(data => {
      this.confirmEmail();
      this.phaseService.checkUserHasCurrency();
    });
  }



  @HostListener('mouseleave', ['$event'])
  public onLeave(event) {
    if (this.appDataService.urlParameters.isCustomPrototype) {
      return;
    }
    if (event.clientY <= 0) {
      if (!this.phaseService.openCommonAuth && !this.phaseService.isEditFeatureRequest() && this.commonService.isPlatformBrowser) {
        this.isMouseHoverOutside = true;
      }
    }
  }

  @HostListener('click', ['$event'])
  @HostListener('window:mousemove', ['$event'])
  public checkOpenClosePopup(event) {
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    const data = document.getElementsByClassName('commonPopUp active');
    if (data.length > 0) {
      this.renderer.addClass(document.body, 'modal-open');
    } else {
      this.renderer.removeClass(document.body, 'modal-open');
    }
    this.setClassForHideScrolBar();
  }

  public isShowBottomBar() {
    return (this.dataService.currentPage === 'home' || this.dataService.currentPage === 'apps' ||
      (this.dataService.currentPage === 'paymentPlan') || this.dataService.currentPage === 'features' ||
      this.dataService.currentPage === 'delivery');
  }

  ngAfterViewChecked() {
    if (window['dataLayer']) {
      //window['dataLayer'].push({ event: 'optimize.activate' });
    }
  }

  removeOfferClass(data) {
    this.removeOffers = true;
  }

  setClassForHideScrolBar() {
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    const data = document.getElementsByClassName('commonPopUp active');
    if (data.length > 0) {
      //this.renderer.addClass(document.getElementById('app-component'), 'hide-scroll-bar');
    } else if (!this.phaseService.isShowDashboardMenuForMobileOrTablet) {
      this.renderer.removeClass(document.getElementById('app-component'), 'hide-scroll-bar');
    }
  }

  trackSessionPageTime(url, time) {
    const path = url.split('/');
    switch (path[1]) {
      case 'home':
        this.gtagTrack('/home');
        break;
      case 'apps':
        this.gtagTrack('/apps');
        break;
      case 'platforms':
        this.gtagTrack('/platforms');
        break;
      case 'features':
        this.gtagTrack('/feature');
        break;
      case 'teamspeed':
        this.gtagTrack('/teamspeed');
        break;
      case 'buildphases':
        this.gtagTrack('/buildphases');
        break;
      case 'build-card':
        this.gtagTrack('/build-card');
        break;
      case 'dashboard':
        this.gtagTrack('/dashboard');
        break;
      case 'buildcard-payment':
        switch (path[3]) {
          case 'schedule':
            this.gtagTrack('/buildcard-payment/schedule');
            break;
          case 'signcontract':
            this.gtagTrack('/buildcard-payment/signcontract');
            break;
          case 'billing':
            this.gtagTrack('/buildcard-payment/billing');
            break;
          case 'confirmation':
            this.gtagTrack('/buildcard-payment/confirmation');
            break;
        }
        break;
      case 'project-progress':
        switch (path[3]) {
          case 'home':
            this.gtagTrack('/project-progress/home');
            break;
          case 'todo':
            this.gtagTrack('/project-progress/todo');
            break;
          case 'billing':
            this.gtagTrack('/project-progress/billing');
            break;
          case 'documents':
            this.gtagTrack('/project-progress/documents');
            break;
          case 'messages':
            this.gtagTrack('/project-progress/messages');
            break;
          case 'coderepo':
            this.gtagTrack('/project-progress/coderepo');
            break;
          case 'projectdetails':
            this.gtagTrack('/project-progress/projectdetails');
            break;
        }
        break;
      default:
        break;
    }
  }

  public isShowTimer() {
    return this.phaseService.showTimer;
  }

  checkForLinkedLogin() {
    if (this.appDataService.urlParameters.code) {
      localStorage.setItem('code', this.appDataService.urlParameters.code);
      window.close();
    }
  }

  public isShowScroll(): boolean {
    return (
      this.phaseService.showSignin ||
      this.phaseService.showRegister ||
      this.phaseService.showForgotPassword
    );
  }

  public isShowCommonAuthPopup(): boolean {
    return this.phaseService.openCommonAuth;
  }

  ngAfterViewInit() {
    this.isPageRendered = true;
    this.setClassForHideScrolBar();
    this.cd.detectChanges();
  }

  public trackEvent(eventString) {
    if (eventString === 'Contact') {
      this.router.navigate(['contactus']);
    } else if (eventString === 'talktous') {
      const evt = new CustomEvent('MyEventType', { detail: '1234' });
      window.dispatchEvent(evt);
      // this.intercom.showNewMessage('');
    }
  }

  public warning() {
    if (!this.phaseService.isFeatureRequest) {
      setTimeout(() => this.timeOutMethod(), 1000);
    }
    return 'You have unsaved data changes. Are you sure to close the page?';
  }

  public timeOutMethod() {
    if (this.dataService.user && this.dataService.currentPage !== 'specing' && this.dataService.currentPage !== 'delivery'
      && this.dataService.currentPage !== 'apps' && this.dataService.currentPage !== 'features') {
      this.apiRequest.createUpdateBuildCard(this.phaseService.selectedHash, this.phaseService.inProgressBuildCardId).subscribe((data: any) => {
        this.phaseService.inProgressBuildCardId = this.dataService.buildCardData.id;
      });
    }
    this.ref.tick();
  }

  @HostListener('window:beforeunload', ['$event'])
  public beforeUnloadHander(event) {
    if (this.dataService.unsavedChanges) {
      event.returnValue = this.warning();
    }
  }

  public hideAllPopups() {
    this.phaseService.showList = false;
    if (!(this.dataService.currentPage === 'templateDetail' && (this.phaseService.checkIfRentalPricingModal() || this.phaseService.isRentalBuildCard()))) {
      this.dataService.showCurrencyPopup = false;
    }
    this.phaseService.showPromotionDropdown = false;
  }

  public isShowLoader() {
    return this.dataService.showLoader;
  }

  public getPromotionClass(): string | any {
    // this.showScroll = this.isShowScroll() ? 'hide' : '';
    if (this.phaseService.checkIfRentalPricingModal() && this.phaseService.selectedHash.source === 'app_detail') {
      return;
    }
    if (this.phaseService.isUrlPromotionValid && !this.phaseService.popupCouponApplied) {
      return 'wrapper offerActive ' + this.showScroll;
    } else if (
      (this.phaseService.offersArr.length > 0 &&
        !this.phaseService.hideOffers &&
        this.removeOffers === false &&
        !this.phaseService.promotionValidStatus &&
        this.dataService.currentPage === 'home' && !this.phaseService.popupCouponApplied) ||
      ((this.dataService.currentPage === 'home' || this.dataService.currentPage === 'apps') && this.phaseService.popupCouponApplied && this.phaseService.promotionValidStatus)
    ) {
      return { offerActive: true, wrapper: true };
    }
  }

  public isDashboardOpen() {
    return this.phaseService.dashboardOpen;
  }

  public getSelectedSpecing() {
    return this.phaseService.isOpenSpecing;
  }

  public getHoldOfferPopup() {
    return this.phaseService.holdOfferPopup;
  }

  public hideHoldOffer() {
    this.phaseService.hideHoldOffer();
  }

  public handleHoldOfferFlow() {
    this.phaseService.handleHoldOfferFlow(this.router);
  }

  public getPreventClick() {
    return this.phaseService.preventClick;
  }

  public gtagTrack(path) {
    if ((window.location.origin === 'https://beta.engineer.ai') || (window.location.origin === 'https://builder.engineer.ai') || (window.location.origin === 'https://studio.builder.ai')) {
      gtag('config', 'AW-800126066', { page_path: path });
    }
  }

  public isEngineerAIBuilder() {
    return this.dataService.isEngineerAIBuilder();
  }

  public getPrivacyPolicyUrl() {
    if (this.dataService.homePageData) {
      if (this.dataService.homePageData.builder_partner) {
        if (this.dataService.homePageData.builder_partner.privacy_policy_url) {
          return this.dataService.homePageData.builder_partner
            .privacy_policy_url;
        }
      }
    }
    return 'https://www.builder.ai/privacy';
  }

  public getTnCUrl() {
    if (this.dataService.homePageData) {
      if (this.dataService.homePageData.builder_partner) {
        if (this.dataService.homePageData.builder_partner.privacy_policy_url) {
          return this.dataService.homePageData.builder_partner
            .privacy_policy_url;
        }
      }
    }
    return 'https://www.builder.ai/terms';
  }

  closeExitIntent() {
    this.showExitIntent = false;
  }

  private initializeApp() {
    this.promotionClass = this.getPromotionClass();
    this.apiRequest.getUserData();
    this.addEventListeners();
    this.trackSessionTime();
    if (this.phaseService.conditionToCreateAndSyncBuildCardToBNow() && this.dataService.user && this.dataService.user.currency) {
      this.dataService.showHideLoaderForBNow(true);
    }
    if (this.commonService.isPlatformBrowser) {
      if (!this.phaseService.showWelcomeScreen
        && !this.phaseService.shouldShowWelcomePopup()
        && !(localStorage.getItem('welcome_screen_seen') !== 'true' || (localStorage.getItem('welcome_screen_letstart') !== 'true'))
      ) {
        this.phaseService.timeSpent = 0;
        this.phaseService.handlePageExitTimer();
      }
    }
    this.experimentPromotions();
    this.getFeedbacks();
  }

  public getFeedbacks() {
    this.apiRequest.getPaymentExitFeedbackOptions('PaymentPage').subscribe((resp: any) => {
      this.dataService.paymentExitFeedbackOptions = resp;
    }, error => {
      console.log(error);
    });
    this.apiRequest.getPaymentExitFeedbackOptions('HomePage').subscribe((resp: any) => {
      this.dataService.homeExitFeedbackOptions = resp;
    }, error => {
      console.log(error);
    });
  }

  private addEventListeners() {
    const self = this;
    window.addEventListener(
      'MyEventType',
      function (evt: any) {
        self.appComponentService.handleIntercom();
        self.phaseService.chatBotShown = true;
        evt.stopPropagation();
      },
      false
    );

    window.addEventListener(
      'LoginEvent',
      function (evt: any) {
        self.dataService.user = evt.detail.user;
      },
      false
    );

    window.addEventListener(
      'SignupEvent',
      function (evt: any) {
        self.dataService.user = evt.detail.user ? evt.detail.user : evt.detail;
      },
      false
    );

    window.addEventListener(
      'LoginFromBuilder',
      function (evt: any) {
        self.dataService.user = evt.detail.user;
      },
      false
    );
  }

  private trackSessionTime() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.startTime = new Date().getTime();
        const timeinmilli = (this.startTime - this.endTime) / 1000;
        if (
          timeinmilli > 0 &&
          this.endTime > 0 &&
          this.url !== '' &&
          this.url !== '/'
        ) {
          this.trackSessionPageTime(this.url, timeinmilli);
        }
      }
      if (event instanceof NavigationEnd) {
        this.url = window.location.pathname;
        this.endTime = new Date().getTime();
      }
    });
  }

  private handleSoftwareUpdate() {
    if (window.location.pathname ===  "/upgrade-plan"  || window.location.pathname ===  "/upgrade") return
    this.swUpdate.available.subscribe(event => {
      if (confirm('A newer version of Builder is now available. Refresh the page now to update.')) {
        this.swUpdate.activateUpdate().then(() => window.location.reload());
      }
    });
    this.swUpdate.unrecoverable.subscribe(event => {
      this.swUpdate.activateUpdate().then(() => window.location.reload());
    });
    // this.swUpdate.checkForUpdate();
  }

  private handlePageScroll() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
    });
  }

  public experimentPromotions() {
    /*if (!this.dataService.user ) {*/
    this.apiRequest.fetchExperiments().subscribe(ext => {
      this.phaseService.popupPromocode = Constants.promotionCodeType.Application;
      this.phaseService.setPromotionObject(this.phaseService.popupPromocode);
    });
    /*}*/
  }

  hideTradeMarkStrip() {
    this.dataService.isTradeMarkStrip = false;
    if (this.commonService.isPlatformBrowser) {
      localStorage.setItem('tradeMarkTripViewed', 'true');
    }
  }

  expandTradeMardStrip() {
    this.isExpandedTradeMark = false;
  }

  showHideChatbot() {
    return (!this.phaseService.showTimer && !this.phaseService.openCommonAuth && (this.dataService.currentPage.indexOf('home') > -1 || this.dataService.currentPage.indexOf('features') > -1 ||
      this.dataService.currentPage.indexOf('apps') > -1)) && !this.phaseService.isFeatureRequest && !this.dataService.showMeetNowModel
      && !((this.appDataService.urlParameters.red === 'self_served') || this.dataService.showMeetNowOverloading || (this.dataService.showMeetNowOverloadingOnFeature && this.dataService.currentPage === 'features'));
  }


  public isShowLoaderForBNow() {
    return this.dataService.showLoaderForBNow;
  }

  onLoadAppComponentPage() {
    this.componentLoader.loadComponent('script-lazyload').then(componentEl => {
      this.elementRef.nativeElement
        .querySelector('#app-component')
        .appendChild(componentEl);
    });
  }

  getTimeSpentOnSite() {
    if (this.commonService.isPlatformBrowser) {
      this.timeSpentOnSite = parseInt(localStorage.getItem('timeSpentOnSite'));
      this.timeSpentOnSite = isNaN(this.timeSpentOnSite) ? 0 : this.timeSpentOnSite;
      return this.timeSpentOnSite;
    }
  }

  startCounting() {
    if (this.commonService.isPlatformBrowser) {
      let timerStart = Date.now();
      let obj = { refVal: this };
      let timer = setInterval(function (obj: any) {
        let oldRef = obj.refVal;
        oldRef.timeSpentOnSite = oldRef.getTimeSpentOnSite() + (Date.now() - timerStart);
        localStorage.setItem('timeSpentOnSite', oldRef.timeSpentOnSite);
        timerStart = Number(Date.now());
        // Convert to seconds
        let result = Math.ceil(Number(oldRef.timeSpentOnSite / 1000));
        oldRef.dataService.timeSpentBeforeSpecCallCreation = result;
      }, 1000, obj);
    }
  }

  checkIfFreshWindow() {
    if (this.commonService.isPlatformBrowser) {
      if ((location.href.includes('home')) || (location.href === location.origin + '/')) {
        return (this.phaseService.shouldShowWelcomePopup() && (localStorage.getItem('welcome_screen_seen') !== 'true'));
      } else {
        return false;
      }
    }
  }

  confirmEmail() {
    if (this.appDataService.urlParameters.email_confirm_token) {
      const data = { "token": this.appDataService.urlParameters.email_confirm_token };
      this.apiRequest.emailConfirm(data).subscribe((resp: any) => {
        this.phaseService.emailConfirmedPoupup = true;
        if (resp.message == 'User already verified') {
          this.phaseService.userAlreadyVeriied = true;
        }
      });
    }
  }

  get isFirstLoad() {
    return window.location.href === `${environment.FE_URL}` || window.location.href === `${environment.FE_URL}/`;
  }

  checkIfNotTnCPage() {
    const page = this.dataService.currentPage;
    if(this.dataService.currentPage == 'tncPro' || this.dataService.currentPage == 'tncStore'){
      this.renderer.addClass(document.body, 'paddingNone');
    }
    return ((page !== 'tncPro') && (page !== 'tncStore'));
  }

  openBuildNow() {

    // this.phaseService.trackEvent(this.analyticsSegemnt, '', 'welcome_cta_selected');
    // if (this.commonService.isPlatformBrowser) {
    localStorage.setItem('welcome_screen_seen', 'true');
    localStorage.setItem('welcome_screen_letstart', 'true');

    this.homeTemplateService.isShowTemplateNatashaInitialAnimation = true;
    this.homeTemplateService.isShowTemplateNatashaIntroPopup = true;
    this.phaseService.showTemplateNatashaMobileAnimation = true;
    this.homeTemplateService.showNatashaPanelMobile = true;
    if (!this.dataService.user) {
      this.homeTemplateService.isShowNatashaNameScreen = true;
    }
    setTimeout(() => {
      this.phaseService.showTemplateNatashaMobileAnimation = false;
    }, 7000);
  }

}
