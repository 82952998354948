<div class="projectInfoBox">
  <h2>Basic info</h2>
  <ul>
    <li>
      <label>Version</label>
      <input type="text" [value]="getBuildCardData()?.version" readonly>
    </li>
    <li>
      <label>Buildcard<sup class="tm">®</sup> name</label>
      <input type="text" [value]="getBuildCardData()?.project_name" readonly>
    </li>
    <li>
      <label>Price</label>
      <input type="text" [value]="getBuildCardData()?.price" readonly>
    </li>
    <li>
      <label>Discounted price</label>
      <input type="text" [value]="getDiscountedprice(getBuildCardData())" readonly>
    </li>
    <li>
      <label>Weeks</label>
      <input type="text" [value]="getBuildCardData()?.weeks" readonly>
    </li>
    <li>
      <label>Support maintenance weeks</label>
      <input type="text" [value]="getBuildCardData()?.support_maintenance_weeks" readonly>
    </li>
    <li>
      <label>Customized template name</label>
      <input type="text" [value]="getBuildCardData()?.customized_template_name" readonly>
    </li>
    <li>
      <label>Uniq code</label>
      <input type="text" [value]="getBuildCardData()?.uniq_code" readonly>
    </li>
    <li>
      <label>Timezone</label>
      <input type="text" [value]="getBuildCardData()?.timezone" readonly>
    </li>
    <li>
      <label>Percentage completed</label>
      <input type="text" [value]="getBuildCardData()?.percentage_completed" readonly>
    </li>
    <li>
      <label>Confidence percentage</label>
      <input type="text" [value]="getBuildCardData()?.confidence_percentage" readonly>
    </li>
    <li>
      <label>Shared users</label>
      <input type="text" [value]="getCommaSeparatedList('shared_users')?.pre_defined" readonly>
    </li>
    <li>
      <div class="seprate">
        <label>Applications</label>
        <input type="text" [value]="getCommaSeparatedList('applications')?.pre_defined" readonly>
      </div>

      <div class="seprate">
        <label>Platforms</label>
        <input type="text" [value]="getCommaSeparatedList('platforms')?.pre_defined" readonly>
      </div>
    </li>
    <li>
      <label>Features</label>
      <textarea readonly>{{ getCommaSeparatedList('features')?.pre_defined }}</textarea>
    </li>
    <li>
      <label>Build phases</label>
      <input type="text" [value]="getCommaSeparatedList('build_phases')?.pre_defined" readonly>
    </li>
    <li>
      <label>Speed</label>
      <input type="text" [value]="getBuildCardData()?.progress?.team?.title" readonly>
    </li>
    <li>
      <label>Team</label>
      <input type="text" [value]="getBuildCardData()?.progress?.speed?.title" readonly>
    </li>
    <li>
      <label>User</label>
      <input type="text" [value]="(getBuildCardData()?.user?.first_name + ' ' + getBuildCardData()?.user?.last_name)"
             readonly>
    </li>
    <li>
      <label>Status</label>
      <input type="text" [value]="getBuildCardData()?.status" readonly>
    </li>
    <li>
      <label>Contracts</label>
      <input type="text"
             [value]="getCommaSeparatedDocuments('contracts').length > 0 ? 'Contract #' + getCommaSeparatedDocuments('contracts') : ''"
             readonly>
    </li>
    <li>
      <label>Contractual documents</label>
      <input type="text"
             [value]="getCommaSeparatedDocuments('contractual_documents').length > 0 ? 'Document #' + getCommaSeparatedDocuments('contractual_documents') : ''"
             readonly>
    </li>
    <li>
      <label>Documents</label>
      <input type="text"
             [value]="getCommaSeparatedDocuments('documents').length > 0 ? 'Document #' + getCommaSeparatedDocuments('documents') : ''"
             readonly>
    </li>
  </ul>
</div>
