import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '@shared/services/data.service';
import { PhaseService } from '@shared/services/phase.service';
import { ReferralService } from '@shared/services/referral.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Intercom} from 'ng-intercom';

@Component({
  selector: 'app-referral-component',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent  {
  isCopyClipboardClicked = false;

  constructor(public referralService: ReferralService, public dataService: DataService,
              private phaseService: PhaseService, private analyticsSegment: Angulartics2Segment,
              private intercom: Intercom) { }


  goBackToDashboard() {
    this.referralService.showMainReferralComponent = false;
    this.phaseService.isDashboardShow  = true;
  }

  copyReferralLinkToClipboard() {
    let url = this.referralService.getShareURLLink();
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      this.isCopyClipboardClicked = true;
      this.phaseService.trackEvent(this.analyticsSegment, 'refer_link_copied', 'user_id', this.dataService.user.id);
      return navigator.clipboard.writeText(url);
    }
  }

  redirectToTAndCLink() {
    window.open('https://www.builder.ai/terms/builder-rewards', '_blank' , "noopener");
  }

}
