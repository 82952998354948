<div class="commonPopUp" [ngClass]="{'active': isAddNewFeature() && !phaseService.isShowFeatureRecommendationsModal}" *ngIf="!phaseService.isShowFeatureRecommendationsModal && isAddNewFeature()">
  <div class="popOverlay"></div>
  <div class="popHolder addnewFeature">
    <div class="closePopUp" (click)="closeAddFeature();"><span class="icon-cancel"></span></div>

    <div class="customFeatureBox" [ngClass]="{'rightExpand' : openAdvanceOptions}">
      <div class="leftSide">
        <img src="../../../../../../assets/images/customFeatureLeft.jpg" alt="" />
      </div>

      <div class="rightSide">
        <!--intro box start-->
        <!--<div class="introBox" *ngIf="!showFooterAndFirstScreen()">
          <h2>What's a custom feature?</h2>
          <p>It's a unique feature designed specifically for your app.</p>
          <p>But as every feature you add gets customised - we use AI to check we can't achieve what you want by customising a feature in our catalogue.</p>
          <p>This works out faster and cheaper for you.</p>
          <button type="button" (click)="describeCustomFeature()">Describe my feature</button>
        </div>-->
        <!--intro box close-->

        <div class="topContainer">
          <!--step 1 start-->
          <div class="featureStepBox" *ngIf="describeFeatureClicked">
            <div class="stepContent">
              <h2>Explain your custom feature</h2>
              <p>I'm an AI - so add lots of detail and I can:</p>
              <ol>
                <li>Analyse your description</li>
                <li>Give you more accurate price and timing estimates</li>
                <li>Search our catalogue for existing features that could do what you need (cheaper and faster)</li>
              </ol>
            </div>
            <div class="formField">
              <ul>
                <li>
                  <div class="formLabel"><label>Name this feature</label></div>
                  <input type="text" placeholder="Suggest a name for your feature" #title1 #title="ngModel" name="title"
                         [(ngModel)]="getAddedFeature().title" maxlength="100" (focusout)="trimTitle()" (keyup)="updateFeatureExist()" required/>
                  <span class="errorMsg show" *ngIf="isValidFeatureName()">Please enter valid feature name</span>
                  <span class="errorMsg show" *ngIf="featureExist">This feature already exists.</span>
                </li>

                <li>
                  <div class="countRemain"
                       [ngClass]="{ 'red-text': ((getDescriptionLength() < 100) && (getDescriptionLength() > 0)),
                          'green-text': (getDescriptionLength() >= 100) }">{{getDescriptionRemaining()}}/1000
                  </div>
                  <div class="formLabel">
                    <label>Describe what it does</label>
                  </div>

                  <div class="noteBox">
                    <em class="icon-infonew"></em>
                    <p>For example: ”I want a feature that lets customers add products to their wish list so they can buy them later, using pre-saved card details.”</p>
                  </div>

                  <div class="textEditor">
                    <ckeditor [config]="editorConfig" [editor]="commonService.Editor" required (focusout)="trimDescription()"
                              [(ngModel)]="getAddedFeature().description" data="" name="text">
                    </ckeditor>
                  </div>
                  <span class="errorMsg show" *ngIf="isFocusedOutDesc && isInvalidMinimumCharLength()">
                    Please enter at least 100 characters.
                  </span>
                  <span class="errorMsg show" *ngIf="isFocusedOutDesc && (getDescriptionLength() > 1000)">
                    Description length cannot be more than 1000 characters.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <!--step 1 close-->

          <!--step 3 start-->
          <div class="featureStepBox" *ngIf="openAdvanceOptions">
            <div class="stepContent">
              <h2>Let's dig into the details</h2>
              <p>Based on what you've told me, I've tried to answer these questions about how complex your custom feature will be. Feel free to change my guesses. You'll discuss all this in detail (and get a more accurate price) on your kick-off call.</p>
            </div>

            <div class="formField">
              <ul>
                <li>
                  <div class="formLabel">
                    <label>How complex is this feature?</label>
                    <div class="optional">(Optional)</div>
                    <div class="tooltipBox">
                      <div class="tooltipIcon"><em class="icon-infonew"></em></div>
                      <div class="tooltipContent">
                        This is asking you to estimate how challenging or intricate your feature will be to build. Ignore this if you're unsure, we'll work it out on your kick-off call.
                      </div>
                    </div>
                  </div>
                  <div class="complexitySlider">
                    <ngx-slider [(value)]="getAddedFeature()?.difficulty ? getAddedFeature()?.difficulty : value" [options]="options" (valueChange)="selectDifficulty($event)"></ngx-slider>
                  </div>
                  <div class="pointerMarks">
                    <div>Easy</div>
                    <div>Difficult</div>
                  </div>
                </li>

                <li>
                  <div class="formLabel">
                    <label>Does it have any algorithms?</label>
                    <div class="optional">(Optional)</div>
                    <div class="tooltipBox">
                      <div class="tooltipIcon"><em class="icon-infonew"></em></div>
                      <div class="tooltipContent largeSize">
                        An algorithm is a step-by-step set of instructions for solving a problem or task:<br>
                        <ul>
                          <li><strong>None:</strong> something simple that users interact with, like a login page.</li>
                          <li><strong>Simple algorithms:</strong> easy to follow, with straightforward logic and operations.</li>
                          <li><strong>Complex algorithms:</strong> involve multiple steps, conditional statements and calculations. More challenging to design and understand.</li>
                          <li><strong>Machine Learning algorithms:</strong> let machines learn from data, make predictions and improve their performance - without being explicitly programmed.</li>
                          <li><strong>Augmented Reality algorithms:</strong> overlay virtual objects onto the physical world - creating interactive experiences.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="customRadioButtons">
                    <input type="radio" name="algorithms" value="none" id="algoone" (click)="setAlgorithm('none')" [checked]="getAddedFeature().algorithm === 'none'"> <label for="algoone">None</label>
                    <input type="radio" name="algorithms" value="simple" id="algotwo" (click)="setAlgorithm('simple')" [checked]="getAddedFeature().algorithm === 'simple'"> <label for="algotwo">Simple</label>
                    <input type="radio" name="algorithms" value="complex" id="algothree" (click)="setAlgorithm('complex')" [checked]="getAddedFeature().algorithm === 'complex'"> <label for="algothree">Complex</label>
                    <input type="radio" name="algorithms" value="machine_learning" id="algofour" (click)="setAlgorithm('machine_learning')" [checked]="getAddedFeature().algorithm === 'machine_learning'"> <label for="algofour">Machine Learning</label>
                    <input type="radio" name="algorithms" value="augmented_reality" id="algofive" (click)="setAlgorithm('augmented_reality')" [checked]="getAddedFeature().algorithm === 'augmented_reality'"> <label for="algofive">Augmented Reality</label>
                  </div>
                </li>

                <li>
                  <div class="formLabel">
                    <label>Will your users interact with it (frontend)?</label>
                    <div class="optional">(Optional)</div>
                    <div class="tooltipBox">
                      <div class="tooltipIcon"><em class="icon-infonew"></em></div>
                      <div class="tooltipContent">
                        An app's frontend (what's on screen) is the part people see and interact with. The app's backend (what's under the hood) delivers data to the frontend. For example:
                        <ul>
                          <li><strong>Frontend only:</strong> Splash screen</li>
                          <li><strong>Backend only:</strong> PayPal integration</li>
                          <li><strong>Back & frontend:</strong> Payments</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="customRadioButtons">
                    <input type="radio" name="interface" value="both" id="interfaceFE" (click)="setInterfaceType('frontend')" [checked]="getAddedFeature().interface_type === 'frontend'"> <label for="interfaceFE">Frontend only</label>
                    <input type="radio" name="interface" value="backend" id="interfaceone" (click)="setInterfaceType('backend')" [checked]="getAddedFeature().interface_type === 'backend'"> <label for="interfaceone">Backend only</label>
                    <input type="radio" name="interface" value="both" id="interfacetwo" (click)="setInterfaceType('both')" [checked]="getAddedFeature().interface_type === 'both'"> <label for="interfacetwo">Both (Backend and User Interface)</label>
                  </div>
                </li>

                <li>
                  <div class="formLabel">
                    <label>Will this custom feature connect to or depend on any of your other features?</label>
                    <div class="optional">(Optional)</div>
                    <div class="tooltipBox">
                      <div class="tooltipIcon"><em class="icon-infonew"></em></div>
                      <div class="tooltipContent leftSide">Will this custom feature connect to or depend on any of your other features?</div>
                    </div>
                  </div>
                  <div class="searchAndCheckbox">
                    <div class="searchFeature">
                      <div class="searchBox">
                        <input type="text" placeholder="Search feature" [(ngModel)]="searchQuery" [ngModelOptions]="{standalone: true}" (keyup)="searchFeatureChange($event)"/>
                        <div class="searchIcon"><em class="icon-magnifyglass"></em></div>
                        <div class="clearIcon" *ngIf="searchText && (searchText.length > 0)" (click)="clearFeatureSearch()"><em class="icon-iconcross"></em></div>
                      </div>
                    </div>
                    <div class="featureListing">
                      <div class="featureSets" *ngIf="!(searchText && filteredCoreFeatures && filteredCoreFeatures.length === 0)">
                          <div class="checkbox" *ngFor="let feature of (searchText ? filteredCoreFeatures : getCoreSelectedFeatures()); let i = index">
                            <input type="checkbox" id="featurelist{{i}}" [checked]="checkIfDependentCoreFeatureSelected(feature)"
                            (change)="toggleCoreFeatureSelection(feature, $event)"> 
                            <label for="featurelist{{i}}">{{feature.title}}</label>
                          </div>
                      </div>
                      <div class="featureNotFound" *ngIf="searchText && filteredCoreFeatures && filteredCoreFeatures.length === 0">
                        <img src="../../../../../../assets/images/search-result.svg" />
                        <h3>No feature found</h3>
                      </div>
                    </div>
                  </div>
                </li>

              </ul>
            </div>
          </div>
          <!--step 3 close-->

        </div>

        <div class="footerSection" [ngClass]="{'alignRight' : !openAdvanceOptions}" *ngIf="showFooterAndFirstScreen()">
          <button type="button" class="prevButton" *ngIf="!describeFeatureClicked"
                  (click)="previousCTAClicked()" [disabled]="preventClick">
            Previous
          </button>
          <button type="button" class="submitButton" *ngIf="!showRecommendedFeatures" (click)="nextClicked()"
                  [disabled]="disableNextCTA()">
            <ng-container *ngIf="!preventClick">
              {{ openAdvanceOptions ? (getAddedFeature()?.id ? 'Update' : 'Create') : 'Analyse my description' }}
            </ng-container>
            <div class="threeDotLoader" *ngIf="openAdvanceOptions && preventClick">
              <div class="dot-flashing"></div>
            </div>
          </button>
          <button type="button" *ngIf="showRecommendedFeatures" (click)="showWarningPopup()">
            <ng-container *ngIf="!preventClick">Skip</ng-container>
            <div class="threeDotLoader" *ngIf="preventClick">
              <div class="dot-flashing"></div>
            </div>
          </button>
        </div>
      </div>

      <div class="analysingPopup" *ngIf="showLoadingScreen">
        <div class="analysingBox">
          <div class="avatarBox">
            <div class="betaBadge"><img src="../../../../../../assets/images/beta-badge.svg" alt="" /></div>
            <div class="wizardAvatar idleAnimation">
              <span></span>
              <span></span>
            </div>
          </div>
          <h3>Analysing your description</h3>
          <h4></h4>
          <div class="tuningLoading"></div>
        </div>
      </div>

      <div class="recommendedFeaturePopup" *ngIf="showRecommendedFeatures">
        <div class="topContainer">
          <div class="stepContent">
            <h2>Try this instead</h2>
            <p>Sounds like we could customise these features to do what you need (you'll save money and it will be quicker):</p>
          </div>

          <div class="featureListBox">
            <div class="featureCardBox" *ngFor="let feature of recommendedFeatures">
              <div class="featureHolder">
                <div class="featureIcon">
                  <img [src]="feature?.feature_screenshots?.android" />
                </div>
                <div class="featureDetail">
                  <h3>{{ feature?.title }}</h3>
                  <h4>{{ feature?.feature_bundle_name }}</h4>
                  <!-- <p>from {{ dataService?.buildCardData?.currency?.symbol + '' + feature?.price }}</p> -->
                  <p>from {{ dataService?.buildCardData?.currency?.symbol}}{{ getLocaledPrice(feature?.price | number : '1.2-2') }}</p>
                  <p>from {{ feature?.duration }} {{ (feature?.duration > 1) ? 'days' : 'day' }}</p>
                </div>
              </div>
              <div class="actionButton">
                <button type="button" (click)="toggleFeatureDetails(feature)"><em class="icon-eye"></em> View details</button>
                <button type="button" [ngClass]="{'selected': feature['isRecommendedCoreFeatureAdded']}" (click)="addRecommendedCoreFeaturesToList(feature)">
                 <em *ngIf="feature['isRecommendedCoreFeatureAdded']" class="icon-checkshape"></em> {{ feature['isRecommendedCoreFeatureAdded'] ? 'Feature added' : checkCoreFeatureCardButtonText(feature) }}
                </button>
              </div>
            </div>

            <div class="sepratorLine">
              Couldn't find what you're looking for?
            </div>

            <div class="customfeatureCardBox">
              <div class="featureHolder">
                <div class="featureIcon">
                  <img src="../../../../../../assets/images/customFeatureLeft.jpg" alt="" />
                </div>
                <div class="featureDetail">
                  <h3>{{getAddedFeature().title}}</h3>
                  <!-- <p>Price: TBC</p>
                  <p>Time: TBC</p> -->
                  <p>from {{ dataService?.buildCardData?.currency?.symbol }}{{ getLocaledPrice(defaultCustomFeaturePrice | number : '1.2-2') }}</p>
                  <p>from {{ defaultCustomFeatureDuration }} {{ (defaultCustomFeatureDuration > 1) ? 'days' : 'day' }}</p>
                </div>
              </div>
              <div class="actionButton">
                <button type="button" (click)="showWarningPopup()" [disabled]="phaseService.recommendedCoreFeaturesToBeAdded?.length > 0">+ Create custom feature</button>
                <div class="disabledTooltip" *ngIf="phaseService.recommendedCoreFeaturesToBeAdded?.length > 0">
                  You can't create a custom feature when other features are selected
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footerSection">
          <button type="button" class="prevButton" (click)="previousCTAClicked()">Previous</button>
          <div class="pricingDetail" *ngIf="phaseService.recommendedCoreFeaturesToBeAdded?.length > 0">
            <div class="detailBox">
              <div class="subText"><h6>ADDING</h6></div>
              <div class="headingText">{{phaseService.recommendedCoreFeaturesToBeAdded?.length}} feature</div>
            </div>
            <div class="detailBox">
              <div class="subText"><h6>DURATION</h6></div>
              <div class="headingText">
                <ng-container *ngIf="!phaseService.isShowLoaderOnCustomFeatureFooter">
                  {{ getSelectedCoreFeaturesDuration() }} {{ (getSelectedCoreFeaturesDuration() > 1) ? 'days' : 'day' }}
                </ng-container>
                <div class="threeDotLoader" *ngIf="phaseService.isShowLoaderOnCustomFeatureFooter">
                  <div class="dot-flashing"></div>
                </div>
              </div>
              
            </div>
            <div class="detailBox">
              <div class="subText"><h6>Cost</h6></div>
              <div class="headingText">
                <ng-container *ngIf="!phaseService.isShowLoaderOnCustomFeatureFooter">
                  {{ dataService?.buildCardData?.currency?.symbol}}{{ getLocaledPrice(getSelectedCoreFeaturesPrice() | number : '1.2-2') }}
                </ng-container>
                <div class="threeDotLoader" *ngIf="phaseService.isShowLoaderOnCustomFeatureFooter">
                  <div class="dot-flashing"></div>
                </div>
              </div>
              
            </div>
            <button type="button"class="addedButton" (click)="addRecommendedFeature()">
              Add {{phaseService.recommendedCoreFeaturesToBeAdded?.length > 1 ? 'features' : 'feature'}}
            </button>
          </div>
        </div>
      </div>

      <div class="suggestionPopup" *ngIf="isShowWarningPopup">
        <div class="suggestionBox">
          <img src="../../../../../../assets/images/suggestion-icon.svg" />
          <h3>Did you check out my suggestions?</h3>
          <p>It's much faster and cheaper to customise our existing features, than creating a custom one.</p>
          <div class="actionButton">
            <button type="button" class="createButton" (click)="goToComplexityScreen()">
              Create custom
            </button>
            <button type="button" class="exploreButton" (click)="beOnRecommendedScreen()">
              Explore suggestions
            </button>
          </div>
        </div>
      </div>

      <div class="featurePrevPopup" *ngIf="featureDetails">
        <div class="featurePrevHolder">
          <div class="backButton" (click)="toggleFeatureDetails('back')">
            <em class="icon-prev"></em> Back
          </div>
          <div class="imageHolder">
            <img [src]="featureDetails?.feature_screenshots?.android" />
          </div>
          <div class="featureDescription">
            <div class="topHead">
              <h2>{{ featureDetails?.title }}</h2>
              <!--<button type="button" class="removeFeature" (click)="addRecommendedFeature(featureDetails)">
                <em *ngIf="isFeatureSelected(featureDetails)" class="icon-delete"></em>
                <em *ngIf="!isFeatureSelected(featureDetails)" class="icon-plus"></em>
              </button>-->
            </div>
            <h3>{{ featureDetails?.feature_bundle_name }}</h3>
            <h4>{{ dataService?.buildCardData?.currency?.symbol + '' + featureDetails?.price }}<br>
              {{ featureDetails?.duration | number : '1.1-1' }} {{ (featureDetails?.duration > 1) ? 'days' : 'day' }}</h4>
            <p>{{ featureDetails?.description }}</p>
            <button type="button" class="addFeature" (click)="toggleFeatureDetails('back', featureDetails)"
              [disabled]="disableButtonForAlreadyAddedFeature(featureDetails)">
              <em *ngIf="isFeatureSelected(featureDetails)" class="icon-delete"></em>
              <em *ngIf="!isFeatureSelected(featureDetails)" class="icon-plus"></em>
              {{ isFeatureSelected(featureDetails) ? 'Remove feature' : 'Add feature' }}
              <div class="disabledTooltip" *ngIf="disableButtonForAlreadyAddedFeature(featureDetails)">
                Feature already added
              </div>
            </button>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>

<div class="commonPopUp" [ngClass]="{'active': phaseService.isShowFeatureRecommendationsModal}" *ngIf="phaseService.isShowFeatureRecommendationsModal">
  <div class="popOverlay"></div>
  <div class="popHolder recommendationTriggerPopup">
    <div class="closePopUp" (click)="closeRecommendationsModal()"><span class="icon-cancel"></span></div>
    <p>We might have a similar feature within our catalog that can save you some time and money. Would you like to visit those?</p>
    <div class="footerButton">
      <button type="button" class="yesButton" (click)="showFeatureRecommendations(featureRecommendationsFromNatashaSearch)">YES</button>
      <button type="button" class="noButton" (click)="addFeature(customFeatureForm, true)">
        <ng-container *ngIf="!showLoaderForNatashaRecommendationModal">
          {{ 'NO' }}
        </ng-container>

        <ng-container *ngIf="showLoaderForNatashaRecommendationModal">
          <div class="threeDotLoader">
            <div class="dot-flashing"></div>
          </div>
        </ng-container>

      </button>
    </div>
  </div>
</div>
