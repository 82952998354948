import {Component, EventEmitter, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {FormGroup} from '@angular/forms';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Subscription} from 'rxjs';
import {ApiRequests} from '@shared/services/api-requests.service';
import {UtilityService} from '@shared/services/utility.service';
import { Intercom } from 'ng-intercom';
import {AppsFilterModel} from '@models/apps-filter-model/apps-filter.model';
import { HomeTemplateService } from '@shared/services/home-template.service';
import {
  PerfectScrollbarComponent, PerfectScrollbarConfigInterface,
  PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-add-new-template',
  templateUrl: './add-new-template.component.html',
  styleUrls: ['./add-new-template.component.scss']
})
export class AddNewTemplateComponent implements OnInit {

  public dataService;
  public phaseService;
  public router;
  public singleUrl = null;
  public templateExist = false;
  public templateButtonText;
  public preventClick = false;
  public searchObservable: Subscription;
  public productsList = [];
  public showLoaderForUrl = false;
  public platformList = [];
  @ViewChild('title') public title;
  @ViewChild('appUrl') public appUrl;
  showCategoriesDropdown: boolean;
  public isSmallImage = false;
  @Output() customTemplateAdded: EventEmitter<any> = new EventEmitter();
  PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
  };

  constructor(phaseService: PhaseService, dataService: DataService, router: Router, public analyticsSegment: Angulartics2Segment, 
              public apiRequest: ApiRequests, public utilityService: UtilityService, private homeTempService: HomeTemplateService, private renderer: Renderer2) {
    this.phaseService = phaseService;
    this.dataService = dataService;
    this.router = router;
    this.templateButtonText = 'Submit';
  }

  ngOnInit() {
    if (this.phaseService.addedTemplate.id) {
      this.dataService.homePageData.platforms.forEach(platform => {
        const findindex = this.phaseService.addedTemplate.platform_ids.findIndex((p,i)=> {return p == platform.id});
        if(findindex !== -1){
          this.platformList.push(platform);
        }
      });
    }
    this.getAndRenderCategories();
  }

  public closeAddTemplatePopup() {
    if (!this.preventClick) {
      this.resetForm();
      this.phaseService.showAddNewTemplate = false;
    }
  }

  public addNewTemplate(form: FormGroup) {
    this.phaseService.trackEventForObject(this.analyticsSegment, 'custom_template_created', {
      templates_id: this.phaseService.addedTemplate.id,
      templates_name: this.phaseService.addedTemplate.name
    });
    if (!this.preventClick) {
      if (this.phaseService.addedTemplate.id) {
        this.editTheTemplate(form);
      } else {
        this.addTheTemplate(form);
      }
      this.customTemplateAnalytics('template_submit');
    }
  }

  public addTheTemplate(form: FormGroup) {
    if (this.templateAlreadyPresent()) {
      this.templateExist = true;
    } else {
      this.preventClick = true;
      this.phaseService.addedTemplate.category_ids = [];
      this.phaseService.addedTemplate.category_ids.push(this.phaseService.selectedCategory.id);
      this.apiRequest.addNewTemplate(this.phaseService.addedTemplate, this.platformList).subscribe((data: any) => {
        form.reset();
        this.preventClick = false;
        const template = data;
        this.resetForm();
        template.new_template_added = true;
        template.selected = true;
        this.phaseService.showAddNewTemplate = false;
        template.platform_ids = Object.assign([], [...data.platform_ids]);
        this.homeTempService.customAddedTemplate.push(template);
        this.homeTempService.requestedTemplateList.push(template);
        this.homeTempService.all_templates.unshift(template);
        this.phaseService.toggleSelectedApps(template);
        this.phaseService.toggleSelectedAppid(template.id);
        this.setSelectedPlatforms(data.platform_ids);
        this.homeTempService.selectedTemplates.push(template);
        this.customTemplateAdded.emit(true);

        setTimeout(() => {
          this.renderer.removeClass(document.getElementById('app-component'), 'hide-scroll-bar');
          this.renderer.removeClass(document.body, 'modal-open');
        }, 100);
        this.addTemplateHeadline(template);
      }, (error) => {
        this.preventClick = false;
      });
    }
  }

  public setSelectedPlatforms(platformIds) {
    this.dataService.homePageData.platforms.forEach(platform => {
      if (platformIds.indexOf(platform) > -1) {
        this.phaseService.toggleSelectedPlatform(platform);
        this.phaseService.toggleSelectedPlatformId(platform.id);
      }
    });
  }

  public addTemplateHeadline(template: any): void {
    if (!this.phaseService.selectedTemplates.firstTemplate.id) {
      this.phaseService.selectedTemplates.firstTemplate = template;
    } else if (!this.phaseService.selectedTemplates.secondTemplate.id) {
      this.phaseService.selectedTemplates.secondTemplate = template;
      this.phaseService.showTemplateSecondInput = true;
    }
  }

  public updateTemplateHeadline(template: any): void {
    if (template.id === this.phaseService.selectedTemplates.firstTemplate.id) {
      this.phaseService.selectedTemplates.firstTemplate = template;
    } else if (template.id === this.phaseService.selectedTemplates.secondTemplate.id) {
      this.phaseService.selectedTemplates.secondTemplate = template;
      this.phaseService.showTemplateSecondInput = true;
    }
  }

  public editTheTemplate(form: FormGroup) {
    if (this.templateAlreadyPresent()) {
      this.templateExist = true;
    } else {
      this.preventClick = true;
      this.phaseService.addedTemplate.category_ids = [];
      if (this.phaseService.selectedCategory) {
        this.phaseService.addedTemplate.category_ids.push(this.phaseService.selectedCategory.id);
      }
      this.apiRequest.addNewTemplate(this.phaseService.addedTemplate, this.platformList).subscribe((data: any) => {
        form.reset();
        this.preventClick = false;
        const template = data;
        template.selected = this.phaseService.addedTemplate.selected;
        if (this.dataService.editedTemplates.indexOf(template.id) === -1) {
          this.dataService.editedTemplates.push(template.id);
        }
        this.phaseService.showAddNewTemplate = false;
        this.resetForm();
        this.updateEditedTemplate(template);
        if (template.selected) {
          this.updatePhases();
        }
        if (this.dataService.editedTemplates.indexOf(data) === -1) {
          this.dataService.editedTemplates.push(data);
        }
        this.updateTemplateHeadline(template);
      }, (error) => {
        this.preventClick = false;
      });
    }
  }

  public updateEditedTemplate(updatedTemplate) {
    const index = this.homeTempService.requestedTemplateList.findIndex(template => {
      return template.id === updatedTemplate.id;
    });
    this.homeTempService.requestedTemplateList[index] = updatedTemplate;
    const index2 = this.phaseService.selectedApps.findIndex(template => {
      return template.id === updatedTemplate.id;
    });
    this.phaseService.selectedApps[index2] = updatedTemplate;
    const index3 = this.phaseService.selectedHash.application_ids.findIndex(template => {
      return template.id === updatedTemplate.id;
    });
    this.phaseService.selectedHash.application_ids[index3] = updatedTemplate.id;
  }

  public templateAlreadyPresent() {
    let present = false;
    if (this.phaseService.addedTemplate.id) {
      for (const template of this.homeTempService.requestedTemplateList) {
        if (this.phaseService.addedTemplate.id !== template.id) {
          if (template.title.toLowerCase() === this.phaseService.addedTemplate.title.trim().toLowerCase()) {
            present = true;
            break;
          }
        }
      }
    } else {
      for (const template of this.homeTempService.requestedTemplateList) {
        if (template.title.toLowerCase() === this.phaseService.addedTemplate.title.trim().toLowerCase()) {
          present = true;
          break;
        }
      }
    }

    if (present) {
      return present;
    } else {
      for (const app of this.dataService.apps) {
        if (app.title.toLowerCase() === this.phaseService.addedTemplate.title.trim().toLowerCase()) {
          present = true;
          break;
        }
      }
      return present;
    }
  }

  public addUrl() {
    if (!this.preventClick) {
      if (this.singleUrl != null) {
        const tempProduct = {product_name: this.singleUrl, description: '', url: '', image: '', tag: ''};
        this.phaseService.addedTemplate.reference_urls.push(tempProduct);
        this.singleUrl = null;
      }
    }
  }

  public deleteAppUrl(i) {
    if (!this.preventClick) {
      this.phaseService.addedTemplate.reference_urls.splice(i, 1);
    }
  }

  public resetForm() {
    this.templateExist = false;
    this.phaseService.addedTemplate = {id: '', category_ids: [], title: '', reference_urls: [], platform_ids: [], selected: false};
  }

  public updatePhases() {
    const collection = [];
    for (const apps of this.phaseService.selectedApps) {
      collection.push(apps.title);
    }
    const phaseObj = {
      title: 'Templates?',
      price: '0',
      icon: '/../../../assets/images/templates_icon.svg',
      collection: collection,
      phase: 'apps'
    };
    if (this.dataService.isEnterpise) {
      if (this.phaseService.selectedDataForSidePanel.length && this.phaseService.selectedDataForSidePanel[3]) {
        this.phaseService.selectedDataForSidePanel.splice(3, this.phaseService.selectedDataForSidePanel.length - 3, phaseObj);
      }
    } else {
      if (this.phaseService.selectedDataForSidePanel.length && this.phaseService.selectedDataForSidePanel[1]) {
        this.phaseService.selectedDataForSidePanel.splice(1, this.phaseService.selectedDataForSidePanel.length - 1, phaseObj);
      }
    }
  }

  public searchProducts() {
    this.showCategoriesDropdown = false;
    if (this.singleUrl && this.singleUrl.length > 2) {
      this.showLoaderForUrl = true;
      if (this.searchObservable) {
        this.searchObservable.unsubscribe();
      }
      this.productsList = [];
      this.searchObservable = this.apiRequest.fetchProductsList(this.singleUrl)
        .subscribe(
          (data: any) => {
            this.productsList = data.data;
            this.showLoaderForUrl = false;
          },
          (error) => {
            this.showLoaderForUrl = false;
          }
        );
    } else {
      if (this.searchObservable) {
        this.searchObservable.unsubscribe();
      }
      this.productsList = [];
      this.showLoaderForUrl = false;
    }
  }

  public selectProduct(product?) {
    const urls = this.phaseService.addedTemplate.reference_urls;
    if (product) {
      if (this.singleUrl && urls.length <= 3) {
        urls.push(product);
        this.singleUrl = null;
        this.productsList = [];
      }
    } else {
      const tempProduct = {product_name: this.singleUrl, description: '', url: '', image: '', tag: ''};
      if (urls.length <= 3) {
        urls.push(tempProduct);
      }
    }
  }

  /*public showAddNewTemplate() {
    return this.phaseService.showAddNewTemplate;
  }*/

  public getAddedTemplate() {
    return this.phaseService.addedTemplate;
  }

  public getSelectedCategory() {
    return this.phaseService.selectedCategory;
  }

  public getCategories() {
    return this.dataService.categories;
  }

  public isValidTitle() {
    return this.title && this.title.errors && (this.title.dirty || this.title.touched);
  }

  public showAddUrlButton() {
    return this.productsList.length === 0 && this.singleUrl && this.singleUrl.length > 2 && !this.showLoaderForUrl;
  }

  public isLinkValid() {
    return this.appUrl && this.appUrl.errors && (this.appUrl.dirty || this.appUrl.touched) && this.productsList.length == 0 && !this.showLoaderForUrl;
  }

  public isDisableSubmit() {
    return (this.getAddedTemplate().reference_urls.length == 0) || !this.getAddedTemplate().title || (this.title && this.title.errors && (this.title.dirty || this.title.touched)) ||
      (this.appUrl && this.appUrl.errors && (this.appUrl.dirty || this.appUrl.touched)) || this.platformList.length === 0;
  }

  public customTemplateAnalytics(input) {

  }

  public selectPlatforms(platform) {
    const index = this.platformList.findIndex(pl => pl.id === platform.id);
    if (index === -1) {
      this.platformList.push(platform);
    } else {
      this.platformList.splice(index, 1);
    }
  }

  public isPlatformSelected(platform) {
    return this.platformList.find(plt => plt.id === platform.id);
  }

  getAndRenderCategories(): void {
    this.apiRequest.fetchCategories(this.phaseService.selectedHash.product_id).subscribe((data: any) => {
      this.dataService.categories = data.categories;
    }, error => this.dataService.showHideLoader(false));
  }

  showCategories() {
    this.showCategoriesDropdown = !this.showCategoriesDropdown;
    this.productsList = [];
    this.customTemplateAnalytics('category_dropdown');
  }

  setSelectedCategory(category) {
    this.phaseService.selectedCategory = category;
    this.customTemplateAnalytics('category_selected');
  }

  filterPlatformsForWebOrMobile(webOrMobile) {
    if(this.dataService.homePageData.platforms) {
      if (webOrMobile === 'web') {
        return this.dataService.homePageData.platforms.filter(p => ((p.title === 'Windows') || (p.title === 'Web') || (p.title === 'macOS')));
      } else {
        return this.dataService.homePageData.platforms.filter(p => ((p.title !== 'Windows') && (p.title !== 'Web') && (p.title !== 'macOS')));
      }
    }
  }
  public onScrollRightSection(e) {

    if ( e.target.scrollTop > 20) {
      console.log(e);
      this.isSmallImage = true;
    }  else {
      this.isSmallImage = false;
    }
  }


}
