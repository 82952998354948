<ng-container *ngIf="innerWidth >= 769">
<div class="featureStudio" id='studioFeatureanimation'>

  <!--Left part start-->
  <div class="studioLeft "  [ngClass]="{ 'setScrollActive': dataService.isScrollTopFeatures}">
    <!--Search panel Box start-->
    <div class="searchBox" [ngClass]="{'searchExpand' : searchFocus}">
      <form>
        <input type="text" placeholder="{{dataService.isScrollTopFeatures ? 'Search' : 'Search for a feature'}}" autocapitalize="none" (keyup)="searchFilter($event)"
               (focus)="toggleRelatedSearches(true)" #searchInput autocomplete="off" name="search" [(ngModel)]="searchQuery">
               <button class="searchButton" type="button" (click)="searchInput.focus();toggleSearchInput()"><em class="icon-magnifyglass"></em></button>
               <button class="closeButton" (click)="closeSearchExpanded()" type="button"><em class="icon-cancel"></em></button>
      </form>
    </div>
    <!--Search panel Box close-->

    <!--Buttons Box start-->
    <div class="buttonsBox" [ngClass]="{'hideThis' : searchFocus}">
      <button type="button" class="featureButton" (click)="addNewFeature()"
              [ngClass]="{ 'disabled-for-storeplus': phaseService.isStoreFeatureRequest }">
        <em class="icon-plus"></em> <span class="notforTooltip">Custom feature</span> <span class="forTooltip">Add custom feature</span>
      </button>
      <div class="tooltipText" *ngIf="phaseService.isStoreFeatureRequest">
        Custom features are not supported for Studio Store buildcards.
      </div>
      <button type="button" class="filterButton" [ngClass]="{active : showFilter}" (click)="toggleFilterPopup()" [disabled]="!realFiltersFetched">
        <em class="icon-fillter"></em>
        <div class="filterNotify" *ngIf="filterApplied && getAppliedFiltersCount()">{{getAppliedFiltersCount()}}</div>
        <span class="tooltipText">Filters</span>
      </button>
      <!--<button type="button" class="filterButton" [ngClass]="{active : showFilter}" (click)="toggleFilterPopup()" [disabled]="!realFiltersFetched"><em class="icon-fillter"></em> <span>Filters</span> <div class="filterNotify" *ngIf="filterApplied && getAppliedFiltersCount()">{{getAppliedFiltersCount()}}</div></button>-->
    </div>
    <!--Buttons Box start-->

    <!--Filters section start-->
    <div class="filterSection" [ngClass]="{active : showFilter}" *ngIf="showFilter && !areBundleFeaturesLoading">
      <div class="filterToolbar">
        <div class="filterCount">FILTER MENU</div>
        <div class="clearFilters" *ngIf="getAppliedFiltersCount() > 0" (click)="resetFilters()">Clear all</div>
      </div>

      <div class="filterListing">
      <perfect-scrollbar class="myScrollbar" (psScrollY)="filterGroupScrolled($event)" [config]="PERFECT_SCROLLBAR_CONFIG">
        <div class="filterRow">
          <h4><strong>Feature cost</strong> <span *ngIf="!checkIfPriceRangeFull()" (click)="resetRangeFilters()">Clear</span></h4>
          <div class="rangeSlider">
            <ngx-slider #sliderElementPrice
                        [(value)]="rangePrice[0]"
                        [(highValue)]="rangePrice[1]"
                        [options]="optionsPrice"
                        (userChangeStart)="onPriceChange($event)"
                        (userChange)="onPriceChange($event)"
                        (userChangeEnd)="onPriceChange($event)"></ngx-slider>
            <div class="minValue">{{getCurrency().symbol}}{{getLocaledPriceForFilter(rangePrice[0] | number)}}
            </div>
            <div class="maxValue">{{getCurrency().symbol}}{{getLocaledPriceForFilter(rangePrice[1] | number)}}
            </div>
          </div>
        </div>

        <div class="filterRow">
          <h4><strong>Functionality</strong> <span *ngIf="tagFilterApplied > 0" (click)="clearFeatureFilter()">Clear</span></h4>
          <div class="customCheckbox" *ngFor="let tag of featureFilter.tags; let k = index">
            <input type="checkbox" [checked]="tag.selected" (change)="applyTagFilter(tag)" id="tag_{{k}}">
            <label for="tag_{{k}}">{{tag.title}}</label>
          </div>
        </div>
      </perfect-scrollbar>
      </div>

      <button type="button" class="filterApply" (click)="filterFeatures()" [disabled]="checkIfFilterChnaged()">Apply filters</button>

    </div>
    <!--Filters section close-->

    <!--<div class="featureCounts" *ngIf="!searchView && !filterApplied">
      ALL FEATURES
    </div>-->

    <!--Feature Group listing start-->
    <div class="featureGroups" *ngIf="!searchView && !areBundleFeaturesLoading && !showFilter && getFeatureBundles().length > 0" (scroll)="featureGroupScrolled($event)">
    <perfect-scrollbar #perfectScroll class="myScrollbar" (psScrollY)="featureGroupScrolled($event)" [config]="PERFECT_SCROLLBAR_CONFIG">
      <ul *ngIf="!areBundlesLoading">
        <li class="active" [ngClass]="{active: focusedBundleIndex === i || bundle.selected, current: focusedBundleIndex === i}"
            *ngFor="let bundle of getFeatureBundles(); let i = index">

          <div class="groupImgName" (click)="changeFocusedBundle(bundle, i, $event)" (mouseover)="showHideArrowIcon(i)">
            <div class="groupImg"><img [src]="bundle.background_url ? utilityService.addHttps(bundle.background_url) : './../../../assets/images/feature_background.png'" alt=""/></div>
            <div class="groupName">{{bundle.title}}</div>
            <div class="toggleIcon"><em class="icon-next"></em></div>
          </div>

          <div class="selectWrap selectedCount">
            <div>SELECTED <span>{{getSelectedFeaturesCount(bundle)}}/{{getFeatureCountInBundle(bundle)}}</span></div>
            <div class="selectAllLink">
              <button *ngIf="(getSelectedFeaturesCount(bundle) < getFeatureCountInBundle(bundle))" (click)="selectAllFeaturesFromBundle(true, bundle)">Select all</button>
              <button *ngIf="(getSelectedFeaturesCount(bundle) === getFeatureCountInBundle(bundle))" (click)="selectAllFeaturesFromBundle(false, bundle)">Unselect all</button>
            </div>
          </div>

          <!--Feature listing start-->
          <div class="featureListPanel" [ngClass]="{ 'storePlusCase': (getFeaturesFromBundle(bundle)?.length <=3) }">
            <perfect-scrollbar class="innerScrollbar" [config]="PERFECT_SCROLLBAR_CONFIG">
            <ng-container *ngIf="focusedBundleIndex === i">
              <ul *ngIf="!showFeatureLoading">
              <li *ngFor="let feature of getFeaturesFromBundle(bundle); let j = index"
                  [ngClass]="{'active': feature.selected, 'preview-active': checkIfPreviewed(feature), 'featureDisabled': feature.disabled}"
                  (click)="handleMouseEnter(j, false, feature, $event)">
                <div class="featureIcon" *ngIf="feature.icon">
                  <img [src]="feature.icon?(phaseService.getUrl(feature.icon) | safeUrl) : './../../../assets/images/featureDefault.png'" alt=""/>
                </div>
                <div class="featureIcon" *ngIf="!feature.icon">
                  <img [src]="'./../../../assets/images/featureDefault.png'" alt=""/>
                </div>
                <div class="featureDetail">
                  <h3>{{feature.title}}</h3>
                  <ng-container *ngIf="phaseService.featureData">
                    <p>{{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(feature) ? getFeaturePrice(feature) : feature?.breakup_price | number : '1.2-2')}}</p>
                    <ng-container *ngIf="!phaseService.isStoreFeatureRequest">
                      <p>{{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{(chekIfFeatureIsInSelected(feature) ? feature?.duration : feature?.breakup_duration) | number : '1.1-1'}} {{getWeeksAndDaysText(feature)}}</p>
                    </ng-container>
                  </ng-container>
                </div>
                <div class="toolButtons">
                  <button type="button" class="viewButton" [ngClass]="{'active': checkIfPreviewed(feature)}" (click)="previewSelected()" (click)="handleMouseEnter(j, false, feature, $event)"><em class="icon-eye"></em></button>
                  <button type="button" class="addButton" (click)="changeFocusedFeature(feature, false, $event)"><em class="icon-plus"></em></button>
                  <button type="button" class="removeButton" *ngIf="!feature?.disabled" (click)="changeFocusedFeature(feature, false, $event)"><em class="icon-delete"></em></button>
                </div>
              </li>
            </ul>

              <!--loading state for bundle feature start-->
              <div class="loadingState" *ngIf="showFeatureLoading">
                <div class="loadingRow"><span></span></div>
                <div class="loadingRow"><span></span></div>
                <div class="loadingRow"><span></span></div>
                <div class="loadingRow"><span></span></div>
                <div class="loadingRow"><span></span></div>
                <div class="loadingRow"><span></span></div>
                <div class="loadingRow"><span></span></div>
                <div class="loadingRow"><span></span></div>
                <div class="loadingRow"><span></span></div>
              </div>
              <!--loading state for bundle feature close-->
            </ng-container>
          </perfect-scrollbar>
        </div>
        </li>
      </ul>

      <!--loading state for bundle set start-->
      <div class="loadingState" *ngIf="areBundlesLoading">
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
      </div>
      <!--loading state for bundle set close-->
    </perfect-scrollbar>
    </div>
    <!--Feature Group listing close-->

    <!--Search Result area start-->
    <div class="searchResults" *ngIf="!areBundleFeaturesLoading && (searchView || (dataService?.filteredFeatures?.length > 0))">
      <div class="searchToolbar">
        <div class="searchCount">RESULTS <span>{{searchView ? dataService.featureList.length : dataService.filteredFeatures.length}}</span></div>
        <div class="clearSearch" (click)="clearSearch(searchView ? '' : 'ClearFilters')">{{searchView ? 'Clear search' : 'Clear filters'}}</div>
      </div>

      <!--List items box start-->
      <div class="searchItems" *ngIf="(dataService.featureList.length > 0) || (dataService.filteredFeatures.length > 0)">
        <perfect-scrollbar class="myScrollbar" [config]="PERFECT_SCROLLBAR_CONFIG">
          <ul>
            <li *ngFor="let feature of getSearchOrFilterFeatures(); index as m"
                [ngClass]="{active: feature.selected, 'preview-active': checkIfPreviewed(feature), 'featureDisabled': feature.disabled}"
                (click)="handleMouseEnter(m, false, feature)">
              <div class="searchIcon" *ngIf="feature.icon">
                <img [src]="feature.icon?(phaseService.getUrl(feature.icon) | safeUrl) : './../../../assets/images/featureDefault.png'" alt=""/>
              </div>
              <div class="searchIcon" *ngIf="!feature.icon">
                <img [src]="'./../../../assets/images/featureDefault.png'" alt=""/>
              </div>
              <div class="searchDetail">
                <h3>{{feature.title}}</h3>
                <p>{{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(feature) ? getFeaturePrice(feature) : feature?.breakup_price | number : '1.2-2')}}</p>
                <p *ngIf="!phaseService.isStoreFeatureRequest">
                  {{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{(chekIfFeatureIsInSelected(feature) ? feature?.duration : feature?.breakup_duration) | number : '1.1-1'}} {{getWeeksAndDaysText(feature)}}
                </p>
              </div>
              <div class="toolButtons">
                <button type="button" class="viewButton" [ngClass]="{'active': checkIfPreviewed(feature)}" (click)="previewSelected()" (click)="handleMouseEnter(m, false, feature, $event)"><em class="icon-eye"></em></button>
                <button type="button" class="addButton" (click)="changeFocusedFeature(feature)"><em class="icon-plus"></em></button>
                <button type="button" class="removeButton" *ngIf="!feature?.disabled" (click)="changeFocusedFeature(feature)"><em class="icon-delete"></em></button>
              </div>
            </li>
          </ul>
        </perfect-scrollbar>
      </div>
      <!--List items box close-->

      <!--Search not found start-->
      <div class="searchNotfound" *ngIf="!filterApplied && (dataService.featureList.length === 0)">
        <p>Your search produced no results.</p>
        <button type="button" class="addfeatureButton" (click)="addNewFeature()"><em class="icon-plus"></em> <span>Custom feature</span></button>
      </div>
      <!--Search not found close-->

    </div>
    <!--Search Result area close-->

    <!--Search and filter loading state start-->
    <div class="searchFilterLoadingState" *ngIf="areBundleFeaturesLoading">
      <div class="loadingState">
        <div class="loadingHeading">
          <div class="headingName">RESULTS</div>
          <div class="fiveDotloader">
            <span></span><span></span><span></span><span></span><span></span>
          </div>
        </div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
      </div>
    </div>
    <!--Search and filter loading state close-->


    <div class="searchNotfound" *ngIf="filterApplied && (dataService.filteredFeatures.length === 0)">
      <div class="searchToolbar">
        <div class="searchCount">RESULTS <span>0</span></div>
        <div class="clearSearch" (click)="clearSearch('ClearFilters')">Clear filters</div>
      </div>
      <p>Your filters produced no results.</p>
      <button type="button" class="addfeatureButton" (click)="addNewFeature()"><em class="icon-plus"></em> <span>Custom feature</span></button>
    </div>

  </div>
  <!--Left part close-->


  <!--Center part start-->
  <div class="studioCenter" [ngClass]="{'maximizeGallery' : minimizeGallery, 'natashaCollaps' : natashaPanel, 'scrollOn': isScrollCartItem, 'discriptionActive' : featureDescription, 'featureRequestFlow' : phaseService.isStoreFeatureRequest}">
    <div class="previewDisplayBox" [ngClass]="{'withRolesPermission' : selectedPreviewPlatform === 'android'}" *ngIf="getSelectedFeatures().length > 0 || inHoverState" (click)="resetPreviewPannelState()"><!-- TODO -->

      <!--Common Notification mark start-->
      <div class="pageNotification" [ngClass]="{'active' : showFeatureAddedToolTip}">
        <em class="icon-tick"></em> <p>Feature added to your Shopping cart</p>
      </div>
      <div class="pageNotification" [ngClass]="{'active' : showFeatureRemovedTooltip}">
        <em class="icon-tick"></em> <p>Feature removed from your Shopping cart</p>
      </div>

      <!--Common Notification mark close-->

      <!--Display view selection start-->
      <div class="viewSelection">
        <ul>
          <li [ngClass]="{active : selectedPreviewPlatform === 'android'}">
            <div class="viewIcon" (click)="toggleLayoutView('android')"><em class="icon-mobiledevice"></em></div>
          </li>
          <li [ngClass]="{active : selectedPreviewPlatform === 'web'}">
            <div class="viewIcon" (click)="toggleLayoutView('web')"><em class="icon-desktopdevice"></em></div>
          </li>
        </ul>
      </div>
      <!--Display view selection close-->

      <!--Mobile preview box start-->
      <div class="mobilePrev" [ngClass]="{'descriptionZindex' : descriptionZindex}" *ngIf="(getSelectedFeatures().length > 0 || inHoverState) && selectedPreviewPlatform === 'android'">
        <!--Mobile screen holder section start-->
        <div class="screenHolder" [ngClass]="{'newImagesWrap' : isNewImage()}" *ngIf="isPreviewAvailable()">
          <img [src]="utilityService.addHttps(getSelectedPreview())" [ngClass]="{animation1: toggleClass, animation2: !toggleClass}" alt="" />
        </div>

        <div class="screenHolder" *ngIf="!isPreviewAvailable()">
          <img *ngIf="previewedFeature && previewedFeature.user_requested === false" src="./../../../assets/images/notavailable_mobile.png" alt="" />
          <img *ngIf="previewedFeature && previewedFeature.user_requested === true" src="./../../../assets/images/custom_feature_mobile.png" alt="" />
        </div>
        <!--Mobile screen holder section close-->


        <!--Mobile description section start-->
        <div class="featureDescription"><!-- TODO -->
          <div class="topHead">
            <h2 #featureTitle>{{previewedFeature?.title}}</h2>
            <button type="button" (click)="removeSelectedFeature($event, previewedFeature, i)" *ngIf="!previewedFeature?.disabled" title="{{ previewedFeature?.selected ? 'Remove feature' : 'Add feature' }}" class="removeFeature">
              <em *ngIf="previewedFeature?.selected" class="icon-delete"></em>
              <em *ngIf="!previewedFeature?.selected" class="icon-plus"></em>
            </button>
            <div class="titleTooltip" *ngIf="isOverflowing(featureTitle)">{{previewedFeature?.title}}</div>
          </div>

          <h3>{{ previewedFeature?.feature_bundle_name }}</h3>
          <h4 *ngIf="phaseService.featureData">
            <ng-container *ngIf="phaseService.showHideBottomBarLoader">
              <div class="threeDotLoader"><div class="dot-flashing"></div></div>
            </ng-container>
            <ng-container *ngIf="!phaseService.showHideBottomBarLoader">
              {{ chekIfFeatureIsInSelected(previewedFeature) ? 'from ' : '' }}{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(previewedFeature) ? getFeaturePrice(previewedFeature) : previewedFeature?.breakup_price | number : '1.2-2')}}<br>
              <ng-container *ngIf="!phaseService.isStoreFeatureRequest">
                {{ chekIfFeatureIsInSelected(previewedFeature) ? 'from ' : '' }}{{(chekIfFeatureIsInSelected(previewedFeature) ? previewedFeature?.duration : previewedFeature?.breakup_duration) | number : '1.1-1'}} {{getWeeksAndDaysText(previewedFeature)}}
              </ng-container>
            </ng-container>
          </h4>

          <div class="discriptionArea" (mouseenter)="descriptionHover()" (mouseleave)="descriptionHover()" *ngIf="previewedFeature?.description">
            <div class="tooltipIcon" (click)="descriptionToggle()"><em class="icon-info-circle"></em></div>
            <div class="discriptonBox" [ngClass]="{'active' : featureDescription}">
              <div class="closeButton" (click)="descriptionToggle()"><em class="icon-cancel"></em></div>
              <p #featureDesc [innerHTML]="previewedFeature?.description | safeHtml"></p>
              <div class="discriptonTooltip" *ngIf="isOverflowing(featureDesc)"
                   [innerHTML]="previewedFeature?.description | safeHtml">
              </div>
            </div>
          </div>

          <div class="toolbuttons">
            <button type="button" *ngIf="previewedFeature?.user_requested && isUnApprovedFeature(previewedFeature)" (click)="editRequestedFeature(previewedFeature, $event)" class="removeButton">Edit feature</button>
            <button type="button" *ngIf="!viewEditButton(previewedFeature) && checkIfFeaturePaid(previewedFeature) && checkIfFeatureEnabled(previewedFeature)" (click)="featureNotes(previewedFeature)" class="addNotes"><img src="../../../../assets/images/notes-tasks.svg" alt="" /> <span>Add note</span></button>
            <button type="button" *ngIf="viewEditButton(previewedFeature) && checkIfFeaturePaid(previewedFeature) && checkIfFeatureEnabled(previewedFeature)" (click)="featureNotesEdit(previewedFeature)" class="addNotes"><img src="../../../../assets/images/notes-tasks.svg" alt="" /> <span>Edit note</span></button>
          </div>


          <div class="rolesRow" *ngIf="previewedFeature?.is_repeatable && previewedFeature?.selected">
            <div class="numHead">Select user roles <em class="icon-info-circle"></em>
              <div class="tooltipInfoBox">Tell us how many different user roles your app needs. For example, the user journeys for an admin will be different from a general user.</div>
              <!-- <div class="tooltipOverlay"></div> -->
            </div>
            <div class="nunBox">
              <button type="button" (click)="IncDecFeatureCount(previewedFeature, 'decrease')" [ngClass]="{'disabled': previewedFeature?.disabled || previewedFeature?.feature_count == previewedFeature?.repeatable_count }"><em class="icon-minus"></em></button>
              <input type="text" readonly [value]="previewedFeature?.feature_count" />
              <button type="button" (click)="IncDecFeatureCount(previewedFeature, 'increase')" [ngClass]="{'disabled': previewedFeature?.feature_count == 9}"><em class="icon-plus"></em></button>
            </div>
          </div>
        </div>
        <!--Mobile description section close-->

      </div>
      <!--Mobile preview box close-->

      <!--Web preview box start-->
      <div class="webPrev" *ngIf="(getSelectedFeatures().length > 0 || inHoverState) && selectedPreviewPlatform === 'web'">
        <!--Web screen holder section start-->
        <div class="screenHolder" *ngIf="isPreviewAvailable()">
          <img [src]="utilityService.addHttps(getSelectedPreview())" [ngClass]="{animation1: toggleClass, animation2: !toggleClass}" alt="" />
        </div>

        <div class="screenHolder" *ngIf="!isPreviewAvailable()">
          <img *ngIf="previewedFeature && previewedFeature.user_requested === false" src="./../../../assets/images/notavailable_web.png" alt="" />
          <img *ngIf="previewedFeature && previewedFeature.user_requested === true" src="./../../../assets/images/custom_feature_web.png" alt="" />
        </div>
        <!--Web screen holder section close-->

        <!--this is line break only start-->
        <div class="lineBreak"></div>
        <!--this is line break only close-->

        <!--Web description section start-->
        <div class="featureDescription">
          <div class="topHead">
            <h2>{{previewedFeature?.title}}</h2>
            <div class="discriptionArea" *ngIf="previewedFeature?.description" (mouseover)="descriptionToggle()" (mouseout)="descriptionToggle()">
              <div class="tooltipIcon"><em class="icon-info-circle"></em></div>
              <div class="discriptonBox">
                <p [innerHTML]="previewedFeature?.description | safeHtml"></p>
              </div>
            </div>

            <button type="button" (click)="removeSelectedFeature($event, previewedFeature, i)" *ngIf="!previewedFeature?.disabled" title="{{ previewedFeature?.selected ? 'Remove feature' : 'Add feature' }}" class="removeFeature"><em class="icon-delete"></em></button>
          </div>

          <h3>{{ previewedFeature?.feature_bundle_name }}</h3>

          <h4 *ngIf="phaseService.featureData">
            <ng-container *ngIf="phaseService.showHideBottomBarLoader">
              <div class="threeDotLoader"><div class="dot-flashing"></div></div>
            </ng-container>
            <ng-container *ngIf="!phaseService.showHideBottomBarLoader">
              {{ chekIfFeatureIsInSelected(previewedFeature) ? 'from ' : '' }}{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(previewedFeature) ? getFeaturePrice(previewedFeature) : previewedFeature?.breakup_price | number : '1.2-2')}}<br>
              <ng-container *ngIf="!phaseService.isStoreFeatureRequest">
                {{ chekIfFeatureIsInSelected(previewedFeature) ? 'from ' : '' }}{{(chekIfFeatureIsInSelected(previewedFeature) ? previewedFeature?.duration : previewedFeature?.breakup_duration) | number : '1.1-1'}} {{getWeeksAndDaysText(previewedFeature)}}
              </ng-container>
            </ng-container>
          </h4>

          <div class="toolbuttons">
            <button type="button" *ngIf="previewedFeature?.user_requested && isUnApprovedFeature(previewedFeature)" (click)="editRequestedFeature(previewedFeature, $event)" class="removeButton">Edit feature</button>
            <button type="button" *ngIf="!viewEditButton(previewedFeature) && checkIfFeaturePaid(previewedFeature) && checkIfFeatureEnabled(previewedFeature)" (click)="featureNotes(previewedFeature)" class="addNotes"><img src="../../../../assets/images/notes-tasks.svg" alt="" /> <span>Add note</span></button>
            <button type="button" *ngIf="viewEditButton(previewedFeature) && checkIfFeaturePaid(previewedFeature) && checkIfFeatureEnabled(previewedFeature)"  (click)="featureNotesEdit(previewedFeature)" class="addNotes"><img src="../../../../assets/images/notes-tasks.svg" alt="" /> <span>Edit note</span></button>
          </div>

          <div class="rolesRow" *ngIf="previewedFeature?.is_repeatable && previewedFeature?.selected">
            <div class="numHead">Select user roles <em class="icon-info-circle"></em>
              <div class="tooltipInfoBox">Tell us how many different user roles your app needs. For example, the user journeys for an admin will be different from a general user.</div>
            </div>
            <div class="nunBox">
              <button type="button" (click)="IncDecFeatureCount(previewedFeature, 'decrease')" [ngClass]="{'disabled': previewedFeature?.disabled || previewedFeature?.feature_count == previewedFeature?.repeatable_count || disbaleDecreaseAfterPayment()}"><em class="icon-minus"></em></button>
              <input type="text" readonly [value]="previewedFeature.feature_count" />
              <button type="button" (click)="IncDecFeatureCount(previewedFeature, 'increase')" [ngClass]="{'disabled': previewedFeature?.feature_count == 9}"><em class="icon-plus"></em></button>
            </div>
          </div>
        </div>
        <!--Web description section close-->

      </div>
      <!--Web preview box close-->


    </div>

    <!--no feature selected box start-->
    <div class="noFeatureScreen" *ngIf="getSelectedFeatures().length === 0 && !previewedFeature">
        <div class="pageNotification" [ngClass]="{'active' : showFeatureRemovedTooltip}" *ngIf="showFeatureRemovedTooltip">
          <em class="icon-tick"></em> <p>Feature removed from your Shopping cart</p>
        </div>
        <div class="pageNotification" [ngClass]="{'active' : showRemoveAllFeatureTooltip}" *ngIf="showRemoveAllFeatureTooltip">
          <em class="icon-tick"></em> <p>All features removed from your Shopping cart</p>
        </div>
        <div class="arrowPointer">
          <em class="icon-leftside"></em>
        </div>
        <div class="noFeatureBox">
          <h3>No features selected</h3>
          <p>Start by picking features from the left panel</p>
        </div>
    </div>
    <!--no feature selected box close-->

    <div class="cartSection" *ngIf="getSelectedFeatures().length > 0" (mouseleave)="ff()">
      <!--Cart items count start-->
      <div class="cartItemCount">Selected features <span>{{getSelectedFeatures().length}}</span></div>
      <!--Cart items count close-->

      <!--Cart toolbar start-->
      <div class="cartToolbar">
        <div class="sortingButton" *ngIf="minimizeGallery">
          <div class="chooseOption sorting-options" (click)="toggleSortingOptions()">
            <img class="sorting-options" src="../../../../assets/images/sorting.svg" alt="" />
            <span class="sorting-options">{{ sortingText ? sortingText : 'Order by' }}</span>
          </div>
          <ul *ngIf="showSortingOptions">
            <li class="sorting-options" (click)="sortFeatures('title', 'A-Z')">A-Z</li>
            <li class="sorting-options" (click)="sortFeatures('titleReverse', 'Z-A')">Z-A</li>
            <li class="sorting-options" (click)="sortFeatures('updated_at', 'Date/Time')">Date/Time added</li>
            <li class="sorting-options" (click)="sortFeatures('feature_bundle_name', 'Bundle name')">Bundle name</li>
          </ul>
        </div>

        <button type="button" class="removeCart" (click)="removedUsingTutorial(false);showHideRemoveAllPopup()" *ngIf="!isFeatureRequest">Remove all</button>
        <button type="button" class="expandCollaps" (click)="setMinimizeGallery()" *ngIf="selectedPreviewPlatform === 'android'">
          <em *ngIf="!minimizeGallery" class="icon-maximize"></em>
          <em *ngIf="minimizeGallery" class="icon-minimize"></em>
        </button>

        <button type="button" class="expandCollaps" (click)="setMinimizeGallery()" *ngIf="selectedPreviewPlatform === 'web'">
          <em *ngIf="!minimizeGallery" class="icon-maximize"></em>
          <em *ngIf="minimizeGallery" class="icon-minimize"></em>
        </button>
      </div>
      <!--Cart toolbar close-->

      <!--Cart items listing start-->
      <div class="cartItemListing" [ngClass]="{webView : selectedPreviewPlatform === 'web', 'scrollActive' : isScrollCartItem}" (scroll)="cartItemScrolled($event)">
      <perfect-scrollbar class="myScrollbar" [config]="PERFECT_SCROLLBAR_CONFIG" (psScrollY)="cartItemScrolled($event)">
        <ul>
          <li *ngFor="let item of (getSelectedFeatures() | sortFeature: sortWith); index as i" [id]="item.id"
              [ngClass]="{active: checkIfPreviewed(item), remove: i === removingFeatureIndex && showRemoveAnimation, 'custom-disabled': (item.disabled && ((item.title !== 'Roles & Permissions') && (item.title !== 'Roles and Permissions')))}"
              (click)="changeFocusedSelectedFeature(i)">
            <div class="itemImg">
              <img [src]="utilityService.addHttps(getPreviewOfFeature(i))" alt="">
              <button type="button" class="removeButton"  *ngIf="!removeDeleteButton(item)" (click)="removeSelectedFeature($event, item, i)"><em class="icon-delete"></em></button>
              <button type="button" class="editButton" *ngIf="item?.user_requested && isUnApprovedFeature(item)" (click)="editRequestedFeature(item, $event)"><em class="icon-iconedit"></em></button>
            </div>
            <div class="itemDetail">
              <div class="itemTitleTooltip" *ngIf="(featureDescriptionTop > 0) && isOverflowing(itemTitle)" [ngStyle]="{'top': featureDescriptionTop + 'px', 'left': featureDescriptionLeft + 'px'}" >{{featuredescription}}</div>
              <div class="headingBox">
                <h3 #itemTitle (mouseenter)='showDescription($event, item.title)' (mouseleave)='hideDescription(e)'>{{item.title}}{{ item?.is_repeatable ? ' (' + item?.feature_count + ')' : '' }}</h3>
                <img *ngIf="checkFeatureNote(item)" src="../../../../assets/images/notes-tasks.svg" alt="" />
              </div>
              <h4>{{ item?.feature_bundle_name }}</h4>
              <ng-container *ngIf="phaseService.featureData">
                <p>{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(item) ? getFeaturePrice(item) : item?.breakup_price | number : '1.2-2')}}</p>
                <p *ngIf="!phaseService.isStoreFeatureRequest">
                  {{(chekIfFeatureIsInSelected(item) ? item?.duration : item?.breakup_duration) | number : '1.1-1'}} {{getWeeksAndDaysText(item)}}
                </p>
              </ng-container>
            </div>
          </li>
        </ul>
      </perfect-scrollbar>
      </div>
      <!--Cart items listing close-->
    </div>
  </div>
  <!--Center part close-->


  <!--Right part start-->
  <div class="studioRight" *ngIf="!phaseService.isStoreFeatureRequest"
       [ngClass]="{'natashaCollaps' : natashaPanel, 'setScrollActive' : isScrollNatashaSidebar}">
    <!--Right top part start-->
    <div class="topPart">
      <div class="hamburgerMenu" [ngClass]="{'active' : dataService.natashaDropdownPanel, 'blinkThis' : blinkAnimation}">
        <div class="clickArea" (click)="natashaDropdownClick()"><em class="icon-more"></em></div>
        <div class="menuOverlay" (click)="natashaDropdownClick()"></div>
        <div class="menuDropdown">
          <ul>
            <li (click)="closeWelcomePopup('showTutorialFromChatbot')">View tutorial</li>
            <li (click)="showFeaturePageSettings()">Settings</li>
            <li (click)="showNatashaInfo()">Natasha Info</li>
            <li (click)="natashaToggle()">{{natashaPanel ? 'Expand' : 'Collapse'}}  panel</li>
          </ul>
        </div>
      </div>

      <div class="natashaMenu" [ngClass]="{'hideThis' : mainwizardDot}" (click)="natashaToggle()">
        <div class="wizardAvatar idleAnimation">
          <span></span>
          <span></span>
        </div>
        <div class="natashaNotify">{{natashaUnreadMessageCount}}</div>
      </div>
    </div>
    <!--Right top part close-->

    <div class="natashaMiddle" id="natashaScrollMiddle" #scrollMe [scrollTop]="scrollMe.scrollHeight">
    <perfect-scrollbar #perfectScrollNatasha class="myScrollbar" (psScrollY)="natashaSidebarScrolled($event)" [config]="PERFECT_SCROLLBAR_CONFIG">

      <!--Wizard part start-->
      <div class="wizardBox">
          <div class="wizardDot" [ngClass]="{'hideThis' : mainwizardDot || shouldOpenNatashaInfo}">
            <div class="betaBadge"><img src="../../../../../assets/images/beta-badge.svg" alt="" /></div>
            <div id="wizardAnimation" class="wizardAvatar idleAnimation">
              <span></span>
              <span></span>
            </div>
          </div>

          <h3 *ngIf="hasMetNatashaFirstTime" [ngClass]="{'hideThis' : ((hasWelcomePopupAppeared() || hasUserLandedOnNatashaAgain()) && (phaseService.selectedFeatures.length > 0)) || (shouldHideWelcomeMessage)}">
            <span [ngClass]="{'msgAnimation' : welcomeMsgAnimation}">Hello <ng-container *ngIf="dataService.user">{{dataService.user.first_name}}</ng-container>!</span>
            <span [ngClass]="{'msgAnimation' : welcomeMsgAnimation}">I'm here to help you</span>
            <span [ngClass]="{'msgAnimation' : welcomeMsgAnimation}">build your app.</span></h3>
          <h3 *ngIf="!hasMetNatashaFirstTime" [ngClass]="{'hideThis' : ((hasWelcomePopupAppeared() || hasUserLandedOnNatashaAgain()) && (phaseService.selectedFeatures.length > 0)) || (shouldHideWelcomeMessage)}">
            <span [ngClass]="{'msgAnimation' : welcomeMsgAnimation}">Hello again, <ng-container *ngIf="dataService.user">{{dataService.user.first_name}}</ng-container>!</span>
            <span [ngClass]="{'msgAnimation' : welcomeMsgAnimation}">Remember me? I'm Natasha, the AI in Builder.ai</span>
            <span [ngClass]="{'msgAnimation' : welcomeMsgAnimation}">Now, let's build your app...</span></h3>

          <!--<div class="natashaBioBar" [ngClass]="{'hideThis' : ((hasWelcomePopupAppeared() || hasUserLandedOnNatashaAgain()) && (phaseService.selectedFeatures.length > 0)) || (shouldHideWelcomeMessage), 'bioAnimation' : welcomeMsgAnimation}">
            <ul>
              <li><span>NAME</span> Natasha</li>
              <li><span>SEX</span> AI (She)</li>
              <li><span>AGE</span> 1 month</li>
              <li><span>LOCATION</span> The internet</li>
            </ul>
          </div>-->
      </div>
      <!--Wizard part close-->

      <div class="showChatHistory" *ngIf="phaseService.isCollapseTemplateHistory" (click)="showTemplateHistory()">
        <span>Show chat history</span>
        <span>Chat history</span>
      </div>

      <!--content part start-->
      <div class="rightContent" [ngClass]="{'paddingUpdate' : phaseService.isCollapseTemplateHistory}">
        <div class="natashaTemplateHistory" *ngIf="!phaseService.isCollapseTemplateHistory">
          <ng-container *ngFor="let itemkey of objectKeys(homeTemplateService.tempateNatashaChatList)">
            <div class="msgContainer animatedRow" [ngClass]="{'userInput' : getTypeOfNatashaGroupElement(itemkey, homeTemplateService.tempateNatashaChatList) === 'user'}">
              <div class="avatarHolder" *ngIf="getTypeOfNatashaGroupElement(itemkey, homeTemplateService.tempateNatashaChatList) === 'natasha'">
                <div id="{{'wizardAnimation' + itemkey}}" class="wizardAvatar idleAnimation">
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div class="avatarHolder" *ngIf="getTypeOfNatashaGroupElement(itemkey, homeTemplateService.tempateNatashaChatList) === 'user'">
                <div class="userAvatar" [ngStyle]="{'background': dataService.natashaAvatarBackground}">
                  {{dataService.natashaAvatarInitials}}
                </div>
              </div>
              <div class="msgHolder">
                <div class="timeRow" *ngIf="getTypeOfNatashaGroupElement(itemkey, homeTemplateService.tempateNatashaChatList) === 'natasha'"><span class="nameTag">Natasha</span> <span class="timeTag">{{getCreatedDateOfFirstNatashaGroupElement(itemkey, homeTemplateService.tempateNatashaChatList)}}</span></div>
                <div class="timeRow" *ngIf="getTypeOfNatashaGroupElement(itemkey, homeTemplateService.tempateNatashaChatList) === 'user'"><span class="nameTag" [ngStyle]="{'color': dataService.natashaAvatarChatHandle}">Me</span> <span class="timeTag">{{getCreatedDateOfFirstNatashaGroupElement(itemkey, homeTemplateService.tempateNatashaChatList)}}</span></div>
                <ng-container *ngFor="let chat of homeTemplateService.tempateNatashaChatList[itemkey]">
                  <ng-container *ngIf="chat.templateChatText">
                    <div class="setBg">
                      <div class="textArea">{{chat.templateChatText}}</div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>

        <ng-container *ngFor="let itemkey of objectKeys(chatsTobeShownOnUI)">
          <div class="msgContainer animatedRow" [ngClass]="{'userInput' : getTypeOfNatashaGroupElement(itemkey, chatsTobeShownOnUI) === 'user'}">
            <div class="avatarHolder" *ngIf="getTypeOfNatashaGroupElement(itemkey, chatsTobeShownOnUI) === 'natasha'">
              <div id="{{'wizardAnimation' + itemkey}}" class="wizardAvatar idleAnimation">
                <span></span>
                <span></span>
              </div>
            </div>
            <div class="avatarHolder" *ngIf="getTypeOfNatashaGroupElement(itemkey, chatsTobeShownOnUI) === 'user'">
              <div class="userAvatar" [ngStyle]="{'background': dataService.natashaAvatarBackground}">
                {{dataService.natashaAvatarInitials}}
              </div>
            </div>

            <div class="msgHolder">
              <div class="timeRow" *ngIf="getTypeOfNatashaGroupElement(itemkey, chatsTobeShownOnUI) === 'natasha'"><span class="nameTag">Natasha</span> <span class="timeTag">{{getCreatedDateOfFirstNatashaGroupElement(itemkey, chatsTobeShownOnUI)}}</span></div>
              <div class="timeRow" *ngIf="getTypeOfNatashaGroupElement(itemkey, chatsTobeShownOnUI) === 'user'"><span class="nameTag" [ngStyle]="{'color': dataService.natashaAvatarChatHandle}">Me</span> <span class="timeTag">{{getCreatedDateOfFirstNatashaGroupElement(itemkey, chatsTobeShownOnUI)}}</span></div>

              <ng-container *ngFor="let chat of chatsTobeShownOnUI[itemkey]">
                <ng-container *ngIf="!chat.showLoader">

                  <!-- <ng-container *ngIf="chat.templateChatText">
                    <div class="setBg">
                      <div class="textArea">{{chat.templateChatText}}</div>
                    </div>
                  </ng-container> -->

                  <ng-container *ngIf="natashaEditModeAlreadyAddedBuildCardFeaturesCondition(chat)">
                    <div class="setBg" *ngFor="let feature of chat.recommendedFeaturesHistory; let i = index">
                      <div class="textArea">You have just added <strong>{{feature.record_text}}</strong> from my recommendations.</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaMessageCondition1(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                      <div class="textArea">{{chat.text2}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaMessageCondition2(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaMessageCondition3(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                      <button type="button" class="tutorialButton" (click)="closeWelcomePopup('showTutorialFromChatbot')">View tutorial</button>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaMessageCondition4(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaMessageCondition5(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                      <div class="textArea">{{chat.text2}}</div>
                      <button type="button" class="tutorialButton" (click)="closeWelcomePopup('showTutorialFromChatbot')">View tutorial</button>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaMessageCondition6(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                      <div class="textArea">{{chat.text2}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaStartFromScratchCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaMessageCondition8(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text}}</div>
                    </div>
                    <div class="recommendedSlider animatedRow" *ngIf="chat.shouldShowRecommendedFeatures">
                      <div class="heightSet" id="{{'carousalAnimate'+chat.sliderIndex}}" (mouseleave)="resetSliderHolderPosition($event)">
                        <div class="sliderHolder">
                          <drag-scroll snap-duration="200" (reachesLeftBound)="leftBoundStat($event,'recommendedFeatureSlider'+chat.sliderIndex, chat.sliderIndex)"
                                  (reachesRightBound)="rightBoundStat($event,'recommendedFeatureSlider'+chat.sliderIndex, chat.sliderIndex)" #navRecommendedFeaturesSlider>

                              <div class="featureCardBox" id = 'featureCardBox{{feature.id}}{{j}}' *ngFor="let feature of chat.recommendedFeatures; let j = index" drag-scroll-item  [ngClass]="{'webView' : selectedPreviewPlatform === 'web', 'custom-disabled': feature.disabled}">
                                <div class="featureCardInner">
                                  <div class="featureIcon">
                                    <img [src]="utilityService.addHttps(getPreviewOfFeature(j, true, chat))" alt="">
                                  </div>
                                  <div class="featureDetail">
                                    <h3>{{feature.title}}</h3>
                                    <h4>{{feature.feature_bundle_name}}</h4>
                                    <ng-container *ngIf="phaseService.featureData">
                                      <p>{{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(feature) ? getFeaturePrice(feature) : feature?.breakup_price | number : '1.2-2')}}</p>
                                      <p *ngIf="!phaseService.isStoreFeatureRequest">
                                        {{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{(chekIfFeatureIsInSelected(feature) ? feature?.duration : feature?.breakup_duration) | number : '1.1-1'}} {{getWeeksAndDaysText(feature)}}
                                      </p>
                                    </ng-container>

                                    <div class="toolButtons">
                                      <button type="button" class="viewButton" [ngClass]="{'active': checkIfPreviewed(feature)}" (click)="previewSelected(true, feature)" (click)="handleMouseEnter(j, true, feature, $event, chat)"><em class="icon-eye"></em></button>
                                      <button type="button" *ngIf="!feature.selected" class="addButton" (click)="addRecommendedFeaturesFromNatashaBot(feature, chat, '', $event, j)"><em class="icon-plus"></em></button>
                                      <button type="button" *ngIf="feature.selected && chat.isFromNatashaSearch" class="removeButton" (click)="changeFocusedFeature(feature)"><em class="icon-delete"></em></button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                          </drag-scroll>
                          <button class="dragbutton leftArrow" [disabled]="leftNavDisabled" type="button"
                                  (click)="moveLeft('recommendedFeatureSlider'+chat.sliderIndex, chat.sliderIndex)"><em class="icon-prev"></em></button>
                          <button class="dragbutton rightArrow" [disabled]="rightNavDisabled" type="button"
                                  (click)="moveRight('recommendedFeatureSlider'+chat.sliderIndex, chat.sliderIndex)"
                                  *ngIf="chat.recommendedFeatures.length > 3"><em class="icon-next"></em></button>
                        </div>
                      </div>

                      <div class="actionButton" *ngIf="chat.shouldShowActionButtons">
                        <button *ngIf="!chat.isFromNatashaSearch" type="button" (click)="addAllRecommendedFeatures(chat)">Add {{chat.recommendedFeatures.length}} {{chat.recommendedFeatures.length === 1? 'feature': 'features'}}</button>
                        <button *ngIf="!chat.isFromNatashaSearch" type="button" (click)="natashaMultipleFeatureRejected(chat)">Not Interested</button>
                        <button *ngIf="chat.isFromNatashaSearch" type="button" (click)="natashaSearchFeaturesRejected(chat)">Not Interested</button>
                      </div>
                    </div>
                  </ng-container>

                  <div class="notifyLoopRow" *ngIf="natashaMessageCondition9(chat)">
                    <div class="setBg">
                      <div class="textArea">You have just added <strong>{{chat.record_text}}</strong> from my recommendations.</div>
                    </div>

                    <div class="setBg">
                      <div class="textAreaNotify">
                        <div class="imgIcon"><img src="../../../../assets/images/information-icon.svg" alt="" /></div>
                        <div class="notifyText">Users who had <strong>{{chat.record_text}}</strong> in their shopping cart also added <strong>{{recommendedFeatures[0].title}}</strong></div>
                      </div>
                    </div>
                  </div>

                  <ng-container *ngIf="natashaMessageCondition11(chat) && !chat.isResponse">
                    <div class="setBg">
                      <div class="textArea"><pre>{{chat.input}}</pre></div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaMessageCondition11(chat) && chat.isResponse">
                    <ng-container *ngIf="(chat.resultRecords.length > 0) && !chat.showLoader">
                      <div class="setBg">
                        <div class="textArea">Sorry, I'm in my infancy, I can't do that yet.<br>
                          But I'm 1 month old and I can already give you suggestions!</div>

                        <div class="textArea">Your top {{chat.resultRecords.length}} results from our FAQs:</div>

                        <div class="faqListResult">
                          <ul>
                            <ng-container *ngFor="let record of chat.resultRecords; let j = index">
                              <li (click)="showResultDetail(record.id, chat.resultRecords)">{{record.title}} <em class="icon-rightarrownew"></em></li>
                            </ng-container>
                          </ul>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="(chat.resultRecords.length === 0) && !chat.showLoader">
                      <div class="setBg">
                        <div class="textArea">
                          Ok, I admit it, you humans understand each other better than me. Try this one (they should be able to help you).
                        </div>
                        <div class="actionButton fullView">
                          <button type="button" (click)="showInstantCall()">Instant call</button>
                          <button type="button" (click)="showScheduleCall()">Schedule a call</button>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="natashaMessageCondition12(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaBundleQuestionCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.questionText}}</div>
                      <div class="actionButton" *ngIf="chat.shouldShowActionButtons">
                        <button type="button" (click)="natashaBundleQuestionAccepted(chat)">Yes</button>
                        <button type="button" (click)="natashaBundleQuestionRejected(chat)">No</button>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaSingleFeatureCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.questionText}}</div>
                    </div>
                    <div *ngIf="chat.shouldShowSingleRecommendedFeature">
                      <div class="singlefeatureCard" *ngFor="let feature of chat.recommendedFeatures; let j = index" [ngClass]="{'webView' : selectedPreviewPlatform === 'web', 'active': feature.selected, 'custom-disabled': feature.disabled}">
                        <div class="featureIcon">
                          <img [src]="utilityService.addHttps(getPreviewOfFeature(j, true, chat))" alt="">
                        </div>
                        <div class="featureDetail">
                          <h3>{{feature.title}}</h3>
                          <h4>{{feature.feature_bundle_name}}</h4>
                          <ng-container *ngIf="phaseService.featureData">
                            <p>{{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(feature) ? getFeaturePrice(feature) : feature?.breakup_price | number : '1.2-2')}}</p>
                            <p *ngIf="!phaseService.isStoreFeatureRequest">
                              {{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{(chekIfFeatureIsInSelected(feature) ? feature?.duration : feature?.breakup_duration) | number : '1.1-1'}} {{getWeeksAndDaysText(feature)}}
                            </p>
                          </ng-container>

                          <div class="toolButtons">
                            <button type="button" class="viewButton" [ngClass]="{'active': checkIfPreviewed(feature)}" (click)="previewSelected(true, feature)" (click)="handleMouseEnter(j, true, feature, $event, chat)"><em class="icon-eye"></em></button>
                            <button type="button" *ngIf="!feature.selected" class="addButton" (click)="addRecommendedFeaturesFromNatashaBot(feature, chat, true)"><em class="icon-plus"></em></button>
                            <button type="button" *ngIf="feature.selected && chat.isFromNatashaSearch" class="removeButton" (click)="changeFocusedFeature(feature)"><em class="icon-delete"></em></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="actionButton" *ngIf="chat.shouldShowActionButtons">
                      <button type="button" *ngIf="!chat.recommendedFeatures[0].selected" (click)="addRecommendedFeaturesFromNatashaBot(chat.recommendedFeatures[0], chat, true)">Add Feature</button>
                      <button type="button" *ngIf="!chat.isFromNatashaSearch" (click)="natashaSingleFeatureRejected(chat)">Not Interested</button>
                      <button type="button" *ngIf="chat.recommendedFeatures[0].selected && chat.isFromNatashaSearch" (click)="changeFocusedFeature(chat.recommendedFeatures[0])">Remove Feature</button>
                      <button type="button" *ngIf="chat.isFromNatashaSearch" (click)="natashaSearchFeaturesRejected(chat)">Not Interested</button>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaMultipleFeaturesCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.bundleResponseText}}</div>
                    </div>

                    <div class="setBg" *ngIf="chat.recommendedFeatures && (chat.recommendedFeatures.length > 0)">
                      <div class="textAreaNotify">
                        <div class="imgIcon"><img src="../../../../assets/images/information-icon.svg" alt="" /></div>
                        <div class="notifyText">{{chat.recommendedFeatures[0]?.reason}} <strong>{{chat.recommendedFeatures[0]?.title}}.</strong></div>
                      </div>
                    </div>

                    <div class="recommendedSlider" *ngIf="chat.shouldShowRecommendedFeatures">
                      <div class="heightSet" id="{{'carousalAnimate'+chat.sliderIndex}}" (mouseleave)="resetSliderHolderPosition($event)">
                        <div class="sliderHolder">
                          <drag-scroll snap-duration="200" (reachesLeftBound)="leftBoundStat($event,'recommendedFeatureSlider'+chat.sliderIndex, chat.sliderIndex)"
                                  (reachesRightBound)="rightBoundStat($event,'recommendedFeatureSlider'+chat.sliderIndex, chat.sliderIndex)" #navRecommendedFeaturesSlider>

                              <div class="featureCardBox" id = 'featureCardBox{{feature.id}}{{j}}' *ngFor="let feature of chat.recommendedFeatures; let j = index" drag-scroll-item  [ngClass]="{'webView' : selectedPreviewPlatform === 'web', 'custom-disabled': feature.disabled}">
                                <div class="featureCardInner">
                                  <div class="featureIcon">
                                    <img [src]="utilityService.addHttps(getPreviewOfFeature(j, true, chat))" alt="">
                                  </div>
                                  <div class="featureDetail">
                                    <h3>{{feature.title}}</h3>
                                    <h4>{{feature.feature_bundle_name}}</h4>
                                    <ng-container *ngIf="phaseService.featureData">
                                      <p>{{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(feature) ? getFeaturePrice(feature) : feature?.breakup_price | number : '1.2-2')}}</p>
                                      <p *ngIf="!phaseService.isStoreFeatureRequest">
                                        {{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{(chekIfFeatureIsInSelected(feature) ? feature?.duration : feature?.breakup_duration) | number : '1.1-1'}} {{getWeeksAndDaysText(feature)}}
                                      </p>
                                    </ng-container>

                                    <div class="toolButtons">
                                      <button type="button" class="viewButton" [ngClass]="{'active': checkIfPreviewed(feature)}" (click)="previewSelected(true, feature)" (click)="handleMouseEnter(j, true, feature, null, chat)"><em class="icon-eye"></em></button>
                                      <button type="button" class="addButton" (click)="addRecommendedFeaturesFromNatashaBot(feature, chat, '', $event, j)"><em class="icon-plus"></em></button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                          </drag-scroll>
                          <button class="dragbutton leftArrow" [disabled]="leftNavDisabled" type="button"
                                  (click)="moveLeft('recommendedFeatureSlider'+chat.sliderIndex, chat.sliderIndex)"><em class="icon-prev"></em></button>
                          <button class="dragbutton rightArrow" [disabled]="rightNavDisabled" type="button"
                                  (click)="moveRight('recommendedFeatureSlider'+chat.sliderIndex, chat.sliderIndex)"
                                  *ngIf="chat.recommendedFeatures.length > 3"><em class="icon-next"></em></button>
                        </div>
                      </div>

                      <div class="actionButton" *ngIf="chat.shouldShowActionButtons">
                        <button type="button" (click)="addAllRecommendedFeatures(chat)">Add {{chat.recommendedFeatures.length}} {{chat.recommendedFeatures.length === 1? 'feature': 'features'}} </button>
                        <button type="button" (click)="natashaMultipleFeatureRejected(chat)">Not Interested</button>
                      </div>
                    </div>

                  </ng-container>

                  <ng-container *ngIf="natashaIdleQuestionCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.questionText}}</div>
                      <div class="actionButton" *ngIf="chat.shouldShowActionButtons">
                        <button type="button" (click)="natashaIdleQuestionAccepted(chat)">Yes</button>
                        <button type="button" (click)="natashaIdleQuestionRejected(chat)">No</button>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaIdleQuestionAcceptedCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaDynamicPricingMessageCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                      <div class="actionButton">
                        <button type="button" (click)="openDynamicPricingPopup()">Learn more</button>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaHelpAnswerSomeQuestionsCondition(chat)">
                    <div class="setBg">
                      <div class="textArea" >{{chat.text1}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaManualFeatureAddFirstTimeCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaAddFeatureCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaNoRecommendationCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.noRecommendationText}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaAskFeedbackCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.askFeedbackText}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaFeedbackThanksCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.feedbackThanksText}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="chat.showSearchResultDetailStatus">
                    <div class="setBg">
                      <div class="textArea">
                        <div class="resultDetail">
                          <div class="resultDetailContent" *ngIf="chat.searchResultDetail">
                            <h3>{{chat.searchResultDetail.title}}</h3>
                            <div [innerHTML]="chat.searchResultDetail.body | safeHtml"></div>
                          </div>

                          <div class="answerButton" *ngIf="chat.searchResultDetail">
                            <h3>Does this answer your question?</h3>
                            <ul>
                              <li [ngClass]="{'active': highlightNotAtAllFAQResponse(chat.searchResultDetail)}" (click)="saveFAQResponse(chat, 'not_at_all')">Not at all</li>
                              <li [ngClass]="{'active': highlightNotExactlyFAQResponse(chat.searchResultDetail)}" (click)="saveFAQResponse(chat, 'not_exactly')">Not exactly</li>
                              <li [ngClass]="{'active': highlightYesFAQResponse(chat.searchResultDetail)}" (click)="saveFAQResponse(chat, 'yes')">Yes</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="chat.showNatashaSearchFeedbackStatus">
                    <div class="setBg">
                      <div class="textArea">{{chat.natashaSearchFeedback}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="chat.customFeatureTitleStatus">
                    <div class="setBg">
                      <div class="textArea">{{chat.customFeatureTitleQuestion}}</div>
                      <div class="actionButton" *ngIf="chat.shouldShowActionButtons">
                        <button type="button" (click)="natashaBundleQuestionAccepted(chat, 'yes, lets start', null, true); startCustomFeatureFlow()">yes, lets start</button>
                        <button type="button" (click)="natashaBundleQuestionRejected(chat, 'No, thanks', null, true)">No, thanks</button>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="chat.customFeatureDescriptionStatus">
                    <div class="setBg">
                      <div class="textArea">{{chat.customFeatureDescriptionQuestion}}</div>
                    </div>
                  </ng-container>

                   <ng-container *ngIf="chat.fourMoreQuestionStatus">
                    <div class="setBg">
                      <div class="textArea">{{chat.fourMoreQuestionText}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="chat.customFeatureComplexityStatus">
                    <div class="setBg">
                      <div class="textArea">{{chat.customFeatureComplexityText}} </div>
                      <div class="actionButton featureOption">
                        <button [disabled]= "chat.disableCustomFeatureComplexityOptions" (click)="saveCustomFeatureComplexity(0, chat)">Not at all complex</button>
                        <button [disabled]= "chat.disableCustomFeatureComplexityOptions" (click)="saveCustomFeatureComplexity(1, chat)">Quite complex</button>
                        <button [disabled]= "chat.disableCustomFeatureComplexityOptions" (click)="saveCustomFeatureComplexity(2, chat)">Very complex</button>
                        <button [disabled]= "chat.disableCustomFeatureComplexityOptions" (click)="saveCustomFeatureComplexity(3, chat)">I don't know</button>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="chat.customFeatureAlgorithmStatus">
                    <div class="setBg">
                      <div class="textArea">{{chat.customFeatureAlgorithmText}}</div>
                      <div class="actionButton featureOption">
                        <button [disabled]="chat.disableCustomFeatureAlgorithmOptions" (click)="saveCustomFeatureAlgorithm(0, chat)">No</button>
                        <button [disabled]="chat.disableCustomFeatureAlgorithmOptions" (click)="saveCustomFeatureAlgorithm(1, chat)">Yes, simple algorithms</button>
                        <button [disabled]="chat.disableCustomFeatureAlgorithmOptions" (click)="saveCustomFeatureAlgorithm(2, chat)">Yes, complex algorithms</button>
                        <button [disabled]="chat.disableCustomFeatureAlgorithmOptions" (click)="saveCustomFeatureAlgorithm(3, chat)">Yes, machine learning</button>
                        <button [disabled]="chat.disableCustomFeatureAlgorithmOptions" (click)="saveCustomFeatureAlgorithm(4, chat)">Yes, augmented reality</button>
                        <button [disabled]="chat.disableCustomFeatureAlgorithmOptions" (click)="saveCustomFeatureAlgorithm(5, chat)">I don't know</button>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="chat.customFeatureUserInterfaceStatus">
                    <div class="setBg">
                      <div class="textArea">{{chat.customFeatureUserInterfaceText}}</div>
                      <div class="actionButton featureOption">
                        <button [disabled]="chat.disableCustomFeatureUserInterfaceOptions" (click)="saveCustomFeatureUserInterface(0, chat)">It's backend only</button>
                        <button [disabled]="chat.disableCustomFeatureUserInterfaceOptions" (click)="saveCustomFeatureUserInterface(1, chat)">It requires both</button>
                        <button [disabled]="chat.disableCustomFeatureUserInterfaceOptions" (click)="saveCustomFeatureUserInterface(2, chat)">I don't know</button>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="chat.customFeatureSimilarAppsStatus">
                    <div class="setBg">
                      <div class="textArea">{{chat.customFeatureSimilarAppsText}}</div>
                      <div class="actionButton featureOption">
                        <button [disabled]="chat.disableCustomFeatureAppsNotProvidedOption" (click)="customFeatureAppsNotProvided(chat)">Sorry, I don't know any</button>
                      </div>
                    </div>
                  </ng-container>

                <ng-container *ngIf="chat.customFeaturePreviewStatus">
                  <div class="setBg">
                    <div class="textArea">{{chat.customFeaturePreviewText}}</div>
                  </div>
                  <div class="singlefeatureCard">
                    <div class="featureIcon">
                      <img [src]="utilityService.addHttps(getPreviewOfFeature(0, false, null, true))" alt="">
                    </div>
                    <div class="featureDetail">
                      <h3>{{chat.customFeature.title}}</h3>
                      <h4>Estimated cost</h4>
                      <ng-container *ngIf="phaseService.featureData">
                        <p>{{ chekIfFeatureIsInSelected(chat.customFeature) ? 'from ' : '' }}{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(chat.customFeature) ? getFeaturePrice(chat.customFeature) : chat.customFeature?.breakup_price | number : '1.2-2')}}</p>
                        <p *ngIf="!phaseService.isStoreFeatureRequest">
                          {{ chekIfFeatureIsInSelected(chat.customFeature) ? 'from ' : '' }}{{(chekIfFeatureIsInSelected(chat.customFeature) ? chat.customFeature?.duration : chat.customFeature?.breakup_duration) | number : '1.1-1'}} {{getWeeksAndDaysText(chat.customFeature)}}
                        </p>
                      </ng-container>

                      <div class="toolButtons">
                        <!-- <button type="button" class="viewButton" [ngClass]="{'active': checkIfPreviewed(chat.customFeature)}" (click)="previewSelected()" (click)="handleMouseEnter(0, false, chat.customFeature, $event)"><em class="icon-eye"></em></button> -->
                        <button [disabled]="!chat.shouldShowActionButtons" type="button" class="addButton" (click)="addCustomFeature(chat)"><em class="icon-plus"></em></button>
                      </div>
                    </div>
                  </div>
                  <div class="actionButton" *ngIf="chat.shouldShowActionButtons">
                    <button type="button" (click)="addCustomFeature(chat)">Yes please add it</button>
                    <button type="button" (click)="discardCustomFeature(chat)">No thank you</button>
                  </div>
                </ng-container>

                <ng-container *ngIf="chat.customFeatureAddedStatus">
                  <div class="setBg">
                    <div class="textArea">{{chat.customFeatureAddedText}} </div>
                  </div>
                </ng-container>


                </ng-container>

                <ng-container *ngIf="chat.showLoader">
                  <div class="setBg">
                    <div class="textArea" >
                      <div class="threeDotLoader"><div class="dot-flashing"></div></div>
                    </div>
                  </div>
                </ng-container>

              </ng-container>

            </div>
          </div>
        </ng-container>

      </div>
      <!--content part close-->

    </perfect-scrollbar>

    </div>

    <div class="natashaInfoPannel" *ngIf="shouldOpenNatashaInfo">
      <perfect-scrollbar class="myScrollbar" [config]="PERFECT_SCROLLBAR_CONFIG">
        <div class="closeButton" (click)="showMainNatashaScreen()"><span class="icon-cancel"></span></div>

      <div>
        <div class="natashaInfoTop">
          <h3 class="title">Hi, I’m Natasha!</h3>
          <div class="natashaInforBx">
            <div class="leftBx">
              <div class="wizardAvatar">
                <span></span>
                <span></span>
              </div>
            </div>
            <div class="rightBx">
              <ul>
                <li><span class="protitle">Name</span> <span> Natasha</span></li>
                <li><span class="protitle">Age</span> <span> 1 month </span></li>
                <li><span class="protitle">Sex</span> <span> AI(She) </span></li>
                <li><span class="protitle">Location</span> <span> The Internet</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="contentBx">
          <h4 class="title">Nice to meet you, I’m an AI called Natasha. Need a hand? I can help you turn your app idea into a fantastic product. That’s the whole point of my existence (yeah, I can get deep too), so let’s get started.
          </h4>

            <div class="infoListing">
              <h5>What I’ve learnt so far (v1.0 release notes)</h5>
              <ul>
                <li>- Feature suggestions based on shopping cart analysis</li>
                <li>- FAQ search based on what you type</li>
                <li>- Suggestions about features and bundles</li>
              </ul>
            </div>
            <div class="infoListing">
              <h5>My to-do list (v2.0 COMING SOON)</h5>
              <ul>
                <li>- Custom responses to give you lots more info</li>
                <li>- Alternatives when you remove a feature</li>
                <li>- Shopping cart efficiency check</li>
              </ul>
            </div>
        </div>
      </div>
      </perfect-scrollbar>
    </div>

    <!--Ask anything section start-->
    <div class="askanythingBox">
      <div class="searchBoxSection" *ngIf="!shouldNatashaSearchTakeYesNoResponse() && !shouldNatashaSearchTakeFeedbackResponse() && !phaseService.isShowCustomFeatureTitleQuestion
        && !phaseService.isShowCustomFeatureDescriptionQuestion && !phaseService.isShowCustomFeatureSimilarAppsQuestion">
        <input type="text" class="searchBar" placeholder="Ask me anything…" (keyup.enter)="searchNatashaFAQs()" [(ngModel)]="searchFAQInput">
        <button type="button" class="searchButton" (click)="searchNatashaFAQs()"><em class="icon-asking"></em></button>
      </div>

      <div class="searchBoxSection" *ngIf="shouldNatashaSearchTakeYesNoResponse() && !phaseService.isShowCustomFeatureTitleQuestion && !phaseService.isShowCustomFeatureDescriptionQuestion
        && !phaseService.isShowCustomFeatureSimilarAppsQuestion">
        <input type="text" class="searchBar" placeholder="You can type your answer here…" (keyup.enter)="showNatashaYesNoQuestionResponse()" [(ngModel)]="natashaYesNoQuestionResponse">
        <button type="button" class="searchButton" (click)="showNatashaYesNoQuestionResponse()"><em class="icon-asking"></em></button>
      </div>

      <div class="searchBoxSection" *ngIf="shouldNatashaSearchTakeFeedbackResponse() && !phaseService.isShowCustomFeatureTitleQuestion && !phaseService.isShowCustomFeatureDescriptionQuestion
        && !phaseService.isShowCustomFeatureSimilarAppsQuestion">
        <input type="text" class="searchBar" placeholder="Share your feedback…" (keyup.enter)="showNatashaFeedbackResponse()" [(ngModel)]="natashaFeedbackResponse">
        <button type="button" class="searchButton" (click)="showNatashaFeedbackResponse()"><em class="icon-asking"></em></button>
      </div>

      <div class="searchBoxSection" *ngIf="phaseService.isShowCustomFeatureTitleQuestion">
        <input type="text" class="searchBar" placeholder="Ask me anything…" maxlength="100" (keyup.enter)="saveCustomFeatureTitleResponse()" [(ngModel)]="customFeatureTitle">
        <button type="button" class="searchButton" (click)="saveCustomFeatureTitleResponse()"><em class="icon-asking"></em></button>
      </div>

      <div class="searchBoxSection" *ngIf="phaseService.isShowCustomFeatureDescriptionQuestion">
        <input type="text" class="searchBar" placeholder="Ask me anything…" maxlength="1000" (keyup.enter)="saveCustomFeatureDescriptionResponse()" [(ngModel)]="customFeatureDescription">
        <button type="button" class="searchButton" (click)="saveCustomFeatureDescriptionResponse()"><em class="icon-asking"></em></button>
      </div>

      <div class="searchBoxSection" *ngIf="phaseService.isShowCustomFeatureSimilarAppsQuestion">
        <input type="text" class="searchBar" placeholder="Ask me anything…" (keyup.enter)="saveCustomFeatureSimilarAppsResponse()" [(ngModel)]="customFeatureSimilarApps">
        <button type="button" class="searchButton" (click)="saveCustomFeatureSimilarAppsResponse()"><em class="icon-asking"></em></button>
      </div>

    </div>
    <!--Ask anything section close-->

    <!--New message notification button start-->
    <div class="newMessage" *ngIf="showNewNatashaMessageButton" (click)="showNewNatashaMessages()"><em class="icon-rightarrow"></em> You have a new message</div>
    <!--New message notification button close-->


  </div>
  <!--Right part close-->


</div>
</ng-container>

<ng-container *ngIf="innerWidth <= 768">
<div class="featureStudiomobile">
  <!--mobile notification tooltips start-->
  <div class="pageNotification" [ngClass]="{'active' : showFeatureAddedToolTip}">
    <em class="icon-tick"></em> <p>Feature added to your Shopping cart</p>
  </div>

  <div class="pageNotification" [ngClass]="{'active' : showFeatureRemovedTooltip}">
    <em class="icon-tick"></em> <p>Feature removed from your Shopping cart</p>
  </div>

  <div class="pageNotification" [ngClass]="{'active' : showRemoveAllFeatureTooltip}" *ngIf="showRemoveAllFeatureTooltip">
    <em class="icon-tick"></em> <p>All features removed from your Shopping cart</p>
  </div>
  <!--mobile notification tooltips close-->

  <!--feature selection box start-->
  <div class="featureSelectionBox">
    <!--toolbar start-->
    <div class="topToolbar">
      <button type="button" class="actionButton" (click)="openOrClosePlatformPopup()">
        <em *ngIf="selectedPreviewPlatform === 'android'" class="icon-mobiledevice"></em>
        <em *ngIf="selectedPreviewPlatform === 'web'" class="icon-desktopdevice"></em>
      </button>
      <button type="button" class="actionButton addFeature" (click)="addNewFeature()"><em class="icon-plus"></em> Custom feature</button>
      <button type="button" class="actionButton" (click)="toggleFilterPopup()"><em class="icon-fillter"></em> <div class="filterNotify" *ngIf="getAppliedFiltersCount()">{{getAppliedFiltersCount()}}</div></button>
      <button type="button" class="actionButton searchButton" (click)="mobileSearch()"><em class="icon-magnifyglass"></em></button>

      <div class="mobileSearch" [ngClass]="{'active': mobileSearchBox}">
        <form>
          <input type="text" placeholder="Search for a feature" (keyup)="searchFilter($event)"
                 (focus)="toggleRelatedSearches(true)" #searchInput autocomplete="off" name="search" [(ngModel)]="searchQuery">
          <button class="searchButton" type="button" (click)="searchInput.focus();toggleSearchInput()"><em class="icon-magnifyglass"></em></button>
          <button class="closeButton" type="button" (click)="mobileSearch()"><em class="icon-iconcross"></em></button>
        </form>
      </div>
    </div>
    <!--toolbar close-->

    <!--feature group set start-->
    <div class="groupsetList" *ngIf="!searchView && !showFilter && getFeatureBundles().length > 0">
      <ng-container *ngIf="getFeatureBundles().length > 0">
        <ng-container *ngFor="let bundle of getFeatureBundles(); let i = index">
          <div class="groupCategory" *ngIf="focusedBundleIndex === i">
            <div class="groupCategoryTab" (click)="mobileFeatureSelect('collapse')">
              <div class="groupImg"><img [src]="bundle.background_url ? utilityService.addHttps(bundle.background_url) : './../../../assets/images/feature_background.png'" alt=""/></div>
              <div class="groupName">{{bundle.title | titlecase}}</div>
              <div class="groupCount">{{getSelectedFeaturesCount(bundle)}}/{{getFeatureCountInBundle(bundle)}}</div>
              <div class="toggleIcon"><em class="icon-next"></em></div>
            </div>
          </div>

          <div class="groupFeatureList" *ngIf="focusedBundleIndex === i">
            <ul *ngIf="!areBundleFeaturesLoading">
              <li *ngFor="let feature of getFeaturesFromBundle(bundle); let j = index" [ngClass]="{'active': feature.selected, 'preview-active': checkIfPreviewed(feature), 'featureDisabled': feature.disabled}">
                <div class="featureIcon" *ngIf="feature.icon">
                  <img [src]="feature.icon?(phaseService.getUrl(feature.icon) | safeUrl) : './../../../assets/images/featureDefault.png'" alt=""/>
                </div>
                <div class="featureIcon" *ngIf="!feature.icon">
                  <img [src]="'./../../../assets/images/featureDefault.png'" alt=""/>
                </div>
                <div class="featureDetail">
                  <h3>{{feature.title}}</h3>
                  <ng-container *ngIf="phaseService.featureData">
                    <p>{{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(feature) ? getFeaturePrice(feature) : feature?.breakup_price | number : '1.2-2')}}</p>
                    <p *ngIf="!phaseService.isStoreFeatureRequest">
                      {{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{(chekIfFeatureIsInSelected(feature) ? feature?.duration : feature?.breakup_duration) | number : '1.1-1'}} {{getWeeksAndDaysText(feature)}}
                    </p>
                  </ng-container>
                </div>
                <div class="toolButtons">
                  <button type="button" class="viewButton" [ngClass]="{'active': checkIfPreviewed(feature)}" (click)="previewSelected()" (click)="handleMouseEnter(j, false, feature)"><em class="icon-eye"></em></button>
                  <button type="button" class="addButton" (click)="changeFocusedFeature(feature)"><em class="icon-plus"></em></button>
                  <button type="button" class="removeButton" *ngIf="!feature?.disabled" (click)="changeFocusedFeature(feature)"><em class="icon-delete"></em></button>
                </div>
              </li>
            </ul>

            <!--loading state for bundle feature start-->
            <div class="loadingState" *ngIf="areBundleFeaturesLoading">
              <div class="loadingRow"><span></span></div>
              <div class="loadingRow"><span></span></div>
              <div class="loadingRow"><span></span></div>
              <div class="loadingRow"><span></span></div>
              <div class="loadingRow"><span></span></div>
              <div class="loadingRow"><span></span></div>
              <div class="loadingRow"><span></span></div>
              <div class="loadingRow"><span></span></div>
              <div class="loadingRow"><span></span></div>
            </div>
            <!--loading state for bundle feature close-->
          </div>
          </ng-container>
      </ng-container>

      <!--filter result data not found start-->
      <div class="mobileFilterNotfound" *ngIf="getFeatureBundles().length === 0">
        <p>Your search / filters produced no results.</p>
        <button type="button" class="addfeatureButton" (click)="addNewFeature()"><em class="icon-plus"></em> <span>Custom feature</span></button>
      </div>
      <!--filter result data not found close-->
    </div>
    <!--feature group set close-->

    <!--Mobile Search Result area start-->
    <div class="mobileSearchResults" *ngIf="!areBundleFeaturesLoading && (searchView || (dataService?.filteredFeatures?.length > 0))">
      <div class="mobileSearchToolbar">
        <div class="mobileSearchCount">RESULTS <span>{{searchView ? dataService.featureList.length : dataService.filteredFeatures.length}}</span></div>
        <div class="clearSearch" (click)="clearSearch(searchView ? '' : 'ClearFilters')">{{searchView ? 'Clear search' : 'Clear filters'}}</div>
      </div>

      <!--List items box start-->
      <div class="mobileSearchItems" *ngIf="(dataService.featureList.length > 0) || (dataService.filteredFeatures.length > 0)">
        <ul>
          <li *ngFor="let feature of getSearchOrFilterFeatures(); index as m" [ngClass]="{active: feature.selected, 'featureDisabled': feature.disabled}">
            <div class="searchIcon" *ngIf="feature.icon">
              <img [src]="feature.icon?(phaseService.getUrl(feature.icon) | safeUrl) : './../../../assets/images/featureDefault.png'" alt=""/>
            </div>
            <div class="searchIcon" *ngIf="!feature.icon">
              <img [src]="'./../../../assets/images/featureDefault.png'" alt=""/>
            </div>
            <div class="searchDetail">
              <h3>{{feature.title}}</h3>
              <ng-container *ngIf="phaseService.featureData">
                <p>{{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(feature) ? getFeaturePrice(feature) : feature?.breakup_price | number : '1.2-2')}}</p>
                <p *ngIf="!phaseService.isStoreFeatureRequest">
                  {{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{(chekIfFeatureIsInSelected(feature) ? feature?.duration : feature?.breakup_duration) | number : '1.1-1'}} {{getWeeksAndDaysText(feature)}}
                </p>
              </ng-container>
            </div>
            <div class="toolButtons">
              <button type="button" class="viewButton" (click)="previewSelected()" (mouseenter)="handleMouseEnter(m, false, feature)"><em class="icon-eye"></em></button>
              <button type="button" class="addButton" (click)="changeFocusedFeature(feature)"><em class="icon-plus"></em></button>
              <button type="button" class="removeButton" *ngIf="!feature?.disabled" (click)="changeFocusedFeature(feature)"><em class="icon-delete"></em></button>
            </div>
          </li>
        </ul>
      </div>
      <!--List items box close-->

      <!--Search not found start-->
      <div class="mobileSearchNotfound" *ngIf="!filterApplied && (dataService.featureList.length === 0)">
        <p>Your search produced no results.</p>
        <button type="button" class="addfeatureButton" (click)="addNewFeature()"><em class="icon-plus"></em> <span>Custom feature</span></button>
      </div>
      <!--Search not found close-->

    </div>
    <div class="mobileSearchResults" *ngIf="filterApplied && (dataService.filteredFeatures.length === 0)">
      <div class="mobileSearchToolbar">
        <div class="mobileSearchCount">RESULTS <span>{{searchView ? dataService.featureList.length : dataService.filteredFeatures.length}}</span></div>
        <div class="clearSearch" (click)="clearSearch(searchView ? '' : 'ClearFilters')">{{searchView ? 'Clear search' : 'Clear filters'}}</div>
      </div>

      <div class="mobileSearchNotfound">
        <p>Your filters produced no results.</p>
        <button type="button" class="addfeatureButton" (click)="addNewFeature()"><em class="icon-plus"></em> <span>Custom feature</span></button>
      </div>
    </div>
    <!--Mobile Search Result area close-->

    <!--Search and filter loading state start-->
    <div class="mobileSearchFilterLoadingState" *ngIf="areBundleFeaturesLoading">
      <div class="loadingState">
        <div class="loadingHeading">
          <div class="headingName">RESULTS</div>
          <div class="fiveDotloader">
            <span></span><span></span><span></span><span></span><span></span>
          </div>
        </div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
      </div>
    </div>
    <!--Search and filter loading state close-->

    <!--natasha bottombar start-->
    <div class="natashaBottomBar" (click)="natashaMobileOpen()">

      <div class="natashaMobileWizard" [ngClass]="{'hideThis' : mainwizardDot}">
        <div class="wizardAvatar idleAnimation">
          <span></span>
          <span></span>
        </div>
        <div class="natashaNotify">{{natashaMobileUnreadMessageCount}}</div>
      </div>

      <strong>Get help with your Buildcard<sup class="tm">®</sup></strong>
      <em class="icon-upside"></em>
    </div>
    <!--natasha bottombar close-->
  </div>
  <!--feature selection box close-->

  <!--mobile natasha section start-->
  <div class="natashaMobile" [ngClass]="{'natashaCollaps' : natashaMobilePanel, 'setScrollActive' : isScrollNatashaSidebar}">
    <!--Right top part start-->
    <div class="topPart">
      <div class="expertButton" (click)="talkToExpertsClicked()">
        <ul>
          <li><img src="../../../../assets/images/experticon1.png" alt="" /></li>
        </ul>
        <span>Talk to our experts</span>
      </div>

      <div class="hamburgerMenu" [ngClass]="{'active' : dataService.natashaDropdownPanel, 'blinkThis' : blinkAnimation}">
        <div class="clickArea" (click)="natashaDropdownClick()"><em class="icon-more"></em></div>
        <div class="menuOverlay" (click)="natashaDropdownClick()"></div>
        <div class="menuDropdown">
          <ul>
            <li (click)="closeWelcomePopup('showTutorialFromChatbot')">View tutorial</li>
            <li (click)="showFeaturePageSettings()">Settings</li>
            <li (click)="showNatashaInfo()">Natasha Info</li>
          </ul>
        </div>
      </div>

      <div class="closeButton" (click)="natashaMobileClose()"><em class="icon-iconcross"></em></div>


    </div>
    <!--Right top part close-->

    <div class="natashaMiddle" #scrollMobile [scrollTop]="scrollMobile.scrollHeight">
    <perfect-scrollbar #perfectScrollNatasha class="myScrollbar" (psScrollY)="natashaSidebarScrolled($event)" [config]="PERFECT_SCROLLBAR_CONFIG">

      <!--Wizard part start-->
      <div class="wizardBox">
        <div>
          <div class="wizardDot" [ngClass]="{'hideThis' : mainwizardDot || shouldOpenNatashaInfo}">
            <div class="betaBadge"><img src="../../../../../assets/images/beta-badge.svg" alt="" /></div>
            <div id="wizardAnimation" class="wizardAvatar idleAnimation">
              <span></span>
              <span></span>
            </div>
          </div>

          <h3 *ngIf="hasMetNatashaFirstTime" [ngClass]="{'hideThis' : ((hasWelcomePopupAppeared() || hasUserLandedOnNatashaAgain()) && (phaseService.selectedFeatures.length > 0)) || (shouldHideWelcomeMessage)}">
            <span [ngClass]="{'msgAnimation' : welcomeMsgAnimation}">Hello <ng-container *ngIf="dataService.user">{{dataService.user.first_name}}</ng-container>!</span>
            <span [ngClass]="{'msgAnimation' : welcomeMsgAnimation}">I'm here to help you</span>
            <span [ngClass]="{'msgAnimation' : welcomeMsgAnimation}">build your app.</span></h3>
          <h3 *ngIf="!hasMetNatashaFirstTime" [ngClass]="{'hideThis' : ((hasWelcomePopupAppeared() || hasUserLandedOnNatashaAgain()) && (phaseService.selectedFeatures.length > 0)) || (shouldHideWelcomeMessage)}">
            <span [ngClass]="{'msgAnimation' : welcomeMsgAnimation}">Hello again, <ng-container *ngIf="dataService.user">{{dataService.user.first_name}}</ng-container>!</span>
            <span [ngClass]="{'msgAnimation' : welcomeMsgAnimation}">Remember me? I'm Natasha, the AI in Builder.ai</span>
            <span [ngClass]="{'msgAnimation' : welcomeMsgAnimation}">Now, let's build your app...</span></h3>

          <!--<div class="natashaBioBar" [ngClass]="{'hideThis' : ((hasWelcomePopupAppeared() || hasUserLandedOnNatashaAgain()) && (phaseService.selectedFeatures.length > 0)) || (shouldHideWelcomeMessage), 'bioAnimation' : welcomeMsgAnimation}">
            <ul>
              <li><span>NAME</span> Natasha</li>
              <li><span>SEX</span> AI (She)</li>
              <li><span>AGE</span> 1 month</li>
              <li><span>LOCATION</span> The internet</li>
            </ul>
          </div>-->

        </div>
      </div>
      <!--Wizard part close-->

      <div class="showChatHistory" *ngIf="phaseService.isCollapseTemplateHistory" (click)="showTemplateHistory()">
        <span>Show chat history</span>
        <span>Chat history</span>
      </div>

      <!--content part start-->
      <div class="rightContent" [ngClass]="{'paddingUpdate' : phaseService.isCollapseTemplateHistory}">
        <div class="natashaTemplateHistory" *ngIf="!phaseService.isCollapseTemplateHistory">
          <ng-container *ngFor="let itemkey of objectKeys(homeTemplateService.tempateNatashaChatList)">
            <div class="msgContainer animatedRow" [ngClass]="{'userInput' : getTypeOfNatashaGroupElement(itemkey, homeTemplateService.tempateNatashaChatList) === 'user'}">
              <div class="avatarHolder" *ngIf="getTypeOfNatashaGroupElement(itemkey, homeTemplateService.tempateNatashaChatList) === 'natasha'">
                <div id="{{'wizardAnimation' + itemkey}}" class="wizardAvatar idleAnimation">
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div class="avatarHolder" *ngIf="getTypeOfNatashaGroupElement(itemkey, homeTemplateService.tempateNatashaChatList) === 'user'">
                <div class="userAvatar" [ngStyle]="{'background': dataService.natashaAvatarBackground}">
                  {{dataService.natashaAvatarInitials}}
                </div>
              </div>
              <div class="msgHolder">
                <div class="timeRow" *ngIf="getTypeOfNatashaGroupElement(itemkey, homeTemplateService.tempateNatashaChatList) === 'natasha'"><span class="nameTag">Natasha</span> <span class="timeTag">{{getCreatedDateOfFirstNatashaGroupElement(itemkey, homeTemplateService.tempateNatashaChatList)}}</span></div>
                <div class="timeRow" *ngIf="getTypeOfNatashaGroupElement(itemkey, homeTemplateService.tempateNatashaChatList) === 'user'"><span class="nameTag" [ngStyle]="{'color': dataService.natashaAvatarChatHandle}">Me</span> <span class="timeTag">{{getCreatedDateOfFirstNatashaGroupElement(itemkey, homeTemplateService.tempateNatashaChatList)}}</span></div>
                <ng-container *ngFor="let chat of homeTemplateService.tempateNatashaChatList[itemkey]">
                  <ng-container *ngIf="chat.templateChatText">
                    <div class="setBg">
                      <div class="textArea">{{chat.templateChatText}}</div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>

        <ng-container *ngFor="let itemkey of objectKeys(chatsTobeShownOnUI)">
          <div class="msgContainer animatedRow" [ngClass]="{'userInput' : getTypeOfNatashaGroupElement(itemkey, chatsTobeShownOnUI) === 'user'}">
            <div class="avatarHolder" *ngIf="getTypeOfNatashaGroupElement(itemkey, chatsTobeShownOnUI) === 'natasha'">
              <div id="{{'wizardAnimation' + itemkey}}" class="wizardAvatar idleAnimation">
                <span></span>
                <span></span>
              </div>
            </div>
            <div class="avatarHolder" *ngIf="getTypeOfNatashaGroupElement(itemkey, chatsTobeShownOnUI) === 'user'">
              <div class="userAvatar" [ngStyle]="{'background': dataService.natashaAvatarBackground}">
                {{dataService.natashaAvatarInitials}}
              </div>
            </div>

            <div class="msgHolder">
              <div class="timeRow" *ngIf="getTypeOfNatashaGroupElement(itemkey, chatsTobeShownOnUI) === 'natasha'"><span class="nameTag">Natasha</span> <span class="timeTag">{{getCreatedDateOfFirstNatashaGroupElement(itemkey, chatsTobeShownOnUI)}}</span></div>
              <div class="timeRow" *ngIf="getTypeOfNatashaGroupElement(itemkey, chatsTobeShownOnUI) === 'user'"><span class="nameTag" [ngStyle]="{'color': dataService.natashaAvatarChatHandle}">Me</span> <span class="timeTag">{{getCreatedDateOfFirstNatashaGroupElement(itemkey, chatsTobeShownOnUI)}}</span></div>

              <ng-container *ngFor="let chat of chatsTobeShownOnUI[itemkey]">
                <ng-container *ngIf="!chat.showLoader">

                  <!-- <ng-container *ngIf="chat.templateChatText">
                    <div class="setBg">
                      <div class="textArea">{{chat.templateChatText}}</div>
                    </div>
                  </ng-container> -->

                  <ng-container *ngIf="natashaEditModeAlreadyAddedBuildCardFeaturesCondition(chat)">
                    <div class="setBg" *ngFor="let feature of chat.recommendedFeaturesHistory; let i = index">
                      <div class="textArea">You have just added <strong>{{feature.record_text}}</strong> from my recommendations.</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaMessageCondition1(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                      <div class="textArea">{{chat.text2}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaMessageCondition2(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaMessageCondition3(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                      <button type="button" class="tutorialButton" (click)="closeWelcomePopup('showTutorialFromChatbot')">View tutorial</button>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaMessageCondition4(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaMessageCondition5(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                      <div class="textArea">{{chat.text2}}</div>
                      <button type="button" class="tutorialButton" (click)="closeWelcomePopup('showTutorialFromChatbot')">View tutorial</button>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaMessageCondition6(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                      <div class="textArea">{{chat.text2}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaStartFromScratchCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaMessageCondition8(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text}}</div>
                    </div>
                    <div class="recommendedSlider animatedRow" *ngIf="chat.shouldShowRecommendedFeatures">
                      <!-- <div class="heightSet" (mouseenter) ='setSliderHolderPosition($event)' (mouseleave) ='resetSliderHolderPosition($event)'> -->
                        <div class="sliderHolder">
                          <drag-scroll snap-duration="200" (reachesLeftBound)="leftBoundStat($event,'recommendedFeatureSlider'+chat.sliderIndex, chat.sliderIndex)"
                                  (reachesRightBound)="rightBoundStat($event,'recommendedFeatureSlider'+chat.sliderIndex, chat.sliderIndex)" #navRecommendedFeaturesSlider>

                              <div class="featureCardBox" id = 'featureCardBox{{feature.id}}{{j}}' *ngFor="let feature of chat.recommendedFeatures; let j = index" drag-scroll-item  [ngClass]="{'webView' : selectedPreviewPlatform === 'web', 'custom-disabled': feature.disabled}">
                                <div class="featureCardInner">
                                  <div class="featureIcon">
                                    <img [src]="utilityService.addHttps(getPreviewOfFeature(j, true, chat))" alt="">
                                  </div>
                                  <div class="featureDetail">
                                    <h3>{{feature.title}}</h3>
                                    <h4>{{feature.feature_bundle_name}}</h4>
                                    <ng-container *ngIf="phaseService.featureData">
                                      <p>{{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(feature) ? getFeaturePrice(feature) : feature?.breakup_price | number : '1.2-2')}}</p>
                                      <p *ngIf="!phaseService.isStoreFeatureRequest">
                                        {{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{(chekIfFeatureIsInSelected(feature) ? feature?.duration : feature?.breakup_duration) | number : '1.1-1'}} {{getWeeksAndDaysText(feature)}}
                                      </p>
                                    </ng-container>

                                    <div class="toolButtons">
                                      <button type="button" class="viewButton" [ngClass]="{'active': checkIfPreviewed(feature)}" (click)="previewSelected(true, feature)" (click)="handleMouseEnter(j, true, feature, $event, chat)"><em class="icon-eye"></em></button>
                                      <button type="button" *ngIf="!feature.selected" class="addButton" (click)="addRecommendedFeaturesFromNatashaBot(feature, chat, '', $event, j)"><em class="icon-plus"></em></button>
                                      <button type="button" *ngIf="feature.selected && chat.isFromNatashaSearch" class="removeButton" (click)="changeFocusedFeature(feature)"><em class="icon-delete"></em></button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                          </drag-scroll>
                          <button class="dragbutton leftArrow" [disabled]="leftNavDisabled" type="button"
                                  (click)="moveLeft('recommendedFeatureSlider'+chat.sliderIndex, chat.sliderIndex)"><em class="icon-prev"></em></button>
                          <button class="dragbutton rightArrow" [disabled]="rightNavDisabled" type="button"
                                  (click)="moveRight('recommendedFeatureSlider'+chat.sliderIndex, chat.sliderIndex)"
                                  *ngIf="chat.recommendedFeatures.length > 3"><em class="icon-next"></em></button>
                        </div>
                      <!-- </div> -->

                      <div class="actionButton" *ngIf="chat.shouldShowActionButtons">
                        <button *ngIf="!chat.isFromNatashaSearch" type="button" (click)="addAllRecommendedFeatures(chat)">Add {{chat.recommendedFeatures.length}} {{chat.recommendedFeatures.length === 1? 'feature': 'features'}}</button>
                        <button *ngIf="!chat.isFromNatashaSearch" type="button" (click)="natashaMultipleFeatureRejected(chat)">Not Interested</button>
                        <button *ngIf="chat.isFromNatashaSearch" type="button" (click)="natashaSearchFeaturesRejected(chat)">Not Interested</button>
                      </div>
                    </div>
                  </ng-container>

                  <div class="notifyLoopRow" *ngIf="natashaMessageCondition9(chat)">
                    <div class="setBg">
                      <div class="textArea">You have just added <strong>{{chat.record_text}}</strong> from my recommendations.</div>
                    </div>

                    <div class="setBg">
                      <div class="textAreaNotify">
                        <div class="imgIcon"><img src="../../../../assets/images/information-icon.svg" alt="" /></div>
                        <div class="notifyText">Users who had <strong>{{chat.record_text}}</strong> in their shopping cart also added <strong>{{recommendedFeatures[0].title}}</strong></div>
                      </div>
                    </div>
                  </div>

                  <ng-container *ngIf="natashaMessageCondition11(chat) && !chat.isResponse">
                    <div class="setBg">
                      <div class="textArea"><pre>{{chat.input}}</pre></div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaMessageCondition11(chat) && chat.isResponse">
                    <ng-container *ngIf="(chat.resultRecords.length > 0) && !chat.showLoader">
                      <div class="setBg">
                        <div class="textArea">Sorry, I'm in my infancy, I can't do that yet.<br>
                          But I'm 1 month old and I can already give you suggestions!</div>

                        <div class="textArea">Your top {{chat.resultRecords.length}} results from our FAQs:</div>

                        <div class="faqListResult">
                          <ul>
                            <ng-container *ngFor="let record of chat.resultRecords; let j = index">
                              <li (click)="showResultDetail(record.id, chat.resultRecords)">{{record.title}} <em class="icon-rightarrownew"></em></li>
                            </ng-container>
                          </ul>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="(chat.resultRecords.length === 0) && !chat.showLoader">
                      <div class="setBg">
                        <div class="textArea">
                          Ok, I admit it, you humans understand each other better than me. Try this one (they should be able to help you).
                        </div>
                        <div class="actionButton fullView">
                          <button type="button" (click)="showInstantCall()">Instant call</button>
                          <button type="button" (click)="showScheduleCall()">Schedule a call</button>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="natashaMessageCondition12(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaBundleQuestionCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.questionText}}</div>
                      <div class="actionButton" *ngIf="chat.shouldShowActionButtons">
                        <button type="button" (click)="natashaBundleQuestionAccepted(chat)">Yes</button>
                        <button type="button" (click)="natashaBundleQuestionRejected(chat)">No</button>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaSingleFeatureCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.questionText}}</div>
                    </div>
                    <div *ngIf="chat.shouldShowSingleRecommendedFeature">
                      <div class="singlefeatureCard" *ngFor="let feature of chat.recommendedFeatures; let j = index" [ngClass]="{'webView' : selectedPreviewPlatform === 'web', 'active': feature.selected, 'custom-disabled': feature.disabled}">
                        <div class="featureIcon">
                          <img [src]="utilityService.addHttps(getPreviewOfFeature(j, true, chat))" alt="" />
                        </div>
                        <div class="featureDetail">
                          <h3>{{feature.title}}</h3>
                          <h4>{{feature.feature_bundle_name}}</h4>
                          <ng-container *ngIf="phaseService.featureData">
                            <p>{{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(feature) ? getFeaturePrice(feature) : feature?.breakup_price | number : '1.2-2')}}</p>
                            <p *ngIf="!phaseService.isStoreFeatureRequest">
                              {{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{(chekIfFeatureIsInSelected(feature) ? feature?.duration : feature?.breakup_duration) | number : '1.1-1'}} {{getWeeksAndDaysText(feature)}}
                            </p>
                          </ng-container>

                          <div class="toolButtons">
                            <button type="button" class="viewButton" [ngClass]="{'active': checkIfPreviewed(feature)}" (click)="previewSelected(true, feature)" (click)="handleMouseEnter(j, true, feature, $event, chat)"><em class="icon-eye"></em></button>
                            <button type="button" *ngIf="!feature.selected" class="addButton" (click)="addRecommendedFeaturesFromNatashaBot(feature, chat, true)"><em class="icon-plus"></em></button>
                            <button type="button" *ngIf="feature.selected && chat.isFromNatashaSearch" class="removeButton" (click)="changeFocusedFeature(feature)"><em class="icon-delete"></em></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="actionButton" *ngIf="chat.shouldShowActionButtons">
                      <button type="button" *ngIf="!chat.recommendedFeatures[0].selected" (click)="addRecommendedFeaturesFromNatashaBot(chat.recommendedFeatures[0], chat, true)">Add Feature</button>
                      <button type="button" *ngIf="!chat.isFromNatashaSearch" (click)="natashaSingleFeatureRejected(chat)">Not Interested</button>
                      <button type="button" *ngIf="chat.recommendedFeatures[0].selected && chat.isFromNatashaSearch" (click)="changeFocusedFeature(chat.recommendedFeatures[0])">Remove Feature</button>
                      <button type="button" *ngIf="chat.isFromNatashaSearch" (click)="natashaSearchFeaturesRejected(chat)">Not Interested</button>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaMultipleFeaturesCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.bundleResponseText}}</div>
                    </div>

                    <div class="setBg">
                      <div class="textAreaNotify">
                        <div class="imgIcon"><img src="../../../../assets/images/information-icon.svg" alt="" /></div>
                        <div class="notifyText">{{chat.recommendedFeatures[0]?.reason}} <strong>{{chat.recommendedFeatures[0]?.title}}.</strong></div>
                      </div>
                    </div>

                    <div class="recommendedSlider" *ngIf="chat.shouldShowRecommendedFeatures">
                      <!-- <div class="heightSet" (mouseenter) ='setSliderHolderPosition($event)' (mouseleave) ='resetSliderHolderPosition($event)'> -->
                        <div class="sliderHolder">
                          <drag-scroll snap-duration="200" (reachesLeftBound)="leftBoundStat($event,'recommendedFeatureSlider'+chat.sliderIndex, chat.sliderIndex)"
                                  (reachesRightBound)="rightBoundStat($event,'recommendedFeatureSlider'+chat.sliderIndex, chat.sliderIndex)" #navRecommendedFeaturesSlider>

                              <div class="featureCardBox" id = 'featureCardBox{{feature.id}}{{i}}'  *ngFor="let feature of chat.recommendedFeatures; let j = index" drag-scroll-item  [ngClass]="{'webView' : selectedPreviewPlatform === 'web', 'custom-disabled': feature.disabled}">
                                <div class="featureCardInner">
                                  <div class="featureIcon">
                                    <img [src]="utilityService.addHttps(getPreviewOfFeature(j, true, chat))" alt="" />
                                  </div>
                                  <div class="featureDetail">
                                    <h3>{{feature.title}}</h3>
                                    <h4>{{feature.feature_bundle_name}}</h4>
                                    <ng-container *ngIf="phaseService.featureData">
                                      <p>{{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(feature) ? getFeaturePrice(feature) : feature?.breakup_price | number : '1.2-2')}}</p>
                                      <p *ngIf="!phaseService.isStoreFeatureRequest">
                                        {{ chekIfFeatureIsInSelected(feature) ? 'from ' : '' }}{{(chekIfFeatureIsInSelected(feature) ? feature?.duration : feature?.breakup_duration) | number : '1.1-1'}} {{getWeeksAndDaysText(feature)}}
                                      </p>
                                    </ng-container>

                                    <div class="toolButtons">
                                      <button type="button" class="viewButton" [ngClass]="{'active': checkIfPreviewed(feature)}" (click)="previewSelected(true, feature)" (click)="handleMouseEnter(j, true, feature, null, chat)"><em class="icon-eye"></em></button>
                                      <button type="button" class="addButton"  (click)="addRecommendedFeaturesFromNatashaBot(feature, chat, '', $event, j)"><em class="icon-plus"></em></button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                          </drag-scroll>
                          <button class="dragbutton leftArrow" [disabled]="leftNavDisabled" type="button"
                                  (click)="moveLeft('recommendedFeatureSlider'+chat.sliderIndex, chat.sliderIndex)"><em class="icon-prev"></em></button>
                          <button class="dragbutton rightArrow" [disabled]="rightNavDisabled" type="button"
                                  (click)="moveRight('recommendedFeatureSlider'+chat.sliderIndex, chat.sliderIndex)"
                                  *ngIf="chat.recommendedFeatures.length > 3"><em class="icon-next"></em></button>
                        </div>
                      <!-- </div> -->

                      <div class="actionButton" *ngIf="chat.shouldShowActionButtons">
                        <button type="button" (click)="addAllRecommendedFeatures(chat)">Add {{chat.recommendedFeatures.length}} {{chat.recommendedFeatures.length === 1? 'feature': 'features'}} </button>
                        <button type="button" (click)="natashaMultipleFeatureRejected(chat)">Not Interested</button>
                      </div>
                    </div>

                  </ng-container>

                  <ng-container *ngIf="natashaIdleQuestionCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.questionText}}</div>
                      <div class="actionButton" *ngIf="chat.shouldShowActionButtons">
                        <button type="button" (click)="natashaIdleQuestionAccepted(chat)">Yes</button>
                        <button type="button" (click)="natashaIdleQuestionRejected(chat)">No</button>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaIdleQuestionAcceptedCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaDynamicPricingMessageCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                      <div class="actionButton">
                        <button type="button" (click)="openDynamicPricingPopup()">Learn more</button>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="checkNatashaHelpAnswerSomeQuestionsCondition(chat)">
                    <div class="setBg">
                      <div class="textArea" >{{chat.text1}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaManualFeatureAddFirstTimeCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaAddFeatureCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.text1}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaNoRecommendationCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.noRecommendationText}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaAskFeedbackCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.askFeedbackText}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="natashaFeedbackThanksCondition(chat)">
                    <div class="setBg">
                      <div class="textArea">{{chat.feedbackThanksText}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="chat.showSearchResultDetailStatus">
                    <div class="setBg">
                      <div class="textArea">
                        <div class="resultDetail">
                          <div class="resultDetailContent" *ngIf="chat.searchResultDetail">
                            <h3>{{chat.searchResultDetail.title}}</h3>
                            <div [innerHTML]="chat.searchResultDetail.body | safeHtml"></div>
                          </div>

                          <div class="answerButton" *ngIf="chat.searchResultDetail">
                            <h3>Does this answer your question?</h3>
                            <ul>
                              <li [ngClass]="{'active': highlightNotAtAllFAQResponse(chat.searchResultDetail)}" (click)="saveFAQResponse(chat, 'not_at_all')">Not at all</li>
                              <li [ngClass]="{'active': highlightNotExactlyFAQResponse(chat.searchResultDetail)}" (click)="saveFAQResponse(chat, 'not_exactly')">Not exactly</li>
                              <li [ngClass]="{'active': highlightYesFAQResponse(chat.searchResultDetail)}" (click)="saveFAQResponse(chat, 'yes')">Yes</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="chat.showNatashaSearchFeedbackStatus">
                    <div class="setBg">
                      <div class="textArea">{{chat.natashaSearchFeedback}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="chat.customFeatureTitleStatus">
                    <div class="setBg">
                      <div class="textArea">{{chat.customFeatureTitleQuestion}}</div>
                      <div class="actionButton" *ngIf="chat.shouldShowActionButtons">
                        <button type="button" (click)="natashaBundleQuestionAccepted(chat, 'yes, lets start', null, true); startCustomFeatureFlow()">yes, lets start</button>
                        <button type="button" (click)="natashaBundleQuestionRejected(chat, 'No, thanks', null, true)">No, thanks</button>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="chat.customFeatureDescriptionStatus">
                    <div class="setBg">
                      <div class="textArea">{{chat.customFeatureDescriptionQuestion}}</div>
                    </div>
                  </ng-container>

                   <ng-container *ngIf="chat.fourMoreQuestionStatus">
                    <div class="setBg">
                      <div class="textArea">{{chat.fourMoreQuestionText}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="chat.customFeatureComplexityStatus">
                    <div class="setBg">
                      <div class="textArea">{{chat.customFeatureComplexityText}} </div>
                      <div class="actionButton featureOption">
                        <button [disabled]= "chat.disableCustomFeatureComplexityOptions" (click)="saveCustomFeatureComplexity(0, chat)">Not at all complex</button>
                        <button [disabled]= "chat.disableCustomFeatureComplexityOptions" (click)="saveCustomFeatureComplexity(1, chat)">Quite complex</button>
                        <button [disabled]= "chat.disableCustomFeatureComplexityOptions" (click)="saveCustomFeatureComplexity(2, chat)">Very complex</button>
                        <button [disabled]= "chat.disableCustomFeatureComplexityOptions" (click)="saveCustomFeatureComplexity(3, chat)">I don't know</button>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="chat.customFeatureAlgorithmStatus">
                    <div class="setBg">
                      <div class="textArea">{{chat.customFeatureAlgorithmText}}</div>
                      <div class="actionButton featureOption">
                        <button [disabled]="chat.disableCustomFeatureAlgorithmOptions" (click)="saveCustomFeatureAlgorithm(0, chat)">No</button>
                        <button [disabled]="chat.disableCustomFeatureAlgorithmOptions" (click)="saveCustomFeatureAlgorithm(1, chat)">Yes, simple algorithms</button>
                        <button [disabled]="chat.disableCustomFeatureAlgorithmOptions" (click)="saveCustomFeatureAlgorithm(2, chat)">Yes, complex algorithms</button>
                        <button [disabled]="chat.disableCustomFeatureAlgorithmOptions" (click)="saveCustomFeatureAlgorithm(3, chat)">Yes, machine learning</button>
                        <button [disabled]="chat.disableCustomFeatureAlgorithmOptions" (click)="saveCustomFeatureAlgorithm(4, chat)">Yes, augmented reality</button>
                        <button [disabled]="chat.disableCustomFeatureAlgorithmOptions" (click)="saveCustomFeatureAlgorithm(5, chat)">I don't know</button>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="chat.customFeatureUserInterfaceStatus">
                    <div class="setBg">
                      <div class="textArea">{{chat.customFeatureUserInterfaceText}}</div>
                      <div class="actionButton featureOption">
                        <button [disabled]="chat.disableCustomFeatureUserInterfaceOptions" (click)="saveCustomFeatureUserInterface(0, chat)">It's backend only</button>
                        <button [disabled]="chat.disableCustomFeatureUserInterfaceOptions" (click)="saveCustomFeatureUserInterface(1, chat)">It requires both</button>
                        <button [disabled]="chat.disableCustomFeatureUserInterfaceOptions" (click)="saveCustomFeatureUserInterface(2, chat)">I don't know</button>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="chat.customFeatureSimilarAppsStatus">
                    <div class="setBg">
                      <div class="textArea">{{chat.customFeatureSimilarAppsText}}</div>
                      <div class="actionButton featureOption">
                        <button [disabled]="chat.disableCustomFeatureAppsNotProvidedOption" (click)="customFeatureAppsNotProvided(chat)">Sorry, I don't know any</button>
                      </div>
                    </div>
                  </ng-container>

                <ng-container *ngIf="chat.customFeaturePreviewStatus">
                  <div class="setBg">
                    <div class="textArea">{{chat.customFeaturePreviewText}}</div>
                  </div>
                  <div class="singlefeatureCard">
                    <div class="featureIcon">
                      <img [src]="utilityService.addHttps(getPreviewOfFeature(0, false, null, true))" alt="" />
                    </div>
                    <div class="featureDetail">
                      <h3>{{chat.customFeature.title}}</h3>
                      <h4>Estimated cost</h4>
                      <ng-container *ngIf="phaseService.featureData">
                        <p>{{ chekIfFeatureIsInSelected(chat.customFeature) ? 'from ' : '' }}{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(chat.customFeature) ? getFeaturePrice(chat.customFeature) : chat.customFeature?.breakup_price | number : '1.2-2')}}</p>
                        <p *ngIf="!phaseService.isStoreFeatureRequest">
                          {{ chekIfFeatureIsInSelected(chat.customFeature) ? 'from ' : '' }}{{(chekIfFeatureIsInSelected(chat.customFeature) ? chat.customFeature?.duration : chat.customFeature?.breakup_duration) | number : '1.1-1'}} {{getWeeksAndDaysText(chat.customFeature)}}
                        </p>
                      </ng-container>

                      <div class="toolButtons">
                        <!-- <button type="button" class="viewButton" [ngClass]="{'active': checkIfPreviewed(chat.customFeature)}" (click)="previewSelected()" (click)="handleMouseEnter(0, false, chat.customFeature, $event)"><em class="icon-eye"></em></button> -->
                        <button [disabled]="!chat.shouldShowActionButtons" type="button" class="addButton" (click)="addCustomFeature(chat)"><em class="icon-plus"></em></button>
                      </div>
                    </div>
                  </div>
                  <div class="actionButton" *ngIf="chat.shouldShowActionButtons">
                    <button type="button" (click)="addCustomFeature(chat)">Yes please add it</button>
                    <button type="button" (click)="discardCustomFeature(chat)">No thank you</button>
                  </div>
                </ng-container>

                <ng-container *ngIf="chat.customFeatureAddedStatus">
                  <div class="setBg">
                    <div class="textArea">{{chat.customFeatureAddedText}} </div>
                  </div>
                </ng-container>

                </ng-container>

                <ng-container *ngIf="chat.showLoader">
                  <div class="setBg">
                    <div class="textArea" >
                      <div class="threeDotLoader"><div class="dot-flashing"></div></div>
                    </div>
                  </div>
                </ng-container>

              </ng-container>

            </div>
          </div>
        </ng-container>

      </div>
      <!--content part close-->

    </perfect-scrollbar>

    </div>

    <!-- <div class="resultListing" *ngIf="showAllSearchResults">
      <div class="closeButton" (click)="showMainNatashaScreen()"><span class="icon-cancel"></span></div>
      <h2>Search result for "{{searchFAQInput}}"</h2>
      <ul>
        <li *ngFor="let result of searchResultFAQs; let i = index;" (click)="showClickedResult(result.id)">
          <h3>{{result.title}}</h3>
          <p>{{showHTMLParsedBodyContent(result.body)}}</p>
        </li>
      </ul>
    </div> -->

    <div class="natashaInfoPannel" *ngIf="shouldOpenNatashaInfo">
      <perfect-scrollbar class="myScrollbar" [config]="PERFECT_SCROLLBAR_CONFIG">
        <div class="closeButton" (click)="showMainNatashaScreen()"><span class="icon-cancel"></span></div>

      <div>
        <div class="natashaInfoTop">
          <h3 class="title">Hi, I’m Natasha!</h3>
          <div class="natashaInforBx">
            <div class="leftBx">
              <div class="wizardAvatar">
                <span></span>
                <span></span>
              </div>
            </div>
            <div class="rightBx">
              <ul>
                <li><span class="protitle">Name</span> <span> Natasha</span></li>
                <li><span class="protitle">Age</span> <span> 1 month </span></li>
                <li><span class="protitle">Sex</span> <span> AI(She) </span></li>
                <li><span class="protitle">Location</span> <span> The Internet</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="contentBx">
          <h4 class="title">
            Nice to meet you, I’m an AI called Natasha. Need a hand? I can help you turn your app idea into a fantastic product. That’s the whole point of my existence (yeah, I can get deep too), so let’s get started.

          </h4>
            <div class="infoListing">
              <h5>What I’ve learnt so far (v1.0 release notes)</h5>
              <ul>
                <li>- Feature suggestions based on shopping cart analysis</li>
                <li>- FAQ search based on what you type</li>
                <li>- Suggestions about features and bundles</li>
              </ul>
            </div>
            <div class="infoListing">
              <h5>My to-do list (v2.0 COMING SOON)</h5>
              <ul>
                <li>- Custom responses to give you lots more info</li>
                <li>- Alternatives when you remove a feature</li>
                <li>- Shopping cart efficiency check</li>
              </ul>
            </div>
        </div>
      </div>
      </perfect-scrollbar>
    </div>

    <!--Ask anything section start-->
    <div class="askanythingBox">
      <div class="searchBoxSection" *ngIf="!shouldNatashaSearchTakeYesNoResponse() && !shouldNatashaSearchTakeFeedbackResponse() && !phaseService.isShowCustomFeatureTitleQuestion
        && !phaseService.isShowCustomFeatureDescriptionQuestion && !phaseService.isShowCustomFeatureSimilarAppsQuestion">
        <input type="text" class="searchBar" placeholder="Ask me anything…" (keyup.enter)="searchNatashaFAQs()" [(ngModel)]="searchFAQInput">
        <button type="button" class="searchButton" (click)="searchNatashaFAQs()"><em class="icon-asking"></em></button>
      </div>

      <div class="searchBoxSection" *ngIf="shouldNatashaSearchTakeYesNoResponse() && !phaseService.isShowCustomFeatureTitleQuestion && !phaseService.isShowCustomFeatureDescriptionQuestion
        && !phaseService.isShowCustomFeatureSimilarAppsQuestion">
        <input type="text" class="searchBar" placeholder="You can type your answer here…" (keyup.enter)="showNatashaYesNoQuestionResponse()" [(ngModel)]="natashaYesNoQuestionResponse">
        <button type="button" class="searchButton" (click)="showNatashaYesNoQuestionResponse()"><em class="icon-asking"></em></button>
      </div>

      <div class="searchBoxSection" *ngIf="shouldNatashaSearchTakeFeedbackResponse() && !phaseService.isShowCustomFeatureTitleQuestion && !phaseService.isShowCustomFeatureDescriptionQuestion
        && !phaseService.isShowCustomFeatureSimilarAppsQuestion">
        <input type="text" class="searchBar" placeholder="Share your feedback…" (keyup.enter)="showNatashaFeedbackResponse()" [(ngModel)]="natashaFeedbackResponse">
        <button type="button" class="searchButton" (click)="showNatashaFeedbackResponse()"><em class="icon-asking"></em></button>
      </div>

      <div class="searchBoxSection" *ngIf="phaseService.isShowCustomFeatureTitleQuestion">
        <input type="text" class="searchBar" placeholder="Ask me anything…" maxlength="100" (keyup.enter)="saveCustomFeatureTitleResponse()" [(ngModel)]="customFeatureTitle">
        <button type="button" class="searchButton" (click)="saveCustomFeatureTitleResponse()"><em class="icon-asking"></em></button>
      </div>

      <div class="searchBoxSection" *ngIf="phaseService.isShowCustomFeatureDescriptionQuestion">
        <input type="text" class="searchBar" placeholder="Ask me anything…" maxlength="1000" (keyup.enter)="saveCustomFeatureDescriptionResponse()" [(ngModel)]="customFeatureDescription">
        <button type="button" class="searchButton" (click)="saveCustomFeatureDescriptionResponse()"><em class="icon-asking"></em></button>
      </div>

      <div class="searchBoxSection" *ngIf="phaseService.isShowCustomFeatureSimilarAppsQuestion">
        <input type="text" class="searchBar" placeholder="Ask me anything…" (keyup.enter)="saveCustomFeatureSimilarAppsResponse()" [(ngModel)]="customFeatureSimilarApps">
        <button type="button" class="searchButton" (click)="saveCustomFeatureSimilarAppsResponse()"><em class="icon-asking"></em></button>
      </div>

    </div>
    <!--Ask anything section close-->

    <!--New message notification button start-->
    <div class="newMessage" *ngIf="showNewNatashaMessageButton" (click)="showNewNatashaMessages()"><em class="icon-rightarrow"></em> You have a new message</div>
    <!--New message notification button close-->

  </div>
  <!--mobile natasha section close-->

  <!--no feature selected box start-->
  <div class="noFeatureSelected" *ngIf="getSelectedFeatures().length === 0 && !previewedFeature && mobileNoFeaturePopup">
    <div class="featureCounts">SELECTED FEATURES <span>0</span></div>

    <div class="closePopUp" (click)="mobileNoFeatureClose()"><em class="icon-cancel"></em></div>

    <div class="arrowPointer"><em class="icon-leftside"></em></div>

    <div class="noFeatureBox">
      <h3>No features selected</h3>
      <p>Close this panel and start by picking<br> features from the feature groups.</p>
    </div>
  </div>
  <!--no feature selected box close-->

  <div class="mobileCartSection" [ngClass]="{'active': mobileCartBox}" *ngIf="getSelectedFeatures().length > 0">
    <div class="mobilecartToolbar">
      <div class="cartItemCount">SELECTED FEATURES <span>{{getSelectedFeatures().length}}</span></div>
      <div class="removeCartItem" (click)="removedUsingTutorial(false);showHideRemoveAllPopup()" *ngIf="!isFeatureRequest">Remove all features</div>
      <div class="cartClose" (click)="mobileCartListBox()"><em class="icon-cancel"></em></div>
    </div>

    <div class="mobilecartListing">
      <ul>
        <li *ngFor="let item of (getSelectedFeatures() | reverse); index as i" [id]="item.id"
            [ngClass]="{active: i === focusedFeatureIndex, remove: i === removingFeatureIndex && showRemoveAnimation, 'custom-disabled': item.disabled && item.title !== 'Roles & Permissions'}"
            (click)="changeFocusedSelectedFeature(i)">
          <div class="mobileCartItemIcon">
            <img [src]="item.icon?(phaseService.getUrl(item.icon) | safeUrl) : './../../../assets/images/featureDefault.png'" alt=""/>
          </div>
          <div class="mobileCartItemDetail">
            <h3>{{item.title}}{{ item?.is_repeatable ? '(' + item?.feature_count + ')' : '' }}</h3>
            <ng-container *ngIf="phaseService.featureData">
              <p>{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(item) ? getFeaturePrice(item) : item?.breakup_price | number : '1.2-2')}}</p>
              <p>{{chekIfFeatureIsInSelected(item) ? getFeatureWeeks(item) : item?.breakup_duration | number : '1.1-1'}} {{getWeeksAndDaysText(item)}}</p>
            </ng-container>
          </div>
          <div class="toolButtons">
            <button type="button" class="viewButton" (click)="previewSelected()" (mouseenter)="handleMouseEnter(m, false, feature)"><em class="icon-eye"></em></button>
            <button type="button" class="removeButton" (click)="removeSelectedFeature($event, item, i)"><em class="icon-delete"></em></button>
          </div>
        </li>
      </ul>
    </div>

  </div>

  <!--cart total box start-->
  <div class="mobileBottomCart" [ngClass]="{'active': mobileCartBox}" (click)="mobileCartListBox()">
    <div><span>{{getSelectedFeatures().length}}</span><em class="icon-cart"></em></div>
  </div>
  <!--cart total box close-->
</div>
</ng-container>


<!--Feature page loading start-->
<div class="featurePageLoading" *ngIf="areBundlesLoading">
<!--top part loading section start-->
  <div class="featureHeaderLoading">
    <div class="logoLoad"></div>
    <div class="topRightLoad">
      <div class="breadcrumLoad"><span></span><strong></strong></div>
      <div class="userSecLoad"></div>
    </div>
  </div>
<!--top part loading section close-->

  <!--middle part loading section start-->
  <div class="featureMiddleLoading" [ngClass]="{'whenNatashaLoading': hideFeatureRightLoading}">
    <div class="featureLeftLoading">
      <div class="searchFilterLoading">
        <div class="searchBarLoading"></div>
        <div class="filterLoading"></div>
      </div>

      <!--Feature List loading section start-->
      <div class="featureListLoading">
        <div class="listLoading"></div><div class="listLoading"></div><div class="listLoading"></div><div class="listLoading"></div>
        <div class="listLoading"></div><div class="listLoading"></div><div class="listLoading"></div><div class="listLoading"></div>
        <div class="listLoading"></div><div class="listLoading"></div><div class="listLoading"></div><div class="listLoading"></div>
        <div class="listLoading"></div><div class="listLoading"></div><div class="listLoading"></div><div class="listLoading"></div>
        <div class="listLoading"></div><div class="listLoading"></div><div class="listLoading"></div><div class="listLoading"></div>
        <div class="listLoading"></div><div class="listLoading"></div><div class="listLoading"></div><div class="listLoading"></div>
      </div>
      <!--Feature List loading section close-->
    </div>

    <div class="featureCenterLoading">
      <!--Preview section loading start-->
      <div class="featurePrevLoading">
        <div class="deviceMarkLoading"></div>
        <div class="phoneHolderLoading"></div>
        <div class="discriptionloading">
          <div class="pricingLoading"></div>
          <div class="detailLoading"></div>
        </div>
      </div>
      <!--Preview section loading close-->

      <!--cart section loading start-->
      <div class="featureCartLoading">
        <div class="cartLeftLoading"></div>
        <div class="cartRightLoading"></div>
        <div class="cartitemlistingloading">
          <div class="itemboxloading">
            <div class="itemImgLoading"></div>
            <div class="itemtextloading"><span></span><strong></strong></div>
          </div>
          <div class="itemboxloading">
            <div class="itemImgLoading"></div>
            <div class="itemtextloading"><span></span><strong></strong></div>
          </div>
          <div class="itemboxloading">
            <div class="itemImgLoading"></div>
            <div class="itemtextloading"><span></span><strong></strong></div>
          </div>
          <div class="itemboxloading">
            <div class="itemImgLoading"></div>
            <div class="itemtextloading"><span></span><strong></strong></div>
          </div>
        </div>
      </div>
      <!--cart section loading close-->
    </div>

    <div class="featureRightLoading">
      <div class="rightboxloading"><span></span></div>
    </div>
  </div>
  <!--middle part loading section close-->

  <!--bottombar loading section start-->
  <div class="featureBottombarLoading">
    <div class="bottomleftloading"><span></span><span></span><span></span><span></span><strong></strong></div>
    <div class="bottomrightloading"></div>
  </div>
  <!--bottombar loading section close-->
</div>
<!--Feature page loading close-->

<!----Welcome popup start---->
<div class="commonPopUp active fadeBg" *ngIf="(phaseService.selectedFeatures.length > 0) && (innerWidth >= 769) && (hasWelcomePopupAppeared() || hasUserLandedOnNatashaAgain())">
  <div class="popHolder welcomeFeaturePopup">

    <h3 *ngIf="phaseService.selectedFeatures.length > 0">Here comes the good bit! I’ve found {{phaseService.selectedFeatures.length}} features for your idea.
    </h3>
    <div class="appIcons">
      <ng-container>
        <img *ngFor="let app of phaseService.selectedApps" src="{{app.app_builder_icon_url}}" alt="" />
      </ng-container>
    </div>
    <h4 *ngIf="phaseService.selectedFeatures.length > 0"></h4>
    <div *ngIf="phaseService.selectedFeatures.length > 0" class="tuningLoading"></div>
    <!--<button type="button" class="startButton" (click)="closeWelcomePopup()">Start</button>-->
  </div>
</div>
<!----Welcome popup close---->


<!----popup for remove locked feature confirmation start---->
<div class="commonPopUp active" *ngIf="showModalForRemovingLockFeature">
  <div class="popOverlay"></div>
  <div class="popHolder lockFeatureRemovePopup">
    <div class="closePopUp" (click)="abortFeatureRemoving()"><span class="icon-cancel"></span></div>
    <h3>"{{lockFeatureToRemove?.title}}" is essential for your idea, do you still want to remove it?</h3>
    <p>This is a locked feature, mandatory for the selected template to work.</p>
    <div class="footerButton">
      <button class="keepButton" (click)="abortFeatureRemoving(true)">No, keep it</button>
      <button class="removeButton" (click)="changeFocusedFeature(null, true)">Yes, remove it</button>
    </div>
    <div class="keepShowingModal" (click)="toggleRemoveLockFeatureModal()">Keep showing me this modal <div class="toggleButton" [ngClass]="{'nomore': noMoreRemoveLockFeatureModalgModal}"></div></div>
  </div>
</div>
<!----popup for remove locked feature confirmation close---->

<!----popup for page setting confirmation start---->
<div class="commonPopUp active" *ngIf="dataService.showSettingsPopup">
  <div class="popOverlay"></div>
  <div class="popHolder settingPopup">
    <div class="closePopUp" (click)="closeSettingsPopup()"><span class="icon-cancel"></span></div>
    <h3>Settings</h3>
    <p>Customise the display of Natasha recommendations and Builder Studio system alerts.</p>

    <!-- <h4>Natasha</h4>
    <ul>
      <li>
        <div class="labelName">Feature recommendations</div> <div (click) ="toggleFeatureRecommendationsConfirmSetting()" class="toggleButton" [ngClass]="{'nomore': !dataService.showFeatureRecommendationsConfirmModalSetting}"></div>
      </li>
    </ul> -->

    <h4>System alerts</h4>
    <ul>
      <li>
        <div class="labelName">Dynamic pricing</div> <div (click) ="toggleDynamicPricingSetting()" class="toggleButton" [ngClass]="{'nomore': !dataService.showDynamicPricingModalSetting}"></div>
      </li>
      <li>
        <div class="labelName">Feature removal confirmation</div> <div (click) ="toggleFeatureRemovalConfirmSetting()" class="toggleButton" [ngClass]="{'nomore': !dataService.showFeatureRemovalConfirmModalSetting}"></div>
      </li>
    </ul>

    <div class="footerButton">
      <button type="button" class="applyButton" (click)="applyFeaturePageSettings(true)">Apply</button>
    </div>

  </div>
</div>
<!----popup for page setting confirmation close---->


<!----popup for remove all feature confirmation start---->
<div class="commonPopUp active" *ngIf="showRemoveAll">
  <div class="popOverlay" (click)="showHideRemoveAllPopup()"></div>
  <div class="popHolder removeAllFeaturePopup">
    <div class="closePopUp" (click)="showHideRemoveAllPopup(true)"><span class="icon-cancel"></span></div>
    <h3>Are you sure you want<br/> to remove all features?</h3>
    <p>You will lose the selected template and you will have to start from scratch selecting features one by one.</p>
    <!--<p *ngIf="innerWidth <= 768">You will have to start from scratch and select every feature one by one.</p>
    <p *ngIf="innerWidth >= 769">You will lose the selected template and you will have to start from scratch selecting features one by one.</p>-->
    <div class="footerButton">
      <button class="keepButton" (click)="showHideRemoveAllPopup(false)">No, keep them</button>
      <button class="removeButton" (click)="removeAllSelections(true)">Yes, remove them</button>
    </div>

  </div>
</div>
<!----popup for remove all feature confirmation close---->

<!----popup for feature notes added start---->
<div class="commonPopUp active" *ngIf="featureNotePopup">
  <div class="popOverlay"></div>
  <div class="popHolder featureNotesPopup">
    <div class="closePopUp" (click)="featureNotes(previewedFeature)"><span class="icon-cancel"></span></div>

    <p>How do you want this to work?</p>
    <div class="featureNoteSection" [ngClass]="{'sidebarExpanded' : featureNoteExpand}">
      <div class="textareaBox">
        <h4>Add text, images and attachments to explain...</h4>
        <!-- <textarea [(ngModel)]="featureNoteText"></textarea> -->
        <div class="textEditor">
          <ckeditor [config]="editorConfig" [editor]="commonService.Editor" required (change)="checkFeatureNoteLength()"
                    [(ngModel)]="featureNoteText" data="" name="text">
          </ckeditor>
        </div>
      </div>
      <div class="featureNoteSidebar">
        <div class="sidebarIcon">
            <button type="button" (click)="featureNoteToggle()"><em class="icon-paperclip"></em></button>
        </div>
        <div class="sidebarItems">
            <div class="attachmentTab">
                <h3>Attachments</h3>
                <div class="attachmentList">
                    <div class="attachmentItem" *ngFor="let file of fileNameArray">
                        <div class="attachmentIcon"></div>
                        <div class="attachmentDetail">
                            <h4>{{file}}</h4>
                            <h6><span (click)="deleteFile(file,previewedFeature)">Delete</span> &bull; <span (click)="downloadFile(file,previewedFeature)">Download</span></h6>
                        </div>
                    </div>
                </div>
                <div class="attachmentButton">
                  <input type="file" id="attachmentFile" (change)="onFileSelected($event,previewedFeature)" (click)="$event.target.value=null" multiple>
                  <label for="attachmentFile"><span>Add Attachment</span> <em class="icon-paperclip"></em></label>
                </div>
            </div>
            <div>
              <span class="txtMsg" *ngIf="duplicateFileValidation">File already uploaded</span>
              <span class="txtMsg" *ngIf="isFileMoreThan60Mb">File can be uploaded of max 60MB</span>
           </div>
        </div>
    </div>
    </div>
    <div class="footerButton">
      <button type="button" class="doneButton" [disabled]="!enableSaveButton" (click)="setFeatureNotes(previewedFeature)">Add note</button>
      <div class="messageContainer" *ngIf="FeatureNoteValidationText">
        <span class="txtMsg" *ngIf="!enableSaveButton">{{FeatureNoteValidationText}}</span>
      </div>
    </div>
  </div>
</div>
<!----popup for feature notes added close---->

<!----popup for feature notes Edit start---->
<div class="commonPopUp" [ngClass]="{'active' : featureNoteEditPopup}">
  <div class="popOverlay"></div>
  <div class="popHolder featureNotesPopup">
    <div class="closePopUp" (click)="featureNotesEdit(previewedFeature)"><span class="icon-cancel"></span></div>
    <!-- <h3><b>{{previewedFeature?.title}}</b></h3> -->
    <p>How do you want this to work?</p>
    <div class="featureNoteSection" [ngClass]="{'sidebarExpanded' : featureNoteExpand}">
    <div class="textareaBox">
      <h4>Add text, images and attachments to explain...</h4>
      <!-- <textarea [(ngModel)]="featureNoteText"></textarea> -->
      <div class="textEditor">
        <ckeditor [config]="editorConfig" [editor]="commonService.Editor" required (change)="checkFeatureNoteLength()"
                  [(ngModel)]="featureNoteText" data="" name="text">
        </ckeditor>
      </div>
    </div>
    <div class="featureNoteSidebar">
      <div class="sidebarIcon">
          <button type="button" (click)="featureNoteToggle()"><em class="icon-paperclip"></em></button>
      </div>
      <div class="sidebarItems">
          <div class="attachmentTab">
              <h3>Attachments</h3>
              <div class="attachmentList">
                  <div class="attachmentItem" *ngFor="let file of fileNameArray">
                      <div class="attachmentIcon"></div>
                      <div class="attachmentDetail">
                          <h4>{{file}}</h4>
                          <h6><span (click)="deleteFile(file,previewedFeature)">Delete</span> &bull; <span (click)="downloadFile(file,previewedFeature)">Download</span></h6>
                      </div>
                  </div>
              </div>
              <div class="attachmentButton">
                <input type="file" id="attachmentFile" (change)="onFileSelected($event,previewedFeature)" (click)="$event.target.value=null" multiple>
                <label for="attachmentFile"><span>Add Attachment</span> <em class="icon-paperclip"></em></label>
              </div>
          </div>
          <div>
          <span class="txtMsg" *ngIf="duplicateFileValidation">File already uploaded</span>
            <span class="txtMsg" *ngIf="isFileMoreThan60Mb">File can be uploaded of max 60MB</span>
        </div>
      </div>
  </div>
  </div>
    <div class="footerButton">
      <button type="button" class="deleteButton" (click)="featureNotesDelete()">Delete note</button>
      <button type="button" class="doneButton" [disabled]="!enableSaveButton" (click)="setFeatureNotes(previewedFeature)">Save note</button>
      <div class="messageContainer" *ngIf="FeatureNoteValidationText">
        <span class="txtMsg" *ngIf="!enableSaveButton">{{FeatureNoteValidationText}}</span>
      </div>
    </div>

  </div>
</div>
<!----popup for feature notes Edit close---->

<!----popup for confirmation feature notes delete start---->
<div class="commonPopUp" [ngClass]="{'active' : featureNoteDeletePopup}">
  <div class="popOverlay"></div>
  <div class="popHolder featureNotesDeletePopup">
    <div class="closePopUp" (click)="featureNotesDelete()"><span class="icon-cancel"></span></div>

    <div class="confirmationBox">
      <div class="leftSide">
        <div class="wizardAvatar">
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="rightSide">
        <h3>Delete note</h3>
        <p>Once deleted, you will not be able to retrieve notes for '{{previewedFeature?.title}}'. Are you sure you want to proceed?</p>
        <div class="footerButton">
          <button type="button" class="keepButton" (click)="featureNotesDelete()">Keep note</button>
          <button type="button" class="deleteButton" (click)="deleteFeatureNotes(previewedFeature)">Delete note</button>
        </div>
      </div>
    </div>

  </div>
</div>
<!----popup for confirmation feature notes delete close---->


<!-- tutorial video popup start-->
<div class="commonPopUp" [ngClass]="{'active': isVideoOpened}">
  <div class="popOverlay"></div>
  <div class="popHolder youtubePopup">
    <div class="closePopUp" (click)="closeVideo()"><span class="icon-cancel"></span></div>
    <div class="playerHolder">
      <img src="assets/images/youtubeBlank.png" width="11" height="6" alt=""/>
      <iframe *ngIf="isVideoOpened" title="" width="850" height="464" src="https://www.youtube.com/embed/4V3PKB5cgBc"
              frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
    </div>
  </div>
</div>
<!-- tutorial video popup close-->


<!-- Mobile Filter popup start-->
<div class="commonPopUp" [ngClass]="{active : showFilter}" *ngIf="innerWidth <= 768">
  <div class="popOverlay"></div>
  <div class="popHolder mobileFilter">
    <div class="closePopUp" (click)="toggleFilterPopup()"><span class="icon-cancel"></span></div>
    <div class="mobileFilterSection">
      <div class="filterToolbar">
        <div class="filterCount">FILTER MENU</div>
        <div class="clearFilters" *ngIf="getAppliedFiltersCount() > 0" (click)="resetFilters()">Clear all</div>
      </div>

      <div class="filterListing">
        <div class="filterRow">
          <h4><strong>Feature cost</strong> <span *ngIf="!checkIfPriceRangeFull()" (click)="resetRangeFilters()">Clear</span></h4>
          <div class="rangeSlider">
            <ngx-slider #sliderElementPrice
                        [(value)]="rangePrice[0]"
                        [(highValue)]="rangePrice[1]"
                        [options]="optionsPrice"
                        (userChangeStart)="onPriceChange($event)"
                        (userChange)="onPriceChange($event)"
                        (userChangeEnd)="onPriceChange($event)"></ngx-slider>
            <div class="minValue">{{getCurrency().symbol}}{{getLocaledPriceForFilter(rangePrice[0] | number)}}
            </div>
            <div class="maxValue">{{getCurrency().symbol}}{{getLocaledPriceForFilter(rangePrice[1] | number)}}
            </div>
          </div>
        </div>

        <div class="filterRow">
          <h4><strong>Functionality</strong> <span *ngIf="tagFilterApplied > 0" (click)="clearFeatureFilter()">Clear</span></h4>
          <div class="customCheckbox" *ngFor="let tag of featureFilter.tags; let k = index">
            <input type="checkbox" [checked]="tag.selected" (change)="applyTagFilter(tag)" id="tag_{{k}}">
            <label for="tag_{{k}}">{{tag.title}}</label>
          </div>
        </div>
      </div>

      <button type="button" class="filterApply" (click)="filterFeatures()" [disabled]="checkIfFilterChnaged()">Apply filters</button>

    </div>
  </div>
</div>
<!-- Mobile Filter popup close-->

<!-- Mobile Feature group selection popup start-->
<div class="commonPopUp" [ngClass]="{'active': mobileFeatureGroup}" *ngIf="innerWidth <= 768">
  <div class="popOverlay"></div>
  <div class="popHolder mobileFeatureGroup">
    <div class="closePopUp" (click)="mobileFeatureSelect()"><span class="icon-cancel"></span></div>
    <h3>Select a feature group</h3>
    <div class="mobileFeatureGroupList">
      <perfect-scrollbar class="myScrollbar" [config]="PERFECT_SCROLLBAR_CONFIG">
        <ul *ngIf="!areBundlesLoading">
          <li class="active" [ngClass]="{active: focusedBundleIndex === i || bundle.selected, current: focusedBundleIndex === i}"
              *ngFor="let bundle of getFeatureBundles(); let i = index" (click)="changeFocusedBundle(bundle, i)">

              <div class="groupImg"><img [src]="bundle.background_url ? utilityService.addHttps(bundle.background_url) : './../../../assets/images/feature_background.png'" alt=""/></div>
              <div class="groupName">{{bundle.title}}</div>
              <div class="groupCount">{{getSelectedFeaturesCount(bundle)}}/{{getFeatureCountInBundle(bundle)}}</div>
              <div class="toggleIcon"><em class="icon-next"></em></div>

          </li>
        </ul>
      </perfect-scrollbar>

      <!--loading state for bundle set start-->
      <div class="loadingState" *ngIf="areBundlesLoading">
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
        <div class="loadingRow"><span></span></div>
      </div>
      <!--loading state for bundle set close-->
    </div>
  </div>
</div>
<!-- Mobile Feature group selection popup close-->

<!-- Mobile platform selection popup start-->
<div class="commonPopUp active" *ngIf="(innerWidth <= 768) && dataService.showPlatformSelectionPopup">
  <div class="popOverlay"></div>
  <div class="popHolder mobilePlatformGroup">
    <div class="closePopUp" (click)="openOrClosePlatformPopup()"><span class="icon-cancel"></span></div>
    <h3>Select a DEVICE PREVIEW</h3>

    <div class="platformListing">
      <div class="platformBox">
        <div class="platformName" [ngClass]="{active : selectedPreviewPlatform === 'android'}" (click)="toggleLayoutView('android')">
          <div class="platformIcon"><em class="icon-mobiledevice"></em></div> <span>Mobile</span> <div class="viewIcon"><em class="icon-eye"></em></div>
        </div>
      </div>

      <div class="platformBox">
        <div class="platformName" [ngClass]="{active : selectedPreviewPlatform === 'web'}" (click)="toggleLayoutView('web')">
          <div class="platformIcon"><em class="icon-desktopdevice"></em></div> <span>Web</span> <div class="viewIcon"><em class="icon-eye"></em></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile platform selection popup close-->

<div class="commonPopUp" [ngClass]="{'active': previewSelect}" *ngIf="innerWidth <= 768">
  <div class="popOverlay"></div>
  <div class="popHolder mobileFeaturePreview">
    <div class="closePopUp" (click)="previewSelect = false"><span class="icon-cancel"></span></div>

    <!--Mobile preview box start-->
    <div class="mobilePrev" *ngIf="(getSelectedFeatures().length > 0 || inHoverState) && selectedPreviewPlatform === 'android'">
      <!--Mobile screen holder section start-->
      <div class="screenHolder" *ngIf="isPreviewAvailable()">
        <img class="frame" src="../../../../assets/images/devicegreyframe.png" alt="" />
        <img class="featureImg" [src]="utilityService.addHttps(getSelectedPreview())" [ngClass]="{animation1: toggleClass, animation2: !toggleClass}" alt="" />
      </div>

      <div class="screenHolder" *ngIf="!isPreviewAvailable()">
        <img class="frame" src="../../../../assets/images/devicegreyframe.png" alt="" />
        <img class="featureImg" *ngIf="previewedFeature && previewedFeature.user_requested === false" src="./../../../assets/images/notavailable_mobile.png" alt="" />
        <img class="featureImg" *ngIf="previewedFeature && previewedFeature.user_requested === true" src="./../../../assets/images/custom_feature_mobile.png" alt="" />
      </div>
      <!--Mobile screen holder section close-->


      <!--Mobile description section start-->
      <div class="featureDescription">
        <div class="topHead">
          <h2>{{previewedFeature?.title}}</h2>
          <div class="discriptionArea" *ngIf="previewedFeature?.description">
            <div class="tooltipIcon"><em class="icon-info-circle"></em></div>
            <div class="discriptonBox">
              <p [innerHTML]="previewedFeature?.description | safeHtml"></p>
            </div>
          </div>
          <button type="button" (click)="removeSelectedFeature($event, previewedFeature, i)" *ngIf="!previewedFeature?.disabled" title="{{ previewedFeature?.selected ? 'Remove feature' : 'Add feature' }}" class="removeFeature"><em class="icon-delete"></em></button>
        </div>

        <h3>{{ previewedFeature?.feature_bundle_name }}</h3>
        <h4 *ngIf="phaseService.featureData">
          <ng-container *ngIf="phaseService.showHideBottomBarLoader">
            <div class="threeDotLoader"><div class="dot-flashing"></div></div>
          </ng-container>
          <ng-container *ngIf="!phaseService.showHideBottomBarLoader">
            {{ chekIfFeatureIsInSelected(previewedFeature) ? 'from ' : '' }}{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(previewedFeature) ? getFeaturePrice(previewedFeature) : previewedFeature?.breakup_price | number : '1.2-2')}}<br>
            <ng-container *ngIf="!phaseService.isStoreFeatureRequest">
              {{ chekIfFeatureIsInSelected(previewedFeature) ? 'from ' : '' }}{{(chekIfFeatureIsInSelected(previewedFeature) ? previewedFeature?.duration : previewedFeature?.breakup_duration) | number : '1.1-1'}} {{getWeeksAndDaysText(previewedFeature)}}
            </ng-container>
          </ng-container>
        </h4>

        <div class="toolbuttons">
          <button type="button" *ngIf="previewedFeature?.user_requested && isUnApprovedFeature(previewedFeature)" (click)="editRequestedFeature(previewedFeature, $event)" class="removeButton">Edit feature</button>
          <button type="button" *ngIf="!viewEditButton(previewedFeature) && checkIfFeaturePaid(previewedFeature) && checkIfFeatureEnabled(previewedFeature)" (click)="featureNotes(previewedFeature)" class="addNotes"><img src="../../../../assets/images/notes-tasks.svg" alt="" /> <span>Add note</span></button>
          <button type="button" *ngIf="viewEditButton(previewedFeature) && checkIfFeaturePaid(previewedFeature) && checkIfFeatureEnabled(previewedFeature)" (click)="featureNotesEdit(previewedFeature)" class="addNotes"><img src="../../../../assets/images/notes-tasks.svg" alt="" /> <span>Edit note</span></button>
        </div>

        <div class="rolesRow" *ngIf="previewedFeature?.is_repeatable && previewedFeature?.selected">
          <div class="numHead">Select user roles <em class="icon-info-circle"></em>
            <div class="tooltipInfoBox">Tell us how many different user roles your app needs. For example, the user journeys for an admin will be different from a general user.</div>
          </div>
          <div class="nunBox">
            <button type="button" (click)="IncDecFeatureCount(previewedFeature, 'decrease')" [ngClass]="{'disabled': previewedFeature?.disabled || previewedFeature?.feature_count == previewedFeature?.repeatable_count || disbaleDecreaseAfterPayment()}"><em class="icon-minus"></em></button>
            <input type="text" readonly [value]="previewedFeature?.feature_count" />
            <button type="button" (click)="IncDecFeatureCount(previewedFeature, 'increase')" [ngClass]="{'disabled': previewedFeature?.feature_count == 9}"><em class="icon-plus"></em></button>
          </div>
        </div>

      </div>
      <!--Mobile description section close-->

    </div>
    <!--Mobile preview box close-->

    <!--Web preview box start-->
    <div class="webPrev" *ngIf="(getSelectedFeatures().length > 0 || inHoverState) && selectedPreviewPlatform === 'web'">
      <!--Web screen holder section start-->
      <div class="screenHolder" *ngIf="isPreviewAvailable()">
        <img [src]="utilityService.addHttps(getSelectedPreview())" [ngClass]="{animation1: toggleClass, animation2: !toggleClass}" alt="" />
      </div>

      <div class="screenHolder" *ngIf="!isPreviewAvailable()">
        <img *ngIf="previewedFeature && previewedFeature.user_requested === false" src="./../../../assets/images/notavailable_web.png" alt="" />
        <img *ngIf="previewedFeature && previewedFeature.user_requested === true" src="./../../../assets/images/custom_feature_web.png" alt="" />
      </div>
      <!--Web screen holder section close-->

      <!--this is line break only start-->
      <div class="lineBreak"></div>
      <!--this is line break only close-->

      <!--Web description section start-->
      <div class="featureDescription">
        <div class="topHead">
          <h2>{{previewedFeature?.title}}</h2>
          <div class="discriptionArea" *ngIf="previewedFeature?.description">
            <div class="tooltipIcon"><em class="icon-info-circle"></em></div>
            <div class="discriptonBox">
              <p [innerHTML]="previewedFeature?.description | safeHtml"></p>
            </div>
          </div>
          <button type="button" (click)="removeSelectedFeature($event, previewedFeature, i)" *ngIf="!previewedFeature?.disabled" title="{{ previewedFeature?.selected ? 'Remove feature' : 'Add feature' }}" class="removeFeature"><em class="icon-delete"></em></button>
        </div>

        <h4 *ngIf="phaseService.featureData">
          <ng-container *ngIf="phaseService.showHideBottomBarLoader">
            <div class="threeDotLoader"><div class="dot-flashing"></div></div>
          </ng-container>
          <ng-container *ngIf="!phaseService.showHideBottomBarLoader">
            {{ chekIfFeatureIsInSelected(previewedFeature) ? 'from ' : '' }}{{getCurrency().symbol}}{{getLocaledPrice(chekIfFeatureIsInSelected(previewedFeature) ? getFeaturePrice(previewedFeature) : previewedFeature?.breakup_price | number : '1.2-2')}}<br>
            <ng-container *ngIf="!phaseService.isStoreFeatureRequest">
              {{ chekIfFeatureIsInSelected(previewedFeature) ? 'from ' : '' }}{{(chekIfFeatureIsInSelected(previewedFeature) ? previewedFeature?.duration : previewedFeature?.breakup_duration) | number : '1.1-1'}} {{getWeeksAndDaysText(previewedFeature)}}
            </ng-container>
          </ng-container>
        </h4>

        <button type="button" *ngIf="!viewEditButton(previewedFeature) && checkIfFeaturePaid(previewedFeature) && checkIfFeatureEnabled(previewedFeature)" (click)="featureNotes(previewedFeature)" class="addNotes"><img src="../../../../assets/images/notes-tasks.svg" alt="" /> <span>Add note</span></button>
        <button type="button" *ngIf="viewEditButton(previewedFeature) && checkIfFeaturePaid(previewedFeature) && checkIfFeatureEnabled(previewedFeature)" (click)="featureNotesEdit(previewedFeature)" class="addNotes"><img src="../../../../assets/images/notes-tasks.svg" alt="" /> <span>Edit note</span></button>

      </div>
      <!--Web description section close-->

    </div>
    <!--Web preview box close-->

  </div>
</div>



<!-- this is only for welcome popup to natasha panel wizard animation start -->
<div *ngIf="(phaseService.selectedFeatures.length > 0)" id="wizardUpdownAnimation" class="welcomeToNatasha"
[ngClass]="{'updownAnimation' : (phaseService.selectedFeatures.length > 0) && (hasWelcomePopupAppeared() || hasUserLandedOnNatashaAgain()),
            'gotoRightAnimation' : welcomeToRightSide,
            'rightToRemoveallAnimation' : rightToRemoveall,
            'removeallToRightAnimation' : removeAllToRight,
            'rightToLockAnimation' : rightToLockAnimation,
            'lockToRightAnimation' : lockToRightAnimation,
            'positionChange' : isScrollNatashaSidebar,
            'bottomToTopAnimation' : bottomToTopAnimation,
            'topToBottomAnimation' : topToBottomAnimation}">

  <div class="betaBadge" *ngIf="(phaseService.selectedFeatures.length > 0) && (innerWidth >= 769) && (hasWelcomePopupAppeared() || hasUserLandedOnNatashaAgain())"><img src="../../../../../assets/images/beta-badge.svg" alt="" /></div>

  <div id="wizardPopupAnimation" class="wizardAvatar idleAnimation">
    <span></span>
    <span></span>
  </div>
</div>
<!-- this is only for welcome popup to natasha panel wizard animation close -->

<addnewfeature *ngIf="phaseService.addnewfeature" (addObjToNatashaChats)="addinNatashChatList($event)"></addnewfeature>


<feature-maxcountpupop *ngIf="isShowMaxFeaturePopUp" (updatePopup)="hideFeatureCountPopup($event)" ></feature-maxcountpupop>
