<div class="phaseOverlay" (click)="hideLearnMore()" [ngClass]="{'active': showLearnMore()}"></div>
<div class="phaseInfo" [ngClass]="{'active': showLearnMore()}">
  <div class="phaseContent">
    <div class="closeIcon" (click)="hideLearnMore()"><em class="icon-cancel"></em></div>
    <h3>{{data[getLearnMoreIndex()].popupHeading}}</h3>
    <h4>{{data[phaseService.learnMoreIndex].content.heading}}</h4>
    <p>{{data[phaseService.learnMoreIndex].content.data}}</p>
    <div class="infoButton">
      <button type="button" *ngIf="data[phaseService.learnMoreIndex].content.documentUrl"
              (click)="viewDocument(data[phaseService.learnMoreIndex].content.documentUrl)">View document</button>
      <button type="button" *ngIf="data[phaseService.learnMoreIndex].content.documentUrl"
              (click)="downloadDocument(data[phaseService.learnMoreIndex].content.documentUrl)">Download document</button>
    </div>
  </div>



</div>
