import { Injectable } from '@angular/core';
import { HttpRequests } from '@shared/services/http-requests.service';
import { environment } from '../../../../environments/environment';
import { Constants } from '@shared/utils/constants';
import { AppDataService } from '@rootmodule/app-data.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'minimatch';
import { DataService } from '@shared/services/data.service';
import { PhaseService } from '@shared/services/phase.service';
import { CommonService } from '@shared/services/common.service';
import { Intercom } from 'ng-intercom';
import { Angulartics2Segment } from 'angulartics2/segment';
import { BuildCardModel } from '@models/build-card-model/build-card.model';
import { ApiRequests } from '@shared/services/api-requests.service';
import { Router } from '@angular/router';
import { AuthGuard } from "../../../core/root-module/auth-guard.service";
import { SpecCallModel } from "src/app/shared/shared-module/models/spec-call-model/spec-call.model";

@Injectable({
  providedIn: 'root'
})
export class HomeTemplateService {
  showTemplatesLoader = false;
  showTempScrollLoader = false;
  public currencyChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public sortScroll: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  currencyId;
  isSortScrolled = true;
  initialLoader = false;
  scrollLoader = false;
  filters = {
    category: [],
    price: []
  }
  page = 1;
  sortPage = 1;
  selectedTemplates = [];
  sortingOption;
  all_templates = [];
  activeFilters = {
    category: [],
    price: []
  }
  studio_templates = [];
  combined_templates = [];
  public notifyFilterToggle: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  searchTemplateInput: string = '';
  showSearchedTemplates = false;
  requestedTemplateList = [];
  searchedTemplates = [];
  public notifySearchToggle: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  sortedsearchedTemplates = [];
  customAddedTemplate = [];
  showNatashaPanel = false;
  showConfirmPopup = false;
  copyNatashaSearchText: string;
  showNatashaPanelMobile = false;
  templateType = 'studio_store';
  hasNatashaPopupAppeared = false;
  isTemplatePageLoaded = false;
  idleAnimation = false;
  createBuildCardPending = false;
  showMaxTempSelectionAlert = false;
  tempateNatashaChatList = {};
  isShowTemplateNatashaInitialAnimation = false;
  isShowTemplateNatashaIntroPopup = false;
  mobileTempateNatashaChatList = {};
  isBuildNowClickedFromTemplatePage = false;
  templateNatashaGroupIdentifier = '';
  templateNatashaGroupChatId = 1;
  isShowNatashaNameScreen = false;
  maintainSearchOnRefresh: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isRefreshedWithSearch = false;
  isFilterAPIPending = false;
  callType = '';
  resetSortingOptions: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public httpRequest: HttpRequests, public appDataService: AppDataService, private dataService: DataService, private authGuard: AuthGuard,
    private phaseService: PhaseService, private commonService: CommonService, public router: Router, public apiRequest: ApiRequests,
    public analyticsSegment: Angulartics2Segment, ) { }

  setSpeedForTemplates() {
    const multiplier = this.dataService.homePageData.multiplier_experiment,
      isUSDUser = (this.dataService.user?.currency?.id === 2);
    if (this.dataService.user?.enterprise_lead && multiplier && multiplier?.is_default && multiplier?.speed_id) {
      return multiplier?.speed_id;
    } else if (this.dataService.user?.enterprise_lead || isUSDUser) {
      return 1;
    } else {
      return 4;
    }
  }

  getAllTemplates(templateType?, page?, currencyId?) {
    let API = environment.API_URL + Constants.apiEndPoints.get_template;
    if (templateType) {
      API += '?template_type=' + templateType;
    }
    if (page) {
      API += (API.includes('?') ? ('&page=' + page) : ('?page=' + page));

    }
    API += '&currency_id=' + currencyId;
    API += '&speed_id=' + this.setSpeedForTemplates();
    API = this.setExperimentMultiplier(API);
    return this.httpRequest.get(API, this.appDataService.getRequestOptionArgs(null, true))
  }

  getFileteredRecord(currencyId?, filters?, sorting?, page?) {
    let API = this.prepareAPIURlForFilter(currencyId, filters, sorting, page);
    return this.httpRequest.get(API, this.appDataService.getRequestOptionArgs(null, true));
  }

  getTemplateFilters() {
    let API = environment.API_URL + Constants.apiEndPoints.template_filters;
    API += '?currency_id=' + this.currencyId
    API = this.setExperimentMultiplier(API);

    return this.httpRequest.get(API, this.appDataService.getRequestOptionArgs(null, true))
  }

  toggleOption(filter, mobile?) {
    if (!filter.active) {
      this.isFilterAPIPending = false;
    }
    const data = { filter_selected: filter.title };
    this.phaseService.trackEventForObject(this.analyticsSegment,  'templates_filtered', data);
    if (this.isFilterAPIPending) {
      return false;
    }
    filter.active = !filter.active;
    if (filter.active) {
      if (filter.id) {
        this.activeFilters.category.push(filter);
      } else {
        this.activeFilters.price.push(filter);
      }
    } else {
      if (filter.id) {
        let index = this.getFilter(filter.id)
        this.activeFilters.category.splice(index, 1)
      } else {
        let index = this.getFilterByMin(filter.min)
        this.activeFilters.price.splice(index, 1)
      }
    }
    if (!mobile) {
      this.isFilterAPIPending = true;
      this.notifyFilter();
    }
    this.templateType = 'all';
    // this.showLoader = true;
  }

  clearFilter(type, isNotRemove?) {
    if (type == 'category') {
      this.clearCategoryFilter();
    } else if (type == 'price') {
      this.clearPriceFilter();
    } else {
      this.clearCategoryFilter();
      this.clearPriceFilter();
    }
    if (!isNotRemove) {
      this.notifyFilter()
    }
  }

  clearCategoryFilter() {
    this.activeFilters.category = [];
    this.filters.category.forEach((f) => f['active'] = false)
  }

  clearPriceFilter() {
    this.activeFilters.price = [];
    this.filters.price.forEach((f) => f['active'] = false)
  }

  notifyFilter() {
    this.notifyFilterToggle.next({ filters: this.activeFilters, sorting: this.sortingOption })
  }

  getFilter(id) {
    return this.activeFilters['category'].findIndex((item) => item.id == id);
  }

  getFilterByMin(min) {
    return this.activeFilters['price'].findIndex((item) => item.min == min);
  }
  fetchTemplatesBasedOnSearch(currencyId, pageNo?) {
    let API = environment.API_URL + Constants.apiEndPoints.get_search_templates + '?search[query]=' + this.searchTemplateInput +
      '&currency_id=' + currencyId + '&template_type=' + this.templateType;
    if (pageNo) {
      API = API + '&search[page]=' + pageNo;
    }
    API = API + '&speed_id=' + this.setSpeedForTemplates();
    API = this.setExperimentMultiplier(API);
    return this.httpRequest.get(API, this.appDataService.getRequestOptionArgs())
      .pipe(map(data => data));
  }

  prepareAPIURlForFilter(currencyId?, filters?, sorting?, page?) {
    let API = environment.API_URL + Constants.apiEndPoints.get_filtered_result;
    let filterStr = '';
    if (filters) {
      if (filters.category.length > 0) {
        filters.category.forEach((cat, index) => {
          if (index == 0) {
            filterStr += "filter[category_ids][]=" + cat.id;
          } else {
            filterStr += "&filter[category_ids][]=" + cat.id;
          }

        })
      }

      if (filters.price.length > 0) {
        let min_price = Infinity;
        let max_price = 0;
        filters.price.forEach((price, index) => {

          if (price.max > max_price) {
            max_price = price.max;

          }
          if (price.min < min_price) {
            min_price = price.min;
          }
          if (!price.max) {
            max_price = Infinity;
          }
        })
        let minPriceStr = '';
        let maxPriceStr = ''
        if (min_price >= 0) {
          minPriceStr = filters.category.length > 0 ? "&filter[min_template_price]=" : "filter[min_template_price]=";
          minPriceStr += min_price;
        }
        if (max_price > 0 && max_price != Infinity) {
          maxPriceStr = (filters.category.length > 0 || minPriceStr) ? "&filter[max_template_price]=" : "filter[max_template_price]=";
          maxPriceStr += max_price;
        }
        filterStr += minPriceStr + maxPriceStr;
      }
      API += '?' + filterStr;
      if (sorting) {
        API += "&filter[sort_by]=" + this.sortingOption.sortBy + '&filter[order]=' + this.sortingOption.order;
      }
      if (currencyId) {
        API += '&currency_id=' + currencyId;
      }
      API += '&filter[page]=' + page
      API = this.setExperimentMultiplier(API);
      API = decodeURIComponent(API);
    }

    return API;
  }
  sortTemplates(currencyId, page) {
    let API = environment.API_URL + Constants.apiEndPoints.sort_template;
    if (this.sortingOption) {
      API += "?sort[sort_by]=" + this.sortingOption.sortBy + '&sort[order]=' + this.sortingOption.order;
    }
    if (currencyId) {
      API += (API.includes('?') ? '&' : '?') + 'currency_id=' + currencyId;
    }
    API += '&sort[page]=' + page;
    API += '&template_type=' + this.templateType;
    API += '&speed_id=' + this.setSpeedForTemplates();
    API = this.setExperimentMultiplier(API);
    return this.httpRequest.get(API, this.appDataService.getRequestOptionArgs(null, true))
  }

  getCurrency() {
    if (this.dataService.user) {
      return this.dataService.user.currency;
    } else {
      return this.dataService.homePageData.currency;
    }
  }

  clearSearch() {
    this.initialLoader = true;
    this.copyNatashaSearchText = '';
    this.phaseService.templateNatashaSearchText = '';
    this.clearSearchParams();
    this.clearUserInput();
  }

  clearUserInput() {
    this.searchTemplateInput = '';
  }

  clearSearchParams() {
    this.showSearchedTemplates = false;
    this.searchedTemplates = [];
  }

  notifySearch() {
    this.notifySearchToggle.next({ sorting: this.sortingOption });
  }


  getSortedSearchedRecords(currencyId) {
    let API = environment.API_URL + Constants.apiEndPoints.get_search_templates + '?search[query]=' + this.searchTemplateInput + '&currency_id=' + currencyId
      + (this.sortingOption ? ('&search[sort_by]=' + this.sortingOption.sortBy + '&search[order]=' + this.sortingOption.order) : '');
    API = API + this.setSpeedForTemplates();
    API = this.setExperimentMultiplier(API);
    return this.httpRequest.get(API, this.appDataService.getRequestOptionArgs())
      .pipe(map(data => data.data));
  }


  setExperimentMultiplier(API) {
    const multiplierExperiment = this.dataService.homePageData.multiplier_experiment;
    if (multiplierExperiment && multiplierExperiment.duration_multiplier) {
      API += '&duration_multiplier=' + multiplierExperiment.duration_multiplier;
    }
    if (multiplierExperiment && multiplierExperiment.price_multiplier) {
      API += '&price_multiplier=' + multiplierExperiment.price_multiplier;
    }
    if (multiplierExperiment && multiplierExperiment.custom_price_multiplier) {
      API += '&custom_price_multiplier=' + multiplierExperiment.custom_price_multiplier;
    }
    if(this.dataService.getUserPriceMultiplier()){
      API += '&user_price_multiplier=' +  this.dataService.getUserPriceMultiplier();
    }
    return API;
  }

  getRecommendedTemplates(searchedtext, currencyId, template_type) {
    let API = environment.API_URL + Constants.apiEndPoints.get_recommended_templates;
    if (searchedtext) {
      API += '?problem_statement=' + searchedtext;
    }
    API += '&currency_id=' + currencyId;
    API = this.setExperimentMultiplier(API);
    if (template_type == 'pro' || template_type == 'studio_store') {
      API += '&template_type=' + template_type;
    }
    return this.httpRequest.get(API, this.appDataService.getRequestOptionArgs(null, true))
  }

  selectTemplate(template, isFromPop?) {
    // this.phaseService.trackEventForObject(this.analyticsSegment, '', 'template_view_details_cta_clicked', { buildcard_type: this.templateType });


    if (template['template_type'] === 'studio_store') {
      return;
    }
    if (this.phaseService.selectedApps.length === 3 && !template.selected) {
      this.showMaxTempSelectionAlert = true;
      return;
    }
    if (isFromPop) {
      this.animateImageIcon(template)
      this.phaseService.selectedHash.application_ids = [];
    }

    if (screen.width > 380) { this.showConfirmPopup = false; }
    this.phaseService.mandatoryFeatures = [];
    this.toggleClickedItem(template, isFromPop);
    this.phaseService.toggleSelectedAppsById(template);
    this.phaseService.toggleSelectedAppid(template.id);
    this.setMandatoryFeatures();
    if (isFromPop) {
      this.phaseService.trackEventForObject(this.analyticsSegment,  'template_add_details__clicked', { user_id: this.dataService.user?.id, buildcard_type: this.templateType });
    } else if (template.selected && !isFromPop) {
      this.phaseService.trackEventForObject(this.analyticsSegment,  'template_selected', { user_id: this.dataService.user?.id, templates_name: template.title });
    } else {
      this.phaseService.trackEventForObject(this.analyticsSegment,  'template_removed', { user_id: this.dataService.user?.id, templates_name: template.title, event_trigger: 'untick template' });
    }
    this.phaseService.selectedFeatures = [].concat.apply([], this.phaseService.selectedApps.map(a => a.features));
    this.preSelectPlatforms();

  }

  animateImageIcon(template) {
    if (document.getElementById(`image${template.id}`)) {
      document.getElementById(`image${template.id}`).remove()
    };
    let imageContainer = document.querySelector(`#imageContainer${template.id}`);
    let viewportOffset = imageContainer.getBoundingClientRect();
    const imageElement = `<img src="${template.app_builder_icon_url}" id='image${template.id}' style="top: ${viewportOffset.top}px; position: fixed; max-width: 40px" alt="" />`
    imageContainer.insertAdjacentHTML('beforeend', imageElement);

    const cardHeight = window.innerHeight;
    const xDirection = 1500;
    document.getElementById(`image${template.id}`).animate([
      // keyframes
      { transform: 'rotate(45deg)' },
      { transform: `translate(-${xDirection}px, ${cardHeight * 2}px)` },
      { easing: 'ease' }
    ], {
        duration: 4000,
      });

    setTimeout(() => {
      document.getElementById(`image${template.id}`).remove();
      document.getElementById('closeTemplateModal').click();
    }, 1000);


  }

  setMandatoryFeatures() {
    this.phaseService.selectedApps.filter((psection) => {
      psection.features = psection.features['data'] ? psection.features['data'].map(feature => feature.attributes) : psection.features;
      const mandatoryFeaturesIds = this.phaseService.mandatoryFeatures.map(feature => feature.id);
      if (this.phaseService.selectedHash.application_ids.indexOf(psection.id) > -1) {
        this.phaseService.mandatoryFeatures.push(...psection.features.filter(feature => {
          if (mandatoryFeaturesIds.indexOf(feature.id) === -1) {
            return feature.is_mvp_feature;
          }
        }));
      }
    });
  }

  toggleClickedItem(item, isFromPop?) {
    item.selected = !item.selected;
    if (isFromPop) {
      let index = this.all_templates.findIndex((element) => {
        return element.id == item.id
      })
      if (index !== -1) {
        this.all_templates[index]['attributes']['selected'] = !this.all_templates[index]['attributes'].selected;
      }
      index = this.searchedTemplates.findIndex((element) => {
        return element.id == item.id
      })
      if (index !== -1) {
        this.searchedTemplates[index]['attributes']['selected'] = !this.searchedTemplates[index]['attributes'].selected;
      }
    }

  }

  setAnimationVariable() {
    this.idleAnimation = false;
    setTimeout(() => { this.idleAnimation = true; }, 3000);
  }

  goToFeatures() {
    const eventData = {
      user_id: this.dataService.user?.id,
      templates_selected_type: this.copyNatashaSearchText ? 'Natasha' : 'Manual',
      templates_selected: this.phaseService.selectedApps.map(app => app.title).join()
    };
    this.phaseService.trackEventForObject(this.analyticsSegment,  'build_cta_clicked', eventData);
    this.phaseService.showSpinner = true;
    if (!this.dataService.user) {
      this.phaseService.openCommonAuth = true;
      if (this.appDataService.urlParameters.signin || this.dataService.homePageData?.block_sign_up) {
        this.phaseService.showSignin = true;
      } else {
        this.phaseService.showRegister = true;
      }
      this.createBuildCardPending = true;
      return;
    }
    if (this.isBuildNowClickedFromTemplatePage) {
      if (!this.checkIfNatashaChatsContainUserMessage()) {
        this.tempateNatashaChatList = {};
      } else {
        this.addSelectedAppNamesToNatashaChat();
        this.phaseService.isCollapseTemplateHistory = true;
      }
      // this.phaseService.natashaChatsList = JSON.parse(JSON.stringify(this.tempateNatashaChatList));
    }
    this.isBuildNowClickedFromTemplatePage = false;
    if (this.phaseService.selectedProductFromHome) {
      this.pushPhases(this.phaseService.selectedProductFromHome);
      this.phaseService.selectedHash.product_id = this.phaseService.selectedProductFromHome.id;
    }
    if (this.phaseService.selectedApps) {
      // this.phaseService.selectedApps.forEach((app, index) => {
      //   this.phaseService.trackEvent(this.analyticsSegment,  'template_selected', 'templates_chosen', app.title);
      // });
      this.pushTemplatePhases();
    }
    if (this.phaseService.newSelectedProblemsIds.length > 0) {
      this.phaseService.newSelectedProblemsIds.forEach((item => {
        let appNames = '';
        this.phaseService.newSelectedProblemsApps[item].forEach(((app, index) => {
          if (index === 0) {
            appNames = app.title;
          } else {
            appNames = appNames + ',' + app.title;
          }
          this.phaseService.toggleSelectedAppsById(app);
          this.phaseService.toggleSelectedAppid(app.id);
        }));
        const problemStatement = this.phaseService.newProblemsData.filter((data) => {
          if (data.id === item) {
            return data;
          }
        });
      }));
    }
    this.createBuildCard();
  }

  checkForCPEUserAndCurrency() {
    return this.dataService.buildCardData && this.dataService.buildCardData.id && this.dataService.user && this.dataService.checkIfCPEUser()
      && (this.dataService.buildCardData.currency.id !== this.dataService.homePageData.currency.id);
  }

  createBuildCard() {
    if (this.phaseService.selectedApps.length && !this.phaseService.selectedFeatures.length) {
      this.phaseService.selectedApps.forEach(template => {
        this.phaseService.mandatoryFeatures = [];
        this.setMandatoryFeatures();
        this.phaseService.selectedFeatures = [].concat.apply([], this.phaseService.selectedApps.map(a => a.features));
      });
    }
    this.phaseService.selectedPhasesIds = [];
    this.phaseService.selectedPhases = [];
    this.phaseService.selectedHash.build_phase_vars = [];
    this.phaseService.onNextClicked();
    this.phaseService.checkAndSetDefaultPhases();
    this.dataService.homePageData.build_phases.forEach(phase => {
      if (phase.selected && (!phase.title.includes('MVP') && (phase['type'] !== 'custom_phase'))) {
        phase.features = [];
        phase.features.push(...this.phaseService.selectedFeatures);
      } else if (phase.selected && (phase.title.includes('MVP') || (phase['type'] === 'custom_phase'))) {
        if ((this.phaseService.selectedFeatures && this.phaseService.selectedFeatures.length === 0) || (phase.features && phase.features.length > 0 && phase.features[0] === undefined)) {
          phase.features = [];
        }
        if (phase.features && phase.features.length > 0) {
          phase.features.forEach((feature, featureIndex) => {
            const index = this.phaseService.selectedFeatures.findIndex(selectedFeature => {
              return feature.id === selectedFeature.id;
            });
            if (index === -1) {
              phase.features.splice(featureIndex, 1);
            }
          });
        }
        if ((this.phaseService.mandatoryFeatures.length > 0) && (phase.title === 'MVP')) {
          const phaseFeatures = phase.features.map(feature => feature.id);
          this.phaseService.mandatoryFeatures.forEach((feature) => {
            if (phaseFeatures.indexOf(feature.id) === -1 && feature.selected) {
              feature['selected'] = true;
              feature['mvpSelected'] = true;
              phase.features.push(feature);
            }
            feature['mvpSelected'] = true;
            if (!phase.platforms || phase.platforms.length === 0) {
              phase.platforms = this.phaseService.selectedPlatforms;
            }
            feature.platforms = phase.platforms;
          });
        }
      }
      if (this.phaseService.selectedApps.length && ((phase.title === 'MVP') || (phase['type'] === 'custom_phase'))) {
        if (!phase.features) { phase.features = []; }
        phase.features.forEach(feature => {
          this.phaseService.selectedFeatures.forEach(ftr => {
            if (ftr.id === feature.id) {
              ftr.phaseId = feature.phaseId;
              ftr.platforms = Object.assign([], feature.platforms);
            }
          });
        });
      }
    });
    this.phaseService.selectedHash.care_type = this.dataService.checkIfEnterpriseUserOrCard() ? 'enterprise_care_plus' : 'standard_care';
    this.phaseService.checkTeamAndSpeed();
    this.phaseService.createBuildCardPriceHash();
    if (this.activeFilters.category.length) {
      this.phaseService.selectedHash.category_ids = this.activeFilters.category.map(cat => cat.id);
    }
    if (this.dataService.user && this.dataService.user.enterprise_lead) {
      this.phaseService.selectedHash.care_type = 'enterprise_care_plus';
    }
    this.phaseService.showTemplateSearchBox = false;
    this.apiRequest.createUpdateBuildCard(this.phaseService.selectedHash, this.phaseService.inProgressBuildCardId).subscribe((data: any) => {
      // this.trackCardEvents();
      if (!this.phaseService.inProgressBuildCardId) {
        this.phaseService.isNewBuildCard = true;
      }
      this.phaseService.showSpinner = false;
      this.phaseService.inProgressBuildCardId = this.dataService.buildCardData.id;
      this.dataService.unsavedChanges = false;
      this.dataService.isFromViewPrototype = false;
      this.phaseService.buildCardEditMode = false;
      this.dataService.isEditBuildCard = false;
      this.phaseService.inProgressFlow = false;
      this.phaseService.isBuildCardSetup = false;
      this.showNatashaPanelMobile = false;
      this.showConfirmPopup = false;
      sessionStorage.setItem('show_natasha_again', 'from_template_page');
      // this.notifyBreadcrumb(false);
      if (this.appDataService.urlParameters.cpe_user) {
        this.router.navigate([this.dataService.buildCardData.uniq_code + '/feature'], {
          queryParams: { cpe_user: 'true', redirection_url: this.appDataService.urlParameters.redirection_url }
        });
      } else {
        this.router.navigate([this.dataService.buildCardData.uniq_code + '/feature']);
      }
      this.dataService.showHideLoader(false);
    }, error => {
      if (error.status === 403 || error.status === 401) {
        this.dataService.user = null;
        // remove cookie here
        this.dataService.unsetCookie();

        this.phaseService.clearLocalStorage();
        this.phaseService.showSignin = true;
        this.phaseService.openCommonAuth = true;
      }
    });
  }

  public pushPhases(base) {
    if (this.phaseService.selectedDataForSidePanel.length) {
      const product = this.phaseService.selectedDataForSidePanel[0];
      if (product.value !== base.title) {
        this.phaseService.reset();
        this.phaseService.selectedDataForSidePanel.push({
          id: 1,
          title: 'What',
          price: '0',
          icon: '/../../../assets/images/product_icon.svg',
          value: base.title,
          phase: 'home'
        });
        this.phaseService.selectedHash.product_id = base.id;
        this.phaseService.selectedProduct = base;
      }
    } else {
      this.phaseService.selectedDataForSidePanel.push({
        id: 1,
        title: 'What',
        price: '0',
        icon: '/../../../assets/images/product_icon.svg',
        value: base.title,
        phase: 'home'
      });
    }
  }

  public pushTemplatePhases() {
    const collection = [];
    for (const apps of this.phaseService.selectedApps) {
      collection.push(apps.title);
    }
    const phaseObj = {
      title: 'Templates',
      price: '0',
      icon: '/../../../assets/images/templates_icon.svg',
      collection: collection,
      phase: 'apps'
    };
    this.phaseService.handleSidePanelData(phaseObj, 'Templates');
  }

  clearTemplateSearchInput() {
    this.searchTemplateInput = '';
    this.appDataService.urlParameters.template_search = '';
    const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
    window.history.pushState({ path: newurl }, '', newurl);
    this.clearSearch();
  }

  addinTemplateNatashaChatList(obj, isForMobile?) {
    if (obj && obj.showLoader && (obj['type'] === 'natasha')) {
      this.addObjInNatashaGroup(obj, isForMobile);

      setTimeout(() => {
        obj.showLoader = false;
        let elementId;
        if (isForMobile) {
          elementId = '#wizardAnimation' + this.phaseService.natashaMobileGroupIdentifier;
        } else {
          elementId = '#wizardAnimation' + this.templateNatashaGroupIdentifier;
        }
        let nbox = document.querySelector(elementId);
        if (nbox) {
          nbox.classList.remove("idleAnimation");
          void (<HTMLElement>nbox).offsetWidth;
          setTimeout(() => {
            nbox.classList.add("idleAnimation");
          }, 3000);
        }
      }, 2000);

    } else if (obj['type'] === 'user') {
      this.addObjInNatashaGroup(obj, isForMobile);
    }
  }

  addObjectInTemplateNatashaGroup(obj, natashaChatList, isForMobile?) {
    if (this.checkIfNatashaChatsAreEmpty(natashaChatList)) {
      if (isForMobile) {
        this.phaseService.natashaMobileGroupIdentifier = this.phaseService.natashaGroupIdText + this.phaseService.natashaMobileGroupChatId;
        natashaChatList[this.phaseService.natashaMobileGroupIdentifier] = [obj];
      } else {
        this.templateNatashaGroupIdentifier = this.phaseService.natashaGroupIdText + this.templateNatashaGroupChatId;
        natashaChatList[this.templateNatashaGroupIdentifier] = [obj];
      }
    } else if (!this.checkIfNatashaChatsAreEmpty(natashaChatList) && this.checkIfObjBelongsToSameNatashaGroup(obj, natashaChatList)) {
      if (isForMobile) {
        natashaChatList[this.phaseService.natashaMobileGroupIdentifier].push(obj);
      } else {
        natashaChatList[this.templateNatashaGroupIdentifier].push(obj);
      }
    } else {
      if (isForMobile) {
        this.phaseService.natashaMobileGroupChatId++;
        this.phaseService.natashaMobileGroupIdentifier = this.phaseService.natashaGroupIdText + this.phaseService.natashaMobileGroupChatId;
        natashaChatList[this.phaseService.natashaMobileGroupIdentifier] = [obj];
      } else {
        this.templateNatashaGroupChatId++;
        this.templateNatashaGroupIdentifier = this.phaseService.natashaGroupIdText + this.templateNatashaGroupChatId;
        natashaChatList[this.templateNatashaGroupIdentifier] = [obj];
      }
    }
  }

  checkIfNatashaChatsAreEmpty(natashaChatList) {
    let keys;
    keys = Object.keys(natashaChatList);
    if (keys && keys.length === 0) {
      return true;
    }
    return false;
  }

  checkIfObjBelongsToSameNatashaGroup(obj, natashaChatList) {
    let keys;
    keys = Object.keys(natashaChatList);
    if (keys) {
      let lastKey = keys[keys.length - 1];
      let lastGroupOfChat = natashaChatList[lastKey];
      let lastElementOfLastNatashaGroup = lastGroupOfChat[lastGroupOfChat.length - 1];

      if (lastElementOfLastNatashaGroup['type'] === obj['type']) {
        return true;
      }
    }
    return false;
  }

  preSelectPlatforms() {
    this.phaseService.selectedPlatforms = [];
    this.phaseService.selectedHash.platform_ids = [];
    if (this.dataService.homePageData.platforms) {
      const platforms = this.dataService.homePageData.platforms;
      let defaultPlatform;
      for (const platform of platforms) {
        platform.selected = false;
        for (const app of this.phaseService.selectedApps) {
          for (const id of app.platform_ids) {
            if (id === platform.id) {
              platform.selected = true;
              defaultPlatform = platform;
              break;
            }
          }
        }
        if (platform.selected) {
          break;
        }
      }
      defaultPlatform = defaultPlatform ? defaultPlatform : platforms[0];
      this.phaseService.toggleSelectedPlatform(defaultPlatform);
      this.phaseService.toggleSelectedPlatformId(defaultPlatform.id);
    }
  }

  checkIfNatashaChatsContainUserMessage() {
    let chatKeys = Object.keys(this.tempateNatashaChatList);
    if (chatKeys && chatKeys.length > 0) {
      for (let key in chatKeys) {
        let groupKey = chatKeys[key];
        let keyElements = this.tempateNatashaChatList[groupKey];
        for (let i = 0; i < keyElements.length; i++) {
          let obj = keyElements[i];
          if (obj['type'] === 'user') {
            return true;
          }
        }
      }
    }
    return false;
  }

  addObjInNatashaGroup(obj, isForMobile) {
    if (isForMobile) {
      this.addObjectInTemplateNatashaGroup(obj, this.mobileTempateNatashaChatList, isForMobile);
    } else {
      this.addObjectInTemplateNatashaGroup(obj, this.tempateNatashaChatList);
      this.addObjectInTemplateNatashaGroup(obj, this.mobileTempateNatashaChatList, true);
    }
  }

  addSelectedAppNamesToNatashaChat() {
    let templateSelected = this.getAppsTitle();
    let obj = {
      templateChatText: 'You selected ' + '"' + templateSelected + '"' + '.',
      type: 'user',
      createdAt: this.phaseService.getCurrentTimeInHoursMinutes()
    };
    this.addinTemplateNatashaChatList(obj);
  }

  getAppsTitle() {
    let templateSelected = '';
    if (this.phaseService.selectedApps.length > 0) {
      const appsList = this.phaseService.selectedApps.map((app) => {
        return app.title
      });
      if (appsList.length > 1) {
        templateSelected = appsList.join(', ');
      } else {
        templateSelected = appsList[0];
      }
      return templateSelected;
    }
  }

  resetTemplateNatashaChatHistory() {
    this.tempateNatashaChatList = {};
    this.templateNatashaGroupIdentifier = '';
    this.templateNatashaGroupChatId = 1;
  }

  checkForSchedular() {
    this.callType = this.appDataService.urlParameters.schedular_reason;
    if (this.callType && this.callType.length) {
      this.authGuard.isAuthenticated() ? this.showSchedular() : this.showLoginpopup();
    }
  }

  showSchedular() {

    const scheduleType = this.appDataService.urlParameters.schedular_reason;
    let call_type = scheduleType == 'book-demo' ? 'demo': scheduleType == 'book-spec'? 'spec' : 'kickoff';
    this.apiRequest.createSpecCall('', '', '', call_type).subscribe((resp) => {
      this.dataService.specingId = resp.id;
      this.phaseService.showScheduleCallFlow = true;
      this.dataService.showMeetNowModel = true;
    }, error => {
      this.dataService.showHideLoader(false);
      this.dataService.isScheduleCall = false;
    });

  }

  showLoginpopup() {
    this.phaseService.openCommonAuth = true;
    if (this.dataService.homePageData?.block_sign_up) {
      this.phaseService.showSignin = true;
      this.phaseService.showRegister = false;
    } else {
      this.phaseService.showRegister = true;
      this.phaseService.showSignin = false;
    }
  }

  checkIfTryingToBuildIsLastNatashaMessage() {
    let chatKeys = Object.keys(this.tempateNatashaChatList);
    let LastGroupKey = chatKeys[chatKeys.length - 1];
    let keyElements = this.tempateNatashaChatList[LastGroupKey];
    if (keyElements) {
      let lastElement = keyElements[keyElements.length - 1];
      if (lastElement['templateChatText'] && lastElement['templateChatText'] === "What are you trying to build?") {
        return true;
      }
    }
    return false;
  }
}
