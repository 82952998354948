import {Component, OnInit} from '@angular/core';
import {BuildCardService} from '../../../services/buildcard.service';
import {DataService} from '@shared/services/data.service';
import {ActivatedRoute} from '@angular/router';
import { PhaseService } from '@shared/services/phase.service';

@Component({
  selector: 'app-project-info',
  templateUrl: 'project-info.component.html'
})

export class ProjectInfoComponent implements OnInit {
  constructor(public buildCardService: BuildCardService,
              private _dataService: DataService,
              private _router: ActivatedRoute, public phaseService: PhaseService) {
  }

  public ngOnInit() {
    this.getBuildCardDataOnReload();
  }

  public getBuildCardData(): any {
    return this.buildCardService.currentBuildCard ? this.buildCardService.currentBuildCard : null;
  }

  public getBuildCardDataOnReload(): void {
    this._router.params.subscribe(params => {
      const id = params['id'];
      if (!this.buildCardService.currentBuildCard) {
        this._dataService.showHideLoader(true);
        this.buildCardService.getBuildCardData(id)
          .finally(() => {
            this._dataService.showHideLoader(false);
          })
          .subscribe();
      }
    });
  }

  public getCommaSeparatedList(key): any {
    const custom = {};
    const predefined = {};
    custom[key] = [];
    predefined[key] = [];

    if (this.buildCardService.currentBuildCard && this.buildCardService.currentBuildCard.progress[key]) {
      if (key !== 'shared_users') {
        this.buildCardService.currentBuildCard.progress[key].forEach(progressType => {
          predefined[key].push(progressType.title);
        });
      }
    }

    if (key === 'shared_users') {
      if (this.buildCardService.currentBuildCard && this.buildCardService.currentBuildCard.shared_users && this.buildCardService.currentBuildCard.shared_users.length > 0) {
        this.buildCardService.currentBuildCard.shared_users.forEach(user => {
          predefined[key].push(user.first_name + ' ' + user.last_name);
        });
      }
    }
    return {custom: custom[key].join(', '), pre_defined: predefined[key].join(', ')};
  }

  public getCommaSeparatedDocuments(key): any {
    const obj = {};
    obj[key] = [];

    if (this.buildCardService.currentBuildCard && this.buildCardService.currentBuildCard[key]) {
      if (key === 'contracts') {
        if (this.buildCardService.currentBuildCard.nda_signed) {
          obj[key].push('NDA Signed');
        }
      }
      this.buildCardService.currentBuildCard[key].forEach(document => {
        if (key === 'contracts') {
          if (document.contract_type === 'contract_master_nda' && document.nda_signed) {
            obj[key].push('Master Contract Signed');
          }
        } else {
          obj[key].push(document.id);
        }
      });
    }
    return obj[key].join(', ');
  }

  public isBuildCardInProgress() {
    const card = this.getBuildCardData();
    if (card) {
      return card && (!this.getDiscountedprice(card) || (card.status === 'card_initialized' && !card.kick_off_date));
    }
  }

  getDiscountedprice(card) {
    return this.phaseService.getDiscountedPriceForCards(card);
  }
}
