import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeetNowComponent } from './meet-now/meet-now.component';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '@shared/shared.module';
import { NguCarouselModule } from '@ngu/carousel';

const childRouterConfig: Routes = [
  {path: 'meet-now', component: MeetNowComponent}
];

@NgModule({
  declarations: [MeetNowComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(childRouterConfig),
    NguCarouselModule
  ],
  exports: [
    MeetNowComponent
  ]
})
export class MeetNowModule { }
