<div class="dashboardLeft" [ngClass]="{'sidebarMenuActive': phaseService.isShowDashboardMenuForMobileOrTablet}">
  <div class="dashboardOverlay" (click)="closeDashboardMenuForMobileOrTablet()"></div>
  <div class="customScrollbar">
    <perfect-scrollbar #scrollTODown class="myScrollbar" [config]="PERFECT_SCROLLBAR_CONFIG">
    <div class="mainTab" [ngClass]="{'active': isNotProfileAndShowCard()}" *ngIf="!this.phaseService.isSettingPage" (click)="showCards(0)"><span class="iconDashboard"></span> <span>Dashboard</span></div>
    <div class="mainTab" [ngClass]="{'active': isNotProfileAndShowCard()}" *ngIf="this.phaseService.isSettingPage" (click)="showCards(0)"><em class="icon-leftarrownew"></em> <span>Back to Dashboard</span></div>
    <div class="separator"></div>

    <div class="subMenu">
      <div class="menuTitle" [ngClass]="{ 'active': showSections.showCalls }">
        <h4><div class="menuIcon"><img src="../../../../../../assets/images/studio-calls-icon.svg" alt="" /></div> Studio Calls</h4>
        <div class="expandButton" [ngClass]="{ 'active': showSections.showCalls }">
          <em class="icon-prev" (click)="openSection('calls')"></em>
        </div>
      </div>
      <ul *ngIf="showSections.showCalls">
        <li>
          <div class="subMenuRow" [ngClass]="{'active': isActiveDemoCallsCards()}" (click)="showCards(14)">
            <em class="icon-finished"></em> Demo Calls <strong *ngIf="!dataService.loadFilteredData || !dataService.isSpecCallAPiResolved"><img
              src="../../../assets/images/rolling.gif" alt="" /></strong> <strong
              *ngIf="dataService.loadFilteredData && dataService.isSpecCallAPiResolved">{{dataService.demoCallCount}}</strong>
              <!-- <strong>0</strong> -->
          </div>
        </li>
        <li *ngIf="dataService.isEngineerAIBuilder()">
          <div class="subMenuRow" [ngClass]="{'active': isActiveSpecCallsCards()}" (click)="showCards(6)"><em
              class="icon-finished"></em>Spec calls <strong *ngIf="!dataService.loadFilteredData || !dataService.isSpecCallAPiResolved"><img
                src="../../../assets/images/rolling.gif" alt="" /></strong> <strong
              *ngIf="dataService.loadFilteredData && dataService.isSpecCallAPiResolved">{{dataService.specCallCount}}</strong>
          </div>
        </li>
      </ul>
    </div>
    <!-- <div class="subMenu">
      <h4>IDEA CARDS</h4>
      <ul></ul>
    </div> -->
    <div class="subMenu">
      <div class="menuTitle" [ngClass]="{ 'active': showSections.showCards }">
        <h4><div class="menuIcon"><img src="../../../../../../assets/images/buildcard-icon.svg" alt="" /></div> Buildcards</h4>
        <div class="expandButton" [ngClass]="{ 'active': showSections.showCards }">
          <em class="icon-prev" (click)="openSection('cards')"></em>
        </div>
      </div>
      <ul *ngIf="showSections.showCards">
        <li>
          <div class="subMenuRow" [ngClass]="{'active': isActiveProgressCards()}" (click)="showCards(2)"><em
              class="icon-archived"></em> Draft <strong *ngIf="!dataService.loadFilteredData"><img
                src="../../../assets/images/rolling.gif" alt="" /></strong><strong
              *ngIf="dataService.loadFilteredData">{{getCardCount('inprogress')}}</strong>
          </div>
        </li>
        <li>
          <div class="subMenuRow" [ngClass]="{'active': isActiveCompletedCards()}" (click)="showCards(1)"><em
              class="icon-finished"></em> Created <strong *ngIf="!dataService.loadFilteredData"><img
                src="../../../assets/images/rolling.gif" alt="" /></strong><strong
              *ngIf="dataService.loadFilteredData">{{getCardCount('completed')}}</strong>
          </div>
        </li>
        <li>
          <div class="subMenuRow" [ngClass]="{'active': isActiveInvitedCards()}" (click)="showCards(4)"><em
              class="icon-archived"></em> Invited <strong *ngIf="!dataService.loadFilteredData"><img
                src="../../../assets/images/rolling.gif" alt="" /></strong><strong
              *ngIf="dataService.loadFilteredData">{{getCardCount('invited')}}</strong></div>
        </li>
        <li>
          <div class="subMenuRow" [ngClass]="{'active': isActiveFreemiumCards()}" (click)="showCards(12)"><em
            class="icon-archived"></em> Freemium <strong *ngIf="!dataService.loadFilteredData"><img
            src="../../../assets/images/rolling.gif" alt="" /></strong><strong
            *ngIf="dataService.loadFilteredData">{{getCardCount('freemium')}}</strong></div>
        </li>
        <li>
          <div class="subMenuRow" [ngClass]="{'active': isActiveShowCards()}" (click)="showCards(3)">
            <em class="icon-running"></em> Running <strong *ngIf="!dataService.loadFilteredData"><img
                src="../../../assets/images/rolling.gif" alt="" /></strong><strong
              *ngIf="dataService.loadFilteredData">{{getCardCount('paid')}}</strong>
          </div>
        </li>
        <!-- <li>
          <div class="subMenuRow"><em class="icon-queued"></em> Queued <strong *ngIf="!dataService.loadFilteredData"><img
                src="../../../assets/images/rolling.gif" /></strong><strong
              *ngIf="dataService.loadFilteredData">0</strong></div>
        </li> -->
        <li>
          <div class="subMenuRow" [ngClass]="{'active': isProjectCompletedCards()}" (click)="showCards(11)"><em class="icon-finished"></em> Shipped <strong
              *ngIf="!dataService.loadFilteredData"><img src="../../../assets/images/rolling.gif" alt="" /></strong><strong
              *ngIf="dataService.loadFilteredData">{{getCardCount('project_completed')}}</strong></div>
        </li>
        <li>
          <div class="subMenuRow" [ngClass]="{'active': isActiveArchivedCards()}" (click)="showCards(5)"><em
              class="icon-archived"></em> Archived <strong *ngIf="!dataService.loadFilteredData"><img
                src="../../../assets/images/rolling.gif" alt="" /></strong><strong
              *ngIf="dataService.loadFilteredData">{{getCardCount('archived')}}</strong></div>
        </li>
      </ul>
    </div>

    <div class="subMenu withoutList">
      <ul>
        <li>
          <div class="subMenuRow" [ngClass]="{'active': isActiveAdditionalFeatureRequest()}" (click)="showCards(7)">
            <em class="icon-plus"></em> Additional Features Req. <strong *ngIf="!dataService.changeRequestDataLoaded"><img src="../../../assets/images/rolling.gif" alt="" /></strong>
            <strong *ngIf="dataService.changeRequestDataLoaded">{{getCardCount('additionalfeaturerequest')}}</strong>
          </div>
        </li>
      </ul>
    </div>
    <!-- -->

    <!-- <div class="subMenu withoutList">
      <ul>
        <li>
          <div class="subMenuRow" [ngClass]="{'active': referralService.showReferralWallet}" (click)="showWallet()">
            <span class="left-icon"> <img src="../../../assets/images/money-wallet.svg" alt="" /></span>  Wallet
          </div>
        </li>
      </ul>
    </div> -->

    <div class="subMenu withoutList">
      <ul>
        <li>
          <div class="subMenuRow" [ngClass]="{'active': isActivePaymentCards()}" (click)="showCards(8)">
            <span class="left-icon"><img src="../../../../../../assets/images/accounting-bills-icon.svg" alt="" /></span>  Payments
          </div>
        </li>
      </ul>
    </div>

    <!-- <div class="mainTab" (click)="signedNdaClicked()">Signed NDA</div> -->

    <div class="subMenu withoutList withtooltip" *ngIf="showTaxDetailsSection()">
      <ul>
        <li>
          <div class="subMenuRow" [ngClass]="{'active': phaseService.showTaxAndBilling}" (click)="showTaxAndBilling()">
            <span class="left-icon"><img src="../../../../../../assets/images/accounting-bills-icon.svg" alt="" /></span> Tax Details
            <strong *ngIf="!dataService.user?.address?.tax_details_disabled">
              <em class="icon-info-circle error"></em>
              <div class="infoTooltip">Please add your tax details so we can kick off your buildcard</div>
            </strong>
          </div>
        </li>
      </ul>
    </div>

    <div class="subMenu withoutList settings">
      <ul>
        <li>
          <div class="subMenuRow" [ngClass]="{'active': phaseService.isProfile}" (click)="settingsShow()">
            <span class="left-icon"><img src="../../../../../../assets/images/settings-icon.svg" alt="" /></span>  Settings
          </div>
        </li>
      </ul>
    </div>

    <div class="subMenu mobileOnly">
      <h4>CONTACT</h4>
      <ul>
        <li (click)="phaseService.showIntercomChat()">
          <div class="subMenuRow" >
            <em class="icon-myaccount"></em> Live chat
          </div>
        </li>
        <li (click)="headerService.showInstantCall()">
          <div class="subMenuRow" >
            <em class="icon-phonescreen"></em> Instant call
          </div>
        </li>
        <li (click)="headerService.showScheduleCall()">
          <div class="subMenuRow" >
            <em class="icon-phonescreen"></em> Book a call
          </div>
        </li>
      </ul>
    </div>

    <div class="subMenu withoutList mobileOnly">
      <ul>
        <li (click)="headerService.performUserActions('logout')">
          <div class="subMenuRow logout" >
            <em class="icon-logout"></em>  Logout
          </div>
        </li>
      </ul>
    </div>

    <!-- <div class="mainTab" (click)="specialOffersClicked()"><em class="icon-offers"></em> <span>Special Offers</span> </div> -->
    </perfect-scrollbar>
  </div>
</div>
