import {FeatureModel} from '@models/feature-model/feature.model';
import {CurrencyModel} from '@models/currency-model/currency.model';
import {PhaseDataModel} from '@models/phase-model/phasedata.model';

export class ChangeFeatureRequestModel {
  approved_at: string;
  build_card: ChangeRequestBuildCardModel;
  build_card_id: number;
  build_phase_data: any;
  created_at: string;
  feature_ids: Array<number>;
  features: Array<FeatureModel>;
  general_info: GeneralInfo;
  id: number;
  installments: Array<InstallmentModel>;
  price: number;
  requested_at: string;
  status: string;
  status_updated_at: string;
  weeks: number;
}

export class GeneralInfo {
  last_payment_date: string;
  next_payment_date: string;
  total_amount: number;
  total_remaining_weeks: number;
  weeks: number;
  tax: number;
}

export class ChangeRequestBuildCardModel {
  currency: CurrencyModel;
  project_name: string;
  uniq_code: string;
  id: number;
  frequency: string;
}

export class InstallmentModel {
  due_date: string;
  installment_amount: number;
  installment_no: number;
  tax: number;
  tax_amount: number;
}
