import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchStates'
})
export class SearchStatesPipe implements PipeTransform {

  transform(items: any, searchText?: any): any {
    if (!items) { return []; }
    if (!searchText) { return items; }
    const searchedItems = items.filter(item => {
      if (item.name) {
        return item.name.toLowerCase().includes(searchText.toLowerCase());
      } else {
        return item.toLowerCase().includes(searchText.toLowerCase());
      }
    });
    return (searchedItems.length ? searchedItems : [{ name: 'No matching results' }]);
  }

}
