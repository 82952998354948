import {Component, ElementRef, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {environment} from '@env/environment';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Intercom} from 'ng-intercom';
import {AppDataService} from '@rootmodule/app-data.service';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss']
})
export class PaymentOptionsComponent implements  AfterViewInit {
  @ViewChild('iframe') iframe: ElementRef;
  isLoading = true;
  count = 1;

  constructor(public phaseService: PhaseService, public dataService: DataService, public appDataService: AppDataService,
              public analyticsSegment: Angulartics2Segment, ) {
  }



  ngAfterViewInit() {
    this.phaseService.isShowPaymentOptionsLoader = false;
    this.iframe.nativeElement.setAttribute('src', environment.PAYMENT_URL + this.dataService.paymentUrl);
    document.querySelector('#myframe').addEventListener('load', ev => {
      this.isLoading = false;
    });
  }

  getCurrency() {
    return (this.dataService.buildCardData && this.dataService.buildCardData.id) ? this.dataService.buildCardData.currency.symbol : this.dataService.user.currency.symbol;
  }

  getDeposit() {
    return this.dataService.getLocaledPrice(parseInt(this.phaseService.paymentData.pending_installment.installment_amount));
  }

  checkIfIframeLoaded() {
    this.count = this.count + 1;
    if (this.count === 3) {
      this.isLoading = false;
      this.count = 1;
    }
  }

}
