import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableProdMode} from '@angular/core';
import {AppModule} from './app/core/root-module/app.module';
import { environment } from './environments/environment';
if (environment.production) {
  enableProdMode();
}
  document.addEventListener('DOMContentLoaded', () => {
    platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/ngsw-worker.js');
      }
    })
      .catch(err => console.log(err));
  });

