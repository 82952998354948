import { Component, OnInit } from '@angular/core';
import { DataService } from '@shared/services/data.service';
import { ReferralService } from '@shared/services/referral.service';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-use-credited-referral-points',
  templateUrl: './use-credited-referral-points.component.html',
  styleUrls: ['./use-credited-referral-points.component.scss']
})
export class UseCreditedReferralPointsComponent{

  sliderOptions: Options = {
    showSelectionBar: true,
    floor: 0,
    ceil: 1000
  };
  shouldSaveForLater = false;
  showSaveInWalletCTA = false;
  sliderValue = 0;
  contentArray = ['Select points to be used', 'How many points do you want to use?']
  popupContent : string;
  constructor(public referralService: ReferralService, public dataService: DataService) {
    this.sliderValue = this.referralService.sliderValueForCreditedReferralPoints ? this.referralService.sliderValueForCreditedReferralPoints : this.dataService.buildCardData?.reward_point;
    this.referralService.builderAmountEquivalentToReferralPointsUsed = this.referralService.creditedBuilderAmountEquivalentToUsedReferralPoints ? this.referralService.creditedBuilderAmountEquivalentToUsedReferralPoints : this.dataService.buildCardData?.total_amount;
    this.popupContent = this.contentArray[1]
    if (this.referralService.totalAmountInWallet) {
      this.sliderOptions = {
        showSelectionBar: true,
        floor: 0,
        ceil: (typeof this.referralService.totalAmountInWallet === 'string') ? parseInt(this.referralService.totalAmountInWallet.replace(/,/g, '')) : this.referralService.totalAmountInWallet
      };
    } else {
      this.sliderOptions = {
        showSelectionBar: true,
        floor: 0,
        ceil: (this.dataService.buildCardData && this.dataService.buildCardData.awarded_reward_points) ? this.dataService.buildCardData.awarded_reward_points : this.referralService.referralBuilderPointsForUser
      };
    }
  }



  closeUseCreditedReferralPointsPopup() {
    this.referralService.showUseCreditedReferralPointsPopup = false;
  }

  onSliderValueChange() {
    if (this.sliderValue === 0) {
      this.popupContent = this.contentArray[0]
      this.shouldSaveForLater = true;
      this.showSaveInWalletCTA = true;

    } else {
      this.shouldSaveForLater = false;
      this.showSaveInWalletCTA = false;
      this.popupContent = this.contentArray[1]
    }
    this.useCreditedBuilderPoints(false, true);
  }

  useCreditedBuilderPoints(shouldSaveInWallet, isFromSliderValueChange?) {
    let requestPayload: any;
    if (shouldSaveInWallet) {
      requestPayload = {
        build_card_id: this.dataService.buildCardData.id,
        reward_point: 0
      }
      requestPayload['save_for_later'] = true;
    } else {
      requestPayload = {
        build_card_id: this.dataService.buildCardData.id,
        reward_point: this.sliderValue
      }
    }
    if (isFromSliderValueChange) {
      requestPayload['scroll_val'] = 'true';
    }
    this.referralService.useCreditedBuilderPoints(requestPayload, isFromSliderValueChange);
  }

  toggleSaveForLater() {
    this.shouldSaveForLater = !this.shouldSaveForLater;
    if (this.shouldSaveForLater) {
      this.popupContent = this.contentArray[0]
      this.showSaveInWalletCTA = true;
      this.sliderValue = 0;
    } else {
      this.popupContent = this.contentArray[1]
      this.showSaveInWalletCTA = false;
    }
  }

}
