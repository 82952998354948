import { Component, OnInit } from '@angular/core';
import {DataService} from '@shared/services/data.service';

@Component({
  selector: 'app-tnc-pro',
  templateUrl: './tnc-pro.component.html',
  styleUrls: ['./tnc-pro.component.scss']
})
export class TncProComponent implements OnInit {

  constructor(private dataService: DataService) {
    this.dataService.currentPage = 'tncPro';
  }

  ngOnInit(): void {
  }

}
