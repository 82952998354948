import {Injectable, EventEmitter} from '@angular/core';

@Injectable()
export class MessageEmitterService {
  event = new EventEmitter();


  sendMessage(data: any) {
    this.event.emit(data);
  }
}
