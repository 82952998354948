import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '@shared/shared.module';
import {CommonModule} from '@angular/common';
import {ApptemplateComponent} from './template/apptemplate.component';
/*import {CarouselModule} from 'ngx-bootstrap';*/
// import {NouisliderModule} from 'ng2-nouislider';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {AddnewtemplateComponent} from '@featuremodules/app-template-module/popups/addnewtemplate/addnewtemplate.component';
import {UtilityService} from '@shared/services/utility.service';
import {TemplateDetailComponent} from '@featuremodules/app-template-module/template-detail/template-detail.component';
import {DragScrollModule} from 'ngx-drag-scroll';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import {StudioStoreTemplateDetailComponent} from '@shared/shared-components/studio-store-template-detail/studio-store-template-detail.component';
import { FreemiumTemplateDetailComponent } from './freemium-template-detail/freemium-template-detail.component';

const childRouterConfig: Routes = [
  {path: ':template_alias', component: TemplateDetailComponent},
  {path: '', component: ApptemplateComponent}
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        /*CarouselModule.forRoot(),*/
        // NouisliderModule,
        InfiniteScrollModule,
        DragScrollModule,
        RouterModule.forChild(childRouterConfig),
        NgxSliderModule
    ],
    declarations: [ApptemplateComponent, AddnewtemplateComponent, TemplateDetailComponent, FreemiumTemplateDetailComponent],
    providers: [UtilityService]
})
export class AppTemplateModule {
}
