/**
 * Created by nitin on 14/12/17.
 */
export class SpeedModel {
  description: string;
  icon: string;
  id: number;
  name: string;
  price_multiplier: number;
  title: string;
  week_multiplier: number;
  selected: boolean;
}
