import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import { UpgradePlanComponent } from './upgrade-plan/upgrade-plan.component';
import {UpgradePlanService} from '@shared/services/upgrade-plan.service';

const childRouterConfig: Routes = [
  { path: '', component: UpgradePlanComponent }
];

@NgModule({
  declarations: [UpgradePlanComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(childRouterConfig)
  ],
  providers: [
    UpgradePlanService
  ]
})
export class UpgradePlanModule { }
