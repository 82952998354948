<div class="payment-bx">
  <div class="container" *ngIf="!dataService.showLoader">
    <div class="row">
      <div class="paymentFlow" *ngIf="!isSpecCallScheduled">
        <ng-container *ngIf="(isPaymentTypePrototype && isPrototypeInstallmentDone); else notPrototype">
          <div class="paymentStatus">
            <ng-container *ngIf="installmentType !== 'support'">
              <div class="thankyouIcon"> <span class="right-icon-bx"> <em class="icon-right"></em></span></div>
              <h3>Thank you for payment</h3>
              <h4> Your transaction has been successful. Payment receipt will be sent on your registered e-mail Id. </h4>
              <div class="order-summery-block">
                <h3> Transaction Summary </h3>
                <ul>
                  <li *ngIf="(getTransactionID() && (getTransactionID().length > 0))"><span>Transaction ID</span> <strong>{{getTransactionID()}}</strong></li>
                  <li><span>Amount </span> <strong>{{ dataService?.user.currency.symbol }}{{ getPaidAmount() }}</strong></li>
                  <li><span>Date</span> <strong>{{getPaidDate() | date: 'dd-MMM-y'}}</strong></li>
                  <li><span>Payment Type</span> <strong>Credit card</strong></li>
                </ul>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #notPrototype>
          <div *ngIf="(installmentType === 'support')" class="text-center">
            <div class="thankyouIcon"><img src="../../../../../assets/images/thumb-icon.png" alt="" /> </div>
            <h3>
              Thank you
            </h3>
            <p>Builder Care now protects your<br> software.</p>
            <div class="commonButton">
              <button type="button" (click)="gotoDashboard()" *ngIf="showDashBoardButton()"> Go to Dashboard </button>
              <div class="clearfix"></div>
              <div class="kickoffDate thankScreen" [ngClass] = "{'calenderOpen' : isScheduleCall}" *ngIf="(installmentType !== 'support') && !isPaymentTypeSpecing && (appDataService.urlParameters.paymentType !== 'prototype')">
                <input class="calenderInput" placeholder="Click to select a date"
                       angular-mydatepicker name="mydate" (click)="dp.toggleCalendar()"
                       [(ngModel)]="model" [options]="myDpOptions"
                       #dp="angular-mydatepicker" (dateChanged)="onDateChanged($event)" (calendarToggle)="onCalendarToggle($event)" [ngClass]="{'hide' : !isScheduleCall}" />
                <button type="button" *ngIf="(installmentType !== 'support')" class="btn2" [ngClass] = "{'hide' : isScheduleCall}" (click) = "showCalender()">Schedule Spec Call</button>
                <button type="button" class="btnpickerdone" [ngClass] = "{'hide' : !isScheduleCall}" (click)="saveScheduleDate()" [disabled] = "!dateChange">Schedule</button>
              </div>
            </div>
          </div>
          <div *ngIf="(installmentType !== 'support')">
            <!--new added start-->
            <!-- <button class="backToPageLink" (click)="backToWireTransfer()" *ngIf="(showThanks && !isSpecCallScheduled)"><i class="fa fa-angle-left"></i> Back to wire transfer details</button> -->
            <div class="paymentDetailTable" [ngClass] = "{'wt' : showThanks && !isSpecCallScheduled}" *ngIf="(showThanks && !isSpecCallScheduled) || (buildCardId && isPaymentTypeInstallment && isInstallmentPaymentDone)  || (isPaymentTypeSpecing && isSpecingPaymentDone)">
              <!--left column start-->
              <div class="leftColumn" [ngClass]='{rightBorder : isInstallmentPage}'>
                <div *ngIf="showThanks && !isSpecCallScheduled">
                  <div class="topIcon wireTransfer"><span><em class="icon-right"></em></span></div>
                  <h3 class="wt">You've made a wire transfer?<br/>Thank you!</h3>
                  <!-- <p>You've chosen to proceed in an offline mode. Please pay via wire transfer, you can also get in touch with your account manager for next steps. Once we confirm payment receipt, you will be notified to proceed towards scheduling the kick-off call with our squad.</p> -->
                  <p class="wt"><strong>As soon as your payment is verified</strong>, you can book your kick-off call to:</p>
                <ul>
                  <li>Meet the expert who'll manage your whole Buildcard<sup class="tm light">®</sup>.</li>
                  <li>Go through all your app features (so they're exactly right).</li>
                  <li>Explore the dashboard that shows you live updates.</li>
                  <li>Get all your questions answered.</li>
                  <li>Officially start your Buildcard<sup class="tm light">®</sup>.</li>
                </ul>
                </div>
                <div *ngIf="!showThanks">
                  <div class="topIcon"><span><em class="icon-right"></em></span></div>
                  <h3>Thank you!</h3>
                  <ng-container *ngIf="((appDataService.urlParameters.payment_method === 'Netbanking') || ((appDataService.urlParameters.payment_method === 'UPI') && appDataService.urlParameters.upi_mandate)) && (appDataService.urlParameters.payment_frequency !== 'upfront'); else showDefaultMessage">
                    <p>
                      Your payment and Autopay were both successful. We'll send your receipt to the email on your account.
                    </p>
                  </ng-container>
                  <ng-template #showDefaultMessage>
                    <p>
                      This payment was successful, we'll send your receipt to the email on your account.
                    </p>
                  </ng-template>

                  <!-- <p>You've chosen to pay offline. Please make a wire transfer or contact your account manager to learn more. We'll tell you once our finance team has your payment. Then you can book your kick-off call.</p> -->
                  <h4 *ngIf="(getTransactionID() && (getTransactionID().length > 0))">Transaction id: {{getTransactionID()}}</h4>
                  <h5 *ngIf="(getTransactionID() && (getTransactionID().length > 0))" (click) = "showHideOrderSummary(true)">View order details</h5>
                  <div class="btnContainer" *ngIf='isInstallmentPage'>
                    <button type="button" class="btGoToDashboard" (click)="gotoDashboard()">Go to dashboard</button>
                  </div>
                </div>
              </div>
              <!--left column close-->

              <!--right column start-->
              <div class="rightColumn" *ngIf='!isInstallmentPage && !showThanks'>
                <div class="stepsRow" [ngClass]="{'haveFourSteps': ((appDataService.urlParameters.payment_method === 'Netbanking') || ((appDataService.urlParameters.payment_method === 'UPI') && appDataService.urlParameters.upi_mandate)) && (appDataService.urlParameters.payment_frequency !== 'upfront')}">
                  <div class="stepCol">
                    <div class="stepIcon tickmark"></div>
                    <div class="stepName">Buildcard<sup class="tm">®</sup></div>
                  </div>
                  <div class="stepCol">
                    <div class="stepIcon" [ngClass]="(showThanks && !isSpecCallScheduled) ? 'exclamatorysign': 'tickmark'"></div>
                    <div class="stepName">Payment</div>
                    <div class="stepTooltip">Deposit payment would need to be cleared to schedule the kick off call.</div>
                  </div>
                  <div class="stepCol" *ngIf="((appDataService.urlParameters.payment_method === 'Netbanking') || ((appDataService.urlParameters.payment_method === 'UPI') && appDataService.urlParameters.upi_mandate)) && (appDataService.urlParameters.payment_frequency !== 'upfront')">
                    <div class="stepIcon tickmark"></div>
                    <div class="stepName">Autopay<sup class="tm">®</sup></div>
                  </div>
                  <div class="stepCol">
                    <div class="stepIcon currentmark"></div>
                    <div class="stepName">Kick-Off Call</div>
                  </div>
                  <div class="exclamatoryline" *ngIf="showThanks && !isSpecCallScheduled"></div>
                </div>

                <h3>What happens in a kick-off call?</h3>
                <ul>
                  <li>Meet the expert who’ll manage your whole Buildcard<sup class="tm light">®</sup>.</li>
                  <li>Go through all your features (so they’re exactly right).</li>
                  <li>Explore the dashboard that shows you live updates.</li>
                  <li>Get all your questions answered.</li>
                  <li>Officially start your Buildcard<sup class="tm light">®</sup>.</li>
                </ul>
                <div class="scheduleExpert">
                  <button type="button" class="callButton" [ngClass]="{'slideRight' : scheduleCallWaiting}"
                  [disabled]="scheduleCallWaiting" (click) = "scheduleExpertCall()">
                    <span class="btnText" *ngIf="scheduleCallWaiting">Book my kick-off call</span>
                    <span class="btnText" *ngIf="!scheduleCallWaiting">Schedule a Kick-off call</span>
                    <span class="progressBar step1" *ngIf="!showThanks" [ngClass]="{'step2' : progressBarStep, 'step3' : !scheduleCallWaiting}"></span>
                  </button>
                    <span class="schedulerMsg" *ngIf="scheduleCallWaiting && !showThanks"><strong><em>{{schedulerWaitingMsg}}</em></strong></span>
                    <div class="calenderPopup" [ngClass] = "{'calenderOpen' : isScheduleCall}"  *ngIf="(installmentType !== 'support') && !isPaymentTypeSpecing && (appDataService.urlParameters.paymentType !== 'prototype')">
                      <input class="calenderInput" placeholder="Click to select a date"
                            angular-mydatepicker name="mydate" (click)="dp.toggleCalendar()"
                            [(ngModel)]="model" [options]="myDpOptions"
                            #dp="angular-mydatepicker" (dateChanged)="onDateChanged($event)" (calendarToggle)="onCalendarToggle($event)" [ngClass]="{'hide' : !isScheduleCall}" />
                      <button type="button" class="btnpickerdone" (click)="saveScheduleDate()" [disabled] = "!dateChange">Schedule</button>
                    </div>
                </div>
              </div>
              <div class="rightColumnOffline" *ngIf='!isInstallmentPage && showThanks'>
                <div style="width: 100%;">
                <h3>Next steps</h3>
                <div class="stepsRow">
                  <div class="stepCol">
                    <div class="stepIcon tickmark"></div>
                    <div class="stepName">Buildcard <br> created</div>
                  </div>
                  <div class="stepCol">
                    <div class="stepIcon tickmark"></div>
                    <div class="stepName">Payment <br> initiated</div>
                  </div>
                  <div class="stepCol">
                    <div class="stepIcon circle"></div>
                    <div class="stepName">Payment <br> received /verified</div>
                  </div>
                  <div class="stepCol">
                    <div class="stepIcon circle"></div>
                    <div class="stepName">Kick-off <br> call</div>
                  </div>
                </div>
              </div>
              <button type="button" class="btGoToDashboard" (click)="gotoDashboard()">Go to dashboard</button>
              </div>
              <!--right column close-->
            </div>
            <!--new added close-->
          </div>

        </ng-template>



        <div class="paymentStatus" *ngIf="checkForFailedPayment()">
          <img src="./../../../../assets/images/payFaile.jpg" alt=""/>
          <h3 class="payFaile">Payment Failed!</h3>
          <h4>Unable to process your payment.</h4>
        </div>
        <div class="commonButton" *ngIf="(isPaymentTypePrototype && isPrototypeInstallmentDone)">
          <p *ngIf="isPaymentTypeSpecing && isSpecingPaymentDone">Click below to schedule a call with our Product Manager</p>

          <!--<p *ngIf="isPaymentTypeInstallment && isInstallmentPaymentDone ">Click below to schedule a call with our On boarding specialist</p>-->

          <button type="button" class="scheduleCall" (click)="phaseService.showTimeKitWindow(false, specingId)" *ngIf="isSpecingPaymentDone">Schedule call</button>
          <!-- <h4 *ngIf="isPaymentTypeInstallment">or</h4> -->
          <div class="clearfix"></div>
          <div class="kickoffDate hidden-xs" [ngClass] = "{'calenderOpen' : isScheduleCall}"  *ngIf="(installmentType !== 'support') && !isPaymentTypeSpecing && (appDataService.urlParameters.paymentType !== 'prototype')">
            <input class="calenderInput" placeholder="Click to select a date"
                   angular-mydatepicker name="mydate" (click)="dp.toggleCalendar()"
                   [(ngModel)]="model" [options]="myDpOptions"
                   #dp="angular-mydatepicker" (dateChanged)="onDateChanged($event)" (calendarToggle)="onCalendarToggle($event)" [ngClass]="{'hide' : !isScheduleCall}" />
                   <button type="button" class="callButton outline-btn" [disabled]="scheduleCallWaiting" (click) = "scheduleExpertCall()">Schedule a call <span *ngIf="scheduleCallWaiting" class="tooltipCounter">Hold tight...</span></button>
            <button type="button" class="btnpickerdone" [ngClass] = "{'hide' : !isScheduleCall}" (click)="saveScheduleDate()" [disabled] = "!dateChange">Schedule</button>
          </div>
          <button type="button" class="scheduleCall" (click)="redirectToNow()" *ngIf="isPrototypeInstallmentDone && (appDataService.urlParameters.paymentType === 'prototype')">View Prototype
          </button>

          <!--          <h4 *ngIf="isSpecingPaymentDone || isInstallmentPaymentDone || isPrototypeInstallmentDone">or</h4>-->
          <button type="button" (click)="gotoDashboard()" *ngIf="(installmentType !== 'deposit') && (appDataService.urlParameters.prototypePayment !== 'custom_prototype')">Go to Dashboard</button>
          <div class="kickoffDate visible-xs" [ngClass] = "{'calenderOpen' : isScheduleCall}" *ngIf="!isPaymentTypeSpecing && (appDataService.urlParameters.paymentType !== 'prototype')">
            <input class="calenderInput" placeholder="Click to select a date"
                   angular-mydatepicker name="mydate" (click)="dp.toggleCalendar()"
                   [(ngModel)]="model" [options]="myDpOptions"
                   #dp="angular-mydatepicker" (dateChanged)="onDateChanged($event)" (calendarToggle)="onCalendarToggle($event)" [ngClass]="{'hide' : !isScheduleCall}" />
            <button type="button" class="btnpicker" [ngClass] = "{'hide' : isScheduleCall}" (click) = "showCalender()">Schedule Spec Call </button>
            <button type="button" class="btnpickerdone" [ngClass] = "{'hide' : !isScheduleCall}" (click)="saveScheduleDate()" [disabled] = "!dateChange">Schedule</button>
          </div>
        </div>
      </div>

      <div *ngIf="isSpecCallScheduled && showThanks">
        <ng-container *ngIf="(isPaymentTypePrototype && isPrototypeInstallmentDone); else normalFlow">
          <!-- Branding page -->
          <div class="paymentFlow">
            <div class="paymentStatus branding-block">
              <div class="branding-top">
                <div class="thankyouIcon"> <span class="thumb-icon">  <img src="../../../assets/images/thumb-icon.png" alt="" /></span></div>
                <div class="heading">
                  <h2> Spec Call Scheduled </h2>
                  <p>You’ve booked your call for {{dateString}}.</p>
                </div>
              </div>
              <div class="branding-mid branding-bx">
                <h3>Help us create designs you’ll love, faster </h3>
                <p>  Provide us your logo, branding and colour schemes to kickstart your designer’s work.</p>
                <div class="commonButton">
                  <button type="button" (click)="addBranding()"> Add Branding </button>
                  <div class="or-seprator"> <span>OR</span> </div>
                  <button type="button" (click)="gotoDashboard()" class="outline-btn"> Go to Dashboard </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #normalFlow>
          <!-- Thankyou screen with rating -->
          <div class="paymentFlow">
            <div class="paymentStatus blockedCall-box">
              <div class="branding-top">
                <div class="thankyouIcon">
                  <span class="checkBlock">
                    <i class="icon-right"></i>
                  </span>
                </div>
                <div class="heading">
                  <h2> Your call is booked </h2>
                  <p>Your call is scheduled for <span>{{dateString}}</span>. we’ll send the details on your email id.</p>
                  <a (click)="gotoDashboard()"> Go to idea board </a>
                </div>
              </div>
              <!-- Rating -->
              <div class="rating-block" *ngIf="showHideRating() && !reviewSelected">
                <div class="user-block">
                  <img src="../../../assets/images/normal.png" [ngClass]="(reviewRating == 0 ? '' : 'hide')" alt="" />
                  <img src="../../../assets/images/unsatisfied.png" [ngClass]="(reviewRating <= 3 ? '' : 'hide') || (reviewRating == 0 ? 'hide' : '') " alt="" />
                  <img src="../../../assets/images/satisfied.png" [ngClass]="(reviewRating >= 4 ? '' : 'hide')" alt="" />
                </div>
                <div class="text-block">
                  <div class="top-txt">
                    <h3 class="hd-txt">Rate your overall experience</h3>
                    <div class="star-block">
                      <em class="icon-star" [ngClass]="(reviewRating >= 1 ? 'fillStar' : 'emptyStar')" (click)="ratingScale(1)"></em>
                      <em class="icon-star" [ngClass]="(reviewRating >= 2 ? 'fillStar' : 'emptyStar')" (click)="ratingScale(2)"></em>
                      <em class="icon-star" [ngClass]="(reviewRating >= 3 ? 'fillStar' : 'emptyStar')" (click)="ratingScale(3)"></em>
                      <em class="icon-star" [ngClass]="(reviewRating >= 4 ? 'fillStar' : 'emptyStar')" (click)="ratingScale(4)"></em>
                      <em class="icon-star" [ngClass]="(reviewRating === 5 ? 'fillStar' : 'emptyStar')" (click)="ratingScale(5)"></em>
                    </div></div>
                  <!-- comment area -->
                  <div class="comment-bx" *ngIf="(reviewRating >= 1) && (reviewRating <= 3)">
                    <textarea row="4" name="reviewSubmission" class="comment-txt" [(ngModel)]="reviewText"
                              [placeholder]="setPlaceHolderForFeedbackBox()">
                    </textarea>
                    <div class="action-btn"><button class="text-btn" (click)="reviewSubmit(reviewText)">Submit</button></div>
                  </div>
                  <!-- share link block -->
                  <div class="sharedLinkBlock" *ngIf="(reviewRating > 3)">
                    <h4> Spread the word </h4>
                    <p> Invite your friends and family.</p>
                    <div class="social-link-bx">
                      <p class="link_block">{{repoUrl}}</p>
                      <div class="socialmedia-icon">
                        <button mat-fab shareButton="email" class="shareButton mailIcon" [url]="repoUrl">
                          <fa-icon [icon]="share.prop.email.icon" size="lg"></fa-icon>
                        </button>
                        <button mat-fab shareButton="facebook" class="shareButton facebookIcon" [url]="repoUrl">
                          <fa-icon [icon]="share.prop.facebook.icon" size="lg"></fa-icon>
                        </button>
                        <button mat-fab shareButton="whatsapp" class="shareButton whatsappIcon" [url]="repoUrl">
                          <fa-icon [icon]="share.prop.whatsapp.icon" size="lg"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<!-- rating bottom box-->
<div class="ratingBottombar" *ngIf="!(installmentType === 'support') && !isPaymentTypePrototype && !isInstallmentPage && showHideRating() && !isScheduleCall && bottomRatingPopup && !isSpecCallScheduled">
  <div class="closebtn"><span class="icon-cancel" (click)="(bottomRatingPopup = false)"></span></div>
  <div class="rating-block">
    <div class="user-block">
      <img src="../../../assets/images/normal.png" [ngClass]="(reviewRating == 0 ? '' : 'hide')" alt="" />
      <img src="../../../assets/images/unsatisfied.png" [ngClass]="(reviewRating <= 3 ? '' : 'hide') || (reviewRating == 0 ? 'hide' : '') " alt="" />
      <img src="../../../assets/images/satisfied.png" [ngClass]="(reviewRating >= 4 ? '' : 'hide')" alt="" />
    </div>
    <div class="text-block">
      <div class="top-txt">
        <h3 class="hd-txt">Rate your overall experience</h3>
        <div class="star-block">
          <em class="icon-star" [ngClass]="(reviewRating >= 1 ? 'fillStar' : 'emptyStar')" (click)="ratingScale(1,true)"></em>
          <em class="icon-star" [ngClass]="(reviewRating >= 2 ? 'fillStar' : 'emptyStar')" (click)="ratingScale(2,true)"></em>
          <em class="icon-star" [ngClass]="(reviewRating >= 3 ? 'fillStar' : 'emptyStar')" (click)="ratingScale(3,true)"></em>
          <em class="icon-star" [ngClass]="(reviewRating >= 4 ? 'fillStar' : 'emptyStar')" (click)="ratingScale(4,true)"></em>
          <em class="icon-star" [ngClass]="(reviewRating === 5 ? 'fillStar' : 'emptyStar')" (click)="ratingScale(5,true)"></em>
        </div></div>
      <!-- comment area -->
      <div class="comment-bx" *ngIf="(reviewRating >= 1) && (reviewRating <= 3)">
        <textarea row="4" name="reviewSubmission" class="comment-txt" [(ngModel)]="reviewText"
                  [placeholder]="setPlaceHolderForFeedbackBox()">
        </textarea>
        <div class="action-btn"><button class="text-btn" (click)="reviewSubmit(reviewText)">Submit</button></div>
      </div>
      <!-- share link block -->
      <div class="sharedLinkBlock" *ngIf="(reviewRating > 3)">
        <h4> Spread the word  </h4>
        <p> Invite your friends and family.</p>
        <div class="social-link-bx">
          <p class="link_block">{{repoUrl}}</p>
          <div class="socialmedia-icon">
            <button mat-fab shareButton="email" class="shareButton mailIcon" [url]="repoUrl">
              <fa-icon [icon]="share.prop.email.icon" size="lg"></fa-icon>
            </button>
            <button mat-fab shareButton="facebook" class="shareButton facebookIcon" [url]="repoUrl">
              <fa-icon [icon]="share.prop.facebook.icon" size="lg"></fa-icon>
            </button>
            <button mat-fab shareButton="whatsapp" class="shareButton whatsappIcon" [url]="repoUrl">
              <fa-icon [icon]="share.prop.whatsapp.icon" size="lg"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- call popUp -->
<div class="commonPopUp active" *ngIf="showIdeaBoard">
  <div class="popOverlay"></div>
  <div class="popHolder thankyou-popup">
    <div class="closePopUp" (click)="checkIdeaBoard(false)"><span class="icon-cancel"></span></div>
    <div class="middle-block">
      <div class="left-bx"><img src="../../../assets/images/why-expert-call.jpg" alt="" /></div>
      <div class="right-bx">
        <h2> Why expert call?</h2>
        <ul class="txt-bx">
          <li> You will discuss your idea in details.</li>
          <li> They set right features for your idea.</li>
          <li> You can clear doubts if you have.</li>
        </ul>
        <button type="button" class="brandFillBtn" (click) = "showCalender()"> Schedule a Call </button>
      </div>
    </div>
  </div>
</div>

<!-- ordersummery popUp-->
<div class="commonPopUp" [ngClass]="{'active': showOrderSummary}">
  <div class="popOverlay"></div>
  <div class="popHolder orderSummeryPopup">
    <div class="closePopUp" (click)="showHideOrderSummary(false)"><span class="icon-cancel"></span></div>

    <div class="middle-block">
      <h3 class="head">Transaction Summary</h3>
      <ul class="listing">
        <li *ngIf="(getTransactionID() && (getTransactionID().length > 0))"><span>Transaction ID</span> <span>{{getTransactionID()}}</span></li>
        <li><span>Amount </span> <span>{{ dataService?.user.currency.symbol }}{{ getPaidAmount() }}</span></li>
        <li><span>Date</span> <span>{{getPaidDate() | date: 'dd-MMM-y'}}</span></li>
        <li><span>Payment Type</span> <span>Credit card</span></li>
      </ul>
    </div>
  </div>
</div>
