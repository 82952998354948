import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'currConvert'})
@Injectable()
export class CurrencyconvertDefaultPipe implements PipeTransform {
  public transform(value: number, prevCurrency: any, curCurrency: any): number {
    if (prevCurrency && curCurrency) {
      if (prevCurrency.multiplier !== 1 && curCurrency.multiplier === 1) {
        return ((value / prevCurrency.exchange_rate) * curCurrency.exchange_rate) / prevCurrency.multiplier;
      } else {
        return (value / prevCurrency.exchange_rate * curCurrency.exchange_rate);
      }
    } else {
      return value;
    }
  }
}
