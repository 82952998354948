import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
} from "@angular/core";
import { DataService } from "@shared/services/data.service";
import { PhaseService } from "@shared/services/phase.service";
import { environment } from "@env/environment";
import { Angulartics2Segment } from "angulartics2/segment";
@Component({
  selector: "app-natasha-communication",
  templateUrl: "./natasha-communication.component.html",
  styleUrls: ["./natasha-communication.component.scss"],
})
export class NatashaCommunicationComponent implements OnInit, OnChanges {
  @Input("isStartNatashaSession") isStartNatashaSession;
  @Output() showNatshaDesign = new EventEmitter();
  socket;
  session_id;
  natashaChats = [];
  natashaChatInput = "";
  @ViewChild("natashaPannel") natashaPannel: ElementRef;
  isShowUpwardArrow = false;
  isShowDownwardArrow = false;
  shouldShowNatashaChats = true;
  shouldDisableNatashaChatInput = false;
  observer;

  constructor(
    public dataService: DataService,
    public phaseService: PhaseService,
    private analyticsSegment: Angulartics2Segment
  ) {}

  ngOnInit(): void {
    // console.log('buildCardData- ', this.dataService.buildCardData);
    if (!this.isStartNatashaSession && !this.session_id) {
      this.session_id = this.uuid();
      this.socket = new WebSocket(environment.DLS_STORE_NATASHA_URL);

      this.startNatshaConnection();
      this.receiveNatashaMessage();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("buildCardData- ", this.dataService.buildCardData);
    // if (this.isStartNatashaSession) {
    // this.session_id = this.uuid();
    //   this.socket = new WebSocket(
    //     "wss://studio-ai-assistant-apis.studio.stage.eastus.az.svc.builder.ai/cable"
    //   );
    // this.startNatshaConnection();
    // this.receiveNatashaMessage();
    // }
    // if (!this.isStartNatashaSession && this.session_id) {
    // this.stopNatashaConnection();
    // this.closeNatashaConnection();
    // }
  }

  startNatshaConnection() {
    this.socket.onopen = () => {
      const msg = {
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "StudioStoreChannel",
          session_id: this.session_id,
        }),
      };
      this.socket.send(JSON.stringify(msg));
    };
  }

  receiveNatashaMessage() {
    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // console.log(data);
      if (data.type === "confirm_subscription") {
        this.natashaInItMessage();
      } else if (data.message?.action === "event_response") {
        this.getNatashaResponse(data.message);
      }
    };
  }

  getNatashaResponse(message: any) {
    if (message?.natasha_chats_params?.now_design_data) {
      message = message?.natasha_chats_params?.now_design_data;
    } else if (message?.ai_chats_session?.natasha_chats_params?.actions) {
      message = message?.ai_chats_session?.natasha_chats_params?.actions;
    }
    // message = message?.natasha_chats_params?.now_design_data || message?.ai_chats_session?.natasha_chats_params?.actions;
    if (!message) {
      return;
    }
    message.forEach((current) => {
      let len = this.natashaChats.length;

      // console.log('current- ', current);
      if (current?.conversational) {
        current.conversational["showLoader"] = false;
        if (
          len > 0 &&
          this.natashaChats[len - 1]?.conversational?.showLoader &&
          this.natashaChats[len - 1]?.conversational?.user_type === "natasha"
        ) {
          let i = 0;
          let currentResponse = current?.conversational?.response;
          let currentResponseWordsArray = currentResponse.split(" ");
          this.natashaChats[len - 1].conversational.response = "";
          let test = setInterval(() => {
            if (i === currentResponseWordsArray.length - 1) {
              current.conversational.response =
                this.natashaChats[len - 1].conversational.response +
                currentResponseWordsArray[i];
              this.natashaChats[len - 1] = current;
              if (
                this.natashaChats[len - 2].conversational?.user_type === "user"
              ) {
                this.phaseService.trackEventForObject(
                  this.analyticsSegment,
                  "natasha_chat_interaction",
                  {
                    user_id: this.dataService.user?.id,
                    keyword:
                      this.natashaChats[len - 2].conversational?.response,
                    natasha_response:
                      this.natashaChats[len - 1].conversational?.response,
                  }
                );
              }
              clearInterval(test);
            } else {
              current.conversational.response =
                this.natashaChats[len - 1].conversational.response +
                currentResponseWordsArray[i] +
                " ";
              this.natashaChats[len - 1] = current;
            }

            i++;
          }, 0);
          // let test = setInterval(() => {
          //   if (i===0) {
          //     current.conversational.response = currentResponse.substring(i, i+3);
          //     this.natashaChats[len-1] = current;

          //   } else {
          //     current.conversational.response = this.natashaChats[len-1].conversational.response + currentResponse.substring(i, i+3);
          //     this.natashaChats[len-1] = current;

          //   }
          //   if (i >= currentResponse.length) {
          //       clearInterval(test);
          //   }
          //   i=i+3;
          // }, 200);
        } else {
          this.natashaChats.push(current);
        }
        // console.log('current- ', current);
      } else if (current) {
        this.phaseService.showLoaderInNatashaDesignIFrame = true;
        setTimeout(() => {
          this.emitNatshaDesign(current);
        }, 1000);
      }
      this.scrollTop();
      this.scrollEllementTopToitsHeight();
    });
    this.shouldDisableNatashaChatInput = false;
    // console.log("this.natashaChats- ", this.natashaChats);
  }

  natashaInItMessage() {
    let message = {
      trigger_type: "session-event",
      event_type: "start-chat",
      timestamp: this.getCurrentTimestamp(),
    };
    this.sentPayloadOverWebSocket(message);
  }

  sentPayloadOverWebSocket(message) {
    let payload = this.getFixedPayload();
    payload.data = JSON.stringify({ ...payload.data, ...message });
    // console.log("payload- ", payload);
    this.socket.send(JSON.stringify(payload));
  }

  getFixedPayload() {
    const msg: any = {
      command: "message",
      identifier: JSON.stringify({
        channel: "StudioStoreChannel",
        session_id: this.session_id,
      }),
      data: {
        channel: "studio-store",
        session_id: this.session_id,
        build_card_id: this.dataService.buildCardData?.id,
      },
    };
    return msg;
  }

  uuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  inputChatHandler(event) {
    this.natashaChatInput.trim();
    var regex = /^[^a-zA-Z0-9]+$/;
    var matchedAuthors = regex.test(this.natashaChatInput);
    if (matchedAuthors) {
      return;
    }
    if (this.natashaChatInput && this.natashaChatInput.length > 0) {
      let message = {
        trigger_type: "chat-event",
        timestamp: this.getCurrentTimestamp(),
        chat_input: {
          payload: "chat-message",
          message: this.natashaChatInput,
        },
      };
      this.sentPayloadOverWebSocket(message);
      this.showUserResponse(this.natashaChatInput);
      this.natashaChatInput = "";
      if (
        this.isStartNatashaSession &&
        this.natashaChats &&
        this.natashaChats.length === 3
      ) {
        this.observeUserInputBoxElement();
      }
    }
  }

  showUserResponse(userInputMessage) {
    let obj = {
      conversational: {
        user_type: "user",
        response: userInputMessage,
      },
    };
    this.natashaChats.push(obj);
    this.scrollTop();
    this.scrollEllementTopToitsHeight();
    let msg = {
      conversational: {
        showLoader: true,
        user_type: "natasha",
      },
    };
    this.natashaChats.push(msg);
    this.shouldDisableNatashaChatInput = true;
    setTimeout(() => {
      this.shouldDisableNatashaChatInput = false;
    }, 10000);
  }

  stopNatashaConnection() {
    let message = {
      trigger_type: "session-event",
      event_type: "end-chat",
      timestamp: this.getCurrentTimestamp(),
    };
    this.sentPayloadOverWebSocket(message);
  }

  closeNatashaConnection() {
    const msg = {
      command: "unsubscribe",
      identifier: JSON.stringify({
        channel: "StudioStoreChannel",
        session_id: this.session_id,
      }),
    };
    this.socket.send(JSON.stringify(msg));

    this.socket.close();
    this.socket.onclose = (event) => {
      console.log("WebSocket closed", event.code, event.reason);
      this.session_id = null;
      this.natashaChats = [];
    };
  }

  natashaError() {
    this.socket.onerror = (error) => {
      console.error("WebSocket error", error);
    };
  }

  getCurrentTimestamp() {
    return new Date().toISOString();
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  scrollEllementTopToitsHeight() {
    setTimeout(() => {
      if (!this.natashaPannel) {
        return;
      }
      this.natashaPannel.nativeElement.scrollTop =
        this.natashaPannel?.nativeElement.scrollHeight;
      const element = document.querySelector(".natashaChatViewport");
      const isNatashaChatInViewport = this.isInViewport(element);
      if (!isNatashaChatInViewport) {
        this.isShowUpwardArrow = true;
      }
    }, 225);
  }

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return rect.top > 115 && rect.y > 115;
  }

  scrollNatashaToTop() {
    setTimeout(() => {
      this.natashaPannel.nativeElement.scrollTop = 0;
      this.isShowUpwardArrow = false;
      if (this.natashaChats && this.natashaChats.length >= 3) {
        this.isShowDownwardArrow = true;
      }
    }, 100);
  }

  scrollNatashaToBottom() {
    this.scrollTop();
    this.scrollEllementTopToitsHeight();
    this.isShowDownwardArrow = false;
    this.isShowUpwardArrow = true;
  }

  public emitNatshaDesign(msg) {
    this.showNatshaDesign.emit(msg);
  }

  toggleNatashaChats() {
    this.shouldShowNatashaChats = !this.shouldShowNatashaChats;
    this.phaseService.shouldBlurDlsStoreNatashaBackground =
      !this.phaseService.shouldBlurDlsStoreNatashaBackground;
    this.scrollNatashaToTop();
  }

  observeUserInputBoxElement() {
    this.observer = new IntersectionObserver(
      (entries) => {
        const userInputBox = entries[0];
        if (userInputBox.isIntersecting) {
          this.isShowDownwardArrow = false;
          this.isShowUpwardArrow = true;
        } else {
          const element = document.querySelector(".natashaChatViewport");
          const isNatashaChatInViewport = this.isInViewport(element);
          if (!isNatashaChatInViewport) {
            this.isShowUpwardArrow = false;
            this.isShowDownwardArrow = true;
          }
        }
      },
      {
        root: document.getElementById("innerContainerBox"),
        threshold: [0],
      }
    );
    let value = document.getElementById("userInputArea");
    this.observer.observe(value);
  }
}
