<div class="dashHeading">Freemium {{ (dataService.getCardCount('freemium') > 1) ? 'Cards' : 'Card' }}
  <button type="button" (click)="showCards(12)" *ngIf="showRecentFreemium && dataService.getCardCount('freemium') > 4">Show all </button>
</div>

<div class="dashProjectRow hideCard tabContent" infiniteScroll [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="300" (scrolled)="onScroll(dataService.getCardCount('freemium'))">
  <div class="progressCard visibleOverflow" *ngFor="let card of (showRecentProgress ? (getFreemiumCards() | slice:0: (dataService.getCardCount('freemium') > 4 ? 4: dataService.getCardCount('freemium'))) : getFreemiumCards());let i = index;">
    <div class="cardHead">
      <div class="cardLeft">
        <h3>{{ card?.project_name }} </h3>
      </div>
      <div class="cardRight">
        <div class="cardMore">
          <em class="icon-more"></em>
          <div class="cardDropDown">
            <ul>
              <li (click)="openBuildCardPdf(card)">Download PDF</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="cardMiddle">
      <div class="completeDetail">
        <div class="completeRow">
          <div class="completeBox">
            <h3>{{card.template_count > 1 ? 'Templates' : 'Template'}} <span>{{card.template_count}}</span></h3>
          </div>
          <div class="completeBox">
            <h3>Features <span>{{card.feature_count}}</span></h3>
          </div>
        </div>
        <div class="completeRow">
          <h4>
            <div class="team-dash-country">Team <span>{{card.team? card.team:""}}</span></div>
          </h4>
          <h4>
            <div>Duration
              <ng-container *ngIf="card.frequency === 'monthly'; else showWeekly">
                 <span>{{card.installment_counts.monthly}} {{ card.installment_counts.monthly > 1 ? 'months' : 'month'}}
                ({{(card.weeks + (card.change_week_status ? card.change_week : -card.change_week))}}
              {{ (card.weeks + (card.change_week_status ? card.change_week : -card.change_week)) > 1 ? 'weeks' : 'week'}})</span>
              </ng-container>
              <ng-template #showWeekly>
                <span>{{(card.weeks + (card.change_week_status ? card.change_week : -card.change_week))}}
              {{ (card.weeks + (card.change_week_status ? card.change_week : -card.change_week)) > 1 ? 'weeks' : 'week'}}</span>
              </ng-template>
            </div>
          </h4>
        </div>
      </div>
      <div class="projectPhases">
        <ul>
          <li *ngFor="let phase of card.build_card_phases">
            <div class="phaseTitle">{{phase?phase.title:""}}</div>
            <div class="phaseDetail" *ngIf="!phase.is_tailor_made && phase.platforms?.length > 0">
              <div class="phaseIcon" *ngFor="let platform of phase.platforms">
                <img [src]="platform.icon" alt="" />
                <div class="phaseIconTooltip">{{platform.title}}</div>
              </div>
            </div>

            <div class="phaseDetail" *ngIf="phase.is_tailor_made &&  phase.platforms?.length > 1">
              <div class="morePhase" >+ {{ phase.platforms?.length - 1 }}
                <div class="platformTooltip">
                  <h5>{{ phase.platforms?.length || 0 }} Platform Selected</h5>
                  <h6 *ngFor="let platform of phase.platforms"><em class="icon-right"></em>{{ platform.title }}</h6>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="cardBottom" *ngIf="card.status === 'card_completed'">
      <button type="button" (click)="proceedToCompleteCard(card)">Validate Now</button><!-- disabled -->
      <div class="clearfix"></div>
    </div>
    <div class="cardBottom" *ngIf="card.status !== 'card_completed'">
      <button type="button" [ngClass]="{'custom-disabled': !card?.admin_urls?.table?.store_front_url}"
              [disabled]="!card?.admin_urls?.table?.store_front_url" (click)="navigateToAdminBoard(card)">Set up your store
      </button><!-- Admin URL -->
      <button type="button" class="btnValidate">Approved</button><!-- Validated -->
    </div>
  </div>
</div>

<app-no-cards *ngIf="dataService.getCardCount('freemium') < 1">

</app-no-cards>
