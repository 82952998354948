import {Component, EventEmitter, NgZone, Output, ViewChild, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Subject, Subscription, interval, forkJoin} from 'rxjs';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {FeatureModel} from '@models/feature-model/feature.model';
import {UtilityService} from '@shared/services/utility.service';
import { Intercom } from 'ng-intercom';
import { Options } from '@angular-slider/ngx-slider';
import { AppDataService } from '@rootmodule/app-data.service';
import {CommonService} from '@shared/services/common.service';
import { filter, debounce, tap, switchMap, map } from 'rxjs/operators';
import {DragScrollComponent} from 'ngx-drag-scroll';

@Component({
  selector: "addnewfeature",
  templateUrl: "addnewfeature.component.html",
  styleUrls: ['addnewfeature.component.scss']
})
export class AddfeatureComponent implements OnInit, OnDestroy {
  public showError = false;
  public errormessage;
  public singleUrl = null;
  public featureExist = false;
  public preventClick = false;
  public showAdvanceOption = false;
  public searchObservable: Subscription;
  public showLoaderForUrl = false;
  public productsList = [];
  public algoName = '';
  @ViewChild('title1') titleViewChildren;
  @ViewChild('title') public title;
  @ViewChild('description') public description;
  @ViewChild('appUrl') public appUrl;
  value = 6;
  options: Options = {
    showSelectionBar: true,
    floor: 1,
    ceil: 10
  };
  @Output() addObjToNatashaChats: EventEmitter<any> = new EventEmitter();
  customFeatureForm: FormGroup;
  featureRecommendationsFromNatashaSearch: any;
  showLoaderForNatashaRecommendationModal = false;
  isFocusedOutDesc = false;
  editorConfig = {
    placeholder: 'What does it do and how do people interact with it?',
  };
  inputTextSubject: Subject<string> = new Subject();
  featureDetails: any;
  describeFeatureClicked = true;
  showRecommendedFeatures = false;
  openAdvanceOptions = false;
  showCostAndTimeline = false;
  recommendedFeatures = [];
  showLoadingScreen = false;
  leftFeatureNavDisabled = false;
  rightFeatureNavDisabled = false;
  @ViewChild('navFeatureSliderPopUp', { read: DragScrollComponent }) navFeatureSliderPopUp: DragScrollComponent;
  isShowWarningPopup = false;
  addedFeaturesCopy: any;
  defaultCustomFeaturePrice: number = 0;
  defaultCustomFeatureDuration: number = 0;
  searchText: string = "";
  searchQuery: string = "";
  filteredCoreFeatures: Array<FeatureModel>;

  /*@ngInject*/
  constructor(
    public phaseService: PhaseService,
    public dataService: DataService,
    public utilityService: UtilityService,
    public router: Router,
    public analyticsSegment: Angulartics2Segment,
    public apiRequest: ApiRequests,
    public zone: NgZone,
    public appDataService: AppDataService,
    public commonService: CommonService,
    private ref: ChangeDetectorRef,
  ) {
    this.commonService.setEditorConfiguration();
  }

  public ngOnInit() {
    if (this.getAddedFeature()?.id) {
      this.addedFeaturesCopy = JSON.parse(JSON.stringify(this.getAddedFeature()));
    }
    if (this.phaseService.isClearCustomFeatureModalValues) {
      this.resetFeatureForm();
    }
    this.subscribeToTextSearch();
  }

  public updateFeatureExist() {
    this.featureExist = false;
  }

  public addFeatureUpdateRequest() {
    this.trimDescription();
    this.trimTitle();
    if (!this.getAddedFeature().description || !this.getAddedFeature().title) {
      return;
    }
    if (!this.preventClick) {
      this.preventClick = true;
      this.editFeature();
    }
  }

  public setDataInPhases(phase) {
    if ((phase.title !== 'MVP') && (phase['type'] !== 'custom_phase')) {
      phase.features = this.phaseService.selectedFeatures;
    }
  }

  public selectPhases(): void {
    for (const buildType of this.dataService.homePageData.build_phases) {
      this.setDataInPhases(buildType);
    }
  }

  public updateSelectedPhases() {
    this.phaseService.selectedPhases.forEach(phase => {
      if ((phase.title !== 'MVP') && (phase['type'] !== 'custom_phase')) {
        phase.features = this.phaseService.selectedFeatures;
      }
    });
  }

  public addFeature(form: FormGroup, isFromNatashaRecommendationModal?): void {
    if (isFromNatashaRecommendationModal) {
      this.showLoaderForNatashaRecommendationModal = true;
    }
    if (this.isFeatureExist()) {
      this.preventClick = false;
      if (isFromNatashaRecommendationModal) {
        this.showLoaderForNatashaRecommendationModal = false;
        this.phaseService.isShowFeatureRecommendationsModal = false;
      }
      this.titleViewChildren.nativeElement.focus();
    } else {
      if (this.phaseService.selectedHash.application_ids.length > 0) {
        this.phaseService.addedFeature.template_ids = this.phaseService.selectedHash.application_ids;
      }
      this.apiRequest.addNewFeature(this.phaseService.addedFeature).subscribe(
        (data: any) => {
          const feature: FeatureModel = data.data.attributes;
          form.reset();
          this.phaseService.isClearCustomFeatureModalValues = true;
          this.resetFeatureForm();
          if (isFromNatashaRecommendationModal) {
            this.showLoaderForNatashaRecommendationModal = false;
          }
          this.performActionsPostCustomFeatureCreation(feature);
        },
        error => {
          this.preventClick = false;
          if (isFromNatashaRecommendationModal) {
            this.showLoaderForNatashaRecommendationModal = false;
          }
          this.showAdvanceOption = false;
          this.showError = true;
          this.errormessage = "Feature could not be added. Please try again.";
        }
      );
    }
  }

  public editFeature(): void {
    if (this.isFeatureExist()) {
      this.preventClick = false;
      this.titleViewChildren.nativeElement.focus();
    } else {
      this.apiRequest.editFeature(this.phaseService.addedFeature).subscribe(
        (data: any) => {
          const feature: FeatureModel = data.data.attributes;
          if (this.dataService.editedFeatures.indexOf(feature.id) === -1) {
            this.dataService.editedFeatures.push(feature.id);
          }
          feature.selected = this.phaseService.addedFeature.selected;
          this.phaseService.addnewfeature = false;
          this.resetFeatureForm();
          this.updateFeature(feature);
          this.preventClick = false;
          this.showAdvanceOption = false;
          this.openAdvanceOptions = false;
          this.describeFeatureClicked = false;
        },
        error => {
          this.preventClick = false;
          this.showAdvanceOption = false;
          this.showError = true;
          this.errormessage = "Feature could not be added. Please try again.";
        }
      );
    }
  }

  public updateFeature(feature: FeatureModel): void {
    this.phaseService.requestedFeatureList.forEach((x, index) => {
      if (x.id === feature.id) {
        this.phaseService.requestedFeatureList[index] = feature;
      }
    });
    let index = this.phaseService.selectedFeatures.findIndex(
      selectedFeature => {
        return feature.id === selectedFeature.id;
      }
    );
    feature.feature_note = this.phaseService.selectedFeatures.find(f => f.id === feature.id).feature_note;
    if (index > -1) {
      this.phaseService.selectedFeatures[index] = feature;
    }
    index = this.phaseService.selectedHash.feature_ids.findIndex(
      selectedFeatureId => {
        return feature.id === selectedFeatureId;
      }
    );
    if (index > -1) {
      this.phaseService.selectedHash.feature_ids[index] = feature.id;
    }

    this.dataService.updateCustomFeature.next(true);
    this.selectPhases();
    this.updateSelectedPhases();
    this.phaseService.updatePrice();
  }

  public resetFeatureForm() {
    this.featureExist = false;
    this.value = 6;
    this.phaseService.addedFeature = {
      id: "",
      title: "",
      description: "",
      template_ids: [],
      difficulty: 6,
      algorithm: "none",
      stream_media: false,
      interface_type: "backend",
      reference_urls: [],
      dependent_features: []
    };
    this.phaseService.isClearCustomFeatureModalValues = false;
    this.phaseService.recommendedCoreFeaturesToBeAdded = [];
  }

  public addUrl() {
    if (!this.preventClick) {
      if (this.singleUrl != null) {
        const tempProduct = {
          product_name: this.singleUrl,
          description: "",
          url: "",
          image: "",
          tag: ""
        };
        this.phaseService.addedFeature.reference_urls.push(tempProduct);
        this.singleUrl = null;
      }
    }
  }

  public deleteAppUrl(i) {
    if (!this.preventClick) {
      this.phaseService.addedFeature.reference_urls.splice(i, 1);
    }
  }

  public selectDifficulty(i) {
    this.phaseService.addedFeature.difficulty = i;
    if (i >= 1 && i < 5) {
      this.phaseService.difficultyText = "Easy";
    } else if (i >= 5 && i <= 7) {
      this.phaseService.difficultyText = "Moderate";
    } else {
      this.phaseService.difficultyText = "Difficult";
    }
  }

  public isFeatureExist(): boolean {
    if (this.phaseService.addedFeature.title != null) {
      this.featureExist = false;
      if (this.phaseService.requestedFeatureList.length > 0) {
        for (const feature of this.phaseService.requestedFeatureList) {
          if (
            this.getAddedFeature().id !== feature.id &&
            feature.title.trim().toLowerCase() ===
              this.phaseService.addedFeature.title.trim().toLowerCase()
          ) {
            this.featureExist = true;
            break;
          }
        }
      }
      if (!this.featureExist && this.dataService.featureBundles.length > 0) {
        for (const bundle of this.dataService.featureBundles) {
          if (
            bundle.features
          ) {
            for (const feature of bundle.features) {
              if (
                feature.title.trim().toLowerCase() ===
                this.phaseService.addedFeature.title.trim().toLowerCase()
              ) {
                this.featureExist = true;
                break;
              }
            }
          }
        }
      }
    }
    return this.featureExist;
  }

  public closeAddFeature() {
    if (!this.preventClick) {
      this.phaseService.addnewfeature = false;
      this.showAdvanceOption = false;
      this.resetFeatureForm();
      if (this.getAddedFeature()?.id) {
        this.phaseService.addedFeature = this.addedFeaturesCopy;
      }
    }
  }
  public searchProducts(evt) {
    const searchText = evt.target.value;
    this.singleUrl = searchText;
    this.inputTextSubject.next(searchText);
  }

  subscribeToTextSearch() {
    this.searchObservable = this.inputTextSubject.pipe(
      filter(text => text.length > 2),
      tap(() => { this.showLoaderForUrl = true, this.productsList = [] }),
      debounce(() => interval(1000)),
      switchMap(searchText => this.apiRequest.fetchProductsList(searchText)),
      tap((data: any) => {
        console.log(data);
        this.productsList = data.data;
        this.showLoaderForUrl = false;
        this.ref.detectChanges();
      }, () => {
        this.productsList = [];
        this.showLoaderForUrl = false;
        this.ref.detectChanges();
      })
    ).subscribe();
  }

  public selectProduct(product?) {
    const urls = this.phaseService.addedFeature.reference_urls;
    if (product) {
      if (this.singleUrl) {
        urls.push(product);
        this.singleUrl = null;
        this.productsList = [];
      }
    }
  }

  public isAddNewFeature(): boolean {
    return this.phaseService.addnewfeature;
  }

  public getAddedFeature(): any {
    return this.phaseService.addedFeature;
  }

  public isValidFeatureName(): boolean {
    return (
      this.title &&
      this.title.errors &&
      (this.title.dirty || this.title.touched)
    );
  }

  public showDescriptionLength(): boolean {
    return (
      this.getAddedFeature().description &&
      this.getAddedFeature().description.length > 0
    );
  }

  getDescriptionLength() {
    if (this.getAddedFeature().description) {
      const html = this.getAddedFeature().description;
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.innerText.length;
    }
  }

  public getDescriptionRemaining(): any {
    return this.getAddedFeature().description ? this.getDescriptionLength() : 0;
  }

  trimDescription() {
    this.isFocusedOutDesc = true;
    this.getAddedFeature().description = this.getAddedFeature().description.trim();
  }

  trimTitle() {
    this.getAddedFeature().title = this.getAddedFeature().title.trim();
  }

  public isDescriptionValid(): boolean {
    return (
      this.description &&
      this.description.errors &&
      (this.description.dirty || this.description.touched)
    );
  }

  public showHideAdvanceOptions() {
    this.showAdvanceOption = !this.showAdvanceOption;
  }

  public getDifficultyText(): any {
    return this.phaseService.difficultyText;
  }

  public showAddCTA() {
    return this.productsList.length === 0 && this.singleUrl && this.singleUrl.length > 2 && !this.showLoaderForUrl;
  }

  public isLinkValid(): boolean {
    return (
      this.appUrl &&
      this.appUrl.errors &&
      (this.appUrl.dirty || this.appUrl.touched) &&
      this.productsList.length == 0 &&
      !this.showLoaderForUrl
    );
  }

  public isFormValid(): boolean {
    return (!this.getAddedFeature().title || (this.title && this.title.errors &&
      (this.title.dirty || this.title.touched)) || !this.getAddedFeature().description ||
      (this.getAddedFeature().description && (this.getDescriptionLength() > 1000))
    );
  }

  setAlgorithm(algorithm) {
    this.getAddedFeature().algorithm = algorithm;
  }

  setInterfaceType(interfaceType) {
    this.getAddedFeature().interface_type = interfaceType;
  }

  public getAlgoName(){
    if (this.getAddedFeature().algorithm == 'machine_learning'){
      this.algoName = 'Machine Learning';
    }
    if(this.getAddedFeature().algorithm == 'augmented_reality'){
      this.algoName = 'Augmented Reality';
    }
    return this.algoName;
  }

  checkForRecommendationsOrAddNewFeature(form: FormGroup) {
    this.trimDescription();
    this.trimTitle();
    if (!this.getAddedFeature().description || !this.getAddedFeature().title) {
      return;
    }
    this.customFeatureForm = form;
    if (!this.preventClick) {
      this.preventClick = true;
      let query = this.getAddedFeature().title + " " + this.getAddedFeature().description;
      let selectedFeatures;
      selectedFeatures = this.isFeatureRequest ? this.phaseService.selectedHash.feature_ids : this.getSelectedFeatures();
      if (this.phaseService.selectedHash.application_ids.length > 0) {
        this.phaseService.addedFeature.template_ids = this.phaseService.selectedHash.application_ids;
      }
      this.apiRequest.fetchNatashaFAQs(query, selectedFeatures, this.phaseService.addedFeature).subscribe((data) => {
        this.preventClick = false;
        if (data.features) {
          let feature = data.features;
          if (Array.isArray(feature) && feature.length > 0) {
            feature = data.features.map(feature => feature.attributes);
            this.featureRecommendationsFromNatashaSearch = feature;
            this.phaseService.isShowFeatureRecommendationsModal = true;
          } else {
            feature = data.features.attributes;
            form.reset();
            this.phaseService.isClearCustomFeatureModalValues = true;
            this.resetFeatureForm();
            this.performActionsPostCustomFeatureCreation(feature);
          }
        }
      },
      error => {
        this.preventClick = false;
        this.showAdvanceOption = false;
        this.showError = true;
        this.errormessage = "Feature could not be added. Please try again.";
      }
      );
    }
  }

  public getSelectedFeatures(): FeatureModel[] {
    return this.phaseService.selectedFeatures.filter((feature, index) => this.phaseService.selectedFeatures.indexOf(feature) === index);
  }

  get isFeatureRequest() {
    return this.appDataService.urlParameters.featureRequest;
  }

  performActionsPostCustomFeatureCreation(feature: FeatureModel) {
    feature.new_feature_added = true;
    feature.selected = true;
    this.phaseService.addnewfeature = false;
    this.showLoadingScreen = false;
    this.phaseService.isShowFeatureRecommendationsModal = false;
    this.phaseService.requestedFeatureList.push(feature);
    this.phaseService.toggleSelectedFeatures(feature);
    this.phaseService.toggleSelectedFeatureId(feature.id);
    this.phaseService.updateFeaturedAddedMessage(feature);
    this.selectPhases();
    this.updateSelectedPhases();
    this.phaseService.updatePrice();
    this.phaseService.clearMessage();
    this.preventClick = false;
    this.showAdvanceOption = false;
    setTimeout(() => {
      this.ref.detectChanges();
    }, 2000);
    this.phaseService.trackEventForObject(this.analyticsSegment, "feature_custom_added", {
      user_id : this.dataService.user?.id,
      feature_name : feature.title
    });
  }

  incrementRecommendedFeatureSliderIndex() {
    let index = this.phaseService.recommendedFeaturesSliderIndex++;
    return index;
  }

  getMessageIdentifierForMultipleFeatures(itemIds) {
    let featureItemIds = '';
    itemIds.sort();
    for (let i = 0; i < itemIds.length; i++) {
      featureItemIds += itemIds[i];
    }
    let messageIdentifier = 'multipleFeatures_' + featureItemIds;
    return messageIdentifier;
  }

  getTime() {
    return this.phaseService.getCurrentTimeInHoursMinutes();
  }

  setMandatoryInSelectedFeatures(features) {
    if (this.phaseService.mandatoryFeatures.length) {
      const mandatoryFeaturesIds = this.phaseService.mandatoryFeatures.map(feature => feature.id);
      features.map((selectedFeature) => {
        if ((mandatoryFeaturesIds.indexOf(selectedFeature.id) > -1)) {
          selectedFeature.is_mvp_feature = true;
        }
      });
    }
  }

  showFeatureRecommendations(feature) {
    this.phaseService.isShowFeatureRecommendationsModal = false;
    this.phaseService.addnewfeature = false;
    let obj: any = {};
    let item_id = [];
    for (let i = 0; i < feature.length; i++) {
      let fobj = feature[i];
      item_id.push(fobj.id);
    }
    let messageIdentifier = this.getMessageIdentifierForMultipleFeatures(item_id);
    if (feature.length === 1) {
      let text1 = "Basis your query, here is the feature from our catalogue.";
      let messageIdentifier = 'singleFeature_' + item_id[0];
      obj = {
        itemId: item_id[0], itemType: 'feature', questionText: text1, recommendedFeatures: feature,
        natashaSingleFeatureConditionStatus: true, showLoader: true, createdAt: this.getTime(), timeStamp: this.phaseService.getCurrentTimestamp(), shouldShowActionButtons: true, shouldShowSingleRecommendedFeature: true,
        messageIdentifier: messageIdentifier, isFromNatashaSearch: true, isFromCustomFeatureModal: true
      };
      this.setMandatoryInSelectedFeatures(obj.recommendedFeatures);
    } else if (feature.length > 1) {
      let text1 = "Okay, these features could be useful.";
      obj = {
        itemId: item_id, itemType: 'feature', text: text1, recommendedFeatures: feature,
        sliderIndex: this.incrementRecommendedFeatureSliderIndex(), condition8Status: true, showLoader: true, createdAt: this.getTime(), timeStamp: this.phaseService.getCurrentTimestamp(),
        shouldShowActionButtons: true, shouldShowRecommendedFeatures: true, messageIdentifier: messageIdentifier, isFromNatashaSearch: true, isFromCustomFeatureModal: true
      };
      this.setMandatoryInSelectedFeatures(obj.recommendedFeatures);
    }
    this.phaseService.setDurationPriceInSelectedFeatures(obj.recommendedFeatures);
    this.addObjToNatashaChats.emit(obj);
  }

  closeRecommendationsModal() {
    this.phaseService.isShowFeatureRecommendationsModal = false;
  }

  getEditorData(event: any) {
    this.getAddedFeature().description = event.editor.getData();
  }

  parseHtmlString(string) {
    const html = string;
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.innerText;
  }

  isInvalidLength() {
    if (this.getAddedFeature().description) {
      return (this.parseHtmlString(this.getAddedFeature().description).trim().length > 1000);
    }
  }

  getSelectedCoreFeatures() {
    return this.phaseService.selectedFeatures.filter((feature) => feature.user_requested === false);
  }

  setFeatureChecked(feature) {
    if (this.phaseService.addedFeature.feature_ids && (this.phaseService.addedFeature.feature_ids.length > 0)) {
      for (let i=0; i<this.phaseService.addedFeature.feature_ids.length ; i++) {
        let id = this.phaseService.addedFeature.feature_ids[i];
        if (id === feature.id) {
          return true;
        }
      }
    }
    return false;
  }

  toggleFeatureSelection(feature) {
    if (this.phaseService.addedFeature.feature_ids && (this.phaseService.addedFeature.feature_ids.length > 0)) {
      const found = this.phaseService.addedFeature.feature_ids.find(id => id === feature.id);
      if (found) {
        const index = this.phaseService.addedFeature.feature_ids.indexOf(feature.id);
        this.phaseService.addedFeature.feature_ids.splice(index, 1);
      } else {
        this.phaseService.addedFeature.feature_ids.push(feature.id);
      }
    } else {
      this.phaseService.addedFeature.feature_ids.push(feature.id);
    }
  }

  isInvalidMinimumCharLength() {
    return (this.parseHtmlString(this.getAddedFeature().description).trim().length < 100);
  }

  showFooterAndFirstScreen() {
    return (this.describeFeatureClicked || this.showRecommendedFeatures || this.openAdvanceOptions ||
      this.showCostAndTimeline);
  }

  describeCustomFeature() {
    this.describeFeatureClicked = true;
  }

  createAndAddCustomFeature() {
    if (this.phaseService.selectedHash.application_ids.length > 0) {
      this.phaseService.addedFeature.template_ids = this.phaseService.selectedHash.application_ids;
    }
    this.preventClick = true;
    this.apiRequest.addNewFeature(this.phaseService.addedFeature).subscribe(
      (data: any) => {
        const feature: FeatureModel = data.data.attributes;
        this.phaseService.isClearCustomFeatureModalValues = true;
        this.resetFeatureForm();
        this.performActionsPostCustomFeatureCreation(feature);
        this.commonService.featureAdded.next('added');
      },
      error => {
        this.showError = true;
        this.preventClick = false;
        this.errormessage = 'Feature could not be added. Please try again.';
      }
    );
  }

  getComplexityAndRecommendations() {
    if (!this.getAddedFeature()?.id) {
      this.customToCoreFeature();
      this.getAdvanceOptionsData();
    } else {
      this.openAdvanceOptions = true;
      this.showLoadingScreen = false;
      this.describeFeatureClicked = false;
    }
  }

  getAdvanceOptionsData() {
    const payload = { description: this.getAddedFeature()?.description };
    this.apiRequest.getCustomFeatureComplexity(payload).subscribe(resp => {
      this.getAddedFeature().difficulty = resp?.difficulty;
      this.getAddedFeature().algorithm = resp?.algo_type?.toLowerCase()?.replace(' ', '_');
      this.getAddedFeature().interface_type = resp?.UI_needed === 0 ? 'backend' : 'both';
    }, error => {
      console.log(error);
    });
  }

  customToCoreFeature() {
    const payload = {
        build_card_id: this.dataService.buildCardData?.id,
        description: this.getAddedFeature()?.description,
        currency_id: this.dataService.buildCardData.currency.id,
        selected_feature_ids: this.phaseService.selectedFeatures.map(feature => feature.id)
      };
    this.showLoadingScreen = true;
    this.apiRequest.customToCoreSearch(payload).subscribe(resp => {
      this.describeFeatureClicked = false;
      if ((resp?.features?.length > 0) && (resp.features.filter(ftr => !ftr.attributes.selected)?.length > 0)) {
        this.showRecommendedFeatures = true;
        this.showLoadingScreen = false;
        this.recommendedFeatures = resp.features.map(feature => feature.attributes);
        this.recommendedFeatures = this.recommendedFeatures.filter(feature => !feature.selected);
        this.defaultCustomFeaturePrice = resp.custom_feature_price;
        this.defaultCustomFeatureDuration = resp.custom_feature_duration;
      } else {
        this.openAdvanceOptions = true;
        this.showLoadingScreen = false;
      }
      this.ref.detectChanges();
    }, error => {
      this.describeFeatureClicked = false;
      this.openAdvanceOptions = true;
      this.showLoadingScreen = false;
      this.ref.detectChanges();
    });
  }

  nextClicked() {
    if (this.describeFeatureClicked && !this.getAddedFeature()?.id) {
      this.getComplexityAndRecommendations();
    } else if (this.describeFeatureClicked && this.getAddedFeature()?.id) {
      this.openAdvanceOptions = true;
      this.showLoadingScreen = false;
      this.describeFeatureClicked = false;
    } else if (this.openAdvanceOptions && this.getAddedFeature()?.id) {
      this.addFeatureUpdateRequest();
    } else if (this.openAdvanceOptions && !this.getAddedFeature()?.id) {
      this.createAndAddCustomFeature();
    }
  }

  toggleFeatureDetails(data, featureData?) {
    if ((data === 'back') && featureData) {
      this.addRecommendedCoreFeaturesToList(featureData);
      this.featureDetails = '';
      this.showRecommendedFeatures = true;
    } else if (data === 'back') {
      this.featureDetails = '';
      this.showRecommendedFeatures = true;
    } else {
      this.featureDetails = data;
    }
  }

  addRecommendedFeature() {
    this.phaseService.recommendedCoreFeaturesToBeAdded.forEach((ftr) => {
      this.commonService.featureAdded.next(ftr);
    });
    this.phaseService.addnewfeature = false;
    this.showLoadingScreen = false;
    this.preventClick = false;
    this.showAdvanceOption = false;
    this.showRecommendedFeatures = false;
    this.featureDetails = '';
    this.resetFeatureForm();
  }

  previousCTAClicked() {
    if (this.describeFeatureClicked) {
      this.describeFeatureClicked = false;
      this.showRecommendedFeatures = false;
      this.openAdvanceOptions = false;
      this.showCostAndTimeline = false;
    } else if (this.showRecommendedFeatures) {
      this.describeFeatureClicked = true;
      this.showRecommendedFeatures = false;
      this.phaseService.recommendedCoreFeaturesToBeAdded = [];
    } else if (this.openAdvanceOptions && (this.recommendedFeatures?.length > 0)) {
      this.showRecommendedFeatures = true;
      this.openAdvanceOptions = false;
      this.phaseService.recommendedCoreFeaturesToBeAdded = [];
    } else if (this.openAdvanceOptions && !this.recommendedFeatures?.length) {
      this.describeFeatureClicked = true;
      this.openAdvanceOptions = false;
    }
  }

  goToComplexityScreen() {
    this.openAdvanceOptions = true;
    this.showRecommendedFeatures = false;
    this.isShowWarningPopup = false;
  }

  beOnRecommendedScreen() {
    this.isShowWarningPopup = false;
  }

  showWarningPopup() {
    this.isShowWarningPopup = true;
  }

  isFeatureSelected(feature) {
    return this.phaseService.selectedFeatures.find(f => f.id === feature.id)?.selected;
  }

  disableNextCTA() {
    if (this.describeFeatureClicked) {
      return (this.isFormValid() || this.isInvalidLength() || this.isInvalidMinimumCharLength());
    }
  }

  leftBoundStat(reachesLeftBound: boolean) {
    this.leftFeatureNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    this.rightFeatureNavDisabled = reachesRightBound;
  }

  moveLeft() {
    this.navFeatureSliderPopUp.moveLeft();
  }

  moveRight() {
    this.navFeatureSliderPopUp.moveRight();
  }

  ngOnDestroy(): void {
    this.searchObservable?.unsubscribe();
  }

  public getLocaledPrice(price: any): any {
    return this.dataService.getLocaledPrice(price) !== '0.00'
      ? this.dataService.getLocaledPrice(price, this.dataService.buildCardData.currency)
      : '-';
  }

  addRecommendedCoreFeaturesToList(feature) {
    feature['isRecommendedCoreFeatureAdded'] = !feature['isRecommendedCoreFeatureAdded'];
    if (feature['isRecommendedCoreFeatureAdded']) {
      this.phaseService.recommendedCoreFeaturesToBeAdded.push(feature);
    } else {
      let index = this.phaseService.recommendedCoreFeaturesToBeAdded.findIndex(ftr => ftr.id === feature.id);
      if (index >= 0) {
        this.phaseService.recommendedCoreFeaturesToBeAdded.splice(index,1);
      }
    }
    this.phaseService.isShowLoaderOnCustomFeatureFooter = true;
    this.phaseService.updatePrice();
  }

  checkCoreFeatureCardButtonText(feature) {
    if (this.isFeatureSelected(feature)) {
      return '- Remove feature';
    }
    return '+ Add feature';
  }

  getSelectedCoreFeaturesDuration() {
    return this.phaseService.recommendedCoreFeaturesToBeAdded.reduce((duration, feature) => {
      if (this.phaseService.featureData?.length > 0) {
        let featureFound = this.phaseService.featureData.find(f => f.id === feature.id);
        if (featureFound) {
          return duration + featureFound.duration || feature.duration;
        }
      }
    }, 0);
  }

  getSelectedCoreFeaturesPrice() {
    return this.phaseService.recommendedCoreFeaturesToBeAdded.reduce((price, feature) => {
      if (this.phaseService.featureData?.length > 0) {
        let featureFound = this.phaseService.featureData.find(f => f.id === feature.id);
        if (featureFound) {
          let totalFeatureCost = featureFound.fixed + featureFound.customisation;
          return price + totalFeatureCost || feature.price;
        }
      }
    }, 0);
  }

  toggleCoreFeatureSelection(feature, event) {
    if (event.target.checked) {
      this.phaseService.addedFeature.dependent_features.push(feature.id);
    } else {
      let featureIndex = this.phaseService.addedFeature.dependent_features.findIndex(id => id === feature.id);
      if (featureIndex >= 0) {
        this.phaseService.addedFeature.dependent_features.splice(featureIndex, 1);
      }
    }
  }

  checkIfDependentCoreFeatureSelected(feature) {
    if (this.phaseService.addedFeature && this.phaseService.addedFeature.dependent_features && this.phaseService.addedFeature.dependent_features.length > 0) {
      let checkedFeatureIndex = this.phaseService.addedFeature.dependent_features.findIndex(id => id === feature.id );
      if (checkedFeatureIndex !== -1) {
        return true;
      }
    }
    return false;
  }

  getCoreSelectedFeatures() {
    return this.phaseService.selectedFeatures.filter(ftr => ftr.user_requested === false);
  }

  searchFeatureChange(evt) {
    const searchText: string = evt.target.value.toLowerCase();
    this.searchText = searchText.length > 2 && searchText;

    if (this.searchText) {
      this.filteredCoreFeatures = this.deepCopyFeatureSets(this.getCoreSelectedFeatures());
      this.filteredCoreFeatures = this.filteredCoreFeatures.filter((ftr) => {
       
        return (
          ftr.selected &&
          ftr.user_requested === false &&
          ftr.title.toLowerCase().includes(this.searchText)
        );
      });
    }
  }

  deepCopyFeatureSets(data: FeatureModel[]): FeatureModel[] {
    return JSON.parse(JSON.stringify(data));
  }

  clearFeatureSearch() {
    this.searchQuery = "";
    this.searchText = "";
  }

  disableButtonForAlreadyAddedFeature(featureData) {
    if (this.phaseService.recommendedCoreFeaturesToBeAdded?.length > 0) {
      let index = this.phaseService.recommendedCoreFeaturesToBeAdded.findIndex(f => f.id === featureData.id);
      if (index >= 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

}
