import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DataService} from '@shared/services/data.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {PhaseService} from '@shared/services/phase.service';
import {AppDataService} from '@rootmodule/app-data.service';
import { ReferralService } from '@shared/services/referral.service';


@Component({
  selector: 'app-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.css']
})
export class DashboardMainComponent implements OnInit, OnDestroy {
  @Output() pexitPaymentStatus = new EventEmitter();
  @Output() pshowCards = new EventEmitter();
  @Output() pselectUserProfile = new EventEmitter();
  @Output() pshowTooltip = new EventEmitter();
  @Output() pshowRenameCard = new EventEmitter();
  @Output() cpreventClick = new EventEmitter();

  @Input('user') user;
  @Input('showCompleted') showCompleted;
  @Input('showInProgress') showInProgress;
  @Input('showPaid') showPaid;
  @Input('showRecentComplete') showRecentComplete;
  @Input('showSpecCalls') showSpecCalls;
  @Input('showDemoCalls') showDemoCalls;
  @Input('showRecentRunning') showRecentRunning;
  @Input('showRecentProgress') showRecentProgress;
  @Input('showRecentSpecCalls') showRecentSpecCalls;
  @Input('showInvited') showInvited;
  @Input('showArchived') showArchived;
  @Input('showRecentArchived') showRecentArchived;
  @Input('preventClick') preventClick;
  @Input('cardToDelete') cardToDelete;
  @Input('showMoreIndex') showMoreIndex;
  @Input('showAdditionalFeatures') showAdditionalFeatures;
  @Input('showPaymentCards') showPaymentCards;
  @Input() showPrototype;
  @Input() showCustomPrototype;
  @Input() showRecentPrototype: boolean;
  @Input() showRecentCustomPrototype: boolean;
  @Input() showProjectCompleted: boolean;
  @Input() showRecentCompletedProjects: boolean;
  @Input() showRecentFreemium: boolean;
  @Input() showFreemiumCards: boolean;
  @Input() showRecentInvited: boolean;
  @Input() showRecentTailorMadePrototype: boolean;
  @Input() showTailorMadePrototype;

  constructor(public appDataService: AppDataService, public dataService: DataService, public phaseService: PhaseService, public apiRequest: ApiRequests, public referralService: ReferralService) { }

  ngOnInit() {
    if (this.appDataService.urlParameters.setting === 'true') {
      this.phaseService.isProfile = true;
      this.appDataService.urlParameters.setting = null;
    }
  }

  public exitPaymentStatus() {
    this.pexitPaymentStatus.emit();
  }

  public getCardCount(type): Number {
    if (this.dataService.buildCardCountData) {
      switch (type) {
        case 'completed':
          return this.dataService.buildCardCountData['completed'];
        case 'inprogress':
          return this.dataService.buildCardCountData['incomplete'];
        case 'paid':
          return this.dataService.buildCardCountData['running'] || this.dataService.paidCards.length;
        case 'invited':
          return this.dataService.buildCardCountData['invited'];
        case 'archived':
          return this.dataService.buildCardCountData['archived'];
        case 'prototype':
          return this.dataService.buildCardCountData['prototype'];
        case 'custom_prototype':
          return this.dataService.buildCardCountData['custom_prototype'];
        case 'project_completed':
          return this.dataService.buildCardCountData['project_completed'];
        case 'freemium':
          return this.dataService.buildCardCountData['freemium'];
        case 'tailor_made_prototype':
          return this.dataService.buildCardCountData['tailor_made_prototype'];
      }
    } else {
      return 0;
    }
  }

  public showCards(type) {
    this.pshowCards.emit(type);
  }

  public deactivateEdit() {
    this.dataService.showHideLoader(true);
    this.apiRequest.deactivateTimelineFromDashboard()
      .subscribe(
        (data) => {
          this.dataService.allBuildCards.updated_build_card_count = 0;
          this.dataService.showHideLoader(false);
        },
        (error) => {
          this.dataService.showHideLoader(false);
        }
      );
  }

  public getIsProfile() {
    return this.phaseService.isProfile;
  }

  public getTaxAndBilling() {
    return this.phaseService.showTaxAndBilling;
  }

  public ngOnDestroy() {
    this.phaseService.dashboardOpen = false;
    this.dataService.toDashBoard = false;
    this.phaseService.isDashboardShow = false;
    this.phaseService.showPaymentStatus = false;
    this.phaseService.isProfile = false;
    this.phaseService.showTaxAndBilling = false;
  }

  public getPaymentShowStatus() {
    return this.phaseService.showPaymentStatus;
  }

  public showRenameCard(card) {
    this.pshowRenameCard.emit(card);
  }

  public selectUserProfile(profile) {
    this.pselectUserProfile.emit(profile);
  }

  public showTooltip(flage) {
    this.pshowTooltip.emit(flage);
  }

  public ppreventClick(flage) {
    this.cpreventClick.emit(flage);
  }
}
