import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '@shared/shared.module';
import {CommonModule} from '@angular/common';
import {DashboardComponent} from './dashboard.component';
import {MomentModule} from 'angular2-moment';
import {RoundProgressModule} from 'angular-svg-round-progressbar';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {InvitedUsersComponent} from './popups/invited-users/invited-users.component';
import {MenuBarComponent} from './components/dashboard-navigation/menu-bar.component';
import {DashboardMainComponent} from './components/dashboard-main/dashboard-main.component';
import {DashboardLoadingComponent} from './components/dashboard-main/dashboard-loading/dashboard-loading.component';
import {PaymentAreaComponent} from './components/dashboard-main/payment-area/payment-area.component';
import {NoCardsComponent} from './components/dashboard-main/no-cards/no-cards.component';
import {ProfileViewComponent} from './components/dashboard-main/profile-view/profile-view.component';
import {CardsComponent} from './components/dashboard-main/spce-call/cards.component';
import {SavedCardsComponent} from './components/dashboard-main/saved-cards/saved-cards.component';
import {CompletedCardsComponent} from './components/dashboard-main/completed-cards/completed-cards.component';
import {InvitedCardsComponent} from './components/dashboard-main/invited-cards/invited-cards.component';
import {RunningCardsComponent} from './components/dashboard-main/running-cards/running-cards.component';
import {ArchivedCardsComponent} from './components/dashboard-main/archived-cards/archived-cards.component';
import {PopupsComponent} from './components/popups/popups.component';
import {ProgressCardLoadComponent} from './components/dashboard-main/progress-card-load/progress-card-load.component';
import {ShareinviteModule} from '../../../shared/shareinvite-module/shareinvite-module';
import { AdditionalFeaturesComponent } from './components/dashboard-main/additional-features/additional-features.component';
import {PaymentCardsComponent} from '@featuremodules/dashboard-module/components/dashboard-main/payment-cards/payment-cards.component';
/*import {CreditCardDirectivesModule} from 'angular-cc-library';*/
import { CustomPrototypeCardsComponent } from './components/dashboard-main/custom-prototype-cards/custom-prototype-cards.component';
import { PrototypeCardsComponent } from './components/dashboard-main/prototype-cards/prototype-cards.component';
import { CompletedProjectCardsComponent } from './components/dashboard-main/completed-project-cards/completed-project-cards.component';
import { FreemiumCardsComponent } from './components/dashboard-main/freemium-cards/freemium-cards.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import { TailorMadePrototypeCardsComponent } from './components/dashboard-main/tailor-made-prototype-cards/tailor-made-prototype-cards.component';
import {DemoCardsComponent} from './components/dashboard-main/demo-call/demo-cards.component';
import { ReferralProgramModule } from '@featuremodules/referral-program-module/referral-program.module';
// import { TaxDetailsComponent } from './components/dashboard-main/tax-details/tax-details.component';

const childRouterConfig: Routes = [
  { path: '', component: DashboardComponent }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(childRouterConfig),
    SharedModule,
    MomentModule,
    RoundProgressModule,
    InfiniteScrollModule,
    ShareinviteModule,
    PerfectScrollbarModule,
    ReferralProgramModule
    /*CreditCardDirectivesModule,*/
  ],
  declarations: [DashboardComponent, InvitedUsersComponent, MenuBarComponent,
    DashboardMainComponent, DashboardLoadingComponent, PaymentAreaComponent,
    NoCardsComponent, ProfileViewComponent, CardsComponent, SavedCardsComponent,
    CompletedCardsComponent, InvitedCardsComponent, RunningCardsComponent,
    ArchivedCardsComponent, PopupsComponent, ProgressCardLoadComponent, AdditionalFeaturesComponent,
    PaymentCardsComponent,
    CustomPrototypeCardsComponent,
    PrototypeCardsComponent,
    CompletedProjectCardsComponent,
    FreemiumCardsComponent,
    TailorMadePrototypeCardsComponent, DemoCardsComponent],
  entryComponents: [DashboardComponent, InvitedUsersComponent]
})
export class DashboardModule {
}

