import {Component, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PhaseService} from '@shared/services/phase.service';
import {UtilityService} from '@shared/services/utility.service';
import {DataService} from '@shared/services/data.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {Constants} from '@shared/utils/constants';
import {AppDataService} from '@rootmodule/app-data.service';
import {Router} from '@angular/router';
import {CurrencyModel} from '@models/currency-model/currency.model';
import parsePhoneNumber from 'libphonenumber-js';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser} from 'angularx-social-login';

@Component({
  selector: 'app-new-login',
  templateUrl: './new-login.component.html',
  styleUrls: ['./new-login.component.scss']
})
export class NewLoginComponent implements OnInit {

  public countryDropdown = false;
  public currencyDropdown = false;
  useEmailClicked = false;
  isRegisteredUser = true;
  loginSignupForm: FormGroup;
  countries = [
    {name: 'India', image: '../assets/images/india.jpg'},
    {name: 'UK', image: '../assets/images/united-Kingdom.jpg'}
  ];
  invalidPassword = false;
  selectedCountry: { name: '', image: '' };
  selectedCurrency: CurrencyModel;
  isSaveButtonClicked = false;

  constructor(private formBuilder: FormBuilder, public phaseService: PhaseService,
              public utilityService: UtilityService, public dataService: DataService,
              private apiRequest: ApiRequests, private appDataService: AppDataService,
              private router: Router, private auth: SocialAuthService, private zone: NgZone) { }

  ngOnInit(): void {
    // this.fetchHomePageData();
    this.getCurrencyByIp();
    this.initializeForm();
    this.dataService.showHideLoader(false);
  }

  fetchHomePageData() {
    this.apiRequest.fetchHomePageData().subscribe((data: any) => {
      this.phaseService.countryNameCode = this.dataService.country_code ? this.dataService.country_code.toLowerCase() : '';
      this.dataService.dataLoadingCurrency = Object.assign({}, this.dataService.homePageData.currency);
      this.phaseService.platformPrices = Array.apply(null, Array(this.dataService.homePageData.platforms.length));
      this.phaseService.platformPrices.map((x, i) => {
        return i;
      });
      if (this.dataService.homePageData.currency) {
        this.phaseService.specing_price = this.dataService.homePageData.currency.specing_price;
      }
      this.dataService.showHideLoader(false);
    });
  }

  initializeForm() {
    this.loginSignupForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required, Validators.minLength],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      store_name: [''],
      app_country: [''],
      phone_number: ['', Validators.required],
      currency_id: [''],
      termsAndConditions: [null, Validators.required]
    });
  }

  get email() {
    return this.loginSignupForm.get('email');
  }

  get password() {
    return this.loginSignupForm.get('password');
  }

  get phone_number() {
    return this.loginSignupForm.get('phone_number');
  }

  get first_name() {
    return this.loginSignupForm.get('first_name');
  }

  get last_name() {
    return this.loginSignupForm.get('last_name');
  }

  get currency_id() {
    return this.loginSignupForm.get('currency_id');
  }

  get termsAndConditions() {
    return this.loginSignupForm.get('termsAndConditions');
  }

  checkIfEmailValid(): boolean {
    return !this.phaseService.validateEmail(this.email.value);
  }

  getCurrencyByIp() {
    this.apiRequest.getCurrencyByIp().subscribe((data: any) => {
      if (data.data.length === 1) {
        this.selectedCurrency = data.data[0]['attributes'];
      }
    }, error => {

    });
  }

  countryToggle() {
    this.countryDropdown = !this.countryDropdown;
    this.currencyDropdown = false;
  }

  currencyToggle() {
    this.currencyDropdown = !this.currencyDropdown;
    this.countryDropdown = false;
  }

  useEmail() {
    this.useEmailClicked = !this.useEmailClicked;
  }

  getCurrencies() {
    return this.phaseService.getCurrencies();
  }

  selectCurrency(currency, event) {
    event.stopPropagation();
    this.selectedCurrency = currency;
    this.currencyDropdown = false;
  }

  getCurrency() {
    return this.dataService.homePageData.currency;
  }

  login() {
    let user = this.loginSignupForm.value;
    let loginOrSignup = 'login';
    if (!this.isRegisteredUser) {
      this.isSaveButtonClicked = true;
      loginOrSignup = 'signup';
      user['user_type'] = 'user';
      user['currency_id'] = this.selectedCurrency.id;
      delete user.app_country;
      delete user.store_name;
    } else {
      user = { email: user.email, password: this.password.value };
    }
    this.apiRequest.login_signup(user, loginOrSignup).subscribe(resp => {
      this.appDataService.urlParameters.redirect_to_login = null;
      this.dataService.loggedInViaStorePage = true;
      this.router.navigate(['apps/' + this.phaseService.rentalTemplateAlias], {
        queryParams: {
          exp: this.appDataService.urlParameters.expCode,
          promotion: this.appDataService.urlParameters.promotion
        }
      });
    }, error => {
      if (Object.keys(error.error.errors).includes('email')) {
        if (error.error.errors.email && error.error.errors.email[0] === 'is not Registered') {
          this.isRegisteredUser = false;
          this.invalidPassword = false;
        }
      }
      if (Object.keys(error.error.errors).includes('password')) {
        this.invalidPassword = true;
      }
    });
  }

  socialLogin(provider) {
    this.loginSignupForm.reset();
    this.auth.signOut();
    if (provider === 'google') {
      this.auth.signIn(GoogleLoginProvider.PROVIDER_ID);
    } else if (provider === 'facebook') {
      this.auth.signIn(FacebookLoginProvider.PROVIDER_ID);
    } else if (provider === 'linkedin') {
      this.linkedInSignUp(provider);
    }
    this.socialSignAuthState(provider);
  }

  linkedInSignUp(provider) {
    let redirect_url = '';
    if (this.dataService.cookie_domain === 'localhost') {
      redirect_url = 'http%3A%2F%2Flocalhost%3A4200';
    } else {
      redirect_url = 'https%3A%2F%2Fstaging.engineer.ai';
    }

    window.open(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77byqc5s5dcifv&redirect_uri=${redirect_url}&scope=r_liteprofile%20r_emailaddress`);
    const timer = setInterval(() => {
      const code = localStorage.getItem('code');
      if (code) {
        clearInterval(timer);
        this.getLinkedInUser(code);
      }
    }, 5000);
  }

  socialSignAuthState(provider) {
    const user = this.loginSignupForm.value;
    if (!this.isRegisteredUser) {
      this.isSaveButtonClicked = true;
      user['user_type'] = 'user';
      user['currency_id'] = this.selectedCurrency.id;
      delete user.app_country;
      delete user.store_name;
    }
    this.dataService.socialSignUser = new SocialUser();
    this.auth.authState.subscribe((socialUser) => {
        if (this.dataService.socialSignUser.email) {
          return;
        }
        this.dataService.socialSignUser = socialUser;
        this.phaseService.SignUpProviders = provider;
        if (!this.dataService.user) {

          if (this.appDataService.urlParameters.inviteToken) {
            user.inviteToken = this.appDataService.urlParameters.inviteToken;
          }
          user.signup_stage = this.appDataService.urlParameters.signup_stage;
          user.utm_campaign = this.appDataService.urlParameters.utm_campaign;
          user.utm_source = this.appDataService.urlParameters.utm_source;
          user.utm_medium = this.appDataService.urlParameters.utm_medium;

          const userData = Object.assign(user, this.dataService.socialSignUser);
          this.apiRequest.login_signup(userData, 'login').subscribe(() => {
            this.zone.run(() => {
              this.useEmailClicked = true;
              this.isRegisteredUser = false;
              this.invalidPassword = false;
              const userr = this.dataService.user;
              this.loginSignupForm.patchValue({
                first_name: [userr ? userr.first_name : ''],
                last_name: [userr ? userr.last_name : '']
              });
            });
          });
        }
      }, (error) => {
        console.log(error);
      });
  }

  getLinkedInUser(code) {
    const userData = {};
    userData['code'] = code;
    userData['redirect_uri'] = window.location.origin;
    userData['utm_campaign'] = this.appDataService.urlParameters.utm_campaign;
    userData['utm_source'] = this.appDataService.urlParameters.utm_source;
    userData['utm_medium'] = this.appDataService.urlParameters.utm_medium;
    this.apiRequest.linkedIn_user(userData).subscribe((data) => {
      this.useEmailClicked = true;
      this.isRegisteredUser = false;
      this.invalidPassword = false;
      const userr = this.dataService.user;
      this.loginSignupForm.patchValue({
        first_name: [userr ? userr.first_name : ''],
        last_name: [userr ? userr.last_name : '']
      });
    }, (error) => {

    });
  }

  setCountry(country) {
    this.selectedCountry = country;
    this.countryDropdown = false;
  }

  setUserContact(event) {
    this.phone_number.setValue(event);
  }

  checkIfPhoneInValid() {
    const phoneNumber = parsePhoneNumber(this.phone_number.value.toString(), this.dataService.countryISOCode);
    if (phoneNumber) {
      return !(phoneNumber.isValid() && this.phone_number.value.includes('+' + phoneNumber.countryCallingCode));
    } else {
      return true;
    }
  }

  CheckIfFormValid() {
    if (!this.isRegisteredUser) {
      return this.email.invalid ||  this.first_name.invalid || this.last_name.invalid ||
        this.checkIfPhoneInValid() || this.currency_id.invalid ||
        this.password.invalid || this.termsAndConditions.invalid;
    }
    return this.email.invalid || this.password.invalid;
  }

  navigateToStorePage() {
    this.appDataService.urlParameters.redirect_to_login = null;
    this.router.navigate(['apps/' + this.phaseService.rentalTemplateAlias], {
      queryParams: {
        exp: this.appDataService.urlParameters.expCode,
        promotion: this.appDataService.urlParameters.promotion
      }
    });
  }

  navigateToLoginScreen() {
    this.isRegisteredUser = true;
  }

  removeErrors() {
    this.invalidPassword = false;
  }

  loginOrUpdateProfile() {
    if (this.dataService.user) {
      if (this.phaseService.selectedCurrency) {
        this.dataService.user.currency = Object.assign({}, this.phaseService.selectedCurrency);
      } else {
        this.dataService.user.currency = Object.assign({}, this.dataService.homePageData.currency);
      }
      this.apiRequest.updateProfile(this.dataService.user, this.dataService.user.currency.id, this.phone_number.value, this.email.value).subscribe((resp) => {
        this.appDataService.urlParameters.redirect_to_login = null;
        this.dataService.loggedInViaStorePage = true;
        this.router.navigate(['apps/' + this.phaseService.rentalTemplateAlias], {
          queryParams: {
            exp: this.appDataService.urlParameters.expCode,
            promotion: this.appDataService.urlParameters.promotion
          }
        });
      });
    } else {
      this.login();
    }
  }

}
