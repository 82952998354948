import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {BillingDetailsService} from '@shared/services/billing-details.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {CommonService} from '@shared/services/common.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UserModel} from '@models/user-model/user.model';
import {ModelMapperService} from '@shared/services/model-mapper.service';
import {TaxDetailsService} from '@shared/services/tax-details.service';

@Component({
  selector: 'app-tax-details',
  templateUrl: './tax-details.component.html',
  styleUrls: ['./tax-details.component.scss']
})
export class TaxDetailsComponent implements OnInit {

  buttonText = 'Continue to Payment plan';
  showToolTip = false;
  
  isConfirmationPopup = false;
  areDetailsSaved = false;
  europeRegionCountries = [
    { name: 'Aland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Andorra', code: 'AD' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Austria', code: 'AT' },
    { name: 'Belarus', code: 'BY' },
    { name: 'Belgium', code: 'BE' },
    { name: 'Bonaire', code: 'BQ' },
    { name: 'Bosnia and Herzegovina', code: 'BA' },
    { name: 'Bulgaria', code: 'BG' },
    { name: 'Croatia', code: 'HR' },
    { name: 'Cyprus', code: 'CY' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Estonia', code: 'EE' },
    { name: 'Faroe Islands', code: 'FO' },
    { name: 'Finland', code: 'FI' },
    { name: 'France', code: 'FR' },
    { name: 'France, Metropolitan', code: 'FX' },
    { name: 'Georgia', code: 'GE' },
    { name: 'Germany', code: 'DE' },
    { name: 'Gibraltar', code: 'GI' },
    { name: 'Greece', code: 'GR' },
    { name: 'Greenland', code: 'GL' },
    { name: 'Guernsey', code: 'GG' },
    { name: 'Holy See (Vatican City State)', code: 'VA' },
    { name: 'Hungary', code: 'HU' },
    { name: 'Iceland', code: 'IS' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Isle of Man', code: 'IM' },
    { name: 'Italy', code: 'IT' },
    { name: 'Jersey', code: 'JE' },
    { name: 'Latvia', code: 'LV' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Lithuania', code: 'LT' },
    { name: 'Luxembourg', code: 'LU' },
    { name: 'Macedonia', code: 'MK' },
    { name: 'Malta', code: 'MT' },
    { name: 'Moldova, Republic of', code: 'MD' },
    { name: 'Monaco', code: 'MC' },
    { name: 'Montenegro', code: 'ME' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'Norway', code: 'NO' },
    { name: 'Poland', code: 'Poland' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Romania', code: 'RO' },
    { name: 'Russian Federation', code: 'RU' },
    { name: 'San Marino', code: 'SM' },
    { name: 'Serbia', code: 'RS' },
    { name: 'Slovakia', code: 'SK' },
    { name: 'Slovenia', code: 'SI' },
    { name: 'Spain', code: 'ES' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'Turkey', code: 'TR' },
    { name: 'Ukraine', code: 'UA' },
    { name: 'United Kingdom (UK)', code: 'GB' }
  ];
  
  @Input('isFromBillingDetails') isFromBillingDetails;

  constructor(public phaseService: PhaseService, public dataService: DataService,
              public billingService: BillingDetailsService, public taxDetailsService: TaxDetailsService,
              private apiRequest: ApiRequests,
              private commonService: CommonService, private _route: ActivatedRoute,
              private formBuilder: FormBuilder, private modelMapperService: ModelMapperService,
              private router: Router, private renderer: Renderer2) {
    
    this.setTaxDetails();
  }

  ngOnInit(): void {
    console.log('isFromBillingDetails- ', this.isFromBillingDetails);
    if (!this.isFromBillingDetails) {
      this.dataService.currentPage = 'taxDetails';
    }
    this.initializeForm();
    this.phaseService.isProfile = false;
  }

  setTaxDetails() {
    const checkBoxValue = this.taxDetailsService.checkBoxValues,
    address = this.dataService.user.address;
    checkBoxValue.isGstChecked = address?.gst_number ? true : false;
    checkBoxValue.isVatChecked = address?.vat_number ? true : false;
    checkBoxValue.isPanChecked = address?.pan_number ? true : false;
    checkBoxValue.isTanChecked = address?.tan_number ? true : false;
    checkBoxValue.isTinChecked = address?.tin_number ? true : false;
    checkBoxValue.isTDSGSTChecked = address?.gst_tds_number ? true : false;
    checkBoxValue.isSalesTaxChecked = address?.sales_tax_number ? true : false;
    checkBoxValue.isZeroGstChecked = address?.zero_gst ? true : false;
    if (address && address.sez_doc) {
      const splittedUrl = address.sez_doc.split('/');
      this.taxDetailsService.documentFiles.sez_doc['file_name'] = splittedUrl[splittedUrl.length - 1];
    }
    this.taxDetailsService.businessCategory = address?.customer_business_category && address?.tax_details_disabled ? address.customer_business_category : 'business';
  }

  initializeForm() {
    const address = this.dataService.user.address;
    this.taxDetailsService.taxDetailsForm = this.formBuilder.group({
      gst_number: [address?.gst_number ? address?.gst_number : ''],
      vat_number: [address?.vat_number ? address?.vat_number : ''],
      pan_number: [address?.pan_number ? address?.pan_number : ''],
      tan_number: [address?.tan_number ? address?.tan_number : ''],
      tin_number: [address?.tin_number ? address?.tin_number : ''],
      gst_tds_number: [address?.gst_tds_number ? address?.gst_tds_number : ''],
      registration_type: [address?.registration_type ? address?.registration_type : 'Regular'],
      sales_tax_number: [address?.sales_tax_number ? address?.sales_tax_number : ''],
      sez_doc: ['']
    });
  }

  getBuildCardIDOnReload(): void {
    this._route.parent.params.subscribe(params => {
      const uniq_code = params['id'];
      this.dataService.buildCardData.uniq_code = uniq_code;
      this.dataService.buildCardUniqueCode = uniq_code;
    });
  }

  showConfirmationPopup() {
    this.taxDetailsService.validateFormFields();
    this.isConfirmationPopup = true;
  }

  saveTaxDetails() {
    this.apiRequest.updateTaxOrPreferencesData(this.taxDetailsService.createPayload()).subscribe(resp => {
      const userObj: UserModel = new UserModel();
      resp.user = this.modelMapperService.getMappedModel(userObj, resp.user);
      this.dataService.user = resp.user;
      this.dataService.storeUserObject();
      this.areDetailsSaved = true;
      this.isConfirmationPopup = false;
    }, error => {
      this.isConfirmationPopup = false;
    });
  }

  toggleConfirmationPopup() {
    this.isConfirmationPopup = !this.isConfirmationPopup;
  }


  showErrorMsg(fieldName) {
    return (this.taxDetailsService.isContinueClicked && (!fieldName.value || fieldName.invalid));
  }

  setFilesData() {
    this.taxDetailsService.documentFiles.sez_doc = '';
  }

  toggleCheckBoxes(checkBoxKey, formKey?) {
    this.taxDetailsService.checkBoxValues[checkBoxKey] = !this.taxDetailsService.checkBoxValues[checkBoxKey];
    
    if (!this.taxDetailsService.checkBoxValues[checkBoxKey]) {
      this.taxDetailsService.taxDetailsForm.patchValue({
        [formKey]: ''
      });
    }
    if ((checkBoxKey === 'isGstChecked')) {
      this.taxDetailsService.taxDetailsForm.patchValue({
        registration_type: 'Regular'
      });
    }
  }

  showForSpecificType() {
    return ((this.taxDetailsService.regTYpe.value === 'SEZ') || (this.taxDetailsService.regTYpe.value === 'Overseas'));
  }

  showFieldsAsPerRegion(fieldName) {
    let address = this.dataService.user.address;
    const isCompany = ((address && (address.billed_as === 'company')) || (this.isFromBillingDetails && (this.phaseService.billingInfo.billed_as === 'company')));
    const isIndividual = ((address && (address.billed_as === 'individual')) || (this.isFromBillingDetails && (this.phaseService.billingInfo.billed_as === 'individual')));
    if (this.dataService.user.currency.code === 'INR') {
      return ((fieldName === 'GST') || (fieldName === 'TAN') || (fieldName === 'GST_TAN') || (fieldName === 'PAN') ||
        ((fieldName === 'reg_type') && this.taxDetailsService.checkBoxValues.isGstChecked) || ((fieldName === 'ZeroGst') && this.showForSpecificType())
        || ((((fieldName === 'sez_doc') && (this.taxDetailsService.regTYpe.value === 'SEZ'))) && this.taxDetailsService.checkBoxValues.isZeroGstChecked));
    } else if (this.taxDetailsService.checkIfUKOwningEntity()) {
      return ((fieldName === 'VAT') || (fieldName === 'TIN'));
    } else if (isCompany || (isIndividual && this.taxDetailsService.checkIfDMCCOwningEntity())) {
      return ((fieldName === 'sales_tax') || (fieldName === 'TIN'));
    }
  }

  onFileChange(event, docName) {
    this.taxDetailsService.fileUploadingError = false;
    const eventFile = event.target.files[0],
     sizeInMb = eventFile.size / 1000000;
    if (sizeInMb >= 8) {
      this.taxDetailsService.fileUploadingError = true;
      return false;
    }
    if (!eventFile.type.includes('pdf') && !eventFile.type.includes('tiff') && !eventFile.type.includes('bitmap')
      && !eventFile.type.includes('jpeg') && !eventFile.type.includes('png') && !eventFile.type.includes('jpg')) {
      this.taxDetailsService.fileUploadingError = true;
      return false;
    }
    const fileReader = new FileReader();
    fileReader.readAsDataURL(eventFile);
    fileReader.onload = () => {
      this.taxDetailsService.documentFiles[docName] = {
        file_name: eventFile.name,
        file: fileReader.result as string
      };
    };
  }

  deleteFile(keyName) {
    this.taxDetailsService.documentFiles[keyName] = {};
    this.taxDetailsService.taxDetailsForm.patchValue({
      [keyName]: ''
    });
  }

  removeError() {
    this.taxDetailsService.showInvalidVatError = false;
    this.taxDetailsService.isCountryCOdeInVAT = false;
  }

  checkIfTaxDetailsAvailable() {
    const address = this.dataService.user.address;
      return (address && address.tax_details_disabled);
  }

  disableSaveTaxDetails() {
    if (this.taxDetailsService.checkIfTaxDetailsFieldNotPresent()) {
      return true;
    } else if (!this.dataService.completedCards.length && !this.dataService.paidCards.length) {
      return true;
    }
  }

  
  toggleToolTip() {
    this.showToolTip = !this.showToolTip;
  }

  hideSuccessPopup() {
    this.areDetailsSaved = false;
  }

  setMinLengthForTINNumber() {
    if ((this.dataService.user.address?.country === 'New Zealand') || (this.isFromBillingDetails && (this.phaseService.billingInfo.country === 'New Zealand'))) {
      return 8;
    } else {
      return 9;
    }
  }

  getTinPlaceHolder() {
    const country = this.dataService.user.address?.country;
    let billingInfo = this.phaseService.billingInfo;
    if ((country === 'United States of America') || (this.isFromBillingDetails && (billingInfo.country === 'United States of America'))) {
      return 'eg. 12-3456789';
    } else if ((country === 'United Kingdom (UK)') || (this.isFromBillingDetails && (billingInfo.country === 'United Kingdom (UK)'))) {
      return 'eg. 1234567890';
    } else {
      return '';
    }
  }

  getVatPlaceHolder() {
    const country = this.dataService.user.address?.country;
    let billingInfo = this.phaseService.billingInfo;
    if ((country === 'United States of America') || (this.isFromBillingDetails && (billingInfo.country === 'United States of America'))) {
      return 'eg. 912-34-5678';
    } else if ((country === 'United Kingdom (UK)') || (this.isFromBillingDetails && (billingInfo.country === 'United Kingdom (UK)'))) {
      return 'eg. GB123456789';
    } else {
      return '';
    }
  }

  checkPressedKey(event) {
    if (((event.charCode >= 47) && (event.charCode <= 57)) || (event.charCode <= 45)) {
      return true;
    } else {
      return false;
    }
  }


  toggleBusinessCategory(event) {
    if (event.target.checked) {
      this.taxDetailsService.businessCategory = 'business';
    } else {
      this.taxDetailsService.businessCategory = 'non_business ';
    }
  }

  toggleTaxDetailsOnBillingPage() {
    this.taxDetailsService.isShowTaxDetailsOnBillingPage = !this.taxDetailsService.isShowTaxDetailsOnBillingPage;
  }

}
