import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {DragulaService} from 'ng2-dragula';
import {Angulartics2Segment} from 'angulartics2/segment';
import {PhaseModel} from '@models/phase-model/phase.model';
import {DataService} from '@shared/services/data.service';
import {PlatformModel} from '@models/platform-model/platform.model';
import {PhaseService} from '@shared/services/phase.service';
import {RecalculatePhaseDataService} from '@shared/services/recalculate-phase-data.service';
import { Intercom } from 'ng-intercom';
import {DeliveryService} from '@featuremodules/delivery-module/services/delivery.service';
import {platform} from 'os';
import {CommonService} from '@shared/services/common.service';

@Component({
  selector: 'platform-list',
  templateUrl: 'platform-list.component.html',
  styleUrls: ['platform-list.component.scss']
})
export class PlatformListComponent implements OnInit {
  @Input() phase: PhaseModel;
  @Input() index: number;
  @Input() generalView: boolean;
  @Output() closeCallback: EventEmitter<any> = new EventEmitter();
  @Output() togglePlatform: EventEmitter<any> = new EventEmitter();
  public tempPlatforms;
  public platformsSelected: Array<PlatformModel>;
  public applySelectionsToAllPhases: boolean;
  public parallelMode: boolean = false;
  public deliveryDeadline: string;
  @Input() isFromPhase: boolean;
  isPlatformDeleted = false;
  @Input() isForFeaturePage = false;
  @Input() webOrMobile: string;
  @Input() isForMobile: boolean;
  clickedPlatformId: number;

  public PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
  };

  constructor(
    public dataService: DataService,
    public dragulaService: DragulaService,
    public analyticsSegment: Angulartics2Segment,

    public phaseService: PhaseService,
    private deliveryService: DeliveryService,
    private recalculatePhaseDataService: RecalculatePhaseDataService,
    private commonService: CommonService
  ) {

  }

  public ngOnInit() {
    setTimeout(() => {
      if (this.generalView || (this.phase && this.phase.is_parallel_platform)) {
        this.parallelMode = true;
      }
      if (!this.parallelMode) {
        const dragula = this.dragulaService.find('PLATFORMS');

        if (dragula) {
          this.dragulaService.destroy('PLATFORMS');
        }

        this.dragulaService.createGroup('PLATFORMS', {
          revertOnSpill: true,
          moves: (el: any, container: any, handle: any): any => {
            this.sortPlatforms();
            if (el.classList.contains('disable_drag')) {
              return false;
            }
            return true;
          }
        });
      }
      this.setPlatformsData();
    }, 12);
  }



  public parallelTab() {
    this.parallelMode = !this.parallelMode;

    if (this.parallelMode && !this.generalView) {
      this.calculateDeliveryDeadline(this.phase);
    } else {
      this.recalculatePhaseDataService.calculateDeliveryDateOfPlatforms(
        this.tempPlatforms,
        this.phase
      );
    }
  }

  public sortPlatforms() {
    this.tempPlatforms = this.tempPlatforms.sort((a, b) => {
      return a.selected === b.selected ? 0 : a.selected ? -1 : 1;
    });

    return this.tempPlatforms;
  }

  public setPlatformsData() {
    const isTaiorMade = this.dataService.homePageData.build_phases[2].is_tailor_made;
    if (this.phase && (this.phase.title.includes('MVP') || (this.phase['type'] === 'custom_phase')) && this.parallelMode && this.generalView){
      this.tempPlatforms = Object.assign([],[...this.phaseService.selectedPlatforms]);
    } else {
      this.tempPlatforms = Object.assign([], [...this.dataService.homePageData.platforms]);
    }

    if (this.phaseService.checkIfOnlyRapidPrototypeSelected() && isTaiorMade) {
      this.tempPlatforms = Object.assign([],[...this.phaseService.rapidPrototypePlatforms]);
    }

    this.platformsSelected = [];
    if (this.phase) {
      this.tempPlatforms.forEach((plt, i) => {
        let index = -1;
        if (this.phaseService.checkIfOnlyRapidPrototypeSelected() && isTaiorMade) {
          index = this.phaseService.selectedPlatformsForRapidPrototype.findIndex(p => p.id === plt.id);
        } else if (this.phase.platforms) {
          index = this.phase.platforms.findIndex(p => p.id === plt.id);
        }
        plt.selected = index !== -1;
        if (plt.selected) {
          this.platformsSelected.push(plt);
        }
      });
      if (this.parallelMode) {
        this.deliveryDeadline = this.recalculatePhaseDataService.calculateDeliveryDateOfParallelPlatformsSinglePhase(this.platformsSelected, this.phase);
      } else {
        this.recalculatePhaseDataService.calculateDeliveryDateOfPlatforms(
          this.tempPlatforms,
          this.phase
        );
      }
    } else {
      for (let platform of this.tempPlatforms) {
        let index = -1;
        if (this.phaseService.checkIfOnlyRapidPrototypeSelected() && isTaiorMade) {
          index = this.phaseService.selectedPlatformsForRapidPrototype.findIndex(p => p.id === platform.id);
        } else {
          index = this.phaseService.selectedPlatforms.findIndex(p => p.id === platform.id);
        }

        platform.selected = index !== -1;
        if (platform.selected) {
          this.platformsSelected.push(platform);
        }
      }
      //  this.deliveryDeadline = this.recalculatePhaseDataService.calculateDeliveryDateOfParallelPlatformsSimpleMode(this.platformsSelected);
      this.deliveryDeadline = this.phaseService.getFinalDeliveryDate();
    }

    this.sortPlatforms();
  }

  public calculateDeliveryDeadline(phase) {
    if (phase && phase.final_date) {
      this.deliveryDeadline = phase.final_date.format('LL');
    }
  }

  public closePlatformPopup() {
    if (this.phase) {
      this.setPlatformsData();
    }
    this.closeCallback.emit();

  }

  checkForDisablingPlatform(platform) {
    if (this.phaseService.isAdvancedVersion) {
      const phaseWithOnePlatform = [];
      const phasePlatforms = [];
      this.dataService.homePageData.build_phases.forEach(phase => {
        if (phase.selected && (phase.platforms.length === 1) && ((phase.title === 'Design') || (phase.title === 'Professional Prototype') || ((this.dataService.homePageData.build_phases[4].selected) && (phase.title === 'MVP')))) {
          phasePlatforms.push(phase.platforms[0].id);
        }
      });
      const whichPhase = (this.dataService.homePageData.build_phases[4].selected) ? (this.phase.title === 'Full Build') : (this.phase.title === 'MVP');
      if (whichPhase && (phasePlatforms.includes(platform.id))) {
        phaseWithOnePlatform.push(this.phase);
      }
      return (phaseWithOnePlatform.length > 0) ? false : true;
    } else {
      return true;
    }
  }

  public togglePlatformSelections(platform: PlatformModel, i, event, platformType?, shouldSelectAllPlatforms?) {
    if (this.isForFeaturePage) {
      this.platformsSelected = this.tempPlatforms.filter(p => p.selected);
    }
    this.phaseService.trackEventForObject(this.analyticsSegment, 'platform_selected', {
      user_id : this.dataService.user?.id,
      platforms_selected : platform.title
    });
    const index = this.platformsSelected.findIndex(
      plt => plt.id === platform.id
    );
    this.togglePlatform.emit(platformType);
    if ((event && event.target.checked) || shouldSelectAllPlatforms) {
      if (index === -1) {
        this.platformsSelected.push({...platform, selected: true});
        platform.selected = true;

        if (this.phase) {
          this.recalculatePhaseDataService.calculateDeliveryDateOfPlatforms(
            this.tempPlatforms,
            this.phase
          );
          if (this.parallelMode) {
            this.calculateDeliveryDeadline(this.phase);
          }
        }
      }
    } else {
      if (this.platformsSelected.length === 1) {
        if(event) {
          event.target.checked = true;
        }

        if (this.phase && ((this.phase.title === 'Design') || (this.phase.title === 'Professional Prototype'))) {
          this.commonService.showError({ error: { message: `Please remove this platform from MVP/ full build to ensure it gets removed from “${this.phase.title}” phase too` } });
        }
        return;
      } else if ((this.phaseService.isAdvancedVersion) && ((this.phase.title === 'Full Build') || (this.phase.title === 'MVP')) && !(((this.phase.title === 'MVP') && this.dataService.homePageData.build_phases[4].selected))) {
        this.dataService.homePageData.build_phases.forEach(phase => {
          const whichPhase = (this.dataService.homePageData.build_phases[4].selected) ? (phase.title !== 'Full Build') : (phase.title !== 'MVP');
          if ((phase.title !== 'Product Roadmap') && whichPhase && phase.platforms.length > 1) {
            const phasePlatforms = JSON.parse(JSON.stringify(phase.platforms));
            const platfromIndex = phasePlatforms.findIndex(p => p.id === platform.id);
            if (platfromIndex > -1) { phasePlatforms.splice(platfromIndex, 1); }
            phase.platforms = phasePlatforms;
          }
        });
      }
      this.platformsSelected.splice(index, 1);
      platform.selected = false;
      delete platform['delivery_date'];

      if (this.parallelMode && this.phase) {
        this.deliveryDeadline = this.recalculatePhaseDataService.calculateDeliveryDateOfParallelPlatformsSinglePhase(this.platformsSelected, this.phase);
      }
      if (this.isFromPhase && ((this.phase.title === 'MVP') || (this.phase['type'] === 'custom_phase'))) {
        this.setFeaturesonPlatformRemoval(this.phase, platform);
      } else if (this.isFromPhase && (this.phase.title === 'Full Build')) {
        this.setFeaturesonPlatformRemoval(this.dataService.homePageData.build_phases[3], platform);
      }
      this.isPlatformDeleted = true;
    }
    this.clickedPlatformId = platform.id;
    if (this.generalView && !this.phase) {
      //this.deliveryDeadline = this.recalculatePhaseDataService.calculateDeliveryDateOfParallelPlatformsSimpleMode(this.platformsSelected);
      this.deliveryDeadline = this.phaseService.getFinalDeliveryDate();
    }
    if (this.isFromPhase && this.phase.features && (this.phase.features.length > 0) && (!this.phase.features[0].platforms || !this.phase.features[0].platforms.length) && ((this.phase.title === 'MVP') || (this.phase['type'] === 'custom_phase'))) {
      this.phase.platforms = [];
      this.phase.platforms.push(platform);
      this.phase.features[0].phaseId = [];
      this.phase.features[0].phaseId.push(this.phase.id + '' + this.phase.platforms[0].id);
      this.phase.features[0].platforms.push(this.phase.platforms[0]);
    }
    this.sortPlatforms();
    if (!this.isForMobile) {
      this.submitNewPlatformSelections(shouldSelectAllPlatforms, i);
    }
  }

  setFeaturesonPlatformRemoval(phase, platform) {
    phase.features.forEach(feature => {
      feature.platforms = JSON.parse(JSON.stringify(feature.platforms));
      const index = feature.platforms.findIndex(p => p.id === platform.id);
      if (index !== -1) {
        feature.platforms.splice(index, 1);
      }
    });
    phase.features = phase.features.filter(feature => feature.platforms.length > 0);
  }

  public isDragEnabledOnPlatform(platform) {
    let platformData = null;
    if (this.platformsSelected) {
      platformData = this.platformsSelected.find(plt => plt.id === platform.id);
    }
    if (platformData && platformData.selected) {
      return '';
    } else {
      return 'disable_drag';
    }
  }

  // TODO: rewrite all logic to apply all platform selections exclude MVP;
  public submitNewPlatformSelections(shouldSelectAllPlatforms?, index?) {
    const isTailorMade = this.dataService.homePageData.build_phases[2].is_tailor_made;
    if (this.phase) {
      if (this.applySelectionsToAllPhases) {
        if (/mvp/i.test(this.phase.title) || this.phase['type'] === 'custom_phase') {
          this.dataService.homePageData.build_phases.forEach(phase => {
            phase.platforms = this.platformsSelected;
            if (this.parallelMode) {
              phase.is_parallel_platform = true;
            } else {
              phase.is_parallel_platform = false;
            }
          });
        } else if (this.phaseService.checkIfOnlyRapidPrototypeSelected() && isTailorMade) {
          const prototype = this.dataService.homePageData.build_phases.find(
            phase => phase.title === 'Professional Prototype'
          );
          prototype.platforms = this.platformsSelected;
          if (this.parallelMode) {
            prototype.is_parallel_platform = true;
          } else {
            prototype.is_parallel_platform = false;
          }

          this.phaseService.selectedPlatformsForRapidPrototype = [];
          this.platformsSelected.forEach(platform => {
            this.phaseService.selectedPlatformsForRapidPrototype.push(platform);
          });
        } else {
          this.dataService.homePageData.build_phases.forEach(phase => {
            if (!/mvp/i.test(phase.title)) {
              phase.platforms = this.platformsSelected;
              if (this.parallelMode) {
                phase.is_parallel_platform = true;
              } else {
                phase.is_parallel_platform = false;
              }
            }

          });
        }
      } else if (this.phaseService.checkIfOnlyRapidPrototypeSelected() && isTailorMade) {
        const prototype = this.dataService.homePageData.build_phases.find(
          phase => phase.title === 'Professional Prototype'
        );
        prototype.platforms = this.platformsSelected;
        if (this.parallelMode) {
          prototype.is_parallel_platform = true;
        } else {
          prototype.is_parallel_platform = false;
        }

        this.phaseService.selectedPlatformsForRapidPrototype = [];
        this.platformsSelected.forEach(platform => {
          this.phaseService.selectedPlatformsForRapidPrototype.push(platform);
        });
      } else {
        this.dataService.homePageData.build_phases.forEach(phase => {
          if (this.phase.id === phase.id) {
            phase.platforms = this.platformsSelected;
            if (this.parallelMode) {
              phase.is_parallel_platform = true;
            } else {
              phase.is_parallel_platform = false;
            }
          }
        });
      }
    } else if (this.generalView) {
      if (this.phaseService.checkIfOnlyRapidPrototypeSelected() && isTailorMade) {
        const prototype = this.dataService.homePageData.build_phases.find(
          phase => phase.title === 'Professional Prototype'
        );
        prototype.platforms = this.platformsSelected;
        if (this.parallelMode) {
          prototype.is_parallel_platform = true;
        } else {
          prototype.is_parallel_platform = false;
        }
        if (this.generalView) {
          this.phaseService.selectedHash.is_advance = false;
        }
        this.phaseService.selectedPlatformsForRapidPrototype = [];
        this.phaseService.selectedHash.platform_ids = [];
        this.platformsSelected.forEach(platform => {
          this.phaseService.selectedPlatformsForRapidPrototype.push(platform);
          this.phaseService.selectedHash.platform_ids.push(platform.id);
        });

      } else {
        this.dataService.homePageData.build_phases.forEach(phase => {
          if (!/mvp/i.test(phase.title) && (phase['type'] !== 'custom_phase')) {
            phase.platforms = this.platformsSelected;
            if (this.parallelMode) {
              phase.is_parallel_platform = true;
            } else {
              phase.is_parallel_platform = false;
            }
          } else if (this.isFromPhase) {
            if (!phase.platforms || phase.platforms.length === 0) {
              phase.platforms = [];
              phase.platforms.push(this.platformsSelected[0]);
            }
            phase.features.forEach(feature => {
              feature['phaseId'] = [];
              feature.platforms = [];
              feature.platforms = Object.assign([], phase.platforms);
              phase.platforms.forEach(platform => {
                feature['phaseId'].push(phase.id + '' + platform.id);
                this.phaseService.selectedFeatures.forEach(ftr => {
                  if (ftr.id === feature.id) {
                    ftr['phaseId'] = [];
                    ftr.platforms = [];
                    ftr.platforms = Object.assign([], phase.platforms);
                    ftr['phaseId'].push(phase.id + '' + platform.id);
                  }
                });
              });
            });
          } else if (!this.isFromPhase && phase.platforms) {
            const phasePlatforms = phase.platforms.map(p => p.id);
            const selectedPlatforms = this.platformsSelected.map(p => p.id);
            phasePlatforms.forEach(() => {
              phase.platforms.forEach((p1, index) => {
                if (selectedPlatforms.indexOf(p1.id) === -1) {
                  phase.platforms.splice(index, 1);
                }
              });
            });
            phase.features.forEach(feature => {
              const featurePlatforms = feature.platforms.map(p => p.id);
              featurePlatforms.forEach(() => {
                feature.platforms.forEach((pltfrm, i) => {
                  if (phase.platforms.map(p => p.id).indexOf(pltfrm.id) === -1) {
                    feature.platforms.splice(i, 1);
                    feature.phaseId.splice(feature.phaseId.findIndex(p => p === (phase.id + '' + pltfrm.id)), 1);
                  }
                });
              });
              featurePlatforms.forEach(() => {
                this.phaseService.selectedFeatures.forEach(ftr => {
                  if (feature.id === ftr.id) {
                    if (!ftr.platforms) { ftr.platforms = []; }
                    if (!ftr.phaseId) { ftr.phaseId = []; }
                    ftr.platforms.forEach((p1, i) => {
                      if (phase.platforms.map(p => p.id).indexOf(p1.id) === -1) {
                        ftr.platforms.splice(i, 1);
                        ftr.phaseId.splice(ftr.phaseId.findIndex(p => p === (phase.id + '' + p1.id)), 1);
                      }
                    });
                  }
                });
              });
            });
            this.phaseService.selectedFeatures.forEach(feature => {
              if (!feature.platforms || !feature.phaseId || !feature.platforms.length || !feature.phaseId.length) {
                feature.phaseId = [];
                feature.platforms = [];
              }
            });
            phase.features = phase.features.filter(f => f.platforms.length > 0);
            if ((phase.platforms.length === 0)) {
              phase.platforms.push(this.platformsSelected[0]);
              phase.selected = false;
              this.phaseService.removeSelectedBuildId(phase.index);
              this.phaseService.removeSelectedBuildIds(phase.index);
              this.phaseService.removeSelectedBuild(phase);
            }
          }
        });
        if (this.generalView) {
          this.phaseService.selectedHash.is_advance = false;
        }
        this.phaseService.selectedPlatforms = [];
        this.phaseService.selectedHash.platform_ids = [];
        this.tempPlatforms.filter(p => p.selected).forEach(platform => {
          this.phaseService.selectedPlatforms.push(platform);
          this.phaseService.selectedHash.platform_ids.push(platform.id);
        });
      }
    }
    if (this.phaseService.isAdvancedVersion) {
      if (this.dataService.homePageData.build_phases[4].selected) {
        this.setPlatformInMvpOrFullBuild(4);
      } else if (this.dataService.homePageData.build_phases[3].selected) {
        this.setPlatformInMvpOrFullBuild(3);
      }
    }
    if ((shouldSelectAllPlatforms === undefined) || (shouldSelectAllPlatforms === null) || (shouldSelectAllPlatforms && (index === this.tempPlatforms.length-1)) || (!shouldSelectAllPlatforms && (index === 1))) {
      this.phaseService.updatePrice();
    }
    this.dataService.showPlatformSelectionPopup = false;
  }

  setPlatformInMvpOrFullBuild(index) {
    const buildPhase = this.dataService.homePageData.build_phases;
    const selectedPlatform = this.platformsSelected.find(p => !buildPhase[index].platforms.map(p => p.id).includes(p.id));
    if (selectedPlatform) {
      const phasePlatforms = JSON.parse(JSON.stringify(buildPhase[index].platforms));
      phasePlatforms.push(selectedPlatform);
      buildPhase[index].platforms = phasePlatforms;
    }
  }

  public applySelectionsHandler(event) {
    this.applySelectionsToAllPhases = event.target.checked;
  }

  filterPlatformsForWebOrMobile(webOrMobile) {
    if(this.tempPlatforms) {
      if (webOrMobile === 'web') {
        return this.tempPlatforms.filter(p => ((p.title === 'Windows') || (p.title === 'Web') || (p.title === 'macOS')));
      } else {
        return this.tempPlatforms.filter(p => ((p.title !== 'Windows') && (p.title !== 'Web') && (p.title !== 'macOS')));
      }
    }
  }

  checkIfPlatformAdded() {
    const extraPlatform = [];
    const allPlatforms = this.phaseService.selectedPlatforms.map(p => p.id);
    this.platformsSelected.forEach(p => {
      if ((allPlatforms.length !== this.platformsSelected.length) || !allPlatforms.includes(p.id)) {
        extraPlatform.push(p.id);
      }
    });
    return (extraPlatform.length > 0) ? false : true;
  }

  selectAllPlatforms(shouldSelectAllPlatforms) {
    if (!shouldSelectAllPlatforms) {
      for(let i=(this.tempPlatforms.length - 1); i >= 0; i--) {
        if (this.tempPlatforms[i].selected) {
          this.togglePlatformSelections(this.tempPlatforms[i], i, null, null, shouldSelectAllPlatforms);
        }
      }
    } else {
      for(let i=0; i < this.tempPlatforms.length; i++) {
        if (!this.tempPlatforms[i].selected) {
          this.togglePlatformSelections(this.tempPlatforms[i], i, null, null, shouldSelectAllPlatforms);
        }
      }
    }
  }

}
