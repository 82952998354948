<div class="commonPopUp active">
  <div class="popOverlay"></div>
  <div class="popHolder saveProgress">
    <div class="closePopUp" (click)="closeShowProgress()"><span class="icon-cancel"></span></div>
    <ng-container *ngIf="phaseService.selectedFeatures?.length">
      <h3>Are you sure you want to leave the current build?</h3>
      <p>Don't worry, your progress is saved in your Dashboard.</p>
    </ng-container>
    <ng-container *ngIf="!phaseService.selectedFeatures?.length">
      <p>Your current build-card changes would be discarded if you move away from this page as you haven't selected any features. Are you sure?</p>
    </ng-container>

    <div class="renameCard">
      <label>Rename your Buildcard<sup class="tm">®</sup></label>
      <input type="text" [(ngModel)]="updatedProjectName" (keyup)="updateProject($event)" (keydown)="handleSpaces($event)" maxlength="30" placeholder="My Buildcard&#8482;" />
    </div>

    <div class="footerButton">
      <button class="cancleButton" (click)="closeShowProgress()">No, stay</button>
      <button class="doneButton" (click)="saveProgress()" [disabled]="!updatedProjectName.trim()">Yes, leave <img *ngIf="preventClick" src="./../../../../../assets/images/buttonLoader.gif" alt=""/>
      </button>
    </div>

  </div>
</div>
