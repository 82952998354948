import { Component, OnInit, Input, Renderer2, ElementRef } from '@angular/core';
import { DataService } from '@shared/services/data.service';
import { HomeTemplateService } from '@shared/services/home-template.service';
import {AppDataService} from '@rootmodule/app-data.service';
import { PhaseService } from '@shared/services/phase.service';
import {CommonService} from '@shared/services/common.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {TemplateDetailModel} from '@models/template-detail-model/template.detail.model';
import { Angulartics2Segment } from "angulartics2/segment";

@Component({
  selector: 'app-home-template-card',
  templateUrl: './home-template-card.component.html',
  styleUrls: ['./home-template-card.component.scss']
})
export class HomeTemplateCardComponent {
  constructor(public dataService: DataService, public analyticsSegment: Angulartics2Segment, public homeTemplateService: HomeTemplateService, public apiRequest: ApiRequests,
    public phaseService: PhaseService, private renderer: Renderer2, public appDataService: AppDataService,
     private elementRef: ElementRef) { }

  @Input('template') template;
  @Input('type') type;

  preventBubling(event) {
    event.stopPropagation();
  }

  opentemplateDetailPopup(template) {
    this.phaseService.reset(true);
    this.renderer.addClass(document.getElementById('app-component'), 'hide-scroll-bar');
    this.dataService.templateSlug = '';
    this.dataService.appPricingModal = '';
    this.dataService.appDetails = new TemplateDetailModel();
    if ( this.dataService?.appDetails?.id !== template.id) {
      this.dataService.templateSlug = template.slug;
      if (template.pricing_modal) {
        this.dataService.appPricingModal = template.pricing_modal;
      }
    }
    this.dataService.isShowTemplateModal = true;
    this.phaseService.showTemplateLoader = true;
    this.phaseService.trackEventForObject(this.analyticsSegment, 'template_view_details_cta_clicked', {templates_selected: template.title, buildcard_type: this.homeTemplateService.templateType});
  }


  public getLocaledPriceInt(price: any) {
    return this.dataService.getLocaledPriceInt(price);
  }

  selectTemplate(template, e) {
    if (this.phaseService.selectedApps.length < 3 && !template.selected) {
      if(document.getElementById(`image${template.id}`)) {
        document.getElementById(`image${template.id}`).remove()
      };
      var imageContainer = this.elementRef.nativeElement.querySelector(`.image${template.id}`);
      var viewportOffset = imageContainer.getBoundingClientRect();
      const imageElement = `<img src="${template.app_builder_icon_url}" alt="" id='image${template.id}' style="top: ${viewportOffset.top}px; position: fixed; max-width: 40px"/>`
      imageContainer.insertAdjacentHTML('beforeend', imageElement);

      let index =  this.homeTemplateService.all_templates.findIndex((element)=> {
        return element.id == template.id
      } ) % 3;

      if ( index === -1 ){
        index =  this.homeTemplateService.searchedTemplates.findIndex((element)=> {
          return element.id == template.id
        } ) % 3;
      }
      if ( index === -1 ){
        index =  this.homeTemplateService.sortedsearchedTemplates.findIndex((element)=> {
          return element.id == template.id
        } ) % 3;
      }
      if ( index === -1 ){
        index =  this.homeTemplateService.combined_templates.findIndex((element)=> {
          return element.id == template.id
        } ) % 3;
      }
      const cardWidth = this.elementRef.nativeElement.querySelector('.templateCard').offsetWidth;
      const cardHeight = window.innerHeight;
      const xDirection = index * cardWidth*3;
      document.getElementById(`image${template.id}`).animate([
        // keyframes
        { transform: 'rotate(45deg)' },
        { transform: `translate(-${xDirection}px, ${cardHeight*2}px)`},
        { easing: 'ease' }
      ], {
        duration: 4000,
      });
      setTimeout(function() {
        document.getElementById(`image${template.id}`).remove();
      }, 2000);
    }
    this.homeTemplateService.selectTemplate(template);
  }

  checkActive(template) {
    if (this.phaseService.selectedApps.length > 0) {
      let index = this.getIndex(template);
      return index >= 0 ?  true :  false;
    }
  }

  getIndex(template) {
   return  this.phaseService.selectedApps.findIndex(temp => template.id == temp.id)
  }
  isOverflowing(el) {
    return (el.clientWidth < el.scrollWidth);
  }

  templateselectionEvent(template) {
    if(this.checkActive(template) ) {
      this.phaseService.trackEventForObject(this.analyticsSegment, 'template_removed', {'template_removed': template.title, event_trigger: 'untick template'});
    }
  }

}
