<div class="speedBox" [ngClass]="{'disableBox': phaseService.isAdvancedVersion && phaseService.selectedSpeed, 'zindexIncrese' : showDropDown}">
  <!-- <h4>Select a delivery speed</h4> -->
  <!-- <div class="speedSlider" [ngClass]="{'disable-for-tailor-made': checkIfRapidPrototypeSelected()}">
    <ng5-slider [(value)]="(phaseService.assignSameSpeed || phaseService.buildCardEditMode || dataService.shareUrlData || checkForEnterpriseUser() || selectedSpeed) ? selectedSpeed.id : barValue"
                [options]="checkIfDisableSpeed() ? optionsWithoutRelax : options"
                (valueChange)="changeSpeedForPhases($event)" (mouseover)="showTooltip($event)" (mouseleave)="hideTooltip()">
    </ngx-slider>
    <div class="speedToolTip" *ngIf="showSliderTooltip">Choose a more relaxed speed and you'll pay less. It costs more, if you choose a speedy delivery.</div>
  </div> -->
  <!-- <div class="selectedSpeedRow">
    <div *ngIf="!checkIfDisableSpeed()" class="speedName" [ngClass]="{'active' : selectedSpeed?.title === 'Relaxed'}"><span>{{ selectedSpeed?.title }}</span></div>
    <div class="speedName" [ngClass]="{'active' : selectedSpeed?.title === 'Slow'}"><span>{{ selectedSpeed?.title }}</span></div>
    <div class="speedName" [ngClass]="{'active' : selectedSpeed?.title === 'Standard'}"><span>{{ selectedSpeed?.title }}</span></div>
    <div class="speedName" [ngClass]="{'active' : selectedSpeed?.title === 'Fast'}"><span>{{ selectedSpeed?.title }}</span></div>
    <div class="speedName" [ngClass]="{'active' : selectedSpeed?.title === 'Speedy'}"><span>{{ selectedSpeed?.title }}</span></div>
  </div> -->
  <div class="sliderBox">
    <div class="costDurationLabel">
      <span>COST</span>
      <span>DURATION</span>
    </div>
    <div class="speedSlider" [ngClass]="{'disable-for-tailor-made': checkIfRapidPrototypeSelected()}">
      <div class="sliderSteps" [ngClass]="{'active' : selectedSpeed?.title === 'Relaxed'}" (click)="changeSpeedForPhases('Relaxed')">
        <h3>Relaxed</h3>
        <div class="threeDotLoader" *ngIf="phaseService.showHideBottomBarLoader && !phaseService.showSpeedLoader">
          <div class="dot-flashing"></div>
        </div>
        <div *ngIf="(!phaseService.showHideBottomBarLoader && phaseService.speedPrices && phaseService.speedPrices['Relaxed']) || phaseService.showSpeedLoader">
          <h4><span>{{getCurrency()}}{{dataService.getLocaledPrice(phaseService.speedPrices['Relaxed']['price'])}}</span> <span>{{phaseService.speedPrices['Relaxed']['weeks']}} weeks</span></h4>
        </div>
      </div>

      <div class="sliderSteps" [ngClass]="{'active' : selectedSpeed?.title === 'Slow'}" (click)="changeSpeedForPhases('Slow')">
        <h3>Slow</h3>
        <div class="threeDotLoader" *ngIf="phaseService.showHideBottomBarLoader && !phaseService.showSpeedLoader">
          <div class="dot-flashing"></div>
        </div>
        <div *ngIf="(!phaseService.showHideBottomBarLoader && phaseService.speedPrices && phaseService.speedPrices['Slow']) || phaseService.showSpeedLoader">
          <h4><span>{{getCurrency()}}{{dataService.getLocaledPrice(phaseService.speedPrices['Slow']['price'])}}</span> <span>{{phaseService.speedPrices['Slow']['weeks']}} weeks</span></h4>
        </div>
      </div>

      <div class="sliderSteps" [ngClass]="{'active' : selectedSpeed?.title === 'Standard'}" (click)="changeSpeedForPhases('Standard')">
        <h3>Standard</h3>
        <div class="threeDotLoader" *ngIf="phaseService.showHideBottomBarLoader && !phaseService.showSpeedLoader">
          <div class="dot-flashing"></div>
        </div>
        <div *ngIf="(!phaseService.showHideBottomBarLoader && phaseService.speedPrices && phaseService.speedPrices['Standard']) || phaseService.showSpeedLoader">
          <h4><span>{{getCurrency()}}{{dataService.getLocaledPrice(phaseService.speedPrices['Standard']['price'])}}</span> <span>{{phaseService.speedPrices['Standard']['weeks']}} weeks</span></h4>
        </div>
      </div>

      <div class="sliderSteps" [ngClass]="{'active' : selectedSpeed?.title === 'Fast'}" (click)="changeSpeedForPhases('Fast')">
        <h3>Fast</h3>
        <div class="threeDotLoader" *ngIf="phaseService.showHideBottomBarLoader && !phaseService.showSpeedLoader">
          <div class="dot-flashing"></div>
        </div>
        <div *ngIf="(!phaseService.showHideBottomBarLoader && phaseService.speedPrices && phaseService.speedPrices['Fast']) || phaseService.showSpeedLoader">
          <h4><span>{{getCurrency()}}{{dataService.getLocaledPrice(phaseService.speedPrices['Fast']['price'])}}</span> <span>{{phaseService.speedPrices['Fast']['weeks']}} weeks</span></h4>
        </div>
      </div>

      <div class="sliderSteps" [ngClass]="{'active' : selectedSpeed?.title === 'Speedy'}" (click)="changeSpeedForPhases('Speedy')">
        <h3>Speedy</h3>
        <div class="threeDotLoader" *ngIf="phaseService.showHideBottomBarLoader && !phaseService.showSpeedLoader">
          <div class="dot-flashing"></div>
        </div>
        <div *ngIf="(!phaseService.showHideBottomBarLoader && phaseService.speedPrices && phaseService.speedPrices['Speedy']) || phaseService.showSpeedLoader">
          <h4><span>{{getCurrency()}}{{dataService.getLocaledPrice(phaseService.speedPrices['Speedy']['price'])}}</span> <span>{{phaseService.speedPrices['Speedy']['weeks']}} weeks</span></h4>
        </div>
      </div>

      <div class="pointerBar">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>

</div>
