<!-- Whatsapp schedule call cancellation modal -->
<div class="commonPopUp active" *ngIf="phaseService.showCancellationPopup">
    <div class="popOverlay"></div>
    <div class="popHolder WAScheduleCallCancellationPopup">
      <div class="closePopUp" (click)="closeCancellationPopup()" ><em class="icon-cancel"></em></div>
      <div class="textContainer">
        <div class="leftBox">
          <span class="imgContainer">
            <span class="innerCircle">
              <img src="../../../../../assets/images/phone-call-end.svg" alt="cancel call"/>
            </span>
          </span>        
        </div>
        <div class="rightBox">
          <h3>Cancel Call?</h3>
          <p>You are about to cancel a scheduled call, are you sure you want to continue?</p>
        </div>      
      </div>    
      <div class="footerButton">
        <button type="button" class="btn goAhead" (click)="closeCancellationPopup()">No, I want this call to go ahead</button>
        <button type="button" class="btn canclCall" (click)="cancelCall()">Yes, please cancel this call</button>
      </div>
    </div>
  </div>
  <!-- Whatsapp schedule call cancellation modal end -->