import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AdminAuthService} from '../../../services/auth.service';
import {GenericService} from '../../../services/generic.service';
import {Router} from '@angular/router';
import {CustomValidators} from '@shared/utils/validators';

@Component({
  selector: 'app-admin-login',
  templateUrl: 'admin-login.component.html'
})

export class AdminLoginComponent implements OnInit {
  public loginForm: FormGroup;
  public eyeClicked = false;
  public loader = false;
  public errorMessage: string;

  constructor(private _formBuilder: FormBuilder,
              public genericService: GenericService,
              private _authService: AdminAuthService,
              private _router: Router) {
  }

  public ngOnInit() {
    this.initLoginForm();
    this.checkLoggedIn();
  }

  public initLoginForm() {
    this.loginForm = this._formBuilder.group({
      email: new FormControl(null, [Validators.required, CustomValidators.emailValidator]),
      password: new FormControl(null, [Validators.required])
    });
  }

  public checkLoggedIn() {
    if (this._authService.isLoggedIn()) {
      this._router.navigate(['admin-dashboard/overview']);
    }
  }

  public loginAdmin(form) {
    if (form.valid) {
      this.loader = true;
      this._authService.login(form.value)
        .finally(() => {
          this.loader = false;
        })
        .subscribe(
          (data) => {
            this._authService.adminUser = data.payment_admin;
            this.genericService.setAdminUser(this._authService.adminUser);
            this._router.navigate(['admin-dashboard/overview']);
          },
          (error) => {
            const err = error.error;
            if ((err.errors && err.errors.password) || err.message) {
              this.errorMessage = 'Email or password is invalid';
            }
          }
        );
    }
  }

  public clearErrorMessage() {
    this.errorMessage = '';
  }

  public changeInputType(input: HTMLInputElement) {
    this.eyeClicked = !this.eyeClicked;
    this.eyeClicked ? input.type = 'text' : input.type = 'password';
  }
}
