<div class="tncStoreContent">
    <div class="logoLarge">
        <img src="../../../../../assets/images/logo-large.jpg" />
    </div>
    <h3>BUILDER STUDIO STORE TERMS AND CONDITIONS</h3>
    <p>These Builder Studio Store Terms and Conditions (“Agreement”) govern your access and use of Builder Studio Store (“Services”) and are entered into by and between Builder and the entity or person placing an order for or accessing the Services (“Customer” or “you”).  If you are an individual accessing or using the Services on behalf of a legal entity, you represent that you are authorized to accept this Agreement on behalf of such legal entity, and all references to “you” or “Customer” reference such legal entity.</p>
    <p>The “Effective Date” of this Agreement is the date which is the earlier of: (a) your initial access to the Services (as defined below) through any online provisioning, registration or order process, or (b) the effective date of the first Order Form referencing this Agreement. You acknowledge and agree that Builder may from time to time modify and/or update the terms and conditions of this Agreement, including the applicable Store Customer Policies that is provided along with this Agreement.</p>
    <p>BY INDICATING YOUR ACCEPTANCE OF THIS AGREEMENT OR ACCESSING OR USING THE SERVICES, YOU ARE AGREEING TO BE BOUND BY ALL TERMS, CONDITIONS AND NOTICES CONTAINED OR REFERENCED IN THIS AGREEMENT. IF YOU DO NOT AGREE TO THIS AGREEMENT, PLEASE DO NOT USE THE SERVICES. FOR CLARITY, EACH PARTY EXPRESSLY AGREES THAT THIS AGREEMENT IS LEGALLY BINDING UPON IT.</p>
    <p>THE SERVICES MAY NOT BE ACCESSED FOR PURPOSES OF MONITORING THEIR AVAILABILITY, PERFORMANCE, OR FUNCTIONALITY, OR FOR ANY OTHER BENCHMARKING OR COMPETITIVE PURPOSES. BUILDER’S DIRECT COMPETITORS ARE PROHIBITED FROM ACCESSING THE SERVICES, EXCEPT WITH BUILDER’S PRIOR WRITTEN CONSENT.</p>
    <ol>
        <li><h4>Account</h4>
            <p>To access and use the Services, you must register for a Builder Studio Store account (“Account”).  You must provide us with true, accurate, and complete information, including your full legal name, business address, phone number, an email address, payment information, and any other information as required by Builder or local laws. We reserve the right to reject your application for an Account, or cancel an existing Account, for any reason, at our sole discretion, including for violating this Agreement.</p>
            <p>You must be the older of: (i) 18 years, or (ii) at least the age of the majority in the jurisdiction where you reside and from which you use the Services to open the Account. If you do not qualify, please do not use the Services or access the Services.</p>
            <p>You agree that the person signing up for the Services by opening an Account will be the contracting party (“Store Owner”) for the purposes of this Agreement. The Store Owner is authorized to use any corresponding Account we may provide in connection with the Service.</p>
            <p><strong>If you are signing up for the Services on behalf of a legal entity including your employer</strong>, (i) such legal entity will be the Store Owner, (ii) you must use an authorized email address, business address and phone number including employer-issued email address, business address, and phone number, if you are signing up on behalf of your employer (iii) you represent and warrant that you have the authority to bind such legal entity to this Agreement.</p>
            <p><strong>You are responsible for securing your password.</strong> We cannot and will not be liable for any loss or damage from your failure to maintain the security of your Account and password, or actions taken on or in association with your Account.</p>
            <p>You may be required to submit a valid means of payment for which you are authorized to purchase Services.</p>
        </li>
        <li><h4>Access and Use</h4>
            <p>Subject to your payment of all Fees and compliance with the terms and conditions of this Agreement, Builder grants you a personal, limited, non-exclusive, non-transferable, non-sublicensable right to access and use the Services during the terms of this Agreement.</p>
            <p>You are responsible for all uses of the Services resulting from access provided by you, directly or indirectly, whether such access or use is permitted by or in violation of this Agreement.</p>
            <p>You acknowledge and agree that any contract of sale made through the Services is directly between you and your customer. You are the seller of record for all items you sell through the Services. You are responsible for the creation and operation of your Builder Store, your materials, the goods and services that you may sell through the Services, and all aspects of the transactions between you and your customers. You represent and warrant that your Builder Store, your materials and the goods and services you sell through the Services will be true, accurate, and complete, and will not violate any applicable laws, regulations or rights of third parties.</p>
            <p>You are solely responsible for the goods or services that you may sell through the Services (including description, price, fees, tax that you calculate, defects, required legal disclosures, regulatory compliance, offers or promotional content), including compliance with any applicable laws or regulations.</p>
            <p>You may not use the Services for any illegal or unauthorized purpose nor may you, in the use of the Services, violate any laws in your jurisdiction (including but not limited to copyright laws), the laws applicable to you in your customer’s jurisdiction. You will comply with all applicable laws, rules and regulations (including but not limited to obtaining and complying with the requirements of any license or permit that may be necessary to operate your store or that may be held by you) in your use of the Services and your performance of obligations under this Agreement.</p>
            <p>You may use the Services only for its business operations, and in accordance with this Agreement, the documentation, the usage restrictions, the usage limits and the Acceptable Use Policy (AUP), available at <<a href="https://builder.ai/terms/acceptable-use-policy" target="_blank">builder.ai/terms/acceptable-use-policy</a>>.  In the event that Builder suspects any breach of the requirements of this Section, Builder may suspend access to the Services without advanced notice, in addition to such other remedies as we may have.</p>
            <p>You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Services, or compete with the Services. You agree not to access the Services or monitor the Services, including any materials or information on the Services, using any robot, spiders, scraper, or other automated means. You agree not to work around, bypass, or circumvent any of the technical limitations of the Services,  use any tool to enable features or functionalities that are otherwise disabled in the Services, or decompile, disassemble or otherwise reverse engineer the Services.</p>
            <p>Builder reserves all rights not expressly granted to you in this Agreement.</p>
            <p>Builder may temporarily suspend your access to the Services if: (i) Builder reasonably determines that (A) there is a threat or attack on any of the Services; (B) your use of the Services disrupts or poses a security risk to the Builder; (C ) you are using the Services for fraudulent or illegal activities; (D) subject to applicable law, you has ceased to continue your business in the ordinary course; or (E) Builder's provision of the Services to you is prohibited by applicable law; (ii) any vendor of Builder has suspended or terminated Builder's access to or use of any third-party services or products required to enable you to access the Services (“Service Suspension”). </p>
            <p>Builder shall use reasonable efforts to provide written notice of any Service Suspension to You and to provide updates regarding resumption of access to the Services following any Service Suspension. Builder shall use commercially reasonable efforts to resume providing access to the Services as soon as reasonably possible after the event giving rise to the Service Suspension is cured. Builder will have no liability for any damage, liabilities, losses (including any loss of data or profits), or any other consequences that you may incur as a result of a Service Suspension.</p>
        </li>
        <li><h4>Marketplace</h4>
            <p>Builder may from time to time recommend, provide you with access to, or enable third party software, applications, products, services, consultants, or website links (collectively, “Third Party Services”) for your consideration or use, including via the Builder Marketplace. Such Third Party Services are made available only as a convenience, and your purchase, access or use of any such Third Party Services is solely between you and the applicable third party services provider (“Third Party Provider”).</p>
            <p>In addition to this Agreement, you also agree to be bound by the additional service-specific terms applicable to services you purchase from, or that are provided by, Third Party Providers.</p>
            <p>Any use by you of Third Party Services offered through the Services, Builder Marketplace or Builder’s website is entirely at your own risk and discretion, and it is your responsibility to read the terms and conditions and/or privacy policies applicable to such Third Party Services before using them. In some instances, Builder may receive a revenue share, commission, or other payments from Third Party Providers that Builder recommends to you or that you otherwise engage through your use of the Services.</p>
            <p>We do not provide any warranties or make representations to you with respect to Third Party Services. You acknowledge that Builder has no control over Third Party Services and will not be responsible or liable to you or anyone else for such Third Party Services.</p>
            <p>The availability of Third Party Services on Builder’s websites, including the Builder Marketplace, or the integration or enabling of such Third Party Services with the Services does not constitute or imply an endorsement, authorization, sponsorship, or affiliation by or with Builder. </p>
            <p>Builder does not guarantee the availability of Third Party Services and you acknowledge that Builder may disable access to any Third Party Services at any time in its sole discretion and without notice to you. Builder is not responsible or liable to anyone for discontinuation or suspension of access to, or disablement of, any Third Party Service. </p>
            <p>Builder strongly recommends that you seek specialist advice before using or relying on Third Party Services, to ensure they will meet your needs. In particular, tax calculators should be used for reference only and not as a substitute for independent tax advice, when assessing the correct tax rates you should charge to your customers.</p>
            <p>If you install or enable a Third Party Service for use with the Services, you grant us permission to allow the applicable Third Party Provider to access your data and other materials and to take any other actions as required for the interoperation of the Third Party Service with the Services, and any exchange of data or other Materials or other interaction between you and the Third Party Provider is solely between you and such Third Party Provider. Builder is not responsible for any disclosure, modification or deletion of your data or other materials, or for any corresponding losses or damages you may suffer, as a result of access by a Third Party Service or a Third Party Provider to your data or other materials.</p>
            <p>The relationship between you and any Third-Party Provider is strictly between you and such Third Party Provider, and we are not obligated to intervene in any dispute arising between you and a Third Party Provider.</p>
            <p>Under no circumstances will Builder be liable for any direct, indirect, incidental, special, consequential, punitive, extraordinary, exemplary or other damages whatsoever, that result from any Third-Party Services or your contractual relationship with any Third Party Provider. These limitations will apply even if Builder has been advised of the possibility of such damages. The foregoing limitations will apply to the fullest extent permitted by applicable law. You agree to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates, Builder partners, officers, directors, agents, employees, and suppliers harmless from any claim or demand, including reasonable attorneys’ fees, arising out of your use of a Third-Party Service or your relationship with a Third Party Provider.</p>
        </li>
        <li><h4>Payment, Fees and Taxes</h4>
            <p>You will pay all fees (“Fees”) as specified in the Order Form, and/or or applicable to your subscription, without offset or deduction. Except as otherwise specified herein: (i) Fees are exclusively based on Services subscriptions purchased, and not actual usage, (ii) payment obligations are non-cancelable and fees are non-refundable, and (ii) quantities purchased cannot be decreased during the relevant subscription term.</p>
            <p>You shall  provide Builder with valid and updated credit card information, or with a valid purchase order or alternative document reasonably accepted  by  us. If you provide credit card information, you expressly authorize us to charge such credit card for all purchased Services, including Fees for the initial subscription term and any renewal subscription term(s). Such payment shall be made in advance, either annually or in accordance with any different billing frequency stated at the time of your purchase. If no credit card is provided or other payment methods are allowed, Builder will invoice you in advance. invoiced fees shall be paid by you within the 30 days following the invoice date. You are responsible for providing complete and accurate billing and contact information to Builder and notifying Builder of any changes to such information.</p>
            <p>If you fail to make any payment when due, without limiting Builder’s other rights and remedies, Builder may charge interest on the past due amount at the rate of 1.5% per month (or the highest rate permitted by law, if less) from the payment due date until paid in full. You will be responsible for all reasonable expenses (including attorneys' fees) incurred by Builder in collecting such delinquent amounts. If you are late on payment for the Services, Builder may Suspend the Services or terminate the Agreement for breach.</p>
            <p>Fees do not include any taxes, levies, duties or similar governmental assessments of any nature, including, for example, value-added, sales, use or withholding taxes, accessible by any jurisdiction whatsoever (collectively, “Taxes”). You are responsible for paying all Taxes associated with its purchases hereunder. If Builder has the legal obligation to pay or collect Taxes for which you are responsible under this section, Builder will invoice you and you will pay that amount unless you provide us with a valid tax exemption certificate authorized by the appropriate taxing authority.</p>
        </li>
        <li><h4>Intellectual Property Rights and Your Materials</h4>
            <p>You grant Builder a non-exclusive, transferable, assignable, unlimited, perpetual, sub-licensable, non-personal,  royalty-free, worldwide right and license to host, use, distribute, expose, modify, run, copy, store, publicly perform, communicate to the public (including by telecommunication), broadcast, reproduce, make available, display, and translate, and create derivative works of any materials provided by you in connection with the Services. We may use our rights under this license to operate, provide, and promote the Services and to perform our obligations and exercise our rights under this Agreement.</p>
            <p>You represent, warrant, and agree that you have all necessary rights, including intellectual property rights, in the materials to grant this license. You agree that Builder can, at any time, may review and/or delete any or all of the materials submitted to the Services, although Builder is not obligated to do so.</p>
            <p>We do not pre-screen your materials and it is in our sole discretion to refuse or remove any materials from any part of the Services, including if we determine in our sole discretion that the goods or services that you offer through the Services, or the materials uploaded or posted to the Services, violate our AUP or this Agreement.</p>
            <p>Upon purchasing a domain name through Builder, domain registration will be preset to automatically renew each year so long as your Builder Account remains active. You acknowledge that it is your sole responsibility to deactivate the auto-renewal function should you choose to do so. You hereby grant to Builder and its affiliates a worldwide, perpetual, irrevocable, royalty-free license to use, distribute, disclose, and make and incorporate into its services any suggestion, enhancement request, recommendation, correction, or other feedback provided by you relating to the operation of Builder’s or its affiliates’ services (the “Feedback”).</p>
            <p>If we receive a DMCA (Digital Millennium Copyright Act) Notice, we may disable access or remove the allegedly infringing content from your Store. If you don’t think the claim is valid, you can send a counter notification. If you believe one of our merchants is infringing your intellectual property rights, you can send Builder a DMCA Notice. We will expeditiously disable access or remove the infringing content.</p>
        </li>
        <li><h4>Privacy, Data Protection  and Security</h4>
            <p>If you elect, you can execute a Data Processing Agreement (DPA) with Builder, available at <a href="https://www.builder.ai/terms/dpa-scc" target="_blank">https://www.builder.ai/terms/dpa-scc</a>.</p>
            <p>Builder will only access or use your data to provide the services and data processing for you.  Builder has implemented and will maintain administrative, physical, and technical safeguards to protect customer data. Builder may change the DPA where such change is required to comply with applicable law, applicable regulation, court order, or guidance issued by a governmental regulator or agency, where such change is expressly permitted by the DPA, or where such change (i) is commercially reasonable; (ii) does not result in a degradation of the overall security of the services; and (iii) does not expand the scope of or remove any restrictions on Builder’s processing of customer data.</p>
            <p>If either party believes that there has been a security incident , such party  must promptly notify the other party to the extent permitted by the law.  BUILDER SHALL NOT BE RESPONSIBLE FOR ANY COSTS OR EXPENSES RELATED TO A SECURITY INCIDENT THAT IS CAUSED BY THE ACTS OF OMISSION, MISCONDUCT, NEGLIGENCE, OR FRAUD BY,  OR OF YOU OR ANY OF YOUR USERS, EMPLOYEES, AGENTS, AFFILIATES  OR CONTRACTORS.</p>
        </li>
        <li><h4>Confidentiality</h4>
            <p>Confidential information means all information disclosed by a party (Disclosing Party), whether orally or in writing, that is designed as confidential or that reasonably should be understood to be confidential given the nature of the information and/or the circumstances of disclosure, including, without limitation, the terms and conditions of this Agreement (including pricing) business and marketing plans, technology and technical information, product plans and design, and business processes. Confidential information does not include any information that ( i) is or becomes generally known to the public without breach of any obligation owned to the disclosing party in this Agreement. (ii) was rightfully known to the receiving party, or (iv) was independently developed by the receiving Party without use of reference to Confidential Information of the Disclosing Party.</p>
            <p>The Receiving Party will use the same degree of care that it uses to protect its own confidential information of like kind (but not less than reasonable care) to (i) avoid using any confidential Information of the Disclosing Party for any purpose outside the scope of this Agreement, and (ii) except as otherwise authorized by the Disclosing Party in writing, limit access to the Disclosing Party’s Confidential Information to Receiving Party’s employees and contractors who (i) have a legitimate need to access Disclosing Party’s Confidential Information for purposes consistent with this Agreement and (ii) have signed confidentiality agreements with the Receiving Party containing protections not materially less protective than those contained herein.</p>
            <p>Nothing in this Agreement will prevent the Receiving Party from disclosing the Confidential Information pursuant to any judicial or governmental order or as otherwise required by law, provided that the Receiving Party gives the Disclosing Party reasonable prior notice of such disclosure unless such notice is prohibited by applicable law. In the event of an actual or threatened breach of the above confidentiality provisions, the parties agree that the non-breaching party may have no adequate remedy at law and shall be entitled to seek immediate injunctive and other equitable relief, without bond and without the necessity of showing actual money damages.</p>
        </li>
        <li><h4>Limitation of Liability & Indemnification</h4>
            <p>LIMITATION AND EXCLUSION OF LIABILITY. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF BUILDER WITH ALL OF ITS AFFILIATES ARISING OUT OF OR RELATED TO THIS AGREEMENT EXCEED THE TOTAL AMOUNT PAID BY CUSTOMER AND ITS AFFILIATES HEREUNDER FOR THE SERVICES OR EVENT GIVING RISE TO THE LIABILITY IN THE SIX MONTHS PRECEDING THE FIRST INCIDENT OUT OF WHICH THE LIABILITY AROSE. THE FOREGOING LIMITATION WILL APPLY WHETHER AN ACTION IS IN CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF LIABILITY BUT WILL NOT LIMIT CUSTOMER'S AND ITS AFFILIATES’ PAYMENT OBLIGATIONS UNDER THE “FEES AND PAYMENT” SECTION ABOVE.IN NO EVENT WILL BUILDER.AI OR ITS AFFILIATES HAVE ANY LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT FOR ANY LOST PROFITS, REVENUES, GOODWILL, OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER, BUSINESS INTERRUPTION OR PUNITIVE DAMAGES, WHETHER AN ACTION IS IN CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF LIABILITY, EVEN IF A PARTY OR ITS AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR IF A PARTY’S OR ITS AFFILIATES’ REMEDY OTHERWISE FAILS OF ITS ESSENTIAL PURPOSE. THE FOREGOING DISCLAIMER WILL NOT APPLY TO THE EXTENT PROHIBITED BY LAW.</p>
            <p>You agree to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates, partners, officers, directors, agents, employees, and suppliers harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of: (a) your breach of this Agreement or the documents it incorporates by reference (including the AUP and Store FAQ or Customer Policies); (b) your violation of any law or the rights of a third party; (c) any aspect of the transaction between you and your Customer, including but not limited to refunds, fraudulent transactions, alleged or actual violation of applicable laws (including but not limited to Federal and State consumer protection laws); or (d) any gross negligence or willful misconduct by you or your employees or other representatives. You will be responsible for any breach of this Agreement by your affiliates, agents or subcontractors and will be liable as if it were your own breach.</p>
            <p>Builder shall indemnify and defend you from and against all claims, actions, demands, (including suits by third parties) and all losses, liabilities, damages, costs, and expenses associated therewith (excluding  attorneys’ fees) arising from or in connection with: (i) any negligence or willful misconduct by Builder  or its employees or other representatives; or (ii) breach by Builder of the fully executed DPA including the unauthorized disclosure of Personal Data subject to a liability cap amounting to 6 months the fees you have paid to us; or (iii) any allegation that the Services infringe a third-party’s intellectual property rights, provided that Builder is given prompt written notice of such claim, lawsuit or action, your reasonable assistance and Builder has sole authority to settle or defend such claim, lawsuit or action; provided however Builder will not enter into any settlement or plea agreement that requires you to admit any liability or wrongdoing without your prior written consent.</p>
            <p>Your use of the Services is at your sole risk. The Services are provided on an “as is” and “as available” basis without any warranty or condition, express, implied or statutory. Builder does not warrant that the Services will be uninterrupted, timely, secure, or error-free. We do not warrant that the results that may be obtained from the use of the Services will be accurate or reliable. We are not responsible for any of your tax obligations or liabilities related to the use of our Services. We do not warrant that the quality of any products, services, information, or other materials purchased or obtained by you through the Services will meet your expectations, or that any errors in the Services will be corrected.</p>
        </li>
        <li><h4>Term of Agreement</h4>
            <p>This Agreement commences on the date you first accept it and continues until all your subscriptions hereunder have expired or have been terminated.</p>
            <p>Without limiting Builder’s termination or other rights hereunder, Builder reserves the right to suspend your access to the Services (and any related services) in whole or in part, without liability to Customer: (i) if your account with us or with a partner or reseller is thirty (30) days or more overdue; (ii) for your breach of this Agreement; or (iii) to prevent harm to other customers or third parties or to preserve the security, availability or integrity of the Services.  When practicable, we will use reasonable efforts to provide you with advance notice of the suspension (email sufficing).</p>
            <p>You may not assign any of the terms, conditions, rights or obligations hereunder, whether by operation of law or otherwise, without Builder’s prior written consent (not to be unreasonably withheld).</p>
            <p>Builder  may assign this Agreement in its entirety to its affiliate or in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all its assets.</p>
            <p>In the event of such a termination, Builder will refund Customer any prepaid fees covering the remainder of the term of all subscriptions for the period after the effective date of such termination. Subject to the foregoing, this Agreement will bind and inure to the benefit of the parties, their respective successors.</p>
            <p>The Builder entity entering into this Agreement, the address to which you should direct notices under this Agreement, the law that will apply in any dispute or lawsuit arising out of or in connection with this Agreement.</p>

            <table cellspacing="0" cellpadding="0">
                <tr>
                    <td>If Customer is domiciled in:</td>
                    <td>The Builder entity entering into this Agreement is:</td>
                    <td>Notices should be addressed to</td>
                </tr>
                <tr>
                    <td>The United States of America, including North America (NA) and Latin America  (LATAM)</td>
                    <td>Engineer.ai Corp.,  Delaware corporation</td>
                    <td>26 S. Rio Grande Street, Suite 2072 Salt Lake City, Utah 84101, USA</td>
                </tr>
                <tr>
                    <td>India, including Asia-Pacific (APAC)</td>
                    <td>Engineer.ai India Private Limited</td>
                    <td>77B, Sector 18, IFFCO Road, Gurugram, Haryana India</td>
                </tr>
                <tr>
                    <td>All Other, including Europe (EU), and Middle East and North Africa (MENA)</td>
                    <td>Engineer.ai Global Limited</td>
                    <td>North West House, 119 Marylebone Rd, London NW1 5PU, United Kingdom</td>
                </tr>
            </table>
        </li>

        <li><h4>Arbitration</h4>
            <p>Any dispute arising out of or in connection with this contract, including any question regarding its existence, validity, execution, or termination, shall be exclusively referred to and finally resolved by arbitration under LCIA rules, which rules are deemed to be incorporated by reference into this clause. The number of arbitrators shall be 3. The seat, or legal place, of arbitration shall be London, United Kingdom. The exclusive governing law shall be the English law, without regard to conflicts of law. Each party shall pay in advance 50% of the arbitration Costs, attorney’s fees excluded.</p>
        </li>
        <li><h4>Integration. Entire Agreement and Order of Precedence.</h4>
            <p>This Agreement is the entire agreement between the parties with respect to subject matter hereof and supersedes all prior and contemporaneous agreements, promotions, proposals, or representations, written or oral, published or unpublished in any Builder marketing materials, including its websites and webpages, concerning the subject matter. In the event of any conflict or inconsistency among the following documents, the order of precedence shall be: (1) the applicable Order Form, and (2) this Agreement, Titles and headings of sections of this Agreement are for convenience only and shall not affect the construction of any provision of this Agreement.</p>
        </li>
    </ol>
</div>
