import { NgModule, Injector, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScriptLazyloadComponent } from './script-lazyload.component';
import { createCustomElement } from '@angular/elements';


@NgModule({
  declarations: [ScriptLazyloadComponent],
  imports: [
    CommonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
  // entryComponents: [ScriptLazyloadComponent]
})
export class ScriptLazyloadModule {
  constructor(injector: Injector) {
    const el = createCustomElement(ScriptLazyloadComponent, { injector });
    customElements.define('script-lazyload', el);
  }
}
