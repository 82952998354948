<div class="formSection" [ngClass]="{'footerAction': screenTobeAppear === 'signup' || dataService.isSocialSignUpForStudioStore, 'footerAction2': screenTobeAppear === 'login' && !dataService.isSocialSignUpForStudioStore}"
  *ngIf='screenTobeAppear === "login" || screenTobeAppear === "signup"'>
  <div *ngIf="!dataService.isSocialSignUpForStudioStore">
    <div class="sectionHeading">
        <span *ngIf="screenTobeAppear === 'login'">sign in using</span>
        <span *ngIf="screenTobeAppear === 'signup'">sign up using</span>
    </div>
    <div class="socialLogins">
      <button type="button"><img src="../../../../../../../assets/images/googlelogin.svg" (click)="social_sign('google')" alt="" /></button>
      <button type="button"><img src="../../../../../../../assets/images/linkedinlogin.svg" (click)="social_sign('linkedin')" alt="" /></button>
      <button type="button"><img src="../../../../../../../assets/images/facebook_logo_signupmodal.svg" (click)="social_sign('facebook')" alt="" /></button>
    </div>
    <!--- login with email -->
    <div class="loginWithEmail">
      <div class="sectionHeading">
          <span *ngIf="screenTobeAppear === 'login'">sign in with email</span>
          <span *ngIf="screenTobeAppear === 'signup'">sign up with email</span>
      </div>
      <form [formGroup]="loginSignupForm">
        <ul>
          <li>
            <label>E-mail address</label>
            <input autoComplete="off" id='loginEmailInput' type="text" [ngClass]="{'redBorder' : emailExist || (!emailExist && (invalidEmail || (email.touched && checkIfEmailValid())))}"
              (focusout)="validateEmail()" placeholder="Enter your e-mail address" formControlName="email" required />
            <div class="errorMsg" *ngIf="emailExist">Email already exist</div>
            <div class="errorMsg" *ngIf="!emailExist && (invalidEmail || (email.touched && checkIfEmailValid()))">Please enter valid email</div>
          </li>

          <li *ngIf="screenTobeAppear === 'signup'">
            <label>Name</label>
            <input type="text" autocomplete="off" [ngClass]="{'redBorder' : (invalidName && !(name.touched && name.invalid)) || (name.touched && name.invalid)}"
              (focusout)="validateName()" placeholder="Enter your name" formControlName="name" required>
            <div class="errorMsg" *ngIf="invalidName && !(name.touched && name.invalid)">Please enter name</div>
            <div *ngIf="name.touched && name.invalid">
              <span *ngIf="name.errors['required']" class="errorMsg"> Name cannot be blank </span>
            </div>
          </li>

          <li *ngIf="screenTobeAppear === 'signup'">
            <label>Phone Number</label>
            <app-phone-number [contactNumber]="phone.value" [isSaveButtonClicked]="isSaveButtonClicked" (setContactInUserInfo)="setUserContact($event)">
            </app-phone-number>
          </li>

          <li *ngIf="screenTobeAppear === 'signup'">
            <label>Currency</label>
            <div class="currencySelBox">
              <span class="sel_state" formControlName="currency" (click)="showPopUp($event); hideOptionList($event)" [ngClass]="{'redBorder': invalidCurrency, 'active':isShowCurrencyDropdown()}"
                required>
              <span class="selectCurrencyValue" *ngIf="phaseService.showCurrencyOptionsForBnow">{{ getCurrency() ? getCurrencyCode() : "" }}</span>
              <span class="defaultCurrencyValue" *ngIf="!phaseService.showCurrencyOptionsForBnow"> Select Currency </span>
              </span>
              <div class="userDropdown" [ngClass]="isShowCurrencyDropdown() ? 'active' : ''">
                <div class="userDropdownBox">
                  <div class="currencychoose" (click)="selectCurrency(i, $event)" *ngFor="let currency of getCurrencies(); let i = index" [ngClass]="isCurrentCurrency(currency) ? 'active' : ''">
                    <div class="icons">
                      <img [src]="utilityService.addHttps(currency.icon_image_file_url)" alt="" />
                    </div>
                    <span class="currencyName">{{ currency.name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <span class="errorMsg" *ngIf="invalidCurrency">Please select a currency</span>
          </li>

          <li>
            <label class="forgotPassBx">Password <span (click)='forgotPassword()' *ngIf="screenTobeAppear === 'login'">Forgot your password?</span></label>
            <input type="password" [ngClass]="{'redBorder' : (invalidPassword && !(password.touched && password.invalid))}" (keydown)="registrationPwdChanged($event)"
              (focusout)="validatePassword()" placeholder="Enter password" minlength="8" formControlName="password" required>
            <span *ngIf="invalidPassword && !(password.touched && password.invalid)" class="errorMsg"> Password is not valid</span>
            <div *ngIf="password.touched && password.invalid">
              <span *ngIf="password.errors['minlength']" class="errorMsg"> Password should contain 8 characters</span>
              <span *ngIf="password.errors['required']" class="errorMsg"> Password cannot be blank </span>
            </div>
          </li>

          <li class="termsCondition" *ngIf="screenTobeAppear === 'signup'">
            <p>
              <input type="checkbox" id="termsconditionSignup" (click)="$event.stopPropagation()" (change)="validateTandCOnCheckboxChange()"
                formControlName="termsconditionSignup">
              <label class="icon-right" for="termsconditionSignup" (click)="$event.stopPropagation()">
              I agree to the <a (click)="commonService.navigateToTnC()" target="_blank" rel="noopener">Terms and Conditions</a>
            </label>
            </p>
            <div class="errorMsgDiloge" *ngIf="termsConditionInvalid">
              <em class="icon-exclamation-triangle"></em> <strong>Please check this box if you want to proceed</strong>
            </div>
          </li>
        </ul>
      </form>
    </div>
  </div>

  <div *ngIf="dataService.isSocialSignUpForStudioStore">
    <form>
      <button type="button" class="simpleBtn" (click)="showSignUp()"> Use another account </button>
      <div class="userBlock">
        <img src="../../../../../../../assets/images/user.png" alt="" />
        <div class="userDetail">
          <h3 class="userName">{{dataService.user.first_name + ' ' + dataService.user.last_name}}</h3>
          <p class="useremail">{{dataService.user.email}}</p>
        </div>
      </div>
      <ul>
        <li *ngIf='!this.dataService.user.phone_number'>
          <label>Phone Number</label>
            <app-phone-number [contactNumber]="phone.value" [isSaveButtonClicked]="isSaveButtonClicked" (setContactInUserInfo)="setUserContact($event)">
            </app-phone-number>
          <!-- <span class="errorMsg" *ngIf="checkIfPhoneInValid()">Please enter valid contact</span> -->
        </li>
        <li>
          <label>Currency</label>
          <div class="errorMsgBox">
            <span class="errorMsg show" *ngIf="!selectedCurrency && isCreateAccountWithAuth">
              Please select currency
          </span>
          </div>
          <div class="currencySelBox">
            <span class="sel_state" formControlName="currency" (click)="toggleCurrenctDropdown($event)" [ngClass]="isShowIpBasedCurrencyDropdown ? 'active' : ''"
              required>
              <span class="selectCurrencyValue" *ngIf="selectedCurrency">{{ selectedCurrency.attributes.name }}</span>
            <span class="defaultCurrencyValue" *ngIf="!selectedCurrency"> Select Currency </span>
            </span>
            <div class="userDropdown" [ngClass]="isShowCurrencyDropdown() ? 'active' : ''">
              <div class="userDropdownBox">
                <div class="currencychoose" (click)="selectCurrency(i)" *ngFor="let currency of getCurrencies(); let i = index" [ngClass]="isCurrentCurrency(currency) ? 'active' : ''">
                  <div class="icons">
                    <img [src]="utilityService.addHttps(currency.icon_image_file_url)" alt="" />
                  </div>
                  <span class="currencyName">{{ currency.name }}</span>
                </div>
              </div>
            </div>
          </div>

        </li>
        <li class="termsCondition">
          <p><input type="checkbox" id="termscondition" (click)="$event.stopPropagation()" [(ngModel)]="signupTerm" [ngModelOptions]="{standalone: true}">
            <label class="icon-right" for="termscondition" (click)="$event.stopPropagation()">I agree to the <a (click)="commonService.navigateToTnC()" target="_blank" rel="noopener">Terms and Conditions</a></label></p>
          <div class="errorMsgBox">
            <span class="errorMsg show" *ngIf="!signupTerm && isCreateAccountWithAuth">
                  Please check terms & conditions
              </span>
          </div>
        </li>


      </ul>
    </form>
  </div>
  <div class="clr"></div>
  <div class="actionFooter">

    <div *ngIf="dataService.isSocialSignUpForStudioStore">
      <button type="button" class="buttonStyle" (click)="createAccountThroughSocialAuth()"> Create Account </button>
    </div>
    <div class="clr"></div>


    <div *ngIf="!dataService.isSocialSignUpForStudioStore">
      <button *ngIf="!phaseService.conditionToCreateAndSyncBuildCardToBNow()" type="button" class="buttonStyle" [ngClass]="{'custom-disabled': phaseService.proceedClickedFromRental}"
        (click)="loginSignup()" [disabled]="CheckIfFormValid()">{{dataService.isExpertClicked}}
      <img *ngIf="phaseService.proceedClickedFromRental" src="../../../../../../../assets/images/buttonLoader.gif" alt=""/>
    </button>
      <button *ngIf="screenTobeAppear === 'signup' && phaseService.conditionToCreateAndSyncBuildCardToBNow()"
              type="button" class="buttonStyle" (click)="loginSignup()" [disabled]="isSignUpClicked">
        Sign up
    </button>
      <button *ngIf="screenTobeAppear === 'login' && phaseService.conditionToCreateAndSyncBuildCardToBNow()" type="button" class="buttonStyle"
        (click)="loginSignup()">Sign in
    </button>
    </div>
    <!---- common already have acount -->
    <h5 *ngIf="screenTobeAppear === 'signup'" (click)="showLoginOrSignup('login')">Already have an account? <strong>Sign in</strong></h5>
    <h5 *ngIf="screenTobeAppear === 'login' && !dataService.isSocialSignUpForStudioStore && !dataService.homePageData?.block_sign_up" (click)="showLoginOrSignup('signup')">Don't have an account? <strong>Sign up</strong></h5>
    <div class="sublinks">
      <!-- <span (click)="commonService.navigateToTnC(true)">Terms and Conditions</span> -->
      <span (click)="openUrls('https://www.builder.ai/terms/privacy')">Privacy</span>
      <span (click)="openUrls('https://www.builder.ai/terms/ccpa')">CCPA</span>
    </div>
  </div>
</div>


<!--  ---Forgot Your Password---   -->
<div class="formSection forgotPasswordWrapper" *ngIf="screenTobeAppear === 'reset'">
  <div>
    <div class="titletxt">
      <h3>
        <span>Forgot your password?</span>
      </h3>
    </div>
    <!--- login with email -->
    <div class="loginWithEmail">
      <form [formGroup]="loginSignupForm">
        <p class="forgotTitle">Enter your email address below and we’ll get you back on track.</p>
        <ul>
          <li>
            <label>E-mail address</label>
            <input type="text" autocomplete="off" [ngClass]="{'redBorder' : emailExist || (!emailExist && (invalidEmail || (email.touched && checkIfEmailValid())))}"
              (focusout)="validateEmail()" placeholder="Enter your e-mail address" formControlName="email" appAutofocus required>
            <div class="errorMsg" *ngIf="ErrorMsg">{{ErrorMsg}}</div>
            <div class="errorMsg" *ngIf="!emailExist && (invalidEmail || (email.touched && checkIfEmailValid()))">Please enter valid email</div>
          </li>
        </ul>
        <div class="footersection">
          <div class="actionbtn">
            <button type="submit" class="doneButton" (click)='send_email()' [disabled]="checkIfEmailValid() || checkIfUserNotFoundError()"> Send Reset Password Link</button>
          </div>
          <div class="alreadyHave">
            <span class="linkcolor" (click)='backToSignIn(true)'>Back to sign in</span>
          </div>
        </div>
        <div class="footersection signUpInstead">
          <div class="sectionHeading">
            <span>Sign up instead</span>
          </div>
          <div class="actionbtn">
            <button type="button" class="doneButton" type="submit" [disabled]="!checkIfUserNotFoundError()" (click)="showLoginOrSignup('signup', true)">Create my Studio account</button>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>

<!---- Password reset link sent sreen --->
<div class="formSection forgotPasswordWrapper" *ngIf="screenTobeAppear === 'resetSuccess'">
  <div class="formElements forgotpassBlock">
    <p class="forgotTitle">We’ve sent a password reset link to your email, click on the link to confirm.</p>
    <div class="footersection">
      <div class="actionbtn">
        <button type="button" class="doneButton" (click)='backToSignIn()'>Sign in again</button>
      </div>
    </div>
  </div>
</div>
<!--  ---Forgot Your Password End---  -->
