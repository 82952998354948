import { Injectable } from '@angular/core';
import {environment} from '@env/environment';
import {DataService} from '@shared/services/data.service';

import { Intercom } from 'ng-intercom';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class AppComponentService {
  constructor(public dataService: DataService,
              private intercom: Intercom) {

  }

  public handleIntercom() {
    // sets an idle timeout of 5 seconds, for testing purposes.
    const intercomId = environment.envName === "prod" ? "tk8uw9aj" : "tk8uw9aj";
    this.intercom.shutdown();
    this.intercom.boot({
      appId: intercomId,
      horizontal_padding: 50,
      vertical_padding: 50,
      // Supports all optional configuration.
      widget: {
        activator: '#intercom'
      },
      user_id: this.dataService.user
        ? `${this.dataService.user.first_name}_${this.dataService.user.last_name}_builder_${
          this.dataService.user.id
          }`
        : undefined
    });
    // this.startTimerForIntercom();
    this.intercom.show();
  }


  // public startTimerForIntercom() {
  //   this.idle.setIdle(5);
  //   // sets a timeout period of 40 seconds. after 45 seconds of inactivity, the user will be considered timed out.
  //   this.idle.setTimeout(40);
  //   // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
  //   this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

  //   this.resetIdleTime();
  //   this.idle.onIdleEnd.subscribe(() => {
  //     this.idleState = "No longer idle.";
  //   });
  //   this.idle.onTimeout.subscribe(() => {
  //     this.idleState = "Timed out!";
  //     this.timedOut = true;
  //     this.startTimeOut();
  //   });
  //   this.idle.onIdleStart.subscribe(() => {
  //     this.idleState = 'You"ve gone idle!';
  //   });
  //   this.idle.onTimeoutWarning.subscribe(countdown => {
  //     this.idleState = "You will time out in " + countdown + " seconds!";
  //   });
  // }

  // public startTimeOut() {
  //   this.intercom.showNewMessage('');
  //   this.idle.stop();
  // }

  // public resetIdleTime() {
  //   this.idle.watch();
  //   this.idleState = "Started.";
  //   this.timedOut = false;
  // }


}
