<div class="overviewSection" *ngIf="phaseService?.paymentData?.pending_installment && phaseService?.paymentData?.pending_installment?.id">

<!--  Monthly Payment UI Start-->
  <ul *ngIf="!dataService.buildCardData.upfront">
    <li *ngIf="isAdditionalFeature" class="headingRow">
      <div class="labelName"><strong>Studio Store package</strong></div>
    </li>
    <li *ngIf="!isAdditionalFeature">
      <div class="labelName">Deposit
        <div class="labelTooltip">
          <em class="icon-info-circle"></em>
          <div class="labelInfo">Studio Store deposit is not included in the installments and will be refunded after the last Studio Store installment has been paid.</div>
        </div>
      </div>
      <strong>{{ getCurrencySymbol() }} {{ dataService.getLocaledPrice(dataService?.buildCardData?.rental_info.studio_store_deposit) }}
      </strong>
    </li>

    <li>
      <div class="labelName">Development Duration
        <div class="labelTooltip">
          <em class="icon-info-circle"></em>
          <div class="labelInfo">Installments will begin once development is complete.</div>
        </div>
      </div>
      <strong>
        <span *ngIf="dataService?.buildCardData?.rental_info.app_development_weeks > 3"> {{getDevelopmentMonth()}} {{getDevelopmentMonth() > 1 ? 'months' : 'month'}} (
        </span>
        {{dataService?.buildCardData?.rental_info.app_development_weeks}} weeks
        <span *ngIf="dataService?.buildCardData?.rental_info.app_development_weeks > 3">)</span>
      </strong>
    </li>

    <li *ngIf="dataService.buildCardData.promotion">
      <div class="labelName">Promo Code</div>
      <strong *ngIf="dataService.buildCardData.promotion.type === 'MonthDiscountPromotion'"> First 3 months free </strong>
      <strong *ngIf="dataService.buildCardData.promotion.type === 'StorePercentagePromotion'"> {{dataService.buildCardData.promotion.code}} </strong>
    </li>

    <li>
      <div class="labelName">Installment Amount
        <div class="labelTooltip">
          <em class="icon-info-circle"></em>
          <div class="labelInfo">Installments for Studio Store will start after development. If promotion applied they will start after promotion.</div>
        </div>
      </div>
      <strong>{{ getCurrencySymbol() }} {{ dataService.getLocaledPrice(dataService.buildCardData?.installment_amount ) }}
      </strong>
    </li>

    <li><div class="labelName">Number of Installments</div> <strong>{{dataService?.buildCardData.rental_info.total_installments}}</strong></li>
    <li><div class="labelName">Length of Contract</div> <strong> {{ dataService?.buildCardData.rental_info.length_of_contract}} months</strong></li>
    <!-- SMB User Flow -->
    <li class="smbUserFlow" *ngIf="smbUser"><div class="labelName">Microsoft 365 (2 years license)</div> <strong><span class="strike">INR 3,000</span> Free</strong></li>

    <li><div class="labelName">Total Cost</div>
      <strong>{{ getCurrencySymbol() }} {{ dataService.getLocaledPrice(getTotalCostWithoutAdditionalFeatures()) }}</strong>
    </li>


<!--    additional feature section start-->
    <li *ngIf="isAdditionalFeature" class="headingRow">
      <div class="labelName"><strong>Additional Features</strong>
        <div class="labelTooltip">
          <em class="icon-info-circle"></em>
          <div class="labelInfo">If additional features take longer to develop than the Studio Store app, the Studio Store app will launch and the additional features will be added upon completion.</div>
        </div>
      </div>
    </li>
    <li *ngIf="isAdditionalFeature" >
        <div class="labelName">Development Duration
        <div class="labelTooltip">
          <em class="icon-info-circle"></em>
          <div class="labelInfo">Additional Features are paid for during their development and must be paid in full upon completion.</div>
        </div>
      </div>
      <strong>
        <span *ngIf="dataService?.buildCardData?.rental_info.development_weeks > 3">
          {{dataService?.buildCardData?.rental_info.development_months}} {{dataService?.buildCardData?.rental_info.development_months.length> 1 ? "months" : 'month' }} (
        </span>{{dataService?.buildCardData?.rental_info.development_weeks}} weeks<span *ngIf="dataService?.buildCardData?.rental_info.development_weeks > 3">)</span>
      </strong>
    </li>
    <li *ngIf="isAdditionalFeature" >
        <div class="labelName">Number of Installments
        <div class="labelTooltip">
          <em class="icon-info-circle"></em>
          <div class="labelInfo">Additional Features are paid every 4 weeks, based on their weekly cost until development completion.</div>
        </div>
      </div>
      <strong>{{dataService?.buildCardData?.rental_info.development_months}}</strong>
    </li>
     <li *ngIf="isAdditionalFeature">
       <div class="labelName">Total Cost</div>
      <strong>{{ getCurrencySymbol() }} {{ dataService.getLocaledPrice(dataService?.buildCardData?.rental_info.additonal_price)}}</strong>
    </li>

    <li *ngIf="isAdditionalFeature" class="headingRow">
      <div class="labelName"><strong>Deposit</strong></div>
    </li>
    <li *ngIf="isAdditionalFeature" >
        <div class="labelName">Studio Store Deposit
        <div class="labelTooltip">
          <em class="icon-info-circle"></em>
          <div class="labelInfo">Studio Store deposit is not included in the installments and will be refunded after the last Studio Store installment has been paid.</div>
        </div>
      </div>
      <strong>{{ getCurrencySymbol() }} {{ dataService.getLocaledPrice(dataService?.buildCardData?.rental_info.studio_store_deposit)}}</strong>
    </li>
    <li *ngIf="isAdditionalFeature" >
        <div class="labelName">Additional Features Deposit
        <div class="labelTooltip">
          <em class="icon-info-circle"></em>
          <div class="labelInfo">Additional Features deposit is not included in the installments and will be refunded after the last Additional Features installment has been paid.</div>
        </div>
      </div>
      <strong>{{ getCurrencySymbol() }} {{ dataService.getLocaledPrice(dataService?.buildCardData?.rental_info.additional_deposit)}}</strong>
    </li>
    <li *ngIf="isAdditionalFeature" >
        <div class="labelName">Total Deposit
        </div>
      <strong>{{ getCurrencySymbol() }} {{ dataService.getLocaledPrice( getTotalDeposit())}}</strong>
    </li>

    <!--    additional feature section end-->

  </ul>

<!--  Monthly Payment UI End-->

<!--  UPFront Payment UI Start-->
  <ul *ngIf="dataService.buildCardData?.upfront">
    <li *ngIf="isAdditionalFeature" class="headingRow">
      <div class="labelName"><strong>Studio Store package</strong></div>
    </li>
    <li>
      <div class="labelName">Upfront Payment
        <div class="labelTooltip">
          <em class="icon-info-circle"></em>
          <div class="labelInfo">
            <ol>
              <li *ngIf="!dataService.buildCardData.promotion">2 months discount applied because you selected upfront payment</li>
              <li *ngIf="dataService.buildCardData.promotion && dataService.buildCardData.promotion.type === 'MonthDiscountPromotion'">3 months discount applied because you applied a promotion</li>
              <li *ngIf="dataService.buildCardData.promotion && dataService.buildCardData.promotion.type === 'StorePercentagePromotion'">{{dataService.buildCardData.promotion.description}} </li>
            </ol>
          </div>
        </div>
      </div>
      <strong>
        <u *ngIf="phaseService.getTotalUpfrontPrice()">
          {{ getCurrencySymbol() }}
          {{ dataService.getLocaledPrice(phaseService.getTotalUpfrontPrice()) }}
        </u>
        {{ getCurrencySymbol() }} {{ dataService.getLocaledPrice(getTotalCostWithoutAdditionalFeatures()) }}
      </strong>
    </li>
    <li><div class="labelName">Development Duration</div>
      <strong>
        <span *ngIf="dataService?.buildCardData?.rental_info.app_development_weeks > 3">{{getDevelopmentMonth()}} {{getDevelopmentMonth() > 1 ? 'months' : 'month'}} (</span>
        {{dataService?.buildCardData?.rental_info.app_development_weeks}} weeks
        <span *ngIf="dataService?.buildCardData?.rental_info.app_development_weeks > 3">)</span>
      </strong>
    </li>
    <li><div class="labelName">Length of Contract</div>
      <strong>{{ dataService?.buildCardData.rental_info.length_of_contract}} months</strong>
    </li>

    <li class="smbUserFlow" *ngIf="smbUser">
      <div class="labelName">Microsoft 365 (2 years license)</div> <strong><span class="strike">INR 3,000</span> Free</strong>
    </li>

    <li *ngIf="isAdditionalFeature" class="headingRow" >
      <div class="labelName"><strong>Additional Features</strong>
        <div class="labelTooltip">
          <em class="icon-info-circle"></em>
          <div class="labelInfo">If additional features take longer to develop than the Studio Store app, the Studio Store app will launch and the additional features will be added upon completion.</div>
        </div>
      </div>
    </li>

    <li *ngIf="isAdditionalFeature" >
        <div class="labelName">Development Duration
        <div class="labelTooltip">
          <em class="icon-info-circle"></em>
          <div class="labelInfo">Installments will begin once development is complete.</div>
        </div>
      </div>
      <strong>
        <span *ngIf="dataService?.buildCardData?.rental_info.development_weeks > 3"> {{dataService?.buildCardData?.rental_info.development_months}}  {{dataService?.buildCardData?.rental_info.development_months.length> 1 ? "months" : 'month' }} (</span>
        {{dataService?.buildCardData?.rental_info.development_weeks}} weeks<span *ngIf="dataService?.buildCardData?.rental_info.development_weeks > 3">)</span>
      </strong>
    </li>

    <li *ngIf="isAdditionalFeature">
       <div class="labelName">Additional Feature Cost</div>
      <strong>{{ getCurrencySymbol() }} {{ dataService.getLocaledPrice(dataService?.buildCardData?.rental_info.additonal_price) }}</strong>
    </li>

     <li *ngIf="isAdditionalFeature" >
       <div class="labelName"><strong>Total Cost</strong></div>
    </li>
    <li *ngIf="isAdditionalFeature">
        <div class="labelName">Studio Store Package + Additional Features
        </div>
      <strong> {{ getCurrencySymbol() }} {{ dataService.getLocaledPrice(phaseService.getDiscountedPriceForCards(this.dataService?.buildCardData)) }} </strong>
    </li>
  </ul>


<!--  UPFront Payment UI End-->
  <div class="actionbx">
    <button type="button" class="buttonStyle" [ngClass]="{'custom-disabled': phaseService.proceedClickedFromRental}" (click)="proceedNext()">Continue to Payment
      <img *ngIf="phaseService.proceedClickedFromRental" src="../../../../../../../assets/images/buttonLoader.gif" alt=""/>
    </button>
  </div>
</div>


<div class="overviewSection"
     *ngIf="!(phaseService?.paymentData?.pending_installment && phaseService?.paymentData?.pending_installment?.id)">
  <div class="sectionDotloader">
    <div class="dot-flashing"></div>
  </div>
</div>
