import { Injectable } from '@angular/core';
import {environment} from '@env/environment';
import {Constants} from '@shared/utils/constants';
import {Subject, Subscription} from 'rxjs/Rx';
import {ApiRequests} from '@shared/services/api-requests.service';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {Router} from '@angular/router';
import {CommonService} from '@shared/services/common.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentBuilderService {

  showBillingInfo = true;
  showPaymentScreen = false;
  showSpecCallInfo = false;
  apiPollSub: Subscription;
  paymentStatusInterval: any;
  specCallInterval: any;
  transactionId: string;
  isCardPaid = false;
  contractTermsConditions = { conditionOne: false, conditionTwo: false, conditionThree: false, conditionFour: false, conditionFive: false };
  msaOpenedAt: string;
  isMSALinkCLicked = false;
  showMSATooltip = false;
  startCapchaseFlow = false;
  capchaseInstallments = [];
  capchaseIframeLink = '';
  isCapchaseFlowApplicable = false;
  isLoading = true;
  isCapchaseLink = false;
  isIframeLink: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private apiRequest: ApiRequests, private phaseService: PhaseService, public dataService: DataService,
              private router: Router, private commonService: CommonService) { }

  getPaymentCallBackUrl() {
    return environment.API_URL + Constants.apiEndPoints.paymentCallBack;
  }

  checkIfAllTermsSelected() {
    if (this.contractTermsConditions && this.contractTermsConditions.conditionOne && this.contractTermsConditions.conditionTwo
      && this.contractTermsConditions.conditionThree && this.contractTermsConditions.conditionFour) {
      return true;
    }
    return false;
  }

  startPollingForPayment(isStop?) {
    if (isStop) {
      clearInterval(this.paymentStatusInterval);
    } else {
      this.paymentStatusInterval = setInterval(() => {
        if (this.apiPollSub) {
          this.apiPollSub.unsubscribe();
        }
        this.apiPollSub = this.apiRequest.pollBuildCardStatus(this.phaseService.inProgressBuildCardId).subscribe(data => {
          this.isCardPaid = data.is_paid;
          if (this.isCardPaid) {
            this.showPaymentScreen = false;
            this.transactionId = data.transaction_id;
            clearInterval(this.paymentStatusInterval);
          }
        }, error => {

        });
      }, 2000);
    }
  }

  selectAllTermsAndConditions() {
    const terms = this.contractTermsConditions;
    terms.conditionOne = true;
    terms.conditionTwo = true;
    terms.conditionThree = true;
    terms.conditionFour = true;
  }

  setInstallmentType() {
    if (this.dataService.isMSAUpdatedFromPaymentPlan) {
      this.dataService.isContractAPICalled = true;
    }
    const installmentType = { frequency: this.dataService.showMonthly ? 'monthly' : 'weekly' };
    if (installmentType.frequency !== this.dataService.buildCardData.frequency) {
      this.apiRequest.createUpdateBuildCard(installmentType, this.phaseService.inProgressBuildCardId, '', 'rename_build_card').subscribe(() => {
        this.phaseService.fetchInstallments(true);
      });
    }
  }

  checkIfAddressUpdated() {
    const updatedKeys = [];
    if (this.dataService.user.address) {
      for (const key of (Object.keys(this.phaseService.billingInfo))) {
        if (key !== 'address') {
          for (const userKey of (Object.keys(this.dataService.user.address))) {
            const userKeyValue = ((this.dataService.user.address[userKey] === null) ? this.dataService.user.address[userKey] : decodeURIComponent(this.dataService.user.address[userKey]));
            if ((key === userKey) && (this.phaseService.billingInfo[key] !== userKeyValue)) {
              updatedKeys.push(key);
            }
          }
        }
      }
    }
    return ((updatedKeys.length) ? true : false);
  }

  getCareTextForSubsFlow(careType) {
    if (careType === 'enterprise_care_plus') {
      return 'Builder Enterprise One Plus';
    } else if (careType === 'standard_care_plus') {
      return 'Builder Studio One Plus';
    }
  }

  checkIfOnlyProfessionalPrototype() {
    if (this.phaseService.selectedPhases && (this.phaseService.selectedPhases.length === 1) &&
      (this.phaseService.selectedPhases[0].title === 'Professional Prototype') &&
      !this.phaseService.selectedPhases[0].is_tailor_made) {
      return true;
    }
    return false;
  }

  getAddedPlatformsCount() {
    const buildCardData = this.dataService.buildCardData;
    if (buildCardData && buildCardData?.progress?.platforms?.length > 0) {
      const approvedPlatforms = buildCardData['progress']['platforms'];
      const allPlatforms = this.phaseService.selectedPlatforms;
      return (allPlatforms?.length - approvedPlatforms?.length);
    }
  }
}
