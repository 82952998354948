import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudioIntroModalComponent } from './studio-intro-modal/studio-intro-modal.component';
import { RouterModule, Routes} from '@angular/router';
import {SharedModule} from '@shared/shared.module';
import { createCustomElement } from '@angular/elements';
import { NguCarouselModule } from '@ngu/carousel';


const childRouterConfig: Routes = [
  {path: '', component: StudioIntroModalComponent}
];

@NgModule({
  declarations: [StudioIntroModalComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(childRouterConfig),
    SharedModule,
    NguCarouselModule
  ]
})

export class StudioIntroModalModuleModule {
  constructor(injector: Injector) {
    if (!customElements.get('welcome-to-studio')) {
      const el = createCustomElement(StudioIntroModalComponent, {injector});
      customElements.define('welcome-to-studio', el);
    }
  }
}
