<div class="main-buildcard-bx" *ngIf="((phaseService.isEditFeatureRequest() && !appDataService?.urlParameters.cpe_user) || (dataService.buildCardData && dataService.buildCardData.id))">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-7 col-sm-12">
        <div class="featureListBlock" *ngIf="!phaseService.isRentalBuildCard() && phaseService.isEditFeatureRequest() && !appDataService?.urlParameters.cpe_user">
          <div class="top-bx">
            <h2> {{dataService.user.first_name}}, you have added <em>{{ dataService.additionalFeatures.length }}</em> additional {{dataService.additionalFeatures.length > 1 ? 'features' : 'feature'}}/{{checkRolesCount() > 1 ?'roles':'role'}}.<br>We'd like to grab more details on why you're requesting {{dataService.additionalFeatures.length > 1 ? 'them' : 'it'}}.
              <span *ngIf="dataService.changeFeatureRequestModel && dataService.changeFeatureRequestModel.requested_at">Last Updated at {{(dataService.changeFeatureRequestModel.requested_at )}}</span>
            </h2>
          </div>
          <div class="middle-bx">
            <div class="featureList fixHeight">
              <ul class="featurelist">
                <li *ngFor="let feature of dataService.additionalFeatures,let questionIndex = index">
                  <div class="md-subhead">
                    <h3>
                      <img src="../../../../../assets/images/keyboard-usb.svg" alt="" /> {{feature?.title}}
                      <div>
                        <em class="featureCost">{{ getCurrencySymbol() }}{{getBreakupPrice(feature.id)}}</em>
                        <!-- <span class="timeLine">
                          - {{feature.duration}}  {{ feature.duration.length > 1 ?  "weeks" : "week"}}
                        </span> -->
                      </div>
                    </h3>
                    <!-- <span class="featureRemoveButton" name="questionIndex" (click)="RemoveFeature($event,feature)"><img src="../../../../../assets/images/Group.svg"> Remove </span> -->
                    <span class="buttonWrap">
                      <span class="featureRemoveButton" name="questionIndex" (click)="RemoveFeature($event,feature)">
                        <img src="../../../../../assets/images/delete-bin-icon-xs.svg" alt="Remove" title="Remove feature">
                      </span>
                      <!-- <span class="editFeatureIcon">
                        <img src="../../../../../assets/images/edit-icon.svg" alt="Edit" title="Edit feature">
                      </span> -->
                    </span>
                  </div>
                  <div class="optionListContainer" [ngClass]="{'ErrText': (this.phaseService.updatedInstamentsClicked && !checkIfFeedbackGiven(feature.id))}">
                    <h4 *ngIf="!(feature?.title === 'Roles & Permissions')"><strong>Reason for adding this feature</strong></h4>
                    <h4 *ngIf="(feature?.title === 'Roles & Permissions')"><strong>Reason for udating your {{checkCountUpdates(feature) > 1 ?'roles':'role'}}</strong></h4>
                    <h5>
                      <div class="optionList">
                        <input type="radio" required  id="MissedByCpe" name="set{{questionIndex}}" (change)="SaveSelectedReason($event,feature?.id)"
                               [value]="feedBackStrings[0]" [checked]="setFeedbackSelected(feature.id) === feedBackStrings[0]"/>
                        <label *ngIf="!(feature?.title === 'Roles & Permissions')" for="MissedByCpe">Missed adding feature during spec call</label>
                        <label *ngIf="(feature?.title === 'Roles & Permissions')" for="MissedByCpe">Missed adding {{checkCountUpdates(feature) > 1 ?'roles':'role'}} during spec call</label>
                      </div>
                      <div class="optionList">
                        <input type="radio" required  id="NotDiscussed" name="set{{questionIndex}}" (change)="SaveSelectedReason($event,feature?.id)"
                               [value]="feedBackStrings[1]" [checked]="setFeedbackSelected(feature.id) === feedBackStrings[1]"/>
                        <label *ngIf="!(feature?.title === 'Roles & Permissions')" for="NotDiscussed">Feature not discussed during spec call</label>
                        <label *ngIf="(feature?.title === 'Roles & Permissions')" for="NotDiscussed">Roles not discussed during spec call</label>
                      </div>
                      <div class="optionList">
                        <input type="radio" required  id="NewRequirement" name="set{{questionIndex}}" (change)="SaveSelectedReason($event,feature?.id)"
                               [value]="feedBackStrings[2]" [checked]="setFeedbackSelected(feature.id) === feedBackStrings[2]">
                        <label for="NewRequirement">Identified as a new requirement post Buildcard kick off</label>
                      </div>
                    </h5>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- <button class="AddMoreFeaturebtn" (click)="startEditing('features', 'Buildcard Features Change',true)">Add more features</button> -->
        </div>


        <div class="featureListBlock " *ngIf="phaseService.isRentalBuildCard() && phaseService.isEditFeatureRequest() && !appDataService?.urlParameters.cpe_user">
          <div class="top-bx">
              <h2> {{dataService.user.first_name}}, you have added <em>{{ dataService.additionalFeatures.length }}</em> additional {{dataService.additionalFeatures.length > 1 ? 'features' : 'feature'}}/{{checkRolesCount() > 1 ?'roles':'role'}}.<br>We'd like to grab more details on why you're requesting {{dataService.additionalFeatures.length > 1 ? 'them' : 'it'}}.
                <span *ngIf="dataService.changeFeatureRequestModel && dataService.changeFeatureRequestModel.requested_at">Last Updated at {{(dataService.changeFeatureRequestModel.requested_at )}}</span>
              </h2>
              <!-- <div class="actionBlock">
              <span class="icon-block" [ngClass]="{activeDot : expandActionButton}" (click)="expandtriggerButtons()"><em class="icon-iconoptions"></em></span>
              <div class="userDropdownMenu" *ngIf="expandActionButton">
                <ul>
                  <li class="dropDownList"
                      *ngIf="!(dataService.buildCardData.build_card_pdf_url && pdf_status)">
                    <strong>Your Buildcard is being prepared.</strong> It will be available for download soon.
                  </li>

                  <li class="dropDownList" *ngIf="dataService.buildCardData.build_card_pdf_url && pdf_status"
                      (click)="openBuildCardPdf()">
                    <em class="icon-icondownload"></em> <span> Download </span>
                  </li>
                  <li class="dropDownList" (click)="phaseService.showInvitePopup(currentCard)"
                      *ngIf="dataService.buildCardData?.owner">
                    <em class="icon-iconinvite"></em> <span> Invite </span>
                  </li>
                  <li class="dropDownList" (click)="phaseService.openOwnerTranferPopup(dataService.buildCardData)"
                      *ngIf="dataService.buildCardData.owner && phaseService.isStatusBeforePayment(dataService.buildCardData)">
                    <em class="icon-icontransfer"></em> <span> Transfer </span>
                  </li>
                  <li class="dropDownList" (click)="phaseService.showSharePopup(currentCard)"
                      *ngIf="dataService.buildCardData?.owner">
                    <em class="icon-iconshare"></em> <span> Share </span>
                  </li>
                  <li class="dropDownList" *ngIf="this.dataService.homePageData.builder_partner.builder_now_url && featureLengthOfBuildCard() > 0">
                    <em class="icon-prototype"></em> <span (click)="togglePrototypeModel()"> View Prototype </span>
                                <li class="prototypeLink" (click)="togglePrototypeModel()"
                                      *ngIf="this.dataService.homePageData.builder_partner.builder_now_url">
                                      <em class="icon-prototype"></em> <span>View Prototype</span>
                                    </li>
                  <li class="dropDownList"
                      (click)="cloneBuildCard(dataService.buildCardData.uniq_code, dataService.buildCardData.currency)"
                      *ngIf="dataService.buildCardData.owner && (dataService.buildCardData.status === 'card_completed')">
                    <em class="icon-iconshare"></em> <span> Duplicate card </span>
                  </li>
                </ul>
              </div>
            </div> -->
          </div>
          <div class="middle-bx">
            <div class="featureList fixHeight">
              <ul class="featurelist">
                <li *ngFor="let feature of dataService.additionalFeatures,let questionIndex = index">
                  <div class="md-subhead">
                    <h3><img src="../../../../../assets/images/keyboard-usb.svg" alt="" /> {{feature?.title}} <em>{{ getCurrencySymbol() }}{{getBreakupPrice(feature.id)}}</em></h3>
                    <span class="featureRemoveButton" name="fIndex" (click)="RemoveFeature($event,feature)"><img src="../../../../../assets/images/Group.svg" alt="" /> Remove </span>
                  </div>
                  <div class="optionListContainer" [ngClass]="{'ErrText': (this.phaseService.updatedInstamentsClicked && !checkIfFeedbackGiven(feature.id))}">
                    <h4 *ngIf="!(feature?.title === 'Roles & Permissions')"><strong>Reason for adding this feature</strong></h4>
                    <h4 *ngIf="(feature?.title === 'Roles & Permissions')"><strong>Reason for udating your {{checkCountUpdates(feature) > 1 ?'roles':'role'}}</strong></h4>
                    <h5>
                      <div class="optionList">
                        <input type="radio" required  id="MissedByCpe" name="set{{questionIndex}}"
                               (change)="SaveSelectedReason($event,feature?.id)" [value]="feedBackStrings[0]"
                                [checked]="setFeedbackSelected(feature.id) === feedBackStrings[0]"/>
                                <label *ngIf="!(feature?.title === 'Roles & Permissions')" for="MissedByCpe">Missed adding feature during spec call</label>
                                <label *ngIf="(feature?.title === 'Roles & Permissions')" for="MissedByCpe">Missed adding {{checkCountUpdates(feature) > 1 ?'roles':'role'}} during spec call</label>
                      </div>
                      <div class="optionList">
                        <input type="radio" required  id="NotDiscussed" name="set{{questionIndex}}" (change)="SaveSelectedReason($event,feature?.id)"
                               [value]="feedBackStrings[1]" [checked]="setFeedbackSelected(feature.id) === feedBackStrings[1]"/>
                               <label *ngIf="!(feature?.title === 'Roles & Permissions')" for="NotDiscussed">Feature not discussed during spec call</label>
                               <label *ngIf="(feature?.title === 'Roles & Permissions')" for="NotDiscussed">Roles not discussed during spec call</label>
                      </div>
                      <div class="optionList">
                        <input type="radio" required  id="NewRequirement" name="set{{questionIndex}}" (change)="SaveSelectedReason($event,feature?.id)"
                               [value]="feedBackStrings[2]" [checked]="setFeedbackSelected(feature.id) === feedBackStrings[2]"/>
                        <label for="NewRequirement">Identified as a new requirement post Buildcard kick off</label>
                      </div>
                    </h5>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- <button class="AddMoreFeaturebtn" (click)="startEditing('features', 'Buildcard Features Change',true)">Add more features</button> -->
        </div>

          <div class="buildCardmain" *ngIf="!phaseService.isEditFeatureRequest() || appDataService?.urlParameters.cpe_user">
            <div class="buildCardTop">
              <div class="buildCardLeft">
                <h1 *ngIf="!phaseService.isRentalBuildCard()">
                  <span class="userspace"> Hi {{dataService.user.first_name}}, </span> Here is your Buildcard<sup class="tm">®</sup>
                  <div class="icon-block">
                  <span class="info" (click)="infoIconClicked()">
                    &nbsp;<img alt="" src="./../../../../../assets/images/info_blue.png" alt="" /></span>
                    <div class="buildcard_summery" *ngIf="isInfoIcon">
                      <div class="close-btn" (click)="infoIconClicked()"><em class="icon-cancel"></em></div>
                      <p> A Buildcard is a summary of your requirements with cost and time estimates.</p>
                    </div>
                  </div>
                </h1>
                <h1 class="rental-head" *ngIf="phaseService.isRentalBuildCard()">
                  <span class="userspace"> Hi {{dataService.user.first_name}}, </span>
                  Here is your Studio Store + <strong class="card">Buildcard<sup class="tm light">®</sup>.</strong>
                  <div class="icon-block">
                  <span class="info" (click)="infoIconClicked()"><img alt=""
                                                                      src="./../../../../../assets/images/info_blue.png"></span>
                    <div class="buildcard_summery" *ngIf="isInfoIcon">
                      <div class="close-btn" (click)="infoIconClicked()"><em class="icon-cancel"></em></div>
                      <p> A Buildcard is a summary of your requirements with cost and time estimates.</p>

                    </div>
                  </div>
                </h1>

              </div>

              <div class="buildCardright" >
                <span class="icon-block" [ngClass]="{activeDot : expandActionButton}" (click)="expandtriggerButtons()"><em class="icon-iconoptions"></em></span>
              </div>

            <div class="userDropdownMenu" *ngIf="expandActionButton">
              <ul>
                <li class="dropDownList"
                    *ngIf="!(dataService.buildCardData.build_card_pdf_url && pdf_status)">
                  <strong>Your Buildcard is being prepared.</strong> It will be available for download soon.
                </li>

                <li class="dropDownList" *ngIf="dataService.buildCardData.build_card_pdf_url && pdf_status"
                    (click)="openBuildCardPdf()">
                  <em class="icon-icondownload"></em> <span> Download </span>
                </li>
                <li class="dropDownList" (click)="phaseService.showInvitePopup(currentCard)"
                    *ngIf="dataService.buildCardData?.owner">
                  <em class="icon-iconinvite"></em> <span> Invite </span>
                </li>
                <li class="dropDownList" (click)="phaseService.openOwnerTranferPopup(dataService.buildCardData)"
                    *ngIf="dataService.buildCardData.owner && phaseService.isStatusBeforePayment(dataService.buildCardData)">
                  <em class="icon-icontransfer"></em> <span> Transfer </span>
                </li>
                <li class="dropDownList" (click)="phaseService.showSharePopup(currentCard)"
                    *ngIf="dataService.buildCardData?.owner">
                  <em class="icon-iconshare"></em> <span> Share </span>
                </li>
                <li class="dropDownList" *ngIf="this.dataService.homePageData.builder_partner.builder_now_url && featureLengthOfBuildCard() > 0  && !phaseService.isFeatureRequest">
                  <em class="icon-prototype"></em> <span (click)="togglePrototypeModel()"> View Prototype </span>
<!--                <li class="prototypeLink" (click)="togglePrototypeModel()"-->
<!--                    *ngIf="this.dataService.homePageData.builder_partner.builder_now_url">-->
<!--                  <em class="icon-prototype"></em> <span>View Prototype</span>-->
<!--                </li>-->
                <li class="dropDownList"
                    (click)="cloneBuildCard(dataService.buildCardData.uniq_code, dataService.buildCardData.currency)"
                    *ngIf="(dataService.buildCardData.status === 'card_completed') || (dataService.buildCardData.status === 'started')
                      || (dataService.buildCardData.status === 'under_development') || (dataService.buildCardData.status === 'on_hold')">
                  <em class="icon-iconshare"></em> <span> Duplicate card </span>
                </li>

              </ul>
            </div>
            <div class="last-update">
              Last edited: {{dataService.buildCardData.updated_at | date :  'dd-MMM-y'}}
            </div>
          </div>

            <div *ngIf="!phaseService.isRentalBuildCard()" class="buildcardBx">
              <div class="tabing-block">
                <div class="tabList" [ngClass]="{'active': sectionName === 'project_info'}"
                     (click)="showClickedSection('project_info')"> Buildcard&nbsp;Info
                </div>
                <div class="tabList" [ngClass]="{'active': sectionName === 'similar_apps'}"
                     (click)="showClickedSection('similar_apps')"> Similar Apps ({{ phaseService.selectedApps.length }})
                </div>
                <div class="tabList" [ngClass]="{'active': sectionName === 'features'}"
                     (click)="showClickedSection('features')"> Features ({{ phaseService.selectedFeatures.length }})
                </div>
                <div class="tabList" [ngClass]="{'active': sectionName === 'phases'}"
                     (click)="showClickedSection('phases')"> Phases ({{ phaseService.selectedPhases.length }})
                </div>
              </div>
              <!---- tab 1 -->
              <div class="fixHeight">
                <div class="buildcard-detail " *ngIf="sectionName === 'project_info'">
                  <h3 class="detail-heading">Buildcard basic details </h3>
                  <div class="detail-list-block ">
                    <div class="list">
                      <div class="project-detail">
                        <div class="pro-icon"><em class="icon-iconprojectname"></em>
                        </div>
                        <div class="pro-text-block">
                          <h3 class="pro-head">Name
                            <div class="project-action2" (click)="handleFocusIn()"
                                   *ngIf="!isEditName && phaseService.isEditableAndPayable() && !dataService.isEditBuildCardAfterSecurityPaid()">
                                <em class="icon-iconedit"></em>
                              </div>
                          </h3>
                          <div class="pro-name-block">
                            <div class="pro-name-strip save" *ngIf="isEditName">
                              <input type="text" placeholder="ABC Mobile App" class="strip-head"
                                     [disabled]="componentType === 'project-settings' || !phaseService.isEditableAndPayable()"
                                     name="projectname" #projectName maxlength="30"
                                     [(ngModel)]="phaseService.currentProjectName" autofocus="autofocus" onFocus="this.select()" (keyup)="updateProjectName($event)">
                              <!--                        <i></i>-->
                              <div class="project-action2 save-mobile" (click)="updateBuildCardName()"><span
                                class="btnStyle1"> Save </span></div>
                            </div>

                            <div class="pro-name-strip edit" *ngIf="!isEditName">
                              <p class="strip-head"> {{ phaseService.currentProjectName }} </p>
                            </div>

                            <div class="buildDescription" *ngIf="!isEditDesc">
                              <h3 class="pro-head">Description
                                <div class="project-action2" *ngIf="phaseService.isEditableAndPayable() && !dataService.isEditBuildCardAfterSecurityPaid()" (click)="handleEditDesc()"><em class="icon-iconedit"></em></div>
                              </h3>
                              <p *ngIf="!phaseService.natashaSearchedText">Enter Buildcard Description</p>
                              <p *ngIf="phaseService.natashaSearchedText" [innerHTML]="phaseService.natashaSearchedText"></p>
                            </div>

                            <div class="buildDescription" *ngIf="isEditDesc">
                              <h3 class="pro-head">Description </h3>
                              <div class="textEditor">
                                <ckeditor [config]="editorConfig" [editor]="commonService.Editor" required
                                          [(ngModel)]="phaseService.natashaSearchedText" data="" name="text">
                                </ckeditor>
                              </div>
                              <div *ngIf='checkLengthValidation()' class="errorMsgCount">Please describe your idea with minimum 30 characters</div>
                               <div class="saveButton" [ngClass]="{'custom-disabled': checkLengthValidation()}" (click)="updateBuildCardDesc()">Save</div>
                            </div>

                          </div>
                        </div>

                      </div>

                    </div>
                    <!-- <div class="list">
                      <div class="project-detail">
                        <div class="pro-icon"><em class="icon-iconproducttype"></em>
                        </div>
                        <div class="pro-text-block">
                          <h3 class="pro-head"> Buildcard Type </h3>
                          <div class="pro-name-block">
                            <div class="pro-name-strip change">
                              <p class="strip-head" *ngIf="phaseService.selectedProduct">
                                {{phaseService.selectedProduct?.title}} </p>
                              <p class="strip-head" *ngIf="!phaseService.selectedProduct">Something</p>
                              <div class="project-action" (click)="startEditing('apps', 'Buildcard Project Type change')" *ngIf="phaseService.isEditableAndPayable() && !dataService.isRestrictEdit && !dataService.isEditBuildCardAfterSecurityPaid()"><span class="btnStyle1"> Change
                          </span></div>
                            </div>

                          </div>
                        </div>
                      </div>

                    </div> -->
                    <div class="list" *ngIf="phaseService.selectedTeam?.title !== 'Anywhere'">
                      <div class="project-detail">
                        <div class="pro-icon"><em class="icon-iconlocation"></em>
                        </div>
                        <div class="pro-text-block">
                          <h3 class="pro-head"> Team Location </h3>
                          <div class="pro-name-block">
                            <div class="pro-name-strip change">
                              <p class="strip-head"> {{phaseService.selectedTeam?.title}} </p>
                              <div class="project-action" (click)="startEditing('delivery', 'Buildcard™ Team location change')"
                                   *ngIf="phaseService.isEditableAndPayable()  && componentType==='summary' && !dataService.isRestrictEdit && !dataService.isEditBuildCardAfterSecurityPaid()">
                                <span class="btnStyle1"> change </span>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
              <!---- tab 2 -->
              <div *ngIf="sectionName === 'similar_apps'" class="fixHeight">
                <div class="buildcard-detail ">
                  <h3 class="detail-heading"> Similar Apps ({{ phaseService.selectedApps.length }})</h3>
                  <div class="detail-list-block list-block2 ">
                    <div class="app-box"
                         *ngFor="let app of phaseService?.selectedApps">
                      <div class="app-left">
                        <span class="simiapp-ico-img"> <img alt="" [src]="app.app_builder_icon_url"> </span>
                        <span> {{ app?.title }}</span>
                        <!--                    <span> {{ app?.title.length > 20 ? (app?.title.substring(0, 20) + '...') : app?.title }}</span>-->
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="app-sep">
                <div class="buildcard-detail">
                  <h3 class="detail-heading"> Competitors ({{ phaseService?.selectedHash.competitors.length }})
                    <span class="btnStyle1 feature-btn"
                          *ngIf="phaseService?.selectedHash.competitors?.length < 3 && phaseService.isEditableAndPayable() && !dataService.isRestrictEdit && !dataService.isEditBuildCardAfterSecurityPaid()"
                          (click)="openAddCompetitorPopup()"> Change
                </span>
                  </h3>
                  <div class="detail-list-block list-block2"
                       *ngIf="(phaseService.selectedHash.competitors.length > 0 && componentType === 'project-settings') || componentType === 'summary'">
                    <div class="app-box" *ngFor="let product of phaseService.selectedHash.competitors; let i = index">
                      <div class="app-left">
                    <span class="competitor-ico-img"> <img alt=""
                                                           [src]="product.image ? utilityService.addHttps(product.image) : './../../../assets/images/default.png'">
                    </span>
                        <span>
                      {{product.product_name.length > 36 ? (product.product_name.substring(0, 36) + '...') : product.product_name }}
                    </span>
                      </div>
                      <div class="app-right" (click)="deleteCompetitors(i)" *ngIf="phaseService.isEditableAndPayable()"><em
                        class="icon-deletenew"> </em></div>
                    </div>
                  </div>
                </div>

              </div>
              <!---- tab 3 -->
              <div class="fixHeight">
                <div class="buildcard-detail " *ngIf="sectionName === 'features'">
                  <h3 class="detail-heading"> Features ({{ phaseService.selectedFeatures.length }})
                    <div class="editAction" *ngIf="enableEditForPaidCards()"
                       (click)="startEditing('features', 'Buildcard™ Features Change' )" >
                      <em class="icon-iconedit"></em>
                    </div>
                    <span class="btnStyle1 feature-btn" (click)="startEditing('features', 'Buildcard™ Features Change')" *ngIf="phaseService.isEditableAndPayable() && !dataService.isRestrictEdit && !dataService.isEditBuildCardAfterSecurityPaid()"> Change </span>
                  </h3>
                  <div class="detail-list-block feature-box ">
                    <div>
                      <ul class="list-block">
                        <li
                          *ngFor="let feature of phaseService.selectedFeatures">
                          {{feature?.title}}
                          <span class="newTag" *ngIf="feature.type== 'new'">New</span>
                        </li>
                        <li class="custom-disabled"
                          *ngFor="let feature of dataService?.modifiedBuildCardData?.features.remove.data">
                          {{feature?.attributes?.title}}
                          <span class="removedTag">Removed</span>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
              <!---- tab 4 -->
              <div class="fixHeight">
                <div class="buildcard-detail " *ngIf="sectionName === 'phases'">
                  <h3 class="detail-heading"> Phases ({{ phaseService.selectedPhases.length }})
                    <div class="editAction" *ngIf="enableEditForPaidCards()"
                       (click)="startEditing('delivery', 'Buildcard™ Phases Change' )">
                      <em class="icon-iconedit"></em>
                    </div>
                    <span class="btnStyle1 feature-btn" (click)="startEditing('delivery', 'Buildcard™ Phases Change')"
                          *ngIf="phaseService.isEditableAndPayable() && !dataService.isRestrictEdit && !dataService.isEditBuildCardAfterSecurityPaid()"> Change  </span>
                  </h3>
                  <div class="detail-list-block">
                    <div class="phases-box">
                      <div class="list-box {{ phase?.title.split(' ')[0].toLowerCase() + '-theme' }}"
                           *ngFor="let phase of phaseService.selectedPhases; let i = index;">
                        <div class="pro-head">
                          <h3> {{ ((phase?.title === 'Professional Prototype') ? dataService.returnNeededPrototypeName() : phase?.title) }} </h3>
                        </div>
                        <div class="pro-mid">
                          <ng-container *ngIf="phase.title.toLowerCase() == 'product roadmap'; else showPlatform">
                            <p>
                              Our product roadmap will help put your launch plan in place so that you can align your business and marketing strategy.
                            </p>
                          </ng-container>
                          <ng-template #showPlatform>
                            <p class="head" *ngIf="!phaseService.checkIfBuildCardForRapidPrototype() || !phaseService.selectedPhases[0].is_tailor_made"> Platform </p>
                            <p class="head" *ngIf="phaseService.checkIfBuildCardForRapidPrototype() && phaseService.selectedPhases[0].is_tailor_made"> Devices </p>
                            <div class="plateform-list-block">
                              <div class="Platform-list showPlateform-">
                                <ul>
                                  <li *ngFor="let platform of phase.platforms; let i = index">
                                    <div class="newTag" *ngIf="platform.type=='new'">New</div>
                              <span class="icons">
                               <img [alt]="platform?.title" [src]="platform?.icon">
                              </span>
                                    <!--<span class="icons"> <img alt="" src="./../../../../../assets/images/android.svg"> </span> -->
                                    <span class="mobile-hide"> {{ platform?.title }} </span>
                                  </li>
                                </ul>
                              </div>
                              <div class="Platform-detail-list" *ngIf="phase.platforms?.length > 3">
                                <span class="showallplateform"> + {{ phase.platforms?.length - 3 }} </span>
                              </div>
                            </div>
                          </ng-template>

                        </div>
                        <div class="pro-ftr">
                          <p class="head"> Buildcard Speed </p>
                          <div class="progress-box">
                            <div> {{phase.speed?.title}}</div>
                            <div class="progress">
                              <div class="progress-bar bg-green"
                                   [class.relaxed]="phase.speed?.title == 'Relaxed'"
                                   [class.slow]="phase.speed?.title == 'Slow'"
                                   [class.standard]="phase.speed?.title == 'Standard'"
                                   [class.fast]="phase.speed?.title == 'Fast'"
                                   [class.speedy]="phase.speed?.title == 'Speedy'"
                                   role="progressbar" aria-valuenow="25" aria-valuemin="0"
                                   aria-valuemax="100"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="list-box" *ngIf="dataService.buildCardData.new_care_flow && builderCareCondition()">
                        <div class="pro-head">
                          <h3 class="careText" *ngIf="checkCareType() === 'standard_care'">Builder Care</h3>
                          <h3 class="careText" *ngIf="checkCareType() === 'standard_care_plus'">
                            {{ dataService.buildCardData.subscription_flow ? 'Builder Studio One+' : 'Builder Care Plus' }}
                          </h3>
                          <h3 class="careText" *ngIf="checkCareType() === 'enterprise_care'">Builder Enterprise Care</h3>
                          <h3 class="careText" *ngIf="checkCareType() === 'enterprise_care_plus'">
                            {{ dataService.buildCardData.subscription_flow ? 'Builder Enterprise One+' : 'Builder Enterprise Care Plus' }}
                          </h3>
                        </div>
                        <div class="pro-mid btm-bdr-none">
                          <div class="mid-txt-block">
                            <app-builder-care-content *ngIf="checkCareType() === 'standard_care'" [careType]="'standardCare'" [isFromDeliveryPage]="false" [viewMoreSCare]="false"></app-builder-care-content>
                            <app-builder-care-content *ngIf="checkCareType() === 'standard_care_plus'" [careType]="'standardCarePlus'" [isFromDeliveryPage]="false" [viewMoreSCare]="false"></app-builder-care-content>
                            <app-builder-care-content *ngIf="checkCareType() === 'enterprise_care'" [careType]="'enterpriseCare'" [isFromDeliveryPage]="false" [viewMoreSCare]="false"></app-builder-care-content>
                            <app-builder-care-content *ngIf="checkCareType() === 'enterprise_care_plus'" [careType]="'enterpriseCarePlus'" [isFromDeliveryPage]="false" [viewMoreSCare]="false"></app-builder-care-content>
                          </div>
                        </div>
                      </div>
                      <div class="list-box" *ngIf="!dataService.buildCardData.new_care_flow && builderCareCondition()">
                        <div class="pro-head">
                          <h3> Builder Care </h3>
                        </div>
                        <div class="pro-mid btm-bdr-none">
                          <div class="mid-txt-block">
                            <p>
                              Get on demand cloud support - setup, monitoring, scalability and migrations. Upgrade your app to support newer iOS/Android updates. </p>
                            <p>
                              Technical support for SDK, third party integrations and upgrades. Support for unexpected bugs, crashes and security issues.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="list-box" *ngIf="builderCloudCondition()">
                        <div class="pro-head">
                          <h3> Builder Cloud </h3>
                        </div>
                        <div class="pro-mid btm-bdr-none">
                          <div class="mid-txt-block">
                              <p><strong>Commitment-free savings:</strong> our customers saved over $4.5m, last year.</p>
                              <p><strong>World-class analytics:</strong> Optimise your software and infrastructure.</p>
                              <p><strong>Best-in-class multi-cloud:</strong> Azure, AWS, and more. Just one bill (for a lot less).</p>
                          </div>
                        </div>

                      </div>
                    </div>


                  </div>
                </div>

              </div>
            </div>

            <div class="rental-tabs" *ngIf="phaseService.isRentalBuildCard()">
              <div class="tabing">
                <div class="tab-list" [ngClass]="{'active': sectionName === 'rental_features'}" (click)="showClickedSection('rental_features')">
                  <span>
                    Studio Store Package <span> ({{dataService.buildCardData.progress.apps[0].title}}) </span>
                  </span>
                </div>
                <div class="tab-list" [ngClass]="{'active': sectionName === 'rental_additional_features' && (phaseService.additionalRentalFeatures.length > 0 || dataService.checkIfCPEUser()) }" (click)="showClickedSection('rental_additional_features')">Additional Features  ({{ phaseService.additionalRentalFeatures.length }})

                </div>
              </div>

              <div class="fixHeight" style="margin-bottom: 20px;" *ngIf="sectionName === 'rental_features'">
                <div class="buildcard-detail ">
                  <h3 class="detail-heading"> Features ({{ phaseService.selectedFeatures.length - phaseService.additionalRentalFeatures.length}})
                  </h3>
                  <div class="detail-list-block feature-box ">
                    <div>
                      <ul class="list-block">
                        <li
                          *ngFor="let feature of phaseService.selectedFeatures | CoreFeatures : phaseService.additionalRentalFeatures">
                          {{feature?.title }}
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>

              <div class="packageItemRow" *ngIf="sectionName === 'rental_features'">
                <h3>Platforms</h3>
                <ul >
                  <li *ngFor="let platform of phaseService.selectedPlatforms"><img src="{{platform.icon}}" alt="" /></li>
                </ul>
              </div>

              <div class="packageItemRow" *ngIf="sectionName === 'rental_features'">
                <h3>Care</h3>
                <!-- <img src="../../../../../assets/images/buildercare_logo.png" alt="" /> Depricated -->
                <!-- New Logos -->
                <img src="../../../../assets/images/Builder_Care_3.svg" height="29" alt="Builder Care"/>
                <!-- <img src="../../../../assets/images/Builder_Care_Plus.svg" height="29" alt="Builder Care Plus"/>
                <img src="../../../../assets/images/Builder_Enterprise_Care_3.svg" height="29" alt="Builder Enterprise Care"/>
                <img src="../../../../assets/images/Builder_Enterprise_Care_Plus.svg" height="29" alt="Builder Enterprise Care Plus"/> -->
              </div>

              <div class="packageItemRow" *ngIf="sectionName === 'rental_features'">
                <h3>Cloud</h3>
                <img src="../../../../../assets/images/buildercloud_logo.png" alt="" />
              </div>

              <div class="fixHeight" *ngIf="phaseService.additionalRentalFeatures.length > 0 || dataService.checkIfCPEUser()">
                <div class="buildcard-detail " *ngIf="sectionName === 'rental_additional_features'">
                  <h3 class="detail-heading"> Additional Features ({{ phaseService.additionalRentalFeatures.length }})
                    <span class="btnStyle1 feature-btn" (click)="startEditing('features', 'Buildcard™ Features Change', null, true)" *ngIf="dataService.checkIfCPEUser() && phaseService.isEditableAndPayable()"> Add/Remove (Advanced Option) </span>
                  </h3>
                  <div class="detail-list-block feature-box ">
                    <div>
                      <ul class="list-block">
                        <li
                          *ngFor="let feature of phaseService.additionalRentalFeatures">
                          {{ feature?.title }}
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>

            </div>


          </div>
      </div>

        <div class="col-xs-12 col-md-4 col-sm-12 pull-right" [ngClass]="{'rental-mobilebg': phaseService.isRentalBuildCard()}">
          <div class="only-buildcard" [ngClass]="{'buildcard-payment-bx': dataService.isMobileModel}">
            <app-payment-summary></app-payment-summary>
<!--            <app-payment-summary [additionalFeaturesCount]="dataService.additionalFeatures.length" [cost]="10000"-->
<!--                                 [duration]="10"></app-payment-summary>-->
            <div *ngIf="!phaseService.isRentalBuildCard()">
              <div class="payment-summery" *ngIf="!phaseService.isEditFeatureRequest() || appDataService?.urlParameters.cpe_user">
              <div class="mobile-view-bx">
                <div class="total-ammount-block">
                  <div class="mobileClickSpace" (click)="dataService.showModelOnMobile()"></div>
                  <h3 *ngIf="!phaseService.isDiscountApplied()">
                    <strong>
                      <ng-container *ngIf="getBuildCardData().change_amount === 0">
                        {{getCurrencySymbol()}}
                        {{ getPrice(getBuildCardData()) | number : '1.2-2' }}
                      </ng-container>
                      <ng-container *ngIf="getBuildCardData().change_amount > 0">
                        {{getCurrencySymbol()}}
                        {{ getDiscountedprice(getBuildCardData()) + (getBuildCardData().change_price_status ? getBuildCardData().change_amount : -getBuildCardData().change_amount) | number : '1.2-2' }}
                      </ng-container>
                    </strong> <span> <img _ngcontent-rst-c7="" alt=""
                                          src="../../../../../assets/images/info_blue.png"></span>
                  </h3>
                  <h3 *ngIf="phaseService.isDiscountApplied()">
                    <strong>{{getCurrencySymbol()}}
                      {{ getDiscountedprice(getBuildCardData()) + (getBuildCardData().change_price_status ? getBuildCardData().change_amount : -getBuildCardData().change_amount) | number : '1.2-2' }}</strong> <span> <img _ngcontent-rst-c7="" alt=""
                                                                                                                                                                                                                          src="../../../../../assets/images/info_blue.png"></span>
                  </h3>


                  <p><label>Duration</label> {{ getBuildCardData().weeks + (getBuildCardData().change_week_status ? getBuildCardData().change_week : -getBuildCardData().change_week) | number : '1.1-1'}} {{(getBuildCardData().weeks | number : '1.1-1') > 1 ? 'weeks' : 'week'}}</p>
                </div>
                <div class="continue-btn" *ngIf="(!phaseService.checkIfBuildCardForRapidPrototype() && !phaseService.isRentalBuildCard() && !dataService.isEditBuildCardAfterSecurityPaid())
                                                || paymentBuilderService.checkIfOnlyProfessionalPrototype()">
                  <button class="start-pro-btn" *ngIf="phaseService.isEditableAndPayable()  && featureLengthOfBuildCard() > 0"
                          (click)="startPaymentFlow()" type="button">Start Buildcard
                  </button>
                  <!--<button  [ngClass]="{ 'speak-to-expert': !appDataService.urlParameters.is_self_serve,
                                       'start-pro-btn': appDataService.urlParameters.is_self_serve }"
                           *ngIf="appDataService.urlParameters.is_self_serve"
                          (click)="openScheduleCallPopup()" type="button">Speak to an expert
                  </button>-->
                </div>

                <div class="continue-btn" *ngIf="showViewInstallments()">
                  <button class="start-pro-btn"
                        (click)="startPaymentFlow()" type="button">View Installments
                  </button>
                  <p class="txt">Our team at Builder.ai will review all the additional requirement and get back to you within 2 working days!</p>
                </div>
                <div class="continue-btn" *ngIf="dataService.modifiedBuildCardData && dataService.buildCardData?.modification_request?.status !== 'approved'">
                    <button class="start-pro-btn"
                            (click)="startPaymentFlow(true)" type="button">View <span class="hidden-xs">Updated</span> Installments
                    </button>
                    <p class="txt">Our team at Builder.ai will review all the additional requirement and get back to you within 2 working days!</p>
                </div>
                  <div class="continue-btn" *ngIf="phaseService.checkIfBuildCardForRapidPrototype() && !paymentBuilderService.checkIfOnlyProfessionalPrototype() && !dataService.modifiedBuildCardData">
                    <button class="start-pro-btn" [ngClass]="{ 'disable-continue-button' : !this.isUserInviteeOfBuildCard() }"
                            (click)="goToRapidPrototypePayment()" type="button">Continue To Payment
                      <span class="tooltip-bottom-arrow" *ngIf="!this.isUserInviteeOfBuildCard()">
                        You can only make payments if you own this Buildcard
                      </span>
                    </button>
                    <p class="txt">Our team at Builder.ai will review all the additional requirement and get back to you within 2 working days!</p>
                  </div>
                  <!-- <div class="continue-btn" *ngIf="phaseService.isRentalBuildCard()">
                    <button class="start-pro-btn"
                            (click)="startRentalBuildCardFlow()" type="button">Continue
                    </button>
                    <p class="txt">Our team at Builder.ai will review all the additional requirement and get back to you within 2 working days!</p>
                  </div> -->
              </div>
            </div>
            </div>

          </div>


        </div>
    </div>

  </div>
</div>


<!----popup for save progress---->
<!--<div *ngIf="componentType === 'summary'" class="commonPopUp" [ngClass]="{active: showProgress}">-->
<!--  <div class="popOverlay"></div>-->
<!--  <div class="popHolder buildPopup">-->
<!--    <div class="closePopUp" (click)="hideShowProgress()"><span class="icon-cancel"></span></div>-->
<!--    <h3>Your progress has been saved into your dashboard.</h3>-->
<!--    <p>Your card is saved in dashboard section and would you like to sign an NDA before proceeding for payment?</p>-->

<!--    <div class="footerButton">-->
<!--      <button class="doLater" (click)="hideShowProgress()">I’ll do it later.</button>-->
<!--      <button class="proceedPayment" (click)="processNDAFlow()">Want to sign NDA?</button>-->
<!--    </div>-->

<!--  </div>-->
<!--</div>-->


<!----popup for Got a better quote---->
<div class="commonPopUp" [ngClass]="{active: showQuotePricePopup}">
  <div class="popOverlay"></div>
  <div class="popHolder diffQuotePopup">
    <div class="closePopUp" (click)="showQuotePrice()"><span class="icon-cancel"></span></div>

    <div class="quoteHolder">
      <div class="quoteLeft">
        <h3>Tell us something about that quote.</h3>
        <h3>We’ll give you a better price.</h3>
      </div>

      <div class="quoteRight">
        <div class="formHolder">
          <ul>
            <li>
              <mat-form-field>
                <input type="number" matInput placeholder="Quoted Price" #quotedPrice="ngModel" name="quotedPrice"
                       pattern="[0-9]*" [(ngModel)]="quotePrice" maxlength="100" required>
              </mat-form-field>
              <div class="errorMsg" *ngIf="quotedPrice.errors && (quotedPrice.dirty || quotedPrice.touched)">Please
                enter valid quote
                price
              </div>
            </li>
          </ul>

          <div class="uploadButton" *ngIf="phaseService.filesList.length < 3">
            <span>If you have files you can share here.</span>
            <input id="shareFile" type="file" accept=".pdf,.doc,.docx,.jpeg,.png,.jpg" (change)="fileChange($event)"
                   *ngIf="phaseService.filesList.length<3">
            <label for="shareFile">browse files here.</label>
          </div>

          <div class="uploadedFile">
            <div class="fileRow" *ngFor="let file of phaseService.filesList;let i = index;">
              <span class="fileName"><em class="icon-files"></em> {{file.name}}</span>
              <span class="fileRemove" (click)="remove(i)">Remove</span>
            </div>
          </div>
          <div class="errorMsg show" *ngIf="duplicateFile && phaseService.filesList.length > 0">
            This file is already added.
          </div>
        </div>

        <div class="formButton">
          <button class="nextButton"
                  disabled="{{(quotedPrice != null && quotedPrice.errors && (quotedPrice.dirty || quotedPrice.touched))}}"
                  (click)="uploadQuote()">
            Done <img *ngIf="preventClick" src="./../../../assets/images/buttonLoader.gif" alt=""/></button>
        </div>

      </div>
    </div>

  </div>
</div>

<!--<div class="tutorialBox" *ngIf="showTutorial">-->
<!--  <div class="boxHolder">-->
<!--    <img src="./../../../assets/images/tutorial.png" alt=""/>-->
<!--    <div class="clearfix"></div>-->
<!--    <div class="closeButton" (click)="closeTutotial()">-->
<!--      <em class="icon-cancel"></em>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="commonPopUp" [ngClass]="{'active': isViewPrototypeClicked}">
  <div class="popOverlay"></div>
  <div class="popHolder builderNow">
    <h3>What is Builder Now?</h3>
    <p>A Prototyping tool build to<br> experience the app you're building</p>
    <div class="footerButton">
      <button type="button" class="cancelButton" (click)="togglePrototypeModel()">I'll check later</button>
      <button type="button" class="doneButton" (click)="redirectToNow()">Let's Begin</button>
    </div>
  </div>
</div>

<div class="commonPopUp" [ngClass]="{active : phaseService.showOwnershipConfirmationSummary}">
  <div class="popOverlay"></div>
  <div class="popHolder ownershipConfirmPopup">
    <div class="closePopUp" (click)="phaseService.showHideRemoveAllPopup()"><span class="icon-cancel"></span></div>
    <!-- <h3 *ngIf="phaseService.ownershipTranferMessage">Ownership Transferred! <br> You can now view this card under your
      Invited cards section.</h3>
    <h3 *ngIf="!phaseService.ownershipTranferMessage">Invitation has been sent successfully. <br> Ownership would be
      transferred when Invitation is accepted by the user!</h3> -->
    <h2 *ngIf="phaseService.ownershipTranferMessage || phaseService.ownershipTransferInvitationSentMessage"><em class="icon-tick-inside"></em> <span>Awesome</span></h2>
    <h3 *ngIf="phaseService.ownershipTransferUserNotAvailableMessage || phaseService.ownershipTransferCurrencyMismatchMessage"><em>X</em> <span>Oh no!</span></h3>
    <p *ngIf="phaseService.ownershipTranferMessage">Ownership of this Buildcard has been successfully transferred. </p>
    <p *ngIf="phaseService.ownershipTransferUserNotAvailableMessage">It seems <strong>{{phaseService.emailToEnterForOwnershipTransfer}}</strong> is not part of our Builder family. </p>
    <p *ngIf="phaseService.ownershipTransferCurrencyMismatchMessage">Unable to make this transfer, make sure that your selected currency matches with the receivers currency. </p>
    <p *ngIf="phaseService.ownershipTransferInvitationSentMessage">Your invitation has been sent successfully. You will be able to transfer the ownership once your invitation gets accepted. </p>

    <div *ngIf="phaseService.ownershipTranferMessage || phaseService.ownershipTransferCurrencyMismatchMessage" class="footerButton">
      <button class="doneButton" (click)="phaseService.showHideRemoveAllPopup()">Okay</button>
    </div>

    <div *ngIf="!phaseService.ownershipTransferUserNotAvailableMessage && phaseService.ownershipTransferInvitationSentMessage" class="footerButton">
      <button class="doneButton" (click)="phaseService.showHideRemoveAllPopup()">Back to Buildcard</button>
    </div>

    <div *ngIf="phaseService.ownershipTransferUserNotAvailableMessage" class="footerButton">
      <button class="doneButton" (click)="phaseService.sendInvitationForOwnershipTransfer()">Send Invitation</button>
      <button class="retryButton" (click)="phaseService.retryOwnershipTransfer()">Retry</button>
    </div>

  </div>
</div>


<div class="commonPopUp" [ngClass]="{'active': isPromotionPopupOpened}">
  <div class="popOverlay"></div>
  <div class="popHolder promotionPopup">
    <div class="closePopUp"><span class="icon-cancel" (click)="showHidePromotionPopup(false)"></span></div>

    <div *ngIf="!phaseService.appliedPromotion">
      <h3>Apply a Coupon Code</h3>
      <p>Please enter the coupon code to avail the discount.</p>
      <form>
        <input type="text" [(ngModel)]="phaseService.manualPromoCode" maxlength="15"
               [ngModelOptions]="{standalone: true}" placeholder="Enter coupon code">
        <span class="errorMsg" *ngIf="phaseService.promoError">{{ phaseService.promoError }}</span>
        <div class="promoButton">
          <button type="button" class="button1" (click)="showHidePromotionPopup(false)">Cancel</button>
          <button type="button" class="button2"
                  (click)="phaseService.applyRemovePromotion(phaseService.manualPromoCode, true)"
                  [disabled]="phaseService.manualPromoCode?.length === 0 || phaseService.manualPromoCode?.length > 15">
            Apply
            <img *ngIf="phaseService.promotionLoader && phaseService.manualPromoCode?.length > 0"
                 src="../../../../../assets/images/buttonLoader.gif" alt="" />
          </button>
        </div>
      </form>
    </div>

    <div class="successBox" *ngIf="phaseService.appliedPromotion">
      <strong>Congratulations!</strong>
      coupon is applied successfully
    </div>


  </div>
</div>

<div class="forAddCompetitor" [ngClass]="{ activeAddCompetitor : popForAddCompetitor}" *ngIf ="popForAddCompetitor">
  <div class="addComp-backdrop" (click)="closeAddCompetitorPopup()"></div>
  <div class="forAddCompetitor-section">
    <div class="addComp-top">
      <em class="icon-cancel" (click)="closeAddCompetitorPopup()"></em>
      <span class="addComp-heading">Add Competitors</span>
    </div>
    <div class="addComp-middle">
      <span class="search-label">Search for competitors</span>
      <div class="searchInputBlock">
        <input class="searchComp" placeholder="e.g. example.com" maxlength="20" name="appurl" [(ngModel)]="singleUrl"
               type="text" #appUrl="ngModel" (keyup.enter)="searchProducts()">
        <button type="button" class="searchCompButton" (click)="addCompetitor('add')">Add</button>
        <button type="button" class="searchCompButton" (click)="searchProducts()"><em class="icon-search"></em></button>
        <div class="new-loaderSpin" *ngIf="isResultLoading"><img src="./../../../assets/images/rolling.gif" alt=""/>
        </div>
      </div>
      <div class="searchlist-block" *ngIf="productsList.length > 0">
        <div class="comp-searchlist" *ngFor="let product of productsList">
          <span class="competitorIcon">
            <img [src]="utilityService.addHttps(product.image)" alt=""/>
          </span>
          <span
            class="competitorName">{{product.product_name.length > 36 ? (product.product_name.substring(0, 36) + '...') : product.product_name }}</span>
          <div class="comp-checkboxBlock">
            <input [id]="product.product_name" type="checkbox" class="forcomp-check"
                   (change)="selectCompetitors(product, $event)"/>
            <label [for]="product.product_name" class="customCheck"></label>
          </div>
        </div>
      </div>
    </div>
    <div class="addComp-bottom">
      <span class="notifyAlert" *ngIf="disableAddCompetitors">At most 3 competitors can be added</span>
      <button type="button" class="close-comp" (click)="closeAddCompetitorPopup()">Close</button>
      <button type="button" class="done-comp" (click)="addCompetitor()" [disabled]="disableAddCompetitors">Done</button>
    </div>
  </div>
</div>

<shareinvite></shareinvite>

<app-duplicate-card-modal *ngIf="commonService.isDuplicateCardFlow"></app-duplicate-card-modal>

<!-- <div class="changesUpdatedNotify" (click)="hideChangeNotify()" *ngIf="isShowChangeNotify" >
  <h3><em class="icon-tick"></em> <span>Changes has been updated successfully.</span></h3>
</div> -->
