<div class="commonPopUp active">
  <div class="popOverlay"></div>
  <div class="popHolder buildPopup">
    <div class="closePopUp" (click)="closeDiscardAdvancedModePopup(false)">
      <span class="icon-cancel"></span>
    </div>
    <h3>Advanced mode</h3>
    <p>Do you want to discard your selections in the Advanced mode?</p>
    <div class="footerButton">
      <button class="doLater" (click)="closeDiscardAdvancedModePopup(false)">No</button>
      <button class="proceedPayment" (click)="closeDiscardAdvancedModePopup(true)">Yes</button>
    </div>
  </div>
</div>
