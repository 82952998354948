import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Constants} from '@shared/utils/constants';
import {PaymentBuilderService} from '@featuremodules/payment-builder-module/payment-builder.service';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'payment-confirmation',
  templateUrl: 'payment-confirmation.component.html'
})

export class PaymentConfirmationComponent {
  public isSpecing = false;
  public showHtml = false;

  /*@ngInject*/
  constructor(public analyticsSegment: Angulartics2Segment,  public phaseService: PhaseService, public apiRequests: ApiRequests, public dataService: DataService, public router: Router, public paymentBuilderService: PaymentBuilderService) {
    this.phaseService.currencySymbol = dataService.user.currency.symbol;
  }

  public ngOnInit() {
    this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.payment_confirmation.code);
    this.dataService.showHideLoader(true);
    this.apiRequests.fetchInstallments(this.phaseService.inProgressBuildCardId, this.phaseService.paidPhaseId).subscribe((data: any) => {
      if (data) {
        this.phaseService.paymentData = data;
        if (this.dataService.buildCardData && this.dataService.buildCardData.specing && !this.dataService.buildCardData.specing_paid) {
          //  this.gotoBilling();
        } else {
          this.phaseService.installments = this.phaseService.paymentData.installments.filter((installment) => {
            return installment.type === 'installment';
          });
          this.phaseService.deposit = this.phaseService.paymentData.installments.filter((installment) => {
            return installment.type === 'deposit';
          });
          if (this.phaseService.paymentData.installments[this.phaseService.paymentData.installments.length - 1].type === 'support') {
            this.phaseService.supportMaintenance = this.phaseService.paymentData.installments[this.phaseService.paymentData.installments.length - 1];
          }
          if (this.phaseService.holdOfferFlow) {
          }
        }
      }
      this.dataService.showHideLoader(false);
      this.showHtml = true;
    }, (error) => {
      this.dataService.showHideLoader(false);
    });
    if (this.dataService.buildCardData && this.dataService.buildCardData.specing && !this.dataService.buildCardData.specing_paid) {
      this.isSpecing = true;
    }
  }

  public getRedirectUrl() {
    let url = this.phaseService.getRedirectUrl() + '&build_card_id=' + this.dataService.buildCardData.id;
    if (this.dataService.buildCardData && this.dataService.buildCardData.specing && !this.dataService.buildCardData.specing_paid) {

      if (url.includes('?')) {
        url = url + '&' + 'specing_payment=true';
      } else {
        url = url + '?' + 'specing_payment=true';
      }
    }
    if (this.dataService.homePageData.promotion && this.phaseService.promotionValid) {
      if (url.includes('?')) {
        url = url + '&promotion=' + this.dataService.homePageData.promotion.code;
      } else {
        url = url + '?promotion=' + this.dataService.homePageData.promotion.code;
      }
    }
    return url;

  }

  public getPaymentData() {
    return this.phaseService.paymentData;
  }

  public getLocaledPrice(price: any) {
    return this.dataService.getLocaledPrice(price);
  }

  public getInstallmentWithTax(price) {
    return this.phaseService.getInstallmentWithTax(price);
  }

  public getCurrencySymbol() {
    return this.phaseService.currencySymbol;
  }

  public getDeposit() {
    return this.phaseService.deposit;
  }

  public getBillingInfo() {
    return this.phaseService.billingInfo;
  }

}
