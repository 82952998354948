import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'reverse',
  pure: false
})
@Injectable()
export class ReversePipe implements PipeTransform {

  public transform(value) {
    return value.slice().reverse();
  }
}
