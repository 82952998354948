import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {PhaseService} from '../services/phase.service';
import {DataService} from '../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryGuard implements CanActivate {

  constructor(private phaseService: PhaseService,
              private dataService: DataService,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.phaseService.selectedFeatures.length === 0 && !this.dataService.shareUrlData) {
      this.router.navigate(['home']);
    } else {
      return true;
    }
  }
}
