import {
  Component, OnInit, ElementRef, AfterViewInit, HostListener, ViewChild, Renderer2,
  ViewChildren, QueryList
} from '@angular/core';
import { ApiRequests } from '@shared/services/api-requests.service';
import { DataService } from '@shared/services/data.service';
import { PhaseService } from '@shared/services/phase.service';
import { HomeTemplateService } from '@shared/services/home-template.service';
import { interval, Subscription } from 'rxjs';
import { AppDataService } from '@rootmodule/app-data.service';
import { CommonService } from '@shared/services/common.service';
import { Angulartics2Segment } from 'angulartics2/segment';
import { Constants } from '@shared/utils/constants';
import { ComponentLoaderService } from '@shared/services/component-loader.service';
import { Intercom } from 'ng-intercom';
import { ClipboardService } from 'ngx-clipboard';
import { trigger, state, style, animate, transition } from '@angular/animations';

import {
  PerfectScrollbarComponent, PerfectScrollbarConfigInterface,
  PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { TemplateDetailModel } from '@models/template-detail-model/template.detail.model';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { BuildCardModel } from '@models/build-card-model/build-card.model';
import { filter } from 'rxjs/internal/operators';

@Component({
  selector: 'app-home-templates',
  templateUrl: './home-templates.component.html',
  styleUrls: ['./home-templates.component.scss'],
})

export class HomeTemplatesComponent implements OnInit, AfterViewInit {

  apiSuccess = false;
  isFiltered = false;
  all_filters = [];
  showMobileFilter = false;
  currencyChangeSubscription: Subscription;
  filterSubscription: Subscription;
  notifyFilterSubscription: Subscription;
  showVideo = false;
  filterPage = 1;
  searchPage = 1;
  isScrolled = true;
  filterLoader = false;
  tryAgainHide = false;
  authToken = '';
  suggestionMsg = '';
  suggestionMsgInterval: any;
  hideNotifyPopup = true;
  videoUrl = 'https://www.youtube.com/embed/ivETCA7OiGU';
  @ViewChild('videoframe') videoframe;
  @ViewChild('searchInput') searchInput;
  PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
  };
  natashaSearchedText: string = '';
  showNatashaErrorMsg = false;
  hasErrorMsgShown = false;
  showSpecCallButtons = false;
  innerWidth = 1023;
  natashaSearchTextMobile = [];
  @ViewChild('natashaTemplateSlider', { read: DragScrollComponent }) ds: DragScrollComponent;
  leftNavDisabled: boolean;
  rightNavDisabled: boolean;
  natashaTemplatesForMobile = [];
  natashaFeedbackMobile = [];
  suggestedMsg = [];
  searchSubscription: Subscription;
  showTime: any;
  natashaScroll = false;
  cookiePolicyMsgPopup = true;
  cookieMsgChange = false;
  urlcopied = false;
  feedBackContainer: string;
  showCallsDropDown = false;
  tryAgainClicked = false;
  feedBackOption: string;
  @ViewChild('mobileInputNatasha') mobileInputNatasha: ElementRef;
  natashaFeedbackResponse: string = '';
  isShowNatashaSearchedText = false;
  isShowFindSomethingText = false;
  animateNatashaAvatarOnIntroPopupClose = false;
  isNatashaPositiveFeedback = false;
  isNatashaNegativeFeedback = false;
  isShowNatashaSearchSuggestionChip = true;
  isShowPositiveFeedbackSuggestionChip = true;
  isShowNegativeFeedbackSuggestionChip = true;
  isShowNatashaWordCountErrorMsg = false;
  natashaSearchedTextCopy: string = '';
  charIndexOfPlaceHolder = 0;
  typingSpeed = 100;
  placeHolderText = '';
  isUserNameEntered = false;
  isShowTryingToBuildText = false;
  isShowTryingToBuildTextMobile = false;
  rows = 1;
  @ViewChild('natshaSearchBox') natshaSearchBox: ElementRef;
  userNameFromNatasha = '';
  isShowTryAgainPopup = false;
  feedbackQuestionDelay = 5000;
  isNatashaScreenAppearingOnHome = false;
  isNatashaScreenFirstTime = false;
  isUserNameInputDisabled = false;
  showUserNameInMobile = false;
  isZeroRecommendationFromNatasha = false;
  searchSortSubscription: Subscription;
  isComingFrom360: boolean;

  constructor(private componentLoader: ComponentLoaderService, public homeTemplateService: HomeTemplateService,
    public appDataService: AppDataService, private renderer: Renderer2, private commonService: CommonService,
    public analyticsSegment: Angulartics2Segment,  public _clipboardService: ClipboardService,
    private apiRequest: ApiRequests, public dataService: DataService, public router: Router,
    public phaseService: PhaseService, private elementRef: ElementRef, private activatedRoute: ActivatedRoute) {
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    this.dataService.currentPage = 'home';
    this.innerWidth = window.innerWidth;
    this.showTime = { a: '', b: '', c: '', d: '', e: '', f: '', g: '', h: '' };
    if (this.homeTemplateService.searchTemplateInput && !location.href.includes('template_search')) {
      const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?template_search=' + this.homeTemplateService.searchTemplateInput;
      window.history.pushState({ path: newurl }, '', newurl);
    }
  }

  @HostListener('window:resize', ['$event'])
  @HostListener('window:popstate', ['$event'])

  onPopState(event) {
    if (event.type !== 'resize') {
      this.phaseService.reset();
    }
    this.phaseService.showSaveProgress = false;
    const data = document.getElementsByClassName('commonPopUp active');
    if (data.length > 0) {
      this.renderer.addClass(document.getElementById('app-component'), 'hide-scroll-bar');
    } else {
      this.renderer.removeClass(document.getElementById('app-component'), 'hide-scroll-bar');
    }
  }

  onResize(event) {
    this.innerWidth = event.target.innerWidth;
  }

  checkForFilterInUrl() {
    if (this.appDataService.urlParameters.category) {
      this.isFiltered = true;
    }
  }

  checkForSpeccallcancelNotification() {
      if(this.appDataService.urlParameters.whatsup_notify){
        this.hideNotifyPopup = false;
      }
  }

  ngOnInit(): void {
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    if (this.appDataService.urlParameters.cancel_call_id) {
      this.phaseService.showCancellationPopup = true;
    }
    this.checkForSpeccallcancelNotification();
    this.checkForFilterInUrl()
    // if (!this.commonService.isPlatformBrowser) {
    //   return;
    // }
    this.handleSearch();
    // this.generateSuggestionMsgFirstTime();
    if (!this.getUser()) {
      this.phaseService.showCurrency = true;
    }
    this.checkForRegisterUser();
    setTimeout(() => {
      this.dataService.showExpertsButtonInHeader = false;
    }, 4000);
    this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.home.code);
    if (this.dataService.user) {
      this.authToken = this.dataService.user.authtoken;
    }
    if (this.commonService.isPlatformBrowser) {
      if (localStorage.getItem('cookie_popup_checked')) {
        this.cookiePolicyMsgPopup = false;
      }
    }
    if (location.href.includes('apps') && !location.href.includes('redirection_url')) {
      this.getBuildCardDetails();
    }
    if (this.dataService.user && this.appDataService.urlParameters.redirection_url) {
      window.location.href = this.appDataService.urlParameters.redirection_url;
    }
    // this.dataService.showHideLoader(true);
    this.phaseService.timerStart();
    this.currencyChangeSubscription = this.homeTemplateService.currencyChange.subscribe((data) => {
      if (data && data.type == 'filter_sort') {
        this.homeTemplateService.currencyId = data.currencyId;
        this.homeTemplateService.page = 1;
        this.filterPage = 1;
        this.isScrolled = true;
        this.homeTemplateService.combined_templates = [];
        this.combinedTemplates({ filters: data.filters, sorting: data.sorting });
      }
    })
    this.notifyFilterSubscription = this.homeTemplateService.notifyFilterToggle.subscribe((data) => {
      if (data) {
        this.homeTemplateService.initialLoader = true;
        this.homeTemplateService.page = 1
        this.filterPage = 1;
        this.isScrolled = true;
        if (typeof window !== 'undefined') {
          window.scrollTo(0, 0);
        }
        this.homeTemplateService.combined_templates = [];
        this.combinedTemplates(data);
      }
    });
    this.homeTemplateService.notifySearchToggle.subscribe((data) => {
      if (data) {
        // this.homeTemplateService.page = 1
        this.sortOnSearchedResults();
      }
    });
    this.apiRequest.fetchHomePageData().subscribe((data: any) => {
      this.apiSuccess = true;
      if (data['multiplier_experiment']) {
        const exp = {
          id: data['multiplier_experiment'].data.attributes.id,
          duration_multiplier: data['multiplier_experiment'].data.attributes.duration_multiplier,
          price_multiplier: data['multiplier_experiment'].data.attributes.price_multiplier,
          custom_price_multiplier: data['multiplier_experiment'].data.attributes.custom_price_multiplier
        }
        this.dataService.multiplierExperiment = exp;
      }
      this.dataService.dataLoadingCurrency = Object.assign({}, this.dataService.homePageData.currency);
      this.phaseService.platformPrices = Array.apply(null, Array(this.dataService.homePageData.platforms.length));
      this.phaseService.platformPrices.map((x, i) => {
        return i;
      });
      if (this.dataService.homePageData.currency) {
        this.phaseService.specing_price = this.dataService.homePageData.currency.specing_price;
      }
      this.homeTemplateService.currencyId = this.homeTemplateService.getCurrency().id;
      this.phaseService.trackEvent(this.analyticsSegment,  'home_page_visited');
      this.initializeHome();
    });

    this.initializeNatashaMessages();
    if (!this.dataService.user && this.appDataService.urlParameters.show_login) {
      this.phaseService.openCommonAuth = true;
      this.phaseService.showRegister = false;
      this.phaseService.showSignin = true;
      const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
      window.history.pushState({ path: newurl }, '', newurl);
    }
    this.isComingFrom360 = !!this.appDataService.urlParameters.redirection_url;
    if (this.isComingFrom360) {
      this.homeTemplateService.templateType = 'pro';
    }
  }

  combinedTemplates(data, isScroll?) {
    this.homeTemplateService.clearSearch();
    if (data) {
      if (data.filters.category.length == 0 && data.filters.price.length == 0) {
        this.isFiltered = false;
        this.homeTemplateService.combined_templates = [];
        if (this.homeTemplateService.sortingOption) {
          this.homeTemplateService.sortScroll.next(1);
        }
      } else {
        this.getFilteredTemplates(data.filters, data.sorting, isScroll);
      }
      this.all_filters = [...data.filters.category, ...data.filters.price];
    }
  }

  getBuildCardDetails () {
    const uniqCode = this.activatedRoute.snapshot.paramMap.get('id');
    if (uniqCode) {
      this.apiRequest.fetchSingleBuildCardData(uniqCode).subscribe((data: any) => {
        this.phaseService.inProgressBuildCardId = this.dataService.buildCardData.id;
        this.phaseService.setUpInFullBuildCard();
        this.phaseService.buildCardEditMode = true;
        this.dataService.isEditBuildCard = true;
      });
    }
  }

  getFilteredTemplates(filters, sorting, isScroll?) {
    if (!isScroll) {
      this.homeTemplateService.showTemplatesLoader = true;
    }
    this.filterSubscription = this.homeTemplateService.getFileteredRecord(this.homeTemplateService.currencyId, filters, sorting, this.filterPage)
      .subscribe((data => {
        let records = data.data;
        let pages = data.total_pages;
        if (this.filterPage >= pages) {
          this.isScrolled = false;
        } else {
          this.isScrolled = true;
        }
        this.homeTemplateService.initialLoader = false;
        const customTemplate = this.homeTemplateService.requestedTemplateList;
        this.homeTemplateService.showTemplatesLoader = false;
        this.homeTemplateService.isFilterAPIPending = false;
        if (isScroll) {
          this.filterLoader = false;
        }
        this.isFiltered = true;
        this.homeTemplateService.combined_templates.push(...records);
        if (customTemplate.length && !this.homeTemplateService.combined_templates.map(id => id.id).includes(customTemplate[0].id)) {
          this.homeTemplateService.combined_templates.unshift(...customTemplate);
        }
      }
      ))
  }


  showAddNewTemplatePopup() {
    if (this.phaseService.selectedApps.length === 3) {
      this.homeTemplateService.showMaxTempSelectionAlert = true;
      return;
    }
    this.phaseService.trackEvent(this.analyticsSegment, 'custom_template_initiated');
    this.phaseService.selectedCategory = this.dataService.categories[0];
    this.phaseService.showAddNewTemplate = true;
  }

  handleSearch() {
    this.homeTemplateService.maintainSearchOnRefresh.subscribe(() => {
      const tempSearch = this.appDataService.urlParameters.template_search;
      if (!tempSearch) { return false; }
      this.homeTemplateService.isTemplatePageLoaded = true;
      this.homeTemplateService.isRefreshedWithSearch = true;
      if (tempSearch.toLowerCase().includes('i want to')) {
        this.natashaSearchedText = tempSearch;
        this.getRecommendedTemplates();
      } else {
        this.searchTemplates(this.appDataService.urlParameters.template_search);
      }
    });
  }

  searchTemplates(event?) {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    this.homeTemplateService.copyNatashaSearchText = '';
    this.phaseService.templateNatashaSearchText = '';
    if (event.keyCode === 13 && !this.homeTemplateService.searchTemplateInput) {
      this.openSearchBar();
      return;
    } else if (event.keyCode === 13) {
      this.phaseService.showTemplateSearchBox = false;
    }
    this.phaseService.trackEventForObject(this.analyticsSegment,  'template_searched', {
      'search_term': this.homeTemplateService.searchTemplateInput,
    });
    if (event && !event.target) {
      this.homeTemplateService.searchTemplateInput = event;
      this.openSearchBar();
    }
    if (this.homeTemplateService.searchTemplateInput) {
      if (event) {
        this.homeTemplateService.initialLoader = true;
        if (typeof window !== 'undefined') {
          window.scrollTo(0, 0);
        }
      }
      this.homeTemplateService.showTemplatesLoader = true;
      let searchInput = this.homeTemplateService.searchTemplateInput;
      this.homeTemplateService.clearSearchParams();
      const currencyId = this.dataService.homePageData.currency ? this.dataService.homePageData.currency.id : this.homeTemplateService.currencyId;
      if (this.homeTemplateService.activeFilters.category.length > 0 || this.homeTemplateService.activeFilters.price.length > 0) {
        this.homeTemplateService.clearFilter('all');
        this.homeTemplateService.searchTemplateInput = searchInput;
      }
      if (this.homeTemplateService.sortingOption) {
        this.sortOnSearchedResults();
      } else {
        this.searchSubscription = this.homeTemplateService.fetchTemplatesBasedOnSearch(currencyId)
          .subscribe(data => {
            this.searchPage = 1;
            if (this.searchPage >= data.total_pages) {
              this.isScrolled = false;
            } else {
              this.isScrolled = true;
            }
            this.homeTemplateService.showTemplatesLoader = false;
            this.homeTemplateService.showSearchedTemplates = true;
            this.homeTemplateService.initialLoader = false;
            this.homeTemplateService.sortedsearchedTemplates = [];
            this.homeTemplateService.searchedTemplates = data.data;
            if (this.homeTemplateService.requestedTemplateList.length) {
              this.homeTemplateService.searchedTemplates.unshift(...this.homeTemplateService.requestedTemplateList);
            }
            if (this.phaseService.selectedApps.length) {
              const selectedAppIDs = this.phaseService.selectedApps.map(template => template.id.toString());
              this.homeTemplateService.searchedTemplates.forEach(template => {
                if (selectedAppIDs.includes(template.id)) {
                  template.attributes['selected'] = true;
                }
              });
            }
            const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?template_search=' + this.homeTemplateService.searchTemplateInput;
            window.history.pushState({ path: newurl }, '', newurl);
          });
      }
    }
  }

  natashaGroupScrolled(event) {
    if (event.target.scrollTop !== 0) {
      this.natashaScroll = true;
    } else {
      this.natashaScroll = false;
    }
  }

  talkToExpertsClicked() {
    if (this.innerWidth <= 1023) {
      this.homeTemplateService.isShowTemplateNatashaIntroPopup = false;
    }
    if (!this.dataService.user) {
      this.phaseService.openCommonAuth = true;
      if (this.dataService.homePageData?.block_sign_up) {
        this.phaseService.showSignin = true;
        this.phaseService.showRegister = false;
      } else {
        this.phaseService.showRegister = true;
        this.phaseService.showSignin = false;
      }
      this.dataService.openMeetNowModal = true;
      this.homeTemplateService.showNatashaPanelMobile = false;
      this.homeTemplateService.setAnimationVariable();
    } else {
      this.dataService.showMeetNowModel = true;
      this.homeTemplateService.showNatashaPanelMobile = false;
    }
  }

  mobileFilterClicked() {
    this.showMobileFilter = !this.showMobileFilter
  }

  clearAllFilter() {
    this.homeTemplateService.clearFilter('all');
  }

  ngOnDestroy() {
    if (this.currencyChangeSubscription) {
      this.currencyChangeSubscription.unsubscribe()
    }
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe()
    }
    if (this.notifyFilterSubscription) {
      this.notifyFilterSubscription.unsubscribe()
    }
    this.isZeroRecommendationFromNatasha = false;
  }

  filiterPagination() {
    if (this.isScrolled) {
      this.isScrolled = false;
      this.filterLoader = true;
      this.filterPage++
      this.combinedTemplates({ filters: this.homeTemplateService.activeFilters, sorting: this.homeTemplateService.sortingOption }, true)
    }
  }

  searchPagination() {
    if (this.isScrolled) {
      this.isScrolled = false;
      this.filterLoader = true;
      this.searchPage++;
      const currencyId = this.dataService.homePageData.currency.id;
      this.homeTemplateService.fetchTemplatesBasedOnSearch(currencyId, this.searchPage).subscribe(resp => {
        if (this.searchPage >= resp.total_pages) {
          this.isScrolled = false;
        } else {
          this.isScrolled = true;
        }
        this.homeTemplateService.searchedTemplates.push(...resp.data);
      });
    }
  }

  getVideoUrlLink() {
    switch (this.homeTemplateService.currencyId) {
      case 1:
        this.videoUrl = 'https://www.youtube.com/embed/ivETCA7OiGU';
        break;
      case 4:
        this.videoUrl = 'https://www.youtube.com/embed/kXBbXniwuIM';
        break;
      default:
        this.videoUrl = 'https://www.youtube.com/embed/ucy0R65yxPE';
    }
  }

  openVideoPopup(type) {
    this.getVideoUrlLink();
    if (type == 'close') {
      this.phaseService.trackEventForObject(this.analyticsSegment,  'studio_video_closed', {
        user_id : this.dataService.user?.id,
      });
      this.videoframe.nativeElement.setAttribute("src", '')
    } else if (type == 'open' && this.videoframe.nativeElement.src != this.videoUrl) {
      this.videoframe.nativeElement.src = this.videoUrl;
      this.phaseService.trackEventForObject(this.analyticsSegment,  'studio_video_cta_clicked', {
        user_id : this.dataService.user?.id,
      });
    }
    this.showVideo = !this.showVideo;
  }

  sortOnSearchedResults() {
    if (this.searchSortSubscription) {
      this.searchSortSubscription.unsubscribe();
    }
    this.homeTemplateService.showSearchedTemplates = true;
    if (this.homeTemplateService.searchedTemplates.length === 0) {
      this.homeTemplateService.sortedsearchedTemplates = [];
    }
    const currencyId = this.homeTemplateService.getCurrency().id;
    this.searchSortSubscription = this.homeTemplateService.getSortedSearchedRecords(currencyId)
      .subscribe((records => {
        this.homeTemplateService.showTemplatesLoader = false;
        this.homeTemplateService.sortedsearchedTemplates = records;
      }));
  }

  isShowSignUp() {
    if (this.appDataService.urlParameters.show_signup) {
      this.openSignupModal();
      // this.openWelcomePopup();
    }
  }

  openWelcomePopup() {
    this.phaseService.trackEventForObject(this.analyticsSegment,  'studio_welcome_screen_visited', { 'referrer_url': this.appDataService.urlParameters.utm_source, 'referrer_trigger': '' });
    this.componentLoader.loadComponent('welcome-to-studio').then(componentEl => {
      this.elementRef.nativeElement
        .querySelector('#main')
        .appendChild(componentEl);
    });
  }

  checkIfShowWelcomeModal() {
    return (this.phaseService.shouldShowWelcomePopup() && (localStorage.getItem('welcome_screen_seen') !== 'true'));
  }

  ngAfterViewInit() {
    // if (this.checkIfShowWelcomeModal()) {
    //   this.isNatashaScreenAppearingOnHome = true;
    //   this.openWelcomePopup();
    // }
    if (!this.dataService.user) {
      this.isShowSignUp();
    }
  }

  closeTemplateModal() {
    this.phaseService.trackEventForObject(this.analyticsSegment, 'template_view_details_cta_closed', { buildcard_type: this.homeTemplateService.templateType });
    this.dataService.buildCardData = new BuildCardModel;
    this.dataService.appDetails = new TemplateDetailModel;
    this.phaseService.inProgressBuildCardId = false;
    this.dataService.isShowTemplateModal = false;
    if (this.dataService.templateDetailModel.pricing_modal === 'rental_price') {
      this.dataService.unsavedChanges = false;
    }
  }

  public initializeHome() {
    this.phaseService.rapidPrototypePlatforms = Object.assign([], [...this.dataService.homePageData.prototype_platforms]);
    this.phaseService.selectedPlatformsForRapidPrototype = Object.assign([], [...this.dataService.homePageData.prototype_platforms]);

    if (this.dataService.fromPasswordReset) {
      this.phaseService.showSignin = true;
      this.phaseService.openCommonAuth = true;
      this.phaseService.showRegister = false;
      this.dataService.fromPasswordReset = false;
    } else if (this.phaseService.conditionToCreateAndSyncBuildCardToBNow()) {
      if (!this.dataService.user) {
        this.phaseService.selectedSectionIndex = 0;
        this.phaseService.showRentalPaymentFlow = true;
      } else if (this.dataService.user && !this.dataService.user.currency) {
        this.phaseService.selectedSectionIndex = 0;
        this.phaseService.showRentalPaymentFlow = true;
      } else {
        this.apiRequest.createAndSyncBuildCardToNow(this.appDataService.urlParameters.application).subscribe((data: any) => {
          this.appDataService.urlParameters.red = null;
          this.appDataService.urlParameters.application = null;
          window.open(this.dataService.homePageData.builder_partner.builder_now_url + '/' + data.id + '?red=now', '_self', "noopener");
        }, error => {
          this.appDataService.urlParameters.red = null;
          this.appDataService.urlParameters.application = null;
          this.dataService.showLoaderForBNow = false;
        });
      }
    } else if (this.appDataService.urlParameters.red && this.appDataService.urlParameters.red === 'now') {
      if (this.dataService.user) {
        this.redirectToBuilderNow();
      } else {
        if (this.dataService.homePageData?.block_sign_up) {
          this.phaseService.showSignin = true;
          this.phaseService.showRegister = false;
        } else {
          this.phaseService.showRegister = true;
          this.phaseService.showSignin = false;
        }
        this.phaseService.openCommonAuth = true;
      }
    } else {
      this.phaseService.checkForSignupPopup(this.analyticsSegment);
    }

    if (this.dataService.user) {
      this.phaseService.parseUserProperties({
        'email': this.dataService.user.email,
        'userId': this.dataService.user?.id,
        'name': this.dataService.user.first_name + ' ' + this.dataService.user.last_name,
        first_name: this.dataService.user.first_name,
        last_name: this.dataService.user.last_name,
        utm_campaign: this.appDataService.urlParameters.utm_campaign,
        utm_source: this.appDataService.urlParameters.utm_source,
        utm_medium: this.appDataService.urlParameters.utm_medium,
        utm_term: this.appDataService.urlParameters.utm_term,
        utm_content: this.appDataService.urlParameters.utm_content,
        traits: {
          email: this.dataService.user.email
        }
      });
    }
  }

  public redirectToBuilderNow() {
    window.location.href = this.dataService.homePageData.builder_partner.builder_now_url + '/createbuildcard' +
      (this.appDataService.urlParameters.prototype_promo ? ('?prototype_promo=' + this.appDataService.urlParameters.prototype_promo) : '');
  }

  checkForRegisterUser() {
    if (this.dataService.previousPage === 'welcome') {
      if (this.dataService.homePageData?.block_sign_up) {
        this.phaseService.showSignin = true;
        this.phaseService.showRegister = false;
      } else {
        this.phaseService.showRegister = true;
        this.phaseService.showSignin = false;
      }
      this.phaseService.openCommonAuth = true;
    }
  }

  public getUser() {
    return this.phaseService.getUser();
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getHomeTemplates(templateType) {
    this.phaseService.trackEvent(this.analyticsSegment,  'build_card_category_selected', 'buildcard_type', templateType);
    this.homeTemplateService.templateType = templateType;
    this.homeTemplateService.showTemplatesLoader = true;
    this.homeTemplateService.getAllTemplates(templateType, '', this.homeTemplateService.currencyId).subscribe(result => {
      this.apiSuccess = true;
      const data = result.data;
      this.homeTemplateService.showTemplatesLoader = false;
      this.homeTemplateService.all_templates = data;
      if (this.homeTemplateService.customAddedTemplate.length) {
        this.homeTemplateService.customAddedTemplate.forEach(t => this.homeTemplateService.all_templates.unshift(t));
      }
      if (this.phaseService.selectedApps.length) {
        const selectedAppIDs = this.phaseService.selectedApps.map(template => template.id.toString());
        this.homeTemplateService.all_templates.forEach(template => {
          if (selectedAppIDs.includes(template.id)) {
            template.attributes['selected'] = true;
          }
        })
      }
      this.homeTemplateService.resetSortingOptions.next(true);
    },
      (error) => {
        this.homeTemplateService.showTemplatesLoader = false;
      });
  }

  openSearchBar() {
    this.phaseService.trackEvent(this.analyticsSegment,  'template_search_closed');
    this.homeTemplateService.showConfirmPopup = false;
    this.homeTemplateService.setAnimationVariable();
    this.phaseService.showTemplateSearchBox = !this.phaseService.showTemplateSearchBox;
    if (!this.phaseService.showTemplateSearchBox && !this.homeTemplateService.searchTemplateInput) {
      const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
      window.history.pushState({ path: newurl }, '', newurl);
    }
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    });
  }

  hanldeErrorMessages() {
    let obj;
    if (this.hasErrorMsgShown) {
      this.tryAgainHide = true;
      this.showSpecCallButtons = true;
      this.showTime.e = new Date();
      obj = {
        templateChatText: "Ok, I admit it, you humans understand each other better than me.",
        type: 'natasha',
        createdAt: this.phaseService.getCurrentTimeInHoursMinutes(),
        showSpecCallButtonStatus: true,
        showLoader: true
      };
      this.addinTemplateNatashaChat(obj);
    } else {
      this.showNatashaErrorMsg = true;
      this.hasErrorMsgShown = true;
      this.showTime.c = new Date();
      obj = {
        templateChatText: "Wow, I'm not sure what you just wrote. Could you explain again?",
        type: 'natasha',
        createdAt: this.phaseService.getCurrentTimeInHoursMinutes(),
        showLoader: true
      };
      this.addinTemplateNatashaChat(obj);
    }
    this.homeTemplateService.setAnimationVariable();
  }

  resetMobileNatashaSearch() {
    if (this.natashaTemplatesForMobile.length) {
      this.natashaFeedbackMobile = [];
      this.natashaSearchTextMobile = [];
      this.natashaTemplatesForMobile = [];
      this.showNatashaErrorMsg = false;
      this.showSpecCallButtons = false;
      this.hasErrorMsgShown = false;
      this.feedBackOption = '';
      this.homeTemplateService.showConfirmPopup = false;
      this.homeTemplateService.setAnimationVariable();
    }
    if (this.natashaSearchedText) {
      this.natashaSearchTextMobile.push(this.natashaSearchedText);
      if (this.natashaSearchTextMobile.length === 1) {
        this.showTime.b = new Date();
      } else if (!this.showTime.d) {
        this.showTime.d = new Date();
      }
    }
  }

  getRecommendedTemplates(e?, screenSource?) {
    if (e && (e.keyCode !== 13)) {
      return;
    }
    this.homeTemplateService.clearFilter('category');
    if (screenSource) {
      this.phaseService.trackEventForObject(this.analyticsSegment,  'natasha_build_query_submitted', {user_id: this.dataService.user?.id, screen_source: screenSource, keywords_entered: this.natashaSearchedText});
    }

    this.homeTemplateService.isShowTemplateNatashaInitialAnimation = false;
    this.isShowNatashaSearchSuggestionChip = true;
    this.isShowPositiveFeedbackSuggestionChip = true;
    this.isShowNegativeFeedbackSuggestionChip = true;
    if (this.tryAgainClicked) {
      this.tryAgainClicked = false;
    }
    this.natashaSearchedTextCopy = this.natashaSearchedText.trim();
    this.natashaSearchedText = '';
    // this.resetMobileNatashaSearch();
    if (!this.natashaSearchedTextCopy) {
      return;
    }
    this.natashaSearchedTextCopy = this.natashaSearchedTextCopy.replace(/\s+/g, ' ').trim();
    if ((!this.natashaSearchedTextCopy.toLowerCase().includes('I want to build'.toLowerCase()))
      && (!this.natashaSearchedTextCopy.toLowerCase().includes('I want to have'.toLowerCase()))
      && (!this.natashaSearchedTextCopy.toLowerCase().includes('I want to make'.toLowerCase()))) {
      this.natashaSearchedTextCopy = 'I want to build ' + this.natashaSearchedTextCopy;
    }
    this.phaseService.selectedHash.description = this.natashaSearchedTextCopy;
    let obj;
    this.isShowNatashaSearchedText = true;

    if (!this.homeTemplateService.checkIfTryingToBuildIsLastNatashaMessage()) {
      let obj1 = {
        templateChatText: "What are you trying to build?",
        type: 'natasha',
        createdAt: this.phaseService.getCurrentTimeInHoursMinutes(),
        showLoader: true
      };
      this.addinTemplateNatashaChat(obj1);
    }

    obj = {
      templateChatText: this.natashaSearchedTextCopy,
      type: 'user',
      createdAt: this.phaseService.getCurrentTimeInHoursMinutes()
    };
    this.addinTemplateNatashaChat(obj);

    setTimeout(() => {
      this.isShowNatashaSearchedText = false;
      this.isShowFindSomethingText = true;
      obj = {
        templateChatText: "Hmm that's a great problem, let me find something for you.",
        type: 'natasha',
        createdAt: this.phaseService.getCurrentTimeInHoursMinutes(),
        showLoader: true
      };
      this.addinTemplateNatashaChat(obj);
      this.homeTemplateService.copyNatashaSearchText = this.natashaSearchedTextCopy;
      this.phaseService.templateNatashaSearchText = this.homeTemplateService.copyNatashaSearchText;
      this.homeTemplateService.showTemplatesLoader = true;
      this.homeTemplateService.setAnimationVariable();
      setTimeout(() => {
        this.homeTemplateService.getRecommendedTemplates(this.natashaSearchedTextCopy, this.homeTemplateService.currencyId, this.homeTemplateService.templateType).subscribe(result => {
          this.apiSuccess = true;
          this.isShowFindSomethingText = false;
          if (this.homeTemplateService.isShowTemplateNatashaIntroPopup) {
            this.homeTemplateService.isShowTemplateNatashaIntroPopup = false;
            this.animateNatashaAvatarOnIntroPopupClose = true;
            this.feedbackQuestionDelay = 9000;
            setTimeout(() => {
              this.animateNatashaAvatarOnIntroPopupClose = false;
            }, 1000);
          }
          const data = result.data;
          if (!data.length) {
            this.natashaSearchedTextCopy = '';
            this.hanldeErrorMessages();
            this.isZeroRecommendationFromNatasha = true;
            return;
          }

          this.isZeroRecommendationFromNatasha = false;
          this.natashaSearchedTextCopy = '';
          this.homeTemplateService.showTemplatesLoader = false;
          const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?template_search=' + this.homeTemplateService.copyNatashaSearchText;
          window.history.pushState({ path: newurl }, '', newurl);
          if (screen.width > 768) {
            this.homeTemplateService.showSearchedTemplates = true;
            this.homeTemplateService.searchedTemplates = data;
            if (this.phaseService.selectedApps.length) {
              const selectedAppIDs = this.phaseService.selectedApps.map(template => template.id.toString());
              this.homeTemplateService.searchedTemplates.forEach(template => {
                if (selectedAppIDs.includes(template.id)) {
                  template.attributes['selected'] = true;
                }
              });
            }
            this.hideNatashaPanel();
            const templateSrc = this.homeTemplateService.isShowTemplateNatashaIntroPopup ? 'trying to build' : 'homescreen';
            let templateData = data.map(template => template.attributes.title);
            let templateNames = templateData.join();
            data.template_source = templateSrc
            this.phaseService.trackEventForObject(this.analyticsSegment,  'natasha_suggestions_recommended', {
              user_id: this.dataService.user ?.id,
              templates_count: data.length,
              template_name: templateNames
            });
          } else {
            this.natashaTemplatesForMobile = data;
            obj = {
              natashaMobileTemplates: this.natashaTemplatesForMobile,
              type: 'natasha',
              createdAt: this.phaseService.getCurrentTimeInHoursMinutes(),
              showLoader: true
            };
            this.addinTemplateNatashaChat(obj, true);
            this.showTime.f = new Date();
          }

          setTimeout(() => {
            this.phaseService.trackEventForObject(this.analyticsSegment, 'natasha_feedback_popup_appears', {user_id : this.dataService.user?.id});
            this.homeTemplateService.showConfirmPopup = true;
            this.homeTemplateService.showNatashaPanel = false;
            this.feedbackQuestionDelay = 5000;
          }, this.feedbackQuestionDelay);

          setTimeout(() => {
            obj = {
              templateChatText: "Are these useful?",
              type: 'natasha',
              createdAt: this.phaseService.getCurrentTimeInHoursMinutes(),
              showLoader: true
            };
            this.addinTemplateNatashaChat(obj);
            this.homeTemplateService.setAnimationVariable();
            this.showTime.g = new Date();
            this.renderer.addClass(document.getElementById('app-component'), 'natasha-confirmation');
          }, 2000);
        },
          (error) => {
            this.homeTemplateService.showTemplatesLoader = false;
            this.hasErrorMsgShown = true;
            this.hanldeErrorMessages();
          });
      }, 1000);

    }, 2000);

  }
  generateSuggestionMsgFirstTime() {
    this.charIndexOfPlaceHolder = 0;
    if (this.suggestedMsg.length === 0) {
      this.suggestedMsg = [
        'I want to build an e-learning web app for students and teachers with online lectures, interactive lessons and direct messaging.',
        'I want to make a Covid-19 tracking app to keep staff in my organisation safe; manage tests and vaccine information, health checks and push notifications.',
        'I want to have a social media app that lets users chat online, post status updates and earn rewards when they invite friends.'
      ];
    }
    if (this.suggestionMsg = this.suggestedMsg[Math.floor(Math.random() * this.suggestedMsg.length)]) {
      setTimeout(() => {
        this.charIndexOfPlaceHolder = 0;
        this.placeHolderText = '';
        this.typeWriter();
      }, 3000);
      const index = this.suggestedMsg.indexOf(this.suggestionMsg);
      this.suggestedMsg.splice(index, 1)
    }
    this.suggestionMsgInterval = setInterval(() => {
      if (this.suggestedMsg.length === 0) {
        this.suggestedMsg = [
          'I want to build an e-learning web app for students and teachers with online lectures, interactive lessons and direct messaging.',
          'I want to make a Covid-19 tracking app to keep staff in my organisation safe; manage tests and vaccine information, health checks and push notifications.',
          'I want to have a social media app that lets users chat online, post status updates and earn rewards when they invite friends.'
        ];
      }
      if (this.suggestionMsg = this.suggestedMsg[Math.floor(Math.random() * this.suggestedMsg.length)]) {
        this.charIndexOfPlaceHolder = 0;
        this.placeHolderText = '';
        this.typeWriter();
        const index = this.suggestedMsg.indexOf(this.suggestionMsg);
        this.suggestedMsg.splice(index, 1)
      }
    }, 20700);
  }

  typeWriter = () => {
    if (this.charIndexOfPlaceHolder < this.suggestionMsg.length) {
      this.placeHolderText += this.suggestionMsg.charAt(this.charIndexOfPlaceHolder);
      this.charIndexOfPlaceHolder++;
      if (this.mobileInputNatasha && this.innerWidth <= 1023) {
        //this.mobileInputNatasha.nativeElement.style.height = 'auto';
        //this.mobileInputNatasha.nativeElement.style.height = `${this.mobileInputNatasha.nativeElement.scrollHeight}px`;
      }
      if (this.natshaSearchBox) {
        this.natshaSearchBox.nativeElement.style.height = 'auto';
        this.natshaSearchBox.nativeElement.style.height = `${this.natshaSearchBox.nativeElement.scrollHeight}px`;
      }
      setTimeout(this.typeWriter, this.typingSpeed);
    }
  }

  showNatashaPannel() {
    const showNatashaPopup = this.homeTemplateService.showNatashaPanel && !this.isShowNatashaSearchedText && !this.isShowFindSomethingText;
    if (!this.isNatashaScreenAppearingOnHome && !this.homeTemplateService.isShowTemplateNatashaIntroPopup && showNatashaPopup && !this.showSpecCallButtons) {
      this.isNatashaScreenAppearingOnHome = true;
      this.generateSuggestionMsgFirstTime();
    }
    return showNatashaPopup;
  }

  showNatashaTempIntroPopup() {
    const showIntroPopup = (this.innerWidth > 1023) ? this.homeTemplateService.isShowTemplateNatashaIntroPopup :
      this.homeTemplateService.showNatashaPanelMobile;
    if (!this.isNatashaScreenFirstTime && showIntroPopup) {
      this.isNatashaScreenFirstTime = true;
      setTimeout(() => {
        this.generateSuggestionMsgFirstTime();
      }, 7000);
    }
    return showIntroPopup;
  }

  hideNatashaPanel(isFromOverlay?) {
    this.isShowTryAgainPopup = false;
    if (this.innerWidth <= 1023) {
      this.homeTemplateService.isShowTemplateNatashaIntroPopup = false;
    }
    clearInterval(this.suggestionMsgInterval);
    this.placeHolderText = '';
    this.suggestionMsg = '';
    this.charIndexOfPlaceHolder = 0;
    this.isNatashaScreenAppearingOnHome = false;
    this.tryAgainHide = false;
    this.natashaFeedbackMobile = [];
    this.natashaSearchTextMobile = [];
    this.homeTemplateService.showNatashaPanelMobile = false;
    this.homeTemplateService.showNatashaPanel = false;
    this.showCallsDropDown = false;
    this.tryAgainClicked = false;
    this.feedBackOption = '';
    this.homeTemplateService.setAnimationVariable();
    this.showNatashaErrorMsg = false;
    this.natashaSearchedText = '';
    this.natashaSearchedTextCopy = '';
    this.homeTemplateService.showTemplatesLoader = false;
    this.natashaScroll = false;
    this.showSpecCallButtons = false;
    this.hasErrorMsgShown = false;
    if (isFromOverlay) {
      this.homeTemplateService.showConfirmPopup = false;
      this.hideFeedbackMessage();
    }
    this.resetMobileNatashaSearch();
    this.renderer.removeClass(document.getElementById('app-component'), 'natasha-things');
    this.renderer.removeClass(document.getElementById('app-component'), 'natasha-confirmation');
    if (!isFromOverlay) {
    this.phaseService.trackEventForObject(this.analyticsSegment,  'natasha_screen_modal_closed', {user_id: this.dataService.user?.id ,screen_source: 'Templates Screen'});
    }
    if (this.isZeroRecommendationFromNatasha) {
      this.getHomeTemplates('all');
      this.homeTemplateService.initialLoader = false;
    }
  }

  hideNatashaErrorMsg() {
    this.showNatashaErrorMsg = false;
    this.showSpecCallButtons = false;
  }

  hideConfirmBox(yesOrNo?) {
    if (yesOrNo) {
      if (yesOrNo == 'I like these') {
        this.phaseService.trackEventForObject(this.analyticsSegment,  'natasha_feedback_provided',{user_id: this.dataService.user?.id,feedback_provided: yesOrNo});
      } else if (yesOrNo == 'Not so good') {
        this.phaseService.trackEventForObject(this.analyticsSegment,  'natasha_feedback_provided',{user_id: this.dataService.user?.id,feedback_provided: yesOrNo});
      }
    }
    this.natashaSearchedText = '';
    this.natashaSearchedTextCopy = '';
    let obj;
    this.showNatashaErrorMsg = false;
    this.feedBackContainer = yesOrNo;
    this.natashaFeedbackResponse = yesOrNo;
    this.isNatashaNegativeFeedback = false;
    this.isNatashaPositiveFeedback = false;
    this.isShowTryAgainPopup = false;
    if (yesOrNo) {
      obj = {
        templateChatText: yesOrNo,
        type: 'user',
        createdAt: this.phaseService.getCurrentTimeInHoursMinutes()
      };
      this.addinTemplateNatashaChat(obj);
    } else {
      this.phaseService.trackEvent(this.analyticsSegment,  'natasha_feedback_popup_closed');
    }
    setTimeout(() => {
      if (this.checkIfPositiveOrNegativeNatashaFeedback()) {
        this.isNatashaNegativeFeedback = false;
        this.isNatashaPositiveFeedback = true;
        obj = {
          templateChatText: "Ok great, thanks for letting me know. Select up to 3 templates.",
          type: 'natasha',
          createdAt: this.phaseService.getCurrentTimeInHoursMinutes(),
          showLoader: true
        };
        this.addinTemplateNatashaChat(obj);
        setTimeout(() => {
          this.hideFeedbackMessage();
        }, 5000);
      } else {
        this.isNatashaPositiveFeedback = false;
        this.isNatashaNegativeFeedback = true;
        this.isShowTryAgainPopup = true;
        obj = {
          templateChatText: "Sorry I couldn’t be helpful. Try again or speak to one of our experts.",
          type: 'natasha',
          createdAt: this.phaseService.getCurrentTimeInHoursMinutes(),
          isNatashaNegativeFeedbackStatus: true,
          showLoader: true
        };
        this.addinTemplateNatashaChat(obj);
      }
    }, 1000);

    // if (screen.width < 768) {
    //   this.natashaFeedbackMobile.push(yesOrNo);
    //   this.showTime.h = new Date();
    // } else {
    this.homeTemplateService.showConfirmPopup = false;
    this.homeTemplateService.setAnimationVariable();
    //}
    const feedBackData = {
      problem_statement: this.homeTemplateService.copyNatashaSearchText,
      feedback_text: this.feedBackContainer
    };
    this.apiRequest.saveNatashaTempFeedBack(feedBackData).subscribe(resp => {
      console.log(resp);
    });
    if (!this.feedBackContainer) {
      this.renderer.removeClass(document.getElementById('app-component'), 'natasha-things');
      this.renderer.removeClass(document.getElementById('app-component'), 'natasha-confirmation');
    }
  }

  showNatashaSearch() {
    this.isNatashaScreenAppearingOnHome = true;
    if (this.innerWidth <= 1023) {
      if (!this.dataService.user && this.objectKeys(this.homeTemplateService.mobileTempateNatashaChatList).length === 0) {
        this.initializeNatashaMessages();
      }
    }
    clearInterval(this.suggestionMsgInterval);
    this.generateSuggestionMsgFirstTime();
    if (screen.width > 768) {
      this.homeTemplateService.showConfirmPopup = false;
      this.homeTemplateService.setAnimationVariable();
    }
    this.feedBackContainer = '';
    this.isShowNatashaSearchSuggestionChip = true;
    this.isShowPositiveFeedbackSuggestionChip = true;
    this.isShowNegativeFeedbackSuggestionChip = true;
    this.homeTemplateService.showNatashaPanel = true;
    this.homeTemplateService.showNatashaPanelMobile = true;
    this.isShowTryAgainPopup = false;
    this.homeTemplateService.setAnimationVariable();
    this.showTime.a = new Date();
    this.renderer.addClass(document.getElementById('app-component'), 'natasha-things');
  }

  openMeetNowModal(event?) {
    if (this.homeTemplateService.isShowTemplateNatashaIntroPopup) {
      this.homeTemplateService.isShowTemplateNatashaIntroPopup = false;
      this.animateNatashaAvatarOnIntroPopupClose = true;
      setTimeout(() => {
        this.animateNatashaAvatarOnIntroPopupClose = false;
      }, 1000);
    }
    if (event === 'instant') {
      this.phaseService.showInstantSpecCallFlow = true;
    }
    if (event === 'liveChat') {
      this.phaseService.showIntercomChat();
    }
    this.analyticsSegment.eventTrack('Instant Call Clicked', { source: 'Home natasha' });
    this.natashaSearchedText = '';
    this.natashaSearchedTextCopy = '';
    this.homeTemplateService.showTemplatesLoader = false;
    this.homeTemplateService.showNatashaPanel = false;
    this.homeTemplateService.showNatashaPanelMobile = false;
    this.showCallsDropDown = false;
    this.feedBackContainer = '';
    this.natashaFeedbackResponse = '';
    this.homeTemplateService.setAnimationVariable();
    this.natashaSearchTextMobile = [];
    this.showSpecCallButtons = false;
    this.showNatashaErrorMsg = false;
    this.hasErrorMsgShown = false;
    this.isShowTryAgainPopup = false;
    this.hideNatashaPanel();
    this.renderer.removeClass(document.getElementById('app-component'), 'natasha-things');
    this.renderer.removeClass(document.getElementById('app-component'), 'natasha-confirmation');
    if (event !== 'liveChat') {
      if (!this.dataService.user) {
        this.phaseService.openCommonAuth = true;
        if (this.dataService.homePageData?.block_sign_up) {
          this.phaseService.showSignin = true;
          this.phaseService.showRegister = false;
        } else {
          this.phaseService.showRegister = true;
          this.phaseService.showSignin = false;
        }
        this.dataService.openMeetNowModal = true;
        return;
      }
      if (this.dataService.user.spec_call_nda_signed || this.phaseService.showInstantSpecCallFlow) {
        this.dataService.showMeetNowModel = true;
      } else {
        this.phaseService.showSignNDAPopup = true;
      }
    }

  }

  clearSearch() {
    this.hideConfirmBox();
    this.homeTemplateService.clearFilter('all');
    this.homeTemplateService.clearSearch();
    const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
    window.history.pushState({ path: newurl }, '', newurl);
    this.appDataService.urlParameters.template_search = '';
  }

  leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }

  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  selectTemplate(template) {
    this.homeTemplateService.selectTemplate(template);
  }

  checkActive(template) {
    if (this.phaseService.selectedApps.length > 0) {
      const index = this.getIndex(template);
      return index >= 0 ? true : false;
    }
  }

  getIndex(template) {
    return this.phaseService.selectedApps.findIndex(temp => template.id === temp.id);
  }

  getLocaledPriceInt(price: any) {
    return this.dataService.getLocaledPriceInt(price);
  }

  getRedirectionLink() {
    if (!this.dataService.appDetails.slug) { return }
    this.phaseService.trackEventForObject(this.analyticsSegment,  'template_view_details_screen_expanded', {
      user_id : this.dataService.user?.id,
      buildcard_category : this.homeTemplateService.templateType
    });
    if (this.dataService.appDetails.pricing_modal === 'rental_price') {
      window.open(this.phaseService.getRedirectionLink(this.dataService.appDetails), '_blank', "noopener");
      return;
    }
    window.open(this.phaseService.getRedirectionLink(this.dataService.appDetails), '_blank', "noopener");
  }

  cookiePolicyMsgClose() {
    this.cookiePolicyMsgPopup = false;
    if (this.commonService.isPlatformBrowser) {
      localStorage.setItem('cookie_popup_checked', 'true');
    }
  }

  disclaimerReveal() {
    this.cookieMsgChange = true;
  }

  animationHandling() {
    return this.homeTemplateService.idleAnimation || (!this.homeTemplateService.showNatashaPanel && !this.homeTemplateService.showConfirmPopup);
  }

  getRedirectionUrl() {
    return window.location.host + this.dataService.redirectionUrl;
  }

  hideFeedbackMessage() {
    this.feedBackContainer = '';
    this.natashaFeedbackResponse = '';
    this.isNatashaPositiveFeedback = false;
    this.isNatashaNegativeFeedback = false;
    this.renderer.removeClass(document.getElementById('app-component'), 'natasha-things');
    this.renderer.removeClass(document.getElementById('app-component'), 'natasha-confirmation');
  }

  showHideDropDown(feedBackOption) {
    this.natashaSearchedText = '';
    this.natashaSearchedTextCopy = '';
    this.showNatashaErrorMsg = false;
    this.feedBackOption = feedBackOption;
    this.isShowTryAgainPopup = false;
    if (feedBackOption === 'Try again') {
      this.tryAgainClicked = true;
      this.feedBackContainer = '';
      this.natashaFeedbackResponse = '';
      this.homeTemplateService.showNatashaPanel = true;
    } else {
      this.showCallsDropDown = !this.showCallsDropDown;
    }
    this.mobileInputNatasha.nativeElement.focus();
  }

  urlCopied(e) {
    this.phaseService.trackEventForObject(this.analyticsSegment,  'template_view_details_link_copied', {
      user_id : this.dataService.user?.id,
      buildcard_category : this.homeTemplateService.templateType
    });
    this.urlcopied = true;
    setTimeout(() => {
      this.urlcopied = false;
    }, 20000);

  }

  closeAlertBox() {
    this.homeTemplateService.showMaxTempSelectionAlert = false;
  }

  checkIfPositiveOrNegativeNatashaFeedback() {
    if (this.feedBackContainer) {
      let feedbackMessage = this.feedBackContainer.toLowerCase();
      if ((feedbackMessage === 'yes') || (feedbackMessage === 'yep') || (feedbackMessage === 'yup') || (feedbackMessage.includes('i like these'))) {
        return true;
      } else if ((feedbackMessage === 'no') || (feedbackMessage === 'nop') || (feedbackMessage.includes('not so good'))) {
        return false;
      }
    }
    return false;
  }

  addinTemplateNatashaChat(obj, isForMobile?) {
    this.homeTemplateService.addinTemplateNatashaChatList(obj, isForMobile);
  }

  closeTemplateNatashaIntroPopup(source) {
    if (source == 'closeBtn') {
      this.phaseService.trackEventForObject(this.analyticsSegment,  'natasha_screen_modal_closed', { user_id: this.dataService.user ?.id, screen_source: 'Welcome Screen' });
    }
    if (source == 'footer') {
      this.phaseService.trackEventForObject(this.analyticsSegment,  'welcome_natasha_skipped',{ user_id : this.dataService.user?.id});
    }
    clearInterval(this.suggestionMsgInterval);
    this.placeHolderText = '';
    this.suggestionMsg = '';
    this.charIndexOfPlaceHolder = 0;
    this.isNatashaScreenAppearingOnHome = false;
    this.homeTemplateService.isShowTemplateNatashaIntroPopup = false;
    this.homeTemplateService.isShowTemplateNatashaInitialAnimation = false;
    this.animateNatashaAvatarOnIntroPopupClose = true;
    setTimeout(() => {
      this.animateNatashaAvatarOnIntroPopupClose = false;
    }, 1000);
  }

  AppendSuggestionChipInInputText(text) {
    // if (text === 'I want to build') {
    this.natashaSearchedText = this.natashaSearchedText + text + " ";
    this.isShowNatashaSearchSuggestionChip = false;
    document.getElementById("input1").focus();
    // }
    // else if (text === 'I like these') {
    //   this.natashaFeedbackResponse = this.natashaFeedbackResponse + text;
    //   this.isShowPositiveFeedbackSuggestionChip = false;
    // } else if (text === 'Not so good') {
    //   this.natashaFeedbackResponse = this.natashaFeedbackResponse + text;
    //   this.isShowNegativeFeedbackSuggestionChip = false;
    // }
  }

  checkIfUserStartTyping(event) {
    if ((this.natashaSearchedText.length === 0) && event.key !== "Backspace") {
      let text = "I want to build";
      this.natashaSearchedText = text + " " + this.natashaSearchedText;
      document.getElementById("input1").focus();
      this.isShowNatashaSearchSuggestionChip = false;
    }
  }

  checkNatashaWordCount(event) {
    clearInterval(this.suggestionMsgInterval);
    this.placeHolderText = '';
    this.suggestionMsg = '';
    this.charIndexOfPlaceHolder = 0;
    const selectedText = window.getSelection().toString();
    if (selectedText && (event.keyCode === 8) && this.natashaSearchedText.includes(selectedText)) {
      this.natashaSearchedText = this.natashaSearchedText.replace(selectedText, '');
    }
    this.checkIfUserClickBack(event);
    if (this.checkWordCount() > 40) {
      this.natashaSearchedText = this.natashaSearchedText.split(' ').filter(a => a.trim()).splice(0, 40).join(' ');
      this.isShowNatashaWordCountErrorMsg = true;
      setTimeout(() => { this.isShowNatashaWordCountErrorMsg = false }, 3000);
    }
    if (this.natshaSearchBox) {
      this.natshaSearchBox.nativeElement.style.height = 'auto';
      this.natshaSearchBox.nativeElement.style.height = `${this.natshaSearchBox.nativeElement.scrollHeight}px`;
    }
    if (this.natashaSearchedText === '') {
      this.isShowNatashaSearchSuggestionChip = true;
    }
  }

  checkWordCount() {
    return this.natashaSearchedText.split(' ').filter(a => a.trim().length > 0).length;
  }

  checkIfNatashaFeedbackResponseEmpty() {
    if (!this.natashaFeedbackResponse) {
      this.isShowPositiveFeedbackSuggestionChip = true;
      this.isShowNegativeFeedbackSuggestionChip = true;
    }
  }

  getTypeOfNatashaGroupElement(key) {
    let groupElements = this.homeTemplateService.mobileTempateNatashaChatList[key];
    let firstGroupElement = groupElements[0];
    let typeOfGroupElement = firstGroupElement['type'];
    return typeOfGroupElement;
  }

  getCreatedDateOfFirstNatashaGroupElement(key) {
    let groupElements = this.homeTemplateService.mobileTempateNatashaChatList[key];
    let firstGroupElement = groupElements[0];
    let createdDateOfGroupElement = firstGroupElement['createdAt'];
    return createdDateOfGroupElement;
  }

  objectKeys(obj) {
    return Object.keys(obj);
  }

  natashaNegativeFeedbackTalkToExpertsClicked() {
    let obj1 = {
      templateChatText: 'Talk to our experts',
      type: 'user',
      createdAt: this.phaseService.getCurrentTimeInHoursMinutes()
    };
    this.addinTemplateNatashaChat(obj1);

    let obj2 = {
      templateChatText: "You’ve 2 ways to speak to an expert:",
      type: 'natasha',
      createdAt: this.phaseService.getCurrentTimeInHoursMinutes(),
      showSpecCallButtonStatus: true,
      showLoader: true
    };
    this.addinTemplateNatashaChat(obj2);
  }

  initializeNatashaMessages() {
    let obj;
    if (this.dataService.user) {
      obj = {
        templateChatText: "Tell me what problem your app solves and I'll help you build it.",
        type: 'natasha',
        createdAt: this.phaseService.getCurrentTimeInHoursMinutes(),
        showLoader: true
      };
      this.addinTemplateNatashaChat(obj);
    } else {
      obj = {
        templateChatText: "Hey you! I’m Natasha your AI Product Manager.",
        type: 'natasha',
        createdAt: this.phaseService.getCurrentTimeInHoursMinutes(),
        showLoader: true,
        natashaIntroLineStatus: true
      };
      this.addinTemplateNatashaChat(obj);
      if (this.innerWidth <= 1023) {
        obj = {
          templateChatText: "You can chat to me here or go straight to the library.",
          type: 'natasha',
          createdAt: this.phaseService.getCurrentTimeInHoursMinutes(),
          showLoader: true
        };
        this.addinTemplateNatashaChat(obj);

        obj = {
          templateChatText: "What's your name?",
          type: 'natasha',
          createdAt: this.phaseService.getCurrentTimeInHoursMinutes(),
          showLoader: true,
          natashaNameStatus: true
        };
        this.addinTemplateNatashaChat(obj);

      }

    }

  }

  getUserNameFromNatasha(e?) {
    if (e && e.keyCode !== 13) {
      return;
    }
    this.phaseService.trackEventForObject(this.analyticsSegment, 'natasha_name_query_submitted', {user_id: this.dataService.user?.id,keywords_entered: this.userNameFromNatasha});

    this.userNameFromNatasha = this.userNameFromNatasha.trim();
    if (this.userNameFromNatasha === "") {
      return;
    }
    this.homeTemplateService.isShowNatashaNameScreen = false;
    this.isUserNameEntered = true;
    let obj1 = {
      templateChatText: "What's your name?",
      type: 'natasha',
      createdAt: this.phaseService.getCurrentTimeInHoursMinutes(),
      showLoader: true
    };
    this.addinTemplateNatashaChat(obj1);

    let obj2 = {
      templateChatText: this.userNameFromNatasha,
      type: 'user',
      createdAt: this.phaseService.getCurrentTimeInHoursMinutes()
    };
    this.addinTemplateNatashaChat(obj2);

    let userName = this.getValidFirstNameFromUserName(this.userNameFromNatasha);
    sessionStorage.setItem('userNameFromNatasha', userName);
    this.userNameFromNatasha = '';
    if (this.innerWidth <= 1023) {
      this.showUserNameInMobile = true;
    }
    setTimeout(() => {
      this.isShowTryingToBuildText = true;
      this.isShowTryingToBuildTextMobile = true;
      this.showUserNameInMobile = false;
    }, 2000);
    setTimeout(() => {
      this.isUserNameEntered = false;
      this.isShowTryingToBuildText = false;
      let obj = {
        templateChatText: "What are you trying to build?",
        type: 'natasha',
        createdAt: this.phaseService.getCurrentTimeInHoursMinutes(),
        showLoader: true
      };
      this.addinTemplateNatashaChat(obj);
    }, 9000);
  }

  checkIfUserClickBack(event) {
    const keyCode = event.keyCode;
    const ctrKey = event.ctrlKey;
    if (ctrKey || (ctrKey && ((keyCode === 86) || (keyCode === 67)))) {
      return;
    }
    if ((event.target.selectionStart < 16) && (this.natashaSearchedText.toLowerCase().includes('i want to build'))) {
      event.target.selectionStart = this.natashaSearchedText.length;
    }
  }

  fetchUserNameFromSessionStorage() {
    if (sessionStorage.getItem('userNameFromNatasha')) {
      return sessionStorage.getItem('userNameFromNatasha');
    }
    return null;
  }

  checkWordAndCharacterLimitForUserName(event) {
    const tempArray = this.userNameFromNatasha.split(' ');
    let isValidName = false;
    for (const item of tempArray) {
      if (((item.length >= 20) || (tempArray.length > 3)) && (event.key !== 'Backspace') && (event.keyCode !== 32)) {
        isValidName = true;
      } else {
        isValidName = false;
      }
    }
    if (isValidName) {
      return false;
    }
  }
  closeConfirmationPopup() {
    this.phaseService.emailConfirmedPoupup = false;
    this.phaseService.userAlreadyVeriied = false;
  }

  getValidFirstNameFromUserName(userName) {
    const tempArray = userName.split(' ');
    if (tempArray[0].length <= 20) {
      return tempArray[0];
    } else {
      let firstNameSubstring = tempArray[0].substring(0, 20);
      return firstNameSubstring;
    }
  }

  studioStoreModalScrolled(event) {
    this.phaseService.trackEvent(this.analyticsSegment,  'studio_store_modal_scrolled', 'user_id', this.dataService.user ?.id);
  }

  openSignupModal() {
    this.phaseService.openCommonAuth = true;
    if (this.dataService.homePageData?.block_sign_up) {
      this.phaseService.showSignin = true;
      this.phaseService.showRegister = false;
    } else {
      this.phaseService.showRegister = true;
      this.phaseService.showSignin = false;
    }
  } 

}
