<div class="questionSection">
  <div class="sectionContent">
    <div class="leftSide">
      <h3>{{problem_group.title}} <img src="../../../../../assets/images/smile3.png" width="32" height="38" alt=""/></h3>
      <p>{{problem_group.description}}</p>
    </div>

    <div class="rightSide">
      <div class="viewAll" (click)="viewAllProblems()">View all</div>
    </div>
  </div>


  <div class="bLoader" *ngIf="showLoader">
    <div class="loaderBox"><img src="../../../../../assets/images/bloader.gif" alt=""/></div>
    <div class="loaderBox"><img src="../../../../../assets/images/bloader.gif" alt=""/></div>
    <div class="loaderBox"><img src="../../../../../assets/images/bloader.gif" alt=""/></div>
  </div>

  <div class="questionRow" *ngIf="primarySections.length > 0">
    <drag-scroll (reachesLeftBound)="leftBoundStat($event)"
                 (reachesRightBound)="rightBoundStat($event)" #nav>
      <div class="questionbox" drag-scroll-item
           [ngClass]="{completed : phaseService.newSelectedProblemsIds.includes(pSection.id)}"
           *ngFor="let pSection of primarySections">
        <h3 (click)="clickProblem(pSection)">{{pSection.problem_statement}}</h3>
        <div class="answearSet">
          <ul>
            <li *ngFor="let question of pSection.applications; index as i">
              <input (change)="removeApp(pSection, question)" type="checkbox" id="answear_{{question.id}}"
                     [checked]="checkApp(pSection, question) === true">
              <label for="answear_{{question.id}}">
                <div class="answearIcon"><img src="{{utilityService.addHttps(question.icon)}}" width="45" height="45" alt=""/></div>
                {{question.title}}
              </label>
            </li>
          </ul>
        </div>
      </div>
    </drag-scroll>
    <button class="dragleftArrow" [disabled]="leftNavDisabled" type="button" (click)="moveLeft()"><em
      class="icon-nexticon"></em></button>
    <button class="dragrightArrow" [disabled]="rightNavDisabled" type="button" (click)="moveRight()"><em
      class="icon-nexticon"></em></button>
  </div>

  <!-- <div *ngIf = "phaseService.newSelectedAppIds.length > 0">
    <button type="button" (click) = "maintainPreSelection()">Cancel</button>
    <button type="button" (click) = "removePreSelection()">Next</button>
  </div>
</div> -->
  <div class="commonPopUp"
       [ngClass]="{active: phaseService.newSelectedAppIds.length > 0 && showConfirm && phaseService.firstClicked === 'app'}">
    <div class="popOverlay"></div>
    <div class="popHolder selectConfirmation">
      <div class="closePopUp" (click)="closePopUp()"><span class="icon-cancel"></span></div>
      <div class="selectHeading">Confirm</div>
      <div class="selectText">
        <div class="infoIcon"><em class="icon-info"></em></div>
        <p>Selecting a problem statement will deselect the apps you
          have currently selected. Are you sure you want to continue?</p>
      </div>

      <div class="selectBottom">
        <h4>Why does this happen?
          <div class="customTooltip"><p>Problem statements are mapped to specific apps. We do not recommend selecting so
            many apps at the same time.</p></div>
        </h4>
        <div class="pull-right">
          <button type="button" (click)="maintainPreSelection()" class="cancelButton">Cancel</button>
          <button type="button" (click)="removePreSelection()" class="nextButton">Next</button>
        </div>
      </div>

    </div>
  </div>
</div>
