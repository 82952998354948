import { Component, OnInit, ViewChild, Input, HostListener } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { NguCarouselConfig } from '@ngu/carousel';
import { DataService } from '@shared/services/data.service';
import { HomeTemplateService } from '@shared/services/home-template.service';
import { Subscription } from 'rxjs';
import { Intercom } from 'ng-intercom';
import { Angulartics2Segment } from 'angulartics2/segment';

@Component({
  selector: 'app-studio-template',
  templateUrl: './studio-template.component.html',
  styleUrls: ['./studio-template.component.scss'],
    host: {
    '(document:click)': 'onClick($event.target)'
  }
})

export class StudioTemplateComponent implements OnInit {


  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;
  showTooltip = false;
  currencyChangeSubscription: Subscription;
  allTemplateSubscription: Subscription;
  @ViewChild("studioTooltip") studioTooltip;

  constructor(public homeTemplateService: HomeTemplateService, private dataService: DataService, public analyticsSegment: Angulartics2Segment, ) { }

  public carouselTile: NguCarouselConfig;
  ngOnInit(): void {
    this.homeTemplateService.currencyId = this.homeTemplateService.getCurrency().id;
    this.getHomeTemplates(this.homeTemplateService.currencyId);
    this.currencyChangeSubscription = this.homeTemplateService.currencyChange.subscribe((data) => {
      if(data && data.type == 'single') {
        this.homeTemplateService.currencyId = data.currencyId;
        this.getHomeTemplates(data.currencyId);
      }
    })
    this.carouselTile = {
      grid: { xs: 1, sm: 2, md: 3, lg: 3, xl:3, all: 0 },
      slide: 2,
      speed: 250,
      point: {
        visible: true
      },
      load: 2,
      velocity: 0,
      touch: true,
      easing: 'cubic-bezier(0, 0, 0.2, 1)'
    };
  }

getHomeTemplates(currencyId) {
  this.homeTemplateService.showTemplatesLoader = true;
  this.allTemplateSubscription =  this.homeTemplateService.getAllTemplates('studio_store', '', currencyId)
  .subscribe(result => {
    let data = result.data;
    this.homeTemplateService.initialLoader = false;
    this.homeTemplateService.showTemplatesLoader = false;
    this.homeTemplateService.studio_templates = data;
  },
(error) => {
  this.homeTemplateService.initialLoader = false;
});
}

toggleTooltip() {
  this.showTooltip = !this.showTooltip;
}


ngOnDestroy() {
  if(this.currencyChangeSubscription) {
    this.currencyChangeSubscription.unsubscribe()
  }
  if(this.allTemplateSubscription) {
    this.allTemplateSubscription.unsubscribe()
  }
}

  public onClick(targetElement) {
    const clickedInside = this.studioTooltip?.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showTooltip = false;
    } else if (targetElement.classList.contains('filterSectionHeading')) {
      this.showTooltip = false;
    }
  }
}
