<div class="dashHeading">Professional Prototypes

  <div class="searchCards" *ngIf="!(showRecentCustomPrototype && dataService.getCardCount('custom_prototype') > 0)">
    <em class="icon-search"></em>
    <input type="text" placeholder="Search for Buildcard" [(ngModel)]="dataService.searchText" (keyup)="getBuildCardsWithSearch()">
    <div class="clearButton" *ngIf="dataService.searchText"><em class="icon-cancel" (click)="getBuildCardsWithSearch('true')"></em></div>
  </div>

  <button type="button" (click)="showCards(9)" *ngIf="showRecentCustomPrototype && dataService.getCardCount('custom_prototype') > 4">Show all </button>
</div>



<div class="dashProjectRow hideCard" infiniteScroll [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="300" (scrolled)="onScroll(dataService.getCardCount('custom_prototype'))">
  <div class="progressCard" *ngFor="let card of (showRecentProgress ? (getCustomPrototypeCards() | slice:0: (dataService.getCardCount('custom_prototype') > 4 ? 4: dataService.getCardCount('custom_prototype'))) : getCustomPrototypeCards());let i = index;">
    <div class="cardHead">
      <div class="cardLeft">
        <h3>{{ card?.project_name }} </h3>
        <div class="cardState inProgress" *ngIf="card?.status === 'under_development'"><em class="icon-clock"></em> In-Progress</div>
        <div class="cardState completed" *ngIf="card?.status === 'card_completed'"><em class="icon-tick-inside"></em> Completed</div>
      </div>

      <div class="cardRight" (click)="phaseService.showSharePopup(card)">
        <!--<div class="shareIcon"><em class="icon-share"></em></div>-->
        <div class="brandingbtn">
          <button *ngIf="!card.is_branding_added" class="addbtn" (click)="viewAddBranding(card, true);$event.stopPropagation();">Add Branding</button>
          <button *ngIf = "card.is_branding_added" class="viewbtn" (click)="viewAddBranding(card, false);$event.stopPropagation();">View Branding</button>
        </div>
      </div>
    </div>

    <div class="cardMiddle">
      <div class="prototypeReady">
        <img src="../../../../../../assets/images/prototypetest.png" alt=""  />
      </div>
      <div class="prototypeList">
        <ul>
          <li><span>Device Type</span> 
            <div class="iconHolder">
              <div class="iconImg"><img src="../../../../../../../assets/images/monitor-icon.svg" alt="" /> <div class="iconTooltip">Web</div></div>
              <div class="iconImg"><img src="../../../../../../../assets/images/phone-icon.svg" alt="" /> <div class="iconTooltip">Mobile</div></div>
            </div>
          </li>
          <li><span>Template</span> <strong>{{ card?.template_count }}</strong></li>
          <li><span>Features</span> <strong>{{ card?.feature_count }}</strong></li>
        </ul>
      </div>
    </div>

    <div class="cardBottom">
      <div class="priceTag"><div>{{ card?.currency?.symbol + dataService.getLocaledPrice((
          ((getDiscountedprice(card) < getprice(card)) ? getDiscountedprice(card) : getprice(card)) + (card.change_price_status ? card.change_amount : -card.change_amount))| number : '1.2-2') }} </div></div> <button type="button" (click)="viewProgress(card)">View progress</button>
      <div class="clearfix"></div>
    </div>
  </div>
</div>

<div class="dashProjectRow" *ngIf="isApiSuccess && (dataService.customPrototypeCards.length === 0)">
  <div class="norecordHeading">No records found.</div>
</div>
