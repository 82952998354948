<div class="commonPopUp" [ngClass]="{'welcomehomebg': !isSignUpflow, 'active': isShowInfoModal}" >
  <div class="popOverlay bgWhite"></div>
  <div class="popHolder studioIntroModal">
    <div class="introText">
      <h2>Welcome to Builder Studio</h2>
      <h3>Software so easy, everyone can do it.</h3>

      <div class="columnContainer">
        <ngu-carousel #myCarousel  [inputs]="carouselConfig" [dataSource]="carouselItems" *ngIf="!isDesktopScreen">
          <ngu-tile class="introColumn" *nguCarouselDef="let item">
            <div class="imageContainer">
              <img [src]="item.image" alt="" />
            </div>
            <ul>
              <li>{{ item.step }}</li>
            </ul>
            <p class="description">{{ item.desc }}</p>
          </ngu-tile>
          <ul class="customPagination" NguCarouselPoint>
            <li *ngFor="let j of myCarousel.pointNumbers; let i = index" [class.active]="i==myCarousel.activePoint" (click)="myCarousel.moveTo(i)"></li>
          </ul>
        </ngu-carousel>

        <ng-container *ngIf="isDesktopScreen">
          <div class="introColumn" *ngFor="let item of carouselItems">
            <div class="imageContainer">
              <img [src]="item.image" alt="" />
            </div>
            <ul>
              <li>{{ item.step }}</li>
            </ul>
            <p class="description">{{ item.desc }}</p>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="sticky-bottom-bar">
      <button class="builderNowCta btnChoose" type="button" *ngIf="!isSignUpflow" (click)="openBuildNow()">Choose a base</button>
      <button class="builderNowCta btnChoose" type="button" *ngIf="isSignUpflow" (click)="openSignupModal()">Sign up to get started</button>
    </div>

  </div>
</div>
