import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {SharableUrlComponent} from '@shared/shared-components/sharable-url/sharable-url.component';
import {DeliveryGuard} from '@shared/guards/delivery.guard';
import {NgModule} from '@angular/core';
import {LoaderComponent} from '@rootmodule/loader/loader.component';
import {InvalidAccessComponent} from '@rootmodule/invalidaccess/invalidaccess.component';
// import {FairUsagePolicyComponent} from '@rootmodule/fair-usage-policy/fair-usage-policy.component';
import {AuthenticationGuard} from '@shared/guards/authentication.guard';
import {TemplateDetailComponent} from '@featuremodules/app-template-module/template-detail/template-detail.component';
// import {InstantPrototypeComponent} from '@rootmodule/instant-prototype/instant-prototype.component';
// import {Homev2Component} from '@rootmodule/homev2/homev2.component';
import {HandleApiErrorsComponent} from '@rootmodule/handle-api-errors/handle-api-errors.component';
import { AuthGuard } from "src/app/core/root-module/auth-guard.service";
import { UpgradePaymentSummaryComponent } from '@featuremodules/payment-summary-module/upgrade-payment-summary/upgrade-payment-summary.component';
import { upgradePaymentThankyouComponent } from '../feature-modules/payment-summary-module/upgrade-payment-thankyou/upgrade-payment-thankyou.component';
import { NewLoginComponent } from '@rootmodule/new-login/new-login.component'
import {TncProComponent} from '@shared/shared-components/tnc-pro/tnc-pro.component';
import {TncStoreComponent} from '@shared/shared-components/tnc-store/tnc-store.component';
// import { BeingReferredComponent } from '@featuremodules/referral-program-module/being-referred/being-referred.component';


const rootRouterConfig: Routes = [
  {
    path: '',
    component: LoaderComponent,
    pathMatch: 'full'
  },
  {
    path: 'loader',
    component: LoaderComponent,
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('@featuremodules/home-templates/home-templates.module').then(m => m.HomeTemplatesModule)
  },
    // {
    //   path: 'welcome-to-studio',
    //   loadChildren: () => import('@featuremodules/studio-intro-modal-module/studio-intro-modal-module.module').then(m => m.StudioIntroModalModuleModule)
    // },
  // {
  //   path: 'welcome',
  //       loadChildren: () => import('@featuremodules/home-templates/home-templates.module').then(m => m.HomeTemplatesModule)
  // },
  {
    path: 'resetpassword',
    loadChildren: () => import('@featuremodules/resetpwd-module/resetpwd.module').then(m => m.ResetPwdModule)
  },
  // {
  //   path: 'apps/:id',
  //   loadChildren: () => import('@featuremodules/app-template-module/apptemplate.module').then(m => m.AppTemplateModule)
  // },
  {
    path: ':id/apps',
    loadChildren: () => import('@featuremodules/home-templates/home-templates.module').then(m => m.HomeTemplatesModule)
  },
  {
    path: 'apps',
    loadChildren: () => import('@featuremodules/app-template-module/apptemplate.module').then(m => m.AppTemplateModule)
  },
  {
    path: 'feature',
    loadChildren: () => import('@featuremodules/feature-module/feature.module').then(m => m.FeatureModule),
    canActivate: [AuthenticationGuard]
  },
  // {
  //   path: 'problemsolve',
  //   loadChildren: () => import('@featuremodules/problemsolve-module/problemsolve.module').then(m => m.ProblemSolveModule)
  // },
  {
    path: ':id/feature',
    loadChildren: () => import('@featuremodules/feature-module/feature.module').then(m => m.FeatureModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'features/platforms',
    loadChildren: () => import('@featuremodules/feature-module/feature.module').then(m => m.FeatureModule)
  },
  {
    path: 'build-card/:id',
    loadChildren: () => import('@featuremodules/summary-module/summary.module').then(m => m.SummaryModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('@featuremodules/dashboard-module/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'buildcard-payment/:id',
    loadChildren: () => import('@featuremodules/payment-builder-module/payment-builder.module').then(m => m.PaymentBuilderModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'buildercare-payment/:id',
    loadChildren: () => import('@featuremodules/payment-builder-module/payment-builder.module').then(m => m.PaymentBuilderModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'buildcard-payment/:id/paymentplan',
    loadChildren: () => import('@featuremodules/payment-builder-module/payment-builder.module').then(m => m.PaymentBuilderModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'buildcard-payment/:id/billingdetails',
    loadChildren: () => import('@featuremodules/payment-builder-module/payment-builder.module').then(m => m.PaymentBuilderModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'buildcard-payment/:id/payConfirm',
    loadChildren: () => import('@featuremodules/payment-builder-module/payment-builder.module').then(m => m.PaymentBuilderModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'specing-payment/:id',
    loadChildren: () => import('@featuremodules/payment-builder-module/payment-builder.module').then(m => m.PaymentBuilderModule)
  },
  {
    path: 'prototype-installment-payment/:id',
    loadChildren: () => import('@featuremodules/payment-builder-module/payment-builder.module').then(m => m.PaymentBuilderModule)
  },
  {
    path: 'admin-dashboard',
    loadChildren: () => import('@featuremodules/payment-dashboard-module/payment-dashboard.module').then(m => m.PaymentDashboardModule)
  },
  {
    path: 'meet-now',
    loadChildren: () => import('@featuremodules/meet-now-module/meet-now-module.module').then(m => m.MeetNowModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'meet-now/:id',
    loadChildren: () => import('@featuremodules/meet-now-module/meet-now-module.module').then(m => m.MeetNowModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'become-a-partner',
    loadChildren: () => import('@featuremodules/partner-dashboard-module/partner-dashboard.module').then(m => m.PartnerDashboardModule),
  },
  {
    path: 'invalidaccess',
    component: InvalidAccessComponent
  },
  {
    path: 'maintenance',
    component: HandleApiErrorsComponent
  },
  {
    path: 'shared-buildcard/:id',
    component: SharableUrlComponent
  },
  {
    path: 'delivery/:id',
    loadChildren: () => import('@featuremodules/delivery-module/delivery.module').then(m => m.DeliveryModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'delivery',
    loadChildren: () => import('@featuremodules/delivery-module/delivery.module').then(m => m.DeliveryModule),
    canActivate: [DeliveryGuard]
  },
  // {
  //   path: 'acceptable_fair_use_policy',
  //   component: FairUsagePolicyComponent
  // },
  // {
  //   path: 'instant_prototype',
  //   component: InstantPrototypeComponent
  // },
  // {
  //   path: 'homev2',
  //   component: Homev2Component
  // },
  {
    path: 'maintenance',
    component: HandleApiErrorsComponent
  },
  {
    path: 'upgrade',
    component: UpgradePaymentSummaryComponent
  },
  {
    path: 'thankyou',
    component: upgradePaymentThankyouComponent
  },
  {
    path: 'login',
    component: NewLoginComponent
  },
  {
    path: 'upgrade-plan',
    loadChildren: () => import('@featuremodules/upgrade-plan-module/upgrade-plan-module.module').then(m => m.UpgradePlanModule)
  },
  {
    path: 'tnc-pro',
    component: TncProComponent
  },
  {
    path: 'tnc-store',
    component: TncStoreComponent
  },
  // {
  //   path: 'ref',
  //   component: BeingReferredComponent
  // },
  {path: '**', redirectTo: '/home'}
];

@NgModule({
  imports: [RouterModule.forRoot(rootRouterConfig, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

//
//
