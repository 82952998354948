<div class="thankyouArea" *ngIf="!showCalendar">
  <div class="topPart">
    <div class="topIcon"><span><em class="icon-right"></em></span></div>
    <ng-container *ngIf="!this.phaseService.IsFromFreemium">
      <h3>Congratulations!</h3>
      <p>Your new store is on its way to you.</p>
    </ng-container>
    <ng-container *ngIf="this.phaseService.IsFromFreemium">
      <h3>Congratulations!</h3>
      <p>Your new store is on its way to you.</p>
    </ng-container>
    <h4 *ngIf="(getTransactionID() && (getTransactionID().length > 0))">Transaction Reference no: {{getTransactionID()}}</h4>
    <div class="downloadArrow">
      <em class="icon-rightarrow"></em>
    </div>
  </div>

  <div class="bottomPart" *ngIf="this.phaseService.IsFromFreemium">
    <ul>
      <li><em class="icon-right"></em> Go check your email for confirmation of your new store</li>
      <li><em class="icon-right"></em> In 30 minutes, you’ll get admin panel access – to update your store and monitor its progress</li>
      <li><em class="icon-right"></em> Add your branding, upload your products – and watch your business grow! </li>
    </ul>
    <button type="button" class="buttonStyle" (click)="getStartedForFreemium()">Learn more about my store</button>
  </div>

  <div class="bottomPart" *ngIf="!this.phaseService.IsFromFreemium">
    <h3>We’re busy matching you with the perfect Product Expert, who’ll be on hand to help you with:</h3>
    <ul>
      <li><em class="icon-right"></em> Helping you bring your brand to life</li>
      <li><em class="icon-right"></em> Making sure your Buildcard™ is delivered on time</li>
      <li><em class="icon-right"></em> Supporting you all the way through to a successful launch</li>
      <li *ngIf="smbUser"><em class="icon-right"></em> You will shortly receive a link to activate your Microsoft 365 account via email</li>
    </ul>
    <button type="button" class="buttonStyle progressButton" [ngClass]="{'slideRight' : scheduleCallWaiting}" [disabled]="scheduleCallWaiting" (click)="scheduleExertCall()">
      <span class="btnText" *ngIf="scheduleCallWaiting">Book my kick-off call</span>
      <span class="btnText" *ngIf="!scheduleCallWaiting">Schedule a Kick-off call</span>
      <span class="progressBar step1" [ngClass]="{'step2' : progressBarStep, 'step3' : !scheduleCallWaiting}"></span>
    </button>
    <!-- <span class="schedulerMsg" *ngIf="scheduleCallWaiting"><strong><i>{{schedulerWaitingMsg}}</i></strong></span> -->
  </div>
</div>
<!-- SMB user flow -->
<!-- <div class="thankyouArea smbUserFlow" *ngIf="!showCalendar && smbUser">
  <div class="topPart">
    <p>This payment wass successful, we'll send your receipt to the email on your account.</p>
    <p>Check your email for your Microsoft office subscription.</p>
  </div>
  <div class="transactionId">
    Transaction Id: <span class="tId">{{getTransactionID()}}</span>
  </div>
  <div class="bottomPart" *ngIf="!this.phaseService.IsFromFreemium">
    <h3>Access your store to:</h3>
    <ul>
      <li>Finalise the branding and overall customisation.</li>
      <li>Build your product's inventory.</li>
      <li>Get ready to start selling.</li>
    </ul>
    <button type="button" class="buttonStyle" >Access store</button>
  </div>
</div> -->
<!-- SMB user flow end -->

<div class="calenderArea" *ngIf="showCalendar">
  <div class="shedulerPopupHolder"><iframe frameborder="0" [src]="schedulerURL"></iframe></div>
  <div class="bottomLinkText" (click)="closeSchedulerPopup()" *ngIf="showTextExceptINR()">
    You want to be billed as a company, so we need extra tax info from you. To avoid any delay – see what we need in your dashboard and add it now.
  </div>
  <div class="bottomLinkText" (click)="closeSchedulerPopup()" *ngIf="showTextForINR()">
    To ensure you are billed correctly, we need extra tax info from you. To avoid any delay – see what we need in your dashboard and add it now.
  </div>
</div>

<div class="thankyouArea hide">
  <div class="sectionDotloader">
    <div class="dot-flashing"></div>
  </div>
</div>

<div class="commonPopUp active" *ngIf="phaseService.showCallBookedSuccess">
  <div class="popOverlay" (click)="closeSpecModal()"></div>
  <div class="popHolder bookedcallPopup">
    <div class="closePopUp"><span class="icon-cancel" (click)="closeSpecModal()"></span></div>
    <div>
      <div class="topIcon"><span><em class="icon-right"></em></span></div>
      <h3>Your call is booked</h3>
      <p>Your call is scheduled on <span>{{dateString}}</span> at <span>{{this.startTimeSlot}}
      -{{this.endTimeSlot}}</span>, we'll send the details on your email address.</p>
      <button type="button" class="goButton" (click)="gotoDashboard()">Go to Dashboard</button>
    </div>
  </div>
</div>

