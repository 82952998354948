import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {finalize} from 'rxjs/operators';
import {environment} from '@env/environment';
import {Subscription} from 'rxjs/Rx';

@Component({
  selector: 'app-prototype-cards',
  templateUrl: './prototype-cards.component.html',
  styleUrls: ['./prototype-cards.component.scss']
})
export class PrototypeCardsComponent  {

  @Input() showRecentProgress: boolean;
  @Output() pshowCards = new EventEmitter();
  loaderCount = 0;
  @Input('showMoreIndex') showMoreIndex;
  @Input() showRecentPrototype: boolean;
  showDummyLoader: boolean;
  searchSubscription: Subscription;
  isApiSuccess = false;

  constructor(public dataService: DataService, public phaseService: PhaseService, public apiRequest: ApiRequests) { }


  getPrototypeCards() {
    return this.dataService.prototypeCards;
  }

  viewProgress(card) {
    window.open(this.dataService.homePageData.builder_partner.builder_now_url + '/' + card.id, '_blank', "noopener");
  }

  showCards(type) {
    this.pshowCards.emit(type);
  }

  onScroll(cardCount: number) {
    this.showDummyLoader = true;
    if (this.showMoreIndex !== 0 && window.pageYOffset > 0) {
      if (this.dataService.loadMore) {
        if (this.dataService.isScrolled) {
          if (cardCount > 10) {
            let pageNo = 0;
            if (this.phaseService.buildCardPage[this.showMoreIndex]) {
              pageNo = (this.phaseService.buildCardPage[this.showMoreIndex] > 0) ? this.phaseService.buildCardPage[this.showMoreIndex] : 0;
            }
            this.loaderCount = (((pageNo + 1) * 10) >= cardCount) ? 0 : (4 - (((pageNo + 1) * 10) % 4));
          }
          this.dataService.isScrolled = false;
          this.phaseService.buildCardPage[this.showMoreIndex]++;
          this.apiRequest.fetchBuildCards(this.phaseService.buildCardPage[this.showMoreIndex],
            this.phaseService.perPageCardCount, this.phaseService.currentSelectedBuildCardStatus).pipe(
            finalize(() => {
              this.loaderCount = 0;
            }))
            .subscribe(
              () => {
                this.dataService.isScrolled = true;
              }
            );
        }
      }
    }
  }

  getBuildCardsWithSearch(clearSearch?) {
    let perPage;
    if (clearSearch) {
      this.dataService.searchText = '';
    } else {
      perPage = this.phaseService.perPageCardCount;
    }
    this.phaseService.buildCardPage[this.showMoreIndex] = 0;
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    this.isApiSuccess = false;
    this.dataService.prototypeCards = [];
    this.searchSubscription = this.apiRequest.fetchBuildCards(0, perPage, this.phaseService.currentSelectedBuildCardStatus).pipe(
      finalize(() => {
        this.loaderCount = 0;
      }))
      .subscribe(
        () => {
          this.dataService.isScrolled = true;
          this.isApiSuccess = true;
        }
      );
  }
}
