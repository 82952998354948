import {Constants} from '@shared/utils/constants';
import {GenericService} from './generic.service';
import {catchError, map} from 'rxjs/internal/operators';
import {Injectable} from '@angular/core';
import {HttpRequests} from '@shared/services/http-requests.service';
import {Observable} from 'rxjs';
import {environment} from '@env/environment';

@Injectable()
export class BuildCardService {
  public buildCards: Array<any> = [];
  public currentBuildCard: any;
  public totalPages: number;
  public paymentDetails: any;

  constructor(private _http: HttpRequests,
              private _genericService: GenericService) {}

  public getBuildCards(page: number, count: number, query?: string): Observable<any> {
    let URI = '';
    if (query) {
      URI = environment.API_URL + Constants.apiEndPoints.payment_dashboard_buildcards + '/search?query=' + query + '&page=' + page + '&per_page=' + count;
    } else {
      URI = environment.API_URL + Constants.apiEndPoints.payment_dashboard_buildcards + '?page=' + page + '&per_page=' + count;
    }
    return this._http.get(URI, this._genericService.getRequestOptionArgs()).pipe(
      map(
        response => {
          this.totalPages = response.total_pages;
          return response;
        }
      ), catchError(error => this._genericService.handleError(error))
    );
  }

  public getBuildCardData(id: string): Observable<any> {
    const URI: string = environment.API_URL + Constants.apiEndPoints.payment_dashboard_buildcards + '/' + id;

    return this._http.get(URI, this._genericService.getRequestOptionArgs()).pipe(
      map(
        response => {
          this.currentBuildCard = response;
          return response;
        }
      ), catchError(error => this._genericService.handleError(error))
    );
  }

  public getPaymentDetails(buildCardId: string): Observable<any> {
    const URI: string = environment.API_URL + Constants.apiEndPoints.payment_dashboard_buildcards + '/' + buildCardId + '/installments';

    return this._http.get(URI, this._genericService.getRequestOptionArgs()).pipe(
      map(
        response => {
          this.paymentDetails = response;
          return response;
        }
      ), catchError(error => this._genericService.handleError(error))
    );
  }

  public getBuildCardCurrency(): string {
    return this.currentBuildCard.currency.symbol;
  }
}
