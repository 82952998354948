<div class="partnerWelcome">
  <div class="partnerWelcomeText">
    <h2>Welcome Partner!</h2>
    <h3>You Think It. We Build It.</h3>
    <p>Welcome to #BuilderGeneration. We turn great ideas into amazing apps. Come join the #BuilderGeneration at
      engineer.ai</p>
    <button type="button" (click)="showSignupPopup()">SignUp as Partner</button>
  </div>
  <div class="partnerWelcomeImg">
    <img src="../../../../../../assets/images/partnerWelcome.png" alt=""/>
  </div>
</div>
