
/**
 * Created by Rakesh on 23/05/19.
 */

export class InviteModel {
  email: string;
  expires_at: string;
  id: number;
  status: string;
  user_name: string;
}
