import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import {environment} from '@env/environment';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {AppDataService} from '@rootmodule/app-data.service';
@Component({
  selector: 'app-upgrade-payment-summary',
  templateUrl: './upgrade-payment-summary.component.html',
  styleUrls: ['./upgrade-payment-summary.component.scss']
})
export class UpgradePaymentSummaryComponent implements OnInit {
  @ViewChild('iframe') iframe: ElementRef;
  isLoading = true;
  SmbUser : boolean;
  constructor(public appDataService: AppDataService,public phaseService: PhaseService, public dataService: DataService,public apiRequest: ApiRequests) {
    this.dataService.currentPage = 'upgradePlan';
  }

  ngOnInit(): void {
    if (!this.phaseService.IsFromFreemium && this.dataService.user && this.dataService.user.currency.code == 'INR') {
      this.SmbUser = true;
    }
    this.isLoading = true;
    if (!this.dataService.user) {
      if (this.appDataService.urlParameters.upgrade_to_starter) {
        window.location.href = environment.FE_URL + '/home?redirection_url=' + 'upgrade?upgrade_to_starter=true&uniq_code=' + this.appDataService.urlParameters.uniq_code;
      }else{
        window.location.href = environment.FE_URL + '/home?redirection_url=' + 'upgrade?upgrade_to_store=true&uniq_code=' + this.appDataService.urlParameters.uniq_code;
      }
    }else if (this.dataService.user && this.dataService.user.authtoken) {
      this.dataService.showHideLoader(true);
      this.getBuildCardData(this.appDataService.urlParameters.uniq_code);
    }
  }


  getBuildCardData(uniqCode) {
    this.apiRequest.fetchSingleBuildCardData(uniqCode).subscribe((data: any) => {
      if (this.appDataService.urlParameters.upgrade_to_starter) {
        this.apiRequest.upgradeToStarter(uniqCode,this.appDataService.urlParameters.upfront).subscribe((res: any) => {
          this.phaseService.installmentID = res.data.id
          this.phaseService.depositAmount = res.data.attributes.installment_amount;
          this.getPaymentUrl(res.data.attributes);
        });
      }else if (this.appDataService.urlParameters.upgrade_to_store) {
        this.apiRequest.upgradeToStore(uniqCode,this.appDataService.urlParameters.upfront).subscribe((res: any) => {
          this.getPaymentUrl(res.data.attributes);
          this.phaseService.installmentID = res.data.id
          this.phaseService.depositAmount = res.data.attributes.installment_amount;
        });
      }
    });
  }

  getPaymentUrl(data){
    this.phaseService.inProgressBuildCardId = data.payment_data.build_card_id;
    let paymentData: any = {
      'apptoken': environment.PAYMENT_APP_TOKEN,
      'payment_data': {
        // 'amount': parseInt(this.phaseService.paymentData.pending_installment.installment_amount)+ parseInt(this.phaseService.paymentData.pending_installment.tax_amount),
        'billing_address_attributes': {
          'billing_address': this.dataService.user.address.address,
          'billing_city': this.dataService.user.address.city,
          'billing_name': this.dataService.user.address.first_name + ' ' + this.dataService.user.address.last_name,
          'billing_state': this.dataService.user.address.state,
          'billing_tel': this.dataService.user.address.phone,
          'billing_zip': this.dataService.user.address.pincode
        },
        'billing_entity': this.dataService.user.billing_entity,
        'capture': 'true',
        'card_notify_url': environment.API_URL + 'payment_billing/builder_card_owners/card_status',
        'currency': (this.dataService.buildCardData && this.dataService.buildCardData.id) ? this.dataService.buildCardData.currency.code : this.dataService.user.currency.code,
        'description': data.payment_data.description,
        'email': this.dataService.user.email,
        'frontend_redirect_url': '',
        'payment_gateway': this.dataService.user && this.dataService.user.currency.code === 'INR' ? 'ccavenue' : 'stripe',
        'payment_mode': 'complete',
        'payment_notify_url': environment.API_URL + 'payment_billing/installments/status',
        'redirect_billing_details': `${window.location.origin}/buildcard-payment/${this.dataService.buildCardData.uniq_code}/billingdetails`,
        'redirect_dashboard_url': window.location.origin + '/dashboard',
        'redirect_payment_plan': `${window.location.origin}/buildcard-payment/${this.dataService.buildCardData.uniq_code}/paymentplan`,
        'redirect_url': '',
        'source': data.payment_data.source,
        'installment_type': data.payment_data.installment_type,
        'build_card_id': data.payment_data.build_card_id,
        'build_card_name': data.payment_data.build_card_name,
        'frequency': data.payment_data.frequency,
        'installment_amount': data.payment_data.installment_amount,
        'installment_count': data.payment_data.installment_count,
        'source_model_guid': data.id,
        'source_model_name': 'Installment',
      }
    };
    this.apiRequest.sendPaymentDetailsAndPoll(paymentData).subscribe(res => {
      this.phaseService.startPollingForPayment();
      this.iframe.nativeElement.setAttribute('src', environment.PAYMENT_URL + this.dataService.paymentUrl);
      document.querySelector('#myframe').addEventListener('load', ev => {
        this.isLoading = false;
        this.dataService.showHideLoader(false);
      });
    });
  }

}
