import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Angulartics2Segment } from "angulartics2/segment";
import { PhaseService } from "@shared/services/phase.service";
import { DataService } from "@shared/services/data.service";
import { ApiRequests } from "@shared/services/api-requests.service";
import { GenericService } from "@featuremodules/payment-dashboard-module/services/generic.service";
import { PartnerDashboardDataService } from "@featuremodules/partner-dashboard-module/Utils/partner-dashboard-data.service";
import { CurrencyModel } from "@models/currency-model/currency.model";
import { Constants } from "@shared/utils/constants";
import { PartnerDashboardApiService } from "@featuremodules/partner-dashboard-module/Utils/partner-dashboard-api.service";
import { UtilityService } from "@shared/services/utility.service";
import { AppDataService } from "@rootmodule/app-data.service";
import { CommonService } from "@shared/services/common.service";
import { Intercom } from "ng-intercom";
import { BuildCardModel } from "src/app/shared/shared-module/models/build-card-model/build-card.model";
import { HomeTemplateService } from "@shared/services/home-template.service";
import { HeaderService } from "@shared/services/header.service";
import { ReferralService } from "@shared/services/referral.service";
import moment from "moment";
import { PaymentBuilderService } from "@featuremodules/payment-builder-module/payment-builder.service";

@Component({
  selector: "headerpart",
  templateUrl: "header.component.html",
  styleUrls: ["header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public weeks;
  showOffers = true;
  // public el;
  //public renderer;
  public el;
  public showBar;
  public topNavPhases;
  public currentActive;
  /*public selectedCurrency;*/
  public currentUrl = "";
  public openSideNav = false;
  public logoFlag = false;
  public isFeaturesPage = false;
  @ViewChild("costPerWeekButton") public costPerWeekWrapper;
  @ViewChild("priceSidebar") public sideBarWrapper;
  @ViewChild("breadCrumb") public breadCrumbWrapper;
  @ViewChild("countHolder") public countHolder;
  @Output("offersSection") offersSection = new EventEmitter();
  public showPlatformPopup: boolean;
  showDiscardAdvancedPopup: boolean;
  showHamburgerBlock = false;
  showUserBlock = false;
  innerWidth = 0;
  showFeatureOverLoading = "protoType";
  // showChat = true;
  editProjectName = false;
  hoveredCurrencyIndex = -1;
  showCurrencyInCaseOfCloning = false;
  searchTextValidation = false;
  showDescriptions = {
    isLiveDemoDesc: false,
    isSpecCallDesc: false,
    isLiveChatDesc: false,
  };
  /*@ngInject*/

  //noinspection JSDeprecatedSymbols
  constructor(
    public appDataService: AppDataService,
    public homeTemplateService: HomeTemplateService,
    public phaseService: PhaseService,
    public dataService: DataService,
    public utilityService: UtilityService,
    private cdRef: ChangeDetectorRef,
    // el: ElementRef,
    // renderer: Renderer2,
    el: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    public analyticsSegment: Angulartics2Segment,

    public apiRequest: ApiRequests,
    public genericService: GenericService,
    private _partnerApiRequest: PartnerDashboardApiService,
    public partnerDataService: PartnerDashboardDataService,
    private commonService: CommonService,
    public headerService: HeaderService,
    public referralService: ReferralService,
    private paymentBuilderService: PaymentBuilderService
  ) {
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    this.dataService = dataService;
    this.weeks = this.phaseService.price;
    // this.el = el;
    this.renderer = renderer;
    this.el = el;
    this.showBar = false;
    this.topNavPhases = this.phaseService.topNavPhases;
    this.currentActive = this.phaseService.currentActive;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        this.isFeaturesPage = this.currentUrl === "/feature";
      }
    });

    // window.addEventListener('MyEventType', function (evt: any) {
    //   this.showChat = false;
    // }, false);
  }

  public ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.logoFlag = this.phaseService.breadcrumb_links.length > 0;
    if (
      this.dataService.expertImages &&
      this.dataService.expertImages.length > 0
    ) {
      let randomNum = this.dataService.getRandomValueForList(
        this.dataService.expertImages
      );
      this.dataService.expertIcon = this.dataService.expertImages[randomNum];
    }
  }

  onResize(event) {
    this.innerWidth = event.target.innerWidth;
  }

  showHideNeedHelp() {
    return this.innerWidth < 768 &&
      !(
        this.dataService.currentPage === "paymentPlan" ||
        this.dataService.currentPage === "billingDetails" ||
        this.dataService.currentPage === "payConfirm"
      )
      ? true
      : false;
  }

  public ngOnDestroy() {
    this.dataService.homapageDataSubject.unsubscribe();
  }

  public showOptionList() {
    this.phaseService.trackEvent(this.analyticsSegment, "profile_icon_clicked");
    this.phaseService.showList = !this.phaseService.showList;
  }

  public hideOptionList() {
    this.phaseService.hideOptionList();
  }

  public updateShowNav() {
    this.phaseService.showNav = !this.phaseService.showNav;
  }

  public performUserActions(option) {
    this.headerService.performUserActions(option);
    this.phaseService.isShowDashboardMenuForMobileOrTablet = false;
  }

  clearBuildCard() {
    this.headerService.clearBuildCard();
  }

  public logoutUser() {
    this.headerService.logoutUser();
  }

  public homeButtonClick() {
    // if (this.appDataService.urlParameters.isCustomPrototype) {
    //   return false;
    // }
    this.closeBar();
    this.closeHeaderPopups();
    this.homeTemplateService.clearSearch();
    this.homeTemplateService.clearFilter("all");
    this.homeTemplateService.initialLoader = false;
    // this.homeTemplateService.tempateNatashaChatList = {};
    // this.phaseService.natashaGroupIdentifier = '';
    // this.phaseService.natashaGroupChatId = 1;
    this.phaseService.natashaGroupIdText = "natashaGroupId";
    this.phaseService.natashaMobileGroupIdentifier = "";
    this.phaseService.natashaMobileGroupChatId = 1;
    this.homeTemplateService.mobileTempateNatashaChatList = {};
    this.phaseService.natashaChatsList = {};
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      "builder_logo_clicked",
      {
        referrer_url: "",
        referrer_trigger: "",
        user_id: this.dataService.user?.id,
        screen_source: this.dataService.currentPage,
      }
    );

    if (
      this.dataService?.user &&
      !this.appDataService.urlParameters?.cpe_user &&
      (this.dataService.currentPage == "features" ||
        this.dataService.currentPage == "delivery")
    ) {
      this.phaseService.gotoCreateNew = true;
      this.phaseService.showSaveProgress = true;
    } else {
      if (this.dataService.shareUrlData) {
        this.dataService.shareUrlData.curPage = "";
      }
      this.dataService.landingPage = "";
      this.dataService.isFromViewPrototype = false;
      this.phaseService.buildCardEditMode = false;
      this.phaseService.reset();
      this.phaseService.isBuildCardSetup = false;
      this.phaseService.inProgressFlow = false;
      this.dataService.totalSelectedFeatures = 0;
      this.dataService.normalFlow = true;
      if (this.appDataService.urlParameters.cpe_user) {
        this.appDataService.urlParameters.cpe_user = null;
        this.appDataService.urlParameters.featureRequest = null;
      }
      this.clearBuildCard();
      this.router.navigate(["home"]);
    }
  }

  isDisableSpecCall() {
    return this.dataService.buildCardData.spec_call_scheduled ? false : true;
  }

  public closeHeaderPopups() {
    this.dataService.showCurrencyPopup = false;
    this.phaseService.showList = false;
  }

  public closeBar() {
    if (
      !this.phaseService.isManualPromotionApplied &&
      !this.dataService.homePageData.promotion &&
      !this.dataService.manualPromotion
    ) {
      this.phaseService.promotionValid = false;
      this.phaseService.manualPromoCode = null;
      this.phaseService.promotionError = null;
    }
    this.searchTextValidation = false;
    this.phaseService.openSideNav = false;
  }

  public selectCurrency(i) {
    this.phaseService.selectCurrency(i);
    if (this.dataService.user && this.dataService.checkIfCPEUser()) {
      const selectedCurrency = this.dataService.homePageData.currencies[i].id;
      this.apiRequest.updateUserProfile({ currency_id: selectedCurrency }, '').subscribe((resp) => {});
    }
    let type = "single";
    if (
      this.homeTemplateService.activeFilters.category.length > 0 ||
      this.homeTemplateService.activeFilters.price.length > 0
    ) {
      type = "filter_sort";
    } else if (this.homeTemplateService.sortingOption) {
      type = "sorting";
    }
    this.homeTemplateService.clearFilter("price", true);
    this.homeTemplateService.clearSearch();
    this.homeTemplateService.currencyChange.next({
      currencyId: this.dataService.homePageData.currency.id,
      type: type,
      filters: this.homeTemplateService.activeFilters,
      sorting: this.homeTemplateService.sortingOption,
    });
  }

  public showPopUp() {
    this.phaseService.showCurrencyDropDown();
  }

  public closePopup() {
    this.dataService.showCurrencyPopup = false;
  }

  public commonAuthScreen(signUpClickedSrc?) {
    this.dataService.signupSrcName = signUpClickedSrc;
    this.phaseService.openCommonAuth = true;
    this.phaseService.showSignin = true;
    this.phaseService.openCommonAuth = true;
  }

  public showSignUpForPartner() {
    this.phaseService.countryNameCode = this.dataService.country_code
      ? this.dataService.country_code.toLowerCase()
      : "";
    this.phaseService.signupNextStep = true;
    this.dataService.signupSrcName = "default_sign_up";
    if (this.dataService.homePageData?.block_sign_up) {
      this.phaseService.showSignin = true;
      this.phaseService.showRegister = false;
    } else {
      this.phaseService.showRegister = true;
      this.phaseService.showSignin = false;
    }
    this.phaseService.openCommonAuth = true;
  }

  public showSingInSignup(isSignIn) {
    this.phaseService.showclose = false;
    this.phaseService.showForgotPassword = false;
    this.phaseService.showNav = false;

    if (isSignIn) {
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        "sign_in_clicked",
        {
          screen_source: "Header",
          user_id: this.dataService.user?.id,
        }
      );
      this.phaseService.showSignin = true;
      this.phaseService.openCommonAuth = true;
      this.phaseService.showRegister = false;
    } else {
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        "sign_up_clicked",
        {
          user_id: this.dataService.user?.id,
        }
      );
      this.phaseService.showRegister = true;
      this.phaseService.openCommonAuth = true;
      this.phaseService.showSignin = false;
    }
  }

  public isPromotionValid() {
    this.phaseService.promotionValidStatus =
      !this.phaseService.isBuildCardSetup &&
      (this.dataService.homePageData.promotion ||
        this.dataService.manualPromotion) &&
      this.phaseService.promotionValid;
    return this.phaseService.promotionValidStatus;
  }

  public getCurrencySymbol() {
    return this.getCurrency().symbol;
  }

  public getCurrencyCode() {
    return this.phaseService.getCurrencyCode();
  }

  public getCurrency() {
    return this.phaseService.getCurrency();
  }

  public getCurrencies() {
    return this.phaseService.getCurrencies();
  }

  public isCurrentCurrency(currency: CurrencyModel) {
    return this.phaseService.isCurrentCurrency(currency);
  }

  public isShowCurrencyDropdown() {
    return this.dataService.isShowTemplateModal
      ? false
      : this.phaseService.isShowCurrencyDropdown();
  }

  public getUser() {
    return this.phaseService.getUser();
  }

  checkForCPEUserAndCurrentPage() {
    return this.phaseService.checkForCPEUserAndCurrentPage();
  }

  public getAdminUser() {
    return this.genericService.getAdminUser();
  }

  public isDashBoardShow() {
    return this.phaseService.isDashboardShow;
  }

  public getCurrencyIcon() {
    return this.dataService.homePageData.currency.icon_image_file_url;
  }

  public getUserCurrencyIcon() {
    return this.getUser().currency.icon_image_file_url;
  }

  public getLocaledPrice(price: any) {
    return this.dataService.getLocaledPrice(price);
  }

  public isShowCurrency() {
    return this.phaseService.showCurrency;
  }

  public isShowList() {
    return this.phaseService.showList;
  }

  public getSelectedSpecing() {
    return this.phaseService.selectedHash.specing;
  }

  public setSelectedSpecing() {
    this.phaseService.selectedHash.specing = false;
  }

  public getSpecingPrice() {
    return this.phaseService.specing_price;
  }

  public getSelectedSnM() {
    return this.phaseService.selectedHash.support_maintenance_weeks;
  }

  public getSnMPrice() {
    return this.phaseService.getSupportMaintenancePrice();
  }

  public getPromotionMessage() {
    return (
      (this.dataService.homePageData.promotion &&
        this.dataService.homePageData.promotion.banner) ||
      (this.dataService.manualPromotion &&
        this.dataService.manualPromotion.banner) ||
      this.phaseService.urlBanner
    );
  }

  public showFeatureDropdown(flag) {
    this.phaseService.showPromotionDropdown = flag;
  }

  public selectSpecing() {
    this.phaseService.getHelpClicked = false;
    this.updateShowNav();
    this.phaseService.openMeetNoeModal();
    this.phaseService.getHelpClicked = true;
  }

  public fetchPromotionData(code) {
    const promoErrMsg = "Oops! Promo Code entered is Invalid.";
    if (
      this.phaseService.manualPromoCode &&
      this.phaseService.manualPromoCode.length <= 20
    ) {
      this.phaseService.promotionLoader = true;

      this.apiRequest
        .fetchPromotionData(
          code,
          this.phaseService.selectedHash.is_advance,
          this.phaseService.finalPricenWeeks.weeks
        )
        .finally(() => {
          this.phaseService.promotionLoader = false;
        })
        .subscribe(
          (data) => {
            if (
              data.message ||
              (this.phaseService.checkIfNotEntUser() &&
                this.dataService.checkIfBuildCardisinvited() &&
                data.promotion.is_enterprise_promo)
            ) {
              this.phaseService.promotionError = data.message
                ? data.message
                : promoErrMsg;
              data.promotion = null;
              this.dataService.manualPromotion = null;
            }
            if (data.promotion) {
              if (
                this.dataService.currentPage === "templateDetail" &&
                this.phaseService.checkIfRentalPricingModal()
              ) {
                if (data.promotion.type !== "MonthDiscountPromotion") {
                  this.phaseService.promotionLoader = false;
                  this.phaseService.promotionError = promoErrMsg;
                  return;
                }
              }
              this.dataService.manualPromotion = data.promotion;
              this.dataService.manualPromotion.code = code;
              if (data.promotion.expires_at) {
                this.phaseService.promotionExpiryDays =
                  this.phaseService.promotionExpiry(data.promotion.expires_at);
              } else {
                this.phaseService.promotionExpiryDays = 0;
              }
            }
            if (this.dataService.manualPromotion) {
              this.phaseService.promotionValid = true;
              this.phaseService.promotionError = false;
              this.phaseService.promotionApplied = true;
              if (this.phaseService.isValidPromotion()) {
                if (this.dataService.modifiedBuildCardData) {
                  this.dataService.modifiedBuildCardData.promotion.add =
                    data.promotion;
                }
                this.phaseService.isManualPromotionApplied = true;
                this.phaseService.selectedHash.promotion_id =
                  this.dataService.manualPromotion.id;
                this.phaseService.flatPrice =
                  this.dataService.manualPromotion.discount;
                this.phaseService.updatePrice();
                if (
                  this.dataService.manualPromotion.type !==
                  "SpecialEventPromotion"
                ) {
                  this.phaseService.sideNavClose.subscribe((response) => {
                    if (response) {
                      this.phaseService.openSideNav = false;
                    }
                  });
                }
              } else {
                this.dataService.manualPromotion = null;
                this.phaseService.isManualPromotionApplied = false;
                if (this.dataService.user) {
                  this.phaseService.promotionError = Constants.promotionError;
                }
              }
            }
            this.phaseService.trackEventForObject(
              this.analyticsSegment,
              "promotion_applied",
              {
                user_id: this.dataService.user?.id,
                screen_source: this.dataService.currentPage,
                promotion_code: code,
                promotion_status: this.phaseService.promotionApplied,
              }
            );
            this.phaseService.isManualPromotionClicked = true;
          },
          (error) => {}
        );
    }
  }

  public goToPartnerDashboard() {
    this.phaseService.showNav = false;
    this.performUserActions("become-a-partner");
  }

  public navigatePartner() {
    this.dataService.totalSelectedFeatures = 0;
    if (this.phaseService.isFeatureRequest) {
      window.location.href = window.location.origin;
    } else {
      this.router.navigate([""]);
    }
  }

  public removeManualPromotion() {
    this.phaseService.promotionApplied = false;
    this.phaseService.isManualPromotionApplied = false;
    this.phaseService.promotionValid = false;
    this.dataService.manualPromotion = null;
    this.phaseService.manualPromoCode = null;
    this.phaseService.selectedHash.promotion_id = null;
    this.dataService.homePageData.promotion = null;
    this.phaseService.updatePrice();
  }

  public logoutPartner() {
    this.dataService.showHideLoader(true);
    this._partnerApiRequest.logoutPartner().subscribe(
      (data) => {
        this.partnerDataService.removePartnerUser();
        this.dataService.resetPartnerViews();
        this.dataService.showHideViews.welcome = true;
        this.dataService.showHideLoader(false);
        this.appDataService.urlParameters.otp_code = null;
        this.dataService.isSkipDone = false;
      },
      (error) => {
        this.dataService.showHideLoader(false);
      }
    );
  }

  public goToPaymentDashboard() {
    this.router.navigate(["admin-dashboard"]);
  }

  showCreateForOthersPopup() {
    this.phaseService.isShowCreateCardForOthersPopup = true;
  }

  public isEngineerAIBuilder() {
    return this.dataService.isEngineerAIBuilder();
  }

  closePlatformPopup() {
    this.showPlatformPopup = false;
  }

  goToOffers() {
    this.phaseService.offerHeaderEvent.emit(true);
  }

  removeOffers() {
    this.showOffers = false;
    this.offersSection.emit(true);
  }

  public isAutoShowSignupPopup(): boolean {
    if (
      this.phaseService.signupPopup &&
      this.phaseService.checkAutoLoginPopup
    ) {
      this.phaseService.checkAutoLoginPopup = false;
    }
    return this.phaseService.signupPopup;
  }

  public applyPromoMobile() {
    this.phaseService.showMobileClose = true;
    this.phaseService.popupPromocode =
      Constants.promotionCodeType.HomeSignUpPopup;
    this.phaseService.setPromotionObject(this.phaseService.popupPromocode);
    this.phaseService.popupCoupon = true;
    this.phaseService.clickedpopupsignin = true;
  }

  public isShowLoginPopup(signUpClickedSrc?) {
    this.dataService.signupSrcName = signUpClickedSrc;
    this.phaseService.showclose = true;
    this.phaseService.clickedpopupsignin = true;
    this.phaseService.openCommonAuth = true;
    this.phaseService.popupPromocode =
      Constants.promotionCodeType.HomeSignUpPopup;
      if (this.dataService.homePageData?.block_sign_up) {
        this.phaseService.showSignin = true;
        this.phaseService.showRegister = false;
      } else {
        this.phaseService.showRegister = true;
        this.phaseService.showSignin = false;
      }
    this.phaseService.setPromotionObject(
      Constants.promotionCodeType.HomeSignUpPopup
    );
    this.phaseService.hidesignupPopup();
  }

  public hidesignupPopup() {
    this.phaseService.signupPopup = false;
    this.phaseService.clickedsignupPopup = true;
  }

  handleDiscard(isDiscard) {
    if (isDiscard) {
      this.showPlatformPopup = true;
      this.phaseService.selectedHash.is_advance = false;
      this.dataService.homePageData.build_phases.forEach((phase) => {
        if (!/mvp/i.test(phase.title)) {
          phase.platforms = this.phaseService.selectedPlatforms;
          phase.is_parallel_platform = true;
        }
      });
    }
    this.showDiscardAdvancedPopup = false;
  }

  public showMinipopup() {
    return (
      !this.dataService.user &&
      this.dataService.experimentPromotion.mini_popup &&
      this.dataService.experimentPromotion.mini_popup.code &&
      !this.phaseService.noexperimentpromotions
    );
  }

  handleRedirection(redirectToRoute) {
    if (this.dataService.currentPage === "paymentPlan") {
      this.updateInstallmentFrequency();
    } else if (redirectToRoute === "buildCard") {
      this.dataService.buildCardData.build_card_pdf_url = "";
    }
  }

  public redirectToTab(redirectToRoute) {
    if (
      !this.dataService.user.address_disabled &&
      (!this.dataService.user.address ||
        this.paymentBuilderService.checkIfAddressUpdated()) &&
      redirectToRoute === "paymentplan"
    ) {
      return false;
    }
    if (this.dataService.buildCardData?.uniq_code) {
      this.breadcrumbRedirection(redirectToRoute);
      this.handleRedirection(redirectToRoute);
    }
  }

  public breadcrumbRedirection(redirectToRoute) {
    if (redirectToRoute === "payConfirm") {
      this.router.navigate([
        `buildcard-payment/${this.dataService.buildCardData.uniq_code}/` +
          redirectToRoute,
      ]);
    } else if (redirectToRoute === "buildCard") {
      this.router.navigate([
        "build-card/" + this.dataService.buildCardData.uniq_code,
      ]);
    } else {
      this.router.navigate([
        `buildcard-payment/${this.dataService.buildCardData.uniq_code}/` +
          redirectToRoute,
      ]);
    }
  }

  public updateInstallmentFrequency() {
    let installmentType;
    let frequencyType = '';
    if (this.dataService.showMonthly) {
      frequencyType = 'monthly';
    } else if (this.dataService.showWeekly) {
      frequencyType = 'weekly';
    } else if (this.dataService.showUpfront) {
      frequencyType = 'upfront';
    }
    installmentType = { frequency: frequencyType };
    if (
      installmentType.frequency !== this.dataService.buildCardData.frequency
    ) {
      this.apiRequest
        .createUpdateBuildCard(
          installmentType,
          this.phaseService.inProgressBuildCardId,
          "",
          "rename_build_card"
        )
        .subscribe(() => {
          this.phaseService.fetchInstallments();
        });
    }
  }

  public showNeedHelp(pageName) {
    this.phaseService.showTimeKitWindow(true);
  }

  public showHeaderBreadCrumb() {
    const currentPage = this.dataService.currentPage;
    if (
      (currentPage === "home" ||
        currentPage === "apps" ||
        currentPage === "features" ||
        currentPage === "delivery" ||
        (currentPage === "templateDetail" &&
          this.dataService.appDetails.pricing_modal !== "rental_price")) &&
      !this.phaseService.isFeatureRequest
    ) {
      return true;
    } else {
      return false;
    }
  }

  public showMobileSteps() {
    return this.dataService.currentPage === "apps" ||
      this.dataService.currentPage === "delivery" ||
      this.dataService.currentPage === "paymentStatus" ||
      this.isPaymentModule()
      ? true
      : false;
  }

  public getMobileStepIndex() {
    if (this.isPaymentModule()) {
      this.phaseService.mobileTotalSteps = 3;
      switch (this.dataService.currentPage) {
        case "paymentPlan":
          this.phaseService.mobileStepName = "Payment Plan";
          return 1;
        case "billingDetails":
          this.phaseService.mobileStepName = "Billing Detail";
          return 2;
        case "payConfirm":
          this.phaseService.mobileStepName = "Pay Confirm";
          return 3;
        default:
          return "";
      }
    } else {
      if (this.phaseService.showAppInBreadCrumb) {
        this.phaseService.mobileTotalSteps = 4;
        switch (this.dataService.currentPage) {
          case "apps":
            this.phaseService.mobileStepName = "Apps";
            return 1;
          case "features":
            this.phaseService.mobileStepName = "Features";
            return 2;
          case "delivery":
            this.phaseService.mobileStepName = "Delivery";
            return 3;
          case "summary":
            this.phaseService.mobileStepName = "BuildCard";
            return 4;
          case "paymentStatus":
            this.phaseService.mobileStepName = "Confirmation";
            return 5;
          default:
            return "";
        }
      } else {
        this.phaseService.mobileTotalSteps = 3;
        switch (this.dataService.currentPage) {
          case "features":
            this.phaseService.mobileStepName = "Features";
            return 1;
          case "delivery":
            this.phaseService.mobileStepName = "Delivery";
            return 2;
          case "summary":
            this.phaseService.mobileStepName = "BuildCard";
            return 3;
          default:
            return "";
        }
      }
    }
  }

  public isPaymentModule() {
    return (
      this.dataService.currentPage &&
      (this.dataService.currentPage === "paymentPlan" ||
        this.dataService.currentPage === "billingDetails" ||
        this.dataService.currentPage === "payConfirm")
    );
  }

  public isBuildCardModule() {
    return (
      this.dataService.currentPage &&
      (this.dataService.currentPage === "home" ||
        this.dataService.currentPage === "apps" ||
        this.dataService.currentPage === "delivery" ||
        this.dataService.currentPage === "features" ||
        this.dataService.currentPage === "templateDetail")
    );
  }

  public applySelectedPromo(promoCode) {
    this.phaseService.manualPromoCode = promoCode;
    this.getPromotionAttributes();
  }

  public getPromotionAttributes() {
    this.searchTextValidation = false;
    // this.applyPromotionEvents();
    this.dataService.currentPage === "summary"
      ? this.phaseService.applyRemovePromotion(
          this.phaseService.manualPromoCode,
          true
        )
      : this.isPaymentModule()
      ? this.phaseService.applyRemovePromotion(
          this.phaseService.manualPromoCode,
          true,
          true
        )
      : this.fetchPromotionData(this.phaseService.manualPromoCode);
  }

  trackHoverOnNeedHelp(pageName) {
    /*Home,Apps,Features,Delivery,Buildcard,Payment Plan,Billing Details/contract,Confirm and Pay*/
    let eventValue: string;
    switch (pageName) {
      case "delivery":
        eventValue = "Delivery";
        break;
      case "features":
        eventValue = "Feature";
        break;
      case "summary":
        eventValue = "Buildcard";
        break;
      case "paymentPlan":
        eventValue = "Payment Plan";
        break;
      case "billingDetails":
        eventValue = "Billing Details";
        break;
      case "home":
        eventValue = "Home";
        break;
      case "apps":
        eventValue = "Apps";
        break;
      case "payConfirm":
        eventValue = "Contract,Confirm and Pay";
    }
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      "talk_to_experts_hover",
      {
        screen_source: eventValue,
        user_id: this.dataService.user?.id,
      }
    );
  }

  trackIfUserStartedTyping(event) {
    if (event.target.value && event.keyCode === 13) {
      this.saveProjectName(true);
    }
  }

  public saveProjectName(saveProjectName) {
    this.phaseService.currentProjectName = this.parseText(
      this.phaseService.currentProjectName
    );
    if (!saveProjectName) {
      this.phaseService.currentProjectName = "My Builder Project";
    } else {
      this.phaseService.isEditDoneOnBuildCard = true;
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        "build_card_name_saved",
        {
          buildcard_name: this.phaseService.currentProjectName,
        }
      );
    }
    this.phaseService.showProjectNamePopup = false;
    this.phaseService.isNewBuildCard = false;
    // if (this.phaseService.isFromDashBard) {
    this.updateProjectName({ keyCode: 13 });
    // }
    if (this.phaseService.isReadyToProceed) {
      this.dataService.showDeliveryLoading = false;
      this.router.navigate([
        "build-card/" + this.dataService.buildCardData.uniq_code,
      ]);
    }
  }

  hideMeetNowOnboarding() {
    this.appDataService.urlParameters.red = "";
    this.dataService.showMeetNowOverloading = false;
    this.dataService.showMeetNowModel = false;
    if (this.dataService.currentPage === "features") {
      this.dataService.showMeetNowOverloadingOnFeature = false;
    } else {
      this.dataService.showMeetNowOverloadingOnFeature = true;
    }
  }

  getViewPrototypeClicked() {
    return this.dataService.isFromViewPrototype;
  }

  redirectLogicToBuilderNow() {
    this.updateShowNav();
    this.phaseService.viewPrototypeClicked = true;
    this.phaseService.redirectToNowForPrototype("viewPrototype");
  }

  clickingFeatureOverloadng(screenName) {
    this.showFeatureOverLoading = screenName;
  }

  getInstallmentWithTax(installment: any) {
    if (installment) {
      return this.phaseService.getInstallmentWithTax(installment);
    }
  }

  getBillingInfo() {
    this.phaseService.billingInfo.name =
      this.phaseService.billingInfo.firstname +
      (this.phaseService.billingInfo.lastname
        ? " " + this.phaseService.billingInfo.lastname
        : "");
    this.phaseService.billingInfo.address =
      this.phaseService.billingInfo.apartment +
      (this.phaseService.billingInfo.street
        ? " " + this.phaseService.billingInfo.street
        : "");
    return this.phaseService.billingInfo;
  }

  public getDeposit() {
    if (this.dataService.showWeekly) {
      return this.phaseService.weeklyDeposit;
    } else if (this.dataService.showMonthly) {
      return this.phaseService.monthlyDeposit;
    } else {
      return this.phaseService.deposit;
    }
  }

  getSecurityDepositWithTax() {
    if (
      this.phaseService.promotionValid &&
      !this.phaseService.promotionError &&
      this.phaseService.isDiscountApplied() &&
      this.getBuildCardData().promotion.type === "GlobalPromotion" &&
      this.getBuildCardData().promotion.deposit
    ) {
      return (
        this.getBuildCardData().promotion.deposit + this.getDepositTaxAmount()
      );
    } else {
      return this.getInstallmentWithTax(
        this.getPaymentData().pending_installment
      );
    }
  }

  getPaymentData() {
    return this.phaseService.paymentData;
  }

  public getBuildCardData() {
    return this.dataService.buildCardData;
  }

  public getInstallmentWithoutTax(installment: any) {
    if (installment) {
      return Number(installment.installment_amount);
    }
  }

  public getDepositTaxAmount() {
    if (
      this.getBuildCardData().promotion &&
      this.getBuildCardData().promotion.type === "GlobalPromotion" &&
      this.phaseService.isDiscountApplied()
    ) {
      return this.getBuildCardData().currency.tax > 0
        ? this.getBuildCardData().promotion.deposit *
            (this.getBuildCardData().currency.tax / 100)
        : 0;
    } else {
      if (this.dataService.showWeekly && this.phaseService.weeklyDeposit) {
        return this.phaseService.weeklyDeposit.tax_amount;
      } else if (
        this.dataService.showMonthly &&
        this.phaseService.monthlyDeposit
      ) {
        return this.phaseService.monthlyDeposit.tax_amount;
      } else {
        return this.phaseService.deposit && this.phaseService.deposit.tax_amount
          ? Number(this.phaseService.deposit.tax_amount)
          : 0;
      }
    }
  }

  getSecurityDepositTaxAmount() {
    const taxAmount = this.getDepositTaxAmount();
    if (taxAmount > 0) {
      return taxAmount;
    } else {
      return 0;
    }
  }

  getSecurityDepositWithoutTax() {
    if (
      this.phaseService.promotionValid &&
      !this.phaseService.promotionError &&
      this.phaseService.isDiscountApplied() &&
      this.getBuildCardData().promotion.type === "GlobalPromotion" &&
      this.getBuildCardData().promotion.deposit
    ) {
      return this.getBuildCardData().promotion.deposit;
    } else {
      return this.getInstallmentWithoutTax(
        this.getPaymentData().pending_installment
      );
    }
  }

  comingSoonPrototypeVisible() {
    return (
      !this.dataService.showLoader &&
      !this.dataService.showAppDetailsLoader &&
      !this.dataService.showDeliveryLoading &&
      !this.phaseService.showViewPrototype() &&
      (this.dataService.currentPage === "templateDetail" ||
        this.dataService.currentPage === "features" ||
        this.dataService.currentPage === "summary") &&
      this.phaseService.selectedFeatures.length > 0
    );
  }

  isRentalAppPage() {
    return this.currentUrl == "/home" || this.currentUrl == "/"
      ? false
      : this.dataService.appDetails &&
          this.dataService.appDetails.pricing_modal == "rental_price";
  }

  public toggleSelectedAppsById(app: any): void {
    const selectedApp = this.phaseService.selectedApps.find(
      (x) => x.id === app.id
    );
    if (selectedApp) {
      const index = this.phaseService.selectedApps.indexOf(selectedApp);
      this.phaseService.selectedApps.splice(index, 1);
    } else {
      this.phaseService.selectedApps.push(app);
      if (
        this.dataService.buildCardData &&
        !this.dataService.buildCardData.id
      ) {
        if (
          this.dataService.user &&
          this.phaseService.selectedApps[0].pricing_modal === "rental_price"
        ) {
          this.dataService.homePageData.currency =
            this.dataService.user.currency;
        } else if (
          !this.dataService.user &&
          this.phaseService.selectedApps[0].pricing_modal === "rental_price"
        ) {
          this.dataService.homePageData.currency = app.currency;
        }
        this.phaseService.currencyForNow = null;
      }
    }
  }

  hideDynamicPricingPopup() {
    if (this.commonService.isPlatformBrowser) {
      return (
        this.dataService.questionMarkClicked ||
        (this.dataService.showDynamicPriceModal &&
          !localStorage.getItem("show_dynamic_pricing_popup"))
      );
    }
  }

  togglePricingModal() {
    this.dataService.noMorePricingModal = !this.dataService.noMorePricingModal;
    this.dataService.questionMarkClicked = false;
    if (
      !this.dataService.noMorePricingModal &&
      this.commonService.isPlatformBrowser
    ) {
      localStorage.removeItem("show_dynamic_pricing_popup");
    }
    this.dataService.showDynamicPricingModalSetting =
      !this.dataService.noMorePricingModal;
    this.phaseService.applyFeaturePageSettings();
  }

  showDemoCall() {
    this.phaseService.specCallCreationData = null;
    this.phaseService.isDemoCall = true;
    this.showDescriptions.isLiveDemoDesc = false;
    this.phaseService.showRequestDemo();
  }

  showInstantCall() {
    this.headerService.showInstantCall();
  }

  showScheduleCall() {
    if (this.isSpecCallScheduled()) {
      return;
    }
    this.phaseService.isDemoCall = false;
    this.showDescriptions.isSpecCallDesc = false;
    this.headerService.showScheduleCall();
  }

  // showIntercomChat() {
  //   this.analyticsSegment.eventTrack('Live Chat Clicked',{});
  //   this.phaseService.specCallCreationData = null;
  //   const evt = new CustomEvent('MyEventType', {detail: '1234'});
  //   window.dispatchEvent(evt);
  //   this.showChat = false;
  //   setTimeout(()=>{
  //     this.showChat = true;
  //   }, 2000);
  //   this.phaseService.showNav = false;
  // }

  showFeatureTutorial() {
    this.dataService.showFeatureTour = true;
    this.phaseService.showNav = false;
  }

  closeTutorial(isGotItClicked?) {
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      "tutorial_closed",
      {}
    );
    this.phaseService.closeTutorial(isGotItClicked);
  }

  applyPromotion() {
    this.phaseService.showNav = false;
    this.phaseService.promoError = null;
    this.phaseService.promotionError = null;
    this.phaseService.openSideNav = true;
    this.phaseService.fetchPromotionList();
  }

  showUpdatedList(event?) {
    if (this.phaseService.manualPromoCode?.length > 2) {
      this.phaseService.filteredPromotionList = this.dataService.promotionList;
      this.phaseService.filteredPromotionList =
        this.phaseService.filteredPromotionList.filter((list) =>
          list.code.includes(this.phaseService.manualPromoCode)
        );
    }
    if (this.phaseService.manualPromoCode?.length == 0) {
      this.phaseService.filteredPromotionList = this.dataService.promotionList;
    }
    this.searchTextValidation = false;
    this.validatePromoText(event);
  }

  validatePromoText(event?) {
    if (event && event.keyCode === 13) {
      if (this.phaseService.manualPromoCode?.length < 3) {
        this.searchTextValidation = true;
      } else {
        this.searchTextValidation = false;
      }
    }
  }

  gotToHome() {
    this.router.navigate(["home"]);
  }

  showProjectNameEditBox() {
    this.editProjectName = !this.editProjectName;
  }

  parseText(text) {
    let html = text;
    let div = document.createElement("div");
    div.innerHTML = html;
    return div.innerText;
  }

  updateProjectName(event?) {
    if (event && event.keyCode === 13) {
      this.phaseService.selectedHash.project_name = this.parseText(
        this.phaseService.selectedHash.project_name
      );
      if (
        this.phaseService.selectedHash.project_name === "My Builder Project" &&
        this.phaseService.currentProjectName
      ) {
        this.phaseService.selectedHash.project_name =
          this.phaseService.currentProjectName;
      }
      const updatedSelectedHash = this.phaseService.updatedBuildCardDataName(
        ["project_name"],
        this.phaseService.selectedHash
      );
      this.apiRequest
        .createUpdateBuildCard(
          updatedSelectedHash,
          this.phaseService.inProgressBuildCardId,
          "",
          "rename_build_card"
        )
        .subscribe((data: any) => {
          this.editProjectName = false;
          const card = this.dataService.buildCardData;
          if (
            this.dataService.isUnpaidCard() &&
            this.dataService.buildCardData.donation_opted === null
          ) {
            this.dataService.buildCardData.donation_opted = true;
          }
          if (!card.donation_amount && card.status === "card_completed") {
            this.dataService.calculateDonationAmount(5);
          }
        });
    }
  }

  cloneBuildCard() {
    if (this.dataService.buildCardData.currency) {
      this.phaseService.cardIdToBeCloned =
        this.dataService.buildCardData.uniq_code;
    }
    if (
      this.dataService.checkIfCPEUser() &&
      !this.phaseService.isCpeCloningCard
    ) {
      this.phaseService.isCpeCloningCard = true;
      return;
    }
    this.closeCurrencyPopup();
    this.dataService.showHideLoader(true);
    const curr = this.phaseService.clonedCardCurrency
      ? this.phaseService.clonedCardCurrency.id
      : "";
    this.apiRequest
      .cloneBuildCard(this.phaseService.cardIdToBeCloned, curr)
      .subscribe(
        (resp) => {
          this.dataService.showHideLoader(false);
          window.open(
            `${window.location.origin}/build-card/${resp.data.attributes.uniq_code}`
          );
        },
        (error) => {
          this.dataService.showHideLoader(false);
          this.commonService.showError("Something Went Wrong.");
        }
      );
  }

  setCurrencyForDuplicateCard(currency) {
    this.phaseService.clonedCardCurrency = currency;
    this.showCurrencyInCaseOfCloning = false;
  }

  setActiveClass(i) {
    this.hoveredCurrencyIndex = i;
  }

  showCurrCloningDropDown() {
    this.showCurrencyInCaseOfCloning = !this.showCurrencyInCaseOfCloning;
  }

  closeCurrencyPopup() {
    this.showCurrencyInCaseOfCloning = false;
    this.phaseService.isCpeCloningCard = false;
  }

  getAppliedPromotionCode() {
    if (this.phaseService.isDiscountApplied()) {
      return this.phaseService.finalPromoApplied.toUpperCase();
    } else {
      return this.phaseService.manualPromoCode;
    }
  }

  checkIfNeedToShowPromotion() {
    return (
      (this.dataService.currentPage === "delivery" ||
        this.dataService.currentPage === "summary" ||
        this.dataService.currentPage === "paymentPlan") &&
      !this.phaseService.checkIfRentalPricingModal() &&
      !this.phaseService.isDiscountApplied()
    );
  }

  closePopUpBlockerModal() {
    this.phaseService.isPopUpBlockerEnabled = false;
  }

  checkIfBuildCardForRapidPrototype() {
    if (
      this.phaseService.selectedPhases &&
      this.phaseService.selectedPhases.length === 1 &&
      this.phaseService.selectedPhases[0].title === "Professional Prototype"
    ) {
      return false;
    }
    return true;
  }

  updateShowDashboardMenuForMobileOrTablet() {
    this.phaseService.isShowDashboardMenuForMobileOrTablet =
      !this.phaseService.isShowDashboardMenuForMobileOrTablet;
    if (this.phaseService.isShowDashboardMenuForMobileOrTablet) {
      this.renderer.addClass(
        document.getElementById("app-component"),
        "hide-scroll-bar"
      );
    } else {
      this.renderer.removeClass(
        document.getElementById("app-component"),
        "hide-scroll-bar"
      );
    }
  }

  openReferralComponent() {
    this.referralService.showMainReferralComponent = true;
    this.phaseService.trackEvent(
      this.analyticsSegment,
      "refer_cta_clicked",
      "user_id",
      this.dataService.user.id
    );
  }

  isSpecCallScheduled() {
    return this.dataService.buildCardData.id &&
      this.dataService.buildCardData.spec_call_scheduled
      ? true
      : false;
  }

  getSpeccall(type) {
    const specCall: any = this.dataService.buildCardData.spec_call_scheduled;
    if (specCall) {
      return type == "date"
        ? moment(specCall.time).format("DD-MM-YYYY")
        : specCall[type];
    }
  }

  toggleDescriptions(descField) {
    switch (descField) {
      case "liveDemo":
        this.showDescriptions.isLiveDemoDesc = true;
        this.showDescriptions.isSpecCallDesc = false;
        this.showDescriptions.isLiveChatDesc = false;
        break;
      case "specCall":
        this.showDescriptions.isSpecCallDesc = true;
        this.showDescriptions.isLiveDemoDesc = false;
        this.showDescriptions.isLiveChatDesc = false;
        break;
      case "liveChat":
        this.showDescriptions.isLiveChatDesc = true;
        this.showDescriptions.isLiveDemoDesc = false;
        this.showDescriptions.isSpecCallDesc = false;
    }
  }

  hideDescriptions() {
    this.showDescriptions = {
      isLiveDemoDesc: false,
      isSpecCallDesc: false,
      isLiveChatDesc: false,
    };
  }

  navigateToTalkToExpertLink() {
    this.phaseService.trackEventForObject(this.analyticsSegment,  'talk_to_an_expert', {
      user_id: this.dataService.user ?.id,
      clicked_source: 'Header'
    });
    window.open('https://www.builder.ai/dls-talk-to-an-expert', '_blank', "noopener");
  }

}
