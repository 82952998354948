<!--NDA start-->

<nda-modal *ngIf="phaseService.showSignNDAPopup"></nda-modal>
<!---NDA end -->
<div id="app-component" [ngClass]="{'templateSearchOn': phaseService.showTemplateSearchBox,'removeHeaderUpgradePlan':dataService.currentPage == 'upgradePlan'}">
  <div class="pageLoaderBox" *ngIf="(isShowLoader() || dataService.isScheduleCall || dataService.isShowUpfrontPaymentPlan) && !isShowLoaderForBNow()">
    <div class="pageLoader"></div>
    <div class="loadingOverlay"></div>
  </div>

  <div class="newCurrencyPopUp" *ngIf="isShowLoaderForBNow()">
    <div class="loaderBx">
      <img src="../assets/images/loader-screen2.gif" alt="" />
    </div>
  </div>
  <!---->
  <div class="wrapper" [ngClass]="getPromotionClass()">
    <div *appShellNoRender>
      <headerpart (offersSection)="removeOfferClass($event)" class="headerPart"
                  [ngClass]="{'headernone': dataService.currentPage === 'homev2' }"
                  *ngIf="!isFirstLoad && (dataService.currentPage !== 'project_dashboard') && !phaseService.showTimer && !referralPath.includes('ref?code') && checkIfNotTnCPage()">
      </headerpart>
    </div>
    <div class="middlePart" [ngClass]="{'hideScrollAccessBlock' : isShowTimer(), 'middleWithFooter' : dataService.currentPage === 'home'}">
      <main (click)="hideAllPopups()">
        <router-outlet></router-outlet>
      </main>
    </div>
    <div *appShellNoRender>
      <app-bottom-bar [currentUrl]="currentUrl" *ngIf="!isFirstLoad && isShowBottomBar()"></app-bottom-bar>

      <div id="footerArea" *ngIf="!isFirstLoad && !phaseService.showTimer && !phaseService.showWelcomeScreen && (dataService.currentPage !== 'homev2') && (dataService.currentPage !== 'delivery') && (dataService.currentPage !== 'features') && dataService.currentPage !== 'home' && !referralPath.includes('ref?code')"
           [ngClass]="{'dashboardOpen': isDashboardOpen() || dataService.currentPage === 'project_dashboard',
                      'seperator': this.dataService.currentPage === 'templateDetail' && dataService.appDetails.pricing_modal !== 'rental_price',
                      'hideExtraSpace': dataService.currentPage === 'paymentPlan',
                      'hideExtraSpace': this.appDataService.urlParameters.is_freemium || appDataService.urlParameters.is_freemium }">

        <!-- {{dataService.currentPage !== 'home' ? "no work" : "work"}} -->
        <div class="container">
          <div class="commonfooter" *ngIf="isEngineerAIBuilder()">
            <div class="templateFooter">
              <div class="footerNewBottom">
                  <div class="leftNewFooter">
                      <div class="footerNewLogo">
                        <img src="../assets/images/buildernew-black.svg" alt="" />

                      </div>
                      <div class="copyText">Copyright © Builder.ai {{year}}</div>
                  </div>
                  <div class="rightNewFooter" (click)="$event.stopPropagation()">
                      <a target="_blank" [href]="getPrivacyPolicyUrl()" (click)="trackEvent('Privacy')" rel="noopener">Privacy policy</a>
                      <a target="_blank" [href]="getTnCUrl()" (click)="trackEvent('Terms & Conditions')" rel="noopener">Terms and conditions</a>
                  </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="templateFooter" *ngIf="dataService.currentPage === 'home'">
        <div class="footerNewBottom">
            <div class="leftNewFooter">
                <div class="footerNewLogo">
                  <img src="../assets/images/buildernew-black.svg" alt="" />

                </div>
                <div class="copyText">Copyright © Builder.ai {{year}}</div>
            </div>
            <div class="rightNewFooter" (click)="$event.stopPropagation()">
                <a target="_blank" [href]="getPrivacyPolicyUrl()" (click)="trackEvent('Privacy')" rel="noopener">Privacy policy</a>
                <a target="_blank" [href]="getTnCUrl()" (click)="trackEvent('Terms & Conditions')" rel="noopener">Terms and conditions</a>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div *appShellNoRender>
    <saveprogress  *ngIf="phaseService.showSaveProgress"></saveprogress>

    <login *ngIf="(isShowCommonAuthPopup() || partnerDataService.isPartnerVerified)"></login>

    <div class="commonPopUp specCall-bx" [ngClass]="{'active': showExitIntent}"
         *ngIf="showExitIntent && !dataService.hideExitIntentPopUp && !phaseService.isPopUpActive()">
      <div class="popOverlay"></div>
      <app-meet-now class="popHolder speCallPopup_bx" [ngClass]="dataService.showQuestionsForInstantSpec ? 'questionnairePopup' : 'meetNowPopup'" *ngIf="showExitIntent"
                    [isShowExitIntent]="showExitIntent" (handleClose)="closeExitIntent()"></app-meet-now>
    </div>


    <div class="commonPopUp zIndex" [ngClass]="getHoldOfferPopup() ? 'active' : ''">
      <div class="popOverlay"></div>
      <div class="popHolder specingPopup">
        <div class="closePopUp" (click)="hideHoldOffer()"><span class="icon-cancel"></span></div>
        <h3>
          By clicking OK, you will be redirected to payment gateway.
          After Payment, you'd be able to schedule a call with one of our Sales Managers.</h3>

        <div class="footerButton">
          <button class="doLater" (click)="hideHoldOffer()">Cancel</button>
          <button class="proceedPayment" (click)="handleHoldOfferFlow()">OK <img
            *ngIf="getPreventClick()"
            src="../assets/images/buttonLoader.gif" alt=""/></button>
        </div>

      </div>
    </div>

    <div class="commonTostMsg" *ngIf="phaseService.otpConfirmationMessage && dataService.showUserConfirmationToast">
      <em class="icon-tick"></em>
      <p><strong>Hello {{ dataService.user.first_name }},</strong> Your Email Address is Confirmed!</p>
    </div>
    <app-create-card-for-others *ngIf="phaseService.isShowCreateCardForOthersPopup"></app-create-card-for-others>
    <spec-call *ngIf="getSelectedSpecing()" [currentUrl]="this.url"></spec-call>
    <app-chat-bot class="appChatBot" *ngIf="showHideChatbot()"
                  [ngClass]="{'hide': dataService.showHidePhaseBreakUp || isShowLoaderForBNow() || phaseService.conditionToCreateAndSyncBuildCardToBNow() || dataService.currentPage === 'features' || dataService.currentPage === 'home'}"></app-chat-bot>

    <div class="commonPopUp specCall-bx" [ngClass]="{'active': phaseService.countExitBillingDetails === 1}"
         *ngIf="(phaseService.countExitBillingDetails === 1)">
      <div class="popOverlay"></div>
      <app-meet-now class="popHolder speCallPopup_bx" [ngClass]="dataService.showQuestionsForInstantSpec ? 'questionnairePopup' : 'meetNowPopup'" [isFromBillingDetail]="true"></app-meet-now>
    </div>

    <div class="commonPopUp specCall-bx active"
         *ngIf="phaseService.isShowPaymentExitOffer || phaseService.isExitPaymentOffer">
      <div class="popOverlay"></div>
      <app-meet-now class="popHolder speCallPopup_bx" [ngClass]="dataService.showQuestionsForInstantSpec ? 'questionnairePopup' : 'meetNowPopup'"></app-meet-now>
    </div>
  </div>
</div>

<rental-parent *ngIf="phaseService.showRentalPaymentFlow && phaseService.conditionToCreateAndSyncBuildCardToBNow()" [appDetails]="dataService.appDetails"></rental-parent>
