<div class="commonButton">
  <form #paymentForm class="hide" accept-charset="UTF-8"
        [action]="getGatewayUrl()"
        method="post">
    <input id="apptoken" name="apptoken" type="text"
           [value]="getAppToken()"/>
    <input id="payment_data_email" name="payment_data[email]" type="text"
           [value]="partnerDataService.getPartnerUser()?.email"/>
    <input id="payment_data_amount_1" name="payment_data[currency]" type="text"
           [value]="partnerDataService.selectedCurrency[0].code"/>
    <input id="payment_data_amount_2" name="payment_data[redirect_url]" type="text"
           [value]="getRedirectUrl()"/>
    <input id="payment_data_redirection_url" name="payment_data[frontend_redirect_url]" type="text"
           [value]="getFrontendUrl()"/>
    <input id="payment_data_dashboard_url" name="payment_data[redirect_dashboard_url]" type="text"
           [value]="getDashboardUrl()"/>
    <input id="payment_data_currency" name="payment_data[notify_url]" type="text"
           [value]="getNotifyUrl()"/>
  </form>
  <button type="button" class="proceedButton" (click)="goToPaymentGateway(paymentForm)">Proceed to Add Card</button>
</div>

