<!-- Feature Conditional popup start -->
<div class="commonPopUp active" >
  <div class="popOverlay"></div>
  <div class="popHolder featureConditionalPopup">
    <!-- <div class="closePopUp"><span class="icon-cancel"></span></div> -->
    <h3>Wow, that's a lot of features.</h3>
    <div class="textDescription">
      <p>Your Buildcard is getting quite complex, would you like to review it with one of our experts?</p>
      <h4>I can book you a call now!</h4>
    </div>
    <div class="footerButtons">
      <button class="btn btnNoImFine" (click)="noButtonHandler()" >No, I'm fine</button>
      <button class="btn btnYesBookCall" (click)="yesButtonHandler()" >Yes, book call</button>
    </div>
  </div>
</div>
<!-- Feature Conditional popup close -->
