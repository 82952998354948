import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '@shared/shared.module';
import { ReferralComponent } from './referral/referral.component';
import { ShareReferralComponent } from './share-referral/share-referral.component';
import { ReferralPointsUsageComponent } from './referral-points-usage/referral-points-usage.component';
// import { BeingReferredComponent } from './being-referred/being-referred.component';
import { ReferralWalletComponent } from './referral-wallet/referral-wallet.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { UseCreditedReferralPointsComponent } from './use-credited-referral-points/use-credited-referral-points.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

// const childRouterConfig: Routes = [
//   {path: '', component: BeingReferredComponent}
// ];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    // RouterModule.forChild(childRouterConfig),
    ShareButtonsModule,
    ShareIconsModule,
    NgxSliderModule  
  ],
  declarations: [ReferralComponent, ShareReferralComponent, ReferralPointsUsageComponent, ReferralWalletComponent, UseCreditedReferralPointsComponent],
  entryComponents: [ReferralComponent],
  exports: [
    ReferralComponent, ReferralWalletComponent, UseCreditedReferralPointsComponent
  ],

  providers: [

  ]
})

export class ReferralProgramModule {
}
