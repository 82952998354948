import {Component} from '@angular/core';
import { PhaseService } from '@shared/services/phase.service';
import {BuildCardService} from '../../services/buildcard.service';

@Component({
  selector: 'app-build-card-detail',
  templateUrl: 'build-card-detail.component.html'
})

export class BuildcardDetailComponent {
  public activeTab = 1;

  constructor(public buildCardService: BuildCardService, public phaseService: PhaseService) {
  }

  public setTab(tab: number) {
    this.activeTab = tab;
  }

  public isBuildCardInProgress() {
    const card = this.buildCardService.currentBuildCard;
    if (card) {
      return card && (!this.getDiscountedprice(card) || (card.status === 'card_initialized' && !card.kick_off_date));
    }
  }

  getDiscountedprice(card) {
    return this.phaseService.getDiscountedPriceForCards(card);
  }

}
