/**
 * Created by Rakesh on 22/05/19.
 */
export class PhaseDataModel {
  id: number;
  phase_name: string;
  delivery_time: number;
  price: number;
  weeks: number;
}
