<!-- Buildcard™ screen start -->
<div class="studioStore" id="studioStore" *ngIf="!phaseService.showTemplateLoader">
    <!--preview section start-->
    <div class="studioStorePrev" #studioStorePrev>
        <div class="studioStoreLeft onlyweb" [ngClass]="{'staticWidth' : checkIfNatashaShownInStudioStore()}">
            <!-- Slider -->
            <div class="detailSlider">
                <div class="sticky">
                    <div class="slider-privew withoutIframe" *ngIf="!checkIfNatashaShownInStudioStore()">
                        <em class="left-arrow" *ngIf="selectedImageIndex > 0 && selectedImageIndex < screenshots.length" (click)="changeSelectedImage('previous')"></em>
                        <div class="deviceFrame mobile" *ngIf="selectedPreviewPlatform === 'android'">
                            <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
                            <img src="{{selectedImage.imageURL}}" class="featureImg" alt="">
                        </div>
                        <div class="deviceFrame desktop" *ngIf="selectedPreviewPlatform === 'web'">
                            <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
                            <img src="{{selectedImage.imageURL}}" class="featureImg" alt="">
                        </div>
                        <em class="right-arrow" *ngIf="selectedImageIndex <  screenshots.length-1" (click)="changeSelectedImage('next')"></em>
                    </div>

                    <div class="slider-privew withIframe" *ngIf="checkIfNatashaShownInStudioStore()">
                        <em class="left-arrow" *ngIf="selectedImageIndex > 0 && selectedImageIndex < screenshots.length" (click)="changeSelectedImage('previous')"></em>
                        <div class="deviceFrame desktop" *ngIf="!selectedImage.iframeURL">
                            <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
                            <img src="{{selectedImage.imageURL}}" class="featureImg" alt="">
                            <div class="sectionNotification">
                                We can't apply your design to this screen just yet - it's coming soon. Real-time changes are reflected on the <strong (click)="navigateToCatalogueFeature()">Catalogue feature</strong>.
                            </div>
                        </div>
                        <div class="deviceFrame desktop" *ngIf="selectedImage.iframeURL">
                            <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
                            <iframe *ngIf="!phaseService.showLoaderInNatashaDesignIFrame" [src]="selectedImage.iframeURL" class="featureFrame" ></iframe>
                            <div class="sectionLoader">
                                <div class="headerPart">
                                    <span></span><span></span>
                                </div>
                                <div class="heroImgBox"></div>
                                <div class="listingArea"><span></span><span></span><span></span><span></span><span></span><span></span></div>
                            </div>
                        </div>
                        <em class="right-arrow" *ngIf="selectedImageIndex <  screenshots.length-1" (click)="changeSelectedImage('next')"></em>
                    </div>
                    <div class="info-block-wrapper">
                        <div class="leftSide">
                            <span class="show-count">{{ screenshots.length ? selectedImageIndex + 1 : 0}}/{{screenshots.length}}</span>
                            <span class="infoTitle"> {{ dataService.appDetails.features[selectedImageIndex].title }} <em class="icon-info-circle" (click)="displayInfoblock()"></em></span>
                            <!-- <span class="infoTitle" *ngIf="checkIfNatashaShownInStudioStore()"> {{ natashaDesignMessage.appFeature?.title || 'Catalogue' }} <em class="icon-info-circle" *ngIf="activityFeed && natashaDesignMessage.appFeature?.description" (click)="displayInfoblock()"></em></span> -->
                            <div class="discription-block" *ngIf="activityFeed">
                                {{ showHTMLParsedBodyContent(dataService.appDetails?.features[selectedImageIndex]?.description) }}
                                <!-- {{natashaDesignMessage.appFeature?.description}} -->
                            </div>
                            <div class="tooltipOverlay" *ngIf="activityFeed" (click)="displayInfoblock()"></div>
                        </div>
                        <!-- <div class="toggleButton" *ngIf="checkIfNatashaShownInStudioStore()">
                            <div class="designWith"><img src="../../../../../assets/images/sparkle.svg"> Design with</div>
                            <div class="natashaButton" (click)="toggleNatashaSession()">
                                <div class="natashaIcon">
                                    
                                    <div class="wizardAvatar">
                                        <span></span>
                                        <span></span>
                                    </div>
                                    
                                </div>
                                Natasha
                            </div>
                        </div> -->
                    </div>
                    <div class="viewSelection" *ngIf="selectedPlatform == 'all'">
                        <ul>
                            <li [ngClass]="{active : selectedPreviewPlatform === 'android'}">
                                <div class="viewIcon" (click)="toggleLayoutView('android')"><em class="icon-mobiledevice"></em></div>
                            </li>
                            <li [ngClass]="{active : selectedPreviewPlatform === 'web'}">
                                <div class="viewIcon" (click)="toggleLayoutView('web')"><em class="icon-desktopdevice"></em></div>
                            </li>
                        </ul>
                    </div>
                    <div class="slider-thumbnil-wrapper" *ngIf="!checkIfNatashaShownInStudioStore()">
                        <button type="button" class="prevNav" *ngIf="imageSetCount > 0 && imageSetCount < totalImageSetCount" (click)="changeImageSet('previous')">
                        <span></span>
                        </button>
                        <ul class="slider-thumbnil">
                            <li class="active" [ngClass]="{'active': selectedImageIndex % imageSet === index}" *ngFor="let image of imagesToShow; let index = index"
                                (click)="selectImage(index)">
                                <div class="deviceFrame mobile" *ngIf="selectedPreviewPlatform === 'android'">
                                    <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
                                    <img src="{{image.imageURL}}" class="featureImg" alt="" />
                                </div>
                                <div class="deviceFrame desktop" *ngIf="selectedPreviewPlatform === 'web'">
                                    <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
                                    <img src="{{image.imageURL}}" class="featureImg" alt="" />
                                </div>
                            </li>
                        </ul>
                        <button type="button" class="nextNav" *ngIf="imageSetCount < totalImageSetCount-1" (click)="changeImageSet('next')">
                            <span></span>
                        </button>
                    </div>
                    <div class="slider-thumbnil-wrapper" *ngIf="checkIfNatashaShownInStudioStore()">
                        <button type="button" class="prevNav" *ngIf="imageSetCount > 0 && imageSetCount < totalImageSetCount" (click)="changeImageSet('previous')">
                        <span></span>
                        </button>
                        <ul class="slider-thumbnil iFrameThumbnail">
                            <li class="active" [ngClass]="{'active': selectedImageIndex % imageSet === index}" *ngFor="let image of imagesToShow; let index = index"
                                (click)="selectImage(index)">
                                <div class="featureFrame" *ngIf="image.iframeURL">
                                    <iframe *ngIf="!phaseService.showLoaderInNatashaDesignIFrame" [src]="image.iframeURL"></iframe>
                                </div>
                                <div class="deviceFrame desktop" *ngIf="!image.iframeURL">
                                    <img src="{{image.imageURL}}" class="featureImg" alt="" />
                                </div>
                            </li>
                        </ul>
                        <button type="button" class="nextNav" *ngIf="imageSetCount < totalImageSetCount-1" (click)="changeImageSet('next')">
                            <span></span>
                        </button>
                    </div>
                    <div class="slider-info">
                        <p>These designs are representative of the features included in the app.</p>
                        <p>They do not show your finished product. You can customise how everything looks with our network of
                            designers.
                        </p>
                    </div>
                </div>
            </div>
            <!-- Slider End -->

        </div>

        <div class="studioStoreRight" [ngClass]="{'blurNatashaBackground': (phaseService.shouldBlurDlsStoreNatashaBackground && checkIfNatashaShownInStudioStore())}">
            <div #rightTopSection>
                <h2>{{ isPayStarterPlan ? dataService.appDetails.starter_title: dataService.appDetails.title}}</h2>
                <div class="SubDetail"> {{dataService.appDetails?.features.length}} features included</div>
                <div class="discriptionContent">
                    <span *ngIf="dataService.appDetails && dataService.appDetails?.description">
                    {{dataService.appDetails.description }} </span>
                </div>
            </div>
            <!---- only show mobile slider -->
            <div class="studioStoreLeft onlymobile mobilesliderui">
                <!-- Slider -->
                <div class="detailSlider">
                    <div class="sticky">
                        <div class="slider-privew">
                            <em class="left-arrow" *ngIf="selectedImageIndex > 0 && selectedImageIndex < screenshots.length" (click)="changeSelectedImage('previous')"></em>
                            <div class="deviceFrame mobile" *ngIf="selectedPreviewPlatform === 'android'">
                                <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
                                <img src="{{selectedImage.imageURL}}" class="featureImg" alt="" />
                            </div>
                            <div class="deviceFrame desktop" *ngIf="selectedPreviewPlatform === 'web'">
                                <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
                                <img src="{{selectedImage.imageURL}}" class="featureImg" alt="" />
                            </div>
                            <em class="right-arrow" *ngIf="selectedImageIndex <  screenshots.length-1" (click)="changeSelectedImage('next')"></em>
                        </div>
                        <div class="info-block-wrapper">
                            <div class="leftSide">
                                <span class="show-count">{{ screenshots.length ? selectedImageIndex + 1 : 0}}/{{screenshots.length}}</span>
                                <span>{{this.dataService.appDetails.features[selectedImageIndex].title}} <em class="icon-info-circle" (click)="displayInfoblock()"></em></span>
                                <div class="discription-block" *ngIf="activityFeed">
                                    {{ dataService.appDetails?.features[selectedImageIndex]?.description }}
                                </div>
                                <div class="tooltipOverlay" *ngIf="activityFeed" (click)="displayInfoblock()"></div>
                            </div>
                        </div>
                        <div class="viewSelection" *ngIf="selectedPlatform == 'all'">
                            <ul>
                                <li [ngClass]="{active : selectedPreviewPlatform === 'android'}">
                                    <div class="viewIcon" (click)="toggleLayoutView('android')"><em class="icon-mobiledevice"></em></div>
                                </li>
                                <li [ngClass]="{active : selectedPreviewPlatform === 'web'}">
                                    <div class="viewIcon" (click)="toggleLayoutView('web')"><em class="icon-desktopdevice"></em></div>
                                </li>
                            </ul>
                        </div>
                        <div class="slider-thumbnil-wrapper" [ngClass]="{'alignment': totalImageSetCount > 1}">
                            <button type="button" class="prevNav" *ngIf="imageSetCount > 0 && imageSetCount < totalImageSetCount" (click)="changeImageSet('previous')">
                                <span></span>
                            </button>
                            <ul class="slider-thumbnil">
                                <li [ngClass]="{'active': selectedImageIndex % imageSet === index}" *ngFor="let image of imagesToShow; let index = index"
                                    (click)="selectImage(index)">
                                    <div class="deviceFrame mobile" *ngIf="selectedPreviewPlatform === 'android'">
                                        <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
                                        <img src="{{image.imageURL}}" class="featureImg" alt="" />
                                    </div>
                                    <div class="deviceFrame desktop" *ngIf="selectedPreviewPlatform === 'web'">
                                        <img src="../../../../../assets/images/devicegreyframe.png" class="frame" alt="" />
                                        <img src="{{image.imageURL}}" class="featureImg" alt="" />
                                    </div>
                                </li>
                            </ul>
                            <button type="button" class="nextNav" *ngIf="imageSetCount < totalImageSetCount-1" (click)="changeImageSet('next')">
                                <span></span>
                            </button>
                        </div>
                        <div class="slider-info">
                            <p>These designs are representative of the features included in the app.</p>
                            <p>They do not show your finished product. You can customise how everything looks with our network
                                of designers.</p>
                        </div>
                    </div>
                </div>
                <!-- Slider End -->
            </div>

            <div class="tabSelection">
                <!--- Monthly selection tab info start-->
                <div class="serviceTab" (click)='toggleMonthlySelection()'>
                    <input type="radio" name="selecting" id="monthly" (change)="toggleMonthlySelection()" [checked]="isMonthlySelected()">
                    <label for="monthly">
                        <span class="tagTitle">Monthly</span>
                        <span class="pricingRow">
                            <strong>
                                {{dataService.getCurrency()}}
                                <span *ngIf='!isPayStarterPlan'>
                    {{dataService.getLocaledPrice(dataService.appDetails.rental_prices.discount_rental_monthly_price.toFixed())}}
                                </span>
                                <span *ngIf='isPayStarterPlan'>
            {{dataService.getLocaledPrice(dataService.appDetails.rental_starter_prices.discount_rental_monthly_price.toFixed())}}
                                </span>
                            </strong>
                            <sub *ngIf="(!isPayStarterPlan) &&dataService.appDetails.rental_prices.discount_rental_monthly_price !== dataService.appDetails.rental_prices.rental_monthly_price">
                                <u>{{dataService.getCurrency()}}
                    {{dataService.getLocaledPrice(dataService.appDetails.rental_prices.rental_monthly_price.toFixed())}}
                                </u>
                            </sub>
                            <sub *ngIf=" isPayStarterPlan &&dataService.appDetails.rental_starter_prices.discount_rental_monthly_price !== dataService.appDetails.rental_starter_prices.rental_monthly_price">
                                <u>{{dataService.getCurrency()}}
                {{dataService.getLocaledPrice(dataService.appDetails.rental_starter_prices.rental_monthly_price.toFixed())}}
                                </u>
                            </sub>
                            <sub>
                                per month for {{ dataService.appDetails.month_count}} {{dataService.appDetails.month_count > 1 ? 'months' : 'month'}}
                            </sub>
                        </span>

                    </label>
                </div>
                <!--- Monthly selection tab info end-->

                <!--- upfront selection tab info start-->
                <div class="serviceTab" (click)="toggleUpfrontSelection()">
                    <input type="radio" name="selecting" id="upfront" (change)="toggleUpfrontSelection()" [checked]="isUpFrontSelected()">
                    <label for="upfront">
                        <span class="tagTitle">Upfront</span>
                        <span class="pricingRow">
                            <strong>
                                {{dataService.getCurrency()}}
                                <span *ngIf='!isPayStarterPlan'>
            {{dataService.getLocaledPrice(dataService.appDetails.rental_prices.discount_upfront_monthly_total_price.toFixed())}}
                                </span>
                                <span *ngIf='isPayStarterPlan'>
    {{dataService.getLocaledPrice(dataService.appDetails.rental_starter_prices.discount_upfront_monthly_total_price.toFixed())}}
                                </span>
                            </strong>
                            <sub *ngIf=" (!isPayStarterPlan) && dataService.appDetails.rental_prices.discount_upfront_monthly_total_price !== dataService.appDetails.rental_prices.upfront_monthly_total_price">
                                <u>{{dataService.getCurrency()}}
    {{dataService.getLocaledPrice(dataService.appDetails.rental_prices.upfront_monthly_total_price.toFixed())}}
                                </u>
                            </sub>

                            <sub *ngIf="isPayStarterPlan && dataService.appDetails.rental_starter_prices.discount_upfront_monthly_total_price !== dataService.appDetails.rental_starter_prices.upfront_monthly_total_price">
                                    <u>{{dataService.getCurrency()}}
        {{dataService.getLocaledPrice(dataService.appDetails.rental_starter_prices.upfront_monthly_total_price.toFixed())}}
                                    </u>
                            </sub>
                        </span>

                    </label>
                </div>
            </div>

            <div class="includeSection">
                <h3>What's included</h3>
                <div class="includeBox">
                    <h4>Platforms included: {{selectedPlatform == 'app'? "Just apps" : selectedPlatform == 'web'? "Just Web"
                        : "Web + Apps"}}
                    </h4>
                    <div class="detailRow">

                        <div class="textSection platforms">
                            <ng-container *ngIf="selectedPlatform == 'app' || selectedPlatform == 'all'">
                                <p><img class="iconMobile" width="17" src="../../../../../assets/images/icon-apple-32.svg" alt="" />                                    <span>Apple iOS mobile app</span></p>
                                <p><img class="iconMobile" width="17" src="../../../../../assets/images/icon-android.svg" alt="" />                                    <span>Google Android mobile app</span></p>
                            </ng-container>
                            <ng-container *ngIf="selectedPlatform == 'web' || selectedPlatform == 'all'">
                                <p class="text"><img alt="" class="iconMobile" width="17" src="../../../../../assets/images/icon-desktop-responsive.svg"
                                    /> <span>Responsive website - optimised for desktop, laptop and mobile screens</span></p>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="includeBox">
                    <h4>
                      <span class="logo-image">
                            <img src="../../../../../assets/images/studio_one.png" />
                        </span>
                      <span class="blueTag graytxt">Included for {{dataService.appDetails.month_count}} months</span>
                    </h4>
                    <h5>{{dataService.getCurrency()}}{{getCarePrice()
                        | number: '1.0-0'}}/year</h5>
                    <div class="detailRow">
                        <!-- <div class="imgSection"><img src="../../../../../assets/images/careinclude.svg" alt="" /></div> -->
                        <div class="textSection careContent">
                            <ul>
                                <li>24*7 uptime monitoring</li>
                                <li>Software application bug fixes (including permanent fixes for recurring issues)</li>
                                <li>Platform updates for latest iOS / Android versions</li>
                                <li>Proactive technical support for third-party API / SDK updates on all standard features**</li>
                                <li>Security patches & Backup Management*</li>
                                <li>Updated tech-stack</li>
                                <li>Industry Standard SLA</li>
                                <li>Multi-Channel Support - Online, Chat, Email with average 24 hour response time</li>
                            </ul>
                            <ul class="bottomDescription">
                                <li>*If part of your original software.</li>
                                <li>**Excluding any third-party, SDK, security, feature, custom integration or browser addition - that wasn't part of your Buildcard.</li>
                                <li>***Features covered under your Buildcard.</li>
                                <li><strong class="carot">&#94;</strong> If hosted with Builder Cloud</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="includeBox">
                    <h4><img src="../../../../assets/images/Builder_Cloud_Blue.svg" height="20" alt=""/> <span class="blueTag graytxt">Included for {{dataService.appDetails.month_count}} months</span></h4>
                    <h5>{{dataService.getCurrency()}}{{builderCloudPrice() | number: '1.0-0'}} will be put on your
                        cloud wallet every month</h5>
                    <div class="detailRow">
                        <div class="textSection careContent">
                            <ul>
                                <li>Best-in-class multi-cloud: Azure, AWS, and more. Just one bill (for a lot less).</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!--our plan-->
    <div class="store-container ourPlans" *ngIf="checkIfNatashaShownInStudioStore()">
        <div class="walkthroughBox">
            <h2>Watch the full walkthrough</h2>
            <div class="videoHolder">
                <iframe title="" width="720" height="405" src="https://www.youtube.com/embed/_DSpoBcKPME" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <span class="graphicBg leftTop"></span>
                <span class="graphicBg leftBottom"></span>
                <span class="graphicBg rightTop"></span>
                <span class="graphicBg rightBottom"></span>
            </div>
        </div>
        <div class="sectionBx" id="dlsStorePlans">
            <div class="topSection">
                <h2>Our plans</h2>
                <div class="tabSection">
                    <div class="tabName" [ngClass]="{'active': (selectedPlatform === 'web')}" (click)="selectWebOnlyTab()">Web only</div>
                    <div class="tabName" [ngClass]="{'active': (selectedPlatform === 'all')}" (click)="selectWebAndAppTab()">Web and app</div>
                </div>
                <div class="payupfrontRow">
                    <div class="toggleButton" (click)="toggleUpfrontPaymentPrice()" [ngClass]="{'active': showUpfrontPaymentPrice}"></div>
                    <p>Pay upfront (Save 4 months payment)</p>
                </div>
            </div>
        </div>
        <div class="planBoxRow">
            <div class="planBoxColumn">
                <h3>Starter plan</h3>
                <p>Take up to 100 orders a month, on your own responsive website store</p>
                <h4>{{dataService.getCurrency()}}
                    <ng-container> {{ this.isMonthlySelected() ? dataService.getLocaledPrice(dataService.appDetails.rental_starter_prices.discount_rental_monthly_price.toFixed()) : dataService.getLocaledPrice(dataService.appDetails.rental_starter_prices.discount_upfront_monthly_total_price.toFixed()) }}
                    </ng-container>
                    <span *ngIf="phaseService.selectMonthlyPackage">x{{dataService.appDetails.month_count}} months</span>
                </h4>
                <button type="button" class="planButton">Current plan</button>
                <div class="listitems">
                    <ul>
                        <li>Responsive website</li>
                        <li>No seller fees</li>
                        <li>Discounts on payment and delivery partners</li>
                        <li>Free hosting for 24 months</li>
                        <li>Fully customisable URL: www.your_store.com</li>
                        <li>FREE 24-month Aftercare package</li>
                        <li>Option to pay up front with a 15% discount</li>
                        <li>Max 100 sales per month</li>
                    </ul>
                </div>
            </div>
            <div class="planBoxColumn">
                <h3>Premium plan</h3>
                <p>Make unlimited sales on your own responsive website store</p>
                <h4> {{dataService.getCurrency()}}
                    <ng-container> {{ this.isMonthlySelected() ? dataService.getLocaledPrice(dataService.appDetails.rental_prices.discount_rental_monthly_price.toFixed()) : dataService.getLocaledPrice(dataService.appDetails.rental_prices.discount_upfront_monthly_total_price.toFixed()) }}
                    </ng-container>
                    <span *ngIf="phaseService.selectMonthlyPackage">x{{dataService.appDetails.month_count}} months</span>
                </h4>
                <button type="button" class="planButton">Get started</button>
                <div class="listitems">
                    <ul>
                        <li>Responsive website</li>
                        <li>No seller fees</li>
                        <li>Discounts on payment and delivery partners</li>
                        <li>Free hosting for 24 months</li>
                        <li>Fully customisable URL: www.your_store.com</li>
                        <li>FREE 24-month Aftercare package</li>
                        <li>Option to pay up front with a 15% discount</li>
                        <li>Unlimited sales</li>
                        <li>Own the code</li>
                        <li>Additional features to scale your business</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!---Studio Store works -->
    <div class="store-container howItAllWorks">
        <div class="sectionBx">
            <div class="headerBlock">
                <h3 class="heading">How it all works</h3>
                <p class="subheading">Looks like you've found the perfect template for your business. What happens next? </p>
            </div>
        </div>
        <div class="sectionBx">
            <div class="worksmidblock">
                <div class="listing">
                    <div class="imgbx"><img src="../../../../../assets/images/studio-step-1-new.svg" alt="" /></div>
                    <div class="imageCaption">
                        <h5 class="title">Make it your own</h5>
                        <p class="detail">Discuss your idea with our experts and share your branding.</p>
                    </div>
                </div>
                <div class="listing">
                    <div class="imgbx"><img src="../../../../../assets/images/studio-step-2-new.svg" alt="" /></div>
                    <div class="imageCaption">
                        <h5 class="title">Kick off & track progress</h5>
                        <p class="detail">Pay a deposit and track your build's progress from our dashboard.</p>
                    </div>
                </div>
                <div class="listing">
                    <div class="imgbx"><img src="../../../../../assets/images/studio-step-3-new.svg" alt="" /></div>
                    <div class="imageCaption">
                        <h5 class="title">Launch app </h5>
                        <p class="detail">{{dataService.appDetails.development_weeks}} {{ dataService.appDetails.development_weeks > 1 ? 'weeks':
                            'week'}} after your kick-off call. </p>
                    </div>
                </div>
                <div class="listing">
                    <div class="imgbx"><img src="../../../../../assets/images/studio-step-4-new.svg" alt="" /></div>
                    <div class="imageCaption">
                        <h5 class="title">Keep it fresh</h5>
                        <p class="detail">Your app moves into maintenance and cloud. Your business grows.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--- question answer -->
    <div class="store-container questionMainBx">
        <div class="sectionBx">
            <div class="headerBlock">
                <h3 class="heading">Your questions answered</h3>
                <p class="subheading">Find out more about Studio Store apps, below. </p>
            </div>
        </div>
        <div class="sectionBx">
            <div class="questionListBox">
                <ul (click)="showAnswer($event.target)">
                    <li *ngFor="let question of dataService.appDetails?.builder_faqs; let i = index" [value]="i">
                        <div class="qlist" [ngClass]="{'active': i == questionShownIndex}" [attr.data-index]="i">
                            {{question?.title}}
                            <span class="toggleButton"><em class="icon-next" [attr.data-index]="i"></em></span>
                        </div>
                        <!--- add active class-->
                        <div class="detailstxt">
                            <p>{{question?.description}}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!--- footer strip -->
    <div class="store-container bottomBox" *ngIf='!dataService.isShowTemplateModal'>

        <div class="bottom-strip" [ngClass]="{'dlsFooter' : checkIfNatashaShownInStudioStore() }">
            <div class="leftSection">
                <ul>
                    <li class="listing">
                        <div class="headtxt"> {{dataService.appDetails?.title}}</div>
                        <div class="nortxt">
                            <span class="count"> {{dataService.appDetails?.feature_count}}</span> {{dataService.appDetails?.feature_count
                            > 1 ? 'features' : 'feature' }}
                        </div>
                    </li>
                    <li class="listing">
                        <div class="headtxt"> Platforms</div>
                        <div class="nortxt justPrice"> {{selectedPlatform == 'app'? "Just Apps" : selectedPlatform == 'web'? "Just Web" : "Web + Apps"}}
                        </div>
                    </li>
                    <li class="listing mobileView" *ngIf="!checkIfNatashaShownInStudioStore()">
                        <div class="headtxt"> Pay {{ isMonthlySelected() ? "monthly" : "upfront" }}
                            <!-- <button type="button" class="action" (click)="scrollToFrequenctSelection()"> Change </button> -->
                        </div>
                        <div class="pricebox">
                            <span class="ammount">{{dataService.getCurrency()}}
                            <span *ngIf='!isPayStarterPlan'> {{ this.isMonthlySelected() ? dataService.getLocaledPrice(dataService.appDetails.rental_prices.discount_rental_monthly_price.toFixed()) : dataService.getLocaledPrice(dataService.appDetails.rental_prices.discount_upfront_monthly_total_price.toFixed()) }}
                            </span>
                            <span *ngIf='isPayStarterPlan' > {{ this.isMonthlySelected() ? dataService.getLocaledPrice(dataService.appDetails.rental_starter_prices.discount_rental_monthly_price.toFixed()) : dataService.getLocaledPrice(dataService.appDetails.rental_starter_prices.discount_upfront_monthly_total_price.toFixed()) }}
                                </span>
                            </span>
                            <span *ngIf="phaseService.selectMonthlyPackage"> for {{ dataService.appDetails.month_count }} months</span>
                        </div>
                    </li>
                    <app-dls-paymentbox *ngIf="checkIfNatashaShownInStudioStore()" 
                        [isPayStarterPlan]="isPayStarterPlan" [selectedPlatform]="selectedPlatform" (updateToUpfrontSelection)="toggleUpfrontSelection()"
                        (updateToMonthlySelection)="toggleMonthlySelection()" (isShowStarterPlanSelection)="shouldShowStarterPlanSelection($event)"
                        (navigateToStorePlanSection)="scrollToStorePlanSection()">
                    </app-dls-paymentbox>
                </ul>
            </div>
            <div class="rightSection" *ngIf="!checkIfNatashaShownInStudioStore()">
                <!-- Button : Add extra features with an expert -->
                <button type="button" class="addButton" *ngIf=" !appDataService?.urlParameters.cpe_user && (dataService.user?.roles !== 'CPE')"
                    (click)="startMeetNowFlowForRental(dataService.appDetails)">Add extra features with an expert</button>
                <button type="button" class="addButton" *ngIf="(dataService.user?.roles === 'CPE')" (click)="startMeetNowFlow(dataService.appDetails)">Add extra features with an expert</button>
                <button type="button" class="overviewBtn" [disabled]="isBuildCardUpdating" (click)="payNowForRental(dataService.appDetails, 'Summary bar')"> Go to payment
                    <span class="mobilehide">overview</span>
                    <span class="loaderAnimation fiveDotloader" *ngIf="isBuildCardUpdating">
                        <span></span><span></span><span></span>
                    </span>
                </button>
            </div>

            <div class="rightSection" *ngIf="checkIfNatashaShownInStudioStore()">
                <!-- Button : Add extra features with an expert -->
                <button type="button" class="viewExampleBtn" (click)="navigateToExampleWebsite()">View example website</button>
                <button type="button" class="overviewBtn" [disabled]="isBuildCardUpdating" (click)="payNowForRental(dataService.appDetails, 'Summary bar')"> Proceed to payment
                    <!-- <span class="mobilehide">overview</span> -->
                    <span class="loaderAnimation fiveDotloader" *ngIf="isBuildCardUpdating">
                        <span></span><span></span><span></span>
                    </span>
                </button>
                
                <!-- <button type="button" class="overviewBtn" (click)="navigateToTalkToExpertLink()">Talk to an expert</button> -->
            </div>
        </div>
    </div>
    <!---->

    <!-- Sticky footer : modal -->
    <div class="sticky-bottom-bar" id="stickyBar" *ngIf='dataService.isShowTemplateModal'>
        <div class="buttonsSection">
            <button type="button" class="addButton" *ngIf=" !appDataService?.urlParameters.cpe_user && (dataService.user?.roles !== 'CPE')"
                (click)="startMeetNowFlowForRental(dataService.appDetails)">Add extra features with an expert</button>
            <button type="button" class="addButton" *ngIf="(dataService.user?.roles === 'CPE')" (click)="startMeetNowFlow(dataService.appDetails)">Add extra features with an expert</button>
            <button type="button" class="paymentButton" [disabled]="isBuildCardUpdating" (click)="payNowForRental(dataService.appDetails, 'Top')">Go to payment overview
                <span class="loaderAnimation fiveDotloader" *ngIf="isBuildCardUpdating">
                        <span></span><span></span><span></span>
                </span>
            </button>
        </div>
    </div>
    <!-- Sticky footer end -->

</div>

<app-natasha-communication [isStartNatashaSession] = 'shouldStartNatashaSession' (showNatshaDesign)="showNatshaDesignInIframe($event)">
</app-natasha-communication>

<div class="commonPopUp" [ngClass]="{'active': isShowTemplateChangePopup}">
    <div class="popOverlay"></div>
    <div class="popHolder changeTemplatePopup">
        <div class="closePopUp" (click)="closeTemplateChangePopup()"><span class="icon-cancel"></span></div>
        <h3>Change Template</h3>
        <p *ngIf="(selectedPlatform === 'web')">You are changing the template from <strong>Web</strong> to <strong>Web and App</strong>.</p>
        <p *ngIf="(selectedPlatform === 'all')">You are changing the template from <strong>Web and App</strong> to <strong>Web</strong>.</p>
        <div class="warningBox">
            <h4>Warning</h4>
            <p>By changing this template, you will be taken to a new page and your customisation will be lost.</p>
        </div>
        <button type="button" class="cancelButton" (click)="closeTemplateChangePopup()">Cancel</button>
        <button type="button" class="changeButton" (click)="navigateToNewTemplate()">Change template</button>
    </div>
</div>

<!----popup change plateform ---->
<div class="commonPopUp active" *ngIf="isShowChangePlatformModal">
    <div class="popOverlay"></div>
    <div class="popHolder plateformPopUp">
        <div class="closePopUp" (click)="closeChangePlatFormModal()">
            <span class="icon-cancel"></span>
        </div>
        <div class="sideSpaceBlock">
            <div class="changePlataformMain">
                <perfect-scrollbar class="myScrollbar" [config]="PERFECT_SCROLLBAR_CONFIG">
                    <h2 class="title"> Change platforms </h2>
                    <div class="plataformTab">
                        <div class="serviceTab" (click)="changePlatform('app')">
                            <input type="radio" name="selectingPlatform" id="app" [checked]="tempSelectedPlatform == 'app'" (change)="changePlatform('app')">
                            <label for="app">
                <span class="tagTitle">Just apps</span>
                <ul class="txtListing">
                    <li>
                    iOS app for Apple (iPhones)
                    </li>app-home-template-card
                    <li>
                        Android app (for Android phones)
                    </li>

                </ul>
                </label>
                        </div>
                        <div class="serviceTab" (click)="changePlatform('web')">
                            <input type="radio" name="selectingPlatform" id="web" [checked]="tempSelectedPlatform == 'web'" (change)="changePlatform('web')">
                            <label for="web">
                <span class="tagTitle">Just web</span>
                <ul class="txtListing">
                    <li>Responsive website – optimised for desktop, laptop and mobile screens.
                    </li>

                </ul>
                </label>
                        </div>
                        <div class="serviceTab" (click)="changePlatform('all')">
                            <input type="radio" name="selectingPlatform" id="webapp" [checked]="tempSelectedPlatform == 'all'" (change)="changePlatform('all')">
                            <label for="webapp">
                <span class="tagTitle">Web + apps</span>
                <ul class="txtListing">
                    <li> iOS app for Apple (iPhones)
                    </li>
                    <li>
                        Android app (for Android phones)
                    </li>
                    <li>
                        Responsive website – optimised for desktop, laptop and mobile screens
                    </li>
                </ul>
                </label>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
        <div class="plateformfooter">
            <button type="button" class="applyButton" (click)="applyChangedPlatform()" [disabled]="!(tempSelectedPlatform && tempSelectedPlatform !== selectedPlatform)">Apply</button>
        </div>
    </div>
</div>

<!----slider popup ---->
<div class="commonPopUp active" *ngIf="selectedFeature >=0">
    <div class="popOverlay"></div>
    <div class="popHolder sliderPouUpBx">
        <div class="closePopUp" (click)="closeFeaturePopup()"><span class="icon-cancel"></span></div>
        <div class="singlePopUp" #featureSliderHolder>
            <drag-scroll class="slideHolder" (reachesLeftBound)="leftBoundStat($event,'mobilefeatureSlider')" (reachesRightBound)="rightBoundStat($event,'mobilefeatureSlider')"
                #navFeatureSliderPopUp>
                <div class="appfeatureBox" drag-scroll-item *ngFor="let feature of dataService.appDetails.features">
                    <div class="imageFrame">
                        <ng-container *ngIf="feature.feature_screenshots?.android">
                            <img src="{{feature.feature_screenshots?.android}}" alt="" />
                        </ng-container>
                        <ng-container *ngIf="!feature.feature_screenshots?.android">
                            <img src="./../../../../../assets/images/notavailable_mobile.png" alt="" />
                        </ng-container>
                    </div>
                </div>
            </drag-scroll>
            <!-- next prevoius button -->
            <button class="dragbutton leftArrow" [disabled]="leftMobileFeatureNavDisabled" type="button" (click)="moveLeft('mobilefeatureSlider')">
                    </button>
            <button class="dragbutton rightArrow" [disabled]="rightMobileFeatureNavDisabled" type="button" (click)="moveRight('mobilefeatureSlider')">
                   </button>
        </div>
    </div>

</div>


<rental-parent *ngIf="phaseService.showRentalPaymentFlow" [appDetails]="dataService.appDetails"></rental-parent>

<!-- Product skeleton loading screen -->
<div class="productModalLoadingScreen" *ngIf="phaseService.showTemplateLoader" [ngClass]="{'detailPage': !dataService.isShowTemplateModal}">
    <div class="appdetailSectionLoading">
        <div class="imageBlock">
            <div class="mobileImage"></div>
            <div class="previewTitle"></div>
            <ul class="thumbnailList">
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
            </ul>
            <div class="scrollbar">
                <div><span></span></div>
                <div><span></span></div>
            </div>
        </div>
        <div class="descriptionBlock">
            <h2></h2>
            <ul class="labels">
                <li></li>
            </ul>
            <p class="secDescription"></p>
            <p class="secDescription2"></p>
            <p class="secDescription3"></p>

            <div class="platforms">
                <div class="circle"></div>
                <div class="content">
                    <div class="title"></div>
                    <div class="description">
                        <div class="block1"></div>
                        <div class="block2"></div>
                    </div>
                </div>
            </div>
            <div class="platforms">
                <div class="circle"></div>
                <div class="content">
                    <div class="title"></div>
                    <div class="description">
                        <div class="block1"></div>
                        <div class="block2"></div>
                    </div>
                </div>
            </div>
            <div class="btnBox"></div>

            <div class="whatsInclude">
                <div class="platformsBox">
                    <div class="platformInclude"></div>
                    <div class="device"></div>
                </div>
                <div class="deviceDescription">
                    <div class="deviceImage"></div>
                    <div class="deviceName"></div>
                </div>
                <div class="deviceDescription">
                    <div class="deviceImage"></div>
                    <div class="deviceName"></div>
                </div>
            </div>
            <div class="whatsInclude brandLogo">
                <div class="platformsBox">
                    <div class="platformInclude"></div>
                    <div class="device"></div>
                </div>
                <div class="platformsBox bottom">
                    <div class="platformInclude"></div>
                </div>
                <div class="deviceDescription">
                    <div class="deviceImage"></div>
                    <div class="deviceName">
                        <div class="brandDesc"></div>
                        <div class="brandDesc bottom"></div>
                    </div>
                </div>
            </div>
            <div class="whatsInclude brandLogo">
                <div class="platformsBox">
                    <div class="platformInclude"></div>
                    <div class="device"></div>
                </div>
                <div class="platformsBox bottom">
                    <div class="platformInclude"></div>
                </div>
                <div class="deviceDescription">
                    <div class="deviceImage"></div>
                    <div class="deviceName">
                        <div class="brandDesc"></div>
                        <div class="brandDesc bottom"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bottomBar">
        <span class="textBox"></span>
        <div class="buttonBox"></div>
    </div>
</div>
<!-- Product skeleton loading screen -->
