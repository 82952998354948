import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filter'
})
@Injectable()
export class SearchPipe implements PipeTransform {

  public transform(items: any, term: any): any {
    if (term === undefined) {
      return items;
    } else if (term.toString().trim() === '') {
      return items;
    }
    return items.filter((item) => {
      let result = false;
      if (item.attributes) {
        if (item.attributes.title.toLowerCase().includes(term.toLowerCase())) {
          result = true;
        } else if ((item.attributes.description != null ? item.attributes.description.toLowerCase().includes(term.toLowerCase()) : false)) {
          result = true;
        }
      } else {
        if (item.title.toLowerCase().includes(term.toLowerCase())) {
          result = true;
        } else if ((item.description != null ? item.description.toLowerCase().includes(term.toLowerCase()) : false)) {
          result = true;
        }
      }
      return result;
    });
  }
}
