<app-partner-home *ngIf="dataService.showHideViews.welcome"></app-partner-home>

<app-partner-profile *ngIf="dataService.showHideViews.profile"></app-partner-profile>

<app-partner-verify *ngIf="dataService.showHideViews.verify"></app-partner-verify>

<app-partner-confirmation *ngIf="dataService.showHideViews.partnerVerified"></app-partner-confirmation>

<app-partner-configuration *ngIf="dataService.showHideViews.configuration"></app-partner-configuration>

<app-partner-payment *ngIf="dataService.showHideViews.payment && !dataService.paymentSkipped"></app-partner-payment>
