import {Component, OnInit} from '@angular/core';
import {DataService} from '@shared/services/data.service';
import {PartnerDashboardDataService} from '../../Utils/partner-dashboard-data.service';
import {PartnerDashboardApiService} from '../../Utils/partner-dashboard-api.service';

@Component({
  selector: 'app-partner-payment',
  templateUrl: 'app-payment.component.html'
})

export class PartnerPaymentComponent  {

  constructor(public dataService: DataService,
              private _partnerApiService: PartnerDashboardApiService,
              public partnerDataService: PartnerDashboardDataService) {
  }



  public redirectToThankyouScreen() {
    this.partnerDataService.partnerStatus = 'payment_selected';
    this._partnerApiService.updatePartnerProfile()
      .subscribe(
        (res) => {
          this.partnerDataService.setPartnerUser(res);
          this.dataService.resetPartnerViews();
          this.dataService.showHideViews.partnerVerified = true;
          this.dataService.partnerProfileSubmitted = true;
          this.dataService.paymentSkipped = true;
          this.dataService.isSkipDone = true;
        });
  }
}
