<div class="upgradePlanPage">
<!-- <button class="backButton" type="button"><em class="icon-prev"></em> Back</button> -->
<h2>Upgrade your store plan</h2>

<div class="plansRow">
    <div *ngIf="(upgradePlanService.upgradePlanData.build_card_type === 'freemium')" class="planBox freemiumPlan" [ngClass]="{currentPlan : upgradePlanService.upgradePlanData.build_card_type === 'freemium'}">
        <div class="topPart">
            <div class="planHeader">
                <div class="planThumb"><img src="../../../../../assets/images/star.svg" alt="" /></div>
                <div class="planTitle">
                    <h3>Freemium plan</h3>
                    <h4>Free</h4>
                </div>
            </div>

            <p>Start selling online fast - and test out our product with our free website plan.</p>

        </div>

        <div class="contentPart">
            <ul>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">Responsive website</div>
                </li>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">No seller fees 
                        <div class="tooltipOverlayUpgradePlan" (click)="showHideUpgradeTooltip('sellerFees')" *ngIf="sellerFeesTooltip"></div>
                        <div  class="infoTooltipUpgradePlan"  (click)="showHideUpgradeTooltip('sellerFees')">
                            <em  class="icon-info-circle"></em>
                            <div  class="tooltipText" *ngIf="sellerFeesTooltip">No seller fees- Marketplaces & other software builders can eat a chunk of your profits with their transaction fees. We don’t charge that. (excludes credit card fees).</div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">Aftercare warranty</div>
                </li>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">Free hosting</div>
                </li>
                <li>
                    <div class="iconType limitedMark"></div>
                    <div class="titleText">URL limited to:
                        <p><span>{{upgradePlanService.upgradePlanData.sub_domain}}</span></p>
                    </div>
                </li>
                <li>
                    <div class="iconType limitedMark"></div>
                    <div class="titleText">Max {{upgradePlanService.upgradePlanData.freemium_order_limit}} sales per month</div>
                </li>
                <li>
                    <div class="iconType notMark"></div>
                    <div class="titleText">Mobile App</div>
                </li>
                <li>
                    <div class="iconType notMark"></div>
                    <div class="titleText disabledText">Own the code
                        <div  class="infoTooltipUpgradePlan">
                            <em  class="icon-info-circle disabledText"></em>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="footerButton">
            <h3>Current plan</h3>
        </div>
    </div>

    <div class="planBox starterPlan" [ngClass]="{currentPlan : upgradePlanService.upgradePlanData.build_card_type === 'starter'}">
        <div class="topPart">
            <div class="planHeader">
                <div class="planThumb"><img src="../../../../../assets/images/house-1.svg" alt="" /></div>
                <div class="planTitle">
                    <h3>Starter plan</h3>
                    <h4>{{upgradePlanService.upgradePlanData.currency_symbol}}{{dataService.getLocaledPriceInt(upgradePlanService.starterPlanAmount)}} <sub *ngIf="!shouldShowUpfrontDataForStarter">x {{upgradePlanService.monthCount}} months</sub></h4>
                </div>
                <div class="upfrontButton">
                    Upfront
                    <div class="toggleButton" [ngClass]="{'active': shouldShowUpfrontDataForStarter}" (click)="toggleUpfrontButtonForStarter()"></div>
                </div>
            </div>

            <p>Take up to {{upgradePlanService.upgradePlanData.starter_order_limit}} orders a month, on your own responsive website store.</p>

        </div>

        <div class="contentPart">
            <ul>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">Responsive website</div>
                </li>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">No seller fees</div>
                </li>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">Aftercare warranty
                        <div class="tooltipOverlayUpgradePlan" (click)="showHideUpgradeTooltip('aftercare')" *ngIf="afterCareTooltip"></div>
                        <div  class="infoTooltipUpgradePlan" (click)="showHideUpgradeTooltip('aftercare')">
                            <em  class="icon-info-circle"></em>
                            <div  class="tooltipText" *ngIf="afterCareTooltip"> Free Aftercare - Our built-in support keeps your website and apps up to date with no fuss. Plus, you get ongoing dedicated support.</div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">Free hosting</div>
                </li>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">Fully customizable URL:
                        <p><span>{{upgradePlanService.upgradePlanData.sub_domain}}</span></p>
                    </div>
                </li>
                <!-- <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">Own the code
                        <div class="tooltipOverlayUpgradePlan" (click)="showHideUpgradeTooltip('ownCode')" *ngIf="ownCodeTooltip"></div>
                        <div  class="infoTooltipUpgradePlan" (click)="showHideUpgradeTooltip('ownCode')">
                            <em  class="icon-info-circle"></em>
                            <div  class="tooltipText" *ngIf="ownCodeTooltip">Own the code- Owning your code is like owning your physical store. Without it, you won’t be able to make changes to your website and app. Others can’t provide this. But we can.</div>
                        </div>
                    </div>
                </li> -->
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">Additional features to scale your business
                        <div class="tooltipOverlayUpgradePlan" (click)="showHideUpgradeTooltip('additional')" *ngIf="additionalTooltip"></div>
                        <div  class="infoTooltipUpgradePlan" (click)="showHideUpgradeTooltip('additional')">
                            <em  class="icon-info-circle"></em>
                            <div  class="tooltipText" *ngIf="additionalTooltip">Additional features to scale your business- New features can be added for an additional fee to allow new functionality without needing a developer .</div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="iconType limitedMark"></div>
                    <div class="titleText">Max {{upgradePlanService.upgradePlanData.starter_order_limit}} sales per month</div>
                </li>
                <li>
                    <div class="iconType notMark"></div>
                    <div class="titleText">Mobile App</div>
                </li>
            </ul>
        </div>
        
        <div class="footerButton">
            <button type="button" (click)="upgradePlanToStarter()">Upgrade</button>
            <h3>Current plan</h3>
        </div>
    </div>

    <div class="planBox storePlan">
        <div class="topPart">
            <div class="planHeader">
                <div class="planThumb"><img src="../../../../../assets/images/diamond.svg" alt="" /></div>
                <div class="planTitle">
                    <h3>Own the store</h3>
                    <h4>{{upgradePlanService.upgradePlanData.currency_symbol}}{{dataService.getLocaledPriceInt(upgradePlanService.storePlanAmount)}} <sub *ngIf="!shouldShowUpfrontDataForStore">x {{upgradePlanService.monthCount}} months</sub></h4>
                </div>
                <div class="upfrontButton">
                    Upfront
                    <div class="toggleButton" [ngClass]="{'active': shouldShowUpfrontDataForStore}" (click)="toggleUpfrontButtonForStore()"></div>
                </div>
            </div>

            <p>Make unlimited sales - plus you'll get iOS and Android apps included.</p>

        </div>

        <div class="contentPart">
            <ul>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">Responsive website</div>
                </li>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">No seller fees</div>
                </li>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">Aftercare warranty ({{upgradePlanService.monthCount}} months)
                        <div class="tooltipOverlayUpgradePlan" (click)="showHideUpgradeTooltip('aftercare1')" *ngIf="afterCareTooltipStore"></div>
                        <div  class="infoTooltipUpgradePlan" (click)="showHideUpgradeTooltip('aftercare1')">
                            <em  class="icon-info-circle"></em>
                            <div  class="tooltipText" *ngIf="afterCareTooltipStore"> Free Aftercare - Our built-in support keeps your website and apps up to date with no fuss. Plus, you get ongoing dedicated support.</div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">Free hosting ({{upgradePlanService.monthCount}} months)
                        <div class="tooltipOverlayUpgradePlan" (click)="showHideUpgradeTooltip('hosting')" *ngIf="freeHostingTooltip"></div>
                        <div  class="infoTooltipUpgradePlan" (click)="showHideUpgradeTooltip('hosting')">
                            <em  class="icon-info-circle"></em>
                            <div  class="tooltipText" *ngIf="freeHostingTooltip">Free hosting (24 months) worth ₹94,848 - Best-in-class multi-cloud: Azure, AWS, and more. Just one bill (for a lot less). </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">Fully customizable URL:
                        <p><span>{{upgradePlanService.upgradePlanData.sub_domain}}</span></p>
                    </div>
                </li>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">Own the code
                        <div class="tooltipOverlayUpgradePlan" (click)="showHideUpgradeTooltip('ownCode1')" *ngIf="ownCodeTooltipStore"></div>
                        <div  class="infoTooltipUpgradePlan" (click)="showHideUpgradeTooltip('ownCode1')">
                            <em  class="icon-info-circle"></em>
                            <div  class="tooltipText" *ngIf="ownCodeTooltipStore">Own the code- Owning your code is like owning your physical store. Without it, you won’t be able to make changes to your website and app. Others can’t provide this. But we can.</div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">Additional features to scale your business
                        <div class="tooltipOverlayUpgradePlan" (click)="showHideUpgradeTooltip('additional1')" *ngIf="additionalTooltipStore"></div>
                        <div  class="infoTooltipUpgradePlan" (click)="showHideUpgradeTooltip('additional1')">
                            <em  class="icon-info-circle"></em>
                            <div  class="tooltipText" *ngIf="additionalTooltipStore">Additional features to scale your business- New features can be added for an additional fee to allow new functionality without needing a developer .</div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">Unlimited sales</div>
                </li>
                <li>
                    <div class="iconType tickMark"></div>
                    <div class="titleText">Mobile App</div>
                </li>
            </ul>
        </div>

        <div class="footerButton">
            <button type="button" (click)="upgradePlanToStore()">Upgrade</button>
            <h3>Current plan</h3>
        </div>
    </div>
</div>

</div>