<div class="uniqueCodeScreen">
  <div class="uniqueCodeHolder">
    <div class="uniqueCodeTop">
      <h3>Hello {{ partnerDataService.getPartnerUser()?.first_name }},</h3>
      <p>Thanks for registering with us!<br>
        We have sent you a Unique Code on your email id, kindly enter the code to proceed with configuration setup.</p>
      <p>Click on <span (click)="resendUniqueCode()">Resend</span> to receive the Unique code again.</p>
      <div class="uniqueCodeForm">
        <form [formGroup]="uniqueCodeForm" (ngSubmit)="onUniqueCodeSubmit(uniqueCodeForm)">
          <input type="text" (focus)="clearMessage()" formControlName="code">
          <button type="submit" class="submitButton" [disabled]="!uniqueCodeForm.valid">Verify
            <img *ngIf="loader" src="./../../../../assets/images/buttonLoader.gif" alt=""/>
          </button>
          <span class="successMsg" *ngIf="successMessage">{{ successMessage }}</span>
          <span class="errorMsg" *ngIf="errorMessage">{{ errorMessage }}</span>
        </form>
      </div>
    </div>
    <div class="uniqueCodeBottom">
      <button type="button" [disabled]="!isUniqueCodeVerified" (click)="goToConfigureScreen()">Click to Configure
      </button>
    </div>
  </div>
</div>
