import {Component, Input, OnInit} from '@angular/core';
import {Angulartics2Segment} from 'angulartics2/segment';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiRequests} from '@shared/services/api-requests.service';
import { Intercom } from 'ng-intercom';


@Component({
  selector: 'app-pay-confirm',
  templateUrl: './pay-confirm.component.html',
  styleUrls: ['./pay-confirm.component.scss']
})
export class PayConfirmComponent implements OnInit {
  @Input('billingInfo') billingInfo;

  showCreditDebit = true;
  showInternetBanking = false;
  showPaypal = false;
  showFinancing = false;

  constructor(public analyticsSegment: Angulartics2Segment,  public phaseService: PhaseService,
              public dataService: DataService, public router: Router,
              public apiRequest: ApiRequests, private apiRequests: ApiRequests, private _route: ActivatedRoute) {
    this.dataService.currentPage = 'payConfirm';
    if (!this.dataService.buildCardData.uniq_code) {
      if (dataService.user) {
        phaseService.billingInfo.firstname = this.dataService.user.first_name;
        phaseService.billingInfo.lastname = this.dataService.user.last_name;
        phaseService.billingInfo.email = dataService.user.email;
        this.setUserAddress();
      }
    }
    this.phaseService.billingInfo.name = this.phaseService.billingInfo.firstname + (this.phaseService.billingInfo.lastname ? ' ' + this.phaseService.billingInfo.lastname : '');
    this.phaseService.billingInfo.address = this.phaseService.billingInfo.apartment + (this.phaseService.billingInfo.street ? ' ' + this.phaseService.billingInfo.street : '');
  }

  ngOnInit() {
      if (!this.dataService.buildCardData.uniq_code) {
        this.getBuildCardIDOnReload();
        if (this.dataService.buildCardData.uniq_code) {
          const uniqueCode = this.dataService.buildCardData.uniq_code;
          this.dataService.showHideLoader(true);
          this.phaseService.fetchContractDetails(uniqueCode);
        }
      }
     this.phaseService.currencySymbol = this.dataService.user.currency.symbol;
  }

  public setUserAddress() {
    const userAddress = this.dataService.user.address,
      billingInfo = this.phaseService.billingInfo;
    billingInfo.contact = userAddress ? userAddress.phone.replace('-', '') : '';
    billingInfo.street = userAddress ? userAddress.street : '';
    billingInfo.apartment = userAddress ? userAddress.apartment : '';
    billingInfo.state = userAddress ? userAddress.state : '';
    billingInfo.city = userAddress ? userAddress.city : '';
    billingInfo.pincode = userAddress ? userAddress.pincode : '';
    billingInfo.country = userAddress ? userAddress.country : '';
    billingInfo.companyName = userAddress ? userAddress.company_name : '';
    billingInfo.gstNumber = userAddress ? userAddress.gst_number : '';
    billingInfo.firstname = userAddress && userAddress.first_name ? userAddress.first_name : billingInfo.firstname;
    billingInfo.lastname = userAddress && userAddress.last_name ? userAddress.last_name : billingInfo.lastname;
  }
  public getBuildCardIDOnReload(): void {
    this._route.parent.params.subscribe(params => {
      const uniq_code = params['id'];
      this.dataService.buildCardData.uniq_code = uniq_code;
    });
  }

  public showPaymentType(paymentMode) {
    this.showCreditDebit = false;
    this.showInternetBanking = false;
    this.showPaypal = false;
    this.showFinancing = false;
    switch (paymentMode) {
      case 'creditdebit':
        this.showCreditDebit = true;
        return;
      case 'internetbanking':
        this.showInternetBanking = true;
        return;
      case 'paypal':
        this.showPaypal = true;
        return;
      case 'financing':
        this.showFinancing = true;
        return;
      default:
        return;
    }
  }

  public isIndian() {
    if (this.dataService.buildCardData && this.dataService.buildCardData.currency && this.dataService.buildCardData.currency.code) {
      return ((this.dataService.buildCardData.currency.code === 'INR') ? true : false);
    }
  }

  public getInstallmentWithTax(installment: any) {
      return this.phaseService.getInstallmentWithTax(installment);
  }

  public getPaymentData() {
    return this.phaseService.paymentData;
  }

  public getBillingInfo() {
    return this.phaseService.billingInfo;
  }

  public showRequestDemo(tabName) {
    this.phaseService.setSignupAnalytics('talk_to_sales_click');
    this.phaseService.showTimeKitWindow(true);
  }

}
