import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import { CommonModule } from '@angular/common';
import { DragScrollModule } from 'ngx-drag-scroll';
import { HomeTemplatesComponent } from './home-templates/home-templates.component';
import { HomeTemplateCardComponent } from './home-template-card/home-template-card.component';
import { StudioTemplateComponent } from './studio-template/studio-template.component';
import { AllTemplateComponent } from './all-template/all-template.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TemplateFilterComponent } from './template-filter/template-filter.component';
import { AddNewTemplateComponent } from './add-new-template/add-new-template.component';
import { SortComponent } from './sort/sort.component';
import { TemplateLoaderComponent } from './template-loader/template-loader.component';
import { NguCarouselModule } from '@ngu/carousel';
import { SharedModule } from '@shared/shared.module';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import { ClipboardModule } from 'ngx-clipboard';
import { ScheduledCallCancellationComponent } from './scheduled-call-cancellation/scheduled-call-cancellation.component';
const childRouterConfig: Routes = [
  {path: '', component: HomeTemplatesComponent}
];

@NgModule({
  declarations: [
    StudioTemplateComponent,
    AllTemplateComponent,
    HomeTemplatesComponent,
    HomeTemplateCardComponent,
    TemplateFilterComponent,
    AddNewTemplateComponent,
    SortComponent,
    TemplateLoaderComponent,
    ScheduledCallCancellationComponent
  ],
  imports: [
    CommonModule,
    DragScrollModule,
    InfiniteScrollModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(childRouterConfig),
    SharedModule,
    ClipboardModule,
    NguCarouselModule,
    PerfectScrollbarModule
  ],
  providers: []
})
export class HomeTemplatesModule {
  constructor() {
  }
}
