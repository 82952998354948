<div class="commonPopUp active">
  <div class="popOverlay"></div>
  <div class="popHolder buildcardPopup">
    <div class="closePopUp" (click)="hidePopup()"><span class="icon-cancel"></span></div>
    <h3>Create Buildcard<sup class="tm">®</sup> for others</h3>
    <form>
      <ul>
        <li>
          <label class="customlabel">Enter email id</label>
          <input type="text" [(ngModel)]="emailForCardCreation" name="email">
        </li>
        <li>
          <label class="customlabel">Make them owner for Buildcard<sup class="tm">®</sup></label>
          <input type="radio" id="yes" name="ownerforbuilcard" [checked]="selectedOption === 0"
                 (click)="changeOption(0)"> <label class="customcheck" for="yes">Yes</label>
          <input type="radio" id="no" name="ownerforbuilcard" [checked]="selectedOption === 1"
                 (click)="changeOption(1)"> <label class="customcheck" for="no">No</label>
        </li>
        <li>
          <button type="button" class="submitButton" (click)="startCreatingCardForOthers()"
                  [disabled]="!emailForCardCreation || !isEmailValid()">Start Buildcard<sup class="tm">®</sup>
          </button>
        </li>
      </ul>
    </form>
  </div>
</div>
