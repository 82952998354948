<div class="phasebreakDetail"
     [ngClass]="{'active': sendFeedback, 'planDelivery1': (dataService.currentPage === 'delivery') || (dataService.currentPage === 'paymentPlan')}"
     xmlns="http://www.w3.org/1999/html">
  <div class="closeButton" (click)="closeFeedbackBox();"><em class="icon-cancel"></em></div>

  <ng-container *ngIf="dataService.homePageData.pricing_logic === 'new'">
    <div class="featurelistBox">
      <h3>Price Details</h3>

      <div class="headerRow">
        <div class="headingName">Customisation</div>
        <div class="headingName">Fixed</div>
        <div class="headingName">Total</div>
      </div>

      <div class="listHolder">
        <perfect-scrollbar class="myScrollbar" [config]="PERFECT_SCROLLBAR_CONFIG">
          <ul>
            <li *ngFor="let feature of phaseService.featureData">
              <div class="itemTitleTooltip" *ngIf="(featureDescriptionTop > 0) && isOverflowingWidth(featureTitle)" [ngStyle]="{'top': featureDescriptionTop + 'px', 'left': featureDescriptionLeft + 'px'}">{{ featuredescription }}</div>
              <span #featureTitle class="featureName" (mouseenter)='showDescription($event, feature?.title)' (mouseleave)='hideDescription(e)' [ngClass]="{ 'largeName': feature?.title?.length > 20 }">{{ feature?.title }}</span>
              <div class="priceRow">
                <span class="priceCost">{{ getcurrencySymbol() + dataService.getLocaledPrice(getFeatureCostUptoTwoDecimalPlaces(getCustomizationCost(feature))) }}</span>
                <span class="priceCost">{{ getcurrencySymbol() + dataService.getLocaledPrice(getFeatureCostUptoTwoDecimalPlaces(getFixedCost(feature))) }}</span>
                <span class="priceCost">{{ getcurrencySymbol() + dataService.getLocaledPrice(getFeatureTotalCost(getCustomizationCost(feature), getFixedCost(feature))) }}</span>
              </div>
            </li>
            <li *ngIf="showCareSection()" [ngClass]="{'careText': checkIfSubsWithPlus()}">
              <div class="itemTitleTooltip" *ngIf="(featureDescriptionTop > 0) && isOverflowingWidth(featureTitle)" [ngStyle]="{'top': featureDescriptionTop + 'px', 'left': featureDescriptionLeft + 'px'}">{{ featuredescription }}</div>
              <span #featureTitle class="featureName" (mouseenter)='showDescription($event,getCareTitle())'
                    (mouseleave)='hideDescription(e)'
                    [ngClass]="{ 'largeName': feature?.title?.length > 20 }">
                <span>{{getCareTitle()}}</span>
                <span *ngIf="checkIfSubsWithPlus()">upgrade</span>
              </span>
              <div class="priceRow">
                <span class="priceCost ifPriceZero"><strong>--</strong></span>
                <span class="priceCost ifPriceZero"><strong>--</strong></span>
                <span class="priceCost">{{ getcurrencySymbol() + dataService.getLocaledPrice(getCarePrice() | number : '1.0-0') }}</span>
              </div>
            </li>
          </ul>
        </perfect-scrollbar>
      </div>

    </div>
  </ng-container>

  <ng-container *ngIf="dataService.homePageData.pricing_logic !== 'new'">
    <div class="pricedetailBox" *ngIf="phaseService.phaseBreakupData">
      <h3>Price Details</h3>
      <ul>
        <li class="headRow"><span>Phases</span> <span>Duration</span> <span>Price</span></li>
        <ng-container *ngFor="
          let phaseData of phaseService.phaseBreakupData;
          let i = index">
          <li [ngClass]="{'headRow' : phaseData?.price === 'undefined' || !phaseData.selected}">
            <ng-container *ngIf="phaseData?.price !== 'undefined' && phaseData.selected">
              <span>{{phaseData?.title}}</span><span>{{phaseData?.weeks}} {{ (phaseData?.weeks) > 1 ? " weeks" : " week" }}</span>
              <span>{{getcurrencySymbol() + phaseData?.price}}</span>
            </ng-container>
            <ng-container *ngIf="phaseData?.price === 'undefined' || !phaseData?.selected">
              <span>{{phaseData?.title}}</span><span>Not Added</span>
            </ng-container>
          </li>
        </ng-container>
        <li class="bgRow discountRow applyPromoMobile">
          <img src="./../../../../../assets/images/promotion_icon.png" alt="" />
          <button *ngIf="!phaseService.isDiscountApplied()" type="button" (click)="callParentApplyPromotion()">Apply Promotion</button>
          <form *ngIf="(phaseService.promotionValid &&  !phaseService.promotionError && phaseService.isDiscountApplied())">
            <div class="applyPromoInner">
              <h4>{{ phaseService.finalPromoApplied?.toUpperCase() }} Applied <em class="icon-right"></em></h4>
              <div class="offerCancel" >
                <button type="button"  (click)="callParentRemovePromotion()" class="removePromo">Remove</button>
              </div>
            </div>
          </form>
        </li>
        <li class="bgRow discountRow appliedPromo hidden-xs" *ngIf="phaseService.isDiscountApplied()"><strong>{{ phaseService.finalPromoApplied?.toUpperCase() }} Applied <em class="icon-right"></em></strong>
          <strong></strong>
          <strong>- {{getcurrencySymbol()}}{{dataService.getLocaledPrice(discountedPrice()| number: '1.0-0')}}</strong>
        </li>
        <li class="bgRow discountRow visible-xs discount-bx-mobile" *ngIf="phaseService.isDiscountApplied()"><strong>Discount</strong>
          <strong></strong>
          <strong>- {{getcurrencySymbol()}}{{dataService.getLocaledPrice(discountedPrice()| number: '1.0-0')}}</strong>
        </li>
        <li class="bgRow" *ngIf="dataService.homePageData?.currency"><strong>Total</strong>
          <strong>{{getFinalWeeks() | number: '1.0-0'}}{{ (getFinalWeeks() | number: "1.0-0") > 1 ? " weeks" : " week" }}</strong>
          <ng-container *ngIf="phaseService.isDiscountApplied()">
            <strong>{{getcurrencySymbol()}}{{dataService.getLocaledPrice(getDiscountedWithSpecing() | number: '1.0-0')}}</strong>
          </ng-container>
          <ng-container *ngIf="!phaseService.isDiscountApplied()">
            <strong>{{getcurrencySymbol()}}{{dataService.getLocaledPrice(getTotalWithSpecing() | number: '1.0-0')}}</strong>
          </ng-container>
        </li>
        <li class="bgRow" *ngIf="dataService.homePageData?.currency"><strong>Weekly
          Price</strong><strong>{{getcurrencySymbol()}}{{dataService.getLocaledPrice(getWeekWisePrice()| number: '1.0-0')}}</strong>
        </li>
      </ul>
    </div>
    <div class="platformdetailBox" *ngIf="!phaseService.selectedHash.is_advance">
      <h3 *ngIf="!phaseService.checkIfOnlyRapidPrototypeSelected()">Platform <span (click)="openPlatformPopup(dataService.currentPage)">Change</span></h3>
      <h3 *ngIf="phaseService.checkIfOnlyRapidPrototypeSelected()">Devices <span class="custom-disabled-txt">Change</span></h3>
      <ul *ngIf="!phaseService.checkIfOnlyRapidPrototypeSelected()">
        <ng-container *ngFor="let platform of phaseService.selectedPlatforms; let i = index">
          <li><img [src]="platform.icon" alt="{{ platform.title }}"></li>
        </ng-container>
      </ul>
      <ul *ngIf="phaseService.checkIfOnlyRapidPrototypeSelected()">
        <ng-container *ngFor="let platform of phaseService.selectedPlatformsForRapidPrototype; let i = index">
          <li><img [src]="platform.icon" alt="{{ platform.title }}"></li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</div>

<!-- combo-feature infoDetail -->
<ng-container *ngIf="dataService.homePageData.pricing_logic !== 'new'">
  <div class="phasebreakDetail active" *ngIf="appDataService?.urlParameters.cpe_user">
    <div class="closeButton" (click)="closeFeedbackBox();"><em class="icon-cancel"></em></div>
    <div class="combo-price-box">
      <h3> Price Details </h3>
      <ul>
        <li>Combo Features <span>{{getcurrencySymbol() + '' + dataService.getLocaledPrice(getMonthlyPrice() | number: '1.0-0')}}{{ getPerMonthText() }}</span></li>
        <li>New Features <span>{{getcurrencySymbol() + '' + dataService.getLocaledPrice(phaseService.finalPricenWeeks.total | number: '1.0-0')}}{{ getPerMonthText() }}</span></li>
        <li class="total-txt">Combo + New Features <span class="total-amount">{{getcurrencySymbol() + '' + dataService.getLocaledPrice(getTotalWithSpecing() | number: '1.0-0')}}{{ getPerMonthText() }}</span></li>
      </ul>
    </div>
  </div>
  <platform-list
    class="forMobileNew"
    *ngIf="showPlatformPopup"
    (closeCallback)="closePlatformPopup()"
    [generalView]="true"
  ></platform-list>
</ng-container>

