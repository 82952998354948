<div class="dashHeading">Completed Projects

  <div class="searchCards" *ngIf="!(showRecentCompletedProjects && dataService.getCardCount('project_completed') > 0)">
    <em class="icon-search"></em>
    <input type="text" placeholder="Search for Buildcard" [(ngModel)]="dataService.searchText" (keyup)="getBuildCardsWithSearch()">
    <div class="clearButton" *ngIf="dataService.searchText"><em class="icon-cancel" (click)="getBuildCardsWithSearch('true')"></em></div>
  </div>

  <button type="button" (click)="showCards(11)" *ngIf="showRecentCompletedProjects && dataService.getCardCount('project_completed') > 2">Show all </button>
</div>

<div class="dashProjectRow" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300" (scrolled)="onScroll(dataService.getCardCount('project_completed'))">

  <div class="completedProjectBox"
       *ngFor="let card of (showRecentProgress ? (getCompletedProjectCards() | slice:0: (dataService.getCardCount('project_completed') > 2 ? 2: dataService.getCardCount('project_completed'))) : getCompletedProjectCards());let i = index;">
    <h2>{{ card?.project_name }}</h2>
    <div class="cardActionRight">
      <div class="cardMore">
        <em class="icon-more"></em>
        <div class="cardDropDown">
          <ul>
            <li (click)="cloneBuildCard(card.uniq_code, card.currency)">Duplicate card</li>
          </ul>
        </div>
      </div>
    </div>
    <h3><strong>Congratulations!</strong> Your Buildcard<sup class="tm">®</sup> is ready now</h3>
    <div class="carePhases">
      <ul>
        <li>Idea</li>
        <li>Buildcard<sup class="tm">®</sup></li>
        <li>Development</li>
        <li [ngClass]="{'notActive' : !card?.is_under_support}">
          Builder care
          <div class="noteIcon" *ngIf="card?.is_under_support">
            <em class="infoIcon icon-info-circle" (click)="opensupportinfo(i)"></em>
            <div class="infoTextOverlay" (click)="opensupportinfo()" *ngIf="openInfoIndex === i"></div>
            <div class="noteText active" *ngIf="openInfoIndex === i">
              <div class="closeIcon" (click)="opensupportinfo()"><em class="icon-cancel"></em></div>
              <app-builder-care-content *ngIf="checkCareType(card) === 'standard_care'"
                                        [careType]="'standardCare'" [isFromDeliveryPage]="false"
                                        [viewMoreSCare]="false" [subscriptionFlow]="card?.subscription_flow">
              </app-builder-care-content>
              <app-builder-care-content *ngIf="checkCareType(card) === 'standard_care_plus'"
                                        [careType]="'standardCarePlus'" [isFromDeliveryPage]="false"
                                        [viewMoreSCare]="false" [subscriptionFlow]="card?.subscription_flow">
              </app-builder-care-content>
              <app-builder-care-content *ngIf="checkCareType(card) === 'enterprise_care'"
                                        [careType]="'enterpriseCare'" [isFromDeliveryPage]="false"
                                        [viewMoreSCare]="false" [subscriptionFlow]="card?.subscription_flow">
              </app-builder-care-content>
              <app-builder-care-content *ngIf="checkCareType(card) === 'enterprise_care_plus'"
                                        [careType]="'enterpriseCarePlus'" [isFromDeliveryPage]="false"
                                        [viewMoreSCare]="false" [subscriptionFlow]="card?.subscription_flow">
              </app-builder-care-content>
              <!-- <p>Builder Care updates your software automatically so it never fails because of third-party changes.</p>
              <p>Updates, bug fixes and infrastructure monitoring are all taken care of.</p> -->
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!--<div class="builderCareRow" *ngIf="card?.is_under_support">
      <img class="builderBrandLogo" *ngIf="checkCareType(card) === 'standard_care'" src="../../../../assets/images/Builder_Care_3.svg" alt="Builder Care" alt="" />
      <img class="builderBrandLogo" *ngIf="checkCareType(card) === 'standard_care_plus'" src="../../../../assets/images/Builder_Care_Plus.svg" alt="Builder Care Plus" alt="" />
      <img class="builderBrandLogo" *ngIf="checkCareType(card) === 'enterprise_care'" src="../../../../assets/images/Builder_Enterprise_Care_3.svg" alt="Builder Enterprise care" alt="" />
      <img class="builderBrandLogo" *ngIf="checkCareType(card) === 'enterprise_care_plus'" src="../../../../assets/images/Builder_Enterprise_Care_Plus.svg" alt="Builder Enterprise Care Plus" alt="" />
      <p><strong>Smart move, you've added Builder Care</strong></p>
    </div>

    <div class="benefitsList">
      <h4>Benefits of selecting the builder care.</h4>
      <ul>
        <li>Confidence: <span>Round-the-clock technical support for 3rd party API/SDK updates.</span></li>
        <li>Relevance: <span>App upgrades for all latest Android/ iOS versions.</span></li>
        <li>Competitive edge: <span>Security patches (app and infrastructure level) and more.</span></li>
      </ul>
    </div>

    <div class="builderCareRow" *ngIf="!card?.is_under_support">
      <img src="../../../../../../../assets/images/builderCare.png" alt="" />
      <p>Get Builder Care for <strong>{{ card?.currency?.symbol + dataService.getLocaledPrice(card.support_maintenance_price) }}</strong>/month</p>
    </div>-->

    <div class="footerButton">
      <button class="btnViewBuilderHome" type="button" (click)="viewProgress(card)">View Builder Home</button>
      <button type="button" *ngIf="!card?.is_under_support" (click)="onBuilderCareClick(card)">Get Builder Care</button>
      <!-- <button type="button" *ngIf="card?.is_under_support" (click)="redirectToLive(card)">View Details</button> -->
    </div>
  </div>
  <app-progress-card-load class="progressCardload" *ngFor="let dummy of ' '.repeat(loaderCount).split(''), let x = index" [loaderCount]='loaderCount'></app-progress-card-load>
</div>
<div class="dashProjectRow running" *ngIf="!(dataService.completedProjectCards.length > 0) && !isApiSuccess">
  <app-progress-card-load class="progressCardload" *ngFor="let dummy of ' '.repeat(4).split(''), let x = index"></app-progress-card-load>
</div>
<div class="dashProjectRow" *ngIf="isApiSuccess && (dataService.completedProjectCards.length === 0)">
  <div class="norecordHeading">No records found.</div>
</div>

<div *ngIf="getBuilderCareClicked">
  <specing-payment class="specingCompo" [billingInfo]="phaseService.billingInfo" [buildCardInstallment]="true"
                   [paymentAmount]="getPaymentAmount()"
                   [paymentAmountWithoutTax] = "getPaymentAmountWithoutTax()"
                   [taxAmount] = "getTaxAmount()"
                   [paymentId]="getPaymentId()"
                   [isFromPayConfirm]="true"
                   [isFromBuilderCare]="true">
  </specing-payment>
</div>

<app-duplicate-card-modal *ngIf="commonService.isDuplicateCardFlow"></app-duplicate-card-modal>
