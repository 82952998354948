import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild,
  NavigationEnd,
  ActivatedRoute
} from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { CommonService } from "src/app/shared/shared-module/services/common.service";

@Injectable({
  providedIn: 'root',
 })
 
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private route: ActivatedRoute, private commonService: CommonService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['home']);
      return false;
    }

  }

  canActivateChild(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }

  public isAuthenticated() {
    return this.getCookie(environment.USER_COOKIE) ? true : false;
  }

  public getCookie(cname) {
    if (this.commonService.isPlatformBrowser) {
      const nameEQ = cname + '=';
      const ca = decodeURI(document.cookie).split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
      return null;
    }
  }
}
