<div class="paymentCards">
<h2>Payment</h2>
  <ng-container *ngIf="paymentCards.length && isFetchCardsApiResolved">
    <div class="savecardList">
      <ul>
        <ng-container *ngFor="let card of paymentCards">
          <li *ngIf="card.card_type">
            <div class="cardName">
              <img [src]="card.card_type ? getCardIcon(card) : cardsImageUrls[0].image" alt=""/>
              <div> {{ card.name_on_card }} ending in **{{ card.last_digit }} <span>Expiration: 12/22</span></div>
            </div>
            <div class="rightPart" [ngClass]="{'default': card.default_card}">
              <span class="icon-block disable-state" *ngIf="card?.default_card && (paymentCards.length === 1)"
                    title="At least 1 credit card has to be present">
                <em class="icon-deletenew"></em>
              </span>
              <span class="icon-block"  *ngIf="!card?.default_card"
                    (click)="makeCardDefaultOrDelete(card.id, 'delete')">
                <em class="icon-deletenew"> </em>
              </span>
              <span *ngIf="card.default_card" class="def-txt">  DEFAULT CARD </span>
              <span *ngIf="!card.default_card" (click)="makeCardDefaultOrDelete(card.id)" class="make-def-txt">  MAKE DEFAULT </span>
            </div>
          </li>
          <li *ngIf="card.customer_card_type">
            <div class="cardName">
              <img [src]="card.customer_card_type ? getCardIcon(card) : cardsImageUrls[0].image" alt=""/>
              <div> {{ card.customer_card_name }} {{(card.customer_card_type !== 'NBK') ? ('ending in ** ' + card.customer_card_no) : '' }} <span>Expiration: 12/22</span></div>
            </div>
            <div class="rightPart">
              <span class="icon-block disable-state" *ngIf="card?.default_card && (paymentCards.length === 1)"
                    title="At least 1 credit card has to be present">
                <em class="icon-deletenew"></em>
              </span>
              <span *ngIf="card.default_card" class="def-txt">  DEFAULT CARD </span>
              <span *ngIf="!card.default_card" class="icon-block" (click)="makeCardDefaultOrDelete(card.customer_card_id, 'delete')"> <em class="icon-deletenew"> </em></span>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>

    <div class="addNewcard" *ngIf="!showAddNewCard && dataService?.user?.currency?.code !== 'INR'">
      <button type="submit" (click)="addCard(true)"> Add New Card </button>
    </div>
  </ng-container>

  <ng-container *ngIf="!paymentCards.length && isFetchCardsApiResolved">
    <div class="noSaveCards">
      <img src="../../../../../../../assets/images/nosaveCard.svg" alt="" />
      <h3>No saved cards</h3>
      <p>You don't have any cards saved in your profile</p>
      <button type="submit" *ngIf="!showAddNewCard && dataService?.user?.currency?.code !== 'INR'" (click)="addCard(true)">Add new card</button>
    </div>
  </ng-container>

</div>


<div class="commonPopUp active" *ngIf="showAddNewCard">
  <div class="popOverlay"></div>
  <div class="popHolder addCardPopup">
    <div class="closePopUp" (click)="addNewCardClose()"><span class="icon-cancel"></span></div>

    <h3>New card</h3>
    <form [formGroup]="paymentCardForm">
      <ul>
        <li>
          <label for="cardNumber">Card Number*</label>
          <input type="text"  class="form-control" id="cardNumber" placeholder="Enter Card Number" value="" formControlName="number" ccNumber (click)="removeError()">
          <span *ngIf="showStripeCardErrors.invalidNumber" class="errormsg">The card number is incorrect and length should be 16 digit.</span>
          <div *ngIf="cardNumber.touched && cardNumber.invalid && !showStripeCardErrors.invalidNumber">
            <span *ngIf="!cardNumber.errors['required'] && cardNumber.invalid" class="errormsg">The card number is incorrect and length should be 16 digit.</span>
            <span *ngIf="cardNumber.errors['required']" class="errormsg"> The card number is required. </span>
          </div>
          <span *ngIf="isCardAlreadyAdded" class="errormsg"> Card already exist. Please add some other card. </span>
        </li>
        <li>
          <label for="cardName">Name on Card*</label>
          <input type="text" id="cardName" class="form-control" placeholder="Name On Card" formControlName="name">
          <span *ngIf="name.touched && name.invalid" class="errormsg"> The card holder name is required.  </span>
        </li>
        <li>
          <div class="halfLength">
            <label for="cardExpiry">Expiry Date*</label>
            <input type="text" class="form-control" id="cardExpiry" placeholder="MM/YYYY" value="" formControlName="expDate" ccExp (click)="removeError()">
            <span *ngIf="showStripeCardErrors.invalidExpiryYear" class="errormsg">The card's expiration date is invalid.</span>
            <div *ngIf="expDate.touched && expDate.invalid && !showStripeCardErrors.invalidExpiryYear">
              <span *ngIf="!expDate.errors['required'] && expDate.invalid" class="errormsg">The card's expiration date is invalid.</span>
              <span *ngIf="expDate.errors['required']" class="errormsg"> The card's expiration date is required </span>
            </div>
          </div>
          <div class="halfLength">
            <label for="cardCvv">CVV*</label>
            <input type="password" class="form-control" id="cardCvv" minlength="3" maxlength="4" placeholder="Enter CVV" value="" formControlName="cvc" ccCVC>
            <div *ngIf="cvc.touched && cvc.invalid">
              <span *ngIf="!cvc.errors['required'] && cvc.invalid" class="errormsg"> The cvv is incorrect.  </span>
              <span *ngIf="cvc.errors['required']" class="errormsg"> The cvv is required </span>
            </div>
          </div>
        </li>
      </ul>

      <div class="doneButton">
        <button type="submit" (click)="addCard(false)" [disabled]="paymentCardForm.invalid"> Add Card </button>
      </div>

    </form>


  </div>
</div>


<!-- popUp -->
<div class="commonPopUp active" *ngIf="showAddCardSuccesspopup">
  <div class="popOverlay"></div>
  <div class="popHolder buildcardname">
    <div class="closePopUp" (click)="closePopup()"><span class="icon-cancel"></span></div>
    <div class="cardnameIcon"> <!--<em class="icon-thumbs-up"></em>--> <img src="../../../assets/images/thumb-icon.png" alt="" /></div>
    <h3> Your card has been saved successfully! </h3>
    <button type="button" (click)="closePopup()"> View saved cards </button>
  </div>
</div>
