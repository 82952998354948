  <div class="loadingBoxTop"><span></span></div>
  <ul>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
  <ol>
    <li><span></span></li>
    <li><span></span></li>
    <li><span></span></li>
    <li><span></span></li>
  </ol>
  <div class="loadingBoxBottom"></div>
