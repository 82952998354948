import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress-card-load',
  templateUrl: './progress-card-load.component.html',
  styleUrls: ['./progress-card-load.component.css']
})
export class ProgressCardLoadComponent implements OnInit {

  @Input('loaderCount') loaderCount: number;



  ngOnInit() {
    if (!this.loaderCount && this.loaderCount !== 0 ) {
      this.loaderCount = 4;
    }
  }

}
