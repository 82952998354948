import { Injectable } from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {AppDataService} from '@rootmodule/app-data.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Intercom} from 'ng-intercom';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  customPhaseFeatures = [];
  phaseFeatures = [];

  constructor(private phaseService: PhaseService, private dataService: DataService, private appDataService: AppDataService,
              public analyticsSegment: Angulartics2Segment, ) { }

  setDefaultFeatureInCustomPhase(phase) {
    const customPhaseFeatures = [];
    const customPhasePlatforms = [];
    phase.features = [];
    for (const buildPhase of this.dataService.homePageData.build_phases) {
      if ((buildPhase['title'] === 'MVP') || (buildPhase['type'] === 'custom_phase')) {
        buildPhase.features.filter(feature => {
          if ((customPhaseFeatures.indexOf(feature.id) === -1)) {
            customPhaseFeatures.push(feature.id);
          }
        });
        if (!buildPhase.platforms) { buildPhase.platforms = []; }
        buildPhase.platforms.filter(feature => {
          if ((customPhasePlatforms.indexOf(feature.id) === -1)) {
            customPhasePlatforms.push(feature.id);
          }
        });
      }
    }
    for (const feature of this.phaseService.selectedFeatures) {
      if (customPhaseFeatures.indexOf(feature.id) === -1) {
        feature.platforms = Object.assign([], phase.platforms);
        phase.features.push(feature);
        if (phase.platforms && phase.platforms.length > 0) {
          phase.features.forEach(ftr => {
            ftr.phaseId = [];
            ftr.phaseId.push(phase.id + '' + phase.platforms[0].id);
          });
        }
        break;
      }
    }
  }

  setMvpSelected(selectedFeatures, phase) {
    const customPhaseFeatures = [];
    const phaseFeatures = phase.features.map(feature => feature.id);
    for (const buildPhase of this.dataService.homePageData.build_phases) {
      if ((buildPhase['title'] === 'MVP') || (buildPhase['type'] === 'custom_phase')) {
        if (!buildPhase.features) {
          buildPhase.features = [];
        }
        buildPhase.features.filter(feature => {
          if ((customPhaseFeatures.indexOf(feature.id) === -1) && (phaseFeatures.indexOf(feature.id) === -1)) {
            customPhaseFeatures.push(feature.id);
          }
        });
      }
    }
    this.customPhaseFeatures = customPhaseFeatures;
    this.phaseFeatures = phaseFeatures;
    selectedFeatures.forEach(feature => {
      feature.mvpSelected = false;
      phase.features.filter(f => {
        if (f.id === feature.id) {
          feature.mvpSelected = true;
        }
      });
    });
  }

  removeManualPromotion() {
    this.phaseService.promotionApplied = false;
    this.phaseService.isManualPromotionApplied = false;
    this.phaseService.promotionValid = false;
    this.dataService.manualPromotion = null;
    this.phaseService.manualPromoCode = null;
    this.phaseService.selectedHash.promotion_id = null;
    this.dataService.homePageData.promotion = null;
    this.phaseService.promotionError = null;
    this.appDataService.urlParameters.promotionCode = null;
    this.phaseService.urlBanner = null;
    this.phaseService.updatePrice();
    if (this.dataService.modifiedBuildCardData) {
      this.dataService.modifiedBuildCardData.promotion.add = null;
    }
  }

  setDataInRapidPrototype() {
    const allSpeeds = this.dataService.homePageData.speed;
    const phase = this.dataService.homePageData.build_phases.find(phase => phase.title === 'Professional Prototype');
    if (this.phaseService.checkIfOnlyRapidPrototypeSelected()) {
      if (phase.is_tailor_made && this.phaseService.selectedPlatformsForRapidPrototype && this.phaseService.selectedPlatformsForRapidPrototype.length > 0) {
        phase.platforms = this.phaseService.selectedPlatformsForRapidPrototype;
      } else if (this.checkIfSelectedPlatformsAreDevices()) {
        phase.platforms = [this.dataService.homePageData.platforms[0]];
      } else {
        phase.platforms = this.phaseService.selectedPlatforms;
      }
      if (phase.is_tailor_made) {
        this.phaseService.selectedSpeed = allSpeeds.find(speed => (speed.title === 'Standard'));
      } else if (this.dataService.checkIfEnterpriseUserOrCard()) {
        this.phaseService.selectedSpeed = allSpeeds.find(speed => (speed.title === 'Standard'));
      } else if (!this.dataService.buildCardData.progress.speed) {
        this.phaseService.selectedSpeed = allSpeeds.find(speed => (speed.title === 'Relaxed'));
      } else if (this.dataService.buildCardData.progress.speed) {
        this.phaseService.selectedSpeed = allSpeeds.find(speed => (speed.id === this.dataService.buildCardData.progress.speed['id']));
      }
    } else {
      phase.platforms = this.checkIfSelectedPlatformsAreDevices() ? [this.dataService.homePageData.platforms[0]] : this.phaseService.selectedPlatforms;
      if (this.dataService.checkIfEnterpriseUserOrCard()) {
        this.phaseService.selectedSpeed = allSpeeds.find(speed => (speed.title === 'Standard'));
      } else if (!this.dataService.buildCardData.progress.speed) {
        this.phaseService.selectedSpeed = allSpeeds.find(speed => (speed.title === 'Relaxed'));
      } else if (this.dataService.buildCardData.progress.speed) {
        this.phaseService.selectedSpeed = allSpeeds.find(speed => (speed.id === this.dataService.buildCardData.progress.speed['id']));
      }
    }
  }

  checkIfSelectedPlatformsAreDevices() {
    const devices = this.phaseService.selectedPlatformsForRapidPrototype.map(p => p.title);
    const areDevices = [];
    this.phaseService.selectedPlatforms.forEach(platform => {
      if (devices.indexOf(platform.title) !== -1) {
        areDevices.push(platform.title);
      }
    });
    return areDevices.length ? true : false;
  }

  removePromotion() {
    this.phaseService.promotionApplied = false;
    this.phaseService.isManualPromotionApplied = false;
    this.phaseService.promotionValid = false;
    this.dataService.manualPromotion = null;
    this.phaseService.manualPromoCode = null;
    this.phaseService.selectedHash.promotion_id = null;
    this.dataService.homePageData.promotion = null;
    this.phaseService.promotionError = null;
    this.appDataService.urlParameters.promotionCode = null;
    this.phaseService.urlBanner = null;
    if (this.dataService.modifiedBuildCardData) {
      this.dataService.modifiedBuildCardData.promotion.add = null;
    }
  }



  checkIfApprovedPlatform(platform1) {
    const buildCardData = this.dataService.buildCardData;
    if (this.phaseService.isPlatformRequest && buildCardData && buildCardData?.progress?.platforms?.length > 0) {
      const platforms = buildCardData['progress']['platforms'].map(p => p.id);
      return (platforms.includes(platform1.id) ? true : false);
    }
    return false;
  }
}
