<ng-container>
  <div class="container paymentContainer">
    <div class="row" [hidden]="!phaseService.removeLoader()">
      <div class="col-md-7">
        <div class="billingDetails">
          <h1>Billing Details
            <ng-container *ngIf="phaseService.checkIfBuildCardForRapidPrototype()">- <span>{{ dataService.returnNeededPrototypeName() }} </span></ng-container>
          </h1>
          <div class="formHolder">
            <form #form="ngForm" [ngClass]="{'disable-billing-form': (dataService.user.address_disabled && phaseService.addressSubmitted)}">
              <ng-container *ngIf="!dataService.user.address_disabled || dataService.user?.address?.billed_as">
                <div class="multiRadioContainer">
                  <div class="inputRow">
                    <label>Do you want to be billed as an individual or as a company (including llc, partnership firm or legal entity)?<sup>*</sup></label>
                    <div class="radioInputWrap">
                      <div class="inputBox" [ngClass]="{'active': !billingService.isCompanyAddress}">
                        <input type="radio" id="invidual" name="radio1" [checked]="!billingService.isCompanyAddress" (change)="changeAddressType('individual')">
                        <label for="invidual">Individual</label>
                      </div>
                      <div class="inputBox" [ngClass]="{'active': billingService.isCompanyAddress}">
                        <input type="radio" id="company" name="radio1" [checked]="billingService.isCompanyAddress" (change)="changeAddressType('company')">
                        <label class="label2" for="company">Company</label>
                      </div>
                    </div>
                  </div>
                </div>


              </ng-container>
              <div class="inputRow" *ngIf="billingService.isCompanyAddress">
                <label>Company Name<sup>*</sup></label>
                <div [ngClass]="{ 'customDropdown': (dataService.user.currency.id === 4) }">
                  <input #searchTextCompany type="text" name="companyName" [(ngModel)]="getBillingInfo().companyName" #companyName="ngModel"
                         minlength="1" (focus)="showCompanyDropdown()" (keyup)="getCompaniesList(searchTextCompany.value)"
                         placeholder="Please enter your company name as it is legally registered to the relevant tax authorities."
                         [disabled]="(dataService.user.address_disabled && phaseService.addressSubmitted && dataService.user?.address?.billed_as)">
                  <div class="dropdownList" *ngIf="((dataService.user.currency.id === 4) && showCompanyDropDown)">
                    <ul>
                      <li id="companyBox" *ngFor="let company of companiesList | searchStates: getBillingInfo().companyName"
                          (click)="selectCompany(company)">{{ company.name }}
                      </li>
                    </ul>
                  </div>
                </div>
                <span class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().companyName">Please enter valid company name</span>
              </div>
              <div class="inputRow divideHalf">
                <div class="halfBox">
                  <label>First Name<sup>*</sup></label>
                  <input type="text" name="firstname" [(ngModel)]="getBillingInfo().firstname" required #firstname="ngModel"
                      maxlength="100" minlength="1" [ngClass]="{'errorInput': isSaveButtonClicked && (!getBillingInfo().firstname || firstname.invalid)}"
                      (focus)="hideStatesOrCountriesList()" [disabled]="(dataService.user.address_disabled && phaseService.addressSubmitted)" autocomplete="given-name">
                    <span class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().firstname && firstname.invalid">Please enter valid first name</span>
                </div>

                <div class="halfBox">
                  <label>Last Name<sup>*</sup></label>
                  <input type="text" name="lastname" [(ngModel)]="getBillingInfo().lastname" required #lastname="ngModel"
                      maxlength="100" [ngClass]="{'errorInput': isSaveButtonClicked && (!getBillingInfo().lastname || lastname.invalid)}"
                      (focus)="hideStatesOrCountriesList()" [disabled]="(dataService.user.address_disabled && phaseService.addressSubmitted)"  autocomplete="family-name">
                    <span class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().lastname && lastname.invalid">Please enter valid last name</span>
                </div>
              </div>

              <div class="inputRow divideHalf">
                <div class="halfBox">
                  <label>Phone Number<sup>*</sup></label>
                  <app-phone-number [contactNumber]="getBillingInfo().contact" [isSaveButtonClicked]="isSaveButtonClicked"
                                    (setContactInUserInfo)="setUserContact($event)"
                                    [disableField]="(dataService.user.address_disabled && phaseService.addressSubmitted)">
                  </app-phone-number>
                </div>
                <div class="halfBox">
                  <label>E-mail Address<sup>*</sup></label>
                  <input type="text" name="email" [(ngModel)]="getBillingInfo().email" required email #email
                      maxlength="100" disabled (focus)="hideStatesOrCountriesList()" [disabled]="(dataService.user.address_disabled && phaseService.addressSubmitted)" autocomplete="email">
                    <span class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().email">Please enter valid email</span>
                </div>
              </div>

              <ng-template #notRequired>
                <div class="inputRow">
                  <label>Company Name</label>
                  <input type="text" required name="companyName" [(ngModel)]="getBillingInfo().companyName" #companyName="ngModel" minlength="1" maxlength="50"
                      (focus)="hideStatesOrCountriesList()" [disabled]="(dataService.user.address_disabled && phaseService.addressSubmitted)">
                  <span class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().companyName">Please enter valid company name</span>
                </div>
              </ng-template>

              <div class="inputRow">
                <label>Apartment Number</label>
                <input type="text" name="apartment" minlength="0" [(ngModel)]="getBillingInfo().apartment"
                       (focus)="hideStatesOrCountriesList()"
                       [disabled]="(dataService.user.address_disabled && phaseService.addressSubmitted)">
              </div>

              <div class="inputRow">
                <label>Address line 1<sup>*</sup></label>
                <input type="text" name="street" [(ngModel)]="getBillingInfo().street" required #street="ngModel"
                    maxlength="100" minlength="1" (focus)="hideStatesOrCountriesList()"
                    [ngClass]="{'errorInput': isSaveButtonClicked && (!getBillingInfo().street || street.invalid)}"
                    [disabled]="(dataService.user.address_disabled && phaseService.addressSubmitted)"
                    autocomplete="address-line1">
                <span class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().street">Please enter valid address</span>
              </div>

              <div class="inputRow">
                <label>Address line 2</label>
                <input type="text" name="address_line_2" [(ngModel)]="getBillingInfo().address_line_2"
                     #address_line_2="ngModel" maxlength="100" minlength="1" (focus)="hideStatesOrCountriesList()"
                     [disabled]="(dataService.user.address_disabled && phaseService.addressSubmitted)"
                     autocomplete="address-line2">
              </div>

              <div class="inputRow" *ngIf="!checkIfUSCustomer()">
                <label>City<sup>*</sup></label>
                <input type="text" name="city" [(ngModel)]="getBillingInfo().city"
                       (focus)="hideStatesOrCountriesList()" #city="ngModel" maxlength="100" minlength="1"
                       [disabled]="(dataService.user.address_disabled && phaseService.addressSubmitted)">
                       <span class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().city">Please enter valid City</span>
              </div>

              <div class="inputRow" *ngIf="checkIfUSCustomer()">
                <label>City<sup>*</sup></label>
                <div class="customDropdown" [ngClass]="{'active' : showCityDropDown}">
                  <input id="cityBox" type="text" name="city" placeholder="Please select a city"
                         [(ngModel)]="getBillingInfo().city" required #city="ngModel" maxlength="100" minlength="1"
                         [ngClass]="{'errorInput': isSaveButtonClicked && (!getBillingInfo().city || city.invalid)}"
                         [disabled]="(dataService.user.address_disabled && phaseService.addressSubmitted)"
                         (keyup)="handleBackSpace($event)" (focus)="showCityDropdown()" >
                  <input type="text" #searchTextCity name="searchcity" #searchcity="ngModel" [(ngModel)]="searchedText" class="hiddenInput">
                  <div class="dropdownList" *ngIf="showCityDropDown">
                    <ul>
                      <li id="cityBox" *ngFor="let city of renderCitiesInDropdown() | searchStates: searchedText"
                          (click)="selectCity(city)">{{ city?.name ? city.name : city }}</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="inputRow divideThree">
                <div class="threeBox">
                  <label>Country<sup>*</sup></label>
                  <div class="customDropdown" [ngClass]="{'active' : showCountryDropDown}">
                    <input id="countryBox" type="text" name="country" placeholder="Please select a country" [(ngModel)]="getBillingInfo().country" required #country="ngModel"
                      maxlength="100" minlength="1" [ngClass]="{'errorInput': isSaveButtonClicked && (!getBillingInfo().country || country.invalid)}"
                      (focus)="showStatesList()" [disabled]="((dataService.userCurrencyAndCountries?.available_country?.length === 1) ||
                      (dataService.user.address_disabled && phaseService.addressSubmitted))" (keyup)="handleBackSpace($event)">
                    <input type="text" #searchTextCountry name="searchCountry" #searchCountry="ngModel" [(ngModel)]="searchedText" class="hiddenInput">
                      <div class="dropdownList" *ngIf="showCountryDropDown">
                        <ul>
                          <li id="countryBox" *ngFor="let country of dataService.userCurrencyAndCountries?.available_country | searchStates: searchedText"
                            (click)="selectCountry(country)">{{ country.name }}</li>
                        </ul>
                      </div>

                  </div>
                  <span class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().country && !showCountryDropDown">Please enter valid country</span>
                </div>

                <div class="threeBox" *ngIf="ifNotUkOrCyprus()">
                  <label>State<sup>*</sup></label>
                  <div class="customDropdown" [ngClass]="{'active' : showStateDropDown}">
                    <input type="text" id="stateBox" name="state" placeholder="Please select a state" [(ngModel)]="getBillingInfo().state"
                           required maxlength="100" minlength="1" (keyup)="ShowDropdown()" #state="ngModel" (focus)="showStatesList('states')"
                           [ngClass]="{'errorInput': isSaveButtonClicked && (!getBillingInfo().state || state.invalid)}"
                           [disabled]="(dataService.user.address_disabled && phaseService.addressSubmitted) || !getBillingInfo().country"
                           (keyup)="handleBackSpace($event, 'states')">
                    <input type="text" #searchTextState name="searchState" #searchState="ngModel" [(ngModel)]="searchedText" class="hiddenInput">
                      <div class="dropdownList" *ngIf="showStateDropDown">
                        <ul>
                          <li id="stateBox" *ngFor="let state of selectedStates | searchStates: searchedText" (click)="selectState(state)">{{ state.name }}</li>
                        </ul>
                      </div>

                  </div>
                  <span class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().state && !showStateDropDown">Please enter valid state</span>
                </div>

                <div class="threeBox">
                  <label>Postcode / ZIP / Pin<sup>*</sup></label>
                  <input type="text" name="pincode" [ngClass]="{'errorInput': isSaveButtonClicked && !getBillingInfo().pincode}"
                      [(ngModel)]="getBillingInfo().pincode" required #pincode="ngModel" minlength="1" maxlength="12" (focus)="showStatesList('zipCode')"
                      [disabled]="(dataService.user.address_disabled && phaseService.addressSubmitted)" autocomplete="postal-code">
                    <span class="errorMsg" *ngIf="isSaveButtonClicked && (!getBillingInfo().pincode || pincode.invalid)">Please enter valid Zip/Postal Code</span>
                </div>
              </div>

              <div class="gstRow" *ngIf="(phaseService.showGSTIN && !dataService.user.address_disabled) || (phaseService.showGSTIN && phaseService.isGSTCheckboxChecked && phaseService.addressSubmitted)">
                <input type="checkbox" (click)="toggleInputFieldsForGST()" name="vehicle" value="" id="usegstin" [checked]="phaseService.isGSTCheckboxChecked"
                      (focus)="hideStatesOrCountriesList()" [disabled]="(dataService.user.address_disabled && phaseService.addressSubmitted)">
                <label for="usegstin"></label>
                <p>Use GSTIN for this Buildcard<sup class="tm">®</sup> (optional)</p>
              </div>

              <div class="inputRow divideHalf" *ngIf="phaseService.showGSTIN && phaseService.isGSTCheckboxChecked">
                <div class="halfBox">
                  <label>GST number</label>
                  <input type="text" name="gstNumber" [(ngModel)]="getBillingInfo().gstNumber" required #gstNumber="ngModel" minlength="1"
                      maxlength="100" (focus)="hideStatesOrCountriesList()" [ngClass]="{'errorInput': isSaveButtonClicked && (!getBillingInfo().gstNumber || gstNumber.invalid)}"
                      placeholder="eg. 06ABCDE1234F2Z5" pattern="^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$"
                      [disabled]="(dataService.user.address_disabled && phaseService.addressSubmitted)">
                    <span class="errorMsg" *ngIf="isSaveButtonClicked && (!getBillingInfo().gstNumber || gstNumber.invalid)">Please enter valid GST number</span>
                </div>
              </div>

            </form>
          </div>
        </div>

        <app-tax-details [isFromBillingDetails] = 'showTaxDetailsOnBillingPage'> </app-tax-details>

        <!--<div class="contracts">
          <h1>Terms and Conditions
            <em class="arrow down" *ngIf="!showSignContracts" (click)="updateShowSignContracts(true)"></em>
            <em class="arrow up" *ngIf="showSignContracts" (click)="updateShowSignContracts(false)"></em>
          </h1>
          <div *ngIf="showSignContracts">
            <div class="contractListBox">
              <p><input type="checkbox" [checked]="phaseService.contractTermsConditionsChecked" (change)="termsAndConditionsClicked($event)"
                id="t&c"> <label for="t&c"></label>Check here to indicate that you have read and agreed to the terms of the <span (click)="openTermsAndConditions()" class="greenbdr">Master Service Agreement</span>.</p>
            </div>
          </div>
        </div>-->
      </div>
      <div class="col-md-1">
      </div>
      <div class="col-md-4">
        <app-payment-summary></app-payment-summary>
        <div class="billingContinue">
          <button type="button"
                  [ngClass]="{ 'disable-continue-button': checkToDisable }"
                  class="continueBtn" (click)="continueToPayment()" (mouseover)="toggleToolTip(true)"
                  (mouseleave)="toggleToolTip(false)">Continue
            <span *ngIf="checkToDisable && (dataService.currentPage === 'billingDetails')"
                  class="tooltip-right-arrow">Please fill required billing information.
            </span>
            <span class="tooltip-right-arrow" *ngIf="isUserInviteeOfBuildCard()">
                  You can only make payments if you own this Buildcard
            </span>
            <span *ngIf="showMSATooltip" class="tooltip-right-arrow">
              Please tick the terms and conditions.
            </span>
          </button>
          <div class="tax-disclamer" *ngIf="dataService.buildCardData.currency?.tax > 0">
            <span>*</span>This tax amount is an estimate. We calculate the final amount of tax you need to pay, once we create your invoice.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="commonPopUp" [ngClass]="{'active' : phaseService.msaPopupScr}">
    <div class="popOverlay bgWhite" (click)="msapopupOpen()"></div>
    <div class="popHolder contractPopup">
      <div class="closePopUp" (click)="msapopupOpen()"><span class="icon-cancel"></span></div>

      <div class="msaPdfHtml">
        <h2>Engineer.ai Master Services Agreement</h2>
        <h3>Rates are confidential and must not be shared with any third parties</h3>
        <h4>Cover Sheet</h4>

        <div class="informationTable">
          <div class="titleRow">Customer information and key terms</div>
          <div class="itemColumn"><span>Customer Name</span></div>
          <div class="itemColumn"><span>Customer Address</span></div>
          <div class="itemColumn"><span>Primary Contact</span></div>
          <div class="itemColumn"><span>Primary Contact Email</span></div>
          <div class="itemColumn"><span>Primary Contact Phone</span></div>
          <div class="itemColumn"><span>Billing Contact</span></div>
          <div class="itemColumn"><span>Billing Contact Email</span></div>
          <div class="itemColumn"><span>Billing Contact Phone</span></div>
          <div class="itemColumn"><span>Effective Date</span></div>
          <div class="itemColumn"><span>Initial Term</span></div>
          <div class="itemColumn"><span>Billing Terms</span></div>
          <div class="itemColumn"><span>Currency</span></div>
        </div>

        <div class="serviceTable">
          <div class="titleRow">Services and Fees</div>
          <div class="tableRow borderNone">
            <div class="tableColumn blueBg"><strong>Services Provided <span>(check applicable Services)</span></strong></div>
            <div class="tableColumn blueBg"><strong>Description</strong></div>
            <div class="tableColumn blueBg"><strong>Fees</strong></div>
          </div>

          <div class="tableRow">
            <div class="tableColumn greyBg"><strong class="checkbox">Build</strong></div>
            <div class="tableColumn"><span>Services as per Work Order</span></div>
            <div class="tableColumn"><span>Fees as per Work Order</span></div>
          </div>

          <div class="tableRow">
            <div class="tableColumn greyBg"><strong class="checkbox">CloudOps Marketplace</strong></div>
            <div class="tableColumn"><span>Services as per Work Order</span></div>
            <div class="tableColumn"><span>Fees as per Work Order</span></div>
          </div>

        </div>


        <div class="pdfContent">
          <p>This Master Services Agreement ("<strong>Agreement</strong>") is made and entered into as of the Effective Date
            ("
            <strong>Effective Date</strong>") specified in the Cover Sheet to this Agreement ("<strong>Cover Sheet</strong>"),
            by and between
            <span class="fillSpace"></span> , a <span class="fillSpace"></span> with a place of business at <span class="fillSpace"></span>            ("<strong>Engineer.ai</strong>") and <span class="fillSpace"></span> , a <span class="fillSpace"></span> with
            a place of business at the Customer Address specified in the Cover Sheet ("<strong>Customer</strong>").</p>

          <p>In this Agreement, Engineer.ai and Customer are sometimes referred to individually as a “<strong>Party</strong>”
            and together as the “<strong>Parties.</strong>”</p>

          <ol>
            <li><strong>Scope of Services Provided.</strong>
              <ol>
                <li>
                  <u>Services Provided.</u> Engineer.ai shall provide to Customer the services listed and described in Work
                  Orders, as defined in
                  <u>Section 1.c</u> (Initialization and Engagement of Services) below, executed under this Agreement from time
                  to time. Customer acknowledges and agrees that the Services are provided to Customer on a non-exclusive
                  basis and that Engineer.ai may provide the same or similar services for, or on behalf of, Engineer.ai’s
                  other customers, provided that Engineer.ai shall observe its confidentiality obligations hereunder with
                  respect to any engagements with other customers.</li>

                <li>
                  <u>Account Registration; Engineer.ai Online Terms and Conditions.</u> In order to receive Services from Engineer.ai,
                  Customer must register an account on Engineer.ai’s platform, available at Engineer.ai’s platform websites
                  at <a href="https://www.builder.ai/">builder.ai</a> & cloudops.ai (collectively known as the “
                  <strong>Platform</strong>”). All persons who use the Engineer.ai Platform are subject to and required to
                  read and accept Engineer.ai’s online Terms & Conditions and associated Privacy Policy, both of which are
                  posted on Engineer.ai’s corporate website (“<strong>Terms & Conditions</strong>”) and are incorporated
                  herein by reference. If there is any conflict between this Agreement and the Terms & Conditions, the terms
                  of this Agreement shall control. From time to time, Engineer.ai may update the Terms & Conditions and will
                  give Customer thirty (30) days to accept the updated Terms & Conditions. If Customer (or any of its personnel)
                  does not accept the updated Terms & Conditions within such period, their access to the Engineer.ai Platform
                  shall be automatically terminated.
                </li>

                <li>
                  <u>Initialization and Engagement of Services.</u> In order to receive any Services, Customer must create a
                  Buildcard<sup class="tm">®</sup> (each, a “<strong>Buildcard<sup class="tm">®</sup></strong>”) or a service provision request with Engineer.ai using
                  the Platform (each, a “<strong>Work Order</strong>”) A Work Order becomes binding when Customer completes
                  and submits the Work Order on the Platform and Engineer.ai accepts the Work Order. Upon the acceptance
                  of a Work Order by Engineer.ai and Customer, Engineer.ai will be obligated to provide the Services and
                  deliver the deliverables (i.e Features of a Software Application) specified in such Work Order (“<strong>Deliverable(s)</strong>”).
                  For the avoidance of doubt, the terms of this Agreement will govern all Work Orders and Services undertaken
                  by Engineer.ai for Customer.</li>

                <li>
                  <u>Subcontracting.</u> Engineer.ai reserves the right to use one or more subcontractors to perform the Services
                  or render any Deliverables. Engineer.ai shall be responsible for all work performed by any such subcontractors.
                  Any subcontractor performing any of the Services or rendering any Deliverables on Engineer.ai’s behalf
                  will be bound by confidentiality restrictions no less restrictive than the confidentiality obligations
                  set forth in
                  <u>Exhibit A</u> (Non-Disclosure Terms).</li>

                <li>
                  <u>Performance.</u> Customer will identify to Engineer.ai a principal-point-of-contact (“CPE”) of Customer
                  for the Services. Engineer.ai will use commercially reasonable efforts to perform the contracted Services
                  in accordance with any agreed schedule of performance. Customer acknowledges, however, that Engineer.ai’s
                  ability to provide contracted Services in accordance with an agreed schedule of performance is dependent
                  on Customer’s provision of necessary access to relevant Customer personnel and Customer’s timely provision
                  of any information, approvals, decisions, or authorizations requested by Engineer.ai that are reasonably
                  necessary for Engineer’ai’s performance of the Services. Engineer.ai only confirms that it will deliver
                  the services described in a Buildcard<sup class="tm">®</sup> (for Builder.ai) within the maximum price limit set by the Build
                  Card; adherence to the delivery schedule is on a best efforts basis due to the aforementioned reasons.
                </li>

                <li>
                  <u>Change Orders.</u> From time to time, Customer may request a modification to the Services or any Deliverable
                  by submitting a request through the Platform describing the desired modifications to Engineer.ai (each
                  a “Change Order”). Upon the receipt of any Change Order, the Engineer.ai Platform will inform Customer
                  of the resulting changes to the price, budget, schedule, and other details of the Services and or Deliverables,
                  as applicable. For the avoidance of doubt, Engineer.ai may accept, reject, or modify any Change Order in
                  its sole discretion. No Change Order will be effective unless separately agreed upon and executed by Engineer.ai
                  through its Platform.
                </li>
              </ol>
            </li>

            <li><strong>Acceptance Procedure for Deliverables.</strong><br><br> Upon delivery of each Deliverable hereunder,
              Customer will have an opportunity review, evaluate and/or test the Deliverable in accordance with the following
              procedures to confirm that it satisfies, conforms with, or operates in all material respects in accordance
              with the acceptance criteria, specifications, or requirements for such Deliverable, as set forth in the applicable
              Work Order (collectively, the “Acceptance Criteria”). The Deliverable review and evaluation period shall be
              five (5) business days for a written Deliverable and fifteen (15) business days for a software Deliverable,
              or such other period as the Parties may agree to a specify in the applicable Buildcard<sup class="tm">®</sup> (each, an “Acceptance
              Period”). If a Deliverable fails to satisfy the applicable Acceptance Criteria, then Customer shall furnish
              to Engineer.ai through its Platform, within the Acceptance Period a reasonably detailed report that identifies
              the specific defects in the Deliverable (a “Defect Report”) and, if applicable, the modifications to the Deliverable
              that need to be made in order for the Deliverable to satisfy the applicable Acceptance Criteria. Upon receipt
              of a Defect Report, Engineer.ai will use commercially reasonable efforts to modify the Deliverable to rectify
              any confirmed defects identified in the Defect Report and re-submit the Deliverable to Customer to review,
              evaluate, and/or test in accordance with the terms of this Section 2 (Acceptance Procedures for Deliverables).
              The foregoing procedure will repeat up to 10 times times for any Deliverable, and if after the tenth (10th)
              such iteration Customer reasonably determines that the Deliverable still fails to satisfy the applicable Acceptance
              Criteria, then either Party may immediately terminate the Work Order for such Deliverable and/or this Agreement
              upon written notice to the other Party and Engineer.ai will refund to Customer a pro-rata amount as determined
              by the Engineer.ai Platform for the affected part of the Deliverable. Such refund shall constitute Customer’s
              sole and exclusive remedy for the failure.</li>

            <li><strong>Fees; Payment.</strong>
              <ol>
                <li>
                  <u>Fees.</u> Customer shall pay fees to Engineer.ai in accordance with the section of the Cover Sheet titled
                  “Services and Fees“ and the applicable Work Order. Engineer.ai shall invoice Customer on a weekly basis
                  in advance of each week unless otherwise agreed in writing by Engineer.ai in a side letter. If specified
                  in the applicable Work Order, Customer will reimburse Engineer.ai’s documented, out-of-pocket expenses
                  incurred in the performance of the Services no later than fifteen (15) days after Customer’s receipt of
                  Engineer.ai’s invoice (with such relevant verifying documentation) for such out-of-pocket expenses.</li>

                <li>
                  <u>Payments.</u> Customer shall pay all amounts due as specified in each Engineer.ai invoice within thirty
                  (14) days of the invoice date. Customer must notify Engineer.ai in writing of all disputes for each invoice
                  within seven (7) days of the invoice date; otherwise any disputes of such invoice shall be deemed waived
                  by Customer. Amounts due under this Agreement and not paid by the aforementioned due date shall incur interest
                  at the rate of two and one-half percent (2.5%) per month or the maximum rate permissible under applicable
                  law, whichever is lower, from such due date until the date that Engineering.ai actually receives full payment
                  for all such overdue amounts. Customer shall be responsible for the reasonable collection costs (including,
                  without limitation, legal fees and collection agency fees) incurred by Engineer.ai in its efforts to collect
                  such overdue amounts. Engineer.ai shall be entitled, in its sole discretion, to withhold performance and
                  discontinue provision of the Services until all amounts due are paid in full or to turn Customer over for
                  collection to a third-party agency.</li>

                <li>
                  <u>Taxes Excluded.</u> All amounts payable under this Agreement exclude all applicable sales, use and other
                  taxes. Customer will be responsible for filing and payment of all such taxes, other than taxes based on
                  Engineer.ai’s net income. Customer shall bear any compliance costs related to any such taxes owed under
                  this Agreement. Engineer.ai may file tax exemptions for any such taxes, if available, in a manner acceptable
                  to the applicable taxing authorities, and Customer shall not invoice Engineer.ai for any taxes for which
                  Engineer.ai has provided a suitable exemption certificate.</li>
              </ol>
            </li>

            <li><strong>Term and Termination.</strong>
              <ol>
                <li>
                  <u>Term.</u> The initial term of this Agreement (“<strong>Initial Term</strong>”) is set forth in the section
                  of the Cover Sheet titled “Initial Term” and shall automatically renew for successive one-year renewal
                  periods following the Initial Term unless terminated in accordance with the terms of this
                  <u>Section 4</u> (Term and Termination) (each, a “<strong>Renewal Period</strong>” and, together with the
                  Initial Term, the “<strong>Term</strong>”)</li>

                <li>
                  <u>Non-Renewal.</u> Either Party may decline to renew the Term for any reason or no reason by so notifying
                  the other Party in writing not less than sixty (60) days before the scheduled commencement of any Renewal
                  Period.
                </li>

                <li>
                  <u>Termination for Material Breach.</u> Notwithstanding anything to the contrary in this Agreement, either
                  Party may terminate this Agreement by written notice of termination to the other Party upon the other Party’s:
                  (i) material breach that is not cured within thirty (30) days following such other Party’s receipt of written
                  notice of such breach or (ii) becoming insolvent, bankrupt, or inability (or admission in writing of its
                  inability) to generally pay its debts as they mature, making an assignment for the benefit of its creditors,
                  ceasing to function as a going concern, or ceasing to conduct its operations in the normal course of business.
                  Termination by a Party under this paragraph shall be without prejudice to any other rights or remedies
                  available to the terminating Party.
                </li>

                <li>
                  <u>Survival.</u> Any term or condition of this Agreement required for the interpretation or enforcement of
                  this Agreement or necessary for the full observation and performance by each Party of all rights and obligations
                  arising prior to the date of termination shall survive the termination of this Agreement for any reason.
                  For the avoidance of doubt, the terms and conditions of this Agreement (including Customer’s obligation
                  to pay all accrued fees) shall continue to apply to any then-outstanding Work Order not terminated thereby
                  until its completion and fulfillment in due course.</li>
              </ol>
            </li>

            <li><strong>Intellectual Property</strong>
              <ol>
                <li>
                  <u>Introduction.</u> Engineer.ai’s ‘Builder’ Services are software development services provided utilizing
                  artificial intelligence, drawing from Engineer.ai’s library of existing components (“
                  <strong>Building Blocks</strong>”) so that its customers don’t pay to build what has been built before.
                  Since much or most of the program code comprising software Deliverables produced by Engineer.ai for its
                  customers is comprised of or leverages pre-existing components, Deliverables (which include source code)
                  are provided to Engineer.ai’s customers under license, not owned by them. This license will allow the customer
                  to make any and all changes to the code.</li>

                <li>
                  <u>Open Source Software.</u> Engineer.ai Deliverables may incorporate or be developed using software governed
                  by a license agreement that is considered by the Open Source Initiative to be an Open Source License (“<strong>Open Source Software</strong>”).
                  In such cases, the Deliverable or its accompanying ReadMe file or other documentation will contain the
                  attributions required by the governing Open Source Software license agreement and identify the governing
                  license agreement, which shall govern Customer’s use of the relevant Open Source Software. However, Engineer.ai
                  will give the Customer seven (7) days’ notice of its intent to create a Deliverable or develop a Deliverable
                  using Open Source Software that is subject to an Open Source Software license agreement that would require
                  Customer to make the source code of the Deliverable available to third parties upon request. If Customer
                  objects, Engineer.ai will not incorporate such Open Source Software into the Deliverable but will reserve
                  the right through its Platform to suspend the work for the Buildcard<sup class="tm">®</sup> until an alternative approach is
                  agreed and an updated Buildcard<sup class="tm">®</sup> (Work Order) is agreed with Customer.</li>

                <li>
                  <u>Deliverable License.</u> Engineer.ai hereby grants to Customer the following license with respect to each
                  Deliverable: a non-exclusive, perpetual, irrevocable, world-wide, non-transferable (except in connection
                  with a permitted assignment of this Agreement) right and license to use (or have used on its behalf) each
                  Deliverable internally in Customer’s and its affiliate’s business. The license only governs the use of
                  the Building Blocks. All other IPR created for Customer i.e the aggregate IPR from the combination of Building
                  Blocks with relevant customizations shall remain the exclusive property of Customer.</li>

                <li>
                  <u>Customer-owned Deliverable(s).</u> In a circumstance in which Customer has a business imperative to develop
                  and own part of or the entire Deliverable, Engineer.ai may agree, in its sole discretion, as an exception
                  to its generally applicable development model to develop a bespoke Deliverable for Customer according to
                  Customer’s specifications (a “<strong>Custom Feature</strong>”) – which Deliverable will be an original
                  work of authorship that does not utilize or incorporate any pre-existing components or program code. In
                  such a case, the applicable Work Order must state conspicuously and expressly that the Deliverable(s) or
                  part of the Deliverable in question is to be a bespoke Deliverable(s) for Customer comprised entirely of
                  newly written program code. In such a case, the Deliverable will be deemed to be a ‘work made for hire’
                  for Customer in which Customer will be the copyright owner. To the extent such a Deliverable does not constitute
                  a work made for hire for Customer under applicable law, Engineer.ai hereby does and will irrevocably assign
                  to Customer all of Engineer.ai’s right of copyright in and to the relevant Deliverable(s).
                </li>

                <li>
                  <u>Agreements with Developers.</u> Engineer.ai is responsible for having or obtaining a written agreement
                  with each entity that that provides professionals who contribute to the ‘Builder’ Services on behalf of
                  Engineer.ai that provides Engineer.ai with all necessary rights to fulfill its obligations under this Agreement,
                  including but not limited to the obligations of this Section 5 (Intellectual Property).</li>

                <li>
                  <u>Customer Materials.</u> All Customer materials or other property provided to Engineer.ai by Customer are
                  and shall remain the sole and exclusive property of Customer, subject to any third party rights, and Customer
                  shall own all of the rights, titles, and interest to the Customer materials or other property provided
                  to Engineer.ai by Customer pursuant to this Agreement, including (but not limited to) any and all patents,
                  copyrights, and trade secrets in connection therewith.
                </li>
              </ol>
            </li>

            <li><strong>Confidentiality; Data Privacy</strong>
              <ol>
                <li>
                  <u>Confidentiality.</u> All activities of the Parties under or in relation to this Agreement are subject to
                  the non-disclosure terms attached as Exhibit B (Non-Disclosure Terms) to this Agreement.</li>

                <li>
                  <u>Data Privacy.</u> If any of the Services provided by Engineer.ai pursuant to this Agreement will require
                  Engineer.ai to have access to and function as a data processor of personal information that is subject
                  to legal protections under U.S. or non-U.S. privacy laws, such Services shall be subject to and provided
                  in accordance with the Data Processing Agreement attached hereto as Exhibit C (Data Processing Agreement).</li>
              </ol>
            </li>

            <li><strong>Representations and Warranties</strong>
              <ol>
                <li>
                  <u>Mutual Representations and Warranties.</u> Each Party represents and warrants to the other that (i) it
                  has the requisite power and authority to enter into this Agreement and perform and fulfill its obligations
                  hereunder, (ii) its execution of and performance of its obligations under this Agreement do not and will
                  not conflict with or violate any other agreement to which it is a party or order by which it is bound,
                  (iii) this Agreement constitutes a legal, valid, and binding obligation when signed and delivered by both
                  Parties, and (iv) it will maintain a published privacy policy on its corporate website page that discloses
                  its practices regarding the collection, use and sharing of data and complies with applicable laws and regulations,
                  and that it will abide by such privacy policy.
                </li>

                <li>
                  <u>Customer Representations and Warranties.</u> Customer represents and warrants to Engineer.ai that: (i)
                  it has sufficient rights in and to and has secured all necessary rights, releases and approvals in order
                  to grant the licenses granted by Customer in this Agreement to Engineer.ai., and (ii) it will not use the
                  Engineer.ai Platform or infrastructure to upload any content that violates any applicable laws or regulations
                  or any rights of any third parties, including but not limited to infringement or misappropriation of any
                  copyright, patent, trademark, trade dress, trade secret, music, image or other proprietary or property
                  right, or that is unlawful, fraudulent, threatening, defamatory, obscene, pornographic, profane or hateful,
                  deceptive, libelous, hate-promoting or discriminatory or that encourages illegal behavior, or that includes
                  or constitutes false advertising, unfair competition, invasion of privacy or publicity rights, or that
                  contains any viruses, worms, Trojan horses, malware, spyware or other contaminants.</li>

                <li>
                  <u>Limited Engineer.ai Warranty for Services and Deliverables.</u> Engineer.ai warrants, for a period of forty-five
                  (45) days commencing when the results of Services are delivered to Customer, that the applicable Services
                  will have been performed in a workmanlike manner consistent with industry standards reasonably applicable
                  to the performance of such Services and that any Deliverables delivered by or on behalf of Engineer.ai
                  will, at the time of their delivery to Customer for acceptance, satisfy, conform with, or operate in all
                  material respects in accordance with their Acceptance Criteria, specifications, or requirements for such
                  Deliverable, as set forth in the applicable Work Order. If Customer believes there has been a breach of
                  this warranty, it must notify Engineer.ai in writing within the warranty period stating in reasonable detail
                  the nature of the alleged breach. If there has been a breach of this warranty, then Engineer.ai’s sole
                  obligation, and Customer’s exclusive remedy, will be for Engineer.ai to correct or re-perform, at no additional
                  charge, any affected Services or Deliverables to cause them to comply with this warranty. However, if Engineer.ai
                  is unable to correct a breach of this warranty after repeated efforts, Customer will also be entitled to
                  receive an equitable adjustment in Engineer.ai’s charges for the Services or Deliverable in question (up
                  to the total amount of such charges under the applicable Work Order) to reflect any reduction in the value
                  of the Services or Deliverable as a result of the uncorrected breach of warranty.</li>

                <li>
                  <u>Non-Infringement.</u> Engineer.ai warrants that any original works of authorship developed by Engineer.ai
                  personnel under a Work Order, including their use by Customer in unaltered form, will not, to Engineer.ai's
                  knowledge, infringe any third-party copyrights, patents or trade secrets that exist on the effective date
                  of the Work Order and that arise or are enforceable under the laws of the United States of America.</li>

                <li>
                  <u>DISCLAIMER.</u> THE EXPRESS REMEDIES SET FORTH IN
                  <u>SECTION 7.C</u> (
                  <u>Limited Engineer.ai Warranty for Services and Deliverables</u>) IS THE EXCLUSIVE REMEDIES FOR ANY AND ALL
                  CLAIMS BASED ON FAILURE OF ANY SERVICES OR DELIVERABLES, WHETHER BASED IN CONTRACT, NEGLIGENCE, STRICT
                  LIABILITY, OTHER TORT, OR OTHERWISE. THE EXPRESS WARRANTIES SET FORTH IN
                  <u>SECTION 7.C</u> (
                  <u>Limited Engineer.ai Warranty for Services and Deliverables</u>) AND SECTION 7.D (
                  <u>Non-Infringement</u>) ARE EXCLUSIVE AND IN LIEU OF ALL OTHER WARRANTIES, WHETHER WRITTEN, ORAL, IMPLIED
                  OR ARISING BY OPERATION OF LAW OR OTHERWISE WITH RESPECT TO THE SERVICES AND DELIVERABLES, INCLUDING WITHOUT
                  LIMITATION, ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT, WHETHER
                  OR NOT THE PURPOSE OR USE HAS BEEN DISCLOSED TO ENGINEER.AI AND WHETHER OR NOT THE DELIVERABLES ARE SPECIFICALLY
                  DESIGNED OR PRODUCED BY OR ON BEHALF OF ENGINEER.AI FOR CUSTOMER’S USE OR PURPOSE. ENGINEER.AI DOES NOT
                  GUARANTEE ANY OUTPUT OR RESULTS OF ANY OF THE SERVICES OR DELIVERABLES AND DOES NOT WARRANT THAT THE SERVICES
                  OR DELIVERABLES WILL BE UNINTERRUPTED OR ERROR-FREE</li>
              </ol>
            </li>

            <li><strong>Limitation of Liability.</strong>
              <ol>
                <li>
                  <u>Limitation on Certain Damages.</u> EXCEPT AS MAY ARISE UNDER EITHER PARTY’S INDEMNIFICATION OBLIGATIONS
                  IN
                  <u>SECTION 9</u> (INDEMNIFICATION) OR BREACH OF THE CONFIDENTIALITY OBLIGATIONS IN
                  <u>EXHIBIT B</u> (NON-DISCLOSURE TERMS), IN NO EVENT SHAL EITHER PARTY HAVE ANY LIABILITY WITH RESPECT TO
                  ANY CLAIM ARISING OUT OF OR RELATED TO THIS AGREEMENT FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
                  EXEMPLARY OR PUNITIVE DAMAGES OR FOR ANY LOST PROFITS, LOST ANTICIPATED SAVINGS OR LOSS OF BUSINESS OPPORTUNITY
                  OR GOODWILL SUFFERED OR INCURRED IN CONNECTION WITH THIS AGREEMENT, WHETHER UNDER TORT (INCLUDING NEGLIGENCE),
                  CONTRACT OR OTHER THEORIES OF RECOVERY, EVEN IF SUCH PARTY HAS BEEN OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY
                  OF SUCH DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF OR CORRUPTION OF DATA, OR LOSS OR DAMAGE RESULTING
                  FROM THIRD PARTY CLAIMS, AND ENGINEER.AI’S MAXIMUM LIABILITY UNDER THIS AGREEMENT SHALL NOT EXCEED THE
                  AMOUNTS PAID TO ENGINEER.AI AND ACTUALLY RECEIVED BY ENGINEER.AI PURSUANT TO THIS AGREEMENT DURING THE
                  SIX (6) MONTH PERIOD IMMEDIATELY PRECEDING THE TIME IN WHICH THE CLAIM INITIALLY AROSE.</li>

                <li>
                  <u>Force Majeure.</u> Except for any obligations to make payment, neither Party shall be liable to the other
                  for any non- performance or delay in the performance of any of its obligations hereunder due to any cause
                  beyond such Party’s reasonable control or due to acts of god, acts of civil or military authorities, terrorist
                  acts, fires, labor disturbances, floods, epidemics, governmental rules or regulations, war, riot, delays
                  in transportation, shortages of raw materials, shortages of services, power outages, or hacker attacks.
                  A Party’s whose performance is impaired by such a cause shall so notify the other Party and use commercially
                  reasonable efforts to work around or overcome the impairment and resume performance hereunder.</li>
              </ol>
            </li>

            <li><strong>Indemnification.</strong>
              <ol>
                <li>
                  <u>Personal Injury and Property Damage Claims.</u> If, as a result of one Party's negligence, a claim is asserted
                  against the other Party for bodily injury (including death) or damage to tangible property, the negligent
                  Party will reimburse the other Party for that portion of any claims the other Party pays for which the
                  negligent Party is legally responsible.</li>

                <li>
                  <u>Indemnification by Engineer.ai.</u> Engineer.ai will defend, indemnify, and hold harmless Customer and
                  its officers, directors, agents, and employees from any, losses, damages, liability, settlements, costs,
                  and expenses, including reasonable attorneys’ fees, arising from, directly or indirectly, any claim, demand,
                  or action by a third party (each, a “<strong>Claim</strong>”) that, if true, would constitute or be attributable
                  to a breach by Engineer.ai of its representations and warranties given in
                  <u>Section 7.d</u> (Non-Infringement). In such event Engineer.ai may, at its sole option and expense, either
                  obtain the right for Customer to continue using the allegedly infringing item(s) or replace or modify the
                  item(s) to resolve the Claim. If Engineer.ai finds that neither of these alternatives is available to it
                  on commercially reasonable terms, Engineer.ai may require Customer to return the allegedly infringing item(s),
                  in which case Customer will receive a refund of the amounts paid by it for the returned item(s) (i.e the
                  feature(s) affecting the overall Deliverable), less a reasonable adjustment for depreciation of the returned
                  item(s) during the period Customer used it. This paragraph states Engineer.ai's entire obligation to Customer
                  and Customer's exclusive remedy with respect to any alleged breach by Engineer.ai of its representations
                  and warranties given in
                  <u>Section 7.d</u> (Non-Infringement).</li>

                <li>
                  <u>Indemnification by Customer.</u> Customer acknowledges and agrees that by entering into and performing
                  its obligations under this Agreement, Engineer.ai is not assuming and should not be exposed to the business
                  and operational risks associated with Customer’s business. Therefore, except for claims covered by
                  <u>Section 9.b</u> (Indemnification by Engineer.ai), Customer shall defend, indemnify, and hold harmless Engineer.ai,
                  and its officers, directors, agents, and employees from any, losses, damages, liability, settlements, costs,
                  and expenses, including reasonable attorneys’ fees, arising from, directly or indirectly, any claim, demand,
                  or action by a third party (each, a “<strong>Claim</strong>”) arising out of or pertaining to Customer’s
                  or any end user’s use of Engineer.ai Deliverables.</li>

                <li>
                  <u>Notice and Process.</u> The foregoing indemnification obligations are conditioned on the indemnified Party:
                  (i) giving the indemnifying Party prompt notice of the relevant claim; (ii) reasonably cooperating with
                  the indemnifying Party at indemnifying Party's expense in the defense of such claim; and (iii) giving the
                  indemnifying Party the right to control the defense and settlement of any the claim, except that the indemnifying
                  Party shall not enter into any settlement that affects the indemnified Party’s rights or interests without
                  the indemnified Party’s prior written approval, which shall not be unreasonably withheld or delayed. The
                  indemnified Party shall have the right to participate in the defense at its expense using legal counsel
                  of its choosing</li>
              </ol>
            </li>

            <li><strong>Non-Solicitation</strong><br><br> During the Term and for 24 months after its expiration or termination,
              neither Party will, either directly or indirectly, solicit for employment or employ (except as permitted below)
              by itself (or any of its affiliates) any employee of the other Party (or any of its affiliates) who was involved
              in the performance of the Party’s obligations under this Agreement unless the hiring Party obtains the written
              consent of the other Party. The actual damages attributable to a breach of the provisions of this paragraph
              would be difficult to determine and prove. Accordingly, the Parties agree that if either Party breaches this
              paragraph, the breaching Party will be liable to the non-breaching Party for liquidated damages in an amount
              equal to the affected employee’s most recent annualized salary (including bonuses and incentive compensation)
              prior to the breach, such sum being a reasonable measure of the damages reasonably anticipated by the Parties.
              The foregoing restriction will not prohibit a general solicitation of employment in the ordinary course of
              business or prevent either Party from employing any employee who contacts such Party as a result of such a
              general solicitation or at his or her own initiative without any direct or indirect solicitation by or encouragement
              from such Party.
            </li>

            <li><strong>Miscellaneous</strong>
              <ol>
                <li>
                  <u>Interpretation.</u> Neither Party will be considered to be the drafter of this Agreement. Section headings
                  are included for convenience of reference only and are not to be used to interpret this Agreement. Unless
                  the context requires otherwise, (i) ”<strong>including</strong>” (and any of its derivative forms) means
                  including but not limited to, (ii) ”<strong>may</strong>” means has the right, but not the obligation to
                  do something and “<strong>may not</strong>” means does not have the right to do something, (iii) ”<strong>will</strong>”
                  and “
                  <strong>shall</strong>” are expressions of command, not merely expressions of future intent or expectation,
                  (iv) ”<strong>written</strong>” or “<strong>in writing</strong>” is used for emphasis in certain circumstances,
                  but that will not derogate from the general application of the notice requirements set forth in this Agreement
                  in those and other circumstances, (v) use of the singular imports the plural and vice versa, and (vi) use
                  of a specific gender imports the other gender(s).</li>

                <li>
                  <u>Independent Engineer.ai.</u> Engineer.ai’s relationship with Customer is that of an independent contractor,
                  and nothing in this Agreement is intended to, or shall be construed to, create a partnership, agency, joint
                  venture, employer-employee relationship or similar relationship.</li>

                <li>
                  <u>Notices.</u> All formal notices and other communications under this Agreement will be in writing and sent
                  or delivered to the recipient at its address set forth in the opening paragraph of this Agreement either
                  (i) in person or (ii) by registered or certified mail or air freight services that provide proof of delivery,
                  with postage or shipping fees prepaid. Notices given as described in the foregoing will be considered received
                  on the day of actual delivery or, if delivery is not accomplished due to some fault of the addressee, on
                  the day the notice was tendered for delivery. A Party may from time to time change its address or designee
                  for notification purposes by giving the other Party prior written notice of the new address or designee
                  in the manner provided above and the date on which it will become effective. The Parties may mutually agree
                  that certain types of routine approvals and notices of a non-legal nature may be given by electronic mail
                  or other informal means.</li>

                <li>
                  <u>Assignment.</u> Customer may not assign, delegate or otherwise transfer this Agreement, whether by operation
                  of law or otherwise, without Engineer.ai’s prior written consent. Any assignment in violation of the foregoing
                  shall be void. Engineer.ai may assign, delegate or otherwise transfer this Agreement, whether by operation
                  of law or otherwise, to a Group Engineer.ai entity as defined in
                  <u>Exhibit B</u> or in connection with a merger or sale of all or substantially all of its stock or assets
                  or otherwise. Subject to the foregoing, this Agreement shall be binding on permitted successors and assigns.</li>

                <li>
                  <u>Waiver.</u> No failure or delay by a Party in exercising any right, power or remedy will operate as a waiver
                  of that right, power or remedy, and no waiver will be effective unless it is in writing and signed by an
                  authorized representative of the waiving Party. If a Party waives any right, power or remedy, the waiver
                  will not waive any successive or other right, power or remedy that Party may have.
                </li>

                <li>
                  <u>Informal Dispute Resolution.</u> Before the Parties resort to formal legal proceedings to resolve any dispute
                  pertaining to this Agreement, they agree to schedule a mandatory meeting at a mutually agreeable location,
                  which meeting will be attended by Customer and a senior representative of Engineer.ai. At that meeting,
                  each side will present its side of the dispute and the participants will conduct good faith negotiations
                  in an attempt to resolve the dispute without the necessity of formal proceedings. If the matter is not
                  so resolved, the Parties reserve all applicable rights and remedies under this Agreement or available at
                  law or in equity</li>

                <li>
                  <u>Governing Law.</u> This Agreement shall be governed by and construed in accordance with the laws of the
                  jurisdiction specified in
                  <u>Exhibit B</u> (Engineer.ai Contracting Parties) corresponding to the residence of the Engineer.ai Group
                  contracting Party without regard to any conflicts of laws provisions thereof. The Parties agree that the
                  UN Convention on Contracts for the International Sale of Good is not applicable to this Agreement or the
                  relationship of the Parties under it. The Parties hereby irrevocably consent to the exclusive jurisdiction
                  of and venue in the courts sitting in such jurisdiction for the resolution of any dispute arising under
                  or relating to this Agreement. In any action or proceeding to enforce rights under this Agreement, the
                  prevailing Party will be entitled to recover costs and attorneys’ fees.</li>

                <li>
                  <u>No Third-Party Beneficiaries.</u> Except in connection with any indemnification obligations hereunder,
                  this Agreement is not intended to, and shall not, benefit any third party, or create any rights to any
                  third-party beneficiaries.</li>

                <li>
                  <u>Severability.</u> If any provision of this Agreement is held invalid by a court of competent jurisdiction,
                  such provision will be severed from this Agreement, and the remainder of this Agreement will remain in
                  full force and effect.</li>

                <li>
                  <u>Order of Precedence.</u> This the terms of this Agreement shall supersede any conflicting terms in any
                  exhibits, attachments, statements of work, invoices, supplementary terms and conditions, or other documents
                  relating to this Agreement, including, but not limited to any Work Orders or the Platform Terms.</li>

                <li>
                  <u>Entire Agreement; Amendment.</u> This Agreement constitutes the entire understanding between the Parties
                  and supersedes all prior and contemporaneous discussions and agreements between them with respect to its
                  subject matter. No amendment or modification of this Agreement shall be valid or binding on the Parties
                  unless made in writing and signed on behalf of each Party by its duly authorized representative</li>

                <li>
                  <u>Counterparts.</u> This Agreement and the Cover Sheet may be executed in separate counterparts, including
                  by facsimile, and by the different Parties on the same or separate counterparts. Any signed copy of this
                  Agreement or the Cover Sheet made by reliable means (e.g., photocopy or facsimile) will be considered an
                  original, and all signed counterparts will constitute one and the same instrument.</li>
              </ol>
            </li>
          </ol>


          <h3>Exhibit A <strong>Engineer.ai Contracting Parties</strong></h3>
          <p>Engineer.ai Group is comprised of company subsidiaries in a number of territories, which are identified in the
            table below</p>

          <table cellpadding="0" cellspacing="0" border="0" summary="">
            <tr>
              <th>Company Territory</th>
              <th>Contracting Entit</th>
              <th>Principal Address</th>
              <th>Legal Jurisdiction</th>
            </tr>
            <tr>
              <td>Americas (USA, Mexico and all South America)</td>
              <td>Engineer.ai Corp</td>
              <td>6300 Arizona Circle, Los Angeles, California 90045</td>
              <td>State of California, USA</td>
            </tr>
            <tr>
              <td>India</td>
              <td>Engineer.ai India Private Limited</td>
              <td>77B, Ground Floor, Sector-18, Ground Floor, Iffco Road, Gurgaon 122015</td>
              <td>India</td>
            </tr>
            <tr>
              <td>United Kingdom and Rest of the World</td>
              <td>Engineer.ai Global Limited</td>
              <td>1 Westferry Circus, Canary Wharf, London E14 4HD United Kingdom</td>
              <td>England</td>
            </tr>
          </table>
        </div>
      </div>

    </div>
  </div>

  <div class="commonPopUp" [ngClass]="{'active' : phaseService.ndaPopupScr}">
    <div class="popOverlay bgWhite" (click)="ndapopupOpen()"></div>
    <div class="popHolder contractPopup">
      <div class="closePopUp" (click)="ndapopupOpen()"><span class="icon-cancel"></span></div>

      <div class="ndaPdfHtml">
        <h2>MUTUAL NON-DISCLOSURE AGREEMENT</h2>



        <div class="pdfContent">
          <p>This Agreement is made and entered into, as of <span class="fillSpace"></span> (“Effective Date”), by and between
            the relevant Contracting Entity of Engineer.ai Group (“Company”), having a Principal Address as detailed in Appendix
            A and <span class="fillSpace"></span> &lt;company name&gt; having a principal place of business at <span class="fillSpace"></span>            &lt;company address&gt; (“Other Party”).</p>

          <p><strong><u>Definition of Confidential Information.</u></strong> “Confidential Information” means (a) any technical
            and non-technical information related to a party’s business and current, future and proposed products and services
            of each of the parties, including for example and without limitation, each party’s respective information concerning
            research, development, design details and specifications, financial information, procurement requirements, engineering
            and manufacturing information, customer lists, business forecasts, sales information and marketing plans and
            (b) any information a party has received from others that may be made known to the other party and which a party
            is obligated to treat as confidential or proprietary, except that information disclosed by a party to this Agreement
            (the “Discloser”) will be considered Confidential Information of Discloser by the other party (the “Recipient”),
            only if the information (a) is provided as information fixed in tangible form or in writing (e.g., paper, disk
            or electronic mail), is conspicuously designated as “Confidential” (or with some other similar legend) or (b)
            if provided orally, is identified as confidential at the time of disclosure and confirmed in writing within thirty
            (30) days of disclosure.</p>


          <p><strong><u>Nondisclosure and Nonuse Obligations.</u></strong> Recipient will not use any Confidential Information
            except to the extent necessary for the purpose described below the signatures to this Agreement (the “Purpose”)
            and Recipient will not disseminate or in any way disclose any Confidential Information to any person, firm, business
            or governmental agency or department, except as such disclosure is expressly permitted in this Agreement. Furthermore,
            neither party may disclose the existence of any negotiations, discussions or consultations in progress between
            the parties to any person, firm or business or to any form of public media without the prior written approval
            of the other party. Recipient shall treat all of Discloser’s Confidential Information with the same degree of
            care as Recipient accords to Recipient’s own Confidential Information, but not less than reasonable care. Recipient
            shall disclose Discloser’s Confidential Information only to those of Recipient’s employees, consultants and contractors
            who need to know the information to assist Recipient with respect to the Purpose. Recipient certifies that each
            of its employees, consultants and contractors will have agreed, either as a condition of employment or in order
            to obtain Discloser’s Confidential Information, to be bound by terms and conditions substantially similar to
            those terms and conditions applicable to Recipient under this Agreement. Recipient shall immediately give notice
            to Discloser of any unauthorized use or disclosure of Discloser’s Confidential Information. Recipient shall assist
            Discloser in remedying the unauthorized use or disclosure of Discloser’s Confidential Information.</p>


          <p><strong><u>Exclusions from Nondisclosure and Nonuse Obligations.</u></strong> Recipient’s obligations under Section
            2 (Nondisclosure and Nonuse Obligations) shall not apply to any of Discloser’s Confidential Information that
            Recipient can document: (a) was in the public domain at or subsequent to the time the Confidential Information
            was communicated to Recipient by Discloser through no fault of Recipient; (b) was rightfully in Recipient’s possession
            free of any obligation of confidence at or subsequent to the time the Confidential Information was communicated
            to Recipient by the Discloser; (c) was developed by employees or agents of Recipient independently of and without
            reference to any of Discloser’s Confidential Information; or (d) was communicated by Discloser to an unaffiliated
            third party free of any obligation of confidence. A disclosure by Recipient of any of Discloser’s Confidential
            Information (a) in response to a valid order by a court or other governmental body; (b) as otherwise required
            by law; or (c) necessary to establish the rights of either party under this Agreement shall not be considered
            to be a breach of this Agreement by the Recipient; provided, however, that Recipient provides prompt prior written
            notice thereof to the Discloser to enable Discloser to seek a protective order or otherwise prevent the disclosure.</p>

          <p><strong><u>Ownership and Return of Confidential Information and Other Materials.</u></strong> All of Discloser’s
            Confidential Information, and any Derivatives (defined below) thereof, whether created by the Discloser or Recipient,
            are the property of Discloser and no license or other rights to the Discloser’s Confidential Information or Derivatives
            is granted or implied hereby. For purposes of this Agreement, “Derivatives” shall mean: (a) for copyrightable
            or copyrighted material, any translation, abridgment, revision or other form in which an existing work may be
            recast, transformed or adapted; (b) for patentable or patented material, any improvement thereon; and (c) for
            material that is protected by trade secret, any new material derived from the existing trade secret material,
            including new material which may be protected under copyright, patent and/or trade secret laws. Recipient hereby
            does and agrees to irrevocably assign to Discloser all of Recipient’s rights, title in interest and interest
            in and to the Derivatives. All materials (including, without limitation, documents, drawings, papers, diskettes,
            tapes, models, apparatus, sketches, designs and lists) furnished by Discloser to Recipient (whether or not they
            contain or disclose Discloser’s Confidential Information) are the property of the Discloser. Within five (5)
            days after any request by Discloser, Recipient shall destroy or deliver to Discloser, at Discloser’s option,
            (a) all Discloser-furnished materials and (b) all materials in Recipient’s possession or control (even if not
            Discloser-furnished) that contain or disclose any of the Discloser’s Confidential Information. Recipient will
            provide Discloser a written certification of Recipient’s compliance with Recipient’s obligations under this Section.</p>

          <p><strong><u>Independent Development.</u></strong> Recipient may currently or in the future be developing information
            internally, or receiving information from other parties that may be similar to the Discloser’s Confidential Information.
            Accordingly, nothing in this Agreement will be construed as a representation or inference that Recipient will
            not develop or have developed products or services, that, without violation of this Agreement, might compete
            with the products or systems contemplated by the Discloser’s Confidential Information</p>

          <p><strong><u>Disclosure of Third Party Information.</u></strong> Neither party shall communicate any information
            to the other in violation of the proprietary rights of any third party.</p>

          <p><strong><u>No Warranty.</u></strong> All Confidential Information is provided by Discloser “AS IS” and without
            any warranty, express, implied or otherwise, regarding the Confidential Information’s completeness, accuracy
            or performance.</p>

          <p><strong><u>No Export.</u></strong> Recipient will obtain any licenses or approvals required by governmental authorities
            prior to exporting, directly or indirectly, any technical data acquired from Discloser pursuant to this Agreement
            or any product utilizing that data.</p>

          <p><strong><u>Term.</u></strong> This Agreement shall govern all communications between the parties that are made
            from the Effective Date to the date on which either party receives from the other written notice that subsequent
            communications shall not be so governed; provided, however, that a Recipient’s obligations under Section 2 (Nondisclosure
            and Nonuse Obligations) will continue in perpetuity with respect to the Discloser’s Confidential Information
            that the Recipient has previously received until the obligations no longer apply pursuant to Section 3 (Exclusions
            from Nondisclosure and Nonuse Obligations).</p>


          <p><strong><u>No Assignment.</u></strong> Neither party will assign or transfer any rights or delegate any performance
            under this Agreement without the prior written consent of the other party, which consent shall not be unreasonably
            withheld. All assignments and delegations in violation of the foregoing are void.</p>

          <p><strong><u>Injunctive Relief.</u></strong> A breach by Recipient of this Agreement will cause irreparable and continuing
            damage to Discloser for which money damages are insufficient, and Discloser shall be entitled to injunctive relief
            and/or a decree for specific performance, and other relief as may be proper (including money damages if appropriate),
            without the need to post a bond.</p>


          <p><strong><u>Notices.</u></strong> Any notice required or permitted by this Agreement shall be in writing and shall
            be delivered as follows, with notice deemed given as indicated: (a) by personal delivery, when delivered personally;
            (b) by overnight courier, upon written verification of receipt; (c) by or facsimile transmission, upon acknowledgment
            of receipt of electronic transmission; or (d) by certified or registered mail, return receipt requested, upon
            verification of receipt. Notice shall be sent to the addresses set forth above or to such other address as either
            party may provide in writing.</p>

          <p><strong><u>Governing Law; Forum.</u></strong> This Agreement shall be governed by and construed in accordance with
            the laws of the Jurisdiction without regard to any conflicts of laws provisions thereof and the Parties hereby
            consent to the exclusive jurisdiction of the Nominated Courts. In any action or proceeding to enforce rights
            under this Capacity Partner Agreement, the prevailing party will be entitled to recover costs and attorneys’
            fees.
          </p>


          <p>
            <u>Severability.</u> If a court of law holds any provision of this Agreement to be illegal, invalid or unenforceable,
            (a) that provision shall be deemed amended to achieve an economic effect that is as near as possible to that
            provided by the original provision and (b) the legality, validity and enforceability of the remaining provisions
            of this Agreement shall not be affected thereby.
          </p>

          <p><strong><u>Waiver; Modification.</u></strong> If a party waives any term, provision or a party’s breach of this
            Agreement, such waiver shall not be effective unless it is in writing and signed by the party against whom such
            waiver is asserted. No waiver by a party of a breach of this Agreement by the other party shall constitute a
            waiver of any other or subsequent breach by such other party. This Agreement may be modified only if authorized
            representatives of both parties consent in writing.</p>

          <p><strong><u>Entire Agreement.</u></strong> This Agreement constitutes the final and exclusive agreement between
            the parties with respect to the treatment of Confidential Information disclosed hereunder. It supersedes all
            agreements, whether prior or contemporaneous, written or oral, concerning the treatment of the Confidential Information.</p>


          <h3>Exhibit A <strong>Engineer.ai Contracting Parties</strong></h3>
          <p>Engineer.ai Group is comprised of company subsidiaries in a number of territories, which are identified in the
            table below</p>

          <table cellpadding="0" cellspacing="0" border="0" summary="">
            <tr>
              <th>Company Territory</th>
              <th>Contracting Entit</th>
              <th>Principal Address</th>
              <th>Legal Jurisdiction</th>
            </tr>
            <tr>
              <td>Americas (USA, Mexico and all South America)</td>
              <td>Engineer.ai Corp</td>
              <td>6300 Arizona Circle, Los Angeles, California 90045</td>
              <td>State of California, USA</td>
            </tr>
            <tr>
              <td>India</td>
              <td>Engineer.ai India Private Limited</td>
              <td>77B, Ground Floor, Sector-18, Ground Floor, Iffco Road, Gurgaon 122015</td>
              <td>India</td>
            </tr>
            <tr>
              <td>United Kingdom and Rest of the World</td>
              <td>Engineer.ai Global Limited</td>
              <td>1 Westferry Circus, Canary Wharf, London E14 4HD United Kingdom</td>
              <td>England</td>
            </tr>
          </table>
        </div>
      </div>

    </div>
  </div>
  <!-- popup --->
  <div class="commonPopUp" [ngClass]="{'active': isUserUpdatingAddress}" *ngIf="isUserUpdatingAddress">
    <div class="popOverlay"></div>
    <div class="popHolder resetBillingDetail">
      <div class="closePopUp" (click)="closeResetMessagePopup()"><span class="icon-cancel"></span></div>
      <h3> Saving details will reset previously signed documents </h3>
      <button type="button" [disabled]="disableSaveOnAddressUpdate" (click)="resetDocsAndSubmitAddress()"> Save</button>
    </div>
  </div>

  <div class="commonPopUp specCall-bx" [ngClass]="{'active': phaseService.countExitBillingDetails === 1}" *ngIf="phaseService.countExitBillingDetails === 1">
    <div class="popOverlay"></div>
    <app-meet-now class="popHolder speCallPopup_bx" [ngClass]="dataService.showQuestionsForInstantSpec ? 'questionnairePopup' : 'meetNowPopup'"
      [isFromBillingDetail]="true"></app-meet-now>
  </div>

</ng-container>

<div class="commonPopUp" [ngClass]="{'active': phaseService.isShowApproveChangeModalAfterSecurityPaid}" *ngIf="phaseService.isShowApproveChangeModalAfterSecurityPaid">
  <div class="popOverlay"></div>
  <div class="popHolder featureEditConfirm">
    <h2 class="subheader">We have received your request!</h2>
    <div class="midBlock">
      <p>Your account manager will review them. Post approval the changes would reflect in your Buildcard<sup class="tm light">TM</sup>.</p>
    </div>
    <div class="action-block">
      <div class="pull-right">
        <button type="button" class="active" (click)="goToDashboard()">Go to dashboard</button>
      </div>
    </div>

  </div>
</div>
