<div class="teamBox">
  <h4>Your team to be located in</h4>
  <div class="customDropdown" (click)="showTimezones()" [ngClass]="{'professionalPrototype': checkIfRoadMapOrTailorMade() && dataService.teams && (dataService.teams.all.length > 0)}">
    <div class="selectedValue">
      {{ phaseService.selectedTeam?.title }}
    </div>

    <div class="timezoneDropdown" *ngIf="showTimeZoneList">
      <div class="closeIcon" (click)="closeTimezones()"><em class="icon-cancel"></em></div>
      <div class="timezoneSearch">
        <input
          class="form-control"
          [(ngModel)]="searchedTimeZone"
          (keyup)="filterTimezones()"
          type="text"
          placeholder="Search"
        />
        <button type="submit">
          <em class="icon-magnifying"></em>
        </button>
      </div>
      <div class="timezoneList">
        <ul *ngIf="searchedTimeZone">
          <li
            class="canBeSelected"
            *ngFor="let team of searchResults"
            (click)="selectTeamTimezone(team)"
            [innerHtml]="team.title | highlight : searchedTimeZone"
            [ngClass]="{'selectedzone' : phaseService.selectedTeam.id === team.id}"
          >
          </li>
          <li *ngIf="searchResults.length === 0">
            There is no timezone matched, please select another.
          </li>
        </ul>

        <ul *ngIf="!searchedTimeZone">
          <li *ngIf="!noPopularTimezones"><strong>Popular Timezones</strong></li>
          <li
            class="canBeSelected"
            *ngFor="let team of teamData?.popular"
            (click)="selectTeamTimezone(team)"
            [ngClass]="{'selectedzone' : phaseService.selectedTeam.id === team.id}"
          >
            {{ team.title }}
          </li>
          <li><strong>All Timezones</strong></li>
          <li
            class="canBeSelected"
            *ngFor="let team of teamData.all"
            (click)="selectTeamTimezone(team)"
            [ngClass]="{'selectedzone' : phaseService.selectedTeam.id === team.id}"
          >
            {{ team.title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
