<div class="configureScreen">
  <div class="configureScreenHolder">
    <h2>Configure your Builder:</h2>
    <div class="whiteBg">
      <div class="configureTabs">
        <ul>
          <ul>
            <li
              [ngClass]="{'current': dataService.configuration.step1, 'complete': dataService.configuration.step2 || dataService.configuration.step3}">
              <div class="icon"></div>
              <span>Upload Logo</span></li>
            <li [ngClass]="{'current': dataService.configuration.step2, 'complete': dataService.configuration.step3}">
              <div class="icon"></div>
              <span>Choose Partnership Type</span></li>
            <li [ngClass]="{'current': dataService.configuration.step3}">
              <div class="icon"></div>
              <span>Setup Pricing & Team</span></li>
          </ul>
        </ul>
      </div>

      <!--step 1 start-->
      <div class="configureData" [ngClass]="{'active': dataService.configuration.step1}"
           *ngIf="dataService.configuration.step1">
        <div class="dataHolder">
          <ul>
            <li>
              <label>Upload your logo*
                <div class="logoInfo"><em class="icon-info"></em>
                  <div class="infoTooltip">Image size needs to be 100x40 Px</div>
                </div>
              </label>
              <div class="uploadLogo">
                <strong><em class="icon-paperclip"></em></strong>
                <span class="fileName" *ngIf="!partnerDataService.configData.logoFile">Upload your logo</span>
                <span class="fileName"
                      *ngIf="partnerDataService.configData.logoFile">{{ partnerDataService.configData.logoFile.name }}</span>
                <input type="file" id="uploadyourlogo" (change)="uploadLogo($event)">
                <label for="uploadyourlogo" class="uploadButton">{{ buttonText }}</label>
              </div>
              <span class="errorMsg" *ngIf="errorMessage">{{ errorMessage }}</span>
            </li>

            <li>
              <label>Choose your logo template*</label>
              <div class="logosRow">
                <div class="logoBox"
                     [ngClass]="{'selected': partnerDataService.configData.selectedTemplate === 'type_1'}"
                     (click)="selectTemplate('type_1')"><img src="../../../../../../assets/images/templateSet1.png" alt="" /></div>
                <div class="logoBox"
                     [ngClass]="{'selected': partnerDataService.configData.selectedTemplate === 'type_2'}"
                     (click)="selectTemplate('type_2')"><img src="../../../../../../assets/images/templateSet2.png" alt="" /></div>
                <div class="logoBox"
                     [ngClass]="{'selected': partnerDataService.configData.selectedTemplate === 'type_3'}"
                     (click)="selectTemplate('type_3')"><img src="../../../../../../assets/images/templateSet3.png" alt="" /></div>
                <div class="logoBox"
                     [ngClass]="{'selected': partnerDataService.configData.selectedTemplate === 'type_4'}"
                     (click)="selectTemplate('type_4')"><img src="../../../../../../assets/images/templateSet4.png" alt="" /></div>
                <div class="logoBox"
                     [ngClass]="{'selected': partnerDataService.configData.selectedTemplate === 'type_5'}"
                     (click)="selectTemplate('type_5')"><img src="../../../../../../assets/images/templateSet5.png" alt="" /></div>
              </div>
            </li>
          </ul>
        </div>

        <div class="configButton">
          <button type="button"
                  class="nextButton"
                  (click)="dataService.resetConfigurationViews();dataService.configuration.step2=true"
                  [disabled]="!partnerDataService.configData.logoFile || !partnerDataService.configData.selectedTemplate">
            Next
          </button>
        </div>
      </div>
      <!--step 1 close-->

      <!--step 2 start-->
      <div class="configureData" [ngClass]="{'active': dataService.configuration.step2}"
           *ngIf="dataService.configuration.step2">
        <div class="dataHolder">
          <ul>
            <li>
              <label>Allow customers to sign up*</label>
              <div class="customSelect">
                <select [(ngModel)]="partnerDataService.configData.isSignupAllowed" (change)="changePartnerType()">
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
                <em class="icon-sort"></em>
              </div>
            </li>
          </ul>
          <div class="chooseOptions">
            <div class="optionBox" [ngClass]="{'active': partnerDataService.configData.paymentMechanism === 'sell_to'}"
                 (click)="setPaymentMechanism('sell_to')">
              <h4>Sell To</h4>
              <p>Payment mechanism where Partner would be doing the payment on behalf of Customer on Builder. Partner
                would be redirected
                to Payment Gateway to set up the Credit card information in advance and then post Buildcard<sup class="tm light">®</sup> creation,
                customer raising payment
                request to Partner for processing payments.</p>
            </div>
            <div *ngIf="partnerDataService.configData.isSignupAllowed === 'true'" class="optionBox"
                 [ngClass]="{'active': partnerDataService.configData.paymentMechanism === 'sell_through'}"
                 (click)="setPaymentMechanism('sell_through')">
              <h4>Sell Through</h4>
              <p>Payment mechanism where customer opts to pay the desired amount using Builder Payment Gateway. For INR
                currencies, CCAvenue would
                be the desired platform. For other currencies, Stripe would be the chosen mechanism fo recurring
                payments.</p>
            </div>
          </div>
        </div>

        <div class="configButton">
          <button type="button" class="prevButton"
                  (click)="dataService.resetConfigurationViews();dataService.configuration.step1=true">Prev
          </button>
          <button type="button" class="nextButton"
                  (click)="dataService.resetConfigurationViews();dataService.configuration.step3=true">Next
          </button>
        </div>
      </div>
      <!--step 2 close-->

      <!--step 3 start-->
      <div class="configureData" [ngClass]="{'active': dataService.configuration.step3}"
           *ngIf="dataService.configuration.step3">
        <div class="dataHolder">
          <ul>
            <li>
              <label>Markup percentage (in %)*
                <div class="logoInfo"><em class="icon-info"></em>
                  <div class="infoTooltip">Markup percentage is percent increase or decrease in the
                    total feature cost. With negative percentage provided, the price would decrease and
                    with percentage value greater than 0, the price would increase.
                  </div>
                </div>
              </label>
              <input type="text" class="markup-input" [(ngModel)]="partnerDataService.configData.variance" max="100"
                     step="0.1" pattern="[-+]?(\d*[.])?\d+" (focus)="errorMessage = null"
                     (keyup)="limitMarkup($event);limitDecimal($event)" (keypress)="validateFloatKeyPress($event)">
              <span class="errorMsg" *ngIf="errorMessage">{{ errorMessage }}</span>
            </li>
            <li *ngIf="partnerDataService.configData.paymentMechanism === 'sell_to'">
              <label>Select Currency</label>
              <div class="currencyRow">
                <div class="currencyBox" *ngFor="let currency of partnerDataService.homePage?.currencies">
                  <input type="radio"
                         name="currencyselect"
                         [id]="currency.code"
                         [checked]="partnerDataService.selectedCurrency[0] === currency.id"
                         (change)="selectCurrency(currency)">
                  <label [for]="currency.code">
                    <img alt="" [src]="currency.icon_image_file_url">{{ currency.name }}
                  </label>
                </div>
              </div>
            </li>
            <li>
              <label>Invite your team
                <div class="logoInfo"><em class="icon-info"></em>
                  <div class="infoTooltip">Provide email address and press enter.</div>
                </div>
              </label>
              <div class="clearfix"></div>
              <div>
                <tag-input [(ngModel)]="partnerDataService.configData.invitedUsersData"
                           placeholder="Enter email"
                           [errorMessages]="chipsValidationMessages"
                           secondaryPlaceholder="Enter email"
                           [validators]="validators">
                </tag-input>
              </div>
            </li>

          </ul>
        </div>
        <div class="configButton">
          <button type="button" class="prevButton"
                  (click)="dataService.resetConfigurationViews();dataService.configuration.step2=true">Prev
          </button>
          <button type="button" class="nextButton"
                  (click)="onSubmitConfigData()"
                  [disabled]="(checkSellto() && !limitMarkup() || errorMessage)">
            {{(partnerDataService.configData.paymentMechanism === 'sell_to' ? 'Next' : 'Submit')}}
            <img *ngIf="loader" src="./../../../../assets/images/buttonLoader.gif" alt=""/>
          </button>
        </div>
      </div>
      <!--step 3 close-->

    </div>
  </div>
</div>
