import {Component, OnInit} from '@angular/core';
import {ApiRequests} from '@shared/services/api-requests.service';
import {ModelMapperService} from '@shared/services/model-mapper.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CreditCardValidator} from 'angular-cc-library';
import {DynamicScriptLoaderService} from '@shared/services/dynamic-script-loader.service';
import {DataService} from '@shared/services/data.service';
import {ToastrService} from 'ngx-toastr';
import {PaymentCardModel} from '../../../../../../shared/shared-module/models/payment-card-model/payment-card-model';
import {environment} from '@env/environment';

@Component({
  selector: 'app-payment-cards',
  templateUrl: './payment-cards.component.html',
  styleUrls: ['./payment-cards.component.scss']
})
export class PaymentCardsComponent implements OnInit {
  showAddNewCard = false;
  paymentCards: PaymentCardModel[] = [];
  paymentCardForm: FormGroup;
  showAddCardSuccesspopup = false;
  isFetchCardsApiResolved = false;
  stripeCardErrors = { invalidExpiryYear: 'invalid_expiry_year', invalidNumber: 'invalid_number' };
  showStripeCardErrors = { invalidExpiryYear: false, invalidNumber: false };
  isCardAlreadyAdded = false;
  cardsImageUrls = [
    { card_type: 'default', image: '../../../../../assets/images/default-card.png' },
    { card_type: 'American Express', image: '../../../../../assets/images/amex-card.svg' },
    { card_type: 'Diners Club', image: '../../../../../assets/images/diners-card.svg' },
    { card_type: 'Discover', image: '../../../../../assets/images/discover-card.svg' },
    { card_type: 'JCB', image: '../../../../../assets/images/jcb-card.svg' },
    { card_type: 'MasterCard', image: '../../../../../assets/images/master-card.svg' },
    { card_type: 'UnionPay', image: '../../../../../assets/images/unionpay-card.svg' },
    { card_type: 'Visa', image: '../../../../../assets/images/visa-card.svg' },
    { card_type: 'maestro', image: '../../../../../assets/images/maestro-card.svg' }
  ];
  stripePublishKey = '';

  constructor(private apiRequest: ApiRequests, private modelMapperService: ModelMapperService, private formBuilder: FormBuilder,
              private dynamicScriptLoaderService: DynamicScriptLoaderService, public dataService: DataService, private toasterService: ToastrService ) {

  }

  ngOnInit() {
    this.fetchListOfCards();
    this.initializeForm();
  }

  initializeForm() {
    this.paymentCardForm = this.formBuilder.group({
      number: ['', [Validators.required, CreditCardValidator.validateCCNumber]],
      name: ['', [Validators.required]],
      cvc: ['', [Validators.required, Validators.minLength, Validators.maxLength]],
      expDate: ['', [Validators.required, CreditCardValidator.validateExpDate]]
    });
  }

  loadScriptDynamically() {
    this.dynamicScriptLoaderService.load('stripe_payment').then((data) => {
      if (this.stripePublishKey) {
        (<any>window).Stripe.setPublishableKey(this.stripePublishKey);
      } else {
        (<any>window).Stripe.setPublishableKey(environment.STRIPE_PUBLISHABLE_KEY);
      }
    }).catch(error => {
    });
  }

  get cardNumber() {
    return this.paymentCardForm.get('number');
  }

  get expDate() {
    return this.paymentCardForm.get('expDate');
  }

  get cvc() {
    return this.paymentCardForm.get('cvc');
  }

  get name() {
    return this.paymentCardForm.get('name');
  }

  fetchListOfCards() {
    this.dataService.showHideLoader(true);
    this.apiRequest.fetchPaymentCardsList().subscribe((resp) => {
      this.paymentCards = [];
      this.paymentCards = resp.data ? this.modelMapperService.getMappedArrayModel(this.paymentCards, resp.data) : resp.cards;
      const areCardsAvailable = (this.paymentCards && this.paymentCards.length);
      if ((this.dataService.user.currency.id === 1) && areCardsAvailable) {
        this.paymentCards[0]['default_card'] = true;
      }
      if (!this.dataService.user.running_project_count && areCardsAvailable) {
        this.paymentCards.forEach(card => { card.default_card = false; });
      }
      if (!this.paymentCards) { this.paymentCards = []; }
      this.stripePublishKey = resp.stripe_publish_key;
      this.dataService.paymentCardsCount = this.paymentCards.length;
      this.isFetchCardsApiResolved = true;
      this.loadScriptDynamically();
      this.dataService.showHideLoader(false);
    }, error => {
      this.dataService.showHideLoader(false);
      this.isFetchCardsApiResolved = true;
    });
  }

  addCard(showHideAddNewCard) {
    if (showHideAddNewCard) {
      this.showAddNewCard = showHideAddNewCard;
      return;
    }
    const formValue = this.paymentCardForm.value;
    const cardData = {
      exp_month: parseInt(formValue.expDate.replace(' ', '').split('/')[0], 10),
      exp_year: parseInt(formValue.expDate.replace(' ', '').split('/')[1], 10),
      number: formValue.number.replace(/ /gi, '').trim(),
      cvc: formValue.cvc
    };
    this.dataService.showHideLoader(true);
    (<any>window).Stripe.card.createToken(cardData, (status: number, response: any) => {
      if (status === 200) {
        response = { card_token: response, name_on_card: formValue.name, billing_entity: this.dataService.user.billing_entity };
        this.apiRequest.addPaymentCard({ card: response }).subscribe((resp) => {
          if (resp.card_id && (resp.message === 'Card already exist. Please add some other card.')) {
            this.isCardAlreadyAdded = true;
            this.dataService.showHideLoader(false);
            return;
          }
          this.showAddNewCard = false;
          this.paymentCardForm.reset();
          this.fetchListOfCards();
          this.showAddCardSuccesspopup = true;
          this.removeError();
        }, error => {
          this.dataService.showHideLoader(false);
        });
      } else {
        this.dataService.showHideLoader(false);
        switch (response.error.code) {
          case this.stripeCardErrors.invalidExpiryYear:
            this.showStripeCardErrors.invalidExpiryYear = true;
            this.showStripeCardErrors.invalidNumber = false;
            break;
          case this.stripeCardErrors.invalidNumber:
            this.showStripeCardErrors.invalidNumber = true;
            this.showStripeCardErrors.invalidExpiryYear = false;
            break;
        }
      }
    });
  }

  makeCardDefaultOrDelete(cardId, deleteCard?) {
    this.dataService.showHideLoader(true);
    this.apiRequest.makePaymentCardDefaultOrDelete(cardId, deleteCard).subscribe((resp) => {
      this.fetchListOfCards();
    }, error => {
      this.dataService.showHideLoader(false);
    });
  }

  closePopup() {
    this.showAddCardSuccesspopup = false;
  }

  addNewCardClose() {
    this.showAddNewCard = false;
  }

  getCardIcon(card) {
    if (card.customer_card_type === 'NBK') {
      card.customer_card_name = 'default';
    }
    for (const cardData of this.cardsImageUrls) {
      if (card.card_type && (cardData.card_type.toLowerCase() === card.card_type.toLowerCase())) {
        return cardData.image;
      } else if (card.customer_card_name && (cardData.card_type.toLowerCase() === card.customer_card_name.toLowerCase())) {
        return cardData.image;
      }
    }
  }

  removeError() {
    this.showStripeCardErrors.invalidNumber = false;
    this.showStripeCardErrors.invalidExpiryYear = false;
    this.isCardAlreadyAdded = false;
  }

}
