import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {EmiComponent} from './emi/emi.component';
import {SignContractComponent} from './sign-contract/sign-contract.component';
import {PaymentConfirmationComponent} from './payment-confirmation/payment-confirmation.component';
import {PaymentStatusComponent} from './payment-status/payment-status.component';
import {PaymentContainerComponent} from './payment-container/payment-container.component';
import {MomentModule} from 'angular2-moment';
import {PaymentSubmissionComponent} from './payment-submission/payment-submission.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
/*import {MyDatePickerModule} from 'mydatepicker';*/
import {OrdinalPipe} from '@featuremodules/payment-builder-module/ordinal-pipe/ordinal-pipe';
import {PaymentBuilderService} from '@featuremodules/payment-builder-module/payment-builder.service';
import {PaymentPlanComponent} from './payment-plan/payment-plan.component';
import { BillingDetailsComponent } from './billing-details/billing-details.component';
import { PayConfirmComponent } from './pay-confirm/pay-confirm.component';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {PaymentSummaryModule} from '@featuremodules/payment-summary-module/payment-summary.module';
import {MeetNowModule} from '@featuremodules/meet-now-module/meet-now-module.module';
import {ShareButtonsModule} from '@ngx-share/buttons';
import { PaymentScreenComponent } from './payment-screen/payment-screen.component';
import { SpecCallScheduleScreenComponent } from './spec-call-schedule-screen/spec-call-schedule-screen.component';
import {AgmCoreModule} from '@agm/core';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PaymentService } from '@shared/services/payment.service';
const childRouterConfig: Routes = [
  {
    path: '', component: PaymentContainerComponent,
    children: [
      {path: '', component: PaymentPlanComponent, pathMatch: 'full'},
      {path: 'schedule', component: EmiComponent},
      {path: 'paymentPlan', component: PaymentPlanComponent},
      {path: 'billingdetails', component: BillingDetailsComponent},
      {path: 'payConfirm', component: PayConfirmComponent},
      {path: 'signcontract', component: SignContractComponent},
      {path: 'confirmation', component: PaymentConfirmationComponent},
      {path: 'status', component: PaymentStatusComponent}
    ]
  }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(childRouterConfig),
        SharedModule,
        MomentModule,
        GooglePlaceModule,
        PaymentSummaryModule,
        MeetNowModule,
        AngularMyDatePickerModule,
        ShareButtonsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCOX1noAtZT05VV_9jK9LXtUVn2PlXHl-8',
            libraries: ['places']
        }),
        // CKEditorModule
    ],
    declarations: [
        PaymentContainerComponent,
        EmiComponent,
        PaymentConfirmationComponent,
        PaymentStatusComponent,
        SignContractComponent,
        PaymentSubmissionComponent,
        OrdinalPipe,
        PaymentPlanComponent,
        BillingDetailsComponent,
        PayConfirmComponent,
        PaymentScreenComponent,
        SpecCallScheduleScreenComponent
    ],
    exports: [],
    providers: [
        PaymentBuilderService,
        PaymentService
    ]
})

export class PaymentBuilderModule {
}
