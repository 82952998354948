import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {Subscription} from 'rxjs/Rx';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Intercom} from 'ng-intercom';
import {finalize} from 'rxjs/internal/operators';

@Component({
  selector: 'app-tailor-made-prototype-cards',
  templateUrl: './tailor-made-prototype-cards.component.html',
  styleUrls: ['./tailor-made-prototype-cards.component.css']
})
export class TailorMadePrototypeCardsComponent implements OnInit {

  @Input() showRecentTailorMadePrototype: boolean;
  @Input() showRecentProgress: boolean;
  @Output() pshowCards = new EventEmitter();
  @Input('showMoreIndex') showMoreIndex;
  showDummyLoader: boolean;
  loaderCount = 0;
  searchSubscription: Subscription;
  isApiSuccess = false;

  constructor(public dataService: DataService, public phaseService: PhaseService, public apiRequest: ApiRequests,
              public analyticsSegment: Angulartics2Segment, ) { }

  ngOnInit(): void {
    console.log('in tailor made s ection');
  }

  getTailorMadePrototypeCards() {
    return this.dataService.tailorMadePrototypeCards;
  }

  viewProgress(card) {
    if (this.dataService.cookie_domain && this.dataService.cookie_domain === 'localhost') {
      window.location.href = `http://localhost:5000/#/project-progress/${card.uniq_code}/home`;
    } else {
      window.location.href = `${this.dataService.homePageData.builder_partner.builder_live_url}/#/project-progress/${card.uniq_code}/home`;
    }
  }

  showCards(type) {
    this.pshowCards.emit(type);
  }

  viewAddBranding(card, addBranding) {
    if (addBranding) {
      window.open(this.dataService.homePageData.builder_partner.builder_now_url + '/' + card.id + '/branding', '_blank', "noopener");
    } else {
      window.open(this.dataService.homePageData.builder_partner.builder_now_url + '/' + card.id + '/branding', '_blank', "noopener");
    }
  }

  onScroll(cardCount: number) {
    this.showDummyLoader = true;
    if (this.showMoreIndex !== 0 && window.pageYOffset > 0) {
      if (this.dataService.loadMore) {
        if (this.dataService.isScrolled) {
          if (cardCount > 10) {
            let pageNo = 0;
            if (this.phaseService.buildCardPage[this.showMoreIndex]) {
              pageNo = (this.phaseService.buildCardPage[this.showMoreIndex] > 0) ? this.phaseService.buildCardPage[this.showMoreIndex] : 0;
            }
            this.loaderCount = (((pageNo + 1) * 10) >= cardCount) ? 0 : (4 - (((pageNo + 1) * 10) % 4));
          }
          this.dataService.isScrolled = false;
          this.phaseService.buildCardPage[this.showMoreIndex]++;
          this.apiRequest.fetchBuildCards(this.phaseService.buildCardPage[this.showMoreIndex],
            this.phaseService.perPageCardCount, this.phaseService.currentSelectedBuildCardStatus).pipe(
            finalize(() => {
              this.loaderCount = 0;
            }))
            .subscribe(
              () => {
                this.dataService.isScrolled = true;
              }
            );
        }
      }
    }
  }

  getBuildCardsWithSearch(clearSearch?) {
    let perPage;
    if (clearSearch) {
      this.dataService.searchText = '';
    } else {
      perPage = this.phaseService.perPageCardCount;
    }
    this.phaseService.buildCardPage[this.showMoreIndex] = 0;
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    this.isApiSuccess = false;
    this.dataService.tailorMadePrototypeCards = [];
    this.searchSubscription = this.apiRequest.fetchBuildCards(0, perPage, this.phaseService.currentSelectedBuildCardStatus).pipe(
      finalize(() => {
        this.loaderCount = 0;
      }))
      .subscribe(
        () => {
          this.dataService.isScrolled = true;
          this.isApiSuccess = true;
        }
      );
  }

  getDiscountedprice(card) {
    const checkForChangeCost = !this.dataService.isEditBuildCardAfterSecurityPaid();
    const changeCost = (card.change_price_status ? card.change_amount : -card.change_amount);
    if (card.new_care_flow || this.dataService.buildCardData.new_care_flow) {
      return checkForChangeCost ? (card.bcard_discounted_price + changeCost) : card.bcard_discounted_price;
    } else {
      return checkForChangeCost ? (card.discounted_price + changeCost) : card.discounted_price;
    }
  }

}
