import {Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {AppDataService} from '@rootmodule/app-data.service';
import { CommonService } from "src/app/shared/shared-module/services/common.service";

@Component({
  selector: 'resetpassword',
  templateUrl: 'resetpassword.component.html',
  styleUrls: ['resetpassword.component.scss'],
})

export class ResetpasswordComponent {
  public dataService;
  public phaseService;
  public router;
  public success_error_Msg = '';
  public success = false;
  public show_reset = true;
  public isShowResetPopUp = true
  public reset_data = {reset_password_token: '', password: '', password_confirmation: ''};
  public resetPass = {'show': false, 'text': 'show'};
  public errorMsg = '';
  public preventClick = false;
  public showResetPwdError = false;
  public resetButtonClass = '';
  public isResetButtonClicked = false;
  @ViewChild('reset_password') public reset_password;

  /*@ngInject*/
  constructor(phaseService: PhaseService, private commonService: CommonService, dataService: DataService, router: Router, public apiRequest: ApiRequests, public appDataService: AppDataService) {
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    this.phaseService = phaseService;
    this.dataService = dataService;
    this.router = router;
    if (this.dataService.user) {
      this.phaseService.reset();
      this.router.navigate([this.dataService.firstPage]);
    }
  }

  public isResetPasswordError(): boolean {
    if (this.isResetButtonClicked) {
      return this.reset_password && this.reset_password.errors && (this.reset_password.pristine || this.reset_password.invalid);
    }
  }

  public isResetPasswordLengthCorrect(): boolean {
    return this.reset_password && this.reset_password.errors.minlength && !this.isResetPasswordRequiredError() && (this.reset_password.pristine || this.reset_password.invalid);
  }

  public isResetPasswordRequiredError(): boolean {
    if (this.isResetButtonClicked) {
      return this.reset_password && this.reset_password.errors && !this.reset_password.errors.minlength;
    }
  }

  resetPwdChanged(event) {
    this.resetButtonClass = '';
    this.showResetPwdError = false;
    this.isResetButtonClicked = false;
  }

  public resetPassword() {
    this.errorMsg = '';
    this.resetButtonClass = '';
    this.isResetButtonClicked = true;
    if (this.isResetPasswordError()) {
      this.showResetPwdError = true;
      return;
    }
    if (!this.preventClick) {
      this.preventClick = true;
      this.reset_data.password_confirmation = this.reset_data.password;
      this.reset_data.reset_password_token = this.appDataService.urlParameters.token;
      this.apiRequest.login_signup(this.reset_data, 'reset').subscribe((data: any) => {
        setTimeout(() => {
          this.resetButtonClass = 'successAnimation';
        }, 100);
        this.success_error_Msg = data.message;
        this.show_reset = false;
        this.success = true;
        this.preventClick = false;
      }, error => {
        const err = error.error;
        if (err.errors.email != null) {
          this.errorMsg = 'Email ' + err.errors.email;
        } else {
          this.errorMsg = err.message;
        }
        this.preventClick = false;
        this.resetButtonClass = 'errorAnimation';
      });
    }
  }

  public gotoLogin() {
    this.dataService.fromPasswordReset = true;
    this.router.navigate(['home']);
  }

  public ngOnDestroy() {
    this.dataService.resetPassword = false;
  }

  public showPasswordReset() {
    this.resetPass.show = !this.resetPass.show;
    if (this.resetPass.show) {
      this.resetPass.text = 'hide';
    } else {
      this.resetPass.text = 'show';
    }
  }

  public hideResetPopUp() {
    this.isShowResetPopUp = false;
  }
}
