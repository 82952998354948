import {Injectable} from '@angular/core';
import {DataService} from '@shared/services/data.service';
import {catchError, map} from 'rxjs/internal/operators';
import {Observable} from 'rxjs';
import {HttpRequests} from '@shared/services/http-requests.service';
import {Constants} from '@shared/utils/constants';
import {PartnerDashboardDataService} from './partner-dashboard-data.service';
import {environment} from '@env/environment';
import {Headers} from '@angular/http';
import {AppDataService} from '@rootmodule/app-data.service';
import {HttpHeaders} from '@angular/common/http';
declare let window: any;

@Injectable()
export class PartnerDashboardApiService {

  constructor(public appDataService: AppDataService,
              private _http: HttpRequests,
              public dataService: DataService,
              private _partnerDataService: PartnerDashboardDataService) {
  }

  public registerPartner(form): Observable<any> {
    const URI: string = environment.API_URL + Constants.apiEndPoints.partner_signup;
    const user: any = {
      builder_partner: {
        email: form.email,
        password: form.password,
        first_name: form.first_name,
        last_name: form.last_name
      }
    };

    return this._http.post(URI, user, this._partnerDataService.getRequestOptionArgs()).pipe(
      map(
        (response) => {
          return response;
        }), catchError(error => this._partnerDataService.handleError(error))
    );
  }

  public getPartnerData(): Observable<any> {
    const URI: string = environment.API_URL + Constants.apiEndPoints.partner_data;

    return this._http.get(URI, this._partnerDataService.getRequestOptionArgs()).pipe(
      map(
        (response) => {
          return response;
        }), catchError(error => this._partnerDataService.handleError(error))
    );
  }

  public resendVerificationLink(email: string): Observable<any> {
    const URI: string = environment.API_URL + Constants.apiEndPoints.partner_resend_verification + '?email=' + encodeURIComponent(email);

    return this._http.get(URI, this.appDataService.getRequestOptionArgs()).pipe(
      map(
        (response) => {
          return response;
        }), catchError(error => this._partnerDataService.handleError(error))
    );
  }

  public verifyPartner(token): Observable<any> {
    const URI: string = environment.API_URL + Constants.apiEndPoints.verify_partner + '?verification_token=' + token;

    return this._http.get(URI, this.appDataService.getRequestOptionArgs()).pipe(
      map(
        (response) => {
          return response;
        }), catchError(error => this._partnerDataService.handleError(error))
    );
  }

  public loginPartner(form): Observable<any> {
    const URI: string = environment.API_URL + Constants.apiEndPoints.partner_signin;
    const user: any = {
      email: form.email,
      password: form.password
    };

    return this._http.post(URI, user, this._partnerDataService.getRequestOptionArgs()).pipe(
      map(
        (response) => {
          return response;
        }), catchError(error => this._partnerDataService.handleError(error))
    );
  }

  public updatePartnerProfile(form?): Observable<any> {
    const URI = environment.API_URL + Constants.apiEndPoints.partner_profile + '/' + this._partnerDataService.getPartnerUser().id;
    form = form || this._partnerDataService.getPartnerUser();
    const data = {
      builder_partner: {
        partner_name: form.partnerName || form.partner_name,
        company_name: form.companyName || form.company_name,
        company_size: form.companySize || form.company_size,
        website_link: form.websiteLink || form.website_link,
        industry_type: form.industry || form.industry_type,
        spoc_name: form.spocName || form.spoc_name,
        spoc_role: form.spocRole || form.spoc_role,
        status: this._partnerDataService.partnerStatus,
        currency_ids: this._partnerDataService.selectedCurrency
      }
    };

    return this._http.put(URI, data, this._partnerDataService.getRequestOptionArgs()).pipe(
      map(
        (response) => {
          return response;
        }), catchError(error => this._partnerDataService.handleError(error))
    );
  }

  public fetchPartnerContractUrl(query: string): Observable<any> {
    const URI = environment.API_URL + Constants.apiEndPoints.fetch_partner_contract + query;

    return this._http.get(URI, this._partnerDataService.getRequestOptionArgs()).pipe(
      map(
        (response) => {
          return response;
        }), catchError(error => this._partnerDataService.handleError(error))
    );
  }

  public fetchContractDetails(partnerId: string): Observable<any> {
    const API = environment.API_URL + 'builder_partner/' + partnerId + '/contracts/';

    return this._http.get(API, this.appDataService.getRequestOptionArgs()).pipe(
      map(
        (response) => {
          return response;
        }), catchError(error => this._partnerDataService.handleError(error))
    );
  }

  public logoutPartner(): Observable<any> {
    const URI = environment.API_URL + Constants.apiEndPoints.partner_logout;

    return this._http.delete(URI, this._partnerDataService.getRequestOptionArgs()).pipe(
      map(
        (response) => {
          return response;
        }), catchError(error => this._partnerDataService.handleError(error))
    );
  }

  public updateMasterContract(contractId) {
    const URI = environment.API_URL + 'partner_contract/' + contractId;
    const contract: any = {};

    contract.nda_signed = true;
    contract.signature_request_guid = this.appDataService.urlParameters.signature_request_guid;
    contract.signature_request_status = this.appDataService.urlParameters.signature_request_status;

    return this._http.put(URI, contract, this._partnerDataService.getRequestOptionArgs()).pipe(
      map(
        (response) => {
          return response;
        }), catchError(error => this._partnerDataService.handleError(error))
    );
  }

  public fetchDocSignUrl(queryString): Observable<any> {
    const URI = environment.API_URL + Constants.apiEndPoints.partner_docsign + queryString;

    return this._http.get(URI, this._partnerDataService.getRequestOptionArgs()).pipe(
      map(
        (response) => {
          return response;
        }), catchError(error => this._partnerDataService.handleError(error))
    );
  }

  public updateNDAContract(partnerId, contract): Observable<any> {
    const URI = environment.API_URL + 'builder_partners/' + partnerId;

    return this._http.put(URI, contract, this._partnerDataService.getRequestOptionArgs()).pipe(
      map(
        (response) => {
          return response;
        }), catchError(error => this._partnerDataService.handleError(error))
    );
  }

  public resendUniqueCode(): Observable<any> {
    const URI = environment.API_URL + Constants.apiEndPoints.resend_unique_code;

    return this._http.get(URI, this._partnerDataService.getRequestOptionArgs()).pipe(
      map(
        (response) => {
          return response;
        }), catchError(error => this._partnerDataService.handleError(error))
    );
  }

  public checkUniqueCode(form): Observable<any> {
    const URI = environment.API_URL + Constants.apiEndPoints.check_unique_code;
    const code = {
      uniq_code: form.code
    };

    return this._http.post(URI, code, this._partnerDataService.getRequestOptionArgs()).pipe(
      map(
        (response) => {
          return response;
        }), catchError(error => this._partnerDataService.handleError(error))
    );
  }

  public configureBuilder(config): Observable<any> {
    const URI = environment.API_URL + Constants.apiEndPoints.configure_buidler;
    const form = new FormData();
    let headers = new HttpHeaders();
    const token = this._partnerDataService.getPartnerUser().authtoken;
    if (token) {
      headers = headers.append('authtoken', token);
    }
    const invites = this._partnerDataService.configData.invitedUsersData.map(data => data.value);
    form.append('builder_partner[variance_percentage]', this._partnerDataService.configData.variance);
    form.append('builder_partner[is_customer_allowed]', config.isSignupAllowed);
    form.append('builder_partner[partner_type]', config.paymentMechanism);
    form.append('builder_partner[logo_template]', config.selectedTemplate);
    form.append('builder_partner[logo]', config.logoFile, config.logoFile.name);

    for (let i = 0; i < invites.length; i++) {
      form.append('builder_partner[team][]', invites[i]);
    }

    return this._http.put(URI, form, headers).pipe(
      map(
        (response) => {
          return response;
        }), catchError(error => this._partnerDataService.handleError(error))
    );
  }

}
