<div class="referralPage">
    <div class="referralPageSection">
        <div class="referralPageHeader">
            <div class="headerLeft">
                <button *ngIf="referralService.showMainReferralComponent" type="button" class="backButton" (click)="goBackToDashboard()"><em class="icon-leftarrownew"></em> Back</button>
                <h2>Earn points (and save money!) for succesful referrals</h2>
                <p>Refer your friends and when they build with us, you'll both earn points to spend on our services - from app building, to maintenance or hosting.</p>
                
                <div class="referralCodeBox">
                    <div class="codeText">{{referralService.getShareURLLink()}}</div>
                    <div class="referralButtons">
                        <button type="button" class="codeCopy" (click)="copyReferralLinkToClipboard()">
                        <div class="customTooltip">
                            <span *ngIf="!isCopyClipboardClicked">Click to copy</span>
                            <span *ngIf="isCopyClipboardClicked">Copied!</span>
                        </div>
                        Copy
                        </button>
                        <button type="button" class="codeShare" (click)="referralService.showShareReferralPopup()">Share</button>
                    </div>
                </div>

                <h6>Share this unique referral link with friends. <span (click)="redirectToTAndCLink()">T&C Apply</span></h6>
            </div>
            <div class="headerRight"><img src="../../../../../assets/images/referral-img.png" alt="" /></div>
        </div>
    </div>

    <div class="referralStepSection">
        <div class="referralStepHolder">
            <h2>In 3 simple steps</h2>
            <ul>
                <li>
                    <div class="stepPointer">1</div>
                    <h3>Share</h3>
                    <p>Share your unique referal link with your friends</p>
                </li>
                <li>
                    <div class="stepPointer">2</div>
                    <h3>Start project</h3>
                    <p>Wait for them to start their project with us</p>
                </li>
                <li>
                    <div class="stepPointer">3</div>
                    <h3>Get points</h3>
                    <p>You both earn 1,000 Builder Points (at least) worth $1,000</p>
                </li>
            </ul>
        </div>
    </div>
</div>

<app-share-referral *ngIf="referralService.openShareReferralPopup"></app-share-referral>