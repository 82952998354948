<div *ngIf="!(phaseService.checkIfRentalPricingModal() && phaseService.selectedHash.source === 'app_detail') && ((phaseService.isUrlPromotionValid || !phaseService.hideOffers) && !this.phaseService.isBuildCardSetup && !phaseService.popupCouponApplied) && (dataService.currentPage !== 'paymentStatus') && (dataService.currentPage !== 'upgradePlan')"
  class="offerbarTop">
  <div class="offerHolder">
    <p>{{ getPromotionMessage() }}</p>
    <div class="dropDownMenu">
      <span (mouseover)="showFeatureDropdown(true)">?</span>
      <div class="dropDownList"
        *ngIf="phaseService.showPromotionDropdown && dataService.homePageData?.promotion?.features?.length > 0">
        <ul>
          <li *ngFor="let feature of dataService.homePageData?.promotion?.features">
            <p>{{ feature.title }}</p>
          </li>
        </ul>
      </div>
    </div>

  </div>
</div>

<div
  *ngIf="dataService.currentPage === 'home' &&  (phaseService.offersArr.length > 0) && showOffers === true && !phaseService.hideOffers && !isPromotionValid() && !phaseService.popupCouponApplied"
  class="offerbarTop" (click)="goToOffers()">
  <div class="offerHolder">
    <p> View best offers for you. Save Money! </p>
    <em class="icon-next"></em>
  </div>
  <div (click)="removeOffers()" class="offerClose icon-cancel"></div>
</div>

<div
  *ngIf="(dataService.currentPage === 'home' || dataService.currentPage === 'apps') && phaseService.popupCouponApplied && isPromotionValid()"
  class="offerbarTop">
  <div class="offerHolder">
    <p>Promocode {{dataService.couponName}} has been applied for your next Buildcard<sup class="tm">®</sup>.</p>
  </div>
</div>

<nav *ngIf="(dataService.currentPage !== 'upgradePlan') && (!phaseService.isFeatureRequest || appDataService?.urlParameters.cpe_user) || (phaseService.isFeatureRequest && (dataService.currentPage !== 'features') && (dataService.currentPage !== 'delivery'))" [ngClass]="{'hide-get-help': dataService.showMeetNowModel, 'navlayer': dataService.isMobileModel}" id="header" (click)="showFeatureDropdown(false)">
  <div class="container-fluid">
    <div class="row">

      <!-- main logo block -->
      <div class="logo"
        [ngClass]="{ halfLogo: logoFlag, 'featureLogoBlock': dataService.currentPage === 'features', 'logo-disabled': appDataService?.urlParameters?.isCustomPrototype || (phaseService.isRentalBuildCard() && dataService.buildCardData?.upfront)}"
        *ngIf=" !phaseService.isAdminDashboard() && !phaseService.isPartnerDashboard() ">
        <a (click)="homeButtonClick()">
          <img
            [src]=" dataService.homePageData?.builder_partner?.logo_url ? utilityService.addHttps(dataService.homePageData.builder_partner.logo_url) : './../../../../../assets/images/studioStoreLogo.png' "
            width="121" height="20" class="mainLogo" alt="" *ngIf="!phaseService.checkIfRentalPricingModal() && !phaseService.checkIfNatashaShownInStudioStore()" />
            <img
            [src]=" dataService.homePageData?.builder_partner?.logo_url ? utilityService.addHttps(dataService.homePageData.builder_partner.logo_url) : './../../../../../assets/images/studioStoreLogo.png' "
            width="121" height="20" class="mainLogo" alt="" *ngIf="!phaseService.checkIfRentalPricingModal() && phaseService.checkIfNatashaShownInStudioStore()" />
          <img
            [src]=" dataService.homePageData?.builder_partner?.logo_url ? utilityService.addHttps(dataService.homePageData.builder_partner.logo_url) : './../../../../../assets/images/studioStoreLogo.png' "
            width="121" height="20" class="mainLogo studioStore" alt=""
            *ngIf="phaseService.checkIfRentalPricingModal()" />
        </a>
        <a (click)="homeButtonClick()"><img
            [src]="utilityService.addHttps(dataService.homePageData?.builder_partner?.small_logo_url) ? utilityService.addHttps(dataService.homePageData.builder_partner.small_logo_url) : './../../../../../assets/images/logoSmall.svg'"
            class="smallLogo" width="21" height="28" alt="" /></a>
        <a *ngIf="dataService.currentPage === 'home'" class="mobilehome-logo-only">
          <img src="./../../../../../assets/images/studioStoreLogo.png" alt="" />
        </a>
      </div>
      <!--- Studio Store braedcrum -->
      <div class="studioStoreBreadCrum"
        *ngIf="!appDataService.urlParameters.redirect_to_login && dataService.currentPage === 'templateDetail' && dataService.appDetails.pricing_modal ==='rental_price'">
        <ul>
          <li (click)="gotToHome()"><em class="icon-home-1"></em></li>
          <li>{{dataService.appDetails?.title}} </li>
        </ul>
      </div>

      <div class="logo" *ngIf=" phaseService.isAdminDashboard() && !phaseService.isPartnerDashboard() ">
        <a (click)="goToPaymentDashboard()"><img src="./../../../../../assets/images/engineer-logo.svg" class="mainLogo"
            alt="" width="121" height="20" /></a>
        <a (click)="goToPaymentDashboard()"><img src="./../../../../../assets/images/logoSmall.svg" class="smallLogo"
            width="21" height="28" alt="" /></a>
      </div>

      <div class="logo" *ngIf=" !phaseService.isAdminDashboard() && phaseService.isPartnerDashboard() ">
        <a (click)="navigatePartner()"><img src="./../../../../../assets/images/engineer-logo.svg" class="mainLogo"
            width="121" height="20" alt="" /></a>
        <a (click)="navigatePartner()"><img src="./../../../../../assets/images/logoSmall.svg" class="smallLogo"
            width="21" height="28" alt="" /></a>
      </div>

      <div class="mobilebreadcrums" *ngIf="showMobileSteps()">
        <strong>{{phaseService.mobileStepName}}</strong> <span *ngIf="getMobileStepIndex() > 0">(Step
          {{getMobileStepIndex()}}/{{phaseService.mobileTotalSteps}})</span>
      </div>

      <!--      <div class="breadcrums payment" *ngIf="phaseService.paymentModuleStarted">-->
      <div class="breadcrums" *ngIf="!phaseService.isEditFeatureRequest() && ((phaseService.isRentalBuildCard() && !dataService.buildCardData?.upfront) || (!phaseService.isRentalBuildCard())) && (dataService.currentPage === 'paymentPlan' ||
        dataService.currentPage === 'billingDetails' || dataService.currentPage === 'payConfirm')">
        <ul>
          <!-- <li><i class="icon-home"></i></li> -->
          <li  (click)="redirectToTab('buildCard')">
            Buildcard
          </li>
          <li *ngIf='!dataService.isEditBuildCardAfterSecurityPaid()'
              [ngClass]="{ active: (dataService.currentPage === 'billingDetails') }"
              (click)="redirectToTab('billingdetails')">Billing Details/Contract
          </li>
          <li *ngIf="checkIfBuildCardForRapidPrototype() || paymentBuilderService.checkIfOnlyProfessionalPrototype()"
            [ngClass]="{ active: (dataService.currentPage === 'paymentPlan') }" (click)="redirectToTab('paymentplan')">
            Payment Plan
          </li>
          <li [ngClass]="{ 'disablelink': !phaseService.isValidToProceedForPayment || dataService.checkIfCPEUser() }">
            <specing-payment *ngIf="dataService.buildCardData?.id" [billingInfo]="getBillingInfo()"
              [buildCardInstallment]="true" [paymentAmount]="getSecurityDepositWithTax()"
              [paymentAmountWithoutTax]="getSecurityDepositWithoutTax()" [taxAmount]="getSecurityDepositTaxAmount()"
              [paymentId]="phaseService.paymentData?.pending_installment?.id" [isFromHeader]="true"
              [isFromPayConfirm]="true">
            </specing-payment>
          </li>
        </ul>
        <!--<div class="projectTitle">{{ phaseService.selectedHash.project_name }}<em class="icon-write"></em></div>-->
        <div class="projectTitle">
          <div [hidden]="editProjectName">{{ phaseService.selectedHash.project_name }}<em
              (click)="showProjectNameEditBox()" class="icon-write"></em></div>
          <div [hidden]="!editProjectName">
            <input type="text" maxlength="30" id="openKeyboard" [(ngModel)]="phaseService.selectedHash.project_name"
              (focusout)="updateProjectName({keyCode: 13})" (keyup)="updateProjectName($event)"
              placeholder="My Buildcard™" />
          </div>
        </div>
      </div>

      <!--Mobile navigation start-->
      <div class="mobileClick" *ngIf="!isPaymentModule() && (dataService.currentPage !== 'dashboard')"
        (click)="updateShowNav()">
        <em class="icon-mobilemenu" *ngIf="!phaseService.showNav"></em>
        <em class="icon-cancel" *ngIf="phaseService.showNav"></em>
      </div>

      <div class="mobileClick dashboardMobileMenu"
        *ngIf="(dataService.currentPage && (dataService.currentPage === 'dashboard'))"
        (click)="updateShowDashboardMenuForMobileOrTablet()">
        <em class="icon-mobilemenu" *ngIf="!phaseService.isShowDashboardMenuForMobileOrTablet"></em>
        <em class="icon-cancel" *ngIf="phaseService.isShowDashboardMenuForMobileOrTablet"></em>
      </div>

      <div class="mobNavigation" [ngClass]="{ active: phaseService.showNav }">
        <!--for logged in user start-->
        <div class="navHolder" *ngIf="getUser()">
          <ul class="loggedIn">
            <li (click)="performUserActions('settings')">
              <div class="profilePic" [ngStyle]="{'background': dataService.natashaAvatarBackground}">
                {{dataService.natashaAvatarInitials}}
              </div>

              Edit profile
            </li>
            <li (click)="performUserActions('dashboard')">Dashboard</li>
            <li *ngIf="this.dataService.currentPage === 'features'" (click)="showFeatureTutorial()">View tutorial</li>
            <li *ngIf="checkIfNeedToShowPromotion()" (click)="applyPromotion()">Apply promotion</li>
            <form
              *ngIf="(phaseService.promotionValid &&  !phaseService.promotionError && phaseService.isDiscountApplied())">
              <div class="applyPromoInner">
                <h4>{{ phaseService.finalPromoApplied?.toUpperCase() }} Applied <em class="icon-right"></em></h4>
                <div class="offerCancel">
                  <button type="button" (click)="removeManualPromotion()" class="removePromo">Remove</button>
                </div>
              </div>
            </form>
            <li class="shareBtn"
              *ngIf="((dataService.currentPage === 'features') || (dataService.currentPage === 'delivery')) && !phaseService.checkIfRentalPricingModal()">
              <share-url-button class="hidden-xs" [currentUrl]="currentUrl" [isForMobile]="true"></share-url-button>
            </li>
            <!--<li *ngIf="!phaseService.checkIfRentalPricingModal()" (click)="goToPartnerDashboard()">Become a partner</li>-->
            <li class="liveChat" [ngClass]="{'disableLink': !phaseService.showIntercomChatWindow}"
              *ngIf="!phaseService.checkIfRentalPricingModal()" (click)="phaseService.showIntercomChat()">Live chat
              <span class="threeDotLoader"><span class="dot-flashing"></span></span></li>
            <li *ngIf="!phaseService.checkIfRentalPricingModal()" (click)="showInstantCall()">Instant call</li>
            <li *ngIf="!phaseService.checkIfRentalPricingModal()" (click)="showScheduleCall()">Book a call</li>
            <li (click)="performUserActions('logout')" class="logoutButton">Logout</li>
          </ul>

          <!--<div class="buttonSet" *ngIf="!phaseService.checkIfRentalPricingModal()">
            <button type="button" (click)="goToPartnerDashboard()"><em class="icon-questionhelp"></em> Become a partner</button>
          </div>

          <div class="buttonSet" *ngIf="!phaseService.checkIfRentalPricingModal()">
            <button type="button" (click)="showIntercomChat()"><em class="icon-questionhelp"></em> Live chat</button>
            <button type="button" (click)="showInstantCall()"><em class="icon-landline"></em> Instant call</button>
            <button type="button" (click)="showScheduleCall()"><em class="icon-phonebook"></em> Book a call</button>
          </div>-->

        </div>
        <!--for logged in user close-->

        <!--for not logged in user start-->
        <div class="navHolder" *ngIf="!getUser()">

          <!--<div class="buttonSet">
            <button type="button" (click)="commonAuthScreen('header'); showSingInSignup(true)">Sign In</button>
          </div>-->

          <ul>
            <li (click)="commonAuthScreen('header'); showSingInSignup(true)">Sign In</li>
            <li *ngIf="dataService.currentPage === 'features'" (click)="showFeatureTutorial()">View tutorial</li>
            <li
              *ngIf="((dataService.currentPage === 'delivery') || (dataService.currentPage === 'summary') ||
            (dataService.currentPage === 'paymentPlan')) && !phaseService.checkIfRentalPricingModal() && !phaseService.isDiscountApplied()"
              (click)="applyPromotion()">Apply promotion</li>
            <form
              *ngIf="(phaseService.promotionValid &&  !phaseService.promotionError && phaseService.isDiscountApplied())">
              <div class="applyPromoInner">
                <h4>{{ phaseService.finalPromoApplied?.toUpperCase() }} Applied <em class="icon-right"></em></h4>
                <div class="offerCancel">
                  <button type="button" (click)="removeManualPromotion()" class="removePromo">Remove</button>
                </div>
              </div>
            </form>
            <li class="shareBtn"
              *ngIf="((dataService.currentPage === 'features') || (dataService.currentPage === 'delivery')) && !phaseService.checkIfRentalPricingModal()">
              <share-url-button class="hidden-xs" [currentUrl]="currentUrl" [isForMobile]="true"></share-url-button>
            </li>
            <!--<li *ngIf="!phaseService.checkIfRentalPricingModal()" (click)="goToPartnerDashboard()">Become a partner</li>-->
            <li *ngIf="!phaseService.checkIfRentalPricingModal()">
              <div class="currencySelBox mobile" [ngClass]="{ loginFlag: getUser() }"
                *ngIf="isShowCurrency() && (!getUser() || checkForCPEUserAndCurrentPage()) && !phaseService.isAdminDashboard() && !phaseService.isPartnerDashboard() && !isRentalAppPage()">
                <span class="sel_state" [ngClass]="isShowCurrencyDropdown() ? 'active' : ''"
                  (click)="showPopUp(); hideOptionList()">{{getCurrency() ? getCurrencyCode() : ""}}</span>
                <div class="userDropdown" [ngClass]="isShowCurrencyDropdown() ? 'active' : ''"
                  *ngIf="isShowCurrencyDropdown()">
                  <div class="userDropdownBox">
                    <div class="currencychoose" (click)="selectCurrency(i)"
                      *ngFor="let currency of getCurrencies(); let i = index"
                      [ngClass]="isCurrentCurrency(currency) ? 'active' : ''">
                      <div class="icons">
                        <img [src]="utilityService.addHttps(currency.icon_image_file_url)" alt="" />
                      </div>
                      <span class="currencyName">{{ currency.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li *ngIf="!phaseService.checkIfRentalPricingModal()" (click)="phaseService.showIntercomChat()">Live chat
              <span class="threeDotLoader"><span class="dot-flashing"></span></span></li>
            <li *ngIf="!phaseService.checkIfRentalPricingModal()" (click)="showInstantCall()">Instant call</li>
            <li *ngIf="!phaseService.checkIfRentalPricingModal()" (click)="showScheduleCall()">Book a call</li>
          </ul>

        </div>
        <!--for not logged in user close-->

      </div>
      <!--Mobile navigation close-->

      <div class="loginPanel"
        *ngIf="getUser() == null && !phaseService.isAdminDashboard() && !phaseService.isPartnerDashboard() && !isRentalAppPage() && (phaseService.selectedFeatures?.length || (dataService.currentPage === 'home'))">
        <ul>
          <li>
            <button type="button" (click)="commonAuthScreen('header'); showSingInSignup(true)">Sign In</button>

            <div class="discountTooltip"
              *ngIf="isAutoShowSignupPopup() && showMinipopup() && !phaseService.showWelcomeScreen"
              [ngClass]="{active: isAutoShowSignupPopup()}">
              <div class="closeButton" (click)="hidesignupPopup()"><em class="icon-cancel"></em></div>
              <p>Sign in now and get upto <strong>10% discount</strong><br> on your next idea</p>
              <button (click)="isShowLoginPopup('min_popup')" type="button">Sign in</button>
            </div>

          </li>
        </ul>
      </div>

      <div class="loginPanel partnerPanel"
        *ngIf="phaseService.isPartnerDashboard() && !partnerDataService.getPartnerUser()">
        <ul>
          <li><button (click)="commonAuthScreen('default_sign_up'); showSingInSignup(true)">Sign In</button></li>
          <li><button class="active" (click)="showSignUpForPartner()">Sign Up</button></li>
        </ul>
      </div>
      <!--need to add (sidebarOpen class) if sidebar show-->

      <div class="userPanel" [ngClass]="{ active: isShowList() }"
        *ngIf="!phaseService.isAdminDashboard() && phaseService.isPartnerDashboard() && partnerDataService.getPartnerUser() && dataService.currentPage !== 'instant_prototype'">
        <h3 (click)="showOptionList(); closePopup()">
          Hello
          <strong>{{ partnerDataService.getPartnerUser().first_name }}</strong>
        </h3>

        <div class="userDropdown">
          <ul>
            <li class="mydashBoard">
              <span (click)="logoutPartner()">Sign out</span>
            </li>
          </ul>
        </div>
      </div>
      <!---After login user  --->

      <div class="userPanel"
        [ngClass]="{ active: isShowList(), 'dashboardMenuActive': (dataService.currentPage && (dataService.currentPage === 'dashboard')) }"
        *ngIf=" getUser() && !phaseService.isAdminDashboard() && !phaseService.isPartnerDashboard() && dataService.currentPage !== 'instant_prototype' ">
        <h3 (click)="showOptionList(); closePopup()">
          <div class="userProfile" [ngStyle]="{'background': dataService.natashaAvatarBackground}">
            {{dataService.natashaAvatarInitials}}
          </div>
        </h3>
        <!--- user dropdown --->
        <div class="userDropdown">
          <ul>
            <li *ngIf="!(this.dataService.currentPage === 'dashboard')" class="mydashBoard">
              <span (click)="performUserActions('dashboard')">Dashboard</span>
            </li>
            <li *ngIf="isDashBoardShow()">
              <span (click)="performUserActions('pricenewproject')">Price New Buildcard<sup class="tm">®</sup></span>
            </li>
            <li *ngIf="!(this.dataService.currentPage === 'dashboard')">
              <span (click)="performUserActions('settings')" class="userSetting">Settings</span>
            </li>
            <li>
              <span (click)="performUserActions('logout')" class="userLogout">Log Out</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="userPanel" [ngClass]="{ active: isShowList() }"
        *ngIf="getAdminUser() && phaseService.isAdminDashboard()">
        <h3 (click)="showOptionList(); closePopup()">
          Hello <strong>{{ getAdminUser().name }}</strong>
        </h3>

        <div class="userDropdown">
          <ul>
            <li>
              <span class="userLogout">Log Out</span>
            </li>
          </ul>
        </div>
      </div>

      <share-url-button class="hidden-xs"
        *ngIf="dataService.currentPage === 'features' || dataService.currentPage === 'delivery'"
        [currentUrl]="currentUrl"></share-url-button>

      <div class="currencySelBox" [ngClass]="{ loginFlag: getUser() }"
        *ngIf="isShowCurrency() && (!getUser() || checkForCPEUserAndCurrentPage()) && !phaseService.isAdminDashboard() && !phaseService.isPartnerDashboard() && !isRentalAppPage()">
        <span class="sel_state" [ngClass]="isShowCurrencyDropdown() ? 'active' : ''"
          (click)="showPopUp(); hideOptionList()">{{getCurrency() ? getCurrencyCode() : ""}}</span>
        <div class="userDropdown" [ngClass]="isShowCurrencyDropdown() ? 'active' : ''" *ngIf="isShowCurrencyDropdown()">
          <div class="userDropdownBox">
            <div class="currencychoose" (click)="selectCurrency(i)"
              *ngFor="let currency of getCurrencies(); let i = index"
              [ngClass]="isCurrentCurrency(currency) ? 'active' : ''">
              <div class="icons">
                <img [src]="utilityService.addHttps(currency.icon_image_file_url)" alt="" />
              </div>
              <span class="currencyName">{{ currency.name }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- view prototype menu box buildcard page-->
      <!-- <div class="requestDemo view-proto hidden-xs"
           [ngClass]="{'custom-disabled': !phaseService.selectedFeatures?.length , active:getViewPrototypeClicked()}"
           *ngIf="comingSoonPrototypeVisible() && !phaseService.isFeatureRequest && !isRentalAppPage() && this.dataService.currentPage !== 'templateDetail'">
        <div class="text" (click)="phaseService.redirectWIPBuilderNow()">
          <span> <em class="right-tringle"></em></span> View Prototype
        </div>
        <div class="coming-soon"> Coming soon</div>
      </div> -->
      <!-- end-->
      <!--- view prototype feature page--->
      <div class="requestDemo view-proto hidden-xs"
        [ngClass]="{'custom-disabled': !phaseService.selectedFeatures?.length , active:getViewPrototypeClicked()}"
        *ngIf="phaseService.showViewPrototype() && !phaseService.isFeatureRequest && !phaseService.checkIfNatashaShownInStudioStore()">
        <div class="text" (click)="redirectLogicToBuilderNow()">
          <!--<span> <em class="right-tringle"></em></span>--> View Prototype
        </div>
      </div>
      <!-- end-->

      <!-- Get help with my Buildcard™ page  -->
      <div class="requestdemo-wrapper">
        <div class="requestDemo requestDemo2 hidden-xs"
          *ngIf="((isPaymentModule() && !appDataService.urlParameters?.isCustomPrototype) ||
             (this.dataService.currentPage && (this.dataService.currentPage === 'summary' || (this.dataService.currentPage === 'templateDetail' && dataService.appDetails.pricing_modal === 'rental_price'))))">
          <div class="expertIcoBlock">
            <span class="listing "> <span class="img-box"> <img src="{{dataService.expertIcon}}" alt="" /> </span></span>
          </div>
          <div *ngIf="!phaseService.checkIfNatashaShownInStudioStore()" class="text" (mouseover)="trackHoverOnNeedHelp(dataService.currentPage)"> Talk to our experts
            <div class="needhelpdroupdown newHeaderDrop">
              <ol>
                <li (click)="showDemoCall()" (mouseover)="toggleDescriptions('liveDemo')">
                  <div class="tabIcon"><img src="../../../../../assets/images/liveDemo-icon.svg" alt="" /></div>
                <div class="tabContent">
                  <h4>Live Demo</h4>
                  <p *ngIf="showDescriptions.isLiveDemoDesc">Get a tour of our product and see if it’s the right choice for your idea.</p>
                </div>
                </li>
                <li (click)="showScheduleCall()" [ngClass]="{'disabled': isSpecCallScheduled()}"
                    (mouseover)="toggleDescriptions('specCall')">
                  <div class="tabIcon"><img src="../../../../../assets/images/specCall-icon.svg" alt="" /></div>
                  <div class="tabContent">
                    <h4>Spec Call
                      <div class="tooltipBox" *ngIf="isSpecCallScheduled()">
                        <em class="icon-info-circle"></em>
                        <div class="tooltipText">
                          <p>This Buildcard already has a spec call booked for {{getSpeccall('date')}} at {{getSpeccall('time').split('.')[0] | date: 'hh:mm a'}} ( {{ getSpeccall('timezone')}} ) with {{getSpeccall('name')}}.</p>
                          <p>Want to book a spec call about a different idea?
                            <u (click)="homeButtonClick($event.stopPropagation())">Get started now</u>
                            </p>
                        </div>
                      </div>
                    </h4>
                    <p *ngIf="showDescriptions.isSpecCallDesc">You’ll leave knowing all the features your idea needs, how much it’ll cost and how long it’ll take.</p>
                  </div>
                </li>
                <li (click)="phaseService.showIntercomChat()" (mouseover)="toggleDescriptions('liveChat')"
                  [ngClass]="{'disabled': !phaseService.showIntercomChatWindow}">
                  <div class="tabIcon"><img src="../../../../../assets/images/liveChat-icon.svg" alt="" /></div>
                  <div class="tabContent">
                    <h4>Live Chat
                      <span class="threeDotLoader"><span class="dot-flashing"></span></span>
                    </h4>
                    <p *ngIf="showDescriptions.isLiveChatDesc">Got a few questions? We’ll try to answer them immediately, here.</p>
                  </div>
                </li>
              </ol>
            </div>
          </div>
          <div *ngIf="phaseService.checkIfNatashaShownInStudioStore()" class="text" (click)="navigateToTalkToExpertLink()">
            Talk to our experts
          </div>
        </div>
        <div class="secure hidden-xs" *ngIf="isPaymentModule()">
          <!--<img src="./../../../../../assets/images/shield.png" alt=""/>--> 100% Secure
        </div>
      </div>

      <div class="newProject" *ngIf="getUser() && isDashBoardShow()">
        <button (click)="performUserActions('pricenewproject')">
          Price new Buildcard<sup class="tm">®</sup>
        </button>
      </div>

      <div class="selectedCurrency" *ngIf="getUser() && isDashBoardShow()">
        <img [src]="utilityService.addHttps(getUserCurrencyIcon())" alt="" />
      </div>

      <!-- <div class="referFriendButton hidden-sm hidden-xs" *ngIf="dataService.currentPage === 'dashboard'" (click)="openReferralComponent()">
        Refer friends to earn rewards
      </div> -->

      <!-- talk to expert  -->
      <!-- desktop -->
      <!-- Become a partner -->
      <!--<div *ngIf=" (!phaseService.isPartnerDashboard() && dataService.isEngineerAIBuilder() && dataService.currentPage === 'home') || ( this.dataService.currentPage === 'templateDetail' && dataService.appDetails.pricing_modal !== 'rental_price')" class="becomePartner" (click)="goToPartnerDashboard()"> Become a Partner </div>-->
      <!-- talk to expert feature page-->
      <div class="requestDemo hidden-xs"
        *ngIf="dataService.showExpertsButtonInHeader && (dataService.currentPage === 'apps')">
        <div class="expertIcoBlock">
          <span class="listing "> <span class="img-box"> <img src="{{dataService.expertIcon}}" alt="" /> </span></span>
        </div>

        <div class="text" (click)="openMeetNoeModal($event)">
          Experts online
        </div>
      </div>
      <!-- end -->


      <phases-mode *ngIf="showDiscardAdvancedPopup" (discardCallBack)="handleDiscard($event)"></phases-mode>

      <div class="requestDemo" *ngIf=" !phaseService.isPartnerDashboard() && dataService.user && dataService.user.user_type === 'Partner' && dataService.currentPage === 'home'
        " (click)="showCreateForOthersPopup()"> Create Buildcard<sup class="tm">®</sup> for others </div>

      <!--need to add (active class) to show sidebar-->
      <div class="priceSideBar" #priceSidebar
        [ngClass]="{ active: phaseService.openSideNav, 'planDelivery1': (dataService.currentPage === 'delivery') || (dataService.currentPage === 'paymentPlan')}">
        <div class="priceOverflow" (click)="closeBar()"></div>

        <div class="priceBoxHold">

          <div class="perweekPrice"
            *ngIf="phaseService.isDiscountApplied() && (dataService.manualPromotion?.type !== 'SpecialEventPromotion')">
            <img [src]="utilityService.addHttps(getCurrencyIcon())" alt="" />
            / Week
          </div>

          <div class="priceListing">
            <div (click)="closeBar()" class="close-btn"><em class="icon-cancel"></em></div>
            <div class="specingAdd" *ngIf="getSelectedSpecing()">
              <span>Spec'ing: {{ getCurrencySymbol() }}{{ getLocaledPrice( getSpecingPrice() | defaultcurrConvert:
                getCurrency() | number: "1.2-2" ) }}
              </span>
              <button (click)="setSelectedSpecing()">Remove</button>
            </div>

            <div class="applyPromoBox">
              <h4>Apply Promotion</h4>

              <div class="pro-box">
                <form (keydown.enter)="$event.preventDefault()">
                  <input type="text" maxlength="20" (focus)="phaseService.promotionError = null"
                    [(ngModel)]="phaseService.isDiscountApplied() ? phaseService.finalPromoApplied?.toUpperCase() : phaseService.manualPromoCode"
                    [ngModelOptions]="{ standalone: true }" [ngClass]="{'success': (!phaseService.promotionError && phaseService.isDiscountApplied()),
                         'isError': phaseService.promotionError}" (keyup)="showUpdatedList($event)"
                    placeholder="Enter Promocode" />
                  <em class="icon-cancel"
                    *ngIf="phaseService.isDiscountApplied() && (dataService.manualPromotion?.type !== 'SpecialEventPromotion')"
                    (click)="removeManualPromotion()"></em>
                  <button type="submit"
                    [ngClass]="{'custom-disabled': !phaseService.manualPromoCode || phaseService.isDiscountApplied()}"
                    (click)="getPromotionAttributes()">Apply
                    <img *ngIf="phaseService.promotionLoader" src="./../../../assets/images/loader1.gif" alt="" />
                  </button>
                  <span class="errorMsg" *ngIf="phaseService.promotionError">{{ phaseService.promotionError }}</span>
                  <span class="errorMsg"
                    *ngIf="!phaseService.promotionError && phaseService.isDiscountApplied() && (dataService.manualPromotion?.type !== 'SpecialEventPromotion')">
                    Promotion code applied successfully.
                  </span>
                  <span class="errorMsg"
                    *ngIf="searchTextValidation && (phaseService.manualPromoCode?.length < 3)">Search will work once you
                    add word with three characters</span>
                </form>
              </div>

              <div class="promoApplyMsg" *ngIf="(dataService.manualPromotion?.type === 'SpecialEventPromotion')">
                <p><span>{{ dataService.manualPromotion?.code }} Applied!</span></p>
                <p>{{ dataService.manualPromotion?.success_banner }}</p>
              </div>

            </div>

            <div class="supportMain" *ngIf="getSelectedSnM() > 0">
              <p>
                <strong>Support & Maintenance</strong> We will work in gathering
                requirements, making information architecture and many more.
              </p>
              <div class="priceWeek">
                <strong>{{ getCurrencySymbol()
                  }}{{
                  getLocaledPrice(
                  getSnMPrice()
                  | number: "1.2-2"
                  )
                  }}</strong>

                ({{ getSelectedSnM() }} weeks)
              </div>
            </div>
            <!---- promotion side bar start ---->

            <div class="promotionListing" *ngIf="((phaseService.filteredPromotionList.length > 0) &&
            (dataService.manualPromotion?.code !== 'SpecialEventPromotion'))">
              <h3>Best offers available for you</h3>
              <ul>
                <li *ngFor="let promotion of phaseService.filteredPromotionList">
                  <div class="promoTag">
                    <div class="promoName">{{ promotion.code }}</div>
                    <p>Use above code and get {{promotion.discount_percentage}}% off.<br /> Expires on
                      <strong>{{promotion.expires_at | date : 'dd-MMM-y'}}.</strong>
                    </p>
                  </div>
                  <button type="button" (click)="applySelectedPromo(promotion.code)">Apply</button>
                </li>
              </ul>
            </div>
            <!---- promotion side bar end ---->
          </div>

        </div>
      </div>
    </div>
  </div>
</nav>

<nav *ngIf="phaseService.isFeatureRequest && !appDataService?.urlParameters.cpe_user && ((dataService.currentPage === 'features') || (dataService.currentPage === 'delivery'))"
  id="header">
  <div class="container-fluid">
    <div class="row">
      <div class="logo">
        <a (click)="navigatePartner()"> <img src="./../../../../../assets/images/engineer-logo.svg" width="121"
            height="20" class="mainLogo" alt="" /></a>
        <a (click)="navigatePartner()"><img src="./../../../../../assets/images/logoSmall.svg" width="21" height="28"
            class="smallLogo" alt="" /></a>
      </div>
      <div class="featureSelected" *ngIf="phaseService.isFeatureRequest && !phaseService.isPlatformRequest">
        <h3 *ngIf="dataService.additionalFeatures.length">{{ dataService.additionalFeatures.length }} new
          {{dataService.additionalFeatures.length > 1 ? 'features' : 'feature'}} selected</h3>
        <h3 *ngIf="!dataService.additionalFeatures.length">No new feature selected</h3>
        <div class="closeButton" (click)="phaseService.redirectToBuilderLive()"><em class="icon-cancel"></em></div>
      </div>
      <div class="featureSelected" *ngIf="phaseService.isPlatformRequest">
        <h3 *ngIf="paymentBuilderService.getAddedPlatformsCount()">{{ paymentBuilderService.getAddedPlatformsCount() }} new
          {{ paymentBuilderService.getAddedPlatformsCount() > 1 ? 'platforms' : 'platform' }} selected</h3>
        <h3 *ngIf="!paymentBuilderService.getAddedPlatformsCount()">No new platfrom selected</h3>
        <div class="closeButton" (click)="phaseService.redirectToBuilderLive()"><em class="icon-cancel"></em></div>
      </div>
    </div>
  </div>
</nav>


<!--Feature tour start-->
<div class="featureTour" [ngClass]="{ 'setScrollActive': dataService.isScrollTopFeatures}"
  *ngIf="dataService.showFeatureTour">
  <div class="featureTourHead">
    <div class="tourLogo"></div>
    <div class="tourtopRight">

      <!--Note: this is only for mouseover use start-->
      <div class="mouseoverBox">
        <div class="animationPointer"></div>
      </div>
      <!--Note: this is only for mouseover use start-->

      <!--Note: plz don't change DOM position start-->
      <div class="customOverlayBox"></div>

      <div class="featureTourInfo">
        <ng-container *ngIf="innerWidth >= 769">
          <h3>Top bar</h3>
          <ul>
            <li>Up here, you can rename your Buildcard<sup class="tm">®</sup> and use the breadcrumbs to see where
              you’re up to in the process.</li>
            <li>View prototype takes you to Builder Now, where you can get a free prototype of your idea – in less than
              10 minutes.</li>
            <li>You can share your Buildcard<sup class="tm">®</sup> too.</li>
          </ul>
        </ng-container>

        <ng-container *ngIf="innerWidth <= 768">
          <h3>Top bar</h3>
          <ul>
            <li>Up here you can rename your Buildcard<sup class="tm">®</sup> and access the menu.</li>
            <li>From the menu, View prototype takes you to Builder Now, where you can get a free prototype of your idea
              – in less than 10 minutes.</li>
            <li>You can share your Buildcard<sup class="tm">®</sup> too.</li>
          </ul>
        </ng-container>

      </div>

      <div class="featureTourClose" (click)="closeTutorial()"><em class="icon-cancel"></em> <span>Close tutorial</span>
      </div>
      <!--Note: plz don't change DOM position close-->

    </div>
  </div>

  <div class="featureTourMiddle">
    <!--tour leftpart start-->
    <div class="featureTourLeft">
      <div class="searchFilter">

        <!--Note: this is only for mouseover use start-->
        <div class="mouseoverBox">
          <div class="animationPointer"></div>
        </div>
        <!--Note: this is only for mouseover use start-->

        <!--Note: plz don't change DOM position start-->
        <div class="customOverlayBox"></div>

        <div class="featureTourInfo">
          <ng-container *ngIf="innerWidth >= 769">
            <h3>Filters and add custom feature</h3>
            <ul>
              <li>You can filter features by functionality, cost or their development time.</li>
              <li>Can't find what you’re looking for? We can create any custom feature you need.</li>
            </ul>
          </ng-container>

          <ng-container *ngIf="innerWidth <= 768">
            <h3>Device, Filters, Add custom feature, Search</h3>
            <ul>
              <li>Use the device controls to select the device in which to preview your Buildcard<sup
                  class="tm">®</sup>.</li>
              <li>The device controls are also used to select the plaforms for which your Buildcard<sup
                  class="tm">®</sup> will be developed.</li>
              <li>You can filter features by functionality, cost or their development time.</li>
              <li>Can't find what you’re looking for? We can create any custom feature you need.</li>
            </ul>
          </ng-container>
        </div>
        <!--Note: plz don't change DOM position close-->

      </div>

      <div class="featureTourFeature">

        <!--Note: this is only for mouseover use start-->
        <div class="mouseoverBox">
          <div class="animationPointer"></div>
        </div>
        <!--Note: this is only for mouseover use start-->

        <!--Note: plz don't change DOM position start-->
        <div class="customOverlayBox"></div>

        <div class="featureTourInfo">
          <ng-container *ngIf="innerWidth >= 769">
            <h3>Feature menu</h3>
            <ul>
              <li>Here's our library of features. It's where you can preview, add or remove them. They're all grouped by
                what they do. Hover over a feature to see its actions: preview and add.</li>
              <li>Features are marked as <em class="icon-link-1"></em> "linked" are core features. You must have these
                or the template you’ve chosen won't work. </li>
              <li>Ones marked as <em class="icon-recommended"></em> "recommended" have been suggested by our AI (she
                thinks they'll be useful for you).</li>
            </ul>
          </ng-container>

          <ng-container *ngIf="innerWidth <= 768">
            <h3>Feature menu</h3>
            <ul>
              <li>Here's our library of features. It's where you can preview, add or remove them. They're all grouped by
                what they do.</li>
              <li>You can use this menu to preview, add and remove features to your build.</li>
              <li>Features are marked as <em class="icon-link-1"></em> “linked” are core features. You must have these
                or the template you've chosen won't work. Ones marked as <em class="icon-recommended"></em>
                “recommended” have been suggested by our AI (she thinks they'll be useful for you).</li>
            </ul>
          </ng-container>
        </div>
        <!--Note: plz don't change DOM position close-->

      </div>
    </div>
    <!--tour leftpart close-->

    <!--tour centerpart start-->
    <div class="featureTourCenter">
      <div class="featureTourPrev">

        <!--Note: this is only for mouseover use start-->
        <div class="mouseoverBox">
          <div class="animationPointer"></div>
        </div>
        <!--Note: this is only for mouseover use start-->

        <!--Note: plz don't change DOM position start-->
        <div class="customOverlayBox"></div>

        <div class="featureTourInfo">
          <h3>Preview</h3>
          <ul>
            <li>See how each feature looks on different devices.</li>
            <li>You can see a preview of any feature by pressing <em class="icon-eye"></em> in the feature menu.</li>
            <li>It's also where you choose which platforms you want your Buildcard<sup class="tm">®</sup> developed
              for.</li>
          </ul>
        </div>
        <!--Note: plz don't change DOM position close-->

      </div>

      <div class="featureTourCart">

        <!--Note: this is only for mouseover use start-->
        <div class="mouseoverBox">
          <div class="animationPointer"></div>
        </div>
        <!--Note: this is only for mouseover use start-->

        <!--Note: plz don't change DOM position start-->
        <div class="customOverlayBox"></div>

        <div class="featureTourInfo">
          <ng-container *ngIf="innerWidth >= 769">
            <h3>Shopping cart</h3>
            <ul>
              <li>This is it. All the features that make up your Buildcard<sup class="tm">®</sup>.</li>
              <li>Click on each one to preview it. You can remove any you don't need.</li>
            </ul>
          </ng-container>

          <ng-container *ngIf="innerWidth <= 768">
            <h3>Shopping cart</h3>
            <ul>
              <li>This is it. All the features that make up your Buildcard<sup class="tm">®</sup>.</li>
              <li>You can preview them and remove any you don't need.</li>
            </ul>
          </ng-container>
        </div>
        <!--Note: plz don't change DOM position close-->

      </div>
    </div>
    <!--tour centerpart close-->

    <!--tour rightpart start-->
    <div class="featureTourRight">

      <!--Note: this is only for mouseover use start-->
      <div class="mouseoverBox">
        <div class="animationPointer"></div>
      </div>
      <!--Note: this is only for mouseover use start-->

      <!--Note: plz don't change DOM position start-->
      <div class="customOverlayBox"></div>

      <div class="featureTourInfo">
        <h3>Meet our Natasha bot</h3>
        <ul>
          <li>Natasha, our AI-powered digital assistant, is here to help! </li>
          <li>Machine learning teaches our AI everything it needs to give you helpful recommendations. </li>
          <li>Natasha will give you options of what to do next, or you can ask her anything about your Buildcard<sup
              class="tm">®</sup>.</li>
          <li>Where Natasha can’t help you, our human product experts are always waiting to chat through anything you
            need.</li>
        </ul>
      </div>
      <!--Note: plz don't change DOM position close-->

    </div>
    <!--tour rightpart close-->
  </div>

  <div class="featureTourBottom">
    <!--Note: this is only for mouseover use start-->
    <div class="mouseoverBox">
      <div class="animationPointer"></div>
    </div>
    <!--Note: this is only for mouseover use start-->

    <!--Note: plz don't change DOM position start-->
    <div class="customOverlayBox"></div>

    <div class="featureTourInfo">
      <ng-container *ngIf="innerWidth >= 769">
        <h3>Summary bar</h3>
        <ul>
          <li>Here's how much your Buildcard<sup class="tm">®</sup> will cost and how long it'll take. </li>
          <li>Want to know more about how we've priced your Buildcard<sup class="tm">®</sup>? Click by <em
              class="icon-question-circle"></em> "Customisation cost". </li>
          <li><em class="icon-info-circle"></em> next to "Total Cost" gives you the cost breakdown of each feature.</li>
        </ul>
      </ng-container>

      <ng-container *ngIf="innerWidth <= 768">
        <h3>Summary bar</h3>
        <ul>
          <li>Here’s how much your Buildcard<sup class="tm">®</sup> will cost and how long it'll take.</li>
          <li><em class="icon-info-circle"></em> next to Total Cost gives you the cost breakdown of each feature.</li>
        </ul>
      </ng-container>
    </div>
    <!--Note: plz don't change DOM position close-->
  </div>
</div>
<!--Feature tour close-->




<!-- login -->
<div class="commonPopUp" [ngClass]="{'active': phaseService.mobUserLogin}">
  <div class="popOverlay" (click)="(phaseService.mobUserLogin = false)"></div>
  <div class="popHolder loginoverlayPopup">
    <div class="main">
      <div class="login-list">
        <p class="login_txt"> Already have an account? </p>
        <button class="login_btn pink" (click)="commonAuthScreen('header'); showSingInSignup(true);applyPromoMobile()">
          Login
        </button>
      </div>
      <div class="login-list">
        <p class="login_txt"> Don’t have an account? </p>
        <button class="login_btn" (click)="commonAuthScreen('header'); showSingInSignup(false);applyPromoMobile()">
          Register
        </button>
      </div>
    </div>


  </div>
</div>

<div class="commonPopUp" [ngClass]="{active: phaseService.showProjectNamePopup}"
  *ngIf=" phaseService.showProjectNamePopup">
  <div class="popOverlay bgNone"></div>
  <div class="popHolder buildcardname">
    <div class="cardnameIcon">
      <!--<em class="icon-thumbs-up"></em>--> <img src="../../../assets/images/thumb-icon.png" alt="" />
    </div>
    <!-- <h3>Great job! We are summarising your requirements into a <strong>Buildcard.</strong></h3> -->
    <h3>Time to create your Buildcard<sup class="tm light">®</sup> (It describes exactly what you want to build)</h3>
    <!-- <p><strong>Meanwhile, please name your Buildcard<sup class="tm">®</sup></strong></p> -->
    <p><strong>First, please name your Buildcard<sup class="tm">®</sup></strong></p>
    <input type="text" [(ngModel)]="phaseService.currentProjectName" (keyup)="trackIfUserStartedTyping($event)"
      maxlength="30" placeholder="eg. Booking.com">
    <button type="button" (click)="saveProjectName(true)"
      [disabled]="!(phaseService.currentProjectName && (phaseService.currentProjectName.length > 0))">Save

    </button>
  </div>
</div>

<div class="commonPopUp specCall-bx"
  [ngClass]="{'active': dataService.showMeetNowModel, 'animate-popup': dataService.applyAnimationClass}"
  *ngIf="dataService.showMeetNowModel">
  <div class="popOverlay"></div>
  <div class="popHolder meetNowPopup scheduleCall-block"
    *ngIf="dataService.isNoramlSpecCall && !dataService.showLoaderForBNow">
    <div class="pageLoaderBox">
      <div class="pageLoader"></div>
      <div class="loadingOverlay"></div>
    </div>
  </div>
  <app-meet-now class="popHolder speCallPopup_bx"
    [ngClass]="dataService.isShowBantModal ? 'tellusPopup' : dataService.openSchedulerPopup ? 'meetNowPopup schedulerPopup' : 'meetNowPopup'"
    [isExpertAvailableButtonClicked]="true"></app-meet-now>
  <!-- <app-meet-now class="popHolder speCallPopup_bx rentalmeetnoe-speCallPopup" [ngClass]="dataService.isShowBantModal ? 'tellusPopup' : 'meetNowPopup'" *ngIf="phaseService.meetNowForRentalClicked"
                  [isExpertAvailableButtonClicked]="true"></app-meet-now> -->

</div>

<div class="commonPopUp active mobile-none simiBoxModal"
  *ngIf="dataService.showMeetNowOverloading || (dataService.showMeetNowOverloadingOnFeature && dataService.currentPage === 'features')">
  <div class="popOverlay bgWhite"></div>
  <div class="popHolder">
    <div class="simiAppBox">
      <div class="leftSide">
        <div class="textBox" *ngIf="dataService.currentPage === 'apps'">
          <h2> Click the app that’s closest to your idea </h2>
          <p> You’ll save time picking out the features that your idea needs. </p>
        </div>
        <!-- listData-->
        <div class="listBox" *ngIf="dataService.currentPage === 'features'">
          <ul>
            <li (click)="clickingFeatureOverloadng('protoType')">
              <h3 [ngClass]="{'active': showFeatureOverLoading === 'protoType'}"> View Prototype</h3>
              <p> Watch your idea come to life in one click – for free. </p>
            </li>
            <li (click)="clickingFeatureOverloadng('features')">
              <h3 [ngClass]="{'active': showFeatureOverLoading === 'features'}"> Add Extra Features </h3>
              <p> Click any extras your idea needs, uncheck any that you don’t. </p>
            </li>
            <li (click)="clickingFeatureOverloadng('getHelp')">
              <h3 [ngClass]="{'active': showFeatureOverLoading === 'getHelp'}"> Need more help? </h3>
              <p> An expert can guide you on a call (it’s a chargeable service). </p>
            </li>
          </ul>
        </div>
        <button type="button" class="singleBtn" (click)="hideMeetNowOnboarding()">Got it<em
            class="icon-rightarrow"></em></button>
      </div>
      <div class="rightSide">
        <img src="../../../../../assets/images/builderonScreen.png" *ngIf="dataService.currentPage === 'apps'"
          alt="screen" />
        <div class="image-bx" *ngIf="dataService.currentPage === 'features'">
          <img src="../../../../../assets/images/feature-main.png" alt="screen" />
          <span class="one" *ngIf="showFeatureOverLoading === 'protoType'"> <img
              src="../../../../../assets/images/view-prototype.png" alt="screen" /> </span>
          <span class="two" *ngIf="showFeatureOverLoading === 'features'"> <img
              src="../../../../../assets/images/features.png" alt="screen" /> </span>
          <span class="three" *ngIf="showFeatureOverLoading === 'getHelp'"> <img
              src="../../../../../assets/images/get_help.png" alt="screen" /> </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="commonPopUp active" *ngIf="hideDynamicPricingPopup()">
  <div class="popOverlay"></div>
  <div class="popHolder dynamicPricing">
    <div class="closePopUp" (click)="phaseService.closeTutorial()"><span class="icon-cancel"></span></div>
    <div class="dynamicPricingLeft">
      <!--<h3>Dynamic pricing</h3>-->
      <img src="../../../../assets/images/dynamic-pricing-img.png" alt="">
      <div class="sectionNote">For illustration purposes only</div>
    </div>
    <div class="dynamicPricingRight">
      <div class="sectionContent">
        <h3>How our pricing works</h3>
        <p>Each feature has a fixed price and a customisation cost.</p>
        <p><strong>What’s the fixed price? </strong><br> Price of using a reusable feature from our library.</p>
        <p><strong>What’s the customisation cost? </strong><br> Cost to customise that reusable feature for your
          idea.<br> Adding a feature to a simple app is cheaper than adding the same feature to a larger, more complex
          app.</p>
      </div>
      <div class="footerButton">
        <button class="gotitButton" type="button" (click)="phaseService.closeTutorial(true)">OK, got it</button>
        <!--<div class="keepShowingModal" (click)="togglePricingModal()">Keep showing me this modal <div class="toggleButton" [ngClass]="{'nomore': dataService.noMorePricingModal}"></div></div>-->
      </div>
    </div>
  </div>
</div>

<div class="commonPopUp active" *ngIf="phaseService.isCpeCloningCard">
  <div class="popOverlay"></div>
  <div class="popHolder currencyCloning">
    <div class="closePopUp" (click)="closeCurrencyPopup()"><span class="icon-cancel"></span></div>
    <div class="titleHead">
      <h3>Select currency for <strong>My Builder Buildcard<sup class="tm">®</sup> (copy)</strong></h3>
    </div>
    <div class="currencySection">
      <div class="selectedRow" (click)="showCurrCloningDropDown()">
        <div class="selectedIcon"><img alt="" [src]="phaseService?.clonedCardCurrency?.icon_image_file_url"></div>
        <div class="selectedName">{{ phaseService?.clonedCardCurrency?.name }}</div>
      </div>

      <ul *ngIf="showCurrencyInCaseOfCloning">
        <li *ngFor="let currency of dataService.homePageData.currencies; let i = index"
          [ngClass]="{'active': hoveredCurrencyIndex === i}" (click)="setCurrencyForDuplicateCard(currency)"
          (mouseover)="setActiveClass(i)">
          <div class="currencyIcon"><img alt="" [src]="currency.icon_image_file_url"></div>
          <div class="currencyName">{{ currency.name }}</div>
        </li>
      </ul>
    </div>
    <div class="footerButton">
      <button type="button" class="doneButton" (click)="cloneBuildCard()">Submit</button>
    </div>
  </div>
</div>

<div class="commonPopUp active" *ngIf="phaseService.isPopUpBlockerEnabled">
  <div class="popOverlay"></div>
  <div class="popHolder confirmPopup">
    <div class="closePopUp" (click)="closePopUpBlockerModal()"><span class="icon-cancel"></span></div>
    <h3>Thank you!</h3>
    <p>Popup blocker is enabled.</p>
    <div class="footerButton">
      <button class="doneButton" (click)="closePopUpBlockerModal()">OK</button>
    </div>
  </div>
</div>

<!--<div class="gridSystem">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div>-->
