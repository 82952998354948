import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Constants} from '@shared/utils/constants';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'sign-contract',
  templateUrl: 'sign-contract.component.html'
})


export class SignContractComponent {

  public enablePayment = false;
  public allContracts;
  public showHtml = false;
  public uploadedDocument: object = {};
  public isDocumentUploaded: object = {};
  public selectedContractId = '';
  public showLoader: object = {};
  public invalidFileError: object = {};

  /*@ngInject*/
  constructor(public phaseService: PhaseService, public apiRequests: ApiRequests, public dataService: DataService, public router: Router, public analyticsSegment: Angulartics2Segment,  ) {

  }

  public ngOnInit() {
    this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.signcontract.code);
    if (this.dataService.buildCardData.id) {
      const cardId = this.dataService.buildCardData.id;
      this.dataService.showHideLoader(true);
      this.apiRequests.fetchContractDetails(cardId).subscribe(contracts => {
       this.allContracts = contracts;
       this.checkForDisabledPayment(contracts);
       this.dataService.showHideLoader(true);
       this.apiRequests.fetchInstallments(this.phaseService.inProgressBuildCardId, this.phaseService.paidPhaseId).subscribe((data: any) => {
       if (data) {
        this.phaseService.paymentData = data;
        if (this.dataService.buildCardData && this.dataService.buildCardData.specing && !this.dataService.buildCardData.specing_paid) {
          this.gotoBilling();
        } else {
          this.phaseService.installments = this.phaseService.paymentData.installments.filter((installment) => {
            return installment.type === 'installment';
          });
          this.phaseService.deposit = this.phaseService.paymentData.installments.filter((installment) => {
            return installment.type === 'deposit';
          });
          if (this.phaseService.paymentData.installments[this.phaseService.paymentData.installments.length - 1].type === 'support') {
            this.phaseService.supportMaintenance = this.phaseService.paymentData.installments[this.phaseService.paymentData.installments.length - 1];
          }
          if (this.phaseService.holdOfferFlow) {
            this.gotoBilling();
          }
        }
      }
      this.dataService.showHideLoader(false);
      this.showHtml = true;
    }, (error) => {
      this.dataService.showHideLoader(false);
    });
      });
    }
  }

  public checkForDisabledPayment(contracts) {
    contracts.forEach(ndaContract => {
     if (ndaContract.nda_signed === true && ndaContract.contracts) {
      this.enablePayment = ndaContract.contracts.every(this.checkContract);
     }
   });
  }

  public checkContract(element) {
    return (element.nda_signed === true);
  }

  public processNDAFlow() {
    this.closePopup();
    this.hideOptionList();
    if (!this.dataService.user) {
      this.phaseService.showSignin = true;
      this.phaseService.openCommonAuth = true;
      this.phaseService.isNdaFlow = true;
    } else {
      this.fetchDocusignUrl();
    }
  }

  public fetchDocusignUrl() {
    this.phaseService.isNdaFlow = false;

    const params = {redirect_url: '', build_card_id: ''};
    if (this.phaseService.inProgressBuildCardId) {
      params.build_card_id = this.phaseService.inProgressBuildCardId;
      params.redirect_url = this.phaseService.getRedirectUrl() +  '&build_card_id=' + this.phaseService.inProgressBuildCardId + '&redirect_key=' + `buildcard-payment/${this.dataService.buildCardData.id}/signcontract`;
    }

    this.apiRequests.fetchDocuSignUrl(params).subscribe((data: any) => {
      if (this.dataService.docuSignUrl) {
        window.location.href = this.dataService.docuSignUrl;
      }
    });
  }

  public processMasterContractFlow(mastercontract) {
    this.closePopup();
    this.hideOptionList();
    if (!this.dataService.user) {
      this.phaseService.showSignin = true;
      this.phaseService.openCommonAuth = true;
      // this.phaseService.isNdaFlow = true;
    } else {
      this.fetchMasterContractDocusignUrl(mastercontract);
    }
  }

  public fetchMasterContractDocusignUrl(mastercontract) {
    const params = {redirect_url: '', record_id: ''};
    if (this.phaseService.inProgressBuildCardId) {
      params.redirect_url = this.phaseService.getRedirectUrl() +  '&build_card_id=' + this.phaseService.inProgressBuildCardId + '&redirect_key=' + `buildcard-payment/${this.dataService.buildCardData.id}/signcontract`;
    }

    if (mastercontract.id) {
      params.record_id = mastercontract.id;
    }
    this.apiRequests.fetchMasterContractDocuSignUrl(params).subscribe((data: any) => {
      if (this.dataService.mastercontractdocuSignUrl) {
        window.location.href = this.dataService.mastercontractdocuSignUrl;
      }
    });
  }

  public closePopup() {
    this.dataService.showCurrencyPopup = false;
  }

  public hideOptionList() {
    this.phaseService.showList = false;
  }

  public viewPdf(contract, type) {
    let url = '';
    if (type === 'NDA') {
      url = contract.nda_link;
    } else if (type === 'contract') {
      url = contract.link;
    }
    window.open(url, '_blank' , "noopener");
  }

  public gotoBilling() {
    this.router.navigate([`buildcard-payment/${this.dataService.buildCardData.id}`, 'billing']);
  }

  public uploadDocument(contractType: string, event: Event, contract) {
    const files: any = event.target;
    if (files.files[0].type === 'application/pdf') {
      this.isDocumentUploaded[contractType] = true;
      this.uploadedDocument[contractType] = files.files[0];
      this.selectedContractId = contract.id;
      this.invalidFileError[contractType] = '';
    } else {
      this.invalidFileError[contractType] = 'Invalid File type. Only .pdf files allowed';
    }
  }

  public removeDocument(contractType) {
    this.isDocumentUploaded[contractType] = false;
    this.uploadedDocument[contractType] = null;
    this.selectedContractId = '';
  }

  public sendDocuments(contractType) {
    this.showLoader[contractType] = true;
    this.apiRequests.uploadContracts(contractType, this.dataService.buildCardData.id, this.uploadedDocument[contractType])
      .subscribe(
        () => {
          this.apiRequests.fetchContractDetails(this.dataService.buildCardData.id)
            .subscribe(
              (contracts) => {
                this.allContracts = contracts;
                this.checkForDisabledPayment(contracts);
                this.showLoader[contractType] = false;
              },
              (error) => {
                this.showLoader[contractType] = false;
              }
            );
        },
        (error) => {
          this.showLoader[contractType] = false;
        }
      );
  }
}
