<div class="appDetailSection freemiumCard">
  <div class="topSection">
    <form [formGroup]="appDetailForm">
      <ul>
        <li>
          <label class="appName">App name (Max 30 characters)
            <em class="icon-info-circle" (click)="infoIconClicked()"></em>
            <div *ngIf="isInfoIcon" class="discriptionBlock">This is what your app will be called in the <strong>Google Play Store</strong> and <strong>Apple App Store</strong>. But don't worry, you can change it later.</div>
            <div class="tooltipOverlay" *ngIf="isInfoIcon" (click)="infoIconClicked()"></div>
          </label>
          <input  type="text" placeholder="Your application name" maxlength="30" (mousemove)="trimValues()" formControlName="project_name" />
          <div *ngIf="projectName.touched && projectName.invalid">
            <span *ngIf="projectName.errors['required']" class="errorMsg">Please enter your app name</span>
          </div>
        </li>
        <li class="subdomain">
          <label>Your Web URL <!--<em class="icon-info-circle"></em>--></label>
          <div class="inputHolder">
            <div class="infoText">{{phaseService.storeUrl}}ecommerce/</div>
            <input type="text" style="text-transform: lowercase;" placeholder="Enter your URL" (keyup)="hideErrorMsg()" pattern="[a-zA-Z0-9- ]*" (focusout)="validateSubDomainOnFocusLeave()" formControlName="sub_domain" />
          </div>
          <div *ngIf="subDomain.touched && subDomain.invalid">
            <span *ngIf="subDomain.errors['required']" class="errorMsg">Please enter sub-domain</span>
            <span *ngIf="(this.appDetailForm.value.sub_domain!='')" class="errorMsg">Special characters except “-” is not acceptable as valid URL</span>
          </div>
          <div *ngIf="subDomain.touched && (this.appDetailForm.value.sub_domain!='')">
            <span *ngIf="appDetailForm.value.sub_domain.includes(' ')" class="errorMsg">A valid URL cannot have spaces</span>
            <span *ngIf="subDomain.value.charAt(0) == '-'" class="errorMsg">A valid URL cannot start with hyphen</span>
          </div>
          <div class="errorMsg" *ngIf="phaseService.freemiumSubDomainExists">{{ phaseService.errMessage }}</div>
        </li>
        <li>
          <label>Country of Store</label>
          <div class="countrySelect">
            <select class="dropdown" formControlName="app_country">
              <option class="option" value="" selected>Select</option>
              <option class="option">India</option>
              <option class="option">UK</option>
            </select>
          </div>
          <div *ngIf="appCountry.touched && appCountry.invalid">
            <span *ngIf="appCountry.errors['required']" class="errorMsg">Please select a country</span>
          </div>
        </li>
        <!-- custom country dropdown -->
        <!--
        <li>
          <label>Country of Store</label>
          <div class="selectCountryWrapper">
            <div class="selectCountryCustom js-selectCountryCustom">
              <div class="selectCountryCustom-trigger">Select...</div>
              <div class="selectCountryCustom-options">
                <div class="selectCountryCustom-option" data-value="ds">India</div>
                <div class="selectCountryCustom-option" data-value="fe">USA</div>
                <div class="selectCountryCustom-option" data-value="be">UK</div>
              </div>
            </div>
          </div>
          <div *ngIf="appCountry.touched && appCountry.invalid">
            <span *ngIf="appCountry.errors['required']" class="errorMsg">Please select a country</span>
          </div>
        </li>-->
        <!-- custom country dropdown end -->

      </ul>
      <div class="bottomsection">
        <div class="actionbx">
          <button type="button" class="buttonStyle" [disabled]="appDetailForm.invalid || InvalidSubDomainErr" (click)="createBuildCard()"
                  [ngClass]="{'custom-disabled': phaseService.proceedClickedFromRental}">Proceed
            <img *ngIf="phaseService.proceedClickedFromRental" src="../../../../../../../assets/images/buttonLoader.gif" alt=""/>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- *********** -->
