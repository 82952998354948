<div class="container" *ngIf="!getShowLoader() && getPaymentData().installments.length > 0">
  <div class="row">
    <div class="paymentFlow">
      <div class="emiList">
        <!--<div class="closePanel"><em class="icon-cancel"></em></div>-->
        <div class="emiTop">
          <ul>
            <li><span>Buildcard<sup class="tm">®</sup> cost</span>
              {{getCurrencySymbol()}}{{getLocaledPrice(getPaymentData()?.general_info?.total_amount | number : '1.2-2')}}
            </li>

            <li><span>Tax ({{getPaymentData().general_info.tax}}% on total amount)</span>
              {{getCurrencySymbol()}}{{getLocaledPrice(getTaxOnTotal() | number : '1.2-2')}}</li>

            <li><span><strong>Total Amount ({{phaseService.computeChangedWeeks(getPaymentData()?.general_info?.weeks)}}
              weeks)</strong></span>
              {{getCurrencySymbol()}}{{getLocaledPrice(getTotalWithTax() | number : '1.2-2')}}</li>
          </ul>
        </div>

        <div class="emiTable">
          <table summary="">
            <tr>
              <th>Sr. No</th>
              <th>Installments (Per week)</th>
              <th>Amount</th>
            </tr>
            <tr *ngFor="let installment of getInstallments(); let i = index">
              <td>{{installment.installment_no < 10 ? '0' : ''}}{{installment.installment_no}}</td>
              <td>Installment {{installment.installment_no < 10 ? '0' : ''}}{{installment.installment_no}}
                ({{i + 1 | ordinal}} week)
              </td>
              <td>{{getCurrencySymbol()}}{{getLocaledPrice(getInstallmentWithTax(installment) | number : '1.2-2')}}</td>
            </tr>
          </table>
          <h3>Total : {{getCurrencySymbol()}}{{getLocaledPrice(getTotalWithTax() | number : '1.2-2')}}</h3>
        </div>

        <div class="emiSupport" *ngIf="getSupportMaintenance()">
          <div class="supportLeft">
            <label>
              <input type="checkbox" name="checkbox" class="check"
                     (change)="phaseService.toggleSupportAndMaintainence(true)"
                     [checked]="phaseService.isSupportAndMaintenance()"/>
              <div class="fancy-check"></div>
              <p>Builder Care <span>We will work in gathering requirements, making information architecture and many more.</span>
              </p>
            </label>
          </div>
          <div class="supportRight">
            <p>{{getCurrencySymbol()}}{{getLocaledPrice(getInstallmentWithTax(getSupportMaintenance()) | number : '1.2-2')}}
              <span>(per month)</span></p>
          </div>
        </div>

        <div class="clearfix"></div>
      </div>

      <div class="emiList">
        <!--<div class="price-promo" *ngIf="getOriginalAmount(getDeposit())">
          <p>Promotional discount applied.</p>
        </div>-->
        <div class="emiLeft">
          <p><span>Security deposit<sup>*</sup></span>
            This amount would be refunded at the end of the Buildcard<sup class="tm light">®</sup>.</p>
        </div>
        <div class="emiRight">
          <p>
            <strong>{{getCurrencySymbol()}}{{getLocaledPrice(getInstallmentWithTax(getDeposit()) | number : '1.2-2')}}</strong>
            <span>1 week payment</span></p>
        </div>
        <div class="clearfix"></div>
      </div>

      <div class="commonButton">
        <h5>{{showMessage}}</h5>
        <button [ngClass]="{buttondisable: phaseService.preventClick}" type="button" (click)="gotoBilling()"
                *ngIf="dataService.homePageData?.builder_partner?.partner_type !== 'sell_to'">Continue <img
          *ngIf="phaseService.preventClick"
          src="./../../../assets/images/buttonLoader.gif" alt=""/></button>

        <button type="button" (click)="sendForPartnerApproval()" [disabled]="phaseService.preventClick"
                *ngIf="dataService.user?.user_type === 'User' && dataService.homePageData?.builder_partner?.partner_type === 'sell_to' && isBuildCardApproved()">
          Send request to {{dataService.homePageData?.builder_partner?.partner_name}} for approval
        </button>

        <button type="button" (click)="gotoDashboard()"
                *ngIf="!isBuildCardApproved()">Go to Dashboard
        </button>

        <button type="button" (click)="sendForPartnerApproval()" [disabled]="phaseService.preventClick"
                *ngIf="dataService.user?.user_type === 'Partner' && dataService.homePageData?.builder_partner?.partner_type === 'sell_to' && isBuildCardApproved()">
          Add these installments in current billing cycle
        </button>

      </div>


    </div>

  </div>
</div>
