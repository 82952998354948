import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {Angulartics2Segment} from 'angulartics2/segment';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import {AppsFilterModel} from '@models/apps-filter-model/apps-filter.model';
import {ApiRequests} from '@shared/services/api-requests.service';
import {Constants} from '@shared/utils/constants';
import {Subscription} from 'rxjs';
import {UtilityService} from '@shared/services/utility.service';
import {CommonService} from '@shared/services/common.service';
import {AppDataService} from '@rootmodule/app-data.service';
import { Intercom } from 'ng-intercom';
import {SeoService} from '@shared/services/seo.service';
import {ChangeContext, Options} from '@angular-slider/ngx-slider';

@Component({
  selector: 'apptemplate',
  templateUrl: 'apptemplate.component.html',
  styleUrls: ['apptemplate.component.scss']
})

export class ApptemplateComponent implements  OnInit, OnDestroy, AfterViewInit {
  public cardSize;
  public showAllApps = false;
  public showDetailPoupToggle = false;
  public popupApp = null;
  public categoryNamesOnPopup = [];
  public deleteAll = false;
  public eventTracked = false;
  public filterCost = [0, 100];
  optionsFilterCost: Options = {floor: 0, ceil: 100, noSwitching: true};
  public filterDuration = [0, 100];
  optionsFilterDuration: Options = {floor: 0, ceil: 100, noSwitching: true};
  public categoryLimitNumber = 5;
  public filterArr: any = [];
  public filter: AppsFilterModel;
  public templateRequest;
  public searchRequest;
  public firstSearchLoader = false;
  public searchMode = false;
  public searchObj = {} as any;
  public Math: any;
  public templateLoaded = false;
  public mobileFilterMode = false;
  public page = 1;
  public perPage = 10;
  public isScrolled = true;
  public currentUrl;
  searchKeyWord = '';
  selectedSortIndex = -1;
  public showFilter = false;
  sortingTypes = [{
    name: 'Name',
    value: 'name,asc'
  }, {
    name: 'Price: Low to High',
    value: 'price,asc'
  }, {
    name: 'Price: High to Low',
    value: 'price,desc'
  }, {
    name: 'Duration: Low to High',
    value: 'week,asc'
  }, {
    name: 'Duration: High to Low',
    value: 'week,desc'
  }];
  private currencyChangeSubscription: Subscription;

  constructor(public dataService: DataService, public phaseService: PhaseService, private router: Router, private analyticsSegment: Angulartics2Segment,
              public apiRequest: ApiRequests,  public utilityService: UtilityService, private appDataService: AppDataService,
              private commonService: CommonService, private seoService: SeoService, private activatedRoute: ActivatedRoute) {
    this.dataService.currentPage = 'apps';
    this.seoService.updateMetaTags(Constants.META_TAGS_DESCRIPTION.apps);
    this.Math = Math;
    this.cardSize = 2;

    if (this.dataService.homePageData.build_products && this.dataService.homePageData.build_products.length === 0) {
      this.phaseService.reset();
      this.router.navigate([this.dataService.firstPage]);
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  clickFilter() {
    this.showFilter = !this.showFilter;
  }

  public ngOnInit() {
    this.dataService.currentPage = 'apps';
    const red = this.appDataService.urlParameters.red;
    if (!this.dataService.user && ((red === 'meet_now') || (red === 'ip_now'))) {
      this.phaseService.openCommonAuth = true;
      this.phaseService.showRegister = true;
      this.phaseService.showSignin = false;
    }
    if (this.dataService.user && ((red === 'meet_now') || (red === 'ip_now')) && !this.dataService.showMeetNowModel) {
      this.dataService.showMeetNowModel = true;
    }
    if (red === 'self_served') {
      this.dataService.showMeetNowOverloading = true;
    }
    if(this.phaseService.popupPromocode !== Constants.promotionCodeType.Application) {
      this.phaseService.popupPromocode = Constants.promotionCodeType.Application;
      this.phaseService.setPromotionObject(this.phaseService.popupPromocode);
    }
    this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.apps.code);
    if (this.phaseService.searchApp) {
      this.searchKeyWord = this.phaseService.selectedTemplates.firstTemplate.title;
      this.onFirstSearchChange(this.phaseService.selectedTemplates.firstTemplate.title);
    }
    this.phaseService.setPhasesUsingSharedUrl();
    if (!this.dataService.homePageData.platforms) {
      this.dataService.showHideLoader(true);
      this.apiRequest.fetchHomePageData().subscribe((data: any) => {
        this.dataService.dataLoadingCurrency = Object.assign({}, this.dataService.homePageData.currency);
        this.phaseService.platformPrices = Array.apply(null, Array(this.dataService.homePageData.platforms.length));
        this.phaseService.platformPrices.map((x, i) => {
          return i;
        });
        if (this.dataService.homePageData.currency) {
          this.phaseService.specing_price = this.dataService.homePageData.currency.specing_price;
        }
        this.initializeAppTemplates();
      });
    } else {
      this.initializeAppTemplates();
    }
    this.setUpCurrencyChangeSubscription();
  }
  public ngAfterViewInit() {
    this.phaseService.checkExitIntentTimer();
    this.phaseService.checkIdlestate();
  }
  setUpCurrencyChangeSubscription() {
    this.currencyChangeSubscription = this.phaseService.getCurrencyUpdatedCallback().subscribe(isCurrencyChanged => {
      if (isCurrencyChanged) {
        this.perPage = this.page * this.perPage;
        this.page = 1;
        this.initializeAppTemplates();
      }
    });
  }

  public initializeAppTemplates() {
    this.filterArr = [];
    this.searchObj = {};

    this.phaseService.rapidPrototypePlatforms = Object.assign([],[...this.dataService.homePageData.prototype_platforms]);

    if (this.phaseService.buildCardEditMode) {
      this.phaseService.breadcrumb_links = [];
      this.phaseService.setBreadcrumbLink(this.router.url.split('/')[1]);

    } else {
      this.phaseService.setBreadcrumbLink(this.router.url.split('/')[1]);
    }

    this.dataService.showHideLoader(true);
    if (this.dataService.user && this.activatedRoute.snapshot.paramMap.get('id') && !this.phaseService.buildCardEditMode) {
      let uniqCode = this.activatedRoute.snapshot.paramMap.get('id');
      this.apiRequest.fetchSingleBuildCardData(uniqCode).subscribe((data: any) => {
        this.phaseService.setUpInFullBuildCard();
        this.phaseService.buildCardEditMode = true;
        this.dataService.isEditBuildCard = true;
        this.getAndRenderCategories();
        this.fetchAppTemplates();
      });
    } else {
      this.getAndRenderCategories();
      this.fetchAppTemplates();
    }
    // this.phaseService.selectAppsFromPromotion();
    // this.trackEvents();
    this.phaseService.checkForSignupPopup(this.analyticsSegment);

  }

  public getApplicationIds() {
    if (this.phaseService.selectedHash.application_ids.length > 0) {
      return this.phaseService.selectedHash.application_ids;
    }
  }

  public fetchAppTemplates() {
    const application_ids = this.getApplicationIds();
    this.apiRequest.fetchTemplates(this.phaseService.selectedHash.product_id, null, this.page, this.perPage, application_ids).subscribe((data: any) => {
      this.phaseService.setPhasesUsingSharedUrl();
      this.handleTemplateSuccess();
      if (this.phaseService.selectedApps.length > 0) {
        if (this.phaseService.selectedHash.application_ids.length === 0) {
          for (const app of this.phaseService.selectedApps) {
            this.phaseService.toggleSelectedAppid(app.id);
          }
        }
        this.phaseService.updatePrice();
      }
      if (!this.appDataService.urlParameters.red && (this.dataService.currentPage === 'apps')) {
        setTimeout(() => {
          this.dataService.showExpertsButtonInHeader = true;
        }, 5000);
      }
      if (this.appDataService.urlParameters.red === 'self_served') {
        this.dataService.showMeetNowOverloading = true;
      }
    }, error => this.dataService.showHideLoader(false));
  }

  public onSortByChange(data, index): void {
    this.selectedSortIndex = index;
    data = data.split(',');
    const sortBy = data[0];
    const order = data[1];
    if (this.searchMode) {
      this.searchObj.sortBy = sortBy;
      this.searchObj.order = order;
      this.dataService.showHideLoader(true);
      this.searchTemplates();
      return;
    }
    const sortByObj = this.filterArr.find(x => x.key === 'sort_by');
    if (sortByObj) {
      sortByObj.value = sortBy;
      const orderObj = this.filterArr.find(x => x.key === 'order');
      if (orderObj) {
        orderObj.value = order;
      } else {
        this.filterArr.push({key: 'order', value: order});
      }
    } else {
      if (sortBy === 'name') {
        this.filterArr.push({key: 'sort_by', value: sortBy});
      } else {
        this.filterArr.push({key: 'sort_by', value: sortBy});
        this.filterArr.push({key: 'order', value: order});
      }
    }
    this.searchObj.sortBy = sortBy;
    this.searchObj.order = order;

    this.filterTemplates();
  }

  public getAndRenderCategories(): void {
    this.apiRequest.fetchCategories(this.phaseService.selectedHash.product_id).subscribe((data: any) => {
      this.filter = data as AppsFilterModel;
      if (this.filter) {
        this.dataService.categories = this.filter.categories;
        this.updateFilterProperties();
      }
    }, error => this.dataService.showHideLoader(false));
  }

  public handleTemplateSuccess(): void {
    if (!this.dataService.apps) {
      this.phaseService.reset();
      this.router.navigate([this.dataService.firstPage]);
    } else {
      this.templateLoaded = true;
      if (this.phaseService.selectedApps.length > 0) {
        this.phaseService.setSelectedItems('selectedApps');
        if (this.phaseService.newSelectedAppIds.length > 0) {
          this.phaseService.selectedApps.forEach((item, index) => {
            this.getAppTemplates().forEach(app => {
              if (item.id === app.id) {
                this.phaseService.selectedApps[index] = app;
              }
            });
          });
        }
      }
    }
    if (this.dataService.currentPage === 'apps') {
      this.dataService.showHideLoader(false);
    }
  }

  public updateFilterProperties(): void {
    this.filter.categories.forEach(category => category['selected'] = false);
    this.filter.platforms.forEach(platform => platform['selected'] = false);
    this.optionsFilterCost = {floor : this.filter.min_template_cost , ceil : this.filter.max_template_cost, noSwitching: true};
    this.filterCost = [this.filter.min_template_cost, this.filter.max_template_cost];
    this.optionsFilterDuration = {floor : this.filter.min_template_week , ceil : this.filter.max_template_week, noSwitching: true};
    this.filterDuration = [this.filter.min_template_week, this.filter.max_template_week];
  }

  public deleteFirstSearch(): void {
    this.onBackFromSearch();
  }

  public onFirstSearchChange(event): void {
    if (!this.searchKeyWord) {
      this.onBackFromSearch();
      return;
    }
    this.searchObj.query = this.searchKeyWord;
    this.searchMode = true;
    this.firstSearchLoader = true;
    this.handleKeyDownEvent(event, this.searchKeyWord);
    this.searchTemplates();
  }

  public deselectTemplate(selectedApp: any): void {
    this.toggleClickedItem(selectedApp);
    this.phaseService.toggleSelectedAppsById(selectedApp);
    this.phaseService.toggleSelectedAppid(selectedApp.id);
    this.phaseService.checkForUnsavedChanges();
  }

  public searchTemplates(): void {
    if (this.searchRequest) {
      this.searchRequest.unsubscribe();
    }
    this.searchRequest = this.apiRequest.searchTemplates(this.phaseService.selectedHash.product_id, this.searchObj)
      .subscribe((data: any) => {
        this.handleTemplateSuccess();
        this.firstSearchLoader = false;
        this.dataService.showHideLoader(false);
      }, error => this.dataService.showHideLoader(false));
  }

  public onBackFromSearch(): void {
    this.searchMode = false;
    this.searchKeyWord = '';
    this.resetSearch();
    this.filterTemplates();
  }

  public resetSearch(): void {
    this.searchObj.query = '';
  }

  public onCategoryChange(category: any): void {
    if (category.selected) {
      this.filterArr.push({key: 'category_ids[]', value: category.id});

    } else {
      this.filterArr.forEach((obj, index) => {
        if (obj.value === category.id) {
          this.filterArr.splice(index, 1);
        }
      });
    }
    if (!this.mobileFilterMode) {
      this.filterTemplates();
    }
  }

  public onPlatformChange(platform: any): void {
    if (platform.selected) {
      this.filterArr.push({key: 'platform_ids[]', value: platform.id});

    } else {
      this.filterArr.forEach((obj, index) => {
        if (obj.value === platform.id) {
          this.filterArr.splice(index, 1);
        }
      });
    }
    if (!this.mobileFilterMode) {
      this.filterTemplates();
    }
  }

  public filterTemplates(): void {
    this.page = 1;
    if (this.templateRequest) {
      this.templateRequest.unsubscribe();
    }
    this.dataService.showHideLoader(true);
    this.isScrolled = true;
    this.templateRequest = this.apiRequest.fetchTemplates(this.phaseService.selectedHash.product_id, this.filterArr, this.page, this.perPage)
      .subscribe((data: any) => {
        this.handleTemplateSuccess();
      }, error => this.dataService.showHideLoader(false));
  }

  public onFilterCostChange(changeContext: ChangeContext): void {
    const costMinObj = this.filterArr.find(x => x.key === 'min_template_price');
    const costMaxObj = this.filterArr.find(x => x.key === 'max_template_price');
    if (costMinObj && costMaxObj) {
      costMinObj.value = changeContext.value;
      costMaxObj.value = changeContext.highValue;
    } else {
      this.filterArr.push({key: 'min_template_price', value: changeContext.value});
      this.filterArr.push({key: 'max_template_price', value: changeContext.highValue});
    }
    if (changeContext) {
      this.filterCost = [changeContext.value, changeContext.highValue];
    }
    if (!this.mobileFilterMode) {
      this.filterTemplates();
    }

  }

  public onFilterDurationChange(changeContext: ChangeContext): void {
    const weekMinObj = this.filterArr.find(x => x.key === 'min_template_week');
    const weekMaxObj = this.filterArr.find(x => x.key === 'max_template_week');
    if (weekMinObj && weekMaxObj) {
      weekMinObj.value = changeContext.value;
      weekMaxObj.value = changeContext.highValue;
    } else {
      this.filterArr.push({key: 'min_template_week', value: changeContext.value});
      this.filterArr.push({key: 'max_template_week', value: changeContext.highValue});
    }
    if (changeContext) {
      this.filterDuration = [changeContext.value, changeContext.highValue];
    }
    if (!this.mobileFilterMode) {
      this.filterTemplates();
    }
  }

  public handleKeyDownEvent(event: any, query: string) {
    const key = event.keyCode || event.charCode;
    if (key == 8 || key == 46) {
      if (!this.eventTracked) {
        this.eventTracked = true;
      }
    } else {
      this.eventTracked = false;
    }

  }

  public removeAllSelections() {
    for (const app of this.dataService.apps) {
      app.selected = false;
    }
    for (const app of this.phaseService.requestedTemplateList) {
      app.selected = false;
    }
    this.phaseService.selectedApps = [];
    this.phaseService.selectedHash.application_ids = [];
    this.deleteAll = false;
    this.showAllApps = false;
  }

  public closeDeleteAllPopup() {
    this.deleteAll = false;
  }

  public showDeleteAll() {
    this.deleteAll = true;
  }

  public showAllApppMethod() {
    this.showAllApps = !this.showAllApps;
  }

  public showCategories() {
    this.phaseService.showCategories ? this.closeCategoryPopup() : this.phaseService.categoriesToShow.length > 0 ? this.phaseService.showCategories = true : '';
  }

  public closeCategoryPopup() {
    this.phaseService.showCategories = false;
  }

  public hideDetailPopup() {
    this.showDetailPoupToggle = false;
    this.popupApp = null;
  }

  public onTemplateClicked(app, toggled?) {
    this.phaseService.mandatoryFeatures = [];
    this.dataService.isTradeMarkStrip = false;
    if (this.commonService.isPlatformBrowser) {
      localStorage.setItem('tradeMarkTripViewed', 'true');
    }
    this.toggleClickedItem(app);
    if (toggled) {
      const Index = this.dataService.apps.findIndex(x => x.id === app.id);
      if (Index >= 0) {
        this.dataService.apps[Index].selected = app.selected;
      }
    }
    this.phaseService.toggleSelectedAppsById(app);
    this.phaseService.toggleSelectedAppid(app.id);
    this.setMandatoryFeatures();
    this.phaseService.checkForUnsavedChanges();
  }

  setMandatoryFeatures() {
    this.getSelectedApps().filter((psection) => {
      const mandatoryFeaturesIds = this.phaseService.mandatoryFeatures.map(feature => feature.id);
      if (this.phaseService.selectedHash.application_ids.indexOf(psection.id) > -1) {
        this.phaseService.mandatoryFeatures.push(...psection.features.filter(feature => {
          if (mandatoryFeaturesIds.indexOf(feature.id) === -1) {
            return feature.is_mvp_feature;
          }
        }));
      }
    });
  }

  public onRequestedAppEditClicked(app: any): void {
    this.phaseService.addedTemplate.id = app.id;
    this.phaseService.addedTemplate.title = app.title;
    this.phaseService.addedTemplate.category_ids = app.category_ids;
    this.phaseService.addedTemplate.platform_ids = app.platform_ids;
    this.phaseService.selectedCategory = this.dataService.categories.find(category => {
      return category.id === this.phaseService.addedTemplate.category_ids[0];
    });
    if (app.reference_urls) {
      this.phaseService.addedTemplate.reference_urls = app.reference_urls;
    } else {
      this.phaseService.addedTemplate.reference_urls = [];
    }
    this.phaseService.addedTemplate.selected = app.selected;
    this.phaseService.showAddNewTemplate = true;
  }

  public showAddNewTemplatePopup() {
    this.phaseService.selectedCategory = this.dataService.categories[0];
    this.phaseService.showAddNewTemplate = true;
    this.onBackFromSearch();
  }

  //noinspection JSMethodCanBeStatic
  public toggleClickedItem(item) {
    item.selected = !item.selected;
  }

  public checkPhases() {
    this.clearSelectedHash();
    if (this.dataService.isEnterpise) {
      if (this.phaseService.selectedDataForSidePanel.length > 3) {
        this.phaseService.selectedDataForSidePanel.splice(3, this.phaseService.selectedDataForSidePanel.length - 3);
      }
    } else {
      if (this.phaseService.selectedDataForSidePanel.length > 1) {
        this.phaseService.selectedDataForSidePanel.splice(1, this.phaseService.selectedDataForSidePanel.length - 1);
      }
    }
  }

  public pushPhases() {
    const collection = [];
    for (const apps of this.phaseService.selectedApps) {
      collection.push(apps.title);
    }
    const phaseObj = {
      title: 'Templates',
      price: '0',
      icon: '/../../../assets/images/templates_icon.svg',
      collection: collection,
      phase: 'apps'
    };
    this.phaseService.handleSidePanelData(phaseObj, 'Templates');
  }

  public getSelectedProduct() {
    return this.phaseService.selectedProduct;
  }

  public getAppTemplateName() {
    return this.getSelectedProduct() && this.getSelectedProduct().title.includes('Something') ? 'something' :
      this.getSelectedProduct() && this.getSelectedProduct().title.includes('Mobile') ? 'mobile app' :
        this.getSelectedProduct() && this.getSelectedProduct().title.toLowerCase();
  }


  public setTargetUserGroup() {
    this.phaseService.selectedHash.target_user_group = '';
  }

  public getCurrencySymbol() {
    return this.phaseService.getCurrencySymbol();
  }

  public getCurrency() {
    return this.phaseService.buildCardEditMode ? this.dataService.buildCardData.currency : this.dataService.homePageData.currency;
  }

  public getPrevCurrency() {
    return this.dataService.previousCurrency;
  }

  public getLocaledPrice(price: any) {
    return this.dataService.getLocaledPrice(price);
  }

  public getLocaledPriceInt(price: any) {
    return this.dataService.getLocaledPriceInt(price);
  }

  public updateCategoryLimit(limit: number) {
    this.categoryLimitNumber = limit;
  }

  public getAppTemplates() {
    return this.dataService.apps.filter(app => (app.user_requested === false) || (app.status === 'approved'));
  }

  public getRequestedTemplateList() {
    return this.phaseService.requestedTemplateList;
  }

  public getSelectedApps() {
    return this.phaseService.selectedApps;
  }

  public getSelectedHash() {
    return this.phaseService.selectedHash;
  }


  public clearSelectedHash() {
    this.dataService.featureBundles = [];
    this.phaseService.selectedPlatforms = [];
    this.phaseService.selectedFeatures = [];
    this.phaseService.selectedTeam = null;
    this.phaseService.selectedSpeed = null;
    this.phaseService.selectedHash.description = null;
    this.phaseService.selectedHash.platform_ids = [];
    this.phaseService.selectedHash.feature_ids = [];
    this.phaseService.selectedHash.team_id = null;
    this.phaseService.selectedHash.speed_id = null;
    this.phaseService.selectedHash.region_skill_ids = [];
    this.phaseService.selectedHash.competitors = [];
    this.phaseService.selectedHash.app_style_ids = [];
    this.phaseService.selectedHash.startup_urls = [];
    this.phaseService.selectedHash.attachments_attributes = [];
    this.phaseService.selectedHash.quote_price = 0;
  }

  onScroll() {
    if (this.isScrolled) {
      this.perPage = 10;
      this.isScrolled = false;
      this.page++;
      this.apiRequest.fetchTemplates(this.phaseService.selectedHash.product_id, this.filterArr, this.page, this.perPage).subscribe((data: any) => {
        if (data.length > 0) {
          this.handleTemplateSuccess();
          this.isScrolled = true;
          if (this.phaseService.selectedApps.length > 0) {
            if (this.phaseService.selectedHash.application_ids.length === 0) {
              for (const app of this.phaseService.selectedApps) {
                this.phaseService.toggleSelectedAppid(app.id);
              }
            }
          }
        }
      }, error => this.dataService.showHideLoader(false));
    }
  }

  toggleBottomPipe() {
    this.showAllApps = !this.showAllApps;
  }

  public ngOnDestroy() {
    this.dataService.shareUrlData = null;
    this.phaseService.showBottomBar = false;
    if(this.searchRequest) { this.searchRequest.unsubscribe() }
  }

  preventBubling(event) {
    event.stopPropagation();
  }
}
