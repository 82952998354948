import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationUnit'
})
export class DurationUnitPipe implements PipeTransform {

  transform(duration: number, type: string): string {
    return `${duration} ${type}${duration === 1 ? '' : 's'}`;
  }

}
