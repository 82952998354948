import {ModuleWithProviders, NgModule} from '@angular/core';
import {PhaseService} from './services/phase.service';
import {DefaultCurrencyConvertPipe} from './pipes/currencyconvert-pipe/defaultcurrencyconvert.pipe';
import {CurrencyconvertDefaultPipe} from './pipes/currencyconvert-pipe/currencyconvert-default.pipe';
import {DataService} from './services/data.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ModelMapperService} from './services/model-mapper.service';
import {ApiRequests} from './services/api-requests.service';
import {HttpRequests} from './services/http-requests.service';
import {MomentModule} from 'angular2-moment';
import {ShareUrlButtonComponent} from './shared-components/share-url-button/share-url-button.component';
import {CurrencyConvertPipe} from './pipes/currencyconvert-pipe/currencyconvert.pipe';
import {BottomBarComponent} from './shared-components/bottom-bar-selection/bottom-bar.component';
import {MaterialModule} from './material/material.module';
import {MessageEmitterService} from './services/message-emmiter.service';
import {PlatformListComponent} from './shared-components/platform-list/platform-list.component';
import {DragulaModule} from 'ng2-dragula';
import {RecalculatePhaseDataService} from '@shared/services/recalculate-phase-data.service';
import {SpecingPaymentComponent} from '@shared/shared-components/spec-payment-submission/specing-payment.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PartnerDashboardDataService} from '@featuremodules/partner-dashboard-module/Utils/partner-dashboard-data.service';
import {PartnerDashboardApiService} from '@featuremodules/partner-dashboard-module/Utils/partner-dashboard-api.service';
import {GenericService} from '@featuremodules/payment-dashboard-module/services/generic.service';
import {UtilityService} from '@shared/services/utility.service';
import {PhasesModeComponent} from '@shared/shared-components/phases-mode/phases-mode.component';
import {SafeUrlPipe} from '@shared/pipes/safeurl-pipe/safeurl-pipe.pipe';
import {HeaderBreadcrumbComponent} from './shared-components/header-breadcrumb/header-breadcrumb.component';
import {PhaseBreakupComponent} from './shared-components/phase-breakup/phase-breakup.component';
import {TruncatePipe} from '@shared/pipes/truncate-pipe/truncate.pipe';
import {PrototypeInstallmentPaymentComponent} from '@shared/shared-components/prototype-installment-submission/prototype-installment-payment.component';
// import {ExitintentFeedbackComponent} from './shared-components/exitintent-feedback/exitintent-feedback.component';
// import {PaymentExitIntentFeedbackComponent} from './shared-components/payment-exit-intent-feedback/payment-exit-intent-feedback.component';
// import {ExitintentDiscountOfferComponent} from './shared-components/exitintent-discount-offer/exitintent-discount-offer.component';
import {AppShellNoRenderDirective} from '@shared/directives/appshellnorender.directive';
import {AppShellRenderDirective} from '@shared/directives/appshellrender.directive';
import {LeftSideComponent} from './shared-components/rental-build-card/left-side/left-side.component';
import {RightSideComponent} from './shared-components/rental-build-card/right-side/right-side.component';
import {LoginSignupComponent} from './shared-components/rental-build-card/right-side/login-signup/login-signup.component';
import {PaymentOverviewComponent} from './shared-components/rental-build-card/right-side/payment-overview/payment-overview.component';
import {ContractsComponent} from './shared-components/rental-build-card/right-side/contracts/contracts.component';
import {PaymentOptionsComponent} from './shared-components/rental-build-card/right-side/payment-options/payment-options.component';
import {ThankuScreenComponent} from './shared-components/rental-build-card/right-side/thanku-screen/thanku-screen.component';
import {RentalParentComponent} from '@shared/shared-components/rental-build-card/rental-parent/rental-parent.component';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {AngularMyDatePickerModule} from 'angular-mydatepicker';
import { SearchCountriesPipe } from './pipes/search-countries.pipe';
import { HomeTemplateService } from '@shared/services/home-template.service';
import { AddressPopUpComponent } from './shared-components/rental-build-card/right-side/address-pop-up/address-pop-up.component';
import {RentalService} from '@shared/services/rental.service';
import {SafeHtmlPipe} from '@shared/pipes/safehtml-pipe/safehtml-pipe.pipe';
import {CoreFeaturesPipe} from '@shared/pipes/core-features.pipe';
import { TemplateDetailPageComponent } from './shared-components/template-detail-page/template-detail-page.component';
import { FreemiumCardCreationOverviewComponent } from './shared-components/rental-build-card/right-side/freemium-card-creation-overview/freemium-card-creation-overview.component';
import {RightSideSummaryComponent} from '@shared/shared-components/right-side-summary/right-side-summary.component';
import { BuildCardCreationStepsComponent } from './shared-components/build-card-creation-steps/build-card-creation-steps.component';
import { StudioStoreTemplateDetailComponent } from './shared-components/studio-store-template-detail/studio-store-template-detail.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { CardStatusSelectionComponent } from './shared-components/rental-build-card/right-side/card-status-selection/card-status-selection.component';
import { PhoneNumberComponent } from './shared-components/phone-number/phone-number.component';
import { BuilderCareContentComponent } from './shared-components/builder-care-content/builder-care-content.component';
import { HeaderService } from './services/header.service';
import {SearchStatesPipe} from '@shared/pipes/search-states.pipe';
import {ReferralService} from './services/referral.service';
import { DuplicateCardModalComponent } from './shared-components/duplicate-card-modal/duplicate-card-modal.component';
import { TncStoreComponent } from './shared-components/tnc-store/tnc-store.component';
import { TncProComponent } from './shared-components/tnc-pro/tnc-pro.component';
import { NatashaCommunicationComponent } from './shared-components/natasha-communication/natasha-communication.component';
import { DlsPaymentboxComponent } from './shared-components/dls-paymentbox/dls-paymentbox.component';
import { TaxDetailsComponent } from './shared-components/tax-details/tax-details.component';
import {TaxDetailsService} from './services/tax-details.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MomentModule,
    MaterialModule,
    PerfectScrollbarModule,
    DragulaModule.forRoot(),
    ReactiveFormsModule,
    GooglePlaceModule,
    AngularMyDatePickerModule,
    DragScrollModule
  ],
  declarations: [
    CurrencyConvertPipe,
    SafeUrlPipe,
    CurrencyconvertDefaultPipe,
    DefaultCurrencyConvertPipe,
    ShareUrlButtonComponent,
    SpecingPaymentComponent,
    BottomBarComponent,
    PlatformListComponent,
    PhasesModeComponent,
    HeaderBreadcrumbComponent,
    PhaseBreakupComponent,
    TruncatePipe,
    PrototypeInstallmentPaymentComponent,
    // ExitintentFeedbackComponent,
    // PaymentExitIntentFeedbackComponent,
    // ExitintentDiscountOfferComponent,
    AppShellNoRenderDirective,
    AppShellRenderDirective,
    LeftSideComponent,
    RightSideComponent,
    LoginSignupComponent,
    PaymentOverviewComponent,
    ContractsComponent,
    PaymentOptionsComponent,
    ThankuScreenComponent,
    RentalParentComponent,
    SearchCountriesPipe,
    CoreFeaturesPipe,
    AddressPopUpComponent,
    SafeHtmlPipe,
    TemplateDetailPageComponent,
    FreemiumCardCreationOverviewComponent,
    RightSideSummaryComponent,
    BuildCardCreationStepsComponent,
    StudioStoreTemplateDetailComponent,
    CardStatusSelectionComponent,
    PhoneNumberComponent,
    BuilderCareContentComponent,
    SearchStatesPipe,
    DuplicateCardModalComponent,
    TncStoreComponent,
    TncProComponent,
    NatashaCommunicationComponent,
    DlsPaymentboxComponent,
    TaxDetailsComponent
  ],
  exports: [
    CurrencyConvertPipe,
    SafeUrlPipe,
    CurrencyconvertDefaultPipe,
    DefaultCurrencyConvertPipe,
    ShareUrlButtonComponent,
    SpecingPaymentComponent,
    BottomBarComponent,
    MaterialModule,
    PlatformListComponent,
    PhasesModeComponent,
    MomentModule,
    HeaderBreadcrumbComponent,
    TruncatePipe,
    PrototypeInstallmentPaymentComponent,
    // ExitintentFeedbackComponent,
    // PaymentExitIntentFeedbackComponent,
    // ExitintentDiscountOfferComponent,
    AppShellNoRenderDirective,
    AppShellRenderDirective,
    LeftSideComponent,
    RightSideComponent,
    LoginSignupComponent,
    PaymentOverviewComponent,
    ContractsComponent,
    PaymentOptionsComponent,
    ThankuScreenComponent,
    RentalParentComponent,
    AddressPopUpComponent,
    SearchCountriesPipe,
    CoreFeaturesPipe,
    SafeHtmlPipe,
    TemplateDetailPageComponent,
    RightSideSummaryComponent,
    BuildCardCreationStepsComponent,
    StudioStoreTemplateDetailComponent,
    PhoneNumberComponent,
    BuilderCareContentComponent,
    SearchStatesPipe,
    DuplicateCardModalComponent,
    TncStoreComponent,
    TncProComponent,
    TaxDetailsComponent
  ]
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        DataService,
        PhaseService,
        ModelMapperService,
        ApiRequests,
        HttpRequests,
        MessageEmitterService,
        RecalculatePhaseDataService,
        PartnerDashboardDataService,
        PartnerDashboardApiService,
        GenericService,
        UtilityService,
        HomeTemplateService,
        RentalService,
        HeaderService,
        ReferralService,
        TaxDetailsService
      ]
    };
  }
}
