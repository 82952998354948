import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OffersComponent} from './offers/offers.component';
import {QuestionComponent} from './question/question.component';
import {TrendingAppsComponent} from './trending-apps/trending-apps.component';
import {VideoComponent} from './video/video.component';
import {OtherProductComponent} from './other-product/other-product.component';
import { HomeLazyLoadComponent } from './home-lazy-load/home-lazy-load.component';
import { createCustomElement } from '@angular/elements';
import {UtilityService} from '@shared/services/utility.service';
import {DragScrollModule} from 'ngx-drag-scroll';

@NgModule({
  declarations: [OffersComponent,
    QuestionComponent, TrendingAppsComponent, VideoComponent, OtherProductComponent, HomeLazyLoadComponent],
  imports: [
    CommonModule,
    DragScrollModule
  ],
  // entryComponents: [HomeLazyLoadComponent],
  providers: [UtilityService]

})

export class HomeLazyLoadModule {

  constructor(injector: Injector) {
    const el = createCustomElement(HomeLazyLoadComponent, { injector });
    customElements.define('home-lazy-load', el);
  }
}
