import {Component, Input, OnInit} from '@angular/core';
import {DataService} from '@shared/services/data.service';
import {Router} from '@angular/router';
import {PhaseService} from '@shared/services/phase.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {Constants} from '@shared/utils/constants';
import {AppDataService} from '@rootmodule/app-data.service';

@Component({
  selector: 'app-additional-features',
  templateUrl: './additional-features.component.html',
  styleUrls: ['./additional-features.component.scss']
})
export class AdditionalFeaturesComponent {

  @Input('preventClick') preventClick;
  selectedIndex: number;

  constructor(public dataService: DataService, public router: Router, public phaseService: PhaseService, private apiRequest: ApiRequests,
              private appDataService: AppDataService) { }



  openBuildCard(card, index) {
    this.selectedIndex = index;
    if (!this.preventClick) {
      this.preventClick = true;
      this.dataService.additionalFeatures = [];
      this.dataService.totalSelectedFeatures = 0;
      this.dataService.changeFeatureRequestModel = this.dataService.changeFeatureRequestArrayModel.filter(x => x.id === card.id)[0];
      this.phaseService.inProgressBuildCardId = this.dataService.changeFeatureRequestModel.build_card_id;
      this.dataService.isFromAdditionalFeature = true;
      this.router.navigate(['build-card/' + card.build_card.uniq_code]);
      this.dataService.showHideLoader(false);
      this.preventClick = false;
      this.selectedIndex = -1;
      this.appDataService.urlParameters.featureRequest = 'true';
    }
  }

  public getLocaledPrice(price: any) {
    return this.dataService.getLocaledPrice(price);
  }

  public getMonths(card) {
    const noOfMonths = ((card.weeks % 4) === 0)
      ? (card.weeks / 4)
      : Math.floor(card.weeks / 4) + 1;
    return noOfMonths;
  }
}
