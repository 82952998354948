import { Injectable } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private metaService: Meta, private titleService: Title) { }

  updateMetaTags(dataObject) {
    this.titleService.setTitle(dataObject.ogTitle);
    this.metaService.updateTag({ property: 'og:title', content: dataObject.ogTitle });
    this.metaService.updateTag({ property: 'og:image', content: dataObject.ogImage });
    this.metaService.updateTag({ name: 'description', content: dataObject.description });
    this.metaService.updateTag({ property: 'og:description', content: dataObject.description });
  }

  noIndexMeta() {
    this.metaService.updateTag({name: 'robots' , content: 'noindex'});
  }
}
