import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Angulartics2Segment} from 'angulartics2/segment';
import {ApiRequests} from '@shared/services/api-requests.service';
import {PhaseService} from '@shared/services/phase.service';
import {PartnerDashboardApiService} from '@featuremodules/partner-dashboard-module/Utils/partner-dashboard-api.service';
import {DataService} from '@shared/services/data.service';
import {PartnerDashboardDataService} from '@featuremodules/partner-dashboard-module/Utils/partner-dashboard-data.service';
import {AppDataService} from '@rootmodule/app-data.service';
import {CommonService} from '@shared/services/common.service';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'loader',
  templateUrl: 'loader.component.html'
})

export class LoaderComponent {
  public dataLoading = false;
  public shareUrlId;
  public toBuildCardPayment = false;
  public paymentSubUrl = '';
  public featureRequest = false;
  forFeatureRequestUrl: string;

  constructor(public router: Router, public dataService: DataService, public phaseService: PhaseService, private appDataService: AppDataService,
              public analyticsSegment: Angulartics2Segment,  public apiRequest: ApiRequests, public route: ActivatedRoute, private partnerApiRequest: PartnerDashboardApiService, public partnerDataService: PartnerDashboardDataService,
              private commonService: CommonService) {

  }

  public ngOnInit() {
    this.phaseService.showCurrency = true;
    this.dataLoading = true;
    this.setUpUserProperties();
    if (this.appDataService.urlParameters.authToken) {
      this.apiRequest.getUserProfile().subscribe();
      this.dataService.normalFlow = true;
    } else if (this.appDataService.urlParameters.ndaSigned) {
      this.handleNdaSignFlow();
    } else if (this.appDataService.urlParameters.paymentType) {
      this.dataService.paymentStatusFlow = true;
    }
    else {
      const path: string = (this.dataService.hashPath === null) ? '' : this.dataService.hashPath;
      if (path && path.length > 0) {
        let subUrl = '';
        if (path.indexOf('?') !== -1) {
          subUrl = path.substr(2, path.indexOf('?') - 2);
        } else {
          subUrl = path.substr(2);
        }
        if (subUrl !== '') {
          this.dataService.landingPage = subUrl;
          if (subUrl === 'dashboard') {
            this.dataService.pendingURL = subUrl;
            this.dataService.toDashBoard = true;
            if (!this.dataService.user) {
              this.phaseService.showSignin = true;
              this.phaseService.showRegister = false;
              this.phaseService.openCommonAuth = true;
              this.dataService.normalFlow = true;
            }
          } else if (subUrl.indexOf('build-card') !== -1) {
            this.dataService.buildCardLink = subUrl;
            this.dataService.isBuildCardUrl = true;
          } else if (subUrl === 'reset-password') {
            this.dataService.resetPassword = true;
          }
          // else if (subUrl === 'acceptable_fair_use_policy') {
          //   this.dataService.fairUsagePolicy = true;
          // }
          else if (subUrl === 'invalidaccess') {
            this.dataService.invalidAccess = true;
          } else if (subUrl.indexOf('shared-buildcard') !== -1) {
            if (this.appDataService.urlParameters.admin_access) {
              this.shareUrlId = subUrl.split('/')[1].split('?')[0];
            } else {
              this.shareUrlId = subUrl.split('/')[1];
            }
          } else if (subUrl.indexOf('buildcard-payment') !== -1) {
            this.dataService.pendingURL = subUrl;
            if(!this.dataService.user){
              this.phaseService.showSignin = true;
              this.phaseService.showRegister = false;
              this.phaseService.openCommonAuth = true;
            }
          } else if (subUrl.indexOf('admin-dashboard') !== -1) {
            this.dataService.isAdminDashboard = true;
          } else if (subUrl.indexOf('become-a-partner') !== -1) {
            this.dataService.isBecomePartner = true;
          } else if (subUrl.indexOf('delivery') !== -1) {
            this.dataService.isDelivery = true;
          }
          // else if (subUrl.indexOf('instant_prototype') !== -1) {
          //   this.dataService.isInstantPrototype = true;
          //   this.dataService.showPrototypePayment = true;
          // }
          else {
            this.dataService.normalFlow = true;
          }
        } else {
          this.dataService.normalFlow = true;
        }
      } else {
        this.dataService.normalFlow = true;
      }
    }
    if (!this.appDataService.urlParameters.ndaSigned) {
      this.loadInitialData();
    }
    this.dataService.hashPath = null;
  }

  private setUpUserProperties() {
    if (this.dataService.user) {
      this.phaseService.parseUserProperties({
        'email': this.dataService.user.email,
        'userId': this.dataService.user.id,
        'name': this.dataService.user.first_name + ' ' + this.dataService.user.last_name,
        first_name: this.dataService.user.first_name,
        last_name: this.dataService.user.last_name,
        utm_campaign: this.appDataService.urlParameters.utm_campaign,
        utm_source: this.appDataService.urlParameters.utm_source,
        utm_medium: this.appDataService.urlParameters.utm_medium,
        utm_term: this.appDataService.urlParameters.utm_term,
        utm_content: this.appDataService.urlParameters.utm_content,
        traits: {
          email: this.dataService.user.email
        }
      });
    }
  }

  public loadInitialData() {
    // window.alert('fjfj lok')
    this.apiRequest.fetchHomePageData().subscribe((data: any) => {
      this.partnerDataService.homePageData.next(this.dataService.homePageData);
      this.apiRequest.handleCurrency();
      this.dataService.dataLoadingCurrency = Object.assign({}, this.dataService.homePageData.currency);
      if (this.dataService.homePageData && this.dataService.homePageData.platforms) {
        this.phaseService.platformPrices = Array.apply(null, Array(this.dataService.homePageData.platforms.length));
        this.phaseService.platformPrices.map((x, i) => {
          return i;
        });
      }
      if (this.dataService.homePageData.currency) {
        this.phaseService.specing_price = this.dataService.homePageData.currency.specing_price;
      }
      if (window.location.href.indexOf('shared-buildcard') === -1) {
        this.phaseService.initializeBuilder();
      }
      if (this.dataService.pendingURL) {
        this.router.navigate([this.dataService.pendingURL]);
      } else if (this.dataService.paymentStatusFlow) {
        if (this.appDataService.urlParameters.paymentType) {
          if (this.appDataService.urlParameters.paymentType === 'specing') {
            this.router.navigate([`specing-payment/${this.appDataService.urlParameters.specingId}`, 'status']);
          } else if (this.appDataService.urlParameters.paymentType === 'instant_specing') {
            const successPage = this.appDataService.urlParameters.successPage;
            if ((successPage === 'apps') || (successPage === 'features') || (successPage === 'home')) {
              this.dataService.showMeetNowModel = true;
              this.router.navigate([successPage]);
            } else {
              this.router.navigate([`meet-now/${this.appDataService.urlParameters.specingId}`]);
            }
          } else if (this.appDataService.urlParameters.paymentType === 'prototype') {
            this.router.navigate([`prototype-installment-payment/${this.appDataService.urlParameters.buildCardId}`, 'status']);
          } else if (this.appDataService.urlParameters.buildCardId && (this.appDataService.urlParameters.paymentType === 'installmentis_From_Builder_Care')) {
            this.router.navigate([`buildercare-payment/${this.appDataService.urlParameters.buildCardId}`, 'status'], { queryParams:  { installment_id: this.appDataService.urlParameters.installment_id }});
          } else if (this.appDataService.urlParameters.buildCardId || this.appDataService.urlParameters.prototypePayment === 'custom_prototype') {
            this.router.navigate([`buildcard-payment/${this.appDataService.urlParameters.buildCardId}`, 'status'], { queryParams:  { installment_id: this.appDataService.urlParameters.installment_id }});
          } else if (this.appDataService.urlParameters.uniq_code) {
            this.router.navigate([`buildcard-payment/${this.appDataService.urlParameters.uniq_code}`, 'status']);
          }
        }
      } else if (this.dataService.toSummary) {
        this.phaseService.setUpInFullBuildCard();
        this.router.navigate(['build-card/' + this.phaseService.getIdOrUniqCode()]);
      } else if (this.dataService.isBuildCardUrl) {
        if (this.dataService.user) {
          this.router.navigate([this.dataService.buildCardLink]);
        } else {
          this.phaseService.openCommonAuth = true;
          this.phaseService.showSignin = true;
        }
      } else if (this.dataService.sendToProjectProgress) {
        if (this.dataService.user) {
          this.router.navigate([this.phaseService.progressSubUrl]);
          this.dataService.sendToProjectProgress = false;
        } else {
          this.phaseService.openCommonAuth = true;
          this.phaseService.showSignin = true;
        }
      } else if (this.dataService.resetPassword) {
        if (!this.dataService.user) {
          this.router.navigate(['resetpassword']);
        } else {
          this.router.navigate(['home']);
        }

      }
      // else if (this.dataService.fairUsagePolicy) {
      //   this.router.navigate(['acceptable_fair_use_policy']);
      // }
      else if (this.dataService.invalidAccess) {
        this.router.navigate(['invalidaccess']);
      } else if (this.dataService.shareVideo) {
        this.router.navigate([this.dataService.buildCardLink]);
      } else if (this.dataService.isAdminDashboard) {
        this.router.navigate(['admin-dashboard']);
      } else if (this.dataService.isBecomePartner) {
        this.router.navigate(['become-a-partner']);
      } else if (this.dataService.isDelivery) {
        this.router.navigate(['home']);
      } else if(this.dataService.isInstantPrototype) {
        this.router.navigate([this.dataService.landingPage], { queryParams: { build_card_id: this.appDataService.urlParameters.build_card_id } });
      } else if (this.appDataService.urlParameters.v2 && this.appDataService.urlParameters.v2 === '1') {
        if(!this.dataService.user) {
          this.phaseService.openCommonAuth = true;
          this.phaseService.showRegister = true;
          this.phaseService.showV2SignUpPopup = true;
        } else {
          if (this.dataService.landingPage) {
            this.router.navigate([this.dataService.landingPage]);
          } else {
            this.router.navigate(['home']);
          }
        }
      } else {
        if (this.dataService.landingPage.split('/').length > 1) {
          this.forFeatureRequestUrl = this.dataService.landingPage;
          this.dataService.landingPage = this.dataService.landingPage.split('/')[0];
        }
        this.navigateToLandingPage();
      }
      this.dataLoading = false;
    });
  }

  public setupDataAndShowFullBuildCard() {
    if (this.appDataService.urlParameters.redirect_key === `buildcard-payment/${this.dataService.buildCardData.uniq_code}/signcontract`) {
      this.phaseService.isRedirectingSignContract = true;
      if (this.appDataService.urlParameters.isCustomPrototype) {
        this.router.navigate([`buildcard-payment/${this.dataService.buildCardData.uniq_code}`, 'billingdetails'], { queryParams: { is_custom_prototype: 'true' } });
      } else {
        this.router.navigate([`buildcard-payment/${this.dataService.buildCardData.uniq_code}`, 'billingdetails']);
      }
      // this.router.navigate([this.appDataService.urlParameters.redirect_key]);
    } else {
      this.phaseService.fromDashBoard = true;
      this.dataService.toSummary = true;
      this.loadInitialData();
    }
  }

  public updateCardAfterNDASigned(id) {
    const card: any = {};
    card.nda_signed = true;
    card.signature_request_guid = this.appDataService.urlParameters.signature_request_guid;
    card.signature_request_status = true;
    if (this.appDataService.urlParameters.record_type === 'BuildCard') {
      let promoId = 0;
      if (this.dataService.buildCardData && this.dataService.buildCardData.promotion) {
        promoId = this.dataService.buildCardData.promotion.id;
      }
      card.promotion_id = promoId;
      this.apiRequest.createUpdateBuildCard(card, id).subscribe((data) => {
        this.setupDataAndShowFullBuildCard();
      });
    } else if (this.appDataService.urlParameters.record_type === 'BuilderPartner') {
      this.dataService.showHideLoader(true);
      this.partnerApiRequest.updateNDAContract(id, card)
        .subscribe(
          (data) => {
            this.dataService.showHideLoader(false);
            if (this.partnerDataService.isPartnerLoggedIn()) {
              this.router.navigate(['become-a-partner']);
              this.dataService.resetPartnerViews();
              this.dataService.showHideViews.profile = true;
              this.dataService.isPartnerProfileUpdated = true;
            }
          },
          (error) => {
            this.dataService.showHideLoader(false);
          }
        );
    }
  }

  public updateCardAfterMasterContractSigned() {
    const contract: any = {};
    contract.nda_signed = true;
    contract.signature_request_guid = this.appDataService.urlParameters.signature_request_guid;
    contract.signature_request_status = this.appDataService.urlParameters.signature_request_status;

    if (this.appDataService.urlParameters.record_type === 'Contract') {
      this.apiRequest.createUpdateMasterContract(contract, this.appDataService.urlParameters.record_id).subscribe((data) => {
        if (data.status === 200 && (this.appDataService.urlParameters.redirect_key === `buildcard-payment/${this.dataService.buildCardData.uniq_code}/signcontract`)) {
          this.phaseService.isRedirectingMasterContract = true;
          if (this.appDataService.urlParameters.isCustomPrototype) {
            this.router.navigate([`buildcard-payment/${this.dataService.buildCardData.uniq_code}`, 'billingdetails'], { queryParams: { is_custom_prototype: 'true' } });
          } else {
            this.router.navigate([`buildcard-payment/${this.dataService.buildCardData.uniq_code}`, 'billingdetails']);
          }
        } else {
          console.log('Error during  Master Contract updation');
        }
        // if (data.status === 200) {
        //   this.router.navigate([this.appDataService.urlParameters.redirect_key]);
        // } else {
        //   console.log('Error during  Master Contract updation');
        // }
      });
    } else if (this.appDataService.urlParameters.record_type === 'PartnerContract') {
      this.partnerApiRequest.updateMasterContract(this.appDataService.urlParameters.record_id)
        .subscribe(
          (data) => {
            if (this.partnerDataService.isPartnerLoggedIn()) {
              this.router.navigate(['become-a-partner']);
              this.dataService.resetPartnerViews();
              this.dataService.showHideViews.profile = true;
              this.dataService.isPartnerProfileUpdated = true;
            }
          }
        );
    }
  }

  public navigateToLandingPage() {
    const isPlatformBrowser = this.commonService.isPlatformBrowser ? (localStorage.getItem('welcome_screen_seen') !== 'true' || (localStorage.getItem('welcome_screen_letstart') !== 'true')) : true;
    switch (this.dataService.landingPage) {
      case 'apps':
        if ((this.appDataService.urlParameters.red) && (this.appDataService.urlParameters.red !== 'now')) {
          this.dataService.showMeetNowModel = true;
        }
        this.router.navigate([this.dataService.landingPage]);
        break;
      case 'platforms':
        this.router.navigate([this.dataService.landingPage]);
        break;
      case 'features':
        if (!this.forFeatureRequestUrl) {
          if(this.appDataService.urlParameters.prototype_promo){
            const promo = this.appDataService.urlParameters.prototype_promo;
            this.router.navigate([this.dataService.landingPage], { queryParams: { prototype_promo: promo } });
          }
          else {
            this.router.navigate([this.dataService.landingPage]);
          }
        } else {
          this.router.navigate([this.forFeatureRequestUrl], { queryParams: { featureRequest: 'true' } });
        }
        break;
      case 'shared-buildcard' :
        this.router.navigate([this.dataService.landingPage, this.shareUrlId]);
        break;
      case 'dashboard':
        this.dataService.user ? this.router.navigate(['dashboard']) : this.router.navigate(['home']);
        if (this.dataService.user) {
          this.router.navigate(['dashboard']);
        }
        break;
      case 'build-card':
        break;
      case 'reset-password':
        break;
      case 'welcome':
        if (isPlatformBrowser && this.phaseService.shouldShowWelcomePopup() && (localStorage.getItem('welcome_screen_seen') !== 'true' || (localStorage.getItem('welcome_screen_letstart') !== 'true')) && !this.dataService.user && (this.appDataService.urlParameters.red === null) && this.commonService.isPlatformBrowser) {
          this.phaseService.showWelcomeScreen = true;
          this.router.navigate(['welcome']);
        } else {
          this.phaseService.showWelcomeScreen = false;
          this.router.navigate(['home']);
        }
        break;
      default:
        if (isPlatformBrowser && this.phaseService.shouldShowWelcomePopup() && (localStorage.getItem('welcome_screen_seen') !== 'true' || (localStorage.getItem('welcome_screen_letstart') !== 'true')) && !this.dataService.user && (this.appDataService.urlParameters.red === null) && this.commonService.isPlatformBrowser) {
          this.phaseService.showWelcomeScreen = true;
          this.router.navigate(['welcome']);
        } else {
          this.phaseService.showWelcomeScreen = false;
          if (this.appDataService.urlParameters.red && (this.appDataService.urlParameters.red !== 'now')) {
            this.router.navigate(['apps']);
          } else {
            this.router.navigate(['home']);
          }
        }
    }
  }

  private handleNdaSignFlow() {
    if (this.appDataService.urlParameters.uniq_code || this.appDataService.urlParameters.record_type === 'PartnerContract' || this.appDataService.urlParameters.record_type === 'BuilderPartner') {
      if (this.appDataService.urlParameters.ndaSigned === 'signature_request_signed') {
        if (this.appDataService.urlParameters.record_type === 'BuilderPartner') {
          this.updateCardAfterNDASigned(this.appDataService.urlParameters.record_id);
        } else if (this.appDataService.urlParameters.record_type === 'PartnerContract') {
          this.updateCardAfterMasterContractSigned();
        } else {
          this.phaseService.inProgressUniqCode = this.appDataService.urlParameters.uniq_code;
          this.apiRequest.fetchSingleBuildCardData(this.phaseService.inProgressUniqCode).subscribe((data: any) => {
            if (this.appDataService.urlParameters.record_type === 'BuildCard') {
              this.updateCardAfterNDASigned(data.id);
            } else if (this.appDataService.urlParameters.record_type === 'Contract') {
              this.updateCardAfterMasterContractSigned();
            }
          });
        }
      }
    } else {
      this.dataService.normalFlow = true;
    }
  }
}
