import { TeamModel } from "./team.model";
import { JsonObject, JsonProperty } from "json2typescript";

/**
 * Created by nitin on 14/12/17.
 */
@JsonObject
export class PopularTeams {
  @JsonProperty("all", [TeamModel])
  all: TeamModel[] = [];
}
@JsonObject
export class TeamsModel {
  default: TeamModel = new TeamModel();
  current: TeamModel = new TeamModel();
  all: TeamModel[] = [];
  popular: TeamModel[] = [];
}
