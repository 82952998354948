<div class="payConfirmScreen">
  <div class="payConfirmHolder">
    <h2>Payment Confirmation</h2>
    <h3>On click of "Proceed to Payment", you will be redirected to Payment Gateway.</h3>

    <div class="footerButton">
      <button type="button" class="skipButton" (click)="redirectToThankyouScreen()">Skip for Now</button>
      <app-partner-payment-submission></app-partner-payment-submission>

      <p>By opting "Skip", the Partner Payments would be handled offline.</p>
    </div>
  </div>
</div>
