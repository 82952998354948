<div class="baseBlock" [ngClass]="{'hide': dataService.isShowBantModal|| dataService.openSchedulerPopup}">
  <div class="middle-block" [ngClass]="{'heightSet' : isShowExitIntent && !(dataService.exitIntentSecondScreen)}">
    <ng-container *ngIf="isExpertAvailableButtonClicked || isFromBillingDetail || dataService.exitIntentSecondScreen || (!phaseService.isShowPaymentExitOffer && phaseService.isExitPaymentOffer)">
      <div class="main-screen" [ngClass]="{slideThis: (!dataService.isSpecCallCreated && isExpertAvailableButtonClicked) || (dataService.exitIntentSecondScreen && !dataService.isAfterAuthentication)
        || (!phaseService.isShowPaymentExitOffer && phaseService.isExitPaymentOffer && !phaseService.isShowPaymentExitFeedback) || (isFromBillingDetail && !phaseService.isShowPaymentExitFeedback)}"
           *ngIf="(!phaseService.showInstantSpecCallFlow && !phaseService.showScheduleCallFlow) && !phaseService.showSignNDAPopup && ((!dataService.isSpecCallCreated && isExpertAvailableButtonClicked) || (!phaseService.showMeetNowSearchingScreen && dataService.exitIntentSecondScreen && !dataService.isAfterAuthentication) || (isFromBillingDetail && !phaseService.isShowPaymentExitFeedback && !phaseService.showMeetNowSearchingScreen)
              || (!phaseService.isShowPaymentExitOffer && phaseService.isExitPaymentOffer && !phaseService.isShowPaymentExitFeedback && !phaseService.showMeetNowSearchingScreen))">
        <div class="spec-call-folow">
          <div *ngIf="isExpertAvailableButtonClicked">
            <div class="closeButton" *ngIf="isExpertAvailableButtonClicked" (click)="closeModal('showOverLoadingScreen')"><span class="icon-cancel"></span></div>
            <h2 class="header"> Need Help? </h2>
            <div class="experts-block">
              <!--<div class="img-bx"> <img src="../../../../../assets/images/experts.png" alt="exprts icon"/> </div>-->
              <div class="expertSlider">
                <ngu-carousel #myCarousel  [inputs]="carouselTile" [dataSource]="carouselTileItems">

                  <ngu-tile *nguCarouselDef="let item of carouselTileItems">
                    <img src="{{item}}" alt="" />
                  </ngu-tile>

                  <button NguCarouselPrev class="actionbutton leftArrow" [disabled]="myCarousel.isFirst"><em class="icon-prev"></em></button>
                  <button NguCarouselNext class="actionbutton rightArrow" [disabled]="myCarousel.isLast"><em class="icon-next"></em></button>

                </ngu-carousel>
              </div>
              <div class="talktoTeam">
                <p *ngIf="!phaseService.isDemoCall">Talk to our team of product experts <br> <strong class="strick">worth $300 </strong> <strong> absolutely <span>FREE</span></strong></p>
                <p *ngIf="phaseService.isDemoCall">Talk to sales and get free demo</p>
              </div>
            </div>
          </div>

          <div *ngIf="isFromBillingDetail && !phaseService.isShowPaymentExitFeedback">
            <div class="closeButton" (click)="closePaymentExitPopups()"><span class="icon-cancel"></span></div>
            <h2 class="header"> Need Help with filling <br>the contracts? </h2>
            <div class="experts-block">
              <div class="img-bx"> <div class="height90"><img src="../../../../../assets/images/exitIcon3.png" class="auto" alt="" /></div></div>
            </div>
            <div class="talktoTeam">
              <p>Talk to our team of product experts <br> <strong class="strick">worth $300 </strong> <strong> absolutely <span>FREE</span></strong></p>
            </div>
          </div>

          <div *ngIf="!phaseService.isShowPaymentExitOffer && phaseService.isExitPaymentOffer && !phaseService.isShowPaymentExitFeedback">
            <div class="closeButton" (click)="closePaymentExitPopups()"><span class="icon-cancel"></span></div>
            <h2 class="header"> Prefer to pay another way? </h2>
            <div class="experts-block">
              <div class="img-bx"> <div class="height90"><img src="../../../../../assets/images/exitIcon3.png" class="auto" alt="" /></div> </div>
            </div>
            <div class="talktoTeam">
              <p>Talk to our team of product experts <br> <strong class="strick">worth $300 </strong> <strong> absolutely <span>FREE</span></strong></p>
            </div>
          </div>

          <div *ngIf="dataService.exitIntentSecondScreen && !dataService.hideExitIntentPopUp">
            <div class="closeButton" (click)="closeExitPopup(true)"><span class="icon-cancel"></span></div>
            <h2 class="header"> Need Help in pricing </h2>
            <div class="experts-block">
              <div class="img-bx"> <img src="../../../../../assets/images/experts.png" alt="exprts icon"/> </div>
            </div>

            <div class="talktoTeam">
              <p>Talk to our team of product experts <br> <strong class="strick">worth $300 </strong> <strong> absolutely <span>FREE</span></strong></p>
            </div>
          </div>

          <div class="action-space">
            <form>
              <div class="availableCall">
                <input type="text" placeholder="Are you ready for a 30 mins call?">
                <button type="button" class="yesButton" *ngIf="!phaseService.talkToSalesClicked" (click)="connectNowClicked(false, 'instant')">Yes</button>
                <button type="button" class="yesButton" *ngIf="phaseService.talkToSalesClicked" (click)="connectNowClicked(false, 'instant', 'demo')">Yes</button>
                <button type="button" class="connectButton" [ngClass]="{'hideAnimation' : showAvailabilityScreen}" (click)="askForAvailability()"> Connect Now </button>
              </div>
              <div class="actionbtn"><button type="button" *ngIf="!phaseService.talkToSalesClicked" class="scheduleButton" (click)="checkToShowNDA()">Schedule a Call</button></div>
              <div class="actionbtn"><button type="button" *ngIf="phaseService.talkToSalesClicked" class="scheduleButton" (click)="connectNowClicked(true, 'normal', (phaseService.isDemoCall ? 'demo' : 'spec'))">Schedule a Call</button></div>
              <div class="actionbtn"><button type="button" class="nothanksButton" *ngIf="((isFromBillingDetail && !phaseService.isShowPaymentExitFeedback) || (!phaseService.isShowPaymentExitOffer && phaseService.isExitPaymentOffer && !phaseService.isShowPaymentExitFeedback)) && !showAvailabilityScreen" (click)="showExitFeedback()">No thanks</button></div>
            </form>
          </div>
          <p class="noteText">Note: All calls are protected by NDA</p>
        </div>


      </div>

      <div class="findExpert-screen" [ngClass]="{'slideThis': !(!phaseService.specCallCreationData?.manager_availability && dataService.isSpecCallCreated && !phaseService.isPaymentDone)
       && !(phaseService.specCallCreationData?.manager_availability && dataService.isSpecCallCreated && phaseService.isPaymentDone)}"
           *ngIf="phaseService.showMeetNowSearchingScreen && !phaseService.showSignNDAPopup" >
        <div class="spec-call-folow">
          <div *ngIf="(isFromBillingDetail && !phaseService.isShowPaymentExitFeedback) || (!phaseService.isShowPaymentExitOffer && phaseService.isExitPaymentOffer && !phaseService.isShowPaymentExitFeedback)" class="closeButton" (click)="closePaymentExitPopups()"><span class="icon-cancel"></span></div>
          <div class="closeButton" *ngIf="isShowExitIntent" (click)="closeExitPopup(false)"><span class="icon-cancel"></span></div>
          <h2 class="header"> Finding an expert for you </h2>
          <div class="experts-block">
            <div class="img-bx findExport">
              <div class="findBx">
                <em class="icon-magnifying brandColor"></em>
              </div>
            </div>
            <div class="regard-text" *ngIf="!phaseService.talkToSalesClicked"> We are looking for product expert, it will take few seconds. </div>
            <div class="regard-text" *ngIf="phaseService.talkToSalesClicked"> We are looking for sales expert, it will take few seconds. </div>
          </div>
        </div>
      </div>

      <div class="NotAvailExpert-screen" [ngClass]="{'slideThis': !phaseService.specCallCreationData?.manager_availability && dataService.isSpecCallCreated && !phaseService.isPaymentDone && !phaseService.showSignNDAPopup}" *ngIf="!phaseService.specCallCreationData?.manager_availability && dataService.isSpecCallCreated && !phaseService.isPaymentDone && !phaseService.showSignNDAPopup">
        <div class="spec-call-folow">
          <div class="closeButton" *ngIf="isExpertAvailableButtonClicked" (click)="closeModal('showOverLoadingScreen')"><span class="icon-cancel"></span></div>
          <div class="closeButton" *ngIf="isShowExitIntent" (click)="closeExitPopup(false)"><span class="icon-cancel"></span></div>
          <div *ngIf="(isFromBillingDetail && !phaseService.isShowPaymentExitFeedback) || (!phaseService.isShowPaymentExitOffer && phaseService.isExitPaymentOffer && !phaseService.isShowPaymentExitFeedback)" class="closeButton" (click)="closePaymentExitPopups()"><span class="icon-cancel"></span></div>
          <h2 class="header only-rentel"> All experts are busy now </h2>

          <div class="experts-block">
            <div class="img-bx noexport only-rentel"> <img src="../../../../../assets/images/busy-expeert-rental.png" alt="exprts find"/> </div>
          </div>
          <p class="subheader">

            <span class="main-flow-txt only-rentel">
              <span class="font-normal" *ngIf="!phaseService.talkToSalesClicked">Schedule a call with product experts
              <span class="dBlock"> <span class="light-gray strick"> worth $300</span>  absolutely <span class="text-uppercase"> free </span>  </span>
              </span>
              <span class="font-normal" *ngIf="phaseService.talkToSalesClicked">Schedule a call with our sales and get free demo</span>
            </span>

          </p>
          <div class="action-space">
            <form>
              <div class="actionbtn only-rentel" *ngIf="!phaseService.talkToSalesClicked"> <button type="button" (click)="checkToShowNDA(true)"> Schedule a Call </button> </div>
              <div class="actionbtn only-rentel" *ngIf="phaseService.talkToSalesClicked"> <button type="button" (click)="connectNowClicked(true, 'normal', 'demo', true)"> Schedule a Call </button> </div>
            </form>
          </div>
          <p class="noteText">Note: All calls are protected by NDA</p>
        </div>
      </div>
      <div class="availableExpert-screen" [ngClass]="{'slideThis': phaseService.specCallCreationData?.manager_availability && dataService.isSpecCallCreated && phaseService.isPaymentDone && !phaseService.showSignNDAPopup}" *ngIf="phaseService.specCallCreationData?.manager_availability && dataService.isSpecCallCreated && phaseService.isPaymentDone && !phaseService.showSignNDAPopup">
        <div class="spec-call-folow">
          <div class="closeButton" *ngIf="isExpertAvailableButtonClicked" (click)="closeModal('showOverLoadingScreen')"><span class="icon-cancel"></span></div>
          <div *ngIf="(isFromBillingDetail && !phaseService.isShowPaymentExitFeedback) || (!phaseService.isShowPaymentExitOffer && phaseService.isExitPaymentOffer && !phaseService.isShowPaymentExitFeedback)" class="closeButton" (click)="closePaymentExitPopups()"><span class="icon-cancel"></span></div>
          <div class="closeButton" *ngIf="isShowExitIntent" (click)="closeExitPopup(false)"><span class="icon-cancel"></span></div>
          <h2 class="header"> {{ phaseService.specCallCreationData?.assigned_to }} is available </h2>
          <div class="experts-block only-rental">
            <div class="img-bx noexport"> <img src="../../../../../assets/images/rental-user.png" alt="export"/> </div>
            <div class="regard-text" *ngIf="!phaseService.talkToSalesClicked"> Our product expert is ready to attend the meeting </div>
            <div class="regard-text" *ngIf="phaseService.talkToSalesClicked"> Our sales expert is ready to attend the meeting </div>
          </div>
          <div class="action-space">
            <form>
              <div class="actionbtn only-rentel">
                <button type="button" [disabled]="!phaseService.specCallData?.zoom_link" (click)="closeModal('copyLink')"> Start Now </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="phaseService.isShowPaymentExitFeedback">
        <app-payment-exit-intent-feedback></app-payment-exit-intent-feedback>
      </div> -->

    </ng-container>
    <!-- <app-exitintent-feedback *ngIf="isShowExitIntent && !(dataService.exitIntentSecondScreen) && !phaseService.showSignNDAPopup" (eventChange)="closeEventTriggered()"></app-exitintent-feedback> -->
    <!-- <app-exitintent-discount-offer *ngIf="phaseService.isShowPaymentExitOffer"></app-exitintent-discount-offer> -->
  </div>
</div>

<div class="tellusPopupHolder" [ngClass]="{'hide': !dataService.isShowBantModal}"  *ngIf="dataService.isShowBantModal">
  <div class="closePopUp" (click)="closeBantModal()" ><span class="icon-cancel"></span></div>
  <div class="tellusLeft">
    <h3>Tell us about you</h3>
    <img src="../../../../../assets/images/telluspopup.png" alt="" />
  </div>
  <div class="tellusRight">
    <div class="tellusContent">
      <h3>By answering a few simple questions now we can get you to a solution faster.</h3>
<!--      <p>Or pay $100 to connect without answering the questions.</p>-->
      <div class="optionSets" *ngFor="let question of bantQuestion; let questionIndex = index">
        <h4>{{question.title}}</h4>
        <ul (click)="selectBantAnswer($event, questionIndex)">
          <li *ngFor="let option of question.options; let answerIndex = index">
            <input type="radio" name="set{{questionIndex}}" value="{{answerIndex}}" id="option{{answerIndex}}{{questionIndex}}">
            <label for="option{{answerIndex}}{{questionIndex}}">{{option.key}}</label>
          </li>
        </ul>
      </div>
    </div>
    <div class="submitAnswer">
      <button type="button" (click)="saveBantAnswer()" [disabled]="isAllQuestionAnswered()" >Submit answers</button>
    </div>
  </div>
</div>

<div class="shedulerPopup" *ngIf="dataService.openSchedulerPopup">
  <div class="closePopUp"><span class="icon-cancel" (click)="openBeforeLeavingSchedulerPopup()"></span></div>
  <div class="shedulerPopupHolder"><iframe frameborder="0" title="" [src]="schedulerURL"></iframe></div>
  <div class="bottomLinkText" (click)="closeSchedulerPopup()" *ngIf="showTextExceptINR()">
    You want to be billed as a company, so we need extra tax info from you. To avoid any delay – see what we need in your dashboard and add it now.
  </div>
  <div class="bottomLinkText" (click)="closeSchedulerPopup()" *ngIf="showTextForINR()">
    To ensure you are billed correctly, we need extra tax info from you. To avoid any delay – see what we need in your dashboard and add it now.
  </div>
</div>

<div class="commonPopUp" [ngClass]="{'active': phaseService.showBeforeLeavingSchedulerPopup}">
  <div class="popOverlay" (click)="closeSpecModal()"></div>
  <div class="popHolder beforeYouLeavePopup">
    <div class="closePopUp"><span class="icon-cancel" (click)="closeSpecModal()"></span></div>
    <div>
      <h3>Before you leave</h3>
      <div *ngIf="phaseService.isDemoCall && (dataService.currentPage !== 'paymentStatus') && !this.phaseService.isFromDashboard">
        <p>Hey <span>{{dataService?.user?.first_name}}.</span></p>
        <p>We're stoked to show you how we can bring your app idea to life! Book a chat with us now to get the ball rolling.</p>
      </div>
      <div *ngIf="!phaseService.isDemoCall && (dataService.currentPage !== 'paymentStatus') && !this.phaseService.isFromDashboard">
        <p>Hey again <span>{{dataService?.user?.first_name}}.</span></p>
        <p>The Spec call is where we get to dive into the detail with you about your idea. Book your spec call now so we can customise your Buildcard.</p>
      </div>
      <div *ngIf="(dataService.currentPage === 'paymentStatus' || this.phaseService.isFromDashboard)">
        <p>Hey again <span>{{dataService?.user?.first_name}}.</span></p>
        <p>We can't wait to get started on your Buildcard! We just need to complete your Kickoff call to get going. Can we book yours in now?</p>
      </div>
      <div class="buttonWrap">
        <button type="button" class="btnbookItLater" *ngIf="((this.dataService.currentPage !== 'paymentStatus') && !this.phaseService.isFromDashboard)" (click)="scheduleCallLater()">No, I'll book it later</button>
        <button type="button" class="btnbookItLater" *ngIf="((this.dataService.currentPage === 'paymentStatus') || this.phaseService.isFromDashboard)" (click)="showOptions()">
          <span class="selectedReason">No, select reason</span>
          <em class="icon-downside"></em>

          <ul *ngIf="showCancelOptions">
            <li (click)="scheduleCallLater('I am not able to start immediately')">I am not able to start immediately</li>
            <li (click)="scheduleCallLater('I need to check my availability')">I need to check my availability</li>
            <li (click)="scheduleCallLater('I can\'t find a suitable time to book')">I can't find a suitable time to book</li>
          </ul>
        </button>

        <button type="button" class="btnBookNow" (click)="scheduleCallNow()">Yes, let me book now</button>
      </div>
    </div>
  </div>
</div>
