import {Component, OnInit} from '@angular/core';
import {ApiRequests} from '../../services/api-requests.service';
import {DataService} from '../../services/data.service';
import {PhaseService} from '../../services/phase.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'sharable-url',
  templateUrl: 'sharable-url.component.html'
})

export class SharableUrlComponent implements OnInit {
  public shareId;

  constructor(public apiRequests: ApiRequests,
              public dataService: DataService,
              public phaseService: PhaseService,
              public route: ActivatedRoute,
              public router: Router, private toaster: ToastrService) {

  }

  public ngOnInit() {
    this.dataService.showHideLoader(true);
    this.shareId = this.route.snapshot.params.id;
    this.fetchSharableUrl(this.shareId);
  }

  public fetchSharableUrl(id) {
    this.apiRequests.fetchSharableUrl(id)
      .subscribe(
        (data) => {
          if (data.url_type === 'json') {
            this.dataService.shareUrlData = JSON.parse(data.url);
            if (this.dataService.shareUrlData) {
              this.phaseService.breadcrumb_links = this.dataService.shareUrlData.breadcrumbs;
              this.dataService.showHideLoader(false);
              this.phaseService.initializeBuilder();
              if (this.dataService.shareUrlData.curPage) {
                this.router.navigate([this.dataService.shareUrlData.curPage]);
              }
            }else{
              this.router.navigate(['home']);
            }
          } else {
            this.router.navigate(['home']);
          }
        }, error => {
          this.router.navigate(['home']);
          this.toaster.warning('This link has been expired for security purpose.', 'Please generate a new link', {positionClass: 'toast-top-center'});
        }
      );
  }

  public setPreSelectedData() {
    this.phaseService.breadcrumb_links = JSON.parse(this.dataService.shareUrlData.breadcrumbs);
    // if (this.dataService.shareUrlData.selectedDataForSidePanel) {
    //   this.phaseService.selectedDataForSidePanel = JSON.parse((this.dataService.shareUrlData.selectedDataForSidePanel));
    // }
    if (this.dataService.shareUrlData.platformIds) {
      this.setPlatformData();
    }
  }

  public setPlatformData() {
    this.phaseService.selectedPlatforms = [];
    this.phaseService.unSelectedPlatforms = [];
    this.phaseService.selectedHash.platform_ids = [];
    const platformIds = this.dataService.shareUrlData.platformIds.split(',');
    const platforms = this.dataService.homePageData.platforms;
    for (const platform of platforms) {
      platformIds.indexOf(platform.id) > -1 ? platform.selected = true : platform.selected = false;
      if (platform.selected) {
        this.phaseService.toggleSelectedPlatform(platform);
        this.phaseService.toggleSelectedPlatformId(platform.id);
      } else {
        this.phaseService.unSelectedPlatforms.push(platform);
      }
    }
    this.phaseService.updatePrice();
  }

}
