import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { PhaseService } from '@shared/services/phase.service';
import { DataService } from '@shared/services/data.service';
import { RentalService } from '@shared/services/rental.service';
import parsePhoneNumber from 'libphonenumber-js';
import { ApiRequests } from "src/app/shared/shared-module/services/api-requests.service";

@Component({
  selector: 'app-address-pop-up',
  templateUrl: './address-pop-up.component.html',
  styleUrls: ['./address-pop-up.component.scss']
})
export class AddressPopUpComponent implements OnInit {
  showCountryDropDown = false;
  showGSTIN = false;
  isSaveButtonClicked = false;
  isGSTCheckboxChecked = false;
  showStateDropDown = false;
  @ViewChild('mobileNumber') public mobileNumber;
  selectedStates = [];
  userCountryData = [];
  constructor(public phaseService: PhaseService, public apiService: ApiRequests, public dataService: DataService, public rentalService: RentalService) {
    this.getCountryviseState();
  }

  getCountryviseState() {
    this.apiService.getUserCountryData().subscribe((data: any) => {
      this.userCountryData = data.data;
      if (this.dataService.user.currency.id === 4) {
        this.phaseService.billingInfo.country = data['data'][0]['name'];
      }
      this.availablestate();
    }, error => {
      // this.commonService.showError();
    });
  }

  ngOnInit(): void {

    if (this.dataService.userCurrencyAndCountries.available_country.length === 1 && this.dataService.userCurrencyAndCountries.available_country[0].code === 'GB') {

      this.phaseService.billingInfo.country = this.dataService.userCurrencyAndCountries.available_country[0].name
    }
    this.dataService.IsGstValid = true;
  }

  getBillingInfo() {
    if (this.dataService.user.currency.code === 'INR') {
      this.showGSTIN = (this.phaseService.billingInfo && this.phaseService.billingInfo.country && (this.phaseService.billingInfo.country.toLowerCase() === 'india')) ? true : false;
    }
      this.phaseService.billingInfo.gstNumber = '';
      this.isGSTCheckboxChecked = false;
    if (this.phaseService.billingInfo.street) {
      this.phaseService.billingInfo.street = decodeURIComponent(this.phaseService.billingInfo.street);
    }
    if (this.phaseService.billingInfo.apartment) {
      try {
        this.phaseService.billingInfo.apartment = decodeURIComponent(this.phaseService.billingInfo.apartment);
      } catch (e) {

      }
    }
    Object.keys(this.phaseService.billingInfo).map((key) => {
      if (this.phaseService.billingInfo[key] && ((this.phaseService.billingInfo[key] === 'null') || (this.phaseService.billingInfo[key] === undefined))) {
        this.phaseService.billingInfo[key] = '';
      }
      if (this.phaseService.billingInfo[key] && key != 'contact') {
        this.phaseService.billingInfo[key] = this.phaseService.billingInfo[key].trim();
      }
      return this.phaseService.billingInfo[key];
    });
    return this.phaseService.billingInfo;
  }

  hideDropDown() {
    this.showCountryDropDown = false;
    this.showStateDropDown = false;
  }

  ShowDropdown() {
    this.showStateDropDown = true;
  }

  showCountriesList() {
    this.showCountryDropDown = true;
    this.showStateDropDown = false;
  }
  closeModal() {
    this.rentalService.isAddressSelected = false;
  }

  checkIfFormValid() {
    for (const key of Object.keys(this.phaseService.billingInfo)) {
      if ((this.phaseService.billingInfo[key] === '') && (((key !== 'name') && (key !== 'address_line_2') && (key !== 'billing_entity') && (key !== 'companyName') && (key !== 'address') && (key !== 'gstNumber') && (key !== 'apartment')))) {
        return false;
      }
    }
    return true;
  }
  onFlagChange() {
    if (this.mobileNumber && this.mobileNumber.valid && this.mobileNumber.valueAccessor) {
      this.mobileNumber.countryNameCode = this.mobileNumber.valueAccessor.selectedCountry.countryCode;
      this.mobileNumber.countryNameCode = this.mobileNumber.valueAccessor.selectedCountry.dialCode;
    }
  }
  submitAddress() {
    this.isSaveButtonClicked = true;
    this.hideDropDown();
    const billingInfo = this.phaseService.billingInfo;
    const isUkOrCyprusCountry = !this.ifNotUkOrCyprus();
    const ifCountriesAreAvailable = this.dataService.userCurrencyAndCountries && this.dataService.userCurrencyAndCountries['available_country'] && (this.dataService.userCurrencyAndCountries['available_country'].length > 0);
    if (ifCountriesAreAvailable && !this.dataService.userCurrencyAndCountries['available_country'].map(country => country.name.toLowerCase()).includes(billingInfo.country.toLowerCase())) {
      billingInfo.country = '';
      return;
    }
    if (!isUkOrCyprusCountry && !this.selectedStates.map(state => state.name.toLowerCase()).includes(billingInfo.state.toLowerCase())) {
      billingInfo.state = '';
      return;
    }
    if (isUkOrCyprusCountry) {
      delete billingInfo.state;
    }
    if (!this.checkIfFormValid() || this.checkIfPhoneInValid()) {
      return;
    }
    this.isSaveButtonClicked = false;
    this.rentalService.isAddressSelected = false;
    this.phaseService.addressSubmitted = true;
  }

  public selectState(state) {
    this.phaseService.billingInfo.state = state.name;
    this.showStateDropDown = false;
  }

  showStatesList(name?) {
    if (name === 'states') {
      this.availablestate();
      this.showStateDropDown = true;
      this.showCountryDropDown = false;
    } else if (name === 'zipCode') {
      this.showCountryDropDown = false;
    } else {
      this.showCountryDropDown = true;
      this.showStateDropDown = false;
    }
  }


  selectCountry(country) {
    this.phaseService.billingInfo.country = country.name;
    this.showCountryDropDown = false;
    this.availablestate();
  }

  availablestate() {

    const index = this.userCountryData.findIndex((country) => {
      return this.phaseService.billingInfo.country.toLowerCase() === country.name.toLowerCase();
    })
    if (index !== -1) {
      this.selectedStates = this.userCountryData[index].states.states;
    }
  }

  checkIfPhoneInValid() {
    const phoneNumber = parsePhoneNumber(this.getBillingInfo().contact.toString(), this.dataService.countryISOCode);
    if (phoneNumber) {
      return !(phoneNumber.isValid() && this.getBillingInfo().contact.includes('+' + phoneNumber.countryCallingCode));
    } else {
      return true;
    }
  }

  setUserContact(event) {
    this.getBillingInfo().contact = event;
  }

  ifNotUkOrCyprus() {
    return ((this.dataService.user.currency.id !== 4) && (this.phaseService.billingInfo.country.toLocaleLowerCase() !== 'Cyprus'.toLocaleLowerCase()));
  }
}
