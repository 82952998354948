import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "@shared/shared.module";
import { CommonModule } from "@angular/common";
import { DragScrollModule } from "ngx-drag-scroll";
import { FeatureCardComponent } from "./featurecard.component";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { AddfeatureComponent } from "@featuremodules/feature-module/popups/addnewfeature/addnewfeature.component";
import { ReversePipe } from "@featuremodules/feature-module/pipes/reverse.pipe";
import { UtilityService } from "@shared/services/utility.service";
// import { UserIdleModule } from 'angular-user-idle';
import { NgxSliderModule } from "@angular-slider/ngx-slider";

import { SortFeaturePipe } from "./pipes/sort-feature.pipe";
import { FeatureMaxCountPopupComponent } from "@featuremodules/feature-module/feature-maxcount-pupup/feature-maxcount-pupup.component";
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
const childRouterConfig: Routes = [
  { path: "", component: FeatureCardComponent },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DragScrollModule,
    SharedModule,
    PerfectScrollbarModule,
    RouterModule.forChild(childRouterConfig),
    // UserIdleModule.forRoot({ idle: 1, timeout: 1, ping: 1 }),
    CKEditorModule,
    NgxSliderModule,
  ],
  declarations: [
    FeatureCardComponent,
    FeatureMaxCountPopupComponent,
    AddfeatureComponent,
    ReversePipe,
    SortFeaturePipe,
  ],
  providers: [UtilityService, SortFeaturePipe],
})
export class FeatureModule {}
