import {Component, Input, ViewChild} from '@angular/core';
import {DragScrollComponent} from 'ngx-drag-scroll';
import {Angulartics2Segment} from 'angulartics2/segment';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'other-product',
  styleUrls: ['other-product.component.scss'],
  templateUrl: 'other-product.component.html'
})

export class OtherProductComponent {

  @Input('sibling_promotions') sibling_promotions;
  @Input('sibling_promotionsSection') sibling_promotionsSection;
  leftNavDisabled = false;
  rightNavDisabled = false;
  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;

  constructor( public analyticsSegment: Angulartics2Segment, public phaseService: PhaseService, public dataService: DataService) {
  }

  primarySections = [];
  showLoader = true;

  ngOnInit() {
    if (this.sibling_promotions.primary_section) {
      this.primarySections = this.sibling_promotions.primary_section;
      this.sortByPosition();
      if (this.primarySections.length > 0) {
        this.showLoader = false;
      }
    }
  }

  ngOnChanges() {
    if (this.sibling_promotionsSection) {
      this.primarySections = this.sibling_promotionsSection.section_details;
      this.sortByPosition();
      if (this.primarySections.length > 0) {
        this.showLoader = false;
      }
    }
  }

  public sortByPosition() {
    this.primarySections.sort(function (a, b) {
      const keyA = new Date(a.precedence),
        keyB = new Date(b.precedence);
      // Compare the 2 dates
      if (keyA < keyB) { return -1; }
      if (keyA > keyB) { return 1; }
      return 0;
    });
  }


  redirectToLink(section) {
    window.location.href = section.redirection_link;
  }

  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }

}
