<div class="offerSection">
  <div class="sectionContent">
    <h3>{{offers.title}}<img src="../../../../../assets/images/smile4.png" width="38" height="38" alt=""/></h3>
    <p>{{offers.description}}</p>
  </div>

  <div class="bLoader" *ngIf="showLoader">
    <div class="loaderBox"><img src="../../../../../assets/images/bloader.gif" alt=""/></div>
    <div class="loaderBox"><img src="../../../../../assets/images/bloader.gif" alt=""/></div>
    <div class="loaderBox"><img src="../../../../../assets/images/bloader.gif" alt=""/></div>
  </div>

  <div class="offerRow" *ngIf="!showLoader">
    <drag-scroll
      (reachesLeftBound)="leftBoundStat($event)"
      (reachesRightBound)="rightBoundStat($event)" #nav>
      <div drag-scroll-item class="offerBox" *ngFor="let pSection of primarySections">
        <h3>{{pSection.banner_message}}</h3>
        <h4>CODE : {{pSection.code}}</h4>
        <button type="button" class="claimButton" (click)="redirectToOffer(pSection)">Claim this Offer</button>
        <p>Extra charges apply for additional selections!</p>
      </div>
    </drag-scroll>
    <button class="dragleftArrow" [disabled]="leftNavDisabled" type="button" (click)="moveLeft()"><em
      class="icon-nexticon"></em></button>
    <button class="dragrightArrow" [disabled]="rightNavDisabled" type="button" (click)="moveRight()"><em
      class="icon-nexticon"></em></button>
  </div>
</div>
