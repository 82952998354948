

<div class="contentSection freemium" *ngIf="!appDataService.urlParameters.is_freemium">
    <div class="stepsBx" *ngIf="!phaseService.conditionToCreateAndSyncBuildCardToBNow()">
        <div class="stepsmain">
            <ul class="sliderinline">
                <li [ngClass]="phaseService.selectedSectionIndex === 4 ? 'currentSelected':'clickable'">
                  <img class="partyHatIcon" [ngClass]="{enabled: phaseService.selectedSectionIndex === 4}" src="../../../../../assets/images/party-hat-icon.svg" alt=""/></li>
                <li [ngClass]="phaseService.selectedSectionIndex === 3 ? 'currentSelected':'clickable'"
                    (click)="handlePaymentoption(3)"><em class="icon-tick"></em><span>4</span></li>
                <li [ngClass]="phaseService.selectedSectionIndex === 2 ? 'currentSelected':'clickable'"
                    (click)="handleContractClick(2)"><em class="icon-tick"></em><span>3</span></li>
                <li [ngClass]="phaseService.selectedSectionIndex === 1 ? 'currentSelected':'clickable'"
                    (click)="handlePaymentOverViewClick(1)"><em class="icon-tick"></em><span>2</span></li>
                <li [ngClass]="phaseService.selectedSectionIndex === 0 ? 'currentSelected':'clickable'"><em class="icon-tick"></em>
                    <span>1</span></li>
            </ul>
        </div>
        <h2 *ngIf="phaseService.selectedSectionIndex === 0">{{(dataService.newSignUpFlowScreen === 'signup')? 'Sign up' : 'Sign in'}}</h2>
        <ng-container *ngIf="!appDataService.urlParameters.is_freemium">
          <h2 *ngIf="phaseService.selectedSectionIndex === 1">
            <span>{{phaseService.selectMonthlyPackage ? 'Monthly' : 'One time'}} </span>payment Overview
          </h2>
        </ng-container>
        <ng-container *ngIf="appDataService.urlParameters.is_freemium">
          <h2 *ngIf="phaseService.selectedSectionIndex === 1">Store details</h2>
        </ng-container>
        <h2 *ngIf="phaseService.selectedSectionIndex === 2">Account Information</h2>
        <ng-container *ngIf="phaseService.selectedSectionIndex === 3">
          <h2>Payment Options</h2>
          <span *ngIf="appDataService.urlParameters.is_freemium">
            <!-- We take {{ getCurrency() + '' + phaseService.getFirstNonZeroInstallmentAmount() }} out of your account to check you’re a real person. Then it’s instantly refunded. -->
            Before we can onboard you, we need to check if you're a real person. This helps keep our platform secure and working efficiently. To do this, we need to take {{ getCurrency() + '' + phaseService.getFirstNonZeroInstallmentAmount() }} from your account, which we’ll instantly refund.
          </span>
        </ng-container>
        <!-- SMB user flow -->
        <!-- <h2 *ngIf="phaseService.selectedSectionIndex === 4">Thank you!</h2> -->
        <h2 class="smbUserFlow" *ngIf="phaseService.selectedSectionIndex === 4">Thank you!</h2>
    </div>

    <app-login-signup class="signupRight" *ngIf="phaseService.selectedSectionIndex === 0"></app-login-signup>
    <app-payment-overview class="signupRight" [ngClass]="{'cpeFlowTrue' : this.phaseService.additionalRentalFeatures.length}" *ngIf="(phaseService.selectedSectionIndex === 1) && (!appDataService.urlParameters.is_freemium)"></app-payment-overview>
    <app-freemium-card-creation-overview class="signupRight" [ngClass]="{'cpeFlowTrue' : this.phaseService.additionalRentalFeatures.length}" *ngIf="(phaseService.selectedSectionIndex === 1) && (appDataService.urlParameters.is_freemium)"></app-freemium-card-creation-overview>
    <app-contracts class="signupRight" *ngIf="phaseService.selectedSectionIndex === 2"></app-contracts>
    <app-payment-options class="signupRight freemiumPaymentOption" *ngIf="phaseService.selectedSectionIndex === 3"></app-payment-options>
    <app-thanku-screen class="signupRight thankyouWrapper" *ngIf="phaseService.selectedSectionIndex === 4"></app-thanku-screen>
</div>

<div class="contentSection freemium" *ngIf="appDataService.urlParameters.is_freemium && !phaseService.freemiumCardWithToken">
  <div class="stepsBx" *ngIf="!phaseService.conditionToCreateAndSyncBuildCardToBNow()" [ngClass]="{stepsWithFreemium :!phaseService.freemiumCardWithToken && !this.phaseService.IsFromFreemium}">
      <div class="stepsmain">
        <ul class="sliderinline2">
          <li [ngClass]="phaseService.selectedSectionIndex === 6 ? 'currentSelected':''" *ngIf="!this.phaseService.IsFromFreemium">
            <img class="partyHatIcon" [ngClass]="{enabled: phaseService.selectedSectionIndex === 6}" src="../../../../../assets/images/party-hat-icon.svg" alt="" /></li>
                      
          <li [ngClass]="phaseService.selectedSectionIndex === 5 ? 'currentSelected':''" *ngIf="this.phaseService.IsFromFreemium">
            <img class="partyHatIcon" [ngClass]="{enabled: phaseService.selectedSectionIndex === 5}" src="../../../../../assets/images/party-hat-icon.svg" alt="" /></li>
          <li [ngClass]="phaseService.selectedSectionIndex === 5 ? 'currentSelected':''" *ngIf="!this.phaseService.IsFromFreemium">
            <em class="icon-tick"></em><span>6</span></li>

          <li [ngClass]="phaseService.selectedSectionIndex === 4 ? 'currentSelected':''" *ngIf="this.phaseService.IsFromFreemium">
            <em class="icon-tick"></em><span>5</span></li>
          <li [ngClass]="phaseService.selectedSectionIndex === 4 ? 'currentSelected':''" *ngIf="!this.phaseService.IsFromFreemium">
            <em class="icon-tick"></em><span>5</span></li>

          <li [ngClass]="phaseService.selectedSectionIndex === 3 ? 'currentSelected':''" *ngIf="this.phaseService.IsFromFreemium">
            <em class="icon-tick"></em><span>4</span></li>
          <li [ngClass]="phaseService.selectedSectionIndex === 3 ? 'currentSelected':''" *ngIf="!this.phaseService.IsFromFreemium">
            <em class="icon-tick"></em><span>4</span></li>

          <li [ngClass]="phaseService.selectedSectionIndex === 2 ? 'currentSelected':'clickable'"
              (click)="handleCardType(2)"><em class="icon-tick"></em><span>3</span></li>

          <li [ngClass]="phaseService.selectedSectionIndex === 1 ? 'currentSelected':'clickable'"
              (click)="handleProceedClick(1)"><em class="icon-tick"></em><span>2</span></li>

          <li [ngClass]="phaseService.selectedSectionIndex === 0 ? 'currentSelected':''"><em class="icon-tick"></em>
              <span>1</span></li>
      </ul>
      </div>
      <h2 *ngIf="phaseService.selectedSectionIndex === 0">{{(dataService.newSignUpFlowScreen === 'signup')? 'Sign up' : 'Sign in'}}</h2>
      <ng-container>
        <h2 *ngIf="phaseService.selectedSectionIndex === 1">Store details</h2>
      </ng-container>
      <ng-container >
        <h2 *ngIf="phaseService.selectedSectionIndex === 2">Supercharge your store</h2>
      </ng-container>
      <div *ngIf="this.phaseService.IsFromFreemium">
        <h2 *ngIf="phaseService.selectedSectionIndex === 3">Account Information</h2>
        <ng-container>
          <h2 *ngIf="phaseService.selectedSectionIndex === 4">Checking it’s really you</h2>
          <span *ngIf="phaseService.selectedSectionIndex === 4">
            <!-- We take {{ getCurrency() + '' + phaseService.getFirstNonZeroInstallmentAmount() }} out of your account to check you’re a real person. Then it’s instantly refunded.  -->
            Before we can onboard you, we need to check if you're a real person. This helps keep our platform secure and working efficiently. To do this, we need to take {{ getCurrency() + '' + phaseService.getFirstNonZeroInstallmentAmount() }} from your account, which we’ll instantly refund.
          </span>
        </ng-container>
        <h2 *ngIf="phaseService.selectedSectionIndex === 5">Thank you!</h2>
      </div>

    <div *ngIf="!this.phaseService.IsFromFreemium">
        <ng-container >
          <h2 *ngIf="phaseService.selectedSectionIndex === 3">Monthly Payment Overview</h2>
        </ng-container>
        <h2 *ngIf="phaseService.selectedSectionIndex === 4">Account Information</h2>
        <ng-container>
          <h2 *ngIf="phaseService.selectedSectionIndex === 5">Payment Options</h2>
        </ng-container>
        <h2 *ngIf="phaseService.selectedSectionIndex === 6">Thank you!</h2>
    </div>
  </div>

  <app-login-signup class="signupRight" *ngIf="phaseService.selectedSectionIndex === 0"></app-login-signup>
  <app-freemium-card-creation-overview class="signupRight" [ngClass]="{'cpeFlowTrue' : this.phaseService.additionalRentalFeatures.length}" *ngIf="(phaseService.selectedSectionIndex === 1)"></app-freemium-card-creation-overview>
  <app-card-status-selection class="signupRight" *ngIf="(phaseService.selectedSectionIndex === 2)"></app-card-status-selection>
  <app-payment-overview class="signupRight" [ngClass]="{'cpeFlowTrue' : this.phaseService.additionalRentalFeatures.length}" *ngIf="(phaseService.selectedSectionIndex === 3) && !this.phaseService.IsFromFreemium"></app-payment-overview>
  <app-contracts class="signupRight" *ngIf="(phaseService.selectedSectionIndex === 3 && this.phaseService.IsFromFreemium) ||(phaseService.selectedSectionIndex === 4 && !this.phaseService.IsFromFreemium)"></app-contracts>
  <app-payment-options class="signupRight freemiumPaymentOption" *ngIf="(phaseService.selectedSectionIndex === 4 && this.phaseService.IsFromFreemium) ||(phaseService.selectedSectionIndex === 5 && !this.phaseService.IsFromFreemium)"></app-payment-options>
  <app-thanku-screen class="signupRight thankyouWrapper" *ngIf="(phaseService.selectedSectionIndex === 5 && this.phaseService.IsFromFreemium) ||(phaseService.selectedSectionIndex === 6 && !this.phaseService.IsFromFreemium)"></app-thanku-screen>
</div>

<div class="contentSection freemium" *ngIf="appDataService.urlParameters.is_freemium && phaseService.freemiumCardWithToken">
  <div class="stepsBx" [ngClass]="{stepsWithFreemiumToken :phaseService.freemiumCardWithToken}" >
      <div class="stepsmain">
        <ul class="sliderinline2">
          <li [ngClass]="phaseService.selectedSectionIndex === 3 ? 'currentSelected':''">
            <em class="icon-tick"></em><span>4</span></li>

          <li [ngClass]="phaseService.selectedSectionIndex === 2 ? 'currentSelected':''">
            <em class="icon-tick"></em><span>3</span></li>

          <li [ngClass]="phaseService.selectedSectionIndex === 1 ? 'currentSelected':'clickable'"
              (click)="handleProceedClick(1)"><em class="icon-tick"></em><span>2</span></li>

          <li [ngClass]="phaseService.selectedSectionIndex === 0 ? 'currentSelected':''"><em class="icon-tick"></em>
              <span>1</span></li>
      </ul>
      </div>
      <h2 *ngIf="phaseService.selectedSectionIndex === 0">{{(dataService.newSignUpFlowScreen === 'signup')? 'Sign up' : 'Sign in'}}</h2>
      <ng-container>
        <h2 *ngIf="phaseService.selectedSectionIndex === 1">Store details</h2>
      </ng-container>
      <h2 *ngIf="phaseService.selectedSectionIndex === 2">Account Information</h2>
      <ng-container>
        <h2 *ngIf="phaseService.selectedSectionIndex === 3">Thank you!</h2>
      </ng-container>
      </div>

  <app-login-signup class="signupRight" *ngIf="phaseService.selectedSectionIndex === 0"></app-login-signup>
  <app-freemium-card-creation-overview class="signupRight" [ngClass]="{'cpeFlowTrue' : this.phaseService.additionalRentalFeatures.length}" *ngIf="(phaseService.selectedSectionIndex === 1)"></app-freemium-card-creation-overview>
  <app-contracts class="signupRight" *ngIf="(phaseService.selectedSectionIndex === 2)"></app-contracts>
  <app-thanku-screen class="signupRight thankyouWrapper" *ngIf="(phaseService.selectedSectionIndex === 3)"></app-thanku-screen>
</div>

