import { Injectable } from '@angular/core';

export const scripts = [
  { name: 'underScore_js', src: 'https://cdnjs.cloudflare.com/ajax/libs/underscore.js/1.9.1/underscore-min.js'},
  { name: 'chart_js', src: 'https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.8.0/Chart.min.js'},
  { name: 'google_add_services', src: '//www.googleadservices.com/pagead/conversion.js' },
  { name: 'chart_loader', src: '//www.gstatic.com/charts/loader.js' },
  { name: 'gtm_UA-80991531-9', src: 'https://www.googletagmanager.com/gtag/js?id=UA-80991531-9' },
  { name: 'gtm_AW-871223914', src: '//www.googletagmanager.com/gtag/js?id=AW-871223914' },
  { name: 'stripe_payment', src: 'https://js.stripe.com/v2/' }
];

declare const document: any;
declare const window: any;
declare const fbq: any;
declare const dataLayer: any;

@Injectable({
  providedIn: 'root'
})
export class DynamicScriptLoaderService {

  private script: any = {};

  constructor() {
    for (const script of scripts) {
      this.script[script.name] = {
        loaded: false,
        src: script.src
      };
    }
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name) {
    return new Promise(((resolve, reject) => {
      if (!this.script[name].loaded) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.script[name].src;
        if (script.readyState) {
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.script[name].loaded = true;
              resolve({script: name, loaded: true, status: 'Loaded'});
            }
          };
        } else {
          script.onload = () => {
            this.script[name].loaded = true;
            resolve({script: name, loaded: true, status: 'Loaded'});
          };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({script: name, loaded: true, status: 'Already Loaded'});
      }
    }));
  }

  googleTagManagerScript1() {
    if (window.location.origin && window.location.origin === 'https://engineerjockey.builder.ai') {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        });
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
          ' https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-5N6CRNW');
    }
  }

  googleTagManagerScript2() {
    function gtag(...tagArgs) {
      dataLayer.push(tagArgs);
    }
    if (window.location.origin) {
      if ((window.location.origin === 'https://beta.engineer.ai') || (window.location.origin === 'https://builder.engineer.ai')) {
        window.dataLayer = window.dataLayer || [];



        gtag('js', new Date());
        gtag('config', 'UA-80991531-9', {'optimize_id': 'GTM-5G2BZDR'});
        gtag('config', 'AW-800126066');
      }
    }
  }

  loadFacebookScript() {
    if (window.location.origin) {
      if (window.location.origin === 'https://builder.engineer.ai') {
        !function (f, b, e, v, n, t, s) {
          if (f.fbq) { return; }
          n = f.fbq = function () {
            n.callMethod ?
              n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          };
          if (!f._fbq) { f._fbq = n; }
          n.push = n;
          n.loaded = !0;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        }.call(window,
          document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '2109049346007886');
        fbq('set', 'agent', 'tmgoogletagmanager', '2109049346007886');
        fbq('track', 'PageView');
      }
    }
  }

  loadHotjarScript() {
    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
      h._hjSettings = {
        hjid: window.location.origin === 'https://builder.engineer.ai' ? 725912 : 725915,
        hjsv: 6
      };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    }).call(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }
  loadEngineerJocketScript() {
    if (window.location.origin && window.location.origin === 'https://engineerjockey.builder.ai') {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        });
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
          ' https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-5N6CRNW');
    }
  }

}
