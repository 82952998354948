import {Component, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import { DataService } from '@shared/services/data.service';
import { HomeTemplateService } from '@shared/services/home-template.service';
import { Subscription } from 'rxjs';
import { Angulartics2Segment } from 'angulartics2/segment';
import { Intercom } from 'ng-intercom';
import {PhaseService} from '@shared/services/phase.service';
import {AppDataService} from '@rootmodule/app-data.service';

@Component({
  selector: 'app-all-template',
  templateUrl: './all-template.component.html',
  styleUrls: ['./all-template.component.scss'],
  // host: {
  //   '(document:click)': 'onClick($event.target)'
  // }
})
export class AllTemplateComponent implements OnInit {
  showTooltip = false;
  isScrolled = true;
  currencyChangeSubscription: Subscription;
  studioTemplateSubscription: Subscription;
  @ViewChild("allTemplateTooltip") allTemplateTooltip;
  @Input() templateType = 'studio_store';

  constructor(public homeTemplateService: HomeTemplateService, private phaseService: PhaseService, public analyticsSegment: Angulartics2Segment,
               private renderer: Renderer2, private appDataService: AppDataService) { }
  ngOnInit(): void {
    if (!this.homeTemplateService.sortingOption) {
      if (this.appDataService.urlParameters.template_search) {
        this.homeTemplateService.maintainSearchOnRefresh.next(true);
      } else {
        this.getHomeTemplates(this.homeTemplateService.currencyId);
      }
    }
    if (this.currencyChangeSubscription) {
      this.currencyChangeSubscription.unsubscribe();
    }
  this.currencyChangeSubscription =  this.homeTemplateService.currencyChange.subscribe((data) => {
      if(data && data.type == 'single') {
        this.isScrolled = true;
        this.homeTemplateService.page = 1;
        this.homeTemplateService.currencyId = data.currencyId
        this.homeTemplateService.all_templates = [];
        this.getHomeTemplates(data.currencyId);
      }
    });
    this.appDataService.multiplierChange.subscribe(() => {
      this.getHomeTemplates(this.homeTemplateService.currencyId.currencyId);
    });
  }

getHomeTemplates(currencyId, isScroll?) {
  if (isScroll) {
    this.homeTemplateService.showTempScrollLoader = true;
  } else {
    this.homeTemplateService.showTemplatesLoader = true;
  }
  this.studioTemplateSubscription = this.homeTemplateService.getAllTemplates(this.homeTemplateService.templateType, this.homeTemplateService.page, currencyId).subscribe(result => {
    let data = result.data;
    let pages = result.total_pages;
    this.homeTemplateService.scrollLoader = false;
    this.homeTemplateService.initialLoader = false;
    if(this.homeTemplateService.page >= pages) {
      this.isScrolled = false;
    } else {
      this.isScrolled = true;
    }
    const customTemplate = this.homeTemplateService.requestedTemplateList;
    this.homeTemplateService.showTemplatesLoader = false;
    this.homeTemplateService.showTempScrollLoader = false;
    if(data.length) {
       if(this.homeTemplateService.page == 1) {
        this.homeTemplateService.all_templates = []
       }
      this.homeTemplateService.all_templates.push(...data);
    }
    if (customTemplate.length && !this.homeTemplateService.all_templates.map(id => id.id).includes(customTemplate[0].id)) {
      this.homeTemplateService.all_templates.unshift(...customTemplate);
    }
    if (!isScroll) {
      setTimeout(() => {
        if (!this.phaseService.showTemplateSearchBox && !this.homeTemplateService.hasNatashaPopupAppeared) {
          if (!this.homeTemplateService.isRefreshedWithSearch) {
            this.homeTemplateService.showNatashaPanel = true;
          }
          this.homeTemplateService.setAnimationVariable();
          this.homeTemplateService.hasNatashaPopupAppeared = true;
          this.homeTemplateService.isTemplatePageLoaded = true;
          this.renderer.addClass(document.getElementById('app-component'), 'natasha-things');
        }
      }, 1000);
    }
  }
, (error) => {
  this.homeTemplateService.scrollLoader = false;
  this.homeTemplateService.initialLoader = false;
});
}

  onScroll() {
    if ((this.isScrolled && ((!this.homeTemplateService.sortingOption) || (this.homeTemplateService.sortingOption && (this.homeTemplateService.activeFilters.category.length > 0 || this.homeTemplateService.activeFilters.price.length > 0))))) {
      this.homeTemplateService.page++;
      this.isScrolled = false;
      this.homeTemplateService.scrollLoader = true;
      this.homeTemplateService.showTempScrollLoader = true;
      this.getHomeTemplates(this.homeTemplateService.currencyId, true);
    } else if (this.homeTemplateService.isSortScrolled && this.homeTemplateService.sortingOption && ((this.homeTemplateService.activeFilters.category.length == 0 && this.homeTemplateService.activeFilters.price.length == 0))) {
      this.homeTemplateService.sortPage++;
      this.homeTemplateService.isSortScrolled = false;
      this.homeTemplateService.scrollLoader = true;
      this.homeTemplateService.sortScroll.next(this.homeTemplateService.sortPage);
    }
  }


ngOnDestroy() {
  if(this.currencyChangeSubscription) {
    this.currencyChangeSubscription.unsubscribe()
  }
  if(this.studioTemplateSubscription) {
    this.studioTemplateSubscription.unsubscribe()
  }
}

  public onClick(targetElement) {

    const clickedInside = this.allTemplateTooltip.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showTooltip = false;
    } else if (targetElement.classList.contains('filterSectionHeading')) {
      this.showTooltip = false;
    }
  }

}
