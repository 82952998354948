import {Component, ElementRef, OnInit, ViewChild, OnDestroy} from '@angular/core';
import { PhaseService } from '@shared/services/phase.service';
import { DataService } from '@shared/services/data.service';
import { ApiRequests } from '@shared/services/api-requests.service';
import { UserModel } from '@models/user-model/user.model';
import { ModelMapperService } from '@shared/services/model-mapper.service';
import { forkJoin } from 'rxjs';
import { environment } from '@env/environment';
import { AppDataService } from '@rootmodule/app-data.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Angulartics2Segment } from 'angulartics2/segment';
import {BillingDetailsService} from '@shared/services/billing-details.service';
import {CommonService} from '@shared/services/common.service';
import {TaxDetailsComponent} from '@shared/shared-components/tax-details/tax-details.component';
import {TaxDetailsService} from '@shared/services/tax-details.service';
import {Constants} from '@shared/utils/constants';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit, OnDestroy {

  isAddressSelected = false;
  isSaveButtonClicked = false;
  projectName = '';
  placeholderProjectName = '';
  @ViewChild('form') form;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  @ViewChild('mobileNumber') public mobileNumber;
  waitingForUpdate = false;
  termsConditionsChecked = false;
  showCountryDropDown = false;
  showStateDropDown = false;
  options: any;
  selectedStates = [];
  userCountryData = [];
  isShowTooltip = false;
  msaOpenedAt: string;
  showCityDropDown = false;
  searchedText = '';
  @ViewChild('searchTextCity') searchTextCity: ElementRef;
  projectNameUpdated = false;
  @ViewChild('gstNumber') public gstNumber;
  isDuplicateProjectName = false;
  isProjectNameWithHyphen = false;
  showTaxDetailsOnStoreBilling = true;

  constructor(public phaseService: PhaseService, public dataService: DataService,
              public apiService: ApiRequests, public modelMapperService: ModelMapperService,
              public appDataService: AppDataService, private analyticsSegment: Angulartics2Segment,
              public billingService: BillingDetailsService, public commonService: CommonService,
              public taxDetailsService: TaxDetailsService) {
    if (dataService.user) {
      phaseService.billingInfo.firstname = this.dataService.user.first_name;
      phaseService.billingInfo.lastname = this.dataService.user.last_name;
      phaseService.billingInfo.email = dataService.user.email;
      phaseService.billingInfo.billing_entity = dataService.user.billing_entity;
      this.billingService.setUserAddress();
      if (this.dataService.buildCardData && this.dataService.buildCardData.studio_store_nda_signed) {
        this.termsConditionsChecked = true;
      }
    }
  }

  getCountryviseState() {
    this.apiService.getUserCountryData().subscribe((data: any) => {
      this.userCountryData = data.data;
      if ((this.dataService.user.currency.id === 4) || (data.data && (data.data.length === 1))) {
        if (this.dataService.user.currency.id === 4) {
          this.phaseService.billingInfo.country = data['data'].find(country => country.code === 'GB')['name'];
        } else {
          this.phaseService.billingInfo.country = data['data'][0]['name'];
        }
      } else if (this.dataService.user.currency.id === 2) {
        this.phaseService.billingInfo.country = data['data'].find(country => country.code === 'US')['name'];
      }
      this.availablestate();
    }, error => {
      // this.commonService.showError();
    });
  }
  setAddressOptions() {
    const countryCode = this.dataService.user ? this.dataService.user.available_country ? this.getCountryCode() : [] : [];
    this.options = {
      componentRestrictions: {
        country: countryCode
      }
    };
  }

  getCountryCode() {
    return this.dataService.user.available_country.map((country) => {
      return country.code;
    })
  }
  ngOnInit(): void {
    this.billingService.getCountryWiseState();
    this.setAddressOptions()
    this.dataService.IsGstValid = true;
    this.placeholderProjectName = this.dataService.buildCardData?.project_name;
    if (this.appDataService.urlParameters.is_freemium || this.phaseService.SSCardRenamed) {
      this.projectName = this.dataService.buildCardData?.project_name;
    }
    this.phaseService.billingInfo.email = this.dataService.user.email;

    this.phaseService.billingInfo.contact = this.dataService.user.phone_number ? this.dataService.user.phone_number.replace('-', '') : '';

    this.phaseService.billingInfo.firstname = this.dataService.user.first_name;
    // this.phaseService.billingInfo.lastname = this.billingInfo.lastname;

    this.phaseService.checkIfAddressSubmitted();
    this.checkIfNDASigned();
    if (this.appDataService.urlParameters.is_freemium) {
      const pageSource = this.phaseService.IsFromFreemium ? 'Free' : 'Upgrade';
    } else {
      this.phaseService.trackEvent(this.analyticsSegment,  't&cs_confirmed_store');
    }
    document.querySelectorAll('.pac-container').forEach(function (a) {
      a.remove();
    });

  }

  checkIfNDASigned() {
    if (this.dataService.buildCardData.nda_signed) {
      this.termsConditionsChecked = true;
    }
  }

  proceedForPayment() {
    let paymentData: any = {
      'apptoken': environment.PAYMENT_APP_TOKEN,
      'payment_data': {
        // 'amount': parseInt(this.phaseService.paymentData.pending_installment.installment_amount)+ parseInt(this.phaseService.paymentData.pending_installment.tax_amount),
        'billing_address_attributes': {
          'billing_address': this.phaseService.billingInfo.address,
          'billing_city': this.phaseService.billingInfo.city,
          'billing_name': this.phaseService.billingInfo.firstname + ' ' + this.phaseService.billingInfo.lastname,
          'billing_state': this.phaseService.billingInfo.state,
          'billing_tel': this.phaseService.billingInfo.contact,
          'billing_zip': this.phaseService.billingInfo.pincode
        },
        'billing_entity': this.dataService.user.billing_entity,
        'capture': 'true',
        'card_notify_url': environment.API_URL + 'payment_billing/builder_card_owners/card_status',
        'currency': (this.dataService.buildCardData && this.dataService.buildCardData.id) ? this.dataService.buildCardData.currency.code : this.dataService.user.currency.code,
        'description': 'Payment for Studio Store',
        'email': this.dataService.user.email,
        'frontend_redirect_url': '',
        'payment_gateway': this.dataService.user && this.dataService.user.currency.code === 'INR' ? 'ccavenue' : 'stripe',
        'payment_mode': 'complete',
        'payment_notify_url': environment.API_URL + 'payment_billing/installments/status',
        'redirect_billing_details': `${window.location.origin}/buildcard-payment/${this.dataService.buildCardData.uniq_code}/billingdetails`,
        'redirect_dashboard_url': window.location.origin + '/dashboard',
        'redirect_payment_plan': `${window.location.origin}/buildcard-payment/${this.dataService.buildCardData.uniq_code}/paymentplan`,
        'redirect_url': '',
        'source': 'Engineer.ai',
        'installment_type': this.phaseService.paymentData.pending_installment.type,
        'build_card_id': this.dataService.buildCardData.id,
        'build_card_name': this.dataService.buildCardData.project_name,
        'frequency': this.dataService.buildCardData.frequency,
        'installment_amount': this.phaseService.getFirstNonZeroInstallmentAmount(),
        'installment_count': this.phaseService.getInstallmentCountExceptDeposit(),
        'source_model_guid': this.phaseService.paymentData.pending_installment.id,
        'source_model_name': 'Installment',
        'sub_total_amount': this.getSecurityDepositWithoutTax()
      }
    };
    this.phaseService.isShowPaymentOptionsLoader = true;
    this.apiService.sendPaymentDetailsAndPoll(paymentData).subscribe(data => {
      this.phaseService.startPollingForPayment();
      this.phaseService.handleNextPrevForRentalFlow();
      this.waitingForUpdate = false;
    }, error => {
      this.phaseService.proceedClickedFromRental = false;
    });

  }

  public getBuildCardData() {
    return this.dataService.buildCardData;
  }


  getSecurityDepositWithoutTax() {
    if (this.phaseService.promotionValid && !this.phaseService.promotionError && this.phaseService.isDiscountApplied() && this.getBuildCardData().promotion && this.getBuildCardData().promotion.type === 'GlobalPromotion' && this.getBuildCardData().promotion.deposit) {
      return this.getBuildCardData().promotion.deposit;
    } else if (this.appDataService.urlParameters.isCustomPrototype) {
      return this.getInstallmentWithoutTax(this.phaseService.paymentData.custom_prototype);
    } else {
      return this.getInstallmentWithoutTax(this.phaseService.paymentData.pending_installment);
    }
  }

  public getInstallmentWithoutTax(installment: any) {
    if (installment) {
      return Number(installment.installment_amount);
    }
  }

  getBillingInfo() {
    return this.billingService.getBillingInfo();
  }

  checkIfFormValid() {
    for (const key of Object.keys(this.phaseService.billingInfo)) {
      if ((this.phaseService.billingInfo[key] === '') && (((key !== 'name') && (key !== 'address_line_2') && (key !== 'billing_entity') && (key !== 'companyName') && (key !== 'apartment') && (key !== 'address') && (key !== 'gstNumber')))) {
        return false;

      }
    }
    return true;
  }

  submitAddress() {
    this.isSaveButtonClicked = true;
    this.hideDropDown();
    this.billingService.checkIfValidDetails();
    this.isSaveButtonClicked = false;
    this.isAddressSelected = false;
    this.phaseService.addressSubmitted = true;
  }

  updateBuildAsKeyAndProceed() {
    this.isSaveButtonClicked = true;
    const user = {
      billed_as: this.phaseService.billingInfo.billed_as,
      companyName: this.phaseService.billingInfo.companyName
    };
    this.apiService.updateBuildAsKey(user).subscribe(() => {
      this.proceedForPaidUsers();
    });
  }

  updateNdaIfAddressDisabledAndProceed() {
    if (this.dataService.user.address_disabled && !this.dataService.user.address.billed_as) {
      this.updateBuildAsKeyAndProceed();
    } else {
      this.proceedForPaidUsers();
    }
  }

  proceedForPaidUsers() {
    const billingInfo = this.phaseService.billingInfo,
      userAddress = this.dataService.user.address;
    this.waitingForUpdate = true;
    this.phaseService.proceedClickedFromRental = true;
    billingInfo.lastname = userAddress && userAddress.last_name ? userAddress.last_name : billingInfo.lastname;
    this.phaseService.billingInfo.name = this.phaseService.billingInfo.firstname + (this.phaseService.billingInfo.lastname ? ' ' + this.phaseService.billingInfo.lastname : '');
    this.phaseService.billingInfo.address = this.phaseService.billingInfo.apartment + (this.phaseService.billingInfo.street ? ' ' + this.phaseService.billingInfo.street : '');
    if (billingInfo.state) {
      billingInfo.state = billingInfo.state['code'] ? billingInfo.state['name'] : billingInfo.state;
    }
    const reqParams = { nda_signed: true, msa_signed_at: this.msaOpenedAt };
    this.apiService.setResetContractsSigned(this.dataService.buildCardData.id, reqParams).subscribe(data => {
      this.isAddressSelected = false;
      this.phaseService.addressSubmitted = true;
      this.proceedForPayment();
    }, error => {
      this.waitingForUpdate = false;
      this.dataService.IsGstValid = false;
      this.phaseService.proceedClickedFromRental = false;
    });
  }

  updateAddressIfAddressNotDisaled() {
    const billingInfo = this.phaseService.billingInfo,
      userAddress = this.dataService.user.address;
    this.isSaveButtonClicked = true;
    if ((this.getBillingInfo().gstNumber && this.gstNumber.invalid) || !this.billingService.checkIfValidDetails()) {
      return false;
    }
    this.waitingForUpdate = true;
    this.phaseService.proceedClickedFromRental = true;
    billingInfo.lastname = userAddress && userAddress.last_name ? userAddress.last_name : billingInfo.lastname;
    this.phaseService.billingInfo.name = this.phaseService.billingInfo.firstname + (this.phaseService.billingInfo.lastname ? ' ' + this.phaseService.billingInfo.lastname : '');
    this.phaseService.billingInfo.address = this.phaseService.billingInfo.apartment + (this.phaseService.billingInfo.street ? ' ' + this.phaseService.billingInfo.street : '');
    if (billingInfo.state) {
      billingInfo.state = billingInfo.state['code'] ? billingInfo.state['name'] : billingInfo.state;
    }
    if (this.phaseService.countryNameCode) {
      this.phaseService.billingInfo.contact = this.phaseService.billingInfo.contact ? this.phaseService.billingInfo.contact.replace(this.phaseService.countryNameCode, this.phaseService.countryNameCode + '-') : this.phaseService.billingInfo.contact;
    }
    const reqParams = { nda_signed: true, msa_signed_at: this.msaOpenedAt };
    const ndaSubscription = this.apiService.setResetContractsSigned(this.dataService.buildCardData.id, reqParams);
    let taxDetails;
    if (this.taxDetailsService.isShowTaxDetailsOnBillingPage && !this.dataService.user.address?.tax_details_disabled) {
      taxDetails = this.taxDetailsService.createPayload();
    }
    const addressSubscription = this.apiService.updateUserAddress(this.phaseService.billingInfo, false, taxDetails);
    forkJoin([ndaSubscription, addressSubscription]).subscribe((res) => {
      let userObj: UserModel = new UserModel();
      let userData = res[1];
      userData.user = this.modelMapperService.getMappedModel(userObj, userData.user);
      this.dataService.user = userData.user;
      this.dataService.storeUserObject();
      this.isAddressSelected = false;
      this.phaseService.addressSubmitted = true;
      this.taxDetailsService.isShowTaxDetailsOnBillingPage = false;
      this.proceedForPayment();
    }, error => {
      this.waitingForUpdate = false;
      this.dataService.IsGstValid = false;
      this.phaseService.proceedClickedFromRental = false;
    });
  }

  parseText(text) {
    let html = text;
    let div = document.createElement("div");
    div.innerHTML = html;
    return div.innerText;
  }

  updateProjectName() {
    const updatedSelectedHash = {'project_name': this.parseText(this.projectName)};
    this.apiService.createUpdateBuildCard(updatedSelectedHash, this.phaseService.inProgressBuildCardId, '', 'rename_build_card').subscribe((data: any) => {
      this.projectNameUpdated = true;
      if (!this.waitingForUpdate) {
        if (this.dataService.user.address_disabled) {
          this.updateNdaIfAddressDisabledAndProceed();
        } else {
          this.updateAddressIfAddressNotDisaled();
        }
      }
    }, (error) => {
      this.isDuplicateProjectName = true;
    });
  }

  updateAddressSignNDAandProceedForPayment() {
    const pageSource = this.phaseService.IsFromFreemium ? 'Free' : 'Upgrade';
    if (this.phaseService.IsFromFreemium) {
      this.phaseService.trackEventForObject(this.analyticsSegment,  'freemium_tcs_confirmed', {
        user_id: this.dataService.user ?.id,
        freemium_source: pageSource
      });
    } else {
      this.phaseService.trackEventForObject(this.analyticsSegment,  'tcs_confirmed_store', {
        user_id: this.dataService.user ?.id,
        build_card_name: this.projectName,
        company_name: this.phaseService.billingInfo.companyName
      });
    }
    if (this.projectName.charAt(0).includes('-') || this.projectName.charAt(this.projectName.length - 1).includes('-')) {
      this.isProjectNameWithHyphen = true;
      return false;
    } else {
      this.isProjectNameWithHyphen = false;
    }
    this.phaseService.SSCardRenamed = true;
    this.hideDropDown();
    if (!this.appDataService.urlParameters.is_freemium && !this.projectNameUpdated) {
      this.updateProjectName();
    } else {
      if (!this.waitingForUpdate) {
        if (this.dataService.user.address_disabled) {
          this.updateNdaIfAddressDisabledAndProceed();
        } else {
          this.updateAddressIfAddressNotDisaled();
        }
      }
    }
  }

  checkValidity() {
    return this.termsConditionsChecked && this.projectName;
  }

  closeModal() {
    this.isAddressSelected = false;
  }

  onFlagChange() {
    if (this.mobileNumber && this.mobileNumber.valid && this.mobileNumber.valueAccessor) {
      this.phaseService.countryNameCode = this.mobileNumber.valueAccessor.selectedCountry.countryCode;
      this.phaseService.countryNameCode = this.mobileNumber.valueAccessor.selectedCountry.dialCode;
    }
  }

  showStatesList(name?) {
    if (name === 'states') {
      this.availablestate();
      this.showStateDropDown = true;
      this.showCountryDropDown = false;
    } else if (name === 'zipCode') {
      this.showCountryDropDown = false;
    } else {
      this.showCountryDropDown = true;
      this.showStateDropDown = false;
    }
  }

  public selectState(state) {
    this.phaseService.billingInfo.state = state.name;
    this.showStateDropDown = false;
    this.billingService.setCity();
  }

  selectCountry(country) {
    this.phaseService.billingInfo.country = country.name;
    this.showCountryDropDown = false;
    this.billingService.availablestate();
    this.billingService.setCity();
  }

  availablestate() {

    const index = this.userCountryData.findIndex((country) => {
      return this.phaseService.billingInfo.country.toLowerCase() === country.name.toLowerCase();
    })
    if (index !== -1) {
      this.selectedStates = this.userCountryData[index].states.states;
    }
  }

  showCountriesList(event) {
    event.stopPropagation();
    this.showCountryDropDown = true;
    this.showStateDropDown = false;
  }

  hideDropDown() {
    this.showCountryDropDown = false;
    this.showStateDropDown = false;
  }

  openPoliciesLink(event) {
    event.stopPropagation();
    const policyType = event.target.dataset.pageName;
    if (policyType === 'TnC') {
      this.commonService.navigateToTnC(true);
    } else {
      const URL = Constants.POLICIES_URLS[policyType];
      window.open(URL, '_blank');
    }
  }

  openMSADocLink() {
    window.open('https://bstudio-assets.azureedge.net/assets-builder/MSA.pdf', '_blank', "noopener");
  }

  setUserContact(event) {
    this.getBillingInfo().contact = event;
  }

  termsAndConditionsClicked(e?) {
    const date = new Date();
    this.msaOpenedAt = date.toUTCString();
    this.termsConditionsChecked = !this.termsConditionsChecked;
  }

  ShowDropdown() {
    this.showStateDropDown = true;
  }

  toggleTooltip() {
    this.isShowTooltip = !this.isShowTooltip;
  }

  acceptAlphaNumeric(event) {
    this.isDuplicateProjectName = false;
    this.isProjectNameWithHyphen = false;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (((charCode > 64) && (charCode < 91)) || ((charCode > 96) && (charCode < 123)) ||
      ((charCode > 47) && (charCode < 58)) || (charCode === 45)) {
      return true;
    } else {
      return false;
    }
  }

  changeAddressType(addressType) {
    this.billingService.changeAddressType(addressType);
  }

  renderCitiesInDropdown() {
    return this.billingService.renderCitiesInDropdown();
  }

  handleBackSpace(event, isState?) {
    if (event.keyCode === 8) {
      this.showStatesList(isState);
    }
  }

  selectCity(city) {
    this.phaseService.billingInfo.city = city;
    this.showCityDropDown = false;
  }

  showCityDropdown() {
    this.searchedText = '';
    this.showCityDropDown = !this.showCityDropDown;
    this.searchTextCity.nativeElement.focus();
    this.showCountryDropDown = false;
    this.showStateDropDown = false;
  }

  hideErrors() {
    this.isDuplicateProjectName = false;
    this.isProjectNameWithHyphen = false;
  }

  checkIfTaxDetailsValid() {
    if (!this.taxDetailsService.validateFormFields()) {
      return false;
    }
    return true;
  }

  public ngOnDestroy() {
    this.taxDetailsService.isShowTaxDetailsOnBillingPage = false;
  }

}
