import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import {Router} from '@angular/router';
import {Angulartics2Segment} from 'angulartics2/segment';
import { Intercom } from 'ng-intercom';


@Component({
  selector: 'app-no-cards',
  templateUrl: './no-cards.component.html',
  styleUrls: ['./no-cards.component.css']
})
export class NoCardsComponent {
  @Output() pshowCards: EventEmitter<any>  = new EventEmitter();

  @Input('showCompleted') showCompleted;
  @Input('showInProgress') showInProgress;

  constructor(public phaseService: PhaseService, public dataService: DataService, public router: Router,
    public analyticsSegment: Angulartics2Segment, ) { }



  public createNewCard() {
    this.phaseService.reset();
    this.router.navigate([this.dataService.firstPage]);
  }
  public getCardCount(type): any  {
    return this.dataService.getCardCount(type);
  }

  public showCards(index) {
    this.pshowCards.emit(index);
  }

  public getLoaderStatus() {
    return this.dataService.showLoader;
  }

}
