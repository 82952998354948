import { Component, OnInit } from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {DataService} from '@shared/services/data.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import { AppDataService } from '@rootmodule/app-data.service';
import { ThisReceiver } from '@angular/compiler/src/expression_parser/ast';

@Component({
  selector: 'app-upgrade-payment-thankyou',
  templateUrl: './upgrade-payment-thankyou.component.html',
  styleUrls: ['./upgrade-payment-thankyou.component.scss']
})
export class upgradePaymentThankyouComponent implements OnInit {
  public title;
  public content;
  public image;
  constructor(public apiRequest: ApiRequests,private appDataService: AppDataService,public router: Router,public phaseService: PhaseService,public dataService: DataService) { }

  ngOnInit(): void {
    if (this.appDataService.urlParameters.upgrade_to_starter) {
      this.title = "Starter plan";
      this.content="Take up to 100 orders a month, on your own responsive website store.";
      this.image = "../../../../../assets/images/house-1.svg"
    } else if (this.appDataService.urlParameters.upgrade_to_store) {
      this.title="Own the store";
      this.content="Make unlimited sales – plus you’ll get iOS and Android apps included.";
      this.image = "../../../../../assets/images/diamondSquare.svg"
    }
  }

  public getTransactionID() {
    return this.phaseService.transactionId;
  }

  getStartedForFreemium() {
    window.open('https://intercom.help/engineerai/en/articles/5793150-1-first-steps', '_blank' , "noopener");
  }

   goToDashboard() {
    this.router.navigate(['dashboard']);
  }

}
