import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {



  public addHttps(img?) {
    let modifyImg = "";
    if (img) {
      if (img.lastIndexOf("http") === 0) {
        if (img.includes("https")) {
          return img;
        }
        return img.replace("http", "https");
      } else if (img.lastIndexOf("http") > 0) {
        modifyImg = img.substring(img.lastIndexOf("http"));
        if (modifyImg.includes("https")) {
          return modifyImg;
        }
        return modifyImg.replace("http", "https");
      }
      return img;
    }
  }

  public generateRandomString() {
    let text = "";
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 4; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return new Date().getTime() + text;
  }
}
