<div class="otherProductSection">
  <div class="sectionContent">
    <h3>{{sibling_promotions.title}} <img src="../../../../../assets/images/smile4.png" width="38" height="38" alt=""/></h3>
    <p>{{sibling_promotions.description}}</p>
  </div>

  <div class="bLoader" *ngIf="showLoader">
    <div class="loaderBox"><img src="../../../../../assets/images/bloader.gif" alt=""/></div>
    <div class="loaderBox"><img src="../../../../../assets/images/bloader.gif" alt=""/></div>
  </div>
  <div class="otherProductRow" *ngIf="!showLoader">
    <drag-scroll
      (reachesLeftBound)="leftBoundStat($event)"
      (reachesRightBound)="rightBoundStat($event)"
      #nav>
      <div drag-scroll-item [style.background-color]="pSection.background_color ? pSection.background_color: ''"
           class="otherProductBox" *ngFor="let pSection of primarySections">
        <h3>{{pSection.title}}</h3>
        <p>{{pSection.description}}</p>
        <button (click)="redirectToLink(pSection)" type="button" class="productButton">{{pSection.button_tag}}</button>
        <div class="smallIcon"><img src="../../../../../assets/images/B_Small.png" width="" height="" alt=""/></div>
      </div>
    </drag-scroll>
    <button class="dragleftArrow" [disabled]="leftNavDisabled" type="button" (click)="moveLeft()"><em
      class="icon-nexticon"></em></button>
    <button class="dragrightArrow" [disabled]="rightNavDisabled" type="button" (click)="moveRight()"><em
      class="icon-nexticon"></em></button>
  </div>
</div>
