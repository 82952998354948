<div class="trendingSection">
  <div class="sectionContent">
    <h3>{{trending.title}} <img src="../../../../../assets/images/smile2.png" width="26" height="38" alt=""/></h3>
    <p>{{trending.description}}</p>
  </div>


  <div class="bLoader" *ngIf="showLoader">
    <div class="loaderBox"><img src="../../../../../assets/images/bloader.gif" alt=""/></div>
    <div class="loaderBox"><img src="../../../../../assets/images/bloader.gif" alt=""/></div>
    <div class="loaderBox"><img src="../../../../../assets/images/bloader.gif" alt=""/></div>
    <div class="loaderBox"><img src="../../../../../assets/images/bloader.gif" alt=""/></div>
  </div>
  <div class="trendingItemrow" *ngIf="primarySections.length > 0">
    <drag-scroll (reachesLeftBound)="leftBoundStat($event)"
                 (reachesRightBound)="rightBoundStat($event)" #nav>
      <div class="trendingItembox" drag-scroll-item *ngFor="let pSection of primarySections">
        <div class="trendingItemImg"><img src="{{utilityService.addHttps(pSection.icon)}}" width="54" height="54" alt=""/></div>
        <div class="trendingItemtext">
          <h3>{{pSection.title}}</h3>
          <p>{{pSection.description}}</p>
        </div>
        <button type="button" (click)="clickTrendingApp(pSection)" class="buildAppButton">Build this App</button>
        <div class="trendingItemFooter">
          <h4>{{pSection.builder_number}}<span>Users BUilding</span></h4>
          <h4>{{pSection.feature_count}} <span>Features</span></h4>
        </div>
      </div>
    </drag-scroll>
    <button class="dragleftArrow" [disabled]="leftNavDisabled" type="button" (click)="moveLeft()"><em
      class="icon-nexticon"></em></button>
    <button class="dragrightArrow" [disabled]="rightNavDisabled" type="button" (click)="moveRight()"><em
      class="icon-nexticon"></em></button>
  </div>
</div>
