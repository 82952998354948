<div class="dashboardOverview">
  <h2><strong>Dashboard</strong> Overview</h2>
  <div class="cardSearch">
    <form>
      <em class="icon-magnifying"></em>
      <input type="text" placeholder="Search" [formControl]="searchQuery">
    </form>
  </div>

  <div class="tableData">
    <table summary="">
      <thead>
      <tr>
        <th>Buildcard<sup class="tm">®</sup> ID</th>
        <th>Buildcard<sup class="tm">®</sup> Name</th>
        <th>User Name</th>
        <th>User Email</th>
        <th>Price</th>
        <th>Weeks</th>
        <th>Spec Call</th>
        <th>Actions</th>
      </tr>
      </thead>
    </table>

    <div class="scrollTable" *ngIf="buildCardService.buildCards?.length > 0">
      <perfect-scrollbar (psYReachEnd)="onScroll()">
        <table summary="">
          <tr *ngFor="let card of buildCardService.buildCards">
            <td>{{ card.id.toUpperCase() }}</td>
            <td>{{ card.project_name }}</td>
            <td>{{ card.user_name || '--' }}</td>
            <td>{{ card.user_email || '--' }}</td>
            <td
              *ngIf="getDiscountedprice(card) && card.status !== 'card_initialized'">{{ card.currency.symbol }} {{ getDiscountedprice(card) }}</td>
            <td *ngIf="!getDiscountedprice(card) || (card.status === 'card_initialized' && !card.kick_off_date)">--</td>
            <td
              *ngIf="getDiscountedprice(card) && card.status !== 'card_initialized'">{{ card.weeks && card.status !== 'card_initialized' ? card.weeks : '--' }} {{ card.weeks === 1 ? 'Week' : 'Weeks' }}</td>
            <td *ngIf="!getDiscountedprice(card) || (card.status === 'card_initialized' && !card.kick_off_date)">--</td>
            <td>
              <span class="yes" *ngIf="card.specing">Yes</span>
              <span class="no" *ngIf="!card.specing">No</span>
            </td>
            <td><em class="icon-edit"></em> <em class="icon-view" (click)="getSingleBuildCard(card)"></em></td>
          </tr>
        </table>
      </perfect-scrollbar>
    </div>
  </div>

  <!--<div class="tablePagination">-->
  <!--<ul>-->
  <!--<li (click)="applyPagination('prev')"><em class="icon-prev"></em> PREV</li>-->
  <!--<li *ngFor="let pageNo of pageList" (click)="applyPagination(null, pageNo)" [ngClass]="{'active': pageNo === page}">{{ pageNo }}</li>-->
  <!--<li (click)="applyPagination('next')">NEXT <em class="icon-next"></em></li>-->
  <!--</ul>-->
  <!--</div>-->

  <div class="noResult" *ngIf="buildCardService.buildCards?.length === 0 && !dataService.showLoader">
    <img src="../../../../../../assets/images/searchNot.png" alt="" />
    <h3>Sorry, no result found</h3>
    <p>What you searched was unfortunately<br> not found or doesn’t exist.</p>
  </div>


</div>
