import {Component, Input, ViewChild} from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {Router} from '@angular/router';
import {DragScrollComponent} from 'ngx-drag-scroll';
import {DataService} from '@shared/services/data.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {UtilityService} from '@shared/services/utility.service';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'app-trending-apps',
  styleUrls: ['trending-apps.component.scss'],
  templateUrl: 'trending-apps.component.html'
})

export class TrendingAppsComponent {

  @Input('trending') trending;
  @Input('trendingSection') trendingSection;
  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;

  constructor( public analyticsSegment: Angulartics2Segment, public phaseService: PhaseService, public router: Router, public dataService: DataService, public utilityService: UtilityService) {
  }

  primarySections = [];
  showLoader = true;
  leftNavDisabled = false;
  rightNavDisabled = false;

  ngOnInit() {
    if (this.trending.primary_section) {
      this.primarySections = this.trending.primary_section;
      this.sortByPosition();
      if (this.primarySections.length > 0) {
        this.showLoader = false;
      }
    }
  }

  public sortByPosition() {
    this.primarySections.sort(function (a, b) {
      const keyA = new Date(a.precedence),
        keyB = new Date(b.precedence);
      // Compare the 2 dates
      if (keyA < keyB) { return -1; }
      if (keyA > keyB) { return 1; }
      return 0;
    });
  }

  ngOnChanges() {
    if (this.trendingSection) {
      this.primarySections = this.trendingSection.section_details;
      this.sortByPosition();
      if (this.primarySections.length > 0) {
        this.showLoader = false;
      }

    }
  }

  clickTrendingApp(section) {
    this.phaseService.toggleSelectedAppsById(section);
    this.phaseService.toggleSelectedAppid(section.id);
    this.phaseService.onNextClicked();
    this.router.navigate(['feature']);

  }

  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }


}
