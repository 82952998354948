<div class="commonPopUp commonPopUp-upZindex active" (click)="hideDropDown()">
  <div class="popOverlay" (click)="closeModal()"></div>
  <div class="popHolder contractformPopup">
    <div class="closePopUp" (click)="closeModal()"><span class="icon-cancel"></span></div>
    <ul>
      <li><label>First Name*</label> <input type="text" [ngClass]="{'redBorder' : (isSaveButtonClicked && !getBillingInfo().firstname)}"
          [(ngModel)]="getBillingInfo().firstname" (click)="hideDropDown()">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().firstname">Please enter valid first name</div>
      </li>

      <li><label>Last Name*</label> <input type="text" [ngClass]="{'redBorder' : (isSaveButtonClicked && !getBillingInfo().lastname)}"
          [(ngModel)]="getBillingInfo().lastname" (click)="hideDropDown()">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().lastname">Please enter valid last name</div>
      </li>

      <li><label>Phone Number*</label>
        <app-phone-number [contactNumber]="getBillingInfo().contact" [isSaveButtonClicked]="isSaveButtonClicked"
                          (setContactInUserInfo)="setUserContact($event)" (closeCountryStateDropDown)="hideDropDown()">
        </app-phone-number>
      </li>

      <li><label>Company Name</label> <input type="text" minlength="1" maxlength="50" [(ngModel)]="getBillingInfo().companyName" (click)="hideDropDown()">
      </li>

      <li><label>Country*</label> <input type="text" [ngClass]="{'redBorder' : (isSaveButtonClicked && !getBillingInfo().country && !showCountryDropDown)}"
          [(ngModel)]="getBillingInfo().country" (focus)="showCountriesList()" (click)="$event.stopPropagation()">
        <ul class="countryDropdown" *ngIf="showCountryDropDown">
          <li *ngFor="let country of dataService.userCurrencyAndCountries?.available_country | searchCountries: getBillingInfo().country"
            (click)="selectCountry(country)">{{ country.name }}</li>
        </ul>
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().country && !showCountryDropDown">Please enter valid country</div>
      </li>

      <li><label>Zip/Postal Code*</label> <input type="text" [ngClass]="{'redBorder' : (isSaveButtonClicked && (!getBillingInfo().pincode))}"
        [(ngModel)]="getBillingInfo().pincode" (click)="hideDropDown()">
      <div class="errorMsg" *ngIf="isSaveButtonClicked && (!getBillingInfo().pincode)">Please enter valid Zip/Postal Code</div>
    </li>

      <li *ngIf="ifNotUkOrCyprus()"><label>State/Territory/Region*</label>
        <input type="text"  [ngClass]="{'redBorder' : (isSaveButtonClicked && !getBillingInfo().state)}" [(ngModel)]="getBillingInfo().state"
               (focus)="showStatesList('states')" (keyup)="ShowDropdown()" (click)="$event.stopPropagation()"  [disabled]="!getBillingInfo().country">
        <ul class="countryDropdown" *ngIf="showStateDropDown">
          <li *ngFor="let state of selectedStates | searchCountries: getBillingInfo().state" (click)="selectState(state)">{{ state.name }}</li>
        </ul>
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().state">Please enter valid state</div>

      </li>

      <li><label>City*</label> <input type="text" [ngClass]="{'redBorder' : ((isSaveButtonClicked && !getBillingInfo().city) || (isSaveButtonClicked && getBillingInfo().city && getBillingInfo().city.length < 3))}"
        [(ngModel)]="getBillingInfo().city" (click)="hideDropDown()">
      <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().city">Please enter valid city</div>
      <div class="errorMsg" *ngIf="isSaveButtonClicked && getBillingInfo().city && getBillingInfo().city.length < 3">City should be minimum of 3 chars long</div>
    </li>

      <li><label>Address*</label> <input type="text" [ngClass]="{'redBorder' : ((isSaveButtonClicked && !getBillingInfo().street) || (isSaveButtonClicked && getBillingInfo().street && getBillingInfo().street.length < 3))}"
          [(ngModel)]="getBillingInfo().street" (click)="hideDropDown()">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().street">Please enter valid address</div>
        <div class="errorMsg" *ngIf="isSaveButtonClicked && getBillingInfo().street && getBillingInfo().street.length < 3">Address should be minimum of 3 chars long</div>
      </li>
      <li><label>Apartment/Unit Number (optional)</label> <input type="text" [(ngModel)]="getBillingInfo().apartment" (click)="hideDropDown()">
      </li>




      <li *ngIf="showGSTIN">
        <label>GST number</label>
        <input type="text" name="gstNumber" [(ngModel)]="getBillingInfo().gstNumber" #gstNumber="ngModel"
          maxlength="100" [ngClass]="{'errorInput': isSaveButtonClicked && gstNumber.invalid}"
          placeholder="eg. 06ABCDE1234F2Z5" pattern="^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$">
        <span class="errorMsg" *ngIf="gstNumber.touched && gstNumber.invalid">Please enter valid GST number</span>
      </li>
    </ul>

    <button class="buttonStyle" (click)="submitAddress()">Done</button>
  </div>
</div>
