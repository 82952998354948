// tslint:disable:max-line-length
import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { DataService } from '@shared/services/data.service';
import { PhaseService } from '@shared/services/phase.service';
import { Angulartics2Segment } from 'angulartics2/segment';
import { Constants } from '@shared/utils/constants';
import { ApiRequests } from '@shared/services/api-requests.service';
import { finalize } from 'rxjs/operators';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'app-popups',
  templateUrl: './popups.component.html',
  styleUrls: ['./popups.component.scss']
})
export class PopupsComponent  {

  @Output() pdetectChanges = new EventEmitter();
  @Output() pshowTooltip = new EventEmitter();
  @Output() ppreventClick = new EventEmitter();

  @Input('renameInProgress') renameInProgress;
  @Input('cardInRenameProgress') cardInRenameProgress;

  @Input('showCompleted') showCompleted;
  @Input('showInProgress') showInProgress;
  @Input('showPaid') showPaid;
  @Input('showSpecCalls') showSpecCalls;
  @Input('showInvited') showInvited;
  @Input('showArchived') showArchived;
  @Input('selectedProfileList') selectedProfileList;
  @Input('preventClick') preventClick;

  public newName = null;
  public updateLoader = false;
  public showEditProfileLink = false;
  public changesSaved = false;

  // public updatedCount;

  constructor(public dataService: DataService, public apiRequest: ApiRequests, public phaseService: PhaseService, public renderer : Renderer2, public analyticsSegment: Angulartics2Segment, ) { }



  public getUser() {
    return this.dataService.user;
  }

  public closeRenamePopup() {
    this.newName = null;
    this.dataService.renameInProgress = false;
    this.cardInRenameProgress = null;
  }

  public renameCard() {
    if (!this.preventClick) {
      this.preventClick = true;
      this.ppreventClick.emit(true);
      this.phaseService.inProgressBuildCardId = this.cardInRenameProgress.id;
      this.apiRequest.renameBuildCard(this.newName, this.phaseService.inProgressBuildCardId).subscribe((data: any) => {
        this.dataService.showHideLoader(true);
        this.apiRequest.fetchBuildCards(null, null, 'card_initialized').subscribe((subdata) => {
          this.pdetectChanges.emit();
          this.dataService.showHideLoader(false);
        });
        this.newName = null;
        this.preventClick = false;
        this.ppreventClick.emit(false);
        this.dataService.renameInProgress = false;
        this.cardInRenameProgress = null;
        this.renderer.removeClass(document.body, 'modal-open');
      }, (error) => {
        if (error.status === 403 || error.status === 401) {
          this.dataService.user = null;
          this.phaseService.clearLocalStorage();
          this.phaseService.showSignin = true;
          this.phaseService.openCommonAuth = true;
        }
        this.preventClick = false;
        this.ppreventClick.emit(false);
      });
    }
  }

  public deleteCard() {
    let ArchivedCardIndex = '';
    if (!this.preventClick) {
      this.preventClick = true;
      this.ppreventClick.emit(true);
      ArchivedCardIndex = this.dataService.dashboardCards.invited_build_card.indexOf(this.dataService.cardToDelete)
      this.apiRequest.deleteCard(this.dataService.cardToDelete.id).subscribe((data: any) => {
        const cardStatus = (this.dataService.cardToDelete && this.dataService.cardToDelete.status) ? this.dataService.cardToDelete.status : null;
        this.dataService.cardToDelete = null;
        this.dataService.showHideLoader(true);
        this.apiRequest.fetchBuildCards(null, null, cardStatus).subscribe((subdata) => {
          // this.updatedCount = this.dataService.allBuildCards.updated_build_card_count;
          this.dataService.dashboardCards.invited_build_card.splice(ArchivedCardIndex,1)
          this.pdetectChanges.emit();
          this.dataService.showHideLoader(false);
        });
        this.preventClick = false;
        this.ppreventClick.emit(false);
        this.renderer.removeClass(document.body, 'modal-open');
      }, (error) => {
        if (error.status === 403 || error.status === 401) {
          this.dataService.user = null;
          this.phaseService.clearLocalStorage();
          this.phaseService.showSignin = true;
          this.phaseService.openCommonAuth = true;
        }
        this.preventClick = false;
        this.ppreventClick.emit(false);
      });
    }
  }

  public isActiveInvitedCards(): boolean {
    return !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling && !this.showSpecCalls && !this.showPaid && !this.showCompleted && !this.showInProgress && !this.showArchived && this.showInvited;
  }

  public closeDeletePopup() {
    if (!this.preventClick) {
      this.dataService.cardToDelete = null;
    }
  }

  public updateUserProfile(user, profile) {
    this.updateLoader = true;
    this.apiRequest.updateUserProfile(user, profile).pipe(
      finalize(() => {
        this.updateLoader = false;
      }))
      .subscribe(
        (data: any) => {
          if (data) {
            this.dataService.user = data.user;
            this.showEditProfileLink = true;
            this.dataService.isUserProfileSelected = false;
            this.pshowTooltip.emit(false);
            this.changesSaved = true;
          }
        },
        (error) => {
        }
      );
  }
}
