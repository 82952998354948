<div class="thanksForRegister" *ngIf="dataService.showUniqueCodeScreen && !dataService.partnerProfileSubmitted">
  <div class="thanksHolder">
    <h3>Hello {{ partnerDataService.getPartnerUser()?.first_name }},</h3>
    <p>Thanks for registering with us, your profile is still in moderation, we will update you soon. once it’s verified.
      Thanks for your patience</p>
    <p>Have a Unique code <span (click)="goToUniqueCodeScreen()">Click here</span></p>
  </div>
</div>

<div class="thanksForRegister" *ngIf="!dataService.showUniqueCodeScreen && !dataService.partnerProfileSubmitted">
  <div class="thanksHolder">
    <h3>Congratulations!</h3>
    <p>Your email address is successfully verified.</p>
    <button type="button" class="loginButton" (click)="openPartnerLogin()">Login Now</button>
  </div>
</div>

<div class="thanksForRegister" *ngIf="dataService.partnerProfileSubmitted">
  <div class="thanksHolder" *ngIf="!dataService.paymentSkipped">
    <h3>Thanks!</h3>
    <p>Your setup is currently in progress, we will get back to you shortly.</p>
  </div>

  <div class="thanksHolder1" *ngIf="dataService.paymentSkipped">
    <div class="iconTick"><em class="icon-tick-inside"></em></div>
    <h3>Thank You!</h3>
    <p>Your information is successfully submitted!<br> We will get back to you shortly.</p>
    <button *ngIf="dataService.isSkipDone" class="backButton" type="button" (click)="goBackToPaymentScreen()">Go Back
    </button>
  </div>
</div>
