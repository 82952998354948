import {ApplicationModule, Injectable} from '@angular/core';
import {JsonConvert, OperationMode, ValueCheckingMode} from 'json2typescript';
import {UrlParameterModel} from '../models/url-parameters-model/url-parameter.model';
import {PopularTeams, TeamsModel} from '../models/teams-model/teams.model';
import {isArray, isObject} from 'rxjs/internal-compatibility';
import {from} from 'rxjs';
import {pluck} from 'rxjs/operators';
import {ProgressModel} from '@models/build-card-model/progress.model';
import {CurrencyModel} from '@models/currency-model/currency.model';
import {TeamModel} from '@models/teams-model/team.model';
import {SpeedModel} from '@models/speed-model/speed.model';
import {FeatureModel} from '@models/feature-model/feature.model';
import {PromotionModel} from '@models/homepage-data-model/promotion.model';
import {PlatformModel} from '@models/platform-model/platform.model';
import {InviteModel} from '@models/build-card-model/invite.model';
import {BuilderPartnerModel} from '@models/builder-partner-model/builder-partner.model';
import {ChangeFeatureRequestModel, ChangeRequestBuildCardModel} from '@models/feature-model/change-feature-request.model';

/**
 * Created by nitin on 16/12/17.
 */
@Injectable()
export class ModelMapperService {
  public jsonConvert: JsonConvert;

  constructor() {
    this.jsonConvert = new JsonConvert();
    this.jsonConvert.operationMode = OperationMode.ENABLE; // print some debug data
    this.jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
    this.jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL; // allow null
  }

  public mapToUrlParameterModel(mappedObj: UrlParameterModel, jsonObject): UrlParameterModel {
    try {
      return this.jsonConvert.deserializeObject(jsonObject, UrlParameterModel);
    } catch (e) {
      console.log((<Error>e));
    }
  }

  public mapToTeamsModel(mappedObj: TeamsModel, jsonObject): TeamsModel {
    try {
      return this.jsonConvert.deserializeObject(jsonObject, TeamsModel);
    } catch (e) {
      console.log((<Error>e));
    }
  }


  public getMappedArrayModel<T>(modelToBeMapped: T[], jsonObject: any[]): T[] {
    if (isArray(jsonObject) && jsonObject.length > 0) {
      from(jsonObject).pipe(pluck('attributes')).subscribe((item: T) => {
        modelToBeMapped.push(item);
      });
    }
    return modelToBeMapped;
  }

  public getMappedObjectModel<T>(modelToBeMapped: T, jsonObject: any): T {
    if (isObject(jsonObject)) {
      modelToBeMapped = jsonObject['attributes'];
    }
    return modelToBeMapped;
  }

  public getMappedModel<T>(modelToBeMapped: T, jsonObject: any): T {
    for (const key in jsonObject) {
      if (jsonObject.hasOwnProperty(key)) {
        if (jsonObject[key] && jsonObject[key].hasOwnProperty('data')) {
          const subObj = jsonObject[key]['data'];
          if (isArray(subObj)) {
            let tempArr = [];
            from(subObj).pipe(pluck('attributes')).subscribe((item) => {
              tempArr.push(item);
            });
            modelToBeMapped[key] = tempArr;
          } else if (isObject(subObj)) {
            if (key === 'promotion') {
              modelToBeMapped[key] = this.getMappedModel(new PromotionModel(), subObj['attributes']);
            } else if (key === 'builder_partner'){
              modelToBeMapped[key] = this.getMappedModel(new BuilderPartnerModel(), subObj['attributes']);
            } else if (key === 'currency'){
              modelToBeMapped[key] = this.getMappedModel(new CurrencyModel(), subObj['attributes']);
            }  else {
              modelToBeMapped[key] = subObj['attributes'];
            }
          }
        } else {
          modelToBeMapped[key] = jsonObject[key];
        }
      }
    }
    return modelToBeMapped;
  }
  public getPromotionModelMapped<T>(modelToBeMapped: T, jsonObject: any): T {
    for (const key in jsonObject) {
      if (jsonObject.hasOwnProperty(key)) {
        if (jsonObject[key] && jsonObject[key].hasOwnProperty('data')) {
          const subObj = jsonObject[key]['data'];
          if (isArray(subObj)) {
            const tempArr = [];
            from(subObj).pipe(pluck('attributes')).subscribe((item) => {
              tempArr.push(item);
            });
            modelToBeMapped[key] = tempArr;
          } else if (isObject(subObj)) {
            modelToBeMapped[key] = subObj['attributes'];
          }
        } else {
          if (key === 'currency') {
            modelToBeMapped[key] = this.getMappedModel(new CurrencyModel(), jsonObject[key]['data']);
          } else if (key === 'team') {
            modelToBeMapped[key] = this.getMappedModel(new TeamModel(), jsonObject[key]['data']);
          } else if (key === 'speed') {
            modelToBeMapped[key] = this.getMappedModel(new SpeedModel(), jsonObject[key]['data']);
          } else if (key === 'features') {
            modelToBeMapped[key] = this.getMappedModel(new FeatureModel(), jsonObject[key]['data']);
          } else if (key === 'platforms') {
            modelToBeMapped[key] = this.getMappedModel(new PlatformModel(), jsonObject[key]['data']);
          } else {
            modelToBeMapped[key] = jsonObject[key];
          }
        }
      }
    }
    return modelToBeMapped;
  }
  public getBuildCardModelMapped<T>(modelToBeMapped: T, jsonObject: any): T {
    for (const key in jsonObject) {
      if (jsonObject.hasOwnProperty(key)) {
        if (jsonObject[key] && jsonObject[key].hasOwnProperty('data')) {
          let subObj = jsonObject[key]['data'];
          if (isArray(subObj)) {
            let tempArr = [];
            if (key === 'change_requests') {
              const changeFeatureArray: ChangeFeatureRequestModel[] = [];
              modelToBeMapped[key] = this.getAdditionalFeaturesArrayModelMapped(changeFeatureArray, subObj);
            } else {
              from(subObj).pipe(pluck('attributes')).subscribe((item) => {
                tempArr.push(item);
              });
              modelToBeMapped[key] = tempArr;
            }
          } else if (isObject(subObj)) {
            if (key === 'progress') {
              modelToBeMapped[key] = this.getMappedModel(new ProgressModel(), subObj['attributes']);
            } else if (key === 'currency') {
              modelToBeMapped[key] = this.getMappedModel(new CurrencyModel(), subObj['attributes']);
            } else if (key === 'promotion') {
              modelToBeMapped[key] = this.getMappedModel(new PromotionModel(), subObj['attributes']);
            } else if (key === 'invites') {
              modelToBeMapped[key] = this.getMappedModel(new InviteModel(), subObj['attributes']);
            } else {
              modelToBeMapped[key] = subObj['attributes'];
            }
          }

        } else {
          modelToBeMapped[key] = jsonObject[key];
        }
      }
    }
    //console.log(modelToBeMapped);
    return modelToBeMapped;
  }

  public getTeamMappedModel<T>(modelToBeMapped: T, jsonObject: any): T {
    for (const key in jsonObject) {
      if (jsonObject.hasOwnProperty(key)) {
        if (jsonObject[key] && jsonObject[key].hasOwnProperty('data')) {
          const subObj = jsonObject[key]['data'];
          if (isArray(subObj)) {
            let tempArr = [];
            from(subObj).pipe(pluck('attributes')).subscribe((item) => {
              tempArr.push(item);
            });
            modelToBeMapped[key] = tempArr;
          } else if (isObject(subObj)) {
            modelToBeMapped[key] = subObj['attributes'];
          }
        } else {
          if (key === 'popular') {
            let popularObj: TeamModel[] = [];
            modelToBeMapped[key] = this.getMappedArrayModel(popularObj, jsonObject[key]['all']['data']);
          } else {
            modelToBeMapped[key] = jsonObject[key];
          }
        }
      }
    }
    return modelToBeMapped;
  }

  public getAppDetailModelMapped<T>(modelToBeMapped: T, jsonObject: any): T {
    for (const key in jsonObject) {
      if (jsonObject.hasOwnProperty(key)) {
        if (jsonObject[key] && jsonObject[key].hasOwnProperty('data')) {
          const subObj = jsonObject[key]['data'];
          if (isArray(subObj)) {
            let tempArr = [];
            from(subObj).pipe(pluck('attributes')).subscribe((item) => {
              tempArr.push(item);
            });
            modelToBeMapped[key] = tempArr;
          } else if (isObject(subObj)) {
            if (key === 'features') {
              modelToBeMapped[key] = this.getMappedModel(new PromotionModel(), subObj['attributes']);
            }
            else if(key === 'builder_partner'){
              modelToBeMapped[key] = this.getMappedModel(new BuilderPartnerModel(), subObj['attributes']);
            }
            else {
              modelToBeMapped[key] = subObj['attributes'];
            }
          }
        } else {
          modelToBeMapped[key] = jsonObject[key];
        }
      }
    }
    return modelToBeMapped;
  }

  public getAdditionalFeaturesArrayModelMapped<T>(modelToBeMapped: ChangeFeatureRequestModel[], jsonObject: any[]): ChangeFeatureRequestModel[]  {
    if (isArray(jsonObject) && jsonObject.length > 0) {
      from(jsonObject).pipe(pluck('attributes')).subscribe((item: T) => {
        const changeRequestModel: ChangeFeatureRequestModel = new ChangeFeatureRequestModel();
        modelToBeMapped.push(this.getAdditionalFeaturesModelMapped(changeRequestModel, item));
      });
    }
    return modelToBeMapped;
  }

  public getAdditionalFeaturesModelMapped<T>(modelToBeMapped: T, jsonObject: any): T {
    for (const key in jsonObject) {
      if (jsonObject.hasOwnProperty(key)) {
        if (jsonObject[key] && jsonObject[key].hasOwnProperty('data')) {
          let subObj = jsonObject[key]['data'];
          if (isArray(subObj)) {
            let tempArr = [];
            from(subObj).pipe(pluck('attributes')).subscribe((item) => {
              tempArr.push(item);
            });
            modelToBeMapped[key] = tempArr;
          } else if (isObject(subObj)) {
            if (key === 'build_card') {
              modelToBeMapped[key] = this.getMappedModel(new ChangeRequestBuildCardModel(), subObj['attributes']);
            } else {
              modelToBeMapped[key] = subObj['attributes'];
            }
          }
        } else {
            modelToBeMapped[key] = jsonObject[key];
        }
      }
    }
    return modelToBeMapped;
  }

  public getMappedArrayDataModel(modelToBeMapped: any, jsonObject: any): any {

    for (const key in jsonObject) {
      if (jsonObject.hasOwnProperty(key)) {
        if (jsonObject[key]) {
          let subObj = jsonObject[key];
          if (isArray(subObj)) {
            let tempArr = [];
            from(subObj).pipe(pluck('attributes')).subscribe((item) => {
              if (item.hasOwnProperty('question_options')) {
                let modifiedQuestionObj = {};
                modifiedQuestionObj = this.getMappedModel(modifiedQuestionObj, item);
                tempArr.push(modifiedQuestionObj);
              } else {
                tempArr.push(item);
              }

            });
            modelToBeMapped = tempArr;
          }
        }
      }
    }

    return modelToBeMapped;
  }

}

