import {Component, Input, OnInit} from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {AppDataService} from '@rootmodule/app-data.service';

@Component({
  selector: 'app-left-side',
  templateUrl: './left-side.component.html',
  styleUrls: ['./left-side.component.scss']
})
export class LeftSideComponent implements OnInit {

  @Input() appDetails;
  smbUser = false;

  constructor(public phaseService: PhaseService, public dataService: DataService, public appDataService: AppDataService) {
  }

  ngOnInit(): void {
    if (!this.phaseService.IsFromFreemium && ((this.dataService.user && this.dataService.user.currency.code == 'INR') || this.dataService.homePageData.currency.code == "INR")) {
      this.smbUser = true;
      console.log('smbUser is true');
    } else {
      console.log('smbUser is not true');
    }
  }

  handlePaymentoption(index: number) {
    if (this.dataService.buildCardData.studio_store_nda_signed && !this.phaseService.isRentalCardPaid) {
      this.phaseService.handleNextPrevForRentalFlow(index);
    }
  }

  handleContractClick(index: number) {
    if (!this.phaseService.isRentalCardPaid) {
      this.phaseService.handleNextPrevForRentalFlow(index);
    }
  }

  handlePaymentOverViewClick(index: number) {
    if (!this.phaseService.isRentalCardPaid) {
      this.phaseService.handleNextPrevForRentalFlow(1);
    }
  }
}
