<div class="commonPopUp active" [ngClass]="{'hiddenParent' : phaseService.showCallBookedSuccess,  'bnow-dummy-bg' : phaseService.conditionToCreateAndSyncBuildCardToBNow()}">
  <div class="popOverlay"></div>
  <div class="popHolder newsignUppoup">
    <div  *ngIf="!phaseService.conditionToCreateAndSyncBuildCardToBNow() && !dataService.isSocialSignUpForStudioStore" class="closePopUp" (click)="closeModal()">
      <span class="icon-cancel"></span>
    </div>
    <app-left-side class="leftside freemiumImageWrap" [appDetails]="appDetails"></app-left-side>
    <app-right-side class="rightside" [ngClass]="phaseService.selectedSectionIndex === 3?'pd-b-0':''"></app-right-side>
  </div>
</div>

<div class="commonPopUp" [ngClass]="{'active': phaseService.showBeforeLeavingSchedulerPopup}">
  <div class="popOverlay" (click)="closeSpecModal()"></div>
  <div class="popHolder beforeYouLeavePopup">
    <div class="closePopUp"><span class="icon-cancel" (click)="closeSpecModal()"></span></div>
    <div>
      <h3>Before you leave</h3>
      <div *ngIf="phaseService.isDemoCall && (dataService.currentPage !== 'paymentStatus') && !this.phaseService.isFromDashboard">
        <p>Hey <span>{{dataService?.user?.first_name}}.</span></p>
        <p>We're stoked to show you how we can bring your app idea to life! Book a chat with us now to get the ball rolling.</p>
      </div>
      <div *ngIf="!phaseService.isDemoCall && (dataService.currentPage !== 'paymentStatus') && !this.phaseService.isFromDashboard">
        <p>Hey again <span>{{dataService?.user?.first_name}}.</span></p>
        <p>The Spec call is where we get to dive into the detail with you about your idea. Book your spec call now so we can customise your Buildcard.</p>
      </div>
      <div *ngIf="(dataService.currentPage === 'paymentStatus' || this.phaseService.isFromDashboard)">
        <p>Hey again <span>{{dataService?.user?.first_name}}.</span></p>
        <p>We can't wait to get started on your Buildcard! We just need to complete your Kickoff call to get going. Can we book yours in now?</p>
      </div>
      <div class="buttonWrap">
        <button type="button" class="btnbookItLater" (click)="showOptions()">
          <span class="selectedReason">No, select reason</span>
          <em class="icon-downside"></em>

          <ul *ngIf="showCancelOptions">
            <li (click)="scheduleCallLater('I am not able to start immediately')">I am not able to start immediately</li>
            <li (click)="scheduleCallLater('I need to check my availability')">I need to check my availability</li>
            <li (click)="scheduleCallLater('I can\'t find a suitable time to book')">I can't find a suitable time to book</li>
          </ul>
        </button>

        <button type="button" class="btnBookNow" (click)="scheduleCallNow()">Yes, let me book now</button>
      </div>
    </div>
  </div>
</div>
