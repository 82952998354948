<div class="commonPopUp active" *ngIf="isShowResetPopUp">
  <div class="popOverlay"></div>
  <div class="popHolder resetPassBlock"> <!---- if height scrollable the then add class 'scrollFix'  -->
    <div class="new-CloseButton" (click) ="hideResetPopUp()"><em class="icon-cancel"></em></div>
    <!--login left section start-->
    <div class="loginLeft">
      <div class="titletxt">
        <h3>
          <span >Reset password</span>
          <span *ngIf="false">Done!</span>

        </h3>

      </div>

      <img src="../../../../assets/images/newsignuppopup.png" alt="" />
    </div>
    <!--login left section close-->

    <!--login right section start-->
    <div class="loginRight">
      <!--- test for shoe only web -->
      <div class="titletxt">
        <h3>
          <span >Reset password</span>
          <span *ngIf="false">Done!</span>
        </h3>
      </div>

      <!----FORGOT PASSWORD ---->
      <!--- screen 1 -->
      <div >
      <!---- Reset password sreen --->
      <div class="formElements forgotpassBlock" *ngIf="show_reset" >
        <p class="forgotTitle" >Please create any password of your choice.</p> <!-- *ngIf="signup_data.password"-->
        <ul>
          <li>
            <label>New password </label>
              <div class="errorMsgBox">
                <div *ngIf="isResetPasswordError() && showResetPwdError" class="errorMsg show">
                  <span *ngIf="isResetPasswordLengthCorrect()">Password should contain 8 characters</span>
                  <span *ngIf="isResetPasswordRequiredError()">Password cannot be blank</span>
                </div>
                <span class="errorMsg show" *ngIf="errorMsg && !isResetPasswordError()">{{errorMsg}}</span>
              </div>
            <div class="relativeRow">
              <input placeholder="Enter your new password here :" [(ngModel)]="reset_data.password"
                         (keydown)="resetPwdChanged($event)"
                         name="signup_password" type="{{resetPass.show?'text':'password'}}" #reset_password="ngModel"
                         maxlength="25" minlength="8" required>
              <div class="viewPassword" (click)="showPasswordReset()" ><em class="icon-eye"></em></div>
            </div>
          </li>
        </ul>
        <div class="footersection">
          <div class="actionbtn">
            <button type="button" class="doneButton" [ngClass]='resetButtonClass' (click)="resetPassword()">Set new password</button>
          </div>
        </div>
      </div>
      <!---- Done sreen --->
      <div class="formElements forgotpassBlock" *ngIf="success">
        <p class="forgotTitle">Password changed successfully.</p>
        <div class="footersection">
          <div class="actionbtn">
            <button type="button" class="doneButton" (click)="gotoLogin()">Sign in now</button>
          </div>
        </div>
      </div>
      </div>
    </div>
    <!--login right section close-->
  </div>

</div>
