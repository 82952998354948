import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {ApiRequests} from '@shared/services/api-requests.service';
import {SpecCallModel} from '@models/spec-call-model/spec-call.model';
import moment from 'moment';
/*import {IMyDateModel} from 'mydatepicker';*/
import {Constants} from '@shared/utils/constants';
import {AppDataService} from '@rootmodule/app-data.service';
import {Intercom} from 'ng-intercom';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import {ShareService} from '@ngx-share/core';
import {Subscription} from 'rxjs';
import { CommonService } from "src/app/shared/shared-module/services/common.service";
import { environment } from '@env/environment';
import {BillingDetailsService} from '@shared/services/billing-details.service';

@Component({
  selector: 'payment-status',
  templateUrl: 'payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})

export class PaymentStatusComponent implements OnInit, OnDestroy {
  public specCallData: SpecCallModel;
  public dateSelected: any = {};
  dateChange = false;
  dateString = '';
  showThanks = false;
  jsDate: any;
  day = 1;
  isPaymentTypeSpecing = false;
  isPaymentTypeInstallment = false;
  isPaymentTypePrototype = false;
  isInstallmentPaymentDone = false;
  isPrototypeInstallmentDone = false;
  isSpecingPaymentDone = false;
  buildCardId;
  specingId;
  installmentId;
  transactionId: string;
  isCalenderOpen = false;
  paidAmount: number;
  isSpecCallScheduled = false;
  isScheduleCall = false;
  installmentType: string;
  public showOrderSummary = false;
  public showIdeaBoard = false;
  public showRating = false;
  public reviewRating = 0;
  public reviewText = '';
  public reviewSubmitted = false;
  public bottomRatingPopup = true;
  public reviewSelected = false;
  public scheduleCallWaiting = true;
  public waitingMessage : string;
  public scheduleCallSubscription : Subscription;
  public inProgressBuildCardId : number;
  public schedulerWaitingMsg : string;
  public installmentPaidDate : any;
  public progressBarStep : boolean;
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd.mm.yyyy',
    disableWeekends: true,
    inline: true,
    disableDates: this.getNextThreeDays()
    // other options are here...
  };

  myDateInit = true;
  model: IMyDateModel = null;
  public repoUrl: string;
  public otherPaymentDone = false;
  public isInstallmentPage = false;
  public isResponseRendered = false; // To check if api response completed for any payment method.
  moment: any;
  paidInstallments = [];
  paymentId : string;

  /*@ngInject*/
  constructor(public appDataService: AppDataService, public phaseService: PhaseService, public dataService: DataService, public billingService: BillingDetailsService,
              public router: Router, public route: ActivatedRoute, public analyticsSegment: Angulartics2Segment,
               private commonService: CommonService, public apiRequests: ApiRequests, public share: ShareService) {
    this.dataService.showLoader = true;
    this.dataService.showHideLoader(true);
    this.dataService.currentPage = 'paymentStatus';
    this.moment = moment;
  }

  ngOnInit() {
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    if (this.appDataService.urlParameters.payment_id) {
      this.paymentId = this.appDataService.urlParameters.payment_id;
    }
    this.isInstallmentPage = this.appDataService.urlParameters.installment_id ? true: false;
    this.schedulerWaitingMsg = 'Hold tight, we’re assigning your Buildcard™ to a squad...';
    setTimeout(() => {
      this.progressBarStep = true;
      this.schedulerWaitingMsg = 'Finding the best experts to work on your Buildcard™...';
    }, 8000);
    this.repoUrl = 'http://studio.builder.ai/home';
    if (this.appDataService.urlParameters.paymentType) {
      if (this.appDataService.urlParameters.paymentType === 'specing') {
        this.isPaymentTypeSpecing = true;
      } else if ((this.appDataService.urlParameters.paymentType === 'prototype') || (this.appDataService.urlParameters.prototypePayment === 'custom_prototype')
        || (this.appDataService.urlParameters.prototypePayment === 'tailor_made_prototype')) {
        this.isPaymentTypePrototype = true;
      } else {
        this.isPaymentTypeInstallment = true;
      }
      this.route.parent.params.subscribe((params: any) => {
        this.dataService.specingId = params.id;
        if (this.isPaymentTypeSpecing) {
          this.specingId = params.id;
        } else if (this.isPaymentTypePrototype) {
          this.installmentId = this.appDataService.urlParameters.paymentId;
          this.buildCardId = ((this.appDataService.urlParameters.prototypePayment === 'custom_prototype') || (this.appDataService.urlParameters.prototypePayment === 'tailor_made_prototype'))
            ? this.appDataService.urlParameters.buildCardId : params.id;
        } else {
          this.buildCardId = params.id;
        }
        this.paymentTypeInstallments();
      });
    } else {
      this.getBuildCardIDOnReload();
    }
    this.phaseService.isFromSpecPayment = false;
    if (!this.showThanks) {
      this.pollForSchedular();
    }
  }

  pollForSchedular() {
      this.inProgressBuildCardId = this.buildCardId;
      this.scheduleCallSubscription = this.apiRequests.scheduleCallPolling(this.inProgressBuildCardId)
      .subscribe(res => {
      if ( res.data.status === "pending"){
        this.scheduleCallWaiting = true;
        this.pollForSchedular();
      } else {
        this.scheduleCallSubscription.unsubscribe();
        this.progressBarStep = false;
        this.scheduleCallWaiting = false;
        this.scheduleExpertCall();
      }
    });
  }

  public checkForFailedPayment() {
    return this.isResponseRendered ?
      (
        !this.showThanks && !this.otherPaymentDone &&
        (
          (this.buildCardId && this.isPaymentTypeInstallment && !this.isInstallmentPaymentDone) ||
          (this.isPaymentTypeSpecing && !this.isSpecingPaymentDone) ||
          (this.isPaymentTypePrototype && !this.isPrototypeInstallmentDone)
        )
      )
      : false;
  }

  public getBuildCardIDOnReload(): void {
    this.route.parent.params.subscribe(params => {
      this.dataService.specingId = params.id;
      this.buildCardId = params.id;
      this.specingId = params.id;
      this.handlePaymentTypeInstallment();
    });
  }

  public showHideRating() {
    if ((this.isPaymentTypePrototype && this.isPrototypeInstallmentDone) ||
      this.showOrderSummary || this.showIdeaBoard || this.reviewSubmitted) {
      return false;
    } else {
      return true;
    }
  }

  public paymentTypeInstallments() {
    if (this.isPaymentTypeSpecing) {
      this.handleSpecingStatus();
    } else if (this.isPaymentTypePrototype) {
      this.handlePrototypeStatus();
    } else {
      this.handleBuildCardStatus();
    }
  }

  handlePaymentTypeInstallment() {
    this.dataService.showHideLoader(true);
    this.apiRequests.fetchInstallments(this.buildCardId).subscribe((data: any) => {
      if (data && data.installments) {
        const prototype = data.installments.filter(installmemt => {
          return installmemt.status === 'paid';
        });
        if (prototype && (prototype.length > 0) && prototype[0].type) {
          switch (prototype[0].type) {
            case 'custom_prototype' :
              this.appDataService.urlParameters.prototypePayment = 'custom_prototype';
              this.isPaymentTypePrototype = true;
              this.isPrototypeInstallmentDone = true;
              break;
            case 'tailor_made_prototype' :
              this.appDataService.urlParameters.prototypePayment = 'tailor_made_prototype';
              this.isPaymentTypePrototype = true;
              this.isPrototypeInstallmentDone = true;
              break;
            case 'prototype' :
              this.appDataService.urlParameters.paymentType = 'prototype';
              this.isPaymentTypePrototype = true;
              break;
            case 'specing' :
              this.appDataService.urlParameters.paymentType = 'specing';
              this.isPaymentTypeSpecing = true;
              break;
            case 'deposit':
              this.isPaymentTypeInstallment = true;
              break;
            case 'installment':
              this.isPaymentTypeInstallment = true;
              this.phaseService.showScheduleCallFlow = true;
              this.dataService.showMeetNowModel = true;
              break;
          }
          this.paymentTypeInstallments();
        }
      } else {
        this.isInstallmentPaymentDone = false;
        this.dataService.showHideLoader(false);
      }
      this.setDateInfo();
      // this.trackEvents();
    }, (error) => {
      // this.trackEvents();
      this.dataService.showHideLoader(false);
    });
  }

  handlePrototypeStatus() {
    this.apiRequests.fetchInstallments(this.buildCardId).subscribe((data: any) => {
      if (data && data.installments) {
        let prototype;
        if (this.appDataService.urlParameters.paymentType === 'prototype') {
          prototype = data.installments.find(installmemt => {
            return installmemt.type === 'prototype';
          });
        } else {
          prototype = data.installments.find(installmemt => {
            return (installmemt.type === 'custom_prototype');
          });
          if (!prototype) {
            prototype = data.installments.find(installmemt => {
              return (installmemt.type === 'tailor_made_prototype');
            });
          }
        }
        if (prototype && (prototype.status === 'paid')) {
          this.isPrototypeInstallmentDone = true;
          this.transactionId = prototype.transaction_id;
          this.paidAmount = prototype.paid_amount;
          this.installmentPaidDate = prototype.paid_at;
          this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.buildcard_payment_success.code);
        } else {
          if (this.appDataService.urlParameters.payment_gateway === 'other') {
            this.showThanks = true;
            this.otherPaymentDone = true;
          } else {
            this.isPrototypeInstallmentDone = false;
          }
          this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.buildcard_payment_failed.code);
        }
        this.paidInstallments = data.installments.filter(installment => (installment.status === 'paid'));
        if (this.paidInstallments.length > 1) {
          this.dataService.showMeetNowModel = false;
        }
      } else {
        this.isPrototypeInstallmentDone = false;
      }
      this.setDateInfo();
      // this.trackEvents();
      this.dataService.showHideLoader(false);
      this.isResponseRendered = true;
      if(this.isInstallmentPage) {
        this.getTransactionIdByInstallment(data)
      }
    }, (error) => {
      // this.trackEvents();
      this.dataService.showHideLoader(false);
      this.isResponseRendered = true;
    });
  }

  getPaidDate() {
    return this.installmentPaidDate;
  }
  getTransactionIdByInstallment(data) {

    const installment =  data.installments.filter((installment)=> {
      return installment.id === this.appDataService.urlParameters.installment_id
    });
    this.transactionId = installment[0].transaction_id;
    this.paidAmount = installment[0].paid_amount;
    this.installmentPaidDate = installment[0].paid_at;

  }

  handleSpecingStatus() {
    this.apiRequests.getSpecCallDetails(this.specingId)
      .subscribe((data: SpecCallModel) => {
        this.specCallData = data;
        if (this.specCallData.status === 'paid') {
          this.isSpecingPaymentDone = true;
          this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.specing_payment_success.code);
        } else {
          this.isSpecingPaymentDone = false;
          this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.specing_payment_failed.code);
        }
        // this.trackEvents();
        this.dataService.showHideLoader(false);
        this.isResponseRendered = true;
      }, error => {
        // this.trackEvents();
        this.dataService.showHideLoader(false);
        this.isResponseRendered = true;
      });
  }

  handleBuildCardStatus() {
    this.apiRequests.fetchInstallments(this.buildCardId).subscribe((data: any) => {
      if (data && data.installments) {
        if (this.appDataService.urlParameters.paymentType === 'prototype') {
          const prototype = data.installments.find(installmemt => {
            return installmemt.type === 'prototype';
          });
          if (prototype && prototype.status === 'paid') {
            this.isInstallmentPaymentDone = true;
            this.transactionId = prototype.transaction_id;
            this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.buildcard_payment_success.code);
          } else {
            this.isInstallmentPaymentDone = false;
            this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.buildcard_payment_failed.code);
          }
        } else {
          let deposit = data.installments.find(installmemt => {
            return (!location.href.includes('buildercare-payment')) ? (installmemt.type === 'deposit') : (installmemt.type === 'support');
          });
          if (!deposit) {
            deposit = data.installments.find(installmemt => (installmemt.type === 'installment'));
          }
          if (deposit && deposit.status === 'paid') {
            this.isInstallmentPaymentDone = true;
            this.installmentType = deposit.type;
            this.transactionId = deposit.transaction_id;
            this.paidAmount = deposit.paid_amount;
            this.installmentPaidDate = deposit.paid_at;
            this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.buildcard_payment_success.code);
          } else {
            if (this.appDataService.urlParameters.payment_gateway === 'other') {
              if (deposit.type === 'support') {
                this.installmentType = 'support';
              }
              this.showThanks = true;
            } else {
              this.isInstallmentPaymentDone = false;
            }
            this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.buildcard_payment_failed.code);
          }
        }
        this.paidInstallments = data.installments.filter(installment => (installment.status === 'paid'));
        if (this.paidInstallments.length > 1) {
          this.dataService.showMeetNowModel = false;
        }
      } else {
        this.isInstallmentPaymentDone = false;
      }
      this.setDateInfo();
      // this.trackEvents();
      this.dataService.showHideLoader(false);
      this.isResponseRendered = true;
      if(this.isInstallmentPage) {
        this.getTransactionIdByInstallment(data)
      }
    }, (error) => {
      // this.trackEvents();
      this.dataService.showHideLoader(false);
      this.isResponseRendered = true;
    });
  }

  public ngOnDestroy() {
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    this.scheduleCallSubscription.unsubscribe();
    this.appDataService.urlParameters.paymentStatus = null;
    this.dataService.paymentStatusFlow = false;
    this.appDataService.urlParameters.paymentType = null;
    this.appDataService.urlParameters.buildCardId = null;
  }

  public gotoDashboard() {
    this.router.navigate(['dashboard']);
  }

  getPaidAmount() {
    if (this.paidAmount) {
      return this.dataService.getLocaledPrice(this.paidAmount);
    }
  }

  public getUrlParameters() {
    return this.appDataService.urlParameters;
  }

  public isEngineerAIBuilder() {
    return this.dataService.isEngineerAIBuilder();
  }

  public setDateInfo(): void {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    const disableDateUntil = {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()};
    this.myDpOptions = {
      dateRange: false,
      dateFormat: 'dd.mm.yyyy',
      disableUntil: disableDateUntil,
      disableWeekends: true,
      inline: true,
      disableDates: this.getNextThreeDays()
    };
    this.model = {isRange: false, singleDate: {jsDate: new Date()}};
  }

  public getNextThreeDays() {
    return [this.getDateObj(), this.getDateObj(this.day), this.getDateObj(this.day), this.getDateObj(this.day)];
  }

  getDateObj(day?) {
    this.moment = moment;
    const today = this.moment();
    if (day) {
      const tomorrow = this.moment(today).add(day, 'days');
      const tomorrowday = tomorrow.day();
      if (tomorrowday === 0 || tomorrowday === 6) {
        this.day = day + 1;
        return this.getDateObj(day + 1);
      } else {
        this.day = day + 1;
        return {year: tomorrow.year(), month: tomorrow.month() + 1, day: tomorrow.date()};
      }
    } else {
      const todaydate = today.day();
      if (todaydate === 0 || todaydate === 6) {
        this.day = todaydate + 1;
        return this.getDateObj(todaydate + 1);
      } else {
        return {year: today.year(), month: today.month() + 1, day: today.date()};
      }
    }
  }

  onCalendarToggle(event: number): void {
    if (event === 1) {
      this.isCalenderOpen = true;
    } else {
      this.isCalenderOpen = false;
    }
  }

  public getPreventClick() {
    return this.phaseService.preventClick;
  }

  closePopup() {
    this.dateChange = false;
    this.jsDate = '';
    this.dateString = '';
    this.dateSelected.date = new Date();
  }

  trackGotoDashboardOrScheduleCall(eventName) {
    let pageSource = 'buildcard payment';
    const paymentType = this.appDataService.urlParameters.paymentType;
    if (paymentType === 'specing') {
      pageSource = 'spec call payment';
    } else if (paymentType === 'installmentis_From_Builder_Care') {
      pageSource = 'builder care';
    } else if (this.appDataService.urlParameters.paymentType === 'prototype') {
      pageSource = 'prototype payment';
    } else if (eventName === 'Go to Dashboard Clicked') {
      pageSource = 'Product';
    }
  }

  saveScheduleDate() {
    if (this.buildCardId) {
      this.dataService.showHideLoader(true);
      let data = {date: this.jsDate};
      this.apiRequests.addScheduleDate(this.buildCardId, data).subscribe((data) => {
        this.dateChange = false;
        this.isSpecCallScheduled = true;
        this.showThanks = true;
        this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.schedule_call_thanks_screen.code);
        this.dataService.showHideLoader(false);
        // this.isScheduleCall = false;
      });
      this.trackGotoDashboardOrScheduleCall('Schedule Spec Call');
    }
  }

  public getTransactionID() {
    return this.isPaymentTypeSpecing ? this.specCallData.transaction_id.toString() : this.transactionId;
  }

  redirectToNow() {
    window.location.href = this.dataService.homePageData.builder_partner.builder_now_url + '/' + this.buildCardId;
  }

  showCalender() {
    this.isScheduleCall = true;
    this.showIdeaBoard = false;
  }

  scheduleExpertCall() {
      if (this.paidInstallments.length < 2) {
        this.phaseService.showScheduleCallFlow = true;
        this.dataService.showMeetNowModel = true;
      }
  }

  public addBranding() {
    window.open(this.dataService.homePageData.builder_partner.builder_now_url + '/' + this.buildCardId + '/branding', '_blank' , "noopener");
  }

  showDashBoardButton() {
    return (this.installmentType && (this.installmentType !== 'deposit') && (this.appDataService.urlParameters.prototypePayment !== 'custom_prototype'));
  }

  public showHideOrderSummary(isShow) {
    this.showOrderSummary = isShow;
  }

  public checkIdeaBoard(showIdeaBoard) {
    this.showIdeaBoard = showIdeaBoard;
  }

  public ratingScale(rating, isFromBottomBar) {
    this.reviewRating = rating;
    if (isFromBottomBar) {
      this.reviewSelected = true;
    }
    this.rateExperienceFeedbackSubmission();
  }

  public reviewSubmit(reviewText: string) {
    this.reviewText = reviewText;
    this.reviewSubmitted = true;
    this.rateExperienceFeedbackSubmission();
  }

  onDateChanged(event: IMyDateModel): void {
    this.jsDate = event.singleDate.jsDate;
    this.dateString = this.moment(event.singleDate.jsDate).format('LL');
    this.dateChange = true;
    //this.saveScheduleDate();
    this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.schedule_call_popup_after_deposit_payment.code);
  }

  public rateExperienceFeedbackSubmission() {
    const feedbackData = {
      id: this.buildCardId,
      experience_rate: this.reviewRating,
      feedback: this.reviewText,
    };
    this.apiRequests.rateExperienceFeedback(feedbackData).subscribe((resp) => {

    }, error => {
      console.log(error);
    });
  }

  public getCurrencySymbol() {
    return this.dataService.buildCardData.id ? this.dataService.buildCardData.currency.symbol : this.phaseService.currencySymbol;
  }

  public getLocaledPrice(price: any): any {
    return this.dataService.getLocaledPrice(price);
  }

  backToWireTransfer(){
    if (this.paymentId) {
      window.location.href = environment.PAYMENT_URL + 'checkouts?payment_uuid=' + this.paymentId;
    }
  }

  setPlaceHolderForFeedbackBox() {
    if ((this.reviewRating === 1) || (this.reviewRating === 2)) {
      return 'What went wrong for you?';
    } else if (this.reviewRating === 3) {
      return 'What would have made your experience better?';
    }
  }
}
