import {CurrencyModel} from '@models/currency-model/currency.model';
import {PromotionModel} from '@models/homepage-data-model/promotion.model';


/**
 * Created by Rakesh on 01/11/19.
 */
export class PrototypeInstallmentModel {
  due_at: string;
  id: number;
  installment_amount: number;
  installment_no: number;
  installment_type: string;
  invoice: string
  original_amount: number;
  paid_amount: number;
  paid_at: string;
  pay_now_url: string;
  project_name: string;
  promotion_id: number;
  promotion: PromotionModel;
  receipt: string;
  status: string
  tax: number;
  tax_amount: number;
  transaction_id: string;
  currency: CurrencyModel;
}
