import {Injectable} from '@angular/core';
import {Headers} from '@angular/http';
import {Router} from '@angular/router';
import {throwError as observableThrowError} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {BehaviorSubject} from "rxjs";
import {PhaseService} from '@shared/services/phase.service';
import {CommonService} from '@shared/services/common.service';
import {HttpHeaders} from '@angular/common/http';
declare let window: any;

@Injectable()
export class PartnerDashboardDataService {
  public partnerData: any;
  public partnerSignupData: any;
  public isPartnerVerified = false;
  public profileForm: FormGroup;
  public partnerStatus = '';
  public contracts;
  public configData: any = {
    invitedUsersData: [],
    paymentMechanism: 'sell_through',
    isSignupAllowed: 'true'
  };
  public homePageData: BehaviorSubject<any> = new BehaviorSubject(null);
  public selectedCurrency = [];
  public homePage;

  constructor(private _router: Router, private commonService: CommonService) {
  }

  public setPartnerUser(user): void {
    if (this.commonService.isPlatformBrowser) {
      localStorage.setItem('partner_user', JSON.stringify(user));
    }
  }

  public getPartnerUser(): any {
   if (this.commonService.isPlatformBrowser) {
     return JSON.parse(localStorage.getItem('partner_user'));
   }
  }

  public isPartnerLoggedIn(): any {
    if (this.commonService.isPlatformBrowser) {
      return localStorage.getItem('partner_user');
    }
  }

  public checkPartnerVerified(): boolean {
    return this.isPartnerLoggedIn() && this.getPartnerUser().is_verified;
  }

  public removePartnerUser(): void {
    this.partnerData = null;
    if (this.commonService.isPlatformBrowser) {
      localStorage.removeItem('partner_user');
    }
  }

  public handleError(error: Response) {
    if (error.status === 401) {
      this.removePartnerUser();
      this._router.navigate(['become-a-partner']);
    }
    return observableThrowError(error);
  }

  public getRequestOptionArgs(contentType?: string) {
    let headers = new HttpHeaders();
    let token;

    if (this.getPartnerUser()) {
      token = this.getPartnerUser().authtoken;
    }

    if (!contentType) {
      headers = headers.append('Content-Type', 'application/json');
    } else {
      headers = headers.append('Content-Type', contentType);
    }
    if (token) {
      headers = headers.append('authtoken', token);
    }
    return headers;
  }

  public isPartnerProfileComplete() {
    return this.getPartnerUser() && this.getPartnerUser().company_name;
  }

  public goBackToProfileEdit(form: FormGroup) {
    const partner = this.getPartnerUser();
    if (partner && form) {
      form.patchValue({
        partnerName: partner.partner_name,
        companyName: partner.company_name,
        websiteLink: partner.website_link,
        spocName: partner.spoc_name,
        spocRole: partner.spoc_role,
        companySize: partner.company_size,
        industry: partner.industry_type
      });
    }
  }

  public partnerContractsSigned(): boolean {
    return this.getPartnerUser().nda_signed;
  }

}
