<div class="buildCardDetail">
  <div class="buildCardTabs">
    <ul>
      <li [ngClass]="{'active': activeTab === 1}" (click)="setTab(1)">Buildcard<sup class="tm">®</sup></li>
      <li [ngClass]="{'active': activeTab === 2}" (click)="setTab(2)" *ngIf="!isBuildCardInProgress()">Payment Details
      </li>
    </ul>
  </div>

  <app-project-info *ngIf="activeTab === 1"></app-project-info>
  <app-payment-details *ngIf="activeTab === 2"></app-payment-details>

  <div class="clearfix"></div>
</div>
