<div class="commonPopUp" [ngClass]="{'active': phaseService.isInvitedUserPopupOpened}">
  <div class="popOverlay" (click)="closeInvitePopup()"></div>
  <div class="popHolder invitedUserPopup">
    <div class="invitedHead">
      <div class="ownerDetail">
        <div class="invitedPic"><em class="icon-myaccount"></em></div>
        <h3>{{ invitedCardData?.owner_name }}<span> (Owner)</span></h3>
        <h4>{{ invitedCardData?.owner_email }}</h4>
      </div>
      <button (click)="showHideAddInviteForm(true)" *ngIf="!isInputVisible && invitedCardData?.owner"><span>+</span>
        Invite User
      </button>
    </div>

    <div class="invitedForm" *ngIf="isInputVisible">
      <form [formGroup]="inviteUserForm" (ngSubmit)="addInviteUser(inviteUserForm)">
        <input type="text" formControlName="email" (focus)="inviteError = null; isInvitationSent = false;">
        <button type="submit" [disabled]="!inviteUserForm.valid">Invite
          <img *ngIf="addInviteLoader" src="./../../../assets/images/buttonLoader.gif" alt="" />
        </button>
        <div class="closeForm" (click)="showHideAddInviteForm(false)"><em class="icon-plus"></em></div>
        <span class="errorMsg" *ngIf="inviteError">{{ inviteError }}</span>
        <span class="errorMsg"
              *ngIf="inviteUserForm.get('email').hasError('invalidEmailAddress') && inviteUserForm.get('email').touched">Email is not valid</span>
        <span class="succMsg" *ngIf="isInvitationSent">Invitation has been sent successfully!</span>
      </form>
    </div>

    <div class="invitedList">
      <h3>Invited Users</h3>
      <ol>
        <li *ngFor="let invitee of invitedCardData?.invites; let i = index">{{ invitee.email }}
          <div class="pull-right" *ngIf="invitedCardData?.owner">
            <span class="removeButton" (click)="showHideRemoveOption(true, i)"
                  *ngIf="!isRemoveOptionVisible[i]">Remove</span>
            <span class="yesButton" *ngIf="isRemoveOptionVisible[i]"
                  (click)="removeInvitedUser(invitee, i)">Yes Sure!</span>
            <span class="noButton" *ngIf="isRemoveOptionVisible[i]" (click)="showHideRemoveOption(false, i)">No</span>
          </div>
        </li>
      </ol>
    </div>

  </div>
</div>
