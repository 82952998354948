import { Component, OnInit } from '@angular/core';
import { DataService } from '@shared/services/data.service';
import { ReferralService } from '@shared/services/referral.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Intercom} from 'ng-intercom';
import {PhaseService} from '@shared/services/phase.service';

@Component({
  selector: 'app-share-referral',
  templateUrl: './share-referral.component.html',
  styleUrls: ['./share-referral.component.scss']
})
export class ShareReferralComponent  {

  constructor(public referralService: ReferralService, public dataService: DataService,
              private phaseService: PhaseService, private analyticsSegment: Angulartics2Segment,
              private intercom: Intercom) { }


  closeShareReferralPopup() {
    this.referralService.openShareReferralPopup = false;
    this.phaseService.trackEvent(this.analyticsSegment, 'referral_share_link_closed', 'user_id', this.dataService.user.id);
  }

  trackEvent(platform) {
    this.phaseService.trackEventForObject(this.analyticsSegment, 'refer_link_shared', {
      'user_id': this.dataService.user.id,
      'share_type': platform
    });
  }

}
