<div class="appDetailSection">
    <div class="topSection">
      <!-- <h4>Supercharge your store</h4> -->
      <div class="storeUpgradeDetail">
      <p> If you’re already selling online, you should think about upgrading!</p>
      <label>What’s included</label>
      <p><img src="../../../../../../../assets/images/Shape.svg" alt=""/> Unlimited orders <br>
        <img src="../../../../../../../assets/images/Shape.svg" alt=""/> Your own custom domain & app store <br>
        <img src="../../../../../../../assets/images/Shape.svg" alt=""/> Personalise with your choice of templates <br>
        <img src="../../../../../../../assets/images/Shape.svg" alt=""/> Reporting to help you grow </p>
      <p>All for just {{dataService.user.currency.symbol}} {{ this.dataService.appDetails.rental_prices.discount_rental_monthly_price }} / month for {{ dataService.appDetails.month_count }} months (includes free cloud hosting and maintenance)</p>
      </div>
    </div>
    <div class="bottomsection">
        <button type="button" class="buttonStyle1" [disabled]="phaseService.proceedClickedFromRental"  (click)="buildCardStatus('freemium')">Continue with Free
          <img *ngIf="phaseService.proceedClickedFromRental &&  this.continueForFreeCtaClicked" src="../../../../../../../assets/images/buttonLoader.gif" alt=""/>
        </button>
        <button type="button" class="buttonStyle2" [disabled]="phaseService.proceedClickedFromRental" (click)="buildCardStatus('studio_store')">Upgrade my Store
          <img *ngIf="phaseService.proceedClickedFromRental &&  !this.continueForFreeCtaClicked" src="../../../../../../../assets/images/buttonLoader.gif" alt=""/>
        </button>
    </div>
  </div>
