import {Component, OnInit} from '@angular/core';
import {PartnerDashboardDataService} from '../../Utils/partner-dashboard-data.service';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'app-partner-home',
  templateUrl: 'home.component.html'
})

export class PartnerHomeComponent  {

  constructor(public partnerDataService: PartnerDashboardDataService,
              public phaseService: PhaseService,
              public dataService: DataService,  public analyticsSegment: Angulartics2Segment) {
  }


  public showSignupPopup() {
    this.phaseService.countryNameCode = this.dataService.country_code ? this.dataService.country_code.toLowerCase() : '';
    this.phaseService.signupNextStep = true;
    this.dataService.signupSrcName = 'default_sign_up';
    this.phaseService.showRegister = true;
    this.phaseService.showSignin = false;
    this.phaseService.openCommonAuth = true;
    this.dataService.showHideViews.signup = true;
  }
}
