<ng-container *ngIf="!isForFeaturePage">
  <div class="platformSidebarOverlay" (click)="closePlatformPopup()" [ngClass]="{'active': generalView || (phase)}"></div>
  <div class="platformSidebar" [ngClass]="{'active': generalView || (phase)}">
    <div class="topHead">
      <h3>PLATFORMS</h3>
      <div class="closeBar" (click)="closePlatformPopup()"><em class="icon-cancel"></em></div>
    </div>

    <div class="platformListing">
      <ul *ngIf="parallelMode">
        <li *ngFor="let platform of tempPlatforms; let i = index;" [ngClass]="{'active': platform.selected}">
          <div class="platformImg"><img [src]="platform.icon" alt=""/></div>
          <div class="platformName">{{ platform.title }}</div>
          <div class="platformCheck" *ngIf="checkForDisablingPlatform(platform)"
               [ngClass]="{ 'custom-disabled': deliveryService.checkIfApprovedPlatform(platform) }">
            <input type="checkbox" [id]="'platform' + i + index" [checked]="platform.selected"
                   (change)="togglePlatformSelections(platform, i, $event)">
            <label [for]="'platform' + i + index">
              <em class="icon-plus" *ngIf="!platform.selected"></em>
              <em class="icon-delete" *ngIf="platform.selected"></em>
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isForFeaturePage">
  <div class="platformList" *ngIf="webOrMobile === 'mobile'">
    <h3>Select a desktop/laptop platform</h3>
    <p *ngFor="let platform of filterPlatformsForWebOrMobile('web'); let i = index;" [ngClass]="{'active': platform.selected}">
      <input type="checkbox" [id]="'platform' + i + 6 + index" [checked]="platform.selected"
             (change)="togglePlatformSelections(platform, i, $event, 'web')"> <label [for]="'platform' + i + 6 + index">{{ platform.title }}</label>
    </p>
  </div>
  <div class="platformList" *ngIf="webOrMobile === 'web'">
    <h3>Select a mobile platform</h3>
    <p *ngFor="let platform of filterPlatformsForWebOrMobile('mobile'); let i = index;" [ngClass]="{'active': platform.selected}">
      <input type="checkbox" [id]="'platform' + i + index" [checked]="platform.selected"
             (change)="togglePlatformSelections(platform, i, $event, 'android')"> <label [for]="'platform' + i + index">{{ platform.title }}</label>
    </p>
  </div>
  <div class="applyButton" *ngIf="isForMobile">
    <button type="button" (click)="submitNewPlatformSelections()" [disabled]="checkIfPlatformAdded()">Apply</button>
  </div>
</ng-container>
