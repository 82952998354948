<div class="featureSidebarOverlay" [ngClass]="{'active': showFeatureList}" (click)="closeFeatureSlider()"></div>
<div class="featureSidebar" [ngClass]="{'active': showFeatureList}">
  <div class="topHead">
    <h3>{{ (phase.title === 'Professional Prototype') ? dataService.returnNeededPrototypeName() : phase.title }} Features</h3>
    <div class="closeBar" (click)="closeFeatureSlider()"><em class="icon-cancel"></em></div>
  </div>

  <div class="featureSearchBox">
    <form>
      <em class="icon-magnifying"></em>
      <input type="text" placeholder="Search" [(ngModel)]="searchQuery" [ngModelOptions]="{standalone: true}">
    </form>
  </div>
  <div class="featureListing">
    <div class="platformContainer" *ngFor="let platform of phase.platforms; let j = index">
      <div class="platformTitle">
        <div class="platformImg"><img [src]="platform?.icon" alt="" /></div>
        <div class="platformName">{{ platform?.title }}</div>
      </div>
      <div class="selectAllLink">
        <button *ngIf="(!platform.selectAllFeature)" (click)="selectAllFeature(true, platform)">Select all</button>
        <button *ngIf="(platform.selectAllFeature)" (click)="selectAllFeature(false, platform)">Unselect all</button>
      </div>
      <ul>
        <li [ngClass]="{'feature-disabled': getItDisabled(feature, platform) && platformsList.includes(platform?.title) && !(feature.phaseId && feature.phaseId.includes(phase.id + '' + platform.id)), 'active': isFeatureChecked(platform, feature)}"
          *ngFor="let feature of selectedFeatures | filter: searchQuery; let i = index" (click)="showToasterForPreSelected(getItDisabled(feature, platform) && platformsList.includes(platform?.title) && !(feature.phaseId && feature.phaseId.includes(phase.id + '' + platform.id)))">
          <div class="featureImg">
            <img [src]="feature.icon ? utilityService.addHttps(feature.icon) : '../../../../assets/images/Default_Feature.png'" alt=""
            />
          </div>
          <div class="featureName">{{ feature.title }}</div>
          <div class="featureCheck" *ngIf="editableFeatureList"
               [ngClass]="{ 'custom-disabled': delieryService.checkIfApprovedPlatform(platform) }">
            <input type="checkbox" [id]="feature.title + j + i" [checked]="isFeatureSelected(platform, feature)"
                   (click)="selectFeatureInAllPlatforms(phase.index, feature, $event, platform)">
            <label [for]="feature.title + j + i">
                <em [ngClass]="!isFeatureSelected(platform, feature) ? 'icon-plus' : 'icon-delete'"></em>
              </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
