import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PhaseService} from '../../services/phase.service';
import {DataService} from '../../services/data.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import { Intercom } from 'ng-intercom';
import {AppDataService} from '@rootmodule/app-data.service';
import {PaymentBuilderService} from '@featuremodules/payment-builder-module/payment-builder.service';

@Component({
  selector: 'app-phase-breakup',
  templateUrl: './phase-breakup.component.html',
  styleUrls: ['./phase-breakup.component.scss']
})
export class PhaseBreakupComponent  {

  public showPlatformPopup: boolean;
  featureDescriptionTop = 0;
  featureDescriptionLeft = 0;
  featuredescription = '';


  @Input() sendFeedback: boolean;
  @Output() phaseFlag: EventEmitter<any> = new EventEmitter();
  @Output() parentApplyPromotion: EventEmitter<boolean> = new EventEmitter();
  @Output() parentRemovePromotion: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public phaseService: PhaseService,
    public dataService: DataService,
    public analyticsSegment: Angulartics2Segment,
    public appDataService: AppDataService,
    private paymentService: PaymentBuilderService
  ) { }

  public getFinalWeeks() {
    return this.phaseService.finalPricenWeeks.weeks;
  }
  public getFinalPrice() {
    return Number(this.phaseService.finalPricenWeeks.total);
  }
  public getWeekWisePrice() {
    return this.phaseService.finalPricenWeeks.bcard_discounted_price / this.phaseService.finalPricenWeeks.payment_weeks;
  }

  public discountedPrice(){
    return this.phaseService.getTotalWithSpecing() - this.phaseService.getDiscountedWithSpecing();
  }

  public getDiscountedWithSpecing() {
    return this.phaseService.getDiscountedWithSpecing();
  }

  public getTotalWithSpecing() {
    return this.phaseService.getTotalWithSpecing();
  }
  closeFeedbackBox(){
    this.phaseFlag.emit(true);
  }
  openPlatformPopup(pageName) {
    let eventValue: string;
    switch (pageName) {
      case 'delivery':
        eventValue = 'Delivery';
        break;
      case 'features':
        eventValue = 'Feature';
    }
    this.showPlatformPopup = true;
  }
  closePlatformPopup() {
    this.showPlatformPopup = false;
  }
  public getcurrencySymbol() {
    return this.phaseService.getCurrencySymbol();
  }
  callParentApplyPromotion() {
    this.parentApplyPromotion.next(true);
  }
  callParentRemovePromotion() {
    this.parentRemovePromotion.next(true);
  }

  getMonthCount() {
    return this.dataService.appDetails ? this.dataService.appDetails.month_count : this.phaseService.selectedApps[0].month_count;
  }

  getMonthlyPrice() {
    return (this.dataService.appDetails && (this.dataService.currentPage !== 'features')) ? this.dataService.appDetails.monthly_price : this.phaseService.selectedApps[0].monthly_price;
  }

  getPerMonthText() {
    return this.dataService.buildCardData.upfront ? '' : '/month for ' + this.getMonthCount() + ' months';
  }

  getFeatureCostUptoTwoDecimalPlaces(featureCost) {
    if (featureCost) {
      return Number(featureCost.toFixed(2));
    }
    return 0;
  }

  getFeatureTotalCost(customisationCost, fixedCost) {
    return parseFloat((customisationCost + fixedCost).toFixed(2));
  }

  getCustomizationCost(feature) {
    return ('discounted_customisation' in feature) ? parseFloat(feature.discounted_customisation.toFixed(2)) : parseFloat(feature.customisation.toFixed(2));
  }

  getFixedCost(feature) {
    return ('discounted_fixed' in feature) ? parseFloat(feature.discounted_fixed.toFixed(2)) : parseFloat(feature.fixed.toFixed(2));
  }

  isOverflowingWidth(el) {
    return (el.offsetWidth < el.scrollWidth);
  }

  showDescription(e, title) {
    this.featureDescriptionTop = e.target.getBoundingClientRect().top - 100;
    this.featureDescriptionLeft = e.target.getBoundingClientRect().left - 25;
    this.featuredescription = title;
    console.log('enter');
  }

  hideDescription(e){
    this.featureDescriptionTop = 0;
    this.featureDescriptionLeft = 0;
    this.featuredescription = '';
    console.log('leave');
  }

  getCareTitle() {
    const selectedHash = this.phaseService.selectedHash;
    if (this.checkIfSubsWithPlus()) {
      if (this.dataService.buildCardData.subscription_flow) {
        return (this.getAdditionalCareText().replace(' Plus', '+'));
      } else {
        return (this.getAdditionalCareText());
      }
    } else {
      return selectedHash.care_type.replaceAll('_', ' ');
    }
  }

  getAdditionalCareText() {
    return this.paymentService.getCareTextForSubsFlow(this.phaseService.selectedHash.care_type);
  }

  checkIfSubsWithPlus() {
    return (this.dataService.buildCardData.subscription_flow && this.phaseService.selectedHash.is_under_support);
  }

  getCarePrice() {
    const selectedHash = this.phaseService.selectedHash;
    if (this.dataService.buildCardData.subscription_flow) {
      return this.phaseService.totalCarePrice;
    } else {
      return this.phaseService.getBuilderCareForMonthlyOrDefaultPricing(selectedHash.care_type);
    }
  }

  getBuilderCareShowHide() {
    const mvp = this.phaseService.selectedPhases.find(
      phase => (phase.title === 'MVP') || (phase['type'] === 'custom_phase')
    );
    const fullBuild = this.phaseService.selectedPhases.find(
      phase => phase.title === 'Full Build'
    );
    if ((mvp && mvp.selected) || (fullBuild && fullBuild.selected)) {
      if (!this.dataService.buildCardData.subscription_flow) {
        this.phaseService.selectedHash.is_under_support = true;
      }
      if (!this.phaseService.selectedHash.care_type && this.dataService.checkIfEnterpriseUserOrCard()) {
        this.phaseService.selectedHash.care_type = 'enterprise_care_plus';
      } else if (!this.phaseService.selectedHash.care_type) {
        this.phaseService.selectedHash.care_type = 'standard_care';
      }
      return true;
    } else {
      this.phaseService.selectedHash.is_under_support = false;
      return false;
    }
  }

  showCareSection() {
    const buildCardData = this.dataService.buildCardData;
    if (buildCardData.new_care_flow && this.getBuilderCareShowHide()) {
      if (!buildCardData.subscription_flow) {
        return true;
      } else if (this.checkIfSubsWithPlus()) {
        return true;
      }
    }
  }


}
