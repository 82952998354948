<div class="storeNatasha" *ngIf="isStartNatashaSession && natashaChats && (natashaChats.length > 0)">

    <div class="contentHolder" [ngClass]="{'hideNatashaChats': !shouldShowNatashaChats}">
        <div class="closeIcon" (click)="toggleNatashaChats()"><em class="icon-iconcross"></em></div>
        <div class="innerContainer" id="innerContainerBox">
                <!--up arrow start-->
                <div class="upArrow" *ngIf="isShowUpwardArrow" (click)="scrollNatashaToTop()"><em class="icon-leftarrow"></em></div>
                <!--up arrow close-->                

                <div class="innerScrollbar natashaChatViewport" #natashaPannel>
                    <!--natasha msg box start-->
                    <ng-container *ngFor="let message of natashaChats">
                        <div *ngIf="message?.conversational" 
                            [ngClass]="{natashaTextMsg : (message.conversational?.user_type === 'natasha'),
                                        userTextMsg : (message.conversational?.user_type === 'user')}">
                            <ng-container *ngIf="!message.conversational.showLoader" >
                                {{message?.conversational?.response}}
                            </ng-container>
                            
                            <ng-container *ngIf="message.conversational.showLoader">
                                <div class="threeDotLoader"><div class="dot-flashing"></div></div>
                            </ng-container>
                            
                        </div>

                        <!-- <div class="natashaTextMsg" *ngIf="message?.conversational && message.conversational.showLoader">
                            
                        </div> -->
                        
                    </ng-container>
                
                    <!--natasha msg box close-->

                    <!--natasha msg box start-->
                    <!-- <div class="natashaTextMsg">
                        Tell me what do you want to get built today.
                    </div> -->
                    <!--natasha msg box close-->

                    <!--user input msg box start-->
                    <!-- <div class="userTextMsg">
                        I want to build an e-commerce app
                    </div> -->
                    <!--user input msg box start-->

                    <!--natasha msg box start-->
                    <!-- <div class="natashaTextMsg">
                        That’s a great idea. I sure can help.
                    </div> -->
                    <!--natasha msg box close-->

                    <!--natasha msg box start-->
                    <!-- <div class="natashaTextMsg">
                        Tell me more about the idea.
                    </div> -->
                    <!--natasha msg box close-->

                    <!--user input box start-->
                    <div class="userInputBox" id="userInputArea">
                        <div class="textareaBox" [ngClass]="{'disableTexarea': shouldDisableNatashaChatInput}">
                            <textarea [(ngModel)]="natashaChatInput" (keyup.enter)="inputChatHandler($event)" 
                                [disabled]="shouldDisableNatashaChatInput">
                            </textarea>
                        </div>
                        <p>Press Enter to submit</p>
                    </div>
                    <!--user input box close-->
                </div>

                <!--down arrow start-->
                <div class="downArrow" *ngIf="isShowDownwardArrow" (click)="scrollNatashaToBottom()"><em class="icon-rightarrow"></em></div>
                <!--down arrow close-->
        </div>
    </div>
   
    <div class="iconHolder" (click)="toggleNatashaChats()">
        <!--natasha wizard start-->
        <div class="wizardAvatar">
            <span></span>
            <span></span>
        </div>
        <!--natasha wizard close-->
    </div>
</div>