<div class="paymentDashLogin">
  <h3><strong>Welcome,</strong> Please login to your account.</h3>

  <div class="formHolder">
    <form [formGroup]="loginForm" (ngSubmit)="loginAdmin(loginForm)">
      <ul>
        <li>
          <label>E-mail address</label>
          <input type="text" formControlName="email" (focus)="clearErrorMessage()">
          <span class="errorMsg" *ngIf="loginForm.get('email').hasError('required') && loginForm.get('email').touched">Email is required</span>
          <span class="errorMsg"
                *ngIf="loginForm.get('email').hasError('invalidEmailAddress') && loginForm.get('email').touched">Email is not valid</span>
        </li>
        <li>
          <label>Password</label>
          <em [ngClass]="{'icon-view': password.type === 'password', 'icon-cancel': password.type === 'text'}"
              (click)="changeInputType(password)"></em>
          <input #password type="password" formControlName="password" (focus)="clearErrorMessage()">
          <span class="errorMsg"
                *ngIf="loginForm.get('password').hasError('required') && loginForm.get('password').touched">Password is required</span>
          <span class="errorMsg" *ngIf="errorMessage">{{ errorMessage }}</span>
        </li>
      </ul>
      <button type="submit" [disabled]="!loginForm.valid">Get Started!
        <img *ngIf="loader" src="./../../../assets/images/buttonLoader.gif" alt="" />
      </button>
    </form>
  </div>
</div>
