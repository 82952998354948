import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {PartnerDashboardApiService} from '../../Utils/partner-dashboard-api.service';
import {PartnerDashboardDataService} from '../../Utils/partner-dashboard-data.service';
import {CustomValidators} from '@shared/utils/validators';
import {DataService} from '@shared/services/data.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {PhaseService} from '@shared/services/phase.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'app-partner-profile',
  templateUrl: 'profile.component.html'
})

export class PartnerProfileComponent implements OnInit {
  public loader = false;

  constructor(private _formBuilder: FormBuilder,
              public apiRequest: ApiRequests,
              private _partnerApiService: PartnerDashboardApiService,
              public partnerDataService: PartnerDashboardDataService,
              public dataService: DataService,  public analyticsSegment: Angulartics2Segment, public phaseService: PhaseService) {
  }

  public ngOnInit() {
    this.apiRequest.getUserData();
    this.initializeProfileForm();
  }

  public initializeProfileForm(): void {
    this.partnerDataService.profileForm = this._formBuilder.group({
      partnerName: new FormControl(null, [Validators.required]),
      companyName: new FormControl(null, [Validators.required]),
      websiteLink: new FormControl(null, [CustomValidators.urlValidator]),
      spocName: new FormControl(null, [Validators.required]),
      spocRole: new FormControl(null, [Validators.required]),
      companySize: new FormControl(null, [Validators.required]),
      industry: new FormControl(null, [Validators.required])
    });
    this.partnerDataService.goBackToProfileEdit(this.partnerDataService.profileForm);
  }

  public onProfileSubmit(form): void {
    if (form.valid) {
      this.loader = true;
      this.partnerDataService.partnerStatus = 'profile_submitted';
      this._partnerApiService.updatePartnerProfile(form.value)
        .subscribe(
          (data) => {
            this.dataService.isPartnerProfileUpdated = true;
            this.setPartnerProfileOnUpdate(data);
            this.loader = false;
          },
          (error) => {
            this.loader = false;
          }
        );
    }
  }

  public setPartnerProfileOnUpdate(data) {
    const partner = this.partnerDataService.getPartnerUser();
    for (const key in partner) {
      if (data[key]) {
        partner[key] = data[key];
      }
    }
    this.partnerDataService.setPartnerUser(data);
  }
}
