import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {Angulartics2Segment} from 'angulartics2/segment';
import {ApiRequests} from '@shared/services/api-requests.service';
import {Constants} from '@shared/utils/constants';
import {PhaseService} from '@shared/services/phase.service';
import {SpecCallModel} from '@models/spec-call-model/spec-call.model';
import {DataService} from '@shared/services/data.service';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'spec-call',
  templateUrl: 'spec-call.component.html',
})

export class SpecCallComponent {
  public preventClick = false;
  public errorMessage;
  public showPromoInputSection = false;
  public specPromoCode = '';
  public discountedPrice = 0;
  public showRedirectingScreen = false;
  public promotionId = '';
  public specCallData: SpecCallModel;
  public isSpecCreation = false;
  public isPromoApplication = false;
  @Input('currentUrl') currentUrl;


  constructor(public phaseService: PhaseService, public dataService: DataService, public router: Router, public analyticsSegment: Angulartics2Segment, public apiRequest: ApiRequests, ) {
  }

  ngOnInit() {
    this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.schedule_call_for_specing.code);
  }

  public getSelectedSpecing() {
    return this.phaseService.isOpenSpecing;
  }

  public hideSpecing() {
    this.phaseService.isOpenSpecing = false;
  }

  public applyPromotionOnSpec() {
    this.errorMessage = '';
    this.discountedPrice = 0;
    this.promotionId = '';
    if (!this.isPromoApplication) {
      this.isPromoApplication = true;
      this.apiRequest.applySpecPromotion(this.specPromoCode).subscribe((data) => {
        if (data) {
          this.discountedPrice = data.discount;
          this.promotionId = data.id;
        }
        this.isPromoApplication = false;
      }, error => {
        const errorData = error.error;
        if (errorData.message) {
          this.errorMessage = errorData.message;
        }
        this.isPromoApplication = false;
      });
    }
  }

  clickShowPromo() {
    this.showPromoInputSection = true;
  }

  public createSpecCall() {
    this.errorMessage = '';
    this.phaseService.isFromSpecPayment = true;
    if (!this.isSpecCreation) {
      this.isSpecCreation = true;
      this.apiRequest.createSpecCall(this.promotionId, this.dataService.currentPage).subscribe((data: SpecCallModel) => {
        if (data) {
          this.specCallData = data;
          if (this.specCallData.payable_amount === 0) {
            if (this.currentUrl === '/dashboard') {
              this.apiRequest.fetchBuildCards(null, null, 'spec_calls').subscribe(data => {
                this.phaseService.showTimeKitWindow(false,(this.specCallData && this.specCallData.id ? this.specCallData.id : 0));
                this.hideSpecing();
              });
            } else {
              this.phaseService.showTimeKitWindow(false,(this.specCallData && this.specCallData.id ? this.specCallData.id : 0));
              this.hideSpecing();
            }

          } else {
            this.showRedirectingScreen = true;
            setTimeout(() => {
              this.showRedirectingScreen = false;
            }, 1000);
          }
        }
        this.isSpecCreation = false;
      }, error => {
        this.isSpecCreation = false;
        this.errorMessage = 'Something is not right. Please try again.';
      });
    }
  }
}
