import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {finalize} from 'rxjs/operators';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {Intercom} from 'ng-intercom';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Subscription} from 'rxjs/Rx';
import { BuilderCareContentComponent } from '@shared/shared-components/builder-care-content/builder-care-content.component';
import {CommonService} from '@shared/services/common.service';

@Component({
  selector: 'app-completed-project-cards',
  templateUrl: './completed-project-cards.component.html',
  styleUrls: ['./completed-project-cards.component.scss']
})
export class CompletedProjectCardsComponent implements OnInit {
  public supportInfo = false;
  @Input() showRecentProgress: boolean;
  @Output() pshowCards = new EventEmitter();
  @Input('showMoreIndex') showMoreIndex;
  @Input() showRecentCompletedProjects: boolean;
  showDummyLoader: boolean;
  loaderCount = 0;
  builderCareInstallment = {id: '', totalAmount: 0, taxAmount: 0};
  getBuilderCareClicked = false;
  openInfoIndex: number;
  searchSubscription: Subscription;
  isApiSuccess = false;

  constructor(public dataService: DataService, public phaseService: PhaseService,
              public apiRequest: ApiRequests, public analyticsSegment: Angulartics2Segment,
              public commonService: CommonService
              ) { }

  ngOnInit() {
    this.setUserAddress();
  }


  opensupportinfo(index?) {
    this.openInfoIndex = index;
    this.supportInfo = !this.supportInfo;
  }

  getCompletedProjectCards() {
    return this.dataService.completedProjectCards;
  }

  showCards(type) {
    this.pshowCards.emit(type);
  }

  setUserAddress() {
    const userAddress = this.dataService.user.address,
      billingInfo = this.phaseService.billingInfo;
    billingInfo.contact = userAddress ? userAddress.phone.replace('-', '') : '';
    billingInfo.street = userAddress ? decodeURIComponent(userAddress.street) : '';
    billingInfo.apartment = userAddress ? decodeURIComponent(userAddress.apartment) : '';
    billingInfo.state = userAddress ? userAddress.state : '';
    billingInfo.city = userAddress ? userAddress.city : '';
    billingInfo.pincode = userAddress ? userAddress.pincode : '';
    billingInfo.country = userAddress ? userAddress.country : '';
    billingInfo.companyName = userAddress ? userAddress.company_name : '';
    billingInfo.gstNumber = userAddress ? userAddress.gst_number : '';
    billingInfo.firstname = userAddress && userAddress.first_name ? userAddress.first_name : billingInfo.firstname;
    billingInfo.lastname = userAddress && userAddress.last_name ? userAddress.last_name : billingInfo.lastname;
    billingInfo.name = this.phaseService.billingInfo.firstname + (this.phaseService.billingInfo.lastname ? ' ' + this.phaseService.billingInfo.lastname : '');
    billingInfo.address = this.phaseService.billingInfo.apartment + (this.phaseService.billingInfo.street ? ' ' + this.phaseService.billingInfo.street : '');
    billingInfo.email = this.dataService.user.email;
    return this.phaseService.billingInfo;
  }

  onBuilderCareClick(buildCard) {
    this.dataService.buildCardData = buildCard;
    this.apiRequest.createBuilderCareSupportInstallment(buildCard.id).subscribe(
      (data) => {
        if (data) {
          this.builderCareInstallment.id = data.id;
          this.builderCareInstallment.totalAmount = data.total_amount;
          this.builderCareInstallment.taxAmount = data.tax_amount;
          this.getBuilderCareClicked = true;
        }
      },
      (error) => {
        console.log('error- ', error);
      }
    );
  }

  getPaymentAmount() {
    return this.builderCareInstallment.totalAmount;
  }

  getPaymentAmountWithoutTax() {
    return this.builderCareInstallment.totalAmount - this.builderCareInstallment.taxAmount;
  }

  getTaxAmount() {
    return this.builderCareInstallment.taxAmount;
  }

  getPaymentId() {
    return this.builderCareInstallment.id;
  }

  onScroll(cardCount: number) {
    this.showDummyLoader = true;
    if (this.showMoreIndex !== 0 && window.pageYOffset > 0) {
      if (this.dataService.loadMore) {
        if (this.dataService.isScrolled) {
          if (cardCount > 10) {
            let pageNo = 0;
            if (this.phaseService.buildCardPage[this.showMoreIndex]) {
              pageNo = (this.phaseService.buildCardPage[this.showMoreIndex] > 0) ? this.phaseService.buildCardPage[this.showMoreIndex] : 0;
            }
            this.loaderCount = (((pageNo + 1) * 10) >= cardCount) ? 0 : (4 - (((pageNo + 1) * 10) % 4));
          }
          this.dataService.isScrolled = false;
          this.phaseService.buildCardPage[this.showMoreIndex]++;
          this.apiRequest.fetchBuildCards(this.phaseService.buildCardPage[this.showMoreIndex],
            this.phaseService.perPageCardCount, this.phaseService.currentSelectedBuildCardStatus).pipe(
            finalize(() => {
              this.loaderCount = 0;
            }))
            .subscribe(
              () => {
                this.dataService.isScrolled = true;
              }
            );
        }
      }
    }
  }

  redirectToLive(card) {
    if (this.dataService.cookie_domain && this.dataService.cookie_domain === 'localhost') {
      window.location.href = `http://localhost:5000/#/project-progress/${card.uniq_code}/home`;
    } else {
      window.location.href = `${this.dataService.homePageData.builder_partner.builder_live_url}/#/project-progress/${card.uniq_code}/home`;
    }
  }

  getBuildCardsWithSearch(clearSearch?) {
    let perPage;
    if (clearSearch) {
      this.dataService.searchText = '';
    } else {
      perPage = this.phaseService.perPageCardCount;
    }
    this.phaseService.buildCardPage[this.showMoreIndex] = 0;
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    this.isApiSuccess = false;
    this.dataService.completedProjectCards = [];
    this.searchSubscription = this.apiRequest.fetchBuildCards(0, perPage, this.phaseService.currentSelectedBuildCardStatus).pipe(
      finalize(() => {
        this.loaderCount = 0;
      }))
      .subscribe(
        () => {
          this.dataService.isScrolled = true;
          this.isApiSuccess = true;
        }
      );
  }

  cloneBuildCard(uniqueCode, currency) {
    if (this.dataService.checkIfCPEUser()) {
      this.commonService.isDuplicateCardFlow = true;
      this.commonService.duplicateCardData = { uniQueCode: uniqueCode, currency: currency };
    } else {
      this.phaseService.cloneBuildCard(uniqueCode, currency);
    }
  }

  checkCareType(card) {
    if (card.is_under_support && !card.care_type) {
      return this.dataService.checkIfEnterpriseUserOrCard() ? 'enterprise_care_plus' : 'standard_care';
    } else {
      return card.care_type;
    }
  }

  viewProgress(card) {
    this.phaseService.currentCardInProgress = card;
    this.dataService.buildCardData = card;
    const liveUrl = this.dataService.homePageData.builder_partner.builder_live_url;
    if (liveUrl === undefined || liveUrl === null) {
      return true;
    }
    if (this.dataService.cookie_domain && this.dataService.cookie_domain === 'localhost') {
      window.location.href = `http://localhost:5000/#/project-progress/${card.uniq_code}/home`;
    } else {
      window.location.href = `${liveUrl}/#/project-progress/${card.uniq_code}/home`;
    }
  }

}
