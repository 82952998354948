import { Injectable } from "@angular/core";
import { DataService } from "@shared/services/data.service";
import { PhaseService } from "@shared/services/phase.service";
import { ApiRequests } from "@shared/services/api-requests.service";
import { CommonService } from "@shared/services/common.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { checkVAT, countries } from 'jsvat';

@Injectable({
  providedIn: "root",
})
export class TaxDetailsService {
  checkBoxValues = {
    isGstChecked: false,
    isPanChecked: false,
    isTanChecked: false,
    isVatChecked: false,
    isTinChecked: false,
    isTDSGSTChecked: false,
    isSalesTaxChecked: false,
    isZeroGstChecked: false,
  };
  taxDetailsForm: FormGroup;
  isCountryCOdeInVAT = false;
  showInvalidVatError = false;
  documentFiles = { sez_doc: {} };
  isContinueClicked = false;
  fileUploadingError = false;
  businessCategory = "business";
  isShowTaxDetailsOnBillingPage = false;

  constructor(
    public dataService: DataService,
    public phaseService: PhaseService,
    public apiRequest: ApiRequests,
    private commonService: CommonService
  ) {}

  get gstNumber() {
    return this.taxDetailsForm.get("gst_number");
  }

  get vatNumber() {
    return this.taxDetailsForm.get("vat_number");
  }

  get panNumber() {
    return this.taxDetailsForm.get("pan_number");
  }

  get tanNumber() {
    return this.taxDetailsForm.get("tan_number");
  }

  get gstTdsNumber() {
    return this.taxDetailsForm.get("gst_tds_number");
  }

  get tinNumber() {
    return this.taxDetailsForm.get("tin_number");
  }

  get regTYpe() {
    return this.taxDetailsForm.get("registration_type");
  }

  get salesTaxNumber() {
    return this.taxDetailsForm.get("sales_tax_number");
  }

  validateFormFields() {
    const checkBox = this.checkBoxValues;
    let address = this.dataService.user.address;
    const isCompany = ((address && (address.billed_as === 'company')) || (this.phaseService.billingInfo.billed_as === 'company')),
      isINR = (this.dataService.user.currency.code === 'INR');
    if (this.checkIfCardAbove2Lacs() && !this.panNumber.value) {
      return false;
    }
    if (this.checkIfUKOwningEntity() && checkBox.isVatChecked) {
      const country = address? address.country : this.phaseService.billingInfo.country;
      let vatInfo;
      if (country !== 'United Kingdom (UK)') {
        vatInfo = checkVAT((this.getCountryCodeFromAvailableCountries() + this.vatNumber.value), countries);
      } else {
        vatInfo = checkVAT(this.vatNumber.value, countries);
      }
      if ((country !== 'United Kingdom (UK)') && vatInfo.isSupportedCountry && this.vatNumber.value.includes(this.getCountryCodeFromAvailableCountries())) {
        this.isCountryCOdeInVAT = true;
        return false;
      }
      if (vatInfo && vatInfo.isSupportedCountry && !vatInfo.isValid) {
        this.showInvalidVatError = true;
        return false;
      }
      this.validateUKVATNumber();
    }
    this.isContinueClicked = true;
    if (isINR && (this.checkFieldValidation('isGstChecked', this.gstNumber) ||
      this.checkFieldValidation('isPanChecked', this.panNumber) || this.checkFieldValidation('isTDSGSTChecked', this.gstTdsNumber) ||
      (checkBox.isGstChecked && !this.regTYpe.value) || this.checkFieldValidation('isTanChecked', this.tanNumber) || (checkBox.isZeroGstChecked &&
      ((this.regTYpe.value === 'SEZ') && checkBox.isZeroGstChecked && !this.documentFiles.sez_doc['file'])))) {
      return false;
    } else if (this.checkIfUKOwningEntity() && ((this.checkFieldValidation('isVatChecked', this.vatNumber) ||
        this.checkFieldValidation('isTinChecked', this.tinNumber)))) {
      return false;
    } else if (!this.checkIfUKOwningEntity() && !isINR && (this.checkFieldValidation('isSalesTaxChecked', this.salesTaxNumber) ||
      this.checkFieldValidation('isTinChecked', this.tinNumber))) {
      return false;
    }
    if (isINR) {
      this.taxDetailsForm.value['zero_gst'] = this.checkBoxValues.isZeroGstChecked;
    }
    return true;
  }

  validateUKVATNumber() {
    this.apiRequest.checkUKVAT(this.vatNumber.value).subscribe(resp => {
      console.log(resp);
    }, error => {
      console.log(error);
    });
  }

  checkFieldValidation(checkBoxKey, field) {
    const checkBox = this.checkBoxValues;
    return (checkBox[checkBoxKey] && (!field.value || field.invalid));
  }

  checkIfTaxDetailsFieldNotPresent() {
    const checkBoxes = this.checkBoxValues;
    if ((checkBoxes.isGstChecked && !this.gstNumber.value) || (checkBoxes.isPanChecked && !this.panNumber.value) ||
      (checkBoxes.isTinChecked && !this.tinNumber.value) || (checkBoxes.isVatChecked && !this.vatNumber.value) ||
      (checkBoxes.isTanChecked && !this.tanNumber.value) || (checkBoxes.isTDSGSTChecked && !this.gstTdsNumber.value)) {
        return true;
    }
    return false;
  }

  checkIfCardAbove2Lacs() {
    const paidCards = this.dataService.paidCards,
      isINR = (this.dataService.user.currency.code === 'INR');
    if (isINR) {
      if (paidCards.length && (paidCards[0].status === 'started') && (paidCards[0].bcard_discounted_price > 200000)) {
        return true;
      } else if ((!paidCards.length || (paidCards[0].status !== 'started')) && this.dataService.completedCards.length && (this.dataService.completedCards[0].bcard_discounted_price > 200000)) {
        return true;
      }
    }
  }

  getCountryCodeFromAvailableCountries() {
    let address = this.dataService.user.address;
    const country = address? address.country : this.phaseService.billingInfo.country;
    let availableCountries = this.dataService.userCurrencyAndCountries.available_country;
    if (availableCountries && (availableCountries.length > 0)) {
      return availableCountries.find(ctry => ctry.name.toLowerCase() === country.toLowerCase()).code;
    }
    return '';
  }

  createPayload() {
    const formValue = this.taxDetailsForm.value;
    const taxDetails = {
      user: {
        address_attributes: {
          id: this.dataService.user.address?.id,
          gst_number: formValue.gst_number,
          vat_number: formValue.vat_number,
          pan_number: formValue.pan_number,
          tan_number: formValue.tan_number,
          tin_number: formValue.tin_number,
          registration_type: formValue.gst_number ? formValue.registration_type : 'Unregistered',
          sez_doc: this.documentFiles.sez_doc,
          zero_gst: formValue.zero_gst,
          sales_tax_number: formValue.sales_tax_number,
          gst_tds_number: formValue.gst_tds_number,
          tax_details_disabled: true,
          customer_business_category: (this.checkIfUKOwningEntity() && !this.checkBoxValues.isVatChecked && !this.checkBoxValues.isTinChecked) ? this.businessCategory : 'business'
        },
      }
    };
    const objectKeys = taxDetails['user']['address_attributes'];
    if (this.dataService.user.currency.code !== 'INR') {
      if (this.checkIfUKOwningEntity()) {
        objectKeys.registration_type = formValue.vat_number ? 'Registered' : 'Unregistered';
      } else if (this.checkIfDMCCOwningEntity()) {
        objectKeys.registration_type = formValue.sales_tax_number ? 'Registered' : 'Unregistered';
      } else {
        delete objectKeys.registration_type;
      }
      delete objectKeys.zero_gst;
    }
    if (!this.checkIfUKOwningEntity()) {
      delete objectKeys.customer_business_category;
    }
    for (const key of Object.keys(objectKeys)) {
      if ((objectKeys[key] === '') || ((typeof objectKeys[key] === 'object') && !Object.keys(objectKeys[key]).length)) {
        delete objectKeys[key];
      }
    }
    if (!this.checkBoxValues.isGstChecked) {
      objectKeys.gst_number = '';
    }
    return taxDetails;
  }

  checkIfUKOwningEntity() {
    if (this.dataService.user.address) {
      return (this.dataService.user && (this.dataService.user.billing_entity === 'UK_OWNING_ENTITY'));
    } else {
      let owningEntity = this.getOwningEntityOfSelectedCountry();
      return (owningEntity === 'UK_OWNING_ENTITY');
    }
  }

  checkIfDMCCOwningEntity() {
    if (this.dataService.user.address) {
      return (this.dataService.user && (this.dataService.user.billing_entity === 'DMCC_OWNING_ENTITY'));
    } else {
      let owningEntity = this.getOwningEntityOfSelectedCountry();
      return (owningEntity === 'DMCC_OWNING_ENTITY');
    }
  }

  getOwningEntityOfSelectedCountry() {
    let country = this.phaseService.billingInfo.country;
    let availableCountries = this.dataService.userCurrencyAndCountries.available_country;
    if (availableCountries && (availableCountries.length > 0)) {
      let countryFound = availableCountries.find(ctry => ctry.name.toLowerCase() === country.toLowerCase());
      if (countryFound) {
        return countryFound.owning_entity;
      }
    }
    return null;
  }

}
