<!--payment status page start-->
<div class="paymentArea" *ngIf="getPaymentShowStatus() && dataService.loadFilteredData">
  <button class="paybackButton" (click)="exitPaymentStatus()"><em class="icon-prev"></em> Back</button>
  <div class="paymentTop">
    <h2>{{getPaymentBuildCard().project_name}}</h2>
    <p *ngIf="getPaymentBuildCard().description">{{getPaymentBuildCard().description}}</p>
    <h3>Totals</h3>
  </div>
  <div class="paystatusRow">
      <div class="paystatusBox">
        <ng-container *ngIf="getPaymentBuildCard().frequency === 'monthly'; else weeklyDuration">
          <span>Total Amount ({{getPaymentBuildCard().installment_counts?.monthly}} {{ getPaymentBuildCard().installment_counts?.monthly > 1 ? 'months' : 'month' }})</span>
        </ng-container>
        <ng-template #weeklyDuration>
          <span>Total Amount ({{getPaymentBuildCard().weeks}} Weeks)</span>
        </ng-template>
        {{getCurrencySymbol()}}{{getLocaledPrice(getTotalWithTax() | number: '1.2-2')}}
      </div>

      <div class="paystatusBox">
        <span>Amount already paid</span>
        {{getCurrencySymbol()}}{{getLocaledPrice(getTotalAmountPaid() | number: '1.2-2')}}
      </div>

      <div class="paystatusBox blueBg">
        <span>Balance Amount</span>
        {{getCurrencySymbol()}}{{getLocaledPrice(getBalanceAmount() | number: '1.2-2')}}
      </div>

      <div class="paystatusBox" *ngIf="getSupportMaintenance()">
        <span>{{ showCareType(getPaymentBuildCard()?.care_type) }}</span>
        {{getCurrencySymbol()}}{{getLocaledPrice(getInstallmentWithTax(getSupportMaintenance()) | number : '1.2-2')}}
      </div>
  </div>

  <div class="paymentTable">
    <h3>Installments breakdown</h3>
    <ng-container *ngIf="innerWidth >= 992">
      <table summary="Installments breakdown">
        <tr>
          <th>Installments</th>
          <th>Amount</th>
          <th>Payable date</th>
          <th>Paid On</th>
          <th>Status</th>
          <th>Invoice</th>
          <th>Action</th>
        </tr>
        <tr *ngFor="let installment of getPaymentData().installments; let i = index">
          <td>{{installment.type === 'deposit' ? 'Deposit' : i + 1}}

            <div class="securityDeposit" *ngIf="installment.type === 'deposit'">
              <em class="icon-info-circle"></em>
              <div class="securityDepositText">
                This will be credited back to you at the end of your Build card
                <ng-container> ({{getPaymentBuildCard().deposit_weeks}} week payment)</ng-container>
              </div>
            </div>

          </td>
          <td>
            {{getCurrencySymbol()}}{{getLocaledPrice(getInstallmentWithTax(installment) | number : '1.2-2')}}
          </td>
          <td>{{installment.due_at | amDateFormat: 'DD-MMM-YYYY'}}</td>
          <td>{{installment.paid_at ? (installment.paid_at | amDateFormat: 'DD-MMM-YYYY') : '-'}}</td>
          <td class="paid" *ngIf="installment.status === 'paid'"><em class="icon-tick"></em> Paid</td>
          <td class="pending" *ngIf="installment.status === 'pending' ">-</td>
          <td *ngIf="installment.status === 'pay_now' ">--</td>
          <td>
            <ng-container *ngIf="installment?.invoice || installment?.receipt">
              <em class="icon-download" (click)="downloadInvoiceReceipt(installment)"></em>
              <em class="icon-view" (click)="handleInvoiceReceipt(installment)"></em>
            </ng-container>
            <ng-container *ngIf="!installment?.invoice && !installment?.receipt">--</ng-container>
          </td>
          <td *ngIf="installment.status !== 'pay_now'">
            --
          </td>
          <td *ngIf="installment.status === 'pay_now' && user.currency?.code === 'INR'">
            <button type="button" class="paynowButton" (click)="gotoBilling(installment)">Pay now</button>
          </td>
        </tr>
      </table>
    </ng-container>

    <ng-container *ngIf="innerWidth <= 991">
      <div class="mobileViewBox" *ngFor="let installment of getPaymentData().installments; let i = index">
        <ul>
          <li class="boxHeader">
            <div class="dataColumn"><strong>Installment</strong></div>
            <div class="dataColumn"><strong>{{installment.type === 'deposit' ? 'Deposit' : i + 1}}</strong>

              <div class="securityDeposit" *ngIf="installment.type === 'deposit'">
                <em class="icon-info-circle"></em>
                <div class="securityDepositText">
                  This will be credited back to you at the end of your Build card
                  <ng-container> ({{getPaymentBuildCard().deposit_weeks}} week payment)</ng-container>
                </div>
              </div>

            </div>
          </li>
          <li>
            <div class="dataColumn">Amount</div>
            <div class="dataColumn">{{getCurrencySymbol()}}{{getLocaledPrice(getInstallmentWithTax(installment) | number : '1.2-2')}}</div>
          </li>
          <li>
            <div class="dataColumn">Payable date</div>
            <div class="dataColumn">{{installment.due_at | amDateFormat: 'DD-MMM-YYYY'}}</div>
          </li>
          <li>
            <div class="dataColumn">Paid on</div>
            <div class="dataColumn">{{installment.paid_at ? (installment.paid_at | amDateFormat: 'DD-MMM-YYYY') : '-'}}</div>
          </li>
          <li>
            <div class="dataColumn">Status</div>
            <div class="dataColumn paid" *ngIf="installment.status === 'paid'"><em class="icon-tick"></em> Paid</div>
            <div class="dataColumn pending" *ngIf="installment.status === 'pending' ">-</div>
            <div class="dataColumn" *ngIf="installment.status === 'pay_now' ">--</div>
          </li>
          <li>
            <div class="dataColumn">Invoice</div>
            <div class="dataColumn">
              <ng-container *ngIf="installment?.invoice || installment?.receipt">
                <em class="icon-download" (click)="downloadInvoiceReceipt(installment)"></em>
                <em class="icon-view" (click)="handleInvoiceReceipt(installment)"></em>
              </ng-container>
              <ng-container *ngIf="!installment?.invoice && !installment?.receipt">--</ng-container>
            </div>
          </li>
          <li>
            <div class="dataColumn">Action</div>
            <div class="dataColumn" *ngIf="installment.status !== 'pay_now'">--</div>
            <div class="dataColumn" *ngIf="installment.status === 'pay_now' && user.currency?.code === 'INR'">
            <button type="button" class="paynowButton" (click)="gotoBilling(installment)">Pay now</button>
            </div>
          </li>
        </ul>
      </div>
    </ng-container>

  </div>

</div>
