<div class="commonPopUp active noAnimation">
    <div class="popOverlay"></div>
    <div class="popHolder useBuilderPointsPopup">
      <div class="closePopUp" (click)="closeUseCreditedReferralPointsPopup()"><em class="icon-cancel"></em></div>

      <div class="sectionBox">
          <div class="sectionHeading">
              <h3>Use Builder Points</h3>
          </div>

          <div class="sectionText">
              <p>{{popupContent}} </p>
          </div>

          <div class="greyBox">
              <div class="sliderSection">
                <div class="walletPointSlider">
                  <ngx-slider [(value)]="sliderValue"
                  [options]="sliderOptions"
                  (userChangeEnd)="onSliderValueChange()">
                  </ngx-slider>
                </div>
                  <p>Equivalent to <strong>{{dataService.user?.currency?.symbol}}{{referralService.builderAmountEquivalentToReferralPointsUsed ? dataService.getLocaledPrice(referralService.builderAmountEquivalentToReferralPointsUsed) : dataService.getLocaledPrice(dataService.buildCardData?.total_amount)}}</strong></p>
              </div>
          </div>

          <div class="footerButtons">
            <div class="leftSide">
              <p>Save points for later</p>

              <div class="tooltipInfo">
                <em class="icon-info-circle"></em>
                <div class="tooltipContent">
                  Any points not used now will be saved in your wallet, located in your dashboard. From there you can use points at any time to reduce your payments.
                </div>
              </div>

              <div class="toggleButton" [ngClass]="{'active': shouldSaveForLater}" (click)="toggleSaveForLater()"></div>
            </div>
            <div class="rightSide">
                <button class="confirmButton" type="button" *ngIf="showSaveInWalletCTA" (click)="useCreditedBuilderPoints(true)">Save in wallet</button>
                <button class="confirmButton" type="button" *ngIf="!showSaveInWalletCTA" (click)="useCreditedBuilderPoints(false)">Use points</button>
            </div>
          </div>

      </div>

    </div>
  </div>
