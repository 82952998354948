import { Component, OnInit } from '@angular/core';
import {DataService} from '@shared/services/data.service';

@Component({
  selector: 'app-tnc-store',
  templateUrl: './tnc-store.component.html',
  styleUrls: ['./tnc-store.component.scss']
})
export class TncStoreComponent implements OnInit {

  constructor(private dataService: DataService) {
    this.dataService.currentPage = 'tncStore';
  }

  ngOnInit(): void {
  }

}
