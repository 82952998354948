import {Component, Input, OnInit} from '@angular/core';
import {RecalculatePhaseDataService} from '@shared/services/recalculate-phase-data.service';
import {DragulaService} from 'ng2-dragula';
import {Angulartics2Segment} from 'angulartics2/segment';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {PhaseModel} from '@models/phase-model/phase.model';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import { Intercom } from 'ng-intercom';
import {SpeedModel} from '@models/speed-model/speed.model';
import {CommonService} from '@shared/services/common.service';
import moment from 'moment';
import {DeliveryService} from '@featuremodules/delivery-module/services/delivery.service';
import {FeatureModel} from '@models/feature-model/feature.model';
import { Options } from '@angular-slider/ngx-slider';

const FULLBUILD = 'Full Build';
const MVP = 'MVP';
const ISTAILORMADE = 'is_tailor_made';

@Component({
  selector: 'advanced-phase-card',
  templateUrl: 'advanced-phase-card.component.html',
  styleUrls: ['advanced-phase-card.component.scss']
})

export class AdvancedPhaseCardComponent implements OnInit {
  @Input() phase: PhaseModel;
  @Input() index: number;
  @Input() advancedVersion: boolean;
  @Input() isForMvp: boolean;
  allPlatformsShown = {};
  isSpeedChanged: boolean;
  PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
  };
  openPlatform = false;
  showFeaturesList: boolean;
  editableFeatureList: boolean;
  selectedSpeed: SpeedModel;
  showDropDown = false;
  deliveryDate: any;
  public selectedFeatures: FeatureModel[];
  barValue = 4;
  options: Options = {
    showSelectionBar: true,
    stepsArray: [
      {value: 4},
      {value: 3},
      {value: 1},
      {value: 5},
      {value: 2}
    ]
  };
  optionsWithoutRelax: Options = {
    showSelectionBar: true,
    stepsArray: [
      {value: 3},
      {value: 1},
      {value: 5},
      {value: 2}
    ]
  };
  moment: any;
  showPrototypePhaseList = false;

  constructor(
    public dataService: DataService,
    private dragulaService: DragulaService,
    public analyticsSegment: Angulartics2Segment,

    public phaseService: PhaseService,
    private recalculatePhaseDataService: RecalculatePhaseDataService,
    private commonService: CommonService,
    private deliveryService: DeliveryService
  ) {
    this.moment = moment;
  }

  public ngOnInit() {
    this.selectedSpeed = this.phaseService.selectedSpeed;
    if (this.phase['type'] === 'custom_phase') {
      if (!this.phase.features.length && this.phase.selected) {
        this.deliveryService.setDefaultFeatureInCustomPhase(this.phase);
      }
      if (!this.phaseService.buildCardEditMode) {
        this.deliveryService.setMvpSelected(this.phaseService.selectedFeatures, this.phase);
        this.phase.features.forEach(feature => {
          feature['phaseId'] = [];
          this.phase.platforms.forEach(platform => {
            feature['phaseId'].push(this.phase.id + '' + platform.id);
            this.phaseService.selectedFeatures.forEach(ftr => {
              if (ftr.id === feature.id) {
                ftr['phaseId'] = [];
                ftr['phaseId'].push(this.phase.id + '' + platform.id);
              }
            });
          });
        });
        this.phase.features.forEach(feature => feature.platforms = this.phase.platforms);
      }
      return;
    }
    this.setFeaturesForPhases();
    // this.setDataInRapidPrototype();
  }

  checkIfDisableSpeed() {
    if (!this.dataService.user.enterprise_lead || !this.dataService.checkIfBuildCardisinvited()) {
      return false;
    }
    const buildCardMultiplier = this.dataService.buildCardData.multiplier_experiment;
    if ((buildCardMultiplier && buildCardMultiplier.is_default) || !buildCardMultiplier) {
      return this.dataService.homePageData.multiplier_experiment.relaxed_mode_disable;
    } else {
      return buildCardMultiplier.relaxed_mode_disable;
    }
  }

  public showAllPlatforms(phase) {
    this.allPlatformsShown[phase.title] = true;
  }

  public showLimitedPlatforms(phase) {
    this.allPlatformsShown[phase.title] = false;
  }

  public openFeatureSlider(phase) {
    if (phase.selected) {
      if (!this.phaseService.setMvpSelected && (phase.title === 'MVP') && (this.phaseService.mandatoryFeatures.length > 0) && ((this.phaseService.mandatoryFeatures.length === phase.features.length)) && !this.phaseService.buildCardEditMode) {
        phase.features = [];
        const mandatoryFeatureIds = this.phaseService.mandatoryFeatures.map(feature => feature.id);
        phase.features = this.phaseService.selectedFeatures.filter((feature) => {
          if (mandatoryFeatureIds.indexOf(feature.id) > -1) {
            feature.mvpSelected = true;
            return feature;
          }
        });
        // this.phase.features.forEach(feature => feature.platforms = this.phase.platforms);
        this.phaseService.setMvpSelected = true;
        this.phaseService.mandatoryFeatures.forEach((feature) => {
          const findIndex = phase.features.findIndex(phaseFeature => phaseFeature.id === feature.id);
          if (findIndex > -1) {
            feature.selected = true;
          } else {
            feature.selected = false;
          }
        });
      } else if ((phase.title === 'MVP') || (phase['type'] === 'custom_phase')) {
        this.deliveryService.setMvpSelected(this.phaseService.selectedFeatures, phase);
        const phaseFeatureIds = phase.features.map(feature => feature.id);
        this.phaseService.selectedFeatures.filter((feature) => {
          if (phaseFeatureIds.indexOf(feature.id) > -1) {
            feature.mvpSelected = true;
            return feature;
          } else {
            feature.mvpSelected = false;
          }
        });
      }
      this.showFeaturesList = true;
      this.editableFeatureList = (phase.title === MVP) || (phase['type'] === 'custom_phase');
    }
  }

  public changeSpeed(speed, phase) {
    this.dataService.homePageData.speed.filter(s => {
      if (s.id === speed) {
        speed = s;
      }
    });
    this.selectedSpeed = speed;
    if (phase.selected) {
      this.showDropDown = false;
      this.recalculatePhaseDataService.propagateSpeedDataToPhases(this.selectedSpeed.id, phase);
      this.phaseService.updatePrice();
    }
  }

  public openPlatformEditPopup(phase) {
    if (phase.selected) {
      this.openPlatform = true;
    }
  }

  public onPlatformDrag(event) {
  }

  public showLearnMore(dataIndex: number, infoForWhichPrototype?): void {
    if (infoForWhichPrototype) {
      this.phaseService.infoForWhichPrototype = infoForWhichPrototype;
    }
    this.phaseService.learnMoreIndex = dataIndex;
    this.phaseService.showLearnMore = true;
    this.phaseService.trackEventForObject(this.analyticsSegment, "phase tooltip viewed", {
      user_id : this.dataService.user?.id,
      phase_tootltip_selected : this.phase.title
    });
  }

  public assignSpeedToAllPhases(event) {

    this.dataService.homePageData.build_phases.forEach(( phaseItem) => {
      if (phaseItem.selected) {
        phaseItem['isChecked'] = true;
      }
    });
    this.phase['isChecked'] = true;
    if (event.target.checked) {
      this.phaseService.assignSameSpeed = true;
      this.recalculatePhaseDataService.propagateSpeedDataToPhases(this.phase.speed.id);
    }

    this.phaseService.updatePrice();
  }

  public removeCheckbox() {
    let isUncheckAllButton: boolean = true;
    const buildPhaseLength = this.dataService.homePageData.build_phases.length;
    for (let i = 0; i <  buildPhaseLength; i++) {
      if ( this.dataService.homePageData.build_phases[i].selected && !this.dataService.homePageData.build_phases[i]['isChecked'] ) {
        isUncheckAllButton =  false;
      }
    }
    if (isUncheckAllButton) {
      this.dataService.homePageData.build_phases.forEach(( phaseItem) => {
        phaseItem['isChecked'] = false;
      });
    }
  }

  public togglePhaseSelection(index, event, phaseName?) {
    if (!this.selectedFeatures) {
      this.selectedFeatures = [...this.phaseService.selectedFeatures];
    }
    if (this.dataService.homePageData.build_phases[index].title === 'Design') {
      if (!this.phaseService.checkIfMvpOrFullBuildSelected()) {
        event.preventDefault();
        this.dataService.homePageData.build_phases[index].selected = false;
        let error = {
          error: {
            message: 'You need to select MVP or Full build to select designs'
          }
        };
        this.commonService.showError(error);
        return;
      }
    }
    if (event.target.checked) {
      if (this.phase.title === 'MVP') {
        const pFeatures = this.phase.features.map(f => f.id);
        this.selectedFeatures.forEach((f, index) => {
          if (pFeatures.indexOf(f.id) === -1) {
            this.phase.features.splice(index, 1);
          }
        });
      }
      if (index === 3) {
        this.dataService.homePageData.build_phases.forEach(phase => {
          if ((phase['type'] === 'custom_phase') && (!phase.platforms || (phase.platforms.length === 0))) { phase.features = []; }
        });
      }
      this.selectPhase(this.dataService.homePageData.build_phases[index]);
      if (!this.phase.features) { this.phase.features = []; }
      if ((this.phase.features.length === 0) && (this.phase['type']) === 'custom_phase') {
        this.deliveryService.setDefaultFeatureInCustomPhase(this.phase);
        this.deliveryService.setMvpSelected(this.phaseService.selectedFeatures, this.phase);
      } else if ((this.phase.features.length === 0) && !this.phase.title.includes('MVP')) {
        this.phase.features.push(this.phaseService.selectedFeatures[0]);
      } else if ((this.phase.features.length === 0) && (this.phaseService.mandatoryFeatures.length > 0) && (this.dataService.homePageData.build_phases.length === 5)) {
        this.phase.features = [];
        const mandatoryFeatureIds = this.phaseService.mandatoryFeatures.map(feature => feature.id);
        this.phase.features.push(...this.phaseService.selectedFeatures.filter((feature) => {
          if (mandatoryFeatureIds.indexOf(feature.id) > -1) {
            return feature;
          }
        }));
        this.phase.features.forEach(feature => {
          feature['mvpSelected'] = true;
          feature['phaseId'] = [];
          this.phase.platforms.forEach(platform => {
            if (!feature['phaseId']) { feature['phaseId'] = []; }
            if (!feature['platforms']) { feature['platforms'] = []; }
            feature['phaseId'].push(this.phase.id + '' + platform.id);
            feature.platforms.push(platform);
          });
        });
      } else if ((this.phase.features.length === 0) && ((this.dataService.homePageData.build_phases.length > 5) || (this.phaseService.mandatoryFeatures.length === 0)) && this.phase.title.includes('MVP')) {
        for (const feature of this.phaseService.selectedFeatures) {
          if ((!feature.phaseId || feature.phaseId.length === 0) && (!feature.platforms || feature.platforms.length === 0)) {
            this.phase.features.push(feature);
            break;
          }
        }
        if (this.phase.features && this.phase.features.length > 0) {
          this.phase.features[0].mvpSelected = true;
        }
        if (this.phase.platforms && this.phase.platforms.length > 0) {
          this.phase.features[0].phaseId = [];
          this.phase.features[0].phaseId.push(this.phase.id + '' + this.phase.platforms[0].id);
          this.phase.features[0].platforms = Object.assign([], this.phase.platforms);
        }
      } else if (this.phaseService.buildCardEditMode && (this.phase.features.length > 0) && (this.phase.title === 'MVP')) {
        this.phase.features.forEach(feature => {
          feature.mvpSelected = true;
        });
      }

      if (!this.phase.platforms || this.phase.platforms.length === 0) {
        this.phase.platforms = [];
        if (this.phaseService.selectedHash.is_advance) {
          this.phase.platforms.push(this.phaseService.selectedPlatforms[0]);
        } else {
          if (this.phase.title.includes(MVP) || (this.phase['type'] === 'custom_phase')) {
            this.phase.platforms.push(this.phaseService.selectedPlatforms[0]);
          } else {
            this.phase.platforms.push(...this.phaseService.selectedPlatforms);
          }
        }
        if (this.phase.title.includes(MVP) || (this.phase['type'] === 'custom_phase')) {
          // this.phase.features[0].platforms = this.phase.platforms;
          this.phase.features.forEach(f => {
            f.platforms = this.phase.platforms;
          });
          this.phase.features.forEach(feature => {
            feature['phaseId'] = [];
            this.phase.platforms.forEach(platform => {
              feature['phaseId'].push(this.phase.id + '' + platform.id);
              this.phaseService.selectedFeatures.forEach(ftr => {
                if (ftr.id === feature.id) {
                  if (!ftr['phaseId'] || ftr['phaseId'].length === 0) { ftr['phaseId'] = []; }
                  ftr['phaseId'].push(this.phase.id + '' + platform.id);
                }
              });
            });
          });
        }
      }

      if (!this.phase.speed || (this.phase.speed.id !== this.phaseService.selectedSpeed.id)) {
        this.phase.speed = this.phaseService.selectedSpeed;
      }

      this.phase.is_parallel_platform = true;
      this.phaseService.trackEventForObject(this.analyticsSegment, "phase_selected", {
        user_id : this.dataService.user?.id,
        phase_selected : this.phase.title
      });
      if (this.phaseService.isAdvancedVersion && this.phase.platforms && this.phase.platforms.length > 0) {
        const whichPage = (this.phase.title !== 'Product Roadmap') && (this.phase.title !== 'Full Build');
        const buildPhase = this.dataService.homePageData.build_phases;
        if (whichPage && buildPhase[4].selected) {
          this.phase.platforms = JSON.parse(JSON.stringify(buildPhase[4].platforms));
        } else if (whichPage && buildPhase[3].selected) {
          this.phase.platforms = JSON.parse(JSON.stringify(buildPhase[3].platforms));
        }
        if (this.phase.title === 'Full Build' && buildPhase[3].selected) {
          this.phase.platforms = JSON.parse(JSON.stringify(buildPhase[3].platforms));
        } else if ((this.phase.title === 'MVP') && !buildPhase[4].selected) {
          this.phase.platforms = JSON.parse(JSON.stringify(buildPhase[4].platforms));
        }
      }
    } else {
      this.deSelectPhase(this.dataService.homePageData.build_phases[index]);
      let platformsIds = [];
      const allPlatforms = [];
      if ((this.dataService.homePageData.build_phases[index].title === 'MVP') || (this.dataService.homePageData.build_phases[index]['type'] === 'custom_phase')) {
        this.dataService.homePageData.build_phases[index].features = JSON.parse(JSON.stringify(this.dataService.homePageData.build_phases[index].features));
        this.dataService.homePageData.build_phases[index].features.forEach(feature => {
          if (!feature.phaseId) { feature.phaseId = []; }
          platformsIds = this.dataService.homePageData.build_phases[index].platforms.map(p => p.id);
          feature.platforms.forEach(pl => {
            if (allPlatforms.indexOf(pl.id) === -1) {
              allPlatforms.push(pl.id);
            }
          });
          feature.phaseId = [];
          allPlatforms.forEach(() => {
            this.phaseService.selectedFeatures.forEach(ftr => {
              if (feature.id === ftr.id) {
                if (!ftr.platforms) { ftr.platforms = []; }
                if (!ftr.phaseId) { ftr.phaseId = []; }
                ftr.platforms.forEach((platform, i) => {
                  if ((platformsIds.indexOf(platform.id) !== -1) && this.checkForSpliceData(ftr, index, platform.id)) {
                    // ftr = JSON.parse(JSON.stringify(ftr));
                    ftr.platforms.splice(i, 1);
                    ftr.phaseId.splice(ftr.phaseId.findIndex(p => (p === (this.dataService.homePageData.build_phases[index].id + '' + platform.id))), 1);
                  }
                });
              }
            });
          });
        });
        this.dataService.homePageData.build_phases[index].features = [];
        this.dataService.homePageData.build_phases[index].platforms = [];
      }
      if (this.phaseService.isAdvancedVersion && (this.phase.title === 'Full Build') && !this.dataService.homePageData.build_phases[4].selected) {
        this.dataService.homePageData.build_phases[3].platforms = JSON.parse(JSON.stringify(this.dataService.homePageData.build_phases[4].platforms));
      }
      this.phase.is_parallel_platform = true;
    }
    if (!this.checkIfDesignSelected() && this.dataService.homePageData.build_phases[index].selected && (phaseName === 'Tailor-made Prototype')) {
      this.dataService.homePageData.build_phases[2][ISTAILORMADE] = true;
    } else if (this.checkIfDesignSelected() || (phaseName === 'Professional Prototype')) {
      this.dataService.homePageData.build_phases[2][ISTAILORMADE] = false;
    }

    if (!this.phaseService.checkIfMvpOrFullBuildSelected()) {
      let phase = this.dataService.homePageData.build_phases.find(phase => phase.title === 'Design');
      this.deSelectPhase(phase);
      this.phase.is_parallel_platform = true;
    }

    this.setDataInRapidPrototype();

    this.provideBuilderCareBasedOnPhases();
    if (this.phaseService.checkIfBuildCardForRapidPrototype()) {
      this.deliveryService.removePromotion();
    }
    this.phaseService.updatePrice();
  }

  checkForSpliceData(ftr, index, platformId) {
    const dataTobeDeleted = [];
    ftr.phaseId.forEach(phaseId => {
      if ((phaseId.charAt(0) === this.dataService.homePageData.build_phases[index].id.toString()) && (phaseId.charAt(1) === platformId.toString())) {
        dataTobeDeleted.push(phaseId.charAt(0));
      }
    });
    return dataTobeDeleted.length ? true : false;
  }


  public deSelectPhase(phase, event?) {
    phase.selected = false;
    this.phaseService.removeSelectedBuildId(phase.index);
    this.phaseService.removeSelectedBuildIds(phase.index);
    this.phaseService.removeSelectedBuild(phase);
  }

  public selectPhase(phase, event?) {
    phase.selected = true;
    this.phaseService.addSelectedBuildId(phase.index);
    this.phaseService.addSelectedBuildIds(phase.index);
    this.phaseService.addSelectedBuild(phase);
    this.phaseService.sortPhases();
  }

  public getSelectedFeatureCount() {
    return this.phase.features.length;
  }

  closePlatformPopup() {
    this.openPlatform = false;
  }

  handleCallBack($event) {
    if ($event) {
      this.deSelectPhase($event);
    } else {
      this.showFeaturesList = false;
    }
  }

  showMvpPhaseSelected() {
    const phase = this.phaseService.selectedPhases.find(item => item.title === 'MVP');
    if (phase && !this.phaseService.mandatoryFeatures.length) {
      this.deSelectPhase(phase);
    }
    for (const phaseData of this.phaseService.selectedPhases) {
      if ((phaseData.title === MVP) || (phaseData.title === FULLBUILD)) {
        return true;
      }
    }
    return false;
  }

  getFinalDeliveryDate() {
    return this.phaseService.getFinalDeliveryDate();
  }

  payForFirsDeliveryOnly(event) {
    const phase = this.dataService.homePageData.build_phases.find(fullBuild => fullBuild.title === FULLBUILD);
    if (event.target.checked) {
      this.deSelectPhase(phase, event);
    } else {
      this.selectPhase(phase, event);
    }
    this.phaseService.updatePrice();
  }

  openDropdown() {
    this.showDropDown = !this.showDropDown;
  }

  getFirstDeliveryPrice() {
    if (!this.phaseService.phaseData) { return; }
    let firstDevileryPrice = 0, deliveryTime = 0;
    this.phaseService.phaseData.forEach((phase) => {
      if (phase.phase_name !== 'full_build') {
        firstDevileryPrice += phase.price;
        deliveryTime += phase.delivery_time;
      }
    });
    const date = new Date();
    this.deliveryDate = this.moment(date).add(deliveryTime, 'days').format('LL');
    return this.dataService.getLocaledPrice(firstDevileryPrice);
  }

  getCurrency() {
    const buildCardStatus = this.phaseService.buildCardEditMode || this.dataService.isIncompleteCardEditMode || this.phaseService.isFeatureRequest;
    if (this.dataService.homePageData && this.dataService.homePageData.currency) {
      return buildCardStatus ? this.dataService.buildCardData.currency.symbol : this.dataService.homePageData.currency.symbol;
    } else if (this.dataService.user && this.dataService.user.currency) {
      return buildCardStatus ? this.dataService.buildCardData.currency.symbol : this.dataService.user.currency.symbol;
    }
  }

  getSelectedSpeed(speed) {
    this.selectedSpeed = speed;
  }

  public setFeaturesForPhases() {
    if ((this.phase.title !== 'MVP') && (this.phase['type'] !== 'custom_phase')) {
      this.phase.features = [...this.phaseService.selectedFeatures];
    } else {
      this.phase.features.forEach(f => f.mvpSelected = true);
      this.setFeaturesInMvp();
      if (!this.phaseService.buildCardEditMode && !this.dataService.shareUrlData) {
        this.phase.features.forEach(feature => feature.platforms = this.phase.platforms);
        this.phase.features.forEach(feature => {
          feature['phaseId'] = [];
          this.phase.platforms.forEach(platform => {
            feature['phaseId'].push(this.phase.id + '' + platform.id);
            this.phaseService.selectedFeatures.forEach(ftr => {
              if (ftr.id === feature.id) {
                if (!ftr['phaseId'] || ftr['phaseId'].length === 0) { ftr['phaseId'] = []; }
                ftr['phaseId'].push(this.phase.id + '' + platform.id);
              }
            });
          });
        });
      }
    }
  }

  public setFeaturesInMvp() {
    this.selectedFeatures = [...this.phaseService.selectedFeatures];

    if (!this.phaseService.buildCardEditMode) {
      if (!this.checkMvpSelected(this.phase)) {
        this.phase.features = [];
        this.selectedFeatures.forEach(feature => {
          if (this.phase.title === 'MVP') {
            feature.mvpSelected = false;
          }
        });
        this.handleCallBack(this.phase);
      }
    } else {
      if (this.selectedFeatures.length > 0) {
        this.deliveryService.setMvpSelected(this.selectedFeatures, this.phase);
      } else {
        this.selectedFeatures = [...this.phaseService.selectedFeatures];
        this.selectedFeatures.forEach(feature => {
          feature.mvpSelected = false;
        });
        this.phase.features = [];
      }
    }
  }

  public checkMvpSelected(phase) {
    let count = 0;
    if (phase.features) {
      phase.features.forEach(feature => {
        if (feature.mvpSelected) {
          count++;
        }
      });
      return count > 0;
    }

  }

  public provideBuilderCareBasedOnPhases() {
    this.phaseService.selectedPhases = this.dataService.homePageData.build_phases.filter(phase => phase.selected);
    const mvp = this.dataService.homePageData.build_phases.find(
      phase => phase.title === 'MVP'
    );
    const fullBuild = this.dataService.homePageData.build_phases.find(
      phase => phase.title === 'Full Build'
    );
    const customPhase = this.dataService.homePageData.build_phases.find(phase => (phase['type'] === 'custom_phase'));
    if ((mvp && mvp.selected) || (fullBuild && fullBuild.selected) || (customPhase && customPhase.selected)) {
      this.phaseService.selectedHash.is_under_support = true;
      this.phaseService.isBuilderCloudChecked = true;
      this.phaseService.selectedHash.cloud_opted = true;
    } else {
      this.phaseService.selectedHash.is_under_support = false;
      this.phaseService.isBuilderCloudChecked = false;
      this.phaseService.selectedHash.cloud_opted = false;
    }
  }

  checkIfSelectedPlatformsAreDevices() {
    const devices = this.phaseService.selectedPlatformsForRapidPrototype.map(p => p.title);
    const areDevices = [];
    this.phaseService.selectedPlatforms.forEach(platform => {
      if (devices.indexOf(platform.title) !== -1) {
        areDevices.push(platform.title);
      }
    });
    return areDevices.length ? true : false;
  }

  setDataInRapidPrototype() {
    const allSpeeds = this.dataService.homePageData.speed;
    const phase = this.dataService.homePageData.build_phases.find(phase => phase.title === 'Professional Prototype');
    if (this.phaseService.checkIfOnlyRapidPrototypeSelected()) {
      if (phase.is_tailor_made && this.phaseService.selectedPlatformsForRapidPrototype && this.phaseService.selectedPlatformsForRapidPrototype.length > 0) {
        phase.platforms = this.phaseService.selectedPlatformsForRapidPrototype;
      } else if (this.checkIfSelectedPlatformsAreDevices()) {
        phase.platforms = [this.dataService.homePageData.platforms[0]];
      } else {
        phase.platforms = this.phaseService.selectedPlatforms;
      }
      if (phase.is_tailor_made) {
        this.phaseService.selectedSpeed = allSpeeds.find(speed => (speed.title === 'Standard'));
      } else if (this.dataService.checkIfEnterpriseUserOrCard()) {
        const speedForEnt = this.phaseService.setSpeedForMultiplier();
        this.phaseService.selectedSpeed = this.dataService.homePageData.speed.find(speed => speed.id === speedForEnt);
      } else if (this.dataService.checkIfEnterpriseUserOrCard() || this.dataService.checkIfCardWithUsd()) {
        this.phaseService.selectedSpeed = allSpeeds.find(speed => (speed.title === 'Standard'));
      } else if (this.dataService.buildCardData.progress.speed && !this.dataService.buildCardData.progress.buildcardphases[0]['is_tailor_made']) {
        this.phaseService.selectedSpeed = allSpeeds.find(speed => (speed.id === this.dataService.buildCardData.progress.speed['id']));
      } else {
        this.phaseService.selectedSpeed = allSpeeds.find(speed => (speed.title === 'Relaxed'));
      }
      phase.speed = this.phaseService.selectedSpeed;
    }
    this.phaseService.selectedHash.speed_id = this.phaseService.selectedSpeed.id;
  }

  checkIfCustomPhaseWithPlatforms() {
    return (this.phase['type'] === 'custom_phase') && !(this.phase.platforms && this.phase.platforms.length);
  }

  showToaterMessage() {
    this.commonService.showError({ error: { message: 'Please select platforms.' } });
  }

  showPrototypePhases() {
    this.showPrototypePhaseList = !this.showPrototypePhaseList;
  }

  checkTitleOfSelectedPhase(phase, phaseToHide) {
    return (phase.title !== phaseToHide);
  }

  setPhaseFromDropDown(index, phaseName) {
    const prototypePhase = this.dataService.homePageData.build_phases[index];
    if ((phaseName === 'Tailor-made Prototype') && !prototypePhase.selected) {
      prototypePhase[ISTAILORMADE] = true;
    }
  }

  setPrototypePhaseName(phase) {
    return phase.is_tailor_made ? 'Tailor-made Prototype' : 'Professional Prototype';
  }

  checkIfDesignSelected() {
    return this.dataService.homePageData.build_phases.find(phase => (phase.title === 'Design')).selected;
  }

}
