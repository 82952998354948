import { Component, OnInit } from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {AppDataService} from '@rootmodule/app-data.service';
import {DataService} from '@shared/services/data.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Intercom} from 'ng-intercom';

@Component({
  selector: 'app-card-status-selection',
  templateUrl: './card-status-selection.component.html',
  styleUrls: ['./card-status-selection.component.css']
})

export class CardStatusSelectionComponent implements OnInit {
  continueForFreeCtaClicked = true;


  constructor(public phaseService: PhaseService, public appDataService: AppDataService, public dataService: DataService,
    private apiRequests: ApiRequests, private analyticsSegment: Angulartics2Segment) { }

    ngOnInit(): void {
      this.phaseService.cardStatusSelected = false;
      this.phaseService.getCloudDetails();
    }

    buildCardStatus(SelectedStatus){
      this.phaseService.buildCardUpdated = true;
      this.phaseService.proceedClickedFromRental = true;
      if(SelectedStatus == 'freemium'){

        this.continueForFreeCtaClicked = true;
      const buildCardData = this.dataService.buildCardData;
        this.apiRequests.convertToFreemium(buildCardData,this.dataService.buildCardData.uniq_code).subscribe((data: any) => {
          this.phaseService.proceedClickedFromRental = false;
          this.phaseService.fetchInstallments();
          this.phaseService.handleNextPrevForRentalFlow();
          this.phaseService.IsFromFreemium = true;
          this.phaseService.cardStatusSelected = true;
        });
        this.phaseService.trackEventForObject(this.analyticsSegment,  'freemium_continue_with_free_CTA_clicked', {
         user_id : this.dataService.user?.id
         });
      }
      if(SelectedStatus == 'studio_store'){
        this.continueForFreeCtaClicked = false;
        this.dataService.buildCardData.cloud_price = this.phaseService.selectedHash.cloud_price;
        const buildCardData = this.dataService.buildCardData;
        this.apiRequests.convertToStudioStore(buildCardData,this.dataService.buildCardData.uniq_code).subscribe((data: any) => {
          this.phaseService.proceedClickedFromRental = false;
          this.phaseService.fetchInstallments();
          this.phaseService.handleNextPrevForRentalFlow();
          this.phaseService.IsFromFreemium = false;
          this.phaseService.cardStatusSelected = true;
        });
        this.phaseService.trackEventForObject(this.analyticsSegment,  'freemium_upgrade_my_store_CTA_clicked', {
          user_id : this.dataService.user?.id
        });
      }
    }
  }
