<div class="dashHeading"> Additional Feature Request </div>
<div class="dashProjectRow">
  <div class="progressCard" *ngFor="let card of dataService.changeFeatureRequestArrayModel; let i=index">
    <div class="cardHead">
      <div class="cardLeft w100">
        <h3>{{card.build_card.project_name}}</h3>
        <h4> Last edited: {{ card.requested_at }} </h4>
      </div>
    </div>
    <div class="cardMiddle">
      <div class="completeDetail">
        <div class="completeRow">
          <div class="completeBox">
            <p> No of new features
              <span>{{ card.features?.length }}</span>
            </p>
          </div>
          <div class="completeBox">
            <p>Duration
              <ng-container *ngIf="card.build_card.frequency === 'monthly'; else showWeekly">
                 <span>{{getMonths(card)}} {{ getMonths(card) > 1 ? 'months' : 'month'}}
                ({{ card.weeks }}
              {{ card.weeks > 1 ? 'weeks' : 'week'}})</span>
              </ng-container>
              <ng-template #showWeekly>
                <span>{{ card.weeks }}
              {{ card.weeks > 1 ? 'weeks' : 'week'}}</span>
              </ng-template>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="cardBottom">
      <div class="priceTag" >
        <div> {{ card.build_card.currency.symbol }}{{getLocaledPrice((card.price)| number : '1.2-2')}} </div>
      </div>
      <button (click)="openBuildCard(card, i)">View <img *ngIf="selectedIndex === i" src="./../../../assets/images/buttonLoader.gif" alt="" /></button>
      <div class="clearfix"></div>
    </div>
  </div>
</div>
