<!-----NDA start-->
<div class="commonPopUp active" *ngIf="!rentalService.isAddressSelected" (click)="hideDropDown()">
  <div class="popOverlay"></div>
  <div class="popHolder ndaBlock ndaBlock2">
    <!---- if height scrollable the then add class 'scrollFix'  -->
    <div class="new-CloseButton" (click)="hideMeetModel()"> <em class="icon-cancel"></em>
    </div>
    <div class="mainBx">
      <!-- login left section start -->
      <div class="loginLeft">
        <!-- <div class="titletxt">
          <h3>
            <span>Sign NDA</span>
          </h3>
        </div> -->

        <!--- web -->
        <img class="onlyDesktop" src="../../../../assets/images/signin_up_desktop.png" alt="" />
        <!--- mobile -->
        <!-- <img class="onlyMobile" src="../../../../assets/images/newsignuppopup.png" alt="" /> -->
      </div>
      <!--login left section close-->

      <!--login right section start-->
      <div class="loginRight" [ngClass]="{'fixBottonAction': isShowManualAddress}">
        <!--start---->
        <div class="mobileImg">
          <!--- mobile -->
          <img class="onlyMobile" src="../../../../assets/images/newsignuppopup.png" alt="" />
        </div>
        <!----end---->
        <!--- test for shoe only web -->
        <div class="titletxt2">
          <h3>
            <span>Sign NDA</span>
          </h3>
          <p>The Customer and Builder.ai are considering entering into a contract for the provision of sofware building
            services. In advance of doing so the parties intend to exchange information that may be sensitive to their
            business. The non-disclosure agreement is entered into in contemplation of that exchange of information.
          </p>
        </div>

        <!--- normal sign up -->
        <div class="formElements signUpBx">
          <!--- input field ---->
          <ul>
            <li>
              <label>Do you want to sign the NDA as an Individual or as a company (Including llc, partnership firm or legal entity)?<sup>*</sup></label>
              <div class="radioButtonRow">
                <div class="radioButtonBox" [ngClass]="{'active': !billingService.isCompanyAddress}">
                  <input type="radio" name="individualbusiness" id="individual" [checked]="!billingService.isCompanyAddress"
                         (change)="changeAddressType('individual')" />
                  <label for="individual">Individual</label>
                </div>

                <div class="radioButtonBox" [ngClass]="{'active': billingService.isCompanyAddress}">
                  <input type="radio" name="individualbusiness" id="business" [checked]="billingService.isCompanyAddress"
                         (change)="changeAddressType('company')" />
                  <label for="business">Company</label>
                </div>
              </div>
            </li>
            <li *ngIf="billingService.isCompanyAddress">
              <label>Company name<sup>*</sup></label>
              <input type="text" placeholder="Enter your company name" [(ngModel)]="getBillingInfo().companyName" />
              <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().companyName">Please enter valid company name</div>
            </li>
            <li [ngClass]="{'disable-billing-form': (dataService.user.address_disabled)}">
              <ul class="userEditDetail">
                <li>
                  <label>First Name<sup>*</sup></label>
                  <input type="text" [(ngModel)]="getBillingInfo().firstname" (click)="hideDropDown()">
                  <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().firstname">Please enter valid first name</div>
                </li>
                <li>
                  <label>Last Name<sup>*</sup></label>
                  <input type="text" [(ngModel)]="getBillingInfo().lastname" (click)="hideDropDown()">
                  <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().lastname">Please enter valid last name</div>
                </li>
                <li><label>Phone Number<sup>*</sup></label>
                  <app-phone-number [contactNumber]="getBillingInfo().contact" [isSaveButtonClicked]="isSaveButtonClicked"
                                    (setContactInUserInfo)="setUserContact($event)" (closeCountryStateDropDown)="hideDropDown()">
                  </app-phone-number>
                </li>
                <li><label>Email Address<sup>*</sup></label>
                  <input type="text" [(ngModel)]="getBillingInfo().email" (click)="hideDropDown()">
                  <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().email">Please enter valid email</div>
                </li>
                <li>
                  <label>Apartment Number</label>
                  <input type="text" [(ngModel)]="getBillingInfo().apartment">
                </li>
                <li>
                  <label>Address Line 1<sup>*</sup></label>
                  <input type="text" [(ngModel)]="getBillingInfo().street" (click)="hideDropDown()">
                  <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().street">Please enter valid address</div>
                </li>
                <li>
                  <label>Address Line 2</label>
                  <input type="text" [(ngModel)]="getBillingInfo().address_line_2" (click)="hideDropDown()">
                </li>
                <li *ngIf="!billingService.checkIfUSCustomer()">
                  <label>City<sup>*</sup></label>
                  <input type="text" [(ngModel)]="getBillingInfo().city" (click)="hideDropDown()">
                  <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().city">Please enter valid city</div>
                </li>
                <li *ngIf="billingService.checkIfUSCustomer()">
                  <label>City<sup>*</sup></label>
                  <div class="customDropdown" [ngClass]="{'active' : showCityDropDown}">
                    <input id="cityBox" type="text" name="city" placeholder="Please select a city"
                           [(ngModel)]="getBillingInfo().city" required #city="ngModel" maxlength="100" minlength="1"
                           [ngClass]="{'errorInput': isSaveButtonClicked && (!getBillingInfo().city || city.invalid)}"
                           [disabled]="(dataService.user.address_disabled && phaseService.addressSubmitted)"
                           (keyup)="handleBackSpace($event)" (focus)="showCityDropdown()" >
                    <input type="text" #searchTextCity name="searchcity" #searchcity="ngModel" [(ngModel)]="searchedText" class="hiddenInput">
                    <div class="dropdownList" *ngIf="showCityDropDown">
                      <ul>
                        <li id="cityBox" *ngFor="let city of renderCitiesInDropdown() | searchStates: searchedText"
                            (click)="selectCity(city)">{{ city?.name ? city.name : city }}</li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li *ngIf="billingService.ifNotUkOrCyprus()">
                  <label>State<sup>*</sup></label>
                  <input type="text"  [(ngModel)]="getBillingInfo().state" (keyup)="ShowDropdown()" (focus)="showStatesList('states', $event)" (click)="$event.stopPropagation()"  [disabled]="!getBillingInfo().country">
                  <ul class="countryDropdown" *ngIf="showStateDropDown">
                    <li *ngFor="let state of billingService.selectedStates | searchCountries: getBillingInfo().state" (click)="selectState(state)">{{ state.name }}</li>
                  </ul>
                  <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().state">Please enter valid state</div>

                </li>
                <li><label>Country<sup>*</sup></label>
                  <input type="text" [(ngModel)]="getBillingInfo().country" (focus)="showCountriesList($event)" (click)="$event.stopPropagation()">
                  <ul class="countryDropdown" *ngIf="showCountryDropDown">
                    <li *ngFor="let country of dataService.userCurrencyAndCountries?.available_country | searchCountries: getBillingInfo().country"
                      (click)="selectCountry(country)">{{ country.name }}</li>
                  </ul>
                  <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().country && !showCountryDropDown">Please enter valid country</div>
                </li>
                <li><label>Postal Code/Zip/Pin<sup>*</sup></label>
                  <input type="text" [(ngModel)]="getBillingInfo().pincode" (click)="hideDropDown()">
                  <div class="errorMsg" *ngIf="isSaveButtonClicked && (!getBillingInfo().pincode)">Please enter valid Zip/Postal Code</div>
                </li>
              </ul>

            </li>
            <li>
              <p class="mb-40">By clicking “I agree”, you warrant that you are duly authorised, and understand and agree to all of the above
                terms without exception. </p>
            </li>
          </ul>
          <div class="footersection">
            <div class="actionbtn">
              <button type="submit" class="doneButton" (click)="updateAddressSignNDAandProceedForScheduler()">I agree</button>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>

<app-address-pop-up *ngIf="rentalService.isAddressSelected"> </app-address-pop-up>
<!--    NDA END-->
