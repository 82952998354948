<div class="contractSection">
  <div class="topSection">
    <ul>
      <li *ngIf="!appDataService.urlParameters.is_freemium">
        <label class="appName">App name (Max 30 characters)
          <em class="icon-info-circle" (click)="toggleTooltip()"></em>
          <div class="discriptionBlock" *ngIf="isShowTooltip">This is what your app will be called in the <strong>GooglePlayStore</strong> and <strong>AppleAppStore</strong>. But don't worry, you can change it later.</div>
           <div class="tooltipOverlay" *ngIf="isShowTooltip" (click)="toggleTooltip()"></div>
        </label>
        <input type="text" placeholder='Like "{{placeholderProjectName}}"' maxlength="30" [(ngModel)]="projectName"
               (keypress)="acceptAlphaNumeric($event)" (keyup)="hideErrors()">
        <div class="errorMsg" *ngIf="isDuplicateProjectName">
          A Buildcard already exists with the same name. Please choose another name in order to proceed.
        </div>
        <div class="errorMsg" *ngIf="isProjectNameWithHyphen">
          Please remove hyphens(-) from start and end of project name.
        </div>
      </li>

      <li *ngIf="!dataService.user.address_disabled || dataService.user?.address?.billed_as"
          [ngClass]="{'disable-billing-form': (dataService.user.address_disabled)}">
        <label>Do you want to be billed as an individual or as a company (including llc, partnership firm or legal entity)?<sup>*</sup></label>
        <div class="radioButtonRow">
          <div class="radioButtonBox" [ngClass]="{'active': !billingService.isCompanyAddress}">
            <input type="radio" name="individualbusiness1" id="individual1"
                   [checked]="!billingService.isCompanyAddress"/>
            <label for="individual1" (click)="changeAddressType('individual')">
              Individual
            </label>
          </div>
          <div class="radioButtonBox" [ngClass]="{'active': billingService.isCompanyAddress}">
            <input type="radio" name="individualbusiness1" id="business1"
                   [checked]="billingService.isCompanyAddress"/>
            <label for="business1" (click)="changeAddressType('company')">
              Company
            </label>
          </div>
        </div>
      </li>
      <li [ngClass]="{'disable-billing-form': (dataService.user.address_disabled)}"
          *ngIf="billingService.isCompanyAddress">
        <label>Company name<sup>*</sup></label>
        <input type="text" placeholder="Enter your company name" [(ngModel)]="getBillingInfo().companyName"
               minlength="1" maxlength="50"/>
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().companyName">Please enter valid company name</div>
      </li>
    </ul>
    <!-- additional form  -->
    <ul class="userEditDetail" [ngClass]="{'disable-billing-form': (dataService.user.address_disabled)}">
      <li>
        <label>First Name<sup>*</sup></label>
        <input type="text" [(ngModel)]="getBillingInfo().firstname" (click)="hideDropDown()">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().firstname">Please enter valid first name</div>
      </li>
      <li>
        <label>Last Name<sup>*</sup></label>
        <input type="text" [(ngModel)]="getBillingInfo().lastname" (click)="hideDropDown()">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().lastname">Please enter valid last name</div>
      </li>
      <li><label>Phone Number<sup>*</sup></label>
        <app-phone-number [contactNumber]="getBillingInfo().contact" [isSaveButtonClicked]="isSaveButtonClicked"
                          (setContactInUserInfo)="setUserContact($event)" (closeCountryStateDropDown)="hideDropDown()">
        </app-phone-number>
      </li>
      <li><label>Email Address<sup>*</sup></label>
        <input type="text" [(ngModel)]="getBillingInfo().email" (click)="hideDropDown()">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().email">Please enter valid email</div>
      </li>
      <li>
        <label>Apartment Number</label>
        <input type="text" [(ngModel)]="getBillingInfo().apartment">
      </li>
      <li>
        <label>Address Line 1<sup>*</sup></label>
        <input type="text" [(ngModel)]="getBillingInfo().street" (click)="hideDropDown()">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().street">Please enter valid address</div>
      </li>
      <li>
        <label>Address Line 2</label>
        <input type="text" [(ngModel)]="getBillingInfo().address_line_2" (click)="hideDropDown()">
      </li>
      <li *ngIf="!billingService.checkIfUSCustomer()">
        <label>City<sup>*</sup></label>
        <input type="text" [(ngModel)]="getBillingInfo().city" (click)="hideDropDown()">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().city">Please enter valid city</div>
      </li>
      <li *ngIf="billingService.checkIfUSCustomer()">
        <label>City<sup>*</sup></label>
        <div class="customDropdown" [ngClass]="{'active' : showCityDropDown}">
          <input id="cityBox" type="text" name="city" placeholder="Please select a city"
                 [(ngModel)]="getBillingInfo().city" required #city="ngModel" maxlength="100" minlength="1"
                 [ngClass]="{'errorInput': isSaveButtonClicked && (!getBillingInfo().city || city.invalid)}"
                 [disabled]="(dataService.user.address_disabled && phaseService.addressSubmitted)"
                 (keyup)="handleBackSpace($event)" (focus)="showCityDropdown()" >
          <input type="text" #searchTextCity name="searchcity" #searchcity="ngModel" [(ngModel)]="searchedText" class="hiddenInput">
          <div class="dropdownList" *ngIf="showCityDropDown">
            <ul>
              <li id="cityBox" *ngFor="let city of renderCitiesInDropdown() | searchStates: searchedText"
                  (click)="selectCity(city)">{{ city?.name ? city.name : city }}</li>
            </ul>
          </div>
        </div>
      </li>
      <li *ngIf="billingService.ifNotUkOrCyprus()">
        <label>State<sup>*</sup></label>
        <input type="text"  [(ngModel)]="getBillingInfo().state" (keyup)="ShowDropdown()" (focus)="showStatesList('states', $event)" (click)="$event.stopPropagation()"  [disabled]="!getBillingInfo().country">
        <ul class="countryDropdown" *ngIf="showStateDropDown">
          <li *ngFor="let state of billingService.selectedStates | searchCountries: getBillingInfo().state" (click)="selectState(state)">{{ state.name }}</li>
        </ul>
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().state">Please enter valid state</div>

      </li>
      <li><label>Country<sup>*</sup></label>
        <input type="text" [(ngModel)]="getBillingInfo().country" (focus)="showCountriesList($event)" (click)="$event.stopPropagation()">
        <ul class="countryDropdown" *ngIf="showCountryDropDown">
          <li *ngFor="let country of dataService.userCurrencyAndCountries?.available_country | searchCountries: getBillingInfo().country"
              (click)="selectCountry(country)">{{ country.name }}</li>
        </ul>
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().country && !showCountryDropDown">Please enter valid country</div>
      </li>
      <li><label>Postal Code/Zip/Pin<sup>*</sup></label>
        <input type="text" [(ngModel)]="getBillingInfo().pincode" (click)="hideDropDown()">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && (!getBillingInfo().pincode)">Please enter valid Zip/Postal Code</div>
      </li>

      <li *ngIf="billingService.showGSTIN">
        <label>GST number</label>
        <input type="text" name="gstNumber" [(ngModel)]="getBillingInfo().gstNumber"  #gstNumber="ngModel" minlength="1"
          maxlength="100" placeholder="eg. 06ABCDE1234F2Z5" pattern="^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$"
          (click)="hideDropDown()" />
         <span class="errorMsg" *ngIf="isSaveButtonClicked && (getBillingInfo().gstNumber && gstNumber.invalid)">Please enter valid GST number</span>
      </li>
    </ul>

    <app-tax-details [isFromBillingDetails] = 'showTaxDetailsOnStoreBilling'></app-tax-details>

    <div class="termsandConditions">
      <h3>Terms & Conditions</h3>
      <div class="listBox">
        <div class="tncRow" (click)="termsAndConditionsClicked($event)">
          <input type="checkbox" (change)="termsAndConditionsClicked($event)" id="t&c"
                 [checked]="termsConditionsChecked">
          <label for="t&c"></label>
          <div class="tncText" (click)="openPoliciesLink($event)">Check here to indicate you have read and agreed to the terms of the
            <span data-page-name="TnC">Terms and Conditions, </span>
            <span data-page-name="PRIVACY_POLICY">Privacy Policy </span>and
            <span data-page-name="CUSTOMER_POLICY">Customer Policy</span>.
          </div>
        </div>
      </div>
      <!-- <div class="listBox">
        <p (click)="termsAndConditionsClicked('pointOne', $event)">
          <input type="checkbox" (change)="termsAndConditionsClicked('pointOne', $event)" [checked]="termsConditions.pointOne" id="pointone">          <label for="pointone" class="icon-right"></label> I understand that my project(s) ("Buildcard™") is built on
          a framework of potential or existing reusable features, designs, workflows and templates; and this will be licensed
          to me on a non exclusive basis upon my final payment; I will also be provided a copy of the source code. I agree
          and understand Builder.ai will be able to use any of its potential or existing reusable parts for other customers.</p>
        <p (click)="termsAndConditionsClicked('pointTwo', $event)">
          <input type="checkbox" (change)="termsAndConditionsClicked('pointTwo', $event)" [checked]="termsConditions.pointTwo" id="pointtwo">          <label for="pointtwo" class="icon-right"></label> I understand that whatever is explicitly defined and/or billed
          for as customizations (design, source code, business logic, workflows or custom features) in my Buildcard™ will
          remain my intellectual property and be assigned to me exclusively upon payment.</p>
        <p (click)="termsAndConditionsClicked('pointThree', $event)">
          <input type="checkbox" (change)="termsAndConditionsClicked('pointThree', $event)" [checked]="termsConditions.pointThree"
            id="pointthree"> <label for="pointthree" class="icon-right"></label> I understand that any timelines
          given are indicative. Builder.ai will do whatever is reasonably necessary to achieve them but does not guarantee
          them unless otherwise provided for in writing. I understand that my timely response to questions and approvals
          is essential to a speedy completion of my Buildcard™. Time spent in the design approval phase is not included
          in timescale calculation and it will also be necessary to use Builder.ai’s approved logistics and payment gateway
          partners for timescales to be met.</p>
        <p (click)="termsAndConditionsClicked('pointFour', $event)">
          <input type="checkbox" (change)="termsAndConditionsClicked('pointFour', $event)" [checked]="termsConditions.pointFour" id="pointfour">          <label for="pointfour" class="icon-right"></label> I understand that Builder will retain all control of the Intellectual
          Property except any data entered by me or my company until all contracted payments have been made unless otherwise
          agreed with Builder.ai in writing. I agree that Builder.ai can delete my data if I do not make payments for 14
          days from any due date.</p>
        <p (click)="termsAndConditionsClicked('pointFive', $event)">
          <input type="checkbox" (change)="termsAndConditionsClicked('pointFive', $event)" [checked]="termsConditions.pointFive" id="pointfive">          <label for="pointfive" class="icon-right"></label> I understand the fixed price for Builder Studio Store is subject
          to a fair usage policy.</p>
        <p (click)="termsAndConditionsClicked('pointSix', $event)">
          <input (change)="termsAndConditionsClicked('pointSix', $event)" type="checkbox" [checked]="termsConditions.pointSix" id="pointsix">          <label for="pointsix" class="icon-right"></label> I understand and agree to the terms and conditions as outlined
          in the Builder.ai (Engineer.ai's) <span (click)="openMSADocLink()" class="greenbdr">Master User Terms & License Agreement</span>.
          I understand and agree to the mutual non disclosure clauses within the agreement.</p>
      </div> -->


    </div>
  </div>

  <div class="bottomsection">
    <button type="button" class="buttonStyle" [ngClass]="{'custom-disabled': (!checkValidity() || (this.dataService.user.roles === 'CPE') || phaseService.proceedClickedFromRental || !this.dataService.IsGstValid || !checkIfTaxDetailsValid())}"
      (click)="updateAddressSignNDAandProceedForPayment()">Agree to Terms & Conditions to proceed
  <img *ngIf="phaseService.proceedClickedFromRental" src="../../../../../../../assets/images/buttonLoader.gif" alt=""/>
  </button>
  </div>

</div>

<div class="contractSection hide">
  <div class="sectionDotloader">
    <div class="dot-flashing"></div>
  </div>
</div>


<div class="commonPopUp active" *ngIf="isAddressSelected">
  <div class="popOverlay" (click)="closeModal()"></div>
  <div class="popHolder contractformPopup">
    <div class="closePopUp" (click)="closeModal()"><span class="icon-cancel"></span></div>
    <ul>
      <li><label>First Name*</label> <input type="text" [(ngModel)]="getBillingInfo().firstname" (click)="hideDropDown()">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().firstname">Please enter valid first name</div>
      </li>
      <li><label>Last Name*</label> <input type="text" [(ngModel)]="getBillingInfo().lastname" (click)="hideDropDown()">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().lastname">Please enter valid last name</div>
      </li>
      <li><label>Phone Number*</label>
        <app-phone-number [contactNumber]="getBillingInfo().contact" [isSaveButtonClicked]="isSaveButtonClicked"
                          (setContactInUserInfo)="setUserContact($event)" (closeCountryStateDropDown)="hideDropDown()">
        </app-phone-number>
        <!--<international-phone-number #mobileNumber="ngModel" name="mobileNumber" (keyup)="onFlagChange()" placeholder="Mobile Number"
            [(ngModel)]="getBillingInfo().contact" [maxlength]="20" [defaultCountry]="phaseService.countryNameCode" required
            (click)="hideDropDown()">
          </international-phone-number>
          <div class="errorMsg" *ngIf="mobileNumber.invalid && mobileNumber.touched">Please enter valid contact</div>-->
      </li>
      <li><label>Company Name</label> <input type="text" [(ngModel)]="getBillingInfo().companyName" minlength="1" maxlength="50"
          (click)="hideDropDown()">
      </li>
      <li><label>Country*</label> <input type="text" [(ngModel)]="getBillingInfo().country" (focus)="showCountriesList()">
        <ul class="countryDropdown" *ngIf="showCountryDropDown">
          <li *ngFor="let country of dataService.userCurrencyAndCountries?.available_country | searchCountries: getBillingInfo().country"
            (click)="selectCountry(country)">{{ country.name }}</li>
        </ul>
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().country && !showCountryDropDown">Please enter valid country</div>
      </li>
      <li><label>Zip/Postal Code*</label> <input type="text" [(ngModel)]="getBillingInfo().pincode" (click)="hideDropDown()">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && (!getBillingInfo().pincode)">Please enter valid Zip/Postal Code</div>
      </li>
      <li *ngIf="billingService.ifNotUkOrCyprus()"><label>State/Territory/Region*</label> <input type="text" [(ngModel)]="getBillingInfo().state" (keyup)="ShowDropdown()" (focus)="showStatesList('states')"  [disabled]="!getBillingInfo().country">
        <ul class="countryDropdown" *ngIf="showStateDropDown">
            <li *ngFor="let state of selectedStates | searchCountries: getBillingInfo().state" (click)="selectState(state)">{{ state.name }}</li>
          </ul>
          <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().state">Please enter valid state</div>
      </li>
      <li><label>City*</label> <input type="text" [(ngModel)]="getBillingInfo().city" (click)="hideDropDown()">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().city">Please enter valid city</div>
        <div class="errorMsg" *ngIf="isSaveButtonClicked && getBillingInfo().city && getBillingInfo().city.length < 3">City should be minimum of 3 chars long</div>
      </li>

      <li><label>Address*</label> <input type="text" [(ngModel)]="getBillingInfo().street" (click)="hideDropDown()">
        <div class="errorMsg" *ngIf="isSaveButtonClicked && !getBillingInfo().street">Please enter valid address</div>
        <div class="errorMsg" *ngIf="isSaveButtonClicked && getBillingInfo().street && getBillingInfo().street.length < 3">Address should be minimum of 3 chars long</div>
      </li>
      <li><label>Apartment/Unit Number (optional)</label>
        <input type="text" minlength="0" [(ngModel)]="getBillingInfo().apartment" (click)="hideDropDown()">
      </li>

      <li *ngIf="billingService.showGSTIN">
        <label>GST number</label>
        <input type="text" name="gstNumber" [(ngModel)]="getBillingInfo().gstNumber" required #gstNumber="ngModel" minlength="1"
          maxlength="100" [ngClass]="{'errorInput': isSaveButtonClicked && (!getBillingInfo().gstNumber || gstNumber.invalid)}"
          placeholder="eg. 06ABCDE1234F2Z5" (click)="hideDropDown()" pattern="^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$">
        <!-- <div class="errorMsg" *ngIf="isSaveButtonClicked && (!getBillingInfo().gstNumber || gstNumber.invalid)">Please enter valid GST number</div> -->
      </li>
    </ul>

    <button class="buttonStyle" (click)="submitAddress()">Done</button>
  </div>
</div>
