<div class="dummyGridStructure">
    <div class="templateDummyCard">
        <div class="header">
            <div class="title"></div>
            <div class="brandicon"></div>
        </div>
        <div class="middle">
            <div class="featureImg">
                <img src="../../../../../assets/images/templateDummyMain-img.png" class="dummyLoader" alt="" />
            </div>
            <div class="featureDetail">
                <span class="heading"></span>
                <div class="subheading"></div>
            </div>
        </div>
        <div class="footer">
            <div class="title"></div>
            <div class="actionbx">
                <button type="button" class="morebtn"></button>
            </div>
        </div>
    </div>
    <div class="templateDummyCard">
            <div class="header">
                <div class="title"></div>
                <div class="brandicon"></div>
            </div>
            <div class="middle">
                <div class="featureImg">
                    <img src="../../../../../assets/images/templateDummyMain-img.png" class="dummyLoader" alt="">
                </div>
                <div class="featureDetail">
                    <span class="heading"></span>
                    <div class="subheading"></div>
                </div>
            </div>
            <div class="footer">
                <div class="title"></div>
                <div class="actionbx">
                    <button type="button" class="morebtn"></button>
                </div>
            </div>
        </div>
        <div class="templateDummyCard">
                <div class="header">
                    <div class="title"></div>
                    <div class="brandicon"></div>
                </div>
                <div class="middle">
                    <div class="featureImg">
                        <img src="../../../../../assets/images/templateDummyMain-img.png" class="dummyLoader" alt="" />
                    </div>
                    <div class="featureDetail">
                        <span class="heading"></span>
                        <div class="subheading"></div>
                    </div>
                </div>
                <div class="footer">
                    <div class="title"></div>
                    <div class="actionbx">
                        <button type="button" class="morebtn"></button>
                    </div>
                </div>
            </div>
            <ng-container>
            <div class="templateDummyCard">
                    <div class="header">
                        <div class="title"></div>
                        <div class="brandicon"></div>
                    </div>
                    <div class="middle">
                        <div class="featureImg">
                            <img src="../../../../../assets/images/templateDummyMain-img.png" class="dummyLoader" alt="">
                        </div>
                        <div class="featureDetail">
                            <span class="heading"></span>
                            <div class="subheading"></div>
                        </div>
                    </div>
                    <div class="footer">
                        <div class="title"></div>
                        <div class="actionbx">
                            <button type="button" class="morebtn"></button>
                        </div>
                    </div>
                </div>
    <div class="templateDummyCard">
        <div class="header">
            <div class="title"></div>
            <div class="brandicon"></div>
        </div>
        <div class="middle">
            <div class="featureImg">
                <img src="../../../../../assets/images/templateDummyMain-img.png" class="dummyLoader" alt="">
            </div>
            <div class="featureDetail">
                <span class="heading"></span>
                <div class="subheading"></div>
            </div>
        </div>
        <div class="footer">
            <div class="title"></div>
            <div class="actionbx">
                <button type="button" class="morebtn"></button>
            </div>
        </div>
    </div>
    <div class="templateDummyCard">
        <div class="header">
            <div class="title"></div>
            <div class="brandicon"></div>
        </div>
        <div class="middle">
            <div class="featureImg">
                <img src="../../../../../assets/images/templateDummyMain-img.png" class="dummyLoader" alt="">
            </div>
            <div class="featureDetail">
                <span class="heading"></span>
                <div class="subheading"></div>
            </div>
        </div>
        <div class="footer">
            <div class="title"></div>
            <div class="actionbx">
                <button type="button" class="morebtn"></button>
            </div>
        </div>
    </div>
    </ng-container>
</div>