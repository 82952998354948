import { Component, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { Angulartics2Segment } from "angulartics2/segment";
import { ApiRequests } from "@shared/services/api-requests.service";
import { PhaseService } from "@shared/services/phase.service";
import { DataService } from "@shared/services/data.service";
import { Intercom } from "ng-intercom";
import { BuildCardModel } from "src/app/shared/shared-module/models/build-card-model/build-card.model";
import { HomeTemplateService } from "@shared/services/home-template.service";
@Component({
  selector: "saveprogress",
  templateUrl: "saveprogress.component.html",
  styleUrls: ["saveprogress.component.scss"],
})
export class SaveprogressComponent {
  public router;
  public analyticsSegment;
  public preventClick = false;
  public updatedProjectName = "";
  constructor(
    public phaseService: PhaseService,
    private dataService: DataService,
    router: Router,
    analyticsSegment: Angulartics2Segment,
    public apiRequest: ApiRequests,
    private renderer: Renderer2,
    public homeTemplateService: HomeTemplateService
  ) {
    this.router = router;
    this.analyticsSegment = analyticsSegment;
  }

  ngOnInit() {
    this.updatedProjectName = this.phaseService.selectedHash.project_name;
  }

  public closeShowProgress() {
    if (!this.updatedProjectName) {
      this.updatedProjectName = "My Builder Project";
    }
    if (!this.preventClick) {
      this.phaseService.showSaveProgress = false;
      if (this.phaseService.gotoDashBoard) {
        this.phaseService.gotoDashBoard = false;
      }
      if (this.phaseService.logoutToHome) {
        this.phaseService.logoutToHome = false;
      }
    }
  }

  public hideShowProgress() {
    if (!this.preventClick) {
      this.phaseService.showSaveProgress = false;
      this.dataService.unsavedChanges = false;
      this.dataService.isFromViewPrototype = false;
      this.phaseService.buildCardEditMode = false;
      this.dataService.isEditBuildCard = false;
      this.phaseService.inProgressFlow = false;
      this.phaseService.isBuildCardSetup = false;
      this.phaseService.firstClicked = "";
      this.phaseService.newSelectedAppIds = [];
      this.phaseService.appListApps = [];
      this.phaseService.newSelectedProblemsAppsIds = [];
      this.phaseService.newSelectedProblemsApps = [];
      this.phaseService.newSelectedProblemsIds = [];
      this.phaseService.selectedApps = [];
      this.phaseService.selectedHash.is_advance = false;
      this.dataService.totalSelectedFeatures = 0;
      this.gotoPendingStep();
    }
  }

  public updateProject(event) {
    if (event.keyCode === 32 && !this.updatedProjectName.replace(/\s/g, "")) {
      this.updatedProjectName = this.updatedProjectName.trim();
      return;
    }
    if (event.target.value && event.keyCode === 13) {
      this.saveProgress();
    }
  }

  handleSpaces(event) {
    /*if ((event.keyCode === 32) && !this.updatedProjectName) {
      this.updatedProjectName = this.updatedProjectName.trim();
      return;
    }*/
  }

  public saveProgress() {
    this.phaseService.showNav = false;
    if (
      this.dataService.buildCardData.id &&
      !this.phaseService.selectedApps.length &&
      location.href.includes("home")
    ) {
      this.phaseService.reset();
      this.emptyBuildCard();
      this.phaseService.showSaveProgress = false;
      this.renderer.removeClass(
        document.getElementById("app-component"),
        "hide-scroll-bar"
      );
      if (this.phaseService.gotoDashBoard) {
        this.phaseService.gotoDashBoard = false;
        this.router.navigate(["dashboard"]);
      }
      return;
    }
    if (!this.preventClick) {
      this.phaseService.showSpinner = false;
      this.phaseService.selectedHash.project_name = this.updatedProjectName;
      this.preventClick = true;
      this.phaseService.showSaveProgress = false;
      if (this.phaseService.buildCardEditMode) {
        this.phaseService.selectedHash.status = "";
      }
      if (
        this.dataService.currentPage.indexOf("feature") > -1 ||
        this.dataService.currentPage.indexOf("delivery") > -1 ||
        this.dataService.currentPage.indexOf("apps") > -1 ||
        this.dataService.currentPage.indexOf("summary") > -1
      ) {
        if (
          this.phaseService.selectedFeatures.length === 0 ||
          this.phaseService.selectedPhases.length === 0
        ) {
          this.preventClick = false;
          if (this.updatedProjectName !== "My Builder Project") {
            if (
              this.phaseService.selectedHash.project_name ===
                "My Builder Project" &&
              this.phaseService.currentProjectName
            ) {
              this.phaseService.selectedHash.project_name =
                this.phaseService.currentProjectName;
            }
            const updatedSelectedHash =
              this.phaseService.updatedBuildCardDataName(
                ["project_name"],
                this.phaseService.selectedHash
              );
            this.apiRequest
              .createUpdateBuildCard(
                updatedSelectedHash,
                this.phaseService.inProgressBuildCardId,
                "",
                "rename_build_card"
              )
              .subscribe((data: any) => {
                this.hideShowProgress();
              });
          } else {
            this.hideShowProgress();
          }
          return;
        }
      }
      if (this.dataService.currentPage !== "summary") {
        this.dataService.showHideLoader(true);
        this.apiRequest
          .createUpdateBuildCard(
            this.phaseService.selectedHash,
            this.phaseService.inProgressBuildCardId
          )
          .subscribe(
            (data: any) => {
              // this.trackCardEvents();
              this.phaseService.inProgressBuildCardId =
                this.dataService.buildCardData.id;
              this.dataService.unsavedChanges = false;
              this.dataService.isFromViewPrototype = false;
              this.phaseService.buildCardEditMode = false;
              this.dataService.isEditBuildCard = false;
              this.phaseService.inProgressFlow = false;
              this.phaseService.isBuildCardSetup = false;
              this.preventClick = false;
              this.dataService.showHideLoader(false);
              this.gotoPendingStep();
            },
            (error) => {
              if (error.status === 403 || error.status === 401) {
                this.dataService.user = null;
                // remove cookie here
                this.dataService.unsetCookie();

                this.phaseService.clearLocalStorage();
                this.phaseService.showSignin = true;
                this.phaseService.openCommonAuth = true;
              }
              this.preventClick = false;
            }
          );
      } else {
        if (this.updatedProjectName !== "My Builder Project") {
          const updatedSelectedHash =
            this.phaseService.updatedBuildCardDataName(
              ["project_name"],
              this.phaseService.selectedHash
            );
          this.apiRequest
            .createUpdateBuildCard(
              updatedSelectedHash,
              this.phaseService.inProgressBuildCardId,
              "",
              "rename_build_card"
            )
            .subscribe((data: any) => {
              this.hideShowProgress();
            });
        }
        this.phaseService.inProgressBuildCardId =
          this.dataService.buildCardData.id;
        this.dataService.unsavedChanges = false;
        this.dataService.isFromViewPrototype = false;
        this.phaseService.buildCardEditMode = false;
        this.dataService.isEditBuildCard = false;
        this.phaseService.inProgressFlow = false;
        this.phaseService.isBuildCardSetup = false;
        this.preventClick = false;
        this.dataService.showHideLoader(false);
        this.gotoPendingStep();
      }
    }
  }

  public gotoPendingStep() {
    this.dataService.landingPage = "";
    if (this.dataService.shareUrlData) {
      this.dataService.shareUrlData.curPage = "";
    }
    if (this.phaseService.linkCopied) {
      this.phaseService.linkCopied = false;
    }
    this.phaseService.reset();
    if (this.phaseService.gotoDashBoard) {
      this.phaseService.gotoDashBoard = false;
      this.router.navigate(["dashboard"]);
    } else if (this.phaseService.gotoCreateNew) {
      this.phaseService.gotoCreateNew = false;
      this.emptyBuildCard();
      this.router.navigate([this.dataService.firstPage]);
    } else if (this.phaseService.logoutToHome) {
      this.logoutUser();
      this.dataService.unsetCookie();
      this.dataService.user = null;
      this.phaseService.logoutToHome = false;
      this.router.navigate([this.dataService.firstPage]);
    } else if (this.phaseService.becomePartner) {
      this.phaseService.becomePartner = false;
      this.router.navigate(["become-a-partner"]);
    }
  }

  public logoutUser() {
    this.apiRequest.signout_user().subscribe(
      (data: any) => {
        this.emptyBuildCard();
        localStorage.removeItem("add_manual_feature_first_time");
        localStorage.removeItem("add_feature_first_time");
        this.phaseService.natashaCurrentAddedFeatureMessages = [
          ...this.phaseService.natashaAddedFeatureMessages,
        ];
        localStorage.removeItem("natashaIdleQuestionCount");
        this.phaseService.natashaIdlQuestionTimeInterval = 50000;
        localStorage.removeItem("templatesVisited");
        this.homeTemplateService.resetTemplateNatashaChatHistory();
        this.phaseService.resetNatashaChatsOnFeaturePage();
        this.phaseService.natashaMobileGroupIdentifier = "";
        this.phaseService.natashaMobileGroupChatId = 1;
        this.homeTemplateService.mobileTempateNatashaChatList = {};
        this.phaseService.isNatashaFirstBundleResponse = true;
        sessionStorage.removeItem("userNameFromNatasha");
        if (this.phaseService.isDashboardShow) {
          this.phaseService.reset();
          this.router.navigate([this.dataService.firstPage]);
        }
      },
      (error) => {
        if (this.phaseService.isDashboardShow) {
          this.phaseService.reset();
          this.router.navigate([this.dataService.firstPage]);
        }
      }
    );
  }

  public emptyBuildCard() {
    this.dataService.modifiedBuildCardData = undefined;
    this.dataService.buildCardData = new BuildCardModel();
  }
  public isShowSaveProgress() {
    return this.phaseService.showSaveProgress;
  }
}
