<div class="dashboardArea" *ngIf="dataService.user && !referralService.showMainReferralComponent">
  <!--<div class="mobileMsg">
    <h2>Sorry dashboard is not active for mobile view. To see the full dashboard, please open it on desktop.</h2>
  </div>-->
  <!-- dashboard left -->
  <app-menu-bar (pshowCards)="showCards($event)" (pexitPaymentStatus)="exitPaymentStatus()"
    [showSubMenuLoader]="showSubMenuLoader" [showTooltip]="showTooltip" [showCompleted]='showCompleted' [showCustomPrototypeCards]="showCustomPrototype"
    [showPrototypeCards]="showPrototype" [showInProgress]='showInProgress' [showPaid]='showPaid' [showSpecCalls]='showSpecCalls' [showInvited]='showInvited'
    [showArchived]='showArchived' [showAdditionalFeatures]='showAdditionalFeatures' [showPaymentCards]='dataService.showPaymentCards'
    [showProjectCompleted]='showProjectCompleted' [showFreemiumCards]="showFreemiumCards" [showTailorMadePrototypeCards]="showTailorMadePrototype"
    [showDemoCalls]='showDemoCalls'>
  </app-menu-bar>

  <!-- dashboard right -->
  <div class="dashboardRight">
    <div class="dashboardHold">
        <app-dashboard-main (pexitPaymentStatus)="exitPaymentStatus()" (pshowCards)="showCards($event)"
          (pselectUserProfile)="selectUserProfile($event)" (pshowTooltip)="pshowTooltip($event)"
          (cpreventClick)="changePreventClick($event)" (pshowRenameCard)="showRenameCard($event)"  [user]='user' [showCompleted]='showCompleted'
          [showInProgress]='showInProgress' [showPaid]='showPaid' [showRecentComplete]='showRecentComplete'
          [showSpecCalls]='showSpecCalls' [showRecentRunning]='showRecentRunning' [showRecentFreemium]="showRecentFreemiumCards"
          [showRecentPrototype]="showRecentPrototype" [showRecentCustomPrototype]="showRecentCustomPrototype"
          [showRecentProgress]='showRecentProgress' [showRecentSpecCalls]='showRecentSpecCalls' [showFreemiumCards]="showFreemiumCards"
          [showRecentArchived]="showRecentArchived" [showInvited]='showInvited' [showArchived]='showArchived'
          [showPrototype]="showPrototype" [showCustomPrototype]="showCustomPrototype" [showRecentInvited]="showRecentInvited"
          [preventClick]='preventClick' [showMoreIndex]='showMoreIndex' [showAdditionalFeatures]="showAdditionalFeatures" [showPaymentCards]='dataService.showPaymentCards'
          [showProjectCompleted]='showProjectCompleted' [showRecentCompletedProjects]="showRecentCompletedProjects"
          [showRecentTailorMadePrototype]="showRecentTailorMadePrototype" [showTailorMadePrototype]="showTailorMadePrototype" [showDemoCalls]='showDemoCalls'>
        </app-dashboard-main>
    </div>
  </div>

  <!----popup for rename project---->
  <app-popups (pdetectChanges)="detectChanges()" (pshowCards)="showCards($event)" [showCompleted]='showCompleted'
    [showInProgress]='showInProgress' [showPaid]='showPaid' [showSpecCalls]='showSpecCalls' [showInvited]='showInvited'
    [showArchived]='showArchived' [renameInProgress]="dataService.renameInProgress"
    [cardInRenameProgress]="cardInRenameProgress" [selectedProfileList]="selectedProfileList"
    [preventClick]='preventClick' (ppreventClick)="changePreventClick($event)" *ngIf="dataService.cardToDelete ||
    dataService.renameInProgress || dataService.isUserProfileSelected || phaseService.showOwnershipConfirmationDashboard">

  </app-popups>

  <invited-users *ngIf="phaseService.isInvitedUserPopupOpened" [invitedCardData]="dataService.invitedCard"></invited-users>
  <shareinvite></shareinvite>
</div>

<app-referral-component *ngIf="referralService.showMainReferralComponent"></app-referral-component>
