import { Component, OnInit } from '@angular/core';
import {ApiRequests} from '../../../shared/shared-module/services/api-requests.service';
import {Router} from '@angular/router';
import {CommonService} from '@shared/services/common.service';

@Component({
  selector: 'app-handle-api-errors',
  templateUrl: './handle-api-errors.component.html',
  styleUrls: ['./handle-api-errors.component.scss']
})
export class HandleApiErrorsComponent implements OnInit {

  constructor(public apiRequest: ApiRequests , public router: Router,
              public commonService: CommonService) { }

  ngOnInit() {
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    this.apiRequest.fetchHomePageData().subscribe((data: any) => {
      if (data) {
        this.router.navigate(['']);
      }
    });
  }

}
