import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'normalize'
})

export class NormalizeInvalidDatePipe implements PipeTransform {
  transform(momentDate: string): string {
    return momentDate === 'Invalid date' ? '---': momentDate;
  }
}
