import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PhaseService } from '../services/phase.service';
import { DataService } from '../services/data.service';
import { ApiRequests } from '@shared/services/api-requests.service';
import { CommonService } from '@shared/services/common.service';
import { environment } from '@env/environment';
import {AppDataService} from '@rootmodule/app-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(private phaseService: PhaseService,
    private dataService: DataService,
    private router: Router,
    private apiRequest: ApiRequests,
    private commonService: CommonService, private appDataService: AppDataService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.dataService.user) {
      this.fetchHomePageData();
      let path = '';
      if (window.location.hash) {
        path = window.location.hash;
        let subUrl = '';
        if (path.indexOf('?') !== -1) {
          subUrl = path.substr(2, path.indexOf('?') - 2);
        } else {
          subUrl = path.substr(2);
        }
        this.dataService.pendingURL = subUrl;
      } else {
        path = window.location.pathname;
        this.dataService.pendingURL = window.location.pathname;
      }
      if (this.dataService.pendingURL.indexOf('buildcard-payment') === -1) {
        if (this.dataService.pendingURL.split('/')[2]) {
          this.phaseService.inProgressBuildCardId = path.split('/')[2].trim();
        }
      }
      this.dataService.signupSrcName = 'default_sign_up';
      if (this.commonService.isPlatformBrowser && !this.appDataService.urlParameters.is_etisalat_flow) {
        this.phaseService.showRegister = true;
        this.phaseService.showSignin = false;
        this.phaseService.openCommonAuth = true;
      }
    } else if (!this.getCookie(environment.USER_COOKIE) && (window.location.pathname.includes('feature') || window.location.pathname.includes('delivery') || (window.location.pathname.includes('dashboard') && !this.appDataService.urlParameters.is_etisalat_flow))) {
      if (this.appDataService.urlParameters.featureRequest) {
        this.phaseService.featureRequestUniqueCode = window.location.pathname.split('/')[1];
      }
      this.router.navigate(['home']);
    } else {
      return true;
    }
  }

  fetchHomePageData() {
    if (!this.dataService.homePageData.platforms) {
      this.phaseService.timerStart();
      this.apiRequest.fetchHomePageData().subscribe((data: any) => {
        this.dataService.dataLoadingCurrency = Object.assign({}, this.dataService.homePageData.currency);
        this.phaseService.platformPrices = Array.apply(null, Array(this.dataService.homePageData.platforms.length));
        this.phaseService.platformPrices.map((x, i) => {
          return i;
        });
        if (this.dataService.homePageData.currency) {
          this.phaseService.specing_price = this.dataService.homePageData.currency.specing_price;
        }
      });
    }
  }

  getCookie(cname) {
    if (this.commonService.isPlatformBrowser) {
      const nameEQ = cname + '=';
      const ca = decodeURI(document.cookie).split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
      return null;
    }
  }
}
