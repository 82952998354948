import { Component, OnInit } from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {CommonService} from '@shared/services/common.service';

@Component({
  selector: 'app-duplicate-card-modal',
  templateUrl: './duplicate-card-modal.component.html',
  styleUrls: ['./duplicate-card-modal.component.scss']
})
export class DuplicateCardModalComponent {

  constructor(public phaseService: PhaseService, public commonService: CommonService) { }



  cloneBuildCard(forSameCustomer) {
    const cardData = this.commonService.duplicateCardData;
    this.phaseService.cloneBuildCard(cardData.uniQueCode, cardData.currency, forSameCustomer);
  }

  closeModal() {
    this.commonService.isDuplicateCardFlow = false;
  }

}
