import {Component, ComponentFactoryResolver, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiRequests} from '@shared/services/api-requests.service';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import {TemplateDetailModel} from '@models/template-detail-model/template.detail.model';
import {DragScrollComponent} from 'ngx-drag-scroll';
import {AppDataService} from '@rootmodule/app-data.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Intercom} from 'ng-intercom';
import {CommonService} from '@shared/services/common.service';
import {HomeTemplateService} from '@shared/services/home-template.service';

@Component({
  selector: 'app-freemium-template-detail',
  templateUrl: './freemium-template-detail.component.html',
  styleUrls: ['./freemium-template-detail.component.scss']
})
export class FreemiumTemplateDetailComponent implements OnInit {
  leftPlatformNavDisabled = false;
  rightPlatformNavDisabled = false;
  leftFeatureNavDisabled = false;
  rightFeatureNavDisabled = false;
  leftImageNavDisabled = false;
  rightImageNavDisabled = false;
  leftMobileFeatureNavDisabled = false;
  rightMobileFeatureNavDisabled = false;
  leftMobileImageNavDisabled = false;
  rightMobileImageNavDisabled = false;
  selectedFeature = -1;
  selectedImageIndex = 0;
  selectedImage = '';
  imageSet = 4;
  imageSetCount = 0;
  imagesToShow: any;
  totalImageSetCount: number;
  screenshots = [];
  activityFeed = false;
  innerWidth = 0;
  public template_alias;
  public selectedPreviewPlatform = 'android';
  public currentUrl;
  public limit = 150;
  scrolledItemsIndex = 5;
  @ViewChild('navPlatformSlider', {read: DragScrollComponent}) ds: DragScrollComponent;
  @ViewChild('navFeatureSlider', {read: DragScrollComponent}) fds: DragScrollComponent;
  @ViewChild('navImageSlider', {read: DragScrollComponent}) ids: DragScrollComponent;
  @ViewChild('navImageSliderMobile', {read: DragScrollComponent}) navImageSliderMobile: DragScrollComponent;
  @ViewChild('navFeatureSliderPopUp', {read: DragScrollComponent}) navFeatureSliderPopUp: DragScrollComponent;
  @ViewChild('studioStorePrev') studioStorePrev: ElementRef;
  @ViewChild('rightTopSection') rightTopSection: ElementRef;
  @ViewChild('featureSliderHolder') featureSliderHolder: ElementRef;
  isBuildCardUpdating = false;
  isShowBottomBar = false;
  isShowChangePlatformModal = false;
  selectedPlatform = 'app';
  tempSelectedPlatform = '';
  featureDisplaySet = 0;
  featureDisplaySetCount = 5;
  @Input('appDetails') appDetails: TemplateDetailModel;
  private questionShownIndex: number;
  public isFeatureHoverActive = false;
  constructor(public route: ActivatedRoute, public apiRequest: ApiRequests, public dataService: DataService,
              public phaseService: PhaseService, public router: Router, public appDataService: AppDataService,
              public analyticsSegment: Angulartics2Segment, public commonService: CommonService,public homeTemplateService: HomeTemplateService) {
  }

  @HostListener('window:scroll') onScroll(e: Event): void {
    if (window.pageYOffset >= this.studioStorePrev.nativeElement.offsetHeight )  {
      this.isShowBottomBar = true;
    } else {
      this.isShowBottomBar = false;
    }
  }

  ngOnInit() {
    this.apiRequest.fetchHomePageData();
    this.getTemplateAlias();
    this.validateToken();
    this.route.queryParams.subscribe(params => {
      if (params.uniq_code) {
        this.getBuildCardData(params.uniq_code);
      }
    });
    this.innerWidth = window.innerWidth;
    const devices = this.dataService.appDetails.template_device;
    this.innerWidth > 768 ? this.featureDisplaySetCount = 5 : this.featureDisplaySetCount = 1;
    devices.length === 2 ? this.selectedPlatform = 'all' : (devices.length && devices[0]) === 'Web' ? this.selectedPlatform = 'web' : this.selectedPlatform = 'app';
    if (this.selectedPlatform === 'app') {
      this.selectedPreviewPlatform = 'android';
    }else if (this.selectedPlatform ==='web') {
      this.selectedPreviewPlatform = 'web';
    }
    this.getSelectedImage();
    this.selectedImage = this.screenshots[0];
    this.imagesToShow = this.screenshots.slice(0, this.imageSet);
    this.totalImageSet();
    this.phaseService.trackEventForObject(this.analyticsSegment, 'freemium_page_visited', {
      user_id : this.dataService.user?.id
    });
    this.checkForDirectPaymentFlow()
  }

  ngOnChanges() {
    this.selectedImage = this.screenshots[0];
    this.imagesToShow = this.screenshots.slice(0, this.imageSet);
    this.totalImageSet();
  }

  checkForDirectPaymentFlow() {
    if(this.appDataService.urlParameters.show_login){
      this.payNowForRental(this.dataService.appDetails, 'Summary bar')
    }
  }

  getTemplateAlias() {
    this.template_alias = this.route.params.subscribe((params: any) => {
      if (params.template_alias) {
        if (params.template_alias.indexOf(' ') === -1) {
            this.apiRequest.fetchTemplateDetails(params.template_alias, true).subscribe((data: any) => {
            });
        } else {
            this.apiRequest.fetchTemplateDetails(params.template_alias.split(' ').join('-').toLowerCase(), true).subscribe((data: any) => {
            });
        }
      }
    });
  }

  moveTo(direction, isMobile?) {
    const remainingFeatures = this.dataService.appDetails.features.length - this.scrolledItemsIndex;
    if (direction === 'right') {
      this.scrolledItemsIndex = (remainingFeatures < 5) ? (this.scrolledItemsIndex + remainingFeatures) : this.scrolledItemsIndex + 5;
      this.featureDisplaySet = (remainingFeatures < 5) ? (this.featureDisplaySet + remainingFeatures) : this.featureDisplaySet + 5;
    } else {
      this.scrolledItemsIndex = (this.featureDisplaySet < 5) ? 5 : this.scrolledItemsIndex - 5;
      this.featureDisplaySet = (this.featureDisplaySet < 5) ? 0 : this.featureDisplaySet - 5;
    }
    if (isMobile) {
      this.navFeatureSliderPopUp.moveTo(this.featureDisplaySet);
    } else  {
      this.fds.moveTo(this.featureDisplaySet);
    }
  }

  getBuildCardData(uniqCode) {
    this.apiRequest.fetchSingleBuildCardData(uniqCode).subscribe((data: any) => {
      this.phaseService.setUpInFullBuildCard();
      this.phaseService.buildCardEditMode = true;
      this.dataService.isEditBuildCard = true;
      this.payNowForRental(this.dataService.appDetails, 'Top');
    });
  }

  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.innerWidth > 768 ? this.featureDisplaySetCount = 5 : this.featureDisplaySetCount = 1;
  }

  toggleSelectedAppsById(app: any): void {
    const selectedApp = this.phaseService.selectedApps.find(x => x.id === app.id);
    if (selectedApp) {
      const index = this.phaseService.selectedApps.indexOf(selectedApp);
      this.phaseService.selectedApps.splice(index, 1);
    } else {
      this.phaseService.selectedApps.push(app);
      if (this.dataService.buildCardData && !this.dataService.buildCardData.id) {
        if (this.dataService.user &&  this.dataService.appDetails.pricing_modal && (this.phaseService.selectedApps[0].pricing_modal === 'rental_price')) {
          this.dataService.homePageData.currency = this.dataService.user.currency;
        } else if (!this.dataService.user && this.dataService.appDetails.pricing_modal && (this.phaseService.selectedApps[0].pricing_modal === 'rental_price')) {
          this.dataService.homePageData.currency = this.appDetails.currency;
        }
      }
    }
  }


  moveLeft(source: string) {
    switch (source) {
      case 'imageSlider':
        this.ids.moveLeft();
        break;
      case 'featureSlider':
        this.fds.moveLeft();
        break;
      case 'mobileImageSlider':
        this.navImageSliderMobile.moveLeft();
        break;
      case 'mobilefeatureSlider':
        this.navFeatureSliderPopUp.moveLeft();
        break;
      default:
        break;
    }
  }

  moveRight(source: string) {
    switch (source) {
      case 'imageSlider':
        this.ids.moveRight();
        break;
      case 'featureSlider':
        this.fds.moveRight();
        break;
      case 'mobileImageSlider':
        this.navImageSliderMobile.moveRight();
        break;
      case 'mobilefeatureSlider':
        this.navFeatureSliderPopUp.moveRight();
        break;
      default:
        break;
    }
  }


  leftBoundStat(reachesLeftBound: boolean, source: string) {
    switch (source) {
      case 'imageSlider':
        this.leftImageNavDisabled = reachesLeftBound;
        break;
      case 'platformSlider':
        this.leftPlatformNavDisabled = reachesLeftBound;
        break;
      case 'featureSlider':
        this.leftFeatureNavDisabled = reachesLeftBound;
        break;
      case 'mobilefeatureSlider':
        this.leftMobileFeatureNavDisabled = reachesLeftBound;
        break;
      case 'mobileImageSlider':
        this.leftMobileImageNavDisabled = reachesLeftBound;
        break;
      default:
        break;
    }
  }

  rightBoundStat(reachesRightBound: boolean, source: string) {
    switch (source) {
      case 'imageSlider':
        this.rightImageNavDisabled = reachesRightBound;
        break;
      case 'platformSlider':
        this.rightPlatformNavDisabled = reachesRightBound;
        break;
      case 'featureSlider':
        this.rightFeatureNavDisabled = reachesRightBound;
        break;
      case 'mobilefeatureSlider':
        this.rightMobileFeatureNavDisabled = reachesRightBound;
        break;
      case 'mobileImageSlider':
        this.rightMobileImageNavDisabled = reachesRightBound;
        break;
      default:
        break;
    }
  }

  setInfoPositionOnFeature() {
    this.isFeatureHoverActive = true;
  }

  public getLocaledPrice(price: any): any {
    return this.dataService.getLocaledPrice(price);
  }


  payNowForRental(appDetails, eventNme) {
    if (appDetails.id === 1) {
      location.href = 'https://google.com';
      return;
    }
    this.phaseService.trackEventForObject(this.analyticsSegment, 'freemium_proceed_CTA_clicked', {
      user_id : this.dataService.user?.id
    });
    if (this.phaseService.inProgressBuildCardId) {
      this.apiRequest.pollBuildCardStatus(this.phaseService.inProgressBuildCardId).subscribe(data => {
        this.phaseService.isRentalCardPaid = data.is_paid;
        if (data.is_paid) {
          this.phaseService.showRentalPaymentFlow = true;
          this.phaseService.handleNextPrevForRentalFlow(4);
          return;
        } else {
          this.proceedForRentalFlow(appDetails);
        }
      }, error => {
        this.proceedForRentalFlow(appDetails);
      });
    } else {
      this.proceedForRentalFlow(appDetails);
    }
  }

  proceedForRentalFlow(appDetails) {
    this.dataService.isExpertClicked = 'Continue to Payment';
    if (this.dataService.user) {
      this.phaseService.selectedHash.create_spec_call = 'false';
      this.phaseService.selectedHash.application_ids = [];
      this.phaseService.selectedHash.application_ids.push(appDetails.id);
      this.dataService.loginNewModule = true;
      this.phaseService.showRentalPaymentFlow = true;
      this.phaseService.handleNextPrevForRentalFlow(1);
    } else {
      this.phaseService.selectedSectionIndex = 0;
      this.phaseService.showRentalPaymentFlow = true;
    }
  }

  isMonthlySelected() {
    return this.phaseService.selectMonthlyPackage;
  }

  closeChangePlatFormModal() {
    this.isShowChangePlatformModal = false;
  }

  changePlatform(platform) {
    this.tempSelectedPlatform = platform;
  }

  applyChangedPlatform () {
    this.isShowChangePlatformModal = false;
    this.selectedPlatform = this.tempSelectedPlatform ? this.tempSelectedPlatform : '';
    let redirectUrl = '';
    this.selectedPlatform === 'all' ? redirectUrl = this.appDetails.complete_app_url :
      this.selectedPlatform === 'web' ?  redirectUrl = this.appDetails.web_app_url : redirectUrl = this.appDetails.mobile_app_url;
    if (redirectUrl)  window.location.href = redirectUrl;
  }

  openFeaturePopup(feature, index) {
    if (this.innerWidth < 768) {
      this.selectedFeature = index;
      setTimeout(() => {
        this.navFeatureSliderPopUp.moveTo(index);
      });
    }
  }
  closeFeaturePopup() {
    this.selectedFeature = -1;
  }

  validateToken(){
    if(this.appDataService.urlParameters.token){
      let decodedToken = decodeURIComponent(this.appDataService.urlParameters.token).split(' ').join('+');
      const data = {
        'token' : decodedToken,
        'build_card_type': 'freemium'
      };
      this.apiRequest.tokenResolve(data).subscribe((resp: any) => {
        this.phaseService.freemiumCardWithToken = true;
        if(resp.user)
        {
          if(this.dataService.user){
            if(this.dataService.user !== resp.user.email){
              this.apiRequest.signout_user().subscribe(
                (res: any) => {
                });
            }
          }
          this.dataService.isExpertClicked = 'Continue to Payment';
          this.phaseService.selectedSectionIndex = 0;
          this.phaseService.showRentalPaymentFlow = true;
          this.phaseService.userRegistered = true;
          this.phaseService.subscriptionId = resp.user.subscription_id;
        }else{
          if(this.dataService.user){
            if(this.dataService.user !== resp.email){
              this.apiRequest.signout_user().subscribe(
                (res: any) => {
                });
            }
          }
          this.dataService.isExpertClicked = 'Continue to Payment';
          this.phaseService.selectedSectionIndex = 0;
          this.phaseService.showRentalPaymentFlow = true;
          this.phaseService.userRegistered = false;
          this.phaseService.subscriptionId = resp.subscription_id;
          this.phaseService.emailFromToken = resp.email;
          this.phaseService.usernameFromToken = resp.first_name;
        }
        },error => {
          this.phaseService.freemiumCardWithToken = false;
      });
    }
    else{
      this.phaseService.freemiumCardWithToken = false;
    }
  }

  changeSelectedImage(type) {
    this.phaseService.trackEventForObject(this.analyticsSegment,'template_view_details_feature_carousel_interacted', {
      user_id : this.dataService.user?.id,
      buildcard_category : this.homeTemplateService.templateType
    });
    if ( type === 'next' ) {
      this.selectedImageIndex++;
    }  else {
      this.selectedImageIndex--;
    }
    this.selectedImage = this.screenshots[this.selectedImageIndex];

    if ( this.selectedImageIndex >= this.imageSet * (this.imageSetCount + 1) ) {
      this.changeImageSet('next');
    } else if ( this.selectedImageIndex < this.imageSet * (this.imageSetCount) ) {
      this.changeImageSet('previous');
    }
  }

  changeImageSet(type) {
    if ( type === 'next') {
      this.imageSetCount++;
    } else {
      this.imageSetCount--;
    }
    this.imagesToShow = this.screenshots.slice((this.imageSet * this.imageSetCount), (this.imageSet * (this.imageSetCount + 1)));
  }

  selectImage(index) {
    const selectedIndex = (this.imageSet * this.imageSetCount) + index;
    this.selectedImageIndex = selectedIndex;
    this.selectedImage = this.screenshots[selectedIndex];
  }

  public getSelectedImage() {
    if (this.selectedPlatform == 'web') {
      this.dataService.appDetails.features.forEach((feature) => {
        this.screenshots.push(feature.feature_screenshots.web);
     });
    }else{
      this.dataService.appDetails.features.forEach((feature) => {
        this.screenshots.push(feature.feature_screenshots.android);
     });
    }
 }

  totalImageSet() {
    this.totalImageSetCount = Math.ceil(this.screenshots.length / this.imageSet);
  }

  displayInfoblock() {
    this.phaseService.trackEventForObject(this.analyticsSegment, 'template_view_details_feature_info_clicked ', {
      user_id : this.dataService.user?.id,
      buildcard_category : this.homeTemplateService.templateType
    });
    this.activityFeed = !this.activityFeed;
  }

  openChangePlatformModal(eventNme) {
    this.tempSelectedPlatform = this.selectedPlatform;
    this.isShowChangePlatformModal = true;
  }

  builderCloudPrice() {
    const cloudPrice = this.dataService.buildCardData.cloud_price ? this.dataService.buildCardData.cloud_price : this.phaseService.selectedHash.cloud_price;

    return cloudPrice > 0 ? cloudPrice.toFixed(2) : 0;
  }
  showAnswer(e) {
    this.questionShownIndex = this.questionShownIndex == e.dataset.index ? null : e.dataset.index;
  }

  public toggleLayoutView(platform) {
    this.screenshots = [];
    if (this.selectedPreviewPlatform !== platform) {
      this.selectedPreviewPlatform = platform;
    }
    if (platform == 'web') {
      this.dataService.appDetails.features.forEach((feature) => {
        this.screenshots.push(feature.feature_screenshots.web);
     });
     this.imagesToShow = this.screenshots.slice(0, this.imageSet);
     this.selectedImage = this.screenshots[this.selectedImageIndex]
    }else{
      this.dataService.appDetails.features.forEach((feature) => {
        this.screenshots.push(feature.feature_screenshots.android);
     });
     this.imagesToShow = this.screenshots.slice(0, this.imageSet);
     this.selectedImage = this.screenshots[this.selectedImageIndex]
    }
  }

  getOrderLimit(){
    if (this.appDataService.urlParameters.is_freemium) {
      return this.dataService.appDetails.freemium_order_limit;
    }else{
      return this.dataService.appDetails.starter_order_limit;
    }
  }
}


