<div class="commonPopUp active">
    <div class="popOverlay"></div>
    <div class="popHolder useReferralPopup" *ngIf="(referralService.showUseBuilderPointsPopup && !showReduceMonthlyPaymentPopup && !showReduceBuilderCarePopup && !showStartNewProjectPopup && !referralService.showConfirmBuilderPointsReductionPopup) || 
    (referralService.builderPointsUsageAPISuccess && showReduceMonthlyPaymentPopup && !showReduceBuilderCarePopup && !showStartNewProjectPopup) ||
    (referralService.builderPointsUsageAPISuccess && showReduceBuilderCarePopup && !showReduceMonthlyPaymentPopup && !showStartNewProjectPopup) || 
    (showStartNewProjectPopup && !showReduceMonthlyPaymentPopup && !showReduceBuilderCarePopup) || 
    (referralService.showConfirmBuilderPointsReductionPopup && !showReduceMonthlyPaymentPopup && !showReduceBuilderCarePopup && !showStartNewProjectPopup)">
    
      <div class="closePopUp" (click)="closeBuilderPointsUsagePopup()"><em class="icon-cancel"></em></div>
      
      <div class="howToUse" *ngIf="referralService.showUseBuilderPointsPopup && !showReduceMonthlyPaymentPopup && !showReduceBuilderCarePopup && !showStartNewProjectPopup && !referralService.showConfirmBuilderPointsReductionPopup">
          <h3>How to use your points</h3>
          <ul>
              <li [ngClass]="{notActive : (referralService.listOfBuildCards.length === 0)}" (click)="openReduceMonthlyPaymentPopup()">
                <div class="infoIcon"><img src="../../../../../assets/images/logoSmall.svg" alt="" /></div>
                <div class="infoText">
                    <h3>Reduce monthly payment</h3>
                    <p>Save money building an idea you've already started with us</p>
                </div>
                <div class="infoArrow"><em class="icon-next"></em></div>
              </li>
              <li [ngClass]="{notActive : (referralService.listOfBuildCards.length === 0)}" (click)="openReduceBuilderCarePopup()">
                <div class="infoIcon"><img src="../../../../../assets/images/careinclude.svg" alt="" /></div>
                <div class="infoText">
                    <h3>Reduce Builder Care payment</h3>
                    <p>Save money on keeping your software running perfectly</p>
                </div>
                <div class="infoArrow"><em class="icon-next"></em></div>
              </li>
              <li class="notActive">
                <div class="infoIcon"><img src="../../../../../assets/images/BuilderCloud-icon.svg" alt="" /></div>
                <div class="infoText">
                    <h3>Reduce Builder Cloud payment</h3>
                    <p>Save money on hosting and other cloud services</p>
                </div>
                <div class="infoArrow"><em class="icon-next"></em></div>
              </li>
              <li [ngClass]="{notActive : (referralService.totalAmountInWallet <= 0)}" (click)="openStartNewProjectPopup()">
                <div class="infoIcon"><img src="../../../../../assets/images/add-circle.svg" alt="" /></div>
                <div class="infoText">
                    <h3>Start a new project!</h3>
                    <p>Save money when you build new software</p>
                </div>
                <div class="infoArrow"><em class="icon-next"></em></div>
              </li>
          </ul>
      </div>

      <!--Box two start-->
      <div class="sectionBox" *ngIf="referralService.builderPointsUsageAPISuccess && showReduceMonthlyPaymentPopup && !showReduceBuilderCarePopup && !showStartNewProjectPopup">
        <div class="sectionHeading">
            <button type="button" (click)="goToMainMenu()"><em class="icon-prev"></em></button> <h3>Reduce monthly payment</h3>
        </div>

        <div class="sectionText">
            <p>Use your Builder Points to reduce your monthly invoices.</p>
        </div>

        <div class="selectBuildcard">
            <h4>Select Buildcard to apply reduction</h4>
            <div *ngIf="referralService.listOfBuildCards && (referralService.listOfBuildCards.length === 1)" class="selectBox">
              {{referralService.listOfBuildCards[0].project_name}}
            </div>
            <div *ngIf="referralService.listOfBuildCards && (referralService.listOfBuildCards.length > 1)" class="selectBox">
                <select (change)="getSelectedBuildCard($event)">
                    <option *ngFor="let buildCard of referralService.listOfBuildCards" value="{{buildCard.id}}">{{buildCard.project_name}}</option>
                </select>
            </div>
        </div>

        <div class="greyBox">
            <div class="sliderSection">
              <div class="walletPointSlider">
                <ngx-slider [(value)]="sliderValue" 
                [options]="referralService.referralPointsUsageSliderOptions"
                (userChangeEnd)="onSliderValueChange()">
                </ngx-slider>
              </div>
              
                <p>Equivalent to <strong>{{dataService.user?.currency?.symbol}}{{dataService.getLocaledPrice(referralService.builderPointsUsageEquivalentToUserCurrency)}}</strong></p>
            </div>

            <hr>

            <div class="upcomingPayments" *ngIf="referralService.builderPointsUsageData && (referralService.builderPointsUsageData.length > 0)">
                <h3>Upcoming payments</h3>
                <table border="0" summary="">
                    <tr>
                      <th>Date</th>
                      <th>Original</th>
                      <th>New</th>
                    </tr>
                    <tr *ngFor="let usageData of referralService.builderPointsUsageData">
                      <td>{{usageData.Date}}</td>
                      <td class="strickText">{{dataService.user?.currency?.symbol}}{{usageData.orignal}}</td>
                      <td class="purpleColor">{{dataService.user?.currency?.symbol}}{{usageData.new_amount}}</td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="footerButtons">
            <div class="rightSide">
                <button class="cancelButton" type="button" (click)="closeBuilderPointsUsagePopup()">Cancel</button>
                <button class="confirmButton" type="button" (click)="confirmBuilderPointsReduction()">Confirm</button>
            </div>
        </div>

      </div>
      <!--Box two close-->

      <!--Box three start-->
      <div class="sectionBox" *ngIf="referralService.builderPointsUsageAPISuccess && showReduceBuilderCarePopup && !showReduceMonthlyPaymentPopup && !showStartNewProjectPopup">
        <div class="sectionHeading">
            <button type="button" (click)="goToMainMenu()"><em class="icon-prev"></em></button> <h3>Reduce Builder Care payment</h3>
        </div>

        <div class="sectionText">
            <p>Use your Builder Points to reduce your Builder Care monthly invoices.</p>
        </div>

        <div class="selectBuildcard">
          <h4>Select Buildcard to apply reduction</h4>
          <div *ngIf="referralService.listOfBuildCards && (referralService.listOfBuildCards.length === 1)" class="selectBox">
            {{referralService.listOfBuildCards[0].project_name}}
          </div>
          <div *ngIf="referralService.listOfBuildCards && (referralService.listOfBuildCards.length > 1)" class="selectBox">
              <select (change)="getSelectedBuildCard($event)">
                  <option *ngFor="let buildCard of referralService.listOfBuildCards" value="{{buildCard.id}}">{{buildCard.project_name}}</option>
              </select>
          </div>
        </div>

        <div class="greyBox">
          <div class="sliderSection">
            <div class="walletPointSlider">
              <ngx-slider [(value)]="sliderValue" 
              [options]="referralService.referralPointsUsageSliderOptions"
              (userChangeEnd)="onSliderValueChange()">
              </ngx-slider>
            </div>
            
              <p>Equivalent to <strong>{{dataService.user?.currency?.symbol}}{{dataService.getLocaledPrice(referralService.builderPointsUsageEquivalentToUserCurrency)}}</strong></p>
          </div>

            <hr>

            <div class="upcomingPayments" *ngIf="referralService.builderPointsUsageData && (referralService.builderPointsUsageData.length > 0)">
                <h3>Upcoming payments</h3>
                <table border="0" summary="">
                    <tr>
                      <th>Date</th>
                      <th>Original</th>
                      <th>New</th>
                    </tr>
                    <tr *ngFor="let usageData of referralService.builderPointsUsageData">
                      <td>{{usageData.Date}}</td>
                      <td class="strickText">{{dataService.user?.currency?.symbol}}{{usageData.orignal}}</td>
                      <td class="purpleColor">{{dataService.user?.currency?.symbol}}{{usageData.new_amount}}</td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="footerButtons">
            <div class="rightSide">
                <button class="cancelButton" type="button" (click)="closeBuilderPointsUsagePopup()">Cancel</button>
                <button class="confirmButton" type="button" (click)="confirmBuilderPointsReduction()">Confirm</button>
            </div>
        </div>

      </div>
      <!--Box three close-->

      <!--Box four start-->
      <div class="sectionBox" *ngIf="showStartNewProjectPopup && !showReduceMonthlyPaymentPopup && !showReduceBuilderCarePopup">
        <div class="sectionHeading">
            <button type="button" (click)="goToMainMenu()"><em class="icon-prev"></em></button> <h3>Start a new project</h3>
        </div>

        <div class="sectionText">
            <p>Use your Builder Points to reduce the bill for new software. Select the number of points now, you can change them later.</p>
        </div>

        <div class="greyBox">
          <div class="sliderSection">
            <div class="walletPointSlider">
              <ngx-slider [(value)]="sliderValue" 
              [options]="referralService.referralPointsUsageSliderOptions">
              </ngx-slider>
            </div>
            
              <p>Project bill will be reduced by <strong>{{dataService.user?.currency?.symbol}}{{sliderValue}}</strong></p>
          </div>
        </div>

        <div class="footerButtons">
            <div class="rightSide">
                <button class="cancelButton" type="button" (click)="closeBuilderPointsUsagePopup()">Cancel</button>
                <button class="confirmButton" type="button" (click)="startNewProject()">Start project</button>
            </div>
            <div class="leftSide">
              <button class="talkButton" type="button" (click)="talkToExpert()">Talk to us</button>
            </div>
        </div>

      </div>
      <!--Box four close-->

      <!--Box five start-->
      <div class="sectionBox" *ngIf="referralService.showConfirmBuilderPointsReductionPopup && !showReduceMonthlyPaymentPopup && !showReduceBuilderCarePopup && !showStartNewProjectPopup">
        <div class="sectionHeading">
            <h2>Congratulations!</h2>
        </div>

        <div class="sectionText">
            <p>You've successfully used {{sliderValue}} Builder Points.</p>
            <p>Here are your updated {{referralService.typeOfInstallmentsForConfirmPopup}}:</p>
        </div>

        <div class="greyBox">
            <div class="upcomingPayments">
                <h3>Upcoming payments</h3>
                <table border="0" summary="">
                    <tr>
                      <th>Date</th>
                      <th>Original</th>
                      <th>New</th>
                    </tr>
                    <tr *ngFor="let usageData of referralService.builderPointsUsageData">
                      <td>{{usageData.Date}}</td>
                      <td class="strickText">{{dataService.user?.currency?.symbol}}{{usageData.orignal}}</td>
                      <td class="purpleColor">{{dataService.user?.currency?.symbol}}{{usageData.new_amount}}</td>
                    </tr>
                </table>
            </div>
        </div>
      </div>
      <!--Box five close-->
      
    </div>
</div>
