import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminLoginComponent} from './components/authentication/login/admin-login.component';
import {AdminAuthGuardService} from './services/auth-guard.service';
import {OverviewComponent} from './components/overview/overview.component';
import {BuildcardDetailComponent} from './components/build-card-detail/build-card-detail.component';

const ROUTER_CONFIG: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: AdminLoginComponent
  },
  {
    path: 'overview',
    component: OverviewComponent,
    canActivate: [AdminAuthGuardService]
  },
  {
    path: 'buildcard/:id',
    component: BuildcardDetailComponent,
    canActivate: [AdminAuthGuardService]
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
    canActivate: [AdminAuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(ROUTER_CONFIG)],
  exports: [RouterModule]
})
export class PaymentDashboardRoutingModule {
}
