<div class="newLoginPage">
    <button type="button" class="backButton" (click)="navigateToStorePage()" *ngIf="isRegisteredUser">
      <em class="icon-prev"></em>
      Back to {{ phaseService.rentalTemplateAlias.charAt(0).toUpperCase() + phaseService.rentalTemplateAlias.slice(1) }}
    </button>
    <button type="button" class="backButton" (click)="navigateToLoginScreen()"
            *ngIf="!dataService.user && !isRegisteredUser">
      <em class="icon-prev"></em> Back to Step 1
    </button>

    <div class="newLoginSection">
        <div class="loginSteps">
            <ul>
                <li class="current" [ngClass]="{ 'stepDone': !isRegisteredUser }"></li>
                <li class="" [ngClass]="{ 'current': !isRegisteredUser }"></li>
                <li></li>
            </ul>
        </div>
        <!--login box start-->
        <div class="loginBoxHolder">
            <div class="socialLoginsBox" *ngIf="isRegisteredUser">
                <h3>Creat an account or Sign in</h3>
                <div class="socialLogins">
                    <button type="button" (click)="socialLogin('google')"><img src="../../../../assets/images/googlelogin.svg" alt="" /></button>
                    <button type="button" (click)="socialLogin('linkedin')"><img src="../../../../assets/images/linkedinIcon.svg" alt="" /></button>
                    <button type="button" (click)="socialLogin('facebook')"><img src="../../../../assets/images/facebook_logo_signupmodal.svg" alt="" /></button>
                </div>
                <button type="button" class="useEmail" (click)="useEmail()">Use E-mail</button>
            </div>
            <ng-container *ngIf="useEmailClicked">
              <form [formGroup]="loginSignupForm">
                <div class="loginRegisterBox" [hidden]="!isRegisteredUser">
                  <div class="inputRow">
                    <label>E-mail</label>
                    <input type="email" placeholder="name@company.com" formControlName="email">
                    <div class="errorMsg" *ngIf="(email.touched && checkIfEmailValid())">Please enter valid email</div>
                  </div>
                  <div class="inputRow">
                    <label>Password</label>
                    <input type="password" placeholder="Enter password" formControlName="password" minlength="8" (focus)="removeErrors()">
                    <span *ngIf="invalidPassword && !(password.touched && password.invalid)" class="errorMsg"> Password is not valid</span>
                    <div *ngIf="password.touched && password.invalid">
                      <span *ngIf="password.errors['minlength']" class="errorMsg"> Password should contain 8 characters</span>
                      <span *ngIf="password.errors['required']" class="errorMsg"> Password cannot be blank </span>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="!isRegisteredUser">
                  <div class="loginRegisterBox">
                    <h3>Personal</h3>
                    <div class="inputRow divideHalf">
                      <div class="halfBox">
                        <label>First Name</label>
                        <input type="text" placeholder="Enter First Name" formControlName="first_name">
                        <div *ngIf="first_name.touched && first_name.invalid">
                          <span *ngIf="first_name.errors['required']" class="errorMsg"> Please enter first name </span>
                        </div>
                      </div>
                      <div class="halfBox">
                        <label>Last Name</label>
                        <input type="text" placeholder="Enter Last Name" formControlName="last_name">
                        <div *ngIf="last_name.touched && last_name.invalid">
                          <span *ngIf="last_name.errors['required']" class="errorMsg"> Please enter last name </span>
                        </div>
                      </div>
                    </div>

                    <div class="inputRow">
                      <label>Phone Number</label>
                      <app-phone-number [contactNumber]="phone_number.value" [isSaveButtonClicked]="isSaveButtonClicked" (setContactInUserInfo)="setUserContact($event)">
                      </app-phone-number>
                    </div>
                  </div>
                  <div class="loginRegisterBox">
                    <h3>Store</h3>
                    <div class="inputRow divideHalf">
                      <div class="halfBox">
                        <label>Store Name</label>
                        <div class="tooltipInfo">
                          <em class="icon-info-circle"></em>
                          <div class="tooltipContent">What do you want your store to be called? Can use your existing business name or anything you like – and you can change it later if you need to.</div>
                        </div>
                        <input type="text" placeholder="Enter your store name" formControlName="store_name">
                      </div>
                      <div class="halfBox">
                        <label>Country</label>
                        <div class="tooltipInfo">
                          <em class="icon-info-circle"></em>
                          <div class="tooltipContent">Where are you based?</div>
                        </div>

                        <div class="customDropdown" [ngClass]="{'active' : countryDropdown}">
                          <div class="selectedItem" (click)="countryToggle()">
                            <div class="itemIcon"><img [src]="selectedCountry?.image" alt="" /></div>
                            <div class="itemText" formControlName="app_country">{{ selectedCountry?.name }}</div>
                          </div>

                          <div class="dropdownList bottom-side">
                            <ul>
                              <li *ngFor="let country of countries" (click)="setCountry(country)">
                                <div class="itemIcon"><img src="{{ country?.image }}" alt="" /></div>
                                <div class="itemText">{{ country?.name }}</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="inputRow">
                      <label>Preferred currency</label>
                      <div class="tooltipInfo">
                        <em class="icon-info-circle"></em>
                        <div class="tooltipContent">How we'll charge you - the currency where you're based.</div>
                      </div>

                      <div class="customDropdown"  [ngClass]="{'active' : currencyDropdown}">
                        <div class="selectedItem" (click)="currencyToggle()">
                          <div class="itemIcon"><img [src]="utilityService.addHttps(selectedCurrency?.icon_image_file_url)" alt="" /></div>
                          <div class="itemText" formControlName="currency_id">{{ selectedCurrency?.code }}</div>
                        </div>

                        <div class="dropdownList">
                          <ul>
                            <li *ngFor="let currency of getCurrencies()" (click)="selectCurrency(currency, $event)">
                              <div class="itemIcon"><img  [src]="utilityService.addHttps(currency?.icon_image_file_url)" alt="" /></div>
                              <div class="itemText">{{ currency?.name }}</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="tncRow">
                      <input type="checkbox" id="tnc" formControlName="termsAndConditions">
                      <label for="tnc"></label>
                      <p>I agree to the <span>Privacy Policy</span> and <span>Privacy Policy</span></p>
                    </div>
                  </div>
                </ng-container>
              </form>
              <div class="submitButton">
                <button type="button" (click)="loginOrUpdateProfile()" [disabled]="CheckIfFormValid()">Continue</button>
              </div>
            </ng-container>

        </div>
        <!--login box close-->
        <!--register box start-->
        <!--<div class="loginBoxHolder" *ngIf="!isRegisteredUser">


            <div class="submitButton">
                <button type="button">Continue</button>
            </div>

        </div>-->
        <!--register box close-->


    </div>
</div>
