import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '@shared/shared.module';
import {CommonModule} from '@angular/common';
import {Ng2PageScrollModule} from 'ng2-page-scroll';
// import {Ng2CarouselamosModule} from 'ng2-carouselamos';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ProjectSummaryComponent} from '@featuremodules/summary-module/project-summary/project-summary.component';
import {ShareinviteModule} from '../../../shared/shareinvite-module/shareinvite-module';
import {UtilityService} from '@shared/services/utility.service';
import {PaymentSummaryModule} from '@featuremodules/payment-summary-module/payment-summary.module';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';

const childRouterConfig: Routes = [
  {path: '', component: ProjectSummaryComponent}
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(childRouterConfig),
        SharedModule,
        Ng2PageScrollModule.forRoot(),
        // Ng2CarouselamosModule,
        PerfectScrollbarModule,
        ShareinviteModule,
        PaymentSummaryModule,
        CKEditorModule
    ],
    declarations: [ProjectSummaryComponent],
    providers: [UtilityService]
})
export class SummaryModule {
}

