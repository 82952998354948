import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {Angulartics2Segment} from 'angulartics2/segment';
import {PhaseModel} from '@models/phase-model/phase.model';
import {PhaseService} from '@shared/services/phase.service';
import {FeatureModel} from '@models/feature-model/feature.model';
import {DataService} from '@shared/services/data.service';
import {UtilityService} from '@shared/services/utility.service';
import { Intercom } from 'ng-intercom';
import {DeliveryService} from '@featuremodules/delivery-module/services/delivery.service';
import {CommonService} from '@shared/services/common.service';
import { Console } from 'console';
import { title } from 'process';

@Component({
  selector: 'feature-slider',
  templateUrl: 'feature-slider.component.html',
  styleUrls: ['feature-slider.component.scss']
})

export class FeatureSliderComponent implements OnInit {
  @Input() phase: PhaseModel;
  @Input() showFeatureList: boolean;
  @Input() editableFeatureList: boolean;
  @Output() featureSliderCallBack: EventEmitter<any> = new EventEmitter();
  public selectedFeatures: FeatureModel[];
  public searchQuery;
  public PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
  };
  platformsList = [];
  ifFeatureClicked = false;

  constructor(public phaseService: PhaseService,
              public analyticsSegment: Angulartics2Segment,

              public dataService: DataService, private commonService: CommonService,
              public utilityService: UtilityService, public delieryService: DeliveryService) {
  }

  public ngOnInit() {
    this.selectedFeatures = [...this.phaseService.selectedFeatures];
  }

  public closeFeatureSlider() {
    this.ifFeatureClicked = false;
    this.featureSliderCallBack.emit();
  }

  selectFeatureInAllPlatforms(index, feature: FeatureModel, event, platform) {
    // feature = JSON.parse(JSON.stringify(feature));
    if (event.target.checked) {
      const phasePlatforms = JSON.parse(JSON.stringify(this.phase.platforms));
      this.phase.platforms.forEach(p => {
        if (!feature.phaseId) { feature.phaseId = []; }
        let alreadyInPlatforms = feature.phaseId.filter(phaseId => phaseId.charAt(1) === p.id.toString());
        if (alreadyInPlatforms.length) {
          alreadyInPlatforms = alreadyInPlatforms.map(el => parseInt(el.charAt(1), 10));
          const i = phasePlatforms.findIndex(pl => alreadyInPlatforms.includes(pl.id));
          phasePlatforms.splice(i, 1);
        }
      });
      phasePlatforms.forEach(pElement => {
        if (this.phaseService.isPlatformRequest && !this.delieryService.checkIfApprovedPlatform(pElement)) {
          this.featureSubsetHandler(index, feature, event, pElement, false, true);
        } else if (!this.phaseService.isPlatformRequest) {
          this.featureSubsetHandler(index, feature, event, pElement, false, true);
        }
      });
      this.phaseService.updatePrice();
    } else {
      this.featureSubsetHandler(index, feature, event, platform);
    }
  }

  public featureSubsetHandler(index, feature: FeatureModel, event, platform, selectAllFeature?,selectUnselectAll?) {
    this.ifFeatureClicked = true;
    this.dataService.homePageData.build_phases.forEach(phase => {
      if ((phase['type'] === 'custom_phase') && (!phase.platforms || (phase.platforms.length === 0))) { phase.features = []; }
    });
    if ((event && event.target.checked) || selectAllFeature) {
      this.dataService.homePageData.build_phases.forEach(phase => {
        if (((phase['type'] === 'custom_phase') || (phase.title === 'MVP')) && (phase.id !== this.phase.id)) {
          phase.features = JSON.parse(JSON.stringify(phase.features));
        }
      });
      if (!feature.phaseId) { feature.phaseId = []; }
      if (!feature.phaseId.includes(this.phase.id + '' + platform.id)) {
        feature.phaseId.push(this.phase.id + '' + platform.id);
      }
      this.phase.platforms = JSON.parse(JSON.stringify(this.phase.platforms));
      const featureData = JSON.parse(JSON.stringify(this.selectedFeatures.find(ftr => ftr.id === feature.id)));
      featureData.mvpSelected = true;
      this.phaseService.selectedFeatures.filter(ftr => {
        if (!feature.platforms) { feature.platforms = []; }
        feature.platforms = JSON.parse(JSON.stringify(feature.platforms));
        if (ftr.id === feature.id) {
          platform['phaseId'] = this.phase.id;
          feature.platforms.push(platform);
          featureData.platforms = feature.platforms;
        }
      });
      // const mandateFeatureData = this.phaseService.mandatoryFeatures.find(ftr => ftr.id === feature.id);
      // if( mandateFeatureData ) { mandateFeatureData.selected = true; }

      const phaseFeatures = this.phase.features.map(ftr => ftr.id);
      if (phaseFeatures.indexOf(featureData.id) !== -1) {
        this.phase.features.forEach(ftr => {
          if (ftr.id === featureData.id) {
            ftr.platforms = featureData.platforms;
            ftr.phaseId = featureData.phaseId;
          }
        });
      } else {
        const phaseIDs = featureData.phaseId.map(id => id.charAt(1));
        phaseIDs.forEach(() => {
          featureData.phaseId.forEach((ph, index1) => {
            if (ph.charAt(0) !== this.phase.id.toString()) {
              featureData.phaseId.splice(index1, 1);
            }
          });
        });
        const ftrPlatforms = featureData.platforms.map(p => p.id);
        ftrPlatforms.forEach(() => {
          featureData.platforms.forEach((plt, index2) => {
            const ftrPhasePlatformsIDs = featureData.phaseId.map(id => id.charAt(1));
            if (!ftrPhasePlatformsIDs.includes(plt.id.toString())) {
              featureData.platforms.splice(index2, 1);
            }
          });
        });
        this.phase.features.push(featureData);
        this.dataService.homePageData.build_phases.forEach(phase => {
          if ((phase['type'] === 'custom_phase') && (!phase.platforms || (phase.platforms.length === 0))) { phase.features = []; }
        });
      }
    } else {
      const featureData = Object.assign({}, this.selectedFeatures.find(ftr => ftr.id === feature.id));
      featureData.platforms = Object.assign([], featureData.platforms);
      featureData.mvpSelected = false;
      featureData.platforms.splice(featureData.platforms.findIndex(element => element.id === platform.id), 1);
      featureData.phaseId.splice(featureData.phaseId.findIndex(element => element === (this.phase.id + '' + platform.id)), 1);
      // const mandateFeatureData = this.phaseService.mandatoryFeatures.find(ftr => ftr.id === feature.id);
      // if( mandateFeatureData ) { mandateFeatureData.selected = true; }
      const index = this.phase.features.findIndex(f => f.id === featureData.id);
      if (!featureData.platforms || !featureData.platforms.length) {
        feature.platforms = [];
        feature.phaseId = [];
        this.phase.features.splice(index, 1);
      } else {
        this.phase.features.forEach(ftr => {
          if (ftr.id === featureData.id) {
            const featurePhaseIds = [];
            ftr.phaseId = featureData.phaseId;
            ftr.platforms = Object.assign([], featureData.platforms);
            feature.platforms = Object.assign([], featureData.platforms);
            feature.phaseId = featureData.phaseId;
            feature.phaseId.forEach(pId => {
              featurePhaseIds.push(pId.charAt(0));
            });
            if (featurePhaseIds.indexOf(this.phase.id.toString()) === -1) {
              this.phase.features.splice(index, 1);
            }
          }
        });
      }
      if (this.phase.features.length === 0) {
        this.phase.selected = false;
        this.phaseService.removeSelectedBuild(this.phase);
        this.phaseService.removeSelectedBuildId(this.phase.index);
        this.phaseService.removeSelectedBuildIds(this.phase.index);
        this.closeFeatureSlider();
      }
    }
    if (!selectUnselectAll) {
      this.phaseService.updatePrice();
    }
  }

  isFeatureSelected(platForm, feature) {
    if (!feature.platforms) { return; }
    const platformsList = feature.platforms.map(platform => platform.title);
    if (platformsList.includes(platForm.title) && (feature.phaseId && feature.phaseId.includes(this.phase.id + '' + platForm.id))) {
      return true;
    }
  }

  getItDisabled(feature) {
    // if (this.ifFeatureClicked) { return; }
    this.platformsList = [];
    if (!feature.platforms) { return; }
    this.platformsList = feature.platforms.map(platform => platform.title);
    // if ((this.delieryService.customPhaseFeatures.indexOf(feature.id) !== -1) && (this.delieryService.phaseFeatures.indexOf(feature.id) === -1)) {
      return true;
    // } else {
    //   return false;
    // }
  }

  isFeatureChecked(platForm, feature) {
    if (!feature.platforms) {
      return;
    }
    const platformsList = feature.platforms.map(platform => platform.title);
    if (platformsList.includes(platForm.title) && (feature.phaseId && feature.phaseId.includes(this.phase.id + '' + platForm.id))) {
      return true;
    }
  }

  showToasterForPreSelected(isPreSelected) {
    if (isPreSelected) {
      this.commonService.showError({ error: { message: 'This feature is already selected as a part of another phase' } });
    }
  }

  selectAllFeature(selectAllFeature, platform) {
    if (!selectAllFeature) {
      for(let i=(this.selectedFeatures.length - 1); i >= 0; i--) {
        // if (this.selectedFeatures[i].selected) {
          this.featureSubsetHandler(i, this.selectedFeatures[i], null, platform, selectAllFeature,true);
        // }
      }
      platform['selectAllFeature'] = false;
    } else {
      for(let i=0; i < this.selectedFeatures.length; i++) {
        // if (!this.selectedFeatures[i].selected) {
          this.featureSubsetHandler(i, this.selectedFeatures[i], null, platform,  selectAllFeature,true);
        // }
      }
      platform['selectAllFeature'] = true;
    }
    this.phaseService.updatePrice();
  }

  selectUnselectActive() {
      this.phase.features.forEach((feature) => {
        for (let i = 0; i < feature.platforms.length; i++) {
          let platform = this.phase.platforms.find(x => x.title === feature.platforms[i].title);
          let index = this.phase.platforms.indexOf(platform);
          if (platform) {
            this.phase.platforms[index]['selectAllFeature']=true;
          }
        }
      });
  }
}

