<div class="templateSection">
  <div class="templateTop">
    <div class="templateSearch">
      <h3 class="visible-xs">Select similar apps to your idea.</h3>
      <ul>
        <li>
          <label>I want to build {{getAppTemplateName()}} like</label>
          <div class="searchInput">
            <input type="text" [(ngModel)]="searchKeyWord" (keyup)="onFirstSearchChange($event)"/>
            <span class="inputloader" *ngIf="firstSearchLoader"><img src="../../../../../assets/images/rolling.gif"
                                                                     alt=""/></span>
            <span class="clear" (click)="deleteFirstSearch()"><em class="icon-crossnew"></em></span>
          </div>
        </li>
        <li>
          <label>For (optional)</label>
          <div class="searchInput">
            <input type="text" [(ngModel)]="getSelectedHash().target_user_group"
                   placeholder="Ex. doctors, teachers, mothers etc"/>
            <span class="clear" (click)="setTargetUserGroup()"><em class="icon-crossnew"></em></span>
          </div>
        </li>
        <li class="hidden-xs"><label></label>
          <button type="button" class="searchButton"><em class="icon-magnifying"></em></button>
        </li>
      </ul>
    </div>

    <div class="templateToolbar">
      <div class="templateFilter" *ngIf="filter && !searchMode">
        <div class="filterIcon" (click)="clickFilter()"><em class="icon-fillter"></em></div>
        <div class="filterClose" (click)="clickFilter()" [ngClass]="{active: showFilter}"></div>
        <div class="filterItemBox" [ngClass]="{active: showFilter}" *ngIf="showFilter">
          <div class="filterItemHolder"><!----yaha fancy scroll lagega-->
            <div class="filterSection">
              <div class="filterRow">
                <h4>Categories</h4>
                <ul class="categoryList" [ngClass]="{'active': filter.categories.length - categoryLimitNumber<=0}">
                  <li *ngFor="let category of filter.categories | slice:0:categoryLimitNumber">
                    <input type="checkbox" [(ngModel)]="category.selected" (change)="onCategoryChange(category)"
                           id="cat{{category.id}}">
                    <label for="cat{{category.id}}">{{category.title}} ({{category.template_count}})</label>
                  </li>
                </ul>
                <button class="moreButton" type="button" (click)="updateCategoryLimit(filter.categories.length)"
                        *ngIf="filter.categories.length - categoryLimitNumber>0">+{{filter.categories.length -
                categoryLimitNumber}} more
                </button>
                <button class="moreButton" type="button" (click)="updateCategoryLimit(5)"
                        *ngIf="filter.categories.length - categoryLimitNumber<=0">View Less
                </button>
              </div>

              <div class="filterRow">
                <h4>Template Cost</h4>
                <div class="rangeSlider">
                  <ngx-slider
                    [(value)]="filterCost[0]"
                    [(highValue)]="filterCost[1]"
                    [options]="optionsFilterCost"
                    (userChangeStart)="onFilterCostChange($event)"
                    (userChange)="onFilterCostChange($event)"
                    (userChangeEnd)="onFilterCostChange($event)" #sliderTemplateCost></ngx-slider>
                  <div class="minValue">
                    {{getCurrencySymbol()}}{{getLocaledPrice((filterCost[0] | number))}}
                  </div>
                  <div class="maxValue">
                    {{getCurrencySymbol()}}{{getLocaledPrice((filterCost[1] | number))}}
                  </div>
                </div>
              </div>

              <div class="filterRow">
                <h4>Development time</h4>
                <div class="rangeSlider">
                  <ngx-slider
                    [(value)]="filterDuration[0]"
                    [(highValue)]="filterDuration[1]"
                    [options]="optionsFilterDuration"
                    (userChangeStart)="onFilterDurationChange($event)"
                    (userChange)="onFilterDurationChange($event)"
                    (userChangeEnd)="onFilterDurationChange($event)" #sliderTemplateDuration></ngx-slider>
                  <div
                    class="minValue">{{Math.floor(filterDuration[0])}} {{Math.floor(filterDuration[0]) > 1 ? 'weeks' : 'week'}}</div>
                  <div
                    class="maxValue">{{Math.ceil(filterDuration[1])}} {{Math.floor(filterDuration[0]) > 1 ? 'weeks' : 'week'}}</div>
                </div>
              </div>

              <div class="filterRow">
                <h4>Supported platforms</h4>
                <ul>
                  <li *ngFor="let platform of filter.platforms">
                    <input type="checkbox" [(ngModel)]="platform.selected" (change)="onPlatformChange(platform)"
                           id="radio{{platform.id}}">
                    <label for="radio{{platform.id}}">{{platform.title}}</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="templateSorting">
        <div class="sortingIcon"><em class="icon-sortby"></em></div>
        <div class="sortItems">
          <h4>Sort by</h4>
          <ul>
            <li (click)="onSortByChange(sorting.value, i)" [ngClass]="{'active': selectedSortIndex === i}"
                *ngFor="let sorting of sortingTypes; let i = index">{{sorting.name}}</li>
          </ul>
        </div>
      </div>

      <div class="searchButton visible-xs">
        <div class="searchIcon"><em class="icon-magnifying"></em></div>
      </div>

      <div class="customTemplate" (click)="showAddNewTemplatePopup()">
        <em class="icon-plus"></em>
        <span>Custom Template</span>
      </div>

    </div>

    <div class="templateSelected" *ngIf="getSelectedApps().length > 0">
      <h3>Selected templates</h3>
      <ul>
        <li *ngFor="let template of getSelectedApps()"><span>{{template.title}}</span> <em
          (click)="onTemplateClicked(template, true);">x</em></li>
      </ul>
    </div>

  </div>

  <div class="templateListing withlock" infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="300"
       (scrolled)="onScroll()">

    <div class="resultFound" *ngIf="searchKeyWord && searchMode">{{dataService.apps.length}} apps found
      for {{searchKeyWord}}</div>
    <button type="button" *ngIf="searchMode" class="backButton" (click)="onBackFromSearch()"><em class="icon-prev"></em>
      Back
    </button>

    <div class="templateRow" *ngIf="getRequestedTemplateList().length > 0 && !searchMode">
      <div class="templateBox" (click)="onTemplateClicked(app)" [ngClass]="app.selected ? 'active' : ''"
           *ngFor="let app of getRequestedTemplateList()">
        <div class="editIcon" (click)="onRequestedAppEditClicked(app);$event.stopPropagation();"><em class="icon-edit"></em></div>
        <h3>{{app.title}}</h3>
        <p>{{app.description}}</p>
        <div class="tickBox"></div>
        <img
          [src]="(utilityService.addHttps(app.app_builder_icon_url) ? (utilityService.addHttps(app.app_builder_icon_url)): ('https://s3.ap-south-1.amazonaws.com/builder.engineer.ai/template_builder_logo/Default.png'))"
          *ngIf="app.icon" width="96" height="96" alt=""/>
        <img src="../../../../../assets/images/template_icon.png" width="40" height="40" *ngIf="!app.icon" alt=""/>
        <div class="editIcon" (click)="onRequestedAppEditClicked(app);$event.stopPropagation();"><em class="icon-edit"></em></div>
      </div>
    </div>

    <div class="templateRow" *ngIf="getAppTemplates().length > 0">
      <div class="templateBox" (click)="onTemplateClicked(app)" *ngFor="let app of getAppTemplates()"
           [ngClass]="app.selected ? 'active' : ''">
        <h3>{{app.title}}</h3>
        <p>{{app.description}}</p>
        <div class="tickBox"></div>
        <img
          [src]="(utilityService.addHttps(app.app_builder_icon_url) ? (utilityService.addHttps(app.app_builder_icon_url)): ('../../../../../assets/images/template_default.png'))"
          *ngIf="app.icon" width="96" height="96" alt=""/>
        <img src="../../../../../assets/images/template_icon.png" width="40" height="40" *ngIf="!app.icon" alt=""/>
        <div class="appListFooter">
          <div class="appPrice" *ngIf="!dataService.showLoader">
            <span>Price</span>
            <strong [ngClass]="{strick: phaseService.AppDiscountSuccessfullyApplied}">{{getCurrencySymbol()}}{{getLocaledPriceInt((app.template_price / 1000) | number : '1.0-0')}} K</strong>
          </div>
          <div *ngIf="phaseService.showApplicationDiscount()" class="appLockPrice" (click)="phaseService.showSignUpForDiscount(app.title,true,true, 'unlock');$event.stopPropagation();">
            <span>{{getCurrencySymbol()}}{{getLocaledPriceInt(((app.template_price) * ((100 - this.dataService.couponObject?.discount_percentage) / 100)) / 1000 | currConvert : dataService.dataLoadingCurrency : dataService.homePageData.currency | number : '1.0-0')}} K
            </span>
            <div class="priceTooltip">Log in to unlock the discount</div>
          </div>

          <div *ngIf="phaseService.showApplicationDiscount()" class="blurPrice" (click)="phaseService.showSignUpForDiscount(app.title,true,true);$event.stopPropagation();">
                        <span class="blurThis">{{getCurrencySymbol()}}{{getLocaledPriceInt(((app.template_price) * ((100 - this.dataService.couponObject?.discount_percentage) / 100)) / 1000 | currConvert : dataService.dataLoadingCurrency : dataService.homePageData.currency | number : '1.0-0')}} K</span>
            Log in to get the deal
                      </div>
          <div *ngIf="dataService.user && phaseService.AppDiscountSuccessfullyApplied" class="appDiscountPrice">
            <strong>{{getCurrencySymbol()}}{{getLocaledPriceInt(((app.template_price) * ((100 - this.dataService.couponObject?.discount_percentage) / 100)) / 1000| number : '1.0-0')}} K</strong>
          </div>
          <a [href]="phaseService.getFreemiumRedirectionLink(app)" target="_blank" class="btn apps-freemium" *ngIf="app?.freemium" (click)="preventBubling($event)" > Get for Free </a>
          <a [href]="phaseService.getRedirectionLink(app)" target="_blank" class="btn apps-detailbtn" (click)="preventBubling($event)"> View Details </a>
        </div>
      </div>
    </div>

    <div class="searchNotFound" *ngIf="templateLoaded && getAppTemplates().length<=0">
      <img src="../../../../../assets/images/searchNotFound.png" alt=""/>
      <h3>NO RESULTS FOUND</h3>
      <h4>We searched far and wide and couldn’t find<br> any template matching your search.</h4>
      <button type="button" class="button1" (click)="showAddNewTemplatePopup()"><em class="icon-plus"></em> Custom
        Template
      </button>
    </div>

  </div>

</div>

<div class="commonPopUp" [ngClass]="{active: showDetailPoupToggle}" *ngIf="popupApp && showDetailPoupToggle">
  <div class="popOverlay" (click)="hideDetailPopup()"></div>
  <div class="popHolder tempDetailPopup">
    <div class="closePopUp" (click)="hideDetailPopup()"><span class="icon-cancel"></span></div>

    <div class="tempTopPart" [ngClass]="{active: popupApp.screenshots && popupApp.screenshots.length > 0}">
      <div class="tempIcon">
        <img
          [src]="popupApp.icon ? utilityService.addHttps(popupApp.icon) : './../../../../assets/images/templateDefault.png'"
          alt="">
      </div>
      <div class="tempDetail">
        <h3>{{popupApp.title}}</h3>
        <p>{{popupApp.description}}</p>
        <div class="tempLinks" *ngIf="popupApp.reference_urls && popupApp.reference_urls.length > 0">
          <div class="linkRow" *ngFor="let link of popupApp.reference_urls">{{link.product_name}}</div>
        </div>
      </div>
      <ul>
        <li *ngFor="let categoryname of categoryNamesOnPopup">{{categoryname}}</li>
      </ul>
    </div>
    <div class="imageSlider" *ngIf="popupApp.screenshots && popupApp.screenshots.length > 0">

    </div>
    <div class="footerButton">
      <button class="cancleButton" (click)="hideDetailPopup()">Cancel</button>
      <button class="doneButton" [ngClass]="{remove: popupApp.selected}"
              (click)="onTemplateClicked(popupApp); hideDetailPopup();">
        {{popupApp.selected ? "Remove" : "Add"}}
      </button>
    </div>

  </div>
</div>


<!----popup for confirmation---->
<div class="commonPopUp" [ngClass]="{active: deleteAll}" *ngIf="deleteAll">
  <div class="popOverlay"></div>
  <div class="popHolder confirmPopup">
    <div class="closePopUp" (click)="closeDeleteAllPopup()"><span class="icon-cancel"></span></div>

    <h3>Are you sure you want to remove all selections?</h3>
    <div class="footerButton">
      <button class="cancleButton" (click)="closeDeleteAllPopup()">Cancel</button>
      <button class="doneButton" (click)="removeAllSelections()">
        OK
      </button>
    </div>

  </div>
</div>

<addnewtemplate *ngIf="phaseService.showAddNewTemplate"></addnewtemplate>
