<div class="displaySection" *ngIf="section && section?.trending"
           [ngStyle]="{'order': section.trending?.priority_order}">
  <app-trending-apps [trendingSection]="sectionsData?.trending" [trending]="section?.trending"></app-trending-apps>
</div>

<div class="displaySection" *ngIf="section && section?.problem_group"
     [ngStyle]="{'order': section?.problem_group.priority_order}">
  <question-type [problem_groupSection]="sectionsData?.problem_group"
                 [problem_group]="section?.problem_group"></question-type>
</div>

<div #offersection class="displaySection" *ngIf="section && section?.offers && !phaseService?.hideOffers"
     [ngStyle]="{'order': section?.offers.priority_order}">
  <app-offers [offersSection]="sectionsData?.offers" [offers]="section?.offers"></app-offers>
</div>

<div class="displaySection" *ngIf="section && section?.promo_video"
     [ngStyle]="{'order': section?.promo_video.priority_order}">
  <video-view [promo_videoSection]="sectionsData?.promo_video" [promo_video]="section?.promo_video"></video-view>
</div>

<div class="displaySection" *ngIf="section && section?.sibling_promotions"
     [ngStyle]="{'order': section?.sibling_promotions?.priority_order}">
  <other-product [sibling_promotionsSection]="sectionsData?.sibling_promotions"
                 [sibling_promotions]="section?.sibling_promotions"></other-product>
</div>
