import { Injectable } from '@angular/core';
import { HttpRequests } from '@shared/services/http-requests.service';
import { environment } from '../../../../environments/environment';
import { DataService } from './data.service';
import { AppDataService } from '@rootmodule/app-data.service';
import { CommonService } from './common.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UpgradePlanService {
  upgradePlanData: any;
  starterPlanAmount = 0;
  storePlanAmount = 0;
  monthCount = 0;

  constructor(public httpRequest: HttpRequests, public dataService: DataService, public appDataService: AppDataService,
    public commonService: CommonService) { }

  getUpgradePlanPrices(buildCardId) {
    let API = environment.API_URL + 'api/v1/build_cards/' + buildCardId + '/upgrade_plan_prices';
    this.dataService.showHideLoader(true);
    let headers = this.appDataService.getRequestOptionArgs();
    headers = headers.append("apptoken", "iCxBWGyfgL89NPCwkc-i");
    
    this.httpRequest.get(API, headers).subscribe((response) => {
      this.dataService.showHideLoader(false);
      this.upgradePlanData = response;
      if (this.upgradePlanData) {
        this.starterPlanAmount = this.upgradePlanData.starter_deposit_amount;
        this.storePlanAmount = this.upgradePlanData.store_deposit_amount;
        this.monthCount = this.upgradePlanData.month_count;
      }
    }, (error) => {
      this.commonService.showError();
    });
  }

}
