import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Constants} from '@shared/utils/constants';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {AppDataService} from '@rootmodule/app-data.service';

@Component({
  selector: 'invalidaccess',
  templateUrl: 'invalidaccess.component.html'
})

export class InvalidAccessComponent {
  public dataService;
  public phaseService;
  public router;

  /*@ngInject*/
  constructor(phaseService: PhaseService, dataService: DataService, router: Router, private analyticsSegment: Angulartics2Segment) {
    this.phaseService = phaseService;
    this.dataService = dataService;
    this.router = router;
    this.dataService.currentPage = 'invalidAccess';
  }

  ngOnInit() {
    this.dataService.invalidAccess = true;
    this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.invalidaccess.code);
  }

  public getUserName() {
    return this.dataService.user ? this.dataService.user.email : '';
  }
}
