import {AppHomeModel} from '../apphome-model/apphome.model';
import {OffersModel} from '../offer-model/offer.model';
import {VideoModel} from '../video-model/video.model';
import {ProductHomeModel} from '../producthome-model/producthome.model';
import {SectionModel} from '../section-model/section.model';

/**
 * Created by nitin on 14/12/17.
 */
export class IndsectiondataModel {
  constructor(section, section_details) {
    this.section = section;
    this.section_details = section_details;
  }

  section: SectionModel;
  section_details: Array<AppHomeModel | OffersModel | ProductHomeModel | VideoModel>;
}
