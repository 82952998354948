import {Component, OnInit} from '@angular/core';
import {BuildCardService} from '../../../services/buildcard.service';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '@shared/services/data.service';
import * as moment from 'moment';
import {GenericService} from '../../../services/generic.service';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import { PhaseService } from '@shared/services/phase.service';

@Component({
  selector: 'app-payment-details',
  templateUrl: 'payment-details.component.html'
})

export class PaymentDetailsComponent implements OnInit {
  public isProjectCostEditable = false;
  public isTotalWeeksEditable = false;
  public isPriceEditable = false;
  public isSpecCallSet = 'no';
  public dateSelected: any = {};
  public moment = moment;
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd.mm.yyyy',
    disableWeekends: true,
    inline: true
    // other options are here...
  };

  myDateInit = true;
  model: IMyDateModel = null;

  constructor(public buildCardService: BuildCardService,
              private _dataService: DataService,
              private _genericService: GenericService,
              private _route: ActivatedRoute, public phaseService: PhaseService) {
  }

  public ngOnInit() {
    this.getPaymentDetailsOnReload();
    this.setDateInfo('kickoffDate');
    this.setDateInfo('initialDate');
    this.setDateInfo('finalDate');
  }

  public setDateInfo(type): void {
    if (this.myDateInit) {
      // Initialize to specific date (14.05.2019) with IMyDate object
      this.model = {isRange: false, singleDate: {date: {
            year: 2019,
            month: 5,
            day: 14
          }}};
    } else {
      // Initialize to today with javascript date object
      this.model = {isRange: false, singleDate: {jsDate: new Date()}};
    }
    /*this.dateSelected[type] = {};
    this.dateSelected[type].options = {
      dateFormat: 'dd.mm.yyyy',
    };
    if (type === 'kickoffDate') {
      this.dateSelected[type].date = this._genericService.handleDateForDatePicker(this.buildCardService.currentBuildCard.kick_off_date);
    } else {
      this.dateSelected[type].date = new Date();
    }
    if (type === 'finalDate') {
      this.dateSelected[type].options.disableUntil = {date: {year: 2018, month: 7, day: 9}};
    }*/
  }

  public setSpecCall(flag): void {
    this.isSpecCallSet = flag;
  }

  public editToggleInput(type): void {
    switch (type) {
      case 'price':
        this.isPriceEditable = !this.isPriceEditable;
        break;
      case 'weeks':
        this.isTotalWeeksEditable = !this.isTotalWeeksEditable;
        break;
      case 'total_cost':
        this.isProjectCostEditable = !this.isProjectCostEditable;
        break;
    }
  }

  public getPaymentDetails(id): void {
    this._dataService.showHideLoader(true);
    this.buildCardService.getPaymentDetails(id)
      .finally(() => {
        this._dataService.showHideLoader(false);
      })
      .subscribe();
  }

  public getPaymentDetailsOnReload(): void {
    this._route.params.subscribe(params => {
      const id = params['id'];
      this.getPaymentDetails(id);
    });
  }

  public viewInvoice(url): void {
    window.open(url, '_blank', "noopener");
  }

  public onDateChange(event: Event) {
  }

  public getSecurityDeposit(): number {
    if (this.buildCardService.paymentDetails && this.buildCardService.paymentDetails.installment) {
      const index = this.buildCardService.paymentDetails.installment.findIndex(installment => {
        return installment.installment_type === 'deposit';
      });
      if (this.buildCardService.paymentDetails.installment[index]) {
        return this.buildCardService.paymentDetails.installment[index].installment_amount || 0;
      } else {
        return 0;
      }
    }
  }

  onDateChanged(event: IMyDateModel): void {

  }

  getDiscountedprice(card) {
    return this.phaseService.getDiscountedPriceForCards(card);
  }

}
