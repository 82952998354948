import {FeatureModel} from '../feature-model/feature.model';

/**
 * Created by nitin on 14/12/17.
 */
export class BundleModel {

  background_url: string;

  title: string;

  id: number;

  bundle_template_names: Array<string>;

  total_features: number;

  selected_feature_count: number;

  features: FeatureModel[];
  selected: boolean;
}
