import {Component, EventEmitter, Input, OnInit, Output, HostListener} from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';

@Component({
  selector: 'app-payment-area',
  templateUrl: './payment-area.component.html',
  styleUrls: ['./payment-area.component.scss']
})
export class PaymentAreaComponent  {

  @Output() pexitPaymentStatus = new EventEmitter();

  @Input ('user') user;
  innerWidth = 991;

  constructor(public phaseService: PhaseService, public dataService: DataService) {
    this.innerWidth = window.innerWidth;
  }



  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
  }

  public getPaymentBuildCard() {
    return this.phaseService.paymentBuildCard;
  }

  public getPaymentShowStatus() {
    return this.phaseService.showPaymentStatus;
  }

  public getHomePagedata() {
    return this.dataService.homePageData;
  }

  public exitPaymentStatus() {
    this.pexitPaymentStatus.emit();
  }

  public getCurrencySymbol() {
    return this.phaseService.paymentBuildCard.currency.symbol;

  }

  public getTotalWithTax() {
    return this.phaseService.getTotalWithTaxForPaymentArea();
  }

  public getTotalAmountPaid() {
    return this.phaseService.getTotalAmountPaid();
  }

  public getBalanceAmount() {
    return this.phaseService.getBalanceAmount();
  }

  public getPreviousCurrency(card) {
    return !card.owner ? card.currency : this.dataService.previousCurrency;
  }

  public getLoaderStatus() {
    return this.dataService.showLoader;
  }

  public getIsProfile() {
    return this.phaseService.isProfile;
  }

  public handleInvoiceReceipt(installment) {
    window.open(installment.receipt ? installment.receipt : installment.invoice, '_blank', "noopener");
  }
  public downloadInvoiceReceipt(installment): void {
    const link = document.createElement('a');
    link.download = 'Invoice Receipt';
    link.target = '_blank';
    link.href = installment.receipt ? installment.receipt : installment.invoice;
    link.click();
  }

  public gotoBilling(installment) {
    window.location.href = installment.pay_now_url;
  }

  public getPaymentData() {
    return this.phaseService.paymentData;
  }

  public getDeposit() {
    return this.phaseService.deposit;
  }

  public getLocaledPrice(price: any) {
    return this.dataService.getLocaledPrice(price);
  }

  public getInstallmentWithTax(installment) {
    return this.phaseService.getInstallmentWithTax(installment);
  }

  public getSupportMaintenance() {
    return this.phaseService.supportMaintenance;
  }

  showCareType(careType) {
    if (careType === 'enterprise_care_plus') {
      return 'Enterprise Care Plus';
    } else if (careType === 'enterprise_care') {
      return 'Enterprise Care';
    } else if (careType === 'standard_care_plus') {
      return 'Standard Care Plus';
    } else if (careType === 'standard_care') {
      return 'Standard Care';
    }
  }

}
