export class BuilderPartnerModel {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  partner_name: string;
  company_name: string;
  company_size: string;
  spoc_name: string;
  spoc_role: string;
  industry_type: string;
  website_link: string;
  is_verified: boolean;
  is_approved: boolean;
  logo_url: string;
  code: string;
  partner_type: string;
  tnc_url: string;
  privacy_policy_url: string;
  nda_signed: boolean;
  signature_request_guid: string;
  signature_request_status: boolean;
  status: string;
  variance_percentage: null;
  authtoken: string;
  is_customer_allowed: boolean;
  small_logo_url: string;
  currencies: Array<any>;
  show_waitlist: boolean;
  builder_live_url: string;
  builder_now_url: string;
  partner_multiplier: string;
}

