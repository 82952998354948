import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'defaultcurrConvert'})
@Injectable()
export class DefaultCurrencyConvertPipe implements PipeTransform {

  public transform(value: number, outputputRate: any): number {
    if (outputputRate.exchange_rate != 1) {
      return value * outputputRate.exchange_rate;
    } else {
      return value;
    }
  }
}
