import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, HostListener} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiRequests} from '@shared/services/api-requests.service';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import {TemplateDetailModel} from '@models/template-detail-model/template.detail.model';
import {DragScrollComponent} from 'ngx-drag-scroll';
import {Constants} from '@shared/utils/constants';
import {TeamsModel} from '@models/teams-model/teams.model';
import {ModelMapperService} from '@shared/services/model-mapper.service';
import {AppDataService} from '@rootmodule/app-data.service';
import {Subscription} from 'rxjs';
import {Angulartics2Segment} from 'angulartics2/segment';
import {SeoService} from '@shared/services/seo.service';
import {Intercom} from 'ng-intercom';
import {CommonService} from '@shared/services/common.service';
import { BuildCardModel } from "src/app/shared/shared-module/models/build-card-model/build-card.model";

@Component({
  selector: 'app-template-detail',
  templateUrl: './template-detail.component.html',
  styleUrls: ['./template-detail.component.scss']
})
export class TemplateDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  public template_alias;
  public appDetails: TemplateDetailModel;
  leftPlatformNavDisabled = false;
  rightPlatformNavDisabled = false;
  leftFeatureNavDisabled = false;
  rightFeatureNavDisabled = false;
  leftImageNavDisabled = false;
  rightImageNavDisabled = false;
  isReadyToProceed = false;
  innerWidth = 0;
  public tempPlatforms;
  public currentUrl;
  private currencyChangeSubscription: Subscription;
  public limit = 150;
  public isFromHomeScreen = false;
  @ViewChild('navPlatformSlider', {read: DragScrollComponent}) ds: DragScrollComponent;
  @ViewChild('navFeatureSlider', {read: DragScrollComponent}) fds: DragScrollComponent;
  @ViewChild('navImageSlider', {read: DragScrollComponent}) ids: DragScrollComponent;
  paramTemplateAlias: any;
  isCurrencyModified = false;
  constructor(public route: ActivatedRoute,
              public apiRequest: ApiRequests,
              public dataService: DataService,
              public phaseService: PhaseService,
              public router: Router,
              public appDataService: AppDataService,
              private modelMapperService: ModelMapperService,
              public analyticsSegment: Angulartics2Segment,

              private seoService: SeoService,
              public commonService: CommonService
  ) {
  }

  ngOnInit() {
    if (this.appDataService.urlParameters.redirect_to_login) {
      this.dataService.showHideLoader(true);
    }
    this.checkToShowSignUpModal();
    this.dataService.buildCardData = new BuildCardModel();
    this.fetchParams();
    this.innerWidth = window.innerWidth;
    this.dataService.currentPage = 'templateDetail';
    if ( this.dataService.appDetails) {
      this.appDetails = this.dataService.appDetails;
    } else {
      this.dataService.showAppDetailsLoader = true;
      this.dataService.appDetails = this.appDetails;
      if (this.appDataService.urlParameters && this.appDataService.urlParameters.currency_id) {
          this.isFromHomeScreen = true;
        }
        this.apiRequest.fetchHomePageData().subscribe((data: any) => {
          this.dataService.dataLoadingCurrency = Object.assign({}, this.dataService.homePageData.currency);
          this.phaseService.platformPrices = Array.apply(null, Array(this.dataService.homePageData.platforms.length));
          this.phaseService.platformPrices.map((x, i) => {
            return i;
          });
          if (this.dataService.homePageData.currency) {
            this.phaseService.specing_price = this.dataService.homePageData.currency.specing_price;
          }
          this.getTemplateAlias();
        });
        this.setUpCurrencyChangeSubscription();
        this.phaseService.rapidPrototypePlatforms = this.dataService.homePageData.prototype_platforms ? Object.assign([], [...this.dataService.homePageData.prototype_platforms]) : [];
      }
  }

  checkToShowSignUpModal() {
    if (this.appDataService.urlParameters.redirect_to_login) {
      this.router.navigate(['login']);
    }
  }

  fetchParams() {
    this.route.queryParams.subscribe(params => {
      if (params['ccavenue'] === 'true') {
        this.dataService.isForCcAvenue = true;
      }
    });
  }

  fetchTeams() {
    this.apiRequest.fetchTeams().subscribe((data: any) => {
      data.teams = this.modelMapperService.getTeamMappedModel(
        new TeamsModel(),
        data.teams
      );
      this.phaseService.selectedTeam = data.teams.default;
      this.phaseService.selectedHash.team_id = data.teams.default.id;
      this.preSelectOptions();
      if (this.phaseService.checkIfRentalPricingModal() || this.phaseService.isRentalBuildCard()) {
        this.phaseService.selectedHash.cloud_opted = false;
      } else {
        this.phaseService.getCloudDetails(true);
      }
      this.phaseService.getCloudDetails(true);
    });
  }


  setUpCurrencyChangeSubscription() {
    this.currencyChangeSubscription = this.phaseService.getCurrencyUpdatedCallback().subscribe(isCurrencyChanged => {
      if (isCurrencyChanged && !(this.phaseService.checkIfRentalPricingModal() || this.phaseService.isRentalBuildCard())) {
        this.dataService.showPriceLoader = true;
        this.dataService.showBuilderCarePriceLoader = true;
        this.isCurrencyModified = true;
        if (this.paramTemplateAlias.indexOf(' ') !== -1) {
          this.paramTemplateAlias = this.paramTemplateAlias.split(' ').join('-').toLowerCase();
        }
        this.apiRequest.fetchTemplateDetails(this.paramTemplateAlias, true).subscribe((data: any) => {
          this.appDetails = this.dataService.templateDetailModel;
          this.dataService.appDetails = this.appDetails;
          this.phaseService.selectedApps = [];
          this.phaseService.updatePrice();
        });

      }
    });
  }

  public ngAfterViewInit() {
    this.phaseService.checkExitIntentTimer();
  }


  getTemplateAlias() {
    this.template_alias = this.route.params.subscribe((params: any) => {
      if (params.template_alias) {
        this.paramTemplateAlias = params.template_alias;
        if (params.template_alias.indexOf(' ') === -1) {
          this.openTemplate(params.template_alias, false);
        } else {
          this.openTemplate(params.template_alias.split(' ').join('-').toLowerCase(), false);
        }
      }
    });
  }

  handleFlowForStudioStore() {
    if (this.appDataService.urlParameters.cpe_user) {
      this.apiRequest.fetchSingleBuildCardData(this.appDataService.urlParameters.uniq_code).subscribe((data: any) => {
        if (!((this.dataService.user && this.dataService.user.roles === 'CPE') && !this.dataService.buildCardData.owner)) {
          this.dataService.invalidAccess = true;
          this.router.navigate(['invalidaccess']);
        } else {
          this.dataService.homePageData.currency = this.dataService.buildCardData.currency;
        }
      }, error => {
        if (this.commonService.isPlatformBrowser && (error.status === 403 || error.status === 401 || error.status === 404)) {
          this.dataService.invalidAccess = true;
          this.router.navigate(['invalidaccess']);
        }
      });
    }
  }

  openTemplate(templateAlias, currencyFlag) {
    if (this.appDataService.urlParameters.redirect_to_login) {
      this.dataService.showHideLoader(true);
    }
    if (this.appDataService.urlParameters.cpe_user && !this.dataService.user) {
      this.phaseService.openCommonAuth = true;
      if (this.dataService.homePageData?.block_sign_up) {
        this.phaseService.showSignin = true;
      } else {
        this.phaseService.showRegister = true;
      }
    } else {
      this.handleFlowForStudioStore();
    }
    this.phaseService.rentalTemplateAlias = templateAlias;
    this.apiRequest.fetchTemplateDetails(templateAlias, currencyFlag).subscribe((data: any) => {
        this.phaseService.showTemplateLoader = false;
        this.appDetails = this.dataService.templateDetailModel;
        this.dataService.appDetails = this.appDetails;
        this.dataService.isDirectCheckOut = this.appDetails.direct_checkout;
        this.dataService.isRestrictEdit = this.appDetails.restrict_edit;
        const ogTitle = this.appDetails && this.appDetails.meta_title ? this.appDetails.meta_title : this.appDetails.title;
        const ogDescription = this.appDetails && this.appDetails.meta_description ? this.appDetails.meta_description : this.appDetails.description;
        this.seoService.updateMetaTags({ogTitle: ogTitle, ogImage: this.appDetails.web_cover_image_url, description: ogDescription});
        this.dataService.showAppDetailsLoader = false;
        this.fetchTeams();
      },
      (error) => {
        this.router.navigate(['home']);
        this.dataService.showAppDetailsLoader = false;
      });
  }

  preSelectOptions() {
    this.phaseService.selectedHash.application_ids = [];
    this.phaseService.selectedApps = [];
    this.phaseService.mandatoryFeatures = [];
    this.phaseService.selectedHash.application_ids.push(this.appDetails.id);
    if (this.appDetails.features && this.appDetails.features.length > 0) {
      this.phaseService.selectedFeatures = Object.assign([], [...this.appDetails.features]);
      // this.toggleSelectedAppsById(this.appDetails);
      this.phaseService.mandatoryFeatures = this.appDetails.features.filter(feature => feature.is_mvp_feature);
      this.preSelectPlatforms();
      this.phaseService.selectDefaultPhases();
      if (!this.isCurrencyModified) {
        this.dataService.homePageData.speed.reverse();
      }
      this.phaseService.selectedSpeed = this.dataService.homePageData.speed[0];
      this.phaseService.selectedHash.speed_id = this.phaseService.selectedSpeed.id;
      this.phaseService.selectedHash.source = 'app_detail';
      this.phaseService.initialAppPhases = Object.assign([...this.phaseService.selectedHash.build_phase_vars]);
      this.phaseService.fetchFeatures();
      this.phaseService.updatePrice();
      // this.trackEventondDetailPage();
      this.isReadyToProceed = (this.dataService.homePageData.platforms.filter(x => x.selected === true).length > 0);
    } else {
      this.router.navigate(['apps']);
    }
    if (this.phaseService.isRentalBuildCard()) {
      if (this.dataService.buildCardData.upfront) {
        this.phaseService.selectUpfrontPackage = true;
        this.phaseService.selectMonthlyPackage = false;
      } else {
        this.phaseService.selectMonthlyPackage = true;
        this.phaseService.selectUpfrontPackage = false;
      }
    }
  }

  public toggleSelectedAppsById(app: any): void {
    const selectedApp = this.phaseService.selectedApps.find(x => x.id === app.id);
    if (selectedApp) {
      const index = this.phaseService.selectedApps.indexOf(selectedApp);
      this.phaseService.selectedApps.splice(index, 1);
    } else {
      this.phaseService.selectedApps.push(app);
      if (this.dataService.buildCardData && !this.dataService.buildCardData.id) {
        if (this.dataService.user && (this.phaseService.selectedApps[0].pricing_modal === 'rental_price')) {
          this.dataService.homePageData.currency = this.dataService.user.currency;
        } else if (!this.dataService.user && (this.phaseService.selectedApps[0].pricing_modal === 'rental_price')) {
          this.dataService.homePageData.currency = this.appDetails.currency;
        }
      }
    }
  }

  public preSelectPlatforms() {
    this.phaseService.selectedPlatforms = [];
    this.phaseService.selectedHash.platform_ids = [];
    if (this.dataService.homePageData.platforms) {
      const platforms = this.dataService.homePageData.platforms;
      if (this.appDetails && this.appDetails.platforms && this.appDetails.platforms.length > 0) {
        for (const platform of platforms) {
          platform.selected = false;
          this.appDetails.platforms.forEach((element, index) => {
            if (element.id === platform.id) {
              platform.selected = true;
              this.phaseService.toggleSelectedPlatform(platform);
              this.phaseService.toggleSelectedPlatformId(platform.id);
            }
          });
        }
      } else {
        platforms[0].selected = true;
        this.phaseService.toggleSelectedPlatform(platforms[0]);
        this.phaseService.toggleSelectedPlatformId(platforms[0].id);
      }
      this.tempPlatforms = Object.assign([], [...this.dataService.homePageData.platforms]);
      this.sortPlatforms();
    }
  }

  public sortPlatforms() {
    this.tempPlatforms = this.tempPlatforms.sort((a, b) => {
      return a.selected === b.selected ? 0 : a.selected ? -1 : 1;
    });

    return this.tempPlatforms;
  }


  moveLeft(source: string) {
    switch (source) {
      case 'imageSlider':
        this.ids.moveLeft();
        break;
      case 'platformSlider':
        this.ds.moveLeft();
        break;
      case 'featureSlider':
        this.fds.moveLeft();
        break;
      default:
        break;
    }
  }

  moveRight(source: string) {
    switch (source) {
      case 'imageSlider':
        this.ids.moveRight();
        break;
      case 'platformSlider':
        this.ds.moveRight();
        break;
      case 'featureSlider':
        this.fds.moveRight();
        break;
      default:
        break;
    }
  }

  leftBoundStat(reachesLeftBound: boolean, source: string) {
    switch (source) {
      case 'imageSlider':
        this.leftImageNavDisabled = reachesLeftBound;
        break;
      case 'platformSlider':
        this.leftPlatformNavDisabled = reachesLeftBound;
        break;
      case 'featureSlider':
        this.leftFeatureNavDisabled = reachesLeftBound;
        break;
      default:
        break;
    }
  }

  rightBoundStat(reachesRightBound: boolean, source: string) {
    switch (source) {
      case 'imageSlider':
        this.rightImageNavDisabled = reachesRightBound;
        break;
      case 'platformSlider':
        this.rightPlatformNavDisabled = reachesRightBound;
        break;
      case 'featureSlider':
        this.rightFeatureNavDisabled = reachesRightBound;
        break;
      default:
        break;
    }
  }

  public getLocaledPrice(price: any): any {
    return this.dataService.getLocaledPrice(price);
  }

  public ngOnDestroy() {
    if (this.dataService.user == null) {
      this.phaseService.showProjectNamePopup = false;
      this.dataService.isFromAppDetails = false;
      this.dataService.isDirectCheckOut = false;
      this.dataService.isFromViewPrototype = false;
      this.phaseService.buildCardEditMode = false;
      this.dataService.totalSelectedFeatures = 0;
      this.phaseService.isBuildCardSetup = false;
      this.dataService.landingPage = '';
      this.phaseService.showRegister = false;
      this.dataService.appDetails = null;
      this.phaseService.reset(true);
    }
    this.isFromHomeScreen = false;
    this.dataService.isFromAppDetails = false;
    if (this.currencyChangeSubscription) {
      this.currencyChangeSubscription.unsubscribe();
    }
  }

}
