import {Component, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DataService} from '@shared/services/data.service';
import {UtilityService} from '@shared/services/utility.service';

@Component({
  selector: 'video-view',
  styleUrls: ['video.component.scss'],
  templateUrl: 'video.component.html'
})

export class VideoComponent {

  @Input('promo_video') promo_video;
  @Input('promo_videoSection') promo_videoSection;
  videoUrl;
  showLoader = true;
  showVideoPopuop = false;

  constructor(public sanitizer: DomSanitizer, public dataService: DataService, public utilityService: UtilityService) {
  }

  primarySections = [];

  ngOnInit() {
    if (this.promo_video.primary_section) {
      this.primarySections = this.promo_video.primary_section;
      if (this.primarySections.length > 0) {
        this.showLoader = false;
      }
    }
  }

  ngOnChanges() {
    if (this.promo_videoSection) {
      this.primarySections = this.promo_videoSection.section_details;
      if (this.primarySections.length > 0) {
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.utilityService.addHttps(this.primarySections[0].video_url.replace('watch?v=', 'embed/')));
        this.showLoader = false;
      }
    }
  }

  openVideoPopup() {
    this.showVideoPopuop = !this.showVideoPopuop;
  }
}
