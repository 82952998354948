import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent  {

  @Output() pshowCards = new EventEmitter();

  @Input('showCompleted') showCompleted;
  @Input('showInProgress') showInProgress;
  @Input('showPaid') showPaid;
  @Input('showRecentComplete') showRecentComplete;
  @Input('showSpecCalls') showSpecCalls;
  @Input('showRecentRunning') showRecentRunning;
  @Input('showRecentProgress') showRecentProgress;
  @Input('showRecentSpecCalls') showRecentSpecCalls;
  @Input('showInvited') showInvited;
  @Input('showArchived') showArchived;
  @Input('showMoreIndex') showMoreIndex;

  public loaderCount = 0;
  public specRedirection;
  showDummyLoader: boolean;
  cancelSpecCallModal = false;
  specCallId: number;

  constructor(public dataService: DataService, public phaseService: PhaseService, public apiRequest: ApiRequests) { }



  public showCards(type) {
    this.pshowCards.emit(type);
  }

  public getHomePagedata() {
    return this.dataService.homePageData;
  }

  public getLocaledPrice(price: any) {
    return this.dataService.getLocaledPrice(price);
  }

  public goToSpecBilling() {
    this.specRedirection = true;
    this.phaseService.isFromSpecPayment =true;
  }

  onScroll(cardCount: number) {
    this.showDummyLoader = true;
    if (this.showMoreIndex !== 0 && window.pageYOffset > 0) {
      if (this.dataService.loadMore) {
        if (this.dataService.isScrolled) {
          if (cardCount > 10) {
            let pageNo = 0;
            if (this.phaseService.buildCardPage[this.showMoreIndex]) {
              pageNo = (this.phaseService.buildCardPage[this.showMoreIndex] > 0) ? this.phaseService.buildCardPage[this.showMoreIndex] : 0;
            }
            this.loaderCount = (((pageNo + 1) * 10) >= cardCount) ? 0 : (4 - (((pageNo + 1) * 10) % 4));
          }
          this.dataService.isScrolled = false;
          this.phaseService.buildCardPage[this.showMoreIndex]++;
          // tslint:disable-next-line:max-line-length
          this.apiRequest.fetchBuildCards(this.phaseService.buildCardPage[this.showMoreIndex], this.phaseService.perPageCardCount, this.phaseService.currentSelectedBuildCardStatus).pipe(
            finalize(() => {
              this.loaderCount = 0;
            }))
            .subscribe(
              () => {
                this.dataService.isScrolled = true;
              }
            );
        }
      }
    }
  }

  showHideSpecCallCancelModal(id) {
    this.specCallId = id;
    this.cancelSpecCallModal = !this.cancelSpecCallModal;
  }

  cancelSpecCall() {
    this.dataService.showHideLoader(true);
    this.apiRequest.cancelSpecCall(this.specCallId).subscribe(() => {
      const cancelledSpecCall = this.dataService.specCards.find(specCall => specCall.id === this.specCallId);
      cancelledSpecCall['meeting_status'] = 'cancelled';
      this.cancelSpecCallModal = false;
      this.dataService.showHideLoader(false);
    }, error => {
      this.dataService.showHideLoader(false);
    });
  }

}
