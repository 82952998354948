import {Component} from '@angular/core';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import {PartnerDashboardDataService} from '../../../Utils/partner-dashboard-data.service';
import {environment} from '@env/environment';
import {PartnerDashboardApiService} from '../../../Utils/partner-dashboard-api.service';

@Component({
  selector: 'app-partner-payment-submission',
  templateUrl: 'partner-payment-submission.component.html'
})

export class PartnerPaymentSubmissionComponent {

  constructor(public dataService: DataService,
              public partnerDataService: PartnerDashboardDataService,
              private _partnerApiService: PartnerDashboardApiService,
              public phaseService: PhaseService) {
  }

  public getAppToken() {
    return '9ut4ECNZXKlh5oBKclp_MA';
  }

  public goToPaymentGateway(form) {
    this.partnerDataService.partnerStatus = 'payment_selected';
    this._partnerApiService.updatePartnerProfile()
      .subscribe(
        (res) => {
          this.partnerDataService.setPartnerUser(res);
          form.submit();
        });
  }

  public getRedirectUrl() {
    return window.location.origin + '/become-a-partner';
  }

  public getFrontendUrl() {
    return window.location.origin + '/become-a-partner';
  }

  public getNotifyUrl() {
    return environment.API_URL + 'payment_billing/builder_partners/card_status';
  }

  public getGatewayUrl() {
    if (environment.production) {
      return 'https://payments.engineer.ai/cards/initiate';
    } else {
      return 'https://staging-payments.engineer.ai/cards/initiate';
    }
  }

  getDashboardUrl() {
    return window.location.origin + '/dashboard';
  }
}
