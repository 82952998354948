/**
 * Created by nitin on 14/12/17.
 */
import {PlatformModel} from '../platform-model/platform.model';
import {FeatureModel} from '../feature-model/feature.model';
import {TeamModel} from '../teams-model/team.model';
import {SpeedModel} from '../speed-model/speed.model';

export class PhaseModel {
  background: string;
  description: string;
  icon: string;
  id: number;
  index: number;
  position: number;
  price_multiplier: number;
  title: string;
  week_multiplier: number;
  selected: boolean;
  platforms: Array<PlatformModel>;
  features: Array<FeatureModel>;
  team: TeamModel;
  speed: SpeedModel;
  active: boolean;
  feature_ids: Array<string>;
  platform_ids: Array<string>;
  speed_id: string;
  initial_date: any;
  final_date: any;
  price: number;
  weeks: number;
  discounted_price: number;
  is_parallel_platform: boolean;
  build_card_phase_id: any;
  delivery_time: any;
  phase_id: string;
  custom_title: string;
  is_tailor_made: boolean;
  calculated_weeks: number;
}
