import {AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DataService} from '../../services/data.service';
import {environment} from '@env/environment';
import {Form} from '@angular/forms';
import {PhaseService} from '../../services/phase.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {ApiRequests} from '../../services/api-requests.service';
import { Intercom } from 'ng-intercom';
import {AppDataService} from '@rootmodule/app-data.service';
import {Router} from '@angular/router';
import { ReferralService } from '@shared/services/referral.service';
import {PaymentBuilderService} from '@featuremodules/payment-builder-module/payment-builder.service';
import {ChangeFeatureRequestModel} from '@models/feature-model/change-feature-request.model';
import {CommonService} from '@shared/services/common.service';
import {ModelMapperService} from '@shared/services/model-mapper.service';
import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import e from 'express';
import {TaxDetailsService} from '@shared/services/tax-details.service';

@Component({
  selector: 'specing-payment',
  templateUrl: 'specing-payment.component.html',
  styleUrls: ['./specing-payment.component.scss']
})
export class SpecingPaymentComponent implements OnInit, AfterViewInit , OnChanges{

  @Input() paymentAmount;
  @Input() paymentId;
  @Input() buildCardInstallment;
  @Input('billingForm') billingForm: Form;
  @Input('billingInfo') billingInfo;
  @Input() isFromPayConfirm;
  @Input() isInstantSpecPayment;
  @Input() pageNameForSuccessScreen: string;
  @Input() showToolTip: boolean;
  @Input() isFromHeader: boolean;
  @Input() taxAmount;
  @Input() paymentAmountWithoutTax;
  @Input() isFromBuilderCare;
  isContinueEnable = false;
  checkToDisable = false;
  @ViewChild('invoiceForm') public invoiceForm;
  isMobile = false;
  showTooltipForMobile = false;
  twoPayStatusTimer: any;
  twoPayFullFillTimer: any;
  @ViewChild('iframe') iframe: ElementRef;
  capchaseStatusTimer: any;
  capchaseFullFillTimer: any;

  constructor(public dataService: DataService, public phaseService: PhaseService, private apiRequests: ApiRequests, public referralService: ReferralService,
              private analyticsSegment: Angulartics2Segment, public appDataService: AppDataService,
              public router: Router, public paymentService: PaymentBuilderService,
              public commonService: CommonService, private modelMapperService: ModelMapperService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
      console.log(this.paymentId, 'paymentId')
  }
  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (event.target && event.target['className'] && event.target['className'].includes('onlyShowMobile')) {
      return false;
    } else {
      this.showTooltipForMobile = false;
    }
  }
  getPaymentId() {
    return this.appDataService?.urlParameters?.isCustomPrototype ? this.getPaymentData()?.custom_prototype?.id : this.getPaymentData()?.pending_installment?.id
  }

  getPaymentData() {
    return this.phaseService.paymentData;
  }
  public getRedirectUrl() {
    if (this.appDataService.urlParameters.isCustomPrototype) {
      return window.location.origin + `?paymentId=${this.paymentId}&build_card_id=${this.dataService.buildCardData.id}&payment_type=custom_prototype&prototype_payment=custom_prototype`;
    }
    if (this.buildCardInstallment) {
      let url = this.phaseService.getRedirectUrl() + '&build_card_id=' + this.dataService.buildCardData.id + (this.isFromBuilderCare ? '&payment_type=installmentis_From_Builder_Care' : '&payment_type=installment');
      if (this.dataService.homePageData.promotion && this.phaseService.promotionValid) {
        if (url.includes('?')) {
          url = url + '&promotion=' + this.dataService.homePageData.promotion.code;
        } else {
          url = url + '?promotion=' + this.dataService.homePageData.promotion.code;
        }
      }
      return url;
    } else if (this.isInstantSpecPayment) {
      return window.location.origin + `?specing_id=${this.paymentId}&payment_type=instant_specing&success_page=${this.pageNameForSuccessScreen}`;
    } else {
      return window.location.origin + `?specing_id=${this.paymentId}&payment_type=specing`;
    }

  }

  ngAfterViewInit() {
    if (this.isFromBuilderCare) {
      this.gotoStatus(this.invoiceForm.nativeElement);
    }
  }

  ngOnInit() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    this.dataService.submitAddress.subscribe((resp) => {
      if (resp === 'go_to_payment_page') {
        this.gotoStatus(this.invoiceForm.nativeElement);
      }
    });
    if (!this.buildCardInstallment) {
      setTimeout(() => {
        this.submitForm();
      }, 500);
    }
  }

  public gotoStatus(paymentForm) {
    if (!this.phaseService.contractTermsConditionsChecked) {
      if (this.isMobile) {
        this.showTooltipForMobile = true;
      }
      return false;
    }
    if ((this.commonService.isTwoPayRegCompany && this.commonService.startTwoPayFlow) ||
      (this.paymentService.startCapchaseFlow)) {
      this.signContractForBNPLAndProceed();
    } else {
      let frequency = '';
      if (this.dataService.showMonthly) {
        frequency = 'monthly';
      } else if (this.dataService.showWeekly) {
        frequency = 'weekly';
      } else if (this.dataService.showUpfront) {
        frequency = 'upfront';
      }
      const installmentType = { frequency: frequency };
      if (installmentType.frequency !== this.dataService.buildCardData.frequency) {
        this.dataService.showHideLoader(true);
        this.apiRequests.createUpdateBuildCard(installmentType, this.phaseService.inProgressBuildCardId, '', 'rename_build_card').subscribe(() => {
          this.fetchInstallments(paymentForm);
        });
      } else {
        // this.fetchInstallments(true);
        this.phaseService.trackEventForObject(this.analyticsSegment, 'payment_progressed', {
          user_id : this.dataService.user?.id
        });
        this.phaseService.billingInfo.name = this.phaseService.billingInfo.firstname + (this.phaseService.billingInfo.lastname ? ' ' + this.phaseService.billingInfo.lastname : '');
        this.phaseService.billingInfo.address = this.phaseService.billingInfo.apartment + (this.phaseService.billingInfo.street ? ' ' + this.phaseService.billingInfo.street : '');
        this.proceedForPayment(paymentForm);
      }
      if (this.dataService.buildCardData.uniq_code) {
        this.phaseService.trackEventForObject(this.analyticsSegment,  'payment_plan_confirmed', {
          user_id: this.dataService.user?.id
        });
      }
    }
  }

  proceedForPayment(paymentForm) {
    const promotion = this.dataService.buildCardData.promotion;
    const is100PercentPromo = (promotion && (promotion.code === '100percent'));
    const reqParams = {
      nda_signed: true,
      is_custom_prototype: this.appDataService.urlParameters.isCustomPrototype ? true : false,
      msa_signed_at: this.paymentService.msaOpenedAt
    };
    this.apiRequests.setResetContractsSigned(this.dataService.buildCardData.id, reqParams).subscribe(data => {
      if (this.billingForm) {
        this.apiRequests.updateUserAddress(this.phaseService.billingInfo).subscribe(data => {
          this.dataService.user = data.user;
          this.dataService.storeUserObject();
          if ((!this.phaseService.getDiscountedPriceForCards(this.dataService.buildCardData)) && is100PercentPromo) {
            this.apiCallForZeroBuildCard();
          } else {
            paymentForm.submit();
          }
        });
      } else {
        if ((!this.phaseService.getDiscountedPriceForCards(this.dataService.buildCardData)) && is100PercentPromo) {
          this.apiCallForZeroBuildCard();
        } else {
          paymentForm.submit();
        }
      }
      // this.referralService.confirmRewardPayment();
    }, error => this.apiRequests.showErrorMessage(error, true));
  }

  apiCallForZeroBuildCard() {
    this.apiRequests.makeZeroPriceBuildCard(this.dataService.buildCardData.uniq_code).subscribe(resp => {
      this.router.navigate([`buildcard-payment/${this.dataService.buildCardData.id}`, 'status']);
    });
  }

  public submitForm() {
    const myForm = <HTMLFormElement>document.getElementById('specingPaymentForm');
    myForm.submit();
  }

  public getAPIURL() {
    return environment.API_URL;
  }

  getCurrencyCode() {
    return ((this.phaseService.isFromSpecPayment || this.phaseService.isFromInstantPrototype) ? this.dataService.user.currency.code : this.dataService.buildCardData.currency.code);
  }

  public getPaymentsUrl() {
    if (this.dataService.buildCardData.currency) {
      return this.dataService.buildCardData.currency.code === 'INR' ? environment.PAYMENT_URL_INR : environment.PAYMENT_URL + 'checkouts/initiate';
    } else if (this.phaseService.isFromSpecPayment) {
      return this.dataService.user.currency.code === 'INR' ? environment.PAYMENT_URL_INR : environment.PAYMENT_URL + 'checkouts/initiate';
    }
  }

  public getAppToken() {
    return environment.PAYMENT_APP_TOKEN;
  }

  getDashboardUrl() {
    return window.location.origin + '/dashboard';
  }

  getBillingDetailsUrl() {
    return `${window.location.origin}/buildcard-payment/${this.dataService.buildCardData.uniq_code}/billingdetails`;
  }

  getPaymentPlanUrl() {
    if (!this.appDataService.urlParameters.isCustomPrototype && !this.isFromBuilderCare) {
      return `${window.location.origin}/buildcard-payment/${this.dataService.buildCardData.uniq_code}/paymentplan`;
    } else {
      return '';
    }
  }

  toggleToolTip(showMsg) {
    if (showMsg) {
      this.isContinueEnable = true;
      this.checkToDisable = !this.phaseService.contractTermsConditionsChecked;
    } else {
      this.isContinueEnable = false;
      this.checkToDisable = false;
    }
  }

  public  getPaymentGateway() {
    return (this.getCurrencyCode() && this.getCurrencyCode() === 'INR' ? 'ccavenue' : 'stripe');
  }

  redirectToPaymentStatus() {
    return environment.API_URL + 'buildcard-payment/' + this.dataService.buildCardData.id + '/status';
    // this.router.navigate(['buildcard-payment/' + this.dataService.buildCardData.id + '/status']);
  }

  fetchInstallments(paymentForm) {
    this.dataService.showHideLoader(true);
    if (this.phaseService.inProgressBuildCardId) {
      this.apiRequests.fetchInstallments(this.phaseService.inProgressBuildCardId, this.phaseService.paidPhaseId).subscribe((data: any) => {
        if (data) {
          this.phaseService.paymentData = data;
          if (this.phaseService.getPriceForCards(this.dataService.buildCardData) !== this.phaseService.getDiscountedPriceForCards(this.dataService.buildCardData)) {
            this.phaseService.costWithoutDiscount =
              Number(this.phaseService.getPriceForCards(this.dataService.buildCardData)) +
              Number(Math.round(((Number(this.phaseService.getPriceForCards(this.dataService.buildCardData)) * Number(this.phaseService.paymentData.general_info.tax))
                / 100))
              );
          }
          if (this.phaseService.paymentData) {
            if (this.phaseService.paymentData.general_info) {
              this.phaseService.totalWeeks = this.phaseService.paymentData.general_info.weeks ? this.phaseService.paymentData.general_info.weeks : 0;
            }
            if (this.phaseService.paymentData.installments && this.phaseService.paymentData.installments.length > 0) {
              this.phaseService.noOfInstallments = this.phaseService.paymentData.installments.filter(installment => installment.type === 'installment').length;
            }
            if (this.phaseService.paymentData.pending_installment) {
              if (this.phaseService.paymentData.pending_installment.invoice_params
                && this.phaseService.paymentData.pending_installment.invoice_params.invoice) {
                this.phaseService.dateOfFirstPayment = this.phaseService.pipe.transform(this.phaseService.paymentData.pending_installment.invoice_params.invoice.date, 'DD-MMM-YYYY');
              }
            }
          }
          this.phaseService.installments = this.phaseService.paymentData.installments.filter((installment) => {
            return installment.type === 'installment';
          });

          this.phaseService.deposit = this.phaseService.paymentData.installments.find(installmemt => {
            return installmemt.type === 'deposit';
          });

          this.phaseService.supportMaintenance = this.phaseService.paymentData.installments.find(installment => {
            return installment.type === 'support';
          });
          if (this.phaseService.supportMaintenance) {
            this.phaseService.builderCareAmount = this.phaseService.supportMaintenance;
          }
          this.phaseService.installmentAmt = this.phaseService.installments && this.phaseService.installments[0] && this.phaseService.installments[0].installment_amount ? this.phaseService.installments[0].installment_amount : 0;
          this.phaseService.getInstallmentCount();
        }
        this.phaseService.isInstallmentsAPIResolved = true;
        this.phaseService.getBuildCardPriceWithDiscount();
        this.phaseService.showCouponLoader = false;
        this.dataService.showHideLoader(false);
        this.phaseService.trackEventForObject(this.analyticsSegment, 'payment_progressed', {
          user_id : this.dataService.user?.id
        });
        this.phaseService.billingInfo.name = this.phaseService.billingInfo.firstname + (this.phaseService.billingInfo.lastname ? ' ' + this.phaseService.billingInfo.lastname : '');
        this.phaseService.billingInfo.address = this.phaseService.billingInfo.apartment + (this.phaseService.billingInfo.street ? ' ' + this.phaseService.billingInfo.street : '');
        this.dataService.showHideLoader(false);
        this.proceedForPayment(paymentForm);
      }, (error) => {
        this.phaseService.isInstallmentsAPIResolved = true;
        this.phaseService.getBuildCardPriceWithDiscount();
        this.phaseService.showCouponLoader = false;
        this.dataService.showHideLoader(false);
      });
    }
  }

  getWeeks() {
    const modifyData = this.dataService.modifiedBuildCardData;
    return ((modifyData && modifyData.weeks && modifyData.weeks.new) ? modifyData.weeks.new : this.dataService.buildCardData.weeks);
  }

  getBillingAccountID(paymentType) {
    const billingData = this.dataService.user['external_billing_accounts'];
    if (billingData.length > 0) {
      const capchaseData = billingData.find(data => data['service_provider'] === paymentType);
      if (capchaseData) {
        return capchaseData['reference_uuid'];
      }
    }
  }

  getCreditCheckData() {
    return this.commonService.twoPayCreditCheckData;
  }

  proceedForTwoPayPayment() {
    const buildCardData = this.dataService.buildCardData;
    const changeCost = (buildCardData.change_price_status ? buildCardData.change_amount : -buildCardData.change_amount);
    const payload = {
      'build_card_data': {
        'id': this.dataService.buildCardData.id,
        'project_name': this.dataService.buildCardData.project_name,
        'description': this.dataService.buildCardData.description
      },
      'price_data': {
        'amount': (this.dataService.buildCardData.bcard_price + changeCost),
        'discounted_amount': (this.dataService.buildCardData.bcard_discounted_price + changeCost)
      },
      'billing_account': this.commonService.twoPayBillingIDDetails ? this.commonService.twoPayBillingIDDetails['id'] : this.getBillingAccountID('two'),
      'required_weeks': this.getWeeks(),
      'minimum_instalments': this.getCreditCheckData()['credit_check']['minimum_instalments']
    };
    this.dataService.showHideLoader(true);
    this.apiRequests.makeTwoPayPayment(payload).subscribe(resp => {
      this.twoPayStatusTimer = setInterval(() => {
        this.apiRequests.twoPayStatusPoling(resp['id']).subscribe(res => {
          if (res['funding_status'] === 'ACCEPTED') {
            clearInterval(this.twoPayStatusTimer);
            this.twoPayFullFillTimer = setInterval(() => {
              this.apiRequests.twoPayFullfillPoling(resp['id']).subscribe(re => {
                if (re['message'] === 'Fulfilment successful') {
                  clearInterval(this.twoPayFullFillTimer);
                  this.dataService.showHideLoader(false);
                  this.router.navigate(
                    [`buildcard-payment/${this.dataService.buildCardData.id}`, 'status'],
                    { queryParams: { 'paid_using_bnpl': 'true' } });
                }
              }, (error) => {
                clearInterval(this.twoPayFullFillTimer);
                this.dataService.showHideLoader(false);
              });
            }, 2000);
          } else if (res['funding_status'] === 'REJECTED') {
            clearInterval(this.twoPayStatusTimer);
            this.dataService.showHideLoader(false);
            this.commonService.isTwoPayRejected = true;
            this.commonService.twoPayInstallments = [];
          }
        }, (error) => {
          clearInterval(this.twoPayStatusTimer);
          this.dataService.showHideLoader(false);
        });
      }, 2000);
    });
  }

  updateBillingFrequency() {
    this.dataService.showMonthly = false;
    this.dataService.showWeekly = false;
    this.dataService.showUpfront = true;
  }

  startPaymentForCapchase() {
    this.dataService.showHideLoader(true);
    const payload = this.paymentService.capchaseInstallments['be_installments'];
    this.apiRequests.createRepaymentPlanForCapchase(payload).subscribe(resp => {
      this.apiRequests.getPaymentLinkForCapchase(resp['id']).subscribe(response => {
        this.paymentService.capchaseIframeLink = response['link'];
        this.paymentService.isCapchaseLink = true;
        this.dataService.showHideLoader(false);
        this.paymentService.isIframeLink.next('openIframe');
        this.capchaseStatusTimer = setInterval(() => {
          this.apiRequests.capcahseStatusPolling(resp['id']).subscribe(res => {
            if (res['status'] === 'INITIALIZED') {
              clearInterval(this.capchaseStatusTimer);
              this.capchaseFullFillTimer = setInterval(() => {
                this.apiRequests.capchaseFullfillpolling(resp['id']).subscribe(re => {
                  this.paymentService.isIframeLink.next('closeIframe');
                  if (re['message'] === 'Fulfilment successful') {
                    clearInterval(this.capchaseFullFillTimer);
                    this.dataService.showHideLoader(false);
                    this.router.navigate(
                      [`buildcard-payment/${this.dataService.buildCardData.id}`, 'status'],
                      { queryParams: { 'paid_using_bnpl': 'true' } });
                  }
                }, (error) => {
                  clearInterval(this.capchaseFullFillTimer);
                });
              }, 2000);
            } else if (res['status'] === 'UNKNOWN') {
              clearInterval(this.capchaseStatusTimer);
              this.paymentService.isIframeLink.next('closeIframe');
              this.dataService.showHideLoader(false);
              this.commonService.isBNPLRejected = true;
              this.paymentService.capchaseInstallments = [];
            }
          }, (error) => {
            clearInterval(this.capchaseStatusTimer);
            this.dataService.showHideLoader(false);
          });
        }, 2000);
      }, error => {
        this.dataService.showHideLoader(false);
      });
    }, error => {
      this.dataService.showHideLoader(false);
    });
  }

  disablePaymentButton() {
    return ((this.dataService.checkIfCPEUser() || this.commonService.isTwoPayRejected) &&
      (this.dataService.currentPage === 'paymentPlan'));
  }

  signContractForBNPLAndProceed() {
    const reqParams = {
      nda_signed: true,
      is_custom_prototype: this.appDataService.urlParameters.isCustomPrototype ? true : false,
      msa_signed_at: this.paymentService.msaOpenedAt,
      bnpl_partner: (this.commonService.startTwoPayFlow ? 'two' : 'capchase')
    };
    const buildCardID = this.dataService.buildCardData.id;
    this.apiRequests.setResetContractsSigned(buildCardID, reqParams).subscribe(data => {
      if (this.commonService.startTwoPayFlow) {
        this.proceedForTwoPayPayment();
      } else if (this.paymentService.startCapchaseFlow) {
        this.startPaymentForCapchase();
      }
    }, error => this.apiRequests.showErrorMessage(error, true));
  }

  getButtonText(){
    if (this.commonService.startTwoPayFlow) {
      return 'Complete Purchase';
    } else {
      return 'Continue to Payment';
    }
  }
}
