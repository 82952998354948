import { Observable, throwError as observableThrowError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CommonService } from "./common.service";

@Injectable()
export class HttpRequests {
  
  constructor(public http: HttpClient, private commonService: CommonService) {
  }

  public get(url: string, headers?: HttpHeaders, requestOptions?): Observable<any> {
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    if (requestOptions) {
      return this.http.get(url, requestOptions).pipe(
        map(res => this.parseResponse(res)));
    } else if (headers) {
      return this.http.get(url, { headers: headers }).pipe(
        map(res => this.parseResponse(res)), catchError(this.handleError), );
    } else {
      return this.http.get(url).pipe(
        map(res => this.parseResponse(res)), catchError(this.handleError), );
    }

  }


  public post(url: string, data: any, headers?: HttpHeaders): Observable<any> {
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    if (headers) {
      return this.http.post(url, data, { headers: headers }).pipe(
        map(res => this.parseResponse(res)), catchError(this.handleError), );
    } else {
      return this.http.post(url, data).pipe(
        map(res => this.parseResponse(res)), catchError(this.handleError), );
    }
  }

  public put(url: string, data: any, headers?: HttpHeaders): Observable<any> {
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    if (headers) {
      return this.http.put(url, data, { headers: headers }).pipe(
        map(res => this.parseResponse(res)), catchError(this.handleError), );
    } else {
      return this.http.put(url, data).pipe(
        map(res => this.parseResponse(res)), catchError(this.handleError), );
    }
  }

  public delete(url: string, headers?: HttpHeaders): Observable<any> {
    if (!this.commonService.isPlatformBrowser) {
      return;
    }
    if (headers) {
      return this.http.delete(url, { headers: headers }).pipe(
        map(res => this.parseResponse(res)), catchError(this.handleError), );
    } else {
      return this.http.delete(url).pipe(
        map(res => this.parseResponse(res)), catchError(this.handleError), );
    }
  }

  public parseResponse(res: any): any {
    return res ? res : '';
  }
  public handleError(error: any) {
    return observableThrowError(error);
  }
}
