import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PartnerMainComponent} from './components/main/main.component';

const ROUTER_CONFIG: Routes = [
  {
    path: '',
    component: PartnerMainComponent,
    pathMatch: 'full'
  },
  {
    path: '**',
    component: PartnerMainComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(ROUTER_CONFIG)],
  exports: [RouterModule]
})
export class PartnerDashboardRoutingModule {

}
