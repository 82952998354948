<div class="container" *ngIf="showHtml">
  <div class="row">
    <div class="paymentFlow">
      <div class="ndaScreen">
        <h2>Next Steps: Sign NDA and Contract</h2>
        <div class="screenHolder" *ngFor="let contract of allContracts">
          <div class="sectionRow">
            <div class="ndaIcon"><img src="../../../../assets/images/ndaimg1.jpg" alt=""/></div>
            <div class="ndaDetail">
              <h3>Non Disclosure Document</h3>
              <p>Protect your proprietary information.</p>
              <button class="signButton" *ngIf="!contract.nda_signed" (click)="processNDAFlow()" type="button">SIGN AN
                NDA
              </button>
              <button class="signedButton" *ngIf="contract.nda_signed" type="button"><em
                class="icon-right"></em> {{contract.document_status}}</button>

              <button class="viewButton" [disabled]="contract.nda_signed && !contract.nda_link"
                      *ngIf="contract.nda_signed && !contract.pdf_not_available" (click)="viewPdf(contract, 'NDA')"
                      type="button">VIEW
              </button>
              <div class="or" *ngIf="!contract.nda_signed">or</div>
              <div class="uploadSection" *ngIf="!contract.nda_signed">
                <div class="uploadDoc" *ngIf="!isDocumentUploaded['nda']"><input type="file" id="contract"
                                                                                 (change)="uploadDocument('nda', $event, contract)"><label
                  for="contract">Upload Document</label></div>
                <div class="fileUpload" *ngIf="isDocumentUploaded['nda']">
                  <div class="fileName">{{ uploadedDocument['nda'].name }}<span class="icon-cancel"
                                                                                (click)="removeDocument('nda')"></span>
                  </div>
                  <button class="uploadButton" type="button" (click)="sendDocuments('nda')">Upload</button>
                </div>
                <div class="uploadLoading" *ngIf="showLoader['nda']"><img src="../../../../assets/images/loader1.gif"
                                                                          alt=""/> Uploading! Please wait…
                </div>
                <div class="errorMsg" *ngIf="invalidFileError['nda'] && invalidFileError['nda'].length > 0">
                  <span>Only .pdf files are allowed</span>
                </div>
              </div>
            </div>
          </div>

          <div class="sectionRow" *ngFor="let mastercontract of contract.contracts">
            <div class="ndaIcon"><img src="../../../../assets/images/ndaimg2.jpg" alt=""/></div>
            <div class="ndaDetail">
              <h3>Master Contract</h3>
              <p>Contract between you and Engineer.AI for the services being rendered</p>
              <button class="signButton" type="button" *ngIf="!mastercontract.nda_signed"
                      (click)="processMasterContractFlow(mastercontract)">SIGN CONTRACT
              </button>
              <button class="signedButton" *ngIf="mastercontract.nda_signed" type="button"><em
                class="icon-right"></em> {{mastercontract.document_status}}</button>
              <button class="viewButton" *ngIf="mastercontract.nda_signed && !mastercontract.pdf_not_available"
                      (click)="viewPdf(mastercontract, 'contract')" type="button">VIEW
              </button>
              <div class="or" *ngIf="!mastercontract.nda_signed">or</div>
              <div class="uploadSection" *ngIf="!mastercontract.nda_signed">
                <div class="uploadDoc" *ngIf="!isDocumentUploaded['contract']"><input type="file" id="mastercontract"
                                                                                      (change)="uploadDocument('contract', $event, mastercontract)"><label
                  for="mastercontract">Upload Document</label></div>
                <div class="fileUpload" *ngIf="isDocumentUploaded['contract']">
                  <div class="fileName">{{ uploadedDocument['contract'].name }}<span class="icon-cancel"
                                                                                     (click)="removeDocument('contract')"></span>
                  </div>
                  <button class="uploadButton" type="button" (click)="sendDocuments('contract')">Upload</button>
                </div>
                <div class="uploadLoading" *ngIf="showLoader['contract']"><img
                  src="../../../../assets/images/loader1.gif" alt=""/> Uploading! Please wait…
                </div>
                <div class="errorMsg" *ngIf="invalidFileError['contract'] && invalidFileError['contract'].length > 0">
                  <span>Only .pdf files are allowed</span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="payContinue">
          <button type="button" (click)="gotoBilling()" [disabled]="!enablePayment">CONTINUE TO PAYMENT</button>
        </div>
      </div>

    </div>
  </div>
</div>
