import {ErrorHandler, Inject, NgModule, PLATFORM_ID, Injectable } from '@angular/core';
import {environment} from '@env/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule , BrowserTransferStateModule } from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {Angulartics2Module} from 'angulartics2';
import {Angulartics2Segment} from 'angulartics2/segment';
import {LocalStorageModule} from 'angular-2-local-storage';
import {SharedModule} from '@shared/shared.module';

// //import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
// import {NgIdleModule} from '@ng-idle/core';
/*import {Ng2OdometerModule} from 'ng2-odometer';*/
/*import {Ng2TelInputModule} from 'ng2-tel-input';*/

import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ServiceWorkerModule} from '@angular/service-worker';
import {AppRoutingModule} from '@rootmodule/app-routing.module';
import {ToastrModule} from 'ngx-toastr';
import {AppComponent} from '@rootmodule/app.component';
import {LoaderComponent} from '@rootmodule/loader/loader.component';
import {LoginComponent} from '@rootmodule/login/login.component';
import {ChatBotComponent} from '@rootmodule/popups/chatbot/chatbot.component';
import {FooterComponent} from '@rootmodule/navbars/footer/footer.component';
import {HeaderComponent} from '@rootmodule/navbars/header/header.component';
import {SaveprogressComponent} from '@rootmodule/popups/saveyourprogress/saveprogress.component';
import {ModelMapperService} from '@shared/services/model-mapper.service';
import {SpecCallComponent} from '@rootmodule/popups/spec-call/spec-call.component';
import {AutofocusDirective} from '@shared/directives/autofocus.directive';
import {PhaseService} from '@shared/services/phase.service';
import {SharableUrlComponent} from '@shared/shared-components/sharable-url/sharable-url.component';
import {CreateCardForOthersComponent} from '@rootmodule/popups/create-card-for-others/create-card-for-others.component';
import {InvalidAccessComponent} from '@rootmodule/invalidaccess/invalidaccess.component';
import {ErrorInterceptor} from '@featuremodules/payment-dashboard-module/services/auth-interceptor.service';
import {AppDataService} from '@rootmodule/app-data.service';
import {UtilityService} from '@shared/services/utility.service';
import {DragScrollModule} from 'ngx-drag-scroll';
import { isPlatformBrowser } from '@angular/common';
import {CommonService} from '@shared/services/common.service';
import {MeetNowModule} from '@featuremodules/meet-now-module/meet-now-module.module';
import * as Sentry from '@sentry/browser';
import { HandleApiErrorsComponent } from './handle-api-errors/handle-api-errors.component';
import { IntercomModule } from 'ng-intercom';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import {NgxSocialShareModule} from 'ngx-social-share';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { ShareButtonsConfig, ShareModule } from '@ngx-share/core';
import { ClipboardModule } from 'ngx-clipboard';
import { NdaModalComponent } from './nda-modal/nda-modal.component';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import { NewLoginComponent } from './new-login/new-login.component';
import {ReferralProgramModule} from '@featuremodules/referral-program-module/referral-program.module';

import { AppComponentService } from './app-component.service';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {PaymentBuilderModule} from '@featuremodules/payment-builder-module/payment-builder.module';
// import 'jest-preset-angular/setup-jest';

const googleLoginOptions = {
  scope: 'profile email',
  plugin_name:'studio social login'
};
Sentry.init({
  dsn: 'https://f619a7bc9edb4a789a23135fc5c16c0c@sentry.engineer.ai/6'
});
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    if (environment.ENABLE_SENTRY) {
      const eventId = Sentry.captureException(error.originalError || error);
     // Sentry.showReportDialog({eventId});
    } else {
      console.log('error- ', error);
    }
  }
}


@NgModule({
  imports: [
    /*Angulartics2Module.forRoot({
      appId: environment.envName === 'prod' ? 'tk8uw9aj' : 'ob3az46u', // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),*/
    IntercomModule.forRoot({
      appId: ((environment.envName === 'prod') ? 'tk8uw9aj' : 'tk8uw9aj'),
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserTransferStateModule,
    Angulartics2Module.forRoot(),
    LocalStorageModule.forRoot({
      prefix: 'e.ai-app',
      storageType: 'localStorage'
    }),
    // NgIdleModule,
    CKEditorModule,
    // //NgIdleKeepaliveModule.forRoot(),
    SharedModule,
    SharedModule.forRoot(),
    PerfectScrollbarModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: true}),
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    DragScrollModule,
    MeetNowModule,
    SocialLoginModule,
    NgxSocialShareModule,
    FontAwesomeModule,
    ShareModule.withConfig(),
    ClipboardModule,
    GooglePlaceModule,
    ReferralProgramModule,
    PaymentBuilderModule
  ],
    declarations: [AppComponent, LoaderComponent, LoginComponent, ChatBotComponent,
        FooterComponent, HeaderComponent, SaveprogressComponent,
        InvalidAccessComponent, SharableUrlComponent,
        CreateCardForOthersComponent, SpecCallComponent, AutofocusDirective,
        HandleApiErrorsComponent, NdaModalComponent, NewLoginComponent],
    entryComponents: [AppComponent, LoaderComponent, LoginComponent, ChatBotComponent,
        FooterComponent, HeaderComponent, SaveprogressComponent,
        InvalidAccessComponent, SpecCallComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('958307779539-opcc4hfmn7d01hl9u8vhb05h6lrrmgn5.apps.googleusercontent.com', googleLoginOptions),
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider('676623097938316'),
                    }
                ],
            } as SocialAuthServiceConfig,
        },
        AppDataService,
        UtilityService,
        AppComponentService
    ],
    exports: [
        LoginComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private appDataService: AppDataService, public phaseService: PhaseService, public modelMapperService: ModelMapperService,
              private commonService: CommonService, @Inject(PLATFORM_ID) private platformId: Object) {
    appDataService.setIncomingUrlParameters(this.phaseService.getUrlParamsFromUrl());
    if (isPlatformBrowser(this.platformId) && localStorage ) {
      this.commonService.isPlatformBrowser = true;
      if (localStorage.getItem('admin_access') !== 'true') {
        if (this.appDataService.urlParameters.admin_access) {
          localStorage.setItem('admin_access', 'true');
        } else {
          localStorage.setItem('admin_access', 'false');
        }
      }
      this.capitalizeString();
    }

  }

  public setupSplitTests() {
    if (!this.appDataService.urlParameters.signup_stage) {
      const randomNumber = this.phaseService.generateRandomNumber();
      if (randomNumber >= 1 && randomNumber <= 10) {
        this.appDataService.urlParameters.signup_stage = 'home';
      } else if (randomNumber > 10 && randomNumber <= 20) {
        this.appDataService.urlParameters.signup_stage = 'features';
      } else {
        this.appDataService.urlParameters.signup_stage = 'buildphases';
      }
    }
  }

  capitalizeString() {
    Object.defineProperty(String.prototype, 'capitalize', {
      value: function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
      },
      enumerable: false
    });
  }
}
