import {Component, Input, OnInit} from '@angular/core';
import {SpeedModel} from '@models/speed-model/speed.model';
import {RecalculatePhaseDataService} from '@shared/services/recalculate-phase-data.service';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import { Intercom } from 'ng-intercom';
import { Options } from '@angular-slider/ngx-slider';;

@Component({
  selector: 'app-general-phase-speed',
  templateUrl: './general-phase-speed.component.html',
  styleUrls: ['./general-phase-speed.component.scss']
})
export class GeneralPhaseSpeedComponent implements OnInit {
  public phasesSpeed: SpeedModel[];
  @Input() selectedSpeed: SpeedModel;
  showDropDown = false;
  barValue = 4;
  showSliderTooltip = false;
  options: Options = {
    showSelectionBar: true,
    stepsArray: [
      {value: 4},
      {value: 3},
      {value: 1},
      {value: 5},
      {value: 2}
    ]
  };
  optionsWithoutRelax: Options = {
    showSelectionBar: true,
    stepsArray: [
      {value: 3},
      {value: 1},
      {value: 5},
      {value: 2}
    ]
  };

  constructor(private recalculatePhaseDataService: RecalculatePhaseDataService,
              public dataService: DataService,
              public phaseService: PhaseService,
              public analyticsSegment: Angulartics2Segment,
              ) {
  }

  ngOnInit() {
    this.phasesSpeed = this.dataService.homePageData.speed;
    this.selectedSpeed = this.phaseService.selectedSpeed;

  }

  checkIfDisableSpeed() {
    if (!this.dataService.user.enterprise_lead || !this.dataService.checkIfBuildCardisinvited()) {
      return false;
    }
    const buildCardMultiplier = this.dataService.buildCardData.multiplier_experiment;
    if ((buildCardMultiplier && buildCardMultiplier.is_default) || !buildCardMultiplier) {
      return this.dataService.homePageData.multiplier_experiment.relaxed_mode_disable;
    } else {
      return buildCardMultiplier.relaxed_mode_disable;
    }
  }

  public getFinalDeliveryDate() {
    return this.phaseService.getFinalDeliveryDate();
  }


  changeSpeedForPhases(speed) {
    this.phaseService.showSpeedLoader = true;
    this.phaseService.assignSameSpeed = true;
    this.phasesSpeed.filter(s => {
      if (s.title === speed) {
        speed = s;
      }
    });
    const index =
      this.phaseService.trackEventForObject(this.analyticsSegment,  'phase_speed_set', {
        user_id : this.dataService.user?.id,
        speed_selected : speed.title,
        phase_selected : this.phaseService.getPhaseTitle(),
      });
    this.selectedSpeed = speed;
    this.phaseService.selectedSpeed = speed;
    this.phaseService.selectedHash.speed_id = speed.id;
    this.recalculatePhaseDataService.propagateSpeedDataToPhases(speed.id);
    this.showDropDown = false;
  }

  openDropdown() {
    this.showDropDown = !this.showDropDown;
  }

  showTooltip(event) {
    if ((this.selectedSpeed.title === 'Slow') && (event.screenX > 127) && (event.screenX < 152)) {
      this.showSliderTooltip = true;
    } else if ((this.selectedSpeed.title === 'Standard') && (event.screenX > 200) && (event.screenX < 225)) {
      this.showSliderTooltip = true;
    } else if ((this.selectedSpeed.title === 'Fast') && (event.screenX > 273) && (event.screenX < 298)) {
      this.showSliderTooltip = true;
    } else if ((this.selectedSpeed.title === 'Speedy') && (event.screenX > 338) && (event.screenX < 370)) {
      this.showSliderTooltip = true;
    } else {
      this.showSliderTooltip = false;
    }
  }

  hideTooltip() {
    this.showSliderTooltip = false;
  }

  checkForEnterpriseUser() {
    return ((this.dataService.user && this.dataService.user.enterprise_lead) || (this.dataService.buildCardData && (this.dataService.buildCardData.enterprise_lead === 'true')));
  }

  checkIfRapidPrototypeSelected() {
    if (this.phaseService.checkIfOnlyRapidPrototypeSelected() && this.dataService.homePageData.build_phases[2].is_tailor_made) {
      return true;
    } else {
      return false;
    }
  }

  getCurrency() {
    const buildCardStatus = this.phaseService.buildCardEditMode || this.dataService.isIncompleteCardEditMode || this.phaseService.isFeatureRequest;
    if (this.dataService.homePageData && this.dataService.homePageData.currency) {
      return buildCardStatus ? this.dataService.buildCardData.currency.symbol : this.dataService.homePageData.currency.symbol;
    } else if (this.dataService.user && this.dataService.user.currency) {
      return buildCardStatus ? this.dataService.buildCardData.currency.symbol : this.dataService.user.currency.symbol;
    }
  }

}
