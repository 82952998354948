import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '@shared/shared.module';
import {CommonModule} from '@angular/common';
import {DeliveryComponent} from './delivery.component';
import {TeamCardComponent} from './team-card/team-card.component';
import {AdvancedPhaseCardComponent} from './advanced-phase-card/advanced-phase-card.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import {FeatureSliderComponent} from './feature-slider/feature-slider.component';
import {DragulaModule} from 'ng2-dragula';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {MomentModule} from 'angular2-moment';
import {DragScrollModule} from 'ngx-drag-scroll';
import {GeneralPhasePlatformComponent} from './general-phase-platform/general-phase-platform.component';
import {GeneralPhaseSpeedComponent} from './general-phase-speed/general-phase-speed.component';
/*import {TypeaheadModule} from 'ngx-bootstrap/typeahead';*/
import {LearnMoreComponent} from '@featuremodules/delivery-module/popups/learn-more/learn-more.component';
import {SearchPipe} from '@featuremodules/delivery-module/search-pipe/search.pipe';
import {HighlightSearch} from '@featuremodules/delivery-module/highlight-search/highlight-search';
import {UtilityService} from '@shared/services/utility.service';
import { LeftDeliveryComponent } from './left-delivery/left-delivery.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NormalizeInvalidDatePipe } from './pipes/normalize-invalid-date-pipe/normalize-invalid-date.pipe';
import { DurationUnitPipe } from './pipes/duration-unit-pipe/duration-unit.pipe';

const childRouterConfig: Routes = [{path: '', component: DeliveryComponent}];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(childRouterConfig),
        SharedModule,
        NgxSliderModule,
        DragulaModule.forRoot(),
        MomentModule,
        PerfectScrollbarModule,
        DragScrollModule,
        AngularMyDatePickerModule,
        /*TypeaheadModule.forRoot()*/
    ],
    declarations: [
        DeliveryComponent,
        TeamCardComponent,
        AdvancedPhaseCardComponent,
        FeatureSliderComponent,
        GeneralPhasePlatformComponent,
        GeneralPhaseSpeedComponent,
        LearnMoreComponent,
        SearchPipe,
        HighlightSearch,
        LeftDeliveryComponent,
        NormalizeInvalidDatePipe,
        DurationUnitPipe,
    ],
    providers: [UtilityService]
})
export class DeliveryModule {
}
