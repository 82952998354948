import {Component, OnInit} from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {PartnerDashboardDataService} from '../../Utils/partner-dashboard-data.service';
import {DataService} from '@shared/services/data.service';

@Component({
  selector: 'app-partner-confirmation',
  templateUrl: 'confirmation.component.html'
})

export class PartnerConfirmationComponent  {
  constructor(public phaseService: PhaseService,
              public dataService: DataService,
              public partnerDataService: PartnerDashboardDataService) {
  }



  public openPartnerLogin() {
    this.phaseService.showSignin = true;
    this.phaseService.openCommonAuth = true;
  }

  public goToUniqueCodeScreen() {
    this.dataService.resetPartnerViews();
    this.dataService.showHideViews.verify = true;
  }

  public goBackToPaymentScreen() {
    this.dataService.resetPartnerViews();
    this.dataService.showHideViews.payment = true;
  }


}
