import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaymentDashboardRoutingModule} from './payment-dashboard.routing.module';
import {AdminLoginComponent} from './components/authentication/login/admin-login.component';
import {AdminAuthService} from './services/auth.service';
import {AdminAuthGuardService} from './services/auth-guard.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GenericService} from './services/generic.service';
import {OverviewComponent} from './components/overview/overview.component';
import {BuildCardService} from './services/buildcard.service';
import {BuildcardDetailComponent} from './components/build-card-detail/build-card-detail.component';
import {HttpClientModule} from '@angular/common/http';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PaymentDetailsComponent} from './components/build-card-detail/payment-details/payment-details.component';
import {ProjectInfoComponent} from './components/build-card-detail/project-info/project-info.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
/*import {MyDatePickerModule} from 'mydatepicker';*/
import {PartnerDashboardDataService} from '@featuremodules/partner-dashboard-module/Utils/partner-dashboard-data.service';
import {PartnerDashboardApiService} from '@featuremodules/partner-dashboard-module/Utils/partner-dashboard-api.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PaymentDashboardRoutingModule,
    InfiniteScrollModule,
    PerfectScrollbarModule,
    AngularMyDatePickerModule
    /*MyDatePickerModule*/
  ],
  declarations: [
    AdminLoginComponent,
    OverviewComponent,
    BuildcardDetailComponent,
    PaymentDetailsComponent,
    ProjectInfoComponent
  ],
  providers: [
    AdminAuthService,
    GenericService,
    AdminAuthGuardService,
    BuildCardService,
  ]
})

export class PaymentDashboardModule {
}
