import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { PhaseService } from "./phase.service";
import { NavigationEnd, Router } from '@angular/router';
import { AppDataService } from "@rootmodule/app-data.service";
import { HomeTemplateService } from "./home-template.service";
import { BuildCardModel } from "@models/build-card-model/build-card.model";
import { ApiRequests } from "./api-requests.service";
import { CommonService } from "./common.service";

@Injectable()
export class HeaderService {

    constructor(private phaseService: PhaseService, public dataService: DataService, public router: Router, public appDataService: AppDataService,
        public homeTemplateService: HomeTemplateService, public apiRequest: ApiRequests, private commonService: CommonService) {
    }

    showInstantCall() {
        this.phaseService.specCallCreationData = null;
        this.phaseService.showInstantSpecCallFlow = true;
        this.phaseService.showNav = false;
        this.phaseService.openMeetNoeModal();
      }

      showScheduleCall() {
        this.phaseService.specCallCreationData = null;
        this.phaseService.showNav = false;
        this.phaseService.bookSpecCallClicked = true;
        this.phaseService.openMeetNoeModal();
      }

      public performUserActions(option) {
        this.dataService.buildCardUniqueCode = '';
        this.dataService.loginNewModule = false;
        this.phaseService.showList = false;
        this.phaseService.showNav = false;

        if (option === 'dashboard') {
          this.phaseService.isProfile = false;
          this.phaseService.showTaxAndBilling = false;
          this.appDataService.urlParameters.featureRequest = '';
          this.phaseService.navigateNormally = true;
          if (this.dataService.unsavedChanges && (this.dataService.currentPage !== 'templateDetail')) {
            this.phaseService.gotoDashBoard = true;
            this.phaseService.showSaveProgress = true;
          } else {
            this.dataService.isFromViewPrototype = false;
            this.phaseService.buildCardEditMode = false;
            this.phaseService.reset();
            this.phaseService.inProgressFlow = false;
            this.phaseService.isBuildCardSetup = false;
            this.router.navigate(['dashboard']);
          }
        } else if (option === 'settings') {
          this.phaseService.isProfile = true;
          this.phaseService.navigateNormally = true;
          if (this.dataService.unsavedChanges && (this.dataService.currentPage !== 'templateDetail')) {
            this.phaseService.gotoDashBoard = true;
            this.phaseService.showSaveProgress = true;
          } else {
            this.dataService.isFromViewPrototype = false;
            this.phaseService.buildCardEditMode = false;
            this.phaseService.reset();
            this.phaseService.isBuildCardSetup = false;
            this.phaseService.inProgressFlow = false;
            this.router.navigate(['dashboard']);
          }
        } else if (option === 'buildcard') {
          this.phaseService.navigateNormally = true;
          this.phaseService.isProfile = false;
          if (this.dataService.unsavedChanges) {
            this.phaseService.gotoDashBoard = true;
            this.phaseService.showSaveProgress = true;
          } else {
            this.dataService.isFromViewPrototype = false;
            this.phaseService.buildCardEditMode = false;
            this.phaseService.reset();
            this.phaseService.isBuildCardSetup = false;
            this.phaseService.inProgressFlow = false;
            this.router.navigate(['dashboard']);
          }
        } else if (option === 'logout') {
          this.dataService.showExpertsButtonInHeader = false;
          this.appDataService.urlParameters.spec_promotion = '';
          this.dataService.openMeetNowModal = false;
          this.homeTemplateService.clearSearch();
          this.homeTemplateService.initialLoader = false;
          if (this.dataService.unsavedChanges && (this.dataService.currentPage !== 'templateDetail')) {
            this.appDataService.urlParameters.red = '';
            this.phaseService.showRegister = false;
            this.phaseService.logoutToHome = true;
            this.phaseService.showSaveProgress = true;
            this.phaseService.popupCouponApplied = false;
            this.phaseService.AppDiscountSuccessfullyApplied = false;
            this.phaseService.signupNextStep = false;
          } else {
            this.logoutUser();
            this.dataService.unsetCookie();
            const isPricingModel = this.dataService.templateDetailModel ? (this.dataService.templateDetailModel.pricing_modal !== 'rental_price') : true;
            if (isPricingModel) {
              this.dataService.user = null;
              this.phaseService.showProjectNamePopup = false;
              this.dataService.isFromAppDetails = false;
              this.dataService.isDirectCheckOut = false;
              this.dataService.isFromViewPrototype = false;
              this.phaseService.buildCardEditMode = false;
              this.dataService.totalSelectedFeatures = 0;
              this.phaseService.isBuildCardSetup = false;
              this.dataService.landingPage = '';
              this.phaseService.showRegister = false;
              this.dataService.appDetails = null;
              this.phaseService.reset();
              this.dataService.firstPage = this.dataService.firstPage ? this.dataService.firstPage : 'home';
              this.router.navigate([this.dataService.firstPage]);
            }
          }
          this.phaseService.showLockDiscount = true;
        } else if (option === 'pricenewproject') {
          if (this.dataService.unsavedChanges) {
            this.phaseService.gotoCreateNew = true;
            this.phaseService.showSaveProgress = true;
          } else {
            this.phaseService.isBuildCardSetup = false;
            this.dataService.isFromViewPrototype = false;
            this.phaseService.buildCardEditMode = false;
            this.phaseService.reset();
            this.appDataService.urlParameters.featureRequest = '';
            this.dataService.totalSelectedFeatures = 0;
            this.router.navigate([this.dataService.firstPage]);
          }
        } else if (option === 'become-a-partner') {
          if (this.dataService.unsavedChanges) {
            this.phaseService.becomePartner = true;
            this.phaseService.showSaveProgress = true;
          } else {
            this.router.navigate([option]);
          }
        }
      }

      clearBuildCard() {
        this.dataService.modifiedBuildCardData = undefined;
        this.dataService.buildCardData = new BuildCardModel();
      }

      public logoutUser() {
        this.apiRequest.signout_user().subscribe(
          (data: any) => {
            this.clearBuildCard();
            this.dataService.unsetCookie();
            this.phaseService.linkCopied = false;
            localStorage.removeItem('add_feature_first_time');
            localStorage.removeItem('add_manual_feature_first_time');
            this.phaseService.natashaCurrentAddedFeatureMessages = [...this.phaseService.natashaAddedFeatureMessages];
            localStorage.removeItem('natashaIdleQuestionCount');
            this.phaseService.natashaIdlQuestionTimeInterval = 50000;
            localStorage.removeItem('templatesVisited');
            this.phaseService.isNatashaFirstBundleResponse = true;
            this.homeTemplateService.resetTemplateNatashaChatHistory();
            this.phaseService.resetNatashaChatsOnFeaturePage();
            this.phaseService.natashaMobileGroupIdentifier = '';
            this.phaseService.natashaMobileGroupChatId = 1;
            this.homeTemplateService.mobileTempateNatashaChatList = {};
            sessionStorage.removeItem('userNameFromNatasha');
            sessionStorage.removeItem('start_project_referral_points');
            localStorage.removeItem("code")
            if (this.phaseService.isDashboardShow) {
              this.dataService.loginData = null;
              this.dataService.isFromViewPrototype = false;
              this.phaseService.buildCardEditMode = false;
              this.phaseService.reset();
              this.dataService.manualPromotion = null;
              this.router.navigate([this.dataService.firstPage]);
            }

            if (this.dataService.currentPage === 'templateDetail' && (this.phaseService.checkIfRentalPricingModal() || this.phaseService.isRentalBuildCard())) {
              this.apiRequest.fetchTemplateDetails(this.phaseService.rentalTemplateAlias, false, true).subscribe((data: any) => {
                this.dataService.appDetails = this.dataService.templateDetailModel;
                this.phaseService.inProgressBuildCardId = null;
                this.phaseService.selectedApps = [];
                this.toggleSelectedAppsById(this.dataService.appDetails);
                this.phaseService.showCurrencyOptionsForBnow = false;
                this.dataService.isFromAppDetails = false;
              });
            }

            const evt = new CustomEvent('LogoutEvent', { detail: true });
            window.dispatchEvent(evt);
            if (this.commonService.isPlatformBrowser) {
              localStorage.removeItem('user');
            }
            this.phaseService.checkMandateLogin();
          },
          error => {
            if (this.phaseService.isDashboardShow) {
              this.dataService.isFromViewPrototype = false;
              this.phaseService.buildCardEditMode = false;
              this.phaseService.reset();
              this.router.navigate([this.dataService.firstPage]);
            }
          }
        );
        if (this.phaseService.SignUpProviders === 'facebook') {
          window.location.reload();
          this.phaseService.checkMandateLogin();
        }
      }

      public toggleSelectedAppsById(app: any): void {
        const selectedApp = this.phaseService.selectedApps.find(x => x.id === app.id);
        if (selectedApp) {
          const index = this.phaseService.selectedApps.indexOf(selectedApp);
          this.phaseService.selectedApps.splice(index, 1);
        } else {
          this.phaseService.selectedApps.push(app);
          if (this.dataService.buildCardData && !this.dataService.buildCardData.id) {
            if (this.dataService.user && (this.phaseService.selectedApps[0].pricing_modal === 'rental_price')) {
              this.dataService.homePageData.currency = this.dataService.user.currency;
            } else if (!this.dataService.user && (this.phaseService.selectedApps[0].pricing_modal === 'rental_price')) {
              this.dataService.homePageData.currency = app.currency;
            }
            this.phaseService.currencyForNow = null;
          }
        }
      }
}
