import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import {AppDataService} from '@rootmodule/app-data.service';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {UpgradePlanService} from '@shared/services/upgrade-plan.service';
import { DataService } from '@shared/services/data.service';
import {environment} from '@env/environment';

@Component({
  selector: 'app-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.scss']
})
export class UpgradePlanComponent implements OnInit, OnDestroy {
  shouldShowUpfrontDataForStarter = false;
  shouldShowUpfrontDataForStore = false;
  sellerFeesTooltip = false;
  freeHostingTooltip = false;
  afterCareTooltip = false;
  ownCodeTooltip = false;
  additionalTooltip = false;
  afterCareTooltipStore = false;
  ownCodeTooltipStore = false;
  additionalTooltipStore = false;

  constructor(public appDataService: AppDataService, public router: Router, public upgradePlanService: UpgradePlanService, public dataService: DataService,
    public renderer: Renderer2) {
    this.dataService.currentPage = 'upgradePlan';
   }

  ngOnInit(): void {
    if (!this.appDataService.urlParameters.project_id || this.appDataService.urlParameters.project_id === 'undefined' || this.appDataService.urlParameters.project_id === 'null') {
      this.router.navigate(['home']);
      return;
    }
    this.renderer.addClass(document.getElementById('app-component'), 'hideHeader');
    this.getUpgradePlanPrices();
  }

  getUpgradePlanPrices() {
    this.upgradePlanService.getUpgradePlanPrices(this.appDataService.urlParameters.project_id);
  }

  toggleUpfrontButtonForStarter() {
    this.shouldShowUpfrontDataForStarter = !this.shouldShowUpfrontDataForStarter;
    if (this.shouldShowUpfrontDataForStarter) {
      this.upgradePlanService.starterPlanAmount = this.upgradePlanService.upgradePlanData.starter_upfront_amount;
    } else {
      this.upgradePlanService.starterPlanAmount = this.upgradePlanService.upgradePlanData.starter_deposit_amount;
    }
  }

  toggleUpfrontButtonForStore() {
    this.shouldShowUpfrontDataForStore = !this.shouldShowUpfrontDataForStore;
    if (this.shouldShowUpfrontDataForStore) {
      this.upgradePlanService.storePlanAmount = this.upgradePlanService.upgradePlanData.store_upfront_amount;
    } else {
      this.upgradePlanService.storePlanAmount = this.upgradePlanService.upgradePlanData.store_deposit_amount;
    }
  }

  upgradePlanToStarter() {
    if (this.shouldShowUpfrontDataForStarter == true) {
      window.open(environment.FE_URL + '/upgrade?upgrade_to_starter=true&upfront=true&uniq_code=' + this.upgradePlanService.upgradePlanData.uniq_code, '_blank', "noopener");
    }else{
      window.open(environment.FE_URL + '/upgrade?upgrade_to_starter=true&uniq_code=' + this.upgradePlanService.upgradePlanData.uniq_code, '_blank', "noopener");
    }
  }

  upgradePlanToStore() {
    if (this.shouldShowUpfrontDataForStore == true) {
      window.open(environment.FE_URL + '/upgrade?upgrade_to_store=true&upfront=true&uniq_code=' + this.upgradePlanService.upgradePlanData.uniq_code, '_blank', "noopener");
    }else{
      window.open(environment.FE_URL + '/upgrade?upgrade_to_store=true&uniq_code=' + this.upgradePlanService.upgradePlanData.uniq_code, '_blank', "noopener");
    }
  }

  ngOnDestroy() {
    let element = document.getElementById('app-component');
    if (element) {
      this.renderer.removeClass(element, 'hideHeader');
    }
  }

  showHideUpgradeTooltip(name?){
    if(name == 'sellerFees'){
      this.sellerFeesTooltip = !this.sellerFeesTooltip;
    }else if (name == 'aftercare') {
      this.afterCareTooltip = !this.afterCareTooltip;
    }else if (name == 'ownCode') {
      this.ownCodeTooltip = !this.ownCodeTooltip;
    } else if (name == 'additional') {
      this.additionalTooltip = !this.additionalTooltip;
    }else if (name == 'hosting') {
      this.freeHostingTooltip = !this.freeHostingTooltip;
    }else if (name == 'aftercare1') {
      this.afterCareTooltipStore = !this.afterCareTooltipStore;
    }else if (name == 'ownCode1') {
      this.ownCodeTooltipStore = !this.ownCodeTooltipStore;
    }else if (name == 'additional1') {
      this.additionalTooltipStore = !this.additionalTooltipStore;
    }
  }

}
