import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from '@shared/services/data.service';
import {ReferralService} from '@shared/services/referral.service'

@Component({
  selector: 'app-referral-wallet',
  templateUrl: './referral-wallet.component.html',
  styleUrls: ['./referral-wallet.component.scss']
})
export class ReferralWalletComponent implements OnInit {

  constructor(public referralService: ReferralService, public dataService: DataService) { }

  ngOnInit(): void {
    this.getReferralWalletData();
  }

  openUseBuilderPointsPopup() {
    this.referralService.showUseBuilderPointsPopup = true;
  }

  getReferralWalletData() {
    this.referralService.getTransactionDataForReferralWallet();
  }

  getWalletTransactionPointsSign(walletData) {
    if (walletData && walletData.transaction_type === 'Use points') {
      return '-';
    } else {
      return '+';
    }
  }

  ngOnDestroy() {
    this.referralService.rewardPointsAPISuccess = false;
  }

}
