import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from "../../../../environments/environment";
import { Subject } from 'rxjs';
import { filter, first, map, take, takeUntil } from 'rxjs/operators';
import { ApiRequests } from './api-requests.service';
import { PhaseService } from './phase.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CommonService } from './common.service';

enum PAYLOAD_TYPE {
    PING = 'ping',
    WELCOME = 'welcome',
    CONFIRM_SUBSCRIPTION = 'confirm_subscription',
}

type WelcomeMessage = {
    type: PAYLOAD_TYPE.WELCOME,
}

type PingMessage = {
    type: PAYLOAD_TYPE.PING,
    message: number,
}

type UpdateMessage = {
    type: undefined, // `type` not coming for update message
    identifier: string,
    message: {
        key_changed: string,
        toast_message: string,
        id: number,
    };
}

type ConfirmSubscriptionMessage = {
    type: PAYLOAD_TYPE.CONFIRM_SUBSCRIPTION,
    identifier: string,
}


type MessageEvent = WelcomeMessage | PingMessage | UpdateMessage | ConfirmSubscriptionMessage;

const DELIVERY_ROUTE = 'delivery';
const FEATURE_ROUTE = 'feature';
const SUMMARY_ROUTE = 'build-card';
const BUILDCARD_PAYMENT_ROUTE = 'buildcard-payment';
const BILLING_DETAILS_ROUTE = 'billingdetails';
const PAYMENT_ROUTE = 'payment';

const WEBSOCKET_ROUTES_TYPES = [
    DELIVERY_ROUTE,
    FEATURE_ROUTE,
    SUMMARY_ROUTE,
    BUILDCARD_PAYMENT_ROUTE,
    BILLING_DETAILS_ROUTE,
    PAYMENT_ROUTE,
]

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {

    public responseSubject$ = new Subject<UpdateMessage>(); // Can be used in the component if needed
    public buildCardFetched$ = new Subject<void>();
    private socket$: WebSocketSubject<MessageEvent>;
    private unsubscribeSubject = new Subject<void>();
    private currentSubscribedCode: string;
    constructor(
        private apiRequest: ApiRequests,
        private phaseService: PhaseService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private commonService: CommonService,
    ) {
        if (this.commonService.isPlatformBrowser) {
            this.socket$ = webSocket<any>(environment.WSS_BUILDER_URL);
            this.initSocket();
            this.subscribeToRoute();
            this.subscribeToResponses();
        }
    }

    private initSocket(): void {
        this.socket$
            .pipe(
                takeUntil(this.unsubscribeSubject),
                // skip ping, welcome and confirm subscription response
                filter(({ type }) => !type),
            )
            .subscribe(
                (event: UpdateMessage) => this.responseSubject$.next(event),
                (error) => console.error('WebSocket subscription error:', error),
            );
    }

    private subscribeToResponses() {
        // Subscribe to update messages
        this.responseSubject$.subscribe((data: UpdateMessage) => {
            this.getBuildCardData(this.currentSubscribedCode);
            this.commonService.showSuccessToaster(data.message.toast_message);
        });
    }

    getBuildCardData(uniqCode: string) {
        const currentUrl = this.router.url;
        this.apiRequest.fetchSingleBuildCardData(uniqCode).subscribe((data: any) => {
            this.phaseService.setUpInFullBuildCard();
            if (currentUrl.includes(FEATURE_ROUTE) || currentUrl.includes(DELIVERY_ROUTE)) {
                this.phaseService.updatePrice();
            }
            if (!(currentUrl.includes(FEATURE_ROUTE) && currentUrl.includes(DELIVERY_ROUTE))) {
                this.phaseService.fetchProposalInstallments();
            }
            this.buildCardFetched$.next();
        });
    }

    private subscribeToRoute() {
        // subscribe on route changed when BC relevant routes
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd && this.checkIfBuildCardRoute(this.router.url)), // identify navigation end && identify route needs wss
                map(() => this.rootRoute(this.activatedRoute)), // get child route
                filter(({ outlet }) => outlet === 'primary'), // filter primary route
                map((route) => route.idParam || route.idParamParent),
            )
            .subscribe(id => this.connectRealTimeSyncForBuildCard(id));

        // unsubscribe on route changed when otherwise
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd
                    && !this.checkIfBuildCardRoute(this.router.url)
                    && !!this.currentSubscribedCode),
            )
            .subscribe(() => {
                this.unsubscribeChannel(this.currentSubscribedCode);
                this.currentSubscribedCode = null;
            });
    }

    // Check if websocket required on current route
    private checkIfBuildCardRoute(url: string) {
        return WEBSOCKET_ROUTES_TYPES.some(path => url.includes(path));
    }

    private rootRoute(route: ActivatedRoute) {
        while (route.firstChild) {
            route = route.firstChild;
        }

        const idParam = route.snapshot.paramMap.get('id');
        const idParamParent = route.parent.snapshot.paramMap.get('id');
        const outlet = route.outlet;

        return {
            idParam,
            idParamParent,
            outlet,
        };
    }

    connectRealTimeSyncForBuildCard(code: string) {
        if (!code || this.currentSubscribedCode === code) {
            return;
        }

        // New cardId subscription
        // unsubscribe old one
        if (this.currentSubscribedCode) {
            this.unsubscribeChannel(this.currentSubscribedCode);
        }

        // subscribe to new
        this.subscribeChannel(code);
        this.currentSubscribedCode = code;
    }

    private buildCardSubsIdentifier(code: string) {
        return JSON.stringify({
            channel: 'BuildCardUpdatesChannel',
            uniq_code: code,
        });
    }

    private subscribeChannel(code: string) {
        this.sendPayload({ command: 'subscribe', identifier: this.buildCardSubsIdentifier(code) });
    }

    private unsubscribeChannel(code: string) {
        this.sendPayload({ command: 'unsubscribe', identifier: this.buildCardSubsIdentifier(code) });
    }

    public sendPayload(payload: any) {
        this.socket$.next(payload);
    }

    // Not in use currently
    getChannelConfirmation(code: string) {
        this.socket$
            .pipe(
                takeUntil(this.unsubscribeSubject),
                first(({ type }) => type === PAYLOAD_TYPE.CONFIRM_SUBSCRIPTION)
            )
            .subscribe(
                (event: UpdateMessage) => this.currentSubscribedCode = code,
                (error) => console.error('WebSocket subscription error:', error),
            );
    }

    public close(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
        this.socket$.complete();
    }

}
