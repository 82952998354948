<div class="main" id="main">
    <!--<div class="leftBlock" [ngClass] = "{filterShowRight: showMobileFilter}" *ngIf="apiSuccess"> &lt;!&ndash;- "filterShowRight" add class when click filter icon &#45;&#45;&ndash;&gt;
    <perfect-scrollbar class="myScrollbar" [config]="PERFECT_SCROLLBAR_CONFIG">
        <div class="filterBlock">
            <div class="closeBtnBx">
                <p>FILTER MENU</p>
                <div class="new-CloseButton" (click) = "mobileFilterClicked()"><em class="icon-cancel"></em></div>
            </div>

            <app-template-filter *ngIf = "apiSuccess" (closeMobileFilter) = "mobileFilterClicked()"></app-template-filter>
        </div>
    </perfect-scrollbar>
    </div>-->
    <div class="rightBlock">
        <div class="bdrBtm" *ngIf="apiSuccess">
            <div class="templateContainer">
                <div class="topBlock">
                    <h2 class="title"><strong>Choose a base</strong> <span class="subhead" (click)="openVideoPopup('open')"></span>
                        <div class="natashPanelOverlay" *ngIf="(homeTemplateService.showNatashaPanel || isShowNatashaSearchedText || isShowFindSomethingText || homeTemplateService.showConfirmPopup || feedBackContainer) && !homeTemplateService.isShowTemplateNatashaIntroPopup" (click)="hideNatashaPanel(true)"></div>
                    </h2>
                    <div class="templateToolBar">
                        <!--<div class="searchBx">
                            &lt;!&ndash; <form>  &ndash;&gt;

                                <input type="text" placeholder="Search our templates" *ngIf="homeTemplateService.searchTemplateInput" [(ngModel)]="homeTemplateService.searchTemplateInput" (click)="openSearchBar()">
                                <button type="button" class="searchButton" (click)="openSearchBar()">
                                    <em class="icon-magnifyglass"></em>
                                </button>
                                <div class="clear-text" *ngIf="homeTemplateService.searchTemplateInput" (click)="homeTemplateService.clearTemplateSearchInput();homeTemplateService.clearFilter('all')">
                                    <img alt="" src="../../../../../assets/images/searchClose.svg" alt="" />
                                </div>
                            &lt;!&ndash; </form> &ndash;&gt;
                        </div>
                        <div class="filter-sort-bx">
                            <app-sort></app-sort>
                        </div>
                        <div class="filterCategory" [ngClass] = "{active: showMobileFilter}">
                            <button class="sortTemplate" (click) = "mobileFilterClicked()">
                                <em class="icon-fillter"></em>
                            </button>
                            <span class="notifyBx" *ngIf = "homeTemplateService.activeFilters.category.length + homeTemplateService.activeFilters.price.length"> {{homeTemplateService.activeFilters.category.length + homeTemplateService.activeFilters.price.length}} </span>
                        </div>-->

                    </div>

                    <div class="expandedSearch" *ngIf="phaseService.showTemplateSearchBox">
                        <div class="searchIcon"><em class="icon-magnifyglass"></em></div>
                        <div class="searchinput">
                          <input #searchInput type="text" (keyup)="searchTemplates($event)" [(ngModel)]="homeTemplateService.searchTemplateInput" autofocus>
                            <ul *ngIf="!homeTemplateService.searchTemplateInput">
                                <li (click)="searchTemplates('uber')">Like “Uber”</li>
                                <li (click)="searchTemplates('facebook')">Like “Facebook”</li>
                                <li (click)="searchTemplates('asana')">Like “Asana”</li>
                            </ul>
                        </div>
                        <div class="searchClose" (click)="openSearchBar()"><em class="icon-cancel"></em></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="templateContainer" [ngClass]="{'hide': !homeTemplateService.searchTemplateInput && phaseService.showTemplateSearchBox}">
            <div class="filterResultBlock" *ngIf = "homeTemplateService.showTemplatesLoader || !apiSuccess || natashaSearchedText || natashaSearchedTextCopy">
                <app-template-loader></app-template-loader>
            </div>
            <ng-container *ngIf = "apiSuccess">

            <div class="filterResultBlock" *ngIf="!homeTemplateService.showSearchedTemplates" [ngClass]="{'fullPageResult' : isFiltered && homeTemplateService.combined_templates.length === 0, overlayBg: showMobileFilter}"> <!--- add class when filter show " overlayBg"-->
                <div class="filterTabs" *ngIf = "!isFiltered">
                    <ul *ngIf="!isComingFrom360">
                        <li [ngClass]="{'active': homeTemplateService.templateType === 'studio_store'}" (click)="getHomeTemplates('studio_store')">Store</li>
                    </ul>
                    <p *ngIf="(homeTemplateService.templateType === 'all') && !isZeroRecommendationFromNatasha">Choose up to 3 templates (apps similar to your idea) to use as a base.</p>
                    <p *ngIf="(homeTemplateService.templateType === 'all') && isZeroRecommendationFromNatasha">We do not have a match right now. Choose any 3 templates (apps similar to your idea) to use as a base.</p>
                    <p *ngIf="homeTemplateService.templateType === 'pro'">Build apps, websites, wearables – any software you can imagine. No coding skills needed.</p>
                    <p *ngIf="homeTemplateService.templateType === 'studio_store'">Complete small business packages for a low monthly cost – includes award-winning apps,<br> maintenance and cloud to run them. Ultra-fast delivery, from 2 weeks.</p>
                </div>
                <div class="tabs-discription" *ngIf = "!all_filters.length > 0 && homeTemplateService.showTemplatesLoader">
                    <div class="content" [ngClass]="{'active': homeTemplateService.templateType === 'pro'}">
                        <p>Build apps, websites, wearables – any software you can imagine. No coding skills needed.</p>
                    </div>
                    <div class="content" [ngClass]="{'active': homeTemplateService.templateType === 'studio_store'}">
                        <p>Complete small business packages for a low monthly cost – includes award – winning apps, maintenance and cloud to run them. Ultra-fast delivery — from 2 weeks.</p>
                    </div>
                </div>

                <div class="selFilterBlock"  *ngIf = "all_filters.length > 0 && !homeTemplateService.showTemplatesLoader">
                    <div class="selItem">
                        <ul class="itemListing">
                            <li *ngFor = "let item of all_filters">{{numberWithCommas(item.title)}}</li>
                            <li>
                              <button type="button" class="clearBtn" (click) = "clearAllFilter()"> Clear all filters </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <ng-container *ngIf = "!isFiltered && apiSuccess">
                    <app-studio-template class="appStudioTemplateWrapper" *ngIf="(homeTemplateService.templateType === 'studio_store')"></app-studio-template>
                    <app-all-template *ngIf="(homeTemplateService.templateType !== 'studio_store')" [templateType]="homeTemplateService.templateType"></app-all-template>
                </ng-container>

                <ng-container *ngIf = "isFiltered">
                    <div class="filterListMain">
                        <div class="filterList"
                        infiniteScroll
                    [infiniteScrollDistance]="2"
                    [infiniteScrollThrottle]="300"
                    (scrolled)="filiterPagination()">
                           <app-home-template-card *ngFor = "let template of homeTemplateService.combined_templates" [type] = "template.attributes && template.attributes.template_type == 'builder_studio' ? 'all': 'studio'"  [template] = "template.attributes ? template.attributes : template" ></app-home-template-card>
                        </div>
                    </div>
                </ng-container>
            </div>
            <ng-container *ngIf = "apiSuccess">
            <div class="filterListMain">
            <div *ngIf="homeTemplateService.showSearchedTemplates" class="filterResultBlock" [ngClass]="{'fullPageResult' : homeTemplateService.searchedTemplates.length === 0}">
                <div class="selFilterBlock no-flx">
                    <div class="search-result">
                        <div class="selItem" *ngIf="homeTemplateService.searchedTemplates.length > 0 || homeTemplateService.sortedsearchedTemplates.length > 0">
                            <p *ngIf="!homeTemplateService.copyNatashaSearchText">Search results for "{{homeTemplateService.searchTemplateInput}}"</p>
                            <p *ngIf="homeTemplateService.copyNatashaSearchText">{{ homeTemplateService.searchedTemplates.length }} results for "{{ homeTemplateService.copyNatashaSearchText }}"</p>
                            <button type="button" class="clearBtn" (click)="clearSearch()"> Clear Search</button>
                        </div>
                        <div class="selItem" *ngIf="homeTemplateService.searchedTemplates.length === 0 && homeTemplateService.sortedsearchedTemplates.length == 0">
                            <p>We searched far and wide and couldn’t find any template matching your search {{homeTemplateService.searchTemplateInput}}</p>
                            <button type="button" class="clearBtn" (click)="clearSearch()"> Clear Search</button>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf = "!isFiltered && apiSuccess && (homeTemplateService.searchedTemplates.length === 0) && (homeTemplateService.sortedsearchedTemplates.length == 0)">
                  <app-studio-template class="appStudioTemplateWrapper" *ngIf="(homeTemplateService.templateType === 'studio_store')"></app-studio-template>
                  <app-all-template *ngIf="(homeTemplateService.templateType !== 'studio_store')" [templateType]="homeTemplateService.templateType"></app-all-template>
                </ng-container>

                <ng-container *ngIf = "apiSuccess && ((homeTemplateService.searchedTemplates.length > 0) || (homeTemplateService.sortedsearchedTemplates.length > 0))">
                    <div class="filterListMain" infiniteScroll
                         [infiniteScrollDistance]="2"
                         [infiniteScrollThrottle]="300"
                         (scrolled)="searchPagination()">
                        <div class="filterList" *ngIf="homeTemplateService.searchedTemplates.length > 0 && homeTemplateService.sortedsearchedTemplates.length === 0">
                            <app-home-template-card *ngFor = "let template of homeTemplateService.searchedTemplates" [type] = "template.attributes && template.attributes.template_type == 'builder_studio' ? 'all': 'studio'" [template] = "template.attributes ? template.attributes : template" ></app-home-template-card>
                        </div>
                        <div class="filterList" *ngIf="homeTemplateService.sortedsearchedTemplates.length > 0">
                            <app-home-template-card *ngFor = "let template of homeTemplateService.sortedsearchedTemplates" [type] = "template.attributes && template.attributes.template_type == 'builder_studio' ? 'all': 'studio'" [template] = "template.attributes ? template.attributes : template" ></app-home-template-card>
                        </div>
                    </div>
                </ng-container>
            </div>
            </div>
            </ng-container>
            </ng-container>

            <div class = "filterResultBlock" *ngIf="homeTemplateService.showTempScrollLoader">
                <ng-container>
                    <app-template-loader></app-template-loader>
                </ng-container>
            </div>
        </div>
</div>
</div>
<!------>

<ng-container *ngIf="innerWidth <= 1023">
<!--template Natasha popup code start-->
<div class="commonPopUp active" [ngClass]="{'bottomOn': this.phaseService.selectedApps?.length}" *ngIf="showNatashaTempIntroPopup()">
    <div class="popOverlay"></div>
    <div class="popHolder natashaTemplatePopup">
        <div class="natashaTemplateMobile" [ngClass]="{'setScrollActive' : natashaScroll, 'firstTimeLand' : phaseService.showTemplateNatashaMobileAnimation || isShowTryingToBuildText}">
            <!--Right top part start-->
            <div class="topPart">
                <div class="expertButton" (click)="talkToExpertsClicked()">
                <ul>
                    <li><img src="../../../../assets/images/experticon1.png" alt="" /></li>
                </ul>
                <span>Talk to our experts</span>
                </div>

                <div class="closeButton" (click)="hideNatashaPanel()"><em class="icon-cancel"></em></div>
            </div>
            <!--Right top part close-->

            <div class="natashaMiddle" #scrollMobile [scrollTop]="scrollMobile.scrollHeight" (scroll)="natashaGroupScrolled($event)"  [ngClass]="{'heightSet1' : !showSpecCallButtons && isShowNatashaSearchSuggestionChip && !homeTemplateService.isShowNatashaNameScreen, 'heightSet2' : homeTemplateService.showConfirmPopup && !homeTemplateService.isShowNatashaNameScreen}">
                <!--Wizard part start-->
                <div class="wizardBox">
                    <div>
                    <div class="wizardDot">
                        <div class="betaBadge"><img src="../../../../../assets/images/beta-badge.svg" alt="" /></div>
                        <div id="wizardAnimation" class="wizardAvatar idleAnimation">
                        <span></span>
                        <span></span>
                        </div>
                    </div>

                    <h3 *ngIf="!isShowTryingToBuildTextMobile"><span>Hey you! I’m <strong>Natasha</strong></span>
                      <span>your AI Product Manager</span></h3>

                    <h3 *ngIf="isShowTryingToBuildTextMobile"><span>Hey {{ dataService?.user ? dataService.user.first_name : fetchUserNameFromSessionStorage() }}!</span>
                      <span>What are you trying to build?</span></h3>

                    </div>
                </div>
                <!--Wizard part close-->

                <div class="rightContent">

                  <ng-container *ngFor="let itemkey of objectKeys(homeTemplateService.mobileTempateNatashaChatList)">
                    <div class="msgContainer animatedRow" [ngClass]="{'userInput' : getTypeOfNatashaGroupElement(itemkey) === 'user'}">
                      <div class="avatarHolder" *ngIf="getTypeOfNatashaGroupElement(itemkey) === 'natasha'">
                        <div id="{{'wizardAnimation' + itemkey}}" class="wizardAvatar idleAnimation">
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                      <div class="avatarHolder" *ngIf="getTypeOfNatashaGroupElement(itemkey) === 'user'">
                        <div class="userAvatar" [ngStyle]="{'background': dataService.natashaAvatarBackground}">
                          {{dataService.natashaAvatarInitials}}
                        </div>
                      </div>

                      <div class="msgHolder">
                        <div class="timeRow" *ngIf="getTypeOfNatashaGroupElement(itemkey) === 'natasha'"><span class="nameTag">Natasha</span> <span class="timeTag">{{getCreatedDateOfFirstNatashaGroupElement(itemkey)}}</span></div>
                        <div class="timeRow" *ngIf="getTypeOfNatashaGroupElement(itemkey) === 'user'"><span class="nameTag" [ngStyle]="{'color': dataService.natashaAvatarChatHandle}">Me</span> <span class="timeTag">{{getCreatedDateOfFirstNatashaGroupElement(itemkey)}}</span></div>

                        <ng-container *ngFor="let chat of homeTemplateService.mobileTempateNatashaChatList[itemkey]">
                          <ng-container *ngIf="!chat.showLoader">

                            <ng-container *ngIf="chat.templateChatText && !chat.natashaIntroLineStatus && !chat.natashaNameStatus">
                              <div class="setBg">
                                <div class="textArea">{{chat.templateChatText}}</div>
                                <div class="actionButton fullView" *ngIf="chat.isNatashaNegativeFeedbackStatus">
                                  <button (click)="natashaNegativeFeedbackTalkToExpertsClicked()">Talk to our experts</button>
                                </div>
                                <div class="actionButton fullView" *ngIf="chat.showSpecCallButtonStatus">
                                  <button type="button" (click)="openMeetNowModal('instant')">Instant call</button>
                                  <button type="button" (click)="openMeetNowModal()">Schedule a call</button>
                                  <button type="button" (click)="openMeetNowModal('liveChat')">
                                    <span>Live chat</span> <span class="threeDotLoader"><span class="dot-flashing"></span></span>
                                  </button>
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="chat.natashaMobileTemplates?.length > 0">
                              <div class="setBg">
                                <div class="recommendedSlider">
                                  <div class="sliderHolder">
                                      <drag-scroll snap-duration="200" (reachesLeftBound)="leftBoundStat($event)"
                                                   (reachesRightBound)="rightBoundStat($event)" #natashaTemplateSlider>
                                        <div class="featureCardBox" *ngFor="let template of chat.natashaMobileTemplates" drag-scroll-item>
                                            <div class="featureCardInner">
                                                <div class="featureIcon">
                                                    <img alt="" [src]="template.attributes?.background_url ? template.attributes?.background_url : template.attributes?.background">
                                                </div>
                                                <div class="featureDetail">
                                                    <h3>{{ template.attributes.title }}</h3>
                                                    <p>{{ template.attributes?.features?.data ? template.attributes?.features?.data?.length : template.attributes?.features?.length }} features selected</p>
                                                    <p>from {{template.attributes?.currency?.data?.attributes?.symbol}}{{(template.attributes?.template_type == 'studio_store' ? (template.attributes?.monthly_price ? (getLocaledPriceInt((template.attributes?.monthly_price) | number : '1.0-0')) : '') : (getLocaledPriceInt((template.attributes?.template_price / 1000) | number : '1.0-0')) + 'k')}}</p>
                                                </div>
                                                <div class="toolButtons" (click)="selectTemplate(template?.attributes)">
                                                    <button type="button" class="addButton" *ngIf="!checkActive(template?.attributes)">
                                                      <em class="icon-plus"></em>
                                                    </button>
                                                    <div class="tickMark" *ngIf="checkActive(template?.attributes)"><em class="icon-checkshape"></em></div>
                                                </div>
                                            </div>
                                        </div>
                                      </drag-scroll>
                                      <!-- <button class="dragbutton leftArrow" [disabled]="leftNavDisabled" type="button"
                                              (click)="moveLeft()"><em class="icon-leftarrownew"></em></button>
                                      <button class="dragbutton rightArrow" [disabled]="rightNavDisabled" type="button"
                                              (click)="moveRight()"><em class="icon-rightarrownew"></em></button> -->
                                    </div>
                                   </div>
                              </div>
                            </ng-container>
                            <ng-container class="inputFieldBox" *ngIf="chat.natashaNameStatus && homeTemplateService.isShowNatashaNameScreen">
                              <div class="setBg">
                                <div class="textArea">{{chat.templateChatText}}</div>
                              </div>

                            </ng-container>
                            <ng-container class="inputFieldBox" *ngIf="showUserNameInMobile">
                              <div class="setBg">
                                <div class="textArea">{{ dataService?.user ? dataService.user.first_name : fetchUserNameFromSessionStorage() }}</div>
                              </div>

                            </ng-container>

                          </ng-container>

                          <ng-container *ngIf="chat.showLoader">
                            <div class="setBg">
                              <div class="textArea" >
                                <div class="threeDotLoader"><div class="dot-flashing"></div></div>
                              </div>
                            </div>
                          </ng-container>


                        </ng-container>

                      </div>
                    </div>
                  </ng-container>
                </div>

            </div>

            <!--Ask anything section start-->
            <div class="askanythingBox" [ngClass]="{'heightSet1' : !showSpecCallButtons && isShowNatashaSearchSuggestionChip && !homeTemplateService.isShowNatashaNameScreen, 'heightSet2' : homeTemplateService.showConfirmPopup && !homeTemplateService.isShowNatashaNameScreen}">
              <ng-container *ngIf="!homeTemplateService.showConfirmPopup && !homeTemplateService.isShowNatashaNameScreen">
                <!-- <div class="suggestionChips" *ngIf="isShowNatashaSearchSuggestionChip"
                  (click)="AppendSuggestionChipInInputText('I want to build')">
                  <button type="button">I want to build…</button>
                </div> -->
                <div class="suggestionChips" *ngIf="!showSpecCallButtons && isShowNatashaSearchSuggestionChip">
                  <button type="button" (click)="AppendSuggestionChipInInputText('I want to build')">I want to build…</button>
                  <button type="button" (click)="AppendSuggestionChipInInputText('I want to have')">I want to have…</button>
                </div>

                <div class="searchBoxSection">
                    <textarea #mobileInputNatasha class="searchBar" placeholder="{{ placeHolderText }}" id="input1"
                    [(ngModel)]="natashaSearchedText"
                    (focus)="checkIfUserStartTyping($event)"
                    (keyup)="checkNatashaWordCount($event)"
                           (keydown)="checkIfUserClickBack($event)"
                    [ngClass]="{'focusThis' : checkWordCount() > 0}"
                     (click)="checkIfUserClickBack($event)"></textarea>
                    <button type="button" class="searchButton" (click)="getRecommendedTemplates()"><em class="icon-asking"></em></button>
                </div>

              </ng-container>

              <ng-container *ngIf="homeTemplateService.showConfirmPopup && !homeTemplateService.isShowNatashaNameScreen">
                <div class="suggestionChips">
                  <button type="button" *ngIf="isShowPositiveFeedbackSuggestionChip" (click)="hideConfirmBox('I like these')">I like these</button>
                  <button type="button" *ngIf="isShowNegativeFeedbackSuggestionChip" (click)="hideConfirmBox('Not so good')">Not so good</button>
                </div>
                <div class="searchBoxSection">
                  <input #mobileInputNatasha type="text" class="searchBar" placeholder="Write a reply"
                  [(ngModel)]="natashaFeedbackResponse" (keyup)="checkIfNatashaFeedbackResponseEmpty()">
                  <button type="button" class="searchButton" (click)="hideConfirmBox(natashaFeedbackResponse)"><em class="icon-asking"></em></button>
                </div>
              </ng-container>

              <ng-container *ngIf="homeTemplateService.isShowNatashaNameScreen">
                <div class="searchBoxSection">
                  <input type="text" class="searchBar" placeholder="John Doe" [(ngModel)]="userNameFromNatasha" (keydown)="checkWordAndCharacterLimitForUserName($event)" maxlength="62"/>
                  <button type="button" class="searchButton" (click)="getUserNameFromNatasha()"><em class="icon-asking"></em></button>
                  <!-- <div class="noteText">Press Enter to submit</div> -->
                </div>
              </ng-container>
            </div>
            <!--Ask anything section close-->
        </div>

    </div>
</div>
<!--template Natasha popup code close-->

<!--template Natasha Bottombar code start-->
<!--<div class="templateNatashaBottombar" (click)="showNatashaSearch()" [ngClass]="{'bottomOn': this.phaseService.selectedApps?.length}">
    <div class="natashaMobileWizard">
        <div class="wizardAvatar idleAnimation">
          <span></span>
          <span></span>
        </div>
        <div class="natashaNotify">1</div>
    </div>

    <strong>Get help with your Buildcard<sup class="tm">®</sup></strong>
    <em class="icon-upside"></em>
</div>-->
<!--template Natasha Bottombar code close-->
</ng-container>

<app-add-new-template *ngIf="phaseService.showAddNewTemplate" (customTemplateAdded)="getHomeTemplates('all')"></app-add-new-template>

<!---- video link start popUp -->
<div class="commonPopUp"  [ngClass]="{'active': showVideo}">
    <div class="popOverlay"></div>
    <div class="popHolder videoPopup">
    <div class="closePopUp" (click)="openVideoPopup('close')"><em class="icon-cancel"></em></div>
    <div class="videoBlock">
        <div class="video-section">
          <img src="../../../../../assets/images/youtubeBlank.png" alt="" />
          <ng-container [ngSwitch]="homeTemplateService.currencyId">
            <iframe *ngSwitchCase="1" title="" width="720" height="405" #videoframe src="https://www.youtube.com/embed/ivETCA7OiGU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe *ngSwitchCase="4" title="" width="720" height="405" #videoframe src="https://www.youtube.com/embed/kXBbXniwuIM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe *ngSwitchDefault title="" width="720" height="405" #videoframe src="https://www.youtube.com/embed/ucy0R65yxPE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </ng-container>
        </div>
    </div>
    </div>
</div>
<!---- video link close popUp -->


<!--template detail popup start-->
<div class="commonPopUp" id='showTemplateModal' [ngClass]='{"active": dataService.isShowTemplateModal}' *ngIf="dataService.isShowTemplateModal">
    <div class="popOverlay"></div>
    <div class="popHolder templateDetailPopup" [ngClass]="{'overflowHide' : phaseService.showRentalPaymentFlow}" >
        <perfect-scrollbar class="myScrollbar" [config]="PERFECT_SCROLLBAR_CONFIG" (psScrollY)="studioStoreModalScrolled($event)">
            <div class="popup-header">
                <div class="share-wrapper" [ngClass]='{"share-wrapper-disabled": !dataService.appDetails.slug}' (click)="getRedirectionLink()"><em class="icon-expandnew"></em>
                  <span class="tooltipBox">Open full screen</span>
                </div>
                <div class="link-wrapper" ngxClipboard (cbOnSuccess)="urlCopied($event)" [cbContent]="getRedirectionUrl()"><em class="icon-hyperlink"></em>
                  <span class="tooltipBox" *ngIf="!urlcopied">Copy link</span>
                  <span class="tooltipBox showtooltip" *ngIf="urlcopied">Copied</span>
                </div>
                <div class="closePopUp" id='closeTemplateModal' (click)="closeTemplateModal()" ><em class="icon-cancel"></em></div>
            </div>
            <div class="templateDetailHolder">
                <app-template-detail-page *ngIf="dataService.appPricingModal !== 'rental_price'">
                </app-template-detail-page>
                <studio-store-template-detail *ngIf="dataService.appPricingModal === 'rental_price'">
                </studio-store-template-detail>
            </div>
        </perfect-scrollbar>
    </div>
</div>
<!--template detail popup close-->

<!-- Max 3 Templates selection popup start -->
<div class="commonPopUp active" *ngIf="homeTemplateService.showMaxTempSelectionAlert">
  <div class="popOverlay"></div>
  <div class="popHolder maxTemplateSelectionAlert ">
    <div class="closePopUp" (click)="closeAlertBox()"><em class="icon-cancel"></em></div>

    <!-- static wizard animation for mobile and tablet -->
    <div class="smallDevicesStaticWizard">
      <div class="wizardAvatar">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <!-- wizard animation end -->

    <div class="textContainer">
      <h3>Maximum of 3 template selections are allowed</h3>
      <p>This is to optimise feature recommendation on our end</p>
      <div class="footerButton">
        <button type="button" class="okButton" (click)="closeAlertBox()">Ok, got it</button>
      </div>
    </div>
  </div>
</div>
<!-- Max 3 Templates selection popup end -->

<!-- Cookies -->
<!--
  <div class="bottomTooltip" [ngClass]="{'bottomOn': this.phaseService.selectedApps?.length}" *ngIf="cookiePolicyMsgPopup">
    <div class="tooltipText"  *ngIf="!cookieMsgChange" (click)="$event.stopPropagation()">
        By using this website you agree to our <a target="_blank" href="https://www.builder.ai/privacy" >cookie policy</a><br> <span (click)="disclaimerReveal()">Click here</span> for important disclaimer about our use of logos
    </div>
    <div class="tooltipText2" *ngIf="cookieMsgChange">
        All third party trademarks™ or registered® trademarks (including logos and icons) referenced on this site remain the property of their respective owners. Use of third party trademarks does not indicate any affiliation, relationship, sponsorship, or endorsement between us and the owners of these trademarks. Any references to third parties or their trademarks are solely to identify the corresponding third party goods and/or services.
    </div>
    <button type="button" (click)="cookiePolicyMsgClose()">OK</button>
</div>
-->

<!-- Natasha Wizard loader -->
<!--<div class="welcomeToNatashaTemplate"
     [ngClass]="{'moveToMaxTemplatePopup': homeTemplateService.showMaxTempSelectionAlert && phaseService.selectedApps.length === 3,
    'landingAnimation': homeTemplateService.isShowTemplateNatashaInitialAnimation && innerWidth > 1023,
    'nameOutputAnimation': (isUserNameEntered || isShowTryingToBuildText) && innerWidth > 1023,
    'natashaOutputAnimation': homeTemplateService.isShowTemplateNatashaIntroPopup && isShowFindSomethingText && innerWidth > 1023,
    'moveToTopbar': animateNatashaAvatarOnIntroPopupClose && innerWidth > 1023,
    'errorMsgAnimation': homeTemplateService.isShowTemplateNatashaIntroPopup && !isShowFindSomethingText && ((showNatashaErrorMsg && !showSpecCallButtons) || showSpecCallButtons) }">

    <div class="betaBadge"><img src="../../../../../assets/images/beta-badge.svg" alt="" /></div>

    <div class="wizardAvatar">
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>

<div class="templateIntroduction" *ngIf="showNatashaTempIntroPopup()">
  <div class="logoIcon"><img src="../../../../../assets/images/engineer-logo.svg" alt="" /></div>
  <div class="closeButton" (click)="closeTemplateNatashaIntroPopup('closeBtn')"><em class="icon-cancel"></em></div>

  <div class="introSection">
    &lt;!&ndash;Dot loading start&ndash;&gt;
    <div class="msgDotLoader loderOne">
      <div class="dot-flashing"></div>
    </div>

    <div class="msgDotLoader loderTwo">
      <div class="dot-flashing"></div>
    </div>
    &lt;!&ndash;Dot loading start&ndash;&gt;

    &lt;!&ndash;First intro msg start&ndash;&gt;
    <div class="introMsgBox">
      <div class="headingAmimation">
        <div class="animateThis"><span>Hey!&nbsp;</span></div> <div class="animateThis"><span>I’m <strong>Natasha</strong></span></div>
        <div class="animateThis"><span>your AI Product Manager</span></div>
      </div>
    </div>
    &lt;!&ndash;First intro msg close&ndash;&gt;

    <div class="msgDotLoader loderTwo">
      <div class="dot-flashing"></div>
    </div>

    &lt;!&ndash;name field area start&ndash;&gt;
    <div class="inputFieldBox" *ngIf="homeTemplateService.isShowNatashaNameScreen">
      <div class="headingAmimation">
        <div class="animateThis">What's your name?</div>
      </div>
      <div class="inputArea">
        <input  type="text" placeholder="John Doe" [(ngModel)]="userNameFromNatasha" (keyup)="getUserNameFromNatasha($event)"
          (keydown)="checkWordAndCharacterLimitForUserName($event)" maxlength="62"/>
        <div class="noteText">Press Enter to submit</div>
      </div>
    </div>
    &lt;!&ndash;name field area end&ndash;&gt;

    &lt;!&ndash;input field area start&ndash;&gt;
    <div class="inputFieldBox" [ngClass]="{'animationQuick' : (showNatashaErrorMsg && !showSpecCallButtons) || showSpecCallButtons}" *ngIf="!isShowNatashaSearchedText && !isShowFindSomethingText">

      <div class="headingAmimation" *ngIf="!tryAgainClicked && !showNatashaErrorMsg && !showSpecCallButtons && !homeTemplateService.isShowNatashaNameScreen">
        <div class="animateThis"><span>Hey {{ dataService?.user ? dataService.user.first_name : fetchUserNameFromSessionStorage() }}!</span></div>
        <div class="animateThis"><span>What are you trying to build?</span></div>
      </div>
      <div class="headingAmimation" *ngIf="showNatashaErrorMsg && !showSpecCallButtons">
        <span>Wow, I'm not sure what you just wrote. Could you explain again?</span>
      </div>
      <div class="headingAmimation" *ngIf="showSpecCallButtons">
        <span>Ok, I admit it, you humans understand each other better than me.</span>
      </div>

      <div class="inputArea" *ngIf="!homeTemplateService.isShowNatashaNameScreen && (!tryAgainClicked && !showNatashaErrorMsg && !showSpecCallButtons) ||
        (showNatashaErrorMsg && !showSpecCallButtons)">
        <textarea #natshaSearchBox type="text" placeholder="{{ placeHolderText }}" id="input1" [rows]="rows"
                  [(ngModel)]="natashaSearchedText"
               (keyup)="getRecommendedTemplates($event, 'Welcome Screen')"
               (focus)="checkIfUserStartTyping($event)"
               (keydown)="checkNatashaWordCount($event)"
                (click)="checkIfUserClickBack($event)">
        </textarea>
        <div *ngIf='isShowNatashaWordCountErrorMsg' class="errorMsgCount">Please describe your idea within 40 words.</div>
        <div class="noteText">Press Enter to submit</div>
        &lt;!&ndash; <div class="suggestionChips" *ngIf="!showSpecCallButtons">
          <button type="button" *ngIf="isShowPositiveFeedbackSuggestionChip" (click)="hideConfirmBox('I like these')">I like these</button>
          <button type="button" *ngIf="isShowNegativeFeedbackSuggestionChip" (click)="hideConfirmBox('Not so good')">Not so good</button>
        </div> &ndash;&gt;
        <div class="suggestionChips" *ngIf="!showSpecCallButtons && isShowNatashaSearchSuggestionChip">
            <button type="button" (click)="AppendSuggestionChipInInputText('I want to make')">I want to make…</button>
            <button type="button" (click)="AppendSuggestionChipInInputText('I want to build')">I want to build…</button>
            <button type="button" (click)="AppendSuggestionChipInInputText('I want to have')">I want to have…</button>
        </div>
      </div>


      <div class="natashaMsgButtons" *ngIf="showSpecCallButtons">
        <button type="button" (click)="openMeetNowModal('instant')">Instant call</button>
        <button type="button" (click)="openMeetNowModal()">Schedule a call</button>
        <button type="button" (click)="openMeetNowModal('liveChat')">
          <span>Live chat</span> <span class="threeDotLoader"><span class="dot-flashing"></span></span>
        </button>
      </div>
    </div>
    &lt;!&ndash;input field area close&ndash;&gt;

    &lt;!&ndash;name field msg start&ndash;&gt;
    <div class="inputMsgBox" *ngIf="(isUserNameEntered || isShowTryingToBuildText)">
      <div class="headingAmimation">
        <span>{{ dataService?.user ? dataService.user.first_name : fetchUserNameFromSessionStorage() }} </span>
      </div>
      <div class="avatarIcon" [ngStyle]="{'background': dataService.natashaAvatarBackground}">
        {{ dataService?.user ? dataService.natashaAvatarInitials : 'Me' }}
      </div>

    </div>
    &lt;!&ndash;name field msg close&ndash;&gt;

    &lt;!&ndash;name output msg start&ndash;&gt;
    <div class="natashaMsgBox" *ngIf="isShowTryingToBuildText">
      <div class="headingAmimation">
        <div class="animateThis"><span>Hey {{ dataService?.user ? dataService.user.first_name : fetchUserNameFromSessionStorage() }}!</span></div>
        <div class="animateThis"><span>What are you trying to build?</span></div>
      </div>
    </div>
    &lt;!&ndash;name output msg close&ndash;&gt;

    &lt;!&ndash;input field msg start&ndash;&gt;
    <div class="inputMsgBox" *ngIf="isShowNatashaSearchedText || isShowFindSomethingText">
      <div class="headingAmimation">
        <span>{{natashaSearchedTextCopy}}</span>
      </div>
      <div class="avatarIcon" [ngStyle]="{'background': dataService.natashaAvatarBackground}">
        {{ dataService?.user ? dataService.natashaAvatarInitials : 'Me' }}
      </div>

    </div>
    &lt;!&ndash;input field msg close&ndash;&gt;



    &lt;!&ndash;natasha output msg start&ndash;&gt;
    <div class="natashaMsgBox" *ngIf="isShowFindSomethingText">
      <div class="headingAmimation">
        <div class="animateThis"><span>Hmm that’s a great idea,</span></div>
        <div class="animateThis"><span>let me find something for you.</span></div>
      </div>
    </div>
    &lt;!&ndash;natasha output msg close&ndash;&gt;


  </div>

  <div class="footerCloseButton" (click)="closeTemplateNatashaIntroPopup('footer')">Actually I’ll browse the library</div>
</div>-->



<div class="commonPopUp active" *ngIf="phaseService.emailConfirmedPoupup && dataService.user">
  <div class="popOverlay"></div>
  <div class="popHolder confirmPopup">
    <div class="closePopUp" (click)="closeConfirmationPopup()"><span class="icon-cancel"></span></div>
    <h3>Thank You!</h3>
    <p *ngIf="!phaseService.userAlreadyVeriied">We've verified your email and are working on your Buildcard<sup class="tm light">®</sup>.</p>
    <p *ngIf="phaseService.userAlreadyVeriied">Your account is already verified.</p>
    <div class="footerButton">
      <button class="doneButton" (click)="closeConfirmationPopup()">OK</button>
    </div>

  </div>
</div>

<!-- whatsup redirection popup start -->
<div class="commonPopUp active" *ngIf="!hideNotifyPopup">
  <div class="popOverlay"></div>
  <div class="popHolder whatsupRedirectionPopup">
    <div class="closePopUp" (click)="hideNotifyPopup = true" ><em class="icon-cancel"></em></div>
      <h3>Spec call has been successfully cancelled.</h3>
      <div class="footerButton">
        <button type="button" class="okButton" (click)="hideNotifyPopup = true" >Ok, got it</button>
      </div>
  </div>
</div>
<!-- whatsup redirection popup end -->

<app-scheduled-call-cancellation *ngIf="appDataService.urlParameters.cancel_call_id">
</app-scheduled-call-cancellation>
