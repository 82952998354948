<div class="ndaScreen">
  <div *ngIf="partnerDataService.contracts">
    <h3>Add Contract & NDA</h3>
    <div *ngFor="let contract of partnerDataService.contracts">
      <div class="sectionRow">
        <div class="ndaIcon"><img src="../../../../../../assets/images/ndaimg1.jpg" alt=""/></div>
        <div class="ndaDetail">
          <h4>Non Disclosure Document</h4>
          <p>Protect your proprietary information.</p>
          <button class="signButton"
                  type="button"
                  *ngIf="!contract.nda_signed"
                  (click)="fetchDocSignUrl()">SIGN AN NDA
          </button>
          <button class="signedButton"
                  type="button"
                  *ngIf="contract.nda_signed">
            <em class="icon-right"></em> Signed
          </button>
          <button class="viewButton" type="button" *ngIf="contract.nda_signed && contract.nda_link"
                  (click)="viewContract(contract.nda_link)">VIEW
          </button>
        </div>
      </div>

      <div class="sectionRow" *ngFor="let masterContract of contract.partner_contracts">
        <div class="ndaIcon"><img src="../../../../../../assets/images/ndaimg2.jpg" alt=""/></div>
        <div class="ndaDetail">
          <h4>Master Contract</h4>
          <p>Contract between you and Engineer.AI for the services being rendered</p>
          <button class="signButton" type="button" (click)="signMasterContract(masterContract)"
                  *ngIf="!masterContract.nda_signed">SIGN CONTRACT
          </button>
          <button class="signedButton" type="button" *ngIf="masterContract.nda_signed"><em class="icon-right"></em>
            Signed
          </button>
          <button class="viewButton" type="button" *ngIf="masterContract.nda_signed && masterContract.link"
                  (click)="viewContract(masterContract.link)">VIEW
          </button>
        </div>
      </div>
    </div>
    <div class="buttonControl">
      <button type="button" class="prevButton"
              (click)="partnerDataService.goBackToProfileEdit(partnerDataService.profileForm); dataService.isPartnerProfileUpdated = false">
        Prev
      </button>
      <!--<button type="button" class="doneButton">Next</button>-->
      <button type="button" class="doneButton" (click)="submitForVerification()"
              [disabled]="!partnerDataService.partnerContractsSigned() || (contracts && contracts[0].partner_contracts && !contracts[0].partner_contracts[0].nda_signed)">
        Submit for Verification
        <img *ngIf="loader" src="./../../../assets/images/buttonLoader.gif" alt=""/>
      </button>
    </div>
  </div>
</div>
