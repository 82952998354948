import {JsonObject, JsonProperty} from 'json2typescript';

/**
 * Created by nitin on 14/12/17.
 */
@JsonObject
export class TeamModel {
  @JsonProperty('description', String)
  description: string = null;
  @JsonProperty('icon', String)
  icon: string = null;
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('price_multiplier', Number)
  price_multiplier = 0;
  @JsonProperty('title', String)
  title: string = null;
  @JsonProperty('week_multiplier', Number)
  week_multiplier = 0;
  selected = false;
}
