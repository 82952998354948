import {finalize} from 'rxjs/operators';
import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {PhaseService} from '../../services/phase.service';
import {ApiRequests} from '../../services/api-requests.service';
import {DataService} from '../../services/data.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {UtilityService} from '@shared/services/utility.service';
import {AppDataService} from '@rootmodule/app-data.service';
import {Router} from '@angular/router';
import { Intercom } from 'ng-intercom';


@Component({
  selector: 'share-url-button',
  styleUrls: ['share-url-button.component.scss'],
  templateUrl: 'share-url-button.component.html'
})

export class ShareUrlButtonComponent{

  @Input('currentUrl') currentUrl;
  public shortenedUrl = '';
  public linkCopied = false;
  public shareLoader = false;
  public randomId: string;
  public showToaster = false;
  @Input() isForMobile: boolean;

  constructor(public appDataService: AppDataService, public phaseService: PhaseService, public apiRequest: ApiRequests,
              public dataService: DataService, public analyticsSegment: Angulartics2Segment, 
              private cdRef: ChangeDetectorRef, public utilityService: UtilityService) {
  }

  public configureUrl(url, queryParam, data) {
    const arr = [];
    if (data.length > 0) {
      data.map((datum) => {
        arr.push(datum.id);
      });
      url += queryParam + '=' + arr.join(',');
    }
    return url;
  }

  public shareUrlForPhaseRevamp() {
    const urlHash: any = {};

    urlHash.curPage = this.currentUrl.substr(1);
    urlHash.templateIds = this.phaseService.selectedApps;
    urlHash.platformIds = this.phaseService.selectedPlatforms;
    urlHash.featureIds = this.phaseService.selectedFeatures;
    urlHash.buildphases = this.phaseService.selectedPhases;
    urlHash.productId = this.phaseService.selectedHash.product_id;
    urlHash.speed = this.phaseService.selectedSpeed;
    if (this.phaseService.selectedTeam) {
      urlHash.team = this.phaseService.selectedTeam;
    }
    urlHash.support = this.phaseService.selectedHash.is_under_support;
    urlHash.breadcrumbs = this.phaseService.breadcrumb_links;
    urlHash.is_advance = this.phaseService.selectedHash.is_advance;
    urlHash.cloud_opted = this.phaseService.selectedHash.cloud_opted;
    urlHash.cloud_price = this.phaseService.selectedHash.cloud_price;
    urlHash.cloud_user_count = this.phaseService.selectedHash.cloud_user_count;
    return urlHash;
  }

  public createSharableUrl() {
    this.addUrl(JSON.stringify(this.shareUrlForPhaseRevamp()));
    this.phaseService.trackEventForObject(this.analyticsSegment, "build_card_copied", {
      user_id : this.dataService.user?.id,
      screen_source : this.dataService.currentPage
    });

  }

  public addUrl(url) {
    this.shareLoader = true;
    this.randomId = this.utilityService.generateRandomString();
    this.shortenedUrl = `${window.location.origin}/#/shared-buildcard/${this.randomId}`;

    if (this.randomId) {
      this.setUrlToClipboard(this.shortenedUrl);
      this.linkCopied = true;
      this.cdRef.detectChanges();
      setTimeout(() => {
        this.linkCopied = false;
        this.cdRef.detectChanges();
      }, 2000);
    }
    this.apiRequest.createSharableUrl(url, this.randomId).pipe(
      finalize(() => {
        this.shareLoader = false;
        this.showToaster = true;
        this.dataService.showToasterOnFeaturePage = true;
        setTimeout(() => {
          this.showToaster = false;
          this.dataService.showToasterOnFeaturePage = false;
          this.cdRef.detectChanges();
        }, 2000);
      }))
      .subscribe(
        (data) => {
        }
      );
  }

  public setUrlToClipboard(url) {
    const textArea: any = document.createElement('textArea');
    textArea.value = url;
    document.body.appendChild(textArea);
    if (navigator.userAgent.match(/ipad|iphone/i)) {
      const range = document.createRange();
      range.selectNodeContents(textArea);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
    try {
      document.execCommand('copy');
    } catch (err) {
    }
    document.body.removeChild(textArea);
  }

}
