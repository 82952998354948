import {CurrencyModel} from '../currency-model/currency.model';
import {SpeedModel} from '../speed-model/speed.model';
import {TeamModel} from '../teams-model/team.model';
import {FeatureModel} from '../feature-model/feature.model';

/**
 * Created by nitin on 14/12/17.
 */
export class PromotionModel {
  code: string;

  currency: CurrencyModel;

  discount: number;

  id: number;

  multiplier: number;

  type: string;

  generic_promotion: boolean;

  discount_percentage: number;

  discounted_feature_count: number;

  discounted_platform_count: number;

  banner: string;

  speed: Array<SpeedModel>;

  team: Array<TeamModel>;

  features: Array<FeatureModel>;

  platforms: Array<any>;

  applications: Array<any>;

  expires_at: string;

  promotion_type: string;

  countries:[];

  error_message: string;

  deposit: number;

  months_discount: number;

  description: string;

  is_enterprise_promo: boolean;

  success_banner: string;

  weeks_extension_max: number;

}
