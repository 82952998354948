import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  isPlatformBrowser = false;
  isToastPresent = false;
  isDuplicateCardFlow = false;
  duplicateCardData = { uniQueCode: '', currency: null };
  duplicate_for_same_customer: boolean;
  isBNPLRejected = false;
  isBuildCardSynced = false;
  isTwoPayRegCompany = false;
  twoPayCompanyDetails: any;
  twoPayInstallments: any;
  twoPayBillingIDDetails: any;
  startTwoPayFlow = false;
  isTwoPayRejected = false;
  twoPayCreditCheckData: any;
  Editor: any;
  featureAdded: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private toasterService: ToastrService, private router: Router) { }

  showError(error?) {
    const options = {
      messageClass: 'toast-message',
      closeButton: true,
      timeOut: 2000,
      enableHtml: true,
      maxOpened: 1,
      preventDuplicates: true,
      preventOpenDuplicates: true,
      newestOnTop: true,
      autoDismiss: false,
      onHidden: this.enableToaster()
    };
    if (!this.isToastPresent) {
      this.isToastPresent = true;
      let showMessage = 'Please try after sometime!';
      try {
        showMessage = (error.error.message ? error.error.message : 'Please try after sometime!');
      } catch (e) { showMessage = 'Please try after sometime!'; }
      this.toasterService.error(showMessage, 'Error', options);
    }
  }

  showSuccessToaster(message: string) {
    this.toasterService.success(message, 'info');
  }

  enableToaster() {
    setTimeout(() => { this.isToastPresent = false; }, 2000);
  }

  navigateToTnC(isFromStore?) {
    let url = 'tnc-pro';
    if (isFromStore) {
      url = 'tnc-store';
    }
    this.router.navigate([]).then(() => { window.open(url, '_blank'); });
  }

  setEditorConfiguration() {
    if (this.isPlatformBrowser) {
      const BalloonEditor = require('@ckeditor/ckeditor5-build-balloon');
      this.Editor = BalloonEditor;
      this.Editor.defaultConfig = {
        'toolbar': {
          'items': [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'insertTable',
            'undo',
            'redo'
          ]
        },
        'image': {
          'toolbar': [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative'
          ]
        },
        'table': {
          'contentToolbar': [
            'tableColumn',
            'tableRow',
            'mergeTableCells'
          ]
        },
        'language': 'en'
      };
    }
  }
}
