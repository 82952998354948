import {AppModel} from '../app-model/app.model';
import {FeatureModel} from '../feature-model/feature.model';
import {PhaseModel} from '../phase-model/phase.model';
import {ProductModel} from '../product-model/product.model';
import {PlatformModel} from '../platform-model/platform.model';
import {TeamModel} from '../teams-model/team.model';
import {SpeedModel} from '../speed-model/speed.model';
import {BuildCardPhaseModel} from '@models/build-card-model/buildcardphase.model';

/**
 * Created by nitin on 14/12/17.
 */
export class ProgressModel {

  apps: Array<AppModel>;
  buildphases: Array<PhaseModel>;
  buildcardphases: Array<BuildCardPhaseModel>;
  features: Array<FeatureModel>;
  home: ProductModel;
  platforms: Array<PlatformModel>;
  team: Array<TeamModel>;
  speed: Array<SpeedModel>;
}

