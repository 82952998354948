import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {ApiRequests} from '@shared/services/api-requests.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Constants} from '@shared/utils/constants';
import {environment} from '@env/environment';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'payment-emi',
  templateUrl: 'emi.component.html'
})

export class EmiComponent {
  showMessage = '';
  supportMaintenance = null;

  /*@ngInject*/
  constructor(public analyticsSegment: Angulartics2Segment,  public phaseService: PhaseService, public dataService: DataService, public router: Router, public apiRequest: ApiRequests) {
    this.phaseService.currencySymbol = dataService.user.currency.symbol;
    this.phaseService.supportMaintenance = null;
  }

  public ngOnInit() {
    this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.installments.code);
    this.dataService.showHideLoader(true);
    this.apiRequest.fetchInstallments(this.phaseService.inProgressBuildCardId, this.phaseService.paidPhaseId).subscribe((data: any) => {
      if (data) {
        this.phaseService.paymentData = data;
        if (this.dataService.buildCardData && this.dataService.buildCardData.specing && !this.dataService.buildCardData.specing_paid) {
          this.gotoBilling();
        } else {
          this.phaseService.installments = this.phaseService.paymentData.installments.filter((installment) => {
            return installment.type === 'installment';
          });

          this.phaseService.deposit = this.phaseService.paymentData.installments.find(installmemt => {
            return installmemt.type === 'deposit';
          });

          this.supportMaintenance = this.phaseService.paymentData.installments.find(installment => {
            return installment.type === 'support';
          });

          if (this.phaseService.holdOfferFlow) {
            this.gotoBilling();
          }
        }
      }
      this.dataService.showHideLoader(false);
    }, (error) => {
      this.dataService.showHideLoader(false);
    });
    if (this.dataService.buildCardData && !this.dataService.buildCardData.is_approved) {
      this.showMessage = 'Your request has been sent to partner for approval';
    }
  }

  public selectDeSelectSupport() {
    if (this.phaseService.selectedHash.support_maintenance_weeks > 0) {
      this.phaseService.selectedHash.support_maintenance_weeks = 0;
      this.deSelectPhase(this.dataService.homePageData.build_phases[5]);
    } else {
      this.phaseService.selectedHash.support_maintenance_weeks = 1;
      this.selectPhase(this.dataService.homePageData.build_phases[5]);
    }
  }

  public deSelectPhase(phase) {
    phase.selected = false;
    this.phaseService.removeSelectedBuildId(phase.index);
    this.phaseService.removeSelectedBuildIds(phase.index);
    this.phaseService.removeSelectedBuild(phase);
  }

  public selectPhase(phase) {
    phase.selected = true;
    this.phaseService.addSelectedBuildId(phase.index);
    this.phaseService.addSelectedBuildIds(phase.index);
    this.phaseService.addSelectedBuild(phase);
  }

  public gotoBilling() {
    this.router.navigate([`buildcard-payment/${this.dataService.buildCardData.id}`, 'signcontract']);

  }

  public getPaymentData() {
    return this.phaseService.paymentData;
  }

  public getLocaledPrice(price: any): any {
    return this.dataService.getLocaledPrice(price);
  }

  public getCurrencySymbol() {
    return this.phaseService.currencySymbol;
  }

  public getBuildCardData() {
    return this.dataService.buildCardData;
  }

  public getShowLoader() {
    return this.dataService.showLoader;
  }

  public getInstallments() {
    return this.phaseService.installments;
  }

  public getDeposit() {
    return this.phaseService.deposit;
  }

  public getSupportMaintenance() {
    return this.supportMaintenance;
  }

  public getSelectedSupportMaintenanceweeks() {
    return this.phaseService.selectedHash.support_maintenance_weeks;
  }

  public getInstallmentWithTax(installment: any) {
    return this.phaseService.getInstallmentWithTax(installment);
  }

  public getOriginalAmount(price: any) {
    return this.phaseService.getOriginalAmount(price);
  }

  public getTaxOnTotal() {
    return this.phaseService.getTaxOnTotal();
  }

  public getTotalWithTax() {
    return this.phaseService.getTotalWithTax();
  }

  public getPreventClick() {
    return this.phaseService.preventClick;
  }

  sendForPartnerApproval() {
    this.apiRequest.sendCardForPartnerApproval(this.phaseService.inProgressBuildCardId).subscribe((data) => {
      this.phaseService.preventClick = false;
      this.showMessage = 'Your request has been sent to partner for approval';
      this.dataService.buildCardData.is_approved = false;
    }, error => {
      this.phaseService.preventClick = false;
    });
  }

  isBuildCardApproved() {
    return this.dataService.buildCardData && this.dataService.buildCardData.is_approved;
  }

  public gotoDashboard() {
    this.router.navigate(['dashboard']);
  }
}
