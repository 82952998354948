<div class="upgradePaymentPage">
    <div class="upgradePaymentHolder">
        <!-- <div class="backButton">
            <button type="button"><em class="icon-prev"></em> Back</button>
        </div> -->

        <div class="upgradePaymentLeft">
            <iframe  #iframe id="myframe"  [class.d-none]="isLoading" title="" frameborder="0"></iframe>
            <div *ngIf="isLoading" class="sectionDotloader">
                <div class="dot-flashing"></div>
              </div>
        </div>

        <div class="upgradePaymentRight">
            <div class="sectionBox borderBottom">
                <h3>Order Summary</h3>
                <ul>
                    <li>
                        <div class="labelText"><strong>Premium E-Commerce bundle</strong></div>
                        <div class="valueBox" *ngIf="!appDataService?.urlParameters.upfront"><strong>Instalments</strong>
                            <div class="infoIconBox">
                                <em class="icon-info-circle infoIcon"></em>
                                <div class="tooltipBox" *ngIf="!appDataService?.urlParameters.upfront">
                                    Installments for Studio Store is the amount you pay each month.
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="labelText">Aftercare warranty</div>
                        <div class="valueBox">24 months
                            <div class="infoIconBox">
                                <em class="icon-info-circle infoIcon"></em>
                                <div class="tooltipBox">
                                    Free Aftercare - Our built-in support keeps your website and apps up to date with no fuss. Plus, you get ongoing dedicated support.
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="labelText">Free hosting</div>
                        <div class="valueBox">24 months
                            <div class="infoIconBox">
                                <em class="icon-info-circle infoIcon"></em>
                                <div class="tooltipBox">
                                    Free hosting (24 months) - Best-in-class multi-cloud: Azure, AWS, and more. Just one bill (for a lot less).
                                </div>
                            </div>
                        </div>
                    </li>
                    <li *ngIf="SmbUser">
                        <div class="labelText">Microsoft 365 (2 year license)</div>
                        <div class="valueBox">INR 3,000 Free </div>
                    </li>
                </ul>
            </div>

            <div class="sectionBox">
                <h3>Pay</h3>
                <ul>
                    <li>
                        <div class="labelText" *ngIf="!appDataService?.urlParameters.upfront"><strong>Deposit</strong></div>
                        <div class="labelText" *ngIf="appDataService?.urlParameters.upfront"><strong>Amount</strong></div>
                        <div class="valueBox"><strong>{{dataService.user.currency.symbol}}{{phaseService.depositAmount}}</strong></div>
                    </li>
                </ul>
                <div class="textBox" *ngIf="!appDataService?.urlParameters.upfront">You only pay for a deposit now, which will be refunded after the last installment.</div>
            </div>
            

        </div>
    </div>
</div>