import {Injectable} from '@angular/core';
import {DataService} from '@shared/services/data.service';
import {Constants} from '@shared/utils/constants';
import {GenericService} from './generic.service';
import {catchError, map} from 'rxjs/internal/operators';
import {Observable} from 'rxjs';
import {IUser} from '../interfaces/user.interface';
import {HttpRequests} from '@shared/services/http-requests.service';
import {environment} from '@env/environment';
import {AppDataService} from '@rootmodule/app-data.service';


@Injectable()
export class AdminAuthService {
  public adminUser: IUser;

  constructor(private appDataService: AppDataService,
              private _dataService: DataService,
              private _http: HttpRequests,
              private _genericService: GenericService) {
  }

  public isLoggedIn() {
    return this._genericService.getAdminUser();
  }

  public login(form): Observable<any> {
    const URI = environment.API_URL + Constants.apiEndPoints.payment_dashboard_signin;
    const user = {
      email: form.email,
      password: form.password
    };

    return this._http.post(URI, user, this.appDataService.getRequestOptionArgs()).pipe(
      map(
        (response) => {
          return response;
        }), catchError(error => this._genericService.handleError(error))
    );

  }
}
