
<div class="paymentThanksPage">
    <div class="paymentThanksHolder">
        <div class="headerTop">
            <h2>Congratulations 🎉  <span>Your plan has been upgraded!</span></h2>
            <!--<p>Go check your email for confirmation of your new store.</p>
            <h4>Transaction Id: {{getTransactionID()}}</h4>-->
        </div>
            <div class="planHeader">
                <div class="planThumb"><img src="{{image}}" alt=""></div>
                <div class="planTitle">
                    <h3>{{title}}</h3>
                    <p>{{content}}</p>
                </div>
            </div>
        <button type="button" (click)="goToDashboard()">View Dashboard</button>
    </div>
</div>