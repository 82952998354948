<div class="phasesHead" [ngClass]="{'advancedMode' : advancedVersion}">
  <h3>
    <div class="phaseIcon">
      <img src="{{ phase.icon }}"  *ngIf="phase['type'] !== 'custom_phase'" alt=""/>
      <img src="../../../../../assets/images/star.png"  *ngIf="phase['type'] === 'custom_phase'" alt=""/>
    </div>
    <strong>{{ (phase.title === 'Professional Prototype') ? dataService.returnNeededPrototypeName() : phase.title }} </strong>
    <div class="phasesinfo" *ngIf="phase['type'] !== 'custom_phase'" (click)="showLearnMore(phase.position - 1)">
      <img src="../../../../../assets/images/info_blue.png" alt="" />
    </div>
  </h3>

  <div class="mobileDate"
    *ngIf="(!advancedVersion && !phaseService.timePromotionApplied()) || (phaseService.checkIfOnlyRapidPrototypeSelected() && phase.is_tailor_made)">
    <div>
      <strong>Estimated Duration:</strong>
      <span *ngIf="(phase.initial_date && phase.final_date && phase.selected);else noDuration">
        {{ phase.calculated_weeks | durationUnit:'Week' }}
      </span>
      <ng-template #noDuration><span class="empty-string">---</span></ng-template>
    </div>

    <div>
      <strong>Estimated Delivery Date:</strong>
      <div *ngIf="(phase.initial_date && phase.final_date && phase.selected);else noDate">
        {{ phaseService.getFormattedDate(phase.final_date)}}
      </div>
      <ng-template #noDate><span class="empty-string">---</span></ng-template>
    </div>
  </div>


  <div class="checkBox" [ngClass]="{ 'custom-disabled': phaseService.isPlatformRequest }">
    <input type="checkbox" [id]="'phaseSelected' + phase.index" [checked]="phase.selected"
           (click)="togglePhaseSelection(index, $event)">
    <label [for]="'phaseSelected' + phase.index"></label>
  </div>

  <div class="toggleIcon" [ngClass]="{'active' : showPrototypePhaseList, 'primeColor' : dataService.homePageData.build_phases[2].selected }"
       *ngIf="!checkIfDesignSelected() && (phase.title === 'Professional Prototype')"
       (click)="showPrototypePhases()">
  </div>

</div>

<ng-container *ngIf="showPrototypePhaseList && !checkIfDesignSelected()">
  <div class="phasesHead dropdownBox" *ngIf="!phase?.is_tailor_made" [ngClass]="{'advancedMode' : advancedVersion}">
    <h3>
      <div class="phaseIcon">
        <img src="{{ phase.icon }}"  *ngIf="phase['type'] !== 'custom_phase'" alt=""/>
        <img src="../../../../../assets/images/star.png"  *ngIf="phase['type'] === 'custom_phase'" alt=""/>
      </div>
      <strong>Tailor-made Prototype</strong>
      <div class="phasesinfo" *ngIf="phase['type'] !== 'custom_phase'" (click)="showLearnMore((phase.position - 1), 'Tailor-made Prototype')">
        <img alt="" src="../../../../../assets/images/info_blue.png" alt="" />
      </div>
    </h3>

    <!--<div class="mobileDate" *ngIf="!advancedVersion && !phaseService.timePromotionApplied()">
      <strong>Delivered by</strong>
      <span *ngIf="(phase.initial_date && phase.final_date && phase.selected);else noDate">
      {{ phaseService.getFormattedDate(phase.final_date)}}
    </span>
      <span><ng-template #noDate>-&#45;&#45;</ng-template></span>
    </div>-->


    <div class="checkBox" [ngClass]="{ 'custom-disabled': phaseService.isPlatformRequest }">
      <input type="checkbox" [id]="'phaseSelectedgagan' + phase.index"
             (click)="togglePhaseSelection(index, $event, 'Tailor-made Prototype')">
      <label [for]="'phaseSelectedgagan' + phase.index"></label>
    </div>

  </div>
  <div class="phasesHead dropdownBox" *ngIf="phase?.is_tailor_made" [ngClass]="{'advancedMode' : advancedVersion}">
    <h3>
      <div class="phaseIcon">
        <img src="{{ phase.icon }}"  *ngIf="phase['type'] !== 'custom_phase'" alt=""/>
        <img src="../../../../../assets/images/star.png"  *ngIf="phase['type'] === 'custom_phase'" alt=""/>
      </div>
      <strong>Professional Prototype</strong>
      <div class="phasesinfo" *ngIf="phase['type'] !== 'custom_phase'" (click)="showLearnMore((phase.position - 1), 'Professional Prototype')">
        <img alt="" src="../../../../../assets/images/info_blue.png" alt="" />
      </div>
    </h3>

    <!--<div class="mobileDate" *ngIf="!advancedVersion && !phaseService.timePromotionApplied()">
      <strong>Delivered by</strong>
      <span *ngIf="(phase.initial_date && phase.final_date && phase.selected);else noDate">
      {{ phaseService.getFormattedDate(phase.final_date)}}
    </span>
      <span><ng-template #noDate>-&#45;&#45;</ng-template></span>
    </div>-->


    <div class="checkBox" [ngClass]="{ 'custom-disabled': phaseService.isPlatformRequest }">
      <input type="checkbox" [id]="'phaseSelectedgagan' + phase.index"
             (click)="togglePhaseSelection(index, $event, 'Professional Prototype')">
      <label [for]="'phaseSelectedgagan' + phase.index"></label>
    </div>

  </div>
</ng-container>

<ng-container *ngIf="!(phaseService.checkIfOnlyRapidPrototypeSelected() && phase.is_tailor_made) && advancedVersion || (!advancedVersion && ((phase.title === 'MVP') || (phase['type'] === 'custom_phase')) && phase.selected && phase.active)">
  <div class="platformSection" *ngIf="phase.title !== 'Product Roadmap'">
    <h3 *ngIf="((!phaseService.checkIfOnlyRapidPrototypeSelected() || !phase.is_tailor_made) && !checkIfCustomPhaseWithPlatforms()) || (phaseService.checkIfOnlyRapidPrototypeSelected() && phase.title !== 'Professional Prototype')">Platform <span (click)="openPlatformEditPopup(phase)">Change</span></h3>
    <h3 *ngIf="phaseService.checkIfOnlyRapidPrototypeSelected() && phase.is_tailor_made">Devices <span (click)="openPlatformEditPopup(phase)" class="custom-disabled-txt">Change</span></h3>
    <ul>
      <ng-container *ngFor="let platform of phase.platforms; let i = index">
        <li *ngIf="i < 3 && (!phaseService.checkIfOnlyRapidPrototypeSelected() || (phaseService.checkIfOnlyRapidPrototypeSelected() && phase.title !== dataService.returnNeededPrototypeName()))">
          <div class="platformIcon">
            <!--<div class="checkBox"></div>-->
            <img [src]="platform.icon" (click)="openPlatformEditPopup(phase)" alt="" />
          </div>
          {{ platform.title }}
        </li>
        <li *ngIf="i < 3 && (phaseService.checkIfOnlyRapidPrototypeSelected() && phase.title === 'Professional Prototype')">
          <div class="platformIcon cursornone">
            <!--<div class="checkBox"></div>-->
            <img [src]="platform.icon" alt="" />
          </div>
          {{ platform.title }}
        </li>
      </ng-container>
      <li class="lastLi" *ngIf="phase.platforms?.length > 3">
        <div class="platformIcon moreLink"><span
          (click)="openPlatformEditPopup(phase)">+{{ phase.platforms?.length - 3 }}</span></div>
      </li>
    </ul>

    <div class="platformOverlay" *ngIf="allPlatformsShown[phase.title]" (click)="showLimitedPlatforms(phase)"></div>
    <div class="morePlatforms" *ngIf="allPlatformsShown[phase.title]">
      <h3>You Selected {{ phase.platforms.length }} Platforms</h3>
      <div class="platformScroll">
        <perfect-scrollbar [config]="PERFECT_SCROLLBAR_CONFIG">
          <ul *ngIf="phase.active">
            <ng-container *ngFor="let platform of phase.platforms; let i = index">
              <li>
                <div class="platformIcon">
                  <!--<div class="checkBox"></div>-->
                  <img [src]="platform.icon" alt="" />
                </div>
                {{ platform.title }}
              </li>
            </ng-container>
          </ul>
        </perfect-scrollbar>
      </div>
    </div>
    <button type="button" *ngIf="checkIfCustomPhaseWithPlatforms()" (click)="openPlatformEditPopup(phase)" class="buttonStyle">Select Platforms</button>
  </div>

  <div class="featureSection">
    <h3>Features <span *ngIf="!checkIfCustomPhaseWithPlatforms()" [ngClass]="{'custom-disabled': !phase.platforms?.length}" (click)="openFeatureSlider(phase)">{{((phase.title === 'MVP') || (phase['type'] === 'custom_phase')) ? 'Change' : 'View'}}</span></h3>
    <p *ngIf="!checkIfCustomPhaseWithPlatforms()" (click)="openFeatureSlider(phase)">{{ getSelectedFeatureCount() }}
      {{ getSelectedFeatureCount() > '1' ? 'Features' : 'Feature'}} selected</p>
    <button type="button" *ngIf="checkIfCustomPhaseWithPlatforms()" (click)="showToaterMessage()" class="buttonStyle">Select Features</button>
  </div>

  <div class="speedSection" *ngIf="advancedVersion">
    <h3>Working Speed</h3>

    <div class="speedSlider" *ngIf="phase.selected">
      <ngx-slider [(value)]="(phaseService.assignSameSpeed || phase?.speed.id) ? phase?.speed.id : barValue"
                  [options]="checkIfDisableSpeed() ? optionsWithoutRelax : options"
                  (valueChange)="changeSpeed($event, phase)"
                  (click)="removeCheckbox()">
      </ngx-slider>
    </div>
    <div class="selectedSpeedRow">
      <div *ngIf="!checkIfDisableSpeed()" class="speedName" [ngClass]="{'active' : phase?.speed?.title === 'Relaxed'}"><span>{{ phase?.speed?.title }}</span></div>
      <div class="speedName" [ngClass]="{'active' : phase?.speed?.title === 'Slow'}"><span>{{ phase?.speed?.title }}</span></div>
      <div class="speedName" [ngClass]="{'active' : phase?.speed?.title === 'Standard'}"><span>{{ phase?.speed?.title }}</span></div>
      <div class="speedName" [ngClass]="{'active' : phase?.speed?.title === 'Fast'}"><span>{{ phase?.speed?.title }}</span></div>
      <div class="speedName" [ngClass]="{'active' : phase?.speed?.title === 'Speedy'}"><span>{{ phase?.speed?.title }}</span></div>
    </div>
    <div class="selectAll" *ngIf="phase.selected">
      <input type="checkbox" [checked]="phase.isChecked" id="selectallsame{{index}}" (change)="assignSpeedToAllPhases($event)"> <label for="selectallsame{{index}}">Same speed for all the phases</label>
    </div>
  </div>
</ng-container>

<div class="deliveryDate" *ngIf="advancedVersion && !(phaseService.checkIfOnlyRapidPrototypeSelected() && phase.is_tailor_made)">
  <h3 class="durationBlock">
    <strong>Estimated Duration</strong>
    <ng-container *ngIf="!phaseService.timePromotionApplied()">
      <span *ngIf="(phase.initial_date && phase.final_date && phase.selected); else noDuration">
        {{ phase.calculated_weeks | durationUnit:'Week' }}
      </span>
      <ng-template #noDuration>---</ng-template>
    </ng-container>
    <ng-container *ngIf="phaseService.timePromotionApplied()">
      <span *ngIf="!phase.selected">To be estimated</span>
      <span *ngIf="phase.selected">Estimated later</span>
    </ng-container>
  </h3>
  <h3>
    <strong>Estimated Delivery Date</strong>
    <ng-container *ngIf="!phaseService.timePromotionApplied()">
      <span *ngIf="(phase.initial_date && phase.final_date && phase.selected); else noDate">
        {{ phaseService.getFormattedDate(phase.final_date)}}
      </span>
      <ng-template #noDate>---</ng-template>
    </ng-container>
    <ng-container *ngIf="phaseService.timePromotionApplied()">
      <span *ngIf="!phase.selected">To be estimated</span>
      <span *ngIf="phase.selected">Estimated later</span>
    </ng-container>
  </h3>
</div>

<learn-more *ngIf="index === phaseService.learnMoreIndex"></learn-more>

<feature-slider *ngIf="showFeaturesList" [phase]="phase" [showFeatureList]="showFeaturesList"
                [editableFeatureList]="editableFeatureList" (featureSliderCallBack)="handleCallBack($event)">
</feature-slider>

<platform-list class="forMobileNew" [phase]="phase" [index]="phase.index" [generalView]="!advancedVersion" [isFromPhase]="true"
               *ngIf="openPlatform" (closeCallback)="closePlatformPopup()"></platform-list>
