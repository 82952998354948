<!-- backup for onboarding screen <div class="deliveryHolder" *ngIf="!dataService.showDeliveryLoading"
  [ngClass]="{
    tooltipOne: tooltipIndex === 0 && !tutorialSeen,
    tooltipTwo: tooltipIndex === 1 && !tutorialSeen,
    tooltipThree: tooltipIndex === 2 && !tutorialSeen,
    overflowhide: dataService.showDeliveryLoading
  }"
></div>-->

<div class="deliveryHolder" *ngIf="!dataService.showDeliveryLoading">

  <!--Decide your deliverables start-->
  <app-general-phase-platform (callCatAPiOnPlatformChange)="platformChaged()"></app-general-phase-platform>
  <!--Decide your deliverables close-->

  <!---phase section start-->
  <div class="phasesSection">
    <h2>
      Select phases for your product
      <div class="advanceTab" [ngClass]="{ 'custom-disabled': isPlatformRequest() }">
        <span>Advanced</span>
        <strong [ngClass]="phaseService.isAdvancedVersion ? 'active' : ''" (click)="advancedTab()"></strong>
      </div>
    </h2>

    <div class="phasesRowSlider" (window:resize)="onResize($event)" *ngIf="innerWidth >= 1281">
      <div class="phaeseRowContent">
        <drag-scroll class="phasesRow" (reachesLeftBound)="leftBoundStat($event)"
                     (reachesRightBound)="rightBoundStat($event)" #nav>
          <ng-container
            *ngFor="
            let phaseData of dataService.homePageData.build_phases;
            let i = index
          "
          >
            <advanced-phase-card drag-scroll-item
                                  *ngIf="phaseService.selectedFeatures?.length"
                                 class="phasesBox {{ i > 5 ? 'MVP-theme' : phaseData.title.split(' ')[0] + '-theme' }}"
                                 [phase]="phaseData"
                                 [index]="i"
                                 [advancedVersion]="phaseService.isAdvancedVersion"
                                 [ngClass]="{
              active: dataService.homePageData.build_phases[i]?.selected,
              dactive: !dataService.homePageData.build_phases[i]?.selected
            }"
            >
            </advanced-phase-card>
            <!--<div
                *ngIf="phaseData.active && phaseData.selected && isMobileActive"
                (click)="phaseData.active = false"
                class="custom-backdrop"
                [ngClass]="{
                  'active-backrop': phaseData.active && phaseData.selected
                }"
              ></div>-->
          </ng-container>
        </drag-scroll>
      </div>
      <button class="prevButton slideButton" [disabled]="dataService.homePageData?.build_phases.length <= 5" type="button" (click)="moveLeft()"><em
        class="icon-prev"></em></button>
      <button class="nextButton slideButton" [disabled]="dataService.homePageData?.build_phases.length <= 5" type="button" (click)="moveRight()"><em
        class="icon-next"></em></button>
      <!--<div class="prevButton slideButton" [disabled]="leftNavDisabled" (click)="moveLeft()"><em class="icon-prev"></em></div>
      <div class="nextButton slideButton" [disabled]="rightNavDisabled" (click)="moveRight()"><em class="icon-next"></em></div>-->
    </div>

    <div class="phasesRowSlider" (window:resize)="onResize($event)" *ngIf="innerWidth <= 1280">
      <div class="phaeseRowContent">
        <div class="phasesRow">
          <ng-container
            *ngFor="
            let phaseData of dataService.homePageData.build_phases;
            let i = index
          "
          >
            <advanced-phase-card drag-scroll-item
                                 *ngIf="phaseService.selectedFeatures?.length"
                                 class="phasesBox {{ i > 5 ? 'MVP-theme' : phaseData.title.split(' ')[0] + '-theme' }}"
                                 [phase]="phaseData"
                                 [index]="i"
                                 [advancedVersion]="phaseService.isAdvancedVersion"
                                 [ngClass]="{
              active: dataService.homePageData.build_phases[i]?.selected,
              dactive: !dataService.homePageData.build_phases[i]?.selected
            }"
            >
            </advanced-phase-card>
          </ng-container>
        </div>
      </div>
    </div>


    <div class="addButton" (click)="addCustomPhase()"
         [ngClass]="{ 'custom-disabled': isPlatformRequest() }">
      <em class="icon-plus"></em> <span>Add custom phase</span>
    </div>
  </div>
  <!---phase section close-->

  <!---Plan your delivery start-->
  <div class="planDelivery">
    <h3>When do you want the delivery?</h3>
    <div class="rowBoxes" [ngClass]="{ 'custom-disabled': isPlatformRequest() }">
      <team-card [ngClass]="{'hide': !phaseService.isAdvancedVersion}" *ngIf="dataService.teams && (dataService.teams.all?.length > 0)"></team-card>
      <app-general-phase-speed *ngIf="!phaseService.isAdvancedVersion && phaseService.selectedSpeed"
                               [selectedSpeed]="phaseService.selectedSpeed">
      </app-general-phase-speed>

      <div class="selectedSpeedBox" *ngIf='!phaseService.isAdvancedVersion'>
        <h4>{{phaseService.selectedSpeed?.title}}</h4>
        <p>{{getSelectedSpeedDescription()}}</p>
      </div>
    </div>

    <div class="planDates" *ngIf="!isPlatformRequest()">
      <h4>If you kick-off on <span>{{ getSelectedDate() | normalize }}</span></h4>
      <p *ngIf="phaseService.finalPricenWeeks?.first_delivery_time && !phaseService.timePromotionApplied()">Estimated First delivery: <strong>{{ getFirstDeliveryDate() | normalize}}</strong>
        <span class="infoIcon" *ngIf="getMVPFeaturesLength() > 0">
          <em class="icon-infonew" (click)="deliveryInfoToggle()"></em>
          <span class="infoOverlay" *ngIf="deliveryInfo" (click)="deliveryInfoToggle()"></span>
          <span class="infoContent" [ngClass]="{'active': deliveryInfo}">
            <span class="icon-cancel" (click)="deliveryInfoToggle()"></span>
            You will get <u>{{ getMVPFeaturesLength() }} {{ getMVPFeaturesLength() === 1 ? 'feature':'features' }}</u> in first delivery that we consider to be the minimum needed for you to test your system.
          </span>
        </span>
      </p>
      <p>Estimated Final delivery: <strong>{{ getFinalDeliveryDate() | normalize }}</strong></p>
    </div>

  </div>
  <!---Plan your delivery close-->

  <!---Select additional services start-->
  <div class="additionalServices" *ngIf="getBuilderCareShowHide()"
       [ngClass]="{ 'custom-disabled': isPlatformRequest() }">
    <h2>Other <a href="https://www.builder.ai/" target="_blank" rel="noopener">Builder.ai</a> services that nurture your idea</h2>
    <div class="rowBoxes">
      <div class="serviceBox" *ngIf="dataService.buildCardData.new_care_flow" [ngClass]="{'selected': phaseService.isSupportAndMaintenance()}">

        <!-- Builder care and Builder care Plus-->
        <div *ngIf="!dataService.checkIfEnterpriseUserOrCard() && !phaseService.checkEnterpriseForPaidCards()">
          <div class="optionServiceBox builderCare" *ngIf="!dataService.buildCardData.subscription_flow">
            <div class="sectionIcon">
              <img src="../../../../assets/images/Builder_Care_3.svg" height="29" alt=""/>
              <div class="checkIcon">
                <input type="checkbox" id="builderCare" (change)="phaseService.toggleSupportAndMaintainence(false, 'standard_care')"
                       [checked]="setCareChecked('standard_care')"
                       [disabled] ="phaseService.selectedHash.care_type === 'standard_care'">
                <label for="builderCare"><em></em></label>
              </div>
            </div>
            <h3>How Builder Care protects your software</h3>
            <div class="listContent">
              <div class="contentWrap" [ngClass]="{'viewLess': !viewMoreSCare}">
                <app-builder-care-content [careType]="'standardCare'" [isFromDeliveryPage]="true" [viewMoreSCare]="viewMoreSCare"></app-builder-care-content>
              </div>
              <button class="viewButton" type="button" *ngIf="!viewMoreSCare" (click)="viewMoreOrLess('standard_care')">View more...</button>
               <button class="viewButton" type="button" *ngIf="viewMoreSCare" (click)="viewMoreOrLess('standard_care')">View less...</button>
            </div>

            <div class="footerRow">
              <h5><strong>
                {{ getCurrencySymbol() }}{{
                dataService.getLocaledPrice(
                (phaseService.getBuilderCareForMonthlyOrDefaultPricing('standard_care', true)
                | number: "1.2-2"), dataService.buildCardData.currency
                )
                }}
              </strong>/month</h5>
            </div>
          </div>

          <div class="optionServiceBox studioSubscription" *ngIf="dataService.buildCardData.subscription_flow">
            <div class="subscritionHeading"><img src="../../../../assets/images/studio_one.png" /> <span>included</span></div>
            <div class="listContent">
              <div class="contentWrap" [ngClass]="{'viewLess': !viewMoreStndSubscription}">
                <app-builder-care-content [careType]="'standardCare'"
                                          [isFromDeliveryPage]="true"
                                          [viewMoreStndSubscription]="viewMoreStndSubscription">
                </app-builder-care-content>
              </div>
              <button class="viewButton" type="button" *ngIf="!viewMoreStndSubscription" (click)="viewMoreOrLess('standardCare')">View more...</button>
               <button class="viewButton" type="button" *ngIf="viewMoreStndSubscription" (click)="viewMoreOrLess('standardCare')">View less...</button>
            </div>
            <div class="footerRow">
              <h5>{{ getCurrencySymbol() }}0 for {{ phaseService.subscriptionDuration }} months (worth
                <strong>
                  {{ getCurrencySymbol() }}
                  {{ dataService.getLocaledPrice(((phaseService.monthlySubscriptionPrice) | number: "1.2-2"),
                  dataService.buildCardData.currency) }}
                </strong>/{{ (dataService.buildCardData.subscription_flow ? 'month' : 'year') }})
              </h5>
            </div>
          </div>

          <div class="boxSeparator">&nbsp;</div>

          <!-- Builder care plus -->
          <div class="optionServiceBox builderCarePlus">
            <div class="sectionIcon">
              <img *ngIf="!dataService.buildCardData.subscription_flow" src="../../../../assets/images/Builder_Care_Plus.svg" height="29" alt=""/>
              <img *ngIf="dataService.buildCardData.subscription_flow" src="../../../../assets/images/studio_one_plus.png" height="29" alt=""/>
              <div class="checkIcon">
                <input type="checkbox" id="builderCarePlus" (change)="phaseService.toggleSupportAndMaintainence(false, 'standard_care_plus')"
                       [checked]="setCareChecked('standard_care_plus')"
                       [disabled] ="!dataService.buildCardData.subscription_flow && (phaseService.selectedHash.care_type === 'standard_care_plus')">
                <label for="builderCarePlus"><em></em></label>
              </div>
            </div>
            <h3 *ngIf="!dataService.buildCardData.subscription_flow">
              How Builder Care Plus protects your software
            </h3>
            <h3 *ngIf="dataService.buildCardData.subscription_flow">
              Upgrade, for more support and to enhance your app features:
            </h3>
            <div class="listContent">
              <div class="contentWrap" [ngClass]="{'viewLess': !viewMoreSCarePlus}">
                <app-builder-care-content [careType]="'standardCarePlus'" [isFromDeliveryPage]="true" [viewMoreSCare]="viewMoreSCarePlus"></app-builder-care-content>
              </div>
              <button class="viewButton" type="button" *ngIf="!viewMoreSCarePlus" (click)="viewMoreOrLess('standard_care_plus')">View more...</button>
               <button class="viewButton" type="button" *ngIf="viewMoreSCarePlus" (click)="viewMoreOrLess('standard_care_plus')">View less...</button>
            </div>
            <div class="footerRow">
              <h5>{{ dataService.buildCardData.subscription_flow ? 'Upgrade for' : '' }}
                <strong>
                  {{ getCurrencySymbol() }}{{
                  dataService.getLocaledPrice(
                  (phaseService.getBuilderCareForMonthlyOrDefaultPricing('standard_care_plus', true)
                  | number: "1.2-2"), dataService.buildCardData.currency
                  )
                  }}
                </strong>/month
              </h5>
            </div>

            <ng-container *ngIf="!dataService.buildCardData.subscription_flow">
              <div class="noteText">
                <ul>
                  <li>*If part of your original software.</li>
                  <li>**Excluding any third-party, SDK, security, feature, custom integration or browser addition - that wasn't part of your Buildcard.</li>
                  <li>***Features covered under your Buildcard.</li>
                  <li><strong class="carot">&#94;</strong> If hosted with Builder Cloud</li>
                </ul>
              </div>
              <h4><a href="https://www.builder.ai/builder-care" target="_blank">Learn more</a></h4>
            </ng-container>
          </div>

        </div>

        <!-- Enterprise Care -->
        <div *ngIf="dataService.checkIfEnterpriseUserOrCard() || phaseService.checkEnterpriseForPaidCards()">
          <div class="optionServiceBox enterpriseCare" *ngIf="!dataService.buildCardData.subscription_flow">
            <div class="sectionIcon">
              <img src="../../../../assets/images/Builder_Enterprise_Care_3.svg" height="29" alt=""/>
              <div class="checkIcon">
                <input type="checkbox" id="enterpriseCare" (change)="phaseService.toggleSupportAndMaintainence(false, 'enterprise_care')"
                       [checked]="setCareChecked('enterprise_care')"
                       [disabled] ="phaseService.selectedHash.care_type === 'enterprise_care'">
                <label for="enterpriseCare"><em></em></label>
              </div>
            </div>
            <h3>How Enterprise Care protects your software</h3>
            <div class="listContent">
              <div class="contentWrap" [ngClass]="{'viewLess': !viewMoreECare}">
                <app-builder-care-content [careType]="'enterpriseCare'" [isFromDeliveryPage]="true" [viewMoreSCare]="viewMoreECare"></app-builder-care-content>
              </div>
              <button class="viewButton" type="button" *ngIf="!viewMoreECare" (click)="viewMoreOrLess('enterprise_care')">View more...</button>
               <button class="viewButton" type="button" *ngIf="viewMoreECare" (click)="viewMoreOrLess('enterprise_care')">View less...</button>
            </div>
            <div class="footerRow">
              <h5><strong>
                {{ getCurrencySymbol() }}{{
                dataService.getLocaledPrice(
                (phaseService.getBuilderCareForMonthlyOrDefaultPricing('enterprise_care', true)
                | number: "1.2-2"), dataService.buildCardData.currency
                )
                }}
              </strong>/month</h5>
            </div>
          </div>

          <div class="optionServiceBox studioSubscription" *ngIf="dataService.buildCardData.subscription_flow">
            <div class="subscritionHeading"><img src="../../../../assets/images/enterprise_one.png" /> <span>included</span></div>
            <div class="listContent">
              <div class="contentWrap" [ngClass]="{'viewLess': !viewMoreEntSubscription}">
                <app-builder-care-content [careType]="'enterpriseCare'"
                                          [isFromDeliveryPage]="true"
                                          [viewMoreEntSubscription]="viewMoreEntSubscription">
                </app-builder-care-content>
              </div>
              <button class="viewButton" type="button" *ngIf="!viewMoreEntSubscription" (click)="viewMoreOrLess('enterpriseCare')">View more...</button>
               <button class="viewButton" type="button" *ngIf="viewMoreEntSubscription" (click)="viewMoreOrLess('enterpriseCare')">View less...</button>
            </div>
            <div class="footerRow">
              <h5>{{ getCurrencySymbol() }}0 for {{ phaseService.subscriptionDuration }} months (worth
                <strong>
                  {{ getCurrencySymbol() }}
                  {{ dataService.getLocaledPrice(((phaseService.monthlySubscriptionPrice) | number: "1.2-2"),
                  dataService.buildCardData.currency) }}
                </strong>/{{ (dataService.buildCardData.subscription_flow ? 'month' : 'year') }})
              </h5>
            </div>
          </div>

          <div class="boxSeparator">&nbsp;</div>
          <!-- Enterprise care plus-->
          <div class="optionServiceBox enterpriseCarePlus">
            <div class="sectionIcon">
              <img *ngIf="dataService.buildCardData.subscription_flow" src="../../../../assets/images/enterprise_one_plus.png" height="29" alt=""/>
              <img *ngIf="!dataService.buildCardData.subscription_flow" src="../../../../assets/images/Builder_Enterprise_Care_Plus.svg" height="29" alt=""/>
              <div class="checkIcon">
                <input type="checkbox" id="enterpriseCarePlus" (change)="phaseService.toggleSupportAndMaintainence(false, 'enterprise_care_plus')"
                       [checked]="setCareChecked('enterprise_care_plus')"
                       [disabled] ="!dataService.buildCardData.subscription_flow && (phaseService.selectedHash.care_type === 'enterprise_care_plus')">
                <label for="enterpriseCarePlus"><em></em></label>
              </div>
            </div>
            <h3 *ngIf="!dataService.buildCardData.subscription_flow">
              How Enterprise Care Plus protects your software
            </h3>
            <h3 *ngIf="dataService.buildCardData.subscription_flow">
              Upgrade, for more support and to enhance your app features:
            </h3>
            <div class="listContent">
              <div class="contentWrap" [ngClass]="{'viewLess': !viewMoreECarePlus}">
                <app-builder-care-content [careType]="'enterpriseCarePlus'" [isFromDeliveryPage]="true" [viewMoreSCare]="viewMoreECarePlus"></app-builder-care-content>
              </div>
<!--              <button class="viewButton" type="button" *ngIf="!viewMoreECarePlus" (click)="viewMoreOrLess('enterprise_care_plus')">View more...</button>-->
<!--               <button class="viewButton" type="button" *ngIf="viewMoreECarePlus" (click)="viewMoreOrLess('enterprise_care_plus')">View less...</button>-->
            </div>
            <div class="footerRow">
              <h5>{{ dataService.buildCardData.subscription_flow ? 'Upgrade for' : '' }}
                <strong>
                  {{ getCurrencySymbol() }}{{
                  dataService.getLocaledPrice(
                  (phaseService.getBuilderCareForMonthlyOrDefaultPricing('enterprise_care_plus', true)
                  | number: "1.2-2"), dataService.buildCardData.currency
                  )
                  }}
                </strong>/month
              </h5>
            </div>

            <ng-container *ngIf="!dataService.buildCardData.subscription_flow">
              <div class="noteText">
                <ul>
                  <li>*If part of your original software.</li>
                  <li>**Excluding any third-party, SDK, security, feature or browser addition - that wasn't part of your Build Card.</li>
                  <li>^ If hosted with Builder Cloud</li>
                </ul>
              </div>
              <h4><a href="https://www.builder.ai/builder-care" target="_blank">Learn more</a></h4>
            </ng-container>

          </div>
        </div>
        <!-- Enterprise Care -->

      </div>

      <div class="serviceBox" *ngIf="!dataService.buildCardData.new_care_flow" [ngClass]="{'selected': phaseService.isSupportAndMaintenanceForOldCards()}">
        <div class="sectionIcon">
          <img src="../../../../assets/images/buildercare_logo.png" width="162" height="29" alt=""/>
          <div class="checkIcon">
            <input type="checkbox" id="builderCare" (change)="phaseService.toggleSupportAndMaintainenceForOldCards(false)" [checked]="phaseService.isSupportAndMaintenanceForOldCards()">
            <label for="builderCare"><em></em></label>
          </div>
        </div>
        <h3>How Builder Care protects your software</h3>
        <ul>
          <li><strong>Confidence:</strong> round-the-clock technical support for third party updates (API/SDK).</li>
          <li><strong>Relevance:</strong> App upgrades for all the latest Android/ iOS versions.</li>
          <li><strong>Competitive edge:</strong> security patches (both app and infrastructure level).</li>
        </ul>
        <h4><a href="https://www.builder.ai/builder-care" target="_blank" rel="noopener">Learn more</a></h4>

        <div class="footerRow">
          <h5><strong>
            {{ getCurrencySymbol() }}{{
            dataService.getLocaledPrice((phaseService.getBuilderCareForOldCards()
            | number: "1.2-2"), dataService.buildCardData.currency)
            }}
          </strong>/month</h5>
        </div>
      </div>


      <div class="serviceBox" [ngClass]="{'selected': phaseService.isCloudOpted('delivery')}">
        <div class="sectionIcon">
          <img src="../../../../assets/images/buildercloud_logo.png" alt=""/>
          <div class="checkIcon"><input type="checkbox" id="builderCloud" [checked]="phaseService.isCloudOpted('delivery')" (change)="toggleBuilderCloud($event)"> <label for="builderCloud"><em></em></label></div>
        </div>

        <h3>Builder Cloud helps you scale your business</h3>
        <ul>
          <li><strong>Commitment-free savings:</strong> our customers saved over $4.5m, last year.</li>
          <li><strong>World-class analytics:</strong> Optimise your software and infrastructure.</li>
          <li><strong>Best-in-class multi-cloud:</strong> Azure, AWS, and more. Just one bill (for a lot less).</li>
        </ul>
        <h4><a href="https://www.builder.ai/builder-cloud" target="_blank" rel="noopener">Learn more</a></h4>

        <!--<div class="systemConfig">System configuration:<br>
          <strong>{{ systemConfig?.cpu }} core CPU</strong>  <strong>{{  systemConfig?.storage}} GB disk space</strong><strong>{{ systemConfig?.memory }} GB RAM</strong>  <strong>{{ systemConfig?.bandwidth }} GB bandwidth</strong>
        </div>-->

        <div class="footerRow">
          <div class="numberUser">
            <h3>Number of users <strong>{{  selectedRange }}</strong></h3>
            <div class="userSlider" *ngIf="barValue !== 'undefined'">
              <ngx-slider [(value)]="barValue" [options]="options" (valueChange)="getCloudPrice($event)"></ngx-slider>
            </div>
            <!--<div class="minValue">0</div>
            <div class="maxValue">1M</div>-->
          </div>

          <h5>
            <strong>
              {{ getCurrencySymbol() }}{{ getBuilderCloudPrice() }}
              <sup *ngIf="barValue == '1000000'">*</sup>
            </strong>
            /month</h5>
          <h6>*This is an estimated price for cloud hosting and will vary according to usage.</h6>
        </div>
      </div>

    </div>
  </div>
  <!---Select additional services close-->

</div>

<!--
<div
  *ngIf="!tutorialSeen"
  class="deliveryTutorial"
  [ngClass]="{ disabled: tooltipIndex === null }">
  <div class="deliveryOverlay" (click)="closeTooltip($event)"></div>
  <div class="commonBox step1" [ngClass]="{ active: tooltipIndex === 0 }">
    <div class="marker"></div>
    <h3>Platform & Speed</h3>
    <p>
      Select the platforms and speed for<br/>
      your end product.
    </p>
    <div class="buttonsBox">
      <button type="button" class="doneButton" (click)="showTutorialTooltip()">
        NEXT
      </button>
      <button type="button" class="skipButton" (click)="closeTooltip($event)">
        SKIP
      </button>
    </div>
  </div>

  <div class="commonBox step2" [ngClass]="{ active: tooltipIndex === 1 }">
    <h3>MVP</h3>
    <p>
      Need a quick MVP with only few features?<br/>
      We would do it for no extra cost.
    </p>
    <div class="buttonsBox">
      <button type="button" class="doneButton" (click)="showTutorialTooltip()">
        NEXT
      </button>
      <button type="button" class="skipButton" (click)="closeTooltip($event)">
        SKIP
      </button>
    </div>
  </div>

  <div class="commonBox step3" [ngClass]="{ active: tooltipIndex === 2 }">
    <h3>Advance Mode</h3>
    <p>
      Want to optimise your delivery cost & time?<br/>
      Switch to advanced mode from here.
    </p>
    <div class="buttonsBox">
      <button type="button" class="doneButton" (click)="closeTooltip($event)">
        GOT IT
      </button>
    </div>
  </div>
</div>
-->

<div class="deliveryBottomBar active" [ngClass]="{ hideBottomPipe: showBottomPipe }"></div>
<!-- Buildcard™ screen start -->
<div *ngIf="dataService.showDeliveryLoading">
  <div class="main-buildcard-bx2">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-4 col-sm-12 pull-right">
          <div class="payment-summery-main">
            <h3></h3>
            <div>
              <ul>
                <li></li>
                <li></li>

              </ul>
            </div>
            <div class="coupon"> <span></span></div>
            <div class="additional-ser">
              <h3></h3>
              <span></span>
            </div>
            <div class="btn-effect"></div>
          </div>
          <div class="dummy-btn"></div>
        </div>
        <div class="col-xs-12 col-md-7 col-sm-12">
          <div class="buildCardmain2">
            <div class="buildCardmain2">
              <div class="buildCardTop2">
                <div class="buildcardtop-dummy">
                  <span></span>
                </div>
              </div>
              <div class="buildcardtab-dummy">
                <ul>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>
            <div class="buildcardtab-dummy">
              <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="buildcardtabdetail_1-dummy">
              <h3 class="pro-head-dummy"></h3>
              <div class="pro-listDetail-dummy">
                <ul>
                  <li>
                    <strong class="circle"></strong>
                    <span></span>
                  </li>
                  <li>
                    <strong class="circle"></strong>
                    <span></span>
                  </li>
                  <li>
                    <strong class="circle"></strong>
                    <span></span>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="openCustomPhasePopup && !dataService.showDeliveryLoading" [ngClass]="{ 'active-cpp': openCustomPhasePopup }">
  <div class="cpp-backdrop" *ngIf="openCustomPhasePopup" (click)="closeCPP()" ></div>
  <div class="cpp-content selectionStep" *ngIf="!showConfirmStep">
    <em class="icon-cancel cpp-close" (click)="closeCPP()"></em>
    <div class="cpp-top">
      <input
        type="text"
        value="Custom Phase 1"
        class="customPhase-name"
        readonly
      />
      <em class="icon-edit forEdit"></em>
    </div>
    <div class="contentBlock">
      <div class="contentSection">
        <div class="customDrop-wrapper">
          <div class="typeName">Type of phase</div>
          <div class="customDropSelect">
            <select class="customSelection">
              <option>Product Roadmap</option>
              <option>Design</option>
              <option>Prototype</option>
              <option>MVP</option>
              <option>Full Build</option>
            </select>
          </div>
        </div>
      </div>
      <div class="contentSection">
        <div class="contentSection-left">
          <div class="typeName">Features</div>
          <div class="Counter">0 Features Selected</div>
        </div>
        <div class="contentSection-right">
          <div class="changeButton">change</div>
        </div>
      </div>
      <div class="contentSection">
        <div class="contentSection-left">
          <div class="typeName">Platform</div>
          <div class="Counter">0 Features Selected</div>
        </div>
        <div class="contentSection-right">
          <div class="changeButton">change</div>
        </div>
      </div>
      <div class="contentSection speedSection">
        <div class="contentSection-left">
          <div class="typeName">Speed</div>
          <div class="speedSlider">
            <input type="radio" name="Build" id="speed10Full Build"/>
            <label for="speed10Full Build"><span>Relaxed</span></label>
            <input type="radio" name="Build" id="speed11Full Build" checked/>
            <label for="speed11Full Build"><span>Slow</span></label>
            <input type="radio" name="Build" id="speed12Full Build"/>
            <label for="speed12Full Build"><span>Standard</span></label>
            <input type="radio" name="Build" id="speed13Full Build"/>
            <label for="speed13Full Build"><span>Fast</span></label>
            <input type="radio" name="Build" id="speed14Full Build"/>
            <label for="speed14Full Build"><span>Speedy</span></label>
          </div>
        </div>
      </div>
    </div>
    <div class="cpp-bottom">
      <button type="button" class="cpp-cancel">Cancel</button>
      <button type="button" class="cpp-save" (click)="gotoConfirm()">
        Save
      </button>
    </div>
  </div>
  <div class="cpp-content confirmStep" *ngIf="showConfirmStep">
    <div class="confirmText">
      Do you want to continue with the new <strong>'Custom Phase 1'</strong>
    </div>
    <div class="cpp-bottom">
      <button type="button" class="cpp-cancel" (click)="discardCustomPhase()">
        Discard
      </button>
      <button type="button" class="cpp-save">Save and Continue</button>
    </div>
  </div>
</div>

<phases-mode
  *ngIf="showDiscardAdvancedPopup"
  (discardCallBack)="handleDiscard($event)"
></phases-mode>


<div class="commonPopUp" [ngClass]="{'active': showCloudPopup}">
  <div class="popOverlay" (click)="closeCloudPopup()"></div>
  <div class="popHolder whycloudPopup">
    <div class="closePopUp" (click)="closeCloudPopup()"><span class="icon-cancel"></span></div>
    <img src="../../../../assets/images/buildercloud_logo.png" width="171" height="29" alt="" />
    <h3>Get cloud services at 20% discount with Builder Cloud.</h3>
    <p>Cloud services are important to run your product after it is live.</p>

    <div class="footerButton">
      <button type="button" class="cancleButton" (click)="closeCloudPopup()">I'll do it later</button>
      <button type="button" class="doneButton" (click)="toggleBuilderCloud('selectCloud')">Select Builder Cloud</button>
    </div>

  </div>
</div>
