import {CanActivate, Router} from '@angular/router';
import {AdminAuthService} from './auth.service';
import {Injectable} from '@angular/core';

@Injectable()
export class AdminAuthGuardService implements CanActivate {
  constructor(private _authService: AdminAuthService,
              private _router: Router) {
  }

  canActivate(): boolean {
    if (!this._authService.isLoggedIn()) {
      this._router.navigate(['admin-dashboard/login']);
      return false;
    }
    return true;
  }
}
