/**
 * Created by nitin on 14/12/17.
 */
 export class Constants {
  static apiEndPoints = {
    homepage: 'configurations',
    newhomepage: 'builder_sections',
    user: 'user',
    get_template: 'api/v1/templates',
    get_recommended_templates: 'api/v1/templates/recommendation',
    sort_template: 'api/v1/templates/sort',
    template_filters: 'api/v1/filters',
    get_filtered_result: 'api/v1/templates/filter',
    get_search_templates: 'api/v1/templates/search',
    payment: 'transactions',
    categories: 'products/product_id/categories',
    regions: 'products/product_id/regions',
    audiences: 'products/product_id/audiences',
    project_status: '/products/product_id/project_statuses',
    templates: 'templates',
    add_new_templates: 'templates',
    app_styles: 'app_styles',
    teams: 'teams',
    user_signin: 'users/sign_in',
    user_signup: 'users',
    master_contract: 'contract',
    user_signout: 'users/sign_out',
    user_forgot: 'users/forgot_password',
    user_reset: 'users/reset_password',
    user_request: 'user/requests',
    add_new_feature: 'features',
    add_new_category: 'categories',
    features: 'features/bundle_details',
    build_card: 'build_cards',
    freemium_build_card: 'freemium/build_cards',
    build_card_modification: 'modification_requests',
    docusign_url: 'users/docusign_url',
    mastercontractdocusign_url: 'contract/signing_url',
    productsList: 'product_list',
    promoCode: 'promotion/check?code=',
    emailValidator: 'validators/email?email=[email]',
    installments: 'installments?build_card_id=[build_card_id]',
    installment_prototype: 'installments',
    updateinstallment: 'installments',
    paymentCallBack: 'payments/call_back',
    feature_filter: 'features/filter_data',
    feature_search: 'features/new_feature_bundle_search',
    updateTemplate: 'templates/id',
    videoShareDetails: 'web_summit/build_cards/[id]/get_build_card',
    activityFeeds: 'trackers/activity_feed',
    sharable_urls: 'build_card_urls',
    wait_list: 'waitlist_users',
    waitlist_total_count: 'waitlist_users/total_count',
    regenerate_otp: 'user/regenrate_otp',
    verify_user_otp: 'user/confirm_user_otp',
    edit_buildcard_email: 'build_card/send_modify_build_card',
    build_card_pdf_status: 'build_card/build_card_pdf_status/',
    get_timeline: 'build_card/get_timelines',
    inactive_timeline: 'build_card/inactive_timelines',
    inactive_timeline_dashboard: 'build_card/seen_timelines',
    ownership_tranfer: 'build_card/ownership_tranfer',
    get_promotion: 'promotion/fetch_promotion_details',
    fetchpromotion: 'promotion/fetch_promotion',
    apply_promotion: 'build_card/apply_promotion',
    payment_dashboard_signin: 'payments/authentications',
    payment_dashboard_buildcards: 'payments/build_cards',
    remove_invited_user: 'build_card/remove_invited_user',
    partner_signup: 'builder_partners',
    partner_resend_verification: 'builder_partners/resend_verification_mail',
    verify_partner: 'builder_partners/verify_token',
    partner_signin: 'builder_partners/login',
    partner_profile: 'builder_partners',
    fetch_partner_contract: 'partner_contract/signing_url',
    partner_logout: 'builder_partners/sign_out',
    partner_docsign: 'builder_partners/docusign_url',
    resend_unique_code: 'builder_partners/resend_uniq_code',
    check_unique_code: 'builder_partners/verify_uniq_code',
    configure_buidler: 'builder_partners/configure_builder',
    partner_data: 'builder_partners/details',
    send_for_partner_approval: 'build_card/send_request_to_partner',
    spec_apply_promotion: 'spec_promotion/apply?code={promocode}',
    create_spec_call: 'spec_calls',
    application_search_suggestion: 'templates/autocomplete_search',
    autocomplete_templates: 'templates/autocomplete_search',
    check_email: 'users/check_user_presence',
    old_build_card_price: 'build_card/builder_price_and_weeks',
    new_build_card_price: 'build_card_pricing',
    selected_features: 'features/selected_features',
    buildcard_price: 'builder_pricing',
    experiment_promotion: 'promotion/experiment_promotions',
    percentage_promotion_list: 'promotion/percentage_promotions',
    template_detail: 'apps',
    additional_feature_request: 'change_requests',
    additional_platform_request: 'platform_addition_requests',
    additional_feature_installments: 'installments',
    reset_documents: 'build_card/reset_documents',
    prototype_installment: '/prototype_installment',
    check_for_build_card_update: 'build_card/check_for_build_card_update',
    proposal_installments: '/proposal_installments',
    payment_cards: 'payment_billing/cards',
    default_mark_card: 'payment_billing/cards/default_mark_card',
    remove_saved_card: 'payment_billing/cards/remove_saved_card',
    save_card: 'payment_billing/cards/save_card',
    spec_call_promotion: 'spec_calls/apply_or_remove_promotion',
    feedBack_Submission: 'feedback',
    rate_experience_feedback: 'build_card/builder_experience_and_feedback',
    get_payment_url: 'checkouts/payment_url',
    poll_payment_status: 'build_cards/{id}/poll_status'
  };
  static buildCardStatus = {
    CARD_INITIALISED: 'card_initialized',
    CARD_COMPLETED: 'card_completed',
    STARTED: 'started',
    UNDER_DEVELOPMENT: 'under_development',
    UNDER_SUPPORT_AND_MAINTENANCE: 'under_support_and_maintenance',
    COMPLETED: 'completed',
    ON_HOLD: 'on_hold'
  };
  static PARAM_CONSTANTS = {
    BUILD_CARD_ID: '?bc_id=',
    DECIMAL: '.',
    PROMOTION: '?promotion=',
    EXP_CODE: '?exp=',
    UNIQ_CODE: '?uniq_code=',
    KEYWORD: '?keyword=',
    PRODUCT_ID: '?product_id=',
    CURRENCY_ID: '&currency_id=',
    QUESTION_MARK: '?',
    BRACKETS: '[]',
    SLASH: '/',
    DATA: 'data?',
    AND: '&',
    BRACKET_FILE: '[][file]',
    BUILDCARD_BRACKET: 'build_card[',
    USER_BRACKET: 'user[',
    CLOSE_BRACKET: ']',
    OPEN_BRACKET: '[',
    SHARE: '/share',
    INVITE: '/invite',
    EMAIL_BRACKET: 'emails[]',
    AUTHTOKEN: '?authtoken=',
    REQUEST: 'requests'
  };

  static productListUrl = 'https://api-productlisting.engineer.ai/products?query=[query]';

  static PMDASHBOARD_URLS = {
    staging: 'http://34.227.230.48/api/v1/',
    prod: 'https://api-pmdashboard.engineer.ai/api/v1'
  };

  static partner_code = {
    e_ai: 'e-ai'
  };

  static PMDASHBOARD_END_POINTS = {
    todos: 'clients/projects/project_id/todos',
    todo_comments: 'clients/todos/todo_id/comments',
    files: 'clients/projects/project_id/folder_files',
    meetings: 'clients/projects/project_id/meetings',
    code_repos: 'clients/projects/project_id/code_repos',
    dashboard_home: 'clients/projects/project_id/dashboard',
    managers: 'clients/projects/project_id/managers',
    tracker_phases: 'clients/projects/project_id/tracker_phases',
    releases: 'clients/projects/project_id/builds',
    important_links: 'clients/projects/project_id/design_boards'

  };
  static API_URL = {
    production_url: 'https://api-production-builder.engineer.ai/',
    demo_url: 'https://api-demo-builder.engineer.ai/',
    staging_url: 'https://api-staging-builder.engineer.ai/',
    develop_url: 'http://api.develop.engineer.ai/'
  };

  static PAGE_TITLES = {
    default: {
      code: 'default',
      title: 'Builder.ai - What would you build?'
    },
    login: {
      code: 'login',
      title: 'Builder.ai - Login on Builder'
    },
    signup: {
      code: 'signup',
      title: 'Builder.ai - Signup on Builder'
    },
    forgot: {
      code: 'forgot',
      title: 'Builder.ai - Forgot Password'
    },
    home: {
      code: 'home',
      title: 'Builder.ai - Home'
    },
    welcome: {
      code: 'welcome',
      title: 'Builder.ai - Welcome'
    },
    apps: {
      code: 'apps',
      title: 'Builder.ai - Apps'
    },
    platforms: {
      code: 'platforms',
      title: 'Builder.ai - Platforms'
    },
    delivery: {
      code: 'delivery',
      title: 'Builder.ai - Delivery'
    },
    features: {
      code: 'features',
      title: 'Builder.ai - Features'
    },
    buildphases: {
      code: 'buildphases',
      title: 'Builder.ai - Phases'
    },
    dashboard: {
      code: 'dashboard',
      title: 'Builder.ai - Dashboard'
    },
    summary: {
      code: 'summary',
      title: 'Builder.ai - Project Summary'
    },
    invalidaccess: {
      code: 'invalidaccess',
      title: 'Builder.ai - Invalid Access'
    },
    project_dashboard: {
      code: 'project_dashboard',
      title: 'Builder.ai - Project Dashboard'
    },
    installments: {
      code: 'installments',
      title: 'Builder.ai - Installments'
    },
    signcontract: {
      code: 'signcontract',
      title: 'Builder.ai - Sign Contract'
    },
    billing: {
      code: 'billing',
      title: 'Builder.ai - Billing'
    },
    payment_confirmation: {
      code: 'payment_confirmation',
      title: 'Builder.ai - Payment Confirmation'
    },
    buildcard_payment_failed: {
      code: 'buildcard_payment_failed',
      title: 'Builder.ai - Buildcard™ Payment Failed'
    },
    buildcard_payment_success: {
      code: 'buildcard_payment_success',
      title: 'Builder.ai - Buildcard™ Payment Success'
    },
    schedule_call_popup_after_deposit_payment: {
      code: 'schedule_call_popup_after_deposit_payment',
      title: 'Builder.ai - After Deposit Schedule Call'
    },
    schedule_call_thanks_screen: {
      code: 'schedule_call_thanks_screen',
      title: 'Builder.ai - After Deposit Schedule Call Thanks'
    },
    schedule_call_for_specing: {
      code: 'schedule_call_for_specing',
      title: 'Builder.ai - Schedule Call for Specing'
    },
    specing_payment_success: {
      code: 'specing_payment_success',
      title: 'Builder.ai - Specing Payment Success'
    },
    specing_payment_failed: {
      code: 'specing_payment_failed',
      title: 'Builder.ai - Specing Payment Failed'
    },
    paymentPlan: {
      code: 'payment_plan',
      title: 'Builder.ai - Payment Plan'
    },
    billingDetails: {
      code: 'billing_details',
      title: 'Builder.ai - Billing Details'
    },
    payConfirm: {
      code: 'pay_confirm',
      title: 'Builder.ai - Pay confirm'
    },
    templateDetail: {
      code: 'templateDetail',
      title: 'Builder.ai - App Details'
    }
  };

  static promotionCodeType = {
    ExitIntentPopup: 'exit_intent',
    HomeSignUpPopup: 'mini_popup',
    PercentPromo: 'PercentageDiscountPromotion',
    Application: 'gamify'
  };

  static promotionError = 'Promocode conditions doesn\'t match the required conditions.';

  static META_TAGS_DESCRIPTION = {
    features: {
      ogTitle: 'Select the Features You Want in Your App - Builder Studio',
      description: 'Here are the features similar apps use. Click ones we’ve suggested and remove ones your app doesn’t need. Or add extras from our library of hundreds.',
      ogImage: 'https://assets-builder.s3-ap-southeast-1.amazonaws.com/BuilderStudioLogoPink.jpg'
    },
    apps: {
      ogTitle: 'Choose Apps Similar to Your Idea - Builder Studio',
      description: 'Select one or more apps that are closest to your idea. Add any extra features you need and remove any you don’t. Start building with Builder Studio.',
      ogImage: 'https://assets-builder.s3-ap-southeast-1.amazonaws.com/BuilderStudioLogoPink.jpg'
    },
    welcome: {
      ogTitle: 'Welcome to Builder Studio - Take a Tour',
      description: 'Find out how Builder Studio makes building software as easy as ordering a pizza. No tech knowledge needed. Start building your app today!',
      ogImage: 'https://assets-builder.s3-ap-southeast-1.amazonaws.com/BuilderStudioLogoPink.jpg'
    },
    delivery: {
      ogTitle: 'How Fast Do You Want Your App? - Builder Studio',
      description: 'Pick the time zone of your team and Buildcard™ speed. Choose all the phases you need: like a product roadmap? Design? Prototype? MVP? Or full build?',
      ogImage: 'https://assets-builder.s3-ap-southeast-1.amazonaws.com/BuilderStudioLogoPink.jpg'
    },
    problemsolve: {
      ogTitle: 'Solve Your Problem and Start Building - Builder',
      description: 'Start by choosing the problem your app is trying to solve. You’ve got the idea to solve it. We’ve got the tech. Let’s start building your app today!',
      ogImage: 'https://assets-builder.s3-ap-southeast-1.amazonaws.com/BuilderStudioLogoPink.jpg'
    },
    become_partner: {
      ogTitle: 'Become our Capacity Partner - Builder Studio',
      description: 'Deploy your idle manpower: they can earn you money, coding for us. Partner with us and get a lifetime revenue share and deeper customer relationships',
      ogImage: 'https://assets-builder.s3-ap-southeast-1.amazonaws.com/BuilderStudioLogoPink.jpg'
    }
  };

  static POLICIES_URLS = {
    TnC: 'https://www.builder.ai/terms/msa',
    PRIVACY_POLICY: 'https://www.builder.ai/terms/privacy',
    CUSTOMER_POLICY: 'https://www.builder.ai/terms/customer-policies'
  };
}
