import {CurrencyModel} from '../currency-model/currency.model';
import {ProgressModel} from './progress.model';
import {ReferenceModel} from '../reference-model/reference.model';
import {TeamModel} from '../teams-model/team.model';
import {SpeedModel} from '../speed-model/speed.model';
import {PromotionModel} from '../homepage-data-model/promotion.model';
import {InviteModel} from '@models/build-card-model/invite.model';
import {FeatureModel} from '@models/feature-model/feature.model';

/**
 * Created by nitin on 14/12/17.
 */
export class BuildCardModel {
  actual_weeks: number;
  build_card_pdf_url: string;
  bundle_count: number;
  change_amount: number;
  change_price_status: boolean;
  change_week: number;
  change_week_status: boolean;
  competitors: Array<ReferenceModel>;
  completion_percentage: number;
  confidence_percentage: number;
  currency: CurrencyModel;
  customized_template_name: string;
  description: string;
  development_state: string;
  discounted_price: number;
  frequency: string;
  invites: Array<InviteModel>;
  is_approved: boolean;
  is_under_support: boolean;
  feature_count: number;
  team: TeamModel;
  speed: SpeedModel;
  id: number;
  installment_amount: number;
  installment_counts: { monthly: 0, weekly: 0 };
  kick_off_date: string;
  nda_signed: boolean;
  owner: boolean;
  owner_email: string;
  owner_name: string;
  percentage_completed: number;
  phase_installment_paid: boolean;
  pre_kick_off_date: string;
  price: number;
  progress: ProgressModel;
  progress_count: number;
  project_name: string;
  promotion: PromotionModel;
  pm_status: string;
  quote: string;
  specing: boolean;
  specing_paid: boolean;
  status: string;
  support_maintenance_price: number;
  support_maintenance_weeks: number;
  target_user_group: string;
  uniq_code: string;
  unseen_section_count: number;
  updated_at: string;
  updated_section_count: number;
  weeks: number;
  build_card_phases: [];
  multiplier_experiment: any;
  change_requests: any[];
  change_requests_status: string;
  features: Array<FeatureModel>;
  payment_weeks: number;
  skip_signing_contract: boolean;
  is_custom_prototype: boolean;
  cloud_price: number;
  cloud_opted: boolean;
  cloud_user_count: number;
  type: string;
  source: string;
  is_branding_added: boolean;
  upfront: boolean;
  spec_call_id: number;
  rental_info: any;
  studio_store_nda_signed: boolean;
  modification_request: any;
  modification_status: string;
  deposit_weeks: number;
  customisation_price: number;
  reusable_features_price: number;
  scheduled_date: any;
  enterprise_lead: string;
  template_query_string: string;
  tailor_made: boolean;
  care_type: string;
  support_yearly_price: number;
  bcard_discounted_price: number;
  bcard_price: number;
  new_care_flow: boolean;
  donation_amount: number;
  donation_opted: boolean;
  donation_percentage: number;
  show_donation: boolean;
  reward_point: any;
  wallet_balance: any;
  minimum_cloud_price: number;
  maximum_cloud_price: number;
  total_amount: number;
  referral_code_used: boolean;
  user_price_multiplier: number;
  spec_call_scheduled: string;
  subscription_flow: boolean;
  subscription_price: number;
  awarded_reward_points: any;
  total_block: number;
  total_delay: number;
  upfront_paid_amount: number;
  paid_using_bnpl: boolean;
  pm_project_id: number;
  build_card_type: string;
  bypass_bnpl: boolean;
}
