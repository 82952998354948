import {Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {PhaseService} from '../../../shared-module/services/phase.service';
import {DataService} from '../../../shared-module/services/data.service';
import {Constants} from '../../../shared-module/utils/constants';
import {ApiRequests} from '../../../shared-module/services/api-requests.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'shareinvite',
  templateUrl: 'shareinvite.component.html',
})

export class ShareInviteComponent {
  public dataService;
  public phaseService;
  public router;
  public analyticsSegment;
  public preventClick = false;
  // public emailToEnter = null;
  public validEmailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  @ViewChild('email') public email;
  public errorMessage;

  constructor(phaseService: PhaseService, dataService: DataService, router: Router, analyticsSegment: Angulartics2Segment, public apiRequest: ApiRequests, ) {
    this.phaseService = phaseService;
    this.dataService = dataService;
    this.router = router;
    this.analyticsSegment = analyticsSegment;
  }

  public addEmailToList(emailForm?) {
    if (this.isOwnershipTransferPopup()) {
      this.phaseService.emailToEnterForOwnershipTransfer = this.phaseService.emailToEnterForOwnershipTransfer.toLowerCase();
      this.transferOwnership(this.phaseService.cardToShareInvite, this.phaseService.emailToEnterForOwnershipTransfer);
    } else {
      if (this.phaseService.emailToEnterForOwnershipTransfer) {
        this.phaseService.emailsToShareInvite.push(this.phaseService.emailToEnterForOwnershipTransfer);
        this.phaseService.emailToEnterForOwnershipTransfer = null;
      }
      if (emailForm) {
        emailForm.reset();
        this.phaseService.sharePopup ? this.shareInviteCard(true, emailForm) : this.shareInviteCard(false, emailForm);
      }
    }
  }

  public removeEmailFromList(index) {
    this.phaseService.emailsToShareInvite.splice(index, 1);
  }

  public hideShareInvitePopup() {
    this.phaseService.sharePopup = false;
    this.phaseService.invitePopup = false;
    this.phaseService.ownershipTransferPopup = false;
    this.phaseService.emailsToShareInvite = [];
    this.phaseService.emailToEnterForOwnershipTransfer = null;
    this.errorMessage = null;
    this.preventClick = false;
  }

  public shareInviteCard(isShare, form) {
    if (!this.preventClick) {
      this.preventClick = true;
      let invitedEmails = this.phaseService.emailsToShareInvite.map(v => v.toLowerCase());
      this.apiRequest.shareInviteCard(invitedEmails, this.phaseService.cardToShareInvite.id, isShare, window.location.origin).subscribe((data: any) => {
        if (isShare) {
          if (this.router.url.indexOf('build-card') > -1) {
            this.phaseService.trackEventForObject(this.analyticsSegment, "build_card_shared", {
              user_id : this.dataService.user?.id
            });
          }
        } else {
          if (this.router.url.indexOf('build-card') > -1) {
            this.phaseService.trackEventForObject(this.analyticsSegment, "build_card_others_invited", {
              user_id : this.dataService.user?.id
            });

          }
        }

        this.phaseService.invitePopup = false;
        this.phaseService.sharePopup = false;
        this.preventClick = false;
        this.phaseService.emailsToShareInvite = [];
        this.phaseService.emailToEnterForOwnershipTransfer = '';
        form.reset();
        this.dataService.showHideLoader(true);
        this.apiRequest.fetchBuildCards(null, null, this.phaseService.currentSelectedBuildCardStatus).finally(() => {
          this.dataService.showHideLoader(false);
        }).subscribe();
        this.dataService.filterData();
      }, (error) => {
        if (error.status === 403 || error.status === 401) {
          this.dataService.user = null;
          // remove cookie here
          this.dataService.unsetCookie();

          this.phaseService.clearLocalStorage();
          this.phaseService.showSignin = true;
          this.phaseService.openCommonAuth = true;
        }
        if (error.error) {
          this.errorMessage = error.error.message;
        }
        this.preventClick = false;
      });
    }
  }

  public transferOwnership(card, email) {
    if (this.router.url.indexOf('build-card') > -1) {
      this.phaseService.trackEventForObject(this.analyticsSegment, "build_card_transferred", {
        user_id : this.dataService.user?.id
      });
    }
    this.preventClick = true;
    this.apiRequest.transferOwnership(card.id, email)
      .subscribe(
        (data) => {
          this.phaseService.ownershipTransferPopup = false;
          if (this.router.url.indexOf('dashboard') > -1) {
            this.phaseService.showOwnershipConfirmationDashboard = true;
          } else {
            this.phaseService.showOwnershipConfirmationSummary = true;
          }
          this.preventClick = false;

          if (data.email_sent === true && data.user_availability === true) {
            this.phaseService.ownershipTranferMessage = true;
          } else if (data.email_sent === false && data.user_availability === false) {
            this.phaseService.ownershipTransferUserNotAvailableMessage = true;
          } else if (data.email_sent === false && data.user_availability === true) {
            this.phaseService.ownershipTransferCurrencyMismatchMessage = true;
          }
          // this.phaseService.ownershipTranferMessage = data.user_present;
          this.apiRequest.fetchBuildCards(null, null, this.phaseService.currentSelectedBuildCardStatus).subscribe();
        },
        (error) => {
          if (error.error) {
            this.errorMessage = error.error.message;
          }
          this.preventClick = false;
        }
      );
  }

  public isSharePopup() {
    return this.phaseService.sharePopup;
  }

  public isInvitePopup() {
    return this.phaseService.invitePopup;
  }

  public getCardShareInvite() {
    return this.phaseService.cardToShareInvite;
  }

  public getEmailsToShareInvite() {
    return this.phaseService.emailsToShareInvite;
  }

  public mailEntered() {
    (!this.phaseService.emailToEnterForOwnershipTransfer || (this.email && this.email.errors && (this.email.dirty || this.email.touched))) ? this.email.focus() : this.addEmailToList();
  }

  public checkIfEmailValid() {
    return this.phaseService.emailToEnterForOwnershipTransfer && (this.email && !this.phaseService.validateEmail(this.phaseService.emailToEnterForOwnershipTransfer));
  }

  public isOwnershipTransferPopup() {
    return this.phaseService.ownershipTransferPopup;
  }
}
