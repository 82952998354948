import { Injectable } from '@angular/core';
import { PhaseService } from './phase.service';
import { DataService } from '@shared/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiRequests } from '@shared/services/api-requests.service';
import { AppDataService } from '@rootmodule/app-data.service';
import { Angulartics2Segment } from 'angulartics2/segment';
import { Intercom } from 'ng-intercom';
import {environment} from '@env/environment';

@Injectable({
    providedIn: 'root'
})

export class PaymentService {

    disableDoneButton = false;

    constructor(private phaseService: PhaseService, private dataService: DataService,
        private apiRequest: ApiRequests, private appDataService: AppDataService,
        public router: Router, public analyticsSegment: Angulartics2Segment) {}

    additionalFeatureRequest(type?, actionType?) {
        if (actionType === 'approveAndContinue') {
            this.dataService.activeAdditionalFeatureRequestCard = true;
        }
        this.disableDoneButton = true;
        type = type ? type : 'pending';
        const uniq_code = this.dataService.buildCardData.uniq_code;
        let payload: any;
        if (this.phaseService.isPlatformRequest) {
          this.changeRequestPlatformHash();
          payload = this.phaseService.changeRequestPlatformHash;
        } else {
          this.changeFeatureRequestHash(type);
          payload = this.phaseService.changeRequestBody;
        }
        this.apiRequest.buildCardChangeFeatureRequest(payload, this.phaseService.isPlatformRequest).subscribe((resp) => {
          this.dataService.activeAdditionalFeatureRequestCard = false;
          this.dataService.showApproveAndContinuePopup = false;
          this.appDataService.urlParameters.featureRequest = '';
          this.appDataService.urlParameters.changeRequest_id  = null;
          this.dataService.buildCardData.change_requests_status = '';
          this.dataService.totalSelectedFeatures = 0;
          this.appDataService.urlParameters.platformRequest = '';
          if (this.dataService.cookie_domain && this.dataService.cookie_domain === 'localhost') {
            window.location.href = `http://localhost:5000/#/project-progress/${uniq_code}/changerequest`;
          } else {
            window.location.href = `${environment.BUILDER_LIVE_URL}/#/project-progress/${uniq_code}/changerequest`;
          }
        }, error => {
            console.log(error);
            this.disableDoneButton = false;
            this.dataService.activeAdditionalFeatureRequestCard = false;
        });
    }

    public changeFeatureRequestHash(type?) {
        const buildCardId = this.dataService.changeFeatureRequestModel.build_card
            ? this.dataService.changeFeatureRequestModel.build_card.id
            : this.dataService.buildCardData.id;

        this.phaseService.changeRequestBody.build_card_id = buildCardId;

        this.phaseService.changeRequestBody.change_request.status = type;
        this.phaseService.changeRequestBody.change_request.weeks = this.dataService.totalAdditionalFeaturesWeek;
        if (this.phaseService.isStoreFeatureRequest) {
          this.phaseService.changeRequestBody.change_request.price = Math.floor(this.phaseService.finalPricenWeeks.bcard_discounted_price);
        } else {
          this.phaseService.changeRequestBody.change_request.price = Math.floor(this.phaseService.customizationCost + this.phaseService.featureReusableCost);
        }

        this.phaseService.changeRequestBody.change_request.custom_feature_price = this.phaseService.customFeaturesPrice;
        this.phaseService.changeRequestBody.change_request.customisation_price = this.phaseService.customizationCost;
        const changeReq = this.phaseService.changeRequestBody.change_request;
        this.setFixedAndSubscriptionPrice(changeReq);
        this.setBuilderCareAmount(changeReq);
        const changeReqFeatures = this.dataService.changeFeatureRequestModel.features.map(f => {
            const addedFeature = this.dataService.buildCardData['progress']['features'].find(fe => fe.id === f.id);
            if (f['feature_count'] && addedFeature['feature_count']) {
                return { feature_id: f.id, feature_count: (f['feature_count'] + addedFeature['feature_count']) };
            } else {
                return { feature_id: f.id };
            }
        });
        if (this.phaseService.phaseData && this.phaseService.phaseData.length > 0) {
            const buildPhaseData = {};
            this.phaseService.phaseData.forEach((phase) => {
                if (!phase['phase_name'].includes('mvp') && !phase['phase_name'].includes('new_phase')) {
                    buildPhaseData[phase.phase_name] = { delivery_time: phase.delivery_time, price: phase.price };
                }
            });
            this.phaseService.changeRequestBody.change_request.build_phase_data = buildPhaseData;
            this.setPriceAndDurationInFeatres(this.dataService.additionalFeatureIds);
            this.setNoteInFeatres();
            this.phaseService.changeRequestBody.change_request.build_card_data.features = (this.dataService.additionalFeatureIds && this.dataService.additionalFeatureIds.length > 0)
                ? this.dataService.additionalFeatureIds
                : changeReqFeatures;
        }
        else {
            this.phaseService.changeRequestBody.change_request.build_phase_data = this.dataService.changeFeatureRequestModel.build_phase_data;
            this.phaseService.changeRequestBody.change_request.build_card_data.features = changeReqFeatures;
        }
    }

    setNoteInFeatres(){
        this.dataService.additionalFeatureIds.forEach(feature => {
            const featureFromPricing = this.phaseService.selectedFeatures.find(f => f.id === feature.feature_id);
            feature['feature_note'] = featureFromPricing['feature_note'];
            feature['feature_attachment'] = featureFromPricing['feature_attachment'];
        });
    }

    setPriceAndDurationInFeatres(features) {
      features.forEach(feature => {
            const featureFromPricing = this.phaseService.featureData.find(f => f.id === feature.feature_id);
            feature['customisation_price'] = featureFromPricing['customisation'];
            feature['fixed_price'] = featureFromPricing['fixed'];
            feature['duration'] = featureFromPricing['duration'];
        });
    }

    featureEditingForAdditionFeature(page, eventName?, editAdditionalFeatures?, editAdditionalRentalFeatures?) {
        this.dataService.showDeliveryLoading = false;
        this.phaseService.buildCardEditMode = true;
        this.dataService.isEditBuildCard = true;
        this.phaseService.fromDashBoard = false;
        this.phaseService.showCurrency = false;
        this.phaseService.trackEventForObject(this.analyticsSegment,  "build_card_edited", {
            user_id: this.dataService.user?.id,
            buildcard_change: eventName
        });
        if (this.dataService.buildCardData.promotion) {
            this.phaseService.selectedHash.promotion_id = this.dataService.buildCardData.promotion.id;
        }
        if (this.getBuildCardData().type === 'rental_price' && this.getBuildCardData().source === 'app_detail') {
            this.appDataService.urlParameters.cpe_user = 'true';
            this.appDataService.urlParameters.uniq_code = this.dataService.buildCardData.uniq_code;
        }
        if ((page === 'features') && !this.phaseService.isFeatureRequest) {
            this.dataService.totalSelectedFeatures = 0;
        }
        const uniqueCode = this.dataService.changeFeatureRequestModel.build_card ? this.dataService.changeFeatureRequestModel.build_card.uniq_code : this.dataService.buildCardData.uniq_code;
        if (editAdditionalFeatures) {
            sessionStorage.setItem('show_natasha_again', 'not_from_template_page');
            this.router.navigate([`${uniqueCode}/feature`], { queryParams: { featureRequest: 'true' , request_id:  this.appDataService.urlParameters.changeRequest_id} });
        } else if (editAdditionalRentalFeatures) {
            if (this.appDataService.urlParameters.cpe_user && this.appDataService.urlParameters.uniq_code) {
                sessionStorage.setItem('show_natasha_again', 'not_from_template_page');
                // window.location.href =   `${window.location.origin}/features/${this.appDataService.urlParameters.uniq_code}?cpe_user=true`;
                sessionStorage.setItem('isReloadFeaturePage', 'true');
                this.router.navigate(['feature'], { queryParams: { uniq_code: this.appDataService.urlParameters.uniq_code, cpe_user: 'true' } });
            }
        } else {
            if (page == 'apps') {
                this.router.navigate([uniqueCode + '/' + page]);
            } else if (page === 'features') {
                sessionStorage.setItem('show_natasha_again', 'not_from_template_page');
                this.router.navigate([uniqueCode + '/feature']);
            } else {
                this.router.navigate([page + '/' + uniqueCode]);
            }
        }
    }

    public getBuildCardData() {
        return this.dataService.buildCardData;
    }

    changeRequestPlatformHash() {
      const buildPhases = this.dataService.homePageData.build_phases;
      const fullBuild = buildPhases.find(phase => phase['title'] === 'Full Build');
      const features = this.phaseService.selectedFeatures.map(feature => {
        return { feature_id: feature.id };
      });
      this.setPriceAndDurationInFeatres(features);
      const buildPhaseData = this.phaseService.selectedPhases.map(phase => {
        const phaseForID = this.dataService.buildCardData['progress']['buildcardphases'].find(p => p['build_phase_id'] === phase.build_card_phase_id);
        const phaseData =  {
          build_card_phase_id: phaseForID.id,
          price: phase.price,
          delivery_time: phase.delivery_time,
        };
        if ((phase['title'] === 'MVP') || (phase['type'] === 'custom_phase')) {
          phaseData['features'] = this.setMVPAndCustomFeatures(phase);
        } else {
          phaseData['platform_ids'] = phase.platforms.map(platform => platform.id);
        }
        return phaseData;
      });
      const buildCardData = {
        build_card_id: this.dataService.buildCardData.id,
        platform_ids: fullBuild['platforms'].map(p => p.id),
        features: features
      };
      this.phaseService.changeRequestPlatformHash = {
        weeks: this.dataService.totalAdditionalFeaturesWeek,
        price: Math.floor(this.phaseService.customizationCost + this.phaseService.featureReusableCost),
        customisation_price: this.phaseService.customizationCost,
        status: 'pending',
        custom_feature_price: this.phaseService.customFeaturesPrice,
        build_card_data: buildCardData,
        build_phase_data: buildPhaseData
      };
      this.setBuilderCareAmount(this.phaseService.changeRequestPlatformHash);
      this.setFixedAndSubscriptionPrice(this.phaseService.changeRequestPlatformHash);
    }

    setMVPAndCustomFeatures(phase) {
      const phasesWithFeatures = phase['features'].map(feature => {
        return {
          feature_id: feature.id,
          platform_ids: feature.platforms.map(platform => platform.id)
        };
      });
      return phasesWithFeatures;
    }

    setBuilderCareAmount(changeReq) {
      if (this.phaseService.selectedHash.is_under_support && !this.phaseService.isStoreFeatureRequest) {
        if (this.phaseService.carePrices) {
          changeReq['builder_care_amount'] = this.phaseService.carePrices[this.phaseService.selectedHash.care_type];
        } else {
          changeReq['builder_care_amount'] = this.phaseService.builderCarePrice;
        }
      }
    }

    setFixedAndSubscriptionPrice(changeReq) {
      if (this.dataService.buildCardData.subscription_flow) {
        changeReq['fixed_price'] = this.phaseService.featureReusableCost;
        changeReq['subscription_price'] = this.phaseService.subscriptionCost;
      }
    }
}
