import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  Renderer2,
  ViewChild,
  NgZone,
} from '@angular/core';
import { Angulartics2Segment } from 'angulartics2/segment';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { DataService } from '@shared/services/data.service';
import { PhaseService } from '@shared/services/phase.service';
import { Subscription, Observer, Observable, Subject } from 'rxjs';
import { BundleModel } from '@models/bundle-model/bundle-model';
import { ApiRequests } from '@shared/services/api-requests.service';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarDirective,
} from 'ngx-perfect-scrollbar';
import { FeatureModel } from '@models/feature-model/feature.model';
import { Constants } from '@shared/utils/constants';
import { ModelMapperService } from '@shared/services/model-mapper.service';
import { UtilityService } from '@shared/services/utility.service';
import { AppDataService } from '@rootmodule/app-data.service';
import { finalize } from 'rxjs/operators';
import { UserIdleService } from 'angular-user-idle';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/takeUntil';
import { CommonService } from '@shared/services/common.service';
// import { CKEditor4 } from "ckeditor4-angular/ckeditor";
import { Intercom } from 'ng-intercom';
import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { SeoService } from '@shared/services/seo.service';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { DomSanitizer } from '@angular/platform-browser';
import { HomeTemplateService } from '@shared/services/home-template.service';
import { KeyValue } from '@angular/common';
// import * as ClassicEditor from "ckeditor4-angular";
import { SortFeaturePipe } from '@featuremodules/feature-module/pipes/sort-feature.pipe';
import { threadId } from 'worker_threads';
import { jsonpFactory } from '@angular/http/src/http_module';

@Component({
  selector: 'featurecard',
  templateUrl: 'featurecard.component.html',
  styleUrls: ['featurecard.component.scss'],
  host: {
    '(document:keydown)': 'handleKeyboardEvents($event)',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureCardComponent implements OnInit, OnDestroy, AfterViewInit {

  public isMore = false;
  public isVideoOpened = false;
  public bundleClick = false;
  public selected = false;
  public showAll = false;
  public sortedFeatureList: any[] = [];
  public featureHolder;
  public popupFeature;
  public isFeatureNoteValid: boolean;
  public animateClass = false;
  public showPopupFeature = false;
  public deleteAll = false;
  public selectedBundle: any;
  public rangePrice = [0, 100];
  public rangeDuration = [0, 100];
  public currentView = 0;
  public featuresAfterUpdate: any;
  public savedFeature: any;
  public filterSubscription: Subscription;
  public searchSubscription: Subscription;
  private viewPrototypeSubscription: Subscription;
  public featurePreview = true;
  public showOverlay = false;
  public removeUsingTutorial = false;
  public scrolledIndex = 0;
  public filterParamsBundle = {
    feature_min_weeks: 0,
    feature_max_weeks: 0,
    feature_min_price: 0,
    feature_max_price: 0,
    feature_bundle_ids: [],
    feature_tag_ids: [],
    feature_template_ids: [],
  };
  public filterParamsFeatures = {
    feature_min_weeks: 0,
    feature_max_weeks: 0,
    feature_min_price: 0,
    feature_max_price: 0,
    feature_bundle_ids: [],
  };
  PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: false,
  };
  PERFECT_SCROLLBAR_CONFIG_HORIZONTAL: PerfectScrollbarConfigInterface = {
    suppressScrollY: true,
  };

  PERFECT_SCROLLBAR_BUNDLE_CONFIG: PerfectScrollbarConfigInterface;

  public featureFilter = {
    tags: [],
    templates: [],
    bundles: [],
    bundle_details: {
      max_price: 0,
      min_price: 0,
      max_global_price: 0,
      min_global_price: 0,
      max_weeks: 0,
      min_weeks: 0,
      max_global_weeks: 0,
      min_global_weeks: 0,
    },
    feature_details: {
      max_price: 0,
      min_price: 0,
      max_global_price: 0,
      min_global_price: 0,
      max_weeks: 0,
      min_weeks: 0,
      max_global_weeks: 0,
      min_global_weeks: 0,
    },
  };
  public searchQuery = '';
  public showQuery = false;
  public searchView = false;
  public selectedBundleView = false;
  public mobileFilterMode = false;
  public focusedBundleIndex = -1;
  public focusedFeatureIndex = 0;
  public selectedPreviewPlatform = 'android';
  public showPreviewFullScreen = false;
  public realFiltersFetched = false;
  public showFilter = false;
  public inHoverState = false;
  public hoveredFeatureIndex = 0;
  public addedFeature: FeatureModel;
  public featureAddedSubscription: Subscription;
  public imagePreviewLoaded = false;
  public priceFilterApplied = 0;
  public durationFilterApplied = 0;
  public tagFilterApplied = 0;
  public previewedFeature: FeatureModel = null;
  public filterReset = false;
  public backFromSearch = false;
  public isRecentSearchVisile = false;
  public selectedFeatureBundle: BundleModel;
  public bundleFeaturesToBeSelectedAfterUserConfirmation: BundleModel;
  public toggleClass = true;
  public showMore = false;
  public showRemoveAll = false;
  public showNavTutorial = false;
  public showRemoveAnimation = false;
  public removingFeatureIndex = 0;
  public tooltipIndex: number = null;
  public tutorialSeen = false;
  public showAllRemovedText = false;
  public previewSelect = false;
  public showGllry = false;
  public hidetoolBar = false;
  public minimizeGallery = false;
  public templateFilterApplied = 0;
  public filterTrackEvent = {
    cost_filter: false,
    duration_filter: false,
    functionality_filter: false,
    app_filter: false,
  };
  public currentUrl;
  @ViewChildren(PerfectScrollbarComponent) componentScroll: QueryList<any>;
  @ViewChildren(PerfectScrollbarComponent, { read: ElementRef })
  componentScrollElement: QueryList<ElementRef>;
  public showShimmerEffect = false;
  public currencyChangeSubscription: Subscription;
  isShowPlatformOnLanding = false;
  public bundlesApiSubscription: Subscription;
  public featureFilterSubscription: Subscription;
  public showBottomPipe = false;
  private clickedJustNow = false;
  private clickedJustNowDuration = false;
  private selectedFeaturesApiSubscription: Subscription;
  public areBundlesLoading = false;
  public areSelectedFeaturesLoading = false;
  public areBundleFeaturesLoading = false;
  showFeatureLoading = false;
  private noFilteredDataFound = false;
  isPollingCancelled = false;
  public updateBuildCardHash = [];
  isIOSChrome = false;
  isMobile = false;
  optionsPrice: Options = { floor: 0, ceil: 100, noSwitching: true };
  optionsDuration: Options = { floor: 0, ceil: 100, noSwitching: true };
  showArrow = false;
  focusedBundleArrowIndex = 0;
  openWelcomeFeaturePopup = true;
  showModalForRemovingLockFeature = false;
  lockFeatureToRemove: FeatureModel;
  tempPlatforms: any;
  showDynamicPriceModal = false;
  noMoreRemoveLockFeatureModalgModal = false;
  innerWidth = 768;
  mobileSearchBox = false;
  mobileNoFeaturePopup = true;
  mobileFeatureGroup = false;
  mobileCartBox = false;
  natashaPanel = false;
  natashaMobilePanel = false;
  isScrollCartItem = false;
  isScrollNatashaSidebar = false;
  showFeatureAddedToolTip = false;
  searchFAQInput = '';
  searchResultFAQs = [];
  noSearchResult = false;
  showSearchResultDetail = false;
  searchResultDetailToBeDisplayed: any;
  hasMetNatashaFirstTime = false;
  hasMetNatashaAgain = false;
  hasMetNatashaAgainComingNotFromTemplate = false;
  hasMetNatashaAgainComingFromTemplate = false;
  showAllSearchResults = false;
  searchResultDetailParsedBody: any;
  recommendedFeatures: Array<FeatureModel> = [];
  showNewRecommendations = true;
  natashaMessagesTimeTrack: Array<string> = [];
  showFeatureAnimation = true;
  @ViewChildren('navRecommendedFeaturesSlider', { read: DragScrollComponent })
  rds: QueryList<DragScrollComponent>;
  leftNavDisabled: boolean;
  rightNavDisabled: boolean;
  animateAllRecommendedFeatures = false;
  blinkAnimation = false;
  mainwizardDot = false;
  welcomeToRightSide = false;
  rightToRemoveall = false;
  filterApplied = false;
  shouldBlinkAnimation = false;
  removeAllToRight = false;
  featureDescription = false;
  filterParamsBundleCopy: any;
  showFeatureRemovedTooltip = false;
  showRemoveAllFeatureTooltip = false;
  searchFocus = false;
  bottomToTopAnimation = false;
  topToBottomAnimation = false;
  rightToLockAnimation = false;
  lockToRightAnimation = false;
  shouldOpenNatashaInfo = false;
  @ViewChild('perfectScroll') perfectScroll: PerfectScrollbarComponent;
  @ViewChild('perfectScrollNatasha')
  perfectScrollNatasha: PerfectScrollbarComponent;
  pulsateNatasha = 0;
  countNatashaPulse = 0;
  chatsTobeShownOnUI = {};
  focusedBundlePreviousIndex = -1;
  buildcardUpdatefeatureSliderIndex = 0;
  buildcardUpdateChatsTobeShownOnUI = {};
  buildcardUpdateNatashaChatsList = {};
  buildcardUpdateNatashaIntroMessagesList = [];
  buildcardUpdateNatashaTempIntroMessagesList = [];
  welcomeMsgAnimation = false;
  @ViewChild('searchInput') searchInput;
  isShowNatashaWelcomePopup = true;
  isNatashaWelcomePopupOpened = false;
  hideFeatureRightLoading = false;
  natashaUnreadMessageCount = 0;
  isPageRefreshed = false;
  natashaMobileUnreadMessageCount = 0;
  shouldBlinkAnimationForRecommendationToggle = false;
  shouldHideWelcomeMessage = true;
  userIdleTimer;
  natashaYesNoQuestionResponse = '';
  natashaFeedbackResponse = '';
  initialChatMessagesLength = 0;
  isAddManualFeatureFirstTime = false;
  isShowSplashScreen = false;
  isShowNatashaLastIntroMessage = false;
  isCarousalExpanded = false;
  showNewNatashaMessageButton = false;
  // isShowCustomFeatureTitleQuestion = false;
  // isShowCustomFeatureDescriptionQuestion = false;
  customFeatureTitle = '';
  customFeatureDescription = '';
  // isShowCustomFeatureSimilarAppsQuestion = false;
  customFeatureSimilarApps = '';
  descriptionZindex = false;
  featureDescriptionTop = 0;
  featureDescriptionLeft = 0;
  featuredescription = '';
  public randomFeatureValue: number;
  featureNotePopup = false;
  featureNoteEditPopup = false;
  featureNoteDeletePopup = false;
  duplicateFileValidation = false;
  featureNoteText: any;
  featureNoteFile: any;
  FeatureNoteValidationText: string;
  public featureNoteExpand = false;
  myFiles: string[] = [];
  enableSaveButton = false;
  urlArray = [];
  public fileData: any;
  fileNameArray = [];
  showSortingOptions = false;
  sortingText = '';
  sortWith = '';
  savedfeatureNote = '';
  savedfeatureNoteFiles = [];
  isFileMoreThan60Mb = false;
  isShowMaxFeaturePopUp = false;
  editorConfig = {
    placeholder: 'Tell us exactly what you’re expecting this feature to do (if it needs to include your images and text – attach these too).',
  };
  showStorePlusTooltip = false;

  constructor(
    public dataService: DataService,
    public phaseService: PhaseService,
    public analyticsSegment: Angulartics2Segment,
    public router: Router,
    public renderer: Renderer2,

    public apiRequest: ApiRequests,
    public cdRef: ChangeDetectorRef,
    public modelMapperService: ModelMapperService,
    public utilityService: UtilityService,
    private activatedRoute: ActivatedRoute,
    public appDataService: AppDataService,
    private userIdle: UserIdleService,
    public commonService: CommonService,
    private seoService: SeoService,
    public sanitizer: DomSanitizer,
    public homeTemplateService: HomeTemplateService,
    private zone: NgZone,
    private sortFeaturePipe: SortFeaturePipe
  ) {
    this.commonService.setEditorConfiguration();
    this.phaseService.showBottomBar = true;
    this.dataService.currentPage = 'features';
    this.seoService.updateMetaTags(Constants.META_TAGS_DESCRIPTION.features);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          this.currentUrl &&
          this.currentUrl.includes('delivery') &&
          event.url.includes('feature')
        ) {
          this.phaseService.dontSetCareConfig = true;
        }
        this.currentUrl = event.url;
        if (this.currentUrl && this.currentUrl.indexOf('platforms') !== -1) {
          this.isShowPlatformOnLanding = true;
        }
      }
    });
    this.innerWidth = window.innerWidth;
    if (window.innerWidth <= 1200) {
      this.natashaPanel = true;
    } else {
      this.natashaPanel = false;
    }
    this.commonService.featureAdded.subscribe(feature => {
      if (feature && feature?.id) {
        this.changeFocusedFeature(feature);
        setTimeout(() => {
          this.cdRef.detectChanges();
        }, 2000);
      }
      if (feature === 'added') {
        this.showFeatureAddedToolTip = true;
        this.cdRef.detectChanges();
        setTimeout(() => {
          this.showFeatureAddedToolTip = false;
          this.cdRef.detectChanges();
        }, 2000);
      }
    });
  }

  handleKeyboardEvents(event: KeyboardEvent) {
    if (this.isCarousalExpanded) {
      this.keydownHandlerForNatashaCarousal(event);
      return;
    }
    if (this.getSelectedFeatures().length > 0) {
      if (event.key === 'ArrowLeft') {
        this.handlePrevButton();
      } else if (event.key === 'ArrowRight') {
        this.handleNextButton();
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.setScrollBarBundleConfig();

    if (window.innerWidth <= 1200) {
      this.natashaPanel = true;
    } else {
      this.natashaPanel = false;
    }
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event) {
    clearTimeout(this.userIdleTimer);
    if (this.phaseService.getNatashaIdleQuestionCount() < 3) {
      this.userIdleTimer = setTimeout(() => {
        const lastAction = [];
        this.fetchRecommendedFeatures(lastAction);
        let count = parseInt(localStorage.getItem('natashaIdleQuestionCount'));
        count = count + 1;
        const countString = '' + count;
        localStorage.setItem('natashaIdleQuestionCount', countString);
      }, this.phaseService.natashaIdlQuestionTimeInterval);
    }
  }
  @HostListener('window:popstate')
  onPopState() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url.includes('home')) {
          this.phaseService.reset();
          this.phaseService.showSaveProgress = false;
          this.renderer.removeClass(
            document.getElementById('app-component'),
            'hide-scroll-bar'
          );
        }
      }
    });
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (event.target && event.target['className'] === 'sorting-options') {
      return false;
    }
    this.showSortingOptions = false;
  }

  MouseWheelHandler(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  keydownHandlerForNatashaCarousal(e) {
    const key = e.key;
    switch (key) {
      case 'ArrowUp':
      case 'ArrowDown':
        e.preventDefault();
        e.stopPropagation();
        break;
    }
  }

  get isFeatureRequest() {
    return this.appDataService.urlParameters.featureRequest;
  }

  handlePriceAndDurationLoader() {
    this.phaseService.handleLoaderOnPrice.subscribe(() => {
      this.cdRef.detectChanges();
    });
  }

  public ngOnInit() {
    this.renderer.removeClass(
      document.getElementById('app-component'),
      'natasha-confirmation'
    );
    if (sessionStorage.getItem('isReloadFeaturePage') === 'true') {
      sessionStorage.setItem('isReloadFeaturePage', 'false');
      window.location.reload();
    }
    this.setInvalidAccessIfBuildCardAccessByLoggedOutUser();
    this.isPollingCancelled = false;
    navigator.userAgent.match('CriOS')
      ? (this.isIOSChrome = true)
      : (this.isIOSChrome = false);
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    this.handleMeetNowFlowFromAppDetail();
    if (this.phaseService.getUrlParamsFromUrl()) {
      this.appDataService.setIncomingUrlParameters(
        this.phaseService.getUrlParamsFromUrl()
      );
    }

    if (
      this.phaseService.selectedApps &&
      this.phaseService.selectedApps.length === 1 &&
      sessionStorage.getItem('show_natasha_again') === 'from_template_page'
    ) {
      this.checkSameTemplateForReturnUser();
    }

    if (
      sessionStorage.getItem('show_natasha_again') ===
        'not_from_template_page' &&
      !this.phaseService.isFromDeliveryPage
    ) {
      this.phaseService.natashaChatsList = {};
      if (!this.isShowSplashScreen) {
        this.homeTemplateService.resetTemplateNatashaChatHistory();
        this.phaseService.isCollapseTemplateHistory = false;
      }
      this.phaseService.natashaGroupIdentifier = '';
      this.phaseService.natashaGroupChatId = 1;
      this.phaseService.natashaGroupIdText = 'natashaGroupId';
      if (
        !this.isShowSplashScreen &&
        this.isNatashaEditModeAlreadyAddedBuildCardFeatures()
      ) {
        this.chatsTobeShownOnUI = {};
        const obj = {
          natashaEditModeAlreadyAddedBuildCardFeaturesStatus: true,
          recommendedFeaturesHistory:
            this.phaseService.recommendedFeaturesHistory,
          showLoader: false,
          type: 'natasha',
          createdAt: this.phaseService.getDateTimeDifferenceFromToday(
            this.phaseService.recommendedFeaturesHistory[0].created_at
          ),
          timeStamp: this.phaseService.getCurrentTimestamp(),
        };
        this.addObjectInNatashaGroup(obj);
      }
    }
    this.chatsTobeShownOnUI = JSON.parse(
      JSON.stringify(this.phaseService.natashaChatsList)
    );
    if (!this.checkIfNatashaChatsAreEmpty()) {
      this.initialChatMessagesLength = this.checkInitialNatashaMessagesLength();
    }

    if (
      this.homeTemplateService.tempateNatashaChatList &&
      this.objectKeys(this.homeTemplateService.tempateNatashaChatList).length >
        0
    ) {
      this.phaseService.isCollapseTemplateHistory = true;
    }

    if (this.dataService.user && this.dataService.user.setting) {
      if (
        this.dataService.user.setting.dynamic_pricing !== null ||
        this.dataService.user.setting.dynamic_pricing !== undefined
      ) {
        this.dataService.dynamicPricingSetting.value =
          this.dataService.user.setting.dynamic_pricing;
      }
      if (
        this.dataService.user.setting.feature_removal_confirmation !== null ||
        this.dataService.user.setting.feature_removal_confirmation !== undefined
      ) {
        this.dataService.featureRemovalConfirmSetting.value =
          this.dataService.user.setting.feature_removal_confirmation;
      }
      if (
        this.dataService.user.setting.is_recommendation_on !== null ||
        this.dataService.user.setting.is_recommendation_on !== undefined
      ) {
        this.dataService.featureRecommendationsConfirmSetting.value =
          this.dataService.user.setting.is_recommendation_on;
      }
      this.dataService.showDynamicPricingModalSetting =
        this.dataService.dynamicPricingSetting.value;
      this.dataService.showFeatureRemovalConfirmModalSetting =
        this.dataService.featureRemovalConfirmSetting.value;
      this.dataService.showFeatureRecommendationsConfirmModalSetting =
        this.dataService.featureRecommendationsConfirmSetting.value;
      this.dataService.noMorePricingModal =
        !this.dataService.showDynamicPricingModalSetting;
      if (
        this.dataService.user.setting.avatar_details &&
        this.dataService.user.setting.avatar_details.color_codes
      ) {
        this.dataService.natashaAvatarBackground =
          this.dataService.user.setting.avatar_details.color_codes.background;
        this.dataService.natashaAvatarChatHandle =
          this.dataService.user.setting.avatar_details.color_codes.chat_handle;
        this.dataService.natashaAvatarInitials =
          this.dataService.user.setting.avatar_details.initials;
      }
      if (
        !this.dataService.noMorePricingModal &&
        this.commonService.isPlatformBrowser
      ) {
        localStorage.removeItem('show_dynamic_pricing_popup');
      } else if (
        this.dataService.noMorePricingModal &&
        this.commonService.isPlatformBrowser
      ) {
        localStorage.setItem('show_dynamic_pricing_popup', 'NO');
      }
    }
    if (
      this.phaseService.natashaCurrentAddedFeatureMessages &&
      this.phaseService.natashaCurrentAddedFeatureMessages.length === 0
    ) {
      this.phaseService.natashaCurrentAddedFeatureMessages = [
        ...this.phaseService.natashaAddedFeatureMessages,
      ];
    }

    this.hasMetNatashaAgain = false;
    this.hasMetNatashaAgainComingNotFromTemplate = false;
    this.hasMetNatashaAgainComingFromTemplate = false;
    this.phaseService.showMobileSteps = true;
    this.setUpCurrencyChangeSubscription();
    this.dataService.currentPage = 'features';
    this.phaseService.setComponentTitle(
      this.analyticsSegment,
      Constants.PAGE_TITLES.features.code
    );
    this.phaseService.setPhasesUsingSharedUrl();
    if (
      !this.dataService.user &&
      (this.appDataService.urlParameters.red === 'meet_now' ||
        this.appDataService.urlParameters.red === 'ip_now')
    ) {
      this.phaseService.showSignin = true;
      this.phaseService.openCommonAuth = true;
      return;
    }
    if (innerWidth <= 768) {
      sessionStorage.setItem('show_natasha_again', 'not_from_template_page');
      this.closeWelcomePopup();
    }
    if (!this.dataService.homePageData.platforms) {
      this.areBundlesLoading = true;
      this.isPageRefreshed = true;
      const featureIds = this.phaseService.selectedHash.feature_ids;
      if (
        this.phaseService.selectedHash.application_ids.length > 0 ||
        featureIds.length > 0
      ) {
        this.areSelectedFeaturesLoading = true;
      }
      this.bundlesApiSubscription = this.apiRequest
        .fetchHomePageData()
        .subscribe((data: any) => {
          this.dataService.dataLoadingCurrency = Object.assign(
            {},
            this.dataService.homePageData.currency
          );
          this.phaseService.platformPrices = Array.apply(
            null,
            Array(this.dataService.homePageData.platforms.length)
          );
          this.phaseService.platformPrices.map((x, i) => {
            return i;
          });
          this.tempPlatforms = Object.assign(
            [],
            [...this.dataService.homePageData.platforms]
          );
          if (this.dataService.homePageData.currency) {
            this.phaseService.specing_price =
              this.dataService.homePageData.currency.specing_price;
          }
          this.renderFeaturePage();
        });
    } else {
      this.areBundlesLoading = true;
      this.renderFeaturePage();
    }
    this.setBreadCrumbForFeatures();
    this.phaseService.openSideNav = false;
    this.dataService.updateCustomFeature.subscribe((data) => {
      if (data) {
        this.previewedFeature = this.getPreviewedFeature();
        this.cdRef.detectChanges();
      }
    });
    this.setScrollBarBundleConfig();
    this.setUpviewprototypeSubscription();
    this.handlePriceAndDurationLoader();
  }

  handleMeetNowFlowFromAppDetail() {
    if (this.dataService.openMeetNowFromAppDetail) {
      if (!this.dataService.user) {
        this.phaseService.openCommonAuth = true;
        if (this.dataService.homePageData?.block_sign_up) {
          this.phaseService.showSignin = true;
          this.phaseService.showRegister = false;
        } else {
          this.phaseService.showRegister = true;
          this.phaseService.showSignin = false;
        }
        this.dataService.openMeetNowModal = true;
      } else if (!this.dataService.checkIfCPEUser()) {
        this.dataService.showMeetNowModel = true;
      }
    }
  }

  public mobileSearch() {
    this.mobileSearchBox = !this.mobileSearchBox;
  }

  public mobileNoFeatureClose() {
    this.mobileNoFeaturePopup = false;
  }

  public mobileFeatureSelect(collapse?) {
    this.mobileFeatureGroup = !this.mobileFeatureGroup;
    if (collapse) {
      this.focusedBundleIndex = -1;
    } else {
      this.focusedBundleIndex = 0;
    }
  }

  public mobileCartListBox() {
    this.mobileCartBox = !this.mobileCartBox;
  }

  public natashaToggle() {
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      'natasha_wizard_menu_selected',
      {
        elements_clicked: 'Collapse Panel',
      }
    );
    this.natashaPanel = !this.natashaPanel;
    this.dataService.natashaDropdownPanel = false;
    if (!this.natashaPanel) {
      this.natashaUnreadMessageCount = 0;
    }
  }

  public natashaMobileOpen() {
    this.natashaMobilePanel = true;
    this.dataService.natashaDropdownPanel = false;
    this.natashaMobileUnreadMessageCount = 0;

    this.bottomToTopAnimation = true;
    this.mainwizardDot = true;
    setTimeout(() => {
      this.bottomToTopAnimation = false;
      this.mainwizardDot = false;
      this.cdRef.detectChanges();
    }, 400);
  }

  public natashaMobileClose() {
    this.natashaMobilePanel = false;
    this.dataService.natashaDropdownPanel = false;

    this.topToBottomAnimation = true;
    this.mainwizardDot = true;
    setTimeout(() => {
      this.topToBottomAnimation = false;
      this.mainwizardDot = false;
      this.cdRef.detectChanges();
    }, 400);
  }

  public natashaDropdownClick() {
    this.dataService.natashaDropdownPanel =
      !this.dataService.natashaDropdownPanel;
    if (this.dataService.natashaDropdownPanel) {
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        'natasha_wizard_menu_clicked',
        {}
      );
    }
  }

  public descriptionToggle() {
    this.featureDescription = !this.featureDescription;
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      'feature_info_icon_hovered',
      {
        user_id: this.dataService.user?.id,
      }
    );
  }

  checkForArrayEquality(_arr1, _arr2) {
    if (
      !Array.isArray(_arr1) ||
      !Array.isArray(_arr2) ||
      _arr1.length !== _arr2.length
    ) {
      return false;
    }
    const arr1 = _arr1.concat().sort();
    const arr2 = _arr2.concat().sort();
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }
  setUpviewprototypeSubscription() {
    this.viewPrototypeSubscription = this.phaseService
      .getViewPrototypeCallback()
      .subscribe((ifview) => {
        if (ifview) {
          setInterval(() => {
            if (
              !this.isPollingCancelled &&
              this.dataService.isFromViewPrototype
            ) {
              this.polling();
            }
          }, 5000);
        }
      });
  }

  polling() {
    this.apiRequest
      .checkforNewFeatures(
        this.phaseService.selectedHash.feature_ids,
        this.dataService.buildCardData.id
      )
      .subscribe((response) => {
        if (
          response &&
          response.build_card_updated &&
          response.selected_features &&
          response.selected_features.length > 0 &&
          !this.checkForArrayEquality(
            this.phaseService.selectedHash.feature_ids,
            response.selected_features
          )
        ) {
          const newfeatures = response.selected_features;
          const feature_ids = [];
          newfeatures.forEach((feature) => {
            feature_ids.push(feature.id);
          });
          this.dataService.featureBundles.forEach((bundle, index) => {
            if (bundle.features && bundle.features.length > 0) {
              bundle.features.forEach((bundlefeature) => {
                bundlefeature.selected = false;
              });
            }
          });
          newfeatures.forEach((feature, i) => {
            this.dataService.featureBundles.forEach((bundle, index) => {
              if (bundle.features && bundle.features.length > 0) {
                const findElement = bundle.features.find((bundlefeature) => {
                  return bundlefeature.id === feature.id;
                });

                if (findElement) {
                  findElement.selected = true;
                }
              }
            });
          });
          this.phaseService.selectedFeatures = Object.assign([...newfeatures]);
          this.phaseService.selectedFeatures.forEach((feature) => {
            feature.selected = true;
          });
          this.phaseService.selectedHash.feature_ids = Object.assign([
            ...feature_ids,
          ]);
          // this.dataService.totalSelectedFeatures = this.phaseService.selectedFeatures.length;
          for (const buildType of this.dataService.homePageData.build_phases) {
            if (buildType.selected && buildType.title !== 'MVP') {
              buildType.features = this.phaseService.selectedFeatures;
            }
          }
          this.phaseService.selectedPhases.forEach((phase) => {
            if (phase.title !== 'MVP') {
              phase.features = this.phaseService.selectedFeatures;
            }
          });
          this.cdRef.detectChanges();
        }
      });
  }

  renderFeaturePage() {
    const redirectUrl = sessionStorage.getItem('featurecard-buildcard-param');
    if (
      ((this.appDataService.urlParameters &&
        this.appDataService.urlParameters.buildcard) ||
        redirectUrl) &&
      this.dataService.user
    ) {
      this.dataService.showHideLoader(true);
      let equalSignIndex;
      let buildCardCode = '';
      if (redirectUrl) {
        equalSignIndex = redirectUrl.indexOf('=');
        buildCardCode = redirectUrl.substr(equalSignIndex + 1);
      } else {
        buildCardCode = this.appDataService.urlParameters.buildcard;
      }
      this.apiRequest.fetchSingleBuildCardData(buildCardCode).subscribe(
        (data: any) => {
          if (redirectUrl) {
            sessionStorage.removeItem('featurecard-buildcard-param');
            this.dataService.invalidAccess = false;
          }
          sessionStorage.setItem(
            'show_natasha_again',
            'not_from_template_page'
          );
          const card = this.dataService.buildCardData;
          this.phaseService.inProgressBuildCardId =
            this.dataService.buildCardData.id;
          if (
            card.status === 'card_initialized' ||
            card.status === 'card_completed'
          ) {
            this.phaseService.setUpInFullBuildCard();
            this.phaseService.buildCardEditMode = true;
            this.dataService.isEditBuildCard = true;
            this.shouldHideWelcomeMessage = false;
            this.renderFeaturePageData();
          } else {
            this.router.navigate(['home']);
          }
        },
        (error) => {
          this.dataService.showHideLoader(false);
          if (
            this.commonService.isPlatformBrowser &&
            (error.status === 403 ||
              error.status === 401 ||
              error.status === 404)
          ) {
            this.dataService.invalidAccess = true;
            this.router.navigate(['invalidaccess']);
          }
        }
      );
    } else if (
      this.dataService.user &&
      this.activatedRoute.snapshot.paramMap.get('id') &&
      !this.activatedRoute.snapshot.queryParamMap.get('featureRequest') &&
      !this.phaseService.buildCardEditMode
    ) {
      const uniqCode = this.activatedRoute.snapshot.paramMap.get('id');
      this.apiRequest.fetchSingleBuildCardData(uniqCode).subscribe((data: any) => {
          this.phaseService.inProgressBuildCardId = this.dataService.buildCardData.id;
          if (this.phaseService.isNewBuildCard && this.dataService.homePageData.promotion) {
            this.phaseService.applyRemovePromotion(
              this.dataService.homePageData.promotion['code'],
              true
            );
          }
          this.phaseService.setUpInFullBuildCard();
          this.phaseService.buildCardEditMode = true;
          this.dataService.isEditBuildCard = true;
          if (!this.dataService.buildCardData.progress.apps.length) {
            this.router.navigate(
              [`${this.dataService.buildCardData.uniq_code}/apps`],
              { queryParams: this.setQueryParamIfFrom360() });
          } else if (this.appDataService.urlParameters.redirection_url && (this.dataService.buildCardData.build_card_type === 'studio_pro')) {
            const newPath = location.href.split('&')[0];
            window.history.pushState({ path: newPath }, '', newPath);
            this.appDataService.urlParameters.cpe_user = null;
          }
          if (
            this.phaseService.buildCardEditMode &&
            sessionStorage.getItem('show_natasha_again') !==
              'from_template_page'
          ) {
            this.shouldHideWelcomeMessage = false;
          }
          this.renderFeaturePageData();
        });
    } else {
      this.renderFeaturePageData();
    }
  }

  setQueryParamIfFrom360() {
    if (this.dataService.buildCardData.build_card_type === 'studio_store') {
      return { cpe_user: 'true', redirection_url: this.appDataService.urlParameters.redirection_url };
    } else {
      this.appDataService.urlParameters.cpe_user = null;
      return { redirection_url: this.appDataService.urlParameters.redirection_url };
    }
  }

  public renderFeaturePageData() {
    const buildCardData = this.dataService.buildCardData;
    if (
      !buildCardData.new_care_flow &&
      (buildCardData.status === 'card_initialized' ||
        buildCardData.status === 'card_completed')
    ) {
      this.dataService.buildCardData.new_care_flow = true;
    }
    /*if (!buildCardData.subscription_flow && (buildCardData.status === 'card_initialized' || buildCardData.status === 'card_completed')) {
      this.dataService.buildCardData.subscription_flow = true;
    }*/
    if (this.appDataService.urlParameters.cpe_user || this.activatedRoute.snapshot.queryParamMap.get('featureRequest')) {
      sessionStorage.setItem('show_natasha_again', 'not_from_template_page');
      this.activatedRoute.params.subscribe(data => {
        if (this.appDataService.urlParameters.cpe_user) {
          this.appDataService.urlParameters.featureRequest = 'true';
        } else {
          this.appDataService.urlParameters.featureRequest = data.id;
        }
        if (this.isFeatureRequest && !this.dataService.totalSelectedFeatures) {
          this.shouldHideWelcomeMessage = false;
          this.dataService.changeFeatureRequestModel.features = [];
          this.getBuildCardDataForRequestedFeatures();
        } else {
          this.initializeFeatures();
          this.getFeatureFilters();
        }
      });
    } else {
      this.initializeFeatures();
      this.getFeatureFilters();
    }
    if (
      !this.dataService.showMeetNowOverloadingOnFeature &&
      !this.dataService.showMeetNowModel
    ) {
      setTimeout(() => {
        this.dataService.showExpertsButtonInHeader = false;
      }, 5000);
    }
  }

  getAdditionalFeatures() {
    let ChangeRequesObj: any;
    if (this.appDataService.urlParameters.changeRequest_id) {
      ChangeRequesObj = this.dataService.buildCardData.change_requests.find(
        (changeRequest) => {
          return (
            this.appDataService.urlParameters.changeRequest_id ==
            changeRequest.id
          );
        }
      );
    }

    if (!ChangeRequesObj) {
      ChangeRequesObj = this.dataService.buildCardData.change_requests.find(
        (changeRequest) => {
          return changeRequest.status == 'pending';
        }
      );
    }

    if (!ChangeRequesObj) {
      ChangeRequesObj = this.dataService.buildCardData.change_requests.find(
        (changeRequest) => {
          return changeRequest.status == 'draft';
        }
      );
    }

    if (ChangeRequesObj) {
      this.removeDuplicateFeature(ChangeRequesObj);
      this.appDataService.urlParameters.changeRequest_id = ChangeRequesObj.id;
      this.dataService['activeChangeRequesObj'] = ChangeRequesObj;
      if (this.dataService.isFromSummary) {
        this.dataService.buildCardData.change_requests =
          this.dataService.remainingRequestedFeatures;
      } else {
        this.dataService.buildCardData.change_requests =
          ChangeRequesObj.features;
        this.dataService.buildCardData.change_requests_status =
          ChangeRequesObj.status;
        this.dataService.isFromSummary = false;
      }
    } else {
      this.dataService.buildCardData.change_requests = [];
      this.dataService.isFromSummary = false;
    }
  }

  removeDuplicateFeature(ChangeRequesObj) {
    let approvedFeatures = [];
    this.dataService.buildCardData.change_requests.forEach((changeRequest) => {
      if (changeRequest.status === 'approved') {
        approvedFeatures = [...approvedFeatures, ...changeRequest.feature_ids];
      }
    });
    if (approvedFeatures.length) {
      approvedFeatures.forEach((approvedFeature) => {
        const index = ChangeRequesObj.features.findIndex((feature) => {
          return approvedFeature == feature.id;
        });
        const approvedFtr = this.dataService.buildCardData.progress['buildcardfeatures'].find(f => f.feature_id === parseInt(approvedFeature));
        const addedFtr = ChangeRequesObj.features.find(f => f.id === parseInt(approvedFeature));
        if (approvedFtr?.feature_count ? (approvedFtr?.feature_count === addedFtr?.feature_count) : (index != -1)) {
          ChangeRequesObj.features.splice(index, 1);
        }
      });
    }
  }

  getBuildCardDataForRequestedFeatures() {
    this.dataService.isIncompleteCardEditMode = true;
    this.activatedRoute.parent.params.subscribe((params) => {
      const uniq_code = params['id'];
      this.apiRequest
        .fetchSingleBuildCardData(
          uniq_code ? uniq_code : this.appDataService.urlParameters.uniq_code
        )
        .subscribe(
          async (data: any) => {
            const buildCardData = this.dataService.buildCardData;
            if (buildCardData.id) {
              if ((buildCardData.status === 'started') && (buildCardData.build_card_type === 'studio_pro')) {
                this.appDataService.urlParameters.featureRequest = null;
                this.router.navigate(['dashboard']);
              }
              this.phaseService.setUpInFullBuildCard();
              this.dataService.showHideLoader(false);
              if (!this.appDataService.urlParameters.cpe_user) {
                this.getAdditionalFeatures();
              } else if (this.appDataService.urlParameters.cpe_user) {
                const appFeatures = this.phaseService.selectedApps[0].features[
                  'data'
                ].map((ftr) => parseInt(ftr.id));
                this.dataService.buildCardData.progress.features.forEach(
                  (feature, index) => {
                    if (appFeatures.indexOf(feature.id) === -1) {
                      this.dataService.buildCardData.change_requests.push(
                        feature
                      );
                      this.phaseService.selectedFeatures.splice(index, 1);
                    }
                  }
                );
              }
              buildCardData.change_requests.forEach(
                (feature) => {
                  feature['disabled'] = false;
                  feature['selected'] = true;
                }
              );
              this.initializeFeatures();
              this.getFeatureFilters();
            }
          },
          (error) => {
            this.dataService.showHideLoader(false);
            if (
              this.commonService.isPlatformBrowser &&
              (error.status === 403 ||
                error.status === 401 ||
                error.status === 404)
            ) {
              this.dataService.invalidAccess = true;
              this.router.navigate(['invalidaccess']);
            }
          }
        );
    });
  }

  public setBreadCrumbForFeatures() {
    this.phaseService.getSelectedTemplatesFromUrl();

    if (this.phaseService.selectedApps.length > 0) {
      const appStep = this.phaseService.headerBreadCrumb.find(
        (step) => step.title === 'Apps'
      );
      if (appStep) {
        appStep.selected = true;
      }
      this.phaseService.showAppInBreadCrumb = true;
    } else {
      this.phaseService.showAppInBreadCrumb = false;
    }
  }

  public setScrollBarBundleConfig() {
    if (window.innerWidth <= 1199) {
      this.PERFECT_SCROLLBAR_BUNDLE_CONFIG =
        this.PERFECT_SCROLLBAR_CONFIG_HORIZONTAL;
    } else {
      this.PERFECT_SCROLLBAR_BUNDLE_CONFIG = this.PERFECT_SCROLLBAR_CONFIG;
    }
  }

  public initializeFeatures() {
    if (
      this.dataService.modifiedBuildCardData &&
      this.dataService.modifiedBuildCardData.care_type &&
      this.dataService.modifiedBuildCardData.care_type.add
    ) {
      this.phaseService.selectedHash.care_type =
        this.dataService.modifiedBuildCardData['care_type']['add'];
    }
    if (this.dataService.isUnpaidCard() && !this.dataService.buildCardData.subscription_flow) {
      this.phaseService.selectedHash.is_under_support = false;
    }
    if (!this.phaseService.isFeatureRequest && this.dataService.checkIfBuildCardisinvited() && this.dataService.user && !this.dataService.user.enterprise_lead && this.dataService.buildCardData && (this.dataService.buildCardData.enterprise_lead === 'true') && !this.dataService.isEditBuildCardAfterSecurityPaid()) {
      this.phaseService.selectedSpeed = this.dataService.homePageData.speed.find(speed => speed.title === 'Relaxed');
      this.dataService.homePageData.build_phases.forEach(phase => {
        phase.speed = this.phaseService.selectedSpeed;
      });
      this.phaseService.selectedHash.care_type = 'standard_care';
    }
    if (
      !this.phaseService.isFeatureRequest &&
      this.dataService.checkIfBuildCardisinvited() &&
      this.dataService.user &&
      this.dataService.user.enterprise_lead &&
      this.dataService.buildCardData &&
      this.dataService.buildCardData.enterprise_lead !== 'true' &&
      !this.dataService.isEditBuildCardAfterSecurityPaid() &&
      !this.phaseService.dontSetCareConfig &&
      !this.dataService.buildCardData.new_care_flow
    ) {
      this.phaseService.selectedSpeed =
        this.dataService.homePageData.speed.find(
          (speed) => speed.title === 'Standard'
        );
      this.dataService.homePageData.build_phases.forEach((phase) => {
        phase.speed = this.phaseService.selectedSpeed;
      });
      this.phaseService.selectedHash.care_type = 'enterprise_care_plus';
    }
    this.handleFetchFeatures();
    this.phaseService.selectedPlatforms.length > 0
      ? this.phaseService.setSelectedItems('selectedPlatforms')
      : this.preSelectPlatforms();
    this.phaseService.rapidPrototypePlatforms = Object.assign(
      [],
      [...this.dataService.homePageData.prototype_platforms]
    );
    // this.phaseService.selectedPlatformsForRapidPrototype.length > 0 ? this.phaseService.setSelectedItems('selectedPlatformsForRapidPrototype') : this.preSelectPlatforms();
    this.phaseService.selectedPlatformsForRapidPrototype = Object.assign(
      [],
      [...this.dataService.homePageData.prototype_platforms]
    );
    this.phaseService.checkAndSetDefaultPhases();
    if (!this.phaseService.isRentalBuildCard() && this.dataService.user && this.dataService.user.enterprise_lead) {
      const speedForEnt = this.phaseService.setSpeedForMultiplier();
      this.phaseService.selectedSpeed =
        this.dataService.homePageData.speed.find(
          (speed) => speed.id === speedForEnt
        );
    } else if (!this.phaseService.isRentalBuildCard() &&
      ((this.dataService.user && this.dataService.user.enterprise_lead) ||
      this.dataService.checkIfCardWithUsd()) && (!this.phaseService.selectedSpeed || (this.dataService.buildCardData.status === 'card_initialized'))
    ) {
      this.phaseService.selectedSpeed =
        this.dataService.homePageData.speed.find(
          (speed) => speed.title === 'Standard'
        );
    }
    this.setBreadCrumbs();
    if (this.featureOpenedInEditMode()) {
      this.phaseService.isBuildCardSetup = false;
      this.phaseService.inProgressFlow = false;
    }
    this.phaseService.checkForSignupPopup(this.analyticsSegment);
    this.handleTutorial();
    this.phaseService.updatePrice();
  }

  private handleFetchFeatures() {
    if (
      this.phaseService.buildCardEditMode &&
      this.phaseService.selectedFeatures.length === 0 &&
      this.phaseService.selectedHash.feature_ids.length === 0
    ) {
      this.phaseService.isEmptyBuildCard = true;
      this.removeAllSelections();
    }
    if (
      this.dataService.featureBundles.length === 0 ||
      this.appDataService.urlParameters.cpe_user
    ) {
      if (!this.phaseService.buildCardEditMode) {
        this.isShowPlatformOnLanding = true;
      }
      this.getAndRenderFeatures();
    } else {
      if (screen.width < 768) {
        this.focusedBundleIndex = 0;
        this.areBundlesLoading = false;
      }
      this.selectedFeatureBundle = this.getFocusedBundle();
      this.previewedFeature = this.getPreviewedFeature();
      this.updateShowMore();
      this.areBundlesLoading = false;
      if (this.isShowPlatformOnLanding) {
        setTimeout(() => {
          this.phaseService.showPlatformPopup = true;
        }, 1500);
      }
    }
    if (
      this.appDataService.urlParameters.red ||
      this.dataService.showMeetNowOverloading ||
      this.dataService.showMeetNowOverloadingOnFeature
    ) {
      this.closeTooltip();
    }
  }

  setUpCurrencyChangeSubscription() {
    this.currencyChangeSubscription = this.phaseService
      .getCurrencyUpdatedCallback()
      .subscribe((isCurrencyChanged) => {
        if (
          isCurrencyChanged &&
          isCurrencyChanged.is_currency_updated &&
          this.dataService.currentPage === 'features'
        ) {
          this.dataService.featureBundles = [];
          this.dataService.totalSelectedFeatures = 0;
          this.initializeFeatures();
          this.getFeatureFilters();
        }
      });
  }

  handleTutorial() {
    if (
      this.commonService.isPlatformBrowser
        ? localStorage.getItem('tutorial_seen') !== 'true'
        : true
    ) {
      this.tutorialSeen = false;
      this.phaseService.isFeatureTutorialActive = !this.isMobile;
      if (this.checkNavigationToPage()) {
        this.showOverlay = true;
        this.tooltipIndex = null;
      } else {
        this.tooltipIndex = 1;
      }
    } else {
      this.tutorialSeen = true;
      this.phaseService.isFeatureTutorialActive = false;
    }
  }

  public navigateTutorialIndividualUrl() {
    if (!this.checkNavigationToPage()) {
      this.showOverlay = true;
      this.tooltipIndex = null;
    } else {
      this.showOverlay = false;
      this.tooltipIndex = 0;
    }
  }

  public handleOverlay() {
    if (this.tooltipIndex === 1 && !this.checkNavigationToPage()) {
      this.navigateTutorialIndividualUrl();
    } else if (this.tooltipIndex === null && this.checkNavigationToPage()) {
      this.navigateTutorialIndividualUrl();
    } else {
      this.showTutorialTooltip();
    }
  }

  public beginTutorial() {
    if (!this.checkNavigationToPage()) {
      if (this.tooltipIndex === null) {
        this.tooltipIndex = 0;
      } else {
        this.showTutorialTooltip();
      }
      this.showOverlay = false;
    }
  }

  public updateShowMore() {
    this.previewedFeature && this.previewedFeature.description
      ? this.previewedFeature.description.length > 110
        ? (this.showMore = true)
        : (this.showMore = false)
      : (this.showMore = false);
  }

  public ngAfterViewInit() {
    this.phaseService.checkExitIntentTimer();
    this.phaseService.checkIdlestate();
    this.featureHolder = document.getElementById('featureHolder');
    this.fullWidth();
  }

  public openMobileFilter() {
    this.mobileFilterMode = true;
  }

  public applyMobileFilters() {
    this.mobileFilterMode = false;
    this.filterFeatures();
  }

  public hideFilterPopup() {
    this.showFilter = false;
  }

  public showHideRemoveAllPopup(isClosedClicked?) {
    if (this.dataService.isFromViewPrototype) {
      this.isPollingCancelled = true;
    }
    this.showRemoveAll = !this.showRemoveAll;
    this.mobileNoFeaturePopup = true;
    if (this.showRemoveAll) {
      this.mainwizardDot = true;
      this.rightToRemoveall = true;
      this.removeAllToRight = false;
      const nbox = document.querySelector('#wizardPopupAnimation');
      this.pulsateNatashaWizard(nbox);
    } else if (!this.showRemoveAll) {
      this.moveWizardDotFromRemoveAllPopupToNatasha();
    }
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      'remove_all_clicked',
      {
        user_id: this.dataService.user?.id,
      }
    );
    // this.trackRemoveAllFeaturesEvents(isClosedClicked);
  }

  public handleShowMore(isHide?) {
    this.isMore = !this.isMore;
    isHide ? (this.showMore = false) : (this.showMore = !this.showMore);
  }

  public changeFocusedBundle(bundle: BundleModel, index, event) {
    this.mobileFeatureGroup = false;
    if (window.innerWidth <= 1199) {
      this.bundleClick = true;
    }
    if (this.focusedBundleIndex === index || index === -1) {
      this.focusedBundleIndex = -1;
    } else {
      this.focusedBundleIndex = index;
    }
    /*if (this.focusedBundlePreviousIndex !== -1) {
      index = index > this.focusedBundlePreviousIndex ? index : index - 1;
      this.dataService.featureBundles.splice((this.focusedBundlePreviousIndex + 1), 0, this.dataService.featureBundles[0]);
      this.dataService.featureBundles.splice(0, 1);
    }
    if ((this.focusedBundleIndex === index) || (index === -1)) {
      this.focusedBundleIndex = -1;
      this.focusedBundlePreviousIndex = -1;
    } else {
      this.dataService.featureBundles.unshift(this.dataService.featureBundles[index]);
      this.dataService.featureBundles.splice(index + 1, 1);
      this.focusedBundlePreviousIndex = index;
      this.focusedBundleIndex = 0;
    }*/
    if (!bundle.features) {
      this.fetchBundleFeatures(bundle);
    } else if (this.isFeatureRequest) {
      // this.setDisabledAttrInFeatures(bundle.features);
    }

  }

  setDisabledAttrInFeatures(features, fromSearch?) {
    this.phaseService.setdisabledKeyInFeatures(features);
    if (this.dataService.buildCardData.change_requests) {
      const requestedFeaturesIDs =
        this.dataService.buildCardData.change_requests.map(
          (feature) => feature.id
        );
      features.map((feature) => {
        if (requestedFeaturesIDs.indexOf(feature.id) !== -1) {
          feature.selected = true;
          feature.disabled = false;
          if (!fromSearch) {
            this.phaseService.selectedFeatures.push(feature);
          }
        }
        return feature;
      });
    }
  }

  public removeSelectedFeature(event, feature, index) {
    event.stopPropagation();
    if (this.dataService.isFromViewPrototype) {
      this.isPollingCancelled = true;
    }
    this.removingFeatureIndex = index;
    this.showRemoveAnimation = true;
    if (feature.user_requested) {
      this.phaseService.requestedFeatureList =
        this.phaseService.requestedFeatureList.filter(
          (item) => item.id !== feature.id
        );
    }
    setTimeout(() => {
      this.showRemoveAnimation = false;
      this.changeFocusedFeature(feature);
    }, 100);
    this.mobileNoFeaturePopup = true;
  }

  public showFeaturePreviewGalleryTooltip() {
    if (!this.featurePreview) {
      if (this.commonService.isPlatformBrowser) {
        localStorage.setItem('tutorial_seen', 'true');
      }
      this.tooltipIndex = 5;
      this.featurePreview = true;
      this.tutorialSeen = false;
      this.phaseService.isFeatureTutorialActive = !this.isMobile;
    }
  }

  updateMandatoryFeatures(feature) {
    const mandatoryFeatureIds = this.phaseService.mandatoryFeatures.map(
      (item) => item.id
    );
    if (mandatoryFeatureIds.indexOf(feature.id) !== -1) {
      this.phaseService.mandatoryFeatures.splice(
        this.phaseService.mandatoryFeatures.findIndex(
          (i) => i.id === feature.id
        ),
        1
      );
    } else {
      for (const app of this.phaseService.selectedApps) {
        let features: any;
        if (app && app.features) {
          if (
            this.isFeatureRequest ||
            this.phaseService.buildCardEditMode ||
            this.dataService.isIncompleteCardEditMode ||
            app.features['data']
          ) {
            features = app.features['data'];
          } else {
            features = app.features;
          }
        }
        if (features) {
          features.filter((featur) => {
            const featureSet =
              this.isFeatureRequest ||
              this.phaseService.buildCardEditMode ||
              this.dataService.isIncompleteCardEditMode
                ? featur.attributes
                : featur;
            if (featureSet.is_mvp_feature && featureSet.id === feature.id) {
              featureSet.selected = true;
              this.phaseService.mandatoryFeatures.push(featureSet);
              mandatoryFeatureIds.push(featureSet.id);
            }
          });
        }
      }
      if (
        !this.phaseService.checkIfOnlyRapidPrototypeSelected() &&
        !this.dataService.isEditBuildCardAfterSecurityPaid()
      ) {
        if (this.phaseService.mandatoryFeatures.length > 0) {
          this.dataService.homePageData.build_phases.filter((phase) => {
            if (phase.title === 'MVP') {
              phase.selected = true;
            }
          });
        }
      }
    }
  }

  checkSelectedFeatureCount(bundleFeatureSToSelect = 0) {
    let featureids = [];
    this.dataService?.buildCardData?.progress?.apps.forEach((app) => {
      featureids = featureids.concat(app.feature_ids);
    });
    const uniqueFeatureSet = new Set(featureids);
    const AppsFeatureCount = uniqueFeatureSet.size;

    const selectedFeatureCount =
      this.phaseService.selectedFeatures.length + bundleFeatureSToSelect;
    if (AppsFeatureCount < 50 && selectedFeatureCount >= 50) {
      this.updatePupupStatus();
    } else if (
      AppsFeatureCount >= 50 &&
      selectedFeatureCount >=
        AppsFeatureCount + Math.floor((AppsFeatureCount * 10) / 100)
    ) {
      this.updatePupupStatus();
    }
  }

  updatePupupStatus() {
    this.isShowMaxFeaturePopUp = true;
  }

  hideFeatureCountPopup(eventType) {
    this.isShowMaxFeaturePopUp = false;
    if (
      this.bundleFeaturesToBeSelectedAfterUserConfirmation?.features?.length &&
      (eventType === false)
    ) {
      this.changeBundleFeatureSelection(
        this.bundleFeaturesToBeSelectedAfterUserConfirmation,
        true
      );
    }
  }

  getFeaturePopupDataInSessionStorage() {
    const buildCradId = this.activatedRoute.snapshot.paramMap.get('id');
    return new Set(
      JSON.parse(window.sessionStorage.getItem('isShowMaxFeaturePopUp'))
    ).has(buildCradId);
  }

  public changeFocusedFeature(
    feature?: FeatureModel,
    isLockFeatureRemove?,
    event?,
    shouldSelectAllFeaturesInBundle?,
    isLastFeatureInBundle?
  ) {
    if (event) {
      event.stopPropagation();
    }
    if (
      !feature?.selected &&
      !this.getFeaturePopupDataInSessionStorage() &&
      this.dataService.user?.roles !== 'CPE'
    ) {
      this.checkSelectedFeatureCount();
      if (this.isShowMaxFeaturePopUp) {
        return;
      }
    }
    if (!feature) {
      feature = this.lockFeatureToRemove;
    }
    if (
      this.isFeatureRequest &&
      feature.selected &&
      this.phaseService.Feedback_Answers
    ) {
      delete this.phaseService.Feedback_Answers[feature.id];
    }
    const checkIfShowPopup =
      this.commonService.isPlatformBrowser &&
      !localStorage.getItem('show_remove_lock_feature_popup');
    if (
      feature.is_mvp_feature &&
      !this.showModalForRemovingLockFeature &&
      feature.selected &&
      checkIfShowPopup
    ) {
      this.hideLockFeatureModal();
      this.lockFeatureToRemove = feature;
      this.showModalForRemovingLockFeature = true;
      this.mainwizardDot = true;
      this.rightToLockAnimation = true;
      this.isScrollCartItem = false;
      const nbox = document.querySelector('#wizardPopupAnimation');
      this.pulsateNatashaWizard(nbox);
      this.cdRef.detectChanges();
      return;
    }
    if (feature.selected) {
      feature.feature_count = feature.repeatable_count;
    }
    if (this.dataService.isFromViewPrototype) {
      this.isPollingCancelled = true;
    }
    this.updateMandatoryFeatures(feature);
    this.showFeaturePreviewGalleryTooltip();
    if (!feature.selected) {
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        'feature_added',
        {
          user_id: this.dataService.user?.id,
          features_selected: feature.title,
        }
      );
    }

    const selectedFeatures = this.getSelectedFeatures();
    const index = selectedFeatures.findIndex((featureItem) => {
      return feature.id === featureItem.id;
    });

    this.toggleFeature(
      feature,
      shouldSelectAllFeaturesInBundle,
      isLastFeatureInBundle
    );
    const featureBundle = this.dataService.featureBundles.find((bundle) => {
      return bundle.id === feature.feature_bundle_id;
    });

    if (
      featureBundle &&
      (!this.isFeatureRequest || this.appDataService.urlParameters.cpe_user)
    ) {
      if (feature.selected) {
        if (featureBundle.selected_feature_count < featureBundle.total_features) {
          featureBundle.selected_feature_count++;
        }
        this.showFeatureAddedToolTip = true;
        setTimeout(() => {
          this.showFeatureAddedToolTip = false;
          // this.cdRef.detectChanges();
        }, 2000);
      } else {
        if (featureBundle.selected_feature_count > 0) {
          featureBundle.selected_feature_count--;
        }
        feature.feature_count = feature.repeatable_count;
        this.showFeatureRemovedTooltip = true;
        setTimeout(() => {
          this.showFeatureRemovedTooltip = false;
          // this.cdRef.detectChanges();
        }, 2000);
        this.phaseService.trackEventForObject(
          this.analyticsSegment,
          'feature_removed',
          {
            user_id: this.dataService.user?.id,
            feature_bin_selected: feature.title,
          }
        );
      }
    }

    if (feature.selected) {
      this.focusedFeatureIndex = 0;
      this.minimizeGallery = false;
      let max = 0;
      this.getSelectedFeatures().forEach((v) => {
        if (v['updated_at'] > max) {
          max = v['updated_at'];
        }
      });
      feature['updated_at'] = max + 1;
      // this.componentScrollElement.toArray().forEach((element, index) => {
      //   if (element.nativeElement.classList.contains('horizontalSlider') === true) {
      //     this.componentScroll.toArray()[index].directiveRef.scrollToLeft();
      //   }
      // });
    } else if (
      this.focusedFeatureIndex === selectedFeatures.length ||
      this.focusedFeatureIndex > selectedFeatures.length - index
    ) {
      this.focusedFeatureIndex--;
    }
    if (this.previewedFeature && this.previewedFeature.id === feature.id) {
      this.focusedFeatureIndex = 0;
    }
    // this.componentScrollElement.toArray().forEach((element, index) => {
    //   if (element.nativeElement.classList.contains('horizontalSlider') === true) {
    //     this.componentScroll.toArray()[index].directiveRef.update();
    //   }
    // });
    this.inHoverState = false;
    this.previewedFeature = this.getPreviewedFeature();
    this.updateShowMore();
    this.selectedFeatureBundle = this.getBundleOfSelectedFeature();

    this.toggleClass = !this.toggleClass;
    this.animateClass = !this.animateClass;
    // this.minimizeGallery = false;
    this.showRemovedAllText();
    this.updateScrollOnGallery();
    if (
      this.isFeatureRequest &&
      this.dataService.totalSelectedFeatures ===
        this.phaseService.selectedFeatures.length
    ) {
      this.focusedFeatureIndex = -1;
    }
    this.showModalForRemovingLockFeature = false;

    if (isLockFeatureRemove) {
      this.rightToLockAnimation = false;
      this.lockToRightAnimation = true;
      setTimeout(() => {
        this.mainwizardDot = false;
        this.lockToRightAnimation = false;
        const text = 'Yes, remove it!';
        this.recordUserInput(text);
        if (!localStorage.getItem('add_feature_first_time')) {
          localStorage.setItem('add_feature_first_time', 'false');
          this.addRemoveFeatureFirstTime(feature);
        } else {
          this.recordNatashaFeatureAdditionRemovalMessage(feature);
        }
        // this.cdRef.detectChanges();
      }, 400);
    }

    // if (this.dataService.noMorePricingModal && !localStorage.getItem('add_feature_first_time')) {
    //   localStorage.setItem('add_feature_first_time', 'false');
    //   let text1 = 'Features have a fixed cost and a dynamic customisation cost.';
    //   let obj = { text1: text1, natashaDynamicPricingMessageStatus: true, showLoader: true, createdAt: this.getTime(), timeStamp: this.phaseService.getCurrentTimestamp()};
    //   this.addObjectToNatashaChat(obj);
    // } else if (!this.dataService.noMorePricingModal) {
    //   this.dataService.showDynamicPriceModal = true;
    // }

    // if (this.phaseService.selectedFeatures.length > 1) {
    //   this.dataService.showDynamicPriceModal = true;
    // }
    // if (this.phaseService.selectedFeatures.length > 0 && !this.animateAllRecommendedFeatures) {
    // this.fetchRecommendedFeatures(feature);
    // }
    // else if (this.phaseService.selectedFeatures.length > 0 && !this.animateAllRecommendedFeatures && !this.dataService.showFeatureRecommendationsConfirmModalSetting) {
    //   this.scrollNatashaToBottom();
    // }
    if (!this.lockToRightAnimation) {
      if (!localStorage.getItem('add_feature_first_time')) {
        localStorage.setItem('add_feature_first_time', 'false');
        this.addRemoveFeatureFirstTime(feature);
      } else {
        this.recordNatashaFeatureAdditionRemovalMessage(
          feature,
          shouldSelectAllFeaturesInBundle,
          isLastFeatureInBundle
        );
      }
    }

    // this.cdRef.detectChanges();
    this.mobileNoFeaturePopup = true;
    this.previewSelect = false;

    this.toggleFeatureInAllBundles(feature);

  }

  public showRemovedAllText() {
    if (this.getSelectedFeatures().length === 0) {
      this.showAllRemovedText = true;
      this.previewedFeature = null;
      this.selectedFeatureBundle = null;
      this.showPreviewFullScreen = false;
      setTimeout(() => {
        this.showAllRemovedText = false;
        this.cdRef.detectChanges();
      }, 2000);
    }
  }

  public changeFocusedSelectedFeature(index) {
    this.focusedFeatureIndex = index;
    this.inHoverState = false;
    this.selectedFeatureBundle = this.getBundleOfSelectedFeature();
    this.toggleClass = !this.toggleClass;
    this.previewedFeature = this.getPreviewedFeature();
    this.updateShowMore();
  }

  public getBundleOfSelectedFeature() {
    const focusedFeature = this.getFocusedFeature();
    if (focusedFeature) {
      return this.findBundleForFeature(focusedFeature.feature_bundle_id);
    }
    return null;
  }

  public showPreviewImage() {
    this.imagePreviewLoaded = true;
  }

  public getFocusedBundle() {
    return this.getFeatureBundles()[this.focusedBundleIndex];
  }

  public getFocusedFeature() {
    const features = this.sortFeaturePipe.transform(
      this.getSelectedFeatures(),
      this.sortWith
    );
    return features[this.focusedFeatureIndex];
  }

  public getHoveredFeature(isRecommendedFeaturePreviewed?, chat?) {
    if (isRecommendedFeaturePreviewed) {
      return chat.recommendedFeatures[this.hoveredFeatureIndex];
    }
    if (this.searchView) {
      return this.dataService.featureList[this.hoveredFeatureIndex];
    } else if (this.filterApplied) {
      return this.dataService.filteredFeatures[this.hoveredFeatureIndex];
    } else {
      return this.getFocusedBundle().features[this.hoveredFeatureIndex];
    }
  }

  public isPreviewAvailable() {
    return (
      this.previewedFeature &&
      !!this.previewedFeature.feature_screenshots[this.selectedPreviewPlatform]
    );
  }

  public getPreviewedFeature(isRecommendedFeaturePreviewed?, chat?) {
    if (this.inHoverState) {
      if (isRecommendedFeaturePreviewed) {
        return this.getHoveredFeature(isRecommendedFeaturePreviewed, chat);
      }
      return this.getHoveredFeature();
    } else {
      return this.getFocusedFeature();
    }
  }

  checkIfPreviewed(feature) {
    if (this.previewedFeature && feature.id === this.previewedFeature.id) {
      return true;
    }
  }

  public getPreviewOfFeature(
    index,
    isRecommendedFeature?,
    natashaChatObject?,
    isCustomFeature?
  ) {
    if (isCustomFeature) {
      return './../../../assets/images/customIcon.png';
    }
    if (
      !isRecommendedFeature &&
      this.phaseService.selectedFeatures &&
      this.getSelectedFeatures()[this.getSelectedFeatures().length - 1 - index]
        .user_requested
    ) {
      return './../../../assets/images/customIcon.png';
    }
    let preview = '';
    if (isRecommendedFeature) {
      if (
        natashaChatObject.recommendedFeatures &&
        natashaChatObject.recommendedFeatures.length > 0 &&
        natashaChatObject.recommendedFeatures[index] !== undefined
      ) {
        preview =
          natashaChatObject.recommendedFeatures[index].feature_screenshots[
            this.selectedPreviewPlatform
          ];
      }
    } else {
      const sFeatures = this.sortFeaturePipe.transform(
        this.getSelectedFeatures(),
        this.sortWith
      );
      if (sFeatures && sFeatures.length - 1 - index >= 0) {
        preview =
          sFeatures[index].feature_screenshots[this.selectedPreviewPlatform];
      }
    }

    if (!preview) {
      if (this.selectedPreviewPlatform === 'android') {
        preview = './../../../assets/images/nomobile.png';
      } else if (this.selectedPreviewPlatform === 'web') {
        preview = './../../../assets/images/noweb.png';
      }
    }
    return preview;
  }

  public getSelectedPreview() {
    return (
      this.previewedFeature &&
      this.previewedFeature.feature_screenshots[this.selectedPreviewPlatform]
    );
  }

  public isNewImage() {
    return this.getSelectedPreview().includes('/builder-now-');
  }

  public toggleLayoutView(platform) {
    if (this.selectedPreviewPlatform !== platform) {
      this.imagePreviewLoaded = false;
      this.selectedPreviewPlatform = platform;

      const selectedPlatform = platform === 'web' ? 'Desktop' : 'Mobile';
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        'platform_mode_selected',
        {
          user_id: this.dataService.user?.id,
          platform_selected: selectedPlatform,
        }
      );
    }
  }

  public toggleFilterPopup() {
    this.showFilter = !this.showFilter;
    if (this.showFilter) {
      this.clickedJustNow = true;
      this.clickedJustNowDuration = true;
    }
    this.filterTrackEvent = {
      app_filter: false,
      cost_filter: false,
      functionality_filter: false,
      duration_filter: false,
    };
    if (this.showFilter) {
    }
  }

  checkIfFilterChnaged() {
    const filterParms = this.filterParamsBundleCopy,
      newTempIds = [];
    if (filterParms) {
      if (
        this.filterParamsBundle.feature_tag_ids.length ===
        filterParms.feature_tag_ids.length
      ) {
        this.filterParamsBundle.feature_tag_ids.forEach((el) => {
          if (!filterParms.feature_tag_ids.includes(el)) {
            newTempIds.push(el);
          }
        });
      }
      if (
        filterParms.feature_min_weeks !== this.rangeDuration[0] ||
        filterParms.feature_max_weeks !== this.rangeDuration[1] ||
        filterParms.feature_min_price !== this.rangePrice[0] ||
        filterParms.feature_max_price !== this.rangePrice[1] ||
        this.filterParamsBundle.feature_tag_ids.length !==
          filterParms.feature_tag_ids.length ||
        newTempIds.length > 0
      ) {
        return false;
      } else {
        return true;
      }
    } else if (this.getAppliedFiltersCount() === 0) {
      return true;
    }
  }

  public showNavigationTip() {
    if (
      !this.showNavTutorial && this.commonService.isPlatformBrowser
        ? !localStorage.getItem('nav_tutorial_shown')
        : true
    ) {
      this.showNavTutorial = true;
      setTimeout(() => {
        this.showNavTutorial = false;
        if (this.commonService.isPlatformBrowser) {
          localStorage.setItem('nav_tutorial_shown', 'true');
        }
      }, 2000);
    }
  }

  public handlePrevButton() {
    this.showNavigationTip();
    this.imagePreviewLoaded = false;
    if (this.focusedFeatureIndex === 0) {
      this.focusedFeatureIndex = this.getSelectedFeatures().length - 1;
    } else {
      this.focusedFeatureIndex--;
    }
    this.previewedFeature = this.getPreviewedFeature();
    this.updateShowMore();
    if (this.showPreviewFullScreen) {
      this.selectedFeatureBundle = this.getBundleOfSelectedFeature();
    }
    this.toggleClass = !this.toggleClass;
    this.componentScrollElement.toArray().forEach((element, index) => {
      if (
        element.nativeElement.classList.contains('horizontalSlider') === true
      ) {
        if (
          this.componentScroll.toArray()[index].directiveRef.position(true).x -
            document.getElementById(String(this.previewedFeature.id))
              .offsetLeft >
            50 ||
          this.componentScroll.toArray()[index].directiveRef.position(true).x <=
            25
        ) {
          this.componentScroll
            .toArray()
            [index].directiveRef.scrollTo(
              document.getElementById(String(this.previewedFeature.id))
                .offsetLeft,
              0
            );
        }
      }
    });
  }

  public handleNextButton() {
    this.showNavigationTip();
    this.imagePreviewLoaded = false;
    if (this.focusedFeatureIndex === this.getSelectedFeatures().length - 1) {
      this.focusedFeatureIndex = 0;
    } else {
      this.focusedFeatureIndex++;
    }
    this.previewedFeature = this.getPreviewedFeature();
    this.updateShowMore();
    if (this.showPreviewFullScreen) {
      this.selectedFeatureBundle = this.getBundleOfSelectedFeature();
    }
    this.toggleClass = !this.toggleClass;
    if (this.previewedFeature.id) {
      this.componentScrollElement.toArray().forEach((element, index) => {
        if (
          element.nativeElement.classList.contains('horizontalSlider') === true
        ) {
          if (
            document.getElementById(String(this.previewedFeature.id))
              .offsetLeft > 350 ||
            document.getElementById(String(this.previewedFeature.id))
              .offsetLeft < 50
          ) {
            this.componentScroll
              .toArray()
              [index].directiveRef.scrollTo(
                document.getElementById(String(this.previewedFeature.id))
                  .offsetLeft - 10,
                0
              );
          }
        }
      });
    }
  }

  public toggleShowPreviewScreen() {
    this.showPreviewFullScreen = !this.showPreviewFullScreen;
    if (this.showPreviewFullScreen) {
      this.selectedFeatureBundle = this.getBundleOfSelectedFeature();
    }
    if (this.showPreviewFullScreen) {
    }
  }

  public checkIfAllFeatureSelected(bundle: BundleModel) {
    let allSelected = false;
    if (bundle && bundle.features) {
      for (const feature of bundle.features) {
        if (!feature.selected) {
          allSelected = false;
          break;
        } else {
          allSelected = true;
        }
      }
    }
    return allSelected;
  }

  public goBackFromSearch() {
    this.resetSearch();
    this.filterFeatures();
  }

  public applyTagFilter(tag) {
    // this.dataService.featureBundles = [];
    // this.phaseService.selectedBundles = [];
    tag.selected = !tag.selected;
    this.phaseService.toggleArray(
      this.filterParamsBundle.feature_tag_ids,
      tag.id
    );
    if (!this.mobileFilterMode) {
      if (
        this.filterParamsBundle.feature_tag_ids.length > 0 &&
        this.tagFilterApplied === 0
      ) {
        this.tagFilterApplied = 1;
      } else if (this.filterParamsBundle.feature_tag_ids.length === 0) {
        this.tagFilterApplied = 0;
      }

      if (
        !this.filterTrackEvent.functionality_filter &&
        this.filterParamsBundle.feature_tag_ids.length > 0
      ) {
        this.phaseService.trackEventForObject(
          this.analyticsSegment,
          'features_filtered',
          {
            user_id: this.dataService.user?.id,
            filter_applied: 'Functionality filter',
          }
        );
        this.filterTrackEvent.functionality_filter = true;
      }

      // this.filterFeatures();
    }
  }

  public applyTemplateFilter(template) {
    template.selected = !template.selected;
    this.phaseService.toggleArray(
      this.filterParamsBundle.feature_template_ids,
      template._id
    );
    if (!this.mobileFilterMode) {
      if (
        this.filterParamsBundle.feature_template_ids.length > 0 &&
        this.templateFilterApplied === 0
      ) {
        this.templateFilterApplied = 1;
      } else if (this.filterParamsBundle.feature_template_ids.length === 0) {
        this.templateFilterApplied = 0;
      }

      if (
        !this.filterTrackEvent.app_filter &&
        this.filterParamsBundle.feature_template_ids.length > 0
      ) {
        this.phaseService.trackEventForObject(
          this.analyticsSegment,
          'features_filtered',
          {
            user_id: this.dataService.user?.id,
            filter_applied: 'App filter',
          }
        );
        this.filterTrackEvent.app_filter = true;
      }
      // this.filterFeatures();
    }
  }

  public getAppliedFiltersCount() {
    if (this.checkIfPriceRangeFull()) {
      this.priceFilterApplied = 0;
    }
    if (this.checkIfDurationRangeFull()) {
      this.durationFilterApplied = 0;
    }
    return (
      this.priceFilterApplied +
      this.durationFilterApplied +
      this.tagFilterApplied +
      this.templateFilterApplied
    );
  }

  public resetFilters() {
    this.filterParamsBundleCopy = null;
    this.priceFilterApplied = 0;
    this.durationFilterApplied = 0;
    this.tagFilterApplied = 0;
    this.templateFilterApplied = 0;
    this.filterReset = true;
    this.filterParamsBundle.feature_min_price =
      this.featureFilter.bundle_details.min_global_price;
    this.filterParamsBundle.feature_max_price =
      this.featureFilter.bundle_details.max_global_price;
    this.filterParamsBundle.feature_max_weeks =
      this.featureFilter.bundle_details.min_global_weeks;
    this.filterParamsBundle.feature_max_weeks =
      this.featureFilter.bundle_details.max_global_weeks;
    this.optionsPrice = {
      floor: this.featureFilter.bundle_details.min_global_price,
      ceil: this.featureFilter.bundle_details.max_global_price,
      noSwitching: true,
    };
    this.rangePrice = [
      this.featureFilter.bundle_details.min_global_price,
      this.featureFilter.bundle_details.max_global_price,
    ];
    this.optionsDuration = {
      floor: this.featureFilter.bundle_details.min_global_weeks,
      ceil: this.featureFilter.bundle_details.max_global_weeks,
      noSwitching: true,
    };
    this.rangeDuration = [
      this.featureFilter.bundle_details.min_global_weeks,
      this.featureFilter.bundle_details.max_global_weeks,
    ];
    this.filterParamsBundle.feature_tag_ids = [];
    this.filterParamsBundle.feature_template_ids = [];
    this.featureFilter.tags.forEach((featureTag) => {
      featureTag.selected = false;
    });
    if (this.filterApplied) {
      this.dataService.totalSelectedFeatures = 0;
      this.getAndRenderFeatures();
      this.updateFeaturesInPhases();
      this.phaseService.updatePrice();
    }
    this.showFilter = false;
    this.filterApplied = false;
    this.isBuildCardEditMode();
    setTimeout(() => {
      this.filterReset = false;
    }, 100);
  }

  public onPriceChange(changeContext: ChangeContext) {
    if (!this.clickedJustNow) {
      if (!this.mobileFilterMode && !this.filterReset) {
        if (this.priceFilterApplied === 0) {
          this.priceFilterApplied = 1;
        } else if (this.checkIfPriceRangeFull()) {
          this.priceFilterApplied = 0;
        }
        if (!this.filterTrackEvent.cost_filter) {
          this.phaseService.trackEventForObject(
            this.analyticsSegment,
            'features_filtered',
            {
              user_id: this.dataService.user?.id,
              filter_applied: 'Cost filter',
            }
          );
          this.filterTrackEvent.cost_filter = true;
        }
        if (changeContext) {
          this.rangePrice = [changeContext.value, changeContext.highValue];
          // this.filterFeatures();
        }
      }
    } else {
      this.clickedJustNow = false;
    }
  }

  checkIfPriceRangeFull() {
    if (
      this.featureFilter.bundle_details.min_global_price ===
        this.rangePrice[0] &&
      this.featureFilter.bundle_details.max_global_price === this.rangePrice[1]
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkIfDurationRangeFull() {
    if (
      this.featureFilter.bundle_details.min_global_weeks ===
        this.rangeDuration[0] &&
      this.featureFilter.bundle_details.max_global_weeks ===
        this.rangeDuration[1]
    ) {
      return true;
    } else {
      return false;
    }
  }

  public ngOnDestroy() {
    this.phaseService.isFromDeliveryPage = false;
    this.appDataService.urlParameters.buildcard = '';
    if (this.featureFilterSubscription) {
      this.featureFilterSubscription.unsubscribe();
    }

    if (this.viewPrototypeSubscription) {
      this.viewPrototypeSubscription.unsubscribe();
    }

    if (this.bundlesApiSubscription) {
      this.bundlesApiSubscription.unsubscribe();
    }

    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.currencyChangeSubscription) {
      this.currencyChangeSubscription.unsubscribe();
    }
    this.dataService.shareUrlData = null;
    this.phaseService.showBottomBar = false;
    this.isPollingCancelled = true;
    this.dataService.showExpertsButtonInHeader = false;
    this.dataService.showMeetNowModel = false;
    this.dataService.openMeetNowFromAppDetail = false;
    this.phaseService.isClearCustomFeatureModalValues = true;
    this.userIdle.stopWatching();
    clearTimeout(this.userIdleTimer);
  }

  public onDurationChange(changeContext: ChangeContext) {
    if (!this.clickedJustNowDuration) {
      if (!this.mobileFilterMode && !this.filterReset) {
        if (this.durationFilterApplied === 0) {
          this.durationFilterApplied = 1;
        } else if (this.checkIfDurationRangeFull()) {
          this.durationFilterApplied = 0;
        }
        if (!this.filterTrackEvent.duration_filter) {
          this.phaseService.trackEventForObject(
            this.analyticsSegment,
            'features_filtered',
            {
              user_id: this.dataService.user?.id,
              filter_applied: 'Duration filter',
            }
          );
          this.filterTrackEvent.duration_filter = true;
        }
        if (changeContext) {
          this.rangeDuration = [changeContext.value, changeContext.highValue];
          // this.filterFeatures();
        }
      }
    } else {
      this.clickedJustNowDuration = false;
    }
  }

  public filterFeatures() {
    this.clearSearch();
    this.noFilteredDataFound = false;
    if (this.filterSubscription != null) {
      this.filterSubscription.unsubscribe();
    }
    this.filterReset = false;
    if (this.currentView === 0) {
      this.filterParamsBundle.feature_min_price = this.rangePrice[0];
      this.filterParamsBundle.feature_max_price = this.rangePrice[1];
      this.filterParamsBundle.feature_min_weeks = this.rangeDuration[0];
      this.filterParamsBundle.feature_max_weeks = this.rangeDuration[1];
    } else {
      this.filterParamsFeatures.feature_min_price = this.rangePrice[0];
      this.filterParamsFeatures.feature_max_price = this.rangePrice[1];
      this.filterParamsFeatures.feature_min_weeks = this.rangeDuration[0];
      this.filterParamsFeatures.feature_max_weeks = this.rangeDuration[1];
    }

    if (
      this.dataService.homePageData.promotion &&
      this.dataService.homePageData.promotion.features
    ) {
      this.phaseService.selectedFeatures.push(
        ...this.dataService.homePageData.promotion.features
      );
      for (const feature of this.phaseService.selectedFeatures) {
        this.phaseService.selectedHash.feature_ids.push(feature.id);
      }
    }
    this.areBundleFeaturesLoading = true;
    this.filterSubscription = this.apiRequest
      .fetchFeatures(
        this.phaseService.selectedHash.application_ids,
        this.phaseService.selectedHash.feature_ids,
        this.currentView === 0
          ? this.filterParamsBundle
          : this.filterParamsFeatures,
        this.filterParamsBundle.feature_template_ids
      )
      .subscribe((bundles) => {
        this.dataService.featureList = [];
        this.filterParamsBundleCopy = JSON.parse(
          JSON.stringify(this.filterParamsBundle)
        );
        this.backFromSearch = false;
        this.phaseService.selectedBundles =
          this.phaseService.fetchSelectedBundles();
        if (this.isFeatureRequest) {
          this.dataService.bundlesFeatures = JSON.parse(
            JSON.stringify(this.dataService.filteredFeatures)
          );
          this.setDisabledAttrInFeatures(
            this.dataService.filteredFeatures,
            true
          );
          if (this.dataService.additionalFeatures) {
            const additionalFeatures = this.dataService.additionalFeatures.map(
              (feature) => feature.id
            );
            this.dataService.filteredFeatures.forEach((feature) => {
              if (additionalFeatures.indexOf(feature.id) !== -1) {
                feature.selected = true;
              }
              return feature;
            });
          }
        }
        if (this.dataService.featureBundles.length === 0) {
          this.noFilteredDataFound = true;
        }
        this.phaseService.setDurationPriceInSelectedFeatures(
          this.dataService.filteredFeatures
        );
        this.areBundleFeaturesLoading = false;
        this.showFilter = false;
        this.filterApplied = true;
        this.cdRef.detectChanges();
      });
    this.updateFeaturesInPhases();
    this.phaseService.updatePrice();
    this.isBuildCardEditMode();
  }

  public searchFilter(event?) {
    if (event && event.keyCode !== 13) {
      return;
    }
    this.resetFilters();
    if (this.searchSubscription != null) {
      this.searchSubscription.unsubscribe();
    }
    if (!this.searchQuery) {
      this.updateSearchScrollTop();
      this.clearSearch();
    } else if (this.searchQuery) {
      this.searchInput.nativeElement.blur();
      this.areBundleFeaturesLoading = true;
      const selectedFeatures = this.isFeatureRequest
        ? this.phaseService.selectedHash.feature_ids
        : this.getSelectedFeatures();
      this.searchSubscription = this.apiRequest
        .searchFeatures(this.searchQuery, this.phaseService.selectedHash.application_ids, selectedFeatures)
        .pipe(finalize(() => {}))
        .subscribe((features) => {
          this.isRecentSearchVisile = false;
          if (this.dataService.featureList.length > 0) {
            this.phaseService.trackEventForObject(
              this.analyticsSegment,
              'feature_searched',
              {
                user_id: this.dataService.user?.id,
                search_term: this.searchQuery,
              }
            );
          } else {
          }
          this.searchView = true;
          this.updateSearchScrollTop();
          if (this.isFeatureRequest) {
            this.dataService.bundlesFeatures = JSON.parse(
              JSON.stringify(this.dataService.featureList)
            );
            this.setDisabledAttrInFeatures(this.dataService.featureList, true);
            if (this.dataService.additionalFeatures) {
              const additionalFeatures =
                this.dataService.additionalFeatures.map(
                  (feature) => feature.id
                );
              this.dataService.featureList.forEach((feature) => {
                if (additionalFeatures.indexOf(feature.id) !== -1) {
                  feature.selected = true;
                }
                return feature;
              });
            }
          }
          this.setFeaturesCount(this.dataService.featureList, true);
          this.phaseService.setDurationPriceInSelectedFeatures(
            this.dataService.featureList
          );
          this.areBundleFeaturesLoading = false;
          // this.mobileSearch();
          this.cdRef.detectChanges();
        });
    }
  }

  public updateSearchScrollTop() {
    this.componentScrollElement.toArray().forEach((element, index) => {
      if (
        element.nativeElement.classList.contains('search-scrollbar') === true
      ) {
        this.componentScroll.toArray()[index].directiveRef.scrollToTop();
      }
    });
  }

  public clearSearch(resetFilters?) {
    if (resetFilters === 'ClearFilters') {
      this.resetFilters();
    } else {
      this.hidetoolBar = false;
      this.searchQuery = '';
      this.searchView = false;
      this.backFromSearch = true;
      this.updateSearchScrollTop();
    }
  }

  public toggleSearchInput() {
    this.showQuery = !this.showQuery;
    if (!this.showQuery) {
      this.searchQuery = '';
    }
  }

  public refreshApp() {
    this.phaseService.reset();
    this.router.navigate([this.dataService.firstPage]);
  }

  public preSelectPlatforms() {
    this.phaseService.selectedPlatforms = [];
    this.phaseService.selectedHash.platform_ids = [];
    if (this.dataService.homePageData.platforms) {
      const platforms = this.dataService.homePageData.platforms;
      let defaultPlatform;
      for (const platform of platforms) {
        platform.selected = false;
        for (const app of this.phaseService.selectedApps) {
          for (const id of app.platform_ids) {
            if (id === platform.id) {
              platform.selected = true;
              defaultPlatform = platform;
              break;
            }
          }
        }
        if (platform.selected) {
          break;
        }
      }
      defaultPlatform = defaultPlatform ? defaultPlatform : platforms[0];
      this.phaseService.toggleSelectedPlatform(defaultPlatform);
      this.phaseService.toggleSelectedPlatformId(defaultPlatform.id);
    }
    // if (this.phaseService.selectedPlatformsForRapidPrototype && this.phaseService.selectedPlatformsForRapidPrototype.length === 0) {
    //   this.phaseService.selectedPlatformsForRapidPrototype.push(this.phaseService.rapidPrototypePlatforms[0]);
    // }
  }

  public setBreadCrumbs() {
    if (this.phaseService.buildCardEditMode) {
      this.phaseService.breadcrumb_links = [];
      this.phaseService.setBreadcrumbLink(this.router.url.split('/')[1]);
    } else {
      this.phaseService.setBreadcrumbLink(this.router.url.split('/')[1]);
    }
  }

  public featureOpenedInEditMode(): boolean {
    return this.phaseService.isBuildCardSetup
      ? this.phaseService.buildCardEditMode
      : this.phaseService.inProgressFlow;
  }

  public getAndRenderFeatures() {
    const featureIds = this.phaseService.selectedHash.feature_ids;
    if (this.isFeatureRequest && !this.dataService.totalSelectedFeatures) {
      this.fetchBundles(featureIds);
      this.fetchSelectedFeatures(featureIds);
    } else if (!this.isFeatureRequest) {
      this.fetchBundles(featureIds);
      this.fetchSelectedFeatures(featureIds);
    }
  }

  public fetchBundles(featureIds) {
    // this.areBundlesLoading = true;
    this.phaseService.selectedBundles = [];
    // this.cdRef.detectChanges();
    this.bundlesApiSubscription = this.apiRequest
      .fetchFeatures(this.phaseService.selectedHash.application_ids, featureIds)
      .subscribe((bundles) => {
        this.backFromSearch = false;
        if (screen.width < 768) {
          this.focusedBundleIndex = 0;
        }
        this.phaseService.selectedBundles =
          this.phaseService.fetchSelectedBundles();
        if (
          this.phaseService.selectedBundles &&
          this.phaseService.selectedBundles.length > 0
        ) {
          this.setFeaturesCount(this.phaseService.selectedBundles[0].features);
        }
        this.selectedFeatureBundle = this.getFocusedBundle();
        if (this.phaseService.queryToSave) {
          if (
            this.phaseService.recentSearchList.indexOf(
              this.phaseService.queryToSave
            ) < 0
          ) {
            this.phaseService.recentSearchList.unshift(
              this.phaseService.queryToSave
            );
          }
        }
        if (
          innerWidth <= 768 ||
          !this.isNatashaWelcomePopupOpened ||
          (!this.phaseService.selectedFeatures.length &&
            !this.isNatashaWelcomePopupOpened)
        ) {
          this.areBundlesLoading = false;
        }

        this.cdRef.detectChanges();
      });
  }

  setMandatoryInSelectedFeatures(features) {
    if (this.phaseService.mandatoryFeatures.length) {
      const mandatoryFeaturesIds = this.phaseService.mandatoryFeatures.map(
        (feature) => feature.id
      );
      features.map((selectedFeature) => {
        if (mandatoryFeaturesIds.indexOf(selectedFeature.id) > -1) {
          selectedFeature.is_mvp_feature = true;
        }
      });
    }
  }

  public fetchSelectedFeatures(featureIds) {
    if (
      this.phaseService.selectedHash.application_ids.length > 0 ||
      featureIds.length > 0
    ) {
      this.areSelectedFeaturesLoading = true;
      this.selectedFeaturesApiSubscription = this.apiRequest
        .fetchSelectedFeatures(
          this.phaseService.selectedHash.application_ids,
          featureIds
        )
        .subscribe(async (featuresData) => {
          this.phaseService.selectedFeatures = featuresData.data.map(
            (feature) => feature.attributes
          );
          this.dataService.totalSelectedFeatures =
            this.phaseService.selectedFeatures.length;
          if (this.isFeatureRequest) {
            const reqFeatures = this.phaseService.selectedFeatures.map(
              (feature) => feature.id
            );
            this.dataService.buildCardData.change_requests.forEach(
              (feature) => {
                if (reqFeatures.indexOf(feature.id) === -1) {
                  this.phaseService.selectedFeatures.push(feature);
                  this.focusedFeatureIndex =
                    this.phaseService.selectedFeatures.indexOf(feature);
                }
              }
            );
            this.setDisabledAttrForSelectedFeatures();
            this.setDurationPriceInCahngeReq(this.phaseService.selectedFeatures);
          }
          this.setFeaturesCount(this.phaseService.selectedFeatures);
          if (this.isFeatureRequest) {
            this.setFeatureCountForAdditionaReq();
          }
          this.setMandatoryInSelectedFeatures(
            this.phaseService.selectedFeatures
          );
          this.setSelectedFeaturesIds();
          this.setNoteInSelectedFeatures();
          this.previewedFeature = this.getPreviewedFeature();
          this.filterRequestedFeatures();
          this.updateShowMore();
          this.updateFeaturesInPhases();
          this.areSelectedFeaturesLoading = false;
          if (
            !this.dataService.buildCardData.kick_off_date &&
            this.dataService.modifiedBuildCardData &&
            this.dataService.buildCardData.modification_request.status !==
              'approved'
          ) {
            if (
              this.dataService.modifiedBuildCardData['build_card_features'] &&
              this.dataService.modifiedBuildCardData['build_card_features']
                .length
            ) {
              const buildCardFeatires = this.dataService.modifiedBuildCardData[
                'build_card_features'
              ].map((f) => f.feature_id);
              this.phaseService.selectedFeatures =
                this.phaseService.selectedFeatures.filter((feature) => {
                  if (buildCardFeatires.includes(feature.id)) {
                    return feature;
                  }
                });
            }
          }
          this.phaseService.updatePrice();
          this.cdRef.detectChanges();
          setTimeout(() => {
            this.cdRef.detectChanges();
          }, 1000);
        });
    } else {
      // this.getNatashaCondition1Object();
      // this.getNatashaCondition5Object();
      // this.getNatashaCondition2Object();
      // this.getNatashaCondition3Object();
      // this.getNatashaCondition7Object();
      this.areSelectedFeaturesLoading = false;
      this.phaseService.showHideBottomBarLoader = false;
      setTimeout(() => {
        this.cdRef.detectChanges();
      }, 1000);
    }
  }

  setDurationPriceInCahngeReq(selectedFeatures) {
    selectedFeatures.forEach(sFeature => {
      const feature = this.dataService.buildCardData['progress']['buildcardfeatures'].find(f => f.feature_id === sFeature.id);
      if (feature) {
        sFeature['breakup_duration'] = feature['duration'];
        if (this.phaseService.isDiscountApplied()) {
          sFeature['breakup_price'] = parseFloat(feature['discounted_customisation'] + feature['discounted_fixed']).toFixed(2);
        } else {
          sFeature['breakup_price'] = parseFloat(feature['customisation_price'] + feature['fixed_price']).toFixed(2);
        }
        if (this.phaseService.isStoreFeatureRequest) {
          const discount = (sFeature['breakup_price'] * this.dataService.homePageData.store_change_request_discount);
          sFeature['breakup_price'] = sFeature['breakup_price'] - discount;
        }
      }
    });
  }

  setFeatureCountForAdditionaReq() {
    this.phaseService.selectedFeatures.forEach((element) => {
      const reqFeature = this.dataService.buildCardData.change_requests.find(
        (el) => el.id === element.id
      );
      const selFeature = this.dataService.additionalFeatures.find(
        (el) => el.id === element.id
      );
      if (reqFeature && reqFeature.feature_count) {
        element.feature_count = reqFeature.feature_count;
        if (selFeature) {
          selFeature.feature_count = reqFeature.feature_count;
        }
      }
    });
  }

  setDisabledAttrForSelectedFeatures() {
    const changedRequestFeatures =
      this.dataService.buildCardData.change_requests;
    const requestedFeaturesIDs = changedRequestFeatures.map(
      (feature) => feature.id
    );
    this.phaseService.selectedFeatures.forEach((feature) => {
      const featureIndex = requestedFeaturesIDs.indexOf(feature.id);
      if (featureIndex !== -1) {
        if (featureIndex === 0) {
          this.focusedFeatureIndex =
            this.phaseService.selectedFeatures.length -
            1 -
            this.phaseService.selectedFeatures.indexOf(feature);
        }
        feature.disabled = false;
      } else {
        feature.disabled = true;
      }
      return feature;
    });
    if (!changedRequestFeatures.length) {
      // this.focusedFeatureIndex = -1;
    }
  }

  public filterRequestedFeatures() {
    this.phaseService.requestedFeatureList = this.getSelectedFeatures().filter(
      (feature) => {
        return feature.user_requested === true;
      }
    );
  }

  public fetchBundleFeatures(bundle: BundleModel) {
    // this.areBundleFeaturesLoading = true;
    this.showFeatureLoading = true;
    const featureIds = this.phaseService.selectedHash.feature_ids;
    if (this.getAppliedFiltersCount()) {
      if (this.currentView === 0) {
        this.filterParamsBundle.feature_min_price = this.rangePrice[0];
        this.filterParamsBundle.feature_max_price = this.rangePrice[1];
        this.filterParamsBundle.feature_min_weeks = this.rangeDuration[0];
        this.filterParamsBundle.feature_max_weeks = this.rangeDuration[1];
      } else {
        this.filterParamsFeatures.feature_min_price = this.rangePrice[0];
        this.filterParamsFeatures.feature_max_price = this.rangePrice[1];
        this.filterParamsFeatures.feature_min_weeks = this.rangeDuration[0];
        this.filterParamsFeatures.feature_max_weeks = this.rangeDuration[1];
      }
      this.bundlesApiSubscription = this.apiRequest
        .fetchFeaturesForBundle(
          bundle.id,
          featureIds,
          this.currentView === 0
            ? this.filterParamsBundle
            : this.filterParamsFeatures,
          this.filterParamsBundle.feature_template_ids
        )
        .subscribe((featuresData) => {
          bundle.features = featuresData.data.attributes;
          if (!this.phaseService.selectedFeatures?.length) {
            bundle.features.filter(f => f.selected = false)
          }
          this.setFeaturesCount(bundle.features);
          this.phaseService.selectedBundles =
            this.phaseService.fetchSelectedBundles();
          this.selectedFeatureBundle = this.getFocusedBundle();
          if (this.isFeatureRequest) {
            this.dataService.bundlesFeatures = JSON.parse(
              JSON.stringify(featuresData.data.attributes)
            );
            this.setDisabledAttrInFeatures(this.selectedFeatureBundle.features);
          }
          this.areBundleFeaturesLoading = false;
          this.showFeatureLoading = false;
          this.cdRef.detectChanges();
        });
    } else {
      this.bundlesApiSubscription = this.apiRequest
        .fetchFeaturesForBundle(
          bundle.id,
          featureIds,
          '',
          this.phaseService.selectedHash.application_ids
        )
        .subscribe((featuresData) => {
          bundle.features = new Array<FeatureModel>();
          this.modelMapperService.getMappedArrayModel(
            bundle.features,
            featuresData.data
          );
          if (!this.phaseService.selectedFeatures?.length) {
            bundle.features.filter(f => f.selected = false)
          }
          this.setFeaturesCount(bundle.features);
          this.phaseService.selectedBundles =
            this.phaseService.fetchSelectedBundles();
          this.dataService.bundlesFeatures = JSON.parse(
            JSON.stringify(bundle.features)
          );
          this.selectedFeatureBundle = this.getFocusedBundle();
          if (this.isFeatureRequest) {
            this.setDisabledAttrInFeatures(this.selectedFeatureBundle.features);
          }
          this.areBundleFeaturesLoading = false;
          this.showFeatureLoading = false;
          this.cdRef.detectChanges();
        });
    }
  }

  public setSelectedFeaturesIds() {
    if (this.getSelectedFeatures().length > 0) {
      this.phaseService.selectedHash.feature_ids = [];
      this.getSelectedFeatures().forEach((feature) => {
        this.phaseService.selectedHash.feature_ids.push(feature.id);
      });
    }
  }

  public fullWidth() {
    if (this.featureHolder != null) {
      if (this.dataService.featureBundles.length > 0) {
        this.renderer.removeClass(this.featureHolder, 'fullwidth');
      } else {
        this.renderer.addClass(this.featureHolder, 'fullwidth');
      }
    }
  }

  public expandBundle(bundle: any) {
    this.selectedBundle = bundle;
  }

  public selectBundle(e, bundle: any, index: number) {
    e.stopPropagation();

    if (!bundle.selected) {
      this.selectAllFeaturesOfBundle(bundle);
      this.focusedBundleIndex = index;
    } else {
      this.removeAllFeaturesOfBundle(bundle);
    }
    this.showRemovedAllText();
    this.updateScrollOnGallery();
  }

  public updateScrollOnGallery() {
    this.componentScrollElement.toArray().forEach((element, index) => {
      if (
        element.nativeElement.classList.contains('horizontalSlider') === true
      ) {
        this.componentScroll.toArray()[index].directiveRef.update();
      }
    });
  }

  public selectAllFeatures(e: any, bundle: any) {
    e.stopPropagation();
    if (this.dataService.isFromViewPrototype) {
      this.isPollingCancelled = true;
    }
    if (e.target.checked) {
      this.showFeaturePreviewGalleryTooltip();
      if (bundle.selected) {
        this.removeAllFeaturesOfBundle(bundle);
      }
      this.selectAllFeaturesOfBundle(bundle);
    } else {
      this.removeAllFeaturesOfBundle(bundle);
    }
    this.focusedFeatureIndex = 0;
    this.previewedFeature = this.getPreviewedFeature();
    this.showRemovedAllText();
    this.updateScrollOnGallery();
    if (
      this.isFeatureRequest &&
      this.dataService.totalSelectedFeatures ===
        this.phaseService.selectedFeatures.length
    ) {
      this.focusedFeatureIndex = -1;
    }
  }

  public onBundleInfoClick(e: any) {
    e.stopPropagation();
  }

  public showSelectedBundleView() {
    this.selectedBundleView = true;
    this.searchView = false;
  }

  public goBackFromSelectedBundleView() {
    this.selectedBundleView = false;
    this.filterFeatures();
  }

  public onFeatureInfoClick(e: any, feature: any) {
    e.stopPropagation();
    this.showFeaturePopup(feature);
  }

  public closeBundle(e?: any) {
    this.selectedBundle = null;
    if (e) {
      e.stopPropagation();
    }
  }

  public handleMouseEnter(
    index,
    isRecommendedFeaturePreviewed,
    feature,
    event?,
    chat?
  ) {
    if (event) {
      event.stopPropagation();
    }
    if (chat) {
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        'natasha_recommended_feature_viewed',
        {}
      );
    }
    if (this.checkIfPreviewed(feature) && !this.getSelectedFeatures().length) {
      this.inHoverState = false;
      this.previewedFeature = null;
    } else if (
      this.checkIfPreviewed(feature) &&
      this.getSelectedFeatures().length
    ) {
      this.previewedFeature =
        this.getSelectedFeatures()[this.getSelectedFeatures().length - 1];
    } else {
      this.inHoverState = true;
      this.hoveredFeatureIndex = index;
      this.imagePreviewLoaded = false;
      this.previewedFeature = isRecommendedFeaturePreviewed
        ? this.getPreviewedFeature(isRecommendedFeaturePreviewed, chat)
        : this.getPreviewedFeature();
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        'feature_viewed',
        {
          user_id: this.dataService.user?.id,
          feature_view_selected: this.previewedFeature.title,
        }
      );
    }
    this.updateShowMore();
    this.cdRef.detectChanges();
  }

  public handleMouseLeave(index) {
    if (screen.width < 768) {
      return;
    }
    this.inHoverState = false;
    this.previewedFeature = this.getPreviewedFeature();
    this.updateShowMore();
  }

  public getSelectedFeaturesCount(bundle: BundleModel): number {
    return bundle.selected_feature_count;
  }

  public toggleFeature(
    feature: any,
    shouldSelectAllFeaturesInBundle?,
    isLastFeatureInBundle?
  ): void {
    if (this.dataService.isFromViewPrototype) {
      this.isPollingCancelled = true;
    }
    feature.selected = !feature.selected;
    if (
      this.isFeatureRequest &&
      this.dataService.buildCardData.change_requests.length
    ) {
      this.dataService.buildCardData.change_requests.forEach((reqFeature) => {
        if (reqFeature.id === feature.id) {
          this.dataService.buildCardData.change_requests.splice(
            this.dataService.buildCardData.change_requests.indexOf(reqFeature),
            1
          );
        }
      });
    }
    if (!this.isFeatureRequest || this.appDataService.urlParameters.cpe_user) {
      this.phaseService.toggleSelectedFeatureId(feature.id);
    }
    this.toggleSelectedFeatures(feature);
    this.performBundleOperation(feature);
    this.updateFeaturesInPhases(
      shouldSelectAllFeaturesInBundle,
      isLastFeatureInBundle
    );
    this.isBuildCardEditMode();
  }

  public updateFeaturesInPhases(
    shouldSelectAllFeaturesInBundle?,
    isLastFeatureInBundle?
  ) {
    this.dataService.homePageData.build_phases.forEach((phase) => {
      if (
        phase.selected &&
        !phase.title.includes('MVP') &&
        phase['type'] !== 'custom_phase'
      ) {
        phase.features = [];
        phase.features.push(...this.phaseService.selectedFeatures);
      } else if (!this.dataService.isEditBuildCardAfterSecurityPaid() &&
        phase.selected && (phase.title.includes('MVP') || phase['type'] === 'custom_phase')) {
        if (
          (this.phaseService.selectedFeatures &&
            this.phaseService.selectedFeatures.length === 0) ||
          (phase.features &&
            phase.features.length > 0 &&
            phase.features[0] === undefined)
        ) {
          phase.features = [];
        }
        if (phase.features && phase.features.length > 0) {
          phase.features.forEach((feature, featureIndex) => {
            const index = this.phaseService.selectedFeatures.findIndex(
              (selectedFeature) => {
                return feature.id === selectedFeature.id;
              }
            );
            if (index === -1) {
              phase.features.splice(featureIndex, 1);
            }
          });
        }
        if (
          this.phaseService.mandatoryFeatures.length > 0 &&
          phase.title === 'MVP'
        ) {
          const phaseFeatures = phase.features.map((feature) => feature.id);
          this.phaseService.mandatoryFeatures.forEach((feature) => {
            if (
              phaseFeatures.indexOf(feature.id) === -1 &&
              feature.selected &&
              this.phaseService.selectedFeatures
                .map((f) => f.id)
                .includes(feature.id)
            ) {
              feature['selected'] = true;
              feature['mvpSelected'] = true;
              phase.features.push(feature);
            }
            feature['mvpSelected'] = true;
            if (!phase.platforms || phase.platforms.length === 0) {
              phase.platforms = this.phaseService.selectedPlatforms;
            }
            feature.platforms = phase.platforms;
          });
        }
      }
      if (!this.dataService.isEditBuildCardAfterSecurityPaid() &&
        ((phase.title === 'MVP') || (phase['type'] === 'custom_phase'))) {
        if (!phase.features) {
          phase.features = [];
        }
        phase.features.forEach((feature) => {
          feature.phaseId = [];
          feature.platforms.forEach((p) => {
            feature['phaseId'].push(phase.id + '' + p.id);
          });
          this.phaseService.selectedFeatures.forEach((ftr) => {
            if (ftr.id === feature.id) {
              ftr.phaseId = feature.phaseId;
              ftr.platforms = Object.assign([], feature.platforms);
            }
          });
        });
      }
    });
    if (
      shouldSelectAllFeaturesInBundle === null ||
      shouldSelectAllFeaturesInBundle === undefined ||
      isLastFeatureInBundle
    ) {
      this.phaseService.updatePrice();
    }
  }

  public updateFeaturesInMVP(phase) {
    if (phase && phase.title.includes('MVP')) {
      if (phase.selected && phase.title.includes('MVP')) {
        phase.features = [];
        if (this.phaseService.mandatoryFeatures.length > 0) {
          phase.features.push(this.phaseService.mandatoryFeatures);
          phase.features.forEach((feature) => (feature['mvpSelected'] = true));
        }
      }
    }
  }

  public toggleSelectedFeatures(feature) {
    const index = this.getSelectedFeatures().findIndex((featureItem) => {
      return feature.id === featureItem.id;
    });
    if (
      !this.isFeatureRequest ? index === -1 : feature.selected && index === -1
    ) {
      this.phaseService.selectedFeatures.push(feature);
      // this.phaseService.toggleSelectedFeatures(feature);
    } else {
      this.phaseService.selectedFeatures.splice(index, 1);
    }
  }

  public performBundleOperation(feature) {
    const bundle = this.findBundleForFeature(feature.feature_bundle_id);
    if (bundle && bundle.features) {
      for (const featureItem of bundle.features) {
        if (feature.id === featureItem.id) {
          featureItem.selected = feature.selected;
          break;
        }
      }
      this.phaseService.queryToSave = this.searchQuery;
      this.shouldBundleBeSelected(bundle);
    } else {
      const selectedBundleIndex =
        this.findBundleIndexForFeatureFromSelectedList(
          feature.feature_bundle_id
        );
      if (selectedBundleIndex !== -1) {
        this.phaseService.selectedBundles.splice(selectedBundleIndex, 1);
      }
    }
  }

  public findBundleForFeature(bundleId) {
    return this.dataService.featureBundles.find((bundle) => {
      return bundle.id === bundleId;
    });
  }

  public getBundleSelectedFeaturePrice(bundle: BundleModel) {
    let selectedTotal = 0;
    if (bundle.features) {
      bundle.features.forEach((feature) => {
        if (feature.selected) {
          selectedTotal = selectedTotal + feature.price;
        }
      });
    }
    return selectedTotal;
  }

  public getBundleTotalPrice(bundle: BundleModel) {
    let selectedTotal = 0;
    if (bundle.features) {
      bundle.features.forEach((feature) => {
        selectedTotal = selectedTotal + feature.price;
      });
    }
    return selectedTotal;
  }

  public toggleClickedItem(feature: any) {
    feature.selected = !feature.selected;
    this.performBundleOperation(feature);
  }

  public findBundleIndexForFeatureFromSelectedList(bundleId) {
    return this.phaseService.selectedBundles.findIndex((bundle) => {
      return bundle.id === bundleId;
    });
  }

  public clearSelectedHash() {
    this.phaseService.selectedTeam = null;
    this.phaseService.selectedSpeed = null;
    this.phaseService.selectedHash.description = null;
    this.phaseService.selectedHash.team_id = null;
    this.phaseService.selectedHash.speed_id = null;
    this.phaseService.selectedHash.region_skill_ids = [];
    this.phaseService.selectedHash.competitors = [];
    this.phaseService.selectedHash.app_style_ids = [];
    this.phaseService.selectedHash.startup_urls = [];
    this.phaseService.selectedHash.attachments_attributes = [];
    this.phaseService.selectedHash.quote_price = 0;
  }

  public showFeaturePopup(feature: any): void {
    this.showPopupFeature = true;
    this.popupFeature = feature;
  }

  public hideFeaturePopup(): void {
    this.showPopupFeature = false;
  }

  public selectAllFeaturesOfBundle(bundle: BundleModel): void {
    bundle.features.forEach((feature) => {
      if (!feature.selected) {
        this.updateMandatoryFeatures(feature);
        feature.selected = true;
        if (!this.isFeatureRequest) {
          bundle.selected_feature_count++;
          this.phaseService.toggleSelectedFeatureId(feature.id);
        }
        this.toggleSelectedFeatures(feature);
      }
    });
    bundle.selected = true;
    this.updateSelectedBundles(bundle);
    this.updateFeaturesInPhases();
    this.isBuildCardEditMode();
  }

  public removeAllFeaturesOfBundle(bundle: BundleModel): void {
    if (
      this.isFeatureRequest &&
      this.dataService.buildCardData.change_requests
    ) {
      const bundleFeatures = bundle.features.map((feature) => feature.id);
      const changeRequests = JSON.parse(
        JSON.stringify(this.dataService.buildCardData.change_requests)
      );
      for (const feature of changeRequests) {
        if (bundleFeatures.indexOf(feature.id) !== -1) {
          this.dataService.buildCardData.change_requests.splice(
            this.dataService.buildCardData.change_requests.indexOf(feature),
            1
          );
        }
      }
    }
    bundle.features.forEach((feature) => {
      if (!this.isFeatureRequest && feature.selected) {
        this.setBundlesFeaturesSelected(bundle, feature);
      } else if (
        this.isFeatureRequest &&
        feature.selected &&
        !feature.disabled
      ) {
        this.setBundlesFeaturesSelected(bundle, feature);
      }
    });
    bundle.selected = false;
    this.updateSelectedBundles(bundle);
    this.updateFeaturesInPhases();
    this.isBuildCardEditMode();
  }

  setBundlesFeaturesSelected(bundle, feature) {
    feature.selected = false;
    if (!this.isFeatureRequest) {
      bundle.selected_feature_count--;
      this.phaseService.toggleSelectedFeatureId(feature.id);
    }
    this.toggleSelectedFeatures(feature);
    this.updateMandatoryFeatures(feature);
  }

  public shouldBundleBeSelected(selectedBundle: BundleModel): void {
    const selectedFeatureCount = selectedBundle.features.filter((feature) => {
      return feature.selected;
    }).length;
    selectedFeatureCount > 0
      ? (selectedBundle.selected = true)
      : (selectedBundle.selected = false);
    this.updateSelectedBundles(selectedBundle);
  }

  public updateSelectedBundles(selectedBundle: BundleModel) {
    if (selectedBundle.selected) {
      const alreadySelectedIndex = this.bundleAlreadySelected(selectedBundle);
      if (alreadySelectedIndex === -1) {
        this.phaseService.selectedBundles.push(selectedBundle);
      } else {
        this.phaseService.selectedBundles[alreadySelectedIndex] =
          selectedBundle;
      }
    } else {
      const alreadySelectedIndex = this.bundleAlreadySelected(selectedBundle);
      this.phaseService.selectedBundles.splice(alreadySelectedIndex, 1);
    }
  }

  public bundleAlreadySelected(selectedBundle) {
    return this.phaseService.selectedBundles.findIndex((bundle) => {
      return selectedBundle.id === bundle.id;
    });
  }

  public removeAllSelections(removeFeaturesPopupInput?) {
    for (const feature of this.phaseService.requestedFeatureList) {
      feature.selected = false;
    }
    this.phaseService.mandatoryFeatures.forEach((f) => {
      f.platforms = [];
      f.phaseId = [];
    });
    this.phaseService.mandatoryFeatures = [];
    this.phaseService.selectedFeatures.forEach((f) => {
      f.platforms = [];
      f.phaseId = [];
    });
    this.phaseService.selectedFeatures = [];
    this.phaseService.selectedHash.feature_ids = [];
    this.showRemoveAll = false;
    for (const bundle of this.getFeatureBundles()) {
      if (bundle.features) {
        for (const feature of bundle.features) {
          feature.selected = false;
        }
      }
      bundle.selected_feature_count = 0;
      bundle.selected = false;
    }
    this.dataService.featureList.forEach((f) => {
      f.selected = false;
    });
    if (this.dataService.filteredFeatures) {
      this.dataService.filteredFeatures.forEach((f) => {
        f.selected = false;
      });
    }
    this.showRemovedAllText();
    this.phaseService.selectedBundles = [];
    if (
      !this.phaseService.isEmptyBuildCard ||
      (this.phaseService.selectedApps.length === 1 &&
        this.phaseService.selectedApps[0].user_requested === true &&
        this.isPageRefreshed)
    ) {
      // this.getNatashaCondition5Object();
      // this.getNatashaCondition2Object();
      const obj = this.getNatashaStartFromScratchObject();
      this.addObjectToNatashaChat(obj);
    }
    this.phaseService.isEmptyBuildCard = false;
    if (removeFeaturesPopupInput) {
      this.idleAnimationForChatButton();
      this.moveWizardDotFromRemoveAllPopupToNatasha();
      if (this.getSelectedFeatures().length === 0) {
        this.showRemoveAllFeatureTooltip = true;
      }
      setTimeout(() => {
        this.showRemoveAllFeatureTooltip = false;
        // this.cdRef.detectChanges();
      }, 2000);
    }

    // this.cdRef.detectChanges();
    if (this.removeUsingTutorial) {
      this.handleOverlay();
    }
    this.dataService.homePageData.build_phases.forEach((phase) => {
      if (phase.selected) {
        if (phase.title === 'MVP') {
          phase.selected = false;
        }
        phase.features = [];
      }
    });
    // this.updateFeaturesInPhases();
    this.phaseService.updatePrice();
    this.isBuildCardEditMode();
  }

  public closeDeleteAllPopup() {
    this.deleteAll = false;
  }

  public showDeleteAll() {
    this.deleteAll = true;
  }

  public changeClassForAnchor(featureBlock) {
    for (const block of this.phaseService.featuresToShow) {
      featureBlock === block
        ? (block.selected = true)
        : (block.selected = false);
    }
  }

  public showLearnMore(): void {
    this.phaseService.learnMoreIndex = 3;
    this.phaseService.showLearnMore = true;
  }

  public gotoTeam() {
    if (this.phaseService.buildCardEditMode) {
      this.router.navigate(['delivery']);
    } else {
      this.phaseService.showCurrency = false;
      this.router.navigate(['delivery']);
    }
  }

  public msToHMS(timeDiff) {
    const secDiff = Math.floor(timeDiff / 1000); // in s
    const minDiff = Math.floor(timeDiff / 60 / 1000); // in minutes
    const hDiff = Math.floor(timeDiff / 3600 / 1000); // in hours
    return hDiff + ':' + minDiff + ':' + secDiff;
  }

  public isPromotionalFeatureAdded() {
    let isPromotionalFeaturePresent = false;
    this.dataService.homePageData.promotion.features.forEach((feat) => {
      this.phaseService.selectedFeatures.forEach((feature) => {
        if (feature.id === feat.id) {
          isPromotionalFeaturePresent = true;
          return;
        }
      });
      if (isPromotionalFeaturePresent) {
        return;
      }
    });
    return isPromotionalFeaturePresent;
  }

  public findPromotionalFeatures() {
    const promotionFeatures = [];
    const nonPromotionalFeatures = [];
    this.phaseService.selectedFeatures.forEach((feat) => {
      let isPresent = false;
      this.dataService.homePageData.promotion.features.forEach((feature) => {
        if (feature.id === feat.id) {
          isPresent = true;
          return;
        }
      });
      if (isPresent) {
        promotionFeatures.push(feat);
      } else {
        nonPromotionalFeatures.push(feat);
      }
    });
    return { promo: promotionFeatures, nonpromo: nonPromotionalFeatures };
  }

  public addNewFeature(fromSearch?): void {
    if (this.phaseService.isStoreFeatureRequest) {
      return;
    }
    if (
      !this.getFeaturePopupDataInSessionStorage() &&
      this.dataService.user?.roles !== 'CPE'
    ) {
      this.checkSelectedFeatureCount();
      if (this.isShowMaxFeaturePopUp) {
        return;
      }
    }
    if (this.dataService.isFromViewPrototype) {
      this.isPollingCancelled = true;
    }
    if (fromSearch) {
      this.goBackFromSearch();
    }
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      'custom_feature_initiated',
      {
        user_id: this.dataService.user?.id,
      }
    );
    this.phaseService.addnewfeature = true;
    this.phaseService.addedFeature.difficulty = 6;
    this.featureAddedSubscription = this.phaseService
      .getAddedFeature()
      .subscribe((feature) => {
        this.addedFeature = feature;
        this.focusedFeatureIndex = 0;
        this.previewedFeature = this.getPreviewedFeature();
        this.updateShowMore();
        this.featureAddedSubscription.unsubscribe();
        this.cdRef.detectChanges();
        this.isBuildCardEditMode();
      });
  }

  public editRequestedFeature(feature, event): void {
    event.stopPropagation();
    this.phaseService.addedFeature.id = feature.id;
    this.phaseService.addedFeature.title = feature.title;
    this.phaseService.addedFeature.description = feature.description;
    this.phaseService.addedFeature.template_ids = feature.template_ids || [];
    this.phaseService.addedFeature.difficulty = feature.difficulty;
    this.phaseService.addedFeature.algorithm = feature.algorithm;
    this.phaseService.addedFeature.stream_media = feature.stream_media;
    this.phaseService.addedFeature.interface_type = feature.interface_type;
    this.phaseService.addedFeature.reference_urls =
      feature.reference_urls || [];
    this.phaseService.addedFeature.selected = feature.selected;
    this.phaseService.addedFeature.dependent_features = feature.dependent_features;
    this.phaseService.addnewfeature = true;
    this.cdRef.detectChanges();
  }

  public resetSearch() {
    this.searchView = false;
    this.searchQuery = '';
    this.showQuery = false;
  }

  public getFeatureFilters() {
    this.featureFilterSubscription = this.apiRequest
      .fetchFeatureFilters(this.phaseService.selectedHash.application_ids)
      .pipe(finalize(() => {}))
      .subscribe((filterdata) => {
        this.featureFilter = filterdata;
        this.optionsPrice = {
          floor: this.featureFilter.bundle_details.min_global_price,
          ceil: this.featureFilter.bundle_details.max_global_price,
          noSwitching: true,
        };
        this.rangePrice = [
          this.featureFilter.bundle_details.min_global_price,
          this.featureFilter.bundle_details.max_global_price,
        ];
        this.optionsDuration = {
          floor: this.featureFilter.bundle_details.min_global_weeks,
          ceil: this.featureFilter.bundle_details.max_global_weeks,
          noSwitching: true,
        };
        this.rangeDuration = [
          this.featureFilter.bundle_details.min_global_weeks,
          this.featureFilter.bundle_details.max_global_weeks,
        ];
        this.realFiltersFetched = true;
        if (
          this.phaseService.buildCardEditMode &&
          sessionStorage.getItem('show_natasha_again') !== 'from_template_page'
        ) {
          this.shouldHideWelcomeMessage = false;
        }
        this.cdRef.detectChanges();
        setTimeout(() => {
          this.cdRef.detectChanges();
        }, 100);
      });
  }

  public getCurrency() {
    return this.phaseService.buildCardEditMode ||
      this.dataService.isIncompleteCardEditMode ||
      this.isFeatureRequest
      ? this.dataService.buildCardData.currency
      : this.dataService.homePageData.currency;
  }

  public getPreviousCurrency() {
    return this.dataService.previousCurrency;
  }

  public getSpecingPrice() {
    return this.phaseService.specing_price;
  }

  public getFeaturesFromBundle(bundle: BundleModel) {
    this.setMandatoryInSelectedFeatures(bundle.features ? bundle.features : []);
    if (bundle.features && this.phaseService.featureData) {
      this.phaseService.setDurationPriceInSelectedFeatures(bundle.features);
    }
    return bundle.features ? bundle.features : [];
  }

  public getFeatureCountInBundle(bundle: BundleModel) {
    return bundle.total_features;
  }

  public getLocaledPrice(price: any): any {
    return this.dataService.getLocaledPrice(price) !== '0.00'
      ? this.dataService.getLocaledPrice(price, this.dataService.buildCardData.currency)
      : '-';
  }

  getLocaledPriceForFilter(price: any): any {
    return this.dataService.getLocaledPrice(price);
  }

  public handleSpecing() {
    this.phaseService.selectSpecing();
  }

  public getSelectedSpecing() {
    return this.phaseService.selectedHash.specing;
  }

  public isShowLoader() {
    return this.dataService.showLoader;
  }

  public getFeatureBundles() {
    return this.dataService.featureBundles;
  }

  public getSelectedFeatures(): FeatureModel[] {
    return this.phaseService.selectedFeatures.filter(
      (feature, index) =>
        this.phaseService.selectedFeatures.indexOf(feature) === index
    );
  }

  public getSelectedBundles() {
    return this.phaseService.selectedBundles;
  }

  public getFeatureWeeks(feature: FeatureModel) {
    return this.phaseService.getFeatureWeeks(feature);
  }

  getWeeksAndDaysText(previewedFeature) {
    if (previewedFeature && previewedFeature.breakup_duration) {
      return previewedFeature.breakup_duration > 1 ? 'days' : 'day';
    } else {
      return (previewedFeature && previewedFeature.duration) > 1
        ? 'days'
        : 'day';
    }
  }

  public getFeaturePrice(feature: FeatureModel) {
    return this.phaseService.getFeaturePrice(feature).toFixed(2);
  }

  public getRequestedFeatureList() {
    return this.phaseService.requestedFeatureList;
  }

  chekIfFeatureIsInSelected(previewedFeature) {
    if (previewedFeature) {
      if (previewedFeature.user_requested) {
        return (!this.phaseService.selectedFeatures.map((f) => f.id).includes(previewedFeature.id));
      } else {
        return (!this.phaseService.selectedFeatures.map((f) => f.id).includes(previewedFeature.id) || previewedFeature.disabled);
      }
    }
  }

  getSearchOrFilterFeatures() {
    const features =
      this.dataService.featureList.length > 0
        ? this.dataService.featureList
        : this.dataService.filteredFeatures;
    this.setMandatoryInSelectedFeatures(features);
    return features;
  }

  public getPreventClick() {
    return this.phaseService.preventClick;
  }

  public getEditMode() {
    return this.phaseService.buildCardEditMode;
  }

  public getSplicedValues(text: any) {
    return this.phaseService.getSplicedValues(text);
  }

  public getRecentSearchList() {
    if (this.phaseService.recentSearchList.length > 0) {
      return this.phaseService.recentSearchList;
    }
  }

  public getResultsFromRecentSearch(query) {
    this.searchQuery = query;
    this.searchFilter();
  }

  public toggleRelatedSearches(flag) {
    this.isRecentSearchVisile = flag;
    this.searchFocus = true;
  }

  public closeSearchExpanded() {
    this.searchFocus = false;
  }

  public showTutorialTooltip() {
    if (this.tooltipIndex === null && !this.checkNavigationToPage()) {
      this.tooltipIndex = 0;
    } else if (this.tooltipIndex === 0 && !this.checkNavigationToPage()) {
      this.tooltipIndex += 2;
    } else {
      this.tooltipIndex += 1;
    }
    if (this.tooltipIndex === 6) {
      if (this.commonService.isPlatformBrowser) {
        localStorage.setItem('tutorial_seen', 'true');
      }
      this.tutorialSeen = true;
      this.phaseService.isFeatureTutorialActive = false;
    } else if (this.commonService.isPlatformBrowser) {
      localStorage.setItem('tutorial_seen', 'false');
    }
  }

  public closeTooltip() {
    this.tooltipIndex = null;
    if (this.commonService.isPlatformBrowser) {
      localStorage.setItem('tutorial_seen', 'true');
    }
    this.tutorialSeen = true;
    this.phaseService.isFeatureTutorialActive = false;
  }

  public clickBundle() {
    this.bundleClick = false;
  }

  public clickFeature() {
    this.bundleClick = true;
  }

  public getSelectedBundleFeatures() {
    return this.getFeatureBundles()[this.focusedBundleIndex];
  }

  public previewSelected(recommendedFeaturePreviewed?, feature?) {
    this.resetPreviewPannelState();
    if (window.innerWidth <= 768) {
      this.bundleClick = true;
    }
    if (this.bundleClick) {
      this.previewSelect = true;
    }
  }

  public selectFeaturesFromSharedUrl() {
    if (
      this.dataService.shareUrlData &&
      this.dataService.shareUrlData.featureIds
    ) {
      const arr = this.dataService.shareUrlData.featureIds.split(',');
      this.phaseService.selectedFeatures = arr;
      this.phaseService.selectedHash.feature_ids = arr;
      this.getAndRenderFeatures();
    }
  }

  resetPreviewPannelState() {
    if (this.minimizeGallery) {
      this.minimizeGallery = false;
    }
  }

  public setMinimizeGallery() {
    if (this.minimizeGallery) {
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        'feature_section_interacted',
        {
          user_id: this.dataService.user?.id,
          interaction_type: 'Collapse',
        }
      );
      this.minimizeGallery = false;
    } else {
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        'feature_section_interacted',
        {
          user_id: this.dataService.user?.id,
          interaction_type: 'Expand',
        }
      );
      this.minimizeGallery = true;
    }
  }

  public clearFeatureFilter() {
    this.tagFilterApplied = 0;
    this.featureFilter.tags.forEach((featureTag) => {
      featureTag.selected = false;
    });
    this.filterParamsBundle.feature_tag_ids = [];
    // this.getAndRenderFeatures();
  }

  public clearTemplateFilter() {
    this.templateFilterApplied = 0;
    this.filterParamsBundle.feature_template_ids = [];
    this.getAndRenderFeatures();
  }

  public openVideo() {
    this.isVideoOpened = true;
  }

  public closeVideo() {
    this.isVideoOpened = false;
  }

  public checkNavigationToPage() {
    return this.dataService.landingPage || this.dataService.shareUrlData;
  }

  public removedUsingTutorial(flag) {
    this.removeUsingTutorial = flag;
  }

  closePlatformPopup() {
    this.phaseService.showPlatformPopup = false;
  }

  togglePlatformView(event) {
    this.toggleLayoutView(event);
  }

  toggleBottomPipe() {
    this.showBottomPipe = !this.showBottomPipe;
  }

  getAppsTitle() {
    let templateSelected = '';
    if (this.phaseService.selectedApps.length > 0) {
      const appsList = this.phaseService.selectedApps.map((app) => {
        return app.title;
      });
      // appsList.splice(3);
      if (appsList.length > 1) {
        // const last = appsList.pop();
        templateSelected = appsList.join(', ');
      } else {
        templateSelected = appsList[0];
      }
      return templateSelected;
    }
  }
  isBuildCardEditMode() {
    if (
      this.dataService.isFromViewPrototype &&
      this.phaseService.buildCardEditMode &&
      this.phaseService.inProgressBuildCardId &&
      !this.isFeatureRequest &&
      !this.dataService.isEditBuildCardAfterSecurityPaid()
    ) {
      this.userIdle.stopWatching();
      this.userIdle.setConfigValues({
        idle: 1,
        timeout: 1,
        ping: 1,
        idleSensitivity: 1,
      });
      this.updateBuildCardHash.push(this.phaseService.selectedHash);
      this.userIdle.startWatching();
      this.userIdle.onTimerStart().subscribe((count) => {});
      this.userIdle.onTimeout().subscribe(() => {
        const hashLength = this.updateBuildCardHash.length;
        if (hashLength > 0) {
          this.updateBuildCardHash = [];
          this.updateBuildCard();
        }
      });
    }
  }
  updateBuildCard(isFromWithin?) {
    this.isPollingCancelled = true;
    this.dataService.unsavedChanges = false;
    this.userIdle.stopWatching();
    this.buildcardUpdateNatashaChatsList = this.phaseService.natashaChatsList;
    this.buildcardUpdateChatsTobeShownOnUI = this.chatsTobeShownOnUI;
    this.buildcardUpdateNatashaTempIntroMessagesList =
      this.phaseService.natashaTempIntroMessagesList;
    this.buildcardUpdateNatashaIntroMessagesList =
      this.phaseService.natashaIntroMessagesList;
    this.buildcardUpdatefeatureSliderIndex =
      this.phaseService.recommendedFeaturesSliderIndex;
    this.apiRequest
      .createUpdateBuildCard(
        this.phaseService.selectedHash,
        this.phaseService.inProgressBuildCardId
      )
      .subscribe(() => {
        this.isPollingCancelled = true;
        const featuresAfterUpdate = this.dataService.buildCardData.progress[
          'features'
        ].map((feature) => feature.id);
        if (
          this.checkForArrayEquality(
            this.phaseService.selectedHash.feature_ids,
            featuresAfterUpdate
          )
        ) {
          this.phaseService.buildCardUpdateOnFeatureAdded = true;
          this.phaseService.setUpInFullBuildCard();
          this.phaseService.buildCardEditMode = true;
          this.dataService.isEditBuildCard = true;
          this.phaseService.natashaChatsList =
            this.buildcardUpdateNatashaChatsList;
          this.chatsTobeShownOnUI = this.buildcardUpdateChatsTobeShownOnUI;
          this.phaseService.natashaTempIntroMessagesList =
            this.buildcardUpdateNatashaTempIntroMessagesList;
          this.phaseService.natashaIntroMessagesList =
            this.buildcardUpdateNatashaIntroMessagesList;
          this.phaseService.recommendedFeaturesSliderIndex =
            this.buildcardUpdatefeatureSliderIndex;
          this.cdRef.detectChanges();
          if (this.dataService.isFromViewPrototype) {
            setTimeout(() => {
              this.isPollingCancelled = false;
            }, 5000);
          }
        } else if (!isFromWithin) {
          this.isPollingCancelled = true;
          this.phaseService.selectedPhases =
            this.dataService.buildCardData.progress['buildphases'];
          this.phaseService.selectedHash.build_card_phases =
            this.dataService.buildCardData.progress['buildcardphases'];
          this.updateBuildCard(true);
        }
        this.phaseService.buildCardUpdateOnFeatureAdded = false;
      });
  }

  setInvalidAccessIfBuildCardAccessByLoggedOutUser() {
    if (
      this.appDataService.urlParameters &&
      this.appDataService.urlParameters.buildcard
    ) {
      if (
        !(
          this.dataService.user &&
          this.dataService.user.roles === 'CPE' &&
          !this.dataService.buildCardData.owner
        )
      ) {
        sessionStorage.setItem(
          'featurecard-buildcard-param',
          this.activatedRoute.snapshot['_routerState'].url
        );
        this.dataService.invalidAccess = true;
        this.router.navigate(['invalidaccess']);
      }
    }
  }

  showHideArrowIcon(index) {
    this.focusedBundleArrowIndex = index;
    this.showArrow = !this.showArrow;
  }

  closeWelcomePopup(showTutorial?) {
    this.hideFeatureRightLoading = true;
    this.isNatashaWelcomePopupOpened = false;
    this.openWelcomeFeaturePopup = false;
    this.dataService.natashaDropdownPanel = false;
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      'natasha_wizard_menu_selected',
      {
        elements_clicked: 'view tutorial',
      }
    );

    if (showTutorial === 'showTutorial') {
      this.areBundlesLoading = false;
      this.dataService.showFeatureTour = true;
      this.mainwizardDot = false;
    }
    if (showTutorial === 'showTutorialFromChatbot') {
      this.areBundlesLoading = false;
      this.dataService.notifyTutorialComing = true;
      this.getNatashaCondition4Object();
      this.natashaMobilePanel = false;
      this.dataService.showFeatureTour = true;
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        'tutorial_viewed',
        {
          user_id: this.dataService.user?.id,
        }
      );
    }
    if (!showTutorial) {
      this.welcomeToRightSide = true;
      this.welcomeMsgAnimation = false;

      const nbox = document.querySelector('#wizardAnimation');
      this.pulsateNatashaWizard(nbox);
      setTimeout(() => {
        this.mainwizardDot = false;
        this.welcomeToRightSide = false;
        this.welcomeMsgAnimation = true;
        this.shouldHideWelcomeMessage = false;
        this.areBundlesLoading = false;
        if (!this.isShowSplashScreen) {
          setTimeout(() => {
            this.initializeNatashaMessages();
          }, 2500);
        } else {
          this.performActionsWhenUserLandedNotFromTemplate();
        }
        this.isShowSplashScreen = false;
        this.cdRef.detectChanges();
      }, 400);
    }
    if (this.commonService.isPlatformBrowser && !this.isShowSplashScreen) {
      localStorage.setItem('show_welcome_popup', 'NO');
      localStorage.setItem('tutorial_seen', 'true');
      sessionStorage.setItem('show_natasha_again', 'false');
    }
  }

  toggleRemoveLockFeatureModal() {
    this.noMoreRemoveLockFeatureModalgModal =
      !this.noMoreRemoveLockFeatureModalgModal;
    if (
      !this.noMoreRemoveLockFeatureModalgModal &&
      this.commonService.isPlatformBrowser
    ) {
      localStorage.removeItem('show_remove_lock_feature_popup');
    } else if (
      this.noMoreRemoveLockFeatureModalgModal &&
      this.commonService.isPlatformBrowser
    ) {
      localStorage.setItem('show_remove_lock_feature_popup', 'NO');
    }
  }

  hideDynamicPricingPopup() {
    if (this.commonService.isPlatformBrowser) {
      return (
        this.dataService.showDynamicPriceModal &&
        !localStorage.getItem('show_dynamic_pricing_popup')
      );
    }
  }

  hasWelcomePopupAppeared() {
    if (this.commonService.isPlatformBrowser) {
      if (!localStorage.getItem('show_welcome_popup')) {
        if (
          this.isShowSplashScreen ||
          (sessionStorage.getItem('show_natasha_again') &&
            sessionStorage.getItem('show_natasha_again') ===
              'from_template_page')
        ) {
          this.hasMetNatashaFirstTime = true;
          this.isNatashaWelcomePopupOpened = true;
          this.mainwizardDot = true;
          const nbox = document.querySelector('#wizardPopupAnimation');
          this.pulsateNatashaWizard(nbox);
          if (this.isShowNatashaWelcomePopup) {
            setTimeout(() => {
              this.closeWelcomePopup();
              this.cdRef.detectChanges();
            }, 6000);
          }
          this.isShowNatashaWelcomePopup = false;
        } else if (
          sessionStorage.getItem('show_natasha_again') &&
          sessionStorage.getItem('show_natasha_again') ===
            'not_from_template_page'
        ) {
          this.performActionsWhenUserLandedNotFromTemplate();
        } else {
          sessionStorage.setItem(
            'show_natasha_again',
            'not_from_template_page'
          );
          this.closeWelcomePopup();
        }
      }
      return (
        this.openWelcomeFeaturePopup &&
        !localStorage.getItem('show_welcome_popup')
      );
    }
  }

  abortFeatureRemoving(keepFeature?) {
    this.showModalForRemovingLockFeature = false;

    this.rightToLockAnimation = false;
    this.lockToRightAnimation = true;
    setTimeout(() => {
      this.mainwizardDot = false;
      this.lockToRightAnimation = false;
      if (keepFeature) {
        const text = 'No, keep it!';
        this.recordUserInput(text);
        setTimeout(() => {
          const text1 = 'Great decision!';
          const obj = {
            text1: text1,
            condition2Status: true,
            showLoader: true,
            createdAt: this.getTime(),
            timeStamp: this.phaseService.getCurrentTimestamp(),
          };
          this.addObjectToNatashaChat(obj);
        }, 1000);
      }
      this.cdRef.detectChanges();
    }, 400);
  }

  hideLockFeatureModal() {
    if (
      this.noMoreRemoveLockFeatureModalgModal &&
      this.commonService.isPlatformBrowser
    ) {
      localStorage.setItem('show_remove_lock_feature_popup', 'NO');
    }
  }

  openOrClosePlatformPopup() {
    this.dataService.showPlatformSelectionPopup =
      !this.dataService.showPlatformSelectionPopup;
  }

  featureGroupScrolled(event) {
    if (event.target.scrollTop !== 0) {
      this.dataService.isScrollTopFeatures = true;
    } else {
      this.dataService.isScrollTopFeatures = false;
    }
  }

  filterGroupScrolled(event) {
    if (event.target.scrollTop !== 0) {
      this.dataService.isScrollTopFeatures = true;
    } else {
      this.dataService.isScrollTopFeatures = false;
    }
  }

  cartItemScrolled(event) {
    if (event.target.scrollTop !== 0) {
      this.isScrollCartItem = true;
    } else {
      this.isScrollCartItem = false;
    }
  }

  ff() {
    this.isScrollCartItem = false;
  }

  natashaSidebarScrolled(event) {
    if (event.target.scrollTop > 5) {
      this.isScrollNatashaSidebar = true;
    }
    if (
      this.homeTemplateService.tempateNatashaChatList &&
      this.objectKeys(this.homeTemplateService.tempateNatashaChatList).length >
        0
    ) {
      const element = document.querySelector('.natashaTemplateHistory');
      const isNatashaHistoryInViewport = this.isInViewport(element);
      if (!isNatashaHistoryInViewport) {
        this.phaseService.isCollapseTemplateHistory = true;
      }
    }
    const element = event.target.querySelector('.sliderHolder');
    if (element && element.classList.contains('hoveron')) {
      this.closeSliderPosition(event);
    }

    // else {
    // this.isScrollNatashaSidebar = false;
    // }
  }

  showHideShareToolTip() {
    return this.dataService.showToasterOnFeaturePage;
  }

  hasUserLandedOnNatashaAgain() {
    if (this.commonService.isPlatformBrowser) {
      if (
        sessionStorage.getItem('show_natasha_again') &&
        sessionStorage.getItem('show_natasha_again') !== 'false'
      ) {
        this.hasMetNatashaFirstTime = false;
        this.hasMetNatashaAgain = true;
      }
      if (
        sessionStorage.getItem('show_natasha_again') &&
        sessionStorage.getItem('show_natasha_again') ===
          'not_from_template_page'
      ) {
        this.hasMetNatashaAgainComingNotFromTemplate = true;
      }
      if (
        sessionStorage.getItem('show_natasha_again') &&
        sessionStorage.getItem('show_natasha_again') === 'from_template_page'
      ) {
        this.hasMetNatashaAgainComingFromTemplate = true;
      }

      if (
        this.isShowSplashScreen ||
        (sessionStorage.getItem('show_natasha_again') &&
          sessionStorage.getItem('show_natasha_again') === 'from_template_page')
      ) {
        this.mainwizardDot = true;
        this.isNatashaWelcomePopupOpened = true;
        const nbox = document.querySelector('#wizardPopupAnimation');
        this.pulsateNatashaWizard(nbox);
        if (this.isShowNatashaWelcomePopup) {
          setTimeout(() => {
            this.closeWelcomePopup();
            this.cdRef.detectChanges();
          }, 6000);
        }
        this.isShowNatashaWelcomePopup = false;
      } else if (
        sessionStorage.getItem('show_natasha_again') &&
        sessionStorage.getItem('show_natasha_again') ===
          'not_from_template_page'
      ) {
        this.performActionsWhenUserLandedNotFromTemplate();
      }

      return (
        this.isShowSplashScreen ||
        (sessionStorage.getItem('show_natasha_again') &&
          sessionStorage.getItem('show_natasha_again') === 'from_template_page')
      );
    }
  }

  searchNatashaFAQs() {
    // this.searchResultFAQs = [];
    if (this.searchFAQInput) {
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        'natasha_interacted',
        {
          user_id: this.dataService.user?.id,
          keywords_submitted: this.searchFAQInput,
        }
      );
      if (this.phaseService.isCustomFeatureFlowFromNatasha) {
        this.phaseService.resetCustomFeatureFlowFromNatasha();
        this.resetFlagsInNatashaCustomFetaureQuestions();
      }
      this.recordUserInput(this.searchFAQInput);
      // let obj = { input: this.searchFAQInput, resultRecords: [], createdAt: this.getTime(), condition11Status: true, showLoader: false,
      //   timeStamp: this.phaseService.getCurrentTimestamp(), isResponse: false };
      //   this.phaseService.natashaChatsList.push(obj);
      //   this.chatsTobeShownOnUI.push(obj);
      let selectedFeatures;
      // if (this.searchFAQInput.length >= 5) {
      selectedFeatures = this.isFeatureRequest
        ? this.phaseService.selectedHash.feature_ids
        : this.getSelectedFeatures();
      // }

      this.apiRequest
        .fetchNatashaFAQs(this.searchFAQInput, selectedFeatures)
        .subscribe((data) => {
          let obj: any = {};
          let features;
          let articles;
          const isQuestionQuery = data.is_question_query;
          if (data.features) {
            features = data.features.map((feature) => feature.attributes);
          }
          if (data.articles) {
            articles = data.articles;
          }

          if (
            features &&
            features.length > 0 &&
            articles &&
            articles.length === 0
          ) {
            const item_id = [];
            for (let i = 0; i < features.length; i++) {
              const fobj = features[i];
              item_id.push(fobj.id);
            }
            const messageIdentifier =
              this.getMessageIdentifierForMultipleFeatures(item_id);
            if (features.length === 1) {
              const text1 =
                'Basis your query, here is the feature from our catalogue.';
              const messageIdentifier = 'singleFeature_' + item_id[0];
              obj = {
                itemId: item_id[0],
                itemType: 'feature',
                questionText: text1,
                recommendedFeatures: features,
                natashaSingleFeatureConditionStatus: true,
                showLoader: true,
                createdAt: this.getTime(),
                timeStamp: this.phaseService.getCurrentTimestamp(),
                shouldShowActionButtons: true,
                shouldShowSingleRecommendedFeature: true,
                messageIdentifier: messageIdentifier,
                isFromNatashaSearch: true,
              };
              this.setMandatoryInSelectedFeatures(obj.recommendedFeatures);
            } else if (features.length > 1) {
              const text1 = 'Okay, these features could be useful.';
              obj = {
                itemId: item_id,
                itemType: 'feature',
                text: text1,
                recommendedFeatures: features,
                sliderIndex: this.incrementRecommendedFeatureSliderIndex(),
                condition8Status: true,
                showLoader: true,
                createdAt: this.getTime(),
                timeStamp: this.phaseService.getCurrentTimestamp(),
                shouldShowActionButtons: true,
                shouldShowRecommendedFeatures: true,
                messageIdentifier: messageIdentifier,
                isFromNatashaSearch: true,
              };
              this.setMandatoryInSelectedFeatures(obj.recommendedFeatures);
            }
            this.phaseService.setDurationPriceInSelectedFeatures(
              obj.recommendedFeatures
            );
          } else if (
            articles &&
            articles.length > 0 &&
            features &&
            features.length === 0
          ) {
            obj = {
              input: this.searchFAQInput,
              resultRecords: [],
              createdAt: this.getTime(),
              condition11Status: true,
              showLoader: true,
              timeStamp: this.phaseService.getCurrentTimestamp(),
              isResponse: true,
            };
            this.searchResultFAQs = articles;
            for (let i = 0; i < 3; i++) {
              if (articles[i] && articles[i].id) {
                obj.resultRecords.push(articles[i]);
              }
            }
            this.noSearchResult = false;
          } else if (
            features &&
            features.length === 0 &&
            articles &&
            articles.length === 0 &&
            isQuestionQuery
          ) {
            obj = {
              input: this.searchFAQInput,
              resultRecords: [],
              createdAt: this.getTime(),
              condition11Status: true,
              showLoader: true,
              timeStamp: this.phaseService.getCurrentTimestamp(),
              isResponse: true,
            };
            this.noSearchResult = true;
          } else if (
            features &&
            features.length === 0 &&
            articles &&
            articles.length === 0 &&
            !isQuestionQuery
          ) {
            obj = this.initiateCustomFeatureFlow();
          }
          // else if((articles && (articles.length === 0)) && !features) {
          //   obj = {
          //     input: this.searchFAQInput, resultRecords: [], createdAt: this.getTime(), condition11Status: true, showLoader: true,
          //     timeStamp: this.phaseService.getCurrentTimestamp(), isResponse: true
          //   };
          //   this.noSearchResult = true;
          //   this.phaseService.trackEvent(this.analyticsSegment,  'Natasha Wizard FAQ typed', 'result_availiable', 'no');
          // }
          this.phaseService.userInputAndResult.push(obj);
          setTimeout(() => {
            this.addinNatashChatList(obj);
          }, 2000);
          this.scrollNatashaToBottom();
          this.searchFAQInput = '';
          this.cdRef.detectChanges();
        });
    }
  }

  startCustomFeatureFlow() {
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      'natasha_custom_feature_initiated',
      {
        user_id: this.dataService.user?.id,
      }
    );
    const text = 'Amazing, lets start by giving it a name?';
    const obj = {
      customFeatureTitleQuestion: text,
      createdAt: this.getTime(),
      customFeatureTitleStatus: true,
      showLoader: true,
      timeStamp: this.phaseService.getCurrentTimestamp(),
    };
    obj['shouldShowActionButtons'] = false;
    setTimeout(() => {
      this.addinNatashChatList(obj);
      this.phaseService.isShowCustomFeatureTitleQuestion = true;
    }, 1000);
  }

  addinNatashChatList(obj) {
    if (obj && obj.showLoader) {
      this.addObjectInNatashaGroup(obj);
      // this.phaseService.natashaChatsList.push(obj);
      // this.chatsTobeShownOnUI.push(obj);
      // if (obj.condition11Status) {
      if (!obj.customFeatureAddedStatus) {
        this.cdRef.detectChanges();
      }
      this.scrollNatashaToBottom(true);
      // }

      // let uniqueIdentifier = obj.timeStamp;

      setTimeout(() => {
        obj.showLoader = false;

        this.cdRef.detectChanges();
        this.scrollNatashaToBottom();

        if (this.natashaPanel) {
          this.natashaUnreadMessageCount++;
        } else {
          this.natashaUnreadMessageCount = 0;
        }

        if (!this.natashaMobilePanel) {
          this.natashaMobileUnreadMessageCount++;
        } else {
          this.natashaMobileUnreadMessageCount = 0;
        }
        const elementId =
          '#wizardAnimation' + this.phaseService.natashaGroupIdentifier;
        const nbox = document.querySelector(elementId);

        if (nbox) {
          nbox.classList.remove('idleAnimation');

          setTimeout(() => {
            if (
              obj.condition8Status ||
              obj.natashaMultipleFeatureConditionStatus
            ) {
              const elementId = 'carousalAnimate' + obj.sliderIndex;
              const e1 = document.getElementById(elementId);
              e1.addEventListener('mouseenter', this.setSliderHolderPosition);
              // let natashaContainer = document.getElementById('natashaScrollMiddle');
              // let e2 = natashaContainer.querySelector('.ps-content');
              // e2.addEventListener("wheel", this.MouseWheelHandler);
            }
          }, 500);
          setTimeout(() => {
            nbox.classList.add('idleAnimation');
            const initialMessagesLength =
              this.checkInitialNatashaMessagesLength();
            if (
              (this.hasMetNatashaAgainComingFromTemplate ||
                this.hasMetNatashaFirstTime ||
                innerWidth <= 768) &&
              initialMessagesLength === 3 &&
              !(
                this.phaseService.selectedApps.length === 1 &&
                this.phaseService.selectedApps[0].user_requested === true
              )
            ) {
              this.areBundlesLoading = false;
              this.hideFeatureRightLoading = false;
              setTimeout(() => {
                this.fetchRecommendedFeatures();
              }, 3000);
            } else if (
              this.phaseService.selectedApps.length === 1 &&
              this.phaseService.selectedApps[0].user_requested === true &&
              (this.hasMetNatashaAgainComingFromTemplate ||
                this.hasMetNatashaFirstTime ||
                innerWidth <= 768) &&
              initialMessagesLength === 4
            ) {
              this.areBundlesLoading = false;
              this.hideFeatureRightLoading = false;
              // setTimeout(() => {
              //   this.fetchRecommendedFeatures();
              // }, 3000);
            }

            if (
              obj.natashaMultipleFeatureConditionStatus &&
              this.phaseService.isNatashaFirstBundleResponse
            ) {
              this.phaseService.isNatashaFirstBundleResponse = false;
            }

            // if (this.isPageRefreshed && this.chatsTobeShownOnUI.length === 4) {
            //   this.isPageRefreshed = false;
            // }
            // if (this.phaseService.buildCardEditMode && this.chatsTobeShownOnUI.length === 1) {
            //   this.cdRef.detectChanges();
            //   this.fetchRecommendedFeatures();
            // }
            this.scrollNatashaToBottom();
            this.cdRef.detectChanges();
          }, 3000);
        }
        //  this.cdRef.detectChanges();
      }, 2000);

      if (!this.isPageRefreshed) {
        setTimeout(() => {
          this.scrollNatashaToBottom();
          this.cdRef.detectChanges();
        }, 0);
      } else {
        this.shouldHideWelcomeMessage = false;
      }
    }
  }

  showResultDetail(id, records) {
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      'natasha_document_viewed',
      {}
    );
    this.searchResultDetailToBeDisplayed = records.filter(
      (data) => data.id === id
    )[0];
    // this.searchResultDetailToBeDisplayed.body = this.sanitizer.bypassSecurityTrustHtml(this.searchResultDetailToBeDisplayed.body);
    const obj = {
      showSearchResultDetailStatus: true,
      searchResultDetail: this.searchResultDetailToBeDisplayed,
      showLoader: true,
      type: 'natasha',
      createdAt: this.getTime(),
      timeStamp: this.phaseService.getCurrentTimestamp(),
    };
    this.addObjectToNatashaChat(obj);
    // this.showSearchResultDetail = true;
  }

  showHTMLParsedBodyContent(htmlString) {
    const parser = new DOMParser();
    this.searchResultDetailParsedBody = parser.parseFromString(
      htmlString,
      'text/html'
    );
    let parsedContent =
      this.searchResultDetailParsedBody.body.firstChild.innerHTML;
    if (parsedContent) {
      parsedContent = parsedContent.substring(0, 100);
    } else {
      parsedContent = '';
    }
    return parsedContent;
  }

  showMainNatashaScreen(isFAQClosed?) {
    this.showSearchResultDetail = false;
    this.showAllSearchResults = false;
    this.searchFAQInput = '';
    this.shouldOpenNatashaInfo = false;
  }

  saveFAQResponse(chat, response) {
    const obj = {
      article_id: chat.searchResultDetail.id,
      response: response,
    };
    this.apiRequest.saveNatashaFAQResponse(obj).subscribe((data) => {
      chat.searchResultDetail.response = response;
      this.cdRef.detectChanges();
    });
    this.trackFAQResponseEvent(response);
  }

  highlightNotAtAllFAQResponse(currentSearchResult) {
    if (
      currentSearchResult &&
      currentSearchResult.response &&
      currentSearchResult.response === 'not_at_all'
    ) {
      return true;
    }
    return false;
  }

  highlightNotExactlyFAQResponse(currentSearchResult) {
    if (
      currentSearchResult &&
      currentSearchResult.response &&
      currentSearchResult.response === 'not_exactly'
    ) {
      return true;
    }
    return false;
  }

  highlightYesFAQResponse(currentSearchResult) {
    if (
      currentSearchResult &&
      currentSearchResult.response &&
      currentSearchResult.response === 'yes'
    ) {
      return true;
    }
    return false;
  }

  showAllResultsForSearch() {
    this.showAllSearchResults = true;
  }

  showClickedResult(id) {
    this.showAllSearchResults = false;
    // this.showResultDetail(id);
  }

  fetchRecommendedFeatures(lastAction?, feature?, isLandedNotFromTemplate?) {
    if (this.phaseService.isCustomFeatureFlowFromNatasha) {
      this.phaseService.resetCustomFeatureFlowFromNatasha();
      this.resetFlagsInNatashaCustomFetaureQuestions();
    }

    const coreFeatures = this.phaseService.selectedFeatures.filter((obj) => {
      if (obj.is_recommended && obj.status === 'approved') {
        return obj;
      } else if (obj.status === 'approved') {
        return obj;
      }
    });
    // let features = [...coreFeatures, ...this.phaseService.recommendedFeaturesAdded];
    const featureIds = this.phaseService.getArrayOfOneFieldFromArrayOfObj(
      coreFeatures,
      'id'
    );

    const requestPayload = {
      build_card_id: this.dataService.buildCardData.id,
      application_ids: this.phaseService.getArrayOfOneFieldFromArrayOfObj(
        this.phaseService.selectedApps,
        'id'
      ),
      feature_ids: featureIds,
    };

    if (lastAction) {
      requestPayload['last_action'] = lastAction;
    }
    if (isLandedNotFromTemplate) {
      requestPayload['edit_feature_session'] = true;
    }
    this.getAllRecommendedFeatures(requestPayload, feature);
  }

  getAllRecommendedFeatures(requestPayload, feature?) {
    // this.getRandomFeatureValue();
    this.apiRequest
      .getRecommendedFeatures(requestPayload)
      .subscribe((recommendedfeaturesData) => {
        let obj;
        if (recommendedfeaturesData.item_type === 'feature') {
          if (
            recommendedfeaturesData &&
            recommendedfeaturesData.features &&
            recommendedfeaturesData.features.data.length > 0
          ) {
            this.recommendedFeatures =
              recommendedfeaturesData.features.data.map(
                (feature) => feature.attributes
              );
            if (recommendedfeaturesData.features.data.length === 1) {
              const messageIdentifier =
                'singleFeature_' + recommendedfeaturesData.item_id[0];
              obj = {
                itemId: recommendedfeaturesData.item_id[0],
                itemType: recommendedfeaturesData.item_type,
                questionText: recommendedfeaturesData.question_text,
                recommendedFeatures: this.recommendedFeatures,
                natashaSingleFeatureConditionStatus: true,
                showLoader: true,
                createdAt: this.getTime(),
                timeStamp: this.phaseService.getCurrentTimestamp(),
                shouldShowActionButtons: true,
                shouldShowSingleRecommendedFeature: true,
                messageIdentifier: messageIdentifier,
              };
            } else if (requestPayload.last_action[0].action_type === 'bundle') {
              const messageIdentifier =
                this.getMessageIdentifierForMultipleFeatures(
                  recommendedfeaturesData.item_id
                );
              let multipleFeatureBundleResponseText = '';
              if (this.phaseService.isNatashaFirstBundleResponse) {
                multipleFeatureBundleResponseText =
                  'I think you’ll want these features from ' +
                  this.recommendedFeatures[0].feature_bundle_name +
                  '.';
              } else {
                multipleFeatureBundleResponseText =
                  'Take a look at these features from ' +
                  this.recommendedFeatures[0].feature_bundle_name +
                  '.';
              }
              if (
                recommendedfeaturesData.reasons &&
                recommendedfeaturesData.reasons.length > 0
              ) {
                for (let i = 0; i < this.recommendedFeatures.length; i++) {
                  const obj = this.recommendedFeatures[i];
                  const searchString =
                    'add ' + this.recommendedFeatures[i].title.toLowerCase();
                  for (
                    let j = 0;
                    j < recommendedfeaturesData.reasons.length;
                    j++
                  ) {
                    const str = recommendedfeaturesData.reasons[j].toLowerCase();
                    if (str.includes(searchString)) {
                      const lastIndex = str.indexOf('add') + 4;
                      const subString = str.substring(0, lastIndex);
                      obj['reason'] = subString;
                      break;
                    }
                  }
                }
              }
              obj = {
                itemId: recommendedfeaturesData.item_id,
                itemType: recommendedfeaturesData.item_type,
                bundleResponseText: multipleFeatureBundleResponseText,
                recommendedFeatures: this.recommendedFeatures,
                sliderIndex: this.incrementRecommendedFeatureSliderIndex(),
                natashaMultipleFeatureConditionStatus: true,
                showLoader: true,
                createdAt: this.getTime(),
                timeStamp: this.phaseService.getCurrentTimestamp(),
                shouldShowActionButtons: true,
                shouldShowRecommendedFeatures: true,
                messageIdentifier: messageIdentifier,
              };
            } else {
              const messageIdentifier =
                this.getMessageIdentifierForMultipleFeatures(
                  recommendedfeaturesData.item_id
                );
              const text1 =
                'Based on the feature you\'ve just added and on those present in your shopping cart, I recommend these ' +
                this.recommendedFeatures.length +
                ' features.';
              obj = {
                itemId: recommendedfeaturesData.item_id,
                itemType: recommendedfeaturesData.item_type,
                text: text1,
                recommendedFeatures: this.recommendedFeatures,
                sliderIndex: this.incrementRecommendedFeatureSliderIndex(),
                condition8Status: true,
                showLoader: true,
                createdAt: this.getTime(),
                timeStamp: this.phaseService.getCurrentTimestamp(),
                shouldShowActionButtons: true,
                shouldShowRecommendedFeatures: true,
                messageIdentifier: messageIdentifier,
              };
            }
          }
        } else if (recommendedfeaturesData.item_type === 'bundle') {
          const messageIdentifier =
            'bundle_' + recommendedfeaturesData.item_id[0];
          obj = {
            itemId: recommendedfeaturesData.item_id[0],
            itemType: recommendedfeaturesData.item_type,
            questionText: recommendedfeaturesData.question_text,
            natashaBundleconditionStatus: true,
            showLoader: true,
            createdAt: this.getTime(),
            timeStamp: this.phaseService.getCurrentTimestamp(),
            shouldShowActionButtons: true,
            messageIdentifier: messageIdentifier,
          };
        } else if (recommendedfeaturesData.item_type === 'no_recommendation') {
          this.phaseService.trackEventForObject(
            this.analyticsSegment,
            'natasha_recommendation_stops',
            {
              user_id: this.dataService.user?.id,
            }
          );
          const noRecommendationText =
            'That\'s most of the features I think you need. If you want any extras, add them yourself from the catalogue on the left. Once you\'re ready, click the green button below to plan delivery.';
          obj = {
            itemType: recommendedfeaturesData.item_type,
            noRecommendationText: noRecommendationText,
            natashaNoRecommendationConditionStatus: true,
            showLoader: true,
            createdAt: this.getTime(),
            timeStamp: this.phaseService.getCurrentTimestamp(),
          };
        } else if (recommendedfeaturesData.item_type === 'feedback') {
          obj = {
            itemType: recommendedfeaturesData.item_type,
            askFeedbackText: recommendedfeaturesData.question_text,
            natashaAskFeedbackConditionStatus: true,
            showLoader: true,
            createdAt: this.getTime(),
            timeStamp: this.phaseService.getCurrentTimestamp(),
          };
        } else if (recommendedfeaturesData.item_type === 'still_need') {
          const messageIdentifier = 'still_need';
          obj = {
            itemId: recommendedfeaturesData.item_id[0],
            itemType: recommendedfeaturesData.item_type,
            questionText: recommendedfeaturesData.question_text,
            natashaIdleConditionStatus: true,
            showLoader: true,
            createdAt: this.getTime(),
            timeStamp: this.phaseService.getCurrentTimestamp(),
            shouldShowActionButtons: true,
            messageIdentifier: messageIdentifier,
          };
        }

        if (this.isShowNatashaLastIntroMessage) {
          this.isShowNatashaLastIntroMessage = false;
          if (obj && obj.itemType) {
            this.getNatashaHelpAnswerSomeQuestionsObject();
            setTimeout(() => {
              this.addObjectToNatashaChat(obj);
            }, 5000);
          }
        } else if (this.isAddManualFeatureFirstTime) {
          this.isAddManualFeatureFirstTime = false;
          if (obj && obj.itemType) {
            this.addManualFeatureFirstTime();
            setTimeout(() => {
              this.addObjectToNatashaChat(obj);
            }, 5000);
          }
        } else {
          this.addObjectToNatashaChat(obj);
        }
      });
  }

  createDynamicCard(feature, chat, index) {
    const animationDiv = document.querySelector(
      `#featureCardBox${feature.id}${index}`
    );
    const divConfig = animationDiv.getBoundingClientRect();
    const cardElement = `<div class="featureCardBox" style='position: fixed;top: ${
      divConfig.top
    }px;left: ${divConfig.left}px; z-index: 99'  id='featureCardBox${
      feature.id
    }'>
      <div class="featureCardInner">
        <div class="featureIcon">
          <img src="${this.utilityService.addHttps(
            this.getPreviewOfFeature(index, true, chat)
          )}" alt="">
        </div>
        <div class="featureDetail">
          <h3>${feature.title}</h3>
          <h4>${feature.feature_bundle_name}</h4>
          <p>${this.chekIfFeatureIsInSelected(feature) ? 'from ' : ''}${
      this.getCurrency().symbol
    }${this.getLocaledPrice(this.chekIfFeatureIsInSelected(feature))}</p>
          <p>${
            this.chekIfFeatureIsInSelected(feature) ? 'from ' : ''
          }${this.chekIfFeatureIsInSelected(
      feature
    )} ${this.getWeeksAndDaysText(feature)}</p>
          <div class="toolButtons">
            <button type="button" class="viewButton"><em class="icon-eye"></em></button>
            <button type="button" class="addButton"><em class="icon-plus"></em></button>
          </div>
        </div>
      </div>
    </div>`;

    animationDiv.insertAdjacentHTML('beforebegin', cardElement);
  }

  addRecommendedFeaturesFromNatashaBot(
    feature,
    chat,
    isFromSingleFeatureCondition?,
    e?,
    index?
  ) {
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      'natasha_recommended_feature_added',
      {}
    );
    feature.isActiveToAdd = true;
    if (e && !feature.selected) {
      this.createDynamicCard(feature, chat, index);
      let xDirection = 10;
      const animationDiv = document.querySelector(
        `#featureCardBox${feature.id}`
      );
      if (document.querySelector('.cartItemListing')) {
        xDirection = -animationDiv.getBoundingClientRect().left;
      }
      animationDiv.animate(
        [
          // keyframes
          { transform: 'rotate(-10deg)' },
          { transform: `translate(${xDirection}px, 1000px)` },
          { easing: 'ease' },
        ],
        {
          duration: 2400,
        }
      );

      setTimeout(() => {
        animationDiv.remove();
      }, 1200);
    }

    feature.is_recommended = true;
    setTimeout(() => {
      this.showFeatureAnimation = false;
      this.showNewRecommendations = false;
      feature.isActiveToAdd = false;
      setTimeout(() => {
        const chatKeys = Object.keys(this.chatsTobeShownOnUI);
        if (chat) {
          if (isFromSingleFeatureCondition) {
            for (const key in chatKeys) {
              const groupKey = chatKeys[key];
              const keyElements = this.chatsTobeShownOnUI[groupKey];
              for (let i = 0; i < keyElements.length; i++) {
                const obj = keyElements[i];
                if (chat.messageIdentifier === obj.messageIdentifier) {
                  obj.shouldShowSingleRecommendedFeature = false;
                  obj.shouldShowActionButtons = false;
                }
              }
            }
          } else {
            for (const key in chatKeys) {
              const groupKey = chatKeys[key];
              const keyElements = this.chatsTobeShownOnUI[groupKey];
              for (let i = 0; i < keyElements.length; i++) {
                const obj = keyElements[i];
                if (
                  obj.recommendedFeatures &&
                  obj.recommendedFeatures.length > 0
                ) {
                  for (let j = 0; j < obj.recommendedFeatures.length; j++) {
                    const recommendFeatureId = obj.recommendedFeatures[j].id;
                    if (recommendFeatureId === feature.id) {
                      this.removeRecommendedFeatureFromListAfterAddition(
                        feature,
                        obj,
                        e
                      );
                      break;
                    }
                  }
                }
              }
            }
          }
        }
        this.changeFocusedFeature(feature);
        this.natashaMessagesTimeTrack[8] = this.getTime();
        // this.showNewRecommendations = true;
        this.showFeatureAnimation = true;
        this.dataService.showDynamicPriceModal = true;
        this.dataService.dynamicPricingModalForSingleFeature = true;
      }, 400);
    }, 600);

    if (chat && chat.isFromCustomFeatureModal) {
      this.phaseService.isClearCustomFeatureModalValues = true;
    }
    //  setTimeout(() => {
    //   this.showDynamicPriceModal = true;
    //   // localStorage.removeItem('show_dynamic_pricing_popup');
    //  }, 1000);
  }

  getCurrentTime(s) {
    if (s) {
      return s;
    }
    return this.getTime();
  }

  getTime() {
    return this.phaseService.getCurrentTimeInHoursMinutes();
  }

  checkNatashaMessageCondition1(chat) {
    if (this.checkIfElementsInNatashaChatListExist() && chat.condition1Status) {
      // this.pulsateNatasha = this.phaseService.natashaChatsList[i].pulseCount;
      return true;
    }
    return false;
  }

  checkNatashaMessageCondition2(chat) {
    if (this.checkIfElementsInNatashaChatListExist() && chat.condition2Status) {
      // this.pulsateNatasha = this.phaseService.natashaChatsList[i].pulseCount;
      return true;
    }
    return false;
  }

  checkNatashaMessageCondition3(chat) {
    if (this.checkIfElementsInNatashaChatListExist() && chat.condition3Status) {
      // this.pulsateNatasha = this.phaseService.natashaChatsList[i].pulseCount;

      if (!this.shouldBlinkAnimation) {
        this.shouldBlinkAnimation = true;
        this.blinkAnimation = true;
        // this.cdRef.detectChanges();
      }
      return true;
    }
    if (!this.shouldBlinkAnimationForRecommendationToggle) {
      this.idleAnimationForChatButton();
    }
    return false;
  }

  checkNatashaMessageCondition4(chat) {
    if (this.checkIfElementsInNatashaChatListExist() && chat.condition4Status) {
      // this.pulsateNatasha = this.phaseService.natashaChatsList[i].pulseCount;

      return true;
    }
    return false;
  }

  checkNatashaMessageCondition5(chat) {
    if (this.checkIfElementsInNatashaChatListExist() && chat.condition5Status) {
      // this.pulsateNatasha = this.phaseService.natashaChatsList[i].pulseCount;

      return true;
    }
    return false;
  }

  checkNatashaMessageCondition6(chat) {
    if (this.checkIfElementsInNatashaChatListExist() && chat.condition6Status) {
      // this.pulsateNatasha = this.phaseService.natashaChatsList[i].pulseCount;
      return true;
    }
    return false;
  }

  checkNatashaStartFromScratchCondition(chat) {
    if (
      this.checkIfElementsInNatashaChatListExist() &&
      chat.natashaStartFromScratchConditionStatus
    ) {
      // this.pulsateNatasha = this.phaseService.natashaChatsList[i].pulseCount;
      return true;
    }
    return false;
  }

  checkNatashaMessageCondition8(chat) {
    if (this.checkIfElementsInNatashaChatListExist() && chat.condition8Status) {
      return true;
    }
    return false;
  }

  natashaMessageCondition9(chat) {
    if (
      this.recommendedFeatures &&
      this.phaseService.recommendedFeaturesActivity.length > 0 &&
      this.checkIfElementsInNatashaChatListExist() &&
      chat.condition9Status
    ) {
      return true;
    }
    return false;
  }

  natashaMessageCondition10(chat) {
    if (
      this.recommendedFeatures &&
      this.recommendedFeatures.length > 0 &&
      this.checkIfElementsInNatashaChatListExist() &&
      chat.condition10Status
    ) {
      // this.natashaMessagesTimeTrack[8] = this.getCurrentTime(this.natashaMessagesTimeTrack[8]);
      return true;
    }
    return false;
  }

  natashaMessageCondition11(chat) {
    if (
      this.checkIfElementsInNatashaChatListExist() &&
      chat.condition11Status
    ) {
      return true;
    }
    return false;
  }

  natashaMessageCondition12(chat) {
    if (chat.condition12Status) {
      return true;
    }
    return false;
  }

  natashaBundleQuestionCondition(chat) {
    if (chat.itemType === 'bundle') {
      return true;
    }
    return false;
  }

  natashaSingleFeatureCondition(chat) {
    if (
      chat.itemType === 'feature' &&
      chat.natashaSingleFeatureConditionStatus
    ) {
      return true;
    }
    return false;
  }

  natashaMultipleFeaturesCondition(chat) {
    if (
      chat.itemType === 'feature' &&
      chat.natashaMultipleFeatureConditionStatus
    ) {
      return true;
    }
    return false;
  }

  natashaIdleQuestionCondition(chat) {
    if (chat.natashaIdleConditionStatus) {
      return true;
    }
    return false;
  }

  natashaIdleQuestionAcceptedCondition(chat) {
    if (chat.natashaIdleConditionAcceptedStatus) {
      return true;
    }
    return false;
  }

  // natashaIdleQuestionRejectedCondition(i) {
  //   if (this.phaseService.natashaChatsList[i].natashaIdleConditionRejectedStatus) {
  //     return true;
  //   }
  //   return false;
  // }

  natashaDynamicPricingMessageCondition(chat) {
    if (chat.natashaDynamicPricingMessageStatus) {
      return true;
    }
    return false;
  }

  checkNatashaHelpAnswerSomeQuestionsCondition(chat) {
    if (
      this.checkIfElementsInNatashaChatListExist() &&
      chat.helpAnswerSomeQuestionsCondition
    ) {
      return true;
    }
    return false;
  }

  natashaManualFeatureAddFirstTimeCondition(chat) {
    if (chat.natashaManualFeatureAddFirstTimeStatus) {
      return true;
    }
    return false;
  }

  natashaAddFeatureCondition(chat) {
    if (chat.natashaAddFeatureStatus) {
      return true;
    }
    return false;
  }

  natashaNoRecommendationCondition(chat) {
    if (chat.natashaNoRecommendationConditionStatus) {
      return true;
    }
    return false;
  }

  natashaAskFeedbackCondition(chat) {
    if (chat.natashaAskFeedbackConditionStatus) {
      return true;
    }
    return false;
  }

  natashaFeedbackThanksCondition(chat) {
    if (chat.natashafeedbackThanksConditionStatus) {
      return true;
    }
    return false;
  }

  natashaEditModeAlreadyAddedBuildCardFeaturesCondition(chat) {
    if (
      chat.natashaEditModeAlreadyAddedBuildCardFeaturesStatus &&
      chat.recommendedFeaturesHistory &&
      chat.recommendedFeaturesHistory.length > 0
    ) {
      return true;
    }
    return false;
  }

  isNatashaEditModeAlreadyAddedBuildCardFeatures() {
    if (
      this.phaseService.buildCardEditMode &&
      this.phaseService.recommendedFeaturesHistory &&
      this.phaseService.recommendedFeaturesHistory.length > 0
    ) {
      return true;
    }
    return false;
  }

  addAllRecommendedFeatures(chat) {
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      'natasha_recommended_feature_sets_added',
      {
        user_id: this.dataService.user?.id,
      }
    );
    const featureTitles = [];
    const lastAction = [];
    while (chat.recommendedFeatures && chat.recommendedFeatures.length > 0) {
      const feature = chat.recommendedFeatures[0];
      feature.is_recommended = true;
      this.animateAllRecommendedFeatures = true;
      this.changeFocusedFeature(feature);
      const lastActionObject = this.getLastActionObject(
        'feature',
        true,
        feature.id
      );
      lastAction.push(lastActionObject);
      featureTitles.push(feature.title);
      const chatKeys = Object.keys(this.chatsTobeShownOnUI);
      for (const key in chatKeys) {
        const groupKey = chatKeys[key];
        const keyElements = this.chatsTobeShownOnUI[groupKey];
        for (let i = 0; i < keyElements.length; i++) {
          const obj = keyElements[i];
          if (obj.recommendedFeatures && obj.recommendedFeatures.length > 0) {
            for (let j = 0; j < obj.recommendedFeatures.length; j++) {
              const recommendFeatureId = obj.recommendedFeatures[j].id;
              if (recommendFeatureId === feature.id) {
                this.removeRecommendedFeatureFromListAfterAddition(
                  feature,
                  obj
                );
                break;
              }
            }
          }
        }
      }
    }
    // });

    // let obj = {
    //   id: 1234,
    //   record_text: featureTitles && (featureTitles.length > 0) ? featureTitles.join() : '',
    //   created_at: this.getTime(),
    //   condition9Status: true,
    //   showLoader: true,
    //   timeStamp: this.phaseService.getCurrentTimestamp()
    // };
    setTimeout(() => {
      this.showFeatureAnimation = false;
      this.showNewRecommendations = false;
      this.animateAllRecommendedFeatures = false;
      setTimeout(() => {
        // chat.shouldShowRecommendedFeatures = false;
        // chat.shouldShowActionButtons = false;

        // for(let i=0; i<this.chatsTobeShownOnUI.length; i++) {
        //   let obj = this.chatsTobeShownOnUI[i];
        //   if (chat.messageIdentifier === obj.messageIdentifier) {
        //     obj.shouldShowRecommendedFeatures = false;
        //     obj.shouldShowActionButtons = false;
        //   }
        // }

        const recordText =
          'Awesome! I have added all of the recommended features.';
        this.natashaAddFeatureMessage(recordText);
        // this.phaseService.recommendedFeaturesActivity.push(obj);
        // this.addinNatashChatList(obj);
        // if (this.dataService.showFeatureRecommendationsConfirmModalSetting) {

        setTimeout(() => {
          this.fetchRecommendedFeatures(lastAction);
        }, 5000);
        // } else if (!this.dataService.showFeatureRecommendationsConfirmModalSetting) {
        //   this.scrollNatashaToBottom();
        // }
        this.natashaMessagesTimeTrack[8] = this.getTime();
        this.showNewRecommendations = true;
        this.showFeatureAnimation = true;
        this.dataService.showDynamicPriceModal = true;
        this.dataService.dynamicPricingModalForAllRecommendedFeatures = true;
      }, 400);
    }, 600);

    //  setTimeout(() => {
    //   this.showDynamicPriceModal = true;
    //   // localStorage.removeItem('show_dynamic_pricing_popup');
    //  }, 1000);
  }

  showFeaturePageSettings() {
    this.dataService.showSettingsPopup = true;
    this.dataService.natashaDropdownPanel = false;
    this.dataService.showDynamicPricingModalSetting =
      this.dataService.dynamicPricingSetting.value;
    this.dataService.showFeatureRemovalConfirmModalSetting =
      this.dataService.featureRemovalConfirmSetting.value;
    this.dataService.showFeatureRecommendationsConfirmModalSetting =
      this.dataService.featureRecommendationsConfirmSetting.value;
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      'natasha_wizard_menu_selected',
      {
        elements_clicked: 'Settings',
      }
    );
  }

  closeSettingsPopup() {
    this.dataService.showFeatureRecommendationsConfirmModalSetting =
      this.dataService.featureRecommendationsConfirmSetting.value;
    this.dataService.showSettingsPopup = false;
    this.dataService.natashaDropdownPanel = false;
  }

  toggleDynamicPricingSetting() {
    this.dataService.showDynamicPricingModalSetting =
      !this.dataService.showDynamicPricingModalSetting;
  }

  toggleFeatureRemovalConfirmSetting() {
    this.dataService.showFeatureRemovalConfirmModalSetting =
      !this.dataService.showFeatureRemovalConfirmModalSetting;
  }

  applyFeaturePageSettings(isApplyClicked?) {
    this.phaseService.applyFeaturePageSettings(isApplyClicked);
  }

  leftBoundStat(reachesLeftBound: boolean, source: string, index) {
    switch (source) {
      case 'recommendedFeatureSlider' + index:
        this.leftNavDisabled = reachesLeftBound;
        break;
      default:
        break;
    }
  }

  rightBoundStat(reachesRightBound: boolean, source: string, index) {
    switch (source) {
      case 'recommendedFeatureSlider' + index:
        this.rightNavDisabled = reachesRightBound;
        break;
      default:
        break;
    }
  }

  moveLeft(source: string, index) {
    switch (source) {
      case 'recommendedFeatureSlider' + index:
        if (this.rds) {
          const leftCarousalArray = this.rds.toArray();
          if (leftCarousalArray.length >= 0 && index >= 0) {
            leftCarousalArray[index].moveLeft();
            this.scrolledIndex--;
          }
        }

        break;
      default:
        break;
    }
  }

  moveRight(source: string, index) {
    switch (source) {
      case 'recommendedFeatureSlider' + index:
        if (this.rds) {
          const rightCarousalArray = this.rds.toArray();
          if (rightCarousalArray.length >= 0 && index >= 0) {
            rightCarousalArray[index].moveRight();
            this.scrolledIndex++;
          }
        }

        break;
      default:
        break;
    }
  }

  trackFAQResponseEvent(response) {
    let feedbackResponseText;
    switch (response) {
      case 'not_at_all':
        feedbackResponseText = 'Oh dear! Sorry about that...';
        break;
      case 'not_exactly':
        feedbackResponseText = 'Hummmmm, I’ll try better next time.';
        break;
      case 'yes':
        feedbackResponseText = 'Happy to help!';
        break;
      default:
        break;
    }
    const obj = {
      showNatashaSearchFeedbackStatus: true,
      natashaSearchFeedback: feedbackResponseText,
      showLoader: true,
      type: 'natasha',
      createdAt: this.getTime(),
      timeStamp: this.phaseService.getCurrentTimestamp(),
    };
    this.addObjectToNatashaChat(obj);
  }

  talkToExpertsClicked() {
    if (!this.dataService.user) {
      this.phaseService.openCommonAuth = true;
      if (this.dataService.homePageData?.block_sign_up) {
        this.phaseService.showSignin = true;
        this.phaseService.showRegister = false;
      } else {
        this.phaseService.showRegister = true;
        this.phaseService.showSignin = false;
      }
      this.dataService.openMeetNowModal = true;
    } else {
      this.dataService.showMeetNowModel = true;
    }
  }

  resetRangeFilters(filterName?) {
    if (filterName === 'duration') {
      this.rangeDuration = [
        this.featureFilter.bundle_details.min_global_weeks,
        this.featureFilter.bundle_details.max_global_weeks,
      ];
    } else {
      this.rangePrice = [
        this.featureFilter.bundle_details.min_global_price,
        this.featureFilter.bundle_details.max_global_price,
      ];
    }
  }

  idleAnimationForChatButton() {
    this.shouldBlinkAnimation = false;
    this.blinkAnimation = false;
  }

  moveWizardDotFromRemoveAllPopupToNatasha() {
    setTimeout(() => {
      this.mainwizardDot = false;
      this.removeAllToRight = false;
      this.cdRef.detectChanges();
    }, 400);
    this.rightToRemoveall = false;
    this.removeAllToRight = true;
  }

  showNatashaInfo() {
    this.natashaPanel = false;
    this.shouldOpenNatashaInfo = true;
    this.dataService.natashaDropdownPanel = false;
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      'natasha_wizard_menu_selected',
      {
        elements_clicked: 'Natasha Info',
      }
    );
  }

  initializeNatashaMessages() {
    this.getNatashaCondition5Object();

    this.getNatashaCondition2Object();
    // this.getNatashaDynamicPricingObject();
    this.getNatashaHelpAnswerSomeQuestionsObject();
    if (
      this.phaseService.selectedApps.length === 1 &&
      this.phaseService.selectedApps[0].user_requested === true
    ) {
      const obj = this.getNatashaStartFromScratchObject();
      this.addObjToChatList(obj);
    }
    // this.getNatashaCondition1Object();
    // this.getNatashaCondition3Object();
    // this.getNatashaCondition6Object();
    // this.getNatashaCondition7Object();
  }

  natashaMessageCondition1() {
    if (
      this.hasMetNatashaFirstTime ||
      this.getSelectedFeatures().length === 0
    ) {
      return true;
    }
    return false;
  }

  natashaMessageCondition2() {
    return true;
  }

  natashaMessageCondition3() {
    if (
      (!(
        this.dataService.user && this.appDataService.urlParameters.buildcard
      ) &&
        !this.hasMetNatashaAgainComingFromTemplate &&
        !this.hasMetNatashaAgainComingNotFromTemplate) ||
      (!this.dataService.buildCardData.id &&
        this.getSelectedFeatures().length === 0) ||
      this.hasMetNatashaFirstTime
    ) {
      return true;
    }
    return false;
  }

  natashaMessageCondition4() {
    if (this.dataService.notifyTutorialComing) {
      return true;
    }
    return false;
  }

  natashaMessageCondition5() {
    if (
      this.hasMetNatashaAgainComingFromTemplate ||
      this.hasMetNatashaFirstTime ||
      innerWidth <= 768 ||
      this.getSelectedFeatures().length === 0
    ) {
      return true;
    }
    return false;
  }

  natashaMessageCondition6() {
    if (
      this.hasMetNatashaAgainComingNotFromTemplate &&
      this.getSelectedFeatures().length > 0
    ) {
      return true;
    }
    return false;
  }

  natashaStartFromScratchCondition() {
    if (this.getSelectedFeatures().length === 0) {
      return true;
    }
    return false;
  }

  natashaMessageCondition8() {
    if (
      this.recommendedFeatures &&
      this.recommendedFeatures.length > 0 &&
      this.phaseService.recommendedFeaturesActivity.length === 0
    ) {
      return true;
    }
    return false;
  }

  getNatashaCondition1Object() {
    if (this.natashaMessageCondition1()) {
      const text1 =
        'I\'ll give you contexual information such as feature suggestions and best practices.';
      const text2 =
        'I\'m in my infancy. I\'m learning every day so watch out for new functionality.';
      const wordCount = this.phaseService.getWordCount(text1 + text2);
      const obj = {
        text1: text1,
        text2: text2,
        createdAt: this.getTime(),
        wordCount: wordCount,
        pulseCount: this.phaseService.getPulseCount(wordCount),
        condition1Status: true,
        showLoader: true,
        timeStamp: this.phaseService.getCurrentTimestamp(),
      };
      this.addObjToChatList(obj);
    }
  }

  addObjWithDelay(obj, delay: number) {
    setTimeout(() => {
      this.addinNatashChatList(obj);
      this.phaseService.natashaIntroMessagesList.push(obj);
      this.scrollNatashaToBottom();
    }, delay * 1000 + 1000);
  }

  addObjToChatList(obj) {
    this.phaseService.natashaTempIntroMessagesList.push(obj);
    if (this.phaseService.natashaIntroMessagesList.length !== 0) {
      const delay = this.phaseService.getNatashaMessageDelay();
      this.addObjWithDelay(obj, delay);
    } else {
      this.addinNatashChatList(obj);
      this.phaseService.natashaIntroMessagesList.push(obj);
    }
  }

  getNatashaCondition2Object() {
    if (this.natashaMessageCondition2()) {
      const text1 =
        'Prefer humans? Click ‘Talk to our experts’ (up there, on the right) anytime.';
      const wordCount = this.phaseService.getWordCount(text1);
      const obj = {
        text1: text1,
        createdAt: this.getTime(),
        wordCount: wordCount,
        pulseCount: this.phaseService.getPulseCount(wordCount),
        condition2Status: true,
        showLoader: true,
        timeStamp: this.phaseService.getCurrentTimestamp(),
      };
      this.addObjToChatList(obj);
    }
  }

  getNatashaCondition3Object() {
    if (this.natashaMessageCondition3()) {
      const text1 = 'First, here\'s a quick guide to what everything does.';
      const wordCount = this.phaseService.getWordCount(text1);
      const obj = {
        text1: text1,
        createdAt: this.getTime(),
        wordCount: wordCount,
        pulseCount: this.phaseService.getPulseCount(wordCount),
        condition3Status: true,
        showLoader: true,
        timeStamp: this.phaseService.getCurrentTimestamp(),
      };
      this.addObjToChatList(obj);
    }
  }

  getNatashaCondition4Object() {
    if (this.natashaMessageCondition4()) {
      let text1;
      if (this.dataService.user) {
        text1 =
          'Ok ' + this.dataService.user.first_name + ', tutorial coming up.';
      } else {
        text1 = 'Ok, tutorial coming up.';
      }
      const wordCount = this.phaseService.getWordCount(text1);
      const obj = {
        text1: text1,
        createdAt: this.getTime(),
        wordCount: wordCount,
        pulseCount: this.phaseService.getPulseCount(wordCount),
        condition4Status: true,
        showLoader: true,
        timeStamp: this.phaseService.getCurrentTimestamp(),
      };
      this.addinNatashChatList(obj);
      this.phaseService.natashaIntroMessagesList.push(obj);
    }
  }

  getNatashaCondition5Object() {
    if (this.natashaMessageCondition5()) {
      const text1 = 'Here’s a quick guide to what everything does.';
      const text2 = '(It’s in the chat menu, if you need it later.)';
      const wordCount = this.phaseService.getWordCount(text1 + text2);
      const obj = {
        text1: text1,
        text2: text2,
        createdAt: this.getTime(),
        wordCount: wordCount,
        pulseCount: this.phaseService.getPulseCount(wordCount),
        condition5Status: true,
        showLoader: true,
        timeStamp: this.phaseService.getCurrentTimestamp(),
      };
      this.addObjToChatList(obj);
    }
  }

  // getNatashaDynamicPricingObject() {
  //   let text1 = 'Features have a fixed cost and a dynamic customisation cost.';
  //   let wordCount = this.phaseService.getWordCount(text1);
  //   let obj = { text1: text1, wordCount: wordCount, pulseCount: this.phaseService.getPulseCount(wordCount), natashaDynamicPricingMessageStatus: true,
  //     showLoader: true, createdAt: this.getTime(), timeStamp: this.phaseService.getCurrentTimestamp()};
  //   this.addObjToChatList(obj);
  // }

  getNatashaHelpAnswerSomeQuestionsObject() {
    const text1 =
      'Let’s chat about how your idea will work. I’m going to ask you a few things so I can make useful suggestions.';
    const wordCount = this.phaseService.getWordCount(text1);
    const obj = {
      text1: text1,
      createdAt: this.getTime(),
      wordCount: wordCount,
      pulseCount: this.phaseService.getPulseCount(wordCount),
      helpAnswerSomeQuestionsCondition: true,
      showLoader: true,
      timeStamp: this.phaseService.getCurrentTimestamp(),
    };
    this.addObjToChatList(obj);
  }

  getNatashaCondition6Object() {
    if (this.natashaMessageCondition6()) {
      const text1 =
        'The Buildcard™ contains ' +
        this.getSelectedFeatures().length +
        ' features.';
      const text2 =
        'Add and remove as many as you wish to customise your product or remove all features to start from scratch';
      const wordCount = this.phaseService.getWordCount(text1 + text2);
      const obj = {
        text1: text1,
        text2: text2,
        createdAt: this.getTime(),
        wordCount: wordCount,
        pulseCount: this.phaseService.getPulseCount(wordCount),
        condition6Status: true,
        showLoader: true,
        timeStamp: this.phaseService.getCurrentTimestamp(),
      };
      this.addObjToChatList(obj);
    }
  }

  getNatashaStartFromScratchObject() {
    if (this.natashaStartFromScratchCondition()) {
      const text1 =
        'Ok, you\'re starting from scratch. As and how you add features from left catalogue I would assist you in adding relevant ' +
        'features further or if you are unsure you may click Talk to our expert from the panel above and speak to a human assistant.';
      const wordCount = this.phaseService.getWordCount(text1);
      const obj = {
        text1: text1,
        createdAt: this.getTime(),
        wordCount: wordCount,
        pulseCount: this.phaseService.getPulseCount(wordCount),
        natashaStartFromScratchConditionStatus: true,
        showLoader: true,
        timeStamp: this.phaseService.getCurrentTimestamp(),
      };
      return obj;
    }
  }

  // getNatashaCondition8Object() {
  //   if (this.natashaMessageCondition8()) {
  //     let text1 = "Based on the feature you've just added and on those present in your shopping cart, I recommend these " + this.recommendedFeatures.length + " features.";
  //     let wordCount = this.phaseService.getWordCount(text1);
  //     let obj = {
  //       text1: text1,
  //       recommendedFeatures: this.recommendedFeatures,
  //       createdAt: this.getTime(),
  //       wordCount: wordCount,
  //       pulseCount: this.phaseService.getPulseCount(wordCount),
  //       sliderIndex: this.incrementRecommendedFeatureSliderIndex(),
  //       condition8Status: true,
  //       showLoader: true,
  //       timeStamp: this.phaseService.getCurrentTimestamp()
  //     }
  //     this.addObjToChatList(obj);
  //   }
  // }

  getNatashaCondition12Object() {
    const text1 =
      'No problem, you can turn recommendations back on from the settings menu at the top.';
    const wordCount = this.phaseService.getWordCount(text1);
    const obj = {
      text1: text1,
      createdAt: this.getTime(),
      wordCount: wordCount,
      pulseCount: this.phaseService.getPulseCount(wordCount),
      condition12Status: true,
      showLoader: true,
      timeStamp: this.phaseService.getCurrentTimestamp(),
    };
    this.addinNatashChatList(obj);
    this.scrollNatashaToBottom();
  }

  scrollNatashaToBottom(isFirstScrollOfMessage?) {
    if (
      (this.isCarousalExpanded ||
        (this.homeTemplateService.tempateNatashaChatList &&
          this.objectKeys(this.homeTemplateService.tempateNatashaChatList)
            .length > 0 &&
          !this.phaseService.isCollapseTemplateHistory)) &&
      isFirstScrollOfMessage
    ) {
      this.showNewNatashaMessageButton = true;
    } else if (
      this.perfectScrollNatasha &&
      this.perfectScrollNatasha.directiveRef &&
      !this.isCarousalExpanded &&
      ((this.homeTemplateService.tempateNatashaChatList &&
        this.objectKeys(this.homeTemplateService.tempateNatashaChatList)
          .length > 0 &&
        this.phaseService.isCollapseTemplateHistory) ||
        (this.homeTemplateService.tempateNatashaChatList &&
          this.objectKeys(this.homeTemplateService.tempateNatashaChatList)
            .length === 0))
    ) {
      this.showNewNatashaMessageButton = false;
      this.perfectScrollNatasha.directiveRef.update();
      this.perfectScrollNatasha.directiveRef.scrollToBottom(0, 600);
    }
  }

  incrementRecommendedFeatureSliderIndex() {
    const index = this.phaseService.recommendedFeaturesSliderIndex++;
    return index;
  }

  setFeaturesCount(features, isFromSearch?) {
    const buildCardData = this.dataService.buildCardData;
    if (
      buildCardData &&
      buildCardData.id &&
      buildCardData.modification_request &&
      buildCardData.modification_request['modify_data']['build_card_features']
    ) {
      buildCardData.modification_request['modify_data'][
        'build_card_features'
      ].forEach((feature) => {
        const ftr = features.find((f) => feature['feature_id'] === f.id);
        if (feature['feature_count'] && ftr) {
          ftr['feature_count'] = feature['feature_count'];
        }
      });
    } else if (!isFromSearch && buildCardData && buildCardData.id) {
      buildCardData.progress['buildcardfeatures'].forEach((feature) => {
        const ftr = features.find((f) => feature['feature_id'] === f.id);
        if (ftr && feature['feature_count'] && ftr) {
          ftr['feature_count'] = feature['feature_count'];
          if (this.isFeatureRequest) {
            ftr.repeatable_count = feature['feature_count'];
          }
        }
      });
    } else if (isFromSearch && buildCardData && buildCardData.id) {
      this.phaseService.selectedFeatures.forEach((feature) => {
        const ftr = features.find((f) => feature['id'] === f.id);
        if (ftr && feature['feature_count'] && ftr) {
          ftr['feature_count'] = feature['feature_count'];
        }
      });
    }
  }

  removeDeleteButton(item) {
    if (
      this.phaseService.isFeatureRequest &&
      this.dataService.buildCardData.progress &&
      this.dataService.buildCardData.progress.features.find(
        (el) => el.id === item.id
      )
    ) {
      return true;
    } else { return false; }
  }

  disbaleDecreaseAfterPayment() {
    const feature = this.dataService.additionalFeatures.find(
      (el) => el.title === 'Roles & Permissions'
    );
    if (
      feature &&
      this.dataService.buildCardData.progress.features.find(
        (el) => el.id === feature.id
      )
    ) {
      const preCount = this.dataService.buildCardData.progress.features.find(
        (el) => el.id === feature.id
      ).feature_count;
      if (feature.feature_count == preCount) {
        return true;
      } else { return false; }
    }
  }

  IncDecFeatureCount(feature, incOrDec) {
    if (
      incOrDec === 'decrease' &&
      feature.feature_count > feature.repeatable_count
    ) {
      feature.feature_count = feature.feature_count - 1;
    } else if (incOrDec === 'increase') {
      feature.feature_count = feature.feature_count + 1;
    }
    if (this.isFeatureRequest) {
      let ftr = this.dataService.additionalFeatures.find(
        (f) => f.id === feature.id
      );
      if (!ftr) {
        feature.disabled = false;
        this.dataService.additionalFeatures.push(feature);
        ftr = this.dataService.additionalFeatures.find(
          (f) => f.id === feature.id
        );
      }
      ftr.feature_count = feature.feature_count;
      ftr.repeatable_count = 1;
    } else {
      this.phaseService.selectedFeatures.find(
        (f) => f.id === feature.id
      ).feature_count = feature.feature_count;
    }
    const mvpPhase = this.dataService.homePageData.build_phases[3];
    if (mvpPhase.features && mvpPhase.features.length > 0) {
      const featureInMvp =
        this.dataService.homePageData.build_phases[3].features.find(
          (f) => f.id === feature.id
        );
      if (featureInMvp) {
        featureInMvp.feature_count = feature.feature_count;
        if (this.isFeatureRequest) {
          featureInMvp.repeatable_count = 1;
        }
      }
    }
    // if (feature.feature_count) {
    //   const ftr = this.dataService.buildCardData.progress[
    //     "buildcardfeatures"
    //   ].find((el) => el.feature_id === feature.id);
    //   if (
    //     ftr &&
    //     ftr.feature_count &&
    //     feature.feature_count === ftr.feature_count
    //   ) {
    //     this.phaseService.selectedFeatures.find(
    //       (ele) => ele.id === feature.id
    //     ).disabled = true;
    //   }
    // }
    this.phaseService.isCountChanged = false;
    this.phaseService.updatePrice();
    if (this.previewedFeature && this.previewedFeature.id) {
      this.previewedFeature = this.phaseService.selectedFeatures.find(
        (f) => f.id === this.previewedFeature.id
      );
    }
  }

  toggleFeatureRecommendationsConfirmSetting(isToggleFromNatasha?) {
    this.dataService.showFeatureRecommendationsConfirmModalSetting =
      !this.dataService.showFeatureRecommendationsConfirmModalSetting;
    if (isToggleFromNatasha) {
      this.applyFeaturePageSettings();
      if (!this.dataService.showFeatureRecommendationsConfirmModalSetting) {
        this.shouldBlinkAnimationForRecommendationToggle = true;
        if (!this.shouldBlinkAnimation) {
          this.shouldBlinkAnimation = true;
          this.blinkAnimation = true;
          setTimeout(() => {
            this.shouldBlinkAnimationForRecommendationToggle = false;
          }, 2000);
        }
        this.getNatashaCondition12Object();
        // this.idleAnimationForChatButton();
      }
    }
  }

  performActionsWhenUserLandedNotFromTemplate() {
    this.areBundlesLoading = false;
    this.isNatashaWelcomePopupOpened = false;
    this.openWelcomeFeaturePopup = false;
    this.dataService.natashaDropdownPanel = false;
    if (this.commonService.isPlatformBrowser) {
      localStorage.setItem('show_welcome_popup', 'NO');
      localStorage.setItem('tutorial_seen', 'true');
      sessionStorage.setItem('show_natasha_again', 'false');
    }
    if (!this.checkIfNatashaChatsContainLastIntroMessage()) {
      this.isShowNatashaLastIntroMessage = true;
    }
    this.fetchRecommendedFeatures(null, null, true);
    // this.initializeNatashaMessages();
    // this.scrollNatashaToBottom();
  }

  pulsateNatashaWizard(nbox) {
    if (nbox) {
      nbox.classList.remove('idleAnimation');
      setTimeout(() => {
        nbox.classList.add('idleAnimation');
      }, 3000);
    }
  }

  getLastActionObject(actionType, userAccepted, itemId) {
    const obj = {
      action_type: actionType,
      user_accepted: userAccepted,
      item_id: itemId,
    };
    return obj;
  }

  natashaBundleQuestionAccepted(
    chat,
    userTypedInput?,
    isFromFeedback?,
    isFromNatasha?
  ) {
    let lastActionObject;
    if (!userTypedInput || (userTypedInput && isFromFeedback)) {
      lastActionObject = this.getLastActionObject('bundle', true, chat.itemId);
      this.phaseService.trackEvent(
        this.analyticsSegment,
        'bundle_question_sets_added',
        'user_id',
        this.dataService.user?.id
      );
    }
    const chatKeys = Object.keys(this.chatsTobeShownOnUI);
    for (const key in chatKeys) {
      const groupKey = chatKeys[key];
      const keyElements = this.chatsTobeShownOnUI[groupKey];
      for (let i = 0; i < keyElements.length; i++) {
        const obj = keyElements[i];
        if (chat.messageIdentifier === obj.messageIdentifier) {
          obj.shouldShowActionButtons = false;
        }
      }
    }
    if (userTypedInput && !isFromFeedback && isFromNatasha) {
      this.recordUserInput(userTypedInput);
    } else if (userTypedInput && !isFromFeedback) {
      this.recordUserInput(userTypedInput);
      setTimeout(() => {
        this.fetchRecommendedFeatures();
      }, 1000);
    } else if (userTypedInput && isFromFeedback) {
      this.recordUserInput(userTypedInput);
      setTimeout(() => {
        this.passLastActionToRecommendedFeatures(lastActionObject);
      }, 1000);
    } else {
      this.recordUserInput('Yes');
      setTimeout(() => {
        this.passLastActionToRecommendedFeatures(lastActionObject);
      }, 1000);
    }
  }

  natashaBundleQuestionRejected(
    chat,
    userTypedInput?,
    isFromFeedback?,
    isFromNatasha?
  ) {
    let lastActionObject;
    if (userTypedInput && !isFromFeedback) {
      this.phaseService.resetCustomFeatureFlowFromNatasha();
    } else {
      lastActionObject = this.getLastActionObject('bundle', false, chat.itemId);
      this.phaseService.trackEvent(
        this.analyticsSegment,
        'bundle_question_sets_denied',
        'user_id',
        this.dataService.user?.id
      );
    }
    const chatKeys = Object.keys(this.chatsTobeShownOnUI);
    for (const key in chatKeys) {
      const groupKey = chatKeys[key];
      const keyElements = this.chatsTobeShownOnUI[groupKey];
      for (let i = 0; i < keyElements.length; i++) {
        const obj = keyElements[i];
        if (chat.messageIdentifier === obj.messageIdentifier) {
          obj.shouldShowActionButtons = false;
        }
      }
    }

    if (userTypedInput && !isFromFeedback && isFromNatasha) {
      this.recordUserInput(userTypedInput);
      if (this.phaseService.isCustomFeatureFlowFromNatasha) {
        this.phaseService.resetCustomFeatureFlowFromNatasha();
        this.resetFlagsInNatashaCustomFetaureQuestions();
      }
    } else if (userTypedInput && !isFromFeedback) {
      this.recordUserInput(userTypedInput);
      setTimeout(() => {
        this.fetchRecommendedFeatures();
      }, 1000);
    } else if (userTypedInput && isFromFeedback) {
      this.recordUserInput(userTypedInput);
      setTimeout(() => {
        this.passLastActionToRecommendedFeatures(lastActionObject);
      }, 1000);
    } else {
      this.recordUserInput('No');
      setTimeout(() => {
        this.passLastActionToRecommendedFeatures(lastActionObject);
      }, 1000);
    }
  }

  natashaSingleFeatureRejected(chat) {
    const lastActionObject = this.getLastActionObject(
      'feature',
      false,
      chat.itemId
    );
    chat.shouldShowActionButtons = false;
    this.recordUserInput('Not Interested');
    this.passLastActionToRecommendedFeatures(lastActionObject);
  }

  natashaMultipleFeatureRejected(chat) {
    chat.shouldShowActionButtons = false;
    this.recordUserInput('Not Interested');
    const lastAction = [];
    let lastActionObject;
    for (let i = 0; i < chat.itemId.length; i++) {
      lastActionObject = this.getLastActionObject(
        'feature',
        false,
        chat.itemId[i]
      );
      lastAction.push(lastActionObject);
    }
    this.fetchRecommendedFeatures(lastAction);
    // this.passLastActionToRecommendedFeatures(lastActionObject);
  }

  passLastActionToRecommendedFeatures(lastActionObject) {
    const lastAction = [];
    if (lastActionObject) {
      lastAction.push(lastActionObject);
    }
    this.fetchRecommendedFeatures(lastAction);
  }

  recordUserInput(userInput) {
    const obj = {
      input: userInput,
      resultRecords: [],
      createdAt: this.getTime(),
      condition11Status: true,
      showLoader: false,
      timeStamp: this.phaseService.getCurrentTimestamp(),
      isResponse: false,
    };
    this.addObjectInNatashaGroup(obj);
    // this.scrollNatashaToBottom();
  }

  addObjectToNatashaChat(obj) {
    if (obj) {
      this.addinNatashChatList(obj);
      this.scrollNatashaToBottom();
    } else {
      this.scrollNatashaToBottom();
    }
  }

  natashaIdleQuestionAccepted(chat, userTypedInput?) {
    // chat.shouldShowActionButtons = false;
    const chatKeys = Object.keys(this.chatsTobeShownOnUI);
    for (const key in chatKeys) {
      const groupKey = chatKeys[key];
      const keyElements = this.chatsTobeShownOnUI[groupKey];
      for (let i = 0; i < keyElements.length; i++) {
        const obj = keyElements[i];
        if (chat.messageIdentifier === obj.messageIdentifier) {
          obj.shouldShowActionButtons = false;
        }
      }
    }
    if (userTypedInput) {
      this.recordUserInput(userTypedInput);
    } else {
      this.recordUserInput('Yes');
    }
    const text =
      'Great. Can we go back and answer this – so my suggestions make sense for your idea.';
    const obj = {
      text1: text,
      natashaIdleConditionAcceptedStatus: true,
      showLoader: true,
      createdAt: this.getTime(),
      timeStamp: this.phaseService.getCurrentTimestamp(),
      shouldShowActionButtons: true,
    };
    this.addObjectToNatashaChat(obj);
    setTimeout(() => {
      const lastAction = [
        {
          action_type: 'still_need',
          user_accepted: true,
        },
      ];
      this.fetchRecommendedFeatures(lastAction);
    }, 1000);
  }

  natashaIdleQuestionRejected(chat, userTypedInput?) {
    // chat.shouldShowActionButtons = false;

    const chatKeys = Object.keys(this.chatsTobeShownOnUI);
    for (const key in chatKeys) {
      const groupKey = chatKeys[key];
      const keyElements = this.chatsTobeShownOnUI[groupKey];
      for (let i = 0; i < keyElements.length; i++) {
        const obj = keyElements[i];
        if (chat.messageIdentifier === obj.messageIdentifier) {
          obj.shouldShowActionButtons = false;
        }
      }
    }
    if (userTypedInput) {
      this.recordUserInput(userTypedInput);
    } else {
      this.recordUserInput('No');
    }
    const lastAction = [
      {
        action_type: 'still_need',
        user_accepted: false,
      },
    ];
    this.fetchRecommendedFeatures(lastAction);
    if (this.phaseService.getNatashaIdleQuestionCount() < 3) {
      this.phaseService.natashaIdlQuestionTimeInterval = 300000;
    }
    // let text1 = 'Ok I won’t give you any more recommendations.';
    // let text2 = 'You can talk to our experts with the options below.';
    // let obj = { text1: text1, text2: text2, natashaIdleConditionRejectedStatus: true, showLoader: true, createdAt: this.getTime(), timeStamp: this.phaseService.getCurrentTimestamp(),
    //   shouldShowActionButtons: true };
    //   this.addObjectToNatashaChat(obj);
  }

  openDynamicPricingPopup() {
    this.dataService.showDynamicPriceModal = true;
    localStorage.removeItem('show_dynamic_pricing_popup');
  }

  showNatashaYesNoQuestionResponse() {
    if (this.natashaYesNoQuestionResponse) {
      const keys = Object.keys(this.chatsTobeShownOnUI);
      const lastKey = keys[keys.length - 1];
      const lastGroupOfChat = this.chatsTobeShownOnUI[lastKey];
      const lastElementOfLastNatashaGroup =
        lastGroupOfChat[lastGroupOfChat.length - 1];
      const response = this.natashaYesNoQuestionResponse.toLowerCase();
      if (lastElementOfLastNatashaGroup.itemType === 'bundle') {
        if (this.validateUserResponseInNatasha(response) === 'positive') {
          this.natashaBundleQuestionAccepted(
            lastElementOfLastNatashaGroup,
            this.natashaYesNoQuestionResponse,
            true
          );
        } else if (
          this.validateUserResponseInNatasha(response) === 'negative'
        ) {
          this.natashaBundleQuestionRejected(
            lastElementOfLastNatashaGroup,
            this.natashaYesNoQuestionResponse,
            true
          );
        } else {
          this.searchFAQInput = this.natashaYesNoQuestionResponse;
          this.searchNatashaFAQs();
        }
      } else if (lastElementOfLastNatashaGroup.itemType === 'still_need') {
        if (this.validateUserResponseInNatasha(response) === 'positive') {
          this.natashaIdleQuestionAccepted(
            lastElementOfLastNatashaGroup,
            this.natashaYesNoQuestionResponse
          );
        } else if (
          this.validateUserResponseInNatasha(response) === 'negative'
        ) {
          this.phaseService.trackEventForObject(
            this.analyticsSegment,
            'natasha_negative_response',
            {
              user_id: this.dataService.user?.id,
            }
          );
          this.natashaIdleQuestionRejected(
            lastElementOfLastNatashaGroup,
            this.natashaYesNoQuestionResponse
          );
        } else {
          this.searchFAQInput = this.natashaYesNoQuestionResponse;
          this.searchNatashaFAQs();
        }
      }

      this.natashaYesNoQuestionResponse = '';
    }
  }

  showInstantCall() {
    this.phaseService.showInstantSpecCallFlow = true;
    this.phaseService.openMeetNoeModal();
  }

  showScheduleCall() {
    this.phaseService.openMeetNoeModal();
  }

  natashaAddFeatureMessage(message) {
    const obj = {
      text1: message,
      resultRecords: [],
      createdAt: this.getTime(),
      natashaAddFeatureStatus: true,
      showLoader: true,
      timeStamp: this.phaseService.getCurrentTimestamp(),
    };
    this.addObjectToNatashaChat(obj);
    // this.phaseService.natashaChatsList.push(obj);
    // this.chatsTobeShownOnUI.push(obj);
    // this.scrollNatashaToBottom();
  }

  getMessageIdentifierForMultipleFeatures(itemIds) {
    let featureItemIds = '';
    itemIds.sort();
    for (let i = 0; i < itemIds.length; i++) {
      featureItemIds += itemIds[i];
    }
    const messageIdentifier = 'multipleFeatures_' + featureItemIds;
    return messageIdentifier;
  }

  showNatashaFeedbackResponse() {
    if (this.natashaFeedbackResponse) {
      const response = this.natashaFeedbackResponse.toLowerCase();
      // if (this.validateUserResponseInNatasha(response) === 'inputSearch') {
      //   this.searchFAQInput = this.natashaFeedbackResponse;
      //   this.searchNatashaFAQs();
      // } else {
      this.recordUserInput(this.natashaFeedbackResponse);
      this.sendNatashaFeedbackResponse(this.natashaFeedbackResponse);
      const feedackThanksObj = {
        feedbackThanksText:
          'Thank you for your feedback, we have recorded it for improvement purpose.',
        natashafeedbackThanksConditionStatus: true,
        showLoader: true,
        createdAt: this.getTime(),
        timeStamp: this.phaseService.getCurrentTimestamp(),
      };
      this.addObjectToNatashaChat(feedackThanksObj);
      // }
      this.natashaFeedbackResponse = '';
    }
  }

  objectKeys(obj) {
    return Object.keys(obj);
  }

  sendNatashaFeedbackResponse(feedbackResponse) {
    const obj = {
      action_type: 'feedback',
      attachment: {
        text: feedbackResponse,
      },
    };
    this.passLastActionToRecommendedFeatures(obj);
  }

  checkSameTemplateForReturnUser() {
    const appSelected = this.phaseService.selectedApps[0].title;
    if (localStorage.getItem('templatesVisited')) {
      let storedTemplates = [];
      storedTemplates = JSON.parse(localStorage.getItem('templatesVisited'));
      let isTemplateStored = false;
      for (let i = 0; i < storedTemplates.length; i++) {
        const item = storedTemplates[i];
        if (item === appSelected) {
          isTemplateStored = true;
          sessionStorage.setItem(
            'show_natasha_again',
            'not_from_template_page'
          );
          this.isShowSplashScreen = true;
        }
      }
      if (!isTemplateStored && storedTemplates.length < 10) {
        storedTemplates.push(appSelected);
        localStorage.setItem(
          'templatesVisited',
          JSON.stringify(storedTemplates)
        );
      }
    } else {
      const selectedTemplate = [];
      selectedTemplate.push(appSelected);
      localStorage.setItem(
        'templatesVisited',
        JSON.stringify(selectedTemplate)
      );
    }
  }

  removeRecommendedFeatureFromListAfterAddition(feature, obj, event?) {
    const index = obj.recommendedFeatures.findIndex((f) => f.id === feature.id);
    obj.recommendedFeatures.splice(index, 1);
    if (obj.recommendedFeatures && obj.recommendedFeatures.length === 0) {
      obj.shouldShowRecommendedFeatures = false;
      obj.shouldShowActionButtons = false;
      const oldIndex = obj.sliderIndex;
      obj.sliderIndex = -1;
      this.reassignSliderIndex(oldIndex);
      if (event && this.isCarousalExpanded) {
        this.isCarousalExpanded = false;
        this.resetSliderHolderPosition(event, true);
      }
    }
  }

  reassignSliderIndex(oldIndex) {
    const chatKeys = Object.keys(this.chatsTobeShownOnUI);
    for (const key in chatKeys) {
      const groupKey = chatKeys[key];
      const keyElements = this.chatsTobeShownOnUI[groupKey];
      for (let i = 0; i < keyElements.length; i++) {
        const obj = keyElements[i];
        if (obj && obj.sliderIndex && obj.sliderIndex >= oldIndex) {
          obj.sliderIndex = obj.sliderIndex - 1;
        }
      }
    }
    if (this.phaseService.recommendedFeaturesSliderIndex > 0) {
      this.phaseService.recommendedFeaturesSliderIndex =
        this.phaseService.recommendedFeaturesSliderIndex - 1;
    }
  }

  natashaSearchFeaturesRejected(chat) {
    chat.shouldShowActionButtons = false;
    this.recordUserInput('Not Interested');
    const obj = this.initiateCustomFeatureFlow();
    setTimeout(() => {
      this.addinNatashChatList(obj);
    }, 2000);
    this.scrollNatashaToBottom();
    this.cdRef.detectChanges();
  }

  checkIfNatashaChatsAreEmpty() {
    const keys = Object.keys(this.chatsTobeShownOnUI);
    if (keys.length === 0) {
      return true;
    }
    return false;
  }

  assignNatashaMessageType(obj) {
    if (obj.condition11Status && !obj.isResponse) {
      obj['type'] = 'user';
    } else {
      obj['type'] = 'natasha';
    }
  }

  checkIfObjBelongsToSameNatashaGroup(obj) {
    this.assignNatashaMessageType(obj);
    const keys = Object.keys(this.chatsTobeShownOnUI);
    const lastKey = keys[keys.length - 1];
    const lastGroupOfChat = this.chatsTobeShownOnUI[lastKey];
    const lastElementOfLastNatashaGroup =
      lastGroupOfChat[lastGroupOfChat.length - 1];

    if (lastElementOfLastNatashaGroup['type'] === obj['type']) {
      return true;
    }
    return false;
  }

  checkInitialNatashaMessagesLength() {
    const chatKeys = Object.keys(this.chatsTobeShownOnUI);
    let natashaChatLength = 0;
    if (chatKeys && chatKeys.length > 0) {
      for (const key in chatKeys) {
        const groupKey = chatKeys[key];
        natashaChatLength =
          natashaChatLength + this.chatsTobeShownOnUI[groupKey].length;
      }
    }
    return natashaChatLength - this.initialChatMessagesLength;
  }

  addObjectInNatashaGroup(obj) {
    if (this.checkIfNatashaChatsAreEmpty()) {
      this.assignNatashaMessageType(obj);
      this.phaseService.natashaGroupIdentifier =
        this.phaseService.natashaGroupIdText +
        this.phaseService.natashaGroupChatId;
      this.chatsTobeShownOnUI[this.phaseService.natashaGroupIdentifier] = [obj];
      this.phaseService.natashaChatsList[
        this.phaseService.natashaGroupIdentifier
      ] = [obj];
    } else if (
      !this.checkIfNatashaChatsAreEmpty() &&
      this.checkIfObjBelongsToSameNatashaGroup(obj)
    ) {
      this.chatsTobeShownOnUI[this.phaseService.natashaGroupIdentifier].push(
        obj
      );
      this.phaseService.natashaChatsList[
        this.phaseService.natashaGroupIdentifier
      ].push(obj);
    } else {
      this.phaseService.natashaGroupChatId++;
      this.phaseService.natashaGroupIdentifier =
        this.phaseService.natashaGroupIdText +
        this.phaseService.natashaGroupChatId;
      this.chatsTobeShownOnUI[this.phaseService.natashaGroupIdentifier] = [obj];
      this.phaseService.natashaChatsList[
        this.phaseService.natashaGroupIdentifier
      ] = [obj];
    }
  }

  checkIfElementsInNatashaChatListExist() {
    const keys = Object.keys(this.phaseService.natashaChatsList);
    if (keys && keys.length > 0) {
      return true;
    }
    return false;
  }

  getTypeOfNatashaGroupElement(key, list) {
    const groupElements = list[key];
    const firstGroupElement = groupElements[0];
    const typeOfGroupElement = firstGroupElement['type'];
    return typeOfGroupElement;
  }

  getCreatedDateOfFirstNatashaGroupElement(key, list) {
    const groupElements = list[key];
    const firstGroupElement = groupElements[0];
    const createdDateOfGroupElement = firstGroupElement['createdAt'];
    return createdDateOfGroupElement;
  }

  shouldNatashaSearchTakeYesNoResponse() {
    if (!this.checkIfNatashaChatsAreEmpty()) {
      const keys = Object.keys(this.chatsTobeShownOnUI);
      const lastKey = keys[keys.length - 1];
      const lastGroupOfChat = this.chatsTobeShownOnUI[lastKey];
      const lastElementOfLastNatashaGroup =
        lastGroupOfChat[lastGroupOfChat.length - 1];

      if (
        lastElementOfLastNatashaGroup['itemType'] &&
        (lastElementOfLastNatashaGroup['itemType'] === 'bundle' ||
          lastElementOfLastNatashaGroup['itemType'] === 'still_need')
      ) {
        return true;
      }
      return false;
    }
    return false;
  }

  shouldNatashaSearchTakeFeedbackResponse() {
    if (!this.checkIfNatashaChatsAreEmpty()) {
      const keys = Object.keys(this.chatsTobeShownOnUI);
      const lastKey = keys[keys.length - 1];
      const lastGroupOfChat = this.chatsTobeShownOnUI[lastKey];
      const lastElementOfLastNatashaGroup =
        lastGroupOfChat[lastGroupOfChat.length - 1];

      if (
        lastElementOfLastNatashaGroup['itemType'] &&
        lastElementOfLastNatashaGroup['itemType'] === 'feedback'
      ) {
        return true;
      }
      return false;
    }
    return false;
  }

  recordNatashaFeatureAdditionRemovalMessage(
    feature,
    shouldSelectAllFeaturesInBundle?,
    isLastFeatureInBundle?
  ) {
    if (
      shouldSelectAllFeaturesInBundle === null ||
      shouldSelectAllFeaturesInBundle === undefined ||
      isLastFeatureInBundle
    ) {
      if (
        feature.is_recommended &&
        !this.animateAllRecommendedFeatures &&
        feature.selected
      ) {
        const lastActionObject = this.getLastActionObject(
          'feature',
          true,
          feature.id
        );
        if (
          this.phaseService.natashaCurrentAddedFeatureMessages &&
          this.phaseService.natashaCurrentAddedFeatureMessages.length === 0
        ) {
          this.phaseService.natashaCurrentAddedFeatureMessages = [
            ...this.phaseService.natashaAddedFeatureMessages,
          ];
        }
        const maxRandomRange =
          this.phaseService.natashaCurrentAddedFeatureMessages.length;
        const index = this.phaseService.getRandomInt(maxRandomRange);
        const text = this.phaseService.natashaCurrentAddedFeatureMessages[
          index
        ].replace('[featureName]', feature.title);
        this.natashaAddFeatureMessage(text);
        this.phaseService.natashaCurrentAddedFeatureMessages.splice(index, 1);
        setTimeout(() => {
          this.passLastActionToRecommendedFeatures(lastActionObject);
        }, 5000);
      } else if (
        !this.animateAllRecommendedFeatures &&
        feature.selected &&
        !localStorage.getItem('add_manual_feature_first_time')
      ) {
        localStorage.setItem('add_manual_feature_first_time', 'false');
        this.isAddManualFeatureFirstTime = true;
        const lastActionObject = this.getLastActionObject(
          'manual',
          true,
          feature.id
        );
        this.passLastActionToRecommendedFeatures(lastActionObject);
      } else if (!this.animateAllRecommendedFeatures && feature.selected) {
        const lastActionObject = this.getLastActionObject(
          'manual',
          true,
          feature.id
        );
        this.passLastActionToRecommendedFeatures(lastActionObject);
      } else if (!this.animateAllRecommendedFeatures && !feature.selected) {
        const lastActionObject = this.getLastActionObject(
          'manual',
          false,
          feature.id
        );
        this.passLastActionToRecommendedFeatures(lastActionObject);
      }
    }
  }

  setSliderHolderPosition(e) {
    e.target.querySelector('.sliderHolder').classList.add('hoveron');
    const fadeDiv = document.getElementById('studioFeatureanimation');
    fadeDiv.querySelector('.studioLeft').classList.add('fadeColumn');
    fadeDiv.querySelector('.studioCenter').classList.add('fadeColumn');
    fadeDiv.querySelector('.studioRight').classList.add('fadeColumn');
    e.target.querySelector(`.sliderHolder`).style.top =
      e.target.getBoundingClientRect().top + 'px';
    this.isCarousalExpanded = true;
    const natashaContainer = document.getElementById('natashaScrollMiddle');
    const el = natashaContainer.querySelector('.ps-content');
    el.addEventListener('wheel', this.MouseWheelHandler);
  }

  resetSliderHolderPosition(e, isLastCarousalElement?) {
    setTimeout(() => {
      this.closeSliderPosition(e, isLastCarousalElement);
    }, 600);
  }

  validateUserResponseInNatasha(response) {
    if (response === 'y' || response === 'yes' || response === 'yup') {
      return 'positive';
    } else if (response === 'n' || response === 'no' || response === 'nop') {
      return 'negative';
    } else {
      return 'inputSearch';
    }
  }

  addManualFeatureFirstTime() {
    const text1 =
      'Interesting. Based on the feature you’ve just added, I’ve a few more suggestions. Let me ask a couple of things first.';
    const obj = {
      text1: text1,
      natashaManualFeatureAddFirstTimeStatus: true,
      showLoader: true,
      createdAt: this.getTime(),
      timeStamp: this.phaseService.getCurrentTimestamp(),
    };
    this.addObjectToNatashaChat(obj);
  }

  addRemoveFeatureFirstTime(feature) {
    const text1 = 'Features have a fixed and customisation cost.';
    const wordCount = this.phaseService.getWordCount(text1);
    const obj = {
      text1: text1,
      wordCount: wordCount,
      pulseCount: this.phaseService.getPulseCount(wordCount),
      natashaDynamicPricingMessageStatus: true,
      showLoader: true,
      createdAt: this.getTime(),
      timeStamp: this.phaseService.getCurrentTimestamp(),
    };
    this.addObjectToNatashaChat(obj);
    if (!this.animateAllRecommendedFeatures) {
      setTimeout(() => {
        this.recordNatashaFeatureAdditionRemovalMessage(feature);
      }, 5000);
    }
  }

  checkIfNatashaChatsContainLastIntroMessage() {
    if (this.checkIfNatashaChatsAreEmpty()) {
      return false;
    }
    const chatKeys = Object.keys(this.chatsTobeShownOnUI);
    for (const key in chatKeys) {
      const groupKey = chatKeys[key];
      const keyElements = this.chatsTobeShownOnUI[groupKey];
      for (let i = 0; i < keyElements.length; i++) {
        const obj = keyElements[i];
        if (obj.helpAnswerSomeQuestionsCondition) {
          return true;
        }
      }
    }
    return false;
  }

  showNewNatashaMessages() {
    this.showNewNatashaMessageButton = false;
    this.phaseService.isCollapseTemplateHistory = true;
    this.scrollNatashaToBottom();
  }

  showTemplateHistory() {
    this.phaseService.isCollapseTemplateHistory = false;
    this.perfectScrollNatasha.directiveRef.update();
    this.perfectScrollNatasha.directiveRef.scrollToTop(0, 600);
  }

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      // rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom >= 0 &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  saveCustomFeatureTitleResponse() {
    this.recordUserInput(this.customFeatureTitle);
    this.phaseService.customFeatureObjectFromNatasha.title =
      this.customFeatureTitle;
    this.customFeatureTitle = '';
    this.phaseService.isShowCustomFeatureTitleQuestion = false;
    this.phaseService.isShowCustomFeatureDescriptionQuestion = true;
    const text =
      'Thanks, please can you describe this new feature in less than 1000 characters.';
    const obj = {
      customFeatureDescriptionQuestion: text,
      createdAt: this.getTime(),
      customFeatureDescriptionStatus: true,
      showLoader: true,
      timeStamp: this.phaseService.getCurrentTimestamp(),
    };
    // setTimeout(() => {
    this.addinNatashChatList(obj);
    // }, 2000);
    this.scrollNatashaToBottom();
    this.cdRef.detectChanges();
  }

  saveCustomFeatureDescriptionResponse() {
    this.recordUserInput(this.customFeatureDescription);
    this.phaseService.customFeatureObjectFromNatasha.description =
      this.customFeatureDescription;
    this.customFeatureDescription = '';
    this.phaseService.isShowCustomFeatureDescriptionQuestion = false;
    const text =
      'OK, thanks I just need to ask 4 more questions about your custom feature.';
    const obj = {
      fourMoreQuestionText: text,
      createdAt: this.getTime(),
      fourMoreQuestionStatus: true,
      showLoader: true,
      timeStamp: this.phaseService.getCurrentTimestamp(),
    };
    this.addinNatashChatList(obj);
    this.scrollNatashaToBottom();

    setTimeout(() => {
      const text = 'Firstly, how complex it?';
      const obj = {
        customFeatureComplexityText: text,
        createdAt: this.getTime(),
        customFeatureComplexityStatus: true,
        showLoader: true,
        timeStamp: this.phaseService.getCurrentTimestamp(),
        disableCustomFeatureComplexityOptions: false,
      };
      this.addinNatashChatList(obj);
      this.scrollNatashaToBottom();
      this.cdRef.detectChanges();
    }, 2000);

    this.cdRef.detectChanges();
  }

  saveCustomFeatureComplexity(complexityId, chat) {
    let userInput = '';
    switch (complexityId) {
      case 0:
        this.phaseService.customFeatureObjectFromNatasha.difficulty = 4;
        userInput = 'Not at all complex';
        break;
      case 1:
        this.phaseService.customFeatureObjectFromNatasha.difficulty = 7;
        userInput = 'Quite complex';
        break;
      case 2:
        this.phaseService.customFeatureObjectFromNatasha.difficulty = 10;
        userInput = 'Very complex';
        break;
      case 3:
        this.phaseService.customFeatureObjectFromNatasha.difficulty = 6;
        userInput = 'I don\'t know';
        break;
    }
    chat.disableCustomFeatureComplexityOptions = true;
    this.recordUserInput(userInput);
    const text = 'Great, does this new feature have any algorithms?';
    const obj = {
      customFeatureAlgorithmText: text,
      createdAt: this.getTime(),
      customFeatureAlgorithmStatus: true,
      showLoader: true,
      timeStamp: this.phaseService.getCurrentTimestamp(),
      disableCustomFeatureAlgorithmOptions: false,
    };
    this.addinNatashChatList(obj);
    this.scrollNatashaToBottom();
    this.cdRef.detectChanges();
  }

  saveCustomFeatureAlgorithm(algorithmId, chat) {
    let userInput = '';
    switch (algorithmId) {
      case 0:
        this.phaseService.customFeatureObjectFromNatasha.algorithm = 'none';
        userInput = 'No';
        break;
      case 1:
        this.phaseService.customFeatureObjectFromNatasha.algorithm = 'simple';
        userInput = 'Yes, simple algorithms';
        break;
      case 2:
        this.phaseService.customFeatureObjectFromNatasha.algorithm = 'complex';
        userInput = 'Yes, complex algorithms';
        break;
      case 3:
        this.phaseService.customFeatureObjectFromNatasha.algorithm =
          'machine_learning';
        userInput = 'Yes, machine learning';
        break;
      case 4:
        this.phaseService.customFeatureObjectFromNatasha.algorithm =
          'augmented_reality';
        userInput = 'Yes, augmented reality';
        break;
      case 5:
        this.phaseService.customFeatureObjectFromNatasha.algorithm = 'none';
        userInput = 'I don\'t know';
        break;
    }

    chat.disableCustomFeatureAlgorithmOptions = true;
    this.recordUserInput(userInput);
    const text = 'Does your feature need a user interface or is it backend only?';
    const obj = {
      customFeatureUserInterfaceText: text,
      createdAt: this.getTime(),
      customFeatureUserInterfaceStatus: true,
      showLoader: true,
      timeStamp: this.phaseService.getCurrentTimestamp(),
      disableCustomFeatureUserInterfaceOptions: false,
    };
    this.addinNatashChatList(obj);
    this.scrollNatashaToBottom();
    this.cdRef.detectChanges();
  }

  saveCustomFeatureUserInterface(userInterfaceId, chat) {
    let userInput = '';
    switch (userInterfaceId) {
      case 0:
        this.phaseService.customFeatureObjectFromNatasha.interface_type =
          'backend';
        userInput = 'It\'s backend only';
        break;
      case 1:
        this.phaseService.customFeatureObjectFromNatasha.interface_type =
          'both';
        userInput = 'It requires both';
        break;
      case 2:
        this.phaseService.customFeatureObjectFromNatasha.interface_type =
          'backend';
        userInput = 'I don\'t know';
        break;
    }

    chat.disableCustomFeatureUserInterfaceOptions = true;
    this.recordUserInput(userInput);
    const text =
      'Last question, if you can, please name up to 3 apps that have a similar feature. You can type in a link or just the names of the apps.';
    const obj = {
      customFeatureSimilarAppsText: text,
      createdAt: this.getTime(),
      customFeatureSimilarAppsStatus: true,
      showLoader: true,
      timeStamp: this.phaseService.getCurrentTimestamp(),
      disableCustomFeatureAppsNotProvidedOption: false,
    };
    this.phaseService.isShowCustomFeatureSimilarAppsQuestion = true;
    this.addinNatashChatList(obj);
    this.scrollNatashaToBottom();
    this.cdRef.detectChanges();
  }

  saveCustomFeatureSimilarAppsResponse() {
    if (this.customFeatureSimilarApps) {
      const appNames = this.customFeatureSimilarApps.split(',');
      const lastNatashaElement = this.getLastElementOfNatashaChats();
      this.recordUserInput(this.customFeatureSimilarApps);
      this.customFeatureSimilarApps = '';
      if (
        lastNatashaElement &&
        lastNatashaElement.customFeatureSimilarAppsStatus
      ) {
        lastNatashaElement.disableCustomFeatureAppsNotProvidedOption = true;
      }
      this.scrollNatashaToBottom();
      this.cdRef.detectChanges();
      for (const app of appNames) {
        const tempProduct = {
          product_name: app,
          description: '',
          url: '',
          image: '',
          tag: '',
        };
        this.phaseService.customFeatureObjectFromNatasha.reference_urls.push(
          tempProduct
        );
      }
      this.PreviewCustomFeatureInNatasha();
    }
  }

  customFeatureAppsNotProvided(chat) {
    this.recordUserInput('Sorry, I don\'t know any');
    chat.disableCustomFeatureAppsNotProvidedOption = true;
    this.scrollNatashaToBottom();
    this.cdRef.detectChanges();
    this.PreviewCustomFeatureInNatasha();
  }

  public setDataInPhases(phase) {
    if (phase.title !== 'MVP' && phase['type'] !== 'custom_phase') {
      phase.features = this.phaseService.selectedFeatures;
    }
  }

  public selectPhases(): void {
    for (const buildType of this.dataService.homePageData.build_phases) {
      this.setDataInPhases(buildType);
    }
  }

  public updateSelectedPhases() {
    this.phaseService.selectedPhases.forEach((phase) => {
      if (phase.title !== 'MVP' && phase['type'] !== 'custom_phase') {
        phase.features = this.phaseService.selectedFeatures;
      }
    });
  }

  addCustomFeature(chat) {
    this.recordUserInput('Yes, please add it');
    const feature = chat.customFeature;
    chat.shouldShowActionButtons = false;
    feature.selected = true;
    this.phaseService.addnewfeature = false;
    this.phaseService.requestedFeatureList.push(feature);
    this.phaseService.toggleSelectedFeatures(feature);
    this.phaseService.toggleSelectedFeatureId(feature.id);
    this.phaseService.updateFeaturedAddedMessage(feature);
    this.selectPhases();
    this.updateSelectedPhases();
    this.phaseService.updatePrice();
    const text =
      'Great, I\'ve added that feature for you. Once our team have reviewed this feature, we will confirm the final price.';
    const obj = {
      customFeatureAddedText: text,
      createdAt: this.getTime(),
      customFeatureAddedStatus: true,
      showLoader: true,
      timeStamp: this.phaseService.getCurrentTimestamp(),
    };
    this.phaseService.isClearCustomFeatureModalValues = true;
    this.addinNatashChatList(obj);
    this.scrollNatashaToBottom();
    this.previewedFeature = this.getPreviewedFeature();
    this.cdRef.detectChanges();
  }

  discardCustomFeature(chat) {
    this.recordUserInput('No thank you');
    chat.shouldShowActionButtons = false;
    this.phaseService.resetCustomFeatureFlowFromNatasha();
  }

  PreviewCustomFeatureInNatasha() {
    this.phaseService.isShowCustomFeatureSimilarAppsQuestion = false;
    if (this.phaseService.selectedHash.application_ids.length > 0) {
      this.phaseService.customFeatureObjectFromNatasha.template_ids =
        this.phaseService.selectedHash.application_ids;
    }
    this.apiRequest
      .addNewFeature(this.phaseService.customFeatureObjectFromNatasha)
      .subscribe((data: any) => {
        const feature = data.data.attributes;
        const text =
          'Thank you. This is a preview of your new feature with an estimated cost, based on your answers. Do you want me to go ahead and add it for you?';
        const obj = {
          customFeaturePreviewText: text,
          createdAt: this.getTime(),
          customFeaturePreviewStatus: true,
          showLoader: true,
          customFeature: feature,
          shouldShowActionButtons: true,
          timeStamp: this.phaseService.getCurrentTimestamp(),
        };
        this.phaseService.trackEventForObject(
          this.analyticsSegment,
          'natasha_custom_feature_created',
          {
            user_id: this.dataService.user?.id,
          }
        );
        this.addinNatashChatList(obj);
        this.scrollNatashaToBottom();
        this.cdRef.detectChanges();
      });
  }

  getLastElementOfNatashaChats() {
    const keys = Object.keys(this.chatsTobeShownOnUI);
    if (keys && keys.length > 0) {
      const lastKey = keys[keys.length - 1];
      const lastGroupOfChat = this.chatsTobeShownOnUI[lastKey];
      const lastElementOfLastNatashaGroup =
        lastGroupOfChat[lastGroupOfChat.length - 1];
      return lastElementOfLastNatashaGroup;
    }
    return null;
  }

  resetFlagsInNatashaCustomFetaureQuestions() {
    const chatKeys = Object.keys(this.chatsTobeShownOnUI);
    for (const key in chatKeys) {
      const groupKey = chatKeys[key];
      const keyElements = this.chatsTobeShownOnUI[groupKey];
      for (let i = 0; i < keyElements.length; i++) {
        const obj = keyElements[i];
        if (obj && obj.customFeatureComplexityStatus) {
          obj.disableCustomFeatureComplexityOptions = true;
        }
        if (obj && obj.customFeatureAlgorithmStatus) {
          obj.disableCustomFeatureAlgorithmOptions = true;
        }
        if (obj && obj.customFeatureUserInterfaceStatus) {
          obj.disableCustomFeatureUserInterfaceOptions = true;
        }
      }
    }
  }

  initiateCustomFeatureFlow() {
    this.phaseService.resetCustomFeatureFlowFromNatasha();
    this.phaseService.isCustomFeatureFlowFromNatasha = true;
    this.phaseService.isShowCustomFeatureTitleQuestion = true;
    const text = 'So you want to start a custom feature?';
    const obj = {
      customFeatureTitleQuestion: text,
      createdAt: this.getTime(),
      customFeatureTitleStatus: true,
      showLoader: true,
      timeStamp: this.phaseService.getCurrentTimestamp(),
      shouldShowActionButtons: true,
    };
    return obj;
  }

  isOverflowing(el) {
    return el.offsetHeight + 5 < el.scrollHeight;
  }

  descriptionHover() {
    this.descriptionZindex = !this.descriptionZindex;
  }

  showDescription(e, title) {
    this.featureDescriptionTop = e.target.getBoundingClientRect().top;
    this.featureDescriptionLeft = e.target.getBoundingClientRect().left;
    this.featuredescription = title;
    console.log('enter');
  }

  hideDescription(e) {
    this.featureDescriptionTop = 0;
    this.featureDescriptionLeft = 0;
    this.featuredescription = '';
    console.log('leave');
  }

  selectAllFeaturesFromBundle(
    shouldSelectAllFeaturesInBundle,
    bundle: BundleModel
  ) {
    if (
      shouldSelectAllFeaturesInBundle &&
      !this.getFeaturePopupDataInSessionStorage() &&
      this.dataService.user?.roles !== 'CPE'
    ) {
      const featuresToAdd =
        this.getFeatureCountInBundle(bundle) -
        this.getSelectedFeaturesCount(bundle);
      this.checkSelectedFeatureCount(featuresToAdd);
      if (this.isShowMaxFeaturePopUp) {
        this.bundleFeaturesToBeSelectedAfterUserConfirmation = bundle;
        return;
      }
    }
    this.changeBundleFeatureSelection(bundle, shouldSelectAllFeaturesInBundle);
  }

  changeBundleFeatureSelection(bundle, shouldSelectAllFeaturesInBundle) {
    for (let i = 0; i < bundle.features.length; i++) {
      const feature = bundle.features[i];
      if (
        (shouldSelectAllFeaturesInBundle && !feature.selected) ||
        (!shouldSelectAllFeaturesInBundle && feature.selected)
      ) {
        if (i === bundle.features.length - 1) {
          this.changeFocusedFeature(
            feature,
            null,
            null,
            shouldSelectAllFeaturesInBundle,
            true
          );
        } else {
          this.changeFocusedFeature(
            feature,
            null,
            null,
            shouldSelectAllFeaturesInBundle
          );
        }
      }
    }
  }

  // getRandomFeatureValue() {
  //   const featureValue = [75,79, 81, 83, 87];
  //     const random = Math.floor(Math.random() * featureValue.length);
  //     this.randomFeatureValue =   featureValue[random];
  // }
  featureNotes(previewedFeature) {
    this.featureNotePopup = !this.featureNotePopup;
    this.checkFeatureNoteLength();
    this.featureNoteText = '';
    this.fileNameArray = [];
    this.urlArray = [];
    this.myFiles = [];
    this.duplicateFileValidation = false;
    this.previewSelect = !this.previewSelect;
  }

  featureNotesEdit(previewedFeature) {
    this.featureNoteEditPopup = !this.featureNoteEditPopup;
    this.myFiles.splice(0, this.myFiles.length);
    this.featureNoteText = this.fetchFeatureNoteText(
      previewedFeature,
      'feature_note'
    );
    this.fileNameArray.splice(0, this.fileNameArray.length);
    this.fileNameArray = this.fetchFeatureNoteText(
      previewedFeature,
      'feature_attachment'
    );
    this.fileNameArray.forEach((feature) => {
      this.myFiles.push(feature.substring(feature.lastIndexOf('/') + 1));
    });
    this.enableSaveButton = false;
    this.duplicateFileValidation = false;

    this.savedfeatureNote = this.parseText(this.featureNoteText);
    this.savedfeatureNoteFiles = JSON.parse(JSON.stringify(this.fileNameArray));
    this.previewSelect = !this.previewSelect;
  }

  featureNotesDelete() {
    this.featureNoteDeletePopup = !this.featureNoteDeletePopup;
    this.featureNoteEditPopup = false;
  }

  setFeatureNotes(feature) {
    if (!this.featureNoteFile) {
      this.phaseService.selectedFeatures.find(
        (f) => f.id === feature.id
      ).feature_note = this.featureNoteText;
    }
    this.featureNotePopup = false;
    this.featureNoteEditPopup = false;
    this.featureNoteDeletePopup = false;
    this.phaseService.selectedFeatures.find(
      (f) => f.id === feature.id
    ).file_name_array = this.fileNameArray;
    this.previewSelect = !this.previewSelect;
    this.phaseService.selectedFeatures.find(
      (f) => f.id === feature.id
    ).feature_attachment = this.urlArray.filter((a) =>
      this.phaseService.selectedFeatures
        .find((f) => f.id === feature.id)
        .file_name_array.includes(a.substring(a.lastIndexOf('/') + 1))
    );
    this.fileNameArray = [];
  }

  deleteFeatureNotes(feature) {
    this.phaseService.selectedFeatures.find(
      (f) => f.id === feature.id
    ).feature_note = '';
    this.phaseService.selectedFeatures.find(
      (f) => f.id === feature.id
    ).feature_attachment = [];
    this.phaseService.selectedFeatures.find(
      (f) => f.id === feature.id
    ).file_name_array = [];
    const buildCardData = this.dataService.buildCardData.progress[
      'buildcardfeatures'
    ].find((f) => f.feature_id === feature.id);
    if (buildCardData && buildCardData.feature_note) {
      buildCardData.feature_note = '';
      buildCardData.feature_attachment = [];
    }
    this.featureNoteText = '';
    this.fileNameArray = [];
    this.urlArray = [];
    this.featureNotePopup = false;
    this.featureNoteEditPopup = false;
    this.featureNoteDeletePopup = false;
    this.previewSelect = !this.previewSelect;
  }

  checkIfFeaturePaid(previewedFeature) {
    if (
      this.dataService.isEditBuildCardAfterSecurityPaid() ||
      this.isFeatureRequest
    ) {
      const savedFeature =
        this.dataService.buildCardData.progress['buildcardfeatures'];
      if (
        savedFeature &&
        savedFeature.find((f) => f.feature_id === previewedFeature.id)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  checkIfFeatureEnabled(feature) {
    if (this.isFeatureRequest) {
      const sFeature = this.phaseService.selectedFeatures.find(
        (f) => f.id === feature.id
      );
      if (sFeature && sFeature.disabled) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  viewEditButton(previewedFeature) {
    if (
      this.isFeatureRequest &&
      this.dataService.buildCardData.change_requests.length > 0
    ) {
      const features = this.dataService.buildCardData.change_requests[0]
        .features
        ? this.dataService.buildCardData.change_requests[0].features
        : this.dataService.buildCardData.change_requests;
      this.savedFeature = features.find(
        (f) => f.feature_id === previewedFeature.id
      );
    } else if (
      this.dataService.isEditBuildCardAfterSecurityPaid() &&
      this.dataService.modifiedBuildCardData &&
      this.dataService.modifiedBuildCardData['build_card_features']
    ) {
      this.savedFeature = this.dataService.modifiedBuildCardData[
        'build_card_features'
      ].find((f) => f.feature_id === previewedFeature.id);
    } else {
      this.savedFeature = this.dataService.buildCardData.progress[
        'buildcardfeatures'
      ].find((f) => f.feature_id === previewedFeature.id);
    }
    const feature = this.phaseService.selectedFeatures.find(
      (f) => f.id === previewedFeature.id
    );
    if (
      (feature && feature.feature_note) ||
      (this.savedFeature && this.savedFeature.feature_note)
    ) {
      return true;
    } else if (
      (feature && feature.file_name_array?.length > 0) ||
      (this.savedFeature && this.savedFeature.feature_attachment?.length > 0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  getEditorData(event: any) {
    this.featureNoteText = event.editor.getData();
    this.checkFeatureNoteLength();
  }

  setEditorData() {
    return this.featureNoteText;
  }

  checkIfFilesUpdated() {
    const updatedFiels = [];
    this.fileNameArray.forEach((file) => {
      if (!this.savedfeatureNoteFiles.includes(file)) {
        updatedFiels.push(file);
      }
    });
    return updatedFiels.length ? true : false;
  }

  checkFeatureNoteLength() {
    this.FeatureNoteValidationText = '';
    let noteText = this.featureNoteText;
    noteText = this.parseText(noteText);
    if (noteText) {
      if (noteText.length < 30) {
        this.FeatureNoteValidationText = 'Minimum 30 characters are required';
        this.enableSaveButton = false;
        return false;
      } else if (noteText.length > 1000) {
        this.FeatureNoteValidationText = 'Maximum 1000 characters are allowed';
        this.enableSaveButton = false;
        return false;
      } else if (
        noteText.length > this.savedfeatureNote.length ||
        noteText.length < this.savedfeatureNote.length ||
        noteText !== this.savedfeatureNote
      ) {
        this.enableSaveButton = true;
      } else if (
        this.fileNameArray &&
        (this.fileNameArray.length !== this.savedfeatureNoteFiles.length ||
          this.checkIfFilesUpdated())
      ) {
        this.enableSaveButton = true;
      } else {
        this.enableSaveButton = false;
        return false;
      }
    } else {
      this.enableSaveButton = false;
    }
  }

  setNoteInSelectedFeatures() {
    if (this.dataService.buildCardData) {
      const featuresAfterUpdate =
        this.dataService.buildCardData.progress['buildcardfeatures'];
      if (featuresAfterUpdate.length > 0) {
        featuresAfterUpdate.forEach((ftr) => {
          if (ftr.feature_note) {
            let feature = this.phaseService.selectedFeatures.find(
              (f) => f.id === ftr.feature_id
            );
            if (feature) {
              feature['feature_note'] = ftr.feature_note;
            }
          }
        });
      }
    } else {
      return;
    }
  }

  fetchFeatureNoteText(previewedFeature, noteOrFile) {
    let data: any;
    if (
      this.isFeatureRequest &&
      this.dataService.buildCardData.change_requests
    ) {
      const features = this.dataService.buildCardData.change_requests[0]
        .features
        ? this.dataService.buildCardData.change_requests[0].features
        : this.dataService.buildCardData.change_requests;
      this.featuresAfterUpdate = features;
    } else if (this.dataService.isEditBuildCardAfterSecurityPaid()) {
      this.featuresAfterUpdate =
        this.dataService.modifiedBuildCardData['build_card_features'];
    } else {
      this.featuresAfterUpdate =
        this.dataService.buildCardData.progress['buildcardfeatures'];
    }
    if (
      this.phaseService.selectedFeatures.find(
        (f) => f.id === previewedFeature.id
      )[noteOrFile]
    ) {
      if (noteOrFile == 'feature_attachment') {
        data = this.phaseService.selectedFeatures.find(
          (f) => f.id === previewedFeature.id
        ).file_name_array;
      } else {
        data = this.phaseService.selectedFeatures.find(
          (f) => f.id === previewedFeature.id
        )[noteOrFile];
      }
    } else {
      data = this.featuresAfterUpdate.find(
        (f) => f.feature_id === previewedFeature.id
      )[noteOrFile];
    }
    if (noteOrFile == 'feature_attachment') {
      const array = [];
      data.forEach((file) => {
        array.push(file.substring(file.lastIndexOf('/') + 1));
      });
      return array;
    } else {
      return JSON.parse(JSON.stringify(data));
    }
  }

  onFileSelected(event, feature) {
    this.isFileMoreThan60Mb = false;
    const sizeInMb = event.target.files[0].size / 1000000;
    if (sizeInMb > 60) {
      this.isFileMoreThan60Mb = true;
      return;
    }
    if (
      this.myFiles.length &&
      this.myFiles.find((f) => f === event.target.files[0].name)
    ) {
      this.duplicateFileValidation = true;
      return;
    }
    this.duplicateFileValidation = false;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.fileData = {
        feature_id: feature.id,
        file_name: event.target.files[0].name,
        file: reader.result as string,
      };
      const data = { documents: this.fileData };
      this.dataService.showHideLoader(true);
      this.apiRequest.uploadFeatureNoteFile(data).subscribe((resp: any) => {
        this.myFiles.push(
          resp.file_url.substring(resp.file_url.lastIndexOf('/') + 1).trim()
        );
        this.urlArray.push(resp.file_url);
        if (!this.fileNameArray) {
          this.fileNameArray = [];
        }
        this.fileNameArray.push(
          resp.file_url.substring(resp.file_url.lastIndexOf('/') + 1)
        );
        this.enableSaveButton = true;
        this.checkFeatureNoteLength();
        this.dataService.showHideLoader(false);
        this.cdRef.detectChanges();
        this.phaseService.selectedFeatures.find(
          (f) => f.id === feature.id
        ).download_link = this.urlArray;
      });
    };
  }

  featureNoteToggle() {
    this.featureNoteExpand = !this.featureNoteExpand;
  }

  deleteFile(file, feature) {
    const index = this.fileNameArray.indexOf(
      this.fileNameArray.find((a) => a === file)
    );
    this.fileNameArray.splice(index, 1);
    const LocalFileindex = this.myFiles.indexOf(
      this.fileNameArray.find((a) => a === file)
    );
    this.myFiles.splice(index, 1);
    this.phaseService.selectedFeatures.find(
      (f) => f.id === feature.id
    ).file_name_array = this.fileNameArray;
    if (!this.fileNameArray.length) {
      this.enableSaveButton = false;
    }
    this.checkFeatureNoteLength();
  }

  public downloadFile(file, feature): void {
    const files = this.phaseService.selectedFeatures.find(
      (f) => f.id === feature.id
    ).download_link;
    const url = files.find((a) => a.includes(file)).toString();
    const link = document.createElement('a');
    link.download = file.name;
    link.target = '_blank';
    link.href = url;
    link.click();
  }

  checkFeatureNote(item) {
    const selectedFeature = this.phaseService.selectedFeatures.find(
      (f) => f.id === item.id
    );
    const buildCardData = this.dataService.buildCardData.progress[
      'buildcardfeatures'
    ].find((f) => f.feature_id === item.id);
    if (
      (selectedFeature && selectedFeature.feature_note) ||
      (buildCardData && buildCardData.feature_note)
    ) {
      return true;
    } else {
      return false;
    }
  }

  parseText(text) {
    const html = text;
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.innerText;
  }

  isUnApprovedFeature(feature) {
    const buildCardData = this.dataService.buildCardData;
    if (!this.dataService.isEditBuildCardAfterSecurityPaid()) {
      return true;
    } else if (
      buildCardData.change_requests_status === 'pending' &&
      buildCardData.change_requests.length
    ) {
      const requestedFeatures = buildCardData.change_requests.map((f) => f.id);
      return requestedFeatures.includes(feature.id);
    } else if (
      !buildCardData.progress.features.map((f) => f.id).includes(feature.id)
    ) {
      return true;
    } else {
      return false;
    }
  }

  closeSliderPosition(e, isLastCarousalElement?) {
    // e.target.querySelector('.sliderHolder').classList.remove('hoveron');
    const elements = document.querySelectorAll(
      '.sliderHolder'
    ) as NodeListOf<HTMLElement>;
    elements.forEach((element) => {
      element.classList.remove('hoveron');
    });
    const fadeDiv = document.getElementById('studioFeatureanimation');
    fadeDiv.querySelector('.studioLeft').classList.remove('fadeColumn');
    fadeDiv.querySelector('.studioCenter').classList.remove('fadeColumn');
    fadeDiv.querySelector('.studioRight').classList.remove('fadeColumn');
    if (!isLastCarousalElement) {
      // e.target.querySelector(`.sliderHolder`).style.top = 0 + 'px';
      elements.forEach((element) => {
        element.style.top = 0 + 'px';
      });
    }
    this.isCarousalExpanded = false;
    const natashaContainer = document.getElementById('natashaScrollMiddle');
    const el = natashaContainer.querySelector('.ps-content');
    el.removeEventListener('wheel', this.MouseWheelHandler);
  }

  toggleSortingOptions() {
    this.showSortingOptions = !this.showSortingOptions;
  }

  sortFeatures(sortingKey, sortingText) {
    this.sortWith = sortingKey;
    this.sortingText = sortingText;
  }

  toggleFeatureInAllBundles(feature?: FeatureModel) {
      for (const bundle of this.getFeatureBundles()) {
        if (bundle.features && (feature.feature_bundle_id !== bundle.id)) {
          for (const ftr of bundle.features) {
            if (feature.id === ftr.id) {
              if (feature.selected) {
                ftr.selected = true;
                if (bundle.selected_feature_count < bundle.total_features) {
                  bundle.selected_feature_count++;
                }
              } else {
                ftr.selected = false;
                if (bundle.selected_feature_count > 0) {
                  bundle.selected_feature_count--;
                }
              }
            }
          }
        }
      }
  }

  toggleStorePlusTooltip() {
    this.showStorePlusTooltip = !this.showStorePlusTooltip;
  }

}
