import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '@shared/shared.module';
import {CommonModule} from '@angular/common';
import {ResetpasswordComponent} from './resetpassword.component';

const childRouterConfig: Routes = [
  {path: '', component: ResetpasswordComponent}
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(childRouterConfig),
        SharedModule
    ],
    declarations: [ResetpasswordComponent]
})
export class ResetPwdModule {
}

