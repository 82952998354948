<div class="videoSection">
  <div class="sectionContent">
    <h3>{{promo_video.title}}<img src="../../../../../assets/images/smile4.png" width="38" height="38" alt=""/></h3>
    <p>{{promo_video.description}}</p>
  </div>
  <!--<div class="bLoader" *ngIf="showLoader">
    <div class="loaderBox"><img src="../../../../../assets/images/bloader.gif" alt=""/></div>
  </div>
  <div class="videoImg" *ngIf="videoUrl">
    <div class="videoHolder">
      <img src="assets/images/youtubeBlank.png" width="11" height="6" alt=""/>
      <iframe width="560" height="315" [src]="videoUrl" frameborder="0"
              allowfullscreen allow="autoplay; encrypted-media"></iframe>
    </div>
  </div>-->
  <div class="video-bx">
    <div class="showImg-bx" (click)="openVideoPopup()">
      <img src="../../../../../assets/images/video-bg.png" width="1360" height="743" alt="" />
      <span class="custom-play">
        <em class="right-arrow"></em>
      </span>
    </div>
  </div>
</div>




<!-- show video link -->
<div class="commonPopUp active" *ngIf="showVideoPopuop">
  <div class="popOverlay"></div>
  <div class="popHolder videoPopup">
    <div class="closePopUp" (click)="openVideoPopup()"><span class="icon-cancel"></span></div>
    <div class="selectHeading"></div>
    <div class="middle-block">
      <iframe width="560" height="315" title="" [src]="videoUrl" frameborder="0"
              allowfullscreen allow="autoplay; encrypted-media"></iframe>
    </div>
    </div>
</div>
