<!--home page bottom bar start-->
<div class="newHomeFooterbar" *ngIf="(phaseService.selectedApps.length > 0) && (dataService.currentPage === 'home')">
  <!--<div class="bottomSelectFeature-toggle" (click)="toggleBottomPipe()"></div>-->
  <div class="leftSide">
    <ul>
      <li *ngFor="let app of phaseService.selectedApps">
        <img [src]="app?.app_builder_icon_url" alt="" />
        <div class="temlateInfo">
          <h3>{{ app?.title }}</h3>
          <p>from {{ getCurrency() + '' + dataService.getLocaledPrice(app?.template_price) }} | {{ app?.feature_ids?.length }} features</p>
        </div>
        <div class="removeIcon" (click)="removeTemplate(app)"><em class="icon-iconcross"></em></div>
      </li>
    </ul>
    <div class="clearButton" (click)="clearTemplateSelections()">Clear selection</div>
  </div>

  <div class="rightSide">
    <p>{{phaseService.selectedApps.length}} template{{phaseService.selectedApps.length > 1 ? "s" : ""}} selected</p>
    <div class="getStart" [ngClass]="{'disableThis' : phaseService.showSpinner}" (click)="goToFeatures()" >
      <span *ngIf="!phaseService.showSpinner">Build Now</span>
      <div class="threeDotLoader" *ngIf="phaseService.showSpinner">
        <div class="dot-flashing"></div>
      </div>
    </div>
    <!--
    <div class="getStart" (click)="goToFeatures()" >
      <span>Build Now</span>
    </div>
    -->
  </div>

</div>


<!--template page bottom bar start-->
<div class="tempBottomBar" *ngIf="showAppFooter() && !phaseService.buildCardEditMode" [ngClass]="(getSelectedApps().length > 0 && !phaseService.buildCardEditMode)? (dataService.showHidePhaseBreakUp ? 'child_btn_full active' : 'active'): (dataService.showHidePhaseBreakUp ? 'child_btn_full' : '')"  >
  <!--<div class="bottomSelectFeature-toggle" (click)="toggleBottomPipe()"></div>-->
  <div class="tempLeft" *ngIf="!phaseService.buildCardEditMode" >
    <h3 *ngIf="getSelectedApps()">{{getSelectedApps() ? getSelectedApps().length : ""}}
      <span *ngIf="getSelectedApps().length > 1">Templates selected</span><span
        *ngIf="getSelectedApps().length == 1">Template selected</span>
    </h3>
  </div>
  <!--  <app-bottom-bar *ngIf="isBuildCardEditMode()"></app-bottom-bar>-->
  <div class="tempRight" *ngIf="(getSelectedApps().length > 0)" >
    <button type="button" class="next" (click)="onNextClicked()">{{isBuildCardEditMode() ? 'Save' :
      'Continue'}} </button>
  </div>

</div>
<!--template page bottom bar close-->

<!--feature & delivery page bottom bar start-->
<div class="pageBottomBar" *ngIf="showBottomBarFields()" [ngClass]="{'featurebottombar': dataService.currentPage === 'features', 'deliveryBottomBar': dataService.currentPage === 'delivery', 'zindexCount': dataService.showHidePhaseBreakUp,
                  'promosidebarOn':phaseService.openSideNav || (isShowCurrencyChooser() && (!dataService.homePageData.builder_partner || isPartnerSellThrough())),
                  'featuretutorial' :getFeatureTutorialStatus()}">
<div class="appBottomBar">

  <ng-container *ngIf="!appDataService?.urlParameters.cpe_user">
    <ng-container *ngIf="!phaseService.isStoreFeatureRequest">
      <div class="priceBox customizeCost" *ngIf="phaseService.customizationCost &&(dataService.homePageData.pricing_logic === 'new')">
        <div class="fiveDotloader" *ngIf="phaseService.showHideBottomBarLoader; else showCustomCost">
          <span></span><span></span><span></span><span></span><span></span>
        </div>

        <ng-template #showCustomCost>
          <h3>
            <span>Customisation cost <img (click)="showDynamicPricingModal()" alt="" src="../../../../../assets/images/question-circle.svg"></span>
            <strong>{{ getCurrency() + getLocaledPrice(phaseService.customizationCost | number: '1.0-2') }}</strong>
          </h3>
          <em class="icon-plus"></em>
        </ng-template>
      </div>

      <div class="priceBox reusableCost" *ngIf=" getFixedCost() > 0 &&(dataService.homePageData.pricing_logic === 'new')">
        <div class="fiveDotloader" *ngIf="phaseService.showHideBottomBarLoader; else showReusableCost">
          <span></span><span></span><span></span><span></span><span></span>
        </div>
        <ng-template #showReusableCost>
          <h3>
          <span class="tooltipInfoBox">Fixed Cost
            <em class="icon-info-circle" *ngIf="phaseService.subscriptionCost" (click)="toggleFixedCostTooltip()"></em>
            <div class="tooltipOverlay" *ngIf="showFixedCostTooltip" (click)="toggleFixedCostTooltip()"></div>
            <div class="discription-block" *ngIf="showFixedCostTooltip">
              <h4>Includes {{ dataService.checkIfEnterpriseUserOrCard() ? 'Enterprise' : 'Studio' }} One for 1 year</h4>
              <h5>This gives you:</h5>
              <ul>
                <li>Access to our platform to track your app build</li>
                <li>Ongoing expert support</li>
                <li>Collaboration tools to manage your project</li>
                <li>Updates, bug fixes and improvements - even after your app's delivered</li>
              </ul>
            </div>
          </span>
            <strong>{{getCurrency()}}{{ (dataService.additionalFeatures.length < 1 && appDataService.urlParameters?.cpe_user) ? 0 :  getLocaledPrice(getFixedCost() | number: '1.0-0')  }}</strong>
          </h3>
          <ng-container *ngIf="dataService.buildCardData.new_care_flow && !dataService.buildCardData.subscription_flow">
            <em class="icon-plus" *ngIf="phaseService.builderCarePrice"></em>
            <em class="icon-equal" *ngIf="!phaseService.builderCarePrice"></em>
          </ng-container>
          <ng-container *ngIf="dataService.buildCardData.new_care_flow && dataService.buildCardData.subscription_flow">
            <em class="icon-plus" *ngIf="showCarePlusInBottom()"></em>
            <em class="icon-equal" *ngIf="!showCarePlusInBottom()"></em>
          </ng-container>
          <em *ngIf="!dataService.buildCardData.new_care_flow" class="icon-equal"></em>
        </ng-template>
      </div>

      <div class="priceBox builderCare" *ngIf="showCareInBottomBar()">
        <div class="fiveDotloader" *ngIf="phaseService.showHideBottomBarLoader; else showPrice">
          <span></span><span></span><span></span><span></span><span></span>
        </div>
        <ng-template #showPrice>
          <h3 class="builder-care-wrap">
            <span class="builder-care">{{ showCareType(phaseService.selectedHash.care_type) }}<em class="icon-info-circle" (click)="showDiscriptionBlockPopup()"></em></span>
            <div class="tooltipOverlay" *ngIf="discriptionBlockPopup" (click)="showDiscriptionBlockPopup()"></div>
            <div class="discription-block" *ngIf="discriptionBlockPopup">
              <div class="careText">
                <h4>How {{ showCareType(phaseService.selectedHash.care_type) }} protects your software</h4>
                <p *ngIf="dataService.buildCardData.subscription_flow">
                  When you take this upgrade, you not only get all the benefits of Studio Subscription shared above but also :
                </p>

                <ul *ngIf="phaseService.selectedHash.care_type === 'standard_care'">
                  <li>24*7 uptime monitoring.</li>
                  <li>Software application bug fixes (including permanent fixes for recurring issues).</li>
                  <li>Platform updates for latest iOS / Android versions.</li>
                  <li>Proactive technical support for third-party API / SDK updates on all standard features.**</li>
                  <li>Security patches & Backup Management.*</li>
                  <li>Updated tech-stack</li>
                  <li>Industry Standard SLA</li>
                  <li>Multi-Channel Support - Online, Chat, Email with average 24 hour response time</li>
                </ul>
                <ul *ngIf="((phaseService.selectedHash.care_type === 'standard_care_plus') && !dataService.buildCardData.subscription_flow)">
                  <li>24*7 uptime monitoring. Includes proactive load planning and monitoring.</li>
                  <li>Software application bug fixes (including root cause analysis and permanent fixes for recurring issues).</li>
                  <li>Platform updates for latest iOS / Android versions.</li>
                  <li>Proactive technical support for third-party API / SDK updates on all standard features and custom features.**</li>
                  <li>Security patches & Backup Management.*</li>
                  <li>Updated tech-stack</li>
                  <li>Industry Standard SLA with 99.95% Uptime Commitment</li>
                  <li>Infrastructure performance and Application Performance Monitoring (APM)<strong class="carot">&#94;</strong></li>
                  <li>Multi-Channel Support - Online, Chat, Email with average 24 hour response time and 4 hour response commitment for severe issues.</li>
                  <li>Enhancements to current*** features (30% of the app features can be enhanced, limited to, 7.5% per quarter)</li>
                </ul>
                <ul *ngIf="((phaseService.selectedHash.care_type === 'standard_care_plus') && dataService.buildCardData.subscription_flow)">
                  <li>Proactive load planning and load monitoring.</li>
                  <li>Root cause analysis.</li>
                  <li>Uptime SLA commitment with credits.</li>
                  <li>Infrastructure performance and Application Performance Monitoring (APM).</li>
                  <li>2 hour response commitment for severe issues.</li>
                  <li>Enhancements to current features (30% of the app features can be enhanced, limited to, 7.5% per quarter).</li>
                </ul>
                <ul *ngIf="phaseService.selectedHash.care_type === 'enterprise_care'">
                  <li>24*7 uptime and performance monitoring. Includes proactive load planning and monitoring.</li>
                  <li>Software application bug fixes (including root cause analysis and permanent fixes for recurring issues).</li>
                  <li>Platform updates for latest iOS / Android versions.</li>
                  <li>Proactive technical support for third-party API / SDK updates for all standard and custom features.**</li>
                  <li>Security patches & Backup Management*</li>
                  <li>Updated tech-stack</li>
                  <li>Enterprise SLA with 99.999% Uptime Guarantee</li>
                  <li>Enhancements to current*** features (30% of the app features can be enhanced, limited to 7.5% per quarter)</li>
                  <li>Infrastructure performance and Application Performance Monitoring (APM)<strong class="carot">&#94;</strong></li>
                  <li>Multi-Channel Support - Online, Chat, Email with average 24 hour response time and 4 hour response commitment for severe issues.</li>
                </ul>
                <ul *ngIf="((phaseService.selectedHash.care_type === 'enterprise_care_plus') && !dataService.buildCardData.subscription_flow)">
                  <li>24*7 uptime and performance monitoring. Includes proactive load planning and monitoring.</li>
                  <li>Software application bug fixes (including root cause analysis and permanent fixes for recurring issues).</li>
                  <li>Platform updates for latest iOS / Android versions.</li>
                  <li>Proactive technical support for third-party API / SDK updates for all standard and custom features.**</li>
                  <li>Security patches & Backup Management*</li>
                  <li>Updated tech-stack</li>
                  <li>Enterprise SLA with 99.999% Uptime Guarantee</li>
                  <li>Enhancements to current*** features (50% of the app features can be enhanced, limited to 12.5% per quarter)</li>
                  <li>Infrastructure performance and Application Performance Monitoring (APM)<strong class="carot">&#94;</strong></li>
                  <li>Multi-Channel Online, Chat, Email, with average 24 hour response time and 2 hour response commitment for severe issues.</li>
                </ul>
                <ul *ngIf="((phaseService.selectedHash.care_type === 'enterprise_care_plus') && dataService.buildCardData.subscription_flow)">
                  <li>Enhancements to current features (50% of the app features can be enhanced, limited to 12.5% per quarter).</li>
                  <li>1 hour response commitment for severe issues.</li>
                </ul>

                <ul class="bottomDescription" *ngIf="!dataService.buildCardData.subscription_flow">
                  <li>*If part of your original software.</li>
                  <li>**Excluding any third-party, SDK, security, feature, custom integration or browser addition - that wasn't part of your Buildcard.</li>
                  <li>***Features covered under your Buildcard.</li>
                  <li><strong class="carot">&#94;</strong> If hosted with Builder Cloud</li>
                </ul>
              </div>
            </div>
            <strong>
              {{getCurrency()}}{{getLocaledPrice(getCarePrice() | number : '1.0-0')}}
              {{ dataService.buildCardData.subscription_flow ? '' : '/year' }}
            </strong>
            <!--/ week -->
          </h3>
          <em class="icon-equal"></em>
        </ng-template>
      </div>
    </ng-container>

    <div class="maxpriceBox totalCost" [ngClass]="{'phasebreakBoxShift' : (phaseService.promotionValid &&  !phaseService.promotionError && (!isCpeInvitedForBuildCard() ? (phaseService.isDiscountApplied() && (dataService.manualPromotion?.type !== 'SpecialEventPromotion')) : phaseService.isDiscountAppliedForRental()))}" *ngIf="!appDataService?.urlParameters.cpe_user">
      <div class="fiveDotloader" *ngIf="phaseService.showHideBottomBarLoader; else showMaxPrice">
        <span></span><span></span><span></span><span></span><span></span>
      </div>
      <ng-template #showMaxPrice>
        <h3>
          <span *ngIf="((!phaseService.isDiscountApplied() || (dataService.manualPromotion?.type === 'SpecialEventPromotion')) && !isCpeInvitedForBuildCard())">Total Cost</span>
          <span *ngIf="(phaseService.promotionValid &&  !phaseService.promotionError && (!isCpeInvitedForBuildCard() ? (phaseService.isDiscountApplied() && dataService.manualPromotion?.type !== 'SpecialEventPromotion') : phaseService.isDiscountAppliedForRental()))">Total discounted cost</span>
          <div *ngIf="phaseService.isDiscountApplied() && (dataService.manualPromotion?.type !== 'SpecialEventPromotion') && !phaseService.weeksPromotionOnly() && ((dataService.buildCardData && dataService.buildCardData.change_amount === 0) || (!dataService.buildCardData.id))" class="strickprice">{{getCurrency()}}{{getLocaledPrice(getTotalWithSpecing() | number: '1.0-0')}}</div>
          <strong *ngIf="!phaseService.isDiscountApplied()">{{getCurrency()}}{{getLocaledPrice(getTotalWithSpecing() | number: '1.0-0')}}</strong>
          <strong *ngIf="phaseService.isDiscountApplied()">{{getCurrency()}}{{getLocaledPrice(getDiscountedWithSpecing())}}</strong>
        </h3>
      </ng-template>

      <div *ngIf="phaseService.showApplicationDiscount()" class="appLockPrice" (click)="phaseService.showSignUpForDiscount('features',true,true)">
        {{getCurrency()}}{{getLocaledPrice(((getTotalWithSpecing()) * ((100 - dataService.couponObject?.discount_percentage) / 100)) / 1000 | number : '1.0-0')}} K
        <div class="priceTooltip">Log in to unlock the discount</div>
      </div>

      <div class="phasebreakBox" *ngIf="phaseService.selectedFeatures?.length && !phaseService.showHideBottomBarLoader && phaseService?.featureData?.length && !phaseService.isStoreFeatureRequest">
        <div class="phaseIcon" [ngClass]="{'active': dataService.showHidePhaseBreakUp}" (click)="phasebreakBox(dataService.currentPage);">
          <em class="icon-info-circle"></em>
        </div>
        <div class="phasebreakOverlay" (click)="hideTotalCosttooltip()" [ngClass]="{'active': dataService.showHidePhaseBreakUp}"></div>
        <app-phase-breakup  *ngIf="dataService.showHidePhaseBreakUp" [sendFeedback]="dataService.showHidePhaseBreakUp" (phaseFlag) = getFeedbackFlag($event) (parentApplyPromotion) = ApplyPromotion() (parentRemovePromotion)="removeManualPromotion()"></app-phase-breakup>
      </div>
    </div>

    <div class="priceBox" *ngIf="!dataService.buildCardData.new_care_flow && (dataService.currentPage !== 'features') && (phaseService.builderCarePrice)">
      <div class="fiveDotloader" *ngIf="phaseService.showHideBottomBarLoader; else showPrice">
        <span></span><span></span><span></span><span></span><span></span>
      </div>
      <ng-template #showPrice>
        <h3 class="builder-care-wrap">
          <span class="builder-care">Builder Care <em class="icon-info-circle" (click)="showDiscriptionBlockPopup()"></em></span>
          <div class="tooltipOverlay" *ngIf="discriptionBlockPopup" (click)="showDiscriptionBlockPopup()"></div>
          <div class="discription-block" *ngIf="discriptionBlockPopup">
            Our optional after ship warranty updates, upgrades that keeps your app running smoothly.
          </div>
          <strong>{{getCurrency()}}{{getLocaledPrice(phaseService.builderCarePrice | number : '1.0-0')}} /month</strong>
          <!--/ week -->
        </h3>
      </ng-template>
    </div>

    <div class="durationBox">
      <div class="fiveDotloader" *ngIf="phaseService.showHideBottomBarLoader; else showDuration">
        <span></span><span></span><span></span><span></span><span></span>
      </div>
      <ng-template #showDuration>

        <h3>
          <span>Indicative Duration</span>
          <strong>
            {{ ((getFinalWeeks() < 1) && (getFinalWeeks() !== 0)) ? 1 : (getFinalWeeks() | number: '1.0-0')}}{{ (getFinalWeeks() | number: "1.0-0") > 1 ? " weeks" : " week" }}
          </strong>
          <!-- <strong *ngIf="phaseService.isStoreFeatureRequest">Immediate</strong> -->
        </h3>
      </ng-template>
    </div>
  </ng-container>

  <!-- combo-feature -->
  <ng-container *ngIf="appDataService?.urlParameters.cpe_user">
    <div class="priceBox customizeCost">
      <h3>
        <span>Combo cost</span>
        <strong>{{ getCurrency() + getLocaledPrice(dataService.buildCardData?.rental_info?.combo_price | number: '1.0-2') }}</strong>
      </h3>
      <em class="icon-plus"></em>
    </div>
    <div class="maxpriceBox reusableCost">
      <h3>
        <span>Additional feature cost</span>
        <strong>
          {{getCurrency() + '' + getLocaledPrice(phaseService.finalPricenWeeks?.bcard_discounted_price | number: '1.0-0')}}
        </strong>
      </h3>
      <div class="phasebreakBox" *ngIf="dataService.additionalFeatures?.length && !phaseService.showHideBottomBarLoader && phaseService?.featureData?.length">
        <div class="phaseIcon" [ngClass]="{'active': dataService.showHidePhaseBreakUp}" (click)="phasebreakBox(dataService.currentPage);">
          <em style="top: 4px;border: none;left: 74px;" class="icon-info-circle"></em>
        </div>
        <div class="phasebreakOverlay" (click)="hideTotalCosttooltip()" [ngClass]="{'active': dataService.showHidePhaseBreakUp}"></div>
        <app-phase-breakup  *ngIf="dataService.showHidePhaseBreakUp" [sendFeedback]="dataService.showHidePhaseBreakUp" (phaseFlag) = getFeedbackFlag($event) (parentApplyPromotion) = ApplyPromotion() (parentRemovePromotion)="removeManualPromotion()"></app-phase-breakup>
    </div>
      <em></em>
    </div>
    <div class="maxpriceBox">
      <h3>
        <span>Total Cost</span>
        <strong>
          {{getCurrency()}}{{getLocaledPrice(getTotalForStoreAdditionalRequest()?.toFixed(2))}}
        </strong>
      </h3>
    </div>
    <div class="maxpriceBox">
        <h3>
          <span>Indicative Duration</span>
          <strong>{{ totalAdditionalFeatureMonths() }} {{ totalAdditionalFeatureMonths() > 1 ? 'months' : 'month' }}</strong>
        </h3>
    </div>
  </ng-container>

  <div class="applyPromoBox" *ngIf="isShowApplyPromotion()" [ngClass]="phaseService.promoError ? 'errorShow' : ''">
    <div class="promo-container">
      <button *ngIf="(!phaseService.isDiscountApplied() && (dataService.manualPromotion?.type !== 'SpecialEventPromotion') && !isCpeInvitedForBuildCard())" type="button" class="applyButton" (click)="ApplyPromotion()">Apply Promotion</button>
      <form *ngIf="(phaseService.promotionValid &&  !phaseService.promotionError && (!isCpeInvitedForBuildCard() ? (phaseService.isDiscountApplied() || (dataService.manualPromotion?.type === 'SpecialEventPromotion')) : phaseService.isDiscountAppliedForRental()))">
        <div class="applyPromoInner">
          <h4>{{ phaseService.finalPromoApplied?.toUpperCase() }} Applied </h4>
          <div class="offerCancel" *ngIf="!isCpeInvitedForBuildCard()">
            <button type="button" (click)="removeManualPromotion()" class="removePromo"> <em class="icon-cancel"></em> </button>
          </div>
        </div>
      </form>
    </div>

  </div>
</div>
<div class="previewBottom" *ngIf="showNavigateButtons()" [ngClass]="{'child_btn_full' : dataService.showHidePhaseBreakUp}">

  <div *ngIf="dataService.currentPage === 'features'">
    <button type="button" *ngIf="appDataService.urlParameters?.cpe_user && (dataService.buildCardData && !dataService.isEditBuildCardAfterSecurityPaid()) " (click)="submitPhases('delivery_screen')" class="nextButton"> Confirm
       <img *ngIf="getPreventClick()" src="./../../../assets/images/buttonLoader.gif" alt=""/>
    </button>
    <button type="button" *ngIf="(getSelectedFeatures().length > 0) && !phaseService.isFeatureRequest && !appDataService?.urlParameters.cpe_user  && (dataService.buildCardData && !dataService.isEditBuildCardAfterSecurityPaid())" class="nextButton" (click)="gotoTeam()">
      {{getEditMode() && !phaseService.showPlatformPopup ? 'Plan Delivery' : 'Plan Delivery'}} <img
      *ngIf="getPreventClick()" src="./../../../assets/images/buttonLoader.gif" alt=""/>
    </button>

    <button type="button"  class="nextButton new_feature_btn"
            *ngIf="(getSelectedFeatures().length > 0) && phaseService.isFeatureRequest && !appDataService?.urlParameters.cpe_user"
            [ngClass]="{ 'custom-disabled': phaseService.showHideBottomBarLoader }"
            (click)="submitPhases('delivery_screen', true)">
       Add {{ dataService.additionalFeatures.length }} selected {{dataService.additionalFeatures.length > 1 ? ' features' : 'feature'}}
    </button>
  </div>
  <div *ngIf="dataService.currentPage === 'delivery' && (dataService.buildCardData && !dataService.isEditBuildCardAfterSecurityPaid())">
    <button type="button" (click)="submitPhases('delivery_screen')" class="nextButton"
            [disabled]="((phaseService.selectedPhases.length == 0) || (checkIfNoNewPlatformsAdded() || phaseService.showHideBottomBarLoader))">
      Done</button>
  </div>
  <div *ngIf="dataService.currentPage === 'apps'">
    <button type="button" *ngIf="(getSelectedApps().length > 0) && isBuildCardEditMode && !dataService.isEditBuildCardAfterSecurityPaid()" class="nextButton" (click)="onNextClicked()">Save </button>
  </div>
  <button type="button" *ngIf="dataService.buildCardData && dataService.isEditBuildCardAfterSecurityPaid()" [disabled]="phaseService.selectedPhases.length == 0"
  class="nextButton" (click)="showConfirmChangeModalAfterSecurityPaid()">
        Save Changes
  </button>
  <!--<share-url-button *ngIf="!phaseService.isFeatureRequest" [currentUrl]="currentUrl"></share-url-button>-->
</div>
</div>
<!--feature & delivery page bottom bar start-->

<!----popup for confirmation---->
<div class="commonPopUp" [ngClass]="{'active': isShowConfirmChangeModalAfterSecurityPaid}" *ngIf="isShowConfirmChangeModalAfterSecurityPaid">
  <div class="popOverlay"></div>
  <div class="popHolder featureEditConfirm">
    <div class="closePopUp" (click)="isShowConfirmChangeModalAfterSecurityPaid = false"><span class="icon-cancel"></span></div>
    <h2 class="header">Are you sure you want to save the changes you made?</h2>
    <div class="midBlock">
      <div class="amountDiffrenceBox">
        <div class="amountRow">
          <div class="amountLabel">Your updated amount</div>
          <div class="amountBox">
            <strong *ngIf="!phaseService.isDiscountApplied()">{{getCurrency()}}{{getLocaledPrice(getTotalWithSpecing() | number: '1.0-0')}}</strong>
            <strong *ngIf="phaseService.isDiscountApplied()">{{getCurrency()}}{{getLocaledPrice(getDiscountedWithSpecing())}}</strong>
            ({{getSelectedFeatures().length}} Features)
          </div>
        </div>

        <div class="amountRow">
          <div class="amountLabel">Previous amount</div>
          <div class="amountBox">
            {{getCurrency()}}{{getLocaledPrice(dataService.getOldPrice())}}
            ({{phaseService.getOldFeatureCount()}} {{phaseService.getOldFeatureCount() > 1 ? 'Features': 'Feature'}})
          </div>
        </div>
      </div>

      <p class="noteTxt"><strong> Note: </strong>Differential amount will be adjusted in the installments.</p>
      <p class="noteTxt" *ngIf="showNoteOnConfirmChangesPopup()" style="margin-top: 0;"><strong style="display: inline-block; width: 23px; height: 10px;"> </strong>Previous amount section would include the approved features only.</p>
    </div>
    <div class="action-block">
      <button type="button" class="discardButton" (click)="isShowConfirmChangeModalAfterSecurityPaid = false" >Discard</button>
        <button type="button" class="confirmButton" (click)="buildCardModificationRequestAfterSecurityPaid()" >Confirm changes</button>
    </div>

  </div>
</div>
