<div class="templateCard"  *ngIf = "template" [ngClass]="{'custom-template': template?.user_requested}" (click) = "selectTemplate(template, $event)">
    <div class="header">
        <div class="title" [ngClass]="{'storeTitle' : (template?.template_type == 'studio_store')}">
            <div class="brandicon" class='image{{template.id}}'>
                <img [src]="template.app_builder_icon_url" loading='lazy' alt="" />
                <!-- <img [src]="template.app_builder_icon_url" id='tunnel{{template.id}}' style="position: fixed;display: none" /> -->
            </div>
            <div class="titleText">
                {{(template?.template_type == 'studio_store' ? 'STUDIO STORE' : 'BUILD AN APP LIKE')}}
                <span #title> {{template.title}}</span>
                <div class="titleTooltip" *ngIf="isOverflowing(title)">{{template.title}} </div>
            </div>
        </div>

        <div class="actionbx" *ngIf="template?.template_type === 'builder_studio' || template?.user_requested">
            <button type="button" class="morebtn" [ngClass] = "{active: checkActive(template)}">
                <em class="icon-plus"></em>
                <img class="tempTick" src="../../../../../assets/images/tickontemp.svg" alt="">
                <span class="tooltipUI">Add template</span>
            </button>
        </div>
    </div>
    <div class="middle">
            <div class="featureImg">
                <img [src]="template?.background_url ? template?.background_url : template?.background" alt="" loading='lazy'>
            </div>
            <div class="featureDetail" *ngIf="!template?.user_requested">
                <span class="heading">INCLUDED FEATURES</span>
                <div class="subheading">
                    <div class="txtBx">
                        <span class="maintxt" *ngIf="template.top_features">{{template.top_features}}</span>
                        <span class="txtOverShow" *ngIf="flase">{{template.top_features}}</span>
                    </div>
                    <span *ngIf = "template.feature_count > 3"> + {{template.feature_count - 3}} other features </span>
                </div>
            </div>
    </div>
    <div class="footer">
        <div class="title">
            <div>
                FROM
                <div class="pricewithmonth">
                    <span class="pHeading">
                        {{template.currency?.data?.attributes?.symbol}}
                    {{(template?.template_type == 'studio_store' ? (template.monthly_price ? (getLocaledPriceInt((template.monthly_price) | number : '1.0-0')) : '') : (getLocaledPriceInt((template.template_price / 1000) | number : '1.0-0')) + 'k')}}</span>
                    <span *ngIf = "template?.template_type == 'studio_store'" class="pSunhead" >per <br> month</span>
                    <span *ngIf = "template?.template_type !== 'studio_store'" class="pSunhead" >per <br> platform</span>
                </div>
            </div>
        </div>
        <div class="actionbx" *ngIf = "!template?.user_requested">
          <a  target="_blank" class="viewDetail" (click)="preventBubling($event);opentemplateDetailPopup(template);" [ngClass]="{'studio-btn' : template?.template_type == 'studio_store'}"> View Details
          </a>
        </div>
    </div>
</div>

<!--bottom  bar html-->
<!-- <app-bottom-bar></app-bottom-bar> -->
<!-- <div class="bottom-active-selection" *ngIf = "homeTemplateService.selectedTemplates.length > 0">
    <div class="buil-btn-wrp">
        <div class="selection-text">{{homeTemplateService.selectedTemplates.length}} template selected</div>
        <button class="build-button">Build now</button>
    </div>
</div>  -->
