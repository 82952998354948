import { Injectable } from "@angular/core";
import { UrlParameterModel } from "@models/url-parameters-model/url-parameter.model";
import { environment } from "@env/environment";
import { DataService } from "@shared/services/data.service";
import { Router } from "@angular/router";
// import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import { PartnerDashboardDataService } from "@featuremodules/partner-dashboard-module/Utils/partner-dashboard-data.service";
import { CommonService } from "@shared/services/common.service";
import { Intercom } from "ng-intercom";
import { HttpHeaders } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
declare let window: any;

@Injectable({
  providedIn: "root",
})
export class AppDataService {
  public urlParameters: UrlParameterModel;
  public idleState = "Not started.";
  public timedOut = false;
  multiplierChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public dataService: DataService,
    public partnerDataService: PartnerDashboardDataService,
    private commonService: CommonService
  ) {
    this.urlParameters = new UrlParameterModel();
  }

  public setIncomingUrlParameters(urlParameters: UrlParameterModel) {
    if (urlParameters) {
      this.urlParameters = urlParameters;
    }
  }

  public generateAnonymousId() {
    let anonymousId = this.commonService.isPlatformBrowser
      ? localStorage.getItem("user_id")
      : null;
    if (anonymousId) {
      return anonymousId;
    } else {
      anonymousId = "Anonymous_Builder_";
      const possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 20; i++) {
        anonymousId += possible.charAt(
          Math.floor(Math.random() * possible.length)
        );
      }

      if (this.commonService.isPlatformBrowser) {
        localStorage.setItem("user_id", anonymousId);
      }
      return anonymousId;
    }
  }

  public setDomain() {
    if (this.commonService.isPlatformBrowser) {
      const hostfromurl = window.location.hostname;
      this.dataService.user_cookie = environment.USER_COOKIE;
      if (hostfromurl === "localhost") {
        this.dataService.cookie_domain = "localhost";
      } else {
        const separate = hostfromurl.split(".");
        separate.shift();
        const currentDomain = separate.join(".");
        this.dataService.cookie_domain = "." + currentDomain;
      }
    }
  }

  // public startTimerForIntercom() {
  //   this.idle.setIdle(5);
  //   // sets a timeout period of 40 seconds. after 45 seconds of inactivity, the user will be considered timed out.
  //   this.idle.setTimeout(40);
  //   // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
  //   this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

  //   this.resetIdleTime();
  //   this.idle.onIdleEnd.subscribe(() => {
  //     this.idleState = "No longer idle.";
  //   });
  //   this.idle.onTimeout.subscribe(() => {
  //     this.idleState = "Timed out!";
  //     this.timedOut = true;
  //     this.startTimeOut();
  //   });
  //   this.idle.onIdleStart.subscribe(() => {
  //     this.idleState = 'You"ve gone idle!';
  //   });
  //   this.idle.onTimeoutWarning.subscribe(countdown => {
  //     this.idleState = "You will time out in " + countdown + " seconds!";
  //   });
  // }

  // public startTimeOut() {
  //   this.intercom.showNewMessage('');
  //   this.idle.stop();
  // }

  // public resetIdleTime() {
  //   this.idle.watch();
  //   this.idleState = "Started.";
  //   this.timedOut = false;
  // }

  public setAssetsPath(path) {
    path = "../../../../assets/images/" + path;
    return path;
  }

  public getRequestOptionArgs(
    contentType?: string,
    isFromUrl?: boolean,
    isClearBitBPI?: boolean
  ) {
    let headers = new HttpHeaders();
    if (contentType) {
      headers = headers.append("Content-Type", contentType);
    }
    if (isClearBitBPI) {
      headers = headers.append(
        "Authorization",
        "Bearer sk_c880f7df8ca8deb674bed39aa76f7771"
      );
    }
    let user;
    if (this.dataService.user) {
      user = this.dataService.user;
    } else {
      user = this.getUserFromLocalStorage();
    }
    // if (user) {
    //   headers = headers.append("authtoken", user.authtoken);
    // } else {
      const token = this.getAuthTokenFromCookie();
      if (token) {
        headers = headers.append("authtoken", token);
      }
    // }
    // if (user && !isClearBitBPI) {
    //   const token = isFromUrl
    //     ? this.urlParameters.authToken
    //       ? this.urlParameters.authToken
    //       : this.getAuthTokenFromCookie()
    //     : this.getAuthTokenFromCookie();
    //   if (token) {
    //     headers = headers.append("authtoken", token);
    //   }
    // }
    if (this.dataService.isLambdaURL) {
      headers = headers.append(
        "x-api-key",
        "WxMdrz3XdA5JocbDNl0Kf9EmtH2Ngbe16DtNutDt"
      );
    }
    if (this.urlParameters.partner) {
      headers = headers.append("partner-uniq-code", this.urlParameters.partner);
    }
    return headers;
  }

  getAuthTokenFromCookie() {
    let userToken: any;
    document.cookie.split(";").forEach((cc) => {
      if (cc.includes(this.dataService.user_cookie)) {
        userToken = JSON.parse(cc.split("=")[1]);
      }
    });
    return userToken?.authtoken;
  }

  getUserFromLocalStorage() {
    return JSON.parse(localStorage.getItem(this.dataService.user_cookie));
  }

  public handlePartnerRoutes() {
    const partner = this.partnerDataService.getPartnerUser();
    this.dataService.resetPartnerViews();
    this.dataService.resetConfigurationViews();
    if (partner) {
      if (!partner.is_approved) {
        if (partner.status === "submit_for_verification") {
          this.dataService.showHideViews.profile = true;
          this.dataService.showHideViews.partnerVerified = false;
          this.dataService.allContractsSigned = true;
        } else if (partner.status === "profile_submitted") {
          this.dataService.showHideViews.profile = true;
          this.dataService.isPartnerProfileUpdated = true;
          this.dataService.allContractsSigned = false;
        } else if (partner.status === "profile_pending") {
          this.dataService.showHideViews.profile = true;
          this.dataService.allContractsSigned = false;
          this.dataService.isPartnerProfileUpdated = false;
        }
      } else {
        if (partner.status === "uniq_code_sent") {
          if (this.urlParameters.otp_code) {
            this.dataService.showHideViews.verify = true;
          } else {
            this.dataService.showHideViews.partnerVerified = true;
            this.dataService.showUniqueCodeScreen = true;
          }
          this.dataService.partnerProfileSubmitted = false;
        } else if (partner.status === "uniq_code_verified") {
          this.dataService.showHideViews.configuration = true;
          this.dataService.configuration.step1 = true;
        } else if (partner.status === "profile_completed") {
          if (partner.partner_type === "sell_to") {
            this.dataService.showHideViews.payment = true;
            this.dataService.paymentSkipped = false;
          } else if (partner.partner_type === "sell_through") {
            this.dataService.showHideViews.partnerVerified = true;
            this.dataService.partnerProfileSubmitted = true;
          }
        } else if (partner.status === "payment_selected") {
          this.dataService.paymentSkipped = true;
          this.dataService.showHideViews.partnerVerified = true;
          this.dataService.partnerProfileSubmitted = true;
        } else {
          this.dataService.showHideViews.profile = true;
          this.dataService.isPartnerProfileUpdated = false;
        }
      }
    } else {
      this.dataService.showHideViews.welcome = true;
    }
  }
  ismicrosoftActive() {
    const officeValue =
      this.dataService.appDetails?.saas_service_inr?.includes("Office365");
    const isInr = this.dataService.user.currency.code == "INR";
    if (officeValue && isInr) {
      return true;
    }
    return false;
  }

  setHeaderFor2PayAndCapchase() {
    let headers = new HttpHeaders();
    let dataValues = this.dataService.user.id + '-' + this.dataService.buildCardData.id;
    if (this.dataService.user.currency.id === 2) {
      dataValues = dataValues + '-USA-USD';
    } else if (this.dataService.user.currency.id === 4) {
      dataValues = dataValues + '-UK-GBP';
    }
    headers = headers.append('IDEMPOTENCY-KEY', dataValues);
    return headers;
  }

}
