<div class="filterListMain" *ngIf = "!homeTemplateService.initialLoader && homeTemplateService.studio_templates.length > 0">
    <div class="filterList">
      <app-home-template-card *ngFor = "let template of homeTemplateService.studio_templates; let i = index"
                              [type] = "'studio'"
                              [template] = "template.attributes ? template.attributes : template" >
      </app-home-template-card>
    </div>
</div>

<ng-container *ngIf = "!homeTemplateService.initialLoader && homeTemplateService.showTemplatesLoader">
    <app-template-loader></app-template-loader>
</ng-container>
