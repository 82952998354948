import {Component, OnInit, ElementRef, ViewChild, NgZone} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiRequests} from '@shared/services/api-requests.service';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Intercom} from 'ng-intercom';
  import {Constants} from '@shared/utils/constants';
import {AppDataService} from '@rootmodule/app-data.service';
import {CurrencyModel} from '@models/currency-model/currency.model';
import {UtilityService} from '@shared/services/utility.service';
import {ActivatedRoute, Router} from '@angular/router';
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { HomeTemplateService } from "src/app/shared/shared-module/services/home-template.service";
import parsePhoneNumber from 'libphonenumber-js';
import {CommonService} from '@shared/services/common.service';

@Component({
  selector: 'app-login-signup',
  templateUrl: './login-signup.component.html',
  styleUrls: ['./login-signup.component.scss']
})
export class LoginSignupComponent implements OnInit {
  currencyByIp: any;
  loginSignupForm: FormGroup;
  screenTobeAppear = 'signup';
  isCreateAccountWithAuth = false;
  isShowIpBasedCurrencyDropdown = false;
  selectedCurrency: any;
  signupTerm = false;
  invalidEmail = false;
  emailExist = false;
  invalidPassword = false;
  invalidName = false;
  invalidPhone = false;
  signupTerms = false;
  public sub;
  @ViewChild('mobileNumber') public mobileNumber;
  userData = {inviteToken: null, signup_stage: null, utm_campaign: null, utm_source: null, utm_medium: null}
  isRegisterClicked = false;
  termsConditionInvalid = false;
  invalidCurrency = false;
  public signupNumberInitiated = 0;
  public signupPwdInitiated = 0;
  paramTemplateAlias: any;
  public SignUpSocialPlatform = '';
  ErrorMsg = '';
  isSaveButtonClicked = false;
  isSignUpClicked = false;
  public socialSignup = {
    email: '',
    phone_number: ''
  }
  constructor(private fb: FormBuilder, private apiRequests: ApiRequests, public phaseService: PhaseService, public dataService: DataService, private el: ElementRef, public homeTemplateService: HomeTemplateService,
              public analyticsSegment: Angulartics2Segment,  public appDataService: AppDataService, public utilityService: UtilityService,
              public route: ActivatedRoute, public auth: SocialAuthService, private zone: NgZone,
              public commonService: CommonService) { }

  ngOnInit(): void {
    if(this.phaseService.userRegistered){
      this.dataService.newSignUpFlowScreen = 'login';
      this.screenTobeAppear = 'login';
    }else{
      this.dataService.newSignUpFlowScreen = 'signup';
      this.screenTobeAppear = 'signup';
      this.appDataService.urlParameters.email = this.phaseService.emailFromToken;
      this.appDataService.urlParameters.name = this.phaseService.usernameFromToken;
    }
    this.getCurrencyByIp();
    this.initializeLoginForm();
    this.checkUserHasCurrency();
    this.phaseService.countryNameCode = this.dataService.country_code ? this.dataService.country_code.toLowerCase() : '';

  }

  checkUserHasCurrency() {
    if (this.dataService.user && !this.dataService.user.currency) {
      this.homeTemplateService.createBuildCardPending = true;
      this.dataService.isSocialSignUpForStudioStore = true;
    }
  }

  public toggleCurrenctDropdown(e) {
    e.stopPropagation();
    this.isShowIpBasedCurrencyDropdown = !this.isShowIpBasedCurrencyDropdown;
  }

  public getCurrencyByIp() {
    this.apiRequests.getCurrencyByIp().subscribe((data: any) => {
      this.currencyByIp = data.data;
        if (this.currencyByIp.length === 1) {
         this.selectedCurrency = this.currencyByIp[0];
        }
      }, error => {

    });
  }
  ngAfterViewInit() {
    this.el.nativeElement.querySelector('#loginEmailInput').focus();
  }
  initializeLoginForm() {
    this.loginSignupForm = this.fb.group({
      email: [this.appDataService.urlParameters.email ? this.appDataService.urlParameters.email : null, Validators.required],
      name: [this.appDataService.urlParameters.name ? this.appDataService.urlParameters.name : null, Validators.required],
      phone: [this.appDataService.urlParameters.phone_number ? this.appDataService.urlParameters.phone_number : null],
      currency: [''],
      password: [null, Validators.required, Validators.minLength],
      termsconditionSignup:  [null, Validators.requiredTrue]
    });
  }

  get email() {
    return this.loginSignupForm.get('email');
  }

  get password() {
    return this.loginSignupForm.get('password');
  }

  get phone() {
    return this.loginSignupForm.get('phone');
  }

  get name() {
    return this.loginSignupForm.get('name');
  }

  get currency() {
    return this.loginSignupForm.get('currency');
  }

  get termsconditionSignup() {
    return this.loginSignupForm.get('termsconditionSignup');
  }

  checkIfEmailValid(): boolean {
    return !this.phaseService.validateEmail(this.email.value);
  }

  checkIfPasswordValid(isSignIn?: boolean): boolean {
    if (isSignIn) {
      return this.password && !!this.password.errors && (this.password.pristine || this.password.invalid);
    } else if (!isSignIn) {
      if (this.isRegisterClicked) {
        return this.password && !!this.password.errors && (this.password.pristine || this.password.invalid);
      } else {
        return this.password && this.password.errors && (this.password.dirty || this.password.touched);
      }
    }
  }

  checkIfNameValid(): boolean {
    if (this.isRegisterClicked) {
      return this.name && this.name.errors && (this.name.invalid || this.name.pristine);
    } else {
      return this.name && this.name.errors && (this.name.dirty || this.name.touched);
    }
  }

  public checkIfPhoneValid(): boolean {
    if (this.isRegisterClicked) {
      return this.phone && this.phone.errors && (this.phone.invalid || this.phone.pristine);
    } else {
      return this.phone && this.phone.errors && (this.phone.dirty || this.phone.touched);
    }
  }

  checkIfCurrencyValid(): boolean {
    if (!this.phaseService.currencyForNow){
      return false;
    }
    return true;
  }

  showLoginOrSignup(screenName, isFromForgotPassword?) {
    if (isFromForgotPassword) {
      this.ErrorMsg = '';
    } else {
      this.resetValidations();
    }
    this.screenTobeAppear = screenName;
    this.dataService.newSignUpFlowScreen = screenName;
    if (screenName === 'login') {
      this.phaseService.trackEventForObject(this.analyticsSegment,  'sign_in_clicked',{
        screen_source : 'Store',
        user_id : this.dataService.user?.id
      });
    } else {
      this.phaseService.trackEventForObject(this.analyticsSegment,  'sign_up_clicked',{
        user_id : this.dataService.user?.id
      });
    }
    this.el.nativeElement.querySelector('#loginEmailInput').focus();
  }

  public resetValidations() {
    this.invalidPassword = false;
    this.emailExist = false;
    this.invalidEmail = false;
    this.invalidPhone = false;
    this.loginSignupForm.reset();
  }

  onFlagChange() {
    if (this.mobileNumber && this.phone.valid && this.mobileNumber.selectedCountry) {
      this.phaseService.countryNameCode = this.mobileNumber.selectedCountry.countryCode;
      this.dataService.countryNameCode = this.mobileNumber.selectedCountry.dialCode;
    }
  }


  registrationMobileChanged(event) {
    if (this.signupNumberInitiated === 0) {
      this.signupNumberInitiated++;
    }
  }

  registrationPwdChanged(event) {
    if (this.signupPwdInitiated === 0) {
      this.signupPwdInitiated++;
    }
  }

  CheckIfFormValid() {
    if (this.screenTobeAppear === 'signup') {
      return this.email.invalid ||  this.name.invalid ||
       this.checkIfPhoneInValid() || this.currency.invalid ||
       this.password.invalid || this.termsconditionSignup.invalid;
    }

    return this.loginSignupForm.get('email').invalid || this.loginSignupForm.get('password').invalid;
  }

  fetchHomePageData() {
    this.apiRequests.fetchHomePageData().subscribe((data: any) => {
      this.dataService.dataLoadingCurrency = Object.assign({}, this.dataService.homePageData.currency);
      this.phaseService.platformPrices = Array.apply(null, Array(this.dataService.homePageData.platforms.length));
      this.phaseService.platformPrices.map((x, i) => {
        return i;
      });
      if (this.dataService.homePageData.currency) {
        this.phaseService.specing_price = this.dataService.homePageData.currency.specing_price;
      }
    });
  }

  loginSignup() {
    if (this.isSignUpClicked) { return false; }
    let userData;
    this.validateEmail();
    if (this.screenTobeAppear === 'login') {
      if (this.checkIfPasswordValid(true)) {
        this.invalidPassword = true;
        return;
      } else {
        this.invalidPassword = false;
      }
      delete this.loginSignupForm.value.name;
      delete this.loginSignupForm.value.phone;
      this.loginSignupForm.value.password = this.password.value;
    } else {
      this.isRegisterClicked = true;
      this.validateName();
      this.validatePhoneNumber();
      this.validateCurrency();
      if (this.checkIfPasswordValid(false)) {
        this.invalidPassword = true;
        return;
      } else {
        this.invalidPassword = false;
      }
      this.validateTandCOnCheckboxChange();
      userData = { first_name: this.name.value, last_name: '', email: this.email.value, password: this.password.value, phone_number: this.phone.value,
          user_type: 'User', currency_id: this.phaseService.currencyForNow.id };

      if (this.name.value.includes(' ')) {
        // userData.first_name = this.name.value.split(' ')[0];
        // userData.last_name = this.name.value.split(' ')[1];
        userData.first_name = this.name.value.split(' ').slice(0, -1).join(' ');
        userData.last_name = this.name.value.split(' ').slice(-1).join(' ');
      }
      if (this.appDataService.urlParameters.inviteToken) {
        userData.invite_token = this.appDataService.urlParameters.inviteToken;
      }
      userData.signup_stage = this.appDataService.urlParameters.signup_stage;
      userData.utm_campaign = this.appDataService.urlParameters.utm_campaign;
      userData.utm_source = this.appDataService.urlParameters.utm_source;
      userData.utm_medium = this.appDataService.urlParameters.utm_medium;
      userData.utm_content = this.appDataService.urlParameters.utm_content;
      if (this.appDataService.urlParameters.is_freemium) {
        userData.customer_type = 'freemium';
      }
      if (this.appDataService.urlParameters.is_self_serve) {
        userData.is_self_serve_user = true;
      }
    }
    const data = (this.screenTobeAppear === 'login') ? this.loginSignupForm.value : userData;
    data.email = data.email.toLowerCase();

    this.phaseService.proceedClickedFromRental = true;
    this.isSignUpClicked = true;
    this.apiRequests.login_signup(data, this.screenTobeAppear).subscribe(resp => {
      this.phaseService.proceedClickedFromRental = false;
      if (this.dataService.user.roles === 'CPE') {
        this.appDataService.urlParameters.cpe_user = 'true';
        this.phaseService.showRentalPaymentFlow = false;
        this.getTemplateAlias();
        this.apiRequests.fetchTemplateDetails(this.paramTemplateAlias, false, true).subscribe((data: any) => {
          this.dataService.appDetails = this.dataService.templateDetailModel;
          this.phaseService.selectedApps = [];
          this.toggleSelectedAppsById(this.dataService.appDetails);
        });
        return;
      }
      this.dataService.setupHomePageData(resp);
      if (!this.appDataService.urlParameters.red) {
        this.phaseService.convertCloudPriceBasedOnUserCurrency();
      }
      this.phaseService.selectedHash.create_spec_call = 'false';
      this.phaseService.selectedHash.application_ids = [];
      if (this.dataService.appDetails) {
        this.phaseService.selectedHash.application_ids.push(this.dataService.appDetails.id);
      }
      this.dataService.loginNewModule = true;
      if (this.phaseService.conditionToCreateAndSyncBuildCardToBNow()) {
        this.dataService.showHideLoaderForBNow(true);
        this.apiRequests.createAndSyncBuildCardToNow(this.appDataService.urlParameters.application).subscribe((data: any) => {
          this.appDataService.urlParameters.red = null;
          this.appDataService.urlParameters.application = null;
          this.phaseService.showRentalPaymentFlow = false;
          window.open(this.dataService.homePageData.builder_partner.builder_now_url + '/' + data.id + '?red=now', '_self', "noopener");
        }, error => {
          this.appDataService.urlParameters.red = null;
          this.appDataService.urlParameters.application = null;
          this.phaseService.showRentalPaymentFlow = false;
          this.dataService.showHideLoaderForBNow(false);
        });
      } else {
        this.getTemplateAlias();
        this.apiRequests.fetchTemplateDetails(this.paramTemplateAlias, false, true).subscribe((data: any) => {
          this.dataService.appDetails = this.dataService.templateDetailModel;
          this.phaseService.selectedApps = [];
          this.toggleSelectedAppsById(this.dataService.appDetails);
          if (this.phaseService.meetNowForRentalClicked) {
          this.phaseService.showRentalPaymentFlow = false;
          this.dataService.showHideLoader(true);
          this.createBuildCard('appDetails');
          } else {
            if ((this.dataService.buildCardData && this.dataService.buildCardData.id) || (this.appDataService.urlParameters.is_freemium)) {
              this.phaseService.showRentalPaymentFlow = true;
              this.phaseService.handleNextPrevForRentalFlow(1);
            } else {
              this.dataService.showHideLoader(true);
              this.createBuildCard('appDetails');
            }
          }
      });
    }
      this.dataService.previousCurrency = this.dataService.homePageData.currency;
      if (this.screenTobeAppear === 'signup') {
        this.trackEvents(true, resp.user.email);
      } else {
        this.trackEvents(false, resp.user.email);
      }
      const userType = (this.screenTobeAppear === 'login') ? 'Signup' : 'Login';
      this.isSignUpClicked = false;
      this.phaseService.fetchUserShowData();
    }, error => {
      if (Object.keys(error.error.errors).includes('email')) {
        if (error.error.errors.email && error.error.errors.email[0] === 'is already taken') {
          this.emailExist = true;
          this.invalidPassword = false;
        } else {
          this.invalidEmail = true;
          this.invalidPassword = false;
        }
      }
      if (Object.keys(error.error.errors).includes('password')) {
        this.invalidEmail = false;
        this.invalidPassword = true;
      }
      this.phaseService.proceedClickedFromRental = false;
      this.isSignUpClicked = false;
    });
  }

  public getTemplateAlias() {
    this.route.params.subscribe((params: any) => {
      if (params.template_alias) {
        this.paramTemplateAlias = params.template_alias;
        if (this.paramTemplateAlias.indexOf(' ') !== -1) {
          this.paramTemplateAlias = this.paramTemplateAlias.split(' ').join('-').toLowerCase();
        }
      }
    });
    if (this.dataService.templateSlug) {
      this.paramTemplateAlias = this.dataService.templateSlug;
    }
    this.phaseService.rentalTemplateAlias = this.paramTemplateAlias;

  }

  public trackEvents(isSignUp, email) {
    if (isSignUp) {
      if (this.appDataService.urlParameters.is_freemium) {
        this.phaseService.trackEventForObject(this.analyticsSegment,  'freemium_new_user_created',{});
      }
      this.phaseService.trackEventForObject(this.analyticsSegment,  'sign_up_successful',{
        current_url: window.location.href,
        platform_source : 'email',
        screen_source : this.appDataService.urlParameters.is_freemium ? 'Freemium' : 'Studio Store',
        user_id : this.dataService.user?.id
      });
    } else {
      this.phaseService.trackEventForObject(this.analyticsSegment,  'sign_in_successful',{
        platform_source : 'email',
        screen_source : this.appDataService.urlParameters.is_freemium ? 'Freemium' : 'Studio Store',
        user_id : this.dataService.user?.id
      });
    }
  }

  createBuildCard(signUpClickedSrc?) {
    this.fetchHomePageData();
    this.dataService.isDirectCheckOut = this.dataService.appDetails.direct_checkout;
    this.dataService.isFromAppDetails = true;
    this.phaseService.isInstallmentsChanged = true;
    this.phaseService.selectedHash.status = Constants.buildCardStatus.CARD_COMPLETED;
    this.phaseService.selectedHash.source = 'app_detail';
    if (this.dataService.user) {
      if (!this.phaseService.isPayNowClickedForRental || this.phaseService.meetNowForRentalClicked) {
        this.dataService.showDeliveryLoading = false;
      } else {
        this.dataService.showDeliveryLoading = true;
      }
      if( this.appDataService.urlParameters.is_freemium ) {
        this.dataService.isSocialSignUpForStudioStore = false;
        this.phaseService.handleNextPrevForRentalFlow(1);
      } else {
        this.phaseService.setDataForFeaturePlatformAndTeam();
      }

    } else {
      this.dataService.signupSrcName = signUpClickedSrc;
      this.phaseService.preventClick = false;
      if (this.dataService.homePageData?.block_sign_up) {
        this.phaseService.showSignin = true;
        this.phaseService.showRegister = false;
      } else {
        this.phaseService.showRegister = true;
        this.phaseService.showSignin = false;
      }
      this.phaseService.openCommonAuth = true;
      this.phaseService.isNdaFlow = false;
    }
  }

  public showPopUp(e) {
    e.stopPropagation();
    this.phaseService.showCurrencyDropDown();
    if (!this.dataService.showCurrencyPopup) {
      this.validateCurrency();
    }
  }

  public hideOptionList(e) {
    e.stopPropagation();
    this.phaseService.hideOptionList();
  }

  public isShowCurrencyDropdown() {
    return this.phaseService.isShowCurrencyDropdownForBNow();
  }

  public selectCurrency(i, e) {
     e.stopPropagation();
    this.phaseService.selectCurrency(i);
    this.validateCurrency();
  }

  public getCurrency() {
    return this.phaseService.getCurrency();
  }

  public getCurrencyCode() {
    return this.phaseService.getCurrencyCode();
  }

  public getCurrencies() {
    return this.phaseService.getCurrencies();
  }

  public isCurrentCurrency(currency: CurrencyModel) {
    if (this.dataService.currentPage === 'templateDetail') {
      return this.phaseService.isCurrentCurrencyForRentalApp(currency);
   }
    return this.phaseService.isCurrentCurrency(currency);
  }

  public toggleSelectedAppsById(app: any): void {
    const selectedApp = this.phaseService.selectedApps.find(x => x.id === app.id);
    if (selectedApp) {
      const index = this.phaseService.selectedApps.indexOf(selectedApp);
      this.phaseService.selectedApps.splice(index, 1);
    } else {
      this.phaseService.selectedApps.push(app);
      if (this.dataService.buildCardData && !this.dataService.buildCardData.id) {
        if (this.dataService.user && (this.phaseService.selectedApps[0].pricing_modal === 'rental_price')) {
          this.dataService.homePageData.currency = this.dataService.user.currency;
        } else if (!this.dataService.user && (this.phaseService.selectedApps[0].pricing_modal === 'rental_price')) {
          this.dataService.homePageData.currency = app.currency;
        }
      }
    }
  }

  public social_sign(provider) {
    this.loginSignupForm.reset();
    if (!this.dataService.countryNameCode.includes('+')) {
      this.dataService.countryNameCode = '+'+this.dataService.countryNameCode;
    }
    this.phone.setValue(this.dataService.countryNameCode);
    this.auth.signOut();
    if (provider === 'google') {
      this.auth.signIn(GoogleLoginProvider.PROVIDER_ID);
    } else if (provider === 'facebook') {
      this.auth.signIn(FacebookLoginProvider.PROVIDER_ID);
    } else if(provider === 'linkedin') {
      this.linkedInSignUp(provider);
    }
    this.socialSignAuthState(provider);
  }

  linkedInSignUp(provider) {
    let redirect_url = '';
    if (this.dataService.cookie_domain === 'localhost') {
      redirect_url = 'http%3A%2F%2Flocalhost%3A4200';
    } else {
      redirect_url = 'https%3A%2F%2Fstaging.engineer.ai';
    }

    window.open(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77byqc5s5dcifv&redirect_uri=${redirect_url}&scope=r_liteprofile%20r_emailaddress`);
    const timer = setInterval(() => {
      const code = localStorage.getItem('code');
      if (code) {
        clearInterval(timer);
        this.getLinkedInUser(code);
      }
    }, 5000);
  }

  getLinkedInUser(code) {
    const userData = {
    }
    userData['code'] = code;
    userData['redirect_uri'] = window.location.origin;
    userData['utm_campaign'] = this.appDataService.urlParameters.utm_campaign;
    userData['utm_source'] = this.appDataService.urlParameters.utm_source;
    userData['utm_medium'] = this.appDataService.urlParameters.utm_medium;
    this.apiRequests.linkedIn_user(userData).subscribe((data) => {
      this.afterLogin(data);
      }, (error) => {

    });
  }

  public socialSignAuthState(provider) {
    this.dataService.socialSignUser = new SocialUser();
    this.sub = this.auth.authState.subscribe(
      (socialUser) => {
        if(this.dataService.socialSignUser.email) {
          return;
        }
        this.dataService.socialSignUser = socialUser;
        this.phaseService.SignUpProviders = provider;
        // this.ErrorMsg = '';
        if (!this.dataService.user) {

          if (this.appDataService.urlParameters.inviteToken) {
            this.userData.inviteToken = this.appDataService.urlParameters.inviteToken;
          }
          this.userData.signup_stage = this.appDataService.urlParameters.signup_stage;
          this.userData.utm_campaign = this.appDataService.urlParameters.utm_campaign;
          this.userData.utm_source = this.appDataService.urlParameters.utm_source;
          this.userData.utm_medium = this.appDataService.urlParameters.utm_medium;

          const user = Object.assign(this.userData, this.dataService.socialSignUser);
          this.apiRequests.login_signup(user, 'login').subscribe((subdata) => {
            this.zone.run(() => {
              this.afterLogin(user);
            });
          });
        }
      }, (error) => {
        console.log(error);
      });
  }

  afterLogin(user) {
    if (this.phaseService.SignUpProviders) {
        this.SignUpSocialPlatform = (this.phaseService.SignUpProviders === 'google') ? 'Google SignUp' : this.phaseService.SignUpProviders === 'facebook' ? 'Facebook SignIn' : 'Linkedin SignIn';
        if (this.appDataService.urlParameters.is_freemium) {
          this.phaseService.trackEventForObject(this.analyticsSegment,  'freemium_new_user_created',{});
        }
        this.phaseService.trackEventForObject(this.analyticsSegment,  'sign_up_successful',{
          current_url: window.location.href,
          platform_source : this.phaseService.SignUpProviders,
          screen_source : this.appDataService.urlParameters.is_freemium ? 'Freemium' : 'Studio Store',
          user_id : this.dataService.user?.id
        });
        this.phaseService.trackEventForObject(this.analyticsSegment,  'sign_in_successful',{
          platform_source : this.phaseService.SignUpProviders,
          screen_source : this.appDataService.urlParameters.is_freemium ? 'Freemium' : 'Studio Store',
          user_id : this.dataService.user?.id
        });
    }

    if (Object.keys(this.dataService.user).length && !this.dataService.user.currency) {
        this.dataService.isSocialSignUpForStudioStore = true;
        return;
    }
    if( this.appDataService.urlParameters.is_freemium) {
      this.phaseService.proceedClickedFromRental = true;
      this.phaseService.handleNextPrevForRentalFlow();
      return
    }
    if ( this.dataService.user) {
      this.forwardToSecondStep();
    }
  }

  createAccountThroughSocialAuth() {
    this.isSaveButtonClicked = true;
    this.isCreateAccountWithAuth = true;
    if (!this.selectedCurrency) {
      return;
    }
    if ( !this.signupTerm) {
      return;
    }
    if(this.checkIfPhoneInValid()) {
      return;
    }
    this.phaseService.selectedCurrency = this.selectedCurrency;
    this.phaseService.closeCurrencyChooserForUser(this.phone.value ? this.phone.value.replace(this.dataService.countryNameCode, this.dataService.countryNameCode + '-') : this.phone.value);

    if( this.appDataService.urlParameters.is_freemium) {
      this.phaseService.proceedClickedFromRental = true;
      this.dataService.showHideLoader(false);
      this.phaseService.handleNextPrevForRentalFlow(1);
      return
    }
    this.forwardToSecondStep();
  }

  forwardToSecondStep() {
    if (this.dataService.buildCardData && this.dataService.buildCardData.id) {
      this.phaseService.showRentalPaymentFlow = true;
      this.phaseService.handleNextPrevForRentalFlow(1);
    } else {
      this.dataService.showHideLoader(true);
      this.createBuildCard('appDetails');
    }
  }

  validateEmail() {
    this.ErrorMsg = '';
    if (this.checkIfEmailValid()) {
      this.invalidEmail = true;
      return;
    } else {
      this.invalidEmail = false;
    }
  }

  validateName() {
    if (this.checkIfNameValid()) {
      this.invalidName = true;
      return;
    } else {
      this.invalidName = false;
    }
  }

  validatePhoneNumber() {
    if (this.checkIfPhoneValid()) {
      this.invalidPhone = true;
      return;
    } else {
      this.invalidPhone = false;
    }
  }

  validateCurrency() {
    if (!this.checkIfCurrencyValid()) {
      this.invalidCurrency = true;
      return;
    } else {
      this.invalidCurrency = false;
    }
  }

  validatePassword() {
    if (this.checkIfPasswordValid(false)) {
      this.invalidPassword = true;
      return;
    } else {
      this.invalidPassword = false;
    }
  }

  validateTandCOnCheckboxChange() {
    if (this.termsconditionSignup && !this.termsconditionSignup.value) {
      this.termsConditionInvalid = true;
      return;
    } else {
      this.termsConditionInvalid = false;
    }
  }

 forgotPassword() {
  this.loginSignupForm.reset();
  this.screenTobeAppear = 'reset'
  this.dataService.newSignUpFlowScreen = 'reset';
 }

 backToSignIn(isFromForgotPassword?) {
   if (isFromForgotPassword) {
    this.ErrorMsg = '';
    this.resetValidations();
   }
   this.screenTobeAppear = 'login'
   this.dataService.newSignUpFlowScreen = 'login';
 }

 public send_email(form: FormGroup) {
  this.ErrorMsg = '';
  // this.sendButtonClass = '';
  this.apiRequests.login_signup(this.loginSignupForm.value, 'forgot').subscribe((data: any) => {
    this.screenTobeAppear = 'resetSuccess'
    // this.sendButtonClass = 'successAnimation';
      this.ErrorMsg = '';
      form.reset();
  }, error => {
    if (error.error.errors.email !== null) {
      this.ErrorMsg = 'Email ' + error.error.errors.email;
      if (this.ErrorMsg === "Email not found") {
        this.ErrorMsg = "User not found!";
      }
    } else {
      this.ErrorMsg = error.message;
    }
    // this.sendButtonClass = 'errorAnimation';
  });
}

  checkIfPhoneInValid() {
    const phoneNumber = parsePhoneNumber(this.phone.value.toString(), this.dataService.countryISOCode);
    if (phoneNumber) {
      return !(phoneNumber.isValid() && this.phone.value.includes('+' + phoneNumber.countryCallingCode));
    } else {
      return true;
    }
  }

  setUserContact(event) {
    this.phone.setValue(event);
  }

  public showSignUp() {
    this.dataService.isSocialSignUpForStudioStore = false;
    this.phaseService.showRegister = true;
    this.dataService.user = null;
    this.dataService.unsetCookie();
  }

  openUrls(url) {
    window.open(url);
  }

  checkIfUserNotFoundError() {
    if (this.ErrorMsg === "User not found!") {
      return true;
    }
    return false;
  }

}
