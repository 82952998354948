import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MessageEmitterService } from '@shared/services/message-emmiter.service';
import { Angulartics2Segment } from 'angulartics2/segment';
import { PhaseService } from '@shared/services/phase.service';
import { DataService } from '@shared/services/data.service';
import { IMyDateModel, IAngularMyDpOptions, SELECTED_DATE } from 'angular-mydatepicker';
import moment from 'moment';

@Component({
  selector: 'app-general-phase-platform',
  templateUrl: './general-phase-platform.component.html',
  styleUrls: ['./general-phase-platform.component.scss']
})
export class GeneralPhasePlatformComponent implements OnInit {

  showPlatformPopup: boolean;
  @Output() callCatAPiOnPlatformChange = new EventEmitter();
  showCalendar: boolean;
  model: IMyDateModel = null;
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'yyyy-mm-dd',
    inline: true,
  };
  isTodaySelected: boolean;
  kickOffDateInfo: boolean = false;
  platformInfoPopup = false;

  constructor(
    private messageEmitterService: MessageEmitterService,
    public phaseService: PhaseService,
    public analyticsSegment: Angulartics2Segment,
    public dataService: DataService
  ) {
  }

  ngOnInit() {
    this.messageEmitterService.sendMessage('some data');
    this.initDefaultCalendar();
    this.subscribeToDateChange();
  }

  subscribeToDateChange() {
    this.phaseService.preKickOffDateModified.subscribe(() => {
      this.setModelToPreKickOffDate();
    });
  }

  setModelToPreKickOffDate() {
    this.model = {
      isRange: false, singleDate: { jsDate: new Date(this.phaseService.selectedHash.pre_kick_off_date) },
    };
    this.checkAndSetIfTodaySelected(this.phaseService.selectedHash.pre_kick_off_date);
  }

  initDefaultCalendar() {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const disableUntil = {
      year: yesterday.getFullYear(),
      month: yesterday.getMonth() + 1,
      day: yesterday.getDate()
    };

    this.myDpOptions = {
      ...this.myDpOptions,
      disableUntil,
    };

    if (this.phaseService.selectedHash?.pre_kick_off_date) {
      this.setModelToPreKickOffDate();
    } else {
      this.model = { isRange: false, singleDate: { jsDate: today } };
      this.isTodaySelected = true;
    }
  }

  openPlatformPopup() {
    this.showPlatformPopup = true;
  }

  closePlatformPopup() {
    this.showPlatformPopup = false;
    this.callCatAPiOnPlatformChange.emit(true);
  }

  checkIfSelectedPlatformsAreDevices() {
    const devices = this.phaseService.selectedPlatformsForRapidPrototype.map(p => p.title);
    const areDevices = [];
    this.phaseService.selectedPlatforms.forEach(platform => {
      if (devices.indexOf(platform.title) !== -1) {
        areDevices.push(platform.title);
      }
    });
    return areDevices.length ? true : false;
  }

  getSelectedPlatforms() {
    let selectedPlatforms = [];
    if (this.phaseService.checkIfOnlyRapidPrototypeSelected() && this.dataService.homePageData.build_phases[2].is_tailor_made) {
      selectedPlatforms = this.phaseService.selectedPlatformsForRapidPrototype;
      this.phaseService.selectedHash.platform_ids = selectedPlatforms.map(p => p.id);
      return selectedPlatforms;
    }
    selectedPlatforms = this.checkIfSelectedPlatformsAreDevices() ? [this.dataService.homePageData.platforms[0]] : this.phaseService.selectedPlatforms;
    this.phaseService.selectedHash.platform_ids = selectedPlatforms.map(p => p.id);
    return selectedPlatforms;
  }

  toggleCalendarDropdown() {
    this.showCalendar = !this.showCalendar;
  }

  onDateChanged(event: IMyDateModel) {
    this.phaseService.selectedHash.pre_kick_off_date = event.singleDate.formatted;
    this.checkAndSetIfTodaySelected(event.singleDate.formatted);
    this.showCalendar = false;
    this.phaseService.getNewPhaseDates();
  }

  getSelectedDate() {
    return moment(this.model.singleDate.jsDate).format("D MMM YYYY");
  }

  checkAndSetIfTodaySelected(date) {
    const today = new Date();
    this.isTodaySelected = moment(date).isSame(today, 'day');
  }

  kickOffDateInfoToggle() {
    this.kickOffDateInfo = !this.kickOffDateInfo;
  }

  platformInfo() {
    /*let selectedPlatforms = this.getSelectedPlatforms();
    //let platformsNotCliked = selectedPlatforms.filter(p => p.id !== platform.id);
    for (let i=0; i<selectedPlatforms.length; i++) {
      let plt = selectedPlatforms[i];
      plt['showInfoBox'] = false;
    }*/
    this.platformInfoPopup = !this.platformInfoPopup;
  }

  /*platformInfonew(platform) {
    this.platformInfoPopup = false;
    let selectedPlatforms = this.getSelectedPlatforms();
    let platformsNotCliked = selectedPlatforms.filter(p => p.id !== platform.id);
    for (let i=0; i<platformsNotCliked.length; i++) {
      let plt = platformsNotCliked[i];
      plt['showInfoBox'] = false;
    }
    platform['showInfoBox'] = !platform['showInfoBox'];
  }*/
}
