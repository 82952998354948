import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import parsePhoneNumber from 'libphonenumber-js';
import {ApiRequests} from '@shared/services/api-requests.service';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import {AppDataService} from '@rootmodule/app-data.service';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent implements OnInit {

  countriesData = [];
  countryCodeOrName = '';
  showCountriesList = false;
  @ViewChild('searchNameOrCode') searchNameOrCode: ElementRef;
  @ViewChild('mobileNumber') public mobileNumber;
  countryISOCode: any;
  @Input() contactNumber: string;
  @Input() isSaveButtonClicked: boolean;
  @Output() setContactInUserInfo = new EventEmitter();
  @Output() closeCountryStateDropDown = new EventEmitter();
  @Input() disableField: boolean;

  constructor(private apiRequest: ApiRequests, public dataService: DataService, public phaseService: PhaseService,
              private appDataService: AppDataService) { }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const elementId = (event.target as Element).id;
    if (elementId && elementId.includes('hiddenInputBox')) {
      return false;
    }
    if (this.showCountriesList) {
      this.showCountriesList = false;
      this.countryCodeOrName = '';
    }
  }

  ngOnInit(): void {
    this.dataService.updateCountryCode.subscribe(() => {
      this.phaseService.countryNameCode = this.dataService.country_code ? this.dataService.country_code.toLowerCase() : '';
      this.getCountriesData();
    });
  }

  getCountriesData() {
    this.apiRequest.getCountriesList('../../../../assets/countries.json').subscribe(resp => {
      this.countriesData = resp;
      let countryNameCode = '';
      const user = this.dataService.user;
      const urlParams = this.appDataService.urlParameters;
      if (!this.dataService.countryNameCode && (user && user.phone_number) || urlParams.phone_number) {
        const phoneNumber = urlParams.phone_number ? urlParams.phone_number.split('-') : user.phone_number.split('-');
        if (phoneNumber && phoneNumber.length === 2) {
          const countryCode = phoneNumber[0].replace('+', '') ;
          countryNameCode = countryCode.replace(/\s/g, '');
          this.dataService.countryNameCode = countryNameCode;
        }
      } else {
        if (!this.dataService.countryNameCode) {
          countryNameCode = this.countriesData.find(country => (country.code.toLowerCase() === this.phaseService.countryNameCode)).dial_code
          countryNameCode = countryNameCode.replace('+', '');
        } else {
          countryNameCode = this.dataService.countryNameCode;
        }
        countryNameCode = countryNameCode.replace('+', '').replace(/\s/g, '');
        this.dataService.countryNameCode = countryNameCode;
      }
      const defaultCountry = this.countriesData.find(country => country.dial_code.substr(1) === countryNameCode);
      this.setPhoneNumber(defaultCountry);
    });
  }

  setPhoneNumber(country, event?) {
    if (event) {
      event.stopPropagation();
    }
    const user = this.dataService.user;
    if (event || (!user && !this.appDataService.urlParameters.phone_number) || (user && !user.phone_number)) {
      this.contactNumber = country.dial_code;
    }
    this.dataService.countryISOCode = country.code;
    this.dataService.countryNameCode = country.dial_code;
    this.showCountriesList = false;
    this.countryCodeOrName = '';
    this.contactNumber = this.contactNumber.replace(/\s/g, '');
    this.contactNumber = this.contactNumber.replace(this.dataService.countryNameCode, (this.dataService.countryNameCode + ' '));
    this.setContactInUserInfo.emit(this.contactNumber);
  }

  toggleCountriesList(event) {
    event.stopPropagation();
    this.showCountriesList = !this.showCountriesList;
    this.countryCodeOrName = '';
    if (this.showCountriesList) {
      this.searchNameOrCode.nativeElement.focus();
    }
    this.closeCountryStateDropDown.emit(true);
  }

  checkIfPhoneInValid() {
    const phoneNumber = parsePhoneNumber(this.contactNumber.toString(), this.dataService.countryISOCode);
    if (phoneNumber) {
      return !(phoneNumber.isValid() && this.contactNumber.includes('+' + phoneNumber.countryCallingCode));
    } else {
      return true;
    }
  }

  acceptNumberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && ((charCode < 48 && charCode !== 43) || charCode > 57)) {
      return false;
    }
    const phoneNumber = parsePhoneNumber(this.contactNumber.toString(), this.dataService.countryISOCode);
    if (phoneNumber) {
      const dialCode = '+' + phoneNumber.countryCallingCode;
      this.dataService.countryISOCode = this.countriesData.find(country => (country.dial_code === dialCode)).code;
      this.dataService.countryNameCode = phoneNumber.countryCallingCode.toString();
    }
    return true;
  }

  setFlagForDialCode() {
    const phoneNumber = parsePhoneNumber(this.contactNumber.toString(), this.dataService.countryISOCode);
    if (!phoneNumber && this.contactNumber) {
      const iSOCode = this.countriesData.find(country => (this.contactNumber.toString().includes(country.dial_code)));
      if (iSOCode) {
        this.dataService.countryISOCode = iSOCode.code;
      } else {
        this.dataService.countryISOCode = '';
      }
    }
    this.setContactInUserInfo.emit(this.contactNumber);
  }

  acceptAlphaNumeric(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode == 32) || (charCode == 43) || (charCode > 47 || charCode < 58) && ((charCode > 64 || charCode < 91)) && ((charCode > 96 || charCode < 123))) {
      return true;
    } else {
      return false;
    }
  }

}
