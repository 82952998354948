import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CoreFeatures'
})
export class CoreFeaturesPipe implements PipeTransform {

  transform(items: any, searchArray?: any): any {
    if (!items) { return []; }
    if (!searchArray.length) { return items; }
    searchArray = searchArray.map((item) => {
      return item.id;
    });
    return items.filter((item) => {
      return searchArray.indexOf(item.id) === -1;
    });
  }

}
