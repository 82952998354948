<div class="dlspaymentbox">
    <div class="clickArea" (click)="paymentToggle()">
        <div class="headtxt">Pay {{ isMonthlySelected() ? "monthly" : "upfront" }}</div>
        <div class="pricebox">
            <span class="ammount">{{dataService.getCurrency()}}
                <ng-container *ngIf='!isPayStarterPlan'> {{ this.isMonthlySelected() ? dataService.getLocaledPrice(dataService.appDetails.rental_prices.discount_rental_monthly_price.toFixed()) : dataService.getLocaledPrice(dataService.appDetails.rental_prices.discount_upfront_monthly_total_price.toFixed()) }}
                </ng-container>
                <ng-container *ngIf='isPayStarterPlan'> {{ this.isMonthlySelected() ? dataService.getLocaledPrice(dataService.appDetails.rental_starter_prices.discount_rental_monthly_price.toFixed()) : dataService.getLocaledPrice(dataService.appDetails.rental_starter_prices.discount_upfront_monthly_total_price.toFixed()) }}
                </ng-container>
            </span>
            <span *ngIf="phaseService.selectMonthlyPackage"> for {{ dataService.appDetails.month_count }} months</span>
        </div>
    </div>

    <div class="paymentPlanDropdown" *ngIf="paymentPlanBox">
        <div class="topSection">
            <div class="tabSection">
                <div class="tabName" [ngClass]="{'active': (selectedPlatform === 'web')}">Web only
                    <div class="tooltipBox" *ngIf="(selectedPlatform === 'all')">
                        <p><strong>You cannot change to a web only version now. If you want to switch to a “Web only” version, <span (click)="navigateToStorePlansSection()">click here</span>.</strong></p>
                        <p>Note: You will be taken to a new page and your customisation will be lost.</p>
                    </div>
                </div>
                <div class="tabName" [ngClass]="{'active': (selectedPlatform === 'all')}">Web and app
                    <div class="tooltipBox" *ngIf="(selectedPlatform === 'web')">
                        <p><strong>You cannot change to a web and app version now. If you want to switch to a “Web and app” version, <span (click)="navigateToStorePlansSection()">click here</span>.</strong></p>
                        <p>Note: You will be taken to a new page and your customisation will be lost.</p>
                    </div>
                </div>
            </div>
            <div class="payupfrontRow">
                <p>Pay upfront (Save 4 months payment)</p>
                <div class="toggleButton" (click)="toggleUpfrontPrice()" [ngClass]="{'active': showUpfrontPrice}"></div>
            </div>
        </div>
        <div class="planListing">
            <div class="planRow">
                <div class="leftSide">
                    <input type="radio" name="planradiobutton" id="starterplan" [checked]="isPayStarterPlan" (change)="showStarterPlanSelection()">
                    <label for="starterplan"></label>
                    <div class="labelName">Starter plan</div>
                    <div class="infoBox">
                        <div class="clickArea"><em class="icon-icon-panding"></em></div>
                    </div>
                </div>
                <div class="rightSide"> {{dataService.getCurrency()}}
                    
                    <ng-container> {{ this.isMonthlySelected() ? dataService.getLocaledPrice(dataService.appDetails.rental_starter_prices.discount_rental_monthly_price.toFixed()) : dataService.getLocaledPrice(dataService.appDetails.rental_starter_prices.discount_upfront_monthly_total_price.toFixed()) }}
                    </ng-container>
                    <ng-container *ngIf="phaseService.selectMonthlyPackage">x {{ dataService.appDetails.month_count }} months</ng-container>
                </div>
            </div>
            <div class="planRow">
                <div class="leftSide">
                    <input type="radio" name="planradiobutton" id="premiumplan" [checked]="!isPayStarterPlan" (change)="showPremiumPlanSelection()">
                    <label for="premiumplan"></label>
                    <div class="labelName">Premium plan</div>
                    <div class="infoBox">
                        <div class="clickArea"><em class="icon-icon-panding"></em></div>
                    </div>
                </div>
                <div class="rightSide"> {{dataService.getCurrency()}}
                    <!-- £499 x 24 months -->
                    <ng-container> {{ this.isMonthlySelected() ? dataService.getLocaledPrice(dataService.appDetails.rental_prices.discount_rental_monthly_price.toFixed()) : dataService.getLocaledPrice(dataService.appDetails.rental_prices.discount_upfront_monthly_total_price.toFixed()) }}
                    </ng-container>
                   
                    <ng-container *ngIf="phaseService.selectMonthlyPackage">x {{ dataService.appDetails.month_count }} months</ng-container>
                </div>
            </div>
        </div>
    </div>
    
</div>