import { Injectable } from '@angular/core';

@Injectable()
export class RentalService {
 isAddressSelected = false

    //  setUserAddress() {
    //     const userAddress = this.dataService.user.address,
    //       billingInfo = this.phaseService.billingInfo;
    //     billingInfo.contact = userAddress ? userAddress.phone.replace('-', '') : '';
    //     billingInfo.street = userAddress ? decodeURIComponent(userAddress.street) : '';
    //     billingInfo.apartment = userAddress ? decodeURIComponent(userAddress.apartment) : '';
    //     billingInfo.state = userAddress ? userAddress.state : '';
    //     billingInfo.city = userAddress ? userAddress.city : '';
    //     billingInfo.pincode = userAddress ? userAddress.pincode : '';
    //     billingInfo.country = userAddress ? userAddress.country : '';
    //     billingInfo.companyName = userAddress ? userAddress.company_name : '';
    //     billingInfo.gstNumber = userAddress ? userAddress.gst_number : '';
    //     billingInfo.firstname = userAddress && userAddress.first_name ? userAddress.first_name : billingInfo.firstname;
    //     billingInfo.lastname = userAddress && userAddress.last_name ? userAddress.last_name : billingInfo.lastname;
    // }

}
