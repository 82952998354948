import {Component, OnDestroy, OnInit} from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {PromotionModel} from '@models/homepage-data-model/promotion.model';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Intercom} from 'ng-intercom';
import {timeout} from 'rxjs/operators';
import {AppDataService} from '@rootmodule/app-data.service';

@Component({
  selector: 'app-payment-overview',
  templateUrl: './payment-overview.component.html',
  styleUrls: ['./payment-overview.component.scss']
})
export class PaymentOverviewComponent implements OnInit, OnDestroy {

  smbUser = false;
  promoObject: PromotionModel;
  private isAdditionalFeature = false;
  constructor(public phaseService: PhaseService, public dataService: DataService,
              public analyticsSegment: Angulartics2Segment, public appDataService: AppDataService) {
  }

  ngOnInit(): void {
    this.appDataService.ismicrosoftActive()
    this.phaseService.fetchInstallments();
    this.promoObject = this.phaseService.getPromotionObject();
    this.isAdditionalFeature = this.phaseService.additionalRentalFeatures.length ? true : false;
    if(!this.phaseService.IsFromFreemium && this.appDataService.ismicrosoftActive()){
      this.smbUser = true;
    }
  }



  getDevelopmentMonth() {
     return Math.ceil(this.dataService?.buildCardData?.rental_info.app_development_weeks / 4);
  }
  getNumberOfInstallments() {
    const installments = [];
    this.phaseService.paymentData.installments.forEach(installment => {
      if (installment.type === 'installment' && parseInt(installment.installment_amount, 10) > 0) {
        installments.push(installment);
      }
    });
    return installments.length;
  }

  lengthOfContract() {
    return this.dataService.buildCardData?.progress.apps[0].month_count;
  }

  getTotalCostWithoutAdditionalFeatures() {
    return  parseInt(String(this.phaseService.getDiscountedPriceForCards(this.dataService?.buildCardData)), 10) - parseInt(this.dataService?.buildCardData?.rental_info?.additonal_price, 10) ;
  }
  getTotalCostWithoutAdditionalFeaturesAndDiscount() {
    return  parseInt(String(this.dataService?.buildCardData?.price), 10) - parseInt(this.dataService?.buildCardData?.rental_info?.additonal_price, 10) ;
  }
  getTotalDeposit() {
    return (parseInt(this.dataService?.buildCardData?.rental_info.additional_deposit, 10) + parseInt(this.dataService?.buildCardData?.rental_info.studio_store_deposit, 10));
  }

  proceedNext() {
    if (this.appDataService.urlParameters.is_freemium && this.phaseService.IsFromFreemium) {
      this.phaseService.trackEventForObject(this.analyticsSegment,  'freemium_payment_progressed', {
        user_id : this.dataService.user?.id
      });
    } else {
      this.phaseService.trackEventForObject(this.analyticsSegment,  'payment_progressed_store', {
        user_id : this.dataService.user?.id
      });
    }
    this.phaseService.proceedClickedFromRental = true;
    this.phaseService.handleNextPrevForRentalFlow();
  }

  ngOnDestroy() {
    this.phaseService.proceedClickedFromRental = false;
  }

  getCurrencySymbol() {
    return this.dataService.buildCardData.id ? this.dataService.buildCardData.currency.symbol : this.dataService.user.currency.symbol;
  }
}
