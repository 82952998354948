import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from '@shared/services/data.service';
import { PhaseService } from '@shared/services/phase.service';

@Component({
  selector: 'app-dls-paymentbox',
  templateUrl: './dls-paymentbox.component.html',
  styleUrls: ['./dls-paymentbox.component.scss']
})
export class DlsPaymentboxComponent implements OnInit {
  @Input('isPayStarterPlan') isPayStarterPlan;
  @Input('selectedPlatform') selectedPlatform;
  @Output() updateToUpfrontSelection = new EventEmitter();
  @Output() updateToMonthlySelection = new EventEmitter();
  @Output() isShowStarterPlanSelection = new EventEmitter();
  @Output() navigateToStorePlanSection = new EventEmitter();
  paymentPlanBox = false;
  showUpfrontPrice = false;

  constructor(public dataService: DataService,
    public phaseService: PhaseService) { }

  ngOnInit(): void {
  }

  paymentToggle(){
    this.paymentPlanBox = !this.paymentPlanBox;
  }

  isMonthlySelected() {
    return this.phaseService.selectMonthlyPackage;
  }

  isUpFrontSelected() {
    return this.phaseService.selectUpfrontPackage;
  }

  toggleUpfrontPrice() {
    this.showUpfrontPrice = !this.showUpfrontPrice;
    if (this.showUpfrontPrice) {
      this.updateToUpfrontSelection.emit();
    } else {
      this.updateToMonthlySelection.emit();
    }
  }

  showStarterPlanSelection() {
    this.isShowStarterPlanSelection.emit(true);
  }

  showPremiumPlanSelection() {
    this.isShowStarterPlanSelection.emit(false);
  }

  navigateToStorePlansSection() {
    this.navigateToStorePlanSection.emit();
  }

}
