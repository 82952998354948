import {Component, OnInit} from '@angular/core';
import {BuildCardService} from '../../services/buildcard.service';
import {DataService} from '@shared/services/data.service';
import {Router} from '@angular/router';
import 'rxjs/add/operator/debounceTime';
import {FormControl} from '@angular/forms';
import { PhaseService } from '@shared/services/phase.service';

@Component({
  selector: 'app-overview',
  templateUrl: 'overview.component.html'
})

export class OverviewComponent implements OnInit {
  public page = 1;
  public perCount = 20;
  public searchQuery: FormControl = new FormControl();
  public scrolled = true;

  constructor(public buildCardService: BuildCardService,
              public dataService: DataService,
              private _router: Router,
              public phaseService: PhaseService) {
  }

  public ngOnInit() {
    this.getBuildCards();
    this.checkSearch();
  }

  public checkSearch() {
    this.searchQuery.valueChanges
      .debounceTime(500)
      .subscribe(
        result => {
          this.page = 1;
          this.getBuildCards(result);
        }
      );
  }

  public getBuildCards(query?: string) {
    this.dataService.showHideLoader(true);
    this.buildCardService.getBuildCards(this.page, this.perCount, query)
      .finally(() => {
        this.dataService.showHideLoader(false);
      })
      .subscribe(
        (data) => {
          this.scrolled = true;
          if (this.page === 1) {
            this.buildCardService.buildCards = data.build_cards || [];
          } else {
            this.buildCardService.buildCards.push(...data.build_cards);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  public getSingleBuildCard(card) {
    this.dataService.showHideLoader(true);
    this.buildCardService.getBuildCardData(card.id)
      .finally(() => {
        this.dataService.showHideLoader(false);
      })
      .subscribe(
        (data) => {
          this.viewBuildCard(data);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  public viewBuildCard(card) {
    this._router.navigate(['admin-dashboard/buildcard', card.id]);
  }

  public onScroll() {
    if (this.page < this.buildCardService.totalPages) {
      if (this.scrolled) {
        this.scrolled = false;
        this.page++;
        this.getBuildCards(this.searchQuery.value);
      }
    }
  }

  getDiscountedprice(card) {
    return this.phaseService.getDiscountedPriceForCards(card);
  }
}
