import {Component, OnInit} from '@angular/core';
import {PartnerDashboardDataService} from '../../Utils/partner-dashboard-data.service';
import {PartnerDashboardApiService} from '../../Utils/partner-dashboard-api.service';
import {DataService} from '@shared/services/data.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AppDataService} from '@rootmodule/app-data.service';


@Component({
  selector: 'app-partner-verify',
  templateUrl: 'verify.component.html'
})

export class PartnerVerifyComponent implements OnInit {
  public successMessage: string = null;
  public errorMessage: string = null;
  public isUniqueCodeVerified = false;
  public uniqueCodeForm: FormGroup;
  public loader = false;

  constructor(private appDataService: AppDataService,
              public partnerDataService: PartnerDashboardDataService,
              public dataService: DataService,
              private _formBuilder: FormBuilder,
              private _router: Router,
              private _partnerApiService: PartnerDashboardApiService) {
  }

  public ngOnInit() {
    this.initUniqueCodeForm();
    this.preFillUniqueCode();
  }

  public initUniqueCodeForm(): void {
    this.uniqueCodeForm = this._formBuilder.group({
      code: new FormControl(null, [Validators.required])
    });
  }

  public preFillUniqueCode(): void {
    if (this.appDataService.urlParameters.otp_code) {
      this._router.navigate(['become-a-partner']);
      this.uniqueCodeForm.setValue({
        code: this.appDataService.urlParameters.otp_code
      });
    }
  }

  public resendUniqueCode(): void {
    this.dataService.showHideLoader(true);
    this._partnerApiService.resendUniqueCode()
      .subscribe(
        (data) => {
          this.dataService.showHideLoader(false);
          this.successMessage = data.data;
        },
        (error) => {
          this.dataService.showHideLoader(false);
        }
      );
  }

  public onUniqueCodeSubmit(form: FormGroup): void {
    if (form.valid) {
      this.loader = true;
      this.partnerDataService.partnerStatus = 'uniq_code_verified';
      this._partnerApiService.checkUniqueCode(form.value)
        .subscribe(
          (data) => {
            this.loader = false;
            this.isUniqueCodeVerified = true;
            this.successMessage = 'Unique Code is verified now';
            this.dataService.showHideLoader(true);
            this._partnerApiService.updatePartnerProfile()
              .subscribe(
                (res) => {
                  this.partnerDataService.setPartnerUser(res);
                  this.dataService.showHideLoader(false);
                },
                (error) => {
                  this.dataService.showHideLoader(false);
                }
              );
          },
          (error) => {
            this.loader = false;
            if (error.error) {
              this.errorMessage = error.error.message;
            }
          }
        );
    }
  }

  public clearMessage(): void {
    this.successMessage = null;
    this.errorMessage = null;
  }

  public goToConfigureScreen(): void {
    if (this.isUniqueCodeVerified) {
      this.dataService.resetPartnerViews();
      this.dataService.configuration.step1 = true;
      this.dataService.showHideViews.configuration = true;
    }
  }
}
