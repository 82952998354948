<!--additional feature start-->
<div class="addi-feature-bx"
  *ngIf="(isEditFeatureRequest() && !phaseService.isRentalBuildCard()); else noAdditionalFeatures">
  <div class="paymentSummary dueNow addi-feature">
    <div class="mobilehandling">
      <!-- <h3> {{dataService.additionalFeatures.length}} Additional {{dataService.additionalFeatures.length > 1 ? 'Features'
        : 'Feature'}} </h3> -->
      <h3> Payment Summary</h3>
      <div class="downPayment">
        <ng-container>
          <div class="detailRow">
            <label>Customisation Cost</label>
            <p>
              <strong>
                {{getCurrencySymbol()}}{{getLocaledPrice(getTaxedPrice(phaseService.customizationCost) | number
                :'1.2-2')}}
              </strong>
            </p>
          </div>
          <div class="detailRow">
            <div class="fixedCost">
              <label>Fixed Cost</label>
            </div>
            <p>
              <strong>
                {{getCurrencySymbol()}}{{getLocaledPrice(getTaxedPrice(getFixedCost()) | number : '1.2-2')}}
              </strong>
            </p>
          </div>
        </ng-container>
        <div class="detailRow careContentRow" *ngIf="showCareForChangeReq()">
          <div class="builderCareRow">
            <label>
              <ng-container *ngIf="checkCareType() === 'standard_care'">Builder Care</ng-container>
              <ng-container *ngIf="(checkCareType() === 'standard_care_plus') && !dataService.buildCardData.subscription_flow">
                Builder Care Plus
              </ng-container>
              <ng-container *ngIf="(checkCareType() === 'standard_care_plus') && dataService.buildCardData.subscription_flow">
                Builder Studio One+
              </ng-container>
              <ng-container *ngIf="checkCareType() === 'enterprise_care'">Builder Enterprise Care</ng-container>
              <ng-container *ngIf="(checkCareType() === 'enterprise_care_plus') && !dataService.buildCardData.subscription_flow">
                Builder Enterprise Care Plus
              </ng-container>
              <ng-container *ngIf="(checkCareType() === 'enterprise_care_plus') && dataService.buildCardData.subscription_flow">
                Builder Enterprise One+
              </ng-container>
            </label>
            <div class="info" (click)="hideShowSupportAndMaintenance(true)">
              <img src="../../../../../assets/images/info_blue.png" alt="">
              <div class="infoBox bcareContent" *ngIf="!fromAddServices && showSupportAndMaintenance">
                <app-builder-care-content *ngIf="checkCareType() === 'standard_care'" [careType]="'standardCare'"
                  [isFromDeliveryPage]="false" [viewMoreSCare]="false"></app-builder-care-content>
                <app-builder-care-content *ngIf="checkCareType() === 'standard_care_plus'"
                  [careType]="'standardCarePlus'" [isFromDeliveryPage]="false" [viewMoreSCare]="false">
                </app-builder-care-content>
                <app-builder-care-content *ngIf="checkCareType() === 'enterprise_care'" [careType]="'enterpriseCare'"
                  [isFromDeliveryPage]="false" [viewMoreSCare]="false"></app-builder-care-content>
                <app-builder-care-content *ngIf="checkCareType() === 'enterprise_care_plus'"
                  [careType]="'enterpriseCarePlus'" [isFromDeliveryPage]="false" [viewMoreSCare]="false">
                </app-builder-care-content>
                <em class="icon-cancel closeInfo close-btn"
                  (click)="hideShowSupportAndMaintenance(false);$event.stopPropagation();"></em>
              </div>
            </div>
          </div>
          <p>
            <strong>
              <ng-container>{{getCurrencySymbol()}}{{dataService.buildCardData.subscription_flow ? getLocaledPrice(phaseService.totalCarePrice | number :
                '1.2-2') : getLocaledPrice(getTaxedPrice(phaseService.builderCarePrice*12) | number :
                '1.2-2')}}
                {{ dataService.buildCardData.subscription_flow ? '' : '/year' }}
              </ng-container>
            </strong>
          </p>
        </div>
        <div class="detailRow">
          <label class="title">Total Cost <br>
            <small *ngIf="((dataService.currentPage === 'paymentPlan') && (dataService.buildCardData?.currency?.tax > 0))">(Including Taxes<span>*</span>)</small>
          </label>
          <p>
            <strong>{{getCurrencySymbol()}}{{getLocaledPrice(getTaxedPrice(phaseService.totalDiscountedPriceWithCare) | number : '1.2-2')}}
            </strong>
          </p>
        </div>
        <div class="detailRow">
          <label>Indicative Development Duration </label>
          <p *ngIf="dataService.showMonthly; else showWeekly">
            <strong *ngIf="checkIfAddFeaturesOrPlatforms()">
              {{getDurationMonthsForAdditional(dataService.totalAdditionalFeaturesWeek)}} {{
              (getDurationMonthsForAdditional(dataService.totalAdditionalFeaturesWeek) > 1) ? 'Months' : 'Month' }}
            </strong>
            <strong *ngIf="checkIfNotAddFeaturesOrPlatforms()">0 Months</strong>
          </p>
          <ng-template #showWeekly>
            <p>
              <strong *ngIf="checkIfAddFeaturesOrPlatforms()"> {{dataService.totalAdditionalFeaturesWeek}} Weeks
              </strong>
              <strong *ngIf="checkIfNotAddFeaturesOrPlatforms()"> 0 Weeks </strong>
            </p>
          </ng-template>
        </div>
        <div class="detailRow estimateDeliveryDateRow">
          <div class="estimateDeliveryDate">
            <label>Estimated Delivery Date</label>
            <div class="info" (click)="toggleDeliveryInfo($event)">
              <img class="infoIcon" src="../../../../../assets/images/info_blue.png" alt="">
              <div class="infoBox" *ngIf="showDeliveryInfo">
                Estimated delivery date takes into account the existing Buildcard duration and new feature duration.
                <em class="icon-cancel closeInfo close-btn" (click)="toggleDeliveryInfo($event)"></em>
              </div>
            </div>
          </div>
          <p>{{ getEstimatedDeliveryDate(dataService.totalAdditionalFeaturesWeek) }}</p>
        </div>
      </div>
    </div>
    <div class="doc-upload"
      [ngClass]="{'ErrText': (phaseService.updatedInstamentsClicked && phaseService.DocNotUploaded)}"
      *ngIf="dataService.additionalFeatures.length && phaseService.showDocUploadArea && !appDataService?.urlParameters.cpe_user && dataService.user.roles=='CPE'">
      <h3>Please upload change request proof received from customer</h3>
      <div class="inputWrap">
        <input style="display: none;" type="file" (change)="onFileSelected($event)" #evidence />
        <label [ngClass]="{'uploaded-doc-Name ': !phaseService.DocNotUploaded }" (click)="clickLabel()">
          <img *ngIf="!phaseService.DocNotUploaded" class="upload-success"
            src="../../../../../assets/images/tick.svg" alt="" />{{this.phaseService.DocUploadedName}}
        </label>
        <span class="removeAttachment" *ngIf="!phaseService.DocNotUploaded" (click)="removeFile()">
          <em class="icon-cancel" title="Remove Attachment"></em>
        </span>
      </div>
      <p class="validationError" *ngIf="!this.fileSizeValid">Document size can be max of 2mb</p>
    </div>
    <div class="bottomBtnContainer">
      <div class="buttonWrap">
        <button *ngIf="dataService.currentPage ==='summary'" class="brand-btn greenBG btnAddMoreFeatures"
          (click)="startEditing('features', 'Buildcard Features Change',true)">Add more features/roles</button>
        <button type="button" class="brand-btn greenBG btnViewInstallments"
          [ngClass]="{'custom-disabled': !this.dataService.additionalFeatures.length}"
          *ngIf="phaseService.isEditFeatureRequest() && (dataService.currentPage ==='summary')"
          (click)="showUpdatedInstallments()"> View updated installments
        </button>
        <button type="button" class="brand-btn btnApproveContinue"
          *ngIf="!phaseService.isPlatformRequest && phaseService.isEditFeatureRequest() && (dataService.user.roles !== 'CPE' ) && (dataService.currentPage === 'paymentPlan') && !dataService.isEditBuildCardAfterSecurityPaid() && this.dataService?.activeChangeRequesObj?.status !== 'pending' "
          (click)="redirectToDashboardForAdditionalFeatures('draft')"> Save as draft
        </button>
        <button type="button" class="brand-btn btnApproveContinue btnApproveCancel"
                *ngIf="phaseService.isStoreFeatureRequest && (dataService.currentPage === 'paymentPlan')"
                (click)="goBackTOBHome()"> Cancel
        </button>
        <button type="button" class="brand-btn btnApproveContinue"
          *ngIf="phaseService.isEditFeatureRequest() && (dataService.currentPage === 'paymentPlan')"
          (click)="onApproveAndContinue('approveAndContinue')"
          [ngClass]="{'custom-disabled': dataService.activeAdditionalFeatureRequestCard}"> Approve and continue
          <div class="threeDotLoader" *ngIf="dataService.activeAdditionalFeatureRequestCard">
            <div class="dot-flashing"></div>
          </div>
        </button>
      </div>
    </div>

    <p class="addi-info">Your squad will review all the additional requirements and get back to you within 2 working
      days.</p>
    <p class="ErrMsg"
      *ngIf="(this.phaseService.updatedInstamentsClicked && !this.phaseService.allQuestionsAnswered && this.dataService.additionalFeatures.length)">
      Please choose a reason for adding each feature</p>
  </div>
</div>

<ng-template #noAdditionalFeatures>
  <div
    [ngClass]="{'editcardMobileview': dataService.buildCardData.modification_request && (dataService.currentPage === 'paymentPlan'), 'mobileActive': editcardMobileView}">
    <div class="paymentSummary modifypaymnt-summry"
      *ngIf="(dataService.currentPage !== 'payConfirm') && !phaseService.isRentalBuildCard()"
      [ngClass]="{'buildcard-payment': dataService.isMobileModel, 'ppSummary': phaseService.checkIfBuildCardForRapidPrototype()}">
      <h3 *ngIf="!phaseService.checkIfBuildCardForRapidPrototype()">Payment Summary <span class="updateTag"
          *ngIf="dataService.buildCardData.modification_request">Updated</span>
        <div class="expandicon" *ngIf="getSupportMaintenance()" (click)="serviceboxMobile()"
          [ngClass]="{active : additionalServiceMobileview}"><strong></strong></div>
        <div class="icon-cancel custom-only-xs" (click)="dataService.showModelOnMobile()"></div>
        <div class="closeEditcardMobileView visible-xs" (click)="editcardMobileClick()"
          *ngIf="dataService.buildCardData.modification_request && (dataService.currentPage === 'paymentPlan')"><em
            class="icon-cancel"></em></div>
      </h3>

      <div class="costDuration payableAmount proto-mobile" *ngIf="phaseService.checkIfBuildCardForRapidPrototype()">
        <div class="icon-cancel custom-only-xs" (click)="dataService.showModelOnMobile()"></div>
        <!-- Toggle row -->
        <div class="toggleRowWrap">
          <div class="detailRow" [ngClass]="{ 'toggleRow': showTaxBifurcation() }">
            <label class="title">Payable Amount <br>
              <small *ngIf="showTaxBifurcation()">
                (Including Taxes<span>*</span>)
              </small>
            </label>
            <p *ngIf="phaseService.isDiscountApplied()" class="hidden-xs">
              <span
                *ngIf="getBuildCardData().change_amount === 0 && !phaseService.weeksPromotionOnly()">{{getCurrencySymbol()}}
                <strong>{{ getLocaledPrice(getPayableAmount() | number : '1.2-2' )}}
                  <div  [ngClass]="{'active': showTotalPriceBreakup}" (click)="hideShowTotalPriceBreakup()"
                        *ngIf="showTaxBifurcation()" class="expand">
                  </div>
                </strong>
              </span>
              <strong>{{getCurrencySymbol()}}
                {{ getLocaledPrice(getPayableAmtWithChangeCost() | number : '1.2-2' )}}
                <div  [ngClass]="{'active': showTotalPriceBreakup}" (click)="hideShowTotalPriceBreakup()"
                      *ngIf="showTaxBifurcation()" class="expand">
                </div>
              </strong>
            </p>
            <p *ngIf="phaseService.isDiscountApplied()" class="visible-xs">
              {{getCurrencySymbol()}} <strong>{{ getLocaledPrice(getPayableAmount() | number : '1.2-2' )}}
                <div  [ngClass]="{'active': showTotalPriceBreakup}" (click)="hideShowTotalPriceBreakup()"
                      *ngIf="showTaxBifurcation()" class="expand">
                </div>
              </strong>
            </p>
            <p *ngIf="!phaseService.isDiscountApplied()">
              <strong> {{getCurrencySymbol()}}
                  {{ getLocaledPrice(getPayableAmtWithChangeCost() | number : '1.2-2' )}}
            <div  [ngClass]="{'active': showTotalPriceBreakup}" (click)="hideShowTotalPriceBreakup()"
                  *ngIf="showTaxBifurcation()" class="expand">
            </div>
              </strong>
            </p>
          </div>
          <ng-container *ngIf="showTotalPriceBreakup && showTaxBifurcation()">
            <div class="detailRow collapseRow cardCost">
              <label>Buildcard Cost</label>
              <p>{{getCurrencySymbol()}}
                  {{ getLocaledPrice(getProjectCost() | number : '1.2-2')}}
              </p>
            </div>
            <div class="detailRow collapseRow taxes">
              <label>Taxes ({{dataService.buildCardData?.currency?.tax}}%
                {{dataService.buildCardData?.currency?.tax_type}})</label>
              <p>{{getCurrencySymbol()}}{{getLocaledPrice(getPrototypeTaxOnTotal() | number : '1.2-2')}}</p>
            </div>
          </ng-container>
        </div>
        <!-- Toggle row end -->

        <div class="detailRow">
          <label>Delivered By</label>
          <p>
            <strong>{{getDeliveryDateForRapidPrototype()}}</strong>
          </p>
        </div>
      </div>

      <div class="costDuration" *ngIf="!phaseService.checkIfBuildCardForRapidPrototype()">
        <ng-container *ngIf="!phaseService.isFeatureRequest && !dataService.isEditBuildCardAfterSecurityPaid()">
          <div class="detailRow">
            <label>Customisation Cost</label>
            <p>
              <strong>
                {{getCurrencySymbol()}}{{getLocaledPrice(dataService.buildCardData?.customisation_price | number :
                '1.2-2')}}
              </strong>
            </p>
          </div>
          <div class="detailRow">
            <div class="fixedCost">
              <label>Fixed Cost</label>
              <span class="info" *ngIf="dataService.buildCardData.subscription_flow" (click)="toggleFixedCostTooltip($event)">
                <img src="../../../../../assets/images/info_blue.png" alt="" class="infoIcon">
                <div class="tooltipOverlay" *ngIf="showFixedCostTooltip" (click)="toggleFixedCostTooltip($event)"></div>
                <div class="infoBoxDark" *ngIf="showFixedCostTooltip"><!-- TODO: use overlay and hide on click -->
                  <h4>Includes {{ dataService.checkIfEnterpriseUserOrCard() ? 'Enterprise' : 'Studio' }} One for 1 year</h4>
                  <h5>This gives you:</h5>
                  <ul>
                    <li>Access to our platform to track your app build</li>
                    <li>Ongoing expert support</li>
                    <li>Collaboration tools to manage your project</li>
                    <li>Updates, bug fixes and improvements - even after your app's delivered</li>
                  </ul>
                  <em class="icon-cancel closeInfo close-btn" (click)="toggleFixedCostTooltip($event)"></em>
                </div>
              </span>
            </div>
            <p>
              <strong>
                {{getCurrencySymbol()}}{{getLocaledPrice(getFixedCost() | number : '1.2-2')}}
              </strong>
            </p>
          </div>

          <div class="detailRow careContentRow" *ngIf="showCare('bcare')">
            <div class="builderCareRow">
              <label>
                <ng-container *ngIf="checkCareType() === 'standard_care'">Builder Care</ng-container>
                <ng-container *ngIf="checkCareType() === 'standard_care_plus'">Builder Care Plus</ng-container>
                <ng-container *ngIf="checkCareType() === 'enterprise_care'">Builder Enterprise Care</ng-container>
                <ng-container *ngIf="checkCareType() === 'enterprise_care_plus'">Builder Enterprise Care Plus
                </ng-container>
              </label>
              <div class="info" (click)="hideShowSupportAndMaintenance(true)">
                <img src="../../../../../assets/images/info_blue.png" alt="">
                <div class="infoBox bcareContent" *ngIf="!fromAddServices && showSupportAndMaintenance">
                  <app-builder-care-content *ngIf="checkCareType() === 'standard_care'" [careType]="'standardCare'"
                    [isFromDeliveryPage]="false" [viewMoreSCare]="false"></app-builder-care-content>
                  <app-builder-care-content *ngIf="checkCareType() === 'standard_care_plus'"
                    [careType]="'standardCarePlus'" [isFromDeliveryPage]="false" [viewMoreSCare]="false">
                  </app-builder-care-content>
                  <app-builder-care-content *ngIf="checkCareType() === 'enterprise_care'" [careType]="'enterpriseCare'"
                    [isFromDeliveryPage]="false" [viewMoreSCare]="false">
                  </app-builder-care-content>
                  <app-builder-care-content *ngIf="checkCareType() === 'enterprise_care_plus'"
                    [careType]="'enterpriseCarePlus'" [isFromDeliveryPage]="false" [viewMoreSCare]="false">
                  </app-builder-care-content>
                  <em class="icon-cancel closeInfo close-btn"
                    (click)="hideShowSupportAndMaintenance(false);$event.stopPropagation();"></em>
                </div>
              </div>
            </div>

            <p>
              <strong>
                <ng-container *ngIf="dataService.modifiedBuildCardData?.support_maintenance_price.new; else supportDataFromMainBuildCard">{{getCurrencySymbol()}} {{getModifiedBuilderCarePrice() | number : '1.2-2'}}</ng-container>
                <ng-template #supportDataFromMainBuildCard>
                  {{getCurrencySymbol()}}{{!isPaymentType() ? (getLocaledPrice(getBuildCardData().support_yearly_price | number :
                  '1.2-2')) : getLocaledPrice(getInstallmentWithTax(getSupportMaintenance())
                  | number : '1.2-2')}}
                </ng-template> /year
              </strong>
            </p>

          </div>
          <div class="detailRow careContentRow studioSubscription" *ngIf="showCare('subscription')">
            <div class="builderCareRow">
              <label class="careText">
                <ng-container *ngIf="checkCareType() === 'standard_care_plus'">Builder Studio One+</ng-container>
                <ng-container *ngIf="checkCareType() === 'enterprise_care_plus'">Builder Enterprise One+
                </ng-container>

                <div class="info" (click)="hideShowSupportAndMaintenance(true)">
                  <img class="iconInfoPurple" src="../../../../../assets/images/info_purple.png" alt="">
                  <div class="infoBox bcareContent" *ngIf="!fromAddServices && showSupportAndMaintenance">
                    <app-builder-care-content *ngIf="checkCareType() === 'standard_care'" [careType]="'standardCare'"
                                              [isFromDeliveryPage]="false" [viewMoreSCare]="false"></app-builder-care-content>
                    <app-builder-care-content *ngIf="checkCareType() === 'standard_care_plus'"
                                              [careType]="'standardCarePlus'" [isFromDeliveryPage]="false" [viewMoreSCare]="false">
                    </app-builder-care-content>
                    <app-builder-care-content *ngIf="checkCareType() === 'enterprise_care'" [careType]="'enterpriseCare'"
                                              [isFromDeliveryPage]="false" [viewMoreSCare]="false">
                    </app-builder-care-content>
                    <app-builder-care-content *ngIf="checkCareType() === 'enterprise_care_plus'"
                                              [careType]="'enterpriseCarePlus'" [isFromDeliveryPage]="false" [viewMoreSCare]="false">
                    </app-builder-care-content>
                    <em class="icon-cancel closeInfo close-btn"
                        (click)="hideShowSupportAndMaintenance(false);$event.stopPropagation();"></em>
                  </div>
                </div>
              </label>
            </div>

            <p>
              <strong>
                <ng-container *ngIf="dataService.modifiedBuildCardData?.support_maintenance_price.new; else supportDataFromMainBuildCard">{{getCurrencySymbol()}} {{getLocaledPrice(getModifiedBuilderCarePrice() | number : '1.2-2')}}</ng-container>
                <ng-template #supportDataFromMainBuildCard>
                  {{getCurrencySymbol()}}{{dataService.buildCardData.subscription_flow ? getLocaledPrice(getCarePrice() | number :'1.2-2') :
                  getLocaledPrice(getInstallmentWithTax(getSupportMaintenance()) | number : '1.2-2')}}
                </ng-template>
              </strong>
            </p>

          </div>
        </ng-container>

        <ng-container *ngIf="isPaymentType()">
          <div *ngIf='!dataService.modifiedBuildCardData'>
            <div class="totalCostContainer" *ngIf="(phaseService.paymentData.general_info.tax > 0); else withoutTax">
              <div class="detailRow toggleRow">
                <label class="title">Total Cost <br><small>(Including Taxes<span>*</span>)</small></label>
                <p class="boldText">
                  <span *ngIf="(getBuildCardData().change_amount === 0 && (getprice(dataService.buildCardData) !== getDiscountedprice(dataService.buildCardData)) && (phaseService.costWithoutDiscount > 0))">{{getCurrencySymbol()}}{{getLocaledPrice(phaseService.costWithoutDiscount | number : '1.2-2') }}</span>
                  <strong>{{getCurrencySymbol()}}{{getLocaledPrice(getTotalWithTax() | number : '1.2-2')}}<div class="expand" [ngClass]="{'active': showTotalPriceBreakup}"(click)="hideShowTotalPriceBreakup()"></div></strong>
                </p>
              </div>
              <div *ngIf="showTotalPriceBreakup" class="detailRow collapseRow cardCost">
                <label>Buildcard Cost</label>
                <p>{{getCurrencySymbol()}}{{getLocaledPrice(getProjectCost() | number : '1.2-2')}}</p>
              </div>
              <div *ngIf="showTotalPriceBreakup && (dataService.buildCardData?.currency?.tax > 0)"
                class="detailRow collapseRow taxes">
                <label>Taxes ({{dataService.buildCardData?.currency?.tax}}%
                  {{dataService.buildCardData?.currency?.tax_type}})</label>
                <p>{{getCurrencySymbol()}}{{getLocaledPrice(getTaxAmount() | number : '1.2-2')}}</p>
              </div>
            </div>
            <ng-template #withoutTax>
              <div class="totalCostContainer">
                <div class="detailRow">
                  <label class="title">Total Cost </label>
                  <p>
                    <span *ngIf="(getBuildCardData().change_amount === 0 && (getprice(dataService.buildCardData) !== getDiscountedprice(dataService.buildCardData)) && (phaseService.costWithoutDiscount > 0))">{{getCurrencySymbol()}}{{getLocaledPrice(phaseService.costWithoutDiscount
                      | number : '1.2-2') }}</span>
                    <strong>{{getCurrencySymbol()}}{{getLocaledPrice(getTotalWithTax() | number : '1.2-2')}}</strong>
                  </p>
                </div>
              </div>
            </ng-template>
          </div>
          <div *ngIf='dataService.modifiedBuildCardData'>
            <div class="totalCostContainer">
              <div class="detailRow toggleRow">
                <label class="title">Total Cost <br><small>({{phaseService.selectedFeatures.length}} Features)</small></label>
                <p>
                  <strong>
                    <ng-container *ngIf="phaseService.isDiscountApplied()">
                      <span *ngIf="dataService.modifiedBuildCardData && getPriceForPaidCards()?.new && (getDiscountedPriceForPaidCards()?.new < getPriceForPaidCards()?.new)">{{getCurrencySymbol()}}{{getLocaledPrice(getPriceForPaidCards()?.new | number : '1.2-2') }}</span>
                      <span *ngIf="dataService.modifiedBuildCardData && !getPriceForPaidCards()?.new && (getDiscountedPriceForPaidCards()?.old > getDiscountedPriceForPaidCards()?.new)">{{getCurrencySymbol()}}{{getLocaledPrice(getDiscountedPriceForPaidCards()?.old | number :'1.2-2')}}</span>
                    </ng-container>
                    {{getCurrencySymbol()}}{{getLocaledPrice(getTotalUpdatedPriceWithTax() | number : '1.2-2')}}
                    <div class="expand" *ngIf="(dataService.buildCardData?.currency?.tax > 0)"
                         [ngClass]="{'active': showTotalPriceBreakup}" (click)="hideShowTotalPriceBreakup()">
                    </div>
                  </strong>
                </p>
              </div>
              <ng-container *ngIf="showTotalPriceBreakup && (dataService.buildCardData?.currency?.tax > 0)">
                <div class="detailRow collapseRow cardCost">
                  <label>Buildcard Cost</label>
                  <p>{{getCurrencySymbol()}}{{getLocaledPrice(getTotalUpdatedPrice() | number : '1.2-2')}}</p>
                </div>
                <div class="detailRow collapseRow taxes">
                  <label>Taxes ({{dataService.buildCardData?.currency?.tax}}%
                    {{dataService.buildCardData?.currency?.tax_type}})</label>
                  <p>{{getCurrencySymbol()}}{{getLocaledPrice(getUpdatedTaxPrice() | number : '1.2-2')}}</p>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <div class="totalCostContainer" *ngIf="!isPaymentType()">
          <div class="detailRow">
            <label class="title">Total Cost <br>
              <small *ngIf='dataService.modifiedBuildCardData'>({{phaseService.selectedFeatures.length}}Features)</small>
            </label>
            <p *ngIf="phaseService.isDiscountApplied()" class="hidden-xs">
              <ng-container *ngIf="getBuildCardData().change_amount === 0 && !phaseService.weeksPromotionOnly() && !dataService.modifiedBuildCardData">
                <span>{{getCurrencySymbol()}}{{ getLocaledPrice(getprice(getBuildCardData()) | number : '1.2-2') }}</span>
              </ng-container>
              <strong>
                {{getCurrencySymbol()}}<ng-container *ngIf='!dataService.modifiedBuildCardData'>{{getLocaledPrice(getDiscountedprice(getBuildCardData()) | number : '1.2-2') }}</ng-container>
                <span *ngIf="dataService.modifiedBuildCardData && getPriceForPaidCards()?.new && (getDiscountedPriceForPaidCards()?.new < getPriceForPaidCards()?.new)">{{getCurrencySymbol()}}{{getLocaledPrice(getPriceForPaidCards()?.new | number : '1.2-2') }}</span>
                <span *ngIf="dataService.modifiedBuildCardData && !getPriceForPaidCards()?.new && (getDiscountedPriceForPaidCards()?.old > getDiscountedPriceForPaidCards()?.new)">{{getCurrencySymbol()}}{{getLocaledPrice(getDiscountedPriceForPaidCards()?.old | number : '1.2-2') }}</span>
                <ng-container *ngIf='dataService.modifiedBuildCardData'>{{getLocaledPrice(getTotalUpdatedPrice() | number : '1.2-2') }}</ng-container>
              </strong>
            </p>
            <p *ngIf="phaseService.isDiscountApplied()" class="visible-xs">
              {{getCurrencySymbol()}}<ng-container *ngIf='!dataService.modifiedBuildCardData'>{{ getLocaledPrice(getprice(getBuildCardData()) | number : '1.2-2') }}</ng-container>
              <ng-container *ngIf='dataService.modifiedBuildCardData'>{{ getLocaledPrice(getTotalUpdatedPrice() | number : '1.2-2') }}</ng-container>
            </p>
            <p *ngIf="!phaseService.isDiscountApplied()">
              <strong>
                {{getCurrencySymbol()}}<ng-container *ngIf='!dataService.modifiedBuildCardData && !dataService.isEditBuildCardAfterSecurityPaid()'>{{ getLocaledPrice(getDiscountedprice(getBuildCardData()) | number : '1.2-2') }}</ng-container>
                <ng-container *ngIf='!dataService.modifiedBuildCardData && dataService.isEditBuildCardAfterSecurityPaid()'>{{ getLocaledPrice(getDiscountedprice(getBuildCardData()) | number : '1.2-2') }}</ng-container>
                <ng-container *ngIf='dataService.modifiedBuildCardData'>{{ getLocaledPrice(getTotalUpdatedPrice() | number : '1.2-2') }}</ng-container>
              </strong>
            </p>
          </div>
        </div>

        <div class="detailRow"
          *ngIf="!isPaymentType() && !dataService.modifiedBuildCardData && !dataService.buildCardData.new_care_flow">
          <ng-container
            *ngIf="(dataService.buildCardData && (dataService.buildCardData.frequency === 'weekly')); else showMonthlyCost">
            <label>Weekly cost</label>
          </ng-container>
          <ng-template #showMonthlyCost>
            <label>Monthly cost</label>
          </ng-template>
          <p>
            {{getCurrencySymbol()}} {{dataService.getLocaledPrice(((getPerWeekOrMonthCost()) | number : '1.2-2'), dataService.buildCardData.currency)}}
          </p>
        </div>

        <div class="detailRow">
          <label><span *ngIf='!dataService.modifiedBuildCardData'>Indicative Development</span> Duration</label>
          <ng-container *ngIf="dataService.currentPage === 'paymentPlan'; else otherPage">
            <p *ngIf="dataService.showMonthly; else showWeekly" class="boldText">
              <ng-container *ngIf='!dataService.modifiedBuildCardData'>
                {{getDurationMonths()}} {{ (getDurationMonths() > 1) ? 'Months' : 'Month' }}
              </ng-container>
              <ng-container *ngIf='dataService.modifiedBuildCardData'>
                {{getDurationMonthsForEditBuildCard()}} {{getDurationMonthsForEditBuildCard() > 1 ? 'Months' : 'Month'
                }}
              </ng-container>
            </p>
            <ng-template #showWeekly>
              <p class="boldText">
                <ng-container *ngIf='!dataService.modifiedBuildCardData'>
                  {{ getBuildCardData().weeks + (getBuildCardData().change_week_status ?
                  getBuildCardData().change_week
                  : -getBuildCardData().change_week)
                  | number : '1.0-0'}} {{(getBuildCardData().weeks | number : '1.0-0') > 1 ? 'weeks' : 'week'}}
                </ng-container>
                <ng-container *ngIf='dataService.modifiedBuildCardData'>
                  {{getWeeks()}} {{getWeeks() > 1 ? 'weeks' : 'week'}}
                </ng-container>
              </p>
            </ng-template>
          </ng-container>
          <ng-template #otherPage>
            <ng-container
              *ngIf="(dataService.buildCardData && ((dataService.showMonthly) || !dataService.buildCardData.frequency)); else showWeekly">
              <p class="boldText">
                <ng-container *ngIf='!dataService.modifiedBuildCardData'>
                  {{dataService.buildCardData?.installment_counts?.monthly}} {{
                  dataService.buildCardData?.installment_counts?.monthly > 1
                  ? 'Months' : 'Month'}}</ng-container>
                <ng-container *ngIf='dataService.modifiedBuildCardData'>
                  {{getDurationMonthsForEditBuildCard()}} {{getDurationMonthsForEditBuildCard() > 1 ? 'Months' :
                  'Month'
                  }}
                </ng-container>
              </p>
            </ng-container>
            <ng-template #showWeekly>
              <p class="boldText">
                <ng-container *ngIf='!dataService.modifiedBuildCardData'>
                  {{ dataService.buildCardData?.installment_counts?.weekly}}
                  {{dataService.buildCardData?.installment_counts?.weekly > 1 ?
                  'weeks' : 'week'}}
                </ng-container>
                <ng-container *ngIf='dataService.modifiedBuildCardData'>
                  {{getWeeks()}} {{getWeeks() > 1 ? 'weeks' : 'week'}}
                </ng-container>
              </p>
            </ng-template>
          </ng-template>
        </div>

        <div class="detailRow estimateDeliveryDateRow">
          <div class="estimateDeliveryDate">
            <label>Estimated Delivery Date</label>
            <div class="info" (click)="toggleDeliveryInfo($event)">
              <img class="infoIcon" src="../../../../../assets/images/info_blue.png" alt="" />
              <div class="infoBox" *ngIf="showDeliveryInfo">
                This date is getting calculated basis the assumption that kick-off call happens today.
                <em class="icon-cancel closeInfo close-btn" (click)="toggleDeliveryInfo($event)"></em>
              </div>
            </div>
          </div>
          <p class="boldText">{{ getEstimatedDeliveryDate() }}</p>
        </div>

      </div>

      <!-- new added by manoj start -->
      <div class="oldSummary" [ngClass]="isActiveOldSummary ? 'active' : ''" *ngIf="dataService.modifiedBuildCardData"
        (click)="toggleOldSummary()">Old Summary</div>
      <div class="costDuration" *ngIf="dataService.modifiedBuildCardData">
        <div *ngIf='isActiveOldSummary == true'>
          <ng-container *ngIf="!phaseService.isFeatureRequest && !dataService.isEditBuildCardAfterSecurityPaid()">
            <div class="detailRow">
              <label>Customisation Cost</label>
              <p>
                <strong>
                  {{getCurrencySymbol()}}{{getLocaledPrice(dataService.buildCardData?.customisation_price | number :
                  '1.2-2')}}
                </strong>
              </p>
            </div>
            <div class="detailRow">
              <div class="fixedCost">
                <label>Fixed Cost</label>
                <span class="info">
                  <img src="../../../../../assets/images/info_blue.png" alt="" class="infoIcon">
                </span>
              </div>
              <p>
                <strong>
                  {{getCurrencySymbol()}}{{getLocaledPrice(getFixedCost() | number: '1.2-2')}}
                </strong>
              </p>
            </div>
            <div class="detailRow" *ngIf="getSupportMaintenance()">
              <div class="builderCareRow">
                <label>
                  <ng-container>{{
                    showCareType(dataService.buildCardData.modification_request.base_build_card_data.care_type) }}
                  </ng-container>
                </label>
                <div class="info" (click)="hideShowSupportAndMaintenance(true)">
                  <img src="../../../../../assets/images/info_blue.png" alt="">
                  <div class="infoBox" *ngIf="!fromAddServices && showSupportAndMaintenance">
                    <app-builder-care-content *ngIf="checkCareType() === 'standard_care'" [careType]="'standardCare'"
                      [isFromDeliveryPage]="false" [viewMoreSCare]="false"></app-builder-care-content>
                    <app-builder-care-content *ngIf="checkCareType() === 'standard_care_plus'"
                      [careType]="'standardCarePlus'" [isFromDeliveryPage]="false" [viewMoreSCare]="false">
                    </app-builder-care-content>
                    <app-builder-care-content *ngIf="checkCareType() === 'enterprise_care'"
                      [careType]="'enterpriseCare'" [isFromDeliveryPage]="false" [viewMoreSCare]="false">
                    </app-builder-care-content>
                    <app-builder-care-content *ngIf="checkCareType() === 'enterprise_care_plus'"
                      [careType]="'enterpriseCarePlus'" [isFromDeliveryPage]="false" [viewMoreSCare]="false">
                    </app-builder-care-content>
                    <em class="icon-cancel closeInfo close-btn"
                      (click)="hideShowSupportAndMaintenance(false);$event.stopPropagation();"></em>
                  </div>
                </div>
              </div>
              <p>
                <strong>
                  <ng-container>{{getCurrencySymbol()}}{{getLocaledPrice(dataService.buildCardData?.modification_request?.base_build_card_data?.support_maintenance_price* 12 | number : '1.2-2')}}/year</ng-container>
                </strong>
              </p>

            </div>
          </ng-container>
          <!-- ============== -->
          <div class="detailRow toggleRow">
            <label class="title">Total Cost <br>
              <small>({{phaseService.getOldFeatureCount()}} {{phaseService.getOldFeatureCount() > 1 ? 'Features':
                'Feature'}})</small>
            </label>
            <p *ngIf="!isPaymentType()">{{getCurrencySymbol()}}{{getLocaledPrice(dataService.getOldPrice() | number :
              '1.2-2' )}} </p>
            <p *ngIf="isPaymentType()">
              <strong>
                {{getCurrencySymbol()}}{{getLocaledPrice(getOldPriceWithTax() | number : '1.2-2')}}
                <div class="expand" *ngIf="(dataService.buildCardData?.currency?.tax > 0)"
                     [ngClass]="{'active': showOldTotalPriceBreakup}" (click)="toggleOldTotalPriceBreakup()">
                </div>
              </strong>
            </p>
          </div>
          <ng-container *ngIf="showOldTotalPriceBreakup && (dataService.buildCardData?.currency?.tax > 0)">
            <div class="detailRow collapseRow cardCost">
              <label>Buildcard Cost</label>
              <p>{{getCurrencySymbol()}}{{getLocaledPrice(getProjectCost() | number : '1.2-2')}}</p>
            </div>
            <div class="detailRow collapseRow taxes">
              <label>Taxes ({{dataService.buildCardData?.currency?.tax}}%
                {{dataService.buildCardData?.currency?.tax_type}})</label>
              <p>{{getCurrencySymbol()}}{{getLocaledPrice(getTaxAmount() | number : '1.2-2')}}</p>
            </div>
          </ng-container>

          <div class="detailRow">
            <label>Duration </label>
            <p *ngIf="dataService.showMonthly; else showWeeklyOldDuration">
              {{getOldMonthDuration()}} {{getOldMonthDuration() > 1 ? 'Months' : 'Month' }} ({{getOldWeeks()}}
              {{getOldWeeks() > 1 ? 'weeks'
              : 'week'}})
            </p>
            <ng-template #showWeeklyOldDuration>
              {{getOldWeeks()}} {{getOldWeeks() > 1 ? 'weeks' : 'week'}}
            </ng-template>
          </div>

          <div class="detailRow" *ngIf='dataService.buildCardData.modification_request.base_build_card_data.promotion'>
            <label>Promotion:</label>
            <p> {{dataService.buildCardData.modification_request.base_build_card_data.promotion.data.attributes.code}}
              Applied</p>
          </div>
        </div>
      </div>
      <!-- new added by manoj close -->
      <div *ngIf="dataService.user.enable_referral_code">
        <div class="referralCodeBox"
          *ngIf="(dataService.buildCardData.status === 'card_completed') && (!dataService.user?.referral_code_used && !dataService?.buildCardData?.referral_code_used && dataService.user?.enable_referral_code) && (referralService.referralBuilderPointsForUser === null) && (dataService.buildCardData?.reward_point === null) && !referralService.totalAmountInWallet">
          <h4>Referral code</h4>
          <div class="referralCodeForm">
            <input type="text" placeholder="Add your referral code" [(ngModel)]="referralCode">
            <button type="button" (click)="applyReferralCode()">Apply</button>
          </div>
          <div class="codeNote">{{referralService.referralCodeError}}</div>
        </div>

        <div class="builderPointsBox"
          *ngIf="(dataService.buildCardData.status === 'card_completed') && ((referralService.referralBuilderPointsForUser !== null) || (dataService.buildCardData?.reward_point !== null)) && ((!dataService?.buildCardData?.use_point_click && !referralService.totalAmountInWallet) || (dataService?.buildCardData?.use_point_click && referralService.totalAmountInWallet))">
          <div class="leftSide">
            <h4>Builder Points</h4>
            <p *ngIf="(getConditionForBuilderPointsText() !== 0)">Referral (using
              {{referralService?.referralBuilderPointsForUser || (referralService?.referralBuilderPointsForUser === 0) ?
              dataService.getLocaledPriceInt(referralService?.referralBuilderPointsForUser) :
              dataService.getLocaledPriceInt(dataService.buildCardData?.reward_point)}}pts)</p>
            <p *ngIf="(getConditionForBuilderPointsText() === 0)">Referral ({{referralService?.totalAmountInWallet ?
              dataService.getLocaledPriceInt(referralService.totalAmountInWallet) :
              dataService.getLocaledPriceInt(dataService.buildCardData?.awarded_reward_points)}}pts saved in wallet)</p>
          </div>
          <div class="rightSide">
            <h4>{{getConditionForBuilderPointsText() !== 0 ? '-' :
              ''}}{{dataService.user?.currency?.symbol}}{{referralService?.creditedBuilderAmountEquivalentToUsedReferralPoints
              || (referralService?.creditedBuilderAmountEquivalentToUsedReferralPoints === 0) ?
              dataService.getLocaledPrice(referralService?.creditedBuilderAmountEquivalentToUsedReferralPoints) :
              dataService.getLocaledPrice(dataService.buildCardData?.total_amount, dataService.buildCardData.currency)}}</h4>
            <button type="button" class="editButton" (click)="editReferralBuilderPoints()"><em
                class="icon-iconedit"></em></button>
          </div>
        </div>

        <div class="builderPointsBox"
          *ngIf="(dataService.buildCardData.status === 'card_completed') && (!dataService?.buildCardData?.use_point_click && (referralService.totalAmountInWallet > 0))">
          <div class="leftSide">
            <h4>Builder Points</h4>
            <p>You currently have {{dataService.getLocaledPriceInt(referralService.totalAmountInWallet)}} pts</p>
          </div>
          <div class="rightSide">
            <button type="button" class="usepointButton" (click)="usePointsCTAClicked()">Use points</button>
          </div>
        </div>
      </div>
      <div class="applyPromo" *ngIf="(dataService.currentPage !== 'billingDetails')"
           [ngClass]="{ 'errorShow': phaseService.promoError,
                        'custom-disabled': disablePromoAndOnePlusForBNPL() }">
        <!--<img src="./../../../../../assets/images/promotion_icon.png" alt="" *ngIf="phaseService.isEditableAndPayable()">-->
        <div class="buttonContainer">
          <label class="title">Promo Code</label>
          <button class="btnApplyPromo" [ngClass]="{'custom-disabled': dataService.isModificationPending}"
            [disabled]='dataService.isModificationPending'
            *ngIf="!phaseService.isDiscountApplied() && (dataService.buildCardData?.promotion?.type !== 'SpecialEventPromotion') && phaseService.isEditableAndPayable()" type="button"
            (click)="ApplyPromotion(dataService.currentPage)">Apply Promotion
          </button>
        </div>
        <form
          *ngIf="(phaseService.promotionValid &&  !phaseService.promotionError && phaseService.isDiscountApplied() || (dataService.buildCardData?.promotion?.type === 'SpecialEventPromotion'))">
          <div class="applyPromoInner">
            <h4>{{ phaseService.finalPromoApplied?.toUpperCase() }} Applied <em class="icon-right"></em></h4>
            <div class="offerCancel" [ngClass]="{'custom-disabled': dataService.isModificationPending}"
              [attr.disabled]='dataService.isModificationPending'
              (click)="phaseService.applyRemovePromotion(dataService.buildCardData?.promotion?.code,false,isPaymentType())"
              *ngIf="phaseService.isEditableAndPayable()">
              <button type="button" class="removePromo">Remove</button>
            </div>
          </div>
        </form>
      </div>

      <!---->
      <div *ngIf="!isPaymentType()">
        <div class="discount-block"
          *ngIf="(phaseService.promotionValid &&  !phaseService.promotionError && phaseService.isDiscountApplied())">
          <div class="list">
            <label> Discount </label>
            <p> - {{getCurrencySymbol()}}{{getLocaledPrice(phaseService.discountPrice() | number: '1.0-0')}}</p>
          </div>
          <div class="list bold">
            <label> Final Cost </label>
            <p><strong>{{getCurrencySymbol()}}
                {{ getLocaledPrice(getDiscountedprice(getBuildCardData()) | number : '1.2-2') }}
              </strong></p>
          </div>
        </div>
      </div>

      <div class="additionalService" *ngIf="showAdditionalServices() && (dataService.currentPage !== 'billingDetails')"
        [ngClass]="{showthis : additionalServiceMobileview}">
        <h4>Additional Services</h4>
        <div class="builderCare flexWrap" *ngIf="dataService.buildCardData.subscription_flow && phaseService.getBuilderCareShowHide()">
          <div class="builderSelect">
            <label class="builderCareCheck" [ngClass]="{'custom-disabled': dataService.isModificationPending || disablePromoAndOnePlusForBNPL()}"
                   [attr.disabled]='dataService.isModificationPending || disablePromoAndOnePlusForBNPL()'>
              {{ (dataService.checkIfEnterpriseUserOrCard() || phaseService.checkEnterpriseForPaidCards()) ? 'Builder Enterprise One+' : 'Builder Studio One+' }}
              <input type="checkbox" name="checkbox" class="check" (change)="phaseService.toggleCare(true)"
                [checked]="phaseService.isSupportAndMaintenanceForOldCards()"
                *ngIf="phaseService.isEditableAndPayable()" />
              <span class="checkmark"></span>
            </label>
            <div class="info" (click)="hideShowSupportAndMaintenance(true, true)">
              <img src="../../../../../assets/images/info_blue.png" alt="">
              <div class="infoBox" *ngIf="fromAddServices && showSupportAndMaintenance">
                <app-builder-care-content *ngIf="((checkCareType() === 'standard_care_plus') || (checkCareType() === 'standard_care'))"
                                          [careType]="'standardCarePlus'" [isFromDeliveryPage]="false" [viewMoreSCare]="false">
                </app-builder-care-content>
                <app-builder-care-content *ngIf="((checkCareType() === 'enterprise_care_plus') || (checkCareType() === 'enterprise_care'))"
                                          [careType]="'enterpriseCarePlus'" [isFromDeliveryPage]="false" [viewMoreSCare]="false">
                </app-builder-care-content>
                <em class="icon-cancel closeInfo close-btn"
                    (click)="hideShowSupportAndMaintenance(false);$event.stopPropagation();"></em>
              </div>
            </div>
          </div>
          <p>
            <span
              *ngIf="dataService.modifiedBuildCardData?.support_maintenance_price.new; else supportDataFromMainBuildCard">
              {{getCurrencySymbol()}}{{getLocaledPrice(getModifiedBuilderCarePrice() | number : '1.2-2')}}
            </span>
            <ng-template #supportDataFromMainBuildCard>
              {{getCurrencySymbol()}}{{!isPaymentType() ? getLocaledPrice(getBuildCardData().support_maintenance_price |
              number :
              '1.2-2') : getLocaledPrice(getInstallmentWithTax(getSupportMaintenance())
              | number : '1.2-2')}}
            </ng-template>/month
          </p>
        </div>

        <div class="builderCare flexWrap" *ngIf="checkIfBuilderCloudValue() && checkIfShowCloud()">
          <div class="builderSelect">
            <label class="builderCareCheck" [ngClass]="{'custom-disabled': (dataService.isModificationPending || disableCloudForBNPL())}"
              [attr.disabled]='(dataService.isModificationPending || disableCloudForBNPL())'>Builder Cloud
              <input type="checkbox" name="checkbox" class="check" (change)="phaseService.toggleBuilderCloud()"
                [checked]="phaseService.isCloudOpted()" *ngIf="phaseService.isEditableAndPayable()" />
              <span class="checkmark"></span>
            </label>
            <div class="info" (click)="hideShowBuilderCloud(true)">
              <img src="../../../../../assets/images/info_blue.png" alt="" />
              <div class="infoBox" *ngIf="showCloudDescription">
                <ul>
                  <li><strong>Commitment-free savings:</strong> our customers saved over $4.5m, last year.</li>
                  <li><strong>World-class analytics:</strong> Optimise your software and infrastructure.</li>
                  <li><strong>Best-in-class multi-cloud:</strong> Azure, AWS, and more. Just one bill (for a lot less).
                  </li>
                </ul>
                <em class="icon-cancel closeInfo close-btn"
                  (click)="hideShowBuilderCloud(false);$event.stopPropagation();"></em>
              </div>
            </div>
          </div>
          <p>
            <span
              *ngIf="(dataService.modifiedBuildCardData?.cloud_price?.new || dataService.modifiedBuildCardData?.minimum_cloud_price?.new); else BuilderCareDataFromMainBuildCard">
              <ng-container
                *ngIf="dataService.modifiedBuildCardData?.cloud_price?.new && !dataService.modifiedBuildCardData?.minimum_cloud_price?.new">
                {{getCurrencySymbol()}}{{getLocaledPrice(dataService.modifiedBuildCardData?.cloud_price.new | number :
                '1.2-2')}}/month
              </ng-container>
              <ng-container *ngIf="dataService.modifiedBuildCardData?.minimum_cloud_price?.new">
                {{getCurrencySymbol()}}{{getLocaledPrice(dataService.modifiedBuildCardData?.minimum_cloud_price.new |
                number :
                '1.2-2')}} -
                {{getCurrencySymbol()}}{{getLocaledPrice(dataService.modifiedBuildCardData?.maximum_cloud_price.new |
                number :
                '1.2-2')}}/month
              </ng-container>
            </span>
            <ng-template #BuilderCareDataFromMainBuildCard>
              {{getCurrencySymbol()}}{{ getBuilderCloud() }}/month
            </ng-template>
          </p>
        </div>

        <div class="builderCare donationAmount"
          *ngIf="(dataService.currentPage === 'summary') && donationAmount() && dataService.buildCardData.show_donation && !phaseService.checkIfBuildCardForRapidPrototype()">
          <div class="builderSelect">
            <label class="builderCareCheck" [ngClass]="{'custom-disabled': dataService.isModificationPending}"
              [attr.disabled]='dataService.isModificationPending'>Add {{ dataService.buildCardData.donation_percentage
              }}% to support <br>Ukraine refugees
              <input type="checkbox" name="checkbox" class="check" (change)="toggleDonationAmount()"
                [checked]="isDonationOpted()" />
              <span class="checkmark"></span>
            </label>
            <div class="info" (click)="toggleDonationDescription(true)">
              <img src="../../../../../assets/images/info_blue.png" alt="" />
              <div class="infoBox" *ngIf="showDonationDescription">
                <ul>
                  <li><strong>Like so many around the world, we're concerned and taken back by the situation in
                      Ukraine. If you'd like to donate, 100% of your donation will help the Red Cross Committee &
                      other potential charities provide food, shelter and evacuate people from the fighting. Thank
                      you</strong> </li>
                </ul>
                <em class="icon-cancel closeInfo close-btn"
                  (click)="toggleDonationDescription(false);$event.stopPropagation();"></em>
              </div>
            </div>
          </div>
          <p>{{getCurrencySymbol()}}{{getLocaledPrice(donationAmount() | number : '1.2-2')}}</p>
        </div>

        <!-- <div class="donationSider"
          *ngIf="(dataService.currentPage === 'summary') && getBuildCardData().donation_opted && dataService.buildCardData.show_donation">
          <ngx-slider
            [(value)]="dataService.buildCardData.donation_percentage ? dataService.buildCardData.donation_percentage : barValue"
            [options]="options" (valueChange)="getCloudPrice($event)">
          </ngx-slider>
        </div> -->
      </div>
    </div>

    <div class="securityDeposit"
      *ngIf="(dataService.currentPage === 'paymentPlan') && dataService.modifiedBuildCardData">
      <h3 *ngIf="!checkIfUpfront()">Security Deposit</h3>
      <h3 *ngIf="checkIfUpfront()">Payment Status</h3>
      <p><strong>Already paid</strong>
        <strong>{{getCurrencySymbol()}}{{getLocaledPrice(getOldSecurityAmount() | number : '1.2-2') }}</strong>
      </p>
      <p>
        <span *ngIf="!checkIfUpfront()">New security deposit</span>
        <span *ngIf="checkIfUpfront()">New amount</span>
        <span>{{getCurrencySymbol()}}{{getLocaledPrice(getNewSecurityAmount() | number : '1.2-2') }}
        </span>
      </p>
      <p>
        <span>Differential Amount</span>
        <span>{{getCurrencySymbol()}}{{getLocaledPrice(getDiffSecurityAmount() | number : '1.2-2') }}
        </span>
      </p>
    </div>

    <div class="notifyColumn" *ngIf="(dataService.currentPage === 'paymentPlan') && dataService.modifiedBuildCardData">
      <p><strong>Note:</strong>
        The differential amount ({{getCurrencySymbol()}}{{getLocaledPrice(getDiffSecurityAmount()
        | number : '1.2-2') }}) will be adjusted in your first installment.
      </p>
    </div>

    <div class="paymentSummary dueNow" [ngClass]="{active: dueNowMobileview}"
      *ngIf="showDueNowSection()">
      <div class="mobilehandling">
        <div class="mobileClick" (click)="dueNowBoxMobile()"></div>
        <div class="closeButton" (click)="dueNowBoxMobile()"></div>
        <h3 class="xs-small-head" *ngIf="!commonService.startTwoPayFlow">Due Now
          <span class="info" (click)="hideShowDueNowInfo(true)">
            <img src="../../../../../assets/images/info_blue.png" alt="" />
            <div class="infoBox" *ngIf="showDueNowInfo">
              <ul>
                <li>This is the amount of money you will pay at the end of checkout to begin your Buildcard<sup
                    class="tm light">®</sup>.
                  Later, you will be able to choose the date when your Buildcard<sup class="tm light">®</sup> begins
                  development.</li>
              </ul>
              <em class="icon-cancel closeInfo close-btn"
                (click)="hideShowDueNowInfo(false);$event.stopPropagation();"></em>
            </div>
          </span>
        </h3>
        <div class="downPayment" *ngIf="(!isFrequencyUpfront() ||
          (phaseService.checkIfBuildCardForRapidPrototype() && !paymentBuilderService.checkIfOnlyProfessionalPrototype())) && !commonService.startTwoPayFlow">
          <div class="detailRow">
            <label class="labelSecurityDeposit">
              <ng-container *ngIf="appDataService?.urlParameters?.isCustomPrototype || paymentBuilderService.checkIfOnlyProfessionalPrototype(); else textfordeposit">
                Amount
              </ng-container>
              <ng-template #textfordeposit>
                Security Deposit
                <span class="info" (click)="hideShowSecurityDepositInfo(true)">
                  <img src="../../../../../assets/images/info_blue.png" alt="" />
                  <div class="infoBox" *ngIf="showSecurityDepositInfo">
                    <ul>
                      <li>This is paid up front and reserves your development team to ensure they are ready to start
                        on
                        time.</li>
                      <li>This cost will be refunded at the end of the development
                        process (we will deduct the cost from your final payment).</li>
                    </ul>
                    <em class="icon-cancel closeInfo close-btn"
                      (click)="hideShowSecurityDepositInfo(false);$event.stopPropagation();"></em>
                  </div>
                </span>
              </ng-template>
            </label>
            <p *ngIf="!phaseService.isRentalBuildCard()">
              <ng-container
                *ngIf="phaseService.promotionValid &&  !phaseService.promotionError && phaseService.isDiscountApplied() && getBuildCardData().promotion.type === 'GlobalPromotion'; else depositamount">
                {{getCurrencySymbol()}}{{getLocaledPrice((getBuildCardData().promotion.deposit + getTaxAmountIfIncluded()) | number : '1.2-2')}}
              </ng-container>
              <ng-template #depositamount>
                {{getCurrencySymbol()}}{{getLocaledPrice(getDepositIncludingTax() | number : '1.2-2')}}
              </ng-template>
            </p>
            <p *ngIf="phaseService.isRentalBuildCard() && dataService.currentPage === 'billingDetails'">
              <ng-container>
                {{getCurrencySymbol()}}{{getLocaledPrice((phaseService.paymentData?.pending_installment?.installment_amount + getTaxAmountIfIncluded())
                | number : '1.2-2')}}
              </ng-container>
            </p>
          </div>
          <div *ngIf="((getDepositTaxAmount() > 0) && (dataService.buildCardData?.currency?.tax > 0) && (dataService.buildCardData?.currency?.code !== 'AED'))"
            class="detailRow">
            <label>Tax ({{dataService.buildCardData?.currency?.tax}}%
              {{dataService.buildCardData?.currency?.tax_type}})
            </label>
            <p>{{getCurrencySymbol()}}{{getLocaledPrice(getDepositTaxAmount() | number : '1.2-2')}}</p>
          </div>
          <div *ngIf="donationAmount() && isDonationOpted()" class="detailRow">
            <label class="labelDonationFund">Donation Fund Amount
              <div class="info" (click)="toggleDonationAmountDescription(true)">
                <img src="../../../../../assets/images/info_blue.png" alt="" />
                <div class="infoBox" *ngIf="showDonationFundDescription">
                  <ul>
                    <li><strong>Like so many around the world, we’re concerned and taken back by the situation in
                        Ukraine. If you’d like to donate, 100% of your donation will help the Red Cross Committee &
                        other potential charities provide food, shelter and evacuate people from the fighting. Thank
                        you</strong> </li>
                  </ul>
                  <em class="icon-cancel closeInfo close-btn"
                    (click)="toggleDonationAmountDescription(false);$event.stopPropagation();"></em>
                </div>
              </div>
            </label>
            <p>{{getCurrencySymbol()}}{{getLocaledPrice(donationAmount() | number : '1.2-2')}}</p>
          </div>
        </div>

        <div class="totalContinue">
          <div class="detailRow totalPrice" *ngIf="!commonService.startTwoPayFlow && !(paymentBuilderService.startCapchaseFlow && (dataService.user.currency.id === 4))">
            <h3>Total</h3>
            <h3 *ngIf="!phaseService.isRentalBuildCard()">
              <ng-container
                *ngIf="phaseService.promotionValid &&  !phaseService.promotionError && phaseService.isDiscountApplied() && getBuildCardData().promotion.type === 'GlobalPromotion'; else depositamounttotal">
                {{getCurrencySymbol()}}{{getLocaledPrice(getPromotiondepositwithtax() | number : '1.2-2')}}
              </ng-container>
              <ng-template #depositamounttotal>
                {{getCurrencySymbol()}}{{getLocaledPrice(getDueNowAmount() | number : '1.2-2')}}
              </ng-template>
            </h3>
            <h3 *ngIf="phaseService.isRentalBuildCard() && dataService.currentPage === 'billingDetails'">
              <ng-container>
                {{getCurrencySymbol()}}{{getLocaledPrice(phaseService.paymentData?.pending_installment?.installment_amount
                | number : '1.2-2')}}
              </ng-container>
            </h3>
          </div>

          <div class="tncRow tncSpacing" *ngIf="checkIfLoadSpecingPayment()">
            <input type="checkbox" id="tncCheck" [checked]="phaseService.contractTermsConditionsChecked"
              (change)="termsAndConditionsClicked($event)" [disabled]="!paymentBuilderService.isMSALinkCLicked">
            <label for="tncCheck" (mouseover)="toggleMSAToolTip()" (mouseleave)="toggleMSAToolTip()">
              <span *ngIf="paymentBuilderService.showMSATooltip && !paymentBuilderService.isMSALinkCLicked"
                class="tooltipText">Please open and review Terms and Conditions before checking this box.</span>
            </label>
            <div class="tncText">{{ getTnCText() }}
              <span (click)="openMSALink()">{{ getMSALinkText() }}</span>
            </div>
          </div>
          <!-- *ngIf='!dataService.isEditBuildCardAfterSecurityPaid() && !phaseService.isInviteEdited && isUserInviteeOfBuildCard()' -->

          <!--<div class="detailRow nextbutton">
                <button type="button" *ngIf="(dataService.currentPage === 'billingDetails')"
                  [ngClass]="{ 'disable-continue-button': checkToDisable }"
                  class="continueBtn" (click)="continueToPayment()" (mouseover)="toggleToolTip(true)"
                  (mouseleave)="toggleToolTip(false)">Continue
                  <span *ngIf="checkToDisable && (dataService.currentPage === 'billingDetails')"
                    class="tooltip-bottom-arrow">Please fill required billing information.</span>
                  <span class="tooltip-bottom-arrow" *ngIf="isUserInviteeOfBuildCard()">
                    You can only make payments if you own this Buildcard
                  </span>
                </button>
              </div>-->

          <div class="detailRow nextbutton" *ngIf="checkIfLoadSpecingPayment()">
            <specing-payment class="specingCompo"
              [billingInfo]="getBillingInfo()" [buildCardInstallment]="true"
              [paymentAmount]="getSecurityDepositWithTax()" [paymentAmountWithoutTax]="getSecurityDepositWithoutTax()"
              [taxAmount]="getSecurityDepositTaxAmount()"
              [paymentId]="appDataService?.urlParameters?.isCustomPrototype ? getPaymentData()?.custom_prototype?.id : getPaymentData()?.pending_installment?.id"
              [isFromPayConfirm]="true">
            </specing-payment>
          </div>
          <div class="tax-disclamer" *ngIf="((dataService.currentPage === 'paymentPlan') && (dataService.buildCardData.currency?.tax > 0))">
            <span>*</span>This tax amount is an estimate. We calculate the final amount of tax you need to pay, once we create your invoice.
          </div>
        </div>
      </div>
    </div>

    <div class="approveContinueButton" *ngIf="isShowApproveBotton()">
      <div class="clickExpand visible-xs" (click)="editcardMobileClick()">
        <h5>{{getCurrencySymbol()}}{{getLocaledPrice(getTotalUpdatedPriceWithTax() | number : '1.2-2')}} <img
            src="../../../../../assets/images/info_blue.png" alt="" /></h5>
        <div>Duration: ({{getWeeks()}} {{getWeeks() > 1 ? 'weeks' : 'week'}})</div>
      </div>
      <div class="bottomBtnContainer">
        <div class="buttonWrap">
          <button class="btnApproveContinue" type="button" (click)="showApproveEditBuildCardAfterSecurityPaid()">Approve
            <span class="hidden-xs">&
              continue</span>
          </button>
          <button class="brand-btn greenBG btnSaveDraft"
            *ngIf="!phaseService.isPlatformRequest && !dataService.isEditBuildCardAfterSecurityPaid() && (dataService.user.roles !== 'CPE' ) && this.dataService?.activeChangeRequesObj?.status !== 'pending'"
            (click)="redirectToDashboardForAdditionalFeatures('draft')">Save as draft</button>
        </div>
      </div>
      <!-- <p>Our team at Builder.ai will review all the additional requirement and get back to you within 2 working days!</p> -->
      <p>Your squad will review all the additional requirements and get back to you within 2 working days.</p>
    </div>
  </div>

  <!-- Rental BuildCard paymentSection -->
  <div class="rental-block" *ngIf="phaseService.isRentalBuildCard() && dataService.currentPage !== 'billingDetails'">
    <div class="pay-method" *ngIf="!dataService.checkIfCPEUser() && !phaseService.isStoreFeatureRequest">
      <h2 class="heading" *ngIf="!(phaseService.isEditFeatureRequest() && !appDataService?.urlParameters.cpe_user)">
        How
        would you like to pay? </h2>
      <div class="pay-list monthly" [ngClass]="{'active' : isMonthlySelected()}"
        *ngIf="!(phaseService.isEditFeatureRequest() && !appDataService?.urlParameters.cpe_user)">
        <h4>Monthly</h4>
        <div class="custom-checkbox">
          <input type="checkbox" id="monthly" (change)="toggleMonthlySelection()" [checked]="isMonthlySelected()" />
          <label for="monthly"></label>
        </div>
      </div>
      <div class="pay-list upfront" [ngClass]="{'active' : isUpfrontSelected()}"
        *ngIf="!dataService?.buildCardData?.kick_off_date || !(phaseService.isEditFeatureRequest() && !appDataService?.urlParameters.cpe_user)">
        <h4>Upfront</h4>
        <div class="custom-checkbox">
          <input type="checkbox" id="payupfront" (change)="toggleUpfrontSelection()" [checked]="isUpfrontSelected()" />
          <label for="payupfront"></label>
        </div>
      </div>
    </div>

    <div class="payment-summery">
      <h2 class="heading">Payment Summary</h2>
      <div class="studio-package-bx">
        <div class="main-section"
          *ngIf="!(phaseService.isEditFeatureRequest() && !appDataService?.urlParameters.cpe_user)">
          <h3 class="head">Studio Store Package <span> (Apps + Care + Cloud) </span></h3>
          <p class="sub-head"
            *ngIf="dataService.buildCardData.promotion && dataService.buildCardData.promotion?.type === 'MonthDiscountPromotion'">
            Promo: first 3 months free
            <ng-container *ngIf="!dataService.buildCardData.upfront">, then</ng-container>
          </p>
          <p class="sub-head alignleft">
            {{getCurrencySymbol()}}
            <ng-container *ngIf="!dataService.buildCardData.upfront">
              {{dataService.getLocaledPrice(dataService.buildCardData?.rental_info?.studio_store_deposit, dataService.buildCardData.currency)}}/month
              for <ng-container>{{ dataService.buildCardData?.rental_info?.total_installments }}
                {{dataService.buildCardData?.rental_info?.total_installments > 1 ? 'months' : 'month'}}
                <span class="strickMonth"
                  *ngIf="dataService.buildCardData?.rental_info?.total_installments!== dataService.buildCardData?.rental_info?.length_of_contract">
                  {{dataService.buildCardData?.rental_info?.length_of_contract}}
                  {{dataService.buildCardData?.rental_info?.length_of_contract > 1 ? 'months' : 'month'}}
                </span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="dataService.buildCardData.upfront">
              {{ getDiscountedprice(dataService.buildCardData) }}
            </ng-container>
          </p>
        </div>
        <div class="main-section"
          [ngClass]="{'topbdr': !(phaseService.isEditFeatureRequest() && !appDataService?.urlParameters.cpe_user) }"
          *ngIf="getAdditionalFeatureCount() > 0">
          <h3 class="head"> Additional Features ({{getAdditionalFeatureCount()}})
          </h3>
          <div *ngIf="phaseService.isEditFeatureRequest() && !appDataService?.urlParameters.cpe_user">
            <!-- {{getCurrencySymbol()}} {{dataService.getLocaledPrice((getAdditionalFeaturePerMonthCost() | number:
            '1.0-0'), dataService.buildCardData.currency)}}/month
            for <ng-container>{{getAdditionalMonth()}} {{getAdditionalMonth() > 1 ? 'months' : 'month'}}
            </ng-container> -->
            <div class="detailRow">
              <!-- <span>
                <label class="title">Total Cost <br>
                  <small *ngIf="((dataService.currentPage === 'paymentPlan') && (dataService.buildCardData?.currency?.tax > 0))">(Including Taxes)</small>
                </label>
              </span>
              <span>
                {{getCurrencySymbol()}} {{dataService.getLocaledPrice(getTotalPrice(getInstallments()) | number : '1.2-2')}}
              </span> -->
              <label class="title">Total Cost <br>
                <small *ngIf="((dataService.currentPage === 'paymentPlan') && (dataService.buildCardData?.currency?.tax > 0))">(Including Taxes<span>*</span>)</small>
              </label>
              <p>
                <strong>
                  {{getCurrencySymbol()}} {{dataService.getLocaledPrice(getTotalPrice(getInstallments()) | number : '1.2-2')}}
                </strong>
              </p>
            </div>


            <div class="detailRow">
              <label>Indicative Development Duration </label>
              <p>
                {{ ((getFinalWeeks() < 1) && (getFinalWeeks() !== 0)) ? 1 : (getFinalWeeks() | number: '1.0-0')}}{{ (getFinalWeeks() | number: "1.0-0") > 1 ? " weeks" : " week" }}
              </p>
              <!-- <p *ngIf="dataService.showMonthly; else showWeekly">
                <strong *ngIf="dataService.additionalFeatures.length">
                  {{getDurationMonthsForAdditional(dataService.totalAdditionalFeaturesWeek)}} {{
                  (getDurationMonthsForAdditional(dataService.totalAdditionalFeaturesWeek) > 1) ? 'Months' : 'Month' }}
                </strong>
                <strong *ngIf="!dataService.additionalFeatures.length">0 Months</strong>
              </p>
              <ng-template #showWeekly>
                <p>
                  <strong *ngIf="dataService.additionalFeatures.length"> {{dataService.totalAdditionalFeaturesWeek}} Weeks
                  </strong>
                  <strong *ngIf="!dataService.additionalFeatures.length"> 0 Weeks </strong>
                </p>
              </ng-template> -->
            </div>

          </div>
          <p class="sub-head" *ngIf="!(phaseService.isEditFeatureRequest() && !appDataService?.urlParameters.cpe_user)">
            <span>Total Cost</span>
            <span>{{getCurrencySymbol()}}
              {{dataService.getLocaledPrice((getBuildCardData().rental_info?.additonal_price
              | number:'1.0-0'), dataService.buildCardData.currency)}}
            </span>
          </p>
          <p class="sub-head" *ngIf="!(phaseService.isEditFeatureRequest() && !appDataService?.urlParameters.cpe_user)">
            <span>No of Installments
              <div class="infoTooltip">
                <span (click)="additionalFeatureTootipClicked()"><img src="../../../../../assets/images/info_blue.png"
                    alt="" /></span>
                <div class="infoBox" *ngIf="additionalFeatureTooltip">
                  <div class="close-btn" (click)="additionalFeatureTootipClicked()"><em class="icon-cancel"></em>
                  </div>
                  <p>Additional features are paid every 4 weeks, based on their weekly cost until development
                    completion.</p>
                </div>
              </div>
            </span> <span>{{dataService.buildCardData.rental_info?.development_months}}</span>
          </p>

          <div class="builderCare sub-head" *ngIf="!phaseService.isStoreFeatureRequest">
            <div class="builderSelect">
              <label class="builderCareCheck">
                <ng-container *ngIf="checkCareType() === 'standard_care'">Builder Care</ng-container>
                <ng-container *ngIf="checkCareType() === 'standard_care_plus'">Builder Care Plus</ng-container>
                <ng-container *ngIf="checkCareType() === 'enterprise_care'">Builder Enterprise Care</ng-container>
                <ng-container *ngIf="checkCareType() === 'enterprise_care_plus'">Builder Enterprise Care Plus
                </ng-container>
                <input type="checkbox" name="checkbox" class="check" [checked]="phaseService.isSupportAndMaintenance()"
                  disabled />
                <span class="checkmark"></span>
              </label>
              <div class="info">
                <span (click)="builderCareTootipClicked()"><img src="../../../../../assets/images/info_blue.png"
                    alt="" /></span>
                <div class="infoBox" *ngIf="builderCareTooltip">
                  <div class="close-btn" (click)="builderCareTootipClicked()"><em class="icon-cancel"></em></div>

                  <div class="careText">
                    <h4>How {{ showCareType(phaseService.selectedHash.care_type) }} protects your software</h4>

                    <ul *ngIf="phaseService.selectedHash.care_type === 'standard_care'">
                      <li>24*7 uptime monitoring.</li>
                      <li>Software application bug fixes (including permanent fixes for recurring issues).</li>
                      <li>Platform updates for latest iOS / Android versions.</li>
                      <li>Proactive technical support for third-party API / SDK updates on all standard features.**
                      </li>
                      <li>Security patches & Backup Management.*</li>
                      <li>Updated tech-stack</li>
                      <li>Industry Standard SLA</li>
                      <li>Multi-Channel Support - Online, Chat, Email with average 24 hour response time</li>
                    </ul>
                    <ul *ngIf="phaseService.selectedHash.care_type === 'standard_care_plus'">
                      <li>24*7 uptime monitoring. Includes proactive load planning and monitoring.</li>
                      <li>Software application bug fixes (including root cause analysis and permanent fixes for
                        recurring issues).</li>
                      <li>Platform updates for latest iOS / Android versions.</li>
                      <li>Proactive technical support for third-party API / SDK updates on all standard features and
                        custom features.**</li>
                      <li>Security patches & Backup Management.*</li>
                      <li>Updated tech-stack</li>
                      <li>Industry Standard SLA with 99.95% Uptime Commitment</li>
                      <li>Infrastructure performance and Application Performance Monitoring (APM)<strong
                          class="carot">&#94;</strong></li>
                      <li>Multi-Channel Support - Online, Chat, Email with average 24 hour response time and 4 hour
                        response commitment for severe issues.</li>
                      <li>Enhancements to current*** features (30% of the app features can be enhanced, limited to,
                        7.5%
                        per quarter)</li>
                    </ul>
                    <ul *ngIf="phaseService.selectedHash.care_type === 'enterprise_care'">
                      <li>24*7 uptime and performance monitoring. Includes proactive load planning and monitoring.
                      </li>
                      <li>Software application bug fixes (including root cause analysis and permanent fixes for
                        recurring issues).</li>
                      <li>Platform updates for latest iOS / Android versions.</li>
                      <li>Proactive technical support for third-party API / SDK updates for all standard and custom
                        features.**</li>
                      <li>Security patches & Backup Management*</li>
                      <li>Updated tech-stack</li>
                      <li>Enterprise SLA with 99.999% Uptime Guarantee</li>
                      <li>Enhancements to current*** features (30% of the app features can be enhanced, limited to
                        7.5%
                        per quarter)</li>
                      <li>Infrastructure performance and Application Performance Monitoring (APM)<strong
                          class="carot">&#94;</strong></li>
                      <li>Multi-Channel Support - Online, Chat, Email with average 24 hour response time and 4 hour
                        response commitment for severe issues.</li>
                    </ul>
                    <ul *ngIf="phaseService.selectedHash.care_type === 'enterprise_care_plus'">
                      <li>24*7 uptime and performance monitoring. Includes proactive load planning and monitoring.
                      </li>
                      <li>Software application bug fixes (including root cause analysis and permanent fixes for
                        recurring issues).</li>
                      <li>Platform updates for latest iOS / Android versions.</li>
                      <li>Proactive technical support for third-party API / SDK updates for all standard and custom
                        features.**</li>
                      <li>Security patches & Backup Management*</li>
                      <li>Updated tech-stack</li>
                      <li>Enterprise SLA with 99.999% Uptime Guarantee</li>
                      <li>Enhancements to current*** features (50% of the app features can be enhanced, limited to
                        12.5%
                        per quarter)</li>
                      <li>Infrastructure performance and Application Performance Monitoring (APM)<strong
                          class="carot">&#94;</strong></li>
                      <li>Multi-Channel Online, Chat, Email, with average 24 hour response time and 2 hour response
                        commitment for severe issues.</li>
                    </ul>

                    <ul class="bottomDescription">
                      <li>*If part of your original software.</li>
                      <li>**Excluding any third-party, SDK, security, feature, custom integration or browser addition
                        -
                        that wasn't part of your Buildcard.</li>
                      <li>***Features covered under your Buildcard.</li>
                      <li><strong class="carot">&#94;</strong> If hosted with Builder Cloud</li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
            <p *ngIf="!phaseService.isEditFeatureRequest()">
              {{getCurrencySymbol()}}
              {{!isPaymentType() ? getLocaledPrice(getBuildCardData().support_yearly_price | number : '1.2-2')
              : getLocaledPrice(getInstallmentWithTax(getSupportMaintenance()) | number : '1.2-2')}}/month
            </p>
            <p *ngIf="phaseService.isEditFeatureRequest() && dataService.buildCardData.new_care_flow">
              {{getCurrencySymbol()}} {{getLocaledPrice(dataService.totalAdditionalsupportMaintenance * 12 | number :
              '1.2-2')}}/year
            </p>
            <p *ngIf="phaseService.isEditFeatureRequest() && !dataService.buildCardData.new_care_flow">
              {{getCurrencySymbol()}} {{getLocaledPrice(dataService.totalAdditionalsupportMaintenance | number :
              '1.2-2')}}/month
            </p>
          </div>
        </div>

      </div>
    </div>

    <div class="pay-method" *ngIf="!dataService.checkIfCPEUser()">
      <div class="doc-upload"
        [ngClass]="{'ErrText': (this.phaseService.updatedInstamentsClicked && phaseService.DocNotUploaded)}"
        *ngIf="this.dataService.additionalFeatures.length && this.phaseService.showDocUploadArea && !appDataService?.urlParameters.cpe_user">
        <h3>You must provide evidence of this change request</h3>
        <div class="inputWrap">
          <label [ngClass]="{'uploaded-doc-Name': !phaseService.DocNotUploaded}" (click)="evidence.click()">
            <img *ngIf="!phaseService.DocNotUploaded" class="upload-success"
              src="../../../../../assets/images/tick.svg" alt="" />
            {{this.phaseService.DocUploadedName}}
          </label>
          <input style="display: none;" type="file" (change)="onFileSelected($event)" #evidence />
          <button *ngIf="phaseService.DocNotUploaded" [disabled]="(this.phaseService.SelectedDoc == null)"
            (click)="onUpload()">Upload</button>
          <button *ngIf="!phaseService.DocNotUploaded" (click)="evidence.click()">Edit</button>
        </div>
        <p class="validationError" *ngIf="!this.fileSizeValid">Document size can be max of 2mb</p>
      </div>
      <div class="bottomBtnContainer paymentPlanSS">
        <div class="buttonWrap">
          <button class="brand-btn greenBG btnAddMoreFeatures"
            *ngIf="!(this.dataService.currentPage == 'paymentPlan') && phaseService.isEditFeatureRequest()"
            (click)="startEditing('features', 'Buildcard Features Change',true)">Add more features/roles</button>
          <button type="button" class="brand-btn greenBG"
            [ngClass]="{'custom-disabled': !this.dataService.additionalFeatures.length}"
            *ngIf="phaseService.isEditFeatureRequest() && (dataService.currentPage ==='summary')"
            (click)="showUpdatedInstallments()"> View updated installments
          </button>
          <button type="button" class="brand-btn btnApproveContinue btnApproveCancel"
                  *ngIf="phaseService.isStoreFeatureRequest && (dataService.currentPage === 'paymentPlan')"
                  (click)="goBackTOBHome()"> Cancel
          </button>
          <button type="button" class="brand-btn btnApproveContinue"
            *ngIf="phaseService.isEditFeatureRequest() && (dataService.currentPage === 'paymentPlan')"
            (click)="onApproveAndContinue()"> Approve and Continue
          </button>
        </div>
      </div>

      <!-- <p class="addi-info"
        *ngIf="dataService.buildCardData.status ==='started' || dataService.buildCardData.kick_off_date"> Our team at
        Builder.ai will review all the additional requirement and get back to you within 2 working days! </p> -->
      <p class="addi-info"
        *ngIf="dataService.buildCardData.status ==='started' || dataService.buildCardData.kick_off_date"> Your squad
        will review all the additional requirements and get back to you within 2 working days. </p>
      <p class="ErrMsg"
        *ngIf="(this.phaseService.updatedInstamentsClicked && !this.phaseService.allQuestionsAnswered && this.dataService.additionalFeatures.length)">
        Please choose a reason for adding each feature</p>
    </div>
    <div *ngIf="phaseService.isEditableAndPayable() && !isUserInviteeOfBuildCard()" class="continue-button">
      <button type="button" class="start-pro-btn" (click)="payNowFromMaxBuildCard()"
        [disabled]="dataService.checkIfCPEUser()"> Go to payment overview </button>
    </div>
  </div>

</ng-template>

<rental-parent *ngIf="phaseService.showRentalPaymentFlow"></rental-parent>

<!----popup for received your request!---->
<div class="commonPopUp" [ngClass]="{'active': phaseService.isShowApproveChangeModalAfterSecurityPaid}"
  *ngIf="phaseService.isShowApproveChangeModalAfterSecurityPaid">
  <div class="popOverlay"></div>
  <div class="popHolder featureEditConfirm">
    <h2 class="subheader">We have received your request!</h2>
    <div class="midBlock">
      <p>Your account manager will review them. Post approval the changes would reflect in your Buildcard<sup
          class="tm">®</sup>.</p>
    </div>
    <div class="action-block">
      <div class="pull-right">
        <button type="button" class="active" (click)="goToDashboard()">Go to dashboard</button>
      </div>
    </div>

  </div>
</div>


<app-use-credited-referral-points *ngIf="referralService.showUseCreditedReferralPointsPopup">
</app-use-credited-referral-points>
