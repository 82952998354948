<div class="dashHeading" *ngIf="dataService.getCardCount('paid') > 0">Running {{ (dataService.getCardCount('paid') > 1) ? 'Cards' : 'Card' }}

  <div class="searchCards" *ngIf="!(showRecentRunning && dataService.getCardCount('paid') > 0)">
    <em class="icon-search"></em>
    <input type="text" placeholder="Search for Buildcard" [(ngModel)]="dataService.searchText" (keyup)="getBuildCardsWithSearch()">
    <div class="clearButton" *ngIf="dataService.searchText"><em class="icon-cancel" (click)="getBuildCardsWithSearch('true')"></em></div>
  </div>

  <button type="button" (click)="showCards(3)" *ngIf="showRecentRunning && dataService.getCardCount('paid') > 4">Show all
  </button>
</div>

<div class="dashProjectRow" [ngClass]="{'hideCard': showRecentRunning}" infiniteScroll [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="300" (scrolled)="onScroll(dataService.getCardCount('paid'))">
  <div class="progressCard"
    *ngFor="let card of (showRecentRunning ? (getPaidCards() | slice: 0 : (dataService.getCardCount('paid') > 4 ? 4: dataService.getCardCount('paid'))) : getPaidCards())">
    <div class="cardHead">
      <div class="cardLeft">
        <h3>{{card.project_name}}
          <div class="cardAnimationIcon" *ngIf="isBuildCardEditedAndHighlighted(card)">
            <div class="pulse"></div>
            <div class="dot"></div>
          </div>
        </h3>
        <h4>Last edited: {{card.updated_at}}</h4>
      </div>
      <!-- <div class="cardRight">
        <div class="shareIcon" (click)="phaseService.showSharePopup(card)"><em class="icon-share"></em>
        </div>
        <div class="inviteIcon" (click)="phaseService.showInvitePopup(card)"><em class="icon-invite"></em>
        </div>
      </div> -->
      <div class="cardRight">
        <div class="cardMore"><em class="icon-more"></em>
          <div class="cardDropDown">
            <ul>
              <li (click)="cloneBuildCard(card.uniq_code, card.currency)">Duplicate card</li>
              <li (click)="phaseService.showSharePopup(card)">Share with others</li>
              <li (click)="phaseService.showInvitePopup(card)">Invite others</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="pendingTag" *ngIf="card?.modification_request_status == 'pending'">Approval pending</div>
    </div>
    <div class="cardMiddle" *ngIf="isStatusPaymentReceived(card)">
      <div class="cardPayStatus recieved">
        <div class="payIcons"><em></em></div>
        <div class="headline">Payment received.</div>
        <p *ngIf="!card.scheduled_date">Schedule a Kick off call with our team.</p>
        <div *ngIf="card.scheduled_date">
          <p> Kick off scheduled for:</p>
          <p> Date- {{card.scheduled_date}}</p>
          <p *ngIf="card.scheduled_call_start"> Time- {{card.scheduled_call_start}}</p>
        </div>

        <button type="button" class="paymentStatusButton" (click)="showPaymentStatus(card)" *ngIf="isStatusInProgressNoDevState(card)">
          Payment Status
        </button>
        <button class="scheduleButton" (click)="openBuildCard(card, true)" *ngIf="isStatusInProgressNoDevState(card) && !card.scheduled_date && (card.pm_status === 'assigned')">
              Schedule <img *ngIf="preventClick && card && selectedCard && card.id === selectedCard.id" src="./../../../assets/images/spinner.gif" alt="" />
        </button>
      </div>
    </div>
    <div class="cardMiddle" *ngIf="isStatusInProgress(card)">
      <div class="runningDetail">
        <div class="pieChart">
          <round-progress [current]="card.percentage_completed ? card.percentage_completed : 0" [max]="100"
            [color]="'#6C00EA'" [background]="'#eff2f9'" [radius]="75" [stroke]="15" [semicircle]="false"
            [rounded]="true" [clockwise]="true" [responsive]="false" [duration]="800" [animation]="'easeInOutQuart'"
            [animationDelay]="0"></round-progress>
            <h5 *ngIf="card.percentage_completed">{{card.percentage_completed}}%</h5>

            <div class="comingSoon" *ngIf="!card.percentage_completed">
              <h6>Coming<br> Soon <span>Stay<br> Tuned!</span></h6>
              <div class="tooltipText">
                Percentage of work done - that's across all the phases you added to your Buildcard.
              </div>
            </div>

        </div>

        <div class="statusTag" *ngIf="card.pm_status" [ngClass]="{'gray': card.pm_status === 'in_90_day_bug_fixing',
           'red': card.pm_status === 'blocked',
           'blue': card.pm_status === 'ongoing',
           'green': card.pm_status === 'completed',
           'orange': card.pm_status === 'builder_care'}">
        {{card.pm_status === 'in_90_day_bug_fixing' ? 'Bug Fixing Window' : card.pm_status ==='builder_care' ? 'Builder Care' : (card.pm_status | titlecase)}}
      </div>
      </div>
    </div>
    <div class="cardMiddle" *ngIf="isStatusOnHold(card)">
      <div class="cardPayStatus pending">
        <div class="payIcons"><em class="icon-paypending"></em></div>
        <div class="headline">Payment pending for this month.</div>
        <!--<p>Please take required action to restart the Buildcard<sup class="tm light">®</sup>.</p>-->
      </div>
    </div>
    <div class="cardBottom">
      <div class="priceTag" *ngIf="!card.owner">
        <div>{{card?.currency?.symbol}}{{getLocaledPrice((
              getDiscountedprice(card)
                | number : '1.2-2'), card.currency)}}
        </div>
        <div class="strick"
          *ngIf="getDiscountedprice(card) < getprice(card) && card.change_amount === 0">
          {{getLocaledPrice((getprice(card) | number : '1.2-2'), card.currency)}}
        </div>
      </div>
      <div class="priceTag" *ngIf="card.owner">
        <div>{{card?.currency?.symbol}}{{getLocaledPrice((
              getDiscountedprice(card)
          | number : '1.2-2'), card.currency)}}
        </div>
        <div class="strick"
          *ngIf="getDiscountedprice(card) < getprice(card) && card.change_amount === 0">
          {{getLocaledPrice(((getprice(card))| number : '1.2-2'), card.currency)}}
        </div>
      </div>
      <!--<button type="button" class="forpayment" (click)="showPaymentStatus(card)" *ngIf="isStatusInProgressNoDevState(card)">
        Payment Status
      </button>-->
      <button (click)="viewProgress(card)" *ngIf="isStatusInProgress(card)">View progress</button>
      <button (click)="openBuildCard(card, true)"
              *ngIf="isStatusInProgressNoDevState(card) && card.scheduled_date && !card.paid_using_bnpl">
              View <img *ngIf="preventClick && card && selectedCard && card.id === selectedCard.id"
          src="./../../../assets/images/buttonLoader.gif" alt="" /></button>
      <!-- <button (click)="openBuildCard(card, true)" *ngIf="isStatusInProgressNoDevState(card) && !card.scheduled_date">
        Schedule <img *ngIf="preventClick && card && selectedCard && card.id === selectedCard.id"
          src="./../../../assets/images/buttonLoader.gif" alt="" /></button> -->
      <button (click)="viewProgress(card)" *ngIf="isStatusInProgressNoDevState(card) && !card.scheduled_date">Builder Home</button>
    </div>
  </div>
  <app-progress-card-load class="progressCardload" *ngFor="let dummy of ' '.repeat(loaderCount).split(''), let x = index" [loaderCount]='loaderCount'></app-progress-card-load>
</div>
<div class="dashProjectRow running" *ngIf="dataService.getCardCount('paid') > 0 && !(dataService.paidCards.length > 0) && !isApiSuccess">
 <app-progress-card-load class="progressCardload" *ngFor="let dummy of ' '.repeat(4).split(''), let x = index"></app-progress-card-load>
</div>
<div class="dashProjectRow" *ngIf="isApiSuccess && (dataService.paidCards.length === 0)">
  <div class="norecordHeading">No records found.</div>
</div>

<app-duplicate-card-modal *ngIf="commonService.isDuplicateCardFlow"></app-duplicate-card-modal>


