// tslint:disable:max-line-length
import {Component, OnInit, Input, Output, EventEmitter, Renderer2, HostListener, ViewChild, AfterViewInit} from '@angular/core';
import { Angulartics2Segment } from 'angulartics2/segment';
import { DataService } from '@shared/services/data.service';
import { PhaseService } from '@shared/services/phase.service';
import { Intercom } from 'ng-intercom';
import {
  PerfectScrollbarComponent, PerfectScrollbarConfigInterface,
  PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';
import { HeaderService } from '@shared/services/header.service';
import { ReferralService } from '@shared/services/referral.service';
import { AppDataService } from '@rootmodule/app-data.service';
import {BillingDetailsService} from '@shared/services/billing-details.service';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit, AfterViewInit {

  @Output() pshowCards = new EventEmitter();
  @Output() pexitPaymentStatus = new EventEmitter();

  @Input ('showSubMenuLoader') showSubMenuLoader;
  @Input('showTooltip') showTooltip;

  @Input('showCompleted') showCompleted;
  @Input('showInProgress') showInProgress;
  @Input('showPaid') showPaid;
  @Input('showSpecCalls') showSpecCalls;
  @Input('showInvited') showInvited;
  @Input('showArchived') showArchived;
  @Input('showAdditionalFeatures') showAdditionalFeatures;
  @Input('showPaymentCards') showPaymentCards;
  @Input() showPrototypeCards;
  @Input() showCustomPrototypeCards;
  @Input() showProjectCompleted;
  @Input() showFreemiumCards;
  @Input() showTailorMadePrototypeCards;
  @Input('showDemoCalls') showDemoCalls;
  @ViewChild(PerfectScrollbarComponent) scrollTODown: PerfectScrollbarComponent;

  PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
  };

  innerWidth = 768;
  showSections = { showCalls: false, showCards: false, showPrototypes: false };

  constructor( public analyticsSegment: Angulartics2Segment, public referralService: ReferralService, public billingService: BillingDetailsService,
    public dataService: DataService, public phaseService: PhaseService, public headerService: HeaderService, private renderer: Renderer2, private appDataService: AppDataService)
    {
      this.renderer = renderer;
      this.innerWidth = window.innerWidth;
    }

  ngOnInit() {
    if(this.appDataService.urlParameters.login_token){
      this.settingsShow()
    }

  }

  ngAfterViewInit() {
    setTimeout(() => {
      const user = this.dataService.user;
      if (this.phaseService.showTaxAndBilling && user.address && !user.address.tax_details_disabled) {
        if (this.checkIfKickOfScheduled()) {
          this.scrollTODown.directiveRef.scrollToBottom();
        }
      }
    }, 3000);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;

    if(this.innerWidth >= 992){
      this.renderer.removeClass(document.getElementById('app-component'), 'hide-scroll-bar');
    }

  }

  public getCardCount(type) {
    if (this.dataService.buildCardCountData) {
      this.showSubMenuLoader = false;
      switch (type) {
        case 'completed':
          return this.dataService.buildCardCountData['completed'];
        case 'inprogress':
          return this.dataService.buildCardCountData['incomplete'];
        case 'paid':
          return this.dataService.buildCardCountData['running'] || this.dataService.paidCards.length;
        case 'invited':
          return this.dataService.buildCardCountData['invited'];
        case 'archived':
          return this.dataService.buildCardCountData['archived'];
        case'additionalfeaturerequest':
          return this.dataService.changeFeatureRequestArrayModel.length;
        case 'prototype':
          return this.dataService.buildCardCountData['prototype'];
        case 'custom_prototype':
          return this.dataService.buildCardCountData['custom_prototype'];
        case 'project_completed':
          return this.dataService.buildCardCountData['project_completed'];
        case 'freemium':
          return this.dataService.buildCardCountData['freemium'];
        case 'tailor_made_prototype':
          return this.dataService.buildCardCountData['tailor_made_prototype'];
      }
    } else { return 0; }
  }

  public hideToolTip(event: any) {
    event.stopPropagation();
    this.showTooltip = false;
  }

  public settingsShow() {
    this.pexitPaymentStatus.emit();
    this.phaseService.isProfile = true;
    this.referralService.showReferralWallet = false;
    this.phaseService.showTaxAndBilling = false;
    if (innerWidth <= 991) {
      this.updateShowDashboardMenuForMobileOrTablet();
    }
    // this.showCompleted = false;
    // this.showInProgress = false;
  }

  public showSubMenuLoaders() {
    return this.showSubMenuLoader;
  }

  public showCards(index) {
    this.dataService.searchText = '';
    if (innerWidth <= 991) {
      this.updateShowDashboardMenuForMobileOrTablet();
    }

    if ((index === 9) && (this.getCardCount('custom_prototype') === 0)) {
      return false;
    }
    if ((index === 10) && (this.getCardCount('prototype') === 0)) {
      return false;
    }
    if ((index === 11) && !this.getCardCount('project_completed')) {
      return false;
    }
    if ((index === 13) && !this.getCardCount('tailor_made_prototype')) {
      return false;
    }
    if ((index === 14) && !this.dataService.demoCallCount) {
      return false;
    }
   this.pshowCards.emit(index);
  }

  public isNotProfileAndShowCard(): boolean {
    return !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling && this.showCompleted && this.showInProgress && !this.showArchived && this.showInvited &&
    this.showAdditionalFeatures && !this.showPaymentCards;
  }

  public isActiveProgressCards(): boolean {
    return !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling && !this.showSpecCalls && this.showInProgress && !this.showCompleted && !this.showPaid && !this.showArchived &&
      !this.showInvited && !this.showAdditionalFeatures && !this.showPaymentCards && !this.showPrototypeCards && !this.showCustomPrototypeCards
      && !this.showFreemiumCards && !this.showTailorMadePrototypeCards && !this.showDemoCalls;
  }

  public isActiveShowCards(): boolean {
    return !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling && !this.showSpecCalls && this.showPaid && !this.showCompleted && !this.showInProgress && !this.showArchived &&
      !this.showInvited && !this.showAdditionalFeatures && !this.showPaymentCards && !this.showFreemiumCards && !this.showDemoCalls;
  }

  public isActiveArchivedCards(): boolean {
    return !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling && !this.showSpecCalls && !this.showPaid && !this.showCompleted && !this.showInProgress && this.showArchived &&
      !this.showInvited && !this.showAdditionalFeatures && !this.showPaymentCards && !this.showPrototypeCards && !this.showCustomPrototypeCards
      && !this.showProjectCompleted && !this.showFreemiumCards && !this.showTailorMadePrototypeCards && !this.showDemoCalls;
  }

  public isActiveInvitedCards(): boolean {
    return !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling && !this.showSpecCalls && !this.showPaid && !this.showCompleted && !this.showInProgress && !this.showArchived &&
      this.showInvited && !this.showAdditionalFeatures && !this.showPaymentCards && !this.showPrototypeCards && !this.showCustomPrototypeCards
      && !this.showProjectCompleted && !this.showFreemiumCards && !this.showTailorMadePrototypeCards && !this.showDemoCalls;
  }

  public isActiveCompletedCards(): boolean {
    return !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling && !this.showSpecCalls && this.showCompleted && !this.showInProgress && !this.showPaid && !this.showArchived &&
      !this.showInvited && !this.showAdditionalFeatures && !this.showPaymentCards && !this.showPrototypeCards && !this.showCustomPrototypeCards
      && !this.showProjectCompleted && !this.showFreemiumCards && !this.showTailorMadePrototypeCards && !this.showDemoCalls;
  }

  public isActiveSpecCallsCards(): boolean {
    return !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling && this.showSpecCalls && !this.showCompleted && !this.showInProgress && !this.showPaid && !this.showArchived &&
      !this.showInvited && !this.showAdditionalFeatures && !this.showPaymentCards && !this.showPrototypeCards && !this.showCustomPrototypeCards
      && !this.showProjectCompleted && !this.showFreemiumCards && !this.showTailorMadePrototypeCards && !this.showDemoCalls;
  }

  public isActiveAdditionalFeatureRequest(): boolean {
    return !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling && !this.showSpecCalls && !this.showCompleted && !this.showInProgress && !this.showPaid && !this.showArchived &&
      !this.showInvited && this.showAdditionalFeatures && this.showPaymentCards && !this.showPrototypeCards && !this.showCustomPrototypeCards
      && !this.showProjectCompleted && !this.showFreemiumCards && !this.showTailorMadePrototypeCards && !this.showDemoCalls;
  }

  public isActivePaymentCards(): boolean {
    return !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling && !this.showSpecCalls && !this.showCompleted && !this.showInProgress && !this.showPaid && !this.showArchived &&
      !this.showInvited && this.showPaymentCards && !this.showPrototypeCards && !this.showCustomPrototypeCards && !this.showProjectCompleted
      && !this.showFreemiumCards && !this.showTailorMadePrototypeCards && !this.showDemoCalls;
  }

  public isPrototypeCards(): boolean {
    return !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling && !this.showSpecCalls && !this.showCompleted && !this.showInProgress && !this.showPaid && !this.showArchived &&
      !this.showInvited && !this.showPaymentCards && !this.showProjectCompleted && !this.showFreemiumCards && this.showPrototypeCards
      && !this.showTailorMadePrototypeCards && !this.showDemoCalls;
  }

  public isCustomPrototypeCards(): boolean {
    return !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling && !this.showSpecCalls && !this.showCompleted && !this.showInProgress && !this.showPaid && !this.showArchived &&
      !this.showInvited && !this.showPaymentCards && !this.showProjectCompleted && !this.showFreemiumCards && this.showCustomPrototypeCards
      && !this.showTailorMadePrototypeCards && !this.showDemoCalls;
  }

  public isProjectCompletedCards(): boolean {
    return !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling && !this.showSpecCalls && !this.showCompleted && !this.showInProgress && !this.showPaid && !this.showArchived &&
      !this.showInvited && !this.showPaymentCards && !this.showCustomPrototypeCards && !this.showFreemiumCards && this.showProjectCompleted
      && !this.showTailorMadePrototypeCards && !this.showDemoCalls;
  }

  public isActiveFreemiumCards(): boolean {
    return !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling && !this.showSpecCalls && !this.showCompleted && !this.showInProgress && !this.showPaid && !this.showArchived &&
      !this.showInvited && !this.showPaymentCards && !this.showCustomPrototypeCards && !this.showProjectCompleted && this.showFreemiumCards
      && !this.showTailorMadePrototypeCards && !this.showDemoCalls;
  }

  isActiveTailorMadePrototypeCards() {
    return !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling && !this.showSpecCalls && !this.showCompleted && !this.showInProgress && !this.showPaid && !this.showArchived &&
      !this.showInvited && !this.showPaymentCards && !this.showCustomPrototypeCards && !this.showProjectCompleted && !this.showFreemiumCards
      && this.showTailorMadePrototypeCards && !this.showDemoCalls;
  }

  public isActiveDemoCallsCards(): boolean {
    return !this.phaseService.isProfile && !this.phaseService.showTaxAndBilling && !this.showSpecCalls && !this.showCompleted && !this.showInProgress && !this.showPaid && !this.showArchived &&
      !this.showInvited && !this.showAdditionalFeatures && !this.showPaymentCards && !this.showPrototypeCards && !this.showCustomPrototypeCards
      && !this.showProjectCompleted && !this.showFreemiumCards && !this.showTailorMadePrototypeCards && this.showDemoCalls;
  }

  closeDashboardMenuForMobileOrTablet() {
    this.phaseService.isShowDashboardMenuForMobileOrTablet = false;
  }

  updateShowDashboardMenuForMobileOrTablet() {
    this.phaseService.isShowDashboardMenuForMobileOrTablet = !this.phaseService.isShowDashboardMenuForMobileOrTablet;
    if (this.phaseService.isShowDashboardMenuForMobileOrTablet) {
      this.renderer.addClass(document.getElementById('app-component'), 'hide-scroll-bar');
    } else {
      this.renderer.removeClass(document.getElementById('app-component'), 'hide-scroll-bar');
    }
  }

  showWallet() {
    this.phaseService.isProfile = false;
    this.phaseService.showPaymentStatus = false
    this.referralService.showReferralWallet = true;
    this.phaseService.showTaxAndBilling = false;
    this.phaseService.trackEvent(this.analyticsSegment,'wallet_cta_clicked', 'user_id', this.dataService.user.id);
  }

  showTaxAndBilling() {
    this.phaseService.isProfile = false;
    this.phaseService.showPaymentStatus = false;
    this.referralService.showReferralWallet = false;
    this.phaseService.showTaxAndBilling = true;
    if (innerWidth <= 991) {
      this.updateShowDashboardMenuForMobileOrTablet();
    }
  }

  showTaxDetailsSection() {
    const user = this.dataService.user;
    if (user.address && (((user.address.billed_as === 'company') || (user.currency.code === 'INR')) || ((user.address.billed_as === 'individual') && ((user.billing_entity === 'UK_OWNING_ENTITY') || (user.billing_entity === 'DMCC_OWNING_ENTITY'))))) {
      return true;
    }
  }

  checkIfKickOfScheduled() {
    const paidCards = this.dataService.paidCards,
      completedCards = this.dataService.completedCards;
    if (paidCards.length && (paidCards[0].status === 'started') &&
      ((paidCards[0].scheduled_date !== 'Invalid date') && (paidCards[0].scheduled_date !== null))) {
      return true;
    }
  }

  openSection(sectionType) {
    if (sectionType === 'calls') {
      this.showSections.showCalls = !this.showSections.showCalls;
      this.showSections.showCards = false;
      this.showSections.showPrototypes = false;
    } else if (sectionType === 'cards') {
      this.showSections.showCards = !this.showSections.showCards;
      this.showSections.showCalls = false;
      this.showSections.showPrototypes = false;
    } else if (sectionType === 'prototypes') {
      this.showSections.showPrototypes = !this.showSections.showPrototypes;
      this.showSections.showCards = false;
      this.showSections.showCalls = false;
    }
  }
}
