import {PhaseModel} from '../phase-model/phase.model';
import {CurrencyModel} from '../currency-model/currency.model';
import {ProductModel} from '../product-model/product.model';
import {PlatformModel} from '../platform-model/platform.model';
import {SettingModel} from '../setting-model/setting.model';
import {SpeedModel} from '../speed-model/speed.model';
import {PromotionModel} from './promotion.model';
import {BuilderPartnerModel} from '../builder-partner-model/builder-partner.model';

/**
 * Created by nitin on 14/12/17.
 */
export class HomepageDataModel {
  build_phases: Array<PhaseModel>;
  build_products: Array<ProductModel>;
  currencies: Array<CurrencyModel>;
  currency: CurrencyModel;
  need_help_products: Array<ProductModel>;
  platforms: Array<PlatformModel>;
  settings: Array<SettingModel>;
  speed: Array<SpeedModel>;
  promotion: PromotionModel;
  builder_partner: BuilderPartnerModel;
  valid_user: boolean;
  country_code: string;
  multiplier_experiment: any;
  spec_call_data: any;
  spec_call_promotion: any;
  prototype_platforms: Array<PlatformModel>;
  pricing_logic: string;
  store_change_request_discount: number;
  block_sign_up: boolean;
}
