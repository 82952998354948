import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { PhaseService } from '@shared/services/phase.service';
import { DataService } from '@shared/services/data.service';
import { RentalService } from '@shared/services/rental.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { ApiRequests } from '@shared/services/api-requests.service';
import { forkJoin } from 'rxjs';
import { UserModel } from '@models/user-model/user.model';
import { ModelMapperService } from '@shared/services/model-mapper.service';
import parsePhoneNumber from 'libphonenumber-js';
import {BillingDetailsService} from '@shared/services/billing-details.service';

@Component({
  selector: 'nda-modal',
  templateUrl: './nda-modal.component.html',
  styleUrls: ['./nda-modal.component.scss']
})

export class NdaModalComponent implements OnInit {
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  isShowManualAddress = false;
  showCountryDropDown = false;
  showStateDropDown = false;
  isSaveButtonClicked = false;
  isSmallImage = false;
  isSearchedPlaceExit = false;
  options: any;
  selectedStates = [];
  @ViewChild('mobileNumber') public mobileNumber;
  showCityDropDown = false;
  searchedText = '';
  @ViewChild('searchTextCity') searchTextCity: ElementRef;

  constructor(public dataService: DataService, public phaseService: PhaseService,
    public rentalService: RentalService, public apiService: ApiRequests,
    public modelMapperService: ModelMapperService, public billingService: BillingDetailsService) {
    this.billingService.getCountryWiseState();
    this.createSpecCall();
    if (dataService.user) {
      phaseService.billingInfo.firstname = dataService.user.first_name;
      phaseService.billingInfo.lastname = dataService.user.last_name;
      phaseService.billingInfo.email = dataService.user.email;
      phaseService.billingInfo.billing_entity = dataService.user.billing_entity;
      this.billingService.setUserAddress();
      if (!this.phaseService.billingInfo.contact) {
        phaseService.billingInfo.contact = dataService.user.phone_number;
      }
    }
  }

  createSpecCall() {
    this.apiService.createSpecCall('normal', this.dataService.currentPage).subscribe((resp) => {
      this.phaseService.specCallCreationData = resp;
    }, error => {
    });
  }

  setAddressOptions() {
    const countryCode = this.dataService.user ? this.dataService.user.available_country ? this.getCountryCode() : [] : [];
    this.options = {
      componentRestrictions: {
        country: countryCode
      }
    };
  }

  getCountryCode() {
    return this.dataService.user.available_country.map((country) => {
      return country.code;
    });
  }

  ngOnInit(): void {
    this.setAddressOptions();
    this.phaseService.fetchUserShowData();
    this.dataService.showMeetNowModel = false;
    this.phaseService.showInstantSpecCallFlow = false;
  }

  hideMeetModel() {
    this.dataService.showMeetNowModel = false;
    this.phaseService.showSignNDAPopup = false;
  }


  updateAddressSignNDAandProceedForScheduler() {
    const billingInfo = this.phaseService.billingInfo;
    this.isSaveButtonClicked = true;
    if (!this.billingService.checkIfValidDetails()) {
      return false;
    }
    this.phaseService.proceedClickedFromRental = true;
    this.phaseService.billingInfo.name = this.phaseService.billingInfo.firstname + (this.phaseService.billingInfo.lastname ? ' ' + this.phaseService.billingInfo.lastname : '');
    this.phaseService.billingInfo.address = this.phaseService.billingInfo.apartment + (this.phaseService.billingInfo.street ? ' ' + this.phaseService.billingInfo.street : '');
    if (billingInfo.state) {
      billingInfo.state = billingInfo.state['code'] ? billingInfo.state['name'] : billingInfo.state;
    }
    // this.phaseService.trackEvent(this.analyticsSegment,  'NDA agree CTA clicked', 'page_source', this.dataService.getPageName());
    const addressSubscription = this.apiService.updateUserAddress(this.phaseService.billingInfo, true);
    const ndaSubscription = this.apiService.signNDAAgreedForSpecCall(this.phaseService.specCallCreationData.id);
    forkJoin([addressSubscription, ndaSubscription]).subscribe((res) => {
      const userObj: UserModel = new UserModel();
      const userData = res[0];
      userData.user = this.modelMapperService.getMappedModel(userObj, userData.user);
      this.dataService.user = userData.user;
      this.dataService.storeUserObject();
      this.rentalService.isAddressSelected = false;
      this.phaseService.addressSubmitted = true;
      this.openScheduler();
    }, error => {
      this.phaseService.proceedClickedFromRental = false;
    });
  }

  openScheduler() {
    // this.phaseService.showScheduleCallFlow = true;
    this.dataService.showMeetNowModel = true;
    this.phaseService.showSignNDAPopup = false;

  }

  checkIfFormValid() {
    for (const key of Object.keys(this.phaseService.billingInfo)) {
      if ((this.phaseService.billingInfo[key] === '') && (((key !== 'name') && (key !== 'address_line_2') && (key !== 'billing_entity') && (key !== 'companyName') && (key !== 'address') && (key !== 'gstNumber') && (key !== 'apartment')))) {
        return false;

      }
    }
    return true;
  }

  hideDropDown() {
    this.showCountryDropDown = false;
    this.showStateDropDown = false;
  }

  toggleAddressManually() {
    this.isShowManualAddress = !this.isShowManualAddress;
  }

  getBillingInfo() {
    return this.billingService.getBillingInfo();
  }

  showStatesList(name?, event?) {
    if (name === 'states') {
      this.showStateDropDown = true;
      this.showCountryDropDown = false;
    } else if (name === 'zipCode') {
      this.showCountryDropDown = false;
    } else {
      this.showCountryDropDown = true;
      this.showStateDropDown = false;
    }
  }

  showCountriesList(e) {
    e.stopPropagation()
    this.showCountryDropDown = true;
    this.showStateDropDown = false;
  }

  public selectState(state) {
    this.phaseService.billingInfo.state = state.name;
    this.showStateDropDown = false;
    this.billingService.setCity();
  }

  selectCountry(country) {
    this.phaseService.billingInfo.country = country.name;
    this.showCountryDropDown = false;
    this.billingService.availablestate();
    this.billingService.setCity();
  }

  public onScrollRightSection(e) {
    if (e.target.scrollTop >= 10) {
      this.isSmallImage = true;
    } else {
      this.isSmallImage = false;
    }
  }

  checkIfPhoneInValid() {
    const phoneNumber = parsePhoneNumber(this.getBillingInfo().contact.toString(), this.dataService.countryISOCode);
    if (phoneNumber) {
      return !(phoneNumber.isValid() && this.getBillingInfo().contact.includes('+' + phoneNumber.countryCallingCode));
    } else {
      return true;
    }
  }

  setUserContact(event) {
    this.getBillingInfo().contact = event;
  }

  ShowDropdown(){
    this.showStateDropDown = true;
  }

  changeAddressType(addressType) {
    this.billingService.changeAddressType(addressType);
  }

  showCityDropdown() {
    this.searchedText = '';
    this.showCityDropDown = !this.showCityDropDown;
    this.searchTextCity.nativeElement.focus();
    this.showCountryDropDown = false;
    this.showStateDropDown = false;
  }

  renderCitiesInDropdown() {
    return this.billingService.renderCitiesInDropdown();
  }

  handleBackSpace(event, isState?) {
    if (event.keyCode === 8) {
      this.showStatesList(isState);
    }
  }

  selectCity(city) {
    this.phaseService.billingInfo.city = city;
    this.showCityDropDown = false;
  }

}

