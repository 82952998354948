<div class="dashHeading" *ngIf="dataService.getCardCount('inprogress') > 0">Saved {{ (dataService.getCardCount('inprogress') > 1) ? 'Cards' : 'Card' }}

  <button type="button" (click)="showCards(2)" *ngIf="showRecentProgress && dataService.getCardCount('inprogress') > 4">Show all
  </button>
</div>

<div class="dashProjectRow" [ngClass]="{'hideCard': showRecentProgress}" infiniteScroll [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="300" (scrolled)="onScroll(dataService.getCardCount('inprogress'))">
  <!-- new design card -->
  <div class="progressCard"
    *ngFor="let card of (showRecentProgress ? (getInProgressCards() | slice:0: (dataService.getCardCount('inprogress') > 4 ? 4: dataService.getCardCount('inprogress'))) : getInProgressCards());let i = index;">
    <div class="cardHead">
      <div class="cardLeft">
        <h3>{{card.project_name ? card.project_name : 'InProgress' + (i < 9? '0'+ (i+1): (i+1))}}</h3>
        <h4>Last edited: {{card.updated_at}}</h4>
      </div>
      <div class="cardRight">
        <div class="cardMore"><em class="icon-more"></em>
          <div class="cardDropDown">
            <ul>
              <li (click)="showDeleteConfirmation(card)" *ngIf="allowedForInvite(card)">
                Archive Card
              </li>
              <li (click)="showRenameCard(card)">Rename</li>
            </ul>
          </div>
        </div>
        <!-- <div class="shareIcon" (click)="phaseService.showSharePopup(card)"><em class="icon-share"></em>
        </div>
        <div class="inviteIcon" (click)="phaseService.showInvitePopup(card)"><em class="icon-invite"></em>
        </div>-->
      </div>
    </div>

    <div class="info-card" *ngIf="!card.hideInfo && !card.specing_paid">
      <em class="icon-iconcross" (click)="card.hideInfo = true"></em>
      <p>Edit this Buildcard to view details</p>
    </div>

    <div class="cardMiddle">
      <div class="completeDetail">
        <div class="completeRow">
          <div class="completeBox" [ngClass]="{'deactive-fade': card.template_count === 0}">
            <h3>{{card.template_count > 1 ? 'Templates' : 'Template'}} <span>{{card.template_count}}</span></h3>
          </div>
          <div class="completeBox" [ngClass]="{'deactive-fade': card.feature_count === 0}">
            <h3>{{card.feature_count > 1 ? 'Features' : 'Feature'}} <span>{{card.feature_count}}</span></h3>
          </div>
        </div>
        <div class="completeRow">
          <h4 [ngClass]="{'deactive-fade': !card.team}">
            <div class="team-dash-country">Team <span>{{card.team? card.team:""}}</span></div>
          </h4>
          <h4 [ngClass]="{'deactive-fade': !card.weeks || card.weeks === 0}">
            <div>Duration
              <ng-container *ngIf="card.frequency === 'monthly'; else showWeekly">
                 <span>{{card.installment_counts.monthly}} {{ card.installment_counts.monthly > 1 ? 'months' : 'month'}}
                ({{(card.weeks + (card.change_week_status ? card.change_week : -card.change_week))}}
              {{ (card.weeks + (card.change_week_status ? card.change_week : -card.change_week)) > 1 ? 'weeks' : 'week'}})</span>
              </ng-container>
              <ng-template #showWeekly>
                <span>{{(card.weeks + (card.change_week_status ? card.change_week : -card.change_week))}}
              {{ (card.weeks + (card.change_week_status ? card.change_week : -card.change_week)) > 1 ? 'weeks' : 'week'}}</span>
              </ng-template>
            </div>
          </h4>
        </div>
      </div>
      <div class="projectPhases"
        [ngClass]="{'deactive-fade': !card.build_card_phases || card.build_card_phases.length === 0}">
        <ul>
          <li *ngFor="let phase of card.build_card_phases">
            <div class="phaseTitle">{{phase?phase.title:""}}</div>
            <div class="phaseDetail" *ngIf="!phase.is_tailor_made && phase.platforms?.length > 0">
              <div class="phaseIcon" *ngFor="let platform of phase.platforms">
                <img [src]="platform.icon" alt="" />
                <div class="phaseIconTooltip">{{platform.title}}</div>
              </div>
            </div>

            <div class="phaseDetail" *ngIf="phase.is_tailor_made &&  phase.platforms?.length > 1">
              <div class="morePhase" >+ {{ phase.platforms?.length - 1 }}
                <div class="platformTooltip">
                  <h5>{{ phase.platforms?.length || 0 }} Platform Selected</h5>
                  <h6 *ngFor="let platform of phase.platforms"><em class="icon-right"></em>{{ platform.title }}</h6>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="builderCare" *ngIf="card.is_under_support || card.cloud_opted">
        <span>Includes</span>
        <span>
          <img src="../../../../../../../assets/images/BuilderCare-icon.svg" *ngIf="card.is_under_support" alt="" />
          <img src="../../../../../../../assets/images/BuilderCloud-icon.svg" *ngIf="card.cloud_opted" alt="" />        
        </span>
      </div>

    </div>
    <div class="cardBottom">
      <div *ngIf="card.specing_paid">
        <div class="specingPaid">Spec'ing amount paid</div>
        <div class="priceTag">
          {{card?.currency?.symbol}}{{getLocaledPrice((phaseService.specing_price)
          | number : '1.2-2')}}
        </div>
      </div>
      <button (click)="phaseService.showTimeKitWindow()" *ngIf="card.specing_paid && !card.pre_kick_off_date">Schedule
        Call</button>
      <button (click)="openBuildCard(card)" *ngIf="!card.specing_paid">Complete Card <img
          *ngIf="preventClick && card && selectedCard && card.id === selectedCard.id "
          src="./../../../assets/images/buttonLoader.gif" alt="" /></button>
    </div>
  </div>
  <app-progress-card-load class="progressCardload" *ngFor="let dummy of ' '.repeat(loaderCount).split(''), let x = index" [loaderCount]='loaderCount'></app-progress-card-load>
</div>
<div class="dashProjectRow" *ngIf="dataService.getCardCount('inprogress') > 0 && !(dataService.inProgressCards.length > 0)">
  <app-progress-card-load class="progressCardload" *ngFor="let dummy of ' '.repeat(4).split(''), let x = index"></app-progress-card-load>
</div>
