import {Component, Input, OnInit} from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiRequests} from '@shared/services/api-requests.service';
import {CustomValidators} from '@shared/utils/validators';

@Component({
  selector: 'invited-users',
  templateUrl: 'invited-users.component.html'
})

export class InvitedUsersComponent implements OnInit {
  public isInputVisible = false;
  public inviteUserForm: FormGroup;
  public isRemoveOptionVisible: Array<boolean> = [];
  public addInviteLoader = false;
  public isInvitationSent = false;
  public inviteError = '';
  @Input() public invitedCardData: any;

  constructor(public phaseService: PhaseService,
              public dataService: DataService,
              private _formBuilder: FormBuilder,
              private _apiRequestService: ApiRequests) {
  }

  public ngOnInit() {
    this.initInviteForm();
    this.setRemoveArrayFlags();
  }

  private setRemoveArrayFlags() {
    if (this.invitedCardData && this.invitedCardData.invites) {
      for (let i = 0; i < this.invitedCardData.invites.length; i++) {
        this.isRemoveOptionVisible[i] = false;
      }
    }
  }

  public initInviteForm() {
    this.inviteUserForm = this._formBuilder.group({
      email: new FormControl(null, [Validators.required, CustomValidators.emailValidator])
    });
  }

  public showHideAddInviteForm(flag: boolean) {
    flag ? this.isInputVisible = true : this.isInputVisible = false;
    this.setRemoveArrayFlags();
    this.resetInviteForm(this.inviteUserForm);
  }

  public addInviteUser(form: FormGroup) {
    if (form.valid) {
      this.addInviteLoader = true;
      this._apiRequestService.shareInviteCard([form.value.email], this.invitedCardData.id, false, window.location.origin)
        .finally(() => {
          this.addInviteLoader = false;
        })
        .subscribe(
          (data) => {
            this.isInvitationSent = true;
            if (data.invitee && data.invitee._status === 'accepted') {
              this.invitedCardData.invites.push(data.invitee);
              form.reset();
            }
          }
        );
    }
  }

  public closeInvitePopup() {
    this.phaseService.isInvitedUserPopupOpened = false;
    this.invitedCardData = null;
  }

  public showHideRemoveOption(flag: boolean, index: number) {
    flag ? this.isRemoveOptionVisible[index] = true : this.isRemoveOptionVisible[index] = false;
  }

  public resetInviteForm(form: FormGroup) {
    if(form ) {form.reset() }
    this.isInvitationSent = false;
    this.inviteError = null;
  }

  public removeInvitedUser(invitee: any, index: number) {
    this.dataService.showHideLoader(true);
    this._apiRequestService.removeInvitedUser(invitee.id || invitee._id, this.invitedCardData.id, invitee.email)
      .finally(() => {
        this.dataService.showHideLoader(false);
      })
      .subscribe(
        (data) => {
          this.invitedCardData.invites.splice(index, 1);
          this.setRemoveArrayFlags();
          if (this.invitedCardData.invites.length === 0) {
            this.phaseService.isInvitedUserPopupOpened = false;
            this.dataService.showHideLoader(true);
            this._apiRequestService.fetchBuildCards(null, null, 'invited').finally(() => {
              this.dataService.showHideLoader(false);
            }).subscribe();
            this.dataService.filterData();
          }
        },
        (error) => {
          this.inviteError = error.message;
        }
      );
  }

}
