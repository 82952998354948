import { Pipe, PipeTransform } from '@angular/core';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';

@Pipe({
  name: 'sortFeature'
})
export class SortFeaturePipe implements PipeTransform {

  constructor(private dataService: DataService, private phaseService: PhaseService) {}

  transform(value, sortingFormat) {
    const sortingKey = ((sortingFormat === 'titleReverse') ? 'title' : sortingFormat);
    if (!sortingKey) { return value.slice().reverse(); }
    const buildcard = this.dataService.buildCardData;
    if ((sortingFormat === 'updated_at') && buildcard && buildcard.progress && buildcard.progress['buildcardfeatures']) {
      value.forEach(ftr => {
        const feature = buildcard.progress['buildcardfeatures'].find(f => f.feature_id === ftr.id);
        if (feature && feature.id) {
          ftr['updated_at'] = feature.id;
        }
      });
    }
    const sortedData = value.sort((a, b) => {
      let valueA, valueB;
      if (sortingFormat === 'updated_at') {
          valueA = a[sortingKey] ? a.updated_at : 0,
          valueB = b[sortingKey] ? b.updated_at : 0;
      } else {
          valueA = a[sortingKey] ? a[sortingKey] : '',
          valueB = b[sortingKey] ? b[sortingKey] : '';
      }
      valueA = (typeof valueA === 'number') ? valueA : valueA.toLowerCase();
      valueB = (typeof valueB === 'number') ? valueB : valueB.toLowerCase();
      if (valueA > valueB) {
        return 1;
      } else if (valueA < valueB) {
        return -1;
      } else {
        return 0;
      }
    });
    return (((sortingFormat === 'titleReverse') || ((sortingFormat === 'updated_at'))) ?
      sortedData.reverse() : sortedData);
  }

}
