<div class="container paymentContainer">
    <div class="row" [hidden]="!phaseService.removeLoader()">
      <div class="col-md-7">
        <div class="payConfirm">
          <h1>Confirm and Pay</h1>
          <div class="paymentTypeMain">
            <div class="paymentTypeList">
              <ul>
                <li [ngClass]="{active: showCreditDebit}" (click)="showPaymentType('creditdebit')">Credit/ Debit Card</li>
                <li [ngClass]="{active: showInternetBanking}" (click)="showPaymentType('internetbanking')">Internet Banking</li>
                <li [ngClass]="{active: showPaypal}" (click)="showPaymentType('paypal')">Paypal</li>
                <li [ngClass]="{active: showFinancing}" (click)="showPaymentType('financing')">Financing</li>
              </ul>
            </div>
            <div *ngIf="showCreditDebit && dataService.user" class="paymentTypeBlock">
              <!--- Payment method -->
              <div>
                  <div class="payment-mode-block fixHeight" [ngClass]="{'hide' : isIndian()}">
                      <h2 class="head"> Credit/ Debit Card </h2>
                      <div class="payment-way-img"><img src="./../../../../../assets/images/strip.png" alt=""> </div>
                      <p> By clicking the button below, you will redirected to our secured payment gateway. </p>
                    </div>
                    <!-- ccavenue -->
                    <div class="payment-mode-block fixHeight" [ngClass]="{'hide' : !isIndian()}">
                        <h2 class="head"> Credit/ Debit Card </h2>
                        <div class="payment-way-img"><img src="./../../../../../assets/images/ccavenue.png" alt=""> </div>
                        <p> By clicking the button below, you will be redirected to our secured payment gateway. </p>
                      </div>
                    <specing-payment [billingInfo]="getBillingInfo()" [buildCardInstallment]="true"
                                     [paymentAmount]="getInstallmentWithTax(getPaymentData()?.pending_installment)"
                                     [paymentId]="getPaymentData()?.pending_installment?.id"
                                      [isFromPayConfirm]="true">
                    </specing-payment>
              </div>

            </div>
            <div *ngIf="showInternetBanking" class="paymentTypeBlock fixHeight">
              <div class="coming_soon">
                <img src="./../../../../../assets/images/coming_soon_icon.png" alt="">
                <h3>Coming Soon</h3>
                <p>In the meantime, please contact sales for this options.</p>
                <button type="button" (click)="phaseService.showRequestDemo('Internet Banking')">Contact Sales</button>
              </div>
            </div>
            <div *ngIf="showPaypal" class="paymentTypeBlock fixHeight">
              <div class="coming_soon">
                <img src="./../../../../../assets/images/coming_soon_icon.png" alt="">
                <h3>Coming Soon</h3>
                <p>In the meantime, please contact sales for this options.</p>
                <button type="button" (click)="phaseService.showRequestDemo('Paypal')">Contact Sales</button>
              </div>
            </div>
            <div *ngIf="showFinancing" class="paymentTypeBlock fixHeight">
              <div class="coming_soon">
                <img src="./../../../../../assets/images/coming_soon_icon.png" alt="">
                <h3>Coming Soon</h3>
                <p>In the meantime, please contact sales for this options.</p>
                <button type="button" (click)="phaseService.showRequestDemo('Financing')">Contact Sales</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1">
      </div>
      <div class="col-md-4">
        <app-payment-summary></app-payment-summary>
      </div>
    </div>
  </div>


