import { Component, OnInit } from '@angular/core';

@Component({

  templateUrl: './script-lazyload.component.html',
  styleUrls: ['./script-lazyload.component.css']
})
export class ScriptLazyloadComponent implements OnInit {



  ngOnInit(): void {

    let s = document.createElement( 'script' );
    s.setAttribute( 'src', '/assets/js/thirdPartyScript.js' );
    // s.onload=callback;
    document.body.appendChild( s );
  }

}
