<div class="quickBuildcardMain" *ngIf="showChat && dataService.currentPage !== 'homev2'" [ngClass]="{manageCurrencyPopup : setZIndex(), 'hide': dataService.isSideBarVisible}">
  <div class="toggleCircleChatView" [ngClass]="{'active': !isChatHidden}" (click)="toggleChatView($event)">

    <div class="ChatViewCommon ChatViewOpen"></div>
    <div class="ChatViewCommon ChatViewClose"></div>

  </div>
  <div class="helloBox" [ngClass]="{'helloBoxShow': phaseService.showHelloText}" (click)="toggleChatView($event)"
       [hidden]="!phaseService.showHelloText">
    <!--<img [src]="dataService.setAssetsPath('natasha.png')" alt="" />-->
    <div class="helloCon">
      <p><span>Hello! Need some help?</span></p>
    </div>
    <span class="closeFirstBox" (click)="openHelloText($event)"></span>
  </div>

  <div class="quickBuildcard" [ngClass]="{'chatbotOpen': !isChatHidden}">
    <div class="quickBuildHead">
      <div class="quickBuildHeadLogo"></div>
      <div class="quickBuildHeadRight">
      <span (click)="resetChatbot()"></span>
      </div>
    </div>
    <div class="quickBuildMiddle"
         [ngClass]="{msgboxOpen : showInputBox, textAreaVisible: chatQuestions.templates.active}" #mainContent
         [scrollTop]="currentStep > 1 ? mainContent.scrollHeight : '0'">

      <!-- Step 1 -->

      <div *ngIf="currentStep >= 1">
        <div class="myMsgBox">
          <div class="myMsgBoxInner">
            <p class="textBold"><span><strong>Hi! I am Natasha, Welcome to Builder</strong></span></p>
            <img [src]="appDataService.setAssetsPath('giphy.gif')" alt="" />
          </div>
          <div class="myMsgBoxInner">
            <p class="textBold">
              <span *ngIf="currentUrl === '/home'">Let's build your software using Builder!</span>
              <span *ngIf="currentUrl !== '/home'">Let's build your software using Builder!</span>
            </p>
            <div class="chooseChips" *ngIf="!chatSelections.introduction || chatQuestions.introduction.active">
              <div class="chipsBox" *ngFor="let option of chatQuestions.introduction.options; index as i"
                   [ngClass]="{selected : option.selected}"
                   (click)="selectChatOptions('introduction', option)">
                <i class="fa fa-circle" aria-hidden="true"></i>
                <span>{{ option.title }}</span>
              </div>
            </div>
          </div>
          <p class="time">{{ chatQuestions.introduction.lastUpdated | async }}</p>
        </div>
        <div class="myMsgBoxRight" *ngIf="chatSelections.introduction">
          <div class="myMsgBoxRightInner">
            {{ chatSelections.introduction }}
          </div>
        </div>
        <span *ngIf="chatSelections.introduction"
              class="time text-right">{{ chatQuestions.introduction.lastUpdated | async }}</span>
      </div>


      <!-- Step 2 -->

      <div *ngIf="currentStep >= 2 && chatSelections.introduction === 'Show me how it works'">
        <div class="myMsgBox">
          <div class="myMsgBoxInner">
            <p class="textBold"><span><strong>Sure, </strong>{{ chatQuestions.howItWorks.question}}</span></p>
            <iframe width="850" height="200" title="" src="https://www.youtube.com/embed/4V3PKB5cgBc"
                    frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
            <div class="chooseChips" *ngIf="!chatSelections.howItWorks || chatQuestions.howItWorks.active">
              <div class="chipsBox" *ngFor="let option of chatQuestions.howItWorks.options; index as i"
                   [ngClass]="{selected : option.selected}"
                   (click)="selectChatOptions('howItWorks', option)">
                <i class="fa fa-circle" aria-hidden="true"></i>
                <span>{{ option.title }}</span>
              </div>
            </div>
          </div>
          <p class="time">{{ chatQuestions.howItWorks.lastUpdated | async }}</p>
        </div>
        <div class="myMsgBoxRight" *ngIf="chatSelections.howItWorks">
          <div class="myMsgBoxRightInner">
            {{ chatSelections.howItWorks }}
          </div>
        </div>
        <span *ngIf="chatSelections.howItWorks"
              class="time text-right">{{ chatQuestions.howItWorks.lastUpdated | async }}</span>
      </div>


      <!-- Step 3 -->

      <div *ngIf="currentStep >= 3">
        <div class="myMsgBox">
          <div class="myMsgBoxInner">
            <p class="textBold"><span><strong>What kind of product do you want to build? Choose from the following options.</strong></span>
            </p>
            <div class="chooseChips" *ngIf="!chatSelections.product || chatQuestions.product.active">
              <div class="chipsBox" *ngFor="let option of getHomePagedata().build_products; index as i"
                   (click)="selectChatOptions('product', option)">
                <i class="fa fa-circle" aria-hidden="true"></i>
                <span>{{ option.title }}</span>
              </div>
            </div>
          </div>
          <p class="time">{{ chatQuestions.product.lastUpdated | async }}</p>
        </div>
        <div class="myMsgBoxRight" *ngIf="chatSelections.product">
          <div class="myMsgBoxRightInner">
            {{ chatSelections.product }}
          </div>
        </div>
        <span *ngIf="chatSelections.product"
              class="time text-right">{{ chatQuestions.product.lastUpdated | async }}</span>
      </div>


      <!-- Step 4 -->

      <div *ngIf="currentStep >= 4">
        <div class="myMsgBox">
          <div class="myMsgBoxInner">
            <p class="textBold"><span><strong>Great. Can you tell us some similar companies in the market? It will help us choose recommended features that closely resemble your idea. Ex. Amazon or Uber (Max 2)</strong></span>
            </p>
          </div>
          <p class="time">{{ chatQuestions.templates.lastUpdated | async }}</p>
        </div>
        <div class="myMsgBoxRight" *ngIf="chatSelections.templates">
          <div class="myMsgBoxRightInner">
            <span>{{ selectedTemplates[0]?.title }} {{ selectedTemplates.length > 1 ? 'and' : '' }} {{ selectedTemplates.length > 1 ? selectedTemplates[1]?.title : '' }}</span>
          </div>
        </div>
        <span *ngIf="chatSelections.templates"
              class="time text-right">{{ chatQuestions.product.lastUpdated | async }}</span>
      </div>


      <!-- Step 5 -->

      <div *ngIf="currentStep >= 5">
        <div class="myMsgBox">
          <div class="myMsgBoxInner">
            <p class="textBold">
            <span>Great. You have chosen {{ selectedTemplates[0].title }} {{ selectedTemplates.length > 1 ? 'and' : '' }} {{ selectedTemplates.length > 1 ? selectedTemplates[1].title : '' }}
              as similar products.
            </span>
            </p>
          </div>
          <p class="time">{{ chatQuestions.templates.lastUpdated | async }}</p>
        </div>
      </div>

      <div *ngIf="currentStep >= 6">
        <div class="myMsgBox">
          <div class="myMsgBoxInner">
            <p class="textBold">
              <span>There are total {{ selectedFeatures?.length }} features we have chosen for you based on your selection of similar products</span>
            </p>
          </div>
          <p class="time">{{ chatQuestions.templates.lastUpdated | async }}</p>
        </div>
      </div>

      <div *ngIf="currentStep >= 7">
        <div class="myMsgBox">
          <div class="myMsgBoxInner">
            <p class="textBold">
            <span>You can customise your features using our 'Feature Studio' once your Buildcard<sup class="tm">®</sup> is ready.
            </span>
            </p>
          </div>
          <p class="time">{{ chatQuestions.templates.lastUpdated | async }}</p>
        </div>
      </div>

      <!-- Step 8 -->


      <div *ngIf="currentStep >= 8">
        <div class="myMsgBox">
          <div class="myMsgBoxInner">
            <p class="textBold">
              <span>{{chatQuestions.platforms.question}}</span>
            </p>
            <div class="platforms" *ngIf="currentStep === 8">
              <div class="platforms-tag" *ngFor="let option of getHomePagedata().platforms; index as i">
                <label class="check_container" [ngClass]="{'checked': option.selected}">{{ option.title }}
                  <input type="checkbox" (click)="selectPlatforms(option, $event, i)">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="confirmBtnMain" *ngIf="currentStep === 8">
            <button class="confirmBtn" type="button"
                    (click)="!isUserLoggedIn() ? selectChatOptions('email'): createBuildCard()"
                    [disabled]="selectedPlatforms.length === 0">Confirm
            </button>
          </div>
          <p class="time">{{ chatQuestions.platforms.lastUpdated | async }}</p>
        </div>
        <div class="myMsgBoxRight" *ngIf="chatSelections.platforms && currentStep >= 9">
          <div class="myMsgBoxRightInner">
            {{ chatSelections.platforms }}
          </div>
        </div>
        <span *ngIf="chatSelections.platforms && currentStep >= 9"
              class="time text-right">{{ chatQuestions.platforms.lastUpdated | async }}</span>
      </div>

      <!-- Step 9 -->

      <div *ngIf="currentStep >= 9">
        <div class="myMsgBox">
          <div class="myMsgBoxInner">
            <p class="textBold">
              <span>Great you have chosen {{ chatSelections.platforms }} platforms to build.</span>
            </p>
          </div>
          <p class="time">{{ chatQuestions.platforms.lastUpdated | async }}</p>
        </div>
      </div>


      <div *ngIf="currentStep >= 10">
        <div class="myMsgBox">
          <div class="myMsgBoxInner">
            <p class="textBold">
              <span>You're almost done! Lastly, help us with your email address so we can send your Buildcard<sup class="tm">®</sup> to you.</span>
              <span *ngIf="!chatSelections.email">A Buildcard<sup class="tm">®</sup> is a dashboard to your Buildcard<sup class="tm light">®</sup> which contains details of all the features, timelines, phases and offers you the flexibility to fully customize your Buildcard<sup class="tm">®</sup>.</span>
            </p>
            <div class="emailForm" *ngIf="!chatSelections.email && currentStep === 10">
              <form [formGroup]="emailForm" (ngSubmit)="submitForm(emailForm)">
                <input class="input-field" type="email" name="" value="" placeholder="Enter your email address"
                       formControlName="email">
                <span class="email-error"
                      *ngIf="emailForm.get('email').touched && !phaseService.validateEmail(emailForm.get('email').value)">Email is not valid</span>
                <span class="email-error"
                      *ngIf="emailForm.get('email').hasError('required') && emailForm.get('email').touched">Email is required</span>
                <button class="googleSignup" type="button" (click)="onGoogleSignup()">
                  <div class="googleIcon"><img src="../../../../../assets/images/glogo.png" alt="" /></div>
                  <span>Continue with Google</span></button>

                <input class="emailSubmit" type="submit" name="" value="Submit" [disabled]="!phaseService.validateEmail(emailForm.get('email').value)">

              </form>
            </div>
          </div>
          <div class="myMsgBoxRight" *ngIf="chatSelections.email && currentStep >= 9">
            <div class="myMsgBoxRightInner">
              {{ chatSelections.email }}
            </div>
          </div>
        </div>
        <span *ngIf="chatSelections.email" class="time text-right">{{ chatQuestions.email.lastUpdated | async }}</span>
      </div>

      <div *ngIf="currentStep === 11">
        <div class="myMsgBox">
          <div class="myMsgBoxInner">
            <p class="textBold">
              <span>{{chatQuestions.name.question}}</span>
            </p>
            <div class="emailForm">
              <form [formGroup]="userDetailForm" (ngSubmit)="submitUserDetails(userDetailForm)">
                <input class="input-field" type="text" name="" value="" placeholder="Enter your Name"
                       formControlName="name">
                <span class="email-error"
                      *ngIf="userDetailForm?.get('name').hasError('required') && userDetailForm?.get('name').touched">Please enter a valid name</span>

                <international-phone-number #mobileNumber class="internationalNumber" (keyup)="onFlagChange()"
                                            (focusin)="focusInNumber($event)"
                                            [ngClass]="checkIfMobileValid() ? 'redBorder' : ''"
                                            placeholder="Mobile Number"
                                            [maxlength]="20" [defaultCountry]="phaseService.countryNameCode" [required]="true"
                                            formControlName="phone"
                                            required></international-phone-number>

                <!--<input type="text"
                       formControlName="phone"
                       class="input-field">-->
                       <!--ng2TelInput
                       [ng2TelInputOptions]="{initialCountry: 'us'}"
                       (hasError)="hasError($event)"
                       (ng2TelOutput)="getNumber($event)"
                       (intlTelInputObject)="telInputObject($event)"
                       (countryChange)="onCountryChange($event)"
                       (keypress)="restrictPhoneField($event)"-->
                <span class="email-error"
                      *ngIf="userDetailForm?.get('phone').hasError('required') && userDetailForm?.get('phone').touched">Please enter a valid number</span>
                <input class="emailSubmit" type="submit" name="" value="Submit" [disabled]="!userDetailForm?.valid">
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- Step 11  -->

      <div *ngIf="currentStep === 12">
        <div class="myMsgBox">
          <div class="myMsgBoxInner">
            <p class="textBold">
              <span>It seems you have an account with us, please enter your password.</span>
            </p>
            <div class="emailForm">
              <form>
                <input class="input-field" type="password" name="" value="" placeholder="Enter password"
                       [(ngModel)]="userPassword" [ngModelOptions]="{standalone: true}" (focus)="errorMessage = null">
                <span class="email-error"
                      *ngIf="errorMessage">Incorrect Password</span>
                <input class="emailSubmit" type="button" name="" value="Submit" (click)="loginUser()"
                       [disabled]="!userPassword">
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- Step 12 --->

      <div *ngIf="currentStep >= 13">
        <div class="myMsgBox">
          <div class="myMsgBoxInner">
            <p class="textBold">
              <span><strong>Thanks for sharing your contact</strong></span>
            </p>
          </div>
        </div>
      </div>

      <!-- Step 13 -->

      <div *ngIf="currentStep >= 14">
        <div class="myMsgBox">
          <div class="myMsgBoxInner">
            <p class="textBold">
              <span><strong>Thanks {{ getFirstName() }}</strong></span>
            </p>
            <p>
              <span>Here you go ...below is the link of your Buildcard<sup class="tm">®</sup>.</span>
            </p>
            <p>
              <a (click)="goToBuildCard()">{{ buildCardUrl }}</a>
            </p>
            <p>
              <span>It was also e-mailed to you.</span>
            </p>
            <div class="chooseChips">
              <div class="chipsBox" *ngFor="let option of chatQuestions.buildCard.options; index as i"
                   [ngClass]="{selected : option.selected}"
                   (click)="selectChatOptions('buildCard', option)">
                <i class="fa fa-circle" aria-hidden="true"></i>
                <span>{{ option.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="myMsgBox" *ngIf="showLoading">{{loadingText}} <img class="loader"
                                                                     src="./../../../../../assets/images/Fading circles.gif" width="64" height="22" alt="" />
      </div>
      <div class="spacing"></div>

    </div>

    <div class="msgBox" *ngIf="chatQuestions.templates.active">

      <div class="chipInputBox">
        <div class="chipText" *ngFor="let template of selectedTemplates"><strong>{{ template.title }}</strong><span
          (click)="selectTemplate(template, false)">x</span></div>
        <input type="text" *ngIf="selectedTemplates.length < 2" [placeholder]="placeholderText"
               [(ngModel)]="queryString" (keyup)="searchTemplates(queryString)"/>
        <button class="tagInputSubmit" type="submit" (click)="selectChatOptions('templates')"
                [disabled]="selectedHash.application_ids.length === 0">
          <i class="fa fa-paper-plane" aria-hidden="true"></i>
        </button>

        <div class="autoSuggest" *ngIf="dataService.apps.length > 0 && showDropdown">
          <ul>
            <li *ngFor="let app of dataService.apps" (click)="selectTemplate(app, true)">
              <strong>
                <img [src]="utilityService.addHttps(app.icon)" alt="" />
              </strong>
              <span>{{ app.title }}</span>
            </li>
          </ul>
        </div>
      </div>

      <!--<tag-input #tagInput-->
      <!--class="textarea"-->
      <!--[ngModel]="selectedTemplate"-->
      <!--[maxItems]="'2'"-->
      <!--(onAdd)="selectTemplate($event, true)"-->
      <!--(onRemove)="selectTemplate($event, false)"-->
      <!--(onTextChange)="searchTemplates($event, tagInput)"-->
      <!--[placeholder]="'add more....'"-->
      <!--[secondaryPlaceholder]="'Please enter the name of similar products'"-->
      <!--[onlyFromAutocomplete]="true">-->
      <!--<tag-input-dropdown-->
      <!--[focusFirstElement]="true"-->
      <!--[displayBy]="'title'"-->
      <!--[keepOpen]="false"-->
      <!--[appendToBody]="false"-->
      <!--[identifyBy]="'id'"-->
      <!--[autocompleteItems]="dataService.apps">-->
      <!--<ng-template let-item="item" let-index="index">-->
      <!--<img class="dropdown-img" [src]="item.icon" />-->
      <!--<span class="itemTitle">{{ item.title }}</span>-->
      <!--</ng-template>-->
      <!--</tag-input-dropdown>-->
      <!--</tag-input>-->
      <!--<button class="tagInputSubmit" type="submit" (click)="selectChatOptions('templates')" [disabled]="selectedHash.application_ids.length === 0">-->
      <!--<i class="fa fa-paper-plane" aria-hidden="true"></i>-->
      <!--</button>-->
    </div>


  </div>
</div>
