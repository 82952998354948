<div class="dashHeading">Prototype

  <div class="searchCards" *ngIf="!(showRecentPrototype && dataService.getCardCount('prototype') > 0)">
    <em class="icon-search"></em>
    <input type="text" placeholder="Search for Buildcard" [(ngModel)]="dataService.searchText" (keyup)="getBuildCardsWithSearch()">
    <div class="clearButton" *ngIf="dataService.searchText"><em class="icon-cancel" (click)="getBuildCardsWithSearch('true')"></em></div>
  </div>

  <button type="button" (click)="showCards(10)" *ngIf="showRecentPrototype && dataService.getCardCount('prototype') > 4">Show all </button>
</div>

<div class="dashProjectRow hideCard" infiniteScroll [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="300" (scrolled)="onScroll(dataService.getCardCount('prototype'))">
  <div class="progressCard" *ngFor="let card of (showRecentProgress ? (getPrototypeCards() | slice:0: (dataService.getCardCount('prototype') > 4 ? 4: dataService.getCardCount('prototype'))) : getPrototypeCards());let i = index;">
    <div class="cardHead">
      <div class="cardLeft">
        <h3>{{ card?.project_name }} </h3>
      </div>

      <div class="cardRight" (click)="phaseService.showSharePopup(card)">
        <!--<div class="shareIcon"><em class="icon-share"></em></div>-->
<!--        <div class="brandingbtn">-->
<!--          <button *ngIf="!card.is_advance" class="addbtn" (click)="viewAddBranding(card, true)">Add Branding</button>-->
<!--          <button *ngIf = "card.is_advance" class="viewbtn" (click)="viewAddBranding(card, false)">View Branding</button>-->
<!--        </div>-->
      </div>
    </div>

    <div class="cardMiddle">
      <div class="prototypeReady">
        <em></em>
        <p><span>Congrats!</span> Your prototype is ready!</p>
      </div>
      <div class="prototypeList">
        <ul>
          <li><span>Device Type</span>
            <div class="iconHolder">
              <div class="iconImg"><img src="../../../../../../../assets/images/monitor-icon.svg" alt="" /> <div class="iconTooltip">Web</div></div>
              <div class="iconImg"><img src="../../../../../../../assets/images/phone-icon.svg" alt="" /> <div class="iconTooltip">Mobile</div></div>
            </div>
          </li>
          <li><span>Template</span> <strong>{{ card.template_count }}</strong></li>
          <li><span>Features</span> <strong>{{ card.feature_count }}</strong></li>
        </ul>
      </div>
    </div>

    <div class="cardBottom">
      <div class="priceTag"><div>{{ card?.currency?.symbol + dataService.getLocaledPrice(phaseService.getPriceForCards(card), card.currency) }} </div></div> <button type="button" (click)="viewProgress(card)">View </button>
      <div class="clearfix"></div>
    </div>
  </div>
  <app-progress-card-load class="progressCardload" *ngFor="let dummy of ' '.repeat(loaderCount).split(''), let x = index" [loaderCount]='loaderCount'></app-progress-card-load>
</div>
<div class="dashProjectRow running" *ngIf="!(dataService.prototypeCards.length > 0) && !isApiSuccess">
  <app-progress-card-load class="progressCardload" *ngFor="let dummy of ' '.repeat(4).split(''), let x = index"></app-progress-card-load>
</div>
<div class="dashProjectRow" *ngIf="isApiSuccess && (dataService.prototypeCards.length === 0)">
  <div class="norecordHeading">No records found.</div>
</div>
