import { Component, OnInit } from '@angular/core';
import { PhaseService } from '@shared/services/phase.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ApiRequests } from '@shared/services/api-requests.service';
import { DataService } from '@shared/services/data.service';
import { AppDataService } from '@rootmodule/app-data.service';

@Component({
  selector: 'app-scheduled-call-cancellation',
  templateUrl: './scheduled-call-cancellation.component.html',
  styleUrls: ['./scheduled-call-cancellation.component.scss']
})
export class ScheduledCallCancellationComponent {
  showCancellationPopup : boolean;
  constructor(public appDataService: AppDataService,public dataService: DataService,private apiRequest: ApiRequests, public router: Router,public phaseService: PhaseService) {}



  closeCancellationPopup(){
    this.phaseService.showCancellationPopup = false;
    this.router.navigate(['home']);
  }

  cancelCall(){
    let id = this.appDataService.urlParameters.cancel_call_id
    this.apiRequest.cancelSpecCall(id).subscribe(() => {
      const cancelledSpecCall = this.dataService.specCards.find(specCall => specCall.id === id);
      cancelledSpecCall['meeting_status'] = 'cancelled';
    });
    this.phaseService.showCancellationPopup = false;
    this.router.navigate(['home']);
  }

}
