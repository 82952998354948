import { Component, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "@shared/services/data.service";
import { PhaseService } from "@shared/services/phase.service";

@Component({
  selector: "feature-maxcountpupop",
  styleUrls: ["./feature-maxcount-pupup.component.scss"],
  templateUrl: "./feature-maxcount-pupup.component.html",
})
export class FeatureMaxCountPopupComponent {
  @Output() updatePopup = new EventEmitter<boolean>(true);
  constructor(
    private dataService: DataService,
    private phaseService: PhaseService,
    private router: ActivatedRoute
  ) {}

  noButtonHandler() {
    const buildCardId = this.router.snapshot.paramMap.get("id");
    const obj = new Set(
      JSON.parse(window.sessionStorage.getItem("isShowMaxFeaturePopUp"))
    );
    if (!obj.has(buildCardId)) {
      obj.add(buildCardId);
    }
    window.sessionStorage.setItem(
      "isShowMaxFeaturePopUp",
      JSON.stringify([...obj])
    );
    this.updatePopup.next(false);
  }

  yesButtonHandler() {
    this.dataService.isRedirectToHome = true;
    this.updatePopup.next(true);
    this.phaseService.bookSpecCallClicked = true;
    this.phaseService.openMeetNoeModal();
    // window.sessionStorage.setItem("isShowMaxFeaturePopUp", "true");
  }
}
