import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { DataService } from '@shared/services/data.service';
import { PhaseService } from '@shared/services/phase.service';
import { ApiRequests } from '@shared/services/api-requests.service';
import { finalize } from 'rxjs/operators';
import {Subscriber, Subscription} from 'rxjs/Rx';
@Component({
  selector: 'app-archived-cards',
  templateUrl: './archived-cards.component.html',
  styleUrls: ['./archived-cards.component.css']
})
export class ArchivedCardsComponent  {

  @Output() pshowCards = new EventEmitter();

  @Input('showRecentArchived') showRecentArchived;
  @Input('preventClick') preventClick;

  @Input('showCompleted') showCompleted;
  @Input('showInProgress') showInProgress;
  @Input('showPaid') showPaid;
  @Input('showRecentComplete') showRecentComplete;
  @Input('showSpecCalls') showSpecCalls;
  @Input('showRecentRunning') showRecentRunning;
  @Input('showRecentProgress') showRecentProgress;
  @Input('showInvited') showInvited;
  @Input('showArchived') showArchived;
  @Input('showMoreIndex') showMoreIndex;

  public selectedCard: any;
  public loaderCount = 0;
  public showRecentInvited = true;
  public specRedirection;
  showDummyLoader: boolean;

  invitedCard: any;
  searchSubscription: Subscription;
  isApiSuccess = false;

  constructor(public dataService: DataService, public phaseService: PhaseService, public apiRequest: ApiRequests) { }



  public showCards(type) {
    this.pshowCards.emit(type);
  }

  public getHomePagedata() {
    return this.dataService.homePageData;
  }

  public getLocaledPrice(price: any) {
    return this.dataService.getLocaledPrice(price);
  }

  onScroll(cardCount: number) {
    this.showDummyLoader = true;
    if (this.showMoreIndex !== 0 && window.pageYOffset > 0) {
      if (this.dataService.loadMore) {
        if (this.dataService.isScrolled) {
          if (cardCount > 10) {
            let pageNo = 0;
            if (this.phaseService.buildCardPage[this.showMoreIndex]) {
              pageNo = (this.phaseService.buildCardPage[this.showMoreIndex] > 0) ? this.phaseService.buildCardPage[this.showMoreIndex] : 0;
            }
            this.loaderCount = (((pageNo + 1) * 10) >= cardCount) ? 0 : (4 - (((pageNo + 1) * 10) % 4));
          }
          this.dataService.isScrolled = false;
          this.phaseService.buildCardPage[this.showMoreIndex]++;
          this.apiRequest.fetchBuildCards(this.phaseService.buildCardPage[this.showMoreIndex],
            this.phaseService.perPageCardCount, this.phaseService.currentSelectedBuildCardStatus).pipe(
              finalize(() => {
                this.loaderCount = 0;
              }))
            .subscribe(
              () => {
                this.dataService.isScrolled = true;
              }
            );
        }
      }
    }
  }

  public getArchivedCards() {
    return this.dataService.archivedCards;
  }

  getBuildCardsWithSearch(clearSearch?) {
    let perPage;
    if (clearSearch) {
      this.dataService.searchText = '';
    } else {
      perPage = this.phaseService.perPageCardCount;
    }
    this.phaseService.buildCardPage[this.showMoreIndex] = 0;
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    this.isApiSuccess = false;
    this.dataService.archivedCards = [];
    this.searchSubscription = this.apiRequest.fetchBuildCards(0, perPage, this.phaseService.currentSelectedBuildCardStatus).pipe(
      finalize(() => {
        this.loaderCount = 0;
      }))
      .subscribe(
        () => {
          this.dataService.isScrolled = true;
          this.isApiSuccess = true;
          this.dataService.showHideLoader(false);
        }, error => {
          this.dataService.showHideLoader(false);
        }
      );
  }

  unArchiveCard(cardId) {
    this.dataService.showHideLoader(true);
    this.apiRequest.unArchiveCard(cardId).subscribe((resp) => {
      this.getBuildCardsWithSearch('true');
    });
  }
}
