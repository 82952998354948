<!----popup for rename project---->
<div class="commonPopUp" [ngClass]="{active: dataService.renameInProgress}">
  <div class="popOverlay"></div>
  <div class="popHolder renamePopup">
    <div class="closePopUp" (click)="closeRenamePopup(); renameForm.reset();"><span class="icon-cancel"></span></div>
    <form role="form" #renameForm>
      <h3>Rename the Buildcard<sup class="tm">®</sup> name</h3>
      <div class="inputRow">
        <input type="text" placeholder="Your Buildcard&#8482; Name" #rename="ngModel" name="rename" [(ngModel)]="newName"
          maxlength="30" required>
        <div class="errorMsg" *ngIf="rename.errors && (rename.dirty || rename.touched)">Please enter valid Buildcard<sup class="tm">®</sup> name
        </div>
      </div>
      <div class="footerButton">
        <button class="cancleButton" (click)="closeRenamePopup(); renameForm.reset();">Cancel</button>
        <button class="doneButton" (click)="renameCard(); renameForm.reset();"
          disabled="{{((!newName || !newName.trim()) || rename != null && rename.errors && (rename.dirty || rename.touched))}}">
          Done <img *ngIf="preventClick" src="./../../../assets/images/buttonLoader.gif" alt="" /></button>
      </div>
    </form>
  </div>
</div>
<!----popup for delete confirmation---->
<div class="commonPopUp" [ngClass]="{active: dataService.cardToDelete}">
  <div class="popOverlay"></div>
  <div class="popHolder confirmPopup">
    <div class="closePopUp" (click)="closeDeletePopup()"><span class="icon-cancel"></span></div>
    <h3>Are you sure you want to archive this card.?</h3>
    <div class="footerButton">
      <button class="cancleButton" (click)="closeDeletePopup()">Cancel</button>
      <button class="doneButton" (click)="deleteCard()">
        Yes, archive it!
        <img *ngIf="preventClick" src="./../../../assets/images/buttonLoader.gif" alt="" /></button>
    </div>
  </div>
</div>
<div class="commonPopUp" [ngClass]="{'active': dataService.isUserProfileSelected}">
  <div class="popOverlay"></div>
  <div class="popHolder companyPopup">
    <h3>Are you sure you want to proceed with these selections? <span>You won't be able to make the changes to company
        info again.</span></h3>
    <ul>
      <li *ngFor="let profile of selectedProfileList">{{ profile.title }}</li>
    </ul>
    <div class="footerButton">
      <button class="cancleButton" (click)="dataService.isUserProfileSelected = false">Edit info</button>
      <button class="doneButton" (click)="updateUserProfile(getUser(), selectedProfileList)">Sure <img
          *ngIf="updateLoader" src="./../../../assets/images/buttonLoader.gif" alt="" /></button>
    </div>
  </div>
</div>

<div class="commonPopUp" [ngClass]="{active : phaseService.showOwnershipConfirmationDashboard}">
  <div class="popOverlay"></div>
  <div class="popHolder ownershipConfirmPopup">
    <div class="closePopUp" (click)="phaseService.showHideRemoveAllPopup()"><span class="icon-cancel"></span></div>
    <!-- <h3 *ngIf="phaseService.ownershipTranferMessage">Ownership Transferred! <br> You can now view this card under your
      Invited cards section.</h3>
    <h3 *ngIf="!phaseService.ownershipTranferMessage">Invitation has been sent successfully. <br> Ownership would be
      transferred when Invitation is accepted by the user!</h3> -->
    <h2 *ngIf="phaseService.ownershipTranferMessage || phaseService.ownershipTransferInvitationSentMessage"><em class="icon-tick-inside"></em> <span>Awesome</span></h2>
    <h3 *ngIf="phaseService.ownershipTransferUserNotAvailableMessage || phaseService.ownershipTransferCurrencyMismatchMessage"><em>X</em> <span>Oh no!</span></h3>
    <p *ngIf="phaseService.ownershipTranferMessage">Ownership of this Buildcard<sup class="tm light">®</sup> has been successfully transferred. </p>
    <p *ngIf="phaseService.ownershipTransferUserNotAvailableMessage">It seems <strong>{{phaseService.emailToEnterForOwnershipTransfer}}</strong> is not part of our Builder family. </p>
    <p *ngIf="phaseService.ownershipTransferCurrencyMismatchMessage">Unable to make this transfer, make sure that your selected currency matches with the receivers currency. </p>
    <p *ngIf="phaseService.ownershipTransferInvitationSentMessage">Your invitation has been sent successfully. You will be able to transfer the ownership once your invitation gets accepted. </p>

    <div *ngIf="phaseService.ownershipTranferMessage || phaseService.ownershipTransferCurrencyMismatchMessage" class="footerButton">
      <button class="doneButton" (click)="phaseService.showHideRemoveAllPopup()">Okay</button>
    </div>

    <div *ngIf="!phaseService.ownershipTransferUserNotAvailableMessage && phaseService.ownershipTransferInvitationSentMessage" class="footerButton">
      <button class="doneButton" (click)="phaseService.showHideRemoveAllPopup()">Back to Buildcard<sup class="tm">®</sup></button>
    </div>

    <div *ngIf="phaseService.ownershipTransferUserNotAvailableMessage" class="footerButton">
      <button class="doneButton" (click)="phaseService.sendInvitationForOwnershipTransfer()">Send Invitation</button>
      <button class="retryButton" (click)="phaseService.retryOwnershipTransfer()">Retry</button>
    </div>

  </div>
</div>
