<div class="commonPopUp active">
  <div class="popOverlay"></div>
  <div class="popHolder duplicareCard">
    <div class="closePopUp" (click)="closeModal()"><span class="icon-cancel"></span></div>
    <h3>Are you creating this Build card for same Customer?</h3>
    <div class="footerButton">
      <button class="doneButton" (click)="cloneBuildCard(true)">Yes, I am</button>
      <button class="cancleButton" (click)="cloneBuildCard(false)">No, I am not</button>
    </div>
  </div>
</div>
