<div class="dashHeading" *ngIf="dataService.getCardCount('completed') > 0">Completed {{ (dataService.getCardCount('completed') > 1) ? 'Cards' : 'Card' }}

  <div class="searchCards" *ngIf="!(showRecentComplete && dataService.getCardCount('completed') > 0)">
    <em class="icon-search"></em>
    <input type="text" placeholder="Search for Buildcard" [(ngModel)]="dataService.searchText" (keyup)="getBuildCardsWithSearch()">
    <div class="clearButton" *ngIf="dataService.searchText"><em class="icon-cancel" (click)="getBuildCardsWithSearch('true')"></em></div>
  </div>

  <button type="button" (click)="showCards(1)"
          *ngIf="showRecentComplete && dataService.getCardCount('completed') > 4"
  >Show all
  </button>
</div>

<div class="dashProjectRow" [ngClass]="{'hideCard': showRecentComplete}" infiniteScroll
     [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="100"
     (scrolled)="onScroll(dataService.getCardCount('completed'))">
  <div class="progressCard" [ngClass]="{'updated': isBuildCardEditedAndHighlighted(card)}" *ngFor="let card of (showRecentProgress ? (getCompletedCards() | slice:0: (dataService.getCardCount('completed') > 4 ? 4: dataService.getCardCount('completed'))) : getCompletedCards());let i = index;">
    <div class="cardHead">
      <div class="cardLeft  ">
        <h3>{{card.project_name}}
        <div class="cardAnimationIcon" *ngIf="isBuildCardEditedAndHighlighted(card)">
          <div class="pulse"></div>
          <div class="dot"></div>
        </div>
        </h3>
        <h4>Last edited: {{card.updated_at}}</h4>
      </div>
      <div class="cardRight">
        <div class="cardMore" *ngIf="allowedForInvite(card)">
          <em class="icon-more"></em>
          <div class="cardDropDown">
            <ul>
              <li (click)="cloneBuildCard(card.uniq_code, card.currency)">Duplicate card</li>
              <li (click)="phaseService.openOwnerTranferPopup(card)" (click)="phaseService.openOwnerTranferPopup(card)" *ngIf="card.owner && phaseService.isStatusBeforePayment(card)">Transfer ownership</li>
              <li (click)="phaseService.showSharePopup(card)" *ngIf="card.owner" (click)="phaseService.showSharePopup(card)">Share with others</li>
              <li (click)="phaseService.showInvitePopup(card)" *ngIf="card.owner" (click)="phaseService.showInvitePopup(card)">Invite others</li>
              <li (click)="showHideInvitedUserPopup(true, card)" *ngIf="card.invites && card.invites.length > 0">Invited Users</li>
              <li (click)="showDeleteConfirmation(card)">Archive Card</li>
              <li (click)="processNDAFlow(card)" *ngIf="!card.nda_signed">Sign an NDA</li>
              <li *ngIf="card.nda_signed">NDA Signed</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="WaitingApproval" *ngIf="!card.is_approved">Waiting for approval</div>
    </div>

    <div class="cardMiddle" *ngIf="!card.offline_payment_initiated">
      <div class="completeDetail">
        <div class="completeRow">
          <div class="completeBox">
            <h3>{{card.template_count > 1 ? 'Templates' : 'Template'}} <span>{{card.template_count}}</span></h3>
          </div>
          <div class="completeBox">
            <h3>Features <span>{{card.feature_count}}</span></h3>
          </div>
        </div>
        <div class="completeRow">
          <h4>
            <div class="team-dash-country">Team <span>{{card.team? card.team:""}}</span></div>
          </h4>
          <h4>
            <div>Duration
              <ng-container *ngIf="card.frequency === 'monthly'; else showWeekly">
                 <span>{{card.installment_counts.monthly}} {{ card.installment_counts.monthly > 1 ? 'months' : 'month'}}
                ({{(card.weeks + (card.change_week_status ? card.change_week : -card.change_week))}}
              {{ (card.weeks + (card.change_week_status ? card.change_week : -card.change_week)) > 1 ? 'weeks' : 'week'}})</span>
              </ng-container>
              <ng-template #showWeekly>
                <span>{{(card.weeks + (card.change_week_status ? card.change_week : -card.change_week))}}
              {{ (card.weeks + (card.change_week_status ? card.change_week : -card.change_week)) > 1 ? 'weeks' : 'week'}}</span>
              </ng-template>
              </div>
            </h4>
        </div>
      </div>
      <div class="projectPhases">
        <ul>
          <li *ngFor="let phase of card.build_card_phases">
            <div class="phaseTitle">{{ setPhaseName(phase, card) }}</div>
            <div class="phaseDetail" *ngIf="!phase.is_tailor_made && phase.platforms?.length > 0">
              <div class="phaseIcon" *ngFor="let platform of phase.platforms">
                <img [src]="platform.icon" alt="" />
                <div class="phaseIconTooltip">{{platform.title}}</div>
              </div>
            </div>

            <div class="phaseDetail" *ngIf="phase.is_tailor_made &&  phase.platforms?.length > 1">
              <div class="morePhase" >+ {{ phase.platforms?.length - 1 }}
                <div class="platformTooltip">
                  <h5>{{ phase.platforms?.length || 0 }} Platform Selected</h5>
                  <h6 *ngFor="let platform of phase.platforms"><em class="icon-right"></em>{{ platform.title }}</h6>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="builderCare" *ngIf="card.is_under_support || card.cloud_opted || card.donation_opted">
        <span>Includes</span>
        <span>
          <img src="../../../../../../../assets/images/BuilderCare-icon.svg" *ngIf=" card.is_under_support" alt="" />
          <img src="../../../../../../../assets/images/BuilderCloud-icon.svg" *ngIf=" card.cloud_opted" alt="" />
        </span>
        <span *ngIf="card.donation_opted"><em class="icon-right"></em>
          Donation fund - {{card?.currency?.symbol}}{{card.donation_amount}}
        </span>
      </div>

    </div>

    <div class="cardMiddle" *ngIf="card.offline_payment_initiated">
      <div class="cardPayStatus recieved">
        <div class="payInitiaedIcon">
          <img src="../../../../../../../assets/images/Payment-initiaed.svg" alt="" />
        </div>
        <div class="headline">Payment initiated.</div>
        <p *ngIf="!card.scheduled_date">Our team is verifying your payment.</p>
        <div *ngIf="card.scheduled_date">
          <p> Kick off scheduled for:</p>
          <p> Date- {{card.scheduled_date}}</p>
          <p *ngIf="card.scheduled_call_start"> Time- {{card.scheduled_call_start}}</p>
        </div>

        <button type="button" class="paymentStatusButton" (click)="showPaymentStatus(card)" *ngIf="isStatusInProgressNoDevState(card)">
          Payment details</button>
      </div>
    </div>
    <div class="cardBottom">
      <div class="priceTag" *ngIf="!card.owner">
        <div>{{card?.currency?.symbol}}{{getLocaledPrice((
            getDiscountedprice(card)
            | number: '1.2-2'), card.currency)}}
        </div>
        <div class="strick" *ngIf="getDiscountedprice(card) < getprice(card) && card.change_amount === 0">
          {{getLocaledPrice((getprice(card) | number: '1.2-2'), card.currency)}}
        </div>
      </div>
      <div class="priceTag" *ngIf="card.owner">
        <div>{{card?.currency?.symbol}}{{getLocaledPrice((
          getDiscountedprice(card)
          | number : '1.2-2'), card.currency)}}
        </div>
        <div class="strick" *ngIf="getDiscountedprice(card) < getprice(card) && card.change_amount === 0">
          {{getLocaledPrice(((getprice(card))| number : '1.2-2'), card.currency)}}
        </div>
      </div>
      <!-- <button type="button" class="forpayment" (click)="showPaymentStatus(card)">Payment Status</button> -->
      <button [disabled]="card.offline_payment_initiated" (click)="openBuildCard(card)">View <img
        *ngIf="preventClick && card && selectedCard && card.id === selectedCard.id"
        src="./../../../assets/images/buttonLoader.gif"/></button>
        <div class="viewButtonTooltip" *ngIf="card.offline_payment_initiated">You will be able to access your Buildcard once our finance team has verified your payment</div>
        <div class="clearfix"></div>
      <!--<div class="info-blue" *ngIf="card.build_card_phases && card.build_card_phases.length > 0">
        <p>Get the {{getCheapestPhaseName(card)}} ready in just {{getHomePagedata().currency.symbol}}{{getCheapestPhasePrice(card)}}. Click <span (click)="proceedForPhasePayment(card)">here</span>.</p>
      </div>-->
    </div>
  </div>

  <app-progress-card-load class="progressCardload" *ngFor="let dummy of ' '.repeat(loaderCount).split(''), let x = index" [loaderCount]='loaderCount' ></app-progress-card-load>

</div>
<div class="dashProjectRow" *ngIf="dataService.getCardCount('completed') > 0 && !(dataService.completedCards.length > 0) && !isApiSuccess">
  <app-progress-card-load class="progressCardload" *ngFor="let dummy of ' '.repeat(4).split(''), let x = index"></app-progress-card-load>
</div>

<div class="dashProjectRow" *ngIf="isApiSuccess && (dataService.completedCards.length === 0)">
  <div class="norecordHeading">No records found.</div>
</div>

<app-duplicate-card-modal *ngIf="commonService.isDuplicateCardFlow"></app-duplicate-card-modal>
