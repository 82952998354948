<div class="container" *ngIf="showHtml">
  <div class="row">
    <div class="paymentFlow">
      <div class="confirmPayment" *ngIf="getPaymentData()?.pending_installment?.type === 'deposit' || isSpecing">
        <img src="./../../../../assets/images/confirmPayment.jpg" alt=""/>
        <h3>
          {{getCurrencySymbol()}}{{getLocaledPrice(getInstallmentWithTax(isSpecing ? getPaymentData()?.pending_installment : getDeposit())
          | number : '1.2-2')}} <span>Amount to be paid</span></h3>

        <h4 *ngIf="!isSpecing">Security Deposit</h4>

        <h4 *ngIf="isSpecing">Spec'ing</h4>

        <p>By clicking proceed to payment, you will be redirected to Builder payment gateway.</p>

        <p *ngIf="isSpecing">If you decide to go ahead with the Buildcard<sup class="tm light">®</sup> development with us, the complete spec'ing cost
          would be refunded.</p>
      </div>

      <div class="confirmPayment" *ngIf="getPaymentData()?.pending_installment?.type !== 'deposit' && !isSpecing">
        <img src="./../../../../assets/images/confirmPayment.jpg" alt=""/>
        <h3>
          {{getCurrencySymbol()}}{{getLocaledPrice(getInstallmentWithTax(getPaymentData()?.pending_installment)
          | number : '1.2-2')}} <span>Amount to be paid</span></h3>

        <ul>
          <li><span>Total Amount</span>
            {{getCurrencySymbol()}}{{getLocaledPrice((getPaymentData()?.pending_installment?.installment_amount)
              | number : '1.2-2')}}
          </li>
          <li><span>Tax ({{getPaymentData()?.pending_installment?.tax}}% on total amount)</span>
            {{getPaymentData()?.pending_installment?.tax_amount}}
          </li>
        </ul>

        <p>By clicking proceed to payment, you will be redirected to Builder payment gateway.</p>

        <p *ngIf="isSpecing">If you decide to go ahead with the Buildcard<sup class="tm light">®</sup> development with us, the complete spec'ing cost
          would be refunded.</p>

      </div>

      <specing-payment [billingInfo]="getBillingInfo()" [buildCardInstallment]="true"
                       [paymentAmount]="getPaymentData()?.pending_installment?.installment_amount"
                       [paymentId]="getPaymentData()?.pending_installment?.id">
      </specing-payment>

    </div>
  </div>
</div>
