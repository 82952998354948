<div class="platformBox">
  <div class="titleWrap">
    <div class="titleContainer">
      <h3>Decide your deliverables</h3>

      <div class="contentText" *ngIf="!phaseService.checkIfOnlyRapidPrototypeSelected() || !dataService.homePageData.build_phases[2].is_tailor_made">
        <p>Select platform for your product</p>

        <div class="infoBox">
          <div class="clickArea" (click)="platformInfo()"><img src="../../../../../assets/images/info_blue.png" /></div>
          <div class="infoContent" [ngClass]="{'active' : platformInfoPopup}">
            <div class="closeIcon" (click)="platformInfo()"><em class="icon-cancel"></em></div>
            <h4>Devices</h4>
            <p>Our apps are designed for the last 3 versions of iOS & Android (at the time your project kicks off). We test on flagship Apple, Samsung & Google devices.<br> (Need testing for a specific device? Ask your delivery team.)</p>
            <br>

            <h4>Browsers</h4>
            <p>For web apps, our testing process covers the last 3 major versions of these browsers:</p>
            <ol>
              <li>Chrome</li>
              <li>Safari</li>
              <li>Firefox</li>
              <li>Edge</li>
            </ol>

            <h4>Responsiveness</h4>
            <ol>
              <li>Desktop displays: 	(1280 x 720) to (1920 x 1080)</li>
              <li>Mobile displays: 	(360 x 640) to (414 x 896)</li>
              <li>Tablet displays: 	(601 x 962) to (1280 x 800)</li>
            </ol>
          </div>
        </div>
      </div>

      <div class="contentText" *ngIf="phaseService.checkIfOnlyRapidPrototypeSelected() && dataService.homePageData.build_phases[2].is_tailor_made">
        <p>Select Devices for your product</p>
      </div>


    </div>

    <div class="dateContainer" *ngIf="!phaseService.isPlatformRequest">
      <h4>Expected kick-off date
        <span class="infoIcon">
          <em class="icon-infonew" (click)="kickOffDateInfoToggle()"></em>
          <span class="infoOverlay" *ngIf="kickOffDateInfo" (click)="kickOffDateInfoToggle()"></span>
          <div class="infoContent" [ngClass]="{'active': kickOffDateInfo}">
            <span class="icon-cancel" (click)="kickOffDateInfoToggle()"></span>
            <p>Essential meeting that kicks off your project. We set everyone's roles, understand your objectives and make sure the app will be exactly how you want it.</p>
          </div>
        </span>
      </h4>
      <div class="dropdownWrap">
        <label (click)="toggleCalendarDropdown()">
          {{getSelectedDate() | normalize}} {{isTodaySelected ? '(Today)':''}}
          <span class="carrotIcon" [ngClass]="{'down': !showCalendar, 'up': showCalendar}"></span>
        </label>
        <span class="calendarOverlay" *ngIf="showCalendar" (click)="toggleCalendarDropdown()"></span>
        <div class="dropbox" [ngClass]="{'active': showCalendar}">
          <input angular-mydatepicker type="hidden" [(ngModel)]="model" [options]="myDpOptions"
            (dateChanged)="onDateChanged($event)" />
        </div>
      </div>
    </div>
  </div>

  <div class="platformListBox"
       [ngClass]="{ 'custom-disabled': (phaseService.isPlatformRequest && phaseService.isAdvancedVersion) }">
    <ul [ngClass]="{'disableBox': phaseService.isAdvancedVersion}"
      *ngIf="!phaseService.checkIfOnlyRapidPrototypeSelected() || !dataService.homePageData.build_phases[2].is_tailor_made">
      <li *ngFor="let platform of getSelectedPlatforms()">
        <div class="openplatformClick" (click)="openPlatformPopup()">
          <img src="{{ platform.icon }}" [alt]="platform.title" />
        </div>
      </li>
      <li class="moreplatform" (click)="openPlatformPopup()">
        <div class="openplatformClick">
          <em class="icon-plus"></em>
        </div>
      </li>
    </ul>

    <ul
      *ngIf="phaseService.checkIfOnlyRapidPrototypeSelected() && dataService.homePageData.build_phases[2].is_tailor_made"
      class="cursornone">
      <li *ngFor="let platform of getSelectedPlatforms()">
        <img src="{{ platform.icon }}" [alt]="platform.title" />
      </li>
      <li class="moreplatform custom-disabled-txt">
        <em class="icon-plus"></em>
      </li>
    </ul>
  </div>

</div>

<platform-list class="forMobileNew" *ngIf="showPlatformPopup" [generalView]="true"
  (closeCallback)="closePlatformPopup()" [isFromPhase]="false"></platform-list>
