import {Component, Input, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import {DragScrollComponent} from 'ngx-drag-scroll';
import {OffersModel} from '@models/offer-model/offer.model';
import {Angulartics2Segment} from 'angulartics2/segment';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'app-offers',
  styleUrls: ['offers.component.scss'],
  templateUrl: 'offers.component.html'
})

export class OffersComponent {
  showLoader = true;
  @Input('offers') offers;
  @Input('offersSection') offersSection;
  leftNavDisabled = false;
  rightNavDisabled = false;
  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;

  constructor( public analyticsSegment: Angulartics2Segment, public router: Router, public dataService: DataService, public phaseService: PhaseService) {
  }

  primarySections: Array<OffersModel>;

  ngOnInit() {
    if (this.offers.primary_section) {
      this.primarySections = this.offers.primary_section;
      this.phaseService.offersArr = this.offers.primary_section;
      // this.sortByPosition()
      if (this.primarySections.length > 0) {
        this.phaseService.hideOffers = false;
        this.showLoader = false;
      } else {
        this.phaseService.hideOffers = true;
        this.showLoader = false;
      }
    }

    this.phaseService.promotionCurrencySubject.subscribe(
      data => {
        if (data) {
          if (this.dataService.user) {
            if (this.dataService.user.currency && this.primarySections) {
              this.primarySections = this.primarySections.filter(offer => offer.currency.id === this.dataService.user.currency.id);
            }
            if (this.primarySections && this.primarySections.length > 0) {
              this.phaseService.hideOffers = false;
            } else {
              this.phaseService.hideOffers = true;
            }
          }
        }
      }
    );
  }

  ngOnChanges() {
    if (this.offersSection) {
      this.primarySections = this.offersSection.section_details;
      this.phaseService.offersArr = this.primarySections;
      // this.sortByPosition()
      if (this.primarySections.length > 0) {
        this.showLoader = false;
      }
    }
  }

  // public sortByPosition() {
  //   this.primarySections.sort(function(a, b){
  //     var keyA = new Date(a.position),
  //         keyB = new Date(b.position);
  //     // Compare the 2 dates
  //     if(keyA < keyB) return -1;
  //     if(keyA > keyB) return 1;
  //     return 0;
  // });
  // }

  redirectToOffer(section) {
    if (section.promo_url) {
      if (section.promo_url.indexOf('#') > -1) {
        const url = section.promo_url.split('#');
        window.location.href = window.location.origin + '/#' + url[1];
      } else {
        const url = section.promo_url.split('?');
        window.location.href = window.location.origin + '?' + url[1];
      }
    } else {
      window.location.href = window.location.origin + '?promotion=' + section.code;
    }
  }

  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }


}
