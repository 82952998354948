<!-- *ngIf="dataService.isEditBuildCardAfterSecurityPaid() || phaseService.isFeatureRequest"-->
<ng-container>
  <div class="container paymentContainer">
    <div class="row" [hidden]="!phaseService.isInstallmentsAPIResolved">
      <div class="col-xs-12 col-md-4 col-sm-12 pull-right">
        <app-payment-summary></app-payment-summary>
      </div>

      <div class="col-xs-12 col-md-7 col-sm-12">
        <div class="paymentPlan">
          <ng-container *ngIf="phaseService.isRentalBuildCard()">
            <h1> Payment plan</h1>
          </ng-container>
          <ng-container *ngIf="phaseService.isEditFeatureRequest() && !phaseService.isRentalBuildCard()">
            <h1>Your updated payment plan</h1>
          </ng-container>
          <ng-template *ngIf="!phaseService.isEditFeatureRequest() && !phaseService.isRentalBuildCard()">
            <h1>Select your payment plan</h1>
          </ng-template>

          <div class="billingCycle">
            <h2 class="pageTitle" *ngIf = "!phaseService.isEditFeatureRequest()">
              {{ setPaymentPlanHeading() }}
            </h2>
            <h3 *ngIf="!phaseService.isRentalBuildCard()">Billing Cycle</h3>

            <ng-container *ngIf="!commonService.startTwoPayFlow && !paymentService.startCapchaseFlow">
              <ng-container *ngIf="dataService.showUpfront">

              <div class="paymentPlanWrapper">
                <div class="contentBox">
                  <div class="discountHead" *ngIf="!phaseService.isPlatformRequest">
                    <div class="discountText" *ngIf="!paymentService.checkIfOnlyProfessionalPrototype() && (getUpfrontDiscountPercentage() !== null) && (getUpfrontDiscountPercentage() !== 0)">Pay upfront and get a <span>
                      {{ getUpfrontDiscountPercentage() }}% discount
                    </span> on your price</div>
                    <div class="discountText" *ngIf="!paymentService.checkIfOnlyProfessionalPrototype() && ((getUpfrontDiscountPercentage() === null) || (getUpfrontDiscountPercentage() === 0))"> Pay upfront for hassle-free billing </div>
                    <div class="discountText" *ngIf="paymentService.checkIfOnlyProfessionalPrototype()">Pay upfront</div>
                    <div class="discountBadge"><img src="../../../../../assets/images/discount-bubble.svg" alt="Discount"/></div>
                  </div>

                    <div class="descriptionBox">
                      <div class="right">
                        <div class="titleContainer">
                          <h3>You pay</h3>
                        </div>
                        <div class="upfrontCostContainer">
                          <div class="totalAmount" *ngIf="isStrikedOutUpfrontCost()">
                            <ng-container *ngIf="!phaseService.isEditFeatureRequest()">
                              <ng-container *ngIf="!dataService.modifiedBuildCardData">
                                {{getCurrencySymbol()}}{{getLocaledPrice(getTotalWithTax() | number : '1.2-2')}}
                              </ng-container>
                              <ng-container *ngIf="dataService.modifiedBuildCardData">
                                {{getCurrencySymbol()}}{{getLocaledPrice(getTotalUpdatedPriceWithTax() | number : '1.2-2')}}
                              </ng-container>
                            </ng-container>
                            <ng-container *ngIf="phaseService.isEditFeatureRequest()">
                              {{getCurrencySymbol()}}{{getLocaledPrice(getTaxedPrice(phaseService.totalDiscountedPriceWithCare) | number : '1.2-2')}}
                            </ng-container>
                          </div>
                          <div class="amount" *ngIf="!phaseService.isEditFeatureRequest()">
                            {{getCurrencySymbol()}}{{getLocaledPrice(getInstallmentWithTax(phaseService.upfrontInstallment) | number : '1.2-2')}}
                          </div>
                          <div class="amount" *ngIf="phaseService.isEditFeatureRequest()">
                            {{getCurrencySymbol()}}{{getLocaledPrice(getTotalPrice(phaseService.installments) | number : '1.2-2')}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="explorePaymentOption" *ngIf="!(dataService.isEditBuildCardAfterSecurityPaid() || phaseService.isEditFeatureRequest())">
                    <div class="linkTextWrap" (click)="openOtherPlans()">
                      You don't need to pay it all now, <span class="linkText">explore our payment options</span>
                    </div>
                  </div>
                </div>
                <!-- payment plan content close -->
              </ng-container>
              <ng-container *ngIf="dataService.showWeekly || dataService.showMonthly">

              <div class="totalCardPayment" *ngIf="dataService.buildCardData?.subscription_flow && dataService.isUnpaidCard()">
                <div class="totalCostDesc" *ngIf="!paymentService.checkIfOnlyProfessionalPrototype()">
                  For hassle-free billing, <span class="linkText" (click)="updateBillingFrequency()">pay upfront.</span>
                </div>
                <div class="totalCostDesc" *ngIf="paymentService.checkIfOnlyProfessionalPrototype()">
                  <span class="linkText" (click)="updateBillingFrequency()">Pay upfront.</span>
                </div>
              </div>

                <div class="billingCycleMainTab">
                  <!-- Powered By : Two -->
                  <div class="poweredBy" style="display:none;">
                    <span class="text">Powered By</span>
                    <img src="../../../../../assets/images/logo-Two.svg" alt="Two"/>
                  </div>
                  <form action="">
                    <ul class="billingCycleMainTabs" *ngIf="!phaseService.isRentalBuildCard()">
                      <li [ngClass]="{'active': dataService.showWeekly, 'custom-disabled': shouldDisableWeekly()}" (click)="showBillingCycleTab(1)">
                        <input type="radio" class="customRadioBtn" name="radioBilling" [checked]="dataService.showWeekly"/>
                        <label for="radioBilling">Weekly Billing</label></li>
                      <li [ngClass]="{'active': dataService.showMonthly, 'custom-disabled': disableMonthly}" (click)="showBillingCycleTab(3)">
                        <input type="radio" class="customRadioBtn" name="radioBilling" [checked]="dataService.showMonthly"/>
                        <label for="radioBilling">Monthly Billing</label></li>
                    </ul>
                  </form>
                </div>

                <div class="specialMsg" *ngIf="!phaseService.isFeatureRequest && ((dataService.manualPromotion?.type === 'SpecialEventPromotion') || (dataService.buildCardData?.promotion?.type === 'special_event'))">
                  <img src="../../../../../assets/images/smile4.png" alt="" />
                  Congratulations you have successfully entered the {{ dataService.manualPromotion?.code }} promo code.
                  The cost of your app will now be billed in
                  {{ dataService.showMonthly ? (math.ceil(getBuildCardData().payment_weeks / 4) + ' monthly') : (getBuildCardData().payment_weeks + ' weekly') }}
                  installments.
                  If you wish to not proceed with this promo code, you can skip using it.
                </div>

                <div class="billingCycleTabel">
                  <h3 [ngClass]="{margin10: phaseService.isPlatformRequest}">Installment Details</h3>
                  <div class="installmentTextForPlatformAddition" *ngIf="phaseService.isPlatformRequest">
                    (This will be added to your existing installments)
                  </div>
                  <ng-container *ngIf="!dataService.buildCardData.subscription_flow">
                    <div class="installmentTable threeColumn" *ngIf="dataService.showWeekly">
                      <div class="tableHeader">
                        <div class="tableRow">
                          <div class="tableColumn">Installment</div>
                          <div class="tableColumn">Date</div>
                          <div class="tableColumn">Amount</div>
                        </div>
                      </div>

                      <div class="tableBody" *ngIf="phaseService.isEditFeatureRequest()">
                        <div class="tableRow" *ngFor="let installment of getInstallments(); let i = index">
                          <div class="tableColumn">
                            <!-- <button type="button" class="expandButton"><em class="icon-next"></em></button> -->
                            <div class="installmentNo">
                              <h4>{{installment.installment_no}}.
                                <span [attr.data-type]="installment.type" [attr.data-id]="installment.id" > Installment </span>
                                <!-- <span *ngIf="installment.type == 'support'"> Support </span>  -->
                                <!-- <div class="infoTooltip" *ngIf="installment.care_included">
                                  <em class="icon-info-circle"></em>
                                  <div class="tooltipText">
                                    Builder care has started after the first shipment date i.e. {{dataService.proposalInstallments.first_shipment_date | date :  'dd-MMM-y'}}
                                  </div>
                                </div> -->
                              </h4>
                              <!-- <p>Studio store</p> -->
                            </div>
                          </div>
                          <div class="tableColumn">{{installment.due_date ? (installment.due_date | date :  'dd-MMM-y') : '-' }}</div>
                          <div class="tableColumn">{{getCurrencySymbol()}}{{getLocaledPrice(getInstallmentWithTax(installment) | number :
                            '1.2-2')}}</div>
                        </div>
                      </div>

                      <div class="tableBody"  *ngIf="!phaseService.isEditFeatureRequest()">
                        <div class="tableRow" *ngFor="let installment of dataService.proposalInstallments?.weekly; let i = index">
                          <div class="tableColumn">
                            <!-- <button type="button" class="expandButton"><em class="icon-next"></em></button> -->
                            <div class="installmentNo">
                              <h4>{{installment.installment_no}}.
                                <span [attr.data-type]="installment.type" [attr.data-id]="installment.id"> Installment </span>
                                <!-- <span *ngIf="installment.type == 'support'"> Support </span>  -->
                                <div class="infoTooltip" *ngIf="!paymentService.checkIfOnlyProfessionalPrototype() && (isShowToolTip(dataService.proposalInstallments.weekly) === i)">
                                  <em class="icon-info-circle"></em>
                                  <div class="tooltipText">
                                    {{selectedCare()}} will start after the {{getTootipShipmentText()}} date i.e., {{getEstimatedDeliveryDate() | date :  'dd-MMM-y'}}
                                  </div>
                                </div>
                                <div class="infoTooltip" *ngIf="dataService.proposalInstallments.weekly.length-1 === i">
                                  <em class="icon-info-circle"></em>
                                  <div class="tooltipText">
                                    Price will decrease if your project finishes ahead of schedule
                                  </div>
                                </div>
                              </h4>
                              <!-- <p>Studio store</p> -->
                            </div>
                          </div>
                          <div class="tableColumn">{{installment.due_date ? (installment.due_date | date :  'dd-MMM-y') : '-' }}</div>
                          <div class="tableColumn">{{getCurrencySymbol()}}{{getLocaledPrice(getInstallmentAndSupportWithTax(installment) | number :
                            '1.2-2')}}</div>
                        </div>
                      </div>

                      <div class="tableFooter" *ngIf="phaseService.isEditFeatureRequest()">
                        <div class="totalBox">Total</div>
                        <div class="totalBox">{{getCurrencySymbol()}}{{getLocaledPrice(getTotalPrice(phaseService.installments) | number : '1.2-2')}}</div>
                      </div>

                      <div class="tableFooter"  *ngIf="!phaseService.isEditFeatureRequest()">
                        <div class="totalBox">Total</div>
                        <div class="totalBox">
                          {{getCurrencySymbol()}} {{getLocaledPrice(getTotalPrice(dataService.proposalInstallments?.weekly) | number : '1.2-2')}}
                        </div>
                      </div>

                    </div>

                    <div class="installmentTable threeColumn" *ngIf="dataService.showMonthly">
                      <div class="tableHeader">
                        <div class="tableRow">
                          <div class="tableColumn">Installment</div>
                          <div class="tableColumn">Date</div>
                          <div class="tableColumn">Amount</div>
                        </div>
                      </div>

                      <div class="tableBody" *ngIf="phaseService.isEditFeatureRequest();">
                        <div class="tableRow" *ngFor="let installment of getInstallments(); let i = index">
                          <div class="tableColumn">
                            <!-- <button type="button" class="expandButton"><em class="icon-next"></em></button> -->
                            <div class="installmentNo">
                              <h4>{{installment.installment_no}}.
                                <span [attr.data-type]="installment.type" [attr.data-id]="installment.id"> Installment </span>
                                <!-- <span *ngIf="installment.type == 'support'"> Support </span>   -->
                                <!-- <div class="infoTooltip" *ngIf="installment.care_included">
                                  <em class="icon-info-circle"></em>
                                  <div class="tooltipText">
                                    Builder care has started after the first shipment date i.e. {{dataService.proposalInstallments.first_shipment_date | date :  'dd-MMM-y'}}
                                  </div>
                                </div> -->
                              </h4>
                              <!-- <p>Studio store</p> -->
                            </div>
                          </div>
                          <div class="tableColumn">{{installment.due_date ? (installment.due_date | date :  'dd-MMM-y') : '-' }}</div>
                          <div class="tableColumn">{{getCurrencySymbol()}}{{getLocaledPrice(getInstallmentWithTax(installment) | number :
                            '1.2-2')}}</div>
                        </div>
                      </div>

                      <div class="tableBody"  *ngIf="!phaseService.isEditFeatureRequest()">
                        <div class="tableRow" *ngFor="let installment of dataService.proposalInstallments?.monthly; let i = index">
                          <div class="tableColumn">
                            <!-- <button type="button" class="expandButton"><em class="icon-next"></em></button> -->
                            <div class="installmentNo">
                              <h4>{{installment.installment_no}}.
                                <span [attr.data-type]="installment.type" [attr.data-id]="installment.id"> Installment </span>
                                <!-- <span *ngIf="installment.type == 'support'"> Support </span>  -->
                                <div class="infoTooltip" *ngIf="!paymentService.checkIfOnlyProfessionalPrototype() && (isShowToolTip(dataService.proposalInstallments.monthly) === i)">
                                  <em class="icon-info-circle"></em>
                                  <div class="tooltipText">
                                    {{selectedCare()}} will start after the {{getTootipShipmentText()}} date i.e., {{getEstimatedDeliveryDate() | date :  'dd-MMM-y'}}
                                  </div>
                                </div>
                                <div class="infoTooltip" *ngIf="dataService.proposalInstallments.monthly.length-1 === i">
                                  <em class="icon-info-circle"></em>
                                  <div class="tooltipText">
                                    Price will decrease if your project finishes ahead of schedule
                                  </div>
                                </div>
                              </h4>
                              <!-- <p>Studio store</p> -->
                            </div>
                          </div>
                          <div class="tableColumn">{{installment.due_date ? (installment.due_date | date :  'dd-MMM-y') : '-' }}</div>
                          <div class="tableColumn">{{getCurrencySymbol()}}{{getLocaledPrice(getInstallmentAndSupportWithTax(installment) | number :
                            '1.2-2')}}</div>
                        </div>
                      </div>

                      <div class="tableFooter" *ngIf="phaseService.isEditFeatureRequest()">
                        <div class="totalBox">Total</div>
                        <div class="totalBox">{{getCurrencySymbol()}} {{getLocaledPrice(getTotalPrice(getInstallments()) | number : '1.2-2')}}</div>
                      </div>

                      <div class="tableFooter" *ngIf="!phaseService.isEditFeatureRequest()">
                        <div class="totalBox">Total</div>
                        <div class="totalBox">
                          {{getCurrencySymbol()}} {{getLocaledPrice(getTotalPrice(dataService.proposalInstallments?.monthly) | number : '1.2-2')}}
                        </div>
                      </div>

                    </div>
                  </ng-container>

                  <ng-container *ngIf="dataService.buildCardData.subscription_flow">
                    <div class="installmentTable threeColumn" *ngIf="dataService.showWeekly">
                      <div class="tableHeader">
                        <div class="tableRow">
                          <div class="tableColumn">Installment</div>
                          <div class="tableColumn">Date</div>
                          <div class="tableColumn">Amount</div>
                        </div>
                      </div>

                      <div class="tableBody" *ngIf="phaseService.isEditFeatureRequest()">
                        <ng-container *ngFor="let installment of getInstallments(); let i = index">
                          <div class="tableRow">
                            <div class="tableColumn">
                              <div class="installmentNo">
                                <h4>{{installment.installment_no}}.
                                  <span [attr.data-type]="installment.type" [attr.data-id]="installment.id" > Installment </span>
                                </h4>
                              </div>
                            </div>
                            <!--<div class="tableColumn">
                              {{getCurrencySymbol()}}
                              {{ getLocaledPrice(getBuildCardPriceWithSSCost(installment) | number : '1.2-2') }}
                            </div>
                            <div class="tableColumn" *ngIf="phaseService.isSupportAndMaintenance()">
                              {{getCurrencySymbol()}}{{ getLocaledPrice(getCarePlusPrice(installment) | number : '1.2-2') }}
                            </div>-->
                            <div class="tableColumn">{{installment.due_date ? (installment.due_date | date :  'dd-MMM-y') : '-' }}</div>
                            <div class="tableColumn">{{getCurrencySymbol()}}{{getLocaledPrice(getInstallmentWithTax(installment) | number :
                              '1.2-2')}}
                              <div class="expandButton">
                                <em class="icon-next" *ngIf="selectedIndex !== i" (click)="showPriceDetails(i)"></em>
                                <em class="icon-prev" *ngIf="selectedIndex === i" (click)="showPriceDetails(i)"></em>
                              </div>
                            </div>
                          </div>
                          <div class="tableSubRow" *ngIf="selectedIndex === i">
                            <p>Buildcard Price : {{getCurrencySymbol()}}{{ getLocaledPrice(getBuildCardPriceWithSSCost(installment) | number : '1.2-2') }}</p>
                            <p *ngIf="phaseService.isSupportAndMaintenance()">
                              {{getAdditionalCareText()}} upgrade : {{getCurrencySymbol()}}{{ getLocaledPrice(getCarePlusPrice(installment) | number : '1.2-2') }}
                            </p>
                          </div>
                        </ng-container>
                      </div>

                      <div class="tableBody"  *ngIf="!phaseService.isEditFeatureRequest()">
                        <ng-container *ngFor="let installment of dataService.proposalInstallments?.weekly; let i = index">
                          <div class="tableRow">
                            <div class="tableColumn">
                              <div class="installmentNo">
                                <h4>{{installment.installment_no}}.
                                  <span [attr.data-type]="installment.type" [attr.data-id]="installment.id"> Installment </span>
                                  <div class="infoTooltip" *ngIf="!paymentService.checkIfOnlyProfessionalPrototype() && (i === 0)">
                                    <em class="icon-info-circle"></em>
                                    <div class="tooltipText" *ngIf="!dataService.checkIfEnterpriseUserOrCard()">
                                      Your app price includes Studio One for a year. This supports, updates and enhances the applications you’re building with us – to maximise your investment. Includes: collaboration tools, 24/7 uptime and performance monitoring, bug fixes, updates, security patches and back-up management.
                                    </div>
                                    <div class="tooltipText" *ngIf="dataService.checkIfEnterpriseUserOrCard()">
                                      Your app price includes Enterprise One for a year. This supports, updates and enhances the applications you’re building with us – to maximise your investment. Includes: collaboration tools, 24/7 uptime and performance monitoring, app enhancements, bug fixes, updates, security patches and back-up management.
                                    </div>
                                  </div>
                                </h4>
                                <!-- <p>Studio store</p> -->
                              </div>
                            </div>
                            <!--<div class="tableColumn">
                              {{getCurrencySymbol()}}
                              {{ getLocaledPrice(getBuildCardPriceWithSSCost(installment) | number : '1.2-2') }}
                            </div>
                            <div class="tableColumn" *ngIf="phaseService.isSupportAndMaintenance()">
                              {{getCurrencySymbol()}}{{ getLocaledPrice(getCarePlusPrice(installment) | number : '1.2-2') }}
                            </div>-->
                            <div class="tableColumn">{{installment.due_date ? (installment.due_date | date :  'dd-MMM-y') : '-' }}</div>
                            <div class="tableColumn">{{getCurrencySymbol()}}{{getLocaledPrice(getInstallmentAndSupportWithTax(installment) | number :
                              '1.2-2')}}
                              <div class="expandButton">
                                <em class="icon-next" *ngIf="selectedIndex !== i" (click)="showPriceDetails(i)"></em>
                                <em class="icon-prev" *ngIf="selectedIndex === i" (click)="showPriceDetails(i)"></em>
                              </div>
                            </div>
                          </div>
                          <div class="tableSubRow" *ngIf="selectedIndex === i">
                            <p>Buildcard Price : {{getCurrencySymbol()}}{{ getLocaledPrice(getBuildCardPriceWithSSCost(installment) | number : '1.2-2') }}</p>
                            <p *ngIf="phaseService.isSupportAndMaintenance()">
                              {{getAdditionalCareText()}} upgrade : {{getCurrencySymbol()}}{{ getLocaledPrice(getCarePlusPrice(installment) | number : '1.2-2') }}
                            </p>
                          </div>
                        </ng-container>
                      </div>

                      <div class="tableFooter" *ngIf="phaseService.isEditFeatureRequest()">
                        <div class="totalBox">Total</div>
                        <div class="totalBox">{{getCurrencySymbol()}}{{getLocaledPrice(getTotalPrice(phaseService.installments) | number : '1.2-2')}}</div>
                      </div>

                      <div class="tableFooter"  *ngIf="!phaseService.isEditFeatureRequest()">
                        <div class="totalBox">Total</div>
                        <div class="totalBox">
                          {{getCurrencySymbol()}} {{getLocaledPrice(getTotalPrice(dataService.proposalInstallments?.weekly) | number : '1.2-2')}}
                        </div>
                      </div>

                    </div>
                    <div class="installmentTable threeColumn" *ngIf="dataService.showMonthly">
                      <div class="tableHeader">
                        <div class="tableRow">
                          <div class="tableColumn">Installment</div>
                          <div class="tableColumn">Date</div>
                          <div class="tableColumn">Amount</div>
                        </div>
                      </div>

                      <div class="tableBody" *ngIf="phaseService.isEditFeatureRequest();">
                        <ng-container *ngFor="let installment of getInstallments(); let i = index">
                          <div class="tableRow">
                            <div class="tableColumn">
                              <!-- <button type="button" class="expandButton"><em class="icon-next"></em></button> -->
                              <div class="installmentNo">
                                <h4>{{installment.installment_no}}.
                                  <span [attr.data-type]="installment.type" [attr.data-id]="installment.id"> Installment </span>
                                </h4>
                              </div>
                            </div>
                            <!--<div class="tableColumn">
                              {{getCurrencySymbol()}}
                              {{ getLocaledPrice(getBuildCardPriceWithSSCost(installment) | number : '1.2-2') }}
                            </div>
                            <div class="tableColumn" *ngIf="phaseService.isSupportAndMaintenance()">
                              {{getCurrencySymbol()}}{{ getLocaledPrice(getCarePlusPrice(installment) | number : '1.2-2') }}
                            </div>-->
                            <div class="tableColumn">{{installment.due_date ? (installment.due_date | date :  'dd-MMM-y') : '-' }}</div>
                            <div class="tableColumn">{{getCurrencySymbol()}}{{getLocaledPrice(getInstallmentWithTax(installment) | number :
                              '1.2-2')}}
                              <div class="expandButton">
                                <em class="icon-next" *ngIf="selectedIndex !== i" (click)="showPriceDetails(i)"></em>
                                <em class="icon-prev" *ngIf="selectedIndex === i" (click)="showPriceDetails(i)"></em>
                              </div>
                            </div>
                          </div>
                          <div class="tableSubRow" *ngIf="selectedIndex === i">
                            <p>Buildcard Price : {{getCurrencySymbol()}}{{ getLocaledPrice(getBuildCardPriceWithSSCost(installment) | number : '1.2-2') }}</p>
                            <p *ngIf="phaseService.isSupportAndMaintenance()">
                              {{getAdditionalCareText()}} upgrade : {{getCurrencySymbol()}}{{ getLocaledPrice(getCarePlusPrice(installment) | number : '1.2-2') }}
                            </p>
                          </div>
                        </ng-container>
                      </div>

                      <div class="tableBody"  *ngIf="!phaseService.isEditFeatureRequest()">
                        <ng-container *ngFor="let installment of dataService.proposalInstallments?.monthly; let i = index">
                          <div class="tableRow">
                            <div class="tableColumn">
                              <div class="installmentNo">
                                <h4>{{installment.installment_no}}.
                                  <span [attr.data-type]="installment.type" [attr.data-id]="installment.id"> Installment </span>
                                  <div class="infoTooltip" *ngIf="!paymentService.checkIfOnlyProfessionalPrototype() && (i === 0)">
                                    <em class="icon-info-circle"></em>
                                    <div class="tooltipText" *ngIf="!dataService.checkIfEnterpriseUserOrCard()">
                                      Your app price includes Studio One for a year. This supports, updates and enhances the applications you’re building with us – to maximise your investment. Includes: collaboration tools, 24/7 uptime and performance monitoring, bug fixes, updates, security patches and back-up management.
                                    </div>
                                    <div class="tooltipText" *ngIf="dataService.checkIfEnterpriseUserOrCard()">
                                      Your app price includes Enterprise One for a year. This supports, updates and enhances the applications you’re building with us – to maximise your investment. Includes: collaboration tools, 24/7 uptime and performance monitoring, app enhancements, bug fixes, updates, security patches and back-up management.
                                    </div>
                                  </div>
                                </h4>
                              </div>
                            </div>
                            <!--<div class="tableColumn">
                              {{getCurrencySymbol()}}
                              {{ getLocaledPrice(getBuildCardPriceWithSSCost(installment) | number : '1.2-2') }}
                            </div>
                            <div class="tableColumn" *ngIf="phaseService.isSupportAndMaintenance()">
                              {{getCurrencySymbol()}}{{ getLocaledPrice(getCarePlusPrice(installment) | number : '1.2-2') }}
                            </div>-->
                            <div class="tableColumn">{{installment.due_date ? (installment.due_date | date :  'dd-MMM-y') : '-' }}</div>
                            <div class="tableColumn">{{getCurrencySymbol()}}{{getLocaledPrice(getInstallmentAndSupportWithTax(installment) | number :
                              '1.2-2')}}
                              <div class="expandButton">
                                <em class="icon-next" *ngIf="selectedIndex !== i" (click)="showPriceDetails(i)"></em>
                                <em class="icon-prev" *ngIf="selectedIndex === i" (click)="showPriceDetails(i)"></em>
                              </div>
                            </div>
                          </div>
                          <div class="tableSubRow" *ngIf="selectedIndex === i">
                            <p>Buildcard Price : {{getCurrencySymbol()}}{{ getLocaledPrice(getBuildCardPriceWithSSCost(installment) | number : '1.2-2') }}</p>
                            <p *ngIf="phaseService.isSupportAndMaintenance()">
                              {{getAdditionalCareText()}} upgrade : {{getCurrencySymbol()}}{{ getLocaledPrice(getCarePlusPrice(installment) | number : '1.2-2') }}
                            </p>
                          </div>
                        </ng-container>
                      </div>

                      <div class="tableFooter" *ngIf="phaseService.isEditFeatureRequest()">
                        <div class="totalBox">Total</div>
                        <div class="totalBox">{{getCurrencySymbol()}} {{getLocaledPrice(getTotalPrice(getInstallments()) | number : '1.2-2')}}</div>
                      </div>

                      <div class="tableFooter" *ngIf="!phaseService.isEditFeatureRequest()">
                        <div class="totalBox">Total</div>
                        <div class="totalBox">
                          {{getCurrencySymbol()}} {{getLocaledPrice(getTotalPrice(dataService.proposalInstallments?.monthly) | number : '1.2-2')}}
                        </div>
                      </div>

                    </div>
                  </ng-container>

                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="commonService.startTwoPayFlow">
              <div class="totalCardPayment" *ngIf="dataService.isUnpaidCard()">
                <div class="totalCostDesc" *ngIf="!paymentService.checkIfOnlyProfessionalPrototype()">
                  For hassle-free billing, <span class="linkText" (click)="updateBillingFrequency()">pay upfront.</span>
                </div>
                <div class="totalCostDesc" *ngIf="paymentService.checkIfOnlyProfessionalPrototype()">
                  <span class="linkText" (click)="updateBillingFrequency()">pay upfront.</span>
                </div>
              </div>

              <ng-container *ngIf="!commonService.isTwoPayRejected">
                <div class="twoPayRow">
                  <h3>Monthly Billing</h3>
                  <div class="twoPayIcon">
                    <span>Powered By</span>
                    <img src="../../../../../assets/images/twopayicon.svg" alt="" />
                  </div>
                </div>
                <div class="billingCycleTabel">
                  <h3>Installment Details</h3>
                  <div class="installmentTable threeColumn">
                    <div class="tableHeader">
                      <div class="tableRow">
                        <div class="tableColumn">Installment</div>
                        <div class="tableColumn">Date</div>
                        <div class="tableColumn">Amount</div>
                      </div>
                    </div>

                    <div class="tableBody" *ngIf="commonService?.twoPayInstallments?.instalments">
                      <div class="tableRow" *ngFor="let installment of commonService?.twoPayInstallments['instalments']; let i = index">
                        <div class="tableColumn">
                          <!-- <button type="button" class="expandButton"><em class="icon-next"></em></button> -->
                          <div class="installmentNo">
                            <h4>{{i + 1}}. Installment</h4>
                          </div>
                        </div>
                        <div class="tableColumn">{{installment.invoice_issue_date ? (installment['invoice_issue_date'] | date :  'dd-MMM-y') : '-' }}</div>
                        <div class="tableColumn">{{getCurrencySymbol()}}{{getLocaledPrice((installment['repayment_amount']) | number :
                          '1.2-2')}}</div>
                      </div>
                    </div>
                    <div class="tableFooter">
                      <div class="totalBox">Total</div>
                      <div class="totalBox">
                        {{getCurrencySymbol()}} {{getLocaledPrice(get2PayTotalPrice(commonService?.twoPayInstallments?.instalments) | number : '1.2-2')}}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="commonService.isTwoPayRejected">
                <div class="twoPayTotalCardPayment">
                  <div class="totalCostDesc">The transaction has failed.
                    <span class="linkText" (click)="openMonthlyFlow()">Try paying using a different method.</span>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="paymentService.startCapchaseFlow">
              <div class="totalCardPayment" *ngIf="dataService.isUnpaidCard()">
                <div class="totalCostDesc" *ngIf="!paymentService.checkIfOnlyProfessionalPrototype()">
                  For hassle-free billing, <span class="linkText" (click)="startForCapchaseFlow()">pay upfront.</span>
                </div>
                <div class="totalCostDesc" *ngIf="paymentService.checkIfOnlyProfessionalPrototype()">
                  <span class="linkText" (click)="startForCapchaseFlow()">pay upfront.</span>
                </div>
              </div>

              <ng-container *ngIf="!commonService.isBNPLRejected">
                <div class="twoPayRow">
                  <h3>Monthly Billing</h3>
                  <div class="twoPayIcon">
                    <span>Powered By <h3>Capchase</h3></span>
                  </div>
                </div>

                <div class="capchaseIframe" [hidden]="!paymentService.isCapchaseLink">
                  <iframe #iframe id="myframe" title="" [class.d-none]="paymentService.isLoading" frameborder="0"></iframe>
                </div>

                <div class="billingCycleTabel" *ngIf="!paymentService.isCapchaseLink">
                  <h3>Installment Details</h3>
                  <div class="installmentTable threeColumn">
                    <div class="tableHeader">
                      <div class="tableRow">
                        <div class="tableColumn">Installment</div>
                        <div class="tableColumn">Date</div>
                        <div class="tableColumn">Amount</div>
                      </div>
                    </div>

                    <div class="tableBody" *ngIf="paymentService.capchaseInstallments['fe_installments']">
                      <div class="tableRow" *ngFor="let installment of paymentService.capchaseInstallments['fe_installments']; let i = index">
                        <div class="tableColumn">
                          <div class="installmentNo">
                            <h4>{{installment['installment_no']}}. Installment</h4>
                          </div>
                        </div>
                        <div class="tableColumn">{{installment.due_date ? (installment['due_date'] | date :  'dd-MMM-y') : '-' }}</div>
                        <div class="tableColumn">{{getCurrencySymbol()}}
                          {{getLocaledPrice((installment['installment_amount'] + installment['tax_amount']) | number :'1.2-2')}}</div>
                      </div>
                    </div>
                    <div class="tableFooter">
                      <div class="totalBox">Total</div>
                      <div class="totalBox">
                        {{getCurrencySymbol()}}
                        {{getLocaledPrice(getTotalPrice(paymentService.capchaseInstallments['fe_installments']) | number : '1.2-2')}}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="commonService.isBNPLRejected">
                <div class="twoPayTotalCardPayment">
                  <div class="totalCostDesc">The transaction has failed.
                    <span class="linkText" (click)="openMonthlyFlow()">Try paying using a different method.</span>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- payment loading sccreen  -->
  <div class="payment-loader hide">
    <div class="container paymentContainer">
      <div class="row">
        <div class="col-md-4 col-md-push-8">
          <div class="paymentSummaryDetail">
            <div class="paymentSummery">
              <span class="summery-head"></span>
              <div>
                <ul class="sub-head">
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>
            <div class="applyPromotion">
              <span class="promotion-img"></span>
            </div>
            <div class="paymentSummery additionalService">
              <span class="summery-head"></span>
              <div>
                <ul class="sub-head">
                  <li></li>
                </ul>
              </div>
            </div>

          </div>

          <div class="paymentSummaryDetail dueNow-style ">
            <div class="paymentSummery">
              <span class="summery-head"></span>
              <div>
                <ul class="sub-head">
                  <li></li>
                </ul>
              </div>
            </div>
            <div class="paymentSummery additionalService">
              <span class="summery-head"></span>
              <div>
                <ul class="sub-head">
                  <li></li>
                </ul>
              </div>
            </div>
            <div class="paymentSummery">
              <span class="continue-btn"></span>
            </div>
          </div>
        </div>
        <div class="col-md-1">
        </div>

        <div class="col-md-7 col-md-pull-5">
          <div class="paymentPlan loadingstyle">
            <span></span>
            <div class="payment-btn"></div>
            <ul class="listing">
              <li> </li>
              <li> </li>
              <li> </li>
              <li> </li>
              <li> </li>
            </ul>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- approve and continue popup start-->
  <div class="commonPopUp" [ngClass]="{active: dataService.showApproveAndContinuePopup}">
    <div class="popOverlay"></div>
    <div class="popHolder conformPopUp">
      <div class="closePopUp" (click)="closeApprovalPopup()"><span class="icon-cancel"></span></div>
      <h3>Accept and continue </h3>
      <div class="mid-popup">
        <ng-container *ngIf="!phaseService.isPlatformRequest">
          <p>Awesome! You have submitted the request to add {{dataService.additionalFeatures.length}} {{dataService.additionalFeatures.length > 1 ? 'features' : 'feature'}} to your Buildcard.</p>
          <p>Next steps- your squad will review the request soon. You can also view this request from your dashboard now.</p>
        </ng-container>
        <ng-container *ngIf="phaseService.isPlatformRequest">
          <p>Awesome! You have submitted the request to add {{ paymentService.getAddedPlatformsCount() }} {{paymentService.getAddedPlatformsCount() > 1 ? 'platforms' : 'platform'}} to your Buildcard.</p>
          <p>Next steps- your squad will review the request soon.</p>
        </ng-container>
        <button class="doneButton" type="button" (click)="redirectToDashboardForAdditionalFeatures()" [disabled]="payments.disableDoneButton"> Done <img *ngIf="payments.disableDoneButton" src="./../../../../../assets/images/buttonLoader.gif" alt=""/> </button>
      </div>
    </div>
  </div>
  <!-- approve and continue popup end -->

  <!-- popup --->
  <div class="commonPopUp" [ngClass]="{'active': isBillingCycleChanged}" *ngIf="isBillingCycleChanged">
    <div class="popOverlay"></div>
    <div class="popHolder resetBillingDetail cus-width-popup">
      <div class="closePopUp" (click)="closeResetMessagePopup()"><span class="icon-cancel"></span></div>
      <h3> Changing the payment plan will discard the earlier signed master contract</h3>
      <button type="button" (click)="resetMSAContract()"> Save</button>
    </div>
  </div>
</ng-container>
