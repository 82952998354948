import {Component, ElementRef, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {DataService} from '@shared/services/data.service';
import {PhaseService} from '@shared/services/phase.service';
import {Angulartics2Segment} from 'angulartics2/segment';
import {TeamModel} from '@models/teams-model/team.model';
import {TeamsModel} from '@models/teams-model/teams.model';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'team-card',
  styleUrls: ['team-card-component.scss'],
  templateUrl: 'team-card.component.html'
})
export class TeamCardComponent implements OnInit {
  @Output('toggleSelect') toggleSelect = new EventEmitter();

  teamData: TeamsModel;
  timezonesData: any[] = [];
  hideTimezoneList: boolean = true;
  searchedTimeZone: string = '';
  selectedTeam: any;
  noPopularTimezones: boolean = true;
  showTimeZoneList: boolean;
  searchResults: TeamModel[] = [];

  constructor(
    public dataService: DataService,
    public phaseService: PhaseService,
    public analyticsSegment: Angulartics2Segment,

    private eRef: ElementRef
  ) {
  }

  public ngOnInit() {
    this.prepareTeamData();
  }

  public prepareTeamData() {
    this.teamData = this.dataService.teams;
    if (this.teamData.popular  && this.teamData.popular.length > 0) {
      this.noPopularTimezones = false;
      this.timezonesData = this.teamData.all.concat(this.teamData.popular);
    } else {
      this.timezonesData = this.teamData.all;
    }
    this.phaseService.selectedTeam
      ? this.phaseService.setSelectedItems('selectedTeam')
      : this.resetTeams();
    this.phaseService.setSelectedTeamId(this.phaseService.selectedTeam.id);
  }

  public resetTeams() {
    this.phaseService.selectedTeam = this.dataService.teams.default;
    this.phaseService.setSelectedTeamId(this.phaseService.selectedTeam.id);
    this.phaseService.setSelectedItems('selectedTeam');
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showTimeZoneList = false;
    }
  }

  public showTimezones() {
    this.showTimeZoneList = true;
  }

  public closeTimezones(){
    this.showTimeZoneList = false;
  }

  public hideTimeZoneList() {
    this.showTimeZoneList = false;
  }

  public filterTimezones() {
    if (this.searchedTimeZone === '') {
      this.searchResults = this.timezonesData;
      return;
    }
    this.searchResults = this.timezonesData.filter((item: any) => {
      let formattedTimeZone: string = this.searchedTimeZone
        .toLocaleLowerCase()
        .trim();
      let formattedTitle: string = item.title.toLocaleLowerCase().trim();

      return formattedTitle.indexOf(formattedTimeZone) !== -1;
    });
  }

  public selectTeamTimezone(team: any) {
    this.phaseService.trackEventForObject(this.analyticsSegment,  'team_location_set', {
      user_id : this.dataService.user?.id,
      location_selected : team.title
    });
    team.selected = true;
    this.phaseService.selectedTeam = team;
    this.phaseService.setSelectedTeamId(this.phaseService.selectedTeam.id);
    for (const item of this.teamData.all) {
      if (team.id === item.id) {
        team.selected = true;
        item.selected = true;
        break;
      }
    }
    if (this.teamData.popular ) {
      for (const item of this.teamData.popular) {
        if (team.id === item.id) {
          team.selected = true;
          item.selected = true;
          break;
        }
      }
    }
    this.updateProperTeamSpeed();
    this.showTimeZoneList = false;
    this.searchedTimeZone = '';
  }

  public updateProperTeamSpeed() {
    const promotion = this.dataService.homePageData.promotion || this.dataService.manualPromotion;
    if (promotion) {
      this.phaseService.checkTeamAndSpeed();
    }
    else {
      this.phaseService.teamandspeedproper = false;
      this.phaseService.selectedHash.promotion_id = '';
    }
  }

  checkIfRoadMapOrTailorMade() {
    const buildPhases = this.dataService.homePageData.build_phases;
    this.phaseService.selectedPhases = buildPhases.filter(phase => phase.selected);
    if (this.checkIfOnlyRoadmapSelected() || this.phaseService.checkIfOnlyRapidPrototypeSelected()) {
      return true;
    } else if (this.phaseService.selectedPhases.length === 0) {
      return true;
    } else if ((this.phaseService.selectedPhases.length === 2) && buildPhases[0].selected && buildPhases[2].selected) {
      return true;
    } else {
      return false;
    }
  }

  checkIfOnlyRoadmapSelected() {
    this.phaseService.selectedPhases = this.dataService.homePageData.build_phases.filter(phase => phase.selected);
    const roadmap = this.dataService.homePageData.build_phases.find(
      phase => phase.title === 'Product Roadmap'
    );
    if (this.phaseService.selectedPhases && (this.phaseService.selectedPhases.length === 1) && roadmap && roadmap.selected) {
      return true;
    }
    return false;
  }
}
