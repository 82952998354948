<div class="filterListMain" *ngIf = "!homeTemplateService.initialLoader && homeTemplateService.all_templates.length > 0">
    <div class="filterList"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="300"
    (scrolled)="onScroll()">
        <app-home-template-card *ngFor = "let template of homeTemplateService.all_templates" [type] = "'all'"  [template] = "template.attributes ? template.attributes : template" ></app-home-template-card>
    </div>
</div>

