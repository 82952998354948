import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import moment from 'moment';
// import {Moment} from 'moment';
import {interval, Observer} from 'rxjs/index';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Http} from '@angular/http';
import {NavigationEnd, Router} from '@angular/router';
import {Angulartics2Segment} from 'angulartics2/segment';
import {ApiRequests} from '@shared/services/api-requests.service';
import {Constants} from '@shared/utils/constants';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {environment} from '@env/environment';
import {UtilityService} from '@shared/services/utility.service';
import {AppDataService} from '@rootmodule/app-data.service';
import {TeamsModel} from '@models/teams-model/teams.model';
import {ModelMapperService} from '@shared/services/model-mapper.service';
import {PhaseDataModel} from '@models/phase-model/phasedata.model';
import {CommonService} from '@shared/services/common.service';
import { Intercom } from 'ng-intercom';
import { SocialAuthService, GoogleLoginProvider } from 'angularx-social-login';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chatbot.component.html',
  styles: ['.ng2-tag-input {border:none!important;}'],
  styleUrls: ['./chatbot.component.scss']
})
export class ChatBotComponent implements OnInit {
  showChat = true;
  userPassword = null;
  errorMessage = null;
  loaded = false;
  private buildCardSelections: any;
  public phaseData: Array<PhaseDataModel>;
  selectedHash = {
    project_name: 'My Builder Project',
    description: null,
    target_user_group: '',
    customized_template_name: '',
    utm_source: null,
    utm_campaign: null,
    support_maintenance_weeks: 0,
    startup: false,
    self_funded: false,
    npo_ngo: false,
    specing: false,
    product_id: null,
    audience_id: null,
    project_status_id: null,
    application_ids: [],
    platform_ids: [],
    feature_ids: [],
    team_id: null,
    speed_id: null,
    build_phase_ids: [],
    build_phase_vars: [],
    promotion_id: null,
    region_skill_ids: [],
    competitors: [],
    app_style_ids: [],
    category_ids: [],
    startup_urls: [],
    attachments_attributes: [],
    quote_price: 0,
    status: 'card_initialized',
    nda_signed: false,
    is_under_support: true,
    buildcardphases: [],
    is_advance: false,
    pricing_params: {},
    build_card_phases_attributes : [],
    currency_id: null
  };
  nextStep = 'product';
  currentList = [];
  selectedListToShow = [];
  stepsCompleted = 0;
  showLoading = false;
  loadingText = '';
  buildCardUrl;
  listOfQuestions =
    {
      //product: 'Hey, I\'m Natasha from Engineer.ai.<br/>Are you looking for help in building a product?',

      product: 'Welcome to Builder Chat',
      specing: 'Perfect! Do you have any specification document?',
      specing_payment: 'No problem, our product experts will prepare one for your product. ',
      apps: 'Awesome! You like to build ',
      platforms: 'Now select platforms to build this product?',
      team: 'We have teams spread across the world, select a team as per your choice. (Anywhere is the cheapest option)',
      phases: 'Great! Let\'s break the Buildcard™ up into phases.',
      speed: 'Last one, let us know your preferred delivery-module date. (The later the cheaper)',
      noted: 'Done!',
      building_card: 'Your Buildcard&#8482; is being prepared.',
      email: 'Provide email (abc@example.com)',
      name: 'Provide name (Min 3 characters)'
    };
  showInputBox = false;
  selectedItemLength = 0;
  inputValue = '';
  placeHolder = 'Enter email here';
  items;
  public signup_data: any = {
    first_name: null,
    last_name: '',
    email: null,
    password: '12345678',
    phone_number: '',
    invite_token: null,
    currency_id: null
  };
  public userDetailForm: FormGroup;
  email;
  name;
  public buildCardData;
  @ViewChild('mainContent') public myScrollContainer: ElementRef;
  public selectedProduct;
  public selectedTemplate;
  public selectedFeatures = [];
  public selectedPlatforms = [];
  public selectedPhases = [];
  public selectedTeam;
  public selectedSpeed;
  public finalPricenWeeks;
  public platformPrices = [];
  public selectedPhaseIndexes = [];
  @Input() public testName: string;
  public self: HTMLElement = document.querySelector('chat-bot');
  public emailForm: FormGroup;
  public placeholderText = 'Please enter the name of similar products';
  public showHelloText = false;
  public supportMaintenance = 0;
  public discountedPrice;
  @ViewChild('mainContent') public mainContent;

  public chatSelections = {
    introduction: null,
    howItWorks: null,
    product: null,
    templates: null,
    platforms: null,
    email: null,
    name: null,
    buildCard: null
  };
  public isChatHidden = true;
  public emailType = '';

  public currentStep = 0;
  public autocompleteData = [];
  autocompleteItemsAsObjects = [
    {value: 'Item1', id: 0, extra: 0},
    {value: 'item2', id: 1, extra: 1},
    'item3'
  ];
  public selectedTemplates = [];
  public queryString = '';
  public showDropdown = false;


  public chatQuestions = {
    introduction: {
      active: false,
      question: 'So tell me how should it start',
      lastUpdated: this.calculateRealTimeElapsed(),
      options: [
        {
          id: 1,
          title: 'Show me how it works'
        },
        {
          id: 2,
          title: 'Schedule a meeting for me'
        },
        {
          id: 3,
          title: 'Start building now'
        },
        {
          id: 4,
          title: 'Talk to Us'
        }
      ]
    },
    howItWorks: {
      active: false,
      question: 'watch this video for a brief introduction to Builder',
      lastUpdated: null,
      options: [
        {
          id: 1,
          title: 'Cool, Lets build it right now.'
        },
        {
          id: 2,
          title: 'Schedule a meeting for me.'
        }
      ]
    },
    product: {
      active: false,
      lastUpdated: null,
      question: 'So what kind of product you are thinking of building, choose one of the following options',
      options: []
    },
    templates: {
      active: false,
      lastUpdated: null,
      question: 'Great. Can you tell us some similar companies in the market? It will help us choose the right features for you. Ex. Amazon, Uber(Max 2)',
      options: []
    },
    platforms: {
      active: false,
      lastUpdated: null,
      question: 'What platforms you are thinking to build your product on ?',
      options: []
    },
    email: {
      active: false,
      lastUpdated: null,
      question: ''
    },
    name: {
      active: false,
      lastUpdated: null,
      question: 'Seems like you are new to our platform, let me quickly create an account for you ? Please provide the following details.'
    },
    buildCard: {
      active: false,
      lastUpdated: null,
      options: [
        {
          id: 1,
          title: 'Schedule a meeting for me.'
        },
        {
          id: 2,
          title: 'Talk to Us'
        }
      ]
    }
  };

  public countryCode;
  public observer: Observer<any>;
  public selfContext;
  public userFromBuilder;
  public currentUrl;
  public onFocus = false;
  public isSubmitted = false;
  @ViewChild('mobileNumber') public mobileNumber;
  moment: any;

  constructor(public phaseService: PhaseService,
              public dataService: DataService,
              public utilityService: UtilityService,
              public appDataService: AppDataService,
              public http: Http,
              public router: Router,
              private _analyticsSegment: Angulartics2Segment,
              private _formBuilder: FormBuilder,
              public auth: SocialAuthService,
              public modelMapperService: ModelMapperService,
              public apiRequest: ApiRequests,
              private commonService: CommonService) {
                this.moment = moment;
    setTimeout(() => {
      if (this.self) {
        this.testName = this.getAttributeValue(this.self, 'partner-id');
      }
    }, 20);
    this.showLoading = true;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });

    const self = this;

    window.addEventListener('MyEventType', function (evt: any) {
      self.showChat = false;
    }, false);

    window.addEventListener('CurrencySelected', function (evt: any) {
      if (evt.detail) {
        self.createBuildCard();
      }
    }, false);
    this.signup_data.phone_number = this.appDataService.urlParameters.phone_number ? this.appDataService.urlParameters.phone_number : null;
    this.signup_data.email = this.appDataService.urlParameters.email ? this.appDataService.urlParameters.email : null;
    this.signup_data.first_name = this.appDataService.urlParameters.name ? this.appDataService.urlParameters.name.split(' ')[0] : null;
    this.signup_data.last_name = this.appDataService.urlParameters.name ? this.appDataService.urlParameters.name.split(' ')[1] : null;
  }

  ngOnInit() {
    this.showLoading = true;
    this.selfContext = this;
    this.loadEmailForm();
    this.loadUserForm();
  }



  public loadEmailForm() {
    this.emailForm = this._formBuilder.group({
      email: new FormControl(this.appDataService.urlParameters.email ? this.appDataService.urlParameters.email : null, [Validators.required, Validators.email])
    });
  }

  public loadUserForm() {
    this.userDetailForm = this._formBuilder.group({
      name: new FormControl(this.appDataService.urlParameters.name ? this.appDataService.urlParameters.name : null, [Validators.required]),
      phone: new FormControl(this.appDataService.urlParameters.phone_number ? this.appDataService.urlParameters.phone_number : null, [Validators.required])
    });
  }


  public getAttributeValue(node, attributeName) {
    let result;
    for (let i = 0; i < node.attributes.length; i++) {
      if (node.attributes[i].nodeName === attributeName) {
        result = node.attributes[i].nodeValue;
      }
    }
    return result;
  }

  public loadInitialData() {
    this.showLoading = true;
    if (this.dataService.homePageData) {
      this.chatQuestions.introduction.active = true;
      this.currentStep = 1;
      this.showLoading = false;

      this.phaseService.platformPrices = Array.apply(null, Array(this.dataService.homePageData.platforms.length));
      this.phaseService.platformPrices.map((x, i) => {
        return i;
      });
      this.platformPrices = Array.apply(null, Array(this.dataService.homePageData.platforms.length));
      this.platformPrices.forEach((x, i) => {
        return i;
      });
      if (this.dataService.homePageData.currency) {
        this.phaseService.specing_price = this.dataService.homePageData.currency.specing_price;
      }
      let speeds = this.dataService.homePageData.speed.reverse();
      this.selectedSpeed = speeds[0];
      this.selectedHash.speed_id = speeds[0].id;

      this.dataService.homePageData.build_phases.forEach((phase, i) => {
        if (phase.title !== 'MVP') {
          this.selectedPhases.push(phase);
          this.selectedHash.build_phase_vars.push(phase.index);
        }
      });

      this.fetchTeams();
      this.autoScrollToBottom();
      setTimeout(() => {
        this.showLoading = false;
        this.currentList = this.dataService.homePageData.build_products;
        this.selectedListToShow.push({question: this.listOfQuestions[this.nextStep], selections: []});
      }, 1000);
    }
  }

  public fetchTemplates() {
    this.showLoading = true;
    this.apiRequest.fetchTemplates(this.selectedHash.product_id).subscribe((data: any) => {
        this.currentList = this.dataService.apps;
        const textAfterMainTitle = this.getAppTemplateName(this.selectedProduct) + ' like?';
        this.selectedListToShow[this.stepsCompleted] = {
          question: this.listOfQuestions[this.nextStep] + textAfterMainTitle,
          selections: []
        };
        this.chatQuestions.templates.active = true;
        this.chatQuestions.templates.lastUpdated = this.calculateRealTimeElapsed();
        this.showLoading = false;
        this.autoScrollToBottom();
      }, error => this.showLoading = false
    );
  }

  public fetchFeatures() {
    this.showLoading = true;
    this.apiRequest.fetchSelectedFeatures(this.selectedHash.application_ids).subscribe((featuresData: any) => {
        this.showLoading = false;
       // console.log(featuresData);
        this.selectedFeatures = featuresData.data.map(feature => feature.attributes);
        this.filterSelectedFeatures();
      }, (error) => {
        this.showLoading = false;
      }
    );
  }


  public filterSelectedFeatures() {
    //console.log(this.selectedFeatures);
    if (this.selectedFeatures.length > 0) {
      this.selectedHash.feature_ids = [];
      this.selectedFeatures.forEach(feature => {
        this.selectedHash.feature_ids.push(feature.id);
      });
    }
    this.currentStep += 1;
    this.showLoading = true;
    setTimeout(() => {
      this.showLoading = false;
      this.currentStep += 1;
      this.chatQuestions.platforms.active = true;
      this.chatQuestions.platforms.lastUpdated = this.calculateRealTimeElapsed();
      this.showLoading = true;
      setTimeout(() => {
        this.showLoading = false;
        this.currentStep += 1;
        this.autoScrollToBottom();
      }, 3000);
    }, 3000);
  }


  public getAppTemplateName(product) {
    return product.title.includes('Something') ? 'something' :
      product.title.includes('Mobile') ? 'mobile app' :
        product.title.toLowerCase();
  }

  public getDeliveryDateForSpeed(speed) {
    return this.moment(this.getDeliveryDate(speed, this.finalPricenWeeks.weeks)).format('DD-MMM-YYYY');
  }

  public getDeliveryDate(speed, finalWeeks) {
    const date = new Date();
    date.setDate(date.getDate() + this.getDays(speed, finalWeeks));
    return date;
  }

  public getDays(speed, finalWeeks) {
    let weeks = 0;
    weeks = finalWeeks * speed.week_multiplier;
    return this.phaseService.getTotalDays(weeks);
  }


  public fetchTeams() {
    this.showLoading = true;
    this.apiRequest.fetchTeams().subscribe((data: any) => {
      data.teams = this.modelMapperService.getTeamMappedModel(
        new TeamsModel(),
        data.teams
      );
      // this.currentList.push(data.teams.default);
      // this.currentList.push(data.teams.current);
      this.selectedHash.team_id = data.teams.default.id;
      this.selectedTeam = data.teams.default;
      this.showLoading = false;
    });
  }

  public getHomePagedata() {
    return this.dataService.homePageData;
  }

  public getLocaledPrice(price: any) {
    return this.dataService.getLocaledPrice(price);
  }


  /////////////////////////////////////////////////////////////////////////////////

  public resetChatViews() {
    for (const key in this.chatQuestions) {
      this.chatQuestions[key].active = false;
      this.chatQuestions[key].lastUpdated = this.calculateRealTimeElapsed();
    }
  }

  public resetChatSelections() {
    for (const key in this.chatSelections) {
      this.chatSelections[key] = null;
    }
  }

  public selectChatOptions(step, data?) {
    if (step === 'introduction') {
      this.showLoading = true;
      this.chatSelections.introduction = data.title;
      this.resetChatViews();
      // this.setAnalyticsForChatbot('first_module', this.chatSelections.introduction);

      if (this.chatSelections.introduction === 'Show me how it works') {
        this.showLoading = true;
        setTimeout(() => {
          this.showLoading = false;
          this.chatQuestions.howItWorks.active = true;
          this.chatQuestions.howItWorks.lastUpdated = this.calculateRealTimeElapsed();
          this.currentStep = 2;
          this.autoScrollToBottom();
        }, 3000);
      } else if (this.chatSelections.introduction === 'Schedule a meeting for me') {
        /* Previous SPEC FLOW */
        /*this.phaseService.selectSpecing();*/
        this.toggleChatView(event);
        this.resetChatbot();
        this.openMeetNoeModal();
      } else if (this.chatSelections.introduction === 'Start building now') {
        this.showLoading = true;
        setTimeout(() => {
          this.showLoading = false;
          this.chatQuestions.product.active = true;
          this.chatQuestions.product.lastUpdated = this.calculateRealTimeElapsed();
          this.currentStep = 3;
          this.autoScrollToBottom();
        }, 3000);
      } else if (this.chatSelections.introduction === 'Talk to Us') {
        this.showLoading = true;
        setTimeout(() => {
          this.showLoading = false;
          const evt = new CustomEvent('MyEventType', {detail: '1234'});
          window.dispatchEvent(evt);
          this.showChat = false;
        }, 2000);
      }
    } else if (step === 'howItWorks') {
      this.chatSelections.howItWorks = data.title;
      this.resetChatViews();

      if (this.chatSelections.howItWorks === 'Cool, Lets build it right now.') {
        this.showLoading = true;
        setTimeout(() => {
          this.showLoading = false;
          this.chatQuestions.product.active = true;
          this.chatQuestions.product.lastUpdated = this.calculateRealTimeElapsed();
          this.currentStep += 1;
          this.autoScrollToBottom();
        }, 3000);
      } else {
        this.showLoading = true;
        setTimeout(() => {
          this.showLoading = false;
          this.phaseService.showTimeKitWindow();
          this.autoScrollToBottom();
        }, 1000);
      }
    } else if (step === 'product') {
      this.chatSelections.product = data.title;
      this.selectedProduct = data;
      this.selectedHash.product_id = data.id;
      this.resetChatViews();
      this.showLoading = true;
      setTimeout(() => {
        this.showLoading = false;
        this.chatQuestions.templates.active = true;
        this.chatQuestions.templates.lastUpdated = this.calculateRealTimeElapsed();
        this.currentStep += 1;
      }, 3000);
      this.autoScrollToBottom();
    } else if (step === 'templates') {
      this.chatQuestions.templates.active = true;
      this.chatSelections.templates = this.selectedTemplates;
      this.showLoading = true;
      setTimeout(() => {
        this.showLoading = false;
        this.currentStep += 1;
        this.setTemplateName();
        this.resetChatViews();
        this.fetchFeatures();
      });
    } else if (step === 'email') {
      this.showLoading = true;
      setTimeout(() => {
        this.showLoading = false;
        this.currentStep += 2;
        this.chatQuestions.email.active = true;
        this.autoScrollToBottom();
      }, 3000);
    } else if (step === 'buildCard') {
      this.chatSelections.buildCard = data.title;
      if (this.chatSelections.buildCard === 'Schedule a meeting for me.') {
        /* Previous SPEC FLOW */
        /*this.phaseService.selectSpecing();*/
        this.toggleChatView(event);
        this.resetChatbot();
        this.openMeetNoeModal();
      } else if (this.chatSelections.buildCard === 'Talk to Us') {
        this.showLoading = true;
        setTimeout(() => {
          this.showLoading = false;
          const evt = new CustomEvent('MyEventType', {detail: '1234'});
          window.dispatchEvent(evt);
          this.showChat = false;
        }, 3000);
      }
    }
  }

  openMeetNoeModal() {
    if (!this.dataService.user) {
      this.phaseService.openCommonAuth = true;
      if (this.dataService.homePageData?.block_sign_up) {
        this.phaseService.showSignin = true;
        this.phaseService.showRegister = false;
      } else {
        this.phaseService.showRegister = true;
        this.phaseService.showSignin = false;
      }
      this.dataService.openMeetNowModal = true;
    } else {
      this.dataService.showMeetNowModel = true;
    }
  }

  public selectTemplate(app, selection) {
    this.showDropdown = false;
    if (selection) {
      if (this.selectedHash.application_ids.length < 2) {
        this.selectedHash.application_ids.push(app.id);
        this.selectedTemplates.push(app);
        this.queryString = '';
      }
      if (this.selectedHash.application_ids.length === 1) {
        this.placeholderText = 'add more....';
      } else if (this.selectedHash.application_ids.length === 2) {
        this.placeholderText = '';
      }
    } else {
      this.selectedHash.application_ids.splice(this.selectedHash.application_ids.indexOf(app), 1);
      this.selectedTemplates.splice(this.selectedTemplates.indexOf(app), 1);
    }

    if (this.selectedTemplates.length === 0) {
      this.placeholderText = 'Please enter the name of similar products';
    } else if (this.selectedTemplates.length === 1) {
      this.placeholderText = 'add more ...';
    }

    const platforms = this.dataService.homePageData.platforms;
    for (const platform of platforms) {
      platform.selected = false;
      for (const app of this.selectedTemplates) {
        for (const id of app.platform_ids) {
          if (id === platform.id) {
            platform.selected = true;
            if (this.selectedPlatforms.indexOf(platform) === -1) {
              this.selectedPlatforms.push(platform);
            }
            if (this.selectedHash.platform_ids.indexOf(platform.id) === -1) {
              this.selectedHash.platform_ids.push(platform.id);
            }
            this.chatSelections.platforms = this.selectedPlatforms.map(platform => platform.title).join(', ');
            break;
          }
        }
      }
    }
  }

  public calculateRealTimeElapsed() {
    let pageLoaded;
    let timeFromNow: Observable<string>;

    pageLoaded = this.moment(new Date());
    timeFromNow = interval(1000).pipe(
      map(() => pageLoaded.fromNow()),
      distinctUntilChanged()
    );
    return timeFromNow;
  }

  public submitForm(form) {
    this.showLoading = true;
    setTimeout(() => {
      this.showLoading = false;
      this.chatSelections.email = form.value.email.toLowerCase();
      this.chatQuestions.name.active = false;
      this.checkEmailRegistered(this.chatSelections.email);
    }, 3000);
  }

  public selectPlatforms(platform, event, index) {
    if (event.target.checked) {
      platform.selected = true;
      const index = this.selectedPlatforms.findIndex(plt => plt.id === platform.id);
      if (index === -1) {
        this.selectedPlatforms.push(platform);
        this.selectedHash.platform_ids.push(platform.id);
      }
    } else {
      platform.selected = false;
      const index = this.selectedPlatforms.findIndex(plt => plt.id === platform.id);
      if (index > -1) {
        this.selectedPlatforms.splice(index, 1);
      }

      const index1 = this.selectedHash.platform_ids.findIndex(id => id === platform.id);
      if (index1 > -1) {
        this.selectedHash.platform_ids.splice(index1, 1);
      }
    }
    this.chatSelections.platforms = this.selectedPlatforms.map(platform => platform.title).join(', ');
  }

  public submitUserDetails(form) {
    this.registerUser();
  }

  public toggleChatView(event) {
    event.stopPropagation();
    this.isChatHidden = !this.isChatHidden;
    if (this.isChatHidden) {
      // this.setAnalyticsForChatbot('close');
    }
    this.phaseService.showHelloText = false;
    if (!this.loaded) {
      // this.setAnalyticsForChatbot('sessionStarts');
      this.loadInitialData();
      this.loaded = true;
    }
  }

  public resetChatbot() {
    this.resetChatViews();
    this.resetChatSelections();
    this.phaseService.isSignupByChatbot = false;
    this.selectedPlatforms = [];
    this.selectedHash.platform_ids = [];
    this.selectedHash.application_ids = [];
    this.selectedHash.pricing_params = {};
    this.selectedHash.build_phase_vars = [];
    this.selectedTemplates = [];
    // this.dataService.apps = [];
    this.placeholderText = 'Please enter the name of similar products';
    this.currentStep = 0;
    this.userPassword = null;
    if (this.userDetailForm) {
      this.userDetailForm.reset();
    }
    if (this.emailForm) {
      this.emailForm.reset();
    }
    this.showLoading = true;
    // this.setAnalyticsForChatbot('refresh');
    setTimeout(() => {
      this.currentStep = 1;
      this.showLoading = false;
      this.chatQuestions.introduction.active = true;
    }, 3000);
    this.loadInitialData();
    this.loaded = true;
  }

  public createSubscriber() {
    this.loginUser();
  }

  public autoScrollToBottom() {
    const element = this.mainContent;
    element.scrollTop = element.scrollHeight;
  }

  public requestAutocompleteItems = (text: string): Observable<Response> => {
    const API = environment.API_URL + Constants.apiEndPoints.autocomplete_templates + '?title=' + text;
    return this.http
      .get(API)
      .pipe(map(data => data.json().map(item => item.title)));
  };

  public searchTemplates(query) {
    this.dataService.apps = [];
    this.apiRequest.searchTemplatesChatbot(query)
      .subscribe(
        () => {
          this.showDropdown = true;
          this.selectedTemplates.forEach(template => {
            this.dataService.apps.splice(template, 1);
          });
        }
      );
  }

  public registerUser() {
    this.isSubmitted = true;
    this.onFocus = this.checkIfMobileValid();
    const obj = {
      first_name: this.userDetailForm.value.name,
      last_name: ' ',
      email: this.emailForm.value.email,
      password: 'default',
      phone_number: this.userDetailForm.value.phone,
      user_type: 'User'
    };
    this.showLoading = true;
    this.apiRequest.login_signup(obj, 'signup')
      .subscribe(
        (data) => {
          this.phaseService.fetchUserShowData();
          this.phaseService.isSignupByChatbot = true;
          this.showLoading = false;
          this.dataService.storeUserObject();
          this.showLoading = true;
          setTimeout(() => {
            this.showLoading = false;
            this.currentStep = 13;
          }, 3000);
          const evt = new CustomEvent('SignupEvent', {detail: data});
          window.dispatchEvent(evt);
        },
        (error) => {
          this.showLoading = false;
        }
      );

  }

  public checkEmailRegistered(email) {
    this.showLoading = true;
    this.apiRequest.checkEmailRegistered(email)
      .subscribe(
        (data) => {
          this.showLoading = false;
          if (data.message) {
            this.showLoading = true;
            this.emailType = 'registered';
            setTimeout(() => {
              this.currentStep = 12;
              this.showLoading = false;
            }, 3000);
            // this.setAnalyticsForChatbot('email_submit_oldUser');
          }
        },
        (error) => {
          setTimeout(() => {
            this.currentStep = 11;
            this.emailType = 'non-registered';
            this.showLoading = false;
            // this.setAnalyticsForChatbot('email_submit_newUser');
          }, 3000);
        }
      );
  }

  public loginUser() {
    const obj = {
      email: this.emailForm.value.email.toLowerCase(),
      password: this.userPassword
    };
    this.showLoading = true;
    this.apiRequest.login_signup(obj, 'login')
      .subscribe(
        (data) => {
          this.showLoading = false;
          this.dataService.storeUserObject();
          const evt = new CustomEvent('LoginEvent', {detail: data});
          window.dispatchEvent(evt);
          this.createBuildCard();
        },
        (error) => {
          this.showLoading = false;
          const err = error.error;
          if (err.errors) {
            this.errorMessage = err.errors;
          }
        }
      );
  }

  public getCookie(cname) {
    if (this.commonService.isPlatformBrowser) {
      let name = cname + '=';
      let decodedCookie = decodeURI(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    } else {
      return '';
    }
  }

  public isUserLoggedIn() {
    return this.getCookie(this.dataService.user_cookie);
  }

  public getFirstName() {
    return this.getCookie(this.dataService.user_cookie) && JSON.parse(this.getCookie(this.dataService.user_cookie)).first_name;
  }
  public getNewUpdatedDate(phase, dayCount) {
    const updatedDate = new Date(phase.initial_date);
    updatedDate.setDate(updatedDate.getDate() + dayCount);
    return updatedDate;
  }
  public sortPhases() {
    this.selectedPhases = this.selectedPhases.sort((a, b) => {
      return a.position - b.position;
    });
  }
  public getNewPhaseDates() {
    if (this.selectedPhases && this.selectedPhases.length > 0) {
      this.sortPhases();
      this.selectedPhases.forEach((phase, index) => {
        if (this.phaseData && this.phaseData.length > 0) {
          if (this.phaseData.filter(data => (data.id === phase.id))) {
            const dayCount = this.phaseData.filter(data => (data.id === phase.id))[0].delivery_time;
            phase.delivery_time = dayCount;
            if (index === 0) {
              phase.initial_date = new Date();
              phase.final_date = this.getNewUpdatedDate(phase, dayCount);
            } else {
              phase.initial_date = this.moment(this.selectedPhases[index - 1].final_date).add(1, 'days');
              phase.final_date = this.getNewUpdatedDate(phase, dayCount);
            }
          }
        }
      });
    }
  }
  public calculateFeatureCostAndWeeks(features) {
    let featureCost = 0;
    let featureWeeks = 0;

    if (features) {
      for (const feature of features) {
        if (feature.price && feature.weeks) {
          featureCost += feature.feature_price;
          featureWeeks += feature.weeks;
        }
      }
      return {price: featureCost, weeks: featureWeeks};
    }
  }
  public getBuildCardPrice(buildCardSelections) {
    this.apiRequest.getBuildCardPrice(buildCardSelections)
      .subscribe(
        (data) => {
          this.finalPricenWeeks = {
            total: this.phaseService.getPriceForCards(data),
            weeks: data.weeks
          };
          this.phaseData = new Array<PhaseDataModel>();
          if (data.phase_data && data.phase_data.length > 0) {
            data.phase_data.forEach(phase => {
              const tempPhase = new PhaseDataModel();
              for (const key in phase) {
                tempPhase[key] = phase[key];
              }
              this.phaseData.push(tempPhase);
            });
          }

          this.supportMaintenance = data.builder_care_price;
          if (this.phaseService.getDiscountedPriceForCards(data)) {
            this.discountedPrice = this.phaseService.getDiscountedPriceForCards(data);
          } else {
            this.discountedPrice = this.finalPricenWeeks.total;
          }
          this.getNewPhaseDates();
        }

      );
  }
  public createBuildCardPriceHash(){
    this.buildCardSelections = {
      currency: {
        multiplier: this.dataService.defaultCurrency.multiplier,
        exchange_rate: this.dataService.defaultCurrency.exchange_rate,
        code: this.dataService.defaultCurrency.code
      }
    };
    if (this.selectedTeam) {
      this.buildCardSelections.team = {
        id: this.selectedTeam.id,
        price_multiplier: this.selectedTeam.price_multiplier,
        week_multiplier: this.selectedTeam.week_multiplier
      };
    } else {
      this.buildCardSelections.team = {
        price_multiplier: 1,
        week_multiplier: 1
      };
    }
    this.buildCardSelections.partner_multiplier = this.dataService.homePageData.builder_partner.partner_multiplier;
    this.buildCardSelections.build_card_phases = {};
    const promotion = this.dataService.homePageData.promotion || this.dataService.manualPromotion;
    if (promotion) {
      if (promotion.id) {
        this.buildCardSelections.promotion = promotion;
        if (promotion.features ) {
          const promotionFeatureIds = [];
          promotion.features.forEach(feature => {
            promotionFeatureIds.push(feature.id);
          });
          this.buildCardSelections.promotion.feature_ids = promotionFeatureIds;
        }
        if (promotion.platforms) {
          const promoPlatformIds = [];
          promotion.platforms.forEach(pltfrm => {
            promoPlatformIds.push(pltfrm.id);
          });
          this.buildCardSelections.promotion.platform_ids = promoPlatformIds;
        }

        if (promotion.applications && promotion.applications.length > 0) {
          const promoAppIds = [];
          promotion.applications.forEach(app => {
            promoAppIds.push(app.id);
          });
          this.buildCardSelections.promotion.application_ids = promoAppIds;
        }
        this.selectedHash.promotion_id = promotion.id;
    }
  }

    const featurePriceWeeksForOtherPhases = this.calculateFeatureCostAndWeeks(this.selectedFeatures);
    if (this.dataService.homePageData.build_phases && this.dataService.homePageData.build_phases.length > 0) {
      this.dataService.homePageData.build_phases.forEach(phase => {
        this.setDataInPhases(phase);
        this.selectedPhases.push(phase);
       // if (phase.selected) {
        phase.is_parallel_platform = true;
        phase.features = this.selectedFeatures;
        if (this.dataService.homePageData.speed && this.dataService.homePageData.speed.length > 0) {
          phase.speed = this.dataService.homePageData.speed.find(speed => speed.title === 'Relaxed');
        }
          const phaseTitle = phase.title.toLowerCase().replace(' ', '_');
          if (phase.title !== 'MVP' && phase.title !== 'Full Build') {
            this.buildCardSelections.build_card_phases[phaseTitle] = {
              features_price: featurePriceWeeksForOtherPhases.price,
              features_weeks: featurePriceWeeksForOtherPhases.weeks,
              price_multiplier: phase.price_multiplier,
              week_multiplier: phase.week_multiplier,
              platforms: phase.platforms
            };
            if (phase.speed) {
              this.buildCardSelections.build_card_phases[phaseTitle].speed = phase.speed;
            }
          } else {
            this.buildCardSelections.build_card_phases[phaseTitle] = {
              platforms: phase.platforms,
              speed: phase.speed,
              week_multiplier: phase.week_multiplier,
              price_multiplier: phase.price_multiplier
            };
          }
        if (phase.features && phase.features.length > 0) {
          phase.features = this.phaseService.uniqueArrayObjectSet(phase.features);
        }
        if ((phase['title'] === 'MVP')) {
          this.buildCardSelections.build_card_phases[phaseTitle].features = phase.features;
        }
        this.buildCardSelections.build_card_phases[phaseTitle].is_parallel_development = phase.is_parallel_platform;
        this.buildCardSelections.build_card_phases[phaseTitle].id = phase.id;
      });
    }
    this.buildCardSelections.application_ids = this.selectedHash.application_ids;
    if(this.dataService.homePageData.speed && this.dataService.homePageData.speed.length > 0) {
      this.buildCardSelections.relaxed_speed = this.dataService.homePageData.speed.find(speed => speed.title === 'Relaxed');
    }
    this.buildCardSelections['features'] = this.selectedFeatures;
    // this.getBuildCardPrice(this.buildCardSelections);
    this.selectedHash.pricing_params = this.buildCardSelections;
    this.selectedHash.currency_id = this.dataService.homePageData.currency.id;
  }

  public createBuildCard() {
    this.showLoading = true;
    if (this.isUserLoggedIn()) {
      this.selectedHash.status = 'card_completed';
      this.selectedPhases = [];
    /*  this.dataService.homePageData.build_phases.forEach(phase => {
        this.setDataInPhases(phase);
        this.selectedPhases.push(phase);
      });*/
      this.createBuildCardPriceHash();
      this.apiRequest.createUpdateBuildCard(this.selectedHash, null, true).subscribe(
        (data) => {

          this.currentStep = 14;
          this.buildCardUrl = this.getHostedUrl() + data.uniq_code;
          this.showLoading = false;
        },
        (error) => {
          if (error.status === 403 || error.status === 401) {
            this.dataService.user = null;
            // remove cookie here
            this.dataService.unsetCookie();
            this.phaseService.clearLocalStorage();
            setTimeout(() => {
              this.currentStep = 10;
              this.showLoading = false;
            }, 3000);
          }
          this.showLoading = false;
        }
      );
    } else {
      setTimeout(() => {
        this.currentStep = 10;
        this.showLoading = false;
      }, 3000);

    }
  }

  public getHostedUrl() {
    return window.location.origin + '/build-card/';
  }

  public toggleArray(array, id) {
    const index = array.indexOf(id);
    if (index === -1) {
      array.push(id);
    } else {
      array.splice(index, 1);
    }
  }

  public setTemplateName() {
    this.selectedHash.customized_template_name = '';
    let index = 0;
    for (const app of this.selectedTemplates) {
      if (index > 0) {
        if (index === this.selectedTemplates.length - 1) {
          this.selectedHash.customized_template_name += ' and ';
        } else {
          this.selectedHash.customized_template_name += ', ';
        }
      }
      this.selectedHash.customized_template_name += app.title;
      index++;
    }
  }

  public openHelloText(event) {
    event.stopPropagation();
    this.phaseService.showHelloText = false;
    this.isChatHidden = true;
  }

  public goToBuildCard() {
    window.open(this.buildCardUrl, '_blank', "noopener");
  }

  public onGoogleSignup() {
    this.auth.signIn(GoogleLoginProvider.PROVIDER_ID);
    this.auth.authState.subscribe(
      (data: any) => {
        this.dataService.socialSignUser = data;
        this.apiRequest.login_signup(data, 'login').subscribe((subdata) => {
          this.phaseService.fetchUserShowData();
          this.phaseService.isSignupByChatbot = true;
          const evt = new CustomEvent('SignupEvent', {detail: subdata});
          window.dispatchEvent(evt);
          this.dataService.storeUserObject();
          this.createBuildCard();
        });
      });
  }

  public setDataInPhases(phase) {
    if (this.selectedPlatforms.length > 0) {
      phase.platforms = this.selectedPlatforms;
    } else {
      this.selectedPlatforms.push(this.dataService.homePageData.platforms[0]);
      phase.platforms = this.selectedPlatforms;
    }
    phase.is_parallel_platform = true;
    phase.features = this.selectedFeatures;
    if (phase.title === 'MVP') {
      phase.features.forEach(feature => {
        feature.platforms = phase.platforms;
      });
    }
    if (this.dataService.homePageData.speed && this.dataService.homePageData.speed.length > 0) {
      phase.speed = this.dataService.homePageData.speed.find(speed => speed.title === 'Relaxed');
    }
  }

  public setZIndex() {
    return (
      this.dataService.showCurrencyAfterLogin &&
      this.dataService.homePageData &&
      this.dataService.homePageData.currencies &&
      this.dataService.homePageData.currencies.length > 0
    );
  }

  public onFlagChange() {
    if (this.mobileNumber && this.userDetailForm.get('phone').valid && this.mobileNumber.selectedCountry) {
      this.phaseService.countryNameCode = this.mobileNumber.selectedCountry.countryCode;
      this.dataService.countryNameCode = this.mobileNumber.selectedCountry.dialCode;
    }
  }

  focusInNumber(event) {
    this.onFocus = true;
  }

  public checkIfMobileValid(): boolean {
    if (this.isSubmitted) {
      return this.userDetailForm.value.phone && !!this.userDetailForm.value.phone.errors && (this.userDetailForm.value.phone.invalid || this.userDetailForm.value.phone.pristine);
    } else {
      return this.userDetailForm.value.phone && !!this.userDetailForm.value.phone.errors && (this.userDetailForm.value.phone.dirty || this.userDetailForm.value.phone.touched);
    }
  }

}
