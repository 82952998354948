import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {Angulartics2Segment} from 'angulartics2/segment';

@Component({
  selector: 'payment-container',
  templateUrl: 'payment-container.component.html'
})

export class PaymentContainerComponent {

  /*@ngInject*/

  constructor(public phaseService: PhaseService, public dataService: DataService, public router: Router, public analyticsSegment: Angulartics2Segment) {

  }


}
