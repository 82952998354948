/**
 * Created by nitin on 14/12/17.
 */
export class PlatformModel {
  background: string;

  description: string;

  icon: string;

  id: number;

  price_multiplier: number;

  title: string;

  week_multiplier: number;

  selected: boolean;

  device: string;

  name: string;

  device_icon: string;
}
