<div class="dashboardLoading" *ngIf="!dataService.loadFilteredData">
  <!--      <div class="dashboardLoading" *ngIf="true">-->
  <app-dashboard-loading></app-dashboard-loading>
</div>
<!--payment status page start-->
<app-payment-area (pexitPaymentStatus)="exitPaymentStatus()" [user]='user'> </app-payment-area>
<div *ngIf="!getPaymentShowStatus()">
  <!-- <app-no-cards   (pshowCards)="showCards($event)" [showCompleted]='showCompleted' [showInProgress]='showInProgress' ></app-no-cards> -->
  <div *ngIf="!getIsProfile() && !referralService.showReferralWallet && !getTaxAndBilling()">
    <div class="cardUpdateNotify" *ngIf="dataService.allBuildCards?.updated_build_card_count > 0">
      {{ dataService.allBuildCards?.updated_build_card_count }}
      {{ dataService.allBuildCards?.updated_build_card_count === 1 ? "Card" : "Cards" }}
      Updated <span
        *ngIf="dataService.allBuildCards?.updated_build_card_count === 1">{{ dataService.allBuildCards?.updated_build_card_time }}</span>
      <div class="sectionClose" (click)="deactivateEdit()"><em class="icon-cancel"></em></div>
    </div>

    <!--Spec Call Details section start-->
    <div *ngIf="dataService.specCallCount > 0 && showSpecCalls && dataService.loadFilteredData">
      <app-cards (pshowCards)="showCards($event)" [showCompleted]='showCompleted' [showInProgress]='showInProgress'
        [showPaid]='showPaid' [showRecentComplete]='showRecentComplete' [showSpecCalls]='showSpecCalls'
        [showRecentRunning]='showRecentRunning' [showRecentProgress]='showRecentProgress'
        [showRecentSpecCalls]='showRecentSpecCalls' [showInvited]='showInvited' [showArchived]='showArchived'
        [showMoreIndex]='showMoreIndex' >
      </app-cards>
    </div>
    <!--Spec Call Details section close-->
    <!--Demo Call Details section start-->
    <div *ngIf="dataService.demoCallCount > 0 && showDemoCalls && dataService.loadFilteredData">
      <app-demo-cards (pshowCards)="showCards($event)" [showCompleted]='showCompleted' [showInProgress]='showInProgress'
        [showPaid]='showPaid' [showRecentComplete]='showRecentComplete' [showSpecCalls]='showSpecCalls'
        [showRecentRunning]='showRecentRunning' [showRecentProgress]='showRecentProgress'
        [showRecentSpecCalls]='showRecentSpecCalls' [showInvited]='showInvited' [showArchived]='showArchived'
        [showMoreIndex]='showMoreIndex' [showDemoCalls]='showDemoCalls'>
      </app-demo-cards>
    </div>
    <!--Demo Call Details section close-->
    <!--Running Cards Details section start-->
    <div *ngIf="showPaid && dataService.loadFilteredData">
      <app-running-cards (pshowCards)="showCards($event)" [showCompleted]='showCompleted'
        [showInProgress]='showInProgress' [showPaid]='showPaid' [showRecentComplete]='showRecentComplete'
        [showSpecCalls]='showSpecCalls' [showRecentRunning]='showRecentRunning'
        [showRecentProgress]='showRecentProgress' [showRecentSpecCalls]='showRecentSpecCalls'
        [showInvited]='showInvited' [showArchived]='showArchived'  [preventClick]="preventClick"
        [showMoreIndex]='showMoreIndex' >
      </app-running-cards>
    </div>
    <!--Running Cards Details section end-->
    <!--Invited Cards Details section start-->
    <div *ngIf="showInvited && dataService.loadFilteredData">
      <app-invited-cards (pshowCards)="showCards($event)" [showCompleted]='showCompleted'
        [showInProgress]='showInProgress' [showPaid]='showPaid' [showRecentComplete]='showRecentComplete'
        [showSpecCalls]='showSpecCalls' [showRecentRunning]='showRecentRunning' [showRecentInvited]="showRecentInvited"
        [showRecentProgress]='showRecentProgress' [showRecentSpecCalls]='showRecentSpecCalls'
        [showInvited]='showInvited' [showArchived]='showArchived' [cardToDelete]="cardToDelete"
        [preventClick]="preventClick" [showMoreIndex]='showMoreIndex' [showRecentInvited]="showRecentInvited"
        ></app-invited-cards>
    </div>
    <!--Invited Cards Details section end-->
    <!--Completed Cards Details section start-->
    <div *ngIf="showCompleted && dataService.loadFilteredData">
      <app-completed-cards (pshowCards)="showCards($event)" [showCompleted]='showCompleted'
        [showInProgress]='showInProgress' [showPaid]='showPaid' [showRecentComplete]='showRecentComplete'
        [showSpecCalls]='showSpecCalls' [showRecentRunning]='showRecentRunning'
        [showRecentProgress]='showRecentProgress' [showRecentSpecCalls]='showRecentSpecCalls'
        [showInvited]='showInvited' [showArchived]='showArchived' [cardToDelete]="cardToDelete" [preventClick]="preventClick"
        [showMoreIndex]='showMoreIndex' ></app-completed-cards>
    </div>
    <!--Completed Cards Details section end-->
    <!--Saved Cards Details section start-->
    <div *ngIf="showInProgress && dataService.loadFilteredData">
      <app-saved-cards (pshowCards)="showCards($event)" (pshowRenameCard)="showRenameCard($event)" [showCompleted]='showCompleted'
        [showInProgress]='showInProgress' [showPaid]='showPaid' [showRecentComplete]='showRecentComplete'
        [showSpecCalls]='showSpecCalls' [showRecentRunning]='showRecentRunning'
        [showRecentProgress]='showRecentProgress' [showRecentSpecCalls]='showRecentSpecCalls'
        [showInvited]='showInvited' [showArchived]='showArchived' [preventClick]="preventClick"
        [showMoreIndex]='showMoreIndex'>
      </app-saved-cards>
    </div>
    <!--Saved Cards Details section end-->
    <!--Archived Cards Details section start-->
    <div *ngIf="showArchived && dataService.loadFilteredData">
      <app-archived-cards (pshowCards)="showCards($event)" [showCompleted]='showCompleted'
        [showInProgress]='showInProgress' [showPaid]='showPaid' [showRecentComplete]='showRecentComplete'
        [showSpecCalls]='showSpecCalls' [showRecentRunning]='showRecentRunning'
        [showRecentProgress]='showRecentProgress' [showRecentArchived]='showRecentArchived'
        [showInvited]='showInvited' [showArchived]='showArchived'  [preventClick]="preventClick" [showMoreIndex]='showMoreIndex'>
      </app-archived-cards>
    </div>
    <!--Archived Cards Details section end-->

    <!--Completed project cards section start-->
    <div *ngIf="(getCardCount('project_completed') > 0) && showProjectCompleted && dataService.loadFilteredData">
      <app-completed-project-cards (pshowCards)="showCards($event)" [showMoreIndex]='showMoreIndex'
                                   [showRecentProgress]='showRecentProgress' [showRecentCompletedProjects]="showRecentCompletedProjects">
      </app-completed-project-cards>
    </div>
    <!--Completed project cards section close-->


    <div *ngIf="showAdditionalFeatures && dataService.changeFeatureRequestArrayModel?.length > 0">
      <app-additional-features [preventClick]="preventClick"></app-additional-features>
    </div>
    <!-- Payment Cards section start -->
    <div *ngIf="showPaymentCards">
      <app-payment-cards></app-payment-cards>
    </div>

    <div *ngIf="getCardCount('custom_prototype') > 0 && showCustomPrototype && dataService.loadFilteredData">
      <app-custom-prototype-cards (pshowCards)="showCards($event)" [showRecentProgress]="showRecentProgress"
                                  [showMoreIndex]='showMoreIndex' [showRecentCustomPrototype]="showRecentCustomPrototype">
      </app-custom-prototype-cards>
    </div>

    <div *ngIf="getCardCount('tailor_made_prototype') > 0 && showTailorMadePrototype && dataService.loadFilteredData">
      <app-tailor-made-prototype-cards (pshowCards)="showCards($event)" [showRecentProgress]="showRecentProgress"
                                        [showMoreIndex]='showMoreIndex' [showRecentTailorMadePrototype]="showRecentTailorMadePrototype">
      </app-tailor-made-prototype-cards>
    </div>

    <div *ngIf="getCardCount('prototype') > 0 && showPrototype && dataService.loadFilteredData">
      <app-prototype-cards (pshowCards)="showCards($event)" [showRecentProgress]="showRecentProgress"
                           [showMoreIndex]='showMoreIndex' [showRecentPrototype]="showRecentPrototype">
      </app-prototype-cards>
    </div>

    <div *ngIf="showFreemiumCards && dataService.loadFilteredData">
      <app-freemium-cards >
      </app-freemium-cards>
    </div>
    <!-- Payment Cards section end -->
  </div>
</div>

<app-profile-view *ngIf="getIsProfile()" (pselectUserProfile)="selectUserProfile($event)" (pshowTooltip)="showTooltip($event)" ></app-profile-view>
<app-referral-wallet *ngIf="referralService.showReferralWallet"></app-referral-wallet>

<app-tax-details *ngIf="getTaxAndBilling()" ></app-tax-details>
