<div class="commonButton manage-btn">
  <form #invoiceForm class="simple_form form-horizontal" novalidate="novalidate" accept-charset="UTF-8" method="post"
        id="specingPaymentForm"
        action="{{(phaseService.totalDepositAmount === 0) ? redirectToPaymentStatus() : getPaymentsUrl()}}">
    <div class="hideForm">
      <input type="hidden" name="apptoken"
             value="{{getAppToken()}}">
      <div class="form-group">
        <div class="row">
          <input class="form-control string email required" [value]="dataService?.user?.email" type="hidden"
                 placeholder="Email" name="payment_data[email]" id="payment_data_email">
          <input class="form-control string required" [value]="dataService?.user?.billing_entity" type="hidden"
                 placeholder="billing_entity" name="payment_data[billing_entity]" id="payment_data_billing_entity">
          <input class="form-control string required" [value]="dataService?.buildCardData?.id" type="hidden"
                 placeholder="build_card_id" name="payment_data[build_card_id]" id="payment_data_build_card_id">
          <input class="form-control string required" [value]="dataService?.buildCardData?.project_name" type="hidden"
                 placeholder="build_card_name" name="payment_data[build_card_name]" id="payment_data_build_card_name">
          <input class="form-control string required" [value]="dataService?.buildCardData?.frequency" type="hidden"
                 placeholder="frequency" name="payment_data[frequency]" id="payment_data_frequency">
          <input class="form-control string required" [value]="phaseService?.getFirstNonZeroInstallmentAmount()" placeholder="installment_amount" type="hidden"
                 name="payment_data[installment_amount]" id="payment_data_installment_amount">
          <input class="form-control string required" [value]="phaseService?.getInstallmentCountExceptDeposit()" placeholder="installment_count" type="hidden"
                 name="payment_data[installment_count]" id="payment_data_installment_count">
          <input class="form-control string required" [value]="phaseService?.paymentData?.pending_installment?.type"
                 placeholder="installment_type" type="hidden" name="payment_data[installment_type]" id="payment_data_installment_type">

          <!-- <input class="form-control string required" [value]="paymentAmount" placeholder="Amount" type="hidden"
                 name="payment_data[amount]" id="payment_data_amount"> -->
          <!-- <input class="form-control string required" [value]="taxAmount" placeholder="taxAmount" type="hidden"
                 name="payment_data[tax_amount]" id="payment_tax_amount"> -->
          <input class="form-control string required" [value]="paymentAmountWithoutTax" placeholder="paymentAmountWithoutTax" type="hidden"
                 name="payment_data[sub_total_amount]" id="sub_total_amount">
          <input class="form-control string required" [value]="'Payment for Specing on Builder'" type="hidden"
                 placeholder="Description" name="payment_data[description]" id="payment_data_description">
          <input class="form-control string required"
                 [value]="getCurrencyCode()" placeholder="currency"
                 type="hidden" name="payment_data[currency]" id="payment_data_currency">
          <input class="form-control string required" value="true" placeholder="capture" type="hidden"
                 name="payment_data[capture]" id="payment_data_capture">
          <input class="form-control string required" value="Engineer.ai" type="hidden"
                 placeholder="source" name="payment_data[source]" id="payment_data_source">
          <input class="form-control string url required" [value]="getRedirectUrl()" type="hidden"
                 placeholder="redirect_url" name="payment_data[redirect_url]"
                 id="payment_data_redirect_url">
          <input class="form-control string url required" [value]="getRedirectUrl()" type="hidden"
                 placeholder="frontend_redirect_url" name="payment_data[frontend_redirect_url]"
                 id="payment_data_frontend_redirect_url">
          <input class="form-control string url required" id="payment_data_dashboard_url"
                 placeholder="redirect_dashboard_url" name="payment_data[redirect_dashboard_url]" type="hidden"
                 [value]="getDashboardUrl()"/>
          <input class="form-control string url required" id="payment_data_payment_plan"
                 placeholder="redirect_payment_plan" name="payment_data[redirect_payment_plan]" type="hidden"
                 [value]="getPaymentPlanUrl()"/>
          <input class="form-control string url required" id="payment_data_billing_details"
                 placeholder="redirect_billing_details" name="payment_data[redirect_billing_details]" type="hidden"
                 [value]="getBillingDetailsUrl()"/>
          <input class="form-control string required" [value]="getPaymentId()" placeholder="installment_id" type="hidden"
                 name="payment_data[source_model_guid]" id="payment_data_source_model_guid">
          <input class="form-control string required" [value]="buildCardInstallment ? 'Installment' : 'SpecCall'"
                 placeholder="installment_id" type="hidden"
                 name="payment_data[source_model_name]" id="payment_data_source_model_name">
          <input class="form-control string required" value="{{getPaymentGateway()}}" placeholder="payment_gateway" type="hidden"
                 name="payment_data[payment_gateway]" id="payment_data_payment_gateway">
          <input class="form-control string url required" type="hidden"
                 [value]="getAPIURL() + 'payment_billing/installments/status'"
                 placeholder="Notify-URL" name="payment_data[payment_notify_url]"
                 id="payment_data_notify_url">
          <input class="form-control string url required"
                 [value]="getAPIURL() + 'payment_billing/builder_card_owners/card_status'" type="hidden"
                 placeholder="Notify-URL" name="payment_data[card_notify_url]"
                 id="payment_data_card_notify_url">
          <input id="payment_data_mode" name="payment_data[payment_mode]" type="hidden"
                 value="complete">
          <input id="payment_data_billing_name" *ngIf="billingInfo"
                 name="payment_data[billing_address_attributes][billing_name]" type="text" type=hidden
                 value="{{billingInfo.name}}"/>
          <input id="payment_data_billing_tel" *ngIf="billingInfo"
                 name="payment_data[billing_address_attributes][billing_tel]" type="text" type=hidden
                 value="{{billingInfo.contact}}"/>
          <input id="payment_data_billing_address" *ngIf="billingInfo"
                 name="payment_data[billing_address_attributes][billing_address]" type="text" type=hidden
                 value="{{billingInfo.address}}"/>
          <input id="payment_data_billing_city" *ngIf="billingInfo"
                 name="payment_data[billing_address_attributes][billing_city]" type="text" type=hidden
                 value="{{billingInfo.city}}"/>
          <input id="payment_data_billing_state" *ngIf="billingInfo"
                 name="payment_data[billing_address_attributes][billing_state]" type="text" type=hidden
                 value="{{billingInfo.state}}"/>
          <input id="payment_data_billing_zip" *ngIf="billingInfo"
                 name="payment_data[billing_address_attributes][billing_zip]" type="text" type=hidden
                 value="{{billingInfo.pincode}}"/>

          <button type="button" class="continueButton" (click)="gotoStatus(invoiceForm)" *ngIf="buildCardInstallment && !isFromPayConfirm">
            Proceed to payment
          </button>
          <button type="button" class="payNowBtn onlyShowDesktop" (click)="gotoStatus(invoiceForm)"
                  [ngClass]="{'custom-disabled': disablePaymentButton(),
                              'disable-continue-button': (((dataService.currentPage === 'paymentPlan') ? checkToDisable : false) && (dataService.currentPage === 'paymentPlan'))}"
                  *ngIf="(buildCardInstallment && isFromPayConfirm && !isFromHeader && !appDataService?.urlParameters?.isCustomPrototype && !isFromBuilderCare)"
                  (mouseover)="toggleToolTip(true)" (mouseleave)="toggleToolTip(false)">
            {{ getButtonText() }}
          </button>
          <button type="button" class="payNowBtn onlyShowDesktop" (click)="gotoStatus(invoiceForm)"
                  [ngClass]="{'custom-disabled': (dataService.checkIfCPEUser() && (dataService.currentPage === 'billingDetails')),
                              'disable-continue-button': (((dataService.currentPage === 'paymentPlan') ? false : checkToDisable) && (dataService.currentPage === 'billingDetails'))}"
                  *ngIf="!paymentService.checkIfOnlyProfessionalPrototype() && buildCardInstallment && isFromPayConfirm && !isFromHeader && appDataService?.urlParameters?.isCustomPrototype && (dataService.currentPage === 'paymentPlan')"
                  (mouseover)="toggleToolTip(true)" (mouseleave)="toggleToolTip(false)">
            CONTINUE
          </button>
          <span *ngIf="checkToDisable && (dataService.currentPage === 'paymentPlan')" class="onlyShowDesktop tooltip-right-arrow">
            Please tick the terms and conditions.
          </span>
          <button class="payment-for-header" *ngIf="isFromHeader"
                  [ngClass]="{ 'active': dataService.currentPage === 'billingDetails', 'disable': !phaseService.isValidToProceedForPayment }"
                  (click)="gotoStatus(invoiceForm)">
            Confirm and Pay
          </button>
          <!-- only mobile btn -->
          <button type="button" class="payNowBtn onlyShowMobile" (click)="gotoStatus(invoiceForm)" *ngIf="buildCardInstallment && isFromPayConfirm">
              {{ getButtonText() }}
          </button>
          <div class="mobileValidation" *ngIf="isMobile && showTooltipForMobile && (dataService.currentPage === 'paymentPlan')">
            <em class="icon-info"></em> Please tick the terms and conditions.
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
