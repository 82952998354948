<div class="commonPopUp" [ngClass]="{'active': showAddNewTemplate()}">
  <div class="popOverlay"></div>
  <div class="popHolder addNewTemplate">
    <div class="closePopUp" (click)="closeAddTemplatePopup();addNewTemplateForm.reset();"><span
      class="icon-cancel"></span></div>

    <div class="boxHolder">

      <form #addNewTemplateForm>
        <h3>Custom template</h3>
        <p>Custom template pricing and time may vary post proper understanding by team.</p>
        <ul class="formField">
          <li>
            <label>Template name</label>
            <input type="text" placeholder="Suggest the name of your template" #title="ngModel" name="title"
                   [(ngModel)]="getAddedTemplate().title" maxlength="100"  required/>
            <span class="errorMsg show" *ngIf="isValidTitle()">Please enter valid template name</span>
            <span class="errorMsg show" *ngIf="templateExist">This template already exists.</span>
          </li>

          <li>
            <label>Select a category?</label>
            <div class="customDropdown">
              <select id="category" name="category" [(ngModel)]="phaseService.selectedCategory">
                <option *ngFor="let category of getCategories()" [ngValue]="category">{{category.title}}</option>
              </select>
            </div>
          </li>

          <li>
            <label>Add links of similar templates * (Minimum 1 and Maximum 3 links)</label>
            <div class="similarUrlBox">
              <div class="tempUrlBox" *ngFor="let url of getAddedTemplate()?.reference_urls; let i = index">
              <span class="tempIcon">
                <img *ngIf="url.image" alt="" [src]="url?.image ? utilityService.addHttps(url?.image) : ''">
                <img *ngIf="!url.image" alt="" src="https://ph-files.imgix.net/f34d2dab-8fcc-491b-86a0-492663ea5a57?auto=format&amp;fit=crop&amp;h=30&amp;w=30">
              </span>

                <div class="tempName">{{ url?.product_name }}</div>
                <div class="tempRemove" (click)="deleteAppUrl(i)"><span class="icon-cancel"></span></div>
              </div>
            </div>

            <div class="relativeBox" [ngClass]="getAddedTemplate()?.reference_urls?.length < 3 ? 'active' : 'hidden'">
              <input type="text" placeholder="e.g., http://www.example.com" name="appurl" [(ngModel)]="singleUrl" #appUrl="ngModel"
                     (keyup)="searchProducts()">
              <button type="button" class="addUrl" (click)="addUrl()" *ngIf="showAddUrlButton()">Add</button>

              <div class="loaderSpin" *ngIf="showLoaderForUrl"><img src="../../../../../../assets/images/rolling.gif" alt=""/></div>

              <div class="autoSuggested" *ngIf="productsList.length > 0">
                <ol>
                  <li *ngFor="let url of productsList" (click)="selectProduct(url)">
                  <span class="tempIcon">
                    <img *ngIf="url.image" alt="" [src]="url?.image ? utilityService.addHttps(url?.image) : ''">
                    <img *ngIf="!url.image" alt="" src="https://ph-files.imgix.net/f34d2dab-8fcc-491b-86a0-492663ea5a57?auto=format&amp;fit=crop&amp;h=30&amp;w=30">
                  </span>
                  <span class="tempName">{{ url?.product_name }} {{ url?.description }}</span>
                  </li>
                </ol>
              </div>
            </div>
            <span class="errorMsg show" *ngIf="isLinkValid()">Please enter valid link</span>
          </li>

          <li>
            <label>Select at least one platform for your product</label>
            <div class="relativeBox">
              <ng-container *ngFor="let platform of dataService.homePageData.platforms">
                <div class="platformChip" [ngClass]="{'selected': isPlatformSelected(platform)}" (click)="selectPlatforms(platform)">{{ platform.title }}</div>
              </ng-container>
            </div>
          </li>
        </ul>

        <div class="footerButton">
          <button class="doneButton" type="button" (click)="addNewTemplate(addNewTemplateForm);" [disabled]="isDisableSubmit()">
            Create Template <img *ngIf="preventClick" src="../../../../../../assets/images/buttonLoader.gif" alt=""/>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
