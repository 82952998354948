<div class="commonPopUp active">
  <div class="popOverlay"></div>
  <div class="popHolder createCustomTemplate"> <!---- if height scrollable the then add class 'scrollFix'  -->
    <div class="new-CloseButton" (click)="closeAddTemplatePopup();addNewTemplateForm.reset();"><em class="icon-cancel"></em></div>
    <!--left section start-->
    <div class="loginLeft">
      <!--<div class="titletxt OnlyWeb">
        <h3>Create custom template </h3>
      </div>-->
      <img src="../../../../../assets/images/newsignuppopup.png" class="onlyMobile" alt="" />
    </div>
    <!--left section close-->

    <!-- right section start-->
    <div class="loginRight" (scroll)= "onScrollRightSection($event)">
      <!--- normal sign up -->
      <form class="formElements" #addNewTemplateForm>
        <div class="customScrollbar">
        <perfect-scrollbar class="myScrollbar" [config]="PERFECT_SCROLLBAR_CONFIG">
          <div class="titletxt">
            <h3>Create custom template </h3>
          </div>

          <p class="head">The price and delivery time of custom features may change once we understand exactly what you need.
          </p>

          <!-- form start--->
          <ul>
            <li>
              <label>Template name</label>
              <div class="errorMsgBox">
                <span class="errorMsg show" *ngIf="isValidTitle()">Please enter valid template name</span>
                <span class="errorMsg show" *ngIf="templateExist">This template already exists.</span>
              </div>
              <input type="text" placeholder="Enter a name for your template" #title="ngModel" name="title"
                    [(ngModel)]="getAddedTemplate().title" maxlength="100" (blur)="customTemplateAnalytics('template_name')" required/>

            </li>
            <li>
              <label>Please select a category</label>
              <div class="customDropdown" (click)="showCategories()">
                <!-- <select id="category" name="category" [(ngModel)]="phaseService.selectedCategory"
                        (change)="customTemplateAnalytics('category_selected')"
                        (click)="customTemplateAnalytics('category_dropdown')">
                  <option *ngFor="let category of getCategories()" [ngValue]="category">{{category.title}}</option>
                </select> -->
                <div class="categoryValue">
                  <span *ngIf="!phaseService.selectedCategory" class="placeholderTxt">Please select a category for your template</span>
                  <span *ngIf="phaseService.selectedCategory">{{ phaseService.selectedCategory.title }}</span>
                </div>
                <div class="categoryListing show" *ngIf="showCategoriesDropdown">
                  <perfect-scrollbar class="myScrollbar" [config]="PERFECT_SCROLLBAR_CONFIG">
                    <div class="categoryList" *ngFor="let category of getCategories()" (click)="setSelectedCategory(category)">{{category.title}} </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </li>
            <li>
              <label>Links to similar templates (min 1, max 3)</label>
              <div class="similarUrlBox">
                <div class="tempUrlBox" *ngFor="let url of getAddedTemplate()?.reference_urls; let i = index">
                <span class="tempIcon">
                  <img *ngIf="url.image" alt="" [src]="url?.image ? utilityService.addHttps(url?.image) : ''">
                  <img *ngIf="!url.image" alt="" src="https://ph-files.imgix.net/f34d2dab-8fcc-491b-86a0-492663ea5a57?auto=format&amp;fit=crop&amp;h=30&amp;w=30">
                </span>

                  <div class="tempName">{{ url?.product_name }}</div>
                  <div class="tempRemove" (click)="deleteAppUrl(i)"><span class="icon-cancel"></span></div>
                </div>
              </div>
              <div class="customDropdown searchListing" *ngIf="getAddedTemplate()?.reference_urls?.length < 3">
                <input type="text" placeholder="www.templatereference.com" name="appurl" [(ngModel)]="singleUrl" #appUrl="ngModel"
                      (keyup)="searchProducts()" (focus)="customTemplateAnalytics('add_similar')"/>
                <button type="button" class="addUrl" (click)="addUrl()" *ngIf="showAddUrlButton()">Add</button>
                <div class="loaderSpin" *ngIf="showLoaderForUrl"><img class="loadSpinwithAnim" src="../../../../../../assets/images/synchronize-arrow.svg" alt=""/></div>
                <div class="categoryListing show" *ngIf="productsList.length > 0"> <!--- ad class show droupdown "show"--->
                  <div class="categoryList" *ngFor="let url of productsList" (click)="selectProduct(url)">{{ url?.product_name }}</div>
                </div>
              </div>
              <span class="errorMsg show" *ngIf="isLinkValid()">Please enter valid link</span>
            </li>
          </ul>

          <div class="listBlock">
            <h2 class="listTitle">Choose platforms for your template </h2>
            <!--<div class="listing">
              <h3 class="listHead"> Mobile </h3>
              <p><input type="checkbox" id="one">
                <label for="one" class="icon-checkshape"> Native Mobile App for Apple iOS </label>
              </p>
              <p><input type="checkbox" id="two">
                <label for="two" class="icon-checkshape"> Native Mobile App for Apple iOS </label>
              </p>
              <p><input type="checkbox" id="three">
                <label for="three" class="icon-checkshape"> Native Mobile App for Apple iOS </label>
              </p>
              <p><input type="checkbox" id="four">
                <label for="four" class="icon-checkshape"> Native Mobile App for Apple iOS </label>
              </p>

            </div>-->
            <div class="listing">
              <h3 class="listHead"> Desktop </h3>
              <p *ngFor="let platform of filterPlatformsForWebOrMobile('web'); let i = index" (click)="selectPlatforms(platform)">
                <input type="checkbox" [checked]="isPlatformSelected(platform)">
                <label class="icon-checkshape">{{ platform.title }}</label>
              </p>
              <h3 class="listHead"> Mobile </h3>
              <p *ngFor="let platform of filterPlatformsForWebOrMobile('mobile'); let i = index" (click)="selectPlatforms(platform)">
                <input type="checkbox" [checked]="isPlatformSelected(platform)">
                <label class="icon-checkshape">{{ platform.title }}</label>
              </p>
            </div>

          </div>
        </perfect-scrollbar>
        </div>


        <div class="footersection">
          <div class="actionbtn">
            <button type="button" class="doneButton" (click)="addNewTemplate(addNewTemplateForm)" [disabled]="isDisableSubmit()"> Create custom template</button>
          </div>
        </div>
      </form>

    </div>
    <!--login right section close-->

  </div>
</div>
