import { Component, OnInit } from '@angular/core';
import { ReferralService } from '@shared/services/referral.service';
import { Options } from '@angular-slider/ngx-slider';
import {ActivatedRoute, Router} from '@angular/router';
import { DataService } from '@shared/services/data.service';
import { PhaseService } from '@shared/services/phase.service';
import { HeaderService } from '@shared/services/header.service';

@Component({
  selector: 'app-referral-points-usage',
  templateUrl: './referral-points-usage.component.html',
  styleUrls: ['./referral-points-usage.component.scss']
})
export class ReferralPointsUsageComponent implements OnInit {
  showReduceMonthlyPaymentPopup = false;
  showReduceBuilderCarePopup = false;
  showStartNewProjectPopup = false;
  sliderValue: number = 0;
  

  constructor(public referralService: ReferralService, public router: Router, public dataService: DataService,
    public phaseService: PhaseService, public headerService: HeaderService) { }

  ngOnInit(): void {
    if (typeof this.referralService.totalAmountInWallet === 'string') {
      this.referralService.referralPointsUsageSliderOptions = {
        showSelectionBar: true,
        floor: 0,
        ceil: parseInt(this.referralService.totalAmountInWallet.replace(/,/g, ''))
      };
    } else {
      this.referralService.referralPointsUsageSliderOptions = {
        showSelectionBar: true,
        floor: 0,
        ceil: this.referralService.totalAmountInWallet
      };
    }
    this.getListOfBuildcards();
    this.sliderValue = this.referralService.sliderValueAfterBuilderPointsReductionConfirm;
  }

  closeBuilderPointsUsagePopup() {
    this.referralService.showUseBuilderPointsPopup = false;
    this.showReduceMonthlyPaymentPopup = false;
    this.showReduceBuilderCarePopup = false;
    this.showStartNewProjectPopup = false;
    this.referralService.showConfirmBuilderPointsReductionPopup = false;
    this.referralService.builderPointsUsageAPISuccess = false;
  }

  openReduceMonthlyPaymentPopup() {
    if (this.referralService.listOfBuildCards && (this.referralService.listOfBuildCards.length === 0)) {
      return;
    }
    this.referralService.builderPointsUsageAPISuccess = false;
    this.showReduceMonthlyPaymentPopup = true;
    this.showReduceBuilderCarePopup = false;
    this.showStartNewProjectPopup = false;
    this.referralService.selectedBuildCard = this.referralService.listOfBuildCards[0];
    this.sliderValue = 0;
    this.getBuilderPointsUsageData();
  }

  openReduceBuilderCarePopup() {
    if (this.referralService.listOfBuildCards && (this.referralService.listOfBuildCards.length === 0)) {
      return;
    }
    this.referralService.builderPointsUsageAPISuccess = false;
    this.showReduceBuilderCarePopup = true;
    this.showReduceMonthlyPaymentPopup = false;
    this.showStartNewProjectPopup = false;
    this.referralService.selectedBuildCard = this.referralService.listOfBuildCards[0];
    this.sliderValue = 0;
    this.getBuilderPointsUsageData();
  }

  openStartNewProjectPopup() {
    this.showStartNewProjectPopup = true;
    this.showReduceBuilderCarePopup = false;
    this.showReduceMonthlyPaymentPopup = false;
    this.referralService.selectedBuildCard = this.referralService.listOfBuildCards[0];
    // this.sliderValue = 0;
    // this.getBuilderPointsUsageData();
  }

  goToMainMenu() {
    this.showReduceMonthlyPaymentPopup = false;
    this.showReduceBuilderCarePopup = false;
    this.showStartNewProjectPopup = false;
  }

  getListOfBuildcards() {
    this.referralService.getListOfBuildcards();
  }

  getSelectedBuildCard(e) {
    let selectedId = parseInt(e.target.value);
    this.referralService.selectedBuildCard = this.referralService.listOfBuildCards.find(item => item.id === selectedId);
    this.getBuilderPointsUsageData();
  }

  onSliderValueChange() {
    this.getBuilderPointsUsageData();
  }

  getBuilderPointsUsageData() {
    let typeForBuilderPointsUsage = this.getTypeForBuilderPointsUsage();
    let requestData = {
      build_card_id: this.referralService.selectedBuildCard.id,
      use_reward_points_for: typeForBuilderPointsUsage,
      reward_point_use: this.sliderValue
    };
    this.referralService.getBuilderPointsUsageData(requestData);
  }

  confirmBuilderPointsReduction() {
    let typeForBuilderPointsUsage = this.getTypeForBuilderPointsUsage();
    this.referralService.typeOfInstallmentsForConfirmPopup = this.getTypeOfInstallmentForConfirmPopup(typeForBuilderPointsUsage);
    let installmentData = [];
    for (let i=0; i < this.referralService.builderPointsUsageData.length; i++) {
      let obj = this.referralService.builderPointsUsageData[i];
      let requestData = {
        installment_id: obj.installment.id,
        new_installment_amount: obj.new_amount
      };
      installmentData.push(requestData);
    }
    let requestPayload = {
      use_reward_points_for: typeForBuilderPointsUsage,
      reward_point_use: this.sliderValue,
      installment: installmentData
    };
    this.referralService.sliderValueAfterBuilderPointsReductionConfirm = this.sliderValue;
    this.showReduceMonthlyPaymentPopup = false;
    this.showReduceBuilderCarePopup = false;
    this.showStartNewProjectPopup = false;
    this.referralService.showUseBuilderPointsPopup = false;
    
    this.referralService.confirmBuilderPointsReduction(requestPayload);
  }

  getTypeForBuilderPointsUsage() {
    let typeForBuilderPointsUsage = '';
    if (this.showReduceMonthlyPaymentPopup) {
      typeForBuilderPointsUsage = 'reduce_monthly_payment';
    } else if(this.showReduceBuilderCarePopup) {
      typeForBuilderPointsUsage = 'reduce_builder_care_payment';
    } 
    // else if(this.showStartNewProjectPopup) {
    //   typeForBuilderPointsUsage = 'start_new_project';
    // }
    return typeForBuilderPointsUsage;
  }

  startNewProject() {
    sessionStorage.setItem('start_project_referral_points', this.sliderValue.toString());
    this.router.navigate(['home']);
  }

  getTypeOfInstallmentForConfirmPopup(typeOfBuilderPointsUsage) {
    let typeOfInstallmentsForConfirmPopup = '';
    if (typeOfBuilderPointsUsage === 'reduce_monthly_payment') {
      typeOfInstallmentsForConfirmPopup = 'Monthly payments';
    } else if (typeOfBuilderPointsUsage === 'reduce_builder_care_payment') {
      typeOfInstallmentsForConfirmPopup = 'Builder Care payments';
    }
    return typeOfInstallmentsForConfirmPopup;
  }

  talkToExpert() {
    this.closeBuilderPointsUsagePopup();
    this.phaseService.isDemoCall = false;
    this.headerService.showScheduleCall();
  }
 
}
