import { Component, OnInit } from '@angular/core';
import {PhaseService} from '@shared/services/phase.service';
import {AppDataService} from '@rootmodule/app-data.service';
import {DataService} from '@shared/services/data.service';
import {Constants} from '@shared/utils/constants';
import {ApiRequests} from '@shared/services/api-requests.service';
import {environment} from '@env/environment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Angulartics2Segment} from 'angulartics2/segment';
import {Intercom} from 'ng-intercom';

@Component({
  selector: 'app-freemium-card-creation-overview',
  templateUrl: './freemium-card-creation-overview.component.html',
  styleUrls: ['./freemium-card-creation-overview.component.scss']
})
export class FreemiumCardCreationOverviewComponent implements OnInit {

  InvalidSubDomainErr = false;
  isInfoIcon = false;
  appDetailForm: FormGroup;

  constructor(public phaseService: PhaseService, public appDataService: AppDataService, public dataService: DataService,
              private apiRequests: ApiRequests, private fb: FormBuilder, private analyticsSegment: Angulartics2Segment) { }

  ngOnInit(): void {
    this.phaseService.buildCardUpdated = false;
    this.phaseService.cardStatusSelected = false;
    this.initializeForm();
  }

  initializeForm() {
    const buildCardData = this.dataService.buildCardData;
    if (buildCardData && buildCardData.id) {
      this.phaseService.fetchInstallments();
    }
    this.appDetailForm = this.fb.group({
      project_name: [(buildCardData && buildCardData.id) ? buildCardData.project_name : '', [Validators.required, Validators.maxLength(50)]],
      sub_domain: [(buildCardData && buildCardData.id) ? buildCardData['sub_domain'].split('/').slice(2).join('/') : '', Validators.required],
      app_country: [(buildCardData && buildCardData.id) ? buildCardData['app_country'] : '', Validators.required]
    });
  }

  get projectName() {
    return this.appDetailForm.get('project_name');
  }

  get subDomain() {
    return this.appDetailForm.get('sub_domain');
  }

  get appCountry() {
    return this.appDetailForm.get('app_country');
  }

  createBuildCard(signUpClickedSrc?) {
    this.phaseService.trackEventForObject(this.analyticsSegment,  'freemium_store_details_proceed_CTA_clicked', {
      user_id : this.dataService.user?.id
    });
    this.phaseService.selectedHash.promotion_id = this.dataService.homePageData?.promotion?.id;
    this.dataService.isFromAppDetails = true;
    this.phaseService.isInstallmentsChanged = true;
    this.phaseService.selectedHash.status = Constants.buildCardStatus.CARD_COMPLETED;
    this.phaseService.selectedHash.source = 'app_detail';
    this.phaseService.proceedClickedFromRental = true;
    if (this.dataService.user) {
      if (this.dataService.buildCardData && this.dataService.buildCardData.id) {
        this.updateBuildCard();
      } else {
        this.phaseService.selectedHash.project_name = this.projectName.value;
        this.phaseService.selectedHash['app_country'] = this.appCountry.value;
        this.phaseService.selectedHash['sub_domain'] = environment.STORE_URL+'ecommerce/' + this.subDomain.value;
        this.phaseService.setDataForFeaturePlatformAndTeam();
      }
    } else {
      this.dataService.signupSrcName = signUpClickedSrc;
      this.phaseService.preventClick = false;
      if (this.dataService.homePageData?.block_sign_up) {
        this.phaseService.showSignin = true;
        this.phaseService.showRegister = false;
      } else {
        this.phaseService.showRegister = true;
        this.phaseService.showSignin = false;
      }
      this.phaseService.openCommonAuth = true;
      this.phaseService.isNdaFlow = false;
    }
  }

  updateBuildCard() {
    const formValue = this.appDetailForm.value,
          buildCardData = this.dataService.buildCardData;
    formValue.sub_domain = environment.STORE_URL+'ecommerce/' + formValue.sub_domain;
    if ((formValue.sub_domain === buildCardData['sub_domain']) && (formValue.app_country === buildCardData['app_country']) &&
      (formValue.project_name === buildCardData['project_name'])) {
      this.phaseService.proceedClickedFromRental = true;
      this.phaseService.handleNextPrevForRentalFlow();
      return;
    }
    const updatedData = { 'build_card': formValue };
    this.apiRequests.updateFreemiuimBuildCard(updatedData, this.dataService.buildCardData.id).subscribe(() => {
      this.phaseService.buildCardUpdated = true;
      this.phaseService.proceedClickedFromRental = true;
      this.phaseService.handleNextPrevForRentalFlow();
    }, error => {
      if (error.error.errors.sub_domain.length) {
        this.phaseService.freemiumSubDomainExists = true;
        this.phaseService.proceedClickedFromRental = false;
      }
    });
  }

  hideErrorMsg() {
    this.phaseService.freemiumSubDomainExists = false;
  }

  trimValues() {
    this.projectName.setValue(this.projectName.value.trim());
    // this.subDomain.setValue(this.subDomain.value.trim());
  }

  validateSubDomainOnFocusLeave(){
    this.subDomain.setValue(this.subDomain.value.toLowerCase());
    if(this.appDetailForm.value.sub_domain.includes(' ') || this.subDomain.value.charAt(0) == '-'){
      this.InvalidSubDomainErr = true;
    }
  else{
    this.InvalidSubDomainErr = false;
    }
  }

  infoIconClicked() {
    this.isInfoIcon = !this.isInfoIcon;
  }
}
