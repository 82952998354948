<div class="dashHeading" *ngIf="dataService.getCardCount('invited') > 0">Invited {{ (dataService.getCardCount('invited') > 1) ? 'Cards' : 'Card' }}

  <div class="searchCards" *ngIf="!(showRecentInvited && dataService.getCardCount('invited') > 0)">
    <em class="icon-search"></em>
    <input type="text" placeholder="Search for Buildcard" [(ngModel)]="dataService.searchText" (keyup)="getBuildCardsWithSearch()">
    <div class="clearButton" *ngIf="dataService.searchText"><em class="icon-cancel" (click)="getBuildCardsWithSearch('true')"></em></div>
  </div>

  <button type="button" (click)="showCards(4)" *ngIf="showRecentInvited && dataService.getCardCount('invited') > 4">Show all
  </button>
</div>

<div class="dashProjectRow" [ngClass]="{'hideCard': showRecentProgress}" infiniteScroll [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="300" (scrolled)="onScroll(dataService.getCardCount('invited'))">
  <!-- new design card -->
  <div class="progressCard" [ngClass]="{'updated': isBuildCardEditedAndHighlighted(card)}"
    *ngFor="let card of (showRecentProgress ? (getInvitedCards() | slice:0: (dataService.getCardCount('invited') > 4 ? 4: dataService.getCardCount('invited'))) : getInvitedCards());let i = index;">
    <div class="cardHead">
      <div class="cardLeft">
        <h3>{{card.project_name ? card.project_name : 'InProgress' + (i < 9? '0'+ (i+1): (i+1))}}
          <div class="cardAnimationIcon" *ngIf="isBuildCardEditedAndHighlighted(card)">
            <div class="pulse"></div>
            <div class="dot"></div>
          </div>
        </h3>
        <h4>Last edited: {{card.updated_at}}</h4>
      </div>
      <div class="cardRight">
        <div class="cardMore"><em class="icon-more"></em>
          <div class="cardDropDown">
            <ul>
              <li (click)="cloneBuildCard(card.uniq_code, card.currency)">Duplicate card</li>
              <li (click)="showHideInvitedUserPopup(true, card)" *ngIf="card.invites && card.invites.length > 0">Invited
                Users</li>
              <li (click)="openBuildCard(card, true, true)"
                *ngIf="!isStatusPaymentReceived(card) && !isStatusInProgress(card)">
                Edit Card
              </li>
              <li (click)="showDeleteConfirmation(card)">
                Archive Card
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="cardMiddle"
      *ngIf="!isStatusPaymentReceived(card) && !isStatusInProgress(card) && !isStatusOnHold(card)">
      <div class="completeDetail">
        <div class="completeRow">
          <div class="completeBox" [ngClass]="{'deactive-fade': card.template_count === 0}">
            <h3>{{card.template_count > 1 ? 'Templates' : 'Template'}} <span>{{card.template_count}}</span></h3>
          </div>
          <div class="completeBox" [ngClass]="{'deactive-fade': card.feature_count === 0}">
            <h3>{{card.feature_count > 1 ? 'Features' : 'Feature'}} <span>{{card.feature_count}}</span></h3>
          </div>
        </div>
        <div class="completeRow">
          <h4 [ngClass]="{'deactive-fade': !card.team}">
            <div class="team-dash-country">Team <span>{{card.team? card.team:""}}</span></div>
          </h4>
          <h4 [ngClass]="{'deactive-fade': !card.weeks || card.weeks === 0}">
            <div>Duration
              <ng-container *ngIf="card.frequency === 'monthly'; else showWeekly">
                 <span>{{card.installment_counts.monthly}} {{ card.installment_counts.monthly > 1 ? 'months' : 'month'}}
                ({{(card.weeks + (card.change_week_status ? card.change_week : -card.change_week))}}
              {{ (card.weeks + (card.change_week_status ? card.change_week : -card.change_week)) > 1 ? 'weeks' : 'week'}})</span>
              </ng-container>
              <ng-template #showWeekly>
                <span>{{(card.weeks + (card.change_week_status ? card.change_week : -card.change_week))}}
              {{ (card.weeks + (card.change_week_status ? card.change_week : -card.change_week)) > 1 ? 'weeks' : 'week'}}</span>
              </ng-template>
            </div>
          </h4>
        </div>
      </div>
      <div class="projectPhases"
        [ngClass]="{'deactive-fade': !card.build_card_phases || card.build_card_phases.length === 0}">
        <ul>
          <li *ngFor="let phase of card.build_card_phases">
            <div class="phaseTitle">{{ setPhaseName(phase, card) }}</div>
            <div class="phaseDetail" *ngIf="!phase.is_tailor_made && phase.platforms?.length > 0">
              <div class="phaseIcon" *ngFor="let platform of phase.platforms">
                <img [src]="platform.icon" alt="" />
                <div class="phaseIconTooltip">{{platform.title}}</div>
              </div>
            </div>

            <div class="phaseDetail" *ngIf="phase.is_tailor_made &&  phase.platforms?.length > 1">
              <div class="morePhase" >+ {{ phase.platforms?.length - 1 }}
                <div class="platformTooltip">
                  <h5>{{ phase.platforms?.length || 0 }} Platform Selected</h5>
                  <h6 *ngFor="let platform of phase.platforms"><em class="icon-right"></em>{{ platform.title }}</h6>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="builderCare" *ngIf="card.is_under_support || card.cloud_opted || card.donation_opted">
        <span>Includes</span>
        <span>
          <img src="../../../../../../../assets/images/BuilderCare-icon.svg" *ngIf=" card.is_under_support" alt="" />
          <img src="../../../../../../../assets/images/BuilderCloud-icon.svg" *ngIf="card.cloud_opted" alt="" />
        </span>
        <span *ngIf="card.donation_opted"><em class="icon-right"></em>
          Donation fund - {{card?.currency?.symbol}}{{card.donation_amount}}
        </span>
      </div>
    </div>

    <div class="cardMiddle" *ngIf="isStatusPaymentReceived(card)">
      <div class="cardPayStatus recieved">
        <div class="payIcons"><em></em></div>
        <div class="headline">Payment received.</div>
        <p>Our team is working on it and we'll reach you shortly.</p>

        <button type="button" class="paymentStatusButton" (click)="showPaymentStatus(card)"
        *ngIf="isStatusInProgressNoDevState(card)">Payment Status</button>
      </div>
    </div>

    <div class="cardMiddle" *ngIf="isStatusInProgress(card)">
      <div class="runningDetail">
        <div class="pieChart">
          <round-progress [current]="card.percentage_completed ? card.percentage_completed : 0" [max]="100"
            [color]="'#6C00EA'" [background]="'#eff2f9'" [radius]="75" [stroke]="15" [semicircle]="false"
            [rounded]="true" [clockwise]="true" [responsive]="false" [duration]="800" [animation]="'easeInOutQuart'"
            [animationDelay]="0"></round-progress>
          <h5>{{card.percentage_completed ? card.percentage_completed : '0'}}%</h5>
        </div>

        <div class="statusTag" *ngIf="card.pm_status" [ngClass]="{'gray': card.pm_status === 'in_90_day_bug_fixing',
           'red': card.pm_status === 'blocked',
           'blue': card.pm_status === 'ongoing',
           'green': card.pm_status === 'completed',
           'orange': card.pm_status === 'builder_care'}">
        {{card.pm_status === 'in_90_day_bug_fixing' ? 'Bug Fixing Window' : card.pm_status ==='builder_care' ? 'Builder Care' : (card.pm_status | titlecase)}}
      </div>
      </div>
    </div>

    <div class="cardMiddle" *ngIf="isStatusOnHold(card)">
      <div class="cardPayStatus pending">
        <div class="payIcons"><em class="icon-paypending"></em></div>
        <div class="headline">Payment pending for this month.</div>
        <!--<p>Please take required action to restart the Buildcard<sup class="tm light">®</sup>.</p>-->
      </div>
    </div>

    <div class="cardBottom" *ngIf="isStatusPaymentReceived(card) || isStatusInProgress(card)">
      <div class="priceTag" *ngIf="!card.owner">
        <div>{{card?.currency?.symbol}}{{getLocaledPrice((
          getDiscountedprice(card))
          | number : '1.2-2', card.currency)}}
        </div>
        <div class="strick"
          *ngIf="getDiscountedprice(card) < getprice(card) && card.change_amount === 0">
          {{getLocaledPrice((getprice(card))| number : '1.2-2', card.currency)}}
        </div>
      </div>
      <div class="priceTag" *ngIf="card.owner">
        <div>{{card?.currency?.symbol}}{{getLocaledPrice((
          getDiscountedprice(card))
          | number : '1.2-2', card.currency)}}
        </div>
        <div class="strick"
          *ngIf="getDiscountedprice(card) < getprice(card) && card.change_amount === 0">
          {{getLocaledPrice((getprice(card))| number : '1.2-2', card.currency)}}
        </div>
      </div>
      <!--<button type="button" class="forpayment" (click)="showPaymentStatus(card)"
        *ngIf="isStatusInProgressNoDevState(card)">
        Payment Status
      </button>-->

      <button (click)="viewProgress(card)" *ngIf="isStatusInProgress(card)">
        View progress
      </button>
      <button (click)="openBuildCard(card, true)" *ngIf="isStatusInProgressNoDevState(card)">
        View <img *ngIf="preventClick && card && selectedCard && card.id === selectedCard.id"
          src="./../../../assets/images/buttonLoader.gif" alt="" /></button>
      <div class="clearfix"></div>
      <div class="info-blue" *ngIf="card.owner_email">
        <p>Shared by: {{ card.owner_email}}</p>
      </div>
    </div>

    <div class="cardBottom" *ngIf="!isStatusPaymentReceived(card) && !isStatusInProgress(card)">
      <div class="priceTag" *ngIf="!card.owner">
        <div>{{card?.currency?.symbol}}{{getLocaledPrice((
          getDiscountedprice(card))
            | number : '1.2-2', card.currency)}}
        </div>
        <div class="strick"
          *ngIf="getDiscountedprice(card) < getprice(card) && card.change_amount === 0">
          {{getLocaledPrice((getprice(card) | number : '1.2-2'), card.currency)}}
        </div>
      </div>
      <div class="priceTag" *ngIf="card.owner">
        <div>{{card?.currency?.symbol}}{{getLocaledPrice(((
          getDiscountedprice(card))
          | number : '1.2-2'), card.currency)}}
        </div>
        <div class="strick"
          *ngIf="getDiscountedprice(card) < getprice(card) && card.change_amount === 0">
          {{getLocaledPrice(((getprice(card))| number : '1.2-2'), card.currency)}}
        </div>
      </div>
      <div *ngIf="card.specing_paid">
        <div class="specingPaid">Spec'ing amount paid</div>
        <div class="priceTag">
          {{card?.currency?.symbol}}{{getLocaledPrice((phaseService.specing_price)
          | number : '1.2-2', card.currency)}}
        </div>
      </div>
      <button (click)="phaseService.showTimeKitWindow()" *ngIf="card.specing_paid && !card.pre_kick_off_date">Schedule
        Call</button>
      <button (click)="openBuildCard(card, true)">View <img
          *ngIf="preventClick && card && selectedCard && card.id === selectedCard.id"
          src="./../../../assets/images/buttonLoader.gif" /></button>
      <!--<button (click)="openBuildCard(card)" *ngIf="!card.specing_paid">Complete Card <img *ngIf="preventClick && card && selectedCard && card.id === selectedCard.id " src="./../../../assets/images/buttonLoader.gif" alt=""/></button>-->
      <div class="clearfix"></div>
      <div class="info-blue" *ngIf="card.owner_email">
        <p>Shared by: {{ card.owner_email}}</p>
      </div>
    </div>
  </div>
  <app-progress-card-load class="progressCardload" *ngFor="let dummy of ' '.repeat(loaderCount).split(''), let x = index" [loaderCount]='loaderCount'></app-progress-card-load>
</div>
<div class="dashProjectRow invited" *ngIf="dataService.getCardCount('invited') > 0 && !(dataService.invitedCards.length > 0) && !isApiSuccess">
  <app-progress-card-load class="progressCardload" *ngFor="let dummy of ' '.repeat(4).split(''), let x = index"></app-progress-card-load>
</div>
<div class="dashProjectRow" *ngIf="isApiSuccess && (dataService.invitedCards.length === 0)">
  <div class="norecordHeading">No records found.</div>
</div>

<app-duplicate-card-modal *ngIf="commonService.isDuplicateCardFlow"></app-duplicate-card-modal>
